/**
 * Created by Sergey Panpurin on 9/2/19.
 */

// @ts-check
(function btRiskBasketServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btRiskBasketService:';

  /**
   * This service ...
   *
   * @ngdoc service
   * @name ecapp.btRiskBasketService
   */

  angular.module('ecapp').factory('btRiskBasketService', service);

  service.$inject = ['$q', '$timeout', 'Risk', 'btColorService', 'btToolsService', 'btInstrumentsService'];

  /**
   *
   * @param {angular.IQService} $q - promise interface
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.IGeneralLoopbackService} lbRisk
   * @param {ecapp.IColorService} btColorService
   * @param {ecapp.IToolsService} btToolsService
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @return {ecapp.IRiskBasketService}
   */
  function service($q, $timeout, lbRisk, btColorService, btToolsService, btInstrumentsService) {
    if (gDebug) console.log(gPrefix, 'running...');

    var gInitialized = false;

    /** @type {ecapp.IRiskIndicator[]}*/
    var gIndicatorsList = [];

    /** @type {Record<string, ecapp.IRiskIndicator>} */
    var gIndicators = {};

    /** @type {ecapp.IRiskBasket[]} */
    var gBasketsList = [];

    /** @type {Record<string, ecapp.IRiskBasket>} */
    var gBaskets = {};

    /** @type {ecapp.IRiskBasket} */
    var gSelectedBasket = {
      id: 'n/a',
      name: 'n/a',
      class: 'neutral',
      magnitude: 0,
      deviation: 0,
      realtime: [],
      intervals: {},
      history: [],
      indicators: [],
      desc: 'Description',
      benefits: [],
    };

    /** @type {number} */
    var gLastHistory = 0;

    /** @type {number} */
    var REALTIME_BUFFER_SIZE = 10;

    return {
      initialize: initialize,
      reinitialize: reinitialize,
      loadHistory: loadHistory,
      updateHistory: updateHistory,
      getBaskets: getBaskets,
      getBasketById: getBasketById,
      selectBasket: updateSelectedBasket,
      getSelectedBasket: getSelectedBasket,
      isSelectedBasket: isSelectedBasket,
      updateBaskets: updateBaskets,
      updateIndicators: updateIndicators,
      getIndicatorById: getIndicatorById,
      getIndicators: getIndicators,
    };

    /**
     * This function creates a new indicator object using document from database.
     *
     * @param {ecapp.IRiskIndicatorDocument} doc - indicator document from database
     * @param {number} i - index
     * @param {ecapp.IRiskInterval[]} intervals - list of interval objects
     * @return {ecapp.IRiskIndicator}
     */
    function createIndicator(doc, i, intervals) {
      var ind = {
        id: doc.id,
        oanda: doc.oanda,
        instrument: btInstrumentsService.getInstrumentByComplexSymbol(doc.oanda + ':OANDA'),
        color: btColorService.getIColor(i),
        short: doc.short,
        name: doc.name,
        magnitude: 0,
        reversed: doc.reversed,
        history: [],
        realtime: [],
        intervals: initializeIntervals(intervals),
      };

      // ind.instrument.test = 'AAA';

      return ind;
    }

    /**
     * This function creates a new indicator object using document from database.
     *
     * @param {ecapp.IRiskBasketDocument} doc - indicator document from database
     * @param {ecapp.IRiskInterval[]} intervals - list of interval objects
     * @return {ecapp.IRiskBasket}
     */
    function createBasket(doc, intervals) {
      return {
        id: doc.id,
        name: doc.name,
        class: 'neutral',
        magnitude: 0,
        spread: [0, 0],
        deviation: 0,
        realtime: [],
        intervals: initializeIntervals(intervals),
        history: [],
        indicators: doc.classes.reduce(function (indicators, cls) {
          indicators = indicators.concat(
            cls.indicators.map(function (indicator) {
              return getIndicatorById(indicator.id);
            })
          );
          return indicators;
        }, []),
        desc: doc.desc,
        benefits: doc.benefits,
      };
    }

    /**
     *
     * @alias ecapp.btRiskBasketService#initialize
     * @param {ecapp.IRiskIndicatorDocument[]} indicators - indicators documents from database
     * @param {ecapp.IRiskBasketDocument[]} baskets - baskets documents from database
     * @param {ecapp.IRiskInterval[]} intervals - list of interval objects
     * @param {string} [userBasket] - identifier of basket saved in user settings
     * @return {angular.IPromise<boolean>}
     */
    function initialize(indicators, baskets, intervals, userBasket) {
      if (!gInitialized) {
        return btInstrumentsService.init().then(function () {
          // console.log('TEST: Initialized indicators');
          // Indicators
          btToolsService.clearList(gIndicatorsList);
          btToolsService.updateList(
            gIndicatorsList,
            indicators.map(function (indicator, i) {
              return createIndicator(indicator, i, intervals);
            })
          );
          btToolsService.clearDict(gIndicators);
          btToolsService.updateDict(gIndicators, btToolsService.convertListToDict(gIndicatorsList));

          // Baskets
          btToolsService.clearList(gBasketsList);
          btToolsService.updateList(
            gBasketsList,
            baskets.map(function (basket) {
              return createBasket(basket, intervals);
            })
          );
          btToolsService.clearDict(gBaskets);
          btToolsService.updateDict(gBaskets, btToolsService.convertListToDict(gBasketsList));

          if (userBasket && getBasketById(userBasket)) updateSelectedBasket(getBasketById(userBasket));
          else updateSelectedBasket(gBasketsList[0]);

          gInitialized = true;
          return true;
        });
      } else {
        return $q.resolve(true);
      }
    }

    /**
     *
     * @alias ecapp.btRiskBasketService#reinitialize
     * @param {ecapp.IRiskIndicatorDocument[]} indicators - indicators documents from database
     * @param {ecapp.IRiskBasketDocument[]} baskets - baskets documents from database
     * @param {ecapp.IRiskInterval[]} intervals - list of interval objects
     * @return {angular.IPromise<boolean>}
     */
    function reinitialize(indicators, baskets, intervals) {
      gInitialized = false;
      return initialize(indicators, baskets, intervals);
    }

    /**
     * @param {ecapp.IRiskInterval[]} intervals - list of interval objects
     * @return {Record<string, ecapp.IRiskRecord[]>}
     */
    function initializeIntervals(intervals) {
      return intervals.reduce(function (res, value) {
        res[value.granularity] = [];
        return res;
      }, {});
    }

    /**
     *
     * @alias ecapp.btRiskBasketService#getIndicators
     * @return {ecapp.IRiskIndicator[]}
     */
    function getIndicators() {
      return gIndicatorsList;
    }

    /**
     *
     * @alias ecapp.btRiskBasketService#getIndicatorById
     * @param {string} id
     * @return {ecapp.IRiskIndicator}
     */
    function getIndicatorById(id) {
      return gIndicators[id];
    }

    /**
     *
     * @alias ecapp.btRiskBasketService#getBaskets
     * @return {ecapp.IRiskBasket[]}
     */
    function getBaskets() {
      return gBasketsList;
    }

    /**
     * This function returns basket with specified identifier.
     *
     * @alias ecapp.btRiskBasketService#getBasketById
     * @param {string} id - basket identifier
     * @return {ecapp.IRiskBasket} - basket with specified identifier
     */
    function getBasketById(id) {
      return gBaskets[id];
    }

    /**
     *
     * @alias ecapp.btRiskBasketService#selectBasket
     * @param {ecapp.IRiskBasket} basket
     * @return {boolean}
     */
    function updateSelectedBasket(basket) {
      if (basket && getBasketById(basket.id)) {
        // angular.extend(gSelectedBasket, basket);
        // console.log('TEST: Moved from', gSelectedBasket.id, 'to', basket.id);
        gSelectedBasket.id = basket.id;
        gSelectedBasket.name = basket.name;
        gSelectedBasket.class = basket.class;
        gSelectedBasket.magnitude = basket.magnitude;
        gSelectedBasket.spread = basket.spread;
        gSelectedBasket.deviation = basket.deviation;
        gSelectedBasket.realtime = basket.realtime;
        gSelectedBasket.intervals = basket.intervals;
        gSelectedBasket.history = basket.history;
        gSelectedBasket.indicators = basket.indicators;
        gSelectedBasket.desc = basket.desc;
        gSelectedBasket.benefits = basket.benefits;

        // console.log('TEST: baskets', gBasketsList.map(function (basket) {
        //   return basket.indicators.map(function (indicator) {
        //     return indicator.instrument;
        //   })
        // }));

        // console.log('TEST: selected', gSelectedBasket.indicators.map(function (indicator) {
        //   return indicator.instrument;
        // }));

        return true;
      } else {
        return false;
      }
    }

    /**
     *
     * @alias ecapp.btRiskBasketService#getSelectedBasket
     * @return {ecapp.IRiskBasket}
     */
    function getSelectedBasket() {
      return gSelectedBasket;
    }

    /**
     *
     * @alias ecapp.btRiskBasketService#isSelectedBasket
     * @param {ecapp.IRiskBasket} basket
     * @return {boolean}
     */
    function isSelectedBasket(basket) {
      return gSelectedBasket.id === basket.id;
    }

    /**
     *
     * @alias ecapp.btRiskBasketService#updateBaskets
     * @param {ecapp.IRiskMessage} [message]
     * @param {ecapp.IRiskInterval} [interval]
     */
    function updateBaskets(message, interval) {
      getBaskets().forEach(function (basket) {
        if (message && interval) updateBasketMagnitude(basket, message, interval);
        updateBasketClass(basket);
      });

      updateSelectedBasket(getBasketById(gSelectedBasket.id));
      // var update = getBasketById(gSelectedBasket.id);
      // if (update) angular.extend(gSelectedBasket, update);
    }

    /**
     *
     * @alias ecapp.btRiskBasketService#loadHistory
     * @param {ecapp.IRiskInterval} interval
     * @return {angular.IPromise}
     */
    function loadHistory(interval) {
      return loadInitialRisks(interval.granularity, interval.history).then(function (docs) {
        // Clear historical data for each indicator
        getIndicators().forEach(function (ind) {
          clearHistory(ind.history);
        });

        // Clear historical data for each basket
        getBaskets().forEach(function (bsk) {
          clearHistory(bsk.history);
        });

        gLastHistory = 0;

        // Parse loaded data
        if (docs.length) {
          // Get timestamp of last point
          gLastHistory = docs[docs.length - 1].time * 1000;

          // Add new data to indicators and baskets
          docs.forEach(function (doc) {
            Object.keys(doc.indicators).forEach(function (key) {
              var ind = getIndicatorById(key);
              if (ind)
                ind.history.push({
                  time: doc.time * 1000,
                  mgn: doc.indicators[key],
                });
            });

            Object.keys(doc.baskets).forEach(function (key) {
              var bsk = getBasketById(key);
              if (bsk) {
                bsk.history.push({
                  time: doc.time * 1000,
                  mgn: doc.baskets[key],
                  dev: doc.deviations ? doc.deviations[key] : 0,
                });
              }
            });
          });
        }

        // Normalize indicators
        getIndicators().forEach(function (ind) {
          if (ind.history.length) {
            // Use last historical value as a current magnitude
            ind.magnitude = ind.history[ind.history.length - 1].mgn;

            // Reserve last value for real-time update
            ind.history.push(ind.history[ind.history.length - 1]);
            normalizeHistory(ind.history, interval.history);
          }
        });

        // Normalize baskets
        getBaskets().forEach(function (bsk) {
          if (bsk.history.length) {
            // Use last historical value as a current magnitude
            bsk.magnitude = bsk.history[bsk.history.length - 1].mgn;
            bsk.spread = [bsk.magnitude, bsk.magnitude];
            bsk.deviation = bsk.history[bsk.history.length - 1].dev;

            // Reserve last value for real-time update
            bsk.history.push(bsk.history[bsk.history.length - 1]);
            normalizeHistory(bsk.history, interval.history);
          }
        });

        return true;
      });
    }

    /**
     *
     * @alias ecapp.btRiskBasketService#updateHistory
     * @param {ecapp.IRiskInterval} interval
     * @return {angular.IPromise}
     */
    function updateHistory(interval) {
      if (gLastHistory === 0) return $q.reject(new Error('History is not initialized!'));

      return loadNewRisks(interval.granularity, gLastHistory).then(function (docs) {
        if (docs.length === 0) return false;

        gLastHistory = docs[docs.length - 1].time * 1000;

        docs.forEach(function (doc) {
          Object.keys(doc.indicators).forEach(function (key) {
            var ind = getIndicatorById(key);
            if (ind) {
              var rt = ind.history.pop();
              ind.history.push({ time: doc.time * 1000, mgn: doc.indicators[key] });
              ind.history.push(rt);
            }
          });

          Object.keys(doc.baskets).forEach(function (key) {
            var bsk = getBasketById(key);
            if (bsk) {
              var rt = bsk.history.pop();
              bsk.spread = [rt.mgn, rt.mgn];
              bsk.history.push({
                time: doc.time * 1000,
                mgn: doc.baskets[key],
                dev: doc.deviations ? doc.deviations[key] : 0,
              });
              bsk.history.push(rt);
            }
          });
        });

        getIndicators().forEach(function (ind) {
          normalizeHistory(ind.history, interval.history);
        });

        getBaskets().forEach(function (bsk) {
          normalizeHistory(bsk.history, interval.history);
        });

        return true;
      });
    }

    /**
     *
     * @param {Array} history - history
     */
    function clearHistory(history) {
      history.splice(0, history.length);
    }

    /**
     *
     * @param {Array} history - history
     * @param {number} size - size
     */
    function normalizeHistory(history, size) {
      // last value is reserved for real-time update
      if (history.length > size + 1) {
        history.splice(0, history.length - (size + 1));
      }
    }

    /**
     * This function updates indicators.
     *
     * @alias ecapp.btRiskBasketService#updateIndicators
     * @param {ecapp.IRiskMessage} msg - message
     * @param {ecapp.IRiskInterval} int - interval
     */
    function updateIndicators(msg, int) {
      if (msg && msg.indicators) {
        Object.keys(msg.indicators).forEach(function (symbol) {
          var ind = gIndicators[symbol];
          if (ind) {
            ind.magnitude = msg.indicators[symbol][int.granularity];
            ind.history[ind.history.length - 1] = { time: msg.time * 1000, mgn: ind.magnitude };
            ind.realtime.push({ time: msg.time * 1000, magnitudes: msg.indicators[symbol] });
            if (ind.realtime.length > REALTIME_BUFFER_SIZE) ind.realtime.shift();

            if (ind.instrument.history) {
              var last = ind.instrument.history[ind.instrument.history.length - 1];
              var p = btInstrumentsService.getPrecision(ind.instrument);
              var price = parseFloat(msg.indicators[symbol]['RAW'].toFixed(p));

              if (last) {
                last[0] = msg.time * 1000;
                last[1] = price;
                last[2] = Math.max(last[2], price);
                last[3] = Math.min(last[3], price);
                last[4] = price;
              }
            }
          }
        });
      }
    }

    /**
     * This function updates basket magnitude.
     *
     * @private
     * @param {ecapp.IRiskBasket} bsk - basket
     * @param {ecapp.IRiskMessage} msg - message
     * @param {ecapp.IRiskInterval} int - interval
     */
    function updateBasketMagnitude(bsk, msg, int) {
      if (msg.baskets && msg.baskets[bsk.id]) {
        if (msg.baskets[bsk.id][int.granularity]) {
          bsk.magnitude = msg.baskets[bsk.id][int.granularity];
          bsk.spread[0] = Math.min(bsk.magnitude, bsk.spread[0]);
          bsk.spread[1] = Math.max(bsk.magnitude, bsk.spread[0]);
          bsk.deviation = msg.deviations[bsk.id][int.granularity];
        }
        bsk.history[bsk.history.length - 1] = { time: msg.time * 1000, mgn: bsk.magnitude, dev: bsk.deviation };
        bsk.realtime.push({
          time: msg.time * 1000,
          magnitudes: msg.baskets[bsk.id],
          deviations: msg.deviations[bsk.id],
        });
        if (bsk.realtime.length > REALTIME_BUFFER_SIZE) bsk.realtime.shift();
      }
    }

    /**
     * @private
     * @param {ecapp.IRiskBasket} basket - basket
     */
    function updateBasketClass(basket) {
      if (basket.magnitude > 0) {
        basket.class = 'positive'; // negative, neutral
      } else if (basket.magnitude < 0) {
        basket.class = 'negative'; // negative, neutral
      } else {
        basket.class = 'neutral'; // negative, neutral
      }
    }

    /**
     * This function loads initial risk documents.
     *
     * @alias ecapp.btRiskService#getLastRisks
     * @param {string} granularity - granularity
     * @param {number} count - number of values
     * @return {angular.IPromise<ecapp.IRiskDocument[]>}
     */
    function loadInitialRisks(granularity, count) {
      var query = {
        filter: {
          where: { granularity: granularity },
          order: ['time DESC'],
          limit: count,
        },
      };

      return lbRisk.find(query).$promise.then(function (results) {
        return results.reverse();
      });
    }

    /**
     * This function loads new risk documents.
     *
     * @alias ecapp.btRiskService#getLastRisks
     * @param {string} granularity - granularity
     * @param {number} from - timestamp in se
     * @return {angular.IPromise<ecapp.IRiskDocument[]>}
     */
    function loadNewRisks(granularity, from) {
      var query = {
        filter: {
          where: { granularity: granularity, time: { gt: Math.floor(from / 1000) } },
          order: ['time ASC'],
        },
      };

      return lbRisk.find(query).$promise.then(function (results) {
        return results;
      });
    }
  }
})();
