/**
 * Created by yatree on 21/08/16.
 */

(function btEventFollowClosure() {
  'use strict';

  /**
   * This directive manage following event.
   *
   * @ngdoc directive
   * @name btEventFollow
   * @memberOf ecapp
   * @scope
   * @param {*} eventId - ?
   * @param {*} eventName - ?
   * @attribute {*} showLabel - ?
   * @attribute {*} upgradeDisabled - ?
   */

  angular.module('ecapp').directive('btEventFollow', btEventFollow).controller('btEventFollowController', Controller);

  btEventFollow.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btEventFollow($templateCache) {
    return {
      restrict: 'E',
      template: $templateCache.get('directives/common/bt-event-follow.html'),
      scope: {
        eventId: '<',
        eventName: '<',
        // showLabel: '<',
        // upgradeDisabled: '<'
      },
      controller: 'btEventFollowController',
    };
  }

  Controller.$inject = [
    '$scope',
    '$attrs',
    '$element',
    '$q',
    '$rootScope',
    '$analytics',
    'btAuthService',
    'btToastrService',
    'btShareScopeService',
    'btVoiceAssistantHelperService',
    'btEventsService',
    'btRestrictionService',
  ];

  /**
   *
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.IAttributes} $attrs - element attributes
   * @param {angular.IRootElementService} $element
   * @param {angular.IQService} $q - promise service
   * @param {angular.IRootScopeService} $rootScope - root scope service
   * @param {ext.IAnalyticsService} $analytics
   * @param {ecapp.IAuthService} btAuthService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IVoiceAssistantHelperService} btVoiceAssistantHelperService
   * @param {ecapp.IEventsService} btEventsService
   * @param {ecapp.IRestrictionService} btRestrictionService
   */
  function Controller(
    $scope,
    $attrs,
    $element,
    $q,
    $rootScope,
    $analytics,
    btAuthService,
    btToastrService,
    btShareScopeService,
    btVoiceAssistantHelperService,
    btEventsService,
    btRestrictionService
  ) {
    // by default upgrade is enabled
    var gHasUpgrade = !($attrs.upgradeDisabled === 'true');

    // click counter
    $scope.clicked = 0;

    // follow flag (is user follow this event?)
    $scope.isFollowed = false;

    // label text
    $scope.labelText = $attrs.showLabel === 'true' ? 'Follow' : '';

    $scope.changeFollowing = changeFollowing;

    // update follow flag if watching event was changed
    $scope.$watch('eventId', onEventIdChange);

    // listen follow event
    $scope.$on('calendar:follow-event', onFollowEvent);
    $scope.$on('tutorial:toggle-event', onToggleEvent);

    // initialize component
    initState();

    /**
     * This function initializes follow flag.
     */
    function initState() {
      $scope.isFollowed = btShareScopeService.getListFollowedEvents().indexOf(parseInt($scope.eventId)) >= 0;
    }

    /**
     * This function changes follow flag on follow event.
     *
     * @param {object} event - ionic event object
     * @param {number} eventId - economic event id
     * @param {boolean} state - follow flag
     */
    function onFollowEvent(event, eventId, state) {
      void event;
      void state;

      if (eventId === $scope.eventId) {
        if ($scope.clicked > 0) {
          // skip
        } else {
          initState();
        }
      }
    }

    /**
     * This function changes follow flag on follow event.
     *
     * @param {object} event - ionic event object
     * @param {number} eventId - economic event id
     */
    function onToggleEvent(event, eventId) {
      void event;

      if (eventId === $scope.eventId) {
        // console.log('TEST: on tutorial:toggle-event', eventId, ' - ',
        //   $scope.$id,
        //   $scope.$parent.$id,
        //   $scope.$parent.$parent.$id,
        //   $scope.$parent.$parent.$parent.$id,
        //   $scope.$parent.$parent.$parent.$parent.$id,
        //   $scope.$parent.$parent.$parent.$parent.$parent.$id,
        //   $scope.$parent.$parent.$parent.$parent.$parent.$parent.$id,
        //   $scope.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$id
        // );

        changeFollowing(eventId.toString(), !$scope.isFollowed);
      }
    }

    /**
     * This function reinitializes follow flag if eventId was changed.
     *
     * @param {number} newValue - new economic event id
     * @param {number} oldValue - old economic event id
     */
    function onEventIdChange(newValue, oldValue) {
      if (newValue !== oldValue) {
        initState();
      }
    }

    /**
     * This function really changes the state.
     *
     * @param {string} data - economic event id
     * @param {boolean} checked - follow flag
     */
    function changeFollowing(data, checked) {
      if (!btRestrictionService.hasFeature('personalization')) {
        btRestrictionService.showUpgradePopup('personalization');
        return;
      }

      // increase click counter
      if ($scope.clicked !== 0) {
        return;
      }

      $scope.clicked += 1;

      // load information about current user from database
      // btAuthService.getCurrInfo()
      btShareScopeService
        .wait()
        .then(function () {
          return parseFollowingEvents(btShareScopeService.accountInfo);
        })
        .then(function (followingEvents) {
          var c = followingEvents.length;
          var l = btRestrictionService.getCapacity('followed-events');
          if (c >= l && !(followingEvents.indexOf(parseInt($scope.eventId)) >= 0)) {
            if (!gHasUpgrade) {
              return $q.reject(new Error('' + c + '/' + l + ' Follow Events limit was reached!'));
            } else {
              return btRestrictionService.showUpgradePopup('follow-events').then(function (status) {
                if (status === 'updated') {
                  console.log('updated');
                  return followingEvents;
                } else {
                  return $q.reject(
                    new Error('' + c + '/' + l + ' Follow Events limit was reached! Try to upgrade subscription plan.')
                  );
                }
              });
            }
          } else {
            return followingEvents;
          }
        })
        .catch(function (reason) {
          btToastrService.error(reason.message, 'Settings', { type: 'settings' });
          return $q.reject(reason);
        })
        .then(function (followingEvents) {
          if (followingEvents === null) {
            return followingEvents;
          }

          // if follow this event
          if (followingEvents.indexOf(parseInt($scope.eventId)) >= 0) {
            // followingEvents.splice(followingEvents.indexOf(parseInt(data)), 1);

            // update listOfFollowedEvents
            btShareScopeService.removeFollowedEvent($scope.eventId);
            $scope.isFollowed = false;

            sendAnalytics('unfollow', $scope.eventId);
          } else {
            // followingEvents.push(parseInt($scope.eventId));

            // update listOfFollowedEvents
            btShareScopeService.addFollowedEvent($scope.eventId);
            $scope.isFollowed = true;

            sendAnalytics('follow', $scope.eventId);
          }

          // emit toast confirmation
          btToastrService.info((checked ? 'Follow' : 'Unfollow') + ' - ' + $scope.eventName, 'Settings', {
            type: 'settings',
          });

          if (window.isDevMode) {
            var event = btEventsService.getEventByIdFromCache($scope.eventId);
            btVoiceAssistantHelperService.readMessage(
              (checked ? 'Follow ' : 'Stop follow ') + btEventsService.getPronunciation(event),
              'calendar',
              'follow'
            );
          }

          $rootScope.$broadcast('calendar:follow-event', $scope.eventId, $scope.isFollowed);

          // TODO: Add expression to check null value before push to mongo
          return btShareScopeService.updateProfileSettingsFromFieldsList(['followingEvents']);
        })
        .catch(function (reason) {
          console.error('btEventFollow: error', reason);
        })
        .finally(function () {
          $scope.clicked -= 1;
        });
    }

    /**
     * This function parses following events from user data.
     *
     * @param {object} response - loopback response
     * @return {Array} - following events
     */
    function parseFollowingEvents(response) {
      if (!Array.isArray(response.followingEvents)) {
        response.followingEvents = [];
      }

      return response.followingEvents;
    }

    /**
     * This function sends analytics.
     *
     * @param {string} action - action 'followEvent' or 'unFollowEvent'
     * @param {number} eventId - economic event id
     */
    function sendAnalytics(action, eventId) {
      if (window.btSettings.BT_DOMAIN === 'bettertrader') {
        $analytics.eventTrack(action, { category: 'settings', label: 'calendar', eventId: eventId });
      }
    }
  }
})();
