(function btNewsAlertsServiceClosure() {
  'use strict';

  var gDebug = false;
  // var gPrefix = 'btNewsAlertsService';

  angular.module('ecapp').factory('btNewsAlertsService', service);

  service.$inject = ['$q', 'btShareScopeService'];

  /**
   * This function is a implementation of btNewsAlertsService
   *
   * @param {angular.IQService} $q
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @return {ecapp.INewsAlertsService}
   */
  function service($q, btShareScopeService) {
    /**
     * @ngdoc service
     * @name btNewsAlertsService
     * @memberOf ecapp
     * @description
     */

    if (gDebug) console.log('Running btNewsAlertsService');

    /**
     * These are a suggested custom sources loaded from database.
     * @type {INewsSource[] | null}
     */
    var gDefaultGeneralNewsSources = null;

    /**
     * @type {ecapp.btNewsAlertsService}
     */
    return {
      initialize: initialize,
      list: getNewsCrawlers,
      findByName: getChannelByName,
      follow: startFollowing,
      unfollow: stopFollowing,
    };

    /**
     * This function initializes btNewsAlertsService.
     *
     * Note: Require error handler
     *
     * @public
     * @alias ecapp.btNewsAlertsService#initialize
     * @return {angular.IPromise<INewsSource[]>}
     */
    function initialize() {
      return getDefaultAccounts();
    }

    /**
     * This function loads default sources from database, prepare custom channels and save demo accounts.
     *
     * Note: This function uses in memory cache.
     *
     * @return {angular.IPromise<INewsSource[]>}
     */
    function getDefaultAccounts() {
      if (gDefaultGeneralNewsSources) {
        return $q.resolve(gDefaultGeneralNewsSources);
      } else {
        return btShareScopeService.getNewsSources().then(function (sources) {
          gDefaultGeneralNewsSources = sources;
          return gDefaultGeneralNewsSources;
        });
      }
    }

    /**
     * This function returns news sources.
     *
     * @public
     * @alias ecapp.btNewsAlertsService#list
     * @return {INewsSource[]}
     */
    function getNewsCrawlers() {
      return gDefaultGeneralNewsSources;
    }

    /**
     * This function returns sources by name (after @ sign).
     *
     * @public
     * @alias ecapp.btNewsAlertsService#findByName
     * @param {string} name - source name
     * @return {INewsSource | undefined}
     */
    function getChannelByName(name) {
      if (!gDefaultGeneralNewsSources) return initialize().then(
        res => gDefaultGeneralNewsSources.find(function (c) {
          return c.name === name;
        })
      )
      return gDefaultGeneralNewsSources.find(function (c) {
        return c.name === name;
      })
    }

    /**
     * This function starts following source.
     *
     * @public
     * @alias ecapp.btNewsAlertsService#follow
     * @param {INewsSource} source - source object
     * @return {angular.IPromise<boolean>}
     */
    function startFollowing(source) {
      void source;
      // TODO Add logic to check if user have access to this feature.
      return $q.resolve(true);
    }

    /**
     * This function stops following source. It should return true on success.
     *
     * @public
     * @alias ecapp.btNewsAlertsService#unfollow
     * @param {INewsSource} source - source object
     * @return {angular.IPromise<boolean>}
     */
    function stopFollowing(source) {
      void source;
      return $q.resolve(true);
    }
  }
})();
