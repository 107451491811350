/**
 * Created by Eyal on 5/27/2016.
 */

(function () {
  'use strict';

  angular
    .module('ecapp')
    /**
     * ???
     *
     * @ngdoc directive
     * @name btEventModalCard
     * @memberOf ecapp
     */
    .directive('btEventModalCard', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/event-modal/event-card/event-card.html'),
          controller: [
            'btStrengthService',
            'btDateService',
            '$scope',
            'btRowProcessorService',
            function (btStrengthService, btDateService, $scope, btRowProcessorService) {
              $scope.showFullMsg = window.localStorage.getItem('showFullMsg') === 'true';

              // this watch makes sure to watch when strength changes and that means there is an update
              // usually we have releaseStrength undefined for future release and {} or null for past release
              // so we add with watcher only if release already have strength
              // newValue is not defined
              if ($scope.rowModalData.releaseStrength != null) {
                $scope.$watchCollection('rowModalData.releaseStrength', function (newStrength, oldStrength) {
                  if (newStrength.value != oldStrength.value) {
                    btRowProcessorService.playStrengthSound(newStrength.strengthPercentage.status);
                  }
                });
              }

              var row_date = btDateService.getDateFromRow($scope.rowModalData);
              $scope.isRowOccurInFuture = function () {
                return btDateService.isInFuture(row_date);
              };
              if ($scope.rowModalData.releaseStrength != null)
                $scope.strength = btStrengthService.prepareStrength(
                  $scope.rowModalData.releaseStrength.value,
                  $scope.rowModalData.time
                );
            },
          ],
        };
      },
    ]);
})();
