/**
 * Created by Sergei Panpurin on 10/31/17.
 */

(function btSubscriptionPopupClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive display toggle.
     *
     * @ngdoc directive
     * @name btSubscriptionPopup
     * @memberOf ecapp
     */
    .directive('btSubscriptionPopup', btSubscriptionPopup)
    .controller('btSubscriptionPopupController', btSubscriptionPopupController);

  btSubscriptionPopup.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btSubscriptionPopup($templateCache) {
    return {
      restrict: 'E',
      template: $templateCache.get('directives/common/bt-subscription-popup.html'),
      scope: false,
      controller: 'btSubscriptionPopupController',
    };
  }

  btSubscriptionPopupController.$inject = ['$scope', '$rootScope', 'btToastrService'];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.IToastrService} btToastrService
   */
  function btSubscriptionPopupController($scope, $rootScope, btToastrService) {
    $scope.closeSubscriptionPopup = closeSubscriptionPopup;
    $scope.buySubscriptionWrapper = buySubscriptionWrapper;

    /**
     *
     */
    function closeSubscriptionPopup() {
      $scope.showSubscriptionPopup = false;
    }

    /**
     *
     * @param {*} plan
     * @param {*} buySubscription
     */
    function buySubscriptionWrapper(plan, buySubscription) {
      if (typeof buySubscription === 'function') {
        var promise = buySubscription(plan, '');
        if (promise && typeof promise.then === 'function') {
          promise
            .then(function (status) {
              if (status === 'success') {
                btToastrService.success('Congratulations. You have subscription!', 'Subscription', {
                  type: 'settings',
                });
                $scope.showSubscriptionPopup = false;

                $rootScope.scheduleAppRestart();
              }
            })
            .catch(function (reason) {
              btToastrService.error(reason.message, 'Subscription', { type: 'settings' });
            });
        }
      }
    }
  }
})();
