/**
 * Created by Sergey Panpurin on 7/24/19.
 */

(function btAdvertisingClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btAdvertising:';

  /**
   * This directive displays notification center button.
   *
   * @ngdoc directive
   * @name btAdvertising
   * @memberOf ecapp
   * @restrict E
   * @scope
   * @param {string} place - ad place
   * @param {string} adIdentifier - ad identifier
   */

  /**
   * This controller process notification center.
   *
   * @ngdoc controller
   * @name btAdvertisingController
   * @memberOf ecapp
   */

  /** Directive */
  angular
    .module('ecapp')
    .directive('btAdvertising', angularDirective)
    .controller('btAdvertisingController', angularController);

  angularDirective.$inject = ['$templateCache', 'btSettingsService'];

  /**
   * Implementation of Notification Center Directive
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {ecapp.ISettingsService} btSettingsService
   * @return {angular.IDirective}
   */
  function angularDirective($templateCache, btSettingsService) {
    return {
      restrict: 'E',
      scope: {
        place: '@',
        adIdentifier: '@',
      },
      template:
        btSettingsService.getDomain() === 'bigbrainbank'
          ? $templateCache.get('directives/advertising/bbb-advertising.html')
          : $templateCache.get('directives/advertising/bt-advertising.html'),
      controller: 'btAdvertisingController',
      link: angularLinker,
    };
  }

  angularController.$inject = [
    '$scope',
    '$rootScope',
    'btShareScopeService',
    'btAdvertisingService',
    'btLinkService',
    'btSettingsService',
  ];

  /**
   * Implementation of Notification Center Controller
   *
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IAdvertisingService} btAdvertisingService
   * @param {ecapp.ILinkService} btLinkService
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function angularController(
    $scope,
    $rootScope,
    btShareScopeService,
    btAdvertisingService,
    btLinkService,
    btSettingsService
  ) {
    if (gDebug) console.log(gPrefix, 'initializing...');

    $scope.ad = null;

    $scope.isBigBrainBank = btSettingsService.getDomain() === 'bigbrainbank';

    $scope.clickButton = clickButton;
    $scope.resetAdvertising = btAdvertisingService.reset;
    $scope.openProMemberTab = openProMemberTab;

    // The rest of the activation
    $scope.$on('$destroy', onDestroy);
    $rootScope.$on('advertising:answer', onAnswer);

    activate();

    /**
     * Controller constructor
     */
    function activate() {
      if (gDebug) console.log(gPrefix, 'initialized');

      btAdvertisingService.initialize().then(function () {
        $scope.ad = btAdvertisingService.selectInitialAd($scope.adIdentifier, $scope.place);
      });
    }

    /**
     * On destroy
     */
    function onDestroy() {
      if (gDebug) console.log(gPrefix, 'destroyed');
    }

    /**
     *
     * @param {*} ad
     * @param {*} button
     */
    function clickButton(ad, button) {
      btAdvertisingService.processButtonClick(ad, button);
    }

    /**
     *
     */
    function openProMemberTab() {
      btLinkService.openInternal('ecapp.app.pro-membership');
    }

    /**
     *
     * @param {*} event
     * @param {*} newAd
     * @param {*} oldAd
     */
    function onAnswer(event, newAd, oldAd) {
      if ($scope.ad.id === oldAd.id) {
        $scope.ad = newAd;
      }
    }
  }

  /**
   *
   * @param {*} scope
   * @param {*} element
   * @param {*} attrs
   */
  function angularLinker(scope, element, attrs) {
    void attrs;
    scope.$watch('ad', function (newVal, oldValue) {
      void oldValue;
      if (newVal === null) {
        if (gDebug) console.log(gPrefix, 'Hide ad', scope.place);
        element.hide();
      } else {
        if (gDebug) console.log(gPrefix, 'Show ad', scope.place);
        element.show();
      }
    });
  }
})();
