/**
 * Created by Sergey Panpurin on 08/29/2017.
 */

(function btMarketWakeupButtonClosure() {
  'use strict';

  angular
    .module('ecapp')
    .directive('btMarketWakeupButton', btMarketWakeupButton)
    .controller('btMarketWakeupButtonController', btMarketWakeupButtonController);

  btMarketWakeupButton.$inject = ['$templateCache'];

  /**
   * This directive display MarketWakeup button.
   *
   * @ngdoc directive
   * @name btMarketWakeupButton
   * @memberOf ecapp
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btMarketWakeupButton($templateCache) {
    return {
      restrict: 'E',
      scope: {
        marketData: '=instrument',
      },
      template: $templateCache.get('directives/instruments/bt-market-wakeup-button.html'),
      controller: 'btMarketWakeupButtonController',
    };
  }

  btMarketWakeupButtonController.$inject = ['$scope', '$rootScope', 'btMarketWidgetsService', 'btToastrService'];

  /**
   *
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.IMarketWidgetsService} btMarketWidgetsService
   * @param {ecapp.IToastrService} btToastrService
   */
  function btMarketWakeupButtonController($scope, $rootScope, btMarketWidgetsService, btToastrService) {
    var gIsProcessing = false;

    $scope.hasMarketWakeup = false;

    $scope.changeMarketWakeup = changeMarketWakeup;
    $scope.delete = deleteMarketWakeup;
    $scope.create = createMarketWakeup;

    setDefaultSettings();

    // initialize market wakeup
    btMarketWidgetsService
      .initMarketWakeup($scope.settings.instrument, $scope.settings.broker, $rootScope.currentUser.id)
      .then(function (initial) {
        if (initial !== null) {
          $scope.hasMarketWakeup = true;
          $scope.settings = initial;
        }
      });

    /**
     * Set default settings
     */
    function setDefaultSettings() {
      $scope.settings = {
        instrument: $scope.marketData.instrument.brokerSymbol,
        displayName: $scope.marketData.instrument.displayName,
        broker: $scope.marketData.instrument.brokerName,
      };
    }

    /**
     * Prepare market wakeup settings
     * @param {btMarketWakeupSettings} settings
     * @return {btMarketWakeupObject}
     */
    function prepareSettings(settings) {
      return {
        id: settings.id,
        instrument: settings.instrument,
        displayName: settings.displayName,
        broker: settings.broker,
        userId: settings.userId,
      };
    }

    /**
     * Callback connected to bt-star-button to react on select event. Create or delete market wakeup.
     * Status connected to hasMarketWakeup variable.
     * @param {String} data - instrument name
     * @param {Boolean} checked - market sense status
     */
    function changeMarketWakeup(data, checked) {
      void data;
      void checked;
      if ($scope.hasMarketWakeup === false) {
        createMarketWakeup($scope.settings);
      } else {
        deleteMarketWakeup($scope.settings.id);
      }
    }

    /**
     * Deletes market wakeup.
     *
     * @param {String} id - market sense id
     */
    function deleteMarketWakeup(id) {
      if (id !== undefined && !gIsProcessing) {
        gIsProcessing = true;
        btMarketWidgetsService
          .deleteMarketWakeup(id)
          .then(function () {
            $scope.hasMarketWakeup = false;
            setDefaultSettings();
            btToastrService.info('turned OFF.', 'MarketWakeup for ' + $scope.marketData.instrument.displayName, {
              type: 'settings',
            });
          })
          .catch(function (error) {
            if (error && error.message)
              btToastrService.error(error.message, $scope.marketData.instrument.displayName, { type: 'settings' });
          })
          .finally(function () {
            gIsProcessing = false;
          });
      }
    }

    /**
     * Creates market wakeup.
     *
     * @param {btMarketWakeupSettings} settings - market sense settings
     */
    function createMarketWakeup(settings) {
      settings.userId = $rootScope.currentUser.id;

      if (!gIsProcessing) {
        gIsProcessing = true;
        btMarketWidgetsService
          .hasMarketWakeupCapacity(settings)
          .then(function () {
            return btMarketWidgetsService.createMarketWakeup(prepareSettings(settings));
          })
          .then(function (data) {
            settings.id = data.id;
            $scope.hasMarketWakeup = true;
            btToastrService.info('turned ON.', 'MarketWakeup for ' + $scope.marketData.instrument.displayName, {
              type: 'settings',
            });
          })
          .catch(function (reason) {
            btToastrService.error(reason.message, $scope.marketData.instrument.displayName, { type: 'settings' });
          })
          .finally(function () {
            gIsProcessing = false;
          });
      }
    }
  }
})();
