// @ts-check
(function btSharedDataServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btSharedDataService:';

  /**
   * @memberOf btUtils
   * @ngdoc service
   * @name btSharedData
   * @description
   *  This factory share information about selected row. You need to add callback which will be called after selection
   *  of row in ecapp.
   */

  angular.module('btUtils').factory('btSharedData', btSharedDataService);

  btSharedDataService.$inject = ['$ionicTabsDelegate', '$q', '$timeout', '$ionicLoading', '$rootScope'];

  /**
   *
   * @param {ionic.ITabsDelegateService} $ionicTabsDelegate - ionic tab delegate service
   * @param {angular.IQService} $q - promise interface
   * @param {angular.ITimeoutService} $timeout - ?
   * @param {ionic.ILoadingService} $ionicLoading - ionic loading service
   * @param {ecapp.ICustomRootScope} $rootScope - ?
   * @return {ecapp.ISharedData}
   */
  function btSharedDataService($ionicTabsDelegate, $q, $timeout, $ionicLoading, $rootScope) {
    if (gDebug) console.log(gPrefix, 'Running...');

    /**
     * @callback btSharedCallback
     * @param {*} data - callback data
     * @param {Object} options - callback options
     */

    /** @type {number} */
    var gBackTesterVersion = 2;

    /** @type {number} */
    var backTesterTabIndex1 = 4;

    /** @type {number} */
    var backTesterTabIndex2 = 1;

    /** @type {number} */
    var tradingPlatformTabIndex = 2;

    /** @type {Record<string, btSharedCallback[]>} */
    var callbacks = {};

    /** @type {Record<string, angular.IDeferred|null>} */
    var gPendingTabs = {};

    /** @type {Record<string, boolean>} */
    var gCache = {
      'backtesting:acquire': false,
    };

    // Get new articles when tab changes
    $rootScope.$on('tabChanged', onTabChanged);

    return {
      shareData: shareData,
      addCallback: addCallback,
      deleteCallback: deleteCallback,
      runBackTester: runBackTester,
      acquireBackTester: acquireBackTester,
      releaseBackTester: releaseBackTester,
      isBackTesterAcquired: isBackTesterAcquired,
      openTradingPlatform: openTradingPlatform,
      openDashboardTabByViewName: openDashboardTabByViewName,
    };

    /**
     * This function reacts on tab changing.
     *
     * @param {*} event -
     * @param {*} data -
     */
    function onTabChanged(event, data) {
      void event;
      if (gPendingTabs[data.view]) {
        if (gDebug) console.log(gPrefix, 'tab ' + data.view + ' was opened');
        gPendingTabs[data.view].resolve();
        gPendingTabs[data.view] = null;
      }
    }

    /**
     * This function runs all registered callbacks.
     *
     * @alias btUtils.btSharedDataService#shareData
     * @param {string} name - name of data
     * @param {*} data - data
     * @param {Object} opts - optional settings
     */
    function shareData(name, data, opts) {
      if (callbacks[name] !== undefined) {
        // notify if there are any listeners
        for (var i = 0; i < callbacks[name].length; i++)
          if (typeof callbacks[name][i] === 'function') {
            callbacks[name][i](data, opts);
          }
      } else {
        console.log('Unexpected: Shared data is not defined');
      }
    }

    /**
     * This function registers a callback.
     *
     * @alias btUtils.btSharedDataService#addCallback
     * @param {string} name - name of data
     * @param {btSharedCallback} callback - callback to call
     */
    function addCallback(name, callback) {
      if (callbacks[name] === undefined) {
        callbacks[name] = [];
      }
      callbacks[name].push(callback);
    }

    /**
     * This function deletes a callback.
     *
     * @alias btUtils.btSharedDataService#deleteCallback
     * @param {string} name - name of data
     * @param {btSharedCallback} callback - callback to call
     */
    function deleteCallback(name, callback) {
      if (callbacks[name] !== undefined) {
        for (var i = 0; i < callbacks[name].length; i++)
          if (callbacks[name][i] === callback) {
            callbacks[name].splice(i, 1);
            i--;
          }
      }
    }

    /**
     * This function opens BackTester tab (v1).
     *
     * @return {angular.IPromise<void>}
     */
    function openBackTester1() {
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Opening BackTester...</p>' });
      return openDashboardTabByIndex(backTesterTabIndex1);
    }

    /**
     * This function opens BackTester tab (v2).
     * @return {angular.IPromise<void>}
     */
    function openBackTester2() {
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Opening BackTester...</p>' });
      return openDashboardTabByIndex(backTesterTabIndex2);
    }

    /**
     * This function opens Trading Platform tab.
     *
     * @alias btUtils.btSharedDataService#openTradingPlatform
     * @return {angular.IPromise<void>}
     */
    function openTradingPlatform() {
      return openDashboardTabByIndex(tradingPlatformTabIndex);
    }

    /**
     * This function opens dashboard tab by name.
     *
     * @alias btUtils.btSharedDataService#openDashboardTabByViewName
     * @param {string} viewName - name of view connected to tab
     * @return {angular.IPromise<void>}
     */
    function openDashboardTabByViewName(viewName) {
      var tabsDelegate = getDashboardTabsDelegate();

      if (tabsDelegate) {
        var result = tabsDelegate.tabs.filter(filterTabsByViewName);

        if (result.length === 1) {
          return openDashboardTabByIndex(tabsDelegate.tabs.indexOf(result[0]));
        } else {
          return $q.resolve();
        }
      } else {
        return $q.resolve();
      }

      /**
       * This function filters tabs by name of view.
       *
       * @param {{navViewName: string}} tab
       * @return {boolean}
       */
      function filterTabsByViewName(tab) {
        return tab.navViewName === viewName;
      }
    }

    /**
     * This function opens dashboard tab by index.
     *
     * @param {number} index - tab index
     * @return {angular.IPromise<void>}
     */
    function openDashboardTabByIndex(index) {
      /** @type {{select, selectedIndex, tabs}} */
      var tabsDelegate = getDashboardTabsDelegate();

      if (tabsDelegate && tabsDelegate.selectedIndex() !== index) {
        var name = tabsDelegate.tabs[index].navViewName;

        if (gDebug) console.log(gPrefix, 'try to open ' + name + ' tab');

        if (isPendingTab(name)) {
          return getTabPromise(name);
        } else {
          var deferred = $q.defer();
          tabsDelegate.select(index);

          addPendingTab(name, deferred);

          $timeout(
            function () {
              if (isPendingTab(name)) {
                if (gDebug) console.log(gPrefix, 'timeout for opening ' + name + ' tab');
                deferred.resolve();
              }
            },
            2000,
            false
          );

          return deferred.promise;
        }
      } else {
        return $q.resolve();
      }
    }

    /**
     * This function adds pending tab.
     *
     * @private
     * @param {string} name - tab name
     * @param {angular.IDeferred} deferred
     */
    function addPendingTab(name, deferred) {
      gPendingTabs[name] = deferred;
    }

    /**
     * This function checks if tab is pending.
     *
     * @private
     * @param {string} name - tab name
     * @return {boolean}
     */
    function isPendingTab(name) {
      return !!gPendingTabs[name];
    }

    /**
     * This function returns promise for pending tab.
     *
     * @private
     * @param {string} name - tab name
     * @return {angular.IPromise}
     */
    function getTabPromise(name) {
      return gPendingTabs[name].promise;
    }

    /**
     * This function gets dashboard tab delegate.
     *
     * @return {Object}
     */
    function getDashboardTabsDelegate() {
      // var tabs = $ionicTabsDelegate.$getByHandle('dashboard-tabs');

      // noinspection JSUnresolvedVariable
      return $ionicTabsDelegate._instances.filter(function (instance) {
        // noinspection JSUnresolvedVariable
        return instance.$$delegateHandle === 'dashboard-tabs';
      })[0];
    }

    /**
     * This function runs backtesting.
     *
     * @alias btUtils.btSharedDataService#runBackTester
     * @param {string} type - release or moment
     * @param {*} data - some data
     * @param {Object} params - some parameters
     * @return {*}
     */
    function runBackTester(type, data, params) {
      if (gBackTesterVersion === 1) {
        return runBackTester1(type, data, params);
      } else {
        return runBackTester2(type, data, params);
      }
    }

    /**
     * This function runs backtesting (version 1).
     *
     * @param {string} type - type of backtesting (release or moment)
     * @param {*} data - some data
     * @param {Object} params - some params
     * @return {*}
     */
    function runBackTester1(type, data, params) {
      return openBackTester1().then(function () {
        if (type === 'release') return shareData('rowInfo', data, params);
        if (type === 'moment') return shareData('moments', data, params);
      });
    }

    /**
     * This function runs backtesting (version 2).
     *
     * @param {string} type - type of backtesting (release or moment)
     * @param {*} data - some data
     * @param {Object} params - some params
     * @return {*}
     */
    function runBackTester2(type, data, params) {
      return openBackTester2().then(function () {
        if (type === 'release') return shareData('backtesting:release', data, params);
        if (type === 'moment') return shareData('backtesting:moment', data, params);
      });
    }

    /**
     * This function acquires BackTester.
     *
     * @alias btUtils.btSharedDataService#acquireBackTester
     */
    function acquireBackTester() {
      gCache['backtesting:acquire'] = true;
      shareData('backtesting:acquire', null, null);
    }

    /**
     * This function releases BackTester.
     *
     * @alias btUtils.btSharedDataService#releaseBackTester
     */
    function releaseBackTester() {
      gCache['backtesting:acquire'] = false;
    }

    /**
     * This function checks if BackTester is acquired.
     *
     * @alias btUtils.btSharedDataService#isBackTesterAcquired
     * @return {boolean}
     */
    function isBackTesterAcquired() {
      return gCache['backtesting:acquire'];
    }
  }
})();
