/**
 * Created by yatree on 13/10/16.
 */

// @ts-check
(function btPushNotificationServiceClosure() {
  'use strict';

  var gDebug = false;

  /**
   * @typedef {object} IGlobalBannerConfig
   * @property {string} id - banner identifier
   * @property {string} style - css class
   * @property {string} text - content as a html
   * @property {function} [onClose] - close button handler
   * @property {function} [onAnswer] - answer handler
   * @property {IBannerAnswer[]} [answers] - list of answers
   */

  /**
   * @typedef {object} IBannerAnswer
   * @property {string} id - answer identifier
   * @property {string} text - answer html
   */

  angular
    .module('ecapp')
    /**
     * @ngdoc service
     * @name btPushNotificationService
     * @memberOf ecapp
     * @description
     *  This service handle desktop push notifications. Now it support Chrome, probably Firefox. To receive Push
     *  notification in Chrome you need:
     *    - register service worker
     *    - subscribe to push service (FCM/GCM sender id specified in manifest.json file)
     *    - receive token and register it in your notification sending system
     *    - due to Chrome doesn't support data field, additionally you need to fetch data about notification
     *
     *  In your system we use loopback endpoint to receive information about notification.
     *  We use Ionic Cloud Push like notification sending system.
     *
     *  (btShareScopeService) Save and remove desktop notification tokens (from user object)
     *  (btAuthService) Save and remove desktop notification tokens (from database).
     *
     * {@link https://developer.mozilla.org/en-US/docs/Web/API/Push_API/Using_the_Push_API}
     */
    .factory('btPushNotificationService', btPushNotificationService);

  btPushNotificationService.$inject = [
    '$rootScope',
    '$ionicPlatform',
    'btShareScopeService',
    '$q',
    '$state',
    '$timeout',
    'btEventEmitterService',
    '$window',
    'btSettingsService',
    'btToastrService',
  ];

  /**
   *
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ionic.IPlatformService} $ionicPlatform
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {angular.IQService} $q
   * @param {angular.ui.IStateService} $state
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.IEventEmitterService} btEventEmitterService
   * @param {angular.IWindowService} $window
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IToastrService} btToastrService
   * @return {ecapp.IPushNotificationService}
   */
  function btPushNotificationService(
    $rootScope,
    $ionicPlatform,
    btShareScopeService,
    $q,
    $state,
    $timeout,
    btEventEmitterService,
    $window,
    btSettingsService,
    btToastrService
  ) {
    if (gDebug) console.log('Running btPushNotificationService');

    if (!window.OneSignal) window.OneSignal = [];

    // Whether push notifications enabled
    var gIsEnabled = true;
    var gIsDisabled = !gIsEnabled;

    // This promise helps to resolve simultaneous calls of getWebSubscriptionState.
    var gSubscriptionStatePromise;

    // This is and access to local settings.
    var localSettings = btSettingsService.getLocalSettings();

    // These variables defines intervals between reminders.
    var LET_ME_FORGET = 3 * 2419200000; /* 3 * 28 days - 12 weeks */
    var LATER = 3 * 604800000; /* 3 * 7 days - 3 week */
    var SOON = 3 * 172800000; /* 3 * 2 days */

    if (gDebug) {
      LET_ME_FORGET = 480000; /* 480 seconds */
      LATER = 240000; /* 240 seconds */
      SOON = 60000; /* 60 seconds */
    }

    // Answers
    /** @type {IBannerAnswer} */
    var ANSWER_HELP = { id: 'help', text: 'How to enable notifications?' };
    /** @type {IBannerAnswer}*/
    var ANSWER_ENABLE = { id: 'enable', text: 'Enable notifications' };
    /** @type {IBannerAnswer} */
    var ANSWER_ENABLE2 = { id: 'enable2', text: ' enable desktop notifications.' };
    /** @type {IBannerAnswer} */
    var ANSWER_LATER = { id: 'later', text: 'Remind me later' };
    /** @type {IBannerAnswer} */
    var ANSWER_NO = { id: 'no', text: 'No, thanks' };

    // Text
    var DENIED_TEXT_WEB =
      "Notifications have been disabled in your browser. You'll need to open your browser preferences to change that.";
    var DENIED_TEXT_APP =
      "Notifications have been disabled on this device. You'll need to open system settings to change that.";
    var DEFAULT1_TEXT_WEB =
      "We strongly recommend enabling push notifications if you'll be using " +
      btSettingsService.getAppName() +
      ' on this computer.';
    var DEFAULT1_TEXT_APP =
      "We strongly recommend enabling push notifications if you'll be using " +
      btSettingsService.getAppName() +
      ' on this device.';
    var DEFAULT2_TEXT_WEB = btSettingsService.getAppName() + ' needs your permission to ';
    var DEFAULT2_TEXT_APP = btSettingsService.getAppName() + ' needs your permission to ';

    // Banners
    /** @type {IGlobalBannerConfig} */
    var BANNER_ENABLED = {
      id: btSettingsService.Banner.Notification,
      style: 'flex success closeable',
      text: 'Push notifications are enabled',
      onClose: function () {
        return $q.resolve(true);
      },
    };

    /** @type {IGlobalBannerConfig} */
    var BANNER_DELAYED = {
      id: btSettingsService.Banner.Notification,
      style: 'flex info closeable',
      text: 'Wait some time to remind user about push notifications (' + getReminder() + ')',
      onClose: function () {
        return $q.resolve(true);
      },
    };

    /** @type {IGlobalBannerConfig} */
    var BANNER_DENIED = {
      id: btSettingsService.Banner.Notification,
      style: 'flex warning',
      text: window.isApp ? DENIED_TEXT_APP : DENIED_TEXT_WEB,
      onAnswer: handleBannerAnswer,
      answers: [ANSWER_HELP, ANSWER_LATER, ANSWER_NO],
    };

    /** @type {IGlobalBannerConfig} */
    var BANNER_DEFAULT1 = {
      id: btSettingsService.Banner.Notification,
      style: 'flex info',
      text: window.isApp ? DEFAULT1_TEXT_APP : DEFAULT1_TEXT_WEB,
      onAnswer: handleBannerAnswer,
      answers: [ANSWER_ENABLE, ANSWER_LATER, ANSWER_NO],
    };

    /** @type {IGlobalBannerConfig} */
    var BANNER_DEFAULT2 = {
      id: btSettingsService.Banner.Notification,
      style: 'inline info closeable',
      text: window.isApp ? DEFAULT2_TEXT_WEB : DEFAULT2_TEXT_APP,
      onClose: function () {
        setReminder(LATER);
        return $q.resolve(true);
      },
      onAnswer: handleBannerAnswer,
      answers: [ANSWER_ENABLE2],
    };

    /** @namespace navigator.serviceWorker */

    // Check status of service worker. Service worker is a part of OneSignal SDK.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.ready.then(function (registration) {
        if (gDebug) console.log('A service worker is active:', registration.active);
      });
    }

    // Register login listener to resubscribe user on this device.
    btEventEmitterService.addListener('login:success', function onLoginSuccess() {
      try {
        // if (isAllowed()) {
        if (gDebug) console.log('TEST: Resubscribing...');
        getSubscriptionState()
          .then(function (value) {
            if (gDebug) console.log('TEST:', JSON.stringify(value));
            if (value.enabled) {
              if (gDebug) console.log('TEST: Saving notification token after login');
              saveToken();
            } else if (value.permission === 'granted') {
              if (gDebug) console.log('TEST: Resubscribe user after login');
              btToastrService.info('Resubscribing to push notifications...');
              allow();
            } else {
              if (gDebug) console.log('TEST: Do nothing');
            }
          })
          .catch(function (reason) {
            console.error(reason);
          });
        // }
      } catch (e) {
        console.error(e);
      }
    });

    return {
      init: init,

      tryToLogout: tryToLogout,

      allow: allow,
      delay: delay,

      getBannerConfig: getBannerConfig,
      getNotificationStatus: getNotificationStatus,

      getUserId: getUserId,
      getSubscriptionState: getSubscriptionState,
      subscribe: subscribe,
      unsubscribe: unsubscribe,
    };

    /**
     *
     * @param {IBannerAnswer} answer - user answer
     * @return {angular.IPromise<*>}
     */
    function handleBannerAnswer(answer) {
      switch (answer.id) {
        case ANSWER_HELP.id:
          if (window.isApp) {
            openAppSettings();
          } else if (window.isMobileWeb) {
            if (gDebug) console.log('Skip push notification on mobile web.');
          } else {
            window.open('https://support.google.com/chrome/answer/3220216', '_blank');
          }
          setReminder(SOON);
          return $q.resolve(true);
        case ANSWER_ENABLE.id:
        case ANSWER_ENABLE2.id:
          allow();
          return $q.resolve(true);
        case ANSWER_LATER.id:
          setReminder(LATER);
          return $q.resolve(true);
        case ANSWER_NO.id:
          disallow();
          setReminder(LET_ME_FORGET);
          return $q.resolve(true);
        default:
          alert('Unknown answer');
          return $q.resolve(true);
      }
    }

    /**
     * This function opens native application settings.
     */
    function openAppSettings() {
      if (window.cordova && window.cordova.plugins.settings) {
        if (gDebug) console.log('TEST: OpenNativeSettings plugin is defined');
        window.cordova.plugins.settings.open(
          'application_details',
          function () {
            if (gDebug) console.log('TEST: native settings was opened');
          },
          function () {
            if (gDebug) console.log('TEST: failed to open native settings');
          }
        );
      } else {
        alert('OpenNativeSettings plugin is not defined!');
      }
    }

    // /**
    //  * This function checks if users allowed push notifications on this device.
    //  * It is a part of implementation of double permission concept.
    //  */
    // function isAllowed() {
    //   return localSettings.get('notifications.allowed') !== false;
    // }

    /**
     * This function allows push notifications on this device.
     * It is a part of implementation of double permission concept.
     */
    function allow() {
      if (gIsDisabled) return;

      localSettings.set('notifications.allowed', true);
      setReminder(SOON);
      askPermission();
    }

    /**
     * This function delay user answer about push notifications on this device.
     * It is a part of implementation of double permission concept.
     */
    function delay() {
      if (gIsDisabled) return;

      localSettings.set('notifications.allowed', false);
      setReminder(SOON);
    }

    /**
     * This function disallows push notifications on this device.
     * It is a part of implementation of double permission concept.
     */
    function disallow() {
      localSettings.set('notifications.allowed', false);
    }

    /**
     * This function disallows push notifications on this device.
     *
     * @param {number} milliseconds - number of milliseconds till next reminder
     */
    function setReminder(milliseconds) {
      btSettingsService.setReminder('notifications.reminder', milliseconds);
    }

    // /**
    //  * This function disallows push notifications on this device.
    //  */
    // function resetReminder() {
    //   btSettingsService.removeReminder('notifications.reminder');
    // }

    /**
     * This function disallows push notifications on this device.
     *
     * @return {Date|undefined}
     */
    function getReminder() {
      return btSettingsService.getReminder('notifications.reminder');
    }

    /**
     * This function disallows push notifications on this device.
     * It is a part of implementation of double permission concept.
     *
     * @param {btSubscriptionState} state
     * @return {?IGlobalBannerConfig}
     */
    function getBannerConfig(state) {
      if (gIsDisabled) return null;
      if (!state) return null;

      var reminder = getReminder();
      // if (!isAllowed() && (reminder === undefined || reminder <= new Date())) {
      if (state.permission === 'granted') {
        // notifications are allowed
        return gDebug ? BANNER_ENABLED : null;
      } else if (reminder === undefined || reminder <= new Date()) {
        if (state.permission === 'denied') {
          return BANNER_DENIED;
        } else if (state.permission === 'default') {
          return Math.random() > 0.5 ? BANNER_DEFAULT1 : BANNER_DEFAULT2;
        } else {
          // it should not happened
          return null;
        }
      } else {
        // waiting for the next reminder
        return gDebug ? BANNER_DELAYED : null;
      }
    }

    /**
     * This function disallows push notifications on this device.
     * It is a part of implementation of double permission concept.
     *
     * @param {btUser} user - user account
     * @param {btSubscriptionState} state - notification state
     * @return {string}
     */
    function getNotificationStatus(user, state) {
      if (gIsDisabled) return 'Push notifications are disabled on this domain';

      if (!state.supported) {
        return 'Push notifications are not supported on this device.';
      } else if (!state.enabled) {
        return 'Push notifications are disabled on this device.';
      } else if (parseUserId(user) !== state.user) {
        return 'You are not subscribed for push notification on this device.';
      } else {
        return 'You are subscribed for push notification on this device.';
      }
    }

    /**
     *
     * @param {btUser} user - user account
     * @return {string}
     */
    function parseUserId(user) {
      var id;
      if (window.isApp) {
        if (user.pushNotificationMobile && user.pushNotificationMobile[0]) {
          id = user.pushNotificationMobile[0];
          return id[8] === '|' ? id.slice(9) : id;
        } else {
          return 'N/A';
        }
      } else if (window.isMobileWeb) {
        return 'N/A';
      } else {
        if (user.pushNotificationWeb && user.pushNotificationWeb[0]) {
          id = user.pushNotificationWeb[0];
          return id[8] === '|' ? id.slice(9) : id;
        } else {
          return 'N/A';
        }
      }
    }

    /**
     * Save OneSignal player id to database
     */
    function saveToken() {
      // resetReminder();

      if (window.isApp) {
        $ionicPlatform.ready(function () {
          if (window.plugins && window.plugins.OneSignal) {
            window.plugins.OneSignal.sendTag('email', $rootScope.currentUser.email);
            window.plugins.OneSignal.setEmail($rootScope.currentUser.email);
            window.plugins.OneSignal.getDeviceState(function (status) {
              btShareScopeService.addNotificationToken(status.userId);
              btShareScopeService.updateProfileSettingsFromFieldsList(['pushNotificationMobile']);
            });
          }
        });
      } else if (window.isMobileWeb) {
        if (gDebug) console.log('Skip push notification on mobile web.');
      } else {
        window.OneSignal.push(function () {
          window.OneSignal.getUserId()
            .then(function (userId) {
              if (userId !== null) {
                if (gDebug) console.log('TEST: Saving token :', userId);
                if (parseUserId(btShareScopeService.accountInfo) !== userId) {
                  btToastrService.info('You have been subscribed to push notifications.');
                  btShareScopeService.addNotificationDesktopToken(userId);

                  // save token to user data and register user in ionic cloud push
                  btShareScopeService.updateProfileSettingsFromFieldsList(['pushNotificationWeb']);

                  if ($rootScope.currentUser && $rootScope.currentUser.email) {
                    window.OneSignal.sendTag('email', $rootScope.currentUser.email);
                  }
                } else {
                  if (gDebug) console.log('TEST: User is already subscribed');
                }
              } else {
                if (gDebug) console.log('TEST: No User ID');
              }
            })
            .catch(function (reason) {
              console.error(reason);
            });
        });
      }
    }

    /**
     *
     * @return {angular.IPromise<?string>|*}
     */
    function tryToLogout() {
      if (gIsDisabled) return $q.resolve();

      if (gDebug) console.log('TEST: Try to remove notification token');
      if (!btShareScopeService.isInitialized()) {
        if (gDebug) console.log('TEST: User is not defined');
        return $q.resolve();
      } else {
        return getUserId()
          .then(function (userId) {
            if (gDebug) console.log('TEST: User ID ' + userId);
            if (parseUserId(btShareScopeService.accountInfo) === userId) {
              if (gDebug) console.log('TEST: User is subscribed');
              if (window.isApp) {
                if (gDebug) console.log('TEST: Remove notification token (mobile)');
                btShareScopeService.removeNotificationToken();
                return btShareScopeService.updateProfileSettingsFromFieldsList(['pushNotificationMobile']);
              } else if (window.isMobileWeb) {
                if (gDebug) console.log('Skip push notification on mobile web.');
              } else {
                if (gDebug) console.log('TEST: Remove notification token (desktop)');
                btShareScopeService.removeNotificationDesktopToken();
                return btShareScopeService.updateProfileSettingsFromFieldsList(['pushNotificationDesktop']);
              }
            } else {
              if (gDebug) console.log('TEST: User is not subscribed');
              return $q.resolve();
            }
          })
          .then(function () {
            if (gDebug) console.log('TEST: Notification token was removed');
          })
          .catch(function (reason) {
            // FIXME "Push service is not initialized."
            console.error(reason);
            return $q.resolve();
          });
      }
    }

    /**
     * Initialize OneSignal push notification
     */
    function init() {
      if (gIsDisabled) return;

      if (gDebug) console.log('TEST: Initialize push notifications');

      if (window.isApp) {
        // Begin OneSignal Push
        if (window.plugins && window.plugins.OneSignal) {
          // Enable to debug issues.
          // window.plugins.OneSignal.setLogLevel({logLevel: 4, visualLevel: 4});

          // var notificationOpenedCallback = ;

          // // Set your iOS Settings
          // var iosSettings = {};
          // iosSettings['kOSSettingsKeyAutoPrompt'] = true;
          // iosSettings['kOSSettingsKeyInAppLaunchURL'] = false;

          // window.plugins.OneSignal.setLogLevel({logLevel: 6, visualLevel: 0});

          window.plugins.OneSignal.setAppId(window.btSettings.BT_ONE_SIGNAL_ID);
          // .startInit()
          // .iOSSettings(iosSettings)
          // .inFocusDisplaying(window.plugins.OneSignal.OSInFocusDisplayOption.None)
          // .handleNotificationOpened(notificationOpenedCallback)
          // .endInit();

          // window.plugins.OneSignal.setLogLevel(6, 0);

          window.plugins.OneSignal.setNotificationOpenedHandler(function (event) {
            if (gDebug) console.log('TEST: OneSignal: notification opened callback data - ' + JSON.stringify(event));
            if (event.complete) {
              event.complete(null);
            }

            var data = {};
            try {
              data = event.notification.additionalData;
            } catch (e) {
              if (gDebug) console.log("TEST: OneSignal: Can't get additional data");
            }

            var params = data.deeplinkParams || {};
            try {
              if (typeof params === 'string') {
                params = JSON.parse(params);
              }
            } catch (e) {
              if (gDebug) console.log("TEST: OneSignal: Can't get params");
            }

            if (data.deeplinkState) {
              if (gDebug)
                console.log(
                  'TEST: OneSignal: Deep link detected. State = ' + data.deeplinkState + ', parameters = ',
                  params
                );
              $timeout(
                function () {
                  if (gDebug) console.log('TEST: OneSignal: try to open ' + data.deeplinkState + '(' + params + ')');
                  $state.go(data.deeplinkState, params);
                },
                0,
                false
              );
            }
          });

          // iOS - Prompts the user for notification permissions.
          //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
          window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
            if (gDebug) console.log('TEST: User accepted notifications: ' + accepted);
          });

          // Call syncHashedEmail anywhere in your app if you have the user's email.
          // This improves the effectiveness of OneSignal's "best-time" notification scheduling feature.
          // window.plugins.OneSignal.syncHashedEmail("s.panpurin+tester@gmail.com");
        }
        // End OneSignal Push
      } else if (window.isMobileWeb) {
        if (gDebug) console.log('Skip push notification on mobile web.');
      } else {
        var config = {
          appId: window.btSettings.BT_ONE_SIGNAL_ID,
          subdomainName: 'localhost:8100',
          allowLocalhostAsSecureOrigin: true,
          autoRegister: false,
          notifyButton: {
            enable: false,
          },
        };

        if (gDebug) console.log('TEST: configuration', window.OneSignal, config);

        $timeout(function () {
          if (!window.OneSignal.initialized && window.isDevMode) {
            btToastrService.error('Push Notification Service was not initialized in 60 seconds!', 'System');
          }
        }, 60000);

        window.OneSignal.push(function () {
          if (gDebug) console.log('OneSignal: Initializing...');
          if (gDebug) console.log('TEST: Initialize OneSignal Web SDK', window.OneSignal, config);
          window.OneSignal.init(config)
            .then(function () {
              if (gDebug) console.log('OneSignal: Initialized.');
            })
            .catch(function (reason) {
              console.error(reason);
              btToastrService.error(reason.message, 'System');
            });
        });

        window.OneSignal.push(function () {
          if (gDebug) console.log('OneSignal: Adding listeners...');

          // Occurs when the user's subscription changes to a new value.
          window.OneSignal.on('subscriptionChange', function (isSubscribed) {
            if (gDebug) console.log("TEST: The user's subscription state is now:", isSubscribed);
            if (isSubscribed) saveToken();
          });

          window.OneSignal.on('permissionPromptDisplay', function () {
            if (gDebug) console.log('TEST: The prompt displayed');
          });

          // window.OneSignal.on('notificationPermissionChange', function (permissionChange) {
          //   var currentPermission = permissionChange.to;
          //   console.log('TEST: New permission state:', currentPermission);
          // });

          if (gDebug) console.log('OneSignal: Listeners was added.');
        });
      }
    }

    /**
     * If user didn't set notification permissions ask him again
     */
    function askPermission() {
      if (gDebug) console.log('TEST: askPermission');
      if (!window.isApp) {
        window.OneSignal.push(function () {
          window.OneSignal.showNativePrompt();
        });
      } else {
        if (gDebug) console.log('TEST: App version of askPermission is not implemented');
      }
    }

    /**
     *
     * @return {angular.IPromise<?string>}
     */
    function getUserId() {
      if (gIsDisabled) return $q.resolve('NA');

      if (gDebug) console.log('TEST: getUserId');
      if (window.isApp) {
        return getAppUserId();
      } else if (window.isMobileWeb) {
        return $q.reject(new Error('Skip push notification on mobile web.'));
      } else {
        return getWebUserId();
      }
    }

    /**
     *
     * @return {angular.IPromise<string>}
     */
    function getAppUserId() {
      var deferred = $q.defer();

      if (hasCordovaPlugin()) {
        window.plugins.OneSignal.getDeviceState(function (deviceState) {
          deferred.resolve(deviceState.userId);
        });
      } else {
        deferred.reject(new Error('One Signal plugin is not found.'));
      }

      return deferred.promise;
    }

    /**
     *
     * @return {angular.IPromise<string>}
     */
    function getWebUserId() {
      var deferred = $q.defer();

      var isTriggered = false;

      window.OneSignal.push(function () {
        window.OneSignal.getUserId()
          .then(function (value) {
            isTriggered = true;
            deferred.resolve(value);
          })
          .catch(function (reason) {
            isTriggered = true;
            deferred.reject(reason);
          });
      });

      setTimeout(function () {
        if (isTriggered === false) deferred.reject('Push service is not initialized.');
      }, 2000);

      return deferred.promise;
    }

    /**
     * @typedef {object} btSubscriptionState
     * @property {boolean} supported -
     * @property {boolean} enabled -
     * @property {boolean} subscribed -
     * @property {string} permission - 'granted', 'default', 'denied'
     * @property {string} user -
     * @property {string} token -
     */

    /**
     *
     * @return {angular.IPromise<btSubscriptionState>}
     */
    function getSubscriptionState() {
      if (gIsDisabled) {
        return $q.resolve({
          supported: false,
          enabled: false,
          subscribed: false,
          permission: 'denied',
          user: 'NA',
          token: 'NA',
        });
      }

      if (gDebug) console.log('TEST: getSubscriptionState');
      if (window.isApp) {
        return getAppSubscriptionState();
      } else if (window.isMobileWeb) {
        return $q.reject(new Error('Skip push notification on mobile web.'));
      } else {
        return getWebSubscriptionState();
      }
    }

    /**
     *
     * @return {angular.IPromise<*>}
     */
    function getAppSubscriptionState() {
      var deferred = $q.defer();

      if (hasCordovaPlugin()) {
        window.plugins.OneSignal.getDeviceState(function (status) {
          if (gDebug) console.log('TEST: getDeviceState', JSON.stringify(status));

          var result = {
            supported: true,
            enabled: !status.isPushDisabled,
            subscribed: status.isSubscribed,
            // permission: getAppPermission(status.notificationPermissionStatus),
            permission: status.hasNotificationPermission ? 'granted' : 'denied',
            user: status.userId,
            token: status.pushToken,
          };
          if (gDebug) console.log('TEST: Result', JSON.stringify(result));
          deferred.resolve(result);
        });
      } else {
        deferred.reject(new Error('One Signal plugin is not found.'));
      }

      return deferred.promise;
    }

    /**
     * ???
     *
     * @param {*} permissionStatus
     * @return {*}
     */
    function getAppPermission(permissionStatus) {
      /*
       * status (iOS only) - Integer
       *   0 = Not Determined
       *   1 = Denied
       *   2 = Authorized
       *
       * state (Android only) - Integer
       *   1 = Authorized
       *   2 = Denied
       *
       * hasPrompted - Bool
       *   false = Not prompted
       *   true = Prompted
       *
       * provisional - Bool
       *  iOS only Provisional notifications are non-interruptive notifications and can be turned on in the iOS Settings
       */
      if (permissionStatus.hasPrompted) {
        if (permissionStatus.status) {
          // iOS only: Integer: 0 = Not Determined, 1 = Denied, 2 = Authorized
          var statuses = ['denied', 'denied', 'granted'];
          return statuses[permissionStatus.status];
        }

        if (permissionStatus.state) {
          // Android only: Integer: 1 = Authorized, 2 = Denied
          var states = ['denied', 'granted', 'denied'];
          return states[permissionStatus.state];
        }

        return 'denied';
      } else {
        return 'denied';
      }
    }

    /**
     *
     * @return {angular.IPromise<*>}
     */
    function getWebSubscriptionState() {
      // return $q.resolve({
      //   supported: true,
      //   enabled: true,
      //   subscribed: true,
      //   permission: 'denied',
      //   user: 'XXX',
      //   token: 'N/A'
      // });

      if (gSubscriptionStatePromise) return gSubscriptionStatePromise;

      var deferred = $q.defer();
      gSubscriptionStatePromise = deferred.promise;

      if (gDebug) console.log('TEST: Getting web subscription state 1');
      window.OneSignal.push(function () {
        try {
          if (gDebug) console.log('TEST: Getting web subscription state 2');
          var isPushSupported = window.OneSignal.isPushNotificationsSupported();

          if (gDebug) console.log('TEST: Getting web subscription state 3', isPushSupported);
          var permissionPromise = window.OneSignal.getNotificationPermission().then(function (value) {
            if (gDebug) console.log('TEST: -> getNotificationPermission');
            return value;
          });

          if (gDebug) console.log('TEST: Getting web subscription state 4', permissionPromise);
          var enabledPromise = window.OneSignal.isPushNotificationsEnabled().then(function (value) {
            if (gDebug) console.log('TEST: -> isPushNotificationsEnabled');
            return value;
          });

          if (gDebug) console.log('TEST: Getting web subscription state 5', enabledPromise);
          var userPromise = window.OneSignal.getUserId().then(function (value) {
            if (gDebug) console.log('TEST: -> getUserId');
            return value;
          });

          // var isPushSupported = true;
          // var permissionPromise = $q.resolve('1');
          // var enabledPromise = $q.resolve('2');
          // var userPromise = $q.resolve('3');

          if (gDebug) console.log('TEST: Getting web subscription state 6', userPromise);
          $q.all([permissionPromise, enabledPromise, userPromise])
            .then(function (values) {
              if (gDebug) console.log('TEST: Getting web subscription state - done');
              deferred.resolve({
                supported: isPushSupported,
                enabled: values[1],
                subscribed: true,
                permission: values[0],
                user: values[2],
                token: 'N/A',
              });
              gSubscriptionStatePromise = undefined;
            })
            .catch(function (reason) {
              console.error(reason);
              deferred.reject(reason);
              gSubscriptionStatePromise = undefined;
            });
        } catch (e) {
          console.error(e);
        }
      });

      // deferred.reject(new Error('Test'));

      return gSubscriptionStatePromise;
    }

    /**
     *
     * @return {boolean}
     */
    function hasCordovaPlugin() {
      return window.plugins && window.plugins.OneSignal;
    }

    /**
     *
     * @return {angular.IPromise<any>}
     */
    function subscribe() {
      if (gIsDisabled) return $q.resolve();

      if (gDebug) console.log('TEST: subscribe');
      return setSubscription(true);
    }

    /**
     *
     * @return {angular.IPromise<any>}
     */
    function unsubscribe() {
      if (gIsDisabled) return $q.resolve();

      if (gDebug) console.log('TEST: unsubscribe');
      return setSubscription(false);
    }

    /**
     *
     * @param {*} unmute
     * @return {any}
     */
    function setSubscription(unmute) {
      if (window.isApp) {
        return setAppSubscription(unmute);
      } else if (window.isMobileWeb) {
        return $q.reject(new Error('Skip push notification on mobile web.'));
      } else {
        return setWebSubscription(unmute);
      }
    }

    /**
     *
     * @param {*} unmute
     * @return {any}
     */
    function setWebSubscription(unmute) {
      var deferred = $q.defer();

      window.OneSignal.push(function () {
        window.OneSignal.setSubscription(unmute)
          .then(function (value) {
            deferred.resolve(value);
          })
          .catch(function (reason) {
            deferred.reject(reason);
          });
      });

      return deferred.promise;
    }

    /**
     *
     * @param {boolean} unmute
     * @return {*}
     */
    function setAppSubscription(unmute) {
      var deferred = $q.defer();

      if (hasCordovaPlugin()) {
        deferred.resolve(window.plugins.OneSignal.disablePush(!unmute));
      } else {
        deferred.reject(new Error('One Signal plugin is not found.'));
      }

      return deferred.promise;
    }
  }
})();
