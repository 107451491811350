/**
 * Created by xxx on xxx.
 */

(function btIconImpactClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive display importance of event.
     *
     * @ngdoc directive
     * @name btIconImpact
     * @memberOf ecapp
     */
    .directive('btIconImpact', [
      '$templateCache',
      function ($templateCache) {
        return {
          //we use this as a tag
          restrict: 'E',
          scope: {
            value: '=',
            max: '@?',
            activeClass: '@?',
            update: '@?',
          },
          template: $templateCache.get('directives/bt-icon-impact.html'),
          controller: [
            '$scope',
            function ($scope) {
              // set value to updateIcon and use it instead of update due to undefined value will keep watcher
              if (angular.isUndefined($scope.update)) {
                $scope.updateIcon = 0;
              } else {
                $scope.updateIcon = $scope.update === '1';
              }

              if (angular.isUndefined($scope.max)) $scope.max = 3;

              setIcons();

              if ($scope.updateIcon) {
                $scope.$watch(
                  'value',
                  function () {
                    setIcons();
                  },
                  true
                );

                $scope.$watch(
                  'activeClass',
                  function () {
                    setIcons();
                  },
                  true
                );
              }

              /**
               *
               */
              function setIcons() {
                var activeClassName = 'active';

                if ($scope.activeClass) activeClassName = $scope.activeClass;

                $scope.icons = [];
                for (var i = 1; i <= $scope.max; i++) {
                  var iconClass = {};
                  iconClass['inactive'] = !isActive(i);
                  iconClass[activeClassName] = isActive(i);

                  $scope.icons.push({
                    iconClass: iconClass,
                  });
                }
              }

              /**
               * This function checks about the arrow whether they should be active or inactive, usage can be seen a few rows below.
               *
               * @param {*} level
               * @return {boolean}
               */
              function isActive(level) {
                return $scope.value >= level;
              }
            },
          ],
        };
      },
    ]);
})();
