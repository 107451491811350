/**
 * Created by Eyal on 8/16/2016.
 */

(function btModalButtonClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive load modal view
     *
     * @ngdoc directive
     * @name btModalButton
     * @memberOf ecapp
     */
    .directive('btModalButton', [
      '$templateCache',
      /**
       *
       * @param {angular.ITemplateCacheService} $templateCache
       * @return {any}
       */
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/common/modals/bt-modal-button.html'),
          scope: {
            rowName: '@row',
            modalTemplateName: '@modal',
            iconClasses: '@',
            showEllipsis: '&?',
          },
          controller: [
            '$scope',
            '$ionicModal',
            '$templateCache',
            function ($scope, $ionicModal, $templateCache) {
              $scope.showEllipsis = !angular.isUndefined($scope.showEllipsis);

              $scope.loadModal = function () {
                console.log('opening modal', $scope.modalTemplateName);

                var templateName = 'directives/common/modals/bt-modal-' + $scope.modalTemplateName + '.html';

                $scope.rowModalData = $scope.$parent[$scope.rowName];
                $scope.modal = $ionicModal.fromTemplate($templateCache.get(templateName), {
                  scope: $scope,
                  animation: 'slide-in-up',
                });

                $scope.modal.show();
              };
            },
          ],
        };
      },
    ]);
})();
