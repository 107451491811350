/**
 * Created by Itay on 6/14/2016.
 */

(function btActualClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive decides how to show the actual in the context, sometimes it supposed to be the data inside but
     * sometimes it should be the loader
     *
     * @ngdoc directive
     * @name btActual
     * @memberOf ecapp
     */
    .directive('btActual', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          scope: {
            rowName: '@row',
          },
          template: $templateCache.get('directives/common/bt-actual.html'),
          controller: [
            '$scope',
            function ($scope) {
              // By calling row this way we save many not required watchers
              $scope.row = $scope.$parent[$scope.rowName];

              /**
               *
               */
              function updateRowStatus() {
                var hasJustReleasedClass =
                  $scope.row.class === 'eventCardJustPositive' ||
                  $scope.row.class === 'eventCardJustAsExpected' ||
                  $scope.row.class === 'eventCardJustNegative';

                if (
                  $scope.row.actual === 'NA' &&
                  ($scope.row.class === 'eventCardNextHot' || $scope.row.class === 'eventCardJustAsExpected')
                )
                  $scope.status = 'loading';
                else if (
                  $scope.row.actual === 'NA' &&
                  ($scope.row.class === 'eventCardFuture' || $scope.row.class === 'eventCardNext')
                )
                  $scope.status = 'empty';
                // when the event was just released and got color, this type of status exists so we'll know that we must color this event
                else if ($scope.row.actual !== 'NA' && (hasJustReleasedClass || $scope.row.isJustReleased))
                  $scope.status = 'justReleased';
                else $scope.status = 'actual';

                $scope.actual = $scope.row.actual;
              }

              updateRowStatus();

              if ($scope.status !== 'actual') {
                $scope.$parent.$watchCollection($scope.rowName, function watchCollectionFunc() {
                  updateRowStatus();
                });
              }
            },
          ],
        };
      },
    ]);
})();
