(function btInstrumentNameClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * Returns instrument data
     *
     * @ngdoc filter
     * @name btInstrumentNameFilter
     * @memberOf ecapp
     */
    .filter('btInstrumentName', ['btInstrumentsService', btInstrumentNameFilter]);

  /**
   *
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @return {angular.IFilterFunction}
   */
  function btInstrumentNameFilter(btInstrumentsService) {
    return function getInstrumentName(symbol) {
      // var instrument = btInstrumentsService.getInstrumentBySomeSymbol(symbol);
      var instrument = btInstrumentsService.getInstrumentSmart(symbol);
      return instrument ? instrument.displayName : symbol;
    };
  }
})();
