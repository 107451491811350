/**
 * Created by Itay on 6/14/2016.
 */

//@ts-check
(function btInstrumentSearchClosure() {
  'use strict';

  angular
    .module('ecapp')
    .directive('btInstrumentSearch', btInstrumentSearch)
    .controller('btInstrumentSearchController', btInstrumentSearchController);

  btInstrumentSearch.$inject = ['$templateCache'];

  /**
   * This directive adds ability to open search instrument dialog. It should be added to html element as a attribute.
   * It will add `openAddInstrumentDialog` method to scope that can be used to open dialog.
   *
   * @example
   * <button bt-instrument-search ng-click="openAddInstrumentDialog()">Add</button>
   * @ngdoc directive
   * @name btInstrumentSearch
   * @memberOf ecapp
   * @return {angular.IDirective}
   */
  function btInstrumentSearch() {
    return {
      restrict: 'A',
      scope: false,
      controller: 'btInstrumentSearchController',
    };
  }

  btInstrumentSearchController.$inject = [
    '$scope',
    '$timeout',
    '$ionicModal',
    '$templateCache',
    'btTradingService',
    'btWatchListService',
    'btRestrictionService',
    'btToastrService',
    '$rootScope',
    'btTradeIdeasFiltersService',
  ];

  /**
   *
   * @param {scopes.InstrumentSearch.IScope} $scope
   * @param {angular.ITimeoutService} $timeout
   * @param {ionic.IModalService} $ionicModal
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.IWatchListService} btWatchListService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.ITradeIdeasFiltersService} btTradeIdeasFiltersService
   */
  function btInstrumentSearchController(
    $scope,
    $timeout,
    $ionicModal,
    $templateCache,
    btTradingService,
    btWatchListService,
    btRestrictionService,
    btToastrService,
    $rootScope,
    btTradeIdeasFiltersService
  ) {
    // search instrument object
    $scope.search = {
      text: '',
      instruments: [],
      hasResult: true,
      isTyping: 0,
      isLoading: false,
      select: selectInstrument,
    };

    $scope.option = {
      supported: false,
      enabled: false,
      stock: null,

      expiration: null,
      expirations: [],

      strike: null,
      strikes: [],
      filteredStrikes: [],

      instrument: null,
    };

    $scope.filter = {
      id: 'option-type',
      value: 'All',
      options: [
        { value: 'All', name: 'All', selected: true },
        { value: 'Put', name: 'Put', selected: false },
        { value: 'Call', name: 'Call', selected: false },
      ],
    };

    $scope.searching = {
      symbol: true,
      expiration: false,
      strike: false,
    };

    $scope.openAddInstrumentDialog = openModal;
    $scope.hideAddInstrumentDialog = hideModal;
    $scope.clearSearch = clearSearch;
    $scope.onSearch = onSearch;

    $scope.toggleOptionSelection = toggleOptionSelection;
    $scope.resetOptionSelection = resetOptionSelection;
    $scope.onOptionTypeChange = onOptionTypeChange;

    $scope.$on('$destroy', onDestroy);

    /**
     * Modal view to add instruments
     */
    $scope.search.modal = null;

    /**
     * On scope destroy
     */
    function onDestroy() {
      if ($scope.search.modal) {
        $scope.search.modal.remove();
        $scope.search.modal = null;
        $rootScope.instrumentSearchModal = null;
      }
    }

    /**
     * Creates modal dialog,
     *
     * @return {ionic.IModal}
     */
    function createModal() {
      if (!$rootScope.instrumentSearchModal) {
        var data = { scope: $scope, animation: 'slide-in-up', focusFirstInput: true };
        $rootScope.instrumentSearchModal = $ionicModal.fromTemplate($templateCache.get('all-stocks-modal.html'), data);
      }

      return $rootScope.instrumentSearchModal;
    }

    /**
     * Open modal view
     */
    function openModal() {
      $scope.option.supported = btTradingService.getBrokerName() === 'tradestation';

      if (btRestrictionService.hasFeature('personalization')) {
        $scope.search.watchlist = btWatchListService.getBrokerWatchList();
        $scope.search.instruments.forEach(function (instrument) {
          instrument.followed = $scope.search.watchlist.indexOf(instrument.ticker) !== -1;
        });

        if (!$scope.search.modal) $scope.search.modal = createModal();

        $scope.search.modal.show();
      } else {
        btRestrictionService.showUpgradePopup('personalization');
      }
    }

    /**
     * Hide modal view
     */
    function hideModal() {
      if ($scope.search.modal) {
        $scope.search.modal.hide();
      }
    }

    /**
     * Clear search
     */
    function clearSearch() {
      $scope.search.text = '';
    }

    /**
     * Load list of suggested instruments on search
     */
    function onSearch() {
      $scope.search.isTyping++;

      var text = $scope.search.text;
      $timeout(onSearchDelay, 800, false);

      /**
       * Delayed search
       * @return {*}
       */
      function onSearchDelay() {
        $scope.search.isTyping--;

        if (text === $scope.search.text && text !== '') {
          $scope.search.isLoading = true;
          $scope.search.hasResult = false;

          if ($scope.searching.symbol) {
            return searchSymbol($scope.search.text);
          }

          if ($scope.searching.expiration) {
            return searchExpiration($scope.search.text);
          }

          if ($scope.searching.strike) {
            return searchStrike($scope.search.text);
          }
        }
      }
    }

    /**
     *
     * @param {string} text
     */
    function searchSymbol(text) {
      btTradingService
        .suggestSymbols(text, 50, {})
        .then(function (symbols) {
          $scope.search.isLoading = false;
          $scope.search.hasResult = true;
          $scope.search.instruments = symbols;
          $scope.search.watchlist = btWatchListService.getBrokerWatchList();
          $scope.search.instruments.forEach(function (item) {
            item.followed = $scope.search.watchlist.indexOf(item.ticker) !== -1;
          });
        })
        .catch(function (reason) {
          $scope.search.isLoading = false;
          $scope.search.hasResult = true;
          console.error(reason);
        });
    }

    /**
     *
     * @param {string} text
     */
    function searchExpiration(text) {
      void text;
      $scope.search.isLoading = false;
      $scope.search.hasResult = true;
    }

    /**
     *
     * @param {string} text
     */
    function searchStrike(text) {
      void text;
      $scope.search.isLoading = false;
      $scope.search.hasResult = true;
    }

    /**
     * Run onSelect callback
     *
     * @param {scopes.InstrumentSearch.IInstrument} selected - selected instrument
     * @return {*}
     */
    function selectInstrument(selected) {
      if (!$scope.option.enabled) {
        return selectRegularInstrument(selected);
      }

      if ($scope.searching.symbol) {
        return selectOptionStock(selected);
      }

      if ($scope.searching.expiration) {
        return selectOptionExpiration(selected);
      }

      if ($scope.searching.strike) {
        return selectOptionStrike(selected);
      }
    }

    /**
     * Run onSelect callback
     *
     * @param {scopes.InstrumentSearch.IInstrument} selected - selected instrument
     */
    function selectRegularInstrument(selected) {
      if (selected.followed === false) {
        var toast = btToastrService.info('Adding...', selected.name, { type: 'market', hidden: true });

        btTradingService
          .getSymbolInfo(selected.ticker)
          .then(addInstrumentToWatchlist)
          .then(function () {
            btToastrService.remove(toast);
            btToastrService.info('Has been added to your Watchlist.', selected.name, { type: 'market' });

            $scope.search.instruments.forEach(function (item) {
              item.followed = $scope.search.watchlist.indexOf(item.ticker) !== -1;
            });
          })
          .catch(function (reason) {
            btToastrService.remove(toast);
            btToastrService.error(reason.message || 'Unknown error!', selected.name, { type: 'market' });
          })
          .finally(function () {
            $scope.hideAddInstrumentDialog();
          });
      }
    }

    /**
     *
     * @param {*} selected
     */
    function selectOptionStock(selected) {
      $scope.option.stock = selected;
      $scope.searching.symbol = false;
      $scope.searching.expiration = true;
      $scope.searching.strike = false;

      $scope.search.isLoading = true;
      btTradingService
        .getOptionExpirations(selected.ticker)
        .then(function (result) {
          // console.log(result);
          $scope.option.expirations = result;
        })
        .finally(function () {
          $scope.search.isLoading = false;
        });
    }

    /**
     *
     * @param {*} selected
     */
    function selectOptionExpiration(selected) {
      $scope.option.expirations = [];
      $scope.option.expiration = selected;
      $scope.searching.symbol = false;
      $scope.searching.expiration = false;
      $scope.searching.strike = true;

      var underlying = $scope.option.stock;
      $scope.search.isLoading = true;
      btTradingService
        .getOptionStrikes(underlying.ticker, selected.date)
        .then(function (result) {
          // console.log(result);
          $scope.option.strikes = result;
          onOptionTypeChange($scope.filter.value);
        })
        .finally(function () {
          $scope.search.isLoading = false;
        });
    }

    /**
     *
     * @param {*} selected
     */
    function selectOptionStrike(selected) {
      $scope.option.strike = selected;
      $scope.searching.symbol = false;
      $scope.searching.expiration = false;
      $scope.searching.strike = true;

      var toast = btToastrService.info('Adding...', selected.name, { type: 'market', hidden: true });

      btTradingService
        .getSymbolInfo(selected.id)
        .then(addInstrumentToWatchlist)
        .then(function () {
          btToastrService.remove(toast);
          btToastrService.info('Has been added to your Watchlist.', selected.name, { type: 'market' });

          $scope.search.instruments.forEach(function (item) {
            item.followed = $scope.search.watchlist.indexOf(item.ticker) !== -1;
          });
        })
        .catch(function (reason) {
          btToastrService.remove(toast);
          btToastrService.error(reason.message || 'Unknown error!', selected.name, { type: 'market' });
        })
        .finally(function () {
          $scope.hideAddInstrumentDialog();
        });
    }

    /**
     *
     */
    function toggleOptionSelection() {
      $scope.option.enabled = !$scope.option.enabled;
      if (!$scope.option.enabled) {
        resetOptionSelection();
      }
    }

    /**
     *
     */
    function resetOptionSelection() {
      $scope.option.stock = null;
      $scope.option.expiration = null;
      $scope.option.expirations = [];
      $scope.option.strike = null;
      $scope.option.strikes = [];

      $scope.searching.symbol = true;
      $scope.searching.expiration = false;
      $scope.searching.strike = false;
    }

    /**
     * Adds instrument to watchlist.
     *
     * @param {ecapp.ITradingInstrument} instrument - instrument instance
     * @return {angular.IPromise<void>}
     */
    function addInstrumentToWatchlist(instrument) {
      return btWatchListService.addInstrumentByName(instrument.brokerSymbol).then(function () {
        btTradeIdeasFiltersService.updateInstrumentsByName(instrument.brokerSymbol, true);
      });
    }

    /**
     *
     * @param {string} option - option type
     */
    function onOptionTypeChange(option) {
      switch (option) {
        case 'Put':
        case 'Call':
          $scope.option.filteredStrikes = $scope.option.strikes.filter(function (strike) {
            return strike.side === option;
          });
          break;
        case 'All':
        default:
          $scope.option.filteredStrikes = $scope.option.strikes;
          break;
      }
    }
  }
})();
