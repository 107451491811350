/**
 * Created by Sergey Panpurin on 12/11/2016.
 */

// @ts-check
(function btInsightsServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btInsightsService:';

  angular.module('ecapp').factory('btInsightsService', btInsightsService);

  btInsightsService.$inject = [
    '$q',
    '$sce',
    'Rows',
    'Insights',
    'btCurrenciesService',
    'btRowProcessorService',
    'btDateService',
    'btStrengthService',
    'btInstrumentsService',
    'btDevService',
  ];

  /**
   * @ngdoc service
   * @name btInsightsService
   * @memberOf ecapp
   * @description
   *  This factory work with insights.
   * @param {angular.IQService} $q
   * @param {angular.ISCEService} $sce
   * @param {ecapp.IGeneralLoopbackService} lbRows
   * @param {ecapp.IGeneralLoopbackService} lbInsights
   * @param {ecapp.ICurrenciesService} btCurrenciesService
   * @param {ecapp.IRowProcessorService} btRowProcessorService
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IStrengthService} btStrengthService
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.IDevService} btDevService
   * @return {ecapp.IInsightsService}
   */
  function btInsightsService(
    $q,
    $sce,
    lbRows,
    lbInsights,
    btCurrenciesService,
    btRowProcessorService,
    btDateService,
    btStrengthService,
    btInstrumentsService,
    btDevService
  ) {
    console.log('Running btInsightsService');

    /** @constant {Number} */
    var gInsightStrengthThreshold = 0.25;

    /** @constant {Number[]} */
    var gBadInsights = [1, 2, 3, 4, 5, 6];

    /**
     * @typedef {Object} btExpectedTradeIdeaCaseTemplate
     * @property {String} value - win ratio
     * @property {String} class - class
     * @property {Number} order - order
     */

    /**
     * @typedef {btExpectedTradeIdeaCaseTemplate} btExpectedTradeIdeaCaseObject
     * @property {String} id - identifier
     * @property {String} icon - icon
     */

    /** @constant {Record<string, btExpectedTradeIdeaCaseTemplate>}*/
    var gTableDataTemplate = {
      'back-pos': { value: '-', class: '', order: 1 },
      'back-exp': { value: '-', class: '', order: 2 },
      'back-neg': { value: '-', class: '', order: 3 },
      'extr-pos': { value: '-', class: '', order: 4 },
      'much-pos': { value: '-', class: '', order: 5 },
      'just-pos': { value: '-', class: '', order: 6 },
      'abit-pos': { value: '-', class: '', order: 7 },
      asexpect: { value: '-', class: '', order: 8 },
      'abit-neg': { value: '-', class: '', order: 9 },
      'just-neg': { value: '-', class: '', order: 10 },
      'much-neg': { value: '-', class: '', order: 11 },
      'extr-neg': { value: '-', class: '', order: 12 },
    };

    /** @constant {Record<string, string>}*/
    var iconsArray = {
      positive: 'ion-arrow-graph-up-right',
      negative: 'ion-arrow-graph-down-right',
    };

    var positiveWords = ['positive', 'highest', 'climb', 'better', 'above', 'uptrend', 'moved up'];
    var negativeWords = ['negative', 'lowest', 'drop', 'below', 'downtrend', 'moved down'];
    var trends = {
      uptrend: 'up',
      downtrend: 'down',
    };
    // var range = btDateService.getDayRange(24, 48);

    return {
      getInsights: getInsights,
      getInsightsAndRowsByStrength: getInsightsAndRowsByStrength,
      handleInsights: handleInsights,
      prepareTemplate: setTemplate,
      getRange: getRange,
      getActual: getActual,
      getSurprise: getSurprise,
      isGoodInsightCard: isGoodInsightCard,
      isGoodPerspectiveInsight: isGoodPerspectiveInsight,
      makeInsightCard: makeInsightCard,
      removeWeakInsights: removeWeakInsights,

      prepareTradingInsightsData: prepareTradingInsightsData,
      convertToTradingInsight: convertToTradingInsight,
    };

    /**
     * This function check is insight weak or not. For post insight test total surprise strength, for expected -
     *  total strength, additionally skip insights with id <= 4
     *
     * @param {btInsight} insight - (const)
     * @param {Number} threshold - (const)
     * @param {Boolean} isReleased - is event already released
     * @return {Boolean}
     */
    function isWeakInsight(insight, threshold, isReleased) {
      if (gBadInsights.indexOf(insight.id) !== -1) {
        if (gDebug) console.log(gPrefix, '1 was triggered');
        return true;
      }

      if (insight.type === 'post-expected') {
        if (insight.totalStrength === undefined || Math.abs(insight.totalStrength) < threshold) {
          return true;
        }
      }

      if (insight.type === 'post') {
        if (insight.totalSurpriseStrength === undefined || Math.abs(insight.totalSurpriseStrength) < threshold) {
          return true;
        }
      }

      if (insight.type === 'back-pre') {
        return isReleased;
      }

      return false;
    }

    /**
     *
     * @param {*} insight
     * @return {any}
     */
    function isGoodPerspectiveInsight(insight) {
      return !isWeakInsight(insight, gInsightStrengthThreshold, true);
    }

    /**
     * Remove weak insights to limit list of insights to display
     *
     * @param {btInsight[]|btRawInsight[]} insights - (changeable)
     * @param {Boolean} isReleased - is event already released
     * @param {boolean} test - ?
     * @return {btInsight[]|btRawInsight[]}
     */
    function removeWeakInsights(insights, isReleased, test) {
      // remove weak insights
      for (var i = 0; i < insights.length; i++) {
        if (isWeakInsight(insights[i], test ? 0 : gInsightStrengthThreshold, isReleased)) {
          insights.splice(i, 1);
          i -= 1;
        }
      }
      return insights;
    }

    /**
     * Separate insights
     *
     * @private
     * @param {btRelease} row - release object
     * @param {*} test
     */
    function _separateInsights(row, test) {
      // sort insights
      row.insightRow.insightsAll = sortByInsightStrength(row.insightRow.insights);

      // remove weak insights
      row.insightRow.insightsGood = removeWeakInsights(row.insightRow.insightsAll, row.isReleased, test);

      row.insightRow.insightsRegular = row.insightRow.insightsGood.filter(function (item) {
        return item.type !== 'back-pre' && item.type !== 'back-test';
      });

      row.insightRow.insightsFree = row.insightRow.insightsGood.filter(function (item) {
        return item.type === 'pre' || item.type === 'post-expected';
      });

      row.insightRow.insightsTrading = row.insightRow.insightsGood.filter(function (item) {
        return item.type === 'back-test';
      });

      row.insightRow.insightsExpectedTrading = row.insightRow.insightsGood.filter(function (item) {
        return item.type === 'back-pre';
      });

      row.insightRow.number = {};
      row.insightRow.number.pre = _countInsightsByType(row.insightRow.insightsGood, 'pre');
      row.insightRow.number.post = _countInsightsByType(row.insightRow.insightsGood, 'post');
      row.insightRow.number.postExpected = _countInsightsByType(row.insightRow.insightsGood, 'post-expected');
      row.insightRow.number.backTest = _countInsightsByType(row.insightRow.insightsGood, 'back-test');
      row.insightRow.number.backPre = _countInsightsByType(row.insightRow.insightsGood, 'back-pre');

      row.insightRow.hasProInsights = row.insightRow.number.post > 0;
    }

    /**
     * Count number of insights of selected type
     *
     * @private
     * @param {btInsight[]} insights - array of insights
     * @param {string} type - insight type
     * @return {number}
     */
    function _countInsightsByType(insights, type) {
      return insights.filter(function (item) {
        return item.type === type;
      }).length;
    }

    /**
     * This function get insights for selected release (row) for modal and quick-view
     *
     * @ngdoc method
     * @name getInsights
     * @memberOf ecapp.btInsightsService
     * @param {btRelease} row - (changeable) row data
     * @return {angular.IPromise<btInsightById>}
     */
    function getInsights(row) {
      if (row.insightRow !== undefined) {
        return $q.resolve();
      }

      var query = { data: { id: row.id } };

      // receive insights for row id
      return lbInsights
        .getInsightsOfRowId(query)
        .$promise.then(function processInsightsMain(res) {
          // this condition is itay's patch in order to make loading of report/speech modal work.
          if (res.getInsightsOfRowId[0]) {
            modifyInsightRow(row, res.getInsightsOfRowId[0]);
          }
          return row.insightRow;
        })
        .catch(function (reason) {
          console.error('btInsightsService: error', reason);
          return $q.reject(reason);
        });
    }

    /**
     * Modify insights
     * @param {btRelease} row - (changeable) row data
     * @param {btInsightRow} insightRow - insights from database
     */
    function modifyInsightRow(row, insightRow) {
      row.insightRow = insightRow;

      // add expected insights
      if (row.insightRow.expectedInsights && row.insightRow.expectedInsights.length > 0) {
        row.insightRow.expectedInsights.forEach(function processExpectedInsights(val) {
          if (row.class === 'eventCardFuture') {
            val.template = 'As expected: ' + val.template;
          } else {
            val.template = 'Was expected: ' + val.template;
          }
          val.type = 'post-expected';
          this.push(val);
        }, row.insightRow.insights);
      }

      // add first expected trading insights
      if (row.insightRow.expectedTradingInsights && row.insightRow.expectedTradingInsights.length > 0) {
        // row.insightRow.insights.push(row.insightRow.expectedTradingInsights[0].expectedInsights[0]);
        // row.insightRow.insights = row.insightRow.insights.concat(row.insightRow.expectedInsightsTrade);

        row.insightRow.expectedTradingInsights.forEach(function (item) {
          item.expectedInsights.forEach(function (element) {
            row.insightRow.insights.push(element);
          });
        });

        row.insightRow.expectedTradingInsightsTable = generateExpectedTable(row);
      }

      _separateInsights(row);
    }

    /**
     * @typedef {Object} btExpectedTradeIdeaTableRow
     * @property {string} market - ?
     * @property {Record<string, btExpectedTradeIdeaCaseObject>} data - ?
     */

    /**
     * This function create table of expected trade ideas for each instruments.
     *
     * @param {btRelease} row - release data
     * @return {btExpectedTradeIdeaTableRow[]}
     */
    function generateExpectedTable(row) {
      return row.insightRow.expectedTradingInsights.map(function (item) {
        var tableData = JSON.parse(JSON.stringify(gTableDataTemplate));
        item.expectedInsights.forEach(function (element) {
          tableData[element.case.id].id = item.id + '@' + element.case.id;
          tableData[element.case.id].value = element.data.win + '/' + element.data.total;
          tableData[element.case.id].class = element.data.class;
          tableData[element.case.id].icon = iconsArray[element.data.class] || 'ion-grid';
        });

        return { market: item.market, data: tableData };
      });
    }

    /**
     * Sort insights using type and strength. Type order: post, post-expected, pre. For 'post' insights we use
     *  totalSurpriseStrength for 'expected' totalStrength. Order of pre insights now it isn't guaranteed.
     *  back-pre, back-test, post, post-expected, pre
     *
     * @param {btGeneralInsight[]} arr - array of insights
     * @return {Array.<*>}
     */
    function sortByInsightStrength(arr) {
      return arr.slice(0).sort(function sortByInsightStrengthFunc2(a, b) {
        // order insights by type
        if (a.type > b.type) return 1;
        if (a.type < b.type) return -1;

        // order insights of same type
        // released regular insights
        if (a.type === 'post') {
          return Math.abs(a.totalSurpriseStrength) > Math.abs(b.totalSurpriseStrength)
            ? -1
            : Math.abs(a.totalSurpriseStrength) < Math.abs(b.totalSurpriseStrength)
            ? 1
            : 0;
        }

        // expected regular insights
        if (a.type === 'post-expected') {
          return Math.abs(a.totalStrength) > Math.abs(b.totalStrength)
            ? -1
            : Math.abs(a.totalStrength) < Math.abs(b.totalStrength)
            ? 1
            : 0;
        }

        // expected trading insights
        if (a.type === 'back-pre') {
          return gTableDataTemplate[a.case.id].order - gTableDataTemplate[b.case.id].order;
        }

        return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
      });
    }

    /**
     * Check existing of trading insights and add specific flags for review
     *
     * @param {btRelease} review - release object
     */
    function prepareTradingInsights(review) {
      /**
       * Array of trading insights
       * @type {btConvertedTradingInsightObject[]}
       */
      review.tradingInsights = [];

      review['insightRow']['insightsGood'].forEach(function (item) {
        if (item.type === 'back-test') {
          var instrument = btInstrumentsService.getInstrumentBySomeSymbol(item.data.market);

          review.tradingInsights.push(convertToTradingInsight(review, item, instrument));
        }
      });

      review.tradingInsights.sort(function (a, b) {
        return a.symbol.localeCompare(b.symbol);
      });

      review['insightRow']['tradingInsights'] = review.tradingInsights;
      review.numTradingInsights = review.tradingInsights.length;
      review.showTradingInsights = review.numTradingInsights > 0;
    }

    /**
     *
     * @param {btRelease} release
     * @param {btRawInsight} insight
     * @param {ecapp.ITradingInstrument} instrument
     * @return {btConvertedTradingInsightObject}
     */
    function convertToTradingInsight(release, insight, instrument) {
      return {
        origin: 'pusher',
        kind: 'release',
        rowId: release.id,
        insight: insight,
        time: release.time,
        eventId: release.eventId,
        market: insight.data.market,
        symbol: instrument ? instrument.displayName : insight.data.market,
        btMarket: insight.data.market,
        releaseStrength: release.releaseStrength ? release.releaseStrength.value : null,
      };
    }

    /**
     * Handler insights messages from pusher
     *
     * @ngdoc method
     * @name handleInsights
     * @memberOf ecapp.btInsightsService
     * @param {btPusherEventMessage} item - insights data
     * @param {btRelease} review - release data
     */
    function handleInsights(item, review) {
      if (review.insightRow === undefined) {
        if (gDebug) console.log(gPrefix, 'Pusher: Bad row without insightRow:', review);
        review.insightRow = { insights: [], totalSurpriseStrength: 0, totalExpectedStrength: 0, totalStrength: 0 };
      }

      if (item.increment) {
        review.insightRow.insights = review.insightRow.insights.concat(item.insights);
        _separateInsights(review);
      } else {
        review.insightRow.insights = item.insights;
        _separateInsights(review);
        review.insightRow.totalSurpriseStrength = item.totalSurpriseStrength;
        review.insightRow.totalExpectedStrength = item.totalExpectedStrength;
        review.insightRow.totalStrength = item.totalStrength;
      }

      prepareTradingInsights(review);
    }

    /**
     * This function get release by release strength and insight strength and process them.
     * Save results to scope and rootScope.
     *
     * TODO: Combine two requests to one (server-side) and move processing to bt-insights-service
     *
     * @param {*} from
     * @param {*} till
     * @param {*} priority
     * @param {*} currencies
     * @param {*} insightThreshold
     * @param {*} releaseThreshold
     * @return {angular.IPromise<*>}
     */
    function getInsightsAndRowsByStrength(from, till, priority, currencies, insightThreshold, releaseThreshold) {
      if (gDebug) console.log(gPrefix, 'getInsightsAndRowsByStrength: do something...');

      // request to receive release with high total surprise strength
      var insightData = {
        from: from,
        till: till,
        importance: priority,
        currency: currencies,
        strength: insightThreshold,
        type: 'insights',
      };

      // request to receive release with high release strength
      var releaseData = {
        from: from,
        till: till,
        importance: priority,
        currency: currencies,
        strength: releaseThreshold,
        type: 'releases',
      };

      // echo requests
      if (gDebug) console.log(gPrefix, 'insightData:', insightData);
      if (gDebug) console.log(gPrefix, 'releaseData:', releaseData);

      // use $q.all to receive two responses at one time
      var promises = [
        lbRows.getInsightsAndRowsByStrength({ data: insightData }).$promise,
        lbRows.getInsightsAndRowsByStrength({ data: releaseData }).$promise,
      ];

      return $q.all(promises).then(function onSuccess(res) {
        if (gDebug) console.log(gPrefix, 'getInsightsAndRowsByStrength', res);

        var insights1 = res[0].getInsightsAndRowsByStrength;
        var insights2 = res[1].getInsightsAndRowsByStrength;

        // process data
        insights1.forEach(processInsights);
        insights2.forEach(processReleases);

        // at first save insights
        var result = insights1;

        // combine with releases
        var n = insights1.length;
        for (var i = 0; i < insights2.length; i++) {
          var isNew = true;
          for (var j = 0; j < n; j++) {
            if (insights2[i].id === insights1[j].id) {
              isNew = false;
              break;
            }
          }
          if (isNew) {
            result.push(insights2[i]);
          }
        }

        // sort data to receive maximal insight
        result = result.sort(function (a, b) {
          return a.time > b.time ? 1 : a.time < b.time ? -1 : 0;
        });

        return result;
      });
    }

    /**
     * This function is a wrapper for function processRow to process insights
     *
     * @param {Object} val - insight data
     * @return {any}
     */
    function processInsights(val) {
      return processRow(val, true);
    }

    /**
     * This function is a wrapper for function processRow to process release
     * @param {Object} val - release data
     * @return {any}
     */
    function processReleases(val) {
      return processRow(val, false);
    }

    /**
     * This function processes release or insight for getInsightsAndRowsByStrength
     *
     * @param {btEventInsightCard} val - release or insight data
     * @param {Boolean} needProcessInsights - need to process insights
     */
    function processRow(val, needProcessInsights) {
      val.insightType = 'release';

      // add currency flag
      val.eventsInfo.currencyFlag = btCurrenciesService.getCurrencyFlag(val.eventsInfo.currency);

      // prepare strength of release
      btRowProcessorService.rowStrengthProcessor(val);

      // prepare time
      val.timeHumanised = btDateService.getHumanisedTimeFromNow(btDateService.getDateFromRow(val));

      // Check insight by id
      // var test = val.insights.insights.filter(function (value) {
      //   if (value.id == 300 || value.id == 301) {
      //     if (gDebug) console.log(gPrefix, 'Test');
      //   }
      // });

      if (val.tradingInsights && val.tradingInsights.length > 0) {
        val.tradingInsights.forEach(extendTradingInsights);
      }

      /**
       * Extent Trading Insights
       * @param {btExtendedTradingInsight} item
       */
      function extendTradingInsights(item) {
        // !!! > HotFix during changing insights structure
        item.data = item.data || { win: 8, total: 9 };
        item.data.btMarket = item.data.market;
        item.kind = 'release';
        item.totalSurpriseStrengthPercentage = btStrengthService.prepareStrength(item.totalSurpriseStrength, val.time);
      }

      if (val.insights === undefined) {
        val.insights = {
          insights: [],
        };
        btDevService.alert('Release without insights: ' + JSON.stringify(val));
      }

      if (needProcessInsights) {
        var maxInsight = Math.max.apply(
          Math,
          val.insights.insights.map(function (o) {
            return o.totalSurpriseStrength;
          })
        );

        val.insights.insights = val.insights.insights.filter(filterInsights);

        if (val.insights.insights.length === 0) {
          if (gDebug) console.log(gPrefix, '--------------- Strange Behavior ---------------');
          if (gDebug) console.log(gPrefix, "Can't find insights", val);
          val.insights.insights = null;
        } else {
          val.insights.insights.sort(sortInsights);
          val.insights.insights[0].totalSurpriseStrengthPercentage = btStrengthService.prepareStrength(
            val.insights.insights[0].totalSurpriseStrength,
            val.time
          );

          if (gDebug) console.log(gPrefix, 'Event name:', val.eventsInfo.name, '- Max insight:', maxInsight);
          if (gDebug) console.log(gPrefix, 'Insight data', val.insights.insights[0]);
        }
      } else {
        val.insights.insights = null;
      }
    }

    /**
     * This function sorts insights by total surprise strength
     *
     * @param {Object} a - first release
     * @param {Object} b - second release
     * @return {Number} 1, -1 or 0
     */
    function sortInsights(a, b) {
      if (Math.abs(a.totalSurpriseStrength) < Math.abs(b.totalSurpriseStrength)) {
        return 1;
      } else if (Math.abs(a.totalSurpriseStrength) > Math.abs(b.totalSurpriseStrength)) {
        return -1;
      } else {
        return 0;
      }
    }

    /**
     * This function removes pre and expected insights
     *
     * @param {Object} a - first release
     * @return {Boolean}
     */
    function filterInsights(a) {
      return a.type === 'post';
    }

    /**
     * This function builds regex for the coloring words
     *
     * @param {*} arr
     * @return {RegExp}
     * @private
     */
    function _buildRegexFromArr(arr) {
      var regex = arr[0];
      for (var index = 1; index < arr.length; index++) {
        regex += '|' + arr[index];
      }

      return new RegExp(regex, 'gi');
    }

    /**
     * This function use regex in order to modify the template variable and change color of some words.
     *
     * @param {*} template
     * @return {*}
     * @private
     */
    function _changeNegativeAndPositiveWordsColor(template) {
      var positiveRegex = _buildRegexFromArr(positiveWords);
      var negativeRegex = _buildRegexFromArr(negativeWords);

      template = template.replace(positiveRegex, function (word) {
        return "<span class='positive'>" + word + '</span>';
      });

      template = template.replace(negativeRegex, function (word) {
        return "<span class='negative'>" + word + '</span>';
      });

      return template;
    }

    /**
     *
     * @param {*} template
     * @return {any}
     */
    function _fixTradingInsights(template) {
      var res1 = template.match(/^(.*) was on the (\w+) in similar situations$/);
      if (res1) {
        template = res1[1] + ' moved ' + trends[res1[2]] + ' following similar releases';
      }

      var res2 = template.match(/^(.*) was on the (\w+) in expected situations$/);
      if (res2) {
        template = res2[1] + ' moved ' + trends[res2[2]] + ' following similar releases';
      }
      return template;
    }

    /**
     * This function set the template variable
     *
     * @param {Object} insight - insight object
     * @return {String}
     */
    function setTemplate(insight) {
      var template = _fixTradingInsights(insight.template);

      template = _changeNegativeAndPositiveWordsColor(template);

      if (insight.templateVars !== undefined && insight.templateVars !== null) {
        var words = [];
        for (var key in insight.templateVars) {
          if (insight.templateVars.hasOwnProperty(key)) {
            if (key === 'market') {
              var marketName = insight.templateVars[key];
              var instrument = btInstrumentsService.getInstrumentBySomeSymbol(marketName);
              var newMarketName = instrument ? instrument.displayName : marketName;

              if (newMarketName !== marketName) {
                // insight.templateVars[key] = newMarketName;
                template = template.replace(marketName, newMarketName);
                words.push(newMarketName);
              } else {
                words.push(marketName);
              }
            } else {
              words.push(insight.templateVars[key]);
            }
          }
        }

        var regex = _buildRegexFromArr(words);

        template = template.replace(regex, function (word) {
          if (word.indexOf('times') !== -1) {
            return "<span class='white-text'>" + word.split(' times')[0] + '</span> times';
          } else {
            return "<span class='white-text'>" + word + '</span>';
          }
        });
      }

      return $sce.trustAsHtml(template);
    }

    /**
     * This function find the ranges of range and surprise insights
     *
     * @param {Object} insight - insight object
     * @return {{range: Array, templateSplit: Array}}
     */
    function getRange(insight) {
      // taking only the text after :
      var templateSplit = insight.template.split(':');

      var strWithNumbers = templateSplit[1];

      // splitting this text into words
      var strWithNumbersWords = strWithNumbers.split(' ');

      // adding range variable to scope
      var range = [];

      // checking if each word is a number, and if it does, adds it to the range.
      for (var i = 0; i < strWithNumbersWords.length; i++) {
        var num = getActual(strWithNumbersWords[i]);

        if (!isNaN(num)) {
          range.push(num.toFixed(2));
        }
      }

      return { range: range, templateSplit: templateSplit };
    }

    /**
     * Prepare actual value
     *
     * @param {String} value - actual value
     * @return {Number}
     */
    function getActual(value) {
      return parseFloat(value.replace(/[,]/, ''));
    }

    /**
     * Prepare surprise value
     *
     * @param {String} actual - actual value
     * @param {String} forecast - forecast value
     * @return {Number}
     */
    function getSurprise(actual, forecast) {
      var value = (getActual(actual) - getActual(forecast)).toFixed(2);
      return parseFloat(value);
    }

    /**
     *
     * @param {*} item
     * @param {*} time
     * @param {*} type
     */
    function prepareTradingInsightsData(item, time, type) {
      if (item.collapsed === undefined) {
        item.enable = true;
        item.collapsed = true;
        item.templateHTML = setTemplate(item);

        /** @type {btConvertedInsight} */
        item.convertedInsight = {
          kind: type,
          origin: 'pusher',
          rowId: '1',
          insight: JSON.parse(JSON.stringify(item)),
          time: time,
          eventId: '1',
          market: btInstrumentsService.convertComplexName2BTName(item.data.market),
          releaseStrength: null,
        };
      }
    }

    /**
     *
     * @param {btRelease} release
     * @param {*} insightThr
     * @param {*} releaseThr
     * @return {Boolean}
     */
    function isGoodInsightCard(release, insightThr, releaseThr) {
      var isGood = false;

      var insights = release.insightRow || {};

      if (release.eventsInfo !== undefined) {
        if (
          release.releaseStrength &&
          (release.releaseStrength.value >= releaseThr || release.releaseStrength.value <= -releaseThr)
        ) {
          isGood = true;
        } else if (insights.insights) {
          insights.insights.forEach(function (insight) {
            if (
              insight.type === 'post' &&
              (insight.totalSurpriseStrength >= insightThr || insight.totalSurpriseStrength <= -insightThr)
            ) {
              isGood = true;
            }
          });
        }

        if (release.tradingInsights && release.tradingInsights.length > 0) {
          isGood = true;
        }
      } else {
        isGood = false;
      }

      return isGood;
    }

    /**
     *
     * @param {btRelease} release
     * @return {btEventInsightCard}
     */
    function makeInsightCard(release) {
      var insightCard = JSON.parse(JSON.stringify(release));

      if (insightCard.tradingInsights) {
        insightCard.tradingInsights = insightCard.tradingInsights.map(function (item) {
          var insight = item.insight;
          prepareTradingInsightsData(insight, release.time, 'release');
          return insight;
        });
      } else {
        insightCard.tradingInsights = [];
      }

      insightCard.insights = insightCard.insightRow;

      if (insightCard.insightRow) {
        processRow(insightCard, true);
      } else {
        processRow(insightCard, false);
      }

      return insightCard;
    }
  }
})();
