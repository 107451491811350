/**
 * Created by Orly on 03/18/2019.
 */

(function HorizontalScrollableListClosure() {
  'use strict';

  /**
   * This directive displays horizontal scrollable list
   *
   * @ngdoc directive
   * @name btToggle
   * @memberOf ecapp
   * @restrict E
   * @scope
   * @param {String} selector - class to select list using jQuery
   * @param {Array} list - list to display
   * @param {function} onSelect - action on item click
   */
  angular.module('ecapp').directive('btHorizontalScrollableList', btHorizontalScrollableList);

  btHorizontalScrollableList.$inject = ['$templateCache', '$interval'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {angular.IIntervalService} $interval
   * @return {angular.IDirective}
   */
  function btHorizontalScrollableList($templateCache, $interval) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {},
      template: $templateCache.get('directives/common/bt-horizontal-scrollable-list.html'),
      link: function (scope, element) {
        btHorizontalScrollableListLink(scope, element, $interval);
      },
    };
  }

  /**
   * @typedef {Object} ListItemObject
   * @property {String} displayName - displayed text of list item
   */

  /**
   *
   * @param {*} scope
   * @param {*} element
   * @param {angular.IIntervalService} $interval
   */
  function btHorizontalScrollableListLink(scope, element, $interval) {
    // is scrolling
    scope.scrolling = false;
    scope.startScrolling = startScrolling;
    scope.stopScrolling = stopScrolling;
    scope.moveList = moveList;

    /**
     * Function to move the list by click
     *
     * @param {string} direction - scrolling direction ('left' or 'right')
     */
    function moveList(direction) {
      if (scope.scrolling) return;

      var delta = 50;
      var list = element.find('ul').get(0);
      var minScroll = 0;
      var maxScroll = list.scrollWidth - list.offsetWidth;

      if (direction === 'left') {
        list.scrollLeft -= delta;
        if (list.scrollLeft <= minScroll) list.scrollLeft = minScroll;
      }

      if (direction === 'right') {
        list.scrollLeft += delta;
        if (list.scrollLeft >= maxScroll) list.scrollLeft = maxScroll;
      }
    }

    /**
     * Starts scrolling.
     * 
     * @param {String} direction - scrolling direction ('left' or 'right')
     */
    function startScrolling(direction) {
      if (scope.scrolling) return;
      scope.scrolling = true;

      var delta = 10;
      var list = element.find('ul').get(0);
      var minScroll = 0;
      var maxScroll = list.scrollWidth - list.offsetWidth;

      scope.scrollInterval = $interval(function () {
        if (direction === 'left') {
          list.scrollLeft -= delta;
          if (list.scrollLeft <= minScroll) {
            list.scrollLeft = minScroll;
            scope.stopScrolling();
          }
        }

        if (direction === 'right') {
          list.scrollLeft += delta;
          if (list.scrollLeft >= maxScroll) {
            list.scrollLeft = maxScroll;
            scope.stopScrolling();
          }
        }
      }, 25);
    }

    /**
     * Stops scrolling.
     */
    function stopScrolling() {
      scope.scrolling = false;
      $interval.cancel(scope.scrollInterval);
      scope.scrollInterval = null;
    }
  }
})();
