/**
 * Created by Sergey Panpurin on 4/8/2018.
 */

(function btInstrumentCalendarClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btInstrumentCalendar';

  angular
    .module('ecapp')
    .directive('btInstrumentCalendar', btInstrumentCalendar)
    .controller('btInstrumentCalendarController', btInstrumentCalendarController);

  btInstrumentCalendar.$inject = ['$templateCache'];

  /**
   * This directive displays event calendar for selected instrument.
   *
   * Now just instruments with btName are supported.
   * Update calendar every 30 seconds (just update interval data).
   *
   * @ngdoc directive
   * @name btInstrumentCalendar
   * @memberOf ecapp
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btInstrumentCalendar($templateCache) {
    return {
      restrict: 'E',
      scope: {
        btInstrumentSymbol: '@',
        btInstrumentName: '@',
        btInstrumentDisplayName: '@',
      },
      template: $templateCache.get('components/mobile/bt-calendar/templates/bt-instrument-calendar.html'),
      controller: 'btInstrumentCalendarController',
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btInstrumentCalendarController.$inject = ['$rootScope', '$interval', 'btCalendarService'];

  /**
   * This is the controller of btInstrumentCalendar directive.
   *
   * @ngdoc directive
   * @name btInstrumentCalendarController
   * @memberOf ecapp
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.ICalendarService} btCalendarService
   */
  function btInstrumentCalendarController($rootScope, $interval, btCalendarService) {
    /*jshint validthis: true*/
    var vm = this;

    vm.btInstrumentSymbol = vm.btInstrumentSymbol + ':OANDA' || '';
    vm.btInstrumentName = vm.btInstrumentName || '';

    vm.meta = vm.meta || {};
    /** @type {bt.ReleaseObject[]} */
    vm.items = vm.items || [];
    vm.templates = {
      empty: 'components/mobile/bt-calendar/templates/bt-instrument-calendar-empty.html',
      head: 'components/mobile/bt-calendar/templates/bt-instrument-calendar-head.html',
      card: 'components/mobile/bt-calendar/templates/bt-instrument-calendar-card.html',
      end: 'components/mobile/bt-calendar/templates/bt-instrument-calendar-end.html',
      separator: 'components/mobile/bt-calendar/templates/bt-instrument-calendar-separator.html',
    };

    vm.isLoading = true;
    vm.hasError = false;
    vm.errorMessage = 'Unknown error';

    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;

    var gIntervalPromise = null;

    $rootScope.$on('calendar:new-release', onNewRelease);

    /**
     * Initialize component
     */
    function onInit() {
      prepareCalendar();
    }

    /**
     * Destroy component
     */
    function onDestroy() {
      stopAutoUpdate();
    }

    /**
     * Start auto update
     * @param {Function} callback - callback function
     * @param {Number} interval - interval in seconds
     */
    function startAutoUpdate(callback, interval) {
      if (gIntervalPromise === null) {
        gIntervalPromise = $interval(callback, interval);
      }
    }

    /**
     * Stop auto update
     */
    function stopAutoUpdate() {
      if (gIntervalPromise !== null) {
        if ($interval.cancel(gIntervalPromise)) {
          gIntervalPromise = null;
        }
      }
    }

    /**
     * Auto update callback
     */
    function autoUpdateCallback() {
      vm.items.forEach(function (t) {
        t.updateInterval();
      });
      if (gDebug) console.log('calendar for ' + vm.btInstrumentName + ' was updated at', new Date());
    }

    /**
     * Callback on new release
     * @param {Object} event - ionic event object
     * @param {btRelease} release - release object
     */
    function onNewRelease(event, release) {
      void event;
      updateItems(release);
    }

    /**
     * Update items
     * @param {btRelease} release - release object
     */
    function updateItems(release) {
      vm.items.filter(sameId).map(update);

      /**
       * Has same id
       * @param {bt.ReleaseObject} item - item
       * @return {Boolean} has same id
       */
      function sameId(item) {
        return item.id === release.id;
      }

      /**
       * Update release object
       * @param {*} item
       */
      function update(item) {
        item.updateFromReleaseObject(release);
        item.filterTradeIdeasByInstrument(vm.btInstrumentName);
      }
    }

    /**
     * Prepare calendar items
     */
    function prepareCalendar() {
      if (vm.btInstrumentName === '') {
        vm.errorMessage = "Instrument isn't selected";
        vm.hasError = true;
        vm.isLoading = false;
        return;
      }

      vm.meta = {
        symbol: vm.btInstrumentDisplayName,
      };

      btCalendarService
        .getInstrumentCalendar(vm.btInstrumentSymbol)
        .then(function (promiseValue) {
          vm.items = promiseValue;
          vm.hasError = false;
          vm.isLoading = false;
          startAutoUpdate(autoUpdateCallback, 30 * 1000);
        })
        .catch(function (reason) {
          console.error(reason);
          stopAutoUpdate();
          vm.errorMessage = reason.message || 'Unknown error';
          vm.hasError = true;
          vm.isLoading = false;
        });
    }
  }
})();
