/**
 * Created by Sergey Panpurin on 9/14/2017.
 */

// @ts-check
(function btOpenPopupClosure() {
  'use strict';

  var gDebug = false;

  angular
    .module('btUtils')
    /**
     * @ngdoc service
     * @name btOpenPopupService
     * @memberOf btUtils
     * @description
     *  Help to open popup window
     */
    .factory('btOpenPopupService', ['$q', '$interval', 'btSettings', btOpenPopupService]);

  /**
   *
   * @param {angular.IQService} $q - promise interface
   * @param {angular.IIntervalService} $interval - ?
   * @param {ecapp.ISettings} btSettings - ?
   * @return {ecapp.IOpenPopupService}
   */
  function btOpenPopupService($q, $interval, btSettings) {
    var windowReference = null;
    var pollTimer = null;

    /**
     * Web version of login. Open pop-up window for login. Wait for result.
     *
     * @param {string} url - ?
     * @return {angular.IPromise.<Object>} - return authorization parameters
     */
    function loginWeb(url) {
      var deferred = $q.defer();
      closePreviousWindow();

      windowReference = openNewWindow(url);

      console.log('Pop-up window reference:', windowReference);

      if (windowReference !== null && windowReference !== undefined) {
        waitForWeb(deferred);
      } else {
        deferred.reject(new Error('Error with pop-up login window.'));
        console.error('Error with pop-up login window.');
      }

      return deferred.promise;
    }

    /**
     * Mobile version of login. Open in app browser. Listen for load stop event.
     *
     * @param {string} url - ?
     * @return {angular.IPromise.<Object>} - return authorization parameters
     */
    function loginMobile(url) {
      var deferred = $q.defer();

      windowReference = openNewWindow(url);

      console.log('Pop-up window reference:', windowReference);

      if (windowReference !== null && windowReference !== undefined) {
        waitForMobile(deferred);
      } else {
        deferred.reject(new Error('Error with pop-up login window.'));
        console.error('Error with pop-up login window.');
      }

      return deferred.promise;
    }

    /**
     * Close previous pop-up if it's necessary
     */
    function closePreviousWindow() {
      if (windowReference !== null && windowReference !== undefined) {
        $interval.cancel(pollTimer);
        pollTimer = null;
        windowReference.close();
        windowReference = null;
      }
    }

    /**
     * Just open new window.
     *
     * @param {string} url - ?
     * @return {Object} - window object of pop-up window or in app browser object
     */
    function openNewWindow(url) {
      return window.open(url, 'Login', 'width=600,height=700,resizable,scrollbars=yes,status=1,location=yes');
    }

    /**
     * Wait for login end on web. Check is window closed every 200 ms.
     * @param {Object} deferred - deferred object to resolve results
     */
    function waitForWeb(deferred) {
      var popupLocation = null;

      pollTimer = $interval(openCallback, 200, 0, false);

      /**
       *
       */
      function openCallback() {
        if (gDebug) console.log('Interval was called (openCallback)');
        popupLocation = getPopUpLocation();

        if (windowReference.closed !== false) {
          // !== is required for compatibility with Opera
          $interval.cancel(pollTimer);

          if (popupLocation !== null && popupLocation.search) {
            var query = popupLocation.search.substring(1);
            var params = parseQueryString(query);
            deferred.resolve(params);
          } else {
            deferred.reject(new Error('Terminated by user'));
          }
        }
      }
    }

    /**
     * Wait for login end on mobile. Listen for load stop event.
     * @param {Object} deferred - deferred object to resolve results
     */
    function waitForMobile(deferred) {
      var resolved = false;

      windowReference.addEventListener('exit', onMobileLoad);
      windowReference.addEventListener('loaderror', onMobileLoad);
      windowReference.addEventListener('loadstart', onMobileLoad);
      windowReference.addEventListener('loadstop', onMobileLoad);

      /**
       *
       * @param {*} event
       */
      function onMobileLoad(event) {
        console.log('TradeStationAPI Service: in app browser params', event);
        if (event.type === 'exit' && !resolved) {
          resolved = true;
          deferred.reject(new Error('Terminated by user'));
        }

        if (event.type === 'loadstop') {
          if (event.url.indexOf(btSettings.BT_BACKEND_URL) === 0) {
            resolved = true;
            windowReference.close();
            windowReference = null;

            var query = event.url.split('?')[1];
            var params = parseQueryString(query);
            deferred.resolve(params);
          }
        }
      }
    }

    /**
     * Get location of pop-up window. Cross origin policy deny access to another domain some we will not have error
     * only on a same domain.
     * @return {Object} - window location object
     */
    function getPopUpLocation() {
      try {
        console.log('Is pop-up closed:', windowReference.closed, 'Current url:', windowReference.location.href);
        if (windowReference.location.host === location.host) {
          windowReference.close();
        }

        return windowReference.location;
      } catch (error) {
        return null;
      }
    }

    /**
     * Parse url parameters to Object
     * @param {String} query - part of url after ? sign
     * @return {Object}
     */
    function parseQueryString(query) {
      var vars = query.split('&');
      var query_string = {};
      for (var i = 0; i < vars.length; i++) {
        var key = vars[i].split('=')[0];
        var value = vars[i].split(key + '=')[1];
        // If first entry with this name
        if (typeof query_string[key] === 'undefined') {
          query_string[key] = decodeURIComponent(value);
          // If second entry with this name
        } else if (typeof query_string[key] === 'string') {
          query_string[key] = [query_string[key], decodeURIComponent(value)];
          // If third or later entry with this name
        } else {
          query_string[key].push(decodeURIComponent(value));
        }
      }
      return query_string;
    }

    /**
     *
     * @param {*} url - ?
     * @return {*}
     */
    function openPopup(url) {
      if (window.isMobile) {
        return loginMobile(url);
      } else {
        return loginWeb(url);
      }
    }

    return {
      openPopup: openPopup,
    };
  }
})();
