/**
 * Created by Eyal on 9/12/2016.
 */

// @ts-check
(function btTimeSupervisionServiceClosure() {
  'use strict';

  angular
    .module('btUtils')
    /**
     * @ngdoc service
     * @name btTimeSupervisionService
     * @memberOf btUtils
     * @description
     *  This factory is used in Scroll-To process too.
     * @function
     * @param {function} functionToRun - function
     * @param {*} functionArgs - arguments
     * @param {string} functionName - name
     * @param {number} minSupervisedTimeInMinutes - time range in minutes
     * @example
     *
     * btTimeSupervisionService(btAudioService.playIncomingInsight, null, 'PLAY_INSIGHT', 0.5);
     */
    .factory('btTimeSupervisionService', btTimeSupervisionService);

  btTimeSupervisionService.$inject = ['btDateService'];

  /**
   *
   * @param {ecapp.IDateService} btDateService - ?
   * @return {ecapp.ITimeSupervisionService}
   */
  function btTimeSupervisionService(btDateService) {
    //request history structure:
    /*
     {
       functionName:
       [request, request, request],
       functionName:
       [request, request, request]
     }
     */
    var requestsHistory = {};

    return runFunction;

    /**
     * @private
     * @param {string} functionName
     */
    function pushToHistoryArray(functionName) {
      if (angular.isUndefined(requestsHistory[functionName])) {
        requestsHistory[functionName] = [];
      }

      requestsHistory[functionName].push(new Date());
    }

    /**
     * @private
     * @param {string} functionName
     * @return {null|*}
     */
    function getLastFunctionCall(functionName) {
      if (!angular.isUndefined(requestsHistory[functionName])) {
        var calls = requestsHistory[functionName];

        //because the last call is always located in the end of the array, this will return the date of the last time that this function was called.
        return calls[calls.length - 1];
      }
      return null;
    }

    /**
     * This function calls function just once in specified time range.
     *
     * @param {function} functionToRun - function
     * @param {*} functionArgs - arguments
     * @param {string} functionName - name
     * @param {number} minSupervisedTimeInMinutes - time range in minutes
     */
    function runFunction(functionToRun, functionArgs, functionName, minSupervisedTimeInMinutes) {
      if (typeof functionToRun === 'function') {
        var lastCall = getLastFunctionCall(functionName);

        if (null != lastCall) var difference = btDateService.getDifferenceInMinutes(new Date(), lastCall);

        if (difference >= minSupervisedTimeInMinutes || null == lastCall) {
          if (null != functionArgs) {
            //calling the function that way will transfer the array functionArgs to the arguments of functionToRun
            functionToRun.apply(this, functionArgs);
          } else {
            // if function args is null, we assume that there are no parameters and the call can be simply like that:
            functionToRun();
          }

          pushToHistoryArray(functionName);
        }
      }
    }
  }
})();
