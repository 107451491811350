/**
 * Created by Orly on 16/01/2019.
 */

// @ts-check
(function btPopoverServiceClosure() {
  'use strict';

  var gDebug = false;

  angular.module('ecapp').factory('btPopoverService', btPopoverService);

  btPopoverService.$inject = ['$ionicBody', '$compile', '$timeout'];

  /**
   * @memberOf ecapp
   * @ngdoc service
   * @name btPopoverService
   * @description
   *  This factory creates popovers
   * @param {ionic.IBodyService} $ionicBody
   * @param {angular.ICompileService} $compile
   * @param {angular.ITimeoutService} $timeout
   * @return {ecapp.IPopoverService}
   */
  function btPopoverService($ionicBody, $compile, $timeout) {
    /**
     * @typedef {Object} btPopoverObject
     * @property {btPopoverParameters} params - popover parameters
     * @property {Boolean} opened - popover is opened
     * @property {Boolean} interactive - popover is interactive
     * @property {Boolean} droppable - popover is droppable
     * @property {Boolean} isOpening - popover is opening right now
     * @property {*} el - html element
     * @property {*} popoverEl - jQuery element
     * @property {function} show - show popover
     * @property {function} hide - hide popover
     * @property {function} remove - remove popover
     * @property {?function} backdrop - drop popover
     * @property {function} isShown - get popover open status
     */

    /**
     * This is BTPopover constructor
     * @param {*} el_ - html element
     * @param {Boolean} interactive_ - popover is interactive
     * @param {Boolean} droppable_ - popover is droppable
     * @param {*} popoverEl_ - jQuery element
     * @param {Boolean} opened_ - popover is opened
     * @class
     */
    function BTPopover(el_, interactive_, droppable_, popoverEl_, opened_) {
      /**
       * HTML element
       * @name BTPopover#el
       * @type {HTMLElement}
       */
      this.el = el_;
      this.interactive = interactive_;
      this.droppable = droppable_;
      this.popoverEl = popoverEl_;
      this.opened = opened_;
      this.backdrop = null;
      this.opened = false;
      this.isOpening = false;
    }

    /**
     * This function shows popover
     */
    BTPopover.prototype.show = function () {
      if (!this.opened) {
        this.opened = true;
        this.isOpening = true;
        this.el.classList.remove('hide');
        gStatus.count++;
      } else {
        this.hide();
      }
    };

    /**
     * This function hides popover
     */
    BTPopover.prototype.hide = function () {
      if (this.opened) {
        this.opened = false;
        this.el.classList.add('hide');
        gStatus.count--;
        if (gStatus.count < 0) gStatus.count = 0;
      }
    };

    /**
     * This function checks if popover is shown
     * @return {boolean}
     */
    BTPopover.prototype.isShown = function () {
      return this.opened;
    };

    /**
     * This function destroys popover
     */
    BTPopover.prototype.remove = function () {
      var popover = this;
      this.hide();

      if (popover.droppable) {
        gStatus.popovers.splice(gStatus.popovers.indexOf(popover), 1);
      }

      popover.el.remove(); //remove popover from DOM
    };

    /**
     * This function hides all opened droppable popovers when area outside of popover is clicked
     * @param {*} event
     */
    function backdrop(event) {
      if (gStatus.count) {
        $timeout(function () {
          gStatus.popovers.forEach(function (popover) {
            if (gDebug) console.log('btPopoverService: Global click listener was called for', event, popover);

            if (popover.opened) {
              if (popover.isOpening) {
                popover.isOpening = false;
              } else if (popover.popoverEl.has(event.target).length !== 1) {
                //popover is opened and clicked target is not within popover
                popover.hide();
              }
            }
          });
        });
      }
    }

    /**
     * @typedef {Object} btPopoverStatus
     * @property {Array} popovers - list of initiated popovers with backdrop
     * @property {Number} count - number of opened popovers
     */

    /**
     * Status of btPopoverService:
     *   list of initiated popovers with backdrop
     *   number of opened popovers
     * @type {btPopoverStatus}
     */
    var gStatus = {
      popovers: [],
      count: 0,
    };

    //adding listener for backdrop
    if (gDebug) console.log('btPopoverService: Add global click listener');
    document.addEventListener('click', backdrop);

    /**
     * @typedef {Object} btPopoverParameters
     * @property {Object} scope - scope for the template
     * @property {Boolean} [interactive] - ability to interact with page elements while popover's open
     * @property {Boolean} [backdrop] - ability to close popover by clicking outside of popover
     */

    /**
     * This function initializes popover with set template and parameters
     * @param {String} template - text of html template
     * @param {btPopoverParameters} params - parameters
     * @return {BTPopover}
     */

    /**
     *
     * @param {*} template
     * @param {*} params
     * @return {any}
     */
    function init(template, params) {
      /*
       * interactivity - ability to interact with page elements while popover's open
       * backdrop - ability to close popover by clicking outside of popover
       */

      if (gDebug) console.log('btPopoverService: Initialize new popover');

      // setting interactivity
      var interactive = params && params.interactive;
      var droppable = params && params.backdrop;

      var classes = (interactive ? ' interactive' : '') + (droppable ? ' droppable' : '');

      var element = $compile(
        '<div class="bt-popover-backdrop' +
          classes +
          ' hide">' +
          '<div class="bt-popover-widget"><div class="bt-popover-wrapper bt-dash-scroll-y">' +
          template +
          '</div></div></div>'
      )(params.scope);

      $ionicBody.append(element);
      var popover = new BTPopover(element[0], interactive, droppable, element.find('.bt-popover'), false);

      // adding back droppable popover to popover list
      if (droppable) {
        gStatus.popovers.unshift(popover);
        popover.backdrop = backdrop;
      }

      return popover;
    }

    /**
     * This function closes all opened droppable popovers
     */
    function closeAll() {
      gStatus.popovers.forEach(function (popover) {
        if (popover.opened) {
          popover.hide();
        }
      });
    }

    /**
     * This function return all status values of btPopoverService
     * @return {{popovers: Array, count: number}}
     */
    function getStatus() {
      return gStatus;
    }

    return {
      init: init,
      status: getStatus,
      closeAll: closeAll,
    };
  }
})();
