/**
 * Created by Orly on 20/02/2019.
 */

(function btOpenOrdersClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btOpenOrders:';

  angular.module('ecapp').directive('btOpenOrders', btOpenOrders);

  btOpenOrders.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btOpenOrders($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('directives/barometer/bt-open-orders-widget.html'),
      controller: btOpenOrdersController,
      controllerAs: 'vm',
    };
  }

  btOpenOrdersController.$inject = ['$scope', '$timeout', 'btTradingService', 'btToastrService', 'btSettingsService'];

  /**
   *
   * @param {*} $scope
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btOpenOrdersController($scope, $timeout, btTradingService, btToastrService, btSettingsService) {
    void $scope;
    console.log('Running btOpenOrdersController');

    /*jshint validthis: true*/
    var vm = this;

    var gSettings = btSettingsService.getLocalSettings();

    $scope.$on('broker:disconnected', onBrokerConnected);
    $scope.$on('broker:connected', onBrokerDisconnected);

    activate();

    /**
     * This function activates controller.
     */
    function activate() {
      if (gDebug) console.log(gPrefix, 'controller activation');
      vm.isLoading = true;
      vm.hasError = false;
      vm.errorMessage = '';
      vm.noBroker = false;
      vm.brokerName = '';
      vm.orders = [];

      // this value will be defined in template
      vm.filteredOrders = [];

      loadSettings();
      sortColumn(vm.orderByColumn, vm.orderByReverse ? -1 : 1);

      vm.cancelOrder = cancelOrder;
      vm.sortColumn = sortColumn;
      vm.hasOpenOrders = hasOpenOrders;
      vm.isOpenOrder = isOpenOrder;
      vm.reactivate = reactivate;

      loadOpenOrders();
    }

    /**
     * This function reactivates controller.
     */
    function reactivate() {
      vm.isLoading = true;
      vm.hasError = false;
      vm.errorMessage = '';
      vm.noBroker = false;
      vm.brokerName = '';
      vm.orders = [];

      loadOpenOrders();
    }

    /**
     * This function loads open orders.
     */
    function loadOpenOrders() {
      btTradingService
        .initialize()
        .then(function () {
          if (btTradingService.isDefaultBroker()) {
            // block for default broker
            vm.isLoading = false;
            vm.hasError = true;
            vm.errorMessage = 'Select your broker.';
            vm.noBroker = true;
            vm.brokerName = 'default';
            vm.orders = [];
          } else {
            vm.noBroker = false;
            return btTradingService.connect().then(function () {
              vm.isLoading = false;
              vm.hasError = false;
              vm.errorMessage = '';
              vm.noBroker = false;
              vm.brokerName = btTradingService.getBrokerName();
              vm.orders = btTradingService.getLastOrders();

              sortColumn('symbol', 1);
            });
          }
        })
        .catch(processError);
    }

    /**
     * This function processes errors.
     *
     * @param {Error} error - error object
     */
    function processError(error) {
      console.error(gPrefix, error);
      vm.isLoading = false;
      vm.hasError = true;
      vm.errorMessage = error.message || 'unknown';
    }

    /**
     * This function cancels order by identifier.
     *
     * @param {String} orderId - order identifier
     */
    function cancelOrder(orderId) {
      btTradingService.cancelOrder(orderId).catch(function (error) {
        console.error(gPrefix, error);
        btToastrService.error('Error: ' + (error.Message || error.message), 'Cancel Order', { type: 'trade' });
      });
    }

    /**
     * This function sorts table by column.
     *
     * @param {String} column - column name
     * @param {Number} order - sort order 1 or -1
     */
    function sortColumn(column, order) {
      if (order !== undefined) {
        vm.orderByReverse = order === -1;
      } else {
        vm.orderByReverse = vm.orderByColumn === column ? !vm.orderByReverse : false;
      }
      vm.orderByColumn = column;

      saveSettings();
    }

    /**
     * This function checks whether order is open.
     *
     * @param {btOrderObject} order - order object
     * @return {Boolean}
     */
    function isOpenOrder(order) {
      return order.status === 'Received' || order.status === 'Queued';
    }

    /**
     * This function checks whether user has open orders.
     *
     * @return {Boolean}
     */
    function hasOpenOrders() {
      return (
        vm.orders.filter(function (value) {
          return value.status === 'Received' || value.status === 'Queued';
        }).length > 0
      );
    }

    /**
     * This function reacts on broker connection.
     */
    function onBrokerConnected() {
      reactivate();
    }

    /**
     * This function reacts on broker disconnection.
     */
    function onBrokerDisconnected() {
      vm.isLoading = false;
      vm.hasError = true;
      vm.errorMessage = '';
      vm.noBroker = true;
      vm.brokerName = '';
      vm.orders = [];
    }

    /**
     * This function saves user settings.
     */
    function loadSettings() {
      vm.orderByReverse = gSettings.get('barometer.open-orders.order');
      vm.orderByReverse = vm.orderByReverse !== undefined ? vm.orderByReverse : false;
      vm.orderByColumn = gSettings.get('barometer.open-orders.column') || 'symbol';
    }

    /**
     * This function loads user settings.
     */
    function saveSettings() {
      gSettings.set('barometer.open-orders.order', vm.orderByReverse);
      gSettings.set('barometer.open-orders.column', vm.orderByColumn);
    }
  }
})();
