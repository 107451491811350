// @ts-check
(function btRssFeedServiceClosure() {
  'use strict';

  angular.module('btRss').provider('btRssFeed', function btRssFeedProvider() {
    var settings = {
      base: 'http://localhost:3000',
    };

    this.setUrlBase = function (url) {
      settings.base = url;
    };

    // noinspection JSUnusedGlobalSymbols
    this.$get = [
      '$http',
      'moment',
      function ($http, moment) {
        return btRssFeedService(settings, $http, moment);
      },
    ];
  });

  /**
   *
   * @param {*} settings - ?
   * @param {angular.IHttpService} $http - ?
   * @param {*} moment - ?
   * @return {ecapp.IRssFeedService}
   */
  function btRssFeedService(settings, $http, moment) {
    /**
     * @typedef {object} btRSSItem
     * @property {string} title - ?
     * @property {string} description - ?
     * @property {string} summary - ?
     * @property {string} date - ?
     * @property {string} pubdate - (not used)
     * @property {string} pubDate - (not used)
     * @property {string} link - ?
     * @property {string} guid - (not used)
     * @property {string} author - ?
     * @property {string} comments - ?
     * @property {string} origlink - (not used)
     * @property {object} image - ?
     * @property {object} source - ?
     * @property {string[]} categories - ?
     * @property {Array} enclosures - (not used)
     * @property {string} shortSummary - ?
     * @property {string} linkSource - ?
     * @property {string} dateTime - ?
     * @property {string} dateDays - ?
     * @property {string} dateAgo - ?
     */

    return {
      getData: getData,
      removeHTML: removeHTML,
      isNew: isNew,
      prepareNews: prepareNews,
      merge: merge,
      getNewest: getNewest,
    };

    /**
     * @ngdoc service
     * @name btRssFeed
     * @memberOf btRss
     * @description
     * This factory **.
     */

    /**
     * Get RSS feed
     *
     * @alias btRss.btRssFeed#getData
     * @param {number} maxResults - number of records
     * @return {angular.IPromise<btRSSItem[]>}
     */
    function getData(maxResults) {
      return $http.get(settings.base + '/rss-feeds?limit=' + maxResults);
    }

    /**
     * Remove unnecessary html from rss summary
     *
     * @alias btRss.btRssFeed#removeHTML
     * @param {string} rssSummary - rss summary
     * @return {string}
     */
    function removeHTML(rssSummary) {
      if (!rssSummary) {
        return '';
      }

      try {
        var parser = new DOMParser();
        var doc = parser.parseFromString(rssSummary, 'text/html');

        if (doc === null) {
          return '';
        }

        var body = doc.getElementsByTagName('body')[0];
        return body.childNodes[0].textContent;
      } catch (e) {
        console.error(e);
        return '';
      }
    }

    /**
     * Is new (5 minutes old)
     *
     * @alias btRss.btRssFeed#isNew
     * @param {number|string|Date} date -
     * @return {boolean}
     */
    function isNew(date) {
      return moment(date).diff(moment(new Date())) > -300000;
    }

    /**
     * Get source from link
     *
     * @param {string} link - link
     * @return {string}
     */
    function _getSourceFromLink(link) {
      var firstPeriod = link.indexOf('.');
      var secondPeriod = link.slice(firstPeriod + 1).indexOf('.');
      var title = link.slice(firstPeriod + 1, firstPeriod + secondPeriod + 1);
      if (title === 'ecb' || title === 'ca') return title.toUpperCase();
      return title.charAt(0).toUpperCase() + title.slice(1);
    }

    /**
     * This function merges rss items.
     *
     * @alias btRss.btRssFeed#merge
     * @param {btRSSItem[]} oldNews - list of old rss
     * @param {btRSSItem[]} freshNews - list of new rss
     * @return {btRSSItem[]} - merged list of rss
     */
    function merge(oldNews, freshNews) {
      var newest = getNewest(freshNews, oldNews);

      // add new items to
      newest.forEach(function (item) {
        oldNews.unshift(item);
      });

      return oldNews;
    }

    /**
     * This function returns newest items.
     *
     * @alias btRss.btRssFeed#getNewest
     * @param {btRSSItem[]} freshNews - list of new rss items
     * @param {btRSSItem[]} oldNews - list of old rss items
     * @return {btRSSItem[]}
     */
    function getNewest(freshNews, oldNews) {
      // find new items
      var skip = false;
      var newest = [];

      freshNews.forEach(function (item) {
        if (skip) return;

        var test = oldNews.find(function (elem) {
          return elem.guid === item.guid;
        });

        if (test === undefined && !skip) {
          newest.unshift(item);
        } else {
          skip = true;
        }
      });

      return newest;
    }

    /**
     * This function prepares list of rss items.
     *
     * @alias btRss.btRssFeed#prepareNews
     * @param {btRSSItem[]} items - list of rss items
     */
    function prepareNews(items) {
      items.forEach(function (item) {
        _prepareItem(item);
      });
    }

    /**
     * This function prepare rss item.
     *
     * @private
     * @param {btRSSItem} item - rss item
     */
    function _prepareItem(item) {
      item.shortSummary = removeHTML(item.summary);
      item.linkSource = _getSourceFromLink(item.link);

      item.dateTime = moment(item.date).format('h:mm:ssA');
      item.dateDays = moment(item.date).format('DD-MM-YYYY');
      item.dateAgo = _capitalizeFirstLetter(moment(item.date).fromNow());
    }

    /**
     * This function capitalize string.
     *
     * @private
     * @param {string} string
     * @return {string}
     */
    function _capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
})();
