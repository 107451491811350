/**
 * Created by Jonas Pickholtz on 09/04/2019.
 */

// @ts-check
(function $btRiskSettingsClosure() {
  'use strict';

  angular
    .module('ecapp')

    /**
     * @typedef {object} SettingsParamsObject
     * @property {boolean} enabled - filter is enabled
     */

    /**
     * @typedef {object} FiltersParamsObject
     * @property {string} style - custom css class
     * @property {boolean} enabled - filter is enabled
     * @property {string} [format] - data format (percentage)
     * @property {boolean} [collapsible] - filter could be collapsed
     * @property {boolean} [expanded] - filter is expanded
     */

    /**
     * @typedef {object} MaxFilterObject
     * @property {string} id - filter's id
     * @property {string} name - filter's name to display as label
     * @property {string} type - type of filter: toggle, multiple, radio-group, range, magnitude, list, title
     * @property {*} value - value of the filter: boolean, number, string or an object {min: {number}, max: {number}}
     * @property {string} [description] - description for the filter
     * @property {object} [range] -
     * @property {OptionObject[]} [options] - array of options
     * @property {FiltersParamsObject} [params] - additional parameters of the filter
     */

    /**
     * @typedef {object} SectionObject
     * @property {string} type - type of the setting section: regular or advanced
     * @property {MaxFilterObject[]} filters - filters within section
     */

    /**
     * @typedef {object} btSettingsObject
     * @property {boolean} wasChanged - settings was changed by user
     * @property {btSettingsBlock[]} blocks - list of settings blocked
     */

    /**
     * @typedef {object} btSettingsBlock
     * @property {string} id - identifier of the settings block
     * @property {string} name - name of the settings block
     * @property {string} type - type of the settings block: regular or toggle
     * @property {?string} description - description of the settings block
     * @property {SettingsParamsObject} params - params of the settings block
     * @property {SectionObject[]} sections - list of settings sections
     */

    /**
     * @ngdoc service
     * @name $btRiskSettings
     * @memberOf ecapp
     * @description
     * This factory is to manage users' risk basket, insight and interval settings and well as provide information on how
     * these filters and charts work.
     * Interaction with risk chart settings
     *
     *
     *
     * Interaction basket insights
     *
     *
     *
     * Filters
     *
     * Filter value null means that this filter doesn't work right now.
     * Range filter min or max value null means that this limit doesn't work right now ([null, null] = any value).
     *
     * Broadcast
     *  - trade-ideas-filters:updated
     * @return {*}
     */
    .factory('$btRiskSettings', service)
    .filter('btRiskInsightsThreshold', function filterFactoryMethod() {
      return function insightThresholdFilter(value) {
        if (value === 1) {
          return 'Notable';
        } else if (value === 2) {
          return 'Moderate';
        } else {
          return 'All';
        }
      };
    });

  service.$inject = [
    '$rootScope',
    '$q',
    '$filter',
    '$timeout',
    '$templateCache',
    '$ionicModal',
    'btShareScopeService',
    'btRestrictionService',
    '$btUserSettings',
    'btToastrService',
    'btRiskBasketService',
    'btToolsService',
    'btCodes',
  ];

  /**
   *
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.IQService} $q - promise interface
   * @param {angular.IFilterService} $filter
   * @param {angular.ITimeoutService} $timeout
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {ionic.IModalService} $ionicModal - ionic modal service
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IUserSettingsService} $btUserSettings
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IRiskBasketService} btRiskBasketService
   * @param {ecapp.IToolsService} btToolsService
   * @param {ecapp.ICodes} btCodes
   * @return {ecapp.IRiskSettingsService}
   */
  function service(
    $rootScope,
    $q,
    $filter,
    $timeout,
    $templateCache,
    $ionicModal,
    btShareScopeService,
    btRestrictionService,
    $btUserSettings,
    btToastrService,
    btRiskBasketService,
    btToolsService,
    btCodes
  ) {
    var gDebug = false;
    var gPrefix = '$btRiskSettings:';

    // var gFilterCache = {};
    var FEATURE = 'riskMonitor';

    /** @type {ecapp.IRiskUserSettings} */
    var gUserSettings;
    /** @type {ecapp.IRiskAppSettings} */
    var gAppSettings;

    var Block = {
      GENERAL: 'general',
      INSTRUMENTS: 'instruments',
      INSIGHTS: 'insights',
      NOTIFICATIONS: 'notifications',
    };

    var Section = {
      REGULAR: 0,
      ADVANCED: 1,
    };

    var Unit = {
      BASKET: 'basket',
      INTERVAL: 'interval',
      INSTRUMENTS: 'instruments',
      RATIO: 'ratio',
      CROSSING: 'crossing',
      MOVEMENT: 'movement',
      DAILY: 'daily',
      DIVERGENCE: 'divergence',
      VOICE: 'voice',
      TOAST: 'toast',
      PUSH: 'push',
    };

    var InsightMagnitude = {
      UNKNOWN: 0,
      NOTABLE: 1,
      MODERATE: 2,
      COMMON: 3,
    };

    var InsightMagnitudeStrings = ['Unknown', 'Notable', 'Moderate', 'Common'];

    var InsightThreshold = {
      NOTABLE: 1,
      MODERATE: 2,
      ALL: 3,
    };

    /** @type {ecapp.ICompressedSettingsObject} */
    var gDefaultUserSettings = {
      general: {
        enabled: true,
        basket: 'basic',
        interval: 'M5',
      },
      instruments: {
        enabled: true,
        instruments: ['SPX500_USD', 'XAU_USD'],
        'ratio-basket': 2,
        'ratio-SPX500_USD': 1,
        'ratio-USB10Y_USD': 1,
        'ratio-DE10YB_EUR': 1,
        'ratio-USD_JPY': 1,
        'ratio-EUR_CHF': 1,
        'ratio-AUD_USD': 1,
        'ratio-NZD_USD': 1,
        'ratio-USD_CAD': 1,
        'ratio-WTICO_USD': 1,
        'ratio-XAU_USD': 1,
        'ratio-XCU_USD': 1,
        'ratio-XAG_USD': 1,
      },
      insights: {
        enabled: true,
        crossing: true,
        movement: false,
        daily: false,
        divergence: true,
      },
      notifications: {
        enabled: true,
        voice: true,
        toast: true,
        push: false,
      },
    };

    /**
     * Modal window to show filters
     * @type {*|object}
     */
    var gDialogScope;

    return {
      loadAppSettings: loadAppSettings,
      getAppBaskets: getAppBaskets,
      getAppIntervals: getAppIntervals,
      getAppIndicators: getAppIndicators,

      loadUserSettings: loadUserSettings,
      getUserSettings: getUserSettings,
      saveUserSettings: saveUserSettings,
      getUserBasket: getUserBasket,
      setUserBasket: setUserBasket,
      getUserInterval: getUserInterval,
      setUserInterval: setUserInterval,
      openSettingsDialog: openSettingsDialog,

      isInsightSelected: isInsightSelected,
      getInsightMagnitude: getInsightMagnitude,
      getInsightImpact: getInsightImpact,
      getMagnitudeText: getMagnitudeText,

      hasVoice: hasVoice,
      hasToast: hasToast,
    };

    /**
     * @alias ecapp.$btRiskSettings#loadAppSettings
     * @return {angular.IPromise<ecapp.IRiskAppSettings>}
     */
    function loadAppSettings() {
      return btShareScopeService
        .wait()
        .then(function () {
          return btShareScopeService.getAppSettings('risk-on-off');
        })
        .then(function (settings) {
          gAppSettings = settings;
          return settings;
        });
    }

    /**
     *
     * @alias ecapp.$btRiskSettings#getAppIntervals
     * @return {any[]}
     */
    function getAppIntervals() {
      return gAppSettings ? gAppSettings.intervals : [];
    }

    /**
     *
     * @alias ecapp.$btRiskSettings#getAppIndicators
     * @return {any[]}
     */
    function getAppIndicators() {
      return gAppSettings ? gAppSettings.indicators : [];
    }

    /**
     *
     * @alias ecapp.$btRiskSettings#getAppIndicators
     * @return {any[]}
     */
    function getAppBaskets() {
      return gAppSettings ? gAppSettings.baskets : [];
    }

    /**
     * This function returns user's filters with saved values
     *
     * @alias ecapp.$btRiskSettings#loadUserSettings
     * @return {angular.IPromise<ecapp.ICompressedSettingsObject>}
     */
    function loadUserSettings() {
      return btShareScopeService.wait().then(function () {
        gUserSettings = btShareScopeService.getUserSettings(FEATURE, gDefaultUserSettings);
        return gUserSettings;
      });
    }

    /**
     * @alias ecapp.$btRiskSettings#getUserSettings
     * @return {ecapp.ICompressedSettingsObject}
     */
    function getUserSettings() {
      if (!gUserSettings) {
        console.error('User settings was not defined');
        alert('Error: User settings was not defined.');
        return gDefaultUserSettings;
      } else {
        return gUserSettings;
      }
    }

    /**
     * @alias ecapp.$btRiskSettings#saveUserSettings
     * @return {any}
     */
    function saveUserSettings() {
      var userSettings = getUserSettings();
      return btShareScopeService
        .wait()
        .then(function () {
          return btShareScopeService.updateNotificationSettings(
            btCodes.Notification.RiskMonitor.Insight,
            userSettings.notifications.push
          );
        })
        .then(function () {
          return btShareScopeService.saveUserSettings(FEATURE, getUserSettings());
        })
        .then(function (value) {
          if (gDebug) console.log(gPrefix, 'User settings was saved');
          return value;
        });
    }

    /**
     * @alias ecapp.$btRiskSettings#setUserBasket
     * @param {*} value
     */
    function setUserBasket(value) {
      $btUserSettings.setUserSettingsValue(getUserSettings(), Block.GENERAL, Unit.BASKET, value);
    }

    /**
     * @alias ecapp.$btRiskSettings#getUserBasket
     * @return {*}
     */
    function getUserBasket() {
      return $btUserSettings.getUserSettingsValue(getUserSettings(), Block.GENERAL, Unit.BASKET);
    }

    /**
     * @alias ecapp.$btRiskSettings#setUserInterval
     * @param {*} value
     */
    function setUserInterval(value) {
      $btUserSettings.setUserSettingsValue(getUserSettings(), Block.GENERAL, Unit.INTERVAL, value);
    }

    /**
     * @alias ecapp.$btRiskSettings#getUserInterval
     * @return {*}
     */
    function getUserInterval() {
      return $btUserSettings.getUserSettingsValue(getUserSettings(), Block.GENERAL, Unit.INTERVAL);
    }

    /**
     * This function returns default trade ideas settings according to user settings.
     *
     * @param {ecapp.IRiskBasket[]} baskets - list of baskets
     * @return {ecapp.ISettingsObject}
     */
    function getConfigTemplate(baskets) {
      var instruments = getBasketInstrumentOptions(
        'extended',
        getUserSettingsValue(Block.INSTRUMENTS, Unit.INSTRUMENTS, [])
      );

      var basketOptions = gAppSettings.baskets.map(function (basket) {
        return {
          value: basket.id,
          name: basket.name,
          details: basket.desc,
        };
      });

      var intervalOptions = gAppSettings.intervals.map(function (interval) {
        return {
          value: interval.id,
          name: interval.name,
          details: interval.desc,
        };
      });

      var insights = gAppSettings.insights.map(function (insight) {
        return {
          id: insight.id,
          name: insight.name,
          type: 'toggle',
          title: insight.example,
          description: '',
          params: { style: '', enabled: true },
          value: false,
        };
      });

      return {
        wasChanged: false,
        blocks: [
          {
            id: Block.GENERAL,
            name: 'General Settings',
            type: 'regular',
            title: '',
            description: false,
            params: { enabled: true },
            sections: [
              {
                type: 'regular',
                filters: [
                  {
                    id: Unit.BASKET,
                    name: 'Select Risk Basket',
                    type: 'drop-down',
                    title: '',
                    description:
                      'Risk Baskets are hand-picked collections of asset classes key market indicators. ' +
                      'Select a Risk Basket that best reflects your trading values. We recommend that beginners start ' +
                      "with the 'Basic' basket while more experienced traders explore the 'Extended' basket.",
                    params: { style: '', enabled: true, detailed: true },
                    value: basketOptions[0].value,
                    options: basketOptions,
                  },
                  {
                    id: Unit.INTERVAL,
                    name: 'Select Interval',
                    type: 'drop-down',
                    title: '',
                    description:
                      'Select an interval based on your trading style. Display Risk Indicator and Basket ' +
                      'chart candlesticks based on hourly, daily, or weekly movement',
                    params: { style: '', enabled: true, detailed: true },
                    value: intervalOptions[0].value,
                    options: intervalOptions,
                  },
                ],
              },
            ],
          },
          {
            id: Block.INSTRUMENTS,
            name: 'Instruments Settings',
            type: 'regular',
            title: '',
            description: false,
            params: { enabled: true },
            sections: [
              {
                type: 'regular',
                filters: [].concat(
                  [
                    {
                      id: Unit.INSTRUMENTS,
                      name: 'Instruments',
                      type: 'multiple',
                      title: '',
                      description:
                        'Select instruments from the current basket that you would like to receive ' +
                        'insights for.<br><br> Adjust the frequency and sensitivity of the insights for the ' +
                        'instruments you are interested in.',
                      params: { style: '', collapsible: false, enabled: true },
                      options: instruments,
                    },
                  ],
                  getInstrumentRatio(instruments)
                ),
              },
            ],
          },
          {
            id: Block.INSIGHTS,
            name: 'Risk Insights Settings',
            type: 'regular',
            title: '',
            description: 'Select the type of insights you would like to get.',
            params: { enabled: true },
            sections: [
              {
                type: 'regular',
                filters: insights,
              },
            ],
          },
          {
            id: Block.NOTIFICATIONS,
            name: 'Notification Settings',
            type: 'regular',
            title: '',
            description:
              'By default you will see new insights on the Risk-On/Risk-Off page and in the ' +
              'Activity Feed. You can add voice, in-app and push notifications.',
            params: { enabled: true },
            sections: [
              {
                type: 'regular',
                filters: [
                  {
                    id: Unit.VOICE,
                    name: 'Voice Notifications',
                    type: 'toggle',
                    title: 'You will hear voice notifications.',
                    description: '',
                    params: { style: '', enabled: true },
                    value: false,
                  },
                  {
                    id: Unit.TOAST,
                    name: 'In-App Notifications',
                    type: 'toggle',
                    title: 'You will see in-app toast notifications.',
                    description: '',
                    params: { style: '', enabled: true },
                    value: false,
                  },
                  {
                    id: Unit.PUSH,
                    name: 'Push Notifications',
                    type: 'toggle',
                    title: 'You will receive push notifications on your phone.',
                    description: '',
                    params: { style: '', enabled: true },
                    value: false,
                  },
                ],
              },
            ],
          },
        ],
      };
    }

    /**
     *
     * @param {string} basketId - basket identifier
     * @param {string[]} selected - instruments selected by user
     * @return {ecapp.IWatchedInstrumentObject[]}
     */
    function getBasketInstrumentOptions(basketId, selected) {
      var baskets = btRiskBasketService.getBaskets();

      var instruments =
        baskets
          .filter(function (basket) {
            return basket.id === basketId;
          })
          .map(function (basket) {
            return basket.indicators.map(function (indicator) {
              return indicator.instrument;
            });
          })[0] || [];

      return getInstrumentOptions(instruments, selected);
    }

    /**
     * This function ...
     *
     * @param {ecapp.IWatchedInstrumentObject[]} instruments
     * @return {ecapp.IMaxFilterObject[]}
     */
    function getInstrumentRatio(instruments) {
      return [].concat(
        [
          createThresholdSlider(
            'basket',
            'Risk Index',
            'Adjust the frequency and sensitivity of the Risk Index insights.',
            true
          ),
        ],
        instruments.map(function (value) {
          return createThresholdSlider(
            value.value,
            value.name,
            'Adjust the frequency and sensitivity of the ' + value.name + ' insights.',
            value.selected
          );
        })
      );
    }

    /**
     *
     * @param {string} suffix -
     * @param {string} name -
     * @param {string} desc -
     * @param {boolean} selected -
     * @return {ecapp.IMaxFilterObject}
     */
    function createThresholdSlider(suffix, name, desc, selected) {
      return {
        id: getRatioId(suffix),
        name: name,
        type: 'slider',
        title: '',
        description: desc,
        params: {
          style: '',
          format: 'btRiskInsightsThreshold',
          enabled: selected,
          limits: { min: true, max: false },
        },
        value: 3,
        range: { min: InsightThreshold.NOTABLE, max: InsightThreshold.ALL, step: 1 },
      };
    }

    /**
     *
     * @param {string} suffix - instrument symbol or 'basket'
     * @return {string}
     */
    function getRatioId(suffix) {
      return Unit.RATIO + '-' + suffix;
    }

    /**
     * This functions returns watched instruments.
     *
     * @param {ecapp.ITradingInstrument[]} instruments - list of instruments
     * @param {string[]} selected - instruments selected by user
     * @return {ecapp.IWatchedInstrumentObject[]}
     */
    function getInstrumentOptions(instruments, selected) {
      var options = [];
      instruments.forEach(function (i) {
        options.push({ value: i.OandaSymbol, name: i.displayName, selected: selected.indexOf(i.OandaSymbol) !== -1 });
      });
      return options;
    }

    /**
     * This function opens settings dialog.
     *
     * @alias ecapp.$btRiskSettings#openSettingsDialog
     * @return {angular.IPromise}
     */
    function openSettingsDialog() {
      var deferred = $q.defer();

      if (!gDialogScope) {
        gDialogScope = $rootScope.$new(true);
        gDialogScope.modal = $ionicModal.fromTemplate($templateCache.get('modals/bt-basket-settings-modal.html'), {
          scope: gDialogScope,
          animation: 'slide-in-up',
        });
        gDialogScope.$on('modal.hidden', onSettingsDialogHidden);
        gDialogScope.onConfigChange = onConfigChange;
        gDialogScope.onConfigReset = onConfigReset;
        gDialogScope.count = 0;
      }

      gDialogScope.deferred = deferred;

      gDialogScope.config = null;
      $timeout(function () {
        customizeConfig(getUserSettings());
      }, 2000);

      gDialogScope.modal.show();

      return deferred.promise;
    }

    /**
     *
     * @param {ecapp.ICompressedSettingsObject} userSettings
     */
    function customizeConfig(userSettings) {
      var baskets = btRiskBasketService.getBaskets();
      gDialogScope.config = $btUserSettings.decompressSettings(getConfigTemplate(baskets), userSettings);
      var block = $btUserSettings.getBlock(gDialogScope.config, Block.GENERAL);
      var section = $btUserSettings.getSection(gDialogScope.config, Block.GENERAL, Section.REGULAR);
      var unit = $btUserSettings.getUnit(gDialogScope.config, Block.GENERAL, Section.REGULAR, Unit.BASKET);
      syncConfig(userSettings, block, section, unit);
    }

    /**
     * This function handles closing of settings dialog. If settings was changed they will be saved.
     */
    function onSettingsDialogHidden() {
      if (!gDialogScope.config) {
        btToastrService.info('Risk-On/Risk-Off settings was not changed.', 'Settings');
        gDialogScope.deferred.resolve(false);
        return;
      }

      var newSettings = $btUserSettings.compressSettings(gDialogScope.config);

      if (!$btUserSettings.isEqualUserSettings(getUserSettings(), newSettings)) {
        gUserSettings = $btUserSettings.compressSettings(gDialogScope.config);
        $btUserSettings
          .saveUserSettings(FEATURE, getUserSettings())
          .then(function () {
            btToastrService.success(
              'Risk-On/Risk-Off settings has been saved and synced with mobile & in-app notification.',
              'Settings'
            );
            gDialogScope.deferred.resolve(true);
          })
          .catch(function (reason) {
            btToastrService.error(reason.message || 'There was error while saving settings', 'Settings');
            gDialogScope.deferred.reject(reason);
          });
      } else {
        btToastrService.info('Risk-On/Risk-Off settings was not changed.', 'Settings');
        gDialogScope.deferred.resolve(false);
      }
    }

    /**
     *
     */
    function onConfigReset() {
      gDialogScope.config = null;
      $timeout(function () {
        customizeConfig(gDefaultUserSettings);
      }, 200);
    }

    /**
     *
     * @param {*} block
     * @param {*} iSection
     * @param {*} iUnit
     */
    function updateInsightThresholds(block, iSection, iUnit) {
      void block;

      var selected = iUnit.options
        .filter(function (instrument) {
          return instrument.selected;
        })
        .map(function (instrument) {
          return getRatioId(instrument.value);
        });

      iSection.filters.forEach(function (rUnit) {
        rUnit.params.enabled =
          rUnit.id === Unit.INSTRUMENTS || rUnit.id === getRatioId(Unit.BASKET)
            ? true
            : selected.indexOf(rUnit.id) !== -1;
      });
    }

    /**
     * This function monitors configuration changes made by user and customize configuration based on these changes.
     *
     * @param {ecapp.ICompressedSettingsObject} userSettings -
     * @param {ecapp.ISettingsBlock} block - the settings block object
     * @param {ecapp.ISectionObject} section - the settings section object
     * @param {ecapp.IMaxFilterObject} unit - the settings unit
     */
    function syncConfig(userSettings, block, section, unit) {
      if (unit.id === Unit.BASKET) {
        var instruments = $btUserSettings.getUnit(
          gDialogScope.config,
          Block.INSTRUMENTS,
          Section.REGULAR,
          Unit.INSTRUMENTS
        );
        instruments.options = getBasketInstrumentOptions(unit.value, userSettings[Block.INSTRUMENTS][Unit.INSTRUMENTS]);
        var iBlock = $btUserSettings.getBlock(gDialogScope.config, Block.INSTRUMENTS);
        var iSection = $btUserSettings.getSection(gDialogScope.config, Block.INSTRUMENTS, Section.REGULAR);
        var iUnit = $btUserSettings.getUnit(gDialogScope.config, Block.INSTRUMENTS, Section.REGULAR, Unit.INSTRUMENTS);
        updateInsightThresholds(iBlock, iSection, iUnit);
      }

      if (unit.id === Unit.INSTRUMENTS) {
        updateInsightThresholds(block, section, unit);
      }
    }

    /**
     * This function monitors configuration changes made by user and customize configuration based on these changes.
     *
     * @param {ecapp.ISettingsBlock} block - the settings block object
     * @param {ecapp.ISectionObject} section - the settings section object
     * @param {ecapp.IMaxFilterObject} unit - the settings unit
     */
    function onConfigChange(block, section, unit) {
      syncConfig(getUserSettings(), block, section, unit);
    }

    /**
     *
     * @alias ecapp.$btRiskSettings#isInsightSelected
     * @param {ecapp.IRiskInsight} insight - the risk insight object
     * @return {boolean}
     */
    function isInsightSelected(insight) {
      if (insight.indicator) {
        return (
          !!getUserSettingsValue(Block.INSIGHTS, insight.id, false) &&
          getUserSettingsValue(Block.INSTRUMENTS, Unit.INSTRUMENTS, []).indexOf(insight.indicator.oanda) !== -1 &&
          insight.magnitude.value <= getUserSettingsValue(Block.INSTRUMENTS, getRatioId(insight.indicator.oanda), 0)
        );
      } else {
        return (
          !!getUserSettingsValue(Block.INSIGHTS, insight.id, false) &&
          insight.magnitude.value <= getUserSettingsValue(Block.INSTRUMENTS, getRatioId(Unit.BASKET), 0)
        );
      }
    }

    /**
     *
     * @alias ecapp.$btRiskSettings#getInsightMagnitude
     * @param {ecapp.IRiskInsight} insight - the risk insight object
     * @return {number}
     */
    function getInsightMagnitude(insight) {
      if (!gAppSettings || !gAppSettings.insights) {
        console.error('Invalid app settings.', gAppSettings);
        return InsightMagnitude.UNKNOWN;
      } else {
        var thresholds = getInsightThresholds(insight);

        // Last threshold
        if (thresholds[thresholds.length - 1] && insight.level >= thresholds[thresholds.length - 1]) {
          return InsightMagnitude.NOTABLE;
        }

        // Next to the last threshold
        if (thresholds[thresholds.length - 2] && insight.level >= thresholds[thresholds.length - 2]) {
          return InsightMagnitude.MODERATE;
        }

        return InsightMagnitude.COMMON;
      }
    }

    /**
     *
     * @alias ecapp.$btRiskSettings#getInsightImpact
     * @param {number} mgn - the risk insight magnitude
     * @return {number}
     */
    function getInsightImpact(mgn) {
      return mgn ? 4 - mgn : 0;
    }

    /**
     *
     * @alias ecapp.$btRiskSettings#getMagnitudeText
     * @param {number} mgn - insight magnitude
     * @return {string}
     */
    function getMagnitudeText(mgn) {
      return InsightMagnitudeStrings[mgn] || InsightMagnitudeStrings[InsightMagnitude.UNKNOWN];
    }

    /**
     *
     * @param {ecapp.IRiskInsight} insight
     * @return {number[]}
     */
    function getInsightThresholds(insight) {
      var result = [];

      gAppSettings.insights.forEach(function (item) {
        if (item.id === insight.id) {
          if (insight.period) {
            item.levels.forEach(function (level) {
              if (level.interval === insight.period) {
                result = level.thresholds;
              }
            });
          } else {
            result = item.levels[0].thresholds;
          }
        }
      });

      return result;
    }

    /**
     * @alias ecapp.$btRiskSettings#hasVoice
     * @return {boolean}
     */
    function hasVoice() {
      return !!getUserSettingsValue(Block.NOTIFICATIONS, Unit.VOICE, false);
    }

    /**
     * @alias ecapp.$btRiskSettings#hasToast
     * @return {boolean}
     */
    function hasToast() {
      return !!getUserSettingsValue(Block.NOTIFICATIONS, Unit.TOAST, false);
    }

    /**
     * This function returns value of user settings.
     *
     * @param {string} block - block identifier
     * @param {string} unit - unit identifier
     * @param {*} def - default value
     * @return {*}
     */
    function getUserSettingsValue(block, unit, def) {
      var value = $btUserSettings.getUserSettingsValue(getUserSettings(), block, unit);
      return value === undefined ? def : value;
    }
  }
})();
