/**
 * Created by Sergey Panpurin on 7/9/2018.
 */

// @ts-check
(function btHistoryServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btHistoryService', btHistoryService);

  btHistoryService.$inject = [];

  /**
   * @ngdoc service
   * @name btHistoryService
   * @memberOf ecapp
   * @description
   *  Help to work with price
   * @return {ecapp.IHistoryService}
   */
  function btHistoryService() {
    console.log('Running btHistoryService');

    /**
     * @typedef {object} btHistoryObject
     * @property {number} capacity - ?
     * @property {object[]} items - ?
     */

    /** @type {Record<string, btHistoryObject>}*/
    var gCache = {};

    /** @type {btHistoryObject} */
    var gDefaultCache = {
      capacity: 100,
      items: [],
    };

    return {
      init: init,
      add: add,
      get: get,
      clear: clear,
      clearAll: clearAll,
    };

    /**
     * @private
     * @param {string} name
     * @return {*}
     */
    function getCache(name) {
      if (gCache[name] === undefined) {
        gCache[name] = angular.copy(gDefaultCache);
      }

      return gCache[name];
    }

    /**
     *
     * @alias ecapp.btHistoryService#init
     * @param {string} name -
     * @param {number} capacity -
     */
    function init(name, capacity) {
      var cache = getCache(name);
      cache.capacity = capacity;
    }

    /**
     *
     * @alias ecapp.btHistoryService#add
     * @param {string} name -
     * @param {object} data -
     */
    function add(name, data) {
      var cache = getCache(name);
      cache.items.push({ date: new Date(), data: data });

      if (cache.items.length > cache.capacity) {
        cache.items.shift();
      }
    }

    /**
     *
     * @alias ecapp.btHistoryService#get
     * @param {string} name -
     * @return {object[]}
     */
    function get(name) {
      var cache = getCache(name);
      return cache.items;
    }

    /**
     *
     * @alias ecapp.btHistoryService#clear
     * @param {string} name
     */
    function clear(name) {
      delete gCache[name];
    }

    /**
     * @alias ecapp.btHistoryService#clearAll
     */
    function clearAll() {
      Object.keys(gCache).forEach(function (key) {
        delete gCache[key];
      });
    }
  }
})();
