/**
 * Created by Sergey Panpurin on 7/30/2017.
 */

/*global ClientEventEmitter*/

// @ts-check
(function btEventEmitterServiceClosure() {
  'use strict';

  /**
   * @ngdoc service
   * @name btEventEmitterService
   * @memberOf btUtils
   * @description
   *  This wrapper for eventEmitter
   */

  angular.module('btUtils').factory('btEventEmitterService', btEventEmitterService);

  btEventEmitterService.$inject = [];

  /**
   *
   * @return {ecapp.IEventEmitterService}
   */
  function btEventEmitterService() {
    /**
     * @namespace window.EventEmitter
     * @property {Function} addListener        - add listener
     * @property {Function} removeListener     - remove listener
     * @property {Function} addOnceListener    - add one time listener
     * @property {Function} emitEvent          - emit event
     * @property {Function} setOnceReturnValue - set return value to terminate listener
     * @property {Function} getListeners       - get list of listeners
     * @property {Function} defineEvents       - define listeners
     */

    var ee = window.ClientEventEmitter ? new ClientEventEmitter() : null;
    if (ee === null) {
      console.error('EventEmitter is not installed!');
    }

    /**
     * @name btUtils.btEventEmitterService#addListener
     * @function
     */

    return {
      addListener: function (evt, listener) {
        if (ee && ee.addListener) {
          return ee.addListener(evt, listener);
        } else {
          console.error('EventEmitter.addListener is not defined!');
          return null;
        }
      },
      emitEvent: function (evt, args) {
        if (ee && ee.emitEvent) {
          return ee.emitEvent(evt, args);
        } else {
          console.error('EventEmitter.emitEvent is not defined!');
          return null;
        }
      },
    };
  }
})();
