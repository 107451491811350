/**
 * Created by Itay on 3/6/2016.
 */

(function btEventCardCircleTimerClosure() {
  'use strict';

  var gDebug = false;

  angular
    .module('ecapp')
    /**
     * This directive adds timer to the event circle, when required (when strength is not assigned in the event is in the future.
     *
     * @ngdoc directive
     * @name btEventCircleTimer
     * @memberOf ecapp
     */
    .directive('btEventCircleTimer', btEventCircleTimer)
    .controller('btEventCircleTimerController', btEventCircleTimerController);

  btEventCircleTimer.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {any}
   */
  function btEventCircleTimer($templateCache) {
    return {
      restrict: 'E',
      scope: {
        rowName: '@row',
      },
      template: $templateCache.get('directives/event-card/event-circle-timer.html'),
      controller: 'btEventCircleTimerController',
    };
  }

  btEventCircleTimerController.$inject = [
    '$scope',
    '$interval',
    'btDateService',
    'btAudioService',
    'btTimeSupervisionService',
    'btDelegateMethodsService',
  ];

  /**
   *
   * @param {*} $scope
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IAudioService} btAudioService
   * @param {ecapp.ITimeSupervisionService} btTimeSupervisionService
   * @param {ecapp.IDelegateMethodsService} btDelegateMethodsService
   */
  function btEventCircleTimerController(
    $scope,
    $interval,
    btDateService,
    btAudioService,
    btTimeSupervisionService,
    btDelegateMethodsService
  ) {
    $scope.row = $scope.$parent[$scope.rowName];

    var interval = null;
    var row_date = btDateService.getDateFromRow($scope.row);

    $scope.$on('$destroy', onDestroy);

    // This watch is adding listener to changes in $scope.row.class, so when the class will change, we will change the
    // $interval function
    $scope.disableWatchOnRow = $scope.$parent.$watch($scope.rowName + '.class', switchCountDownInterval);

    /**
     *
     */
    function onDestroy() {
      $interval.cancel(interval);
      console.log('Controller btEventCircleTimer was destroyed!');
    }

    /**
     * Manage countdown
     */
    function switchCountDownInterval() {
      switch ($scope.row.class) {
        case 'eventCardNextHot':
          // The reason that I'm both calling and then setting interval is in order to not display empty time in the
          // first second
          cancelInterval();
          updateScopeTimer(true);
          interval = $interval(updateScopeTimer, 1000);
          break;
        case 'eventCardNext':
          // When event card is next we are setting interval that is called every minute in order to update the event
          // card next.
          cancelInterval();
          getHumanisedTimeFromNow(true);
          interval = $interval(getHumanisedTimeFromNow, 60000);
          break;
        default:
          //eventCardPast
          // this is being called when nextHot is changed to something else, and then we want to remove the interval.
          cancelInterval();

          // this assigns the text time so it'll be shown
          $scope.convertTextTime = $scope.row.convertTextTime;

          // can be called if it's eventCardPast since after we get the value we don't need to watch the row anymore.
          if ($scope.row.class === 'eventCardPast') $scope.disableWatchOnRow();
          break;
      }
      $scope.class = $scope.row.class;
    }

    /**
     * This function exists in order to cancel an irrelevant interval (happens when $scope.row.class is changed.)
     */
    function cancelInterval() {
      if (interval !== null) {
        var res = $interval.cancel(interval);
        if (res) {
          interval = null;
        }
        console.log('Cancel interval', res);
      }
    }

    /**
     * This function is being called every second when the event type is next hot, and it updates the time value.
     */
    function updateScopeTimer() {
      if (gDebug) console.log('Interval was called (updateScopeTimer)');
      var diff = btDateService.getMinutesSecondFromNow(row_date, null);

      // noinspection JSUnresolvedFunction
      if (diff.seconds() === 10 && diff.minutes() === 0) {
        // Play sound for release in 10 seconds and scroll calendar to this position
        btTimeSupervisionService(btAudioService.playIn10SecSound, null, 'PLAY_AUDIO', 1);
        btTimeSupervisionService(btDelegateMethodsService.getDelegateMethod('SCROLL-TO-LATEST-ROW'), null, 'SCROLL', 1);
      }

      $scope.minutesSecondsFromNow = diff.format('mm:ss');
    }

    /**
     * This function is being called every minute when the event type is next, and it updates the time-text
     * (5 minutes -> 4 minutes)
     */
    function getHumanisedTimeFromNow() {
      if (gDebug) console.log('Interval was called (getHumanisedTimeFromNow)');
      $scope.timeHumanised = btDateService.getHumanisedTimeFromNow(row_date);
      if (gDebug) console.log('Interval 2 is being called.');
    }
  }
})();
