/**
 * Created by xxx on xxx.
 */

(function goToScrollClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive scroll calendar to new event
     *
     * @ngdoc directive
     * @name goToScroll
     * @memberOf ecapp
     */
    .directive('goToScroll', [
      function () {
        return {
          restrict: 'A',
          link: function ($scope, element) {
            element[0].addEventListener('click', function () {
              $scope.scrollToNext();
            });

            var spaceBarScroll = function (e) {
              if (e.code === 'Space' || e.key === ' ') {
                $scope.scrollToNext();
                return false;
              }
            };

            $scope.$on('$ionicView.loaded', function () {
              $('body').on('keypress', spaceBarScroll);
            });

            $scope.$on('$ionicView.enter', function () {
              $('body').on('keypress', spaceBarScroll);
            });

            $scope.$on('$ionicView.leave', function () {
              $('body').off('keypress', spaceBarScroll);
            });
          },
        };
      },
    ]);
})();
