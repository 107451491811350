/**
 * Created by Sergei Panpurin on 9/5/18.
 */

//@ts-check
(function btLatestTradeChartClosure() {
  'use strict';

  angular
    .module('ecapp')
    .directive('btLatestTradeChart', btLatestTradeChart)
    .controller('btLatestTradeChartController', btLatestTradeChartController);

  btLatestTradeChart.$inject = ['$templateCache'];

  /**
   * This directive displays list of trade ideas. User can select one of them.
   *
   * @ngdoc directive
   * @name btLatestTradeChart
   * @memberOf ecapp
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btLatestTradeChart($templateCache) {
    return {
      restrict: 'E',
      scope: {
        widgetId: '@',
        tradeInstrument: '<',
        currentChart: '<',
        tradeDelay: '<',
        manualTesting: '@',
        tradeIdea: '<',
        onOpenRealtime: '&',
      },
      template: $templateCache.get('components/mobile/bt-back-tester/bt-latest-trade-chart.html'),
      controller: 'btLatestTradeChartController',
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btLatestTradeChartController.$inject = ['$scope', 'btChartService', 'btTradingCardService'];

  /**
   *
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.IChartService} btChartService
   * @param {ecapp.ITradingCardService} btTradingCardService
   */
  function btLatestTradeChartController($scope, btChartService, btTradingCardService) {
    void $scope;

    /** @type {angular.IComponentController} */
    var vm = this;

    vm.openRealtime = openRealtime;

    vm.$onInit = onInit;
    vm.$onChanges = onChanges;
    vm.$onDestroy = onDestroy;

    /**
     * Initialize component
     */
    function onInit() {
      /** @type {String} */
      vm.side = 'any';

      /** @type {String} */
      vm.chartId = 'latest-trade-chart-' + vm.widgetId;

      /** @type {Number} */
      vm.tradeDelay = vm.tradeDelay || 90;

      /** @type {{date: String|Date, trade: btTradeSimulation}} */
      vm.item = {
        date: 'N/A',
        trade: getTradePlaceholder(),
      };

      /** @type {Boolean} */
      vm.isManualBackTesting = vm.manualTesting === 'true';

      /**
       * @name vm.currentChart
       * @type {?btBackChart}
       */
      if (vm.currentChart) {
        /** @type {Boolean} */
        vm.hasRelease = true;

        vm.item.date = new Date(vm.currentChart.time * 1000);
        var delta = Date.now() - vm.item.date;

        /** @type {Boolean} */
        vm.shouldHaveChart = delta > vm.tradeDelay * 60 * 1000;

        /** @type {btGraphObject} */
        var graph = vm.currentChart.graphs.filter(function (value) {
          return value.symbol === vm.tradeInstrument.OandaSymbol;
        })[0];

        if (graph === undefined) {
          graph = vm.currentChart.graphs.filter(function (value) {
            return value.symbol === vm.tradeInstrument.histData;
          })[0];
        }

        if (graph && graph.chartData && graph.chartData.series) {
          /** @type {?zcChartJSON} */
          vm.chart = graph.chartData;
          btChartService.updateTradeMarkers(vm.chartId, vm.chart, vm.side, false);
          vm.hasChart = true;

          if (graph.chartMeta && graph.chartMeta.simulation) {
            vm.item.trade = graph.chartMeta.simulation;
          } else {
            vm.item.trade = getTradePlaceholder();
          }
        } else {
          vm.chart = null;
          vm.hasChart = false;
        }
      } else {
        vm.hasRelease = false;
        vm.shouldHaveChart = false;
        vm.hasChart = false;
        vm.chart = null;
      }

      onTradeIdeaChange(vm.tradeIdea);
    }

    /**
     * React on input changes
     * @param {Object} changes - input changes
     */
    function onChanges(changes) {
      if (changes.tradeIdea) {
        onTradeIdeaChange(changes.tradeIdea.currentValue);
      }
    }

    /**
     * React on trade idea change
     * @param {bt.TradeIdeaObject} tradeIdea
     */
    function onTradeIdeaChange(tradeIdea) {
      if (tradeIdea) {
        if (vm.item.trade.buy.entry.price) {
          btTradingCardService.fixPrices(tradeIdea.trade, vm.item.trade.buy.entry.price);
          fixMarkers(vm.chart, tradeIdea);
        }

        if (tradeIdea.data.action === 'uptrend') {
          vm.side = 'buy';
        }

        if (tradeIdea.data.action === 'downtrend') {
          vm.side = vm.side = 'sell';
        }
      } else {
        vm.side = 'any';
      }

      if (vm.chart) {
        btChartService.updateTradeMarkers(vm.chartId, vm.chart, vm.side, false);
      }
    }

    /**
     *
     * @param {object} chart - chart object
     * @param {bt.TradeIdeaObject} tradeIdea - trade idea object
     */
    function fixMarkers(chart, tradeIdea) {
      if (chart && chart['scale-y'] && chart['scale-y']['markers']) {
        chart['scale-y']['markers'].forEach(function (marker) {
          if (marker.label.text === 'Buy target') marker.range = [tradeIdea.trade.price.target1];
          if (marker.label.text === 'Buy stop') marker.range = [tradeIdea.trade.price.stop3];
          if (marker.label.text === 'Sell target') marker.range = [tradeIdea.trade.price.target1];
          if (marker.label.text === 'Sell stop') marker.range = [tradeIdea.trade.price.stop3];
        });
      }
    }

    /**
     * Destroy component
     */
    function onDestroy() {}

    /**
     * Open realtime chart widget
     */
    function openRealtime() {
      if (typeof vm.onOpenRealtime === 'function') {
        vm.onOpenRealtime();
      }
    }

    /**
     * Return trade placeholder
     * @return {btTradeSimulation}
     */
    function getTradePlaceholder() {
      //TODO start to use null
      // noinspection JSValidateTypes
      return {
        buy: {
          status: 'N/A',
          atr: {},
          entry: {},
          stop: {},
          target: {},
          reward: {},
          risk: {},
          ratio: {},
          opportunity: {},
        },
        sell: {
          status: 'N/A',
          atr: {},
          entry: {},
          stop: {},
          target: {},
          reward: {},
          risk: {},
          ratio: {},
          opportunity: {},
        },
      };
    }
  }
})();
