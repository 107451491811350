/**
 * Created by Sergey Panpurin on 3/16/19
 */

(function btTwitterServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btTwitterService';

  angular.module('ecapp').factory('btTwitterService', service);

  service.$inject = ['$http', '$q', '$rootScope', 'btSettings', 'btErrorService'];

  /**
   * This function is a implementation of btTwitterService
   *
   * @param {angular.IHttpService} $http
   * @param {angular.IQService} $q
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {object} btSettings
   * @param {btUtils.btErrorService} btErrorService
   * @return {ecapp.btTwitterService}
   */
  function service($http, $q, $rootScope, btSettings, btErrorService) {
    /**
     * This service implements interaction with backend twitter features (`/server/boot/twitter.js`).
     *
     * @ngdoc service
     * @name btTwitterService
     * @memberOf ecapp
     * @see TwitterUser
     */

    if (gDebug) console.log('Running btTwitterService');

    return {
      search: search,
      show: show,
      add: add,
      remove: remove,
    };

    /**
     * This function shows information about twitter user
     *
     * @alias ecapp.btTwitterService#show
     * @param {string} name - twitter screen name
     * @return {angular.IPromise<TwitterUser>}
     */
    function show(name) {
      if (gDebug) console.log(gPrefix, 'show twitter user ' + name);
      var options = {
        headers: { 'Content-Type': 'application/json' },
        params: { screen_name: name },
      };

      return $http
        .get(btSettings.BT_BACKEND_URL + '/twitter/users/show', options)
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return $q.reject(response);
          }
        })
        .catch(btErrorService.handleHTTPError)
        .catch(function (reason) {
          if (gDebug) console.error(gPrefix, reason);
          return $q.reject(reason);
        });
    }

    /**
     * This function searches twitter users
     *
     * @alias ecapp.btTwitterService#search
     * @param {string} text - text for the search
     * @return {angular.IPromise<TwitterUser[]>}
     */
    function search(text) {
      if (gDebug) console.log(gPrefix, 'search twitter users for ' + text);
      var options = {
        headers: { 'Content-Type': 'application/json' },
        params: { q: text },
      };

      return $http
        .get(btSettings.BT_BACKEND_URL + '/twitter/users/search', options)
        .then(function (response) {
          if (response.status === 200) return response.data;
          else return $q.reject(response);
        })
        .catch(btErrorService.handleHTTPError)
        .catch(function (reason) {
          if (gDebug) console.error(gPrefix, reason);
          return $q.reject(reason);
        });
    }

    /**
     * This function adds custom channel
     *
     * @alias ecapp.btTwitterService#add
     * @param {string} name - twitter account screen name
     * @return {angular.IPromise<boolean>}
     * @throws UndefinedUser, ServerError
     */
    function add(name) {
      if (gDebug) console.log(gPrefix, 'subscribe to twitter user ' + name);
      if ($rootScope.currentUser) {
        var options = { headers: { 'Content-Type': 'application/json' } };
        var data = { screen_name: name, client_id: $rootScope.currentUser.id };

        return $http
          .post(btSettings.BT_BACKEND_URL + '/twitter/users', data, options)
          .then(function (response) {
            if (response.status === 200) return true;
            else return $q.reject(response);
          })
          .catch(btErrorService.handleHTTPError)
          .catch(function (reason) {
            if (gDebug) console.error(gPrefix, reason);
            return $q.reject(reason);
          });
      } else {
        return $q.reject(new Error('User is undefined!'));
      }
    }

    /**
     * This function removes custom channel
     *
     * @alias ecapp.btTwitterService#remove
     * @param {string} name - twitter account screen name
     * @return {angular.IPromise<boolean>}
     * @throws UndefinedUser, ServerError
     */
    function remove(name) {
      if (gDebug) console.log(gPrefix, 'unsubscribe from twitter user ' + name);
      if ($rootScope.currentUser) {
        var options = {
          headers: { 'Content-Type': 'application/json' },
          params: { screen_name: name, client_id: $rootScope.currentUser.id },
        };

        return $http
          .delete(btSettings.BT_BACKEND_URL + '/twitter/users', options)
          .then(function (response) {
            if (response.status === 200) return true;
            else return $q.reject(response);
          })
          .catch(btErrorService.handleHTTPError)
          .catch(function (reason) {
            if (gDebug) console.error(gPrefix, reason);
            return $q.reject(reason);
          });
      } else {
        return $q.reject(new Error('User is undefined!'));
      }
    }
  }
})();
