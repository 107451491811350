(function btFractionalPartClosure() {
  'use strict';

  angular
    .module('btUtils')
    /**
     * @ngdoc filter
     * @name btFractionalPartFilter
     * @memberOf btUtils
     * @description
     *  Get fractional part of number
     */
    .filter('btFractionalPart', [btFractionalPartFilter]);

  /**
   *
   * @return {angular.IFilterFunction}
   */
  function btFractionalPartFilter() {
    return function getFractionalPart(number) {
      number = number || 0;
      var c = number.toString().indexOf('.');
      if (c !== -1) return number.toString().slice(number.toString().indexOf('.') + 1);
      else return 0;
    };
  }
})();
