/**
 * Created by Sergey Panpurin on 8/22/2016.
 */

(function btAllReviewsControllerClosure() {
  'use strict';

  var gDebug = false;

  angular.module('ecapp').controller('btAllReviewsController', btAllReviewsController);

  btAllReviewsController.$inject = [
    '$scope',
    '$rootScope',
    '$window',
    '$timeout',
    '$state',
    '$ionicScrollDelegate',
    '$ionicPosition',
    '$ionicSlideBoxDelegate',
    '$ionicHistory',
    '$analytics',
    'btCurrenciesService',
    'btDelegateMethodsService',
    'btInsightsService',
    'btCalendarService',
    'btRestrictionService',
    'btAdvertisingService',
  ];

  /**
   * This is economical calendar controller.
   *
   * @ngdoc controller
   * @name btAllReviewsController
   * @memberOf ecapp
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {ecapp.ICustomRootScope} $rootScope - access to root scope
   * @param {angular.IWindowService} $window - window object service
   * @param {angular.ITimeoutService} $timeout - wrapper for window.setTimeout
   * @param {angular.ui.IStateService} $state -
   * @param {ionic.IScrollDelegateService} $ionicScrollDelegate - ionic scroll delegate service
   * @param {ionic.IPositionService} $ionicPosition - ionic position service
   * @param {ionic.ISlideBoxDelegateService} $ionicSlideBoxDelegate - ionic slide box delegate service
   * @param {ionic.IHistoryService} $ionicHistory - ionic history service
   * @param {ext.IAnalyticsService} $analytics
   * @param {ecapp.ICurrenciesService} btCurrenciesService - service to receive information about currencies
   * @param {ecapp.IDelegateMethodsService} btDelegateMethodsService -
   * @param {ecapp.IInsightsService} btInsightsService -
   * @param {ecapp.ICalendarService} btCalendarService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IAdvertisingService} btAdvertisingService
   */
  function btAllReviewsController(
    $scope,
    $rootScope,
    $window,
    $timeout,
    $state,
    $ionicScrollDelegate,
    $ionicPosition,
    $ionicSlideBoxDelegate,
    $ionicHistory,
    $analytics,
    btCurrenciesService,
    btDelegateMethodsService,
    btInsightsService,
    btCalendarService,
    btRestrictionService,
    btAdvertisingService
  ) {
    console.log('Running btAllReviewsController');

    // $scope.isMobile = $window.isMobile;
    // $scope.isTesting = $window.isTesting;

    $scope.hasAdvertising = btAdvertisingService.checkHasAdvertising();
    $scope.pageState = 'init';
    $scope.isScrolling = false;
    $scope.justFromWatchList = btCalendarService.getSettings().watchlist;
    $scope.filters = { watchlist: $scope.justFromWatchList };
    $scope.toolbarTitle = 'Today';
    $scope.nextIndex = 1;

    $scope.resetScroll = resetScroll;
    $scope.openRelease = openRelease;
    $scope.scrollToNext = scrollToNextAction;
    $scope.getInsightsQuickView = getInsightsQuickView;

    $scope.onToolbarAction = onToolbarAction;
    // $scope.refresh = refresh;
    // $scope.toggleWatchListFilter = toggleWatchListFilter;

    $scope.activateTradeIdeas = activateTradeIdeas;

    // cancel $interval on controller destroy
    $scope.$on('$destroy', onDestroy);
    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('$ionicView.enter', onViewEnter);

    $scope.$on('calendar:refreshed', onCalendarRefresh);
    $scope.$on('calendar:reloaded', onCalendarReload);
    $scope.$on('calendar:new-release', onCalendarNewRelease);

    $rootScope.$on('broker:connected', onBrokerConnected);
    $rootScope.$on('broker:disconnected', onBrokerDisconnected);

    // save scroll to latest release function to use it from event timer and row processor
    btDelegateMethodsService.addDelegateMethod('SCROLL-TO-LATEST-ROW', $scope.scrollToNext);

    // initialize currency flags
    btCurrenciesService
      .getAllCachedCurrenciesFlags()
      .then(function (data) {
        $scope.currencyFlags = data.allCachedCurrenciesFlags;
      })
      .catch(function (reason) {
        console.log('btAllReviewsController: error', reason);
      });

    // calendar initialization
    initializeCalendar();

    /*-------------------------*/

    /**
     *
     * @param {*} action
     */
    function onToolbarAction(action) {
      if (action === 'refresh') refresh();
      if (action === 'toggle-watchlist') toggleWatchListFilter();
    }

    /**
     *
     */
    function refresh() {
      $ionicScrollDelegate.$getByHandle('mainScrollRows').scrollTop(false);
      btCalendarService.refreshCalendar().then(scrollToNextAction);
    }

    /**
     * Scroll to next event
     */
    function scrollToNext() {
      var topOffset = getScrollReference();
      var currentScrollHandles = getScrollHandlers('mainScrollRows');

      if (gDebug) console.log('Scroll reference:', topOffset);
      if (gDebug) console.log('Scroll handlers:', currentScrollHandles);

      if (topOffset && currentScrollHandles) {
        // Question: Sometime topOffset is undefined
        var offsetPosition = 0;
        offsetPosition = $ionicPosition.offset(topOffset).top;
        console.log('Scroll reference offset top:', offsetPosition);

        var marginTop = $window.isMobile ? 140 : 155;
        if (gDebug) console.log('Scroll top by:', offsetPosition - marginTop);
        currentScrollHandles.forEach(function (handle) {
          handle.scrollBy(0, offsetPosition - marginTop, false);
        });
      } else {
        console.log('Scroll problem!');
      }
    }

    /**
     *
     * @return {*}
     */
    function getScrollReference() {
      // grabs the first element of each of those classes
      var tmpNow = angular.element(document.querySelector('.bt-calendar-list .eventCardNextHot'));
      var tmpNow1 = angular.element(document.querySelector('.bt-calendar-list .no-more-events-for-today'));
      var tmpNow2 = angular.element(document.querySelector('.bt-calendar-list .eventCardNext'));

      var topOffset = null;

      // by checking length property it actually checks if that variable exists, instead I would replace it with
      // null == tmpNow which is more readable
      if (tmpNow.length) {
        topOffset = tmpNow;
      } else if (tmpNow1.length) {
        topOffset = tmpNow1;
      } else if (tmpNow2.length) {
        topOffset = tmpNow2;
      }

      return topOffset;
    }

    /**
     *
     * @param {*} scrollName
     * @return {*}
     */
    function getScrollHandlers(scrollName) {
      var instances = $ionicScrollDelegate.$getByHandle(scrollName)['_instances'];
      if (instances) {
        return instances.filter(function (element) {
          return element['$$delegateHandle'] === scrollName;
        });
      } else {
        return null;
      }
    }

    /**
     * Indicate that scrolling is stopped
     */
    function stopScrolling() {
      $scope.isScrolling = false;
    }

    /**
     * This function initializes calendar (prepares user data and gets calendar data)
     * @ngdoc method
     * @name initializeCalendar
     * @memberOf ecapp.btAllReviewsController
     * @return {angular.IPromise<*>} promise
     */
    function initializeCalendar() {
      return getCalendarData();
    }

    /**
     * This function run main process: get rows for ecapp and run converter routine with interval in 30 seconds
     *
     * @ngdoc method
     * @name getCalendarData
     * @memberOf ecapp.btAllReviewsController
     * @return {*} promise
     */
    function getCalendarData() {
      $scope.pageState = 'loading';

      return btCalendarService
        .getCalendar()
        .then(function (calendar) {
          $scope.calendar = calendar;

          // console.log('>>> Calendar cards:', calendar.cards);

          $scope.pageState = 'good';

          // used by ion-sticky
          $scope.numberLoad = 0;

          $timeout($scope.scrollToNext, 500, false);
        })
        .catch(function (reason) {
          $scope.pageState = 'error';
          console.log('btAllReviewsController: error', reason);
        });
    }

    /**
     * This function scroll ecapp to next release.
     *
     * Release is marked as next:
     *   - if there is a hot event (which is going to be in the next 5 minutes) - scroll to it
     *   - if we passed all the incoming events for today (usually after 4:00PM) - scroll to no more events for today
     *   - if there is more next event which occurs today, scroll to it.
     *
     * @ngdoc method
     * @name scrollToNext
     * @memberOf ecapp.btAllReviewsController
     */
    function scrollToNextAction() {
      if ($scope.isScrolling === false) {
        $scope.isScrolling = true;
        $timeout(stopScrolling, 500, false);
        $timeout(scrollToNext, 0, false);
      }
    }

    /**
     * This function is called when the length of the scroll view changes (like when we open an accordion or another
     * event loads), in order to be able to scroll to the end.
     *
     * @ngdoc method
     * @name resetScroll
     * @memberOf ecapp.btAllReviewsController
     */
    function resetScroll() {
      $ionicScrollDelegate.$getByHandle('mainScrollRows').resize();
    }

    /**
     * This function open insights quick view
     *
     * @ngdoc method
     * @name getInsightsQuickView
     * @memberOf ecapp.btAllReviewsController
     * @param {btRelease} row - (changeable) release data
     * @return {angular.IPromise<*>}
     */
    function getInsightsQuickView(row) {
      return btInsightsService
        .getInsights(row)
        .then(function onSuccess() {
          $ionicSlideBoxDelegate.update();
          $scope.resetScroll();
        })
        .catch(function onFail(reason) {
          console.log('btAllReviewsController: error', reason);
          return reason;
        });
    }

    /**
     * This function open release page
     *
     * @ngdoc method
     * @name openRelease
     * @memberOf ecapp.btAllReviewsController
     * @param {btRelease} release - (changeable) release data
     */
    function openRelease(release) {
      // load insights and save information of release to cache
      // btRowProcessorService.prepareEventDetailsView(release);

      var data = btCalendarService.getReleaseStateParams(release);
      console.log('Economical calendar: try to open ecapp.app.main.detail' + '(' + data + ')');
      $state.go('ecapp.app.main.detail', data);
    }

    /**
     *
     */
    function activateTradeIdeas() {
      btRestrictionService.showUpgradePopup('trade-ideas').catch(function (reason) {
        console.error(reason);
      });
    }

    /**
     *
     */
    function toggleWatchListFilter() {
      $scope.justFromWatchList = btCalendarService.toggleWatchListFilter();
      $scope.filters.watchlist = $scope.justFromWatchList;
      // console.log('Calendar Filter: show just watchlist', $scope.justFromWatchList);
    }

    /**
     *
     */
    function onDestroy() {
      console.log('Controller btAllReviewsController was destroyed!');
    }

    /**
     *
     */
    function onViewLoaded() {
      onViewOpen();
    }

    /**
     *
     * @param {*} event
     * @param {*} data
     */
    function onViewEnter(event, data) {
      void event;

      onViewOpen();

      $ionicHistory.clearHistory();

      if (data.direction !== 'back') {
        // $scope.scrollToNext();
      }

      if (data.direction === 'forward') {
        // $ionicHistory.removeBackView();
      }
    }

    /**
     *
     */
    function onViewOpen() {
      $timeout(function () {
        $('#bt-calendar-content').focus();
      }, 1000);
    }

    /**
     *
     */
    function onCalendarRefresh() {
      $scope.numberLoad = 0;
    }

    /**
     *
     */
    function onCalendarReload() {
      $scope.numberLoad = 0;
    }

    /**
     *
     * @param {*} event
     * @param {*} data
     */
    function onCalendarNewRelease(event, data) {
      void event;
      void data;

      // if (!data.show) {
      //   return;
      // }
    }

    /**
     *
     * @param {*} event
     */
    function onBrokerConnected(event) {
      void event;
    }

    /**
     *
     * @param {*} event
     */
    function onBrokerDisconnected(event) {
      void event;
    }
  }
})();
