/**
 * Created by Sergey on 6/4/2020.
 */

(function btEventSearchClosure() {
  'use strict';

  angular
    .module('ecapp')
    .directive('btEventSearch', btEventSearch)
    .controller('btEventSearchController', btEventSearchController);

  btEventSearch.$inject = ['$templateCache'];

  /**
   * This directive show add instrument button on markets page.
   *
   * @ngdoc directive
   * @name btEventSearch
   * @memberOf ecapp
   * @return {angular.IDirective}
   */
  function btEventSearch() {
    return {
      restrict: 'A',
      scope: false,
      controller: 'btEventSearchController',
    };
  }

  btEventSearchController.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    '$attrs',
    '$parse',
    '$ionicModal',
    '$templateCache',
    'btShareScopeService',
    'btCurrenciesService',
    'btRestrictionService',
    'btEventsService',
  ];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.ITimeoutService} $timeout
   * @param {angular.IAttributes} $attrs
   * @param {angular.IParseService} $parse
   * @param {ionic.IModalService} $ionicModal
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.ICurrenciesService} btCurrenciesService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IEventsService} btEventsService
   */
  function btEventSearchController(
    $scope,
    $rootScope,
    $timeout,
    $attrs,
    $parse,
    $ionicModal,
    $templateCache,
    btShareScopeService,
    btCurrenciesService,
    btRestrictionService,
    btEventsService
  ) {
    // search instrument object
    $scope.search = {
      text: '',
      hasResult: true,
      isTyping: false,
      isLoading: false,
      select: selectEvent,
    };

    $scope.search.hasResult = true;
    $scope.search.isTyping = false;
    $scope.search.isLoading = false;

    $scope.openAddEventDialog = openModal;
    $scope.hideAddEventDialog = hideModal;
    $scope.clearSearch = clearSearch;
    $scope.onSearch = onSearch;

    $scope.currencyFlags = [];

    $scope.$on('$destroy', onDestroy);

    activate();

    /**
     *
     */
    function activate() {
      $scope.search.modal = createModal();

      btCurrenciesService
        .getAllCachedCurrenciesFlags()
        .then(function (data) {
          $scope.currencyFlags = data.allCachedCurrenciesFlags;
        })
        .catch(function (reason) {
          console.error(reason);
        });
    }

    /**
     * On scope destroy
     */
    function onDestroy() {
      if ($scope.search.modal !== undefined) {
        $scope.search.modal.remove();
      }
    }

    /**
     * Create modal
     * @return {any}
     */
    function createModal() {
      var data = { scope: $scope, animation: 'slide-in-up', focusFirstInput: true };
      return $ionicModal.fromTemplate($templateCache.get('modals/bt-search-event-modal.html'), data);
    }

    /**
     * Open modal view
     */
    function openModal() {
      if (btRestrictionService.hasFeature('personalization')) {
        $scope.search.modal.show();
      } else {
        btRestrictionService.showUpgradePopup('personalization');
      }
    }

    /**
     * Hide modal view
     */
    function hideModal() {
      $scope.search.modal.hide();
    }

    /**
     * Clear search
     */
    function clearSearch() {
      $scope.search.text = '';
    }

    /**
     * Load list of suggested instruments on search
     */
    function onSearch() {
      $scope.search.isTyping = true;
      // console.log('btMarketsController: search instrument', $scope.search.text);
      var text = $scope.search.text;
      $timeout(onSearchDelay, 800, false);

      /**
       *
       */
      function onSearchDelay() {
        $scope.search.isTyping = false;
        if (text === $scope.search.text && text !== '') {
          // console.log('btMarketsController: run search');
          $scope.search.isLoading = true;
          $scope.search.hasResult = false;
          btEventsService
            .suggestEvent($scope.search.text, 50, {})
            .then(function (events) {
              $scope.search.isLoading = false;
              $scope.search.hasResult = true;
              $scope.search.events = events;
              // $scope.search.followedEvents = btShareScopeService.getListFollowedEvents();
              // normalizeSearchObject($scope.search);
            })
            .catch(function (reason) {
              $scope.search.isLoading = false;
              $scope.search.hasResult = true;
              console.error(reason);
            });
        }
      }
    }

    /**
     * Run onSelect callback
     *
     * @param {Object} event - instrument data
     */
    function selectEvent(event) {
      // $rootScope.$broadcast('calendar:follow-event', event.id, true);
      // console.log('TEST: broadcast tutorial:toggle-event', event.id, ' - ', $scope.$id);
      $scope.$broadcast('tutorial:toggle-event', event.id);
      // if (event.followed === false) {
      //   if ($attrs.onSelect) {
      //     var onSelect = $parse($attrs.onSelect);
      //     var promise = onSelect($scope, {eventId: event.id});
      //     if (promise && typeof promise.then === 'function') {
      //       promise
      //         .then(function () {
      //           normalizeSearchObject($scope.search);
      //         })
      //         .finally($scope.hideAddEventDialog);
      //     } else {
      //       normalizeSearchObject($scope.search);
      //       $scope.hideAddEventDialog();
      //     }
      //   } else {
      //     console.error('Function onSelect is undefined!')
      //   }
      // }
    }

    // function normalizeSearchObject(search) {
    //   search.events.forEach(function (event) {
    //     event.followed = (search.followedEvents.indexOf(event.id) !== -1);
    //   });
    // }
  }
})();
