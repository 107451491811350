(function btSignUpControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btSignUpController', btSignUpController);

  btSignUpController.$inject = [
    '$scope',
    '$q',
    '$timeout',
    '$state',
    '$document',
    '$ionicLoading',
    '$http',
    'btAuthService',
    'btShareScopeService',
    'btSettingsService',
    'btOptimusService',
    'btBigBrainBankService',
    '$ionicHistory',
    'btSubscriptionService',
    'btWindowsSizeService',
    'btErrorService',
    'btTagService',
    'btBranchService',
    'btToastrService',
  ];

  /**
   * Sign up controller
   *
   * @ngdoc controller
   * @name ecapp.btSignUpController
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.IQService} $q - promise interface
   * @param {angular.ITimeoutService} $timeout
   * @param {angular.ui.IStateService} $state
   * @param {angular.IDocumentService} $document
   * @param {ionic.ILoadingService} $ionicLoading - ionic loading service
   * @param {angular.IHttpService} $http
   * @param {ecapp.IAuthService} btAuthService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IOptimusService} btOptimusService
   * @param {ecapp.IBigBrainBankService} btBigBrainBankService
   * @param {ionic.IHistoryService} $ionicHistory - ionic history service
   * @param {ecapp.ISubscriptionService} btSubscriptionService
   * @param {ecapp.IWindowsSizeService} btWindowsSizeService
   * @param {ecapp.IErrorService} btErrorService
   * @param {ecapp.ITagService} btTagService
   * @param {ecapp.IBranchService} btBranchService
   * @param {ecapp.IToastrService} btToastrService
   */
  function btSignUpController(
    $scope,
    $q,
    $timeout,
    $state,
    $document,
    $ionicLoading,
    $http,
    btAuthService,
    btShareScopeService,
    btSettingsService,
    btOptimusService,
    btBigBrainBankService,
    $ionicHistory,
    btSubscriptionService,
    btWindowsSizeService,
    btErrorService,
    btTagService,
    btBranchService,
    btToastrService
  ) {
    console.log('Running btSignUpController');

    var UNKNOWN_DOMAIN = 'Unknown domain';

    var gSettings = btSettingsService.getLocalSettings();

    $scope.isBetterTrader = btSettingsService.domain === 'bettertrader';
    $scope.isBigBrainBank = btSettingsService.domain === 'bigbrainbank';
    $scope.isOptimus = btSettingsService.domain === 'optimusfutures';
    $scope.hasSocialLogin = btSettingsService.hasFeature('social-login');

    $scope.logoSrc = btSettingsService.getAssets('login-logo-src');
    $scope.logoAlt = btSettingsService.getAssets('login-logo-alt');
    $scope.openAccountHref = btSettingsService.getAssets('open-account-href');

    if (btShareScopeService.userIsLogin()) {
      console.log('SignUp: try to open ecapp.app.main.reviews');
      $state.go('ecapp.app.main.reviews');
      return;
    }

    btTagService.intendRegistration();

    /**
     * @typedef {Object} UserRegistration
     * @property {String} email - user email
     * @property {String} password - user password
     */

    /**
     * BetterTrader registration data
     * @type {UserRegistration}
     */
    $scope.user = {
      email: '',
      password: '',
    };

    /**
     * @typedef {Object} DemoRegistration
     * @property {String} email - user email
     * @property {String} password - user password
     * @property {String} confirm - user password confirmation
     * @property {String} name - user name
     * @property {String} phone - user phone
     * @property {String} country - user country
     * @property {String} referral - referral email
     * @property {Object} errors - registration errors
     * @property {Boolean} errors.hasDemoAccount - whether user has domain specific demo account
     * @property {Boolean} errors.hasRegularAccount - whether user has regular account
     */

    /**
     * Custom domain account registration data
     * @type {DemoRegistration}
     */
    $scope.custom = {
      name: '',
      email: '',
      phone: '',
      country: '',
      password: '',
      confirm: '',
      referral: '',
      errors: { hasDemoAccount: false, hasRegularAccount: false },
    };

    try {
      var referral = localStorage.getItem('BT_REFERRAL');
      $scope.custom.referral = referral ? window.atob(referral) : '';
    } catch (e) {
      console.error('Can not read referral data: ', localStorage.getItem('BT_REFERRAL'));
      localStorage.removeItem('BT_REFERRAL');
    }

    if (!$scope.custom.referral) {
      $q.all([btBranchService.getFirstReferringParams(), btBranchService.getLatestReferringParams()]).then(function (
        results
      ) {
        if (results[1] && results[1]['data_parsed'] && results[1]['data_parsed']['ref_email']) {
          $scope.custom.referral = results[1]['data_parsed']['ref_email'];
        } else if (results[0] && results[0]['data_parsed'] && results[0]['data_parsed']['ref_email']) {
          $scope.custom.referral = results[0]['data_parsed']['ref_email'];
        }
      });
    }

    $scope.socialLogin = socialLogin;
    $scope.openWebSite = openWebSite;

    $scope.btSubmit = btSubmit;
    $scope.customSubmit = customSubmit;

    /**
     *
     * @param {*} provider
     */
    function socialLogin(provider) {
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Log in via ' + provider + '...</p>' });

      btAuthService
        .socialLogin(provider)
        .then(btAuthService.postSocialLogin)
        .then(function (isTutorialPassed) {
          if (isTutorialPassed) {
            $state.go('ecapp.app.main.reviews');
          } else {
            $state.go('ecapp.user.tutorial');
          }
        })
        .catch(function (error) {
          console.error('Social Login:', error);
          $state.go('ecapp.user.login');
        })
        .finally(function () {
          $ionicLoading.hide();
        });
    }

    /**
     *
     */
    function openWebSite() {
      window.open('https://bettertrader.co', '_system');
    }

    /**
     * This function implements bettertrader submit button.
     */
    function btSubmit() {
      $scope.errorAlert = '';

      if (!btAuthService.isValidEmail($scope.user.email)) {
        $scope.errorAlert = 'Enter a valid email';
        return;
      }

      // freeze system with loader
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Register User...</p>' });

      $timeout(function () {
        $ionicLoading.hide();
      }, 3000);

      // try to register new user
      btAuthService
        .register($scope.user.email, $scope.user.password, null, null, null)
        .then(loginBTClient)
        .then(function onSuccess() {
          $ionicLoading.hide();

          // register succeeded, but now we need to confirm email
          console.log('SignUp: try to open ecapp.user.sign-up-success');
          // $state.go('ecapp.user.sign-up-success');
        })
        .catch(function onFailure(response) {
          $ionicLoading.hide();

          if (response.data && response.data.error) {
            if (response.data.error.name === 'ValidationError') {
              $scope.errorAlert = 'User already exists';
            } else if (response.data.error.code === 'LOGIN_FAILED_WHITELIST') {
              $scope.errorAlert = 'Looks that you need to sign up';
            } else if (response.data.error.message) {
              $scope.errorAlert = response.data.error.message;
            } else {
              $scope.errorAlert = 'Sign up is temporary unavailable, please try again';
            }
          } else {
            $scope.errorAlert = 'Sign up is temporary unavailable, please try again';
          }
        });
    }

    /**
     * This function logs in new bettertrader client.
     *
     * @return {angular.IPromise<any>}
     */
    function loginBTClient() {
      return $ionicHistory
        .clearCache()
        .then(btAuthService.login.bind(null, $scope.user.email, $scope.user.password))
        .then(onSuccessLogin);
    }

    /**
     * This function processes success login.
     */
    function onSuccessLogin() {
      btSubscriptionService.updateStatus();
      redirectAfterLogin();
    }

    /**
     * This function redirects user after login to tutorial or economic calendar.
     */
    function redirectAfterLogin() {
      if (btSettingsService.isLinkDataService()) {
        return;
      } else {
        var isTutorialPassed = btShareScopeService.getTutorialStatus();
        console.log('btAuthLoginController check is tutorial passed ', isTutorialPassed);

        if (isTutorialPassed) {
          console.log('AuthLogin: try to open ecapp.app.main.reviews');
          $state.go('ecapp.app.main.reviews');
        } else {
          btWindowsSizeService.setSize();
          console.log('AuthLogin: try to open ecapp.user.tutorial');
          $state.go('ecapp.user.tutorial');
        }
      }
    }

    /**
     * Create custom client record and submit custom form
     *
     * @return {angular.IPromise<*>}
     */
    function customSubmit() {
      $scope.errorAlert = '';

      $scope.custom.errors.hasDemoAccount = false;
      $scope.custom.errors.hasRegularAccount = false;

      if (isEmptyField($scope.custom, 'name')) return showEmptyFiledError('Name');

      if ($scope.isBigBrainBank) {
        try {
          $scope.custom.phone = btAuthService.validatePhoneNumber($scope.custom.phone);
        } catch (e) {
          return showPhoneError(e.message);
        }
      }

      if (!btAuthService.isValidEmail($scope.custom.email)) return showInvalidEmailError();

      if (!btAuthService.isValidPassword($scope.custom.password)) return showInvalidPasswordError();

      if ($scope.custom.password !== $scope.custom.confirm) return showUnconfirmedPasswordError();

      if ($scope.isBigBrainBank) {
        if ($scope.custom.referral && !btAuthService.isValidEmail($scope.custom.referral))
          return showInvalidReferralEmailError();
      }

      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Register User...</p>' });

      registerBetterTraderUser($scope.custom)
        .then(submitCustomForm)
        .then(registerCustomUser)
        .then(loginCustomUser)
        .then(redirectAfterLogin)
        .catch(function (reason) {
          console.error(reason);
        })
        .finally(function () {
          $ionicLoading.hide();
        });
    }

    /**
     * This function registers a new optimus user in bettertrader system and handles errors.
     *
     * @param {DemoRegistration} user - information about optimus user.
     * @return {angular.IPromise<DemoRegistration>}
     */
    function registerBetterTraderUser(user) {
      return btAuthService
        .register(user.email, user.password, user.name, user.phone, user.country)
        .then(function () {
          return $q.resolve(user);
        })
        .catch(function (response) {
          if (response.data && response.data.error) {
            if (response.data.error.name === 'ValidationError') {
              user.errors.hasRegularAccount = true;
              return $q.resolve(user);
            } else if (response.data.error.code === 'LOGIN_FAILED_WHITELIST') {
              $scope.errorAlert = 'To use this system you need to approve your email. Please contact us.';
            } else {
              $scope.errorAlert = 'Sign up is temporary unavailable, please try again';
            }
          } else {
            $scope.errorAlert = 'Sign up is temporary unavailable, please try again';
          }
          return $q.reject(response);
        });
    }

    /**
     * This function creates optimus record in bettertrader system.
     *
     * @param {DemoRegistration} user - information about optimus user.
     * @return {angular.IPromise<*>}
     */
    function registerCustomUser(user) {
      var register;
      var details;

      if (btSettingsService.isOptimusFutures()) {
        register = btOptimusService.auth.register;
        details = null;
      }

      if (btSettingsService.isBigBrainBank()) {
        register = btBigBrainBankService.auth.register;
        details = {
          name: user.name,
          phone: user.phone,
          ref: user.referral,
        };
      }

      if (btSettingsService.isLinkDataService()) {
        register = function (email, details) {
          void email;
          void details;
          return $q.resolve();
        };
        details = {};
      }

      if (!register) return $q.reject(new Error(UNKNOWN_DOMAIN));

      return register(user.email, details)
        .then(function () {
          return user;
        })
        .catch(function (reason) {
          if (reason.code === 'UserExists') {
            user.errors.hasDemoAccount = true;
            return $q.resolve(user);
          } else {
            return $q.reject(reason);
          }
        })
        .then(function (user) {
          if (user.errors.hasDemoAccount && user.errors.hasRegularAccount) {
            // User already has bettertrader and optimus accounts. Show error. User should use login page.
            $scope.errorAlert =
              'You already have account. Please try to login. If you have forgotten your password, use "Forgot your password" link on login page.';
            $ionicLoading.hide();
            return $q.reject(new Error('Existed user!'));
          } else {
            // User already has bettertrader account (he doesn't have optimus account). Notify about password issue.
            if (user.errors.hasRegularAccount) showUserExistsError();

            // User doesn't have optimus account.
            if (!user.errors.hasDemoAccount) saveCustomRedirectionDetails(user);

            return $q.resolve(user);
          }
        });
    }

    /**
     * This function runs login process for new optimus user.
     *
     * @param {DemoRegistration} user - information about optimus user.
     * @return {angular.IPromise<DemoRegistration>}
     */
    function loginCustomUser(user) {
      if (btSettingsService.isLinkDataService()) {
        btToastrService.success('We are processing your access request. Please wait!', 'Request', { type: 'system' });
        $scope.custom.name = '';
        $scope.custom.email = '';
        $scope.custom.password = '';
        $scope.custom.confirm = '';

        return $q.resolve(user);
      } else {
        return $ionicHistory
          .clearCache()
          .then(btAuthService.login.bind(null, user.email, user.password))
          .then(onCustomLoginSuccess)
          .then(function () {
            return user;
          });
      }
    }

    /**
     * This function processes success login action of new optimus user.
     */
    function onCustomLoginSuccess() {
      btSubscriptionService.updateStatus();
      var isTutorialPassed = btShareScopeService.getTutorialStatus();
      console.log('btAuthLoginController check is tutorial passed ', isTutorialPassed);
    }

    /**
     * This function submits data to a Optimus HubSpot form
     *
     * @param {DemoRegistration} user - information about new user
     * @return {angular.IPromise<DemoRegistration>}
     */
    function submitCustomForm(user) {
      if (btSettingsService.isOptimusFutures()) return submitOptimusForm(user);
      if (btSettingsService.isBigBrainBank()) return $q.resolve(user);
      if (btSettingsService.isLinkDataService()) return $q.resolve(user);
      return $q.reject(new Error(UNKNOWN_DOMAIN));
    }

    /**
     * This function submits data to a Optimus HubSpot form
     *
     * @param {DemoRegistration} user - information about new user
     * @return {angular.IPromise<DemoRegistration>}
     */
    function submitOptimusForm(user) {
      var url =
        'https://api.hsforms.com/submissions/v3/integration/submit/3635070/25c0855e-cabb-4557-953d-6d089ffe222e';
      var data = {
        fields: [
          { name: 'email', value: user.email },
          { name: 'firstname', value: user.name },
        ],
      };

      var params = {};

      return $http
        .post(url, data, params)
        .catch(function (reason) {
          if (reason instanceof Error) {
            return $q.reject(reason);
          } else {
            if (reason.data && reason.data.errors) {
              return $q.reject(new Error(reason.data.errors[0].message));
            } else {
              console.error(reason);
              return $q.reject(new Error('Unknown Error'));
            }
          }
        })
        .then(function (response) {
          if (response.status !== 200) {
            return $q.reject(
              new Error(
                response.status + ' ' + (response.data.errors ? response.data.errors[0].message : response.statusText)
              )
            );
          } else {
            return user;
          }
        })
        .catch(function (error) {
          $scope.errorAlert =
            'User was not registered due to server error. Please contact support. Error message: ' + error.message;
          return $q.reject(error);
        });
    }

    /**
     * This function saves registration data of new optimus user in local storage to use it after redirection.
     * @param {DemoRegistration} user - information about optimus user.
     */
    function saveCustomRedirectionDetails(user) {
      var prefix;

      if ($scope.isOptimus) prefix = 'optimus';
      if ($scope.isBigBrainBank) prefix = 'bigbrainbank';

      if (prefix) {
        gSettings.set(prefix + '.registration.created', new Date());
        gSettings.set(prefix + '.registration.email', user.email);
        gSettings.set(prefix + '.registration.name', user.name);
        gSettings.set(prefix + '.registration.phone', user.phone);
        gSettings.set(prefix + '.registration.country', user.country);
      }
    }

    /**
     *
     * @param {Record<string, any>} form - form
     * @param {string} field - field
     * @return {boolean}
     */
    function isEmptyField(form, field) {
      return form[field] === '' || form[field] === undefined || form[field] === null;
    }

    /**
     *
     * @param {string} field - field
     * @return {angular.IPromise<void>}
     */
    function showEmptyFiledError(field) {
      alert(field + ' is required. Please fill it.');
      return $q.resolve();
    }

    /**
     *
     * @return {angular.IPromise<void>}
     */
    function showInvalidEmailError() {
      alert('Invalid e-mail address.');
      return $q.resolve();
    }

    /**
     *
     * @return {angular.IPromise<void>}
     */
    function showInvalidReferralEmailError() {
      alert('Invalid referral e-mail address.');
      return $q.resolve();
    }

    /**
     *
     * @param {Error} error - error
     * @return {angular.IPromise<void>}
     */
    function showPhoneError(error) {
      alert('Phone number: ' + error);
      return $q.resolve();
    }

    /**
     *
     * @return {angular.IPromise<void>}
     */
    function showInvalidPasswordError() {
      alert('Invalid password. Minimal password length is 4 symbols.');
      return $q.resolve();
    }

    /**
     *
     * @return {angular.IPromise<void>}
     */
    function showUnconfirmedPasswordError() {
      alert('Password does not match the confirm password.');
      return $q.resolve();
    }

    /**
     *
     * @return {angular.IPromise<void>}
     */
    function showUserExistsError() {
      var suffix = '';
      if (btSettingsService.isOptimusFutures()) suffix = ' via BetterTrader';

      var text =
        'You already have account created' +
        suffix +
        '. ' +
        'Please use password from that account. ' +
        'If you have forgotten your password, use "Forgot your password" link on login page.';

      alert(text);
      return $q.resolve();
    }
  }
})();
