/**
 * Created by Orly on 05/02/19.
 */

(function btMultipleSelection() {
  'use strict';

  angular
    .module('ecapp')

    /**
     * @typedef {object} IconObject
     * @property {string} name - name of the icon
     * @property {string} class - classes to add to the icon
     */

    /**
     * This directive displays multi select
     *
     * @ngdoc directive
     * @name btMultipleSelection
     * @memberOf ecapp
     * @scope
     * @param {ecapp.IOptionObject[]} options - array of options
     * @param {function} [onSelect] - function to call on select
     */
    .directive('btMultipleSelection', btMultipleSelection);

  btMultipleSelection.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btMultipleSelection($templateCache) {
    return {
      restrict: 'E',
      scope: {
        options: '=',
        onSelect: '&?',
      },
      template: $templateCache.get('directives/common/bt-multiple-selection.html'),
      controller: ['$scope', '$templateCache', btMultipleSelectionController],
    };
  }

  /**
   *
   * @param {*} $scope
   */
  function btMultipleSelectionController($scope) {
    $scope.selectOption = selectOption;
    $scope.deselectAll = deselectAll;
    $scope.selectAll = selectAll;

    /**
     * This functions changes selected value of the option.
     *
     * @param {ecapp.IOptionObject} option - option object
     */
    function selectOption(option) {
      option.selected = !option.selected;
      if (typeof $scope.onSelect === 'function') $scope.onSelect(option);
    }

    /**
     * This function sets selected to false for all the options.
     */
    function deselectAll() {
      $scope.options.forEach(function (o) {
        o.selected = false;
        if (typeof $scope.onSelect === 'function') $scope.onSelect(o);
      });
    }

    /**
     * This function sets selected to true for all the options.
     */
    function selectAll() {
      $scope.options.forEach(function (o) {
        o.selected = true;
        if (typeof $scope.onSelect === 'function') $scope.onSelect(o);
      });
    }
  }
})();
