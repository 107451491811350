/* global fbq */

(function btAuthLoginControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btAuthLoginController', btAuthLoginController);

  btAuthLoginController.$inject = [
    '$scope',
    '$q',
    '$location',
    '$timeout',
    '$state',
    '$ionicLoading',
    '$window',
    '$ionicHistory',
    'btAuthService',
    'btShareScopeService',
    'btWindowsSizeService',
    'btSubscriptionService',
    'btSettingsService',
    'btToastrService',
  ];

  /**
   * Login controller connected to state 'login' with url '/login'. Check user authorization and after save
   * information about use to cache and root scope.
   *
   * @ngdoc controller
   * @name ecapp.btAuthLoginController
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.IQService} $q - promise interface
   * @param {angular.ILocationService} $location
   * @param {angular.ITimeoutService} $timeout
   * @param {angular.ui.IStateService} $state
   * @param {ionic.ILoadingService} $ionicLoading - ionic loading service
   * @param {angular.IWindowService} $window - window object service
   * @param {ionic.IHistoryService} $ionicHistory - ionic history service
   * @param {ecapp.IAuthService} btAuthService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IWindowsSizeService} btWindowsSizeService
   * @param {ecapp.ISubscriptionService} btSubscriptionService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IToastrService} btToastrService
   */
  function btAuthLoginController(
    $scope,
    $q,
    $location,
    $timeout,
    $state,
    $ionicLoading,
    $window,
    $ionicHistory,
    btAuthService,
    btShareScopeService,
    btWindowsSizeService,
    btSubscriptionService,
    btSettingsService,
    btToastrService
  ) {
    console.log('Running btAuthLoginController');

    var gSettings = btSettingsService.getLocalSettings();

    if (window.fbq) {
      fbq('track', 'ViewContent', { content_name: 'SignInPage' });
    }

    $scope.isLarge = $window.isLarge;
    $scope.errorAlert = false;

    $scope.isBetterTrader = btSettingsService.domain === 'bettertrader';
    $scope.isBigBrainBank = btSettingsService.domain === 'bigbrainbank';
    $scope.isOptimus = btSettingsService.domain === 'optimusfutures';
    $scope.hasSocialLogin = btSettingsService.hasFeature('social-login');

    $scope.signUpText = btSettingsService.getAssets('sign-up-text');
    $scope.logoSrc = btSettingsService.getAssets('login-logo-src');
    $scope.logoAlt = btSettingsService.getAssets('login-logo-alt');
    $scope.openAccountHref = btSettingsService.getAssets('open-account-href');
    $scope.registerAccountHref = btSettingsService.getAssets('register-account-href');

    // play video
    $scope.hasVideo = !$window.isMobile && !$scope.isLarge && $scope.isBetterTrader;
    $scope.isVideoOpen = false;
    $scope.user = { email: '', password: '' };

    $scope.displayVideo = displayVideo;
    $scope.openWebSite = openWebSite;
    $scope.socialLogin = socialLogin;
    $scope.regularLogin = login;

    $scope.registrationDetails = null;

    if (btShareScopeService.userIsLogin()) {
      $state.go('ecapp.app.main.reviews');
    } else {
      activate();
    }

    /**
     *
     */
    function activate() {
      checkPreviousTermination();

      // Handle social login error
      var error = window.location.href.split('errorCode=')[1];
      error = decodeURIComponent(error);
      if (error === 'EMAIL_MISSING') {
        $scope.errorAlert = "Can't receive email, try another way to sign in.";
      }

      if ($scope.isBigBrainBank) {
        prepareDomainLogin('bigbrainbank');
      }

      if ($scope.isOptimus) {
        prepareDomainLogin('optimus');
      }
    }

    /**
     *
     */
    function checkPreviousTermination() {
      if (window.localStorage.getItem('btTerminated') === 'true') {
        if (btSettingsService.isBigBrainBank()) {
          btToastrService.error(
            'The session expired or was aborted due to repeated use. Please note that you can run one mobile version and one web version at the same time.',
            'Authorization',
            { timeOut: 30000, closeButton: true, type: 'system' }
          );
        } else {
          btToastrService.error('The session has expired. Please log in again.', 'Authorization', {
            timeOut: 30000,
            closeButton: true,
            type: 'system',
          });
        }
      }

      window.localStorage.removeItem('btTerminated');
    }

    /**
     *
     * @param {*} domain
     */
    function prepareDomainLogin(domain) {
      var savedDetails = {
        created: gSettings.get(domain + '.registration.created'),
        email: gSettings.get(domain + '.registration.email'),
        name: gSettings.get(domain + '.registration.name'),
        phone: gSettings.get(domain + '.registration.phone'),
        country: gSettings.get(domain + '.registration.country'),
      };

      if (savedDetails.email) {
        $scope.registrationDetails = savedDetails;
      }

      var searchObject = $location.search();

      var urlDetails = {
        email: searchObject.inf_field_Email,
        name: searchObject.inf_field_Name,
      };

      if (urlDetails.email) {
        $scope.registrationDetails = urlDetails;
        $scope.user.email = urlDetails.email;
        $location.search({});
        $timeout(function () {
          $('#password-input').focus();
        }, 1000);
      }
    }

    /**
     *
     */
    function displayVideo() {
      $scope.isVideoOpen = !$scope.isVideoOpen;
    }

    /**
     *
     */
    function openWebSite() {
      window.open('https://bettertrader.co', '_system');
    }

    /**
     *
     * @param {*} provider
     */
    function socialLogin(provider) {
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Log in via ' + provider + '...</p>' });

      btAuthService
        .socialLogin(provider)
        .then(btAuthService.postSocialLogin)
        .then(onSuccess)
        .catch(onFail)
        .finally(onFinal);

      /**
       *
       * @param {boolean} isTutorialPassed
       */
      function onSuccess(isTutorialPassed) {
        if (isTutorialPassed) {
          console.log('AuthLogin: try to open ecapp.app.main.reviews');
          $state.go('ecapp.app.main.reviews');
        } else {
          console.log('AuthLogin: try to open ecapp.user.tutorial');
          $state.go('ecapp.user.tutorial');
        }
      }

      /**
       *
       * @param {*} error
       */
      function onFail(error) {
        console.error('Error:', error);
        console.log('AuthLogin: try to open ecapp.user.login');
        $state.go('ecapp.user.login');
      }

      /**
       *
       */
      function onFinal() {
        $ionicLoading.hide();
      }
    }

    /**
     * This function login user using btAuthService.login and redirect to reviews page on success or display
     * error on fail.
     *
     * @ngdoc method
     * @name login
     * @memberOf ecapp.btAuthLoginController
     * @param {Object} loginForm - reference to user login and password form
     * @return {any}
     */
    function login(loginForm) {
      if (loginForm.$valid) {
        $scope.errorAlert = false;

        $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Log in...</p>' });

        return $ionicHistory
          .clearCache()
          .then(btAuthService.login.bind(null, $scope.user.email, $scope.user.password))
          .then(function () {
            return useRegistrationDetails(btShareScopeService.accountInfo, $scope.registrationDetails);
          })
          .then(onSuccess)
          .catch(onFail)
          .finally(onFinal);
      }

      /**
       *
       */
      function onSuccess() {
        btSubscriptionService.updateStatus();
        var isTutorialPassed = btShareScopeService.getTutorialStatus();
        console.log('btAuthLoginController check is tutorial passed ', isTutorialPassed);

        if (isTutorialPassed) {
          console.log('AuthLogin: try to open ecapp.app.main.reviews');
          $state.go('ecapp.app.main.reviews');
        } else {
          btWindowsSizeService.setSize();
          console.log('AuthLogin: try to open ecapp.user.tutorial');
          $state.go('ecapp.user.tutorial');
        }
      }

      /**
       *
       * @param {*} account
       * @param {*} details
       * @return {any}
       */
      function useRegistrationDetails(account, details) {
        var fields = [];

        if (details === null || details.email !== account.email) {
          if ($scope.isBigBrainBank) {
            gSettings.delete('bigbrainbank.registration');
          }

          if ($scope.isOptimus) {
            gSettings.delete('optimus.registration');
          }
          return $q.resolve();
        }

        if ((account.firstName === undefined || account.firstName === '') && details.name) {
          account.firstName = details.name;
          fields.push('firstName');
        }

        if ((account.telephone === undefined || account.telephone === '') && details.phone) {
          account.telephone = details.phone;
          fields.push('telephone');
        }

        if ((account.country === undefined || account.country === '') && details.country) {
          account.country = details.country;
          fields.push('country');
        }

        if (fields.length > 0) {
          return btShareScopeService.updateProfileSettingsFromFieldsList(fields);
        } else {
          return $q.resolve();
        }
      }

      /**
       *
       * @param {*} reason
       */
      function onFail(reason) {
        $scope.errorAlert = 'For some reason log in process was failed. Pleasure contact our support team.';

        if (reason.code === 'LOGIN_FAILED_EMAIL_NOT_VERIFIED') {
          $scope.errorAlert = 'You forgot to verify email.';
        } else if (reason.code === 'LOGIN_FAILED_WHITELIST') {
          $scope.errorAlert = 'Looks that you need to sign up.';
        } else if (reason.code === 'LOGIN_FAILED') {
          $scope.errorAlert = 'Wrong username or password.';
        } else if (reason.message) {
          $scope.errorAlert = reason.message;
        }
      }

      /**
       *
       */
      function onFinal() {
        $ionicLoading.hide();
      }
    }
  }
})();
