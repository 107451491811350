/**
 * Created by xxx on xxx.
 */

(function btInstrumentsContainerClosure() {
  'use strict';

  var gDebug = false;

  /**
   * This directive display and update list of instruments.
   *
   * @ngdoc directive
   * @name btInstrumentsContainer
   * @memberOf ecapp
   * @restrict E
   */

  angular
    .module('ecapp')
    .directive('btInstrumentsContainer', btInstrumentsContainer)
    .controller('btInstrumentsContainerController', btInstrumentsContainerController);

  btInstrumentsContainer.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {any}
   */
  function btInstrumentsContainer($templateCache) {
    return {
      restrict: 'E',
      template: $templateCache.get('directives/instruments/instruments-container.html'),
      controller: 'btInstrumentsContainerController',
    };
  }

  btInstrumentsContainerController.$inject = [
    '$scope',
    '$interval',
    '$q',
    'btInstrumentsService',
    'btTradingService',
    'btSettingsService',
  ];

  /**
   *
   * @param {*} $scope
   * @param {angular.IIntervalService} $interval
   * @param {angular.IQService} $q
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btInstrumentsContainerController(
    $scope,
    $interval,
    $q,
    btInstrumentsService,
    btTradingService,
    btSettingsService
  ) {
    $scope.hasLoaded = false;

    /** @type {BTInstrumentMarketData[]} */
    $scope.instrumentsDatas = [];

    //TODO: start use brokerObject.hasPastData
    $scope.hasPastData = true;

    $scope.hasPrices = btSettingsService.hasFeature('prices');

    $scope.isBrokerConnected = false;
    $scope.$on('$destroy', onDestroy);

    var gIntervalPromise = null;
    var gRelatedInstruments = [];

    activate();

    /**
     * @typedef {Object} BTInstrumentMarketData
     * @property {ecapp.ITradingInstrument} instrument -
     * @property {Object} data -
     * @property {Boolean} hasMarketData -
     * @property {Boolean} loadingMarketData -
     */

    /**
     *
     */
    function activate() {
      $q.all([initInstruments(), initTrading()])
        .then(prepare)
        .catch(function (reason) {
          console.error('btInstrumentsContainer: error', reason);
        });
    }

    /**
     *
     * @return {any}
     */
    function initInstruments() {
      return btInstrumentsService.init();
    }

    /**
     *
     * @return {any}
     */
    function initTrading() {
      if ($scope.hasPrices) {
        return btTradingService.initialize().then(btTradingService.connect);
      } else {
        return $q.resolve();
      }
    }

    /**
     *
     */
    function prepare() {
      console.log('Data has been loaded');

      if ($scope.hasPrices) {
        $scope.hasPastData = btTradingService.getBrokerName() !== 'ctrader';
        $scope.isBrokerConnected = btTradingService.isConnected() && !btTradingService.isDefaultBroker();
      } else {
        $scope.hasPastData = false;
        $scope.isBrokerConnected = false;
      }

      $scope.hasLoaded = true;

      if ($scope.rowModalData.eventsInfo.relatedInstruments != null) {
        $scope.rowModalData.eventsInfo.relatedInstruments.forEach(function (obj) {
          // gRelatedInstruments.push(btInstrumentsService.getInstrumentBySomeSymbol(obj));
          gRelatedInstruments.push(btInstrumentsService.getInstrumentSmart(obj));
        });
      }

      if (gRelatedInstruments.length === 0) {
        console.log('No instruments');
      } else {
        if ($scope.hasPrices) {
          if (gDebug) console.log('Interval was called (updateInstrumentsData)');
          var rateLimit = btTradingService.getLimit('getQuotes');
          // gRelatedInstruments.length can be zero
          var period = parseInt(600 / parseInt(rateLimit / gRelatedInstruments.length)) * 100;
          console.log('Calculated refreshing time equal to', period, 'ms');
          updateInstrumentsData(gRelatedInstruments);
          gIntervalPromise = $interval(updateInstrumentsData.bind(null, gRelatedInstruments), Math.max(period, 1000));
        } else {
          updateInstrumentsData(gRelatedInstruments);
        }
      }
    }

    /**
     * React on destroying of controller
     */
    function onDestroy() {
      if (gIntervalPromise) $interval.cancel(gIntervalPromise);
      console.log('Controller btInstrument was destroyed!');
    }

    /**
     * Check is instrument
     *
     * @param {ecapp.ITradingInstrument} instrument - instrument market data
     * @return {Number}
     */
    function isExist(instrument) {
      var insIdx = -1;
      for (var i = 0; i < $scope.instrumentsDatas.length; i++) {
        var arrObj = $scope.instrumentsDatas[i];

        if (arrObj && arrObj.instrument === instrument) {
          insIdx = i;
        }
      }
      return insIdx;
    }

    /**
     * Update market data for all instruments
     *
     * @param {ecapp.ITradingInstrument[]} instruments
     */
    function updateInstrumentsData(instruments) {
      if (gDebug) console.log('Interval was called (updateInstrumentsData)');

      var symbols = [];
      instruments.forEach(function (instrument) {
        if (instrument && instrument.brokerSymbol) {
          if ($scope.hasPrices) {
            symbols.push(instrument.getSymbol());
          } else {
            handleInstrumentMarketData(undefined, instrument);
          }
        }
      });

      btTradingService
        .getLiveCandlesData(symbols)
        .then(function (values) {
          values.forEach(function (value, i) {
            handleInstrumentMarketData(value, instruments[i]);
          });
        })
        .catch(function (reason) {
          console.log('btInstrumentsContainer: error', reason);
        });
    }

    /**
     * Handle market data for instrument
     *
     * @param {btLiveCandleObject} [data] - market data
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     */
    function handleInstrumentMarketData(data, instrument) {
      var index = isExist(instrument);
      if (index !== -1) {
        $scope.instrumentsDatas[index].instrument = instrument;
        $scope.instrumentsDatas[index].priceData = data;
        $scope.instrumentsDatas[index].hasMarketData = data !== undefined;
        $scope.instrumentsDatas[index].loadingMarketData = false;
      } else {
        $scope.instrumentsDatas.push({
          instrument: instrument,
          priceData: data,
          hasMarketData: data !== undefined,
          loadingMarketData: false,
        });
      }
    }
  }
})();
