/**
 * Created by Sergey Panpurin on 12/11/2016.
 */

// @ts-check
(function btTradeIdeasServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btTradeIdeasService:';

  angular.module('ecapp').factory('btTradeIdeasService', btTradeIdeasService);

  btTradeIdeasService.$inject = [
    '$ionicPlatform',
    '$rootScope',
    '$q',
    '$state',
    'Rows',
    'btRowProcessorService',
    'btDateService',
    'btStrengthService',
    'btInstrumentsService',
    'btDefaultSpeechImage',
    'btDefaultReportImage',
    'btTradingCardService',
    'btMarketMovementService',
    'btEventsService',
    'btTradingService',
    'btImportanceFilter',
    'btTradingSessionsService',
    'btShareScopeService',
    'btEventEmitterService',
    'btPusherService',
    'btTradeIdeasFiltersService',
    '$interval',
    'btMomentConverterService',
    'btRestrictionService',
    'btWatchListService',
  ];

  /**
   *  This factory works with trade ideas.
   *
   *  1. Initialization
   *  On application loading initial trade ideas should be loading. Application shows just trade ideas in last 24 hours.
   *  All trade ideas in this time frame should be saved to main storage.
   *
   *  2. Real-time connection
   *  As soon as possible service should start listening for new trade ideas via Pusher. New trade ideas should be
   *  saved to main storage.
   *
   *  3. Update (Is it necessary?)
   *  Trade ideas should be updated.
   *
   *  4. Recover missing data
   *  On mobile some real-time data can be missed. Those data should be loaded from server.
   *
   *  Trade Ideas Storing
   *  The service should store all trade ideas in last 24 hours. New trade ideas should be added to main storage.
   *  It may be useful to store separately trade ideas from user watchlist and active trade ideas.
   *
   *  Time-dependent properties
   *  Trade idea has some time-dependent properties. For example: expired status, how long ago was it released.
   *  These properties should be updated in some interval or it should be a function that can be called on demand.
   *
   * TODO:
   *  1. Implement trade ideas recovering.
   *  2. Implement broker reconnection.
   *  3. Test user log in and user log out behavior.
   *
   * @ngdoc service
   * @name btTradeIdeasService
   * @memberOf ecapp
   * @param {ionic.IPlatformService} $ionicPlatform
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.IQService} $q
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.IGeneralLoopbackService} lbRows
   * @param {ecapp.IRowProcessorService} btRowProcessorService
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IStrengthService} btStrengthService
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.IDefaultSpeechImage} btDefaultSpeechImage
   * @param {ecapp.IDefaultReportImage} btDefaultReportImage
   * @param {ecapp.ITradingCardService} btTradingCardService
   * @param {ecapp.IMarketMovementService} btMarketMovementService
   * @param {ecapp.IEventsService} btEventsService
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.IImportanceFilter} btImportanceFilter
   * @param {ecapp.ITradingSessionsService} btTradingSessionsService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IEventEmitterService} btEventEmitterService
   * @param {ecapp.IPusherService} btPusherService
   * @param {ecapp.ITradeIdeasFiltersService} btTradeIdeasFiltersService
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.IMomentConverterService} btMomentConverterService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IWatchListService} btWatchListService
   * @return {ecapp.ITradeIdeasService}
   */
  function btTradeIdeasService(
    $ionicPlatform,
    $rootScope,
    $q,
    $state,
    lbRows,
    btRowProcessorService,
    btDateService,
    btStrengthService,
    btInstrumentsService,
    btDefaultSpeechImage,
    btDefaultReportImage,
    btTradingCardService,
    btMarketMovementService,
    btEventsService,
    btTradingService,
    btImportanceFilter,
    btTradingSessionsService,
    btShareScopeService,
    btEventEmitterService,
    btPusherService,
    btTradeIdeasFiltersService,
    $interval,
    btMomentConverterService,
    btRestrictionService,
    btWatchListService
  ) {
    console.log('Running btTradeIdeasService');

    /**
     * This object can be used for Trade Idea generation.
     * @typedef {Object} btConvertedTradingInsightObject
     * @property {String} origin -
     * @property {String} kind -
     * @property {Number} rowId -
     * @property {btInsight} insight -
     * @property {Number} time -
     * @property {Number} eventId -
     * @property {String} market -
     * @property {String} symbol -
     * @property {String} btMarket -
     * @property {?Number} releaseStrength -
     */

    /**
     * Service was initialized.
     * @type {Boolean}
     */
    var gInitialized = false;

    /**
     * Initialization promise.
     * @type {angular.IPromise<Boolean>}
     */
    var gInitializationPromise;

    /**
     * Storage for all trade ideas. It contains all trade ideas in last 24 hours. The first element is the newest.
     * Note: This array should not be reassigned.
     * @type {bt.TradeIdeaObject[]}
     */
    var gMainStorage = [];

    /**
     * Storage for active trade ideas. It contains only active trade ideas. The first element is the newest.
     * Note: This array should not be reassigned.
     * @type {bt.TradeIdeaObject[]}
     */
    var gActiveStorage = [];

    /**
     * Storage time in milliseconds. After that time trade idea will be deleted from storage.
     * @type {Number}
     */
    var gStorageTime = 28 * 60 * 60 * 1000;

    /**
     * Storage limit. Maximal number of trade ideas in storage.
     * @type {Number}
     */
    var gStorageLimit = 1000;

    var gThresholds = { weak: 0.6 };

    var gPusherConnected = false;

    var gWatchlist;

    $interval(updateStoredTradeIdeas, 5000, 0, false);

    btEventEmitterService.addListener('login:success', onLoginSuccess);
    btEventEmitterService.addListener('logout:success', onLogoutSuccess);
    $rootScope.$on('broker:connected', onBrokerConnected);
    $rootScope.$on('broker:disconnected', onBrokerDisconnected);
    $rootScope.$on('trade-ideas-filters:updated', onTradeIdeasFiltersUpdated);
    $ionicPlatform.on('resume', onResume);

    /**
     * This function updates time-dependent properties of stored trade ideas.
     */
    function updateStoredTradeIdeas() {
      gMainStorage.forEach(function (value) {
        value.updateInterval();
        value.counts.update++;
      });

      if (gActiveStorage.length) updateActiveStorage();
    }

    /**
     * Trade Idea Class
     * @memberof bt
     * @param {btRawMomentInsight|btRawTradingInsightContainer|btConvertedTradingInsightObject} rawData
     * @class
     */
    function TradeIdeaObject(rawData) {
      /**
       * Type of trade idea: release or moment
       * @name bt.TradeIdeaObject#type
       * @type {String}
       * @readonly
       */

      /**
       * Trade idea identifier
       * @name bt.TradeIdeaObject#id
       * @type {String}
       * @readonly
       */

      /**
       * Timestamp of trade idea (in seconds)
       * @name bt.TradeIdeaObject#time
       * @type {Number}
       * @readonly
       */

      /**
       * Date of trade idea
       * @name bt.TradeIdeaObject#date
       * @type {Date}
       * @readonly
       */

      /**
       * Calculation data of trade idea
       * @name bt.TradeIdeaObject#data
       * @type {Object}
       * @readonly
       */

      /**
       * Instrument of trade idea
       * @name bt.TradeIdeaObject#instrument
       * @type {Object}
       * @readonly
       */

      /**
       * Complex symbol of trade idea
       * @name bt.TradeIdeaObject#complexSymbol
       * @type {String}
       * @readonly
       */

      /**
       * Display symbol of trade idea
       * @name bt.TradeIdeaObject#displaySymbol
       * @type {String}
       * @readonly
       */

      /**
       * Broker symbol of trade idea
       * @name bt.TradeIdeaObject#brokerSymbol
       * @type {String}
       */

      /**
       * Raw data
       * @name bt.TradeIdeaObject#raw
       * @type {btRawMomentInsight|btRawTradingInsightContainer|btConvertedTradingInsightObject}
       * @readonly
       */

      /**
       * Complex symbol of trade idea
       * @name bt.TradeIdeaObject#complexSymbol
       * @type {String}
       * @readonly
       */

      /**
       * Complex symbol of trade idea
       * @name bt.TradeIdeaObject#trigger
       * @type {btTradeIdeaTrigger}
       * @readonly
       */

      /**
       * @typedef {Object} btTradeIdeaTrigger
       * @property {String} text - trigger text
       * @property {String} html - trigger html
       * @property {String} link - link to release page
       * @property {String} dev - dev info
       */

      if (typeof rawData.time === 'number') {
        if (rawData.time < 1262347200000) {
          this.time = rawData.time;
          this.date = new Date(rawData.time * 1000);
        } else {
          this.time = Math.floor(rawData.time / 1000);
          this.date = new Date(rawData.time);
        }
      } else {
        this.date = new Date(rawData.time);
        this.time = Math.floor(this.date.getTime() / 1000);
      }

      if (rawData && rawData.insight && rawData.kind !== 'moment') {
        this.type = 'release';
        this.id = rawData.rowId + '-' + rawData.time + '-' + rawData.market;

        this.data = angular.copy(rawData.insight.data);

        this.raw = rawData;
        this.trigger = generateReleaseTrigger(rawData);
      } else {
        this.type = 'moment';
        this.id = rawData.id || rawData._id;

        this.data = angular.copy(rawData.data);

        this.raw = rawData;
        this.trigger = generateMomentTrigger(rawData);
      }

      if (this.data.market.indexOf('OANDA') === -1) {
        this.instrument = btInstrumentsService.getInstrumentBySomeSymbol(this.data.market);
        this.complexSymbol = this.instrument.complexSymbol;
        // this.complexSymbol = btInstrumentsService.createComplexSymbol(
        //   this.instrument.brokerSymbol,
        //   this.instrument.brokerName
        // );
        this.displaySymbol = this.instrument.displayName;
        this.brokerSymbol = this.instrument.brokerSymbol;
      } else {
        this.complexSymbol = this.data.market;
        this.instrument = btTradingService.getInstrumentByBrokerSymbol(
          btInstrumentsService.getSymbol(this.complexSymbol)
        );
        this.displaySymbol = this.instrument.displayName;
        this.brokerSymbol = this.instrument.brokerSymbol;

        // fix market name (convert complex symbol to bt symbol) (create copy of data to prevent problem)
        this.data.market = btInstrumentsService.convertComplexName2BTName(this.data.market);
      }

      this.locked = false;
      this.icon = this.getIcon();

      this.counts = {
        update: 0,
      };

      this.sessions = {
        us: btTradingSessionsService.checkDate('us', this.date),
        eu: btTradingSessionsService.checkDate('eu', this.date),
        as: btTradingSessionsService.checkDate('as', this.date),
        au: btTradingSessionsService.checkDate('au', this.date),
      };

      this.side = this.data.action === 'uptrend' ? 'long' : 'short';
      this.rate = this.data.successRate.value / 100;
      this.quality = this.data.quality;

      this.rule = this.data.rule ? this.data.rule : 'old';
      this.successRate = Math.round((this.data.win / this.data.total) * 100);
      this.successText = this.data.win + '/' + this.data.total;

      /** @type {String} */
      this.text =
        this.data.win +
        ' out of ' +
        this.data.total +
        ' (' +
        this.successRate +
        '%) times ' +
        this.displaySymbol +
        (this.data.class === 'positive' ? ' moved up ' : ' moved down ') +
        'following similar release';

      var positive = '</span> moved <span class="positive">up</span> ';
      var negative = '</span> moved <span class="negative">down</span> ';

      /** @type {String} */
      this.html =
        '<span class="bt-highlighted">' +
        this.data.win +
        ' out of ' +
        this.data.total +
        '</span> ' +
        ' (' +
        this.successRate +
        '%) times ' +
        '<span class="bt-highlighted">' +
        this.displaySymbol +
        (this.data.class === 'positive' ? positive : negative) +
        'following similar release';

      /** @type {Number} */
      this.minAfter = btTradingCardService.getMinutesAfter(this.type);

      this.updateInterval();

      this.enable = true;
      this.collapsed = true;

      var bit = btInstrumentsService.getPriceUnitValue(this.instrument);
      var unit = btInstrumentsService.getPriceUnitName(this.instrument);
      this.trade = btTradingCardService.getTradeDataGeneral(this.data, this.data.tradeData, this.date, bit, unit);

      if (this.trade) {
        this.ratio = this.trade.ratio;
        this.duration = this.trade.delay.reward;
      } else {
        this.ratio = 0;
        this.duration = 0;
      }

      /**
       * @typedef {Object} btConvertedInsight
       * @property {String} origin -
       * @property {String} kind -
       * @property {String} rowId -
       * @property {String} eventId -
       * @property {Number} time -
       * @property {btRawTradingInsight} insight -
       * @property {String} market -
       * @property {null} releaseStrength -
       */

      /**
       *
       * @type {btConvertedInsight}
       */
      this.convertedInsight = {
        kind: this.type,
        origin: 'pusher',
        rowId: '1',
        eventId: '1',
        time: this.time,
        insight: rawData.insight,
        market: btInstrumentsService.convertComplexName2BTName(this.data.market),
        releaseStrength: null,
      };

      if (!this.convertedInsight.insight) {
        this.convertedInsight.insight = {
          id: 1,
          type: 'type',
          templateVars: {},
          totalSurpriseStrength: 1,
          totalStrength: 1,
          strengths: {},
          template: 'template',
          data: JSON.parse(JSON.stringify(this.data)),
        };
      }
    }

    TradeIdeaObject.statuses = ['active', 'expired'];

    TradeIdeaObject.prototype.getIcon = function getIcon() {
      if (this.locked) {
        return 'bt-trade-idea-icon ion-locked';
      } else {
        if (this.data.action === 'uptrend') {
          return 'bt-trade-idea-icon ion-arrow-graph-up-right';
        } else {
          return 'bt-trade-idea-icon ion-arrow-graph-down-right';
        }
        // $yellow-text-color
      }
    };

    TradeIdeaObject.prototype.isSame = function isSame(tradeIdea) {
      return this.id === tradeIdea.id;
    };

    TradeIdeaObject.prototype.isObsolete = function isObsolete(now) {
      now = now || new Date();
      return now - this.date > gStorageTime;
    };

    TradeIdeaObject.prototype.updateInterval = function updateInterval() {
      /** @type {Boolean} */
      this.isExpired = btTradingCardService.isExpired(this.time, this.minAfter);
      this.isWaiting = btTradingCardService.isWaiting(this.time, this.minAfter);

      if (this.data && this.data.tradeData && this.data.tradeData.reward && this.data.tradeData.reward.time) {
        var a = this.data.tradeData.reward.time.avg;
        this.isOld = btTradingCardService.isOld(this.time, this.minAfter, a);
        this.isExpiring = btTradingCardService.isExpiring(this.time, this.minAfter, a);
        // this.ttl = btTradingCardService.getTimeToLive(this.time, this.minAfter, a);
      } else {
        this.isOld = btTradingCardService.isOld(this.time, this.minAfter);
        this.isExpiring = btTradingCardService.isExpiring(this.time, this.minAfter);
        // this.ttl = btTradingCardService.getTimeToLive(this.time, this.minAfter);
      }

      this.status = this.isExpired ? TradeIdeaObject.statuses[1] : TradeIdeaObject.statuses[0];
      this.detected = btDateService.getHumanisedTimeFromNow(this.date, true);
      this.entry = btDateService.getHumanisedTimeFromNow(
        btTradingCardService.getEntryTime(this.time, this.minAfter),
        true
      );

      if (this.type === 'moment') {
        this.locked = btDateService.getFreeDelay(this.date) > 0 && !btRestrictionService.hasFeature('trade-ideas');
      }

      if (this.type === 'release') {
        this.locked = btDateService.getFreeDelay(this.date) > 0 && !btRestrictionService.hasFeature('trade-ideas');
      }

      this.icon = this.getIcon();
    };

    TradeIdeaObject.prototype.passUserFilter = function (f) {
      if (f) {
        // Checks if "show all trade ideas" is on.
        if (!!f['ignore']) {
          // Checks if symbol is in watchlist.
          var symbol = this.instrument.id;
          return (
            gWatchlist &&
            gWatchlist.filter(function (instrument) {
              return instrument.id === symbol;
            })[0] !== undefined
          );
        }

        if (f['characteristics'] && f['characteristics'].enabled) {
          var c = f['characteristics'];
          if (c['expired'] && this.isOld) {
            if (gDebug) console.log(gPrefix, 'Trade Idea Filter: expired', this.isOld, c['expired']);
            return false;
          }

          if (c['instruments'] && c['instruments'].indexOf(this.instrument.id) < 0) {
            if (gDebug)
              console.log(gPrefix, 'Trade Idea Filter: out of custom watchlist', this.instrument.id, c['instruments']);
            return false;
          }

          if (c['ratio'] && !between(this.ratio, c['ratio'])) {
            if (gDebug) console.log(gPrefix, 'Trade Idea Filter: bad ratio', this.ratio, this.trade.ratio, c['ratio']);
            return false;
          }

          if (c['side'] && c['side'].indexOf(this.side) < 0) {
            if (gDebug) console.log(gPrefix, 'Trade Idea Filter: bad side', this.side, c['side']);
            return false;
          }

          if (c['rate'] && !between(this.rate, c['rate'])) {
            if (gDebug) console.log(gPrefix, 'Trade Idea Filter: bad success rate', this.rate, c['rate']);
            return false;
          }

          if (c['duration'] && !between(this.duration, c['duration'])) {
            if (gDebug) console.log(gPrefix, 'Trade Idea Filter: bad duration', this.duration, c['duration']);
            return false;
          }

          if (c['quality'] && this.quality < c['quality']) {
            if (gDebug) console.log(gPrefix, 'Trade Idea Filter: bad quality', this.quality, c['quality']);
            return false;
          }
        } else {
          return false;
        }

        if (this.type === 'release') {
          if (f['news-driven'] && f['news-driven'].enabled) {
            var n = f['news-driven'];
            var en = this.trigger.event;
            var s = this.trigger.strength;

            if (n['triggered-by']) {
              if (n['triggered-by'] === 'following' && !isFollowingEvent(en)) {
                if (gDebug)
                  console.log(
                    gPrefix,
                    'Trade Idea Filter: bad trigger',
                    en.importance,
                    en.currency,
                    n['triggered-by'],
                    n['priority'],
                    n['currencies']
                  );
                return false;
              }

              if (n['triggered-by'] === 'calendar' && !isCalendarEvent(en, n['priority'], n['currencies'])) {
                if (gDebug)
                  console.log(
                    gPrefix,
                    'Trade Idea Filter: bad trigger',
                    en.importance,
                    en.currency,
                    n['triggered-by'],
                    n['priority'],
                    n['currencies']
                  );
                return false;
              }
            }

            if (n['priority'] && en.importance < n['priority']) {
              if (gDebug) console.log(gPrefix, 'Trade Idea Filter: bad priority', en.importance, n['priority']);
              return false;
            }

            if (n['currencies'] && n['currencies'].indexOf(en.currency) < 0) {
              if (gDebug) console.log(gPrefix, 'Trade Idea Filter: bad currency', en.currency, n['currencies']);
              return false;
            }

            if (n['low-magnitude'] && btStrengthService.isLowMagnitude(s.magnitude)) {
              if (gDebug) console.log(gPrefix, 'Trade Idea Filter: low magnitude', s.magnitude, n['low-magnitude']);
              return false;
            }

            if (n['magnitude'] && btStrengthService.passMagnitudeFilter(s.magnitude, n['magnitude'].value)) {
              if (gDebug)
                console.log(gPrefix, 'Trade Idea Filter: out of magnitude-range', s.magnitude, n['magnitude']);
              return false;
            }
          } else {
            if (gDebug) console.log(gPrefix, 'Trade Idea Filter: skip news-driven');
            return false;
          }
        }

        if (this.type === 'moment') {
          if (f['price-driven'] && f['price-driven'].enabled) {
            var p = f['price-driven'];
            var ep = this.trigger;
            if (p['small-movement'] && ep.isSmallMovement) {
              if (gDebug)
                console.log(gPrefix, 'Trade Idea Filter: small movement', ep.isSmallMovement, p['small-movement']);
              return false;
            }

            var passSessionFilter = false;
            if (p['us-session'] && p['us-session'].indexOf(this.sessions.us) >= 0) passSessionFilter = true;
            if (p['eu-session'] && p['eu-session'].indexOf(this.sessions.eu) >= 0) passSessionFilter = true;
            if (p['as-session'] && p['as-session'].indexOf(this.sessions.as) >= 0) passSessionFilter = true;
            if (p['au-session'] && p['au-session'].indexOf(this.sessions.au) >= 0) passSessionFilter = true;

            if (passSessionFilter === false) {
              if (gDebug)
                console.log(
                  gPrefix,
                  'Trade Idea Filter: out of sessions',
                  'us',
                  this.sessions.us,
                  p['us-session'],
                  'eu',
                  this.sessions.eu,
                  p['eu-session'],
                  'as',
                  this.sessions.as,
                  p['as-session'],
                  'au',
                  this.sessions.au,
                  p['au-session']
                );
              return false;
            }

            if (p['linking'] && p['linking'].indexOf(ep.linking) < 0) {
              if (gDebug) console.log(gPrefix, 'Trade Idea Filter: bad linking', ep.linking, p['linking']);
              return false;
            }

            if (!p['cross-yesterday'] && ep.type === 'cross-yesterday') {
              if (gDebug)
                console.log(gPrefix, 'Trade Idea Filter: skip crossing yesterday', ep.type, p['cross-yesterday']);
              return false;
            }

            if (!p['day-change'] && ep.type === 'day-change') {
              if (gDebug) console.log(gPrefix, 'Trade Idea Filter: skip daily change', ep.type, p['day-change']);
              return false;
            }

            if (!p['intraday-movement'] && ep.type === 'intraday-movement') {
              if (gDebug)
                console.log(gPrefix, 'Trade Idea Filter: skip intraday movement', ep.type, p['intraday-movement']);
              return false;
            }

            if (!p['interval-change'] && ep.type === 'interval-change') {
              if (gDebug)
                console.log(gPrefix, 'Trade Idea Filter: skip intraday movement', ep.type, p['interval-change']);
              return false;
            }

            if (!p['consecutive'] && ep.type === 'consecutive') {
              if (gDebug)
                console.log(gPrefix, 'Trade Idea Filter: skip consecutive candles', ep.type, p['consecutive']);
              return false;
            }
          } else {
            if (gDebug) console.log(gPrefix, 'Trade Idea Filter: skip price-driven');
            return false;
          }
        }
      }

      return true;
    };

    /**
     *
     * @param {btRawMomentInsight|btRawTradingInsightContainer} tradeIdea
     * @return {bt.TradeIdeaObject}
     */
    TradeIdeaObject.convertToTradeIdea = function (tradeIdea) {
      fixTime(tradeIdea);
      return new TradeIdeaObject(tradeIdea);
    };

    return {
      initialize: initialize,
      getCachedActiveTradeIdeas: getCachedActiveTradeIdeas,
      refreshCachedActiveTradeIdeas: refreshCachedActiveTradeIdeas,
      getTradeIdeasBySymbols: getTradeIdeasBySymbols,
      getTradeIdeasByDates: getTradeIdeasByDates,
      parseInstrumentTradeIdea: parseInstrumentTradeIdea,
      isGoodTradeIdea: isGoodTradeIdea,
      convertToTradeIdea: TradeIdeaObject.convertToTradeIdea,
      generateReleaseTrigger: generateReleaseTrigger,
      generateEventTrigger: generateEventTrigger,
      generateMomentTrigger: generateMomentTrigger,
    };

    /**
     * This function initializes trade idea service.
     *
     * @return {angular.IPromise<Boolean>}
     */
    function initialize() {
      if (gInitializationPromise === undefined) {
        bindPusherHandlers();
        gInitializationPromise = btWatchListService
          .initialize()
          .then(function () {
            gInitialized = true;
            gWatchlist = btWatchListService.getUserWatchlist();
            return true;
          })
          .catch(function (reason) {
            gInitialized = false;
            gInitializationPromise = undefined;
            console.error(gPrefix, reason);
            return $q.reject(reason);
          });
      }
      return gInitializationPromise;
    }

    /**
     * This function loads past trade ideas.
     *
     * @private
     * @return {angular.IPromise<*>}
     */
    function loadPastTradeIdeas() {
      return getTradeIdeasBySymbols([]).then(function (tradeIdeas) {
        tradeIdeas.forEach(function (tradeIdea) {
          gMainStorage.push(tradeIdea);
        });
        gMainStorage.sort(compareStoredTradeIdeasByDate);
        updateStorage();
      });
    }

    /**
     * This function updates trade ideas storage.
     */
    function updateStorage() {
      updateMainStorage();
      updateActiveStorage();
    }

    /**
     * This function updates main trade ideas storage.
     */
    function updateMainStorage() {
      // remove obsolete trade ideas
      var obsoleteTradeIdeas = [];
      var now = new Date();
      gMainStorage.forEach(function (tradeIdea, i) {
        if (tradeIdea.isObsolete(now)) obsoleteTradeIdeas.push(i);
      });

      if (obsoleteTradeIdeas.length > 0) {
        if (gDebug)
          console.log(gPrefix, obsoleteTradeIdeas.length + ' trade ideas have been removed due to obsolescence.');
        obsoleteTradeIdeas.reverse().forEach(function (value) {
          gMainStorage.splice(value, 1);
        });
        if (gDebug) console.log(gPrefix, 'current number of trade ideas in storage is ' + gMainStorage.length);
      }

      // limit number of trade ideas
      if (gMainStorage.length > gStorageLimit) {
        if (gDebug)
          console.log(
            gPrefix,
            'number of tradeideas ' + gMainStorage.length + ' was limited to ' + gStorageLimit + '.'
          );
        gMainStorage.splice(gStorageLimit, gMainStorage.length - gStorageLimit);
      }
    }

    /**
     * This function updates active storage.
     *
     * @param {tradeIdeasFiltersObject} [filters] - trade ideas filters
     */
    function updateActiveStorage(filters) {
      var wasUpdated = false;
      filters = filters || btTradeIdeasFiltersService.getUserSettings();

      // remove bad trade ideas from active storage
      var badTradeIdeas = [];
      gActiveStorage.forEach(function (tradeIdea, i) {
        if (tradeIdea.isOld || !tradeIdea.passUserFilter(filters)) badTradeIdeas.push(i);
      });

      if (badTradeIdeas.length) {
        wasUpdated = true;
        if (gDebug) console.log(gPrefix, badTradeIdeas.length + ' active trade ideas have been removed.');
        badTradeIdeas.reverse().forEach(function (value) {
          gActiveStorage.splice(value, 1);
        });
      }

      // move new trade ideas from main storage to active storage
      var count = 0;
      gMainStorage.forEach(function (tradeIdea) {
        if (tradeIdea.passUserFilter(filters) && !tradeIdea.isOld && gActiveStorage.indexOf(tradeIdea) === -1) {
          wasUpdated = true;
          gActiveStorage.unshift(tradeIdea);
          count++;
        }
      });

      if (count) {
        if (gDebug) console.log(gPrefix, count + ' new trade ideas have been added.');
      }

      if (wasUpdated) {
        $rootScope.$broadcast('active-trade-ideas:updated');
      }
    }

    /**
     * This function clears trade ideas storage.
     */
    function clearStorage() {
      var n = gMainStorage.length;
      for (var i = 0; i < n; i++) {
        gMainStorage.pop();
      }

      var m = gActiveStorage.length;
      for (var j = 0; j < m; j++) {
        gActiveStorage.pop();
      }

      $rootScope.$broadcast('active-trade-ideas:updated');
    }

    /**
     * This function reacts to user log in action.
     */
    function onLoginSuccess() {
      try {
        if (gDebug) console.log(gPrefix, 'user logged in');
        initialize();
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * This function reacts to user log out action.
     */
    function onLogoutSuccess() {
      try {
        if (gDebug) console.log(gPrefix, 'user logged out');
        unbindPusherHandlers();
        clearStorage();
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * This function reacts to broker connection.
     *
     * @param {Object} event - event object
     * @param {Object} data - event data
     */
    function onBrokerConnected(event, data) {
      try {
        loadPastTradeIdeas();
        if (gDebug) console.log(gPrefix, 'broker was connected', event, data);
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * This function reacts to broker disconnection.
     *
     * @param {Object} event - event object
     * @param {Object} data - event data
     */
    function onBrokerDisconnected(event, data) {
      try {
        clearStorage();
        if (gDebug) console.log(gPrefix, 'broker was disconnected', event, data);
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * This function reacts on application resuming.
     */
    function onResume() {
      refreshCachedActiveTradeIdeas().catch(function (reason) {
        console.error(reason);
      });
    }

    /**
     * This function reacts to trade ideas filters update.
     *
     * @param {Object} event - event object
     * @param {Object} data - event data
     */
    function onTradeIdeasFiltersUpdated(event, data) {
      try {
        if (gDebug) console.log(gPrefix, 'trade ideas filters were updated', event, data);
        updateActiveStorage(data);
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * Start listening to pusher channels.
     */
    function bindPusherHandlers() {
      if (!gPusherConnected) {
        gPusherConnected = true;

        btPusherService.channels.tradingInsights.bind('update', handleNewsDrivenTradeIdeas);
        btPusherService.channels.moments.bind('update', handlePriceDrivenTradeIdeas);

        // add developers events
        if (window.isDevelopment) {
          btPusherService.channels.tradingInsights.bind('update-dev', handleNewsDrivenTradeIdeas);
          btPusherService.channels.moments.bind('update-dev', handlePriceDrivenTradeIdeas);
        }

        // add testing events
        if (window.isTesting) {
          btPusherService.channels.tradingInsights.bind('update-test', handleNewsDrivenTradeIdeas);
          btPusherService.channels.moments.bind('update-test', handlePriceDrivenTradeIdeas);
        }
      }
    }

    /**
     * Stop listen pusher
     */
    function unbindPusherHandlers() {
      if (gPusherConnected) {
        btPusherService.channels.tradingInsights.unbind('update', handleNewsDrivenTradeIdeas);
        btPusherService.channels.moments.unbind('update', handlePriceDrivenTradeIdeas);

        // add developers events
        if (window.isDevelopment) {
          btPusherService.channels.tradingInsights.unbind('update-dev', handleNewsDrivenTradeIdeas);
          btPusherService.channels.moments.unbind('update-dev', handlePriceDrivenTradeIdeas);
        }

        // add testing events
        if (window.isTesting) {
          btPusherService.channels.tradingInsights.unbind('update-test', handleNewsDrivenTradeIdeas);
          btPusherService.channels.moments.unbind('update-test', handlePriceDrivenTradeIdeas);
        }
      }
    }

    /**
     *
     * @param {*} message
     */
    function handlePriceDrivenTradeIdeas(message) {
      try {
        if (gDebug) console.log(gPrefix, 'pusher message', message);

        var data = btMomentConverterService.extendMoment(JSON.parse(JSON.stringify(message)));

        if (data.insights) {
          var tradeIdeas = data.insights.map(function (value) {
            return new TradeIdeaObject(value);
          });
          if (gDebug) console.log(gPrefix, 'new trade ideas', tradeIdeas);
          saveTradeIdeas(tradeIdeas);
          broadcastTradeIdeas(tradeIdeas);
        }
      } catch (e) {
        console.error(e);
      }
    }

    /**
     *
     * @param {*} value
     */
    function fixTimeString(value) {
      if (typeof value.time === 'string') {
        value.time = Math.floor(new Date(value.time).getTime() / 1000);
      }
    }

    /**
     * This function handles new driven trade ideas.
     *
     * @param {btPusherEventUpdate} message - pusher message
     */
    function handleNewsDrivenTradeIdeas(message) {
      try {
        if (gDebug) console.log(gPrefix, message);
        if (isValidPusherReleaseMessage(message)) {
          Object.keys(message).forEach(function (releaseId) {
            if (isValidPusherInsightData(message[releaseId], releaseId)) {
              var tradingInsights = parseTradingInsights(message[releaseId]);
              var tradeIdeas = tradingInsights.map(function (value) {
                var data = JSON.parse(JSON.stringify(value));
                fixTimeString(data);
                return new TradeIdeaObject(data);
              });
              saveTradeIdeas(tradeIdeas);
              broadcastTradeIdeas(tradeIdeas);
            } else {
              if (gDebug) console.log(gPrefix, 'Invalid release data.');
            }
          });
        } else {
          if (gDebug) console.log(gPrefix, 'Invalid pusher message.');
        }
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * This function checks whether pusher release message is valid.
     *
     * @param {btPusherEventUpdate} message -
     * @return {Boolean}
     */
    function isValidPusherReleaseMessage(message) {
      return typeof message === 'object';
    }

    /**
     * This function checks whether release data is valid.
     *
     * @param {btPusherInsightMessage} data - release data
     * @param {String} releaseId - stringified release identifier
     * @return {boolean}
     */
    function isValidPusherInsightData(data, releaseId) {
      return data._id && data._id.toString() === releaseId;
    }

    /**
     * This function return trading insights parsed from insight data of pusher message.
     *
     * @param {btPusherInsightMessage} data - insight data
     * @return {btConvertedTradingInsightObject[]}
     */
    function parseTradingInsights(data) {
      var tradingInsights = [];
      data.insights.forEach(function (insight) {
        if (insight.type === 'back-test') {
          var instrument = btInstrumentsService.getInstrumentBySomeSymbol(insight.data.market);

          tradingInsights.push(convertToTradingInsight(data, insight, instrument));
        }
      });

      return tradingInsights;

      /**
       * This function converts insight data of pusher message to trading insight.
       *
       * @param {btPusherInsightMessage} data
       * @param {btRawInsight} insight
       * @param {ecapp.ITradingInstrument} instrument
       * @return {btConvertedTradingInsightObject}
       */
      function convertToTradingInsight(data, insight, instrument) {
        return {
          origin: 'pusher',
          kind: 'release',
          rowId: data._id,
          insight: insight,
          time: data.time,
          eventId: data.eventId,
          market: insight.data.market,
          symbol: instrument ? instrument.displayName : insight.data.market,
          btMarket: insight.data.market,
          releaseStrength: data.releaseStrength,
        };
      }
    }

    /**
     * This function save trade ideas.
     *
     * @param {bt.TradeIdeaObject[]} tradeIdeas
     */
    function saveTradeIdeas(tradeIdeas) {
      tradeIdeas.forEach(function (tradeIdea) {
        if (hasTradeIdea(gMainStorage, tradeIdea)) {
          // Trade Idea can be recalculated. For example it could be recalculate due to correction of economic event
          // actual value. Here we just ignore this changes.
          if (gDebug) console.log(gPrefix, 'skip duplicated trade idea');
        } else {
          gMainStorage.unshift(tradeIdea);
        }
      });
      updateStorage();
    }

    /**
     *
     * @param {*} tradeIdeas
     * @param {*} newTradeIdea
     * @return {boolean}
     */
    function hasTradeIdea(tradeIdeas, newTradeIdea) {
      return (
        tradeIdeas.filter(function (tradeIdea) {
          return tradeIdea.isSame(newTradeIdea);
        }).length > 0
      );
    }

    /**
     * This function broadcast trade ideas.
     *
     * @param {bt.TradeIdeaObject[]} tradeIdeas
     */
    function broadcastTradeIdeas(tradeIdeas) {
      tradeIdeas.forEach(function (tradeIdea) {
        $rootScope.$broadcast('trade-ideas:new', tradeIdea);
      });
    }

    /**
     *
     * @param {*} value
     * @param {*} range
     * @return {boolean}
     */
    function between(value, range) {
      if (range[0] !== null && range[1] !== null) {
        return range[0] <= value && value <= range[1];
      }

      if (range[0] === null && range[1] === null) {
        return true;
      }

      if (range[0] === null) {
        return value <= range[1];
      }

      if (range[1] === null) {
        return range[0] <= value;
      }
    }

    /**
     * Fix NA time of backtesting trade ideas
     * @param {btRawMomentInsight|btRawTradingInsightContainer} tradeIdea
     */
    function fixTime(tradeIdea) {
      if (tradeIdea.time === 'NA') {
        tradeIdea.time = btDateService.getNowDate().getTime() / 1000;
      }
    }

    /**
     * This function returns trade ideas for specified instruments.
     *
     * @param {String[]} complexSymbols - list of symbols
     * @param {Number} [from] - from date
     * @param {Number} [till] - till date
     * @return {angular.IPromise<bt.TradeIdeaObject[]>}
     */
    function getTradeIdeasBySymbols(complexSymbols, from, till) {
      var btSymbols = complexSymbols.map(btInstrumentsService.convertComplexName2BTName);

      if (from === undefined || till === undefined) {
        var range = btDateService.getDayRange(24, 48);
        from = range[2];
        till = range[3];
      }

      var query = {
        symbols: btSymbols,
        brokerSymbols: complexSymbols,
        from: from,
        till: till,
      };

      return lbRows
        .getInstrumentsTradeIdeas(query)
        .$promise.then(function (promiseValue) {
          _fixMissingData(promiseValue.ideas);
          return promiseValue.ideas.map(function (t) {
            return new TradeIdeaObject(t);
          });
        })
        .then(function (tradeIdeas) {
          return tradeIdeas.filter(hasGoodRewardRiskRatio);
        })
        .then(_dumpTradeIdeas);
    }

    /**
     * This function dumps trade ideas for testing.
     *
     * @param {bt.TradeIdeaObject[]} tradeIdeas - trade ideas
     * @return {bt.TradeIdeaObject[]} - trade ideas
     * @private
     */
    function _dumpTradeIdeas(tradeIdeas) {
      // var ideas = [];
      // tradeIdeas.forEach(function (idea) {
      //   ideas.push({
      //     t: idea.time,
      //     k: idea.type === 'release' ? 0 : 1,
      //     i: idea.instrument.displayName,
      //     a: idea.data.action === 'uptrend' ? 0 : 1,
      //     q: idea.quality,
      //     r: idea.trigger.text,
      //     s: {t: idea.data.total, w: idea.data.win, l: idea.data.loss, p: idea.data.weak, e: idea.data.noData},
      //     d: {}
      //   });
      // });
      //
      // console.log('>>>>>>', JSON.stringify(ideas));

      return tradeIdeas;
    }

    /**
     * This function returns history of tradeideas.
     *
     * @param {*} type - events or markets
     * @param {*} id - identifier of reference trade idea trigger
     * @param {String[]} complexSymbols - list of symbols
     * @param {Number[]} dates - time range
     * @return {angular.IPromise<bt.TradeIdeaObject[]>}
     */
    function getTradeIdeasByDates(type, id, complexSymbols, dates) {
      var btSymbols = complexSymbols.map(btInstrumentsService.convertComplexName2BTName);

      var query = {
        type: type,
        id: id,
        symbols: btSymbols,
        brokerSymbols: complexSymbols,
        dates: dates,
      };

      return lbRows
        .getTradeIdeasHistory(query)
        .$promise.then(function (promiseValue) {
          _fixMissingData(promiseValue.ideas);
          return promiseValue.ideas.map(function (t) {
            return new TradeIdeaObject(t);
          });
        })
        .then(function (tradeIdeas) {
          return tradeIdeas.filter(hasGoodRewardRiskRatio);
        })
        .then(_dumpTradeIdeas);
    }

    /**
     *
     * @return {*}
     */
    function getCachedActiveTradeIdeas() {
      return gActiveStorage;
    }

    /**
     *
     * @return {angular.IPromise<void>}
     */
    function refreshCachedActiveTradeIdeas() {
      return getTradeIdeasBySymbols([]).then(function (tradeIdeas) {
        tradeIdeas.forEach(function (tradeIdea) {
          if (!hasTradeIdea(gMainStorage, tradeIdea)) {
            gMainStorage.push(tradeIdea);
          }
        });
        gMainStorage.sort(compareStoredTradeIdeasByDate);
        updateStorage();
      });
    }

    /**
     *
     * @param {bt.TradeIdeaObject} a -
     * @param {bt.TradeIdeaObject} b -
     * @return {number}
     */
    function compareStoredTradeIdeasByDate(a, b) {
      return b.date - a.date;
    }

    /**
     *
     * @param {*} releases
     * @return {*}
     * @private
     */
    function _fixMissingData(releases) {
      releases.forEach(function (release) {
        _setDefaultEventType(release);
        _setDefaultImage(release);
      });

      return releases;
    }

    /**
     *
     * @param {*} rawTradeIdeas
     * @param {*} data
     * @param {*} type
     * @param {*} complexSymbols
     * @return {Array}
     */
    function parseInstrumentTradeIdea(rawTradeIdeas, data, type, complexSymbols) {
      void data;
      void type;

      var preparedTradeIdeas = [];
      if (rawTradeIdeas) {
        rawTradeIdeas.forEach(function (tradeIdea) {
          var preparedTradeIdea = new TradeIdeaObject(tradeIdea);
          if (complexSymbols.indexOf(preparedTradeIdea.complexSymbol) !== -1) {
            preparedTradeIdeas.push(preparedTradeIdea);
          }
        });
      }

      return preparedTradeIdeas;
    }

    /**
     *
     * @param {*} release
     * @private
     */
    function _setDefaultEventType(release) {
      if (release.eventsInfo) {
        if (release.eventsInfo.eventType === undefined) {
          if (release.eventsInfo.name && release.eventsInfo.name.indexOf('Speaks') !== -1) {
            release.eventsInfo.eventType = 1;
            return;
          }

          if (release.previous === 'NA' && release.actual === 'NA') {
            release.eventsInfo.eventType = 1;
          } else {
            release.eventsInfo.eventType = 2;
          }
        }
      }
    }

    /**
     *
     * @param {*} release
     * @private
     */
    function _setDefaultImage(release) {
      if (release.eventsInfo && release.eventsInfo.speaker === undefined) {
        if (release.eventsInfo.eventType === 1) {
          release.eventsInfo.speaker = btDefaultSpeechImage;
        }

        if (release.eventsInfo.eventType === 2) {
          release.eventsInfo.speaker = btDefaultReportImage;
        }
      }
    }

    /**
     * This function returns true if trade idea has a small number of weak trades.
     * @param {Object} idea - trade idea
     * @return {Boolean} trade idea is good
     */
    function isGoodTradeIdea(idea) {
      return idea.data.weak / (idea.data.total + idea.data.weak) <= gThresholds.weak;
    }

    /**
     * Generate release trigger
     * @param {btRawTradingInsightContainer} release - release object
     * @return {{text: string, html: string, link: string, dev: string}}
     */
    function generateReleaseTrigger(release) {
      // !!! > fix error
      var rStrength = btStrengthService.prepareStrength(release.releaseStrength, release.time);

      var event = btEventsService.getEventByIdFromCache(release.eventId);

      var data = btRowProcessorService.getReleaseIdData(release, event);
      var link = $state.href('ecapp.app.main.detail', data, { absolute: false });

      var importanceConvert = btImportanceFilter(event.importance);

      return {
        event: event,
        strength: rStrength,
        text:
          'analysis of previous ' +
          event.name +
          ' ' +
          event.importance +
          ' (' +
          event.currency +
          ') releases with ' +
          rStrength.fullMsg.toLowerCase() +
          ' magnitude',
        html:
          'analysis of previous <a href="' +
          link +
          '" class="highlight-text">' +
          event.name +
          ' ' +
          importanceConvert +
          ' (' +
          event.currency +
          ') </a> releases with <span class="' +
          rStrength.class +
          '">' +
          rStrength.fullMsg.toLowerCase() +
          '</span> magnitude',
        link: link,
        dev: '',
      };
    }

    /**
     * This function returns true if event is in customized user calendar.
     * @param {btRawEvent} event - economic event object
     * @param {Number} priority - custom event priority
     * @param {String[]} currencies - custom event currencies
     * @return {Boolean}
     */
    function isCalendarEvent(event, priority, currencies) {
      if (!btShareScopeService.isInitialized()) {
        return false;
      } else {
        var currentPriority = priority ? priority : btShareScopeService.accountInfo.priority;
        var currentCurrencies = currencies ? currencies : btShareScopeService.accountInfo.currency;
        return (
          (event.importance >= currentPriority && currentCurrencies.indexOf(event.currency) !== -1) ||
          isFollowingEvent(event)
        );
      }
    }

    /**
     * This function returns true if user follows the event.
     * @param {btRawEvent} event - economic event object
     * @return {Boolean}
     */
    function isFollowingEvent(event) {
      if (!btShareScopeService.isInitialized()) {
        return false;
      } else {
        return btShareScopeService.accountInfo.followingEvents.indexOf(event.id) !== -1;
      }
    }

    /**
     * This function generates news-driven trade idea trigger.
     * @param {String} name - event name
     * @param {Number} importance - event importance
     * @param {String} currency - event currency
     * @param {String} magnitude - magnitude label
     * @return {{text: string, html: string, link: string, dev: string}}
     */
    function generateEventTrigger(name, importance, currency, magnitude) {
      var importanceText = btImportanceFilter(importance, 'text');
      var importanceHtml = btImportanceFilter(importance, 'html');

      var style = 'neutral';
      if (magnitude.toLowerCase().indexOf('stronger') !== -1) style = 'positive';
      if (magnitude.toLowerCase().indexOf('weaker') !== -1) style = 'negative';
      if (magnitude.toLowerCase().indexOf('any') !== -1) style = 'general';

      return {
        text:
          'analysis of previous ' +
          name +
          ' ' +
          importanceText +
          ' (' +
          currency +
          ') releases' +
          (magnitude ? ' with ' + magnitude.toLowerCase() + ' magnitude' : ''),
        html:
          'analysis of previous <span class="bt-event">' +
          name +
          ' ' +
          importanceHtml +
          ' (' +
          currency +
          ') </span> releases' +
          (magnitude ? ' with <span class="' + style + '">' + magnitude.toLowerCase() + '</span> magnitude' : ''),
        link: '',
        dev: '',
      };
    }

    /**
     * This function generates price driven trade idea trigger.
     * @param {btRawMomentInsight} moment - market movement moment
     * @return {{text: string, html: string, link: string, dev: string}}
     */
    function generateMomentTrigger(moment) {
      var insightType = moment.insightTemplateName.split('.')[1];
      var triggerInstrument = btInstrumentsService.getInstrumentByComplexSymbol(moment.situationObject);
      var displaySymbol = triggerInstrument.displayName;
      var mStrength = moment.params.range;
      var minutes = moment.insightTemplateName.split('.')[2].split('-')[0];

      var data = {
        broker: btInstrumentsService.getBroker(moment.situationObject).toLowerCase(),
        symbol: btInstrumentsService.getSymbol(moment.situationObject),
      };

      var link = $state.href('ecapp.app.main.instrument-page', data, { absolute: false });

      var trigger = btMarketMovementService.generateTrigger(displaySymbol, insightType, mStrength, minutes, link);
      trigger.link = link;
      trigger.dev =
        '<span class="highlight-text">' +
        displaySymbol +
        '</span> ' +
        moment.insightTemplateName +
        ' <span class="positive">' +
        moment.params.range +
        '</span>';

      var levels = moment.insightTemplate ? moment.insightTemplate.params.levels : null;
      trigger.isSmallMovement = !btMarketMovementService.isHighLevelMovement(insightType, mStrength, levels);
      trigger.linking = moment.situationObject === moment.data.market ? 'self' : 'cross';
      trigger.type = insightType;
      return trigger;
    }

    /**
     *
     * @param {bt.TradeIdeaObject} tradeIdea
     * @return {boolean}
     */
    function hasGoodRewardRiskRatio(tradeIdea) {
      return tradeIdea.ratio > 1;
    }
  }
})();
