/**
 * Created by Eyal on 5/27/2016.
 */

(function btEventCardClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive shows event card
     *
     * @ngdoc directive
     * @name btEventCard
     * @memberOf ecapp
     * @param {ecapp.ISharedData} btSharedData - ?
     * @param {ext.IAnalyticsService} $analytics - ?
     */
    .directive('btEventCard', [
      'btSharedData',
      '$analytics',
      '$templateCache',
      'btSubscriptionService',
      'toastr',
      '$location',
      '$timeout',
      function (btSharedData, $analytics, $templateCache, btSubscriptionService, toastr, $location, $timeout) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/event-card/event-card.html'),
          controller: [
            '$scope',
            '$rootScope',
            function ($scope, $rootScope) {
              $scope.subStatus = btSubscriptionService.status;

              $scope.go = function (path) {
                $location.path(path);
              };

              // Connected to card share button. On click share information about selected row.
              $scope.shareRowInfo = function (row) {
                $analytics.eventTrack('trigger', {
                  category: 'back-tester',
                  label: 'calendar',
                  eventId: row.eventsInfo.id,
                });

                btSharedData.acquireBackTester();
                btSharedData.runBackTester('release', row, { magnitude: 'all' });

                // Itay: added in order to add selectedRow feature (change style of selected row)
                // At first deselect current row using saved reference from root scope
                if (null != $rootScope.selectedRow && $rootScope.selectedRow != row) {
                  $rootScope.selectedRow.isSelected = false;
                }
                // Select current row and save it to root scope
                row.isSelected = true;
                $rootScope.selectedRow = row;
              };

              /**
               *
               * @param {*} title
               * @param {*} eventId
               * @param {*} isOpen
               */
              function sendShowStatistic(title, eventId, isOpen) {
                $timeout(statisticFunc, 0, false);

                /**
                 *
                 */
                function statisticFunc() {
                  if (window.btSettings.BT_DOMAIN === 'bettertrader') {
                    if (isOpen) {
                      $analytics.eventTrack('open', { category: 'calendar', label: title, eventId: eventId });
                    } else {
                      $analytics.eventTrack('close', { category: 'calendar', label: title, eventId: eventId });
                    }
                  }
                }
              }

              /**
               * Closing the previous insight row if it's closed. and it's not the same one as the one that is opening
               * now. Close the previous opened insight quick view if you try to open modal view for same view it will
               * stay opened.
               *
               * @param {Object} row - release object
               */
              function closePrevious(row) {
                if ($rootScope.lastOpenedEventRow !== undefined && $rootScope.lastOpenedEventRow !== row) {
                  $rootScope.lastOpenedEventRow.openAccordionInsights = false;
                  $rootScope.lastOpenedEventRow.openAccordionBars = false;
                  $rootScope.lastOpenedEventRow.openAccordionTrading = false;
                  heightSize($rootScope.lastOpenedEventRow);
                }
                $rootScope.lastOpenedEventRow = row;
              }

              /**
               * This function gets a review ("a"), and changes the 'heightSize' field in it. This function is called from
               * the template when someone expands the card and open the accordion. Now heightSize is not used. but it may
               * be useful to collection-repeat
               *
               * @param {Object} a - row data
               */
              function heightSize(a) {
                if (a.openAccordionBars || a.openAccordionInsights) {
                  // this if is called if "openAccordionBars" or "openAccordionInsights" are true
                  a.heightSize = 320;
                } else {
                  // if both are false.
                  a.heightSize = 280;
                }
              }

              // Show historic chart
              $scope.showHistoricChart = function (row) {
                closePrevious(row);
                row.openAccordionInsights = false;
                row.openAccordionTrading = false;
                row.openAccordionBars = !row.openAccordionBars;

                sendShowStatistic('historic-chart', row.openAccordionBars, row.eventsInfo.id);

                $scope.resetScroll();
                heightSize(row);
              };

              // Show insights information
              $scope.showInsights = function (row) {
                closePrevious(row);
                row.openAccordionBars = false;
                row.openAccordionTrading = false;
                row.openAccordionInsights = !row.openAccordionInsights;

                sendShowStatistic('quick-insights', row.openAccordionInsights, row.eventsInfo.id);

                $scope.resetScroll();
                heightSize(row);
              };

              // Show trading information
              $scope.showTradingInfo = function (row) {
                closePrevious(row);
                row.openAccordionBars = false;
                row.openAccordionInsights = false;
                row.openAccordionTrading = !row.openAccordionTrading;

                sendShowStatistic('trading-info', row.openAccordionTrading, row.eventsInfo.id);

                $scope.resetScroll();
                heightSize(row);
              };
            },
          ],
        };
      },
    ]);
})();
