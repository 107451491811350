/**
 * Created by Orly on 19/02/19.
 */

(function btMarketMoversClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btMarketMovers:';

  angular.module('ecapp').directive('btMarketMovers', btMarketMovers);

  btMarketMovers.$inject = ['$templateCache'];

  /**
   * @ngdoc directive
   * @name btMarketMovers
   * @memberOf ecapp
   * @scope
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btMarketMovers($templateCache) {
    return {
      restrict: 'E',
      scope: {
        markets: '<',
        options: '<',
      },
      template: $templateCache.get('directives/barometer/bt-market-movers.html'),
      controller: btMarketMoversController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btMarketMoversController.$inject = [
    '$scope',
    '$interval',
    'btWatchListService',
    'btTradingService',
    'btMoversAlertsService',
    'btSettingsService',
    'btCalendarService',
    'btDateService',
    'btChartsService',
    'btTradeIdeasFiltersService',
  ];

  /**
   *
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.IWatchListService} btWatchListService
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.IMoversAlertsService} btMoversAlertsService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.ICalendarService} btCalendarService
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IChartsService} btChartsService
   * @param {ecapp.ITradeIdeasFiltersService} btTradeIdeasFiltersService
   */
  function btMarketMoversController(
    $scope,
    $interval,
    btWatchListService,
    btTradingService,
    btMoversAlertsService,
    btSettingsService,
    btCalendarService,
    btDateService,
    btChartsService,
    btTradeIdeasFiltersService
  ) {
    /**
     * @typedef {object} btMarketMover
     * @property {ecapp.ITradingInstrument} instrument - ?
     * @property {btMarketMoverAlerts} alerts - ?
     */

    /*jshint validthis: true*/
    var vm = this;

    vm.options = vm.options || {};

    vm.options.settingsPrefix = vm.options.settingsPrefix || 'misc.market-movers';
    vm.options.loadingText = vm.options.loadingText || 'Loading market movers';

    vm.options.sorting = vm.options.sorting === undefined ? true : vm.options.sorting;
    vm.options.adding = vm.options.adding === undefined ? true : vm.options.adding;

    /** @type {LocalSettings} */
    var gSettings = btSettingsService.getLocalSettings();

    var gTitlesTemplates = {
      newLimit: function (a, t) {
        return a.value !== 'inactive'
          ? 'New ' +
              (a.value === 'positive' ? 'high' : a.value === 'negative' ? 'low' : 'value') +
              ' occurred ' +
              t +
              '.'
          : 'This icon will be highlighted once alert is triggered.';
      },
      volatilityIncrease: function (a, t) {
        return a.value !== 'inactive'
          ? 'Volatility increased significantly ' + t + '.'
          : 'This icon will be highlighted once alert is triggered.';
      },
      activeTradeIdea: function (a, t) {
        return a.value !== 'inactive'
          ? 'Trade idea detected ' + t + '.'
          : 'This icon will be highlighted once alert is triggered.';
      },
      potentialEvent: function (a, t) {
        if (a.value === 'inactive') return 'This icon will be highlighted once alert is triggered.';
        else if (a.value === 'locked') return 'Upgrade to get alerts for events with potential trade ideas.';
        else return 'Economic events with a potential trade idea for this instrument ' + t + '.';
      },
      common: function (a, t) {
        return a.value !== 'inactive'
          ? 'Triggered ' + t + '.'
          : 'This icon will be highlighted once alert is triggered.';
      },
    };

    vm.$onDestroy = onDestroy;

    $scope.$watchCollection('vm.markets', onMarketsChange);

    activate();

    /**
     *
     */
    function activate() {
      if (gDebug) console.log(gPrefix, 'activating...');

      vm.isLoading = true;
      vm.hasError = false;
      vm.errorMessage = '';

      // vm.originalWatchlist = [];
      /** @type {ecapp.ITradingInstrument[]} */
      // vm.marketsPrices = [];
      // vm.watchlistPrices = [];
      /** @type {btMarketMover[]} */
      vm.movers = [];

      vm.orderByReverse = false;
      vm.orderByColumn = null;

      vm.sortColumn = sortColumn;
      vm.getAlertTitle = getAlertTitle;
      vm.getValueClasses = getValueClasses;
      vm.toDisplayCounter = toDisplayCounter;
      vm.getCounter = getCounter;
      vm.getOrderByValue = getOrderByValue;
      vm.toAnimateAlert = toAnimateAlert;
      vm.clickOnAlert = clickOnAlert;
      vm.isClickableAlert = isClickableAlert;
      vm.openInstrumentChart = openInstrumentChart;

      $scope.addInstrument = addInstrument;

      // List of alerts settings
      vm.alerts = btMoversAlertsService.getAlertsInfo();

      if (vm.markets && vm.markets.length) {
        vm.isLoading = false;
        vm.markets.forEach(function (instrument) {
          btTradingService.enablePriceUpdates(instrument);
        });

        vm.movers = getWatchListMovers(vm.markets);
      }

      loadSettings();
      sortColumn(vm.orderByColumn, vm.orderByReverse ? -1 : 1);
    }

    /**
     *
     * @param {ecapp.ITradingInstrument} instrument
     */
    function openInstrumentChart(instrument) {
      btChartsService.openInstrumentChart(instrument);
    }

    /**
     *
     */
    function onDestroy() {
      if (gDebug) console.log(gPrefix, 'destroying...');
      if (vm.markets && vm.markets.length) {
        vm.markets.forEach(function (instrument) {
          btTradingService.disablePriceUpdates(instrument);
        });
      }
    }

    /**
     *
     * @param {Instrument[]} newMarkets
     * @param {Instrument[]} oldMarkets
     */
    function onMarketsChange(newMarkets, oldMarkets) {
      if (gDebug) console.log('>>>>', newMarkets);

      if (newMarkets && newMarkets.length) {
        vm.isLoading = false;

        if (oldMarkets && oldMarkets.length) {
          oldMarkets.forEach(function (instrument) {
            btTradingService.disablePriceUpdates(instrument);
          });
        }

        if (newMarkets && newMarkets.length) {
          newMarkets.forEach(function (instrument) {
            btTradingService.enablePriceUpdates(instrument);
          });

          vm.movers = getWatchListMovers(newMarkets);
        }
      }
    }

    /**
     * This function get market movers.
     *
     * @param {Instrument[]} watchlist
     * @return {btMarketMover[]}
     */
    function getWatchListMovers(watchlist) {
      return watchlist.map(createMarketMover);
    }

    /**
     *
     * @param {Instrument} item
     * @return {btMarketMover}
     */
    function createMarketMover(item) {
      // console.log(item.brokerSymbol, item.price);

      return {
        id: item.id,
        instrument: item,
        // displayName: item.displayName,
        // brokerName: item.brokerName,
        // brokerSymbol: item.brokerSymbol,
        // price: item.price,
        alerts: btMoversAlertsService.getMoverAlerts(item),
      };
    }

    /**
     * This function returns tooltip title for icons specified by property name.
     *
     * @param {btMarketMover} mover
     * @param {string} prop
     * @return {string}
     */
    function getAlertTitle(mover, prop) {
      // !!! HotFix to improve performance
      if (!mover.alerts[prop].title || !mover.alerts[prop].updated || Date.now() - mover.alerts[prop].updated > 2000) {
        var template = gTitlesTemplates[prop];
        var t = btDateService.getHumanisedTimeFromNow(btMoversAlertsService.getTime(mover.alerts, prop), true);
        mover.alerts[prop].title = template ? template(mover.alerts[prop], t) : template('common', t);
        mover.alerts[prop].updated = Date.now();
      }

      return mover.alerts[prop].title;
    }

    /**
     * This function checks if the alert should be animated.
     *
     * @param {btMarketMover} mover - mover object
     * @param {string} alert - alert identifier
     * @return {boolean} - whether the alert should be animated
     */
    function toAnimateAlert(mover, alert) {
      return btMoversAlertsService.toAnimateAlert(mover, alert);
    }

    /**
     * This function returns classes for icon representing alert for the mover.
     *
     * @param {btMarketMover} mover - mover object
     * @param {string} alert - name of the alert
     * @return {string} - space-separated css classes
     */
    function getValueClasses(mover, alert) {
      return btMoversAlertsService.getValueClasses(mover, alert);
    }

    /**
     * This function checks if counter should be displayed for mover alert.
     *
     * @param {btMarketMover} mover - mover object
     * @param {string} alert - name of the alert
     * @return {boolean} - whether the counter should be displayed
     */
    function toDisplayCounter(mover, alert) {
      return btMoversAlertsService.toDisplayCounter(mover, alert);
    }

    /**
     * This function returns counter for mover alert.
     *
     * @param {btMarketMover} mover - mover object
     * @param {string} alert - name of the alert
     * @return {number|undefined}
     */
    function getCounter(mover, alert) {
      return btMoversAlertsService.getCounter(mover, alert);
    }

    /**
     * This function returns counter for mover alert.
     *
     * @param {btMarketMover} mover - mover object
     * @param {string} alert - name of the alert
     * @return {number|undefined}
     */
    function clickOnAlert(mover, alert) {
      return btMoversAlertsService.clickOnAlert(mover, alert);
    }

    /**
     * This function returns counter for mover alert.
     *
     * @param {btMarketMover} mover - mover object
     * @param {string} alert - name of the alert
     * @return {number|undefined}
     */
    function isClickableAlert(mover, alert) {
      return btMoversAlertsService.isClickableAlert(mover, alert);
    }

    /**
     * This function sorts data by some column.
     *
     * @param {string} column - name of column
     * @param {number} order - sorting order: 1 or -1
     */
    function sortColumn(column, order) {
      if (vm.options.sorting) {
        // Some columns don't have reverse order
        if (['volatility', 'potential-event'].indexOf(column) === -1) {
          if (order !== undefined) {
            vm.orderByReverse = order === -1;
          } else {
            vm.orderByReverse = vm.orderByColumn === column ? !vm.orderByReverse : false;
          }
        } else {
          vm.orderByReverse = false;
        }

        vm.orderByColumn = column;

        saveSettings();
      }
    }

    /**
     *
     * @param {*} mover
     * @param {*} alert
     * @return {number|number}
     */
    function convertPositiveNegativeAlertToNumber(mover, alert) {
      var limit = 2 * 10;
      if (vm.orderByReverse) {
        if (mover.alerts[alert].value.includes('positive')) return -btMoversAlertsService.getCounter(mover, alert);
        if (mover.alerts[alert].value.includes('negative'))
          return limit - btMoversAlertsService.getCounter(mover, alert);
        return -limit;
      } else {
        if (mover.alerts[alert].value.includes('positive'))
          return -limit + btMoversAlertsService.getCounter(mover, alert);
        if (mover.alerts[alert].value.includes('negative')) return btMoversAlertsService.getCounter(mover, alert);
        return limit;
      }
    }

    /**
     *
     * @param {*} mover
     * @param {*} alert
     * @return {*}
     */
    function convertActiveInactiveAlertToNumber(mover, alert) {
      var limit = 2 * 10;
      return mover.alerts[alert].value === 'inactive' ? limit : btMoversAlertsService.getCounter(mover, alert);
    }

    /**
     *
     * @param {*} mover
     * @return {*}
     */
    function convertSignificanceToNumber(mover) {
      var percent = mover.instrument.price.now.last.percent;
      var significance = mover.instrument.price.now.last.significance;

      if (significance > 0) {
        if (percent > 0) percent += significance * 1000;
        else percent -= significance * 1000;
      }
      return -percent;
    }

    /**
     * This function value that will be used during column sorting.
     *
     * @param {btMarketMover} item
     * @return {string|number}
     */
    function getOrderByValue(item) {
      switch (vm.orderByColumn) {
        case 'instrument':
          return item.instrument.displayName;
        case 'last':
          return item.instrument.price ? item.instrument.price.now.last.value : 0;
        case 'change':
          return convertSignificanceToNumber(item);
        case 'movement':
          return item.instrument.price ? -item.instrument.price.now.last.percent : 0;
        case 'high-low':
          return convertPositiveNegativeAlertToNumber(item, 'newLimit');
        case 'volatility':
          return convertActiveInactiveAlertToNumber(item, 'volatilityIncrease');
        case 'trade-idea':
          return convertPositiveNegativeAlertToNumber(item, 'activeTradeIdea');
        case 'potential-event':
          return convertActiveInactiveAlertToNumber(item, 'potentialEvent');
        default:
          return 0;
      }
    }

    /**
     * This function saves user settings.
     */
    function loadSettings() {
      if (vm.options.sorting) {
        vm.orderByReverse = gSettings.get(vm.options.settingsPrefix + '.order');
        vm.orderByReverse = vm.orderByReverse !== undefined ? vm.orderByReverse : false;
        vm.orderByColumn = gSettings.get(vm.options.settingsPrefix + '.column') || 'movement';
      }
    }

    /**
     * This function loads user settings.
     */
    function saveSettings() {
      gSettings.set(vm.options.settingsPrefix + '.order', vm.orderByReverse);
      gSettings.set(vm.options.settingsPrefix + '.column', vm.orderByColumn);
    }

    /**
     * Adds instrument to watchlist.
     *
     * @deprecated
     * @see scopes.InstrumentSearch
     * @param {ecapp.ITradingInstrument} instrument - instrument
     * @return {angular.IPromise<void>}
     */
    function addInstrument(instrument) {
      return btWatchListService.addInstrumentByName(instrument.brokerSymbol).then(function () {
        btTradeIdeasFiltersService.updateInstrumentsByName(instrument.brokerSymbol, true);
      });
    }
  }
})();
