/**
 * Created by Sergey Panpurin on 9/2/19.
 */

// @ts-check
(function btRiskIntervalServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btRiskIntervalService:';

  /**
   * This service ...
   *
   * @ngdoc service
   * @name ecapp.btRiskIntervalService
   */

  angular.module('ecapp').factory('btRiskIntervalService', service);

  service.$inject = ['$q', 'btToolsService'];

  /**
   *
   * @param {angular.IQService} $q
   * @param {ecapp.IToolsService} btToolsService
   * @return {ecapp.IRiskIntervalService}
   */
  function service($q, btToolsService) {
    if (gDebug) console.log(gPrefix, 'running...');

    var gInitialized = false;

    /** @type {ecapp.IRiskInterval[]} */
    var gIntervalsList = [];

    /** @type {Record<string, ecapp.IRiskInterval>} */
    var gIntervals = {};

    /** @type {ecapp.IRiskInterval} */
    var gSelectedInterval = {
      id: 'n/a',
      value: 'n/a',
      granularity: 'n/a',
      name: 'n/a',
      desc: 'n/a',
      size: 0,
      history: 0,
      preview: {
        size: 0,
        title: 'n/a',
      },
      step: 0,
      format: {scale: "n/a", crosshair: "n/a"},
      benefits: [],
    };

    return {
      initialize: initialize,
      reinitialize: reinitialize,
      getIntervals: getIntervals,
      getIntervalById: getIntervalById,
      selectInterval: selectInterval,
      getSelectedInterval: getSelectedInterval,
      isSelectedInterval: isSelectedInterval,
    };

    /**
     * This function creates a new interval object using document from database.
     *
     * @param {ecapp.IRiskIntervalDocument} doc - interval document from database
     * @return {ecapp.IRiskInterval}
     */
    function createInterval(doc) {
      return {
        id: doc.id,
        value: doc.id,
        granularity: doc.granularity.id,
        name: doc.name,
        size: doc.size,
        preview: doc.preview,
        step: doc.step,
        history: doc.history || 240,
        format: doc.format,
        desc: doc.desc,
        benefits: doc.benefits,
      };
    }

    /**
     *
     * @alias ecapp.btRiskIntervalService#initialize
     * @param {ecapp.IRiskIntervalDocument[]} intervals
     * @param {string} [userInterval] - identifier of interval saved in user settings
     * @return {angular.IPromise<boolean>}
     */
    function initialize(intervals, userInterval) {
      if (!gInitialized) {
        // Intervals
        btToolsService.clearList(gIntervalsList);
        btToolsService.updateList(
          gIntervalsList,
          intervals.map(function (interval) {
            return createInterval(interval);
          })
        );
        btToolsService.clearDict(gIntervals);
        btToolsService.updateDict(gIntervals, btToolsService.convertListToDict(gIntervalsList));

        if (userInterval && getIntervalById(userInterval)) selectInterval(getIntervalById(userInterval));
        else selectInterval(gIntervalsList[1]);

        gInitialized = true;

        return $q.resolve(true);
      } else {
        return $q.resolve(true);
      }
    }

    /**
     * This function reinitialize service. It can user during unit tests.
     *
     * @alias ecapp.btRiskIntervalService#reinitialize
     * @param {ecapp.IRiskIntervalDocument[]} intervals
     * @return {angular.IPromise<boolean>}
     */
    function reinitialize(intervals) {
      gInitialized = false;
      return initialize(intervals);
    }

    /**
     * This function returns risk intervals.
     *
     * @alias ecapp.btRiskIntervalService#getIntervals
     * @return {ecapp.IRiskInterval[]} - list of risk intervals
     */
    function getIntervals() {
      return gIntervalsList;
    }

    /**
     * This function selects risk interval.
     *
     * @alias ecapp.btRiskIntervalService#selectInterval
     * @param {ecapp.IRiskInterval} interval - risk interval
     * @return {boolean}
     */
    function selectInterval(interval) {
      if (interval && getIntervalById(interval.id)) {
        // angular.extend(gSelectedInterval, interval);
        gSelectedInterval.id = interval.id;
        gSelectedInterval.value = interval.value;
        gSelectedInterval.granularity = interval.granularity;
        gSelectedInterval.name = interval.name;
        gSelectedInterval.size = interval.size;
        gSelectedInterval.preview = interval.preview;
        gSelectedInterval.step = interval.step;
        gSelectedInterval.history = interval.history;
        gSelectedInterval.format = interval.format;
        gSelectedInterval.desc = interval.desc;
        gSelectedInterval.benefits = interval.benefits;
        return true;
      } else return false;
    }

    /**
     * This function returns interval with specified identifier.
     *
     * @alias ecapp.btRiskIntervalService#getIntervalById
     * @param {string} id - interval identifier
     * @return {ecapp.IRiskInterval}
     */
    function getIntervalById(id) {
      return gIntervals[id];
    }

    /**
     * This function returns selected risk interval.
     *
     * @alias ecapp.btRiskIntervalService#getSelectedInterval
     * @return {ecapp.IRiskInterval} - selected risk interval
     */
    function getSelectedInterval() {
      return gSelectedInterval;
    }

    /**
     * This function checks if a risk interval is selected.
     *
     * @alias ecapp.btRiskIntervalService#isSelectedInterval
     * @param {ecapp.IRiskInterval} interval
     * @return {boolean} - whether a interval is selected
     */
    function isSelectedInterval(interval) {
      return gSelectedInterval.id === interval.id;
    }
  }
})();
