/**
 * Created by Sergey on 5/9/2018.
 */

(function btTradeIdeaAClosure() {
  'use strict';

  angular
    .module('ecapp')
    .directive('btTradeIdeaA', btTradeIdeaA)
    .controller('btTradeIdeaAController', btTradeIdeaAController);

  btTradeIdeaA.$inject = ['$templateCache'];

  /**
   * This directive displays trade idea.
   *
   * @ngdoc directive
   * @name btTradeIdeaA
   * @memberOf ecapp
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btTradeIdeaA($templateCache) {
    return {
      restrict: 'E',
      scope: {
        insight: '=',
        showTradeCard: '=',
        displayOptions: '=',
      },
      template: $templateCache.get('directives/insight/bt-trade-idea-a.html'),
      controller: 'btTradeIdeaAController',
    };
  }

  btTradeIdeaAController.$inject = [
    '$scope',
    '$q',
    '$rootScope',
    '$timeout',
    '$interval',
    '$analytics',
    '$ionicLoading',
    'btDateService',
    'btRestrictionService',
    'btSharedData',
    'btMomentService',
    'btReleasesService',
    'btComponentService',
    'btSettingsService',
    'btPubNubService',
    'btChartsService',
  ];

  /**
   *
   * @param {*} $scope
   * @param {angular.IQService} $q
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.ITimeoutService} $timeout
   * @param {angular.IIntervalService} $interval
   * @param {ext.IAnalyticsService} $analytics
   * @param {ionic.ILoadingService} $ionicLoading
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.ISharedData} btSharedData
   * @param {ecapp.IMomentService} btMomentService
   * @param {ecapp.IReleasesService} btReleasesService
   * @param {ecapp.IComponentService} btComponentService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IPubNubService} btPubNubService
   * @param {ecapp.IChartsService} btChartsService
   */
  function btTradeIdeaAController(
    $scope,
    $q,
    $rootScope,
    $timeout,
    $interval,
    $analytics,
    $ionicLoading,
    btDateService,
    btRestrictionService,
    btSharedData,
    btMomentService,
    btReleasesService,
    btComponentService,
    btSettingsService,
    btPubNubService,
    btChartsService
  ) {
    var updatePromise = null;

    $scope.$on('$destroy', onDestroy);

    $scope.hasPrices = btSettingsService.hasFeature('prices');
    $scope.hasTrading = btSettingsService.hasFeature('trading');

    $scope.$watch('insight.status', updateEntryStatusTitle);
    $scope.$watch('insight.isOld', updateTradeStatusTitle);
    $scope.$watch('insight.isExpiring', updateTradeStatusTitle);

    $scope.unlockTradeIdea = unlockTradeIdea;
    $scope.runTradeIdeaSimulation = runTradeIdeaSimulation;
    $scope.runExternalSimulation = runExternalSimulation;

    $scope.showChart = showChart;
    $scope.openChart = openChart;
    $scope.collapseInsight = collapseInsight;
    $scope.expandInsight = expandInsight;
    $scope.toggleInsight = toggleInsight;

    activate();

    /**
     * Activate directive
     */
    function activate() {
      $scope.instrument = {
        instrument: $scope.insight.instrument,
        data: {},
        openOrders: false,
        openCharts: false,
      };
      $scope.market = $scope.instrument;
      $scope.component = btComponentService.newComponent('trade-idea', $scope.insight.id);

      if ($scope.insight.locked) {
        $scope.icon = 'ion-locked';
        $scope.hasTradeCard = false;
        $scope.isLocked = true;
      } else {
        $scope.icon = getTradeIdeaIcon($scope.insight);
        $scope.hasTradeCard = $scope.showTradeCard;
        $scope.isLocked = false;
      }

      if ($scope.insight.isWaiting) {
        if (updatePromise === null) {
          updatePromise = $interval(function () {
            if (typeof $scope.insight.updateInterval === 'function') {
              $scope.insight.updateInterval();

              if ($scope.insight.isWaiting === false) {
                $interval.cancel(updatePromise);
              }
            }
          }, 1000);
        }
      }
    }

    /**
     * Update entry status title
     * @param {String} newValue - new value
     * @param {String} oldValue - old value
     */
    function updateEntryStatusTitle(newValue, oldValue) {
      void oldValue;
      if (newValue === 'active') {
        $scope.entryStatusTitle = $rootScope.titles.tradeIdeaEntryStatusActive;
      } else {
        $scope.entryStatusTitle = $rootScope.titles.tradeIdeaEntryStatusExpired;
      }
    }

    /**
     * Update trade status title
     * @param {Boolean} newValue - new value
     * @param {Boolean} oldValue - old value
     */
    function updateTradeStatusTitle(newValue, oldValue) {
      void newValue;
      void oldValue;

      if ($scope.insight.isOld === true) {
        $scope.tradeStatusTitle = $rootScope.titles.tradeIdeaTradeStatusExpired;
      } else {
        if ($scope.insight.isExpiring === true) {
          $scope.tradeStatusTitle = $rootScope.titles.tradeIdeaTradeStatusExpiring;
        } else {
          $scope.tradeStatusTitle = $rootScope.titles.tradeIdeaTradeStatusActive;
        }
      }
    }

    /**
     * On directive destroy
     */
    function onDestroy() {
      if (updatePromise !== null) {
        $interval.cancel(updatePromise);
      }
    }

    /**
     * Show chart
     */
    function showChart() {
      $scope.instrument.openCharts = !$scope.instrument.openCharts;
    }

    /**
     *
     */
    function openChart() {
      if (btRestrictionService.hasFeature('custom-charts')) {
        btChartsService.updateChart(btChartsService.getChartSymbol($scope.instrument.instrument));

        btSharedData.openDashboardTabByViewName('dashboard-charts').then(function () {
          btChartsService.glowChart(btChartsService.getActiveChart().index);
        });
      } else {
        btRestrictionService.showUpgradePopup('custom-charts');
      }
    }

    /**
     *
     * @param {*} insight
     */
    function collapseInsight(insight) {
      insight.collapsed = true;
    }

    /**
     *
     * @param {*} insight
     */
    function expandInsight(insight) {
      $analytics.eventTrack('expand', { category: 'trade-ideas', label: insight.instrument.id });

      insight.collapsed = false;
    }

    /**
     *
     * @param {*} insight
     */
    function toggleInsight(insight) {
      try {
        if (insight.collapsed) {
          $analytics.eventTrack('expand', { category: 'trade-ideas', label: insight.instrument.id });
        }
      } catch (e) {
        console.error(e);
      }
      if ($scope.isLocked) {
        unlockTradeIdea();
      } else if ($scope.hasTradeCard) {
        insight.collapsed = !insight.collapsed;
      }
    }

    /**
     *
     * @param {*} insight
     * @return {string}
     */
    function getTradeIdeaIcon(insight) {
      if (insight.data.action === 'uptrend') {
        return 'ion-arrow-graph-up-right positive';
      }
      if (insight.data.action === 'downtrend') {
        return 'ion-arrow-graph-down-right negative';
      }
    }

    /**
     *
     */
    function unlockTradeIdea() {
      if (!$rootScope.hasUpgrade) {
        alert('This feature is not included in your subscription!');
      } else {
        btRestrictionService.showUpgradePopup('trade-ideas');
      }
    }

    /**
     *
     * @param {*} tradeIdea
     */
    function runExternalSimulation(tradeIdea) {
      var type = 'unknown';
      if (tradeIdea.type === 'release') type = 'news';
      if (tradeIdea.type === 'moment') type = 'price';

      // tradeIdea.instrument
      btPubNubService.devPublish('my_channel', {
        beginMessage: '1',
        tiSimulation: 1,
        tiTime: tradeIdea.time,
        tiType: type,
        tiSymbol: tradeIdea.instrument.OandaSymbol + ':OANDA',
        tiSide: tradeIdea.data.action,
        tiStop: tradeIdea.data.tradeData.stop.price.max,
        tiStopWin: tradeIdea.data.tradeData.risk.price.max,
        tiProfit1: tradeIdea.data.tradeData.reward.price.min,
        tiProfit2: tradeIdea.data.tradeData.reward.price.mdn,
        tiDuration: tradeIdea.data.tradeData.reward.time.avg,
        tiNWin: tradeIdea.data.win,
        tiNLoss: tradeIdea.data.loss,
        tiNWeak: tradeIdea.data.weak,
        tiQuality: tradeIdea.data.quality,
        endMessage: '1',
      });
    }

    /**
     * Run backtesting
     * @param {bt.TradeIdeaObject} tradeIdea
     * @return {any}
     */
    function runTradeIdeaSimulation(tradeIdea) {
      $analytics.eventTrack('trigger', { category: 'back-tester', label: 'trade-idea' });

      btComponentService.select($scope.component, 'trade-ideas');

      if (tradeIdea.type === 'release') {
        $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Preparing...</p>' });

        btSharedData.acquireBackTester();
        return btReleasesService
          .getReleaseById(tradeIdea.raw.rowId)
          .then(function (releaseObject) {
            $ionicLoading.hide();
            return btSharedData.runBackTester('release', releaseObject, {
              magnitude: 'all',
              instrument: tradeIdea.instrument,
            });
          })
          .catch(function (reason) {
            btSharedData.releaseBackTester();
            return $q.reject(reason);
          });
      }

      if (tradeIdea.type === 'moment') {
        $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Preparing...</p>' });
        btSharedData.acquireBackTester();
        return btMomentService
          .getMomentById(tradeIdea.raw.momentId)
          .then(function (momentObject) {
            $ionicLoading.hide();
            return btMomentService.testConvertedMoment(momentObject, { instrument: tradeIdea.instrument });
          })
          .catch(function (reason) {
            btSharedData.releaseBackTester();
            return $q.reject(reason);
          });
      }
    }
  }
})();
