/**
 * Created by Sergey Panpurin on 8/13/2017.
 */

(function btEventCardHistoryChartClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive show chart with historical data for event card
     *
     * @ngdoc directive
     * @name btEventCardHistoryChart
     * @memberOf ecapp
     */
    .directive('btEventCardHistoryChart', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/event-card/history-chart.html'),
          controller: [
            '$scope',
            '$rootScope',
            function ($scope, $rootScope) {
              var row = $scope.a;
              if ($rootScope.lastOpenedEventRow != null && $rootScope.lastOpenedEventRow != row) {
                $rootScope.lastOpenedEventRow.openAccordionInsights = false;
                $rootScope.lastOpenedEventRow.openAccordionBars = false;
                $rootScope.lastOpenedEventRow.openAccordionTrading = false;
                $scope.heightSize($rootScope.lastOpenedEventRow);
              }
              $rootScope.lastOpenedEventRow = row;
            },
          ],
        };
      },
    ]);
})();
