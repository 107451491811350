(function () {
  'use strict';

  // This directive manage the insights inside of the event modal.

  angular
    .module('ecapp')
    /**
     * ???
     *
     * @ngdoc directive
     * @name btEventModalInsightsContainer
     * @memberOf ecapp
     */
    .directive('btEventModalInsightsContainer', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/event-modal/regular/insights-container.html'),
          controller: [
            '$scope',
            'btStrengthService',
            '$location',
            function ($scope, btStrengthService, $location) {
              $scope.go = function (path) {
                $location.path(path);
              };
              var disableWatch = $scope.$watch('rowModalData.insightRow.totalSurpriseStrength', function () {
                if (
                  null != $scope.rowModalData.insightRow &&
                  null != $scope.rowModalData.insightRow.totalSurpriseStrength
                ) {
                  $scope.totalInsightsSurpriseStrengthPercentage = btStrengthService.prepareStrength(
                    $scope.rowModalData.insightRow.totalSurpriseStrength,
                    $scope.rowModalData.time
                  );
                  disableWatch();
                }
              });
            },
          ],
        };
      },
    ]);
})();
