/**
 * Created by Mikhail Kharitonovich on 6/4/2017.
 */

(function btMarginUsedClosure() {
  'use strict';

  angular
    .module('ecapp')
    .directive('btMarginUsed', btMarginUsed)
    .controller('btMarginUsedController', btMarginUsedController);

  btMarginUsed.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btMarginUsed($templateCache) {
    return {
      template: $templateCache.get('directives/common/bt-margin-used.html'),
      restrict: 'E',
      scope: {
        total: '=',
        used: '=',
      },
      controller: 'btMarginUsedController',
    };
  }

  btMarginUsedController.$inject = ['$scope'];

  /**
   *
   * @param {*} $scope
   */
  function btMarginUsedController($scope) {
    /**
     *
     */
    function getPercentage() {
      $scope.percentage = ($scope.used / $scope.total) * 100;
      $scope.fgLineStyle = {
        width: $scope.percentage + '%',
      };
    }

    getPercentage();

    $scope.$watch('total', function () {
      getPercentage();
    });
  }
})();
