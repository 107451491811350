/**
 * Created by Sergey Panpurin on 11/11/2018.
 */

// @ts-check
(function btBigBrainBankServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btBigBrainBankService', btBigBrainBankService);

  btBigBrainBankService.$inject = ['$q', '$http', 'btErrorService', 'btBigBrainBankAuthService'];

  /**
   * This service collect logic connected to BigBrainBank integration.
   *
   * @ngdoc service
   * @name btBigBrainBankService
   * @memberOf ecapp
   * @param {angular.IQService} $q - promise interface
   * @param {angular.IHttpService} $http
   * @param {ecapp.IErrorService} btErrorService
   * @param {ecapp.IBigBrainBankAuthService} btBigBrainBankAuthService
   * @return {ecapp.IBigBrainBankService}
   */
  function btBigBrainBankService($q, $http, btErrorService, btBigBrainBankAuthService) {
    console.log('Running btBigBrainBankService');

    /**
     * This function registers new BigBrainBank user in bettertrader system.
     *
     * @param {string} email - email of new BigBrainBank user
     * @return {angular.IPromise<boolean>}
     */
    function initialize(email) {
      var params = {};
      return $http
        .get(btSettings.BT_BACKEND_URL + '/v1/bigbrainbank/users/' + encodeURIComponent(email) + '/contact', params)
        .then(function (response) {
          // console.log('btBigBrainBankService:', response);
          return true;
        })
        .catch(function (reason) {
          if (reason.status === 404) {
            return false;
          } else {
            return $q.reject(reason);
          }
        })
        .catch(btErrorService.handleHTTPError);
    }

    /**
     * This function registers new BigBrainBank user in bettertrader system.
     *
     * @param {string} email - email of new BigBrainBank user
     * @param {object} details - BigBrainBank user details
     * @return {angular.IPromise<boolean>}
     */
    function subscribe(email, details) {
      var params = {};
      return $http
        .post(
          btSettings.BT_BACKEND_URL + '/v1/bigbrainbank/users/' + encodeURIComponent(email) + '/contact',
          { details: details },
          params
        )
        .then(function (response) {
          // console.log('btBigBrainBankService:', response);
          return true;
        })
        .catch(btErrorService.handleHTTPError);
    }

    /**
     * This function registers new BigBrainBank user in bettertrader system.
     *
     * @param {string} email - email of new BigBrainBank user
     * @return {angular.IPromise<boolean>}
     */
    function unsubscribe(email) {
      var params = {};
      return $http
        .delete(btSettings.BT_BACKEND_URL + '/v1/bigbrainbank/users/' + encodeURIComponent(email) + '/contact', params)
        .then(function (response) {
          // console.log('btBigBrainBankService:', response);
          return true;
        })
        .catch(btErrorService.handleHTTPError);
    }

    return {
      auth: btBigBrainBankAuthService,
      mailing: {
        initialize: initialize,
        subscribe: subscribe,
        unsubscribe: unsubscribe,
      },
    };
  }
})();
