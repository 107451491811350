/**
 * Created by Sergey Panpurin on 5/7/2018.
 */

(function btIconTextClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     *  This directive displays icon, two lines of text and percent.
     *
     *
     *
     *    ****    Big text
     *  ********
     *  * Icon *  Small text
     *  ********
     *    ****    ==========--Percent----
     *
     * @ngdoc directive
     * @name btIconText
     * @memberOf ecapp
     */
    .directive('btIconText', btIconText);

  btIconText.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btIconText($templateCache) {
    return {
      restrict: 'E',
      scope: {
        icon: '@',
        bigText: '@',
        smallText: '@',
        scale: '@?',
        hint: '@?',
      },
      template: $templateCache.get('components/mobile/bt-icon-text/bt-icon-text.html'),
      controller: btIconTextController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btIconTextController.$inject = [];

  /**
   *
   */
  function btIconTextController() {
    /*jshint validthis: true*/
    var vm = this;

    vm.icon = vm.icon || 'ion-checkmark-circled';
    vm.bigText = vm.bigText || 'The big text';
    vm.smallText = vm.smallText || 'The small text';
    vm.hint = vm.hint || '';

    vm.hasScale = vm.scale !== undefined;
    vm.scale = vm.scale ? parseInt(vm.scale) : 0;

    vm.barStyle = { width: getWidth(vm.scale) + '%' };

    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;

    /**
     *
     */
    function onInit() {}

    /**
     *
     */
    function onDestroy() {}

    /**
     *
     * @param {*} value
     * @return {any}
     */
    function getWidth(value) {
      if (value < 0 || value > 100) {
        value = 0;
      }

      return value;
    }
  }
})();
