/**
 * Created by Sergey Panpurin on 8/13/2017.
 */

(function btCardElementClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive ???
     *
     * @ngdoc directive
     * @name btCardElement
     * @memberOf ecapp
     */
    .directive('btCardElement', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/bt-card-element.html'),
          link: {
            post: btCardElementPostLink,
          },
        };
      },
    ]);

  /**
   *
   * @param {*} scope
   */
  function btCardElementPostLink(scope) {
    var count = 0;

    /**
     * This function recursively all watchers for selected scope
     * @param {*} sc
     */
    function removeWatchers(sc) {
      if (sc != null) {
        count++;
        sc.$$watchers = null;
        sc.$$watchersCount = 0;

        // var i = Math.round(Math.random()*100000);
        // console.log(i, 'TEST Sibling - - - - - - = = = = = ');
        // console.log(i, 'scope id ', sc.id);
        while (sc.hasOwnProperty('$$nextSibling') && sc.$$nextSibling != null) {
          removeWatchers(sc.$$nextSibling);
          sc = sc.$$nextSibling;
          // console.log(i, 'scope id ', sc.id);
        }

        if (sc.hasOwnProperty('$$childHead') && sc.$$childHead != null) {
          removeWatchers(sc.$$childHead);
        }
      }
    }

    if (scope.a.canRemoveWatchers && window.btRemoveUnnecessaryWatchers) {
      // console.log(scope);
      setTimeout(function () {
        console.log('Remove watchers!');
        scope.$$watchers = null;
        scope.$$watchersCount = 0;

        removeWatchers(scope.$$childHead);
        // console.log(scope);
        console.log('Number of recursions:', count);
      }, 5000);
    }
  }
})();
