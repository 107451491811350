/**
 * Created by Orly on 09/01/19.
 */

(function btNotificationCenter() {
  'use strict';

  /**
   * This directive displays notification center button.
   *
   * @ngdoc directive
   * @name btNotificationCenter
   * @memberOf ecapp
   * @restrict E
   * @scope
   */

  /**
   * This controller process notification center.
   *
   * @ngdoc controller
   * @name btNotificationCenterController
   * @memberOf ecapp
   * @scope
   */

  angular
    .module('ecapp')
    .directive('btNotificationCenter', angularDirective)
    .controller('btNotificationCenterController', angularController);

  angularDirective.$inject = ['$templateCache'];

  /**
   * Implementation of Notification Center Directive
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function angularDirective($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('directives/common/bt-notification-center.html'),
      controller: 'btNotificationCenterController',
    };
  }

  angularController.$inject = [
    '$scope',
    '$templateCache',
    '$timeout',
    '$state',
    '$filter',
    'btToastrService',
    'btPopoverService',
    'btAudioService',
    'btSettingsService',
  ];

  /**
   * Implementation of Notification Center Controller
   *
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {$timeout} $timeout
   * @param {angular.ui.IStateService} $state
   * @param {angular.IFilterService} $filter
   * @param {btToastrService} btToastrService
   * @param {ecapp.IPopoverService} btPopoverService
   * @param {ecapp.IAudioService} btAudioService
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function angularController(
    $scope,
    $templateCache,
    $timeout,
    $state,
    $filter,
    btToastrService,
    btPopoverService,
    btAudioService,
    btSettingsService
  ) {
    activate();

    /**
     * Controller constructor
     */
    function activate() {
      // Function declarations
      $scope.openPopover = openPopover;
      $scope.closePopover = closePopover;
      $scope.clearAll = clearAll;
      $scope.readAll = readAll;
      $scope.soundOnOff = soundOnOff;
      $scope.openNotification = btToastrService.open;

      // Variable declarations
      $scope.popover = btPopoverService.init($templateCache.get('modals/bt-notification-center-popover.html'), {
        scope: $scope,
        interactive: true,
        backdrop: true,
      });

      /** @type {btNotification[]} */
      $scope.notifications = getNotifications();

      $scope.soundOn = btAudioService.getBeepSoundOn();

      // The rest of the activation
      $scope.$on('$destroy', onDestroy);
    }

    /**
     * This function initialized notification center and return notifications.
     *
     * @return {btNotification[]}
     */
    function getNotifications() {
      return btToastrService.list();
    }

    /**
     * This function opens notification center and refresh notifications (recalculate age, load new notifications).
     *
     */
    function openPopover() {
      $scope.popover.show();
      btToastrService.refresh();
    }

    /**
     * This function closes notification center
     */
    function closePopover() {
      $scope.popover.hide();
    }

    /**
     * This function is controller destructor
     */
    function onDestroy() {
      $scope.popover.remove();
    }

    /**
     * This function removes all notifications
     */
    function clearAll() {
      btToastrService.clear();
    }

    /**
     * This function mark all notifications as read
     */
    function readAll() {
      btToastrService.read();
      btToastrService.refresh();
    }

    /**
     *
     */
    function soundOnOff() {
      $scope.soundOn = !$scope.soundOn;
      btAudioService.setBeepSoundOnOff($scope.soundOn);
      var localSettings = btSettingsService.getLocalSettings();
      localSettings.set('beepSoundOn', $scope.soundOn);
    }
  }
})();
