/**
 * Created by BTsergey on 11/20/2017.
 */

// @ts-check
(function btNewAccountFormClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * @ngdoc directive
     * @name btNewAccountForm
     * @memberOf ecapp
     * @description
     *  This directive display broker request button or form.
     */
    .directive('btNewAccountForm', btNewAccountForm)
    .controller('btNewAccountFormController', btNewAccountFormController);

  btNewAccountForm.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btNewAccountForm($templateCache) {
    return {
      restrict: 'E',
      replace: false,
      scope: {
        brokerName: '@?',
      },
      template: $templateCache.get('bt-brokerage/directives/bt-new-account-form.html'),
      controller: 'btNewAccountFormController',
      link: {
        post: btNewAccountFormLinkPost,
      },
    };
  }

  btNewAccountFormController.$inject = [
    '$q',
    '$rootScope',
    '$scope',
    'btToastrService',
    '$http',
    'btShareScopeService',
    'btErrorService',
    'btToolsService',
    'btRestrictionService',
  ];

  /**
   *
   * @param {angular.IQService} $q - promise interface
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.IToastrService} btToastrService
   * @param {angular.IHttpService} $http
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IErrorService} btErrorService
   * @param {ecapp.IToolsService} btToolsService
   * @param {ecapp.IRestrictionService} btRestrictionService
   */
  function btNewAccountFormController(
    $q,
    $rootScope,
    $scope,
    btToastrService,
    $http,
    btShareScopeService,
    btErrorService,
    btToolsService,
    btRestrictionService
  ) {
    $scope.newAccount = {
      name: '',
      phone: '',
      code: '',
      note: '',
      country: '',
      markets: {
        forex: false,
        futures: false,
        stocks: false,
      },
    };

    $scope.createNewAccount = createNewAccount;

    // btShareScopeService.wait()
    //   .then(function () {
    //     $scope.newAccount.name = btShareScopeService.accountInfo.firstName;
    //   });

    /**
     * Send new account request to server
     * @param {Object} form - angular form controller
     */
    function createNewAccount(form) {
      if (form.$valid) {
        var url = window.btSettings.BT_BACKEND_URL;

        btToastrService.info('Request in process!', 'New account', { type: 'trade' });

        var phone = btToolsService.preparePhoneNumber('phone', $scope.newAccount.phone);

        var data = {
          params: {
            userEmail: $rootScope.currentUser.email,
            userName: $scope.newAccount.name,
            userNote: $scope.newAccount.note,
            userMarket: getMarkets(),
            userPhone: phone.full,
            userPhoneCountry: phone.country,
            userResidenceCountry: $scope.newAccount.country,
            brokerName: $scope.brokerName ? $scope.brokerName : 'n/a',
          },
        };

        if (btRestrictionService.hasFeature('personalization')) {
          btShareScopeService.setMailChimpField('TELEPHONE', phone.full);
          btShareScopeService.setMailChimpField('COUNTRY', phone.country);
        }

        $http
          .get(url + '/broker/account', data)
          .then(function (response) {
            if (response.status === 200 || response.status === 201) {
              $scope.newAccount.phone = '';
              form.$setPristine();
              form.$setUntouched();
              btToastrService.success('Soon you will get a confirmation email', 'New Trade Account', { type: 'trade' });
            } else {
              $q.reject(response);
            }
          })
          .catch(btErrorService.handleHTTPError)
          .catch(function (error) {
            console.error(error);
            btToastrService.error('Error: ' + error.message, 'New account', { type: 'trade' });
          });
      }
    }

    /**
     * Get markets as a text
     * @return {String} - text
     */
    function getMarkets() {
      if ($scope.newAccount.markets.forex || $scope.newAccount.markets.futures || $scope.newAccount.markets.stocks) {
        var markets = [];
        if ($scope.newAccount.markets.forex) {
          markets.push('Forex');
        }

        if ($scope.newAccount.markets.futures) {
          markets.push('Futures');
        }

        if ($scope.newAccount.markets.stocks) {
          markets.push('Stocks');
        }

        return markets.join(', ');
      } else {
        return '';
      }
    }
  }

  /**
   *
   */
  function btNewAccountFormLinkPost() {
    // noinspection JSUnresolvedFunction
    $('#user-phone').intlTelInput();
  }
})();
