/**
 * Created by Sergey Panpurin on 4/8/2018.
 */

(function btCalendarClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     *  This directive represents items in calendar view.
     *  Calendar is a list of card with or without day separators. Calendar separates past and future cards. Calendar
     *  highlights set of next cards and automatically updates it. Calendar have full and short (collapsed) views.
     *
     *  Variable collapseSize control size of collapsed view. In collapsed view next events will be showed.
     *
     *  *** Parameter: data-items ***
     *
     *  Each item must have id and time properties. Calendar uses id to track ng-repeat, so it must be unique. Calendar
     *  uses time to display next item marker.
     *
     *  Expected data structure of item:
     *  {
     *    id: '1234',       // Any string or number
     *    time: 1525172705  // Unix timestamp in seconds
     *  }
     *
     *  Item object will be saved to calendar card as a data property. So it can be used in custom template. For
     *  example, to access item property "class" use next reference "card.data.class".
     *
     *  Calendar support "hot" items. If item have property isHot
     *
     *  Attention: Calendar detect just changes of items object.
     *
     *  *** Parameter: data-templates ***
     *
     *  Calendar support custom templates. Be careful, custom templates overwrite default templates, so all properties
     *  must be initialized. This parameter is constant. It can't be modified after initialization.
     *
     *  Expected data structure of templates:
     *  {
     *    empty: 'template for empty calendar',
     *    head: 'template for head line',
     *    card: 'template for item card',
     *    end: 'template of day end',
     *    separator: 'template for day separator'
     *  }
     *
     *  *** Parameter: data-meta ***
     *
     *  Meta data can be used to customize calendar. For example instrument calendar save instrument name to met a object.
     *  This parameter is constant. It can't be modified after initialization.
     *
     *  Calendar support meta.collapseSize property to customize number of cards in collapsed view.
     *
     *  *** Parameter: has-separator ***
     *
     *  Set true to add day separators to calendar. Default value is true.
     *
     *  This parameter is constant. It can't be modified after initialization.
     *
     *  *** HTML Structure ***
     *
     *  Calendar consists of bt-calendar-element. There are next types of elements:
     *   * bt-calendar-empty - empty calendar will have just this element
     *   * bt-calendar-more - element to collapse or expand calendar
     *   * bt-calendar-head - element show calendar columns description
     *   * bt-calendar-card - calendar cards with user data
     *   * bt-calendar-separator - day separators
     *   * bt-calendar-end - there is no more future cards in calendar
     *
     * @ngdoc directive
     * @name btCalendarDirective
     * @memberOf ecapp
     */
    .directive('btCalendar', btCalendarDirective)
    .controller('btCalendarController', btCalendarController);

  btCalendarDirective.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {any}
   */
  function btCalendarDirective($templateCache) {
    return {
      restrict: 'E',
      scope: {
        meta: '<?',
        items: '<',
        customTemplates: '<?templates',
        hasSeparatorText: '@hasSeparator',
      },
      template: $templateCache.get('components/mobile/bt-calendar/templates/bt-calendar.html'),
      controller: 'btCalendarController',
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btCalendarController.$inject = ['$scope', 'btComponentCalendarService'];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.IComponentCalendarService} btComponentCalendarService
   */
  function btCalendarController($scope, btComponentCalendarService) {
    void $scope;

    /*jshint validthis: true*/
    var vm = this;

    vm.meta = vm.meta || {};
    vm.items = vm.items || [];
    vm.templates = vm.customTemplates || {
      empty: 'components/mobile/bt-calendar/templates/bt-calendar-default-empty.html',
      head: 'components/mobile/bt-calendar/templates/bt-calendar-default-head.html',
      card: 'components/mobile/bt-calendar/templates/bt-calendar-default-card.html',
      end: 'components/mobile/bt-calendar/templates/bt-calendar-default-end.html',
      separator: 'components/mobile/bt-calendar/templates/bt-calendar-default-separator.html',
    };
    vm.hasSeparator = vm.hasSeparatorText ? vm.hasSeparatorText === 'true' : true;

    vm.cards = [];
    vm.isEmpty = true;
    vm.isCollapsible = false;
    vm.isCollapsed = true;
    vm.collapseSize = vm.meta.collapseSize || 5;

    vm.toggleCollapse = toggleCollapse;

    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;
    vm.$onChanges = onChange;

    var gAutoUpdatePromise = null;

    /**
     * Initialize component
     */
    function onInit() {
      prepareCalendar();
    }

    /**
     * Destroy component
     */
    function onDestroy() {
      stopAutoUpdate();
    }

    /**
     * Called whenever one-way (<) or interpolation (@) bindings are updated.
     * @param {Record<String, Object>}changesObj
     */
    function onChange(changesObj) {
      if (changesObj.items) {
        onItemsChanges(changesObj.items.currentValue, changesObj.items.previousValue);
      }
    }

    /**
     * Update calendar on items change.
     * @param {Array} currentItems - new items
     * @param {Array} originalItems - old items
     */
    function onItemsChanges(currentItems, originalItems) {
      if (currentItems !== originalItems) {
        if (currentItems !== undefined) {
          prepareCalendar();
        }
      }
    }

    /**
     * Stop auto update.
     */
    function stopAutoUpdate() {
      if (gAutoUpdatePromise !== null) {
        if (btComponentCalendarService.stopAutoUpdate(gAutoUpdatePromise)) {
          gAutoUpdatePromise = null;
        }
      }
    }

    /**
     * Toggle full and collapsed views.
     */
    function toggleCollapse() {
      if (vm.isCollapsible) {
        vm.isCollapsed = !vm.isCollapsed;
      }
    }

    /**
     * Prepare calendar: generate cards, mark next cards, setup collapsed view, start auto update.
     */
    function prepareCalendar() {
      vm.cards = btComponentCalendarService.prepareCards(vm.items, vm.hasSeparator, vm.templates);
      vm.isEmpty = vm.cards.length === 0;
      vm.isCollapsible = vm.cards.length > vm.collapseSize;

      btComponentCalendarService.startAutoUpdate(vm.cards, vm.collapseSize, onAutoUpdate);
    }

    /**
     * Save $timeout promise during auto update cycle.
     * @param {*} promise
     */
    function onAutoUpdate(promise) {
      gAutoUpdatePromise = promise;
    }
  }
})();
