var gDebug = false;
var id = 1;

/**
 * @implements {TradingView.IBasicDataFeed}
 */
class TradeStationDataFeed {
  constructor(service) {
    this.uid = 'TV_DataFeed_' + id++;

    /** @type {ecapp.ITradingService} */
    this.service = service;

    this.configuration = {
      exchanges: [],
      symbols_types: [],
      supported_resolutions: ['1', '5', '15', '30', '1H', '4H', '8H', 'D', 'W', 'M'],
      currency_codes: undefined,
      units: undefined,
      supports_marks: false,
      supports_timescale_marks: false,
      supports_time: false,
      symbols_grouping: false,
    };

    this.subscriptions = {};
    if (gDebug) console.log(this.uid, 'Creating datafeed...');
  }

  /**
   *
   * @param {TradingView.OnReadyCallback} callback
   */
  onReady(callback) {
    if (gDebug) console.log(this.uid, 'onReady');
    var that = this;

    Promise.resolve()
      .then(this.service.initialize)
      .then(this.service.connect)
      .then(function () {
        callback(that.configuration);
      });
  }

  /**
   *
   * @param {string[]} symbols
   * @param {TradingView.QuotesCallback} onDataCallback
   * @param {TradingView.ErrorCallback} onErrorCallback
   */
  getQuotes(symbols, onDataCallback, onErrorCallback) {
    if (gDebug) console.log(this.uid, 'getQuotes', symbols);

    setTimeout(function () {
      onErrorCallback('NO QUOTES');
    }, 100);
  }

  /**
   *
   * @param {string[]} symbols
   * @param {string[]} fastSymbols
   * @param {TradingView.QuotesCallback} onRealtimeCallback
   * @param {string} listenerGuid
   */
  subscribeQuotes(symbols, fastSymbols, onRealtimeCallback, listenerGuid) {
    if (gDebug) console.log(this.uid, 'subscribeQuotes', symbols, fastSymbols, listenerGuid);
  }

  /**
   *
   * @param {string} listenerGuid
   */
  unsubscribeQuotes(listenerGuid) {
    if (gDebug) console.log(this.uid, 'unsubscribeQuotes', listenerGuid);
  }

  /**
   *
   * @param {TradingView.ResolutionString} resolution
   * @param {TradingView.ResolutionBackValues} resolutionBack
   * @param {TradingView.number} intervalBack
   * @return {TradingView.HistoryDepth | undefined}
   */
  calculateHistoryDepth(resolution, resolutionBack, intervalBack) {
    if (gDebug) console.log(this.uid, 'calculateHistoryDepth', resolution, resolutionBack, intervalBack);
    return undefined;
  }

  /**
   *
   * @param {TradingView.LibrarySymbolInfo} symbolInfo
   * @param {number} from
   * @param {number} to
   * @param {TradingView.GetMarksCallback<TradingView.Mark>} onDataCallback
   * @param {TradingView.ResolutionString} resolution
   */
  getMarks(symbolInfo, from, to, onDataCallback, resolution) {
    if (gDebug) console.log(this.uid, 'symbolInfo', symbolInfo, from, to, resolution);
  }

  /**
   *
   * @param {TradingView.LibrarySymbolInfo} symbolInfo
   * @param {number} from
   * @param {number} to
   * @param {TradingView.GetMarksCallback<TradingView.TimescaleMark>} onDataCallback
   * @param {TradingView.ResolutionString} resolution
   */
  getTimescaleMarks(symbolInfo, from, to, onDataCallback, resolution) {
    if (gDebug) console.log(this.uid, 'getTimescaleMarks', symbolInfo, from, to, resolution);
  }

  /**
   *
   * @param {TradingView.ServerTimeCallback} callback
   */
  getServerTime(callback) {
    void callback;
    if (gDebug) console.log(this.uid, 'getServerTime');
  }

  /**
   *
   * @param {string} userInput
   * @param {string} exchange
   * @param {string} symbolType
   * @param {TradingView.SearchSymbolsCallback} onResult
   */
  searchSymbols(userInput, exchange, symbolType, onResult) {
    if (gDebug) console.log(this.uid, 'searchSymbols', userInput, exchange, symbolType);
    var that = this;

    this.service
      .suggestSymbols(userInput, 10, {})
      .then(function (symbols) {
        var results = symbols.map(prepareSymbolSearch);
        if (gDebug) console.log(that.uid, '> searchSymbols', results);
        onResult(results);
      })
      .catch(function (error) {
        if (gDebug) console.log(that.uid, '> searchSymbols', error);
        onResult([]);
      });
  }

  /**
   *
   * @param {string} symbolName
   * @param {TradingView.ResolveCallback} onResolve
   * @param {TradingView.ErrorCallback} onError
   * @param {TradingView.SymbolResolveExtension} [extension]
   */
  resolveSymbol(symbolName, onResolve, onError, extension) {
    if (gDebug) console.log(this.uid, 'resolveSymbol', symbolName, extension);
    var that = this;

    var res = parseTicker(symbolName);
    var symbol = res.symbol;
    var prefix = res.prefix;

    if (prefix !== 'TRADESTATION') {
      setTimeout(function () {
        if (gDebug) console.log(that.uid, '> resolveSymbol Unknown symbol');
        onError('Unknown symbol');
      }, 100);
    } else {
      this.service
        .getSymbolInfo(symbol)
        .then(function (instrument) {
          var symbolInfo = prepareSymbolInfo(instrument);
          if (gDebug) console.log(that.uid, '> resolveSymbol', symbolInfo);
          onResolve(symbolInfo);
        })
        .catch(function (error) {
          if (gDebug) console.log(that.uid, '> resolveSymbol', error);
          onError(error.message);
        });
    }
  }

  /**
   *
   * @param {TradingView.LibrarySymbolInfo} symbolInfo
   * @param {TradingView.ResolutionString} resolution
   * @param {TradingView.PeriodParams} periodParams - seconds
   * @param {TradingView.HistoryCallback} onResult
   * @param {TradingView.ErrorCallback} onError
   */
  getBars(symbolInfo, resolution, periodParams, onResult, onError) {
    if (gDebug) console.log(this.uid, 'getBars', symbolInfo, resolution, periodParams);
    var that = this;

    var granularity = parseResolution(resolution);
    if (!granularity) return onError('Unsupported resolution');

    var from = periodParams.from * 1000;
    var to = periodParams.to * 1000;

    var res = parseTicker(symbolInfo.ticker);
    var symbol = res.symbol;
    var period = { from: from, to: to, back: periodParams.countBack };

    if (gDebug) console.log(this.uid, 'getBars', symbol, granularity, period);

    this.service
      .getCandles(symbol, granularity, period)
      .then(function (data) {
        var bars = prepareBars(data.candles);
        // .filter(function (bar) {
        //   return bar.time >= start && bar.time <= end;
        // });

        if (gDebug) console.log(that.uid, 'getBars', bars);
        if (bars.length) {
          if (gDebug) console.log(that.uid, '> getBars', bars[0].time, bars[bars.length - 1].time);
        }
        onResult(bars);
      })
      .catch(function (error) {
        if (gDebug) console.log(that.uid, '> getBars', error);
        onResult([], { noData: true });
        // onError(error.message);
      });
  }

  /**
   *
   * @param {TradingView.LibrarySymbolInfo} symbolInfo
   * @param {TradingView.ResolutionString} resolution
   * @param {TradingView.SubscribeBarsCallback} onTick
   * @param {string} listenerGuid
   * @param {() => void} onResetCacheNeededCallback
   */
  subscribeBars(symbolInfo, resolution, onTick, listenerGuid, onResetCacheNeededCallback) {
    void onResetCacheNeededCallback;
    if (gDebug) console.log(this.uid, 'subscribeBars', symbolInfo, resolution, listenerGuid);
    var that = this;

    var granularity = parseResolution(resolution);
    if (!granularity) {
      if (gDebug) console.log(that.uid, 'Unsupported resolution');
      // return onError('Unsupported resolution');
    }

    var res = parseTicker(symbolInfo.ticker);
    var symbol = res.symbol;

    this.service
      .streamSnapshot(symbol, granularity, 1, function (msg) {
        var bar = {
          time: msg.time,
          open: msg.mid.o,
          high: msg.mid.h,
          low: msg.mid.l,
          close: msg.mid.c,
          volume: msg.volume,
        };
        onTick(bar);
      })
      .then(function (stream) {
        that.subscriptions[listenerGuid] = stream;
      })
      .catch(function (error) {
        if (gDebug) console.log(that.uid, error);
      });
  }

  /**
   *
   * @param {string} listenerGuid
   */
  unsubscribeBars(listenerGuid) {
    if (gDebug) console.log(this.uid, 'unsubscribeBars', listenerGuid);

    if (this.subscriptions[listenerGuid]) {
      this.subscriptions[listenerGuid].stop();
      delete this.subscriptions[listenerGuid];
    }
  }
}

// prettier-ignore
var RESOLUTION_OPTIONS = {
    '1': 'M1',
    '5': 'M5',
   '15': 'M15',
   '30': 'M30',
   '60': 'H1',
  '240': 'H4',
  '480': 'H8',
   '1D': 'D',
   '1W': 'W',
   '1M': 'M',
};

/**
 *
 * @param {string} ticker - ticker
 * @return {{prefix: string, symbol: string}}
 */
function parseTicker(ticker) {
  var parts = ticker.split(':');

  return {
    prefix: parts[0],
    symbol: parts[1],
  };
}

/**
 *
 * @param {string} resolution
 * @return {string}
 */
function parseResolution(resolution) {
  return RESOLUTION_OPTIONS[resolution];
}

/**
 *
 * @param {ecapp.ITradingInstrument} instrument
 * @return {TradingView.symbolInfo}
 */
function prepareSymbolInfo(instrument) {
  return {
    name: instrument.displayName,
    ticker: instrument.ticker,
    description: instrument.description,
    type: instrument.type,
    session: '24x7',
    // session_display: '',
    // session_holidays: '',
    // session_holidays: '',
    // exchange: 'exchange',
    // listed_exchange: 'listed_exchange',
    timezone: 'Etc/UTC',
    format: 'price',
    minmov: 1,
    pricescale: 100,
    minmove2: 0,
    fractional: false,
    has_intraday: true,
    supported_resolutions: ['1', '5', '15', '30', '1H', '4H', '8H', 'D', 'W', 'M'],
    // intraday_multipliers: [],
    has_seconds: false,
    // seconds_multipliers: [],
    has_ticks: false,
    has_daily: true,
    // daily_multipliers: ['1'],
    has_weekly_and_monthly: true,
    // weekly_multipliers: ['1'],
    // monthly_multipliers: ['1'],
    has_empty_bars: false,
    // has_no_volume: false,
    visible_plots_set: 'ohlcv',
    volume_precision: 0,
    data_status: 'streaming',
    expired: false,
    // expiration_date: 0,
    // sector: 'sector',
    // industry: 'industry',
    // original_currency_code: 'original_currency_code',
    // currency_code: 'currency_code',
    // original_unit_id: 'original_unit_id',
    // unit_id: 'unit_id',
    // unit_conversion_types: 'unit_conversion_types',
  };
}

/**
 *
 * @param {ecapp.ITradingSymbol} info
 * @return {TradingView.SearchSymbolResultItem}
 */
function prepareSymbolSearch(info) {
  return {
    symbol: info.name,
    full_name: info.name,
    description: info.desc,
    exchange: 'tradestation',
    ticker: info.ticker,
    type: info.type,
  };
}

/**
 *
 * @param {ecapp.ITradingCandle[]} candles
 * @return {TradingView.Bar[]}
 */
function prepareBars(candles) {
  var bars = candles.map(function (candle) {
    return {
      time: candle.time * 1000,
      open: candle.mid.o,
      high: candle.mid.h,
      low: candle.mid.l,
      close: candle.mid.c,
      volume: candle.volume,
    };
  });

  return bars;
}

window.TradeStationDatafeed = TradeStationDataFeed;
