(function btActualValueFilterClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * Returns actual value
     *
     * @ngdoc filter
     * @name btActualValueFilter
     * @memberOf ecapp
     */
    .filter('btActualValue', [btActualValueFilter]);

  /**
   *
   * @return {angular.IFilterFunction}
   */
  function btActualValueFilter() {
    return function convertTrend(actual) {
      return actual === 'NA' ? ' - ' : actual;
    };
  }
})();
