/**
 * Created by Sergey Panpurin on 8/13/2017.
 */

(function btEventCardInsightsQuickViewClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive ???
     *
     * @ngdoc directive
     * @name btEventCardInsightsQuickView
     * @memberOf ecapp
     * @param {ionic.ISlideBoxDelegateService} $ionicSlideBoxDelegate - ionic slide box delegate service
     */
    .directive('btEventCardInsightsQuickView', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/event-card/insights-quick-view.html'),
          controller: [
            '$scope',
            '$ionicSlideBoxDelegate',
            function ($scope, $ionicSlideBoxDelegate) {
              $scope.isLoading = true;
              $scope
                .getInsightsQuickView($scope.a)
                .then(function () {
                  $scope.isLoading = false;
                })
                .catch(function (reason) {
                  console.log('btEventCardInsightsQuickView: error', reason);
                });

              $scope.slide = function (index) {
                $ionicSlideBoxDelegate.slide(index);
              };
            },
          ],
        };
      },
    ]);
})();
