/**
 * Created by Sergey Panpurin on 6/5/2018.
 */

/* global Sentry */

// @ts-check
(function btErrorServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btErrorService:';

  /**
   * @ngdoc service
   * @name btErrorService
   * @memberOf btUtils
   * @description
   *  This service ...
   */
  angular.module('btUtils').factory('btErrorService', btErrorService);

  btErrorService.$inject = ['$q'];

  /**
   * @typedef {object} btAppErrorRecord
   * @property {Date} created - creation date
   * @property {string} module - module name
   * @property {string} code - error code
   * @property {string} error - error message
   */

  /**
   * @param {angular.IQService} $q - promise interface
   * @return {ecapp.IErrorService}
   */
  function btErrorService($q) {
    /** @type {number} */
    var BUFFER_SIZE = 20;

    /** @type {string} */
    var BUFFER_NAME = 'btErrorBuffer';

    /** @type {btAppErrorRecord[]} */
    var gErrorBuffer = restoreAppErrors();

    /** @type {{value: number}} */
    var gErrorCounter = {
      value: 0,
    };

    /**
     * @name btUtils.btErrorService#ErrorLevel
     * @type {{ERROR: string, INFO: string, FATAL: string, WARNING: string, DEBUG: string}}
     */
    var ErrorLevel = {
      FATAL: 'fatal',
      ERROR: 'error',
      WARNING: 'warning',
      INFO: 'info',
      DEBUG: 'debug',
    };

    /** @type {Record<string, boolean>} */
    var gRegisteredErrorCodes = {};

    /**
     * @name btUtils.btErrorService#ErrorCode
     * @type {Record<string, string>}
     */
    var ErrorCode = {
      /* Data received from the server is outdated. */
      OUTDATED: 'OUTDATED_DATA',
      loginMailNotVerified: 'LOGIN_FAILED_EMAIL_NOT_VERIFIED',
      loginNotInWhitelist: 'LOGIN_FAILED_WHITELIST',
      loginDuplication: 'LOGIN_FAILED_DUPLICATION',
      loginDevelopersOnly: 'LOGIN_FAILED_DEV_ONLY',
      loginNoAccess: 'LOGIN_FAILED_NO_ACCESS',
      loginFailed: 'LOGIN_FAILED',
      testUserError: 'TEST_USER_ERROR',
      internalError: 'INTERNAL_ERROR',
      badArgument: 'BAD_ARGUMENT_ERROR',
      noInternet: 'NO_INTERNET',
      httpError: 'HTTP_ERROR',
      unknownError: 'UNKNOWN_ERROR',
      usageUpdateFailure: 'USAGE_UPDATE_FAILURE',
      noEvents: 'EVENTS_LOADING_FAILURE',
      noUser: 'USER_LOADING_FAILURE',
      tutorialNotFinished: 'TUTORIAL_NOT_FINISHED',
      expiredData: 'EXPIRED_DATA',
      unexpectedData: 'UNEXPECTED_DATA',
    };

    /**
     * @name btUtils.btErrorService#ErrorMessage
     * @type {Record<string, string>}
     */
    var ErrorMessage = {
      SOMETHING_WRONG: 'Something went wrong, please try again shortly.',
      MARKET_CHARACTERISTICS_OUTDATED: 'Market characteristics is outdated.',
      DATA_LOADING_FAILED: 'Data loading was failed.',
    };

    // Initialize prototypes for custom errors
    ServerError.prototype = Object.create(Error.prototype, {
      constructor: { value: Error, enumerable: false, writable: true, configurable: true },
    });
    ClientError.prototype = Object.create(Error.prototype, {
      constructor: { value: Error, enumerable: false, writable: true, configurable: true },
    });

    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(ServerError, Error);
      Object.setPrototypeOf(ClientError, Error);
    } else {
      ServerError.__proto__ = Error;
      ClientError.__proto__ = Error;
    }

    return {
      ErrorLevel: ErrorLevel,
      ErrorMessage: ErrorMessage,
      ErrorCode: ErrorCode,
      getErrorCounter: getErrorCounter,
      reportAppError: reportAppError,
      getAppErrors: getAppErrors,
      // clearAppErrors: clearAppErrors,
      skip: skipError,
      skipTransitionSuperseded: skipTransitionSuperseded,
      print: printError,
      getErrorHandler: getErrorHandler,
      handleHTTPError: handleHTTPError,
      registerErrorCode: registerErrorCode,
      ServerError: ServerError,
      ClientError: ClientError,
    };

    /**
     * This is a server error object.
     *
     * @alias btUtils.btErrorService#ServerError
     * @param {string} code
     * @param {number} status
     * @param {string} message
     * @param {string} desc
     * @return {Error}
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
     * @class
     */
    function ServerError(code, status, message, desc) {
      var instance = new Error(message);
      instance.name = 'ServerError';
      instance.code = code;
      instance.status = status;
      instance.desc = desc || '';

      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));

      if (Error.captureStackTrace) {
        Error.captureStackTrace(instance, ServerError);
      }

      return instance;
    }

    /**
     * This is client error object.
     *
     * @alias btUtils.btErrorService#ClientError
     * @param {string} code
     * @param {string} message
     * @param {string} desc
     * @return {Error}
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
     * @class
     */
    function ClientError(code, message, desc) {
      var instance = new Error(message);
      instance.name = 'ClientError';
      instance.code = code;
      instance.desc = desc || '';

      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));

      if (Error.captureStackTrace) {
        Error.captureStackTrace(instance, ClientError);
      }

      gErrorCounter.value++;

      return instance;
    }

    /**
     * This function returns error counter object.
     *
     * @alias btUtils.btErrorService#getErrorCounter
     * @return {*}
     */
    function getErrorCounter() {
      return gErrorCounter;
    }

    /**
     * This function just skip error.
     *
     * @alias btUtils.btErrorService#skip
     * @param {Error} error - error instance
     */
    function skipError(error) {
      void error;
    }

    /**
     * This function just skip error.
     *
     * @alias btUtils.btErrorService#skipTransitionSuperseded
     * @param {Error} error - error instance
     * @return {*}
     */
    function skipTransitionSuperseded(error) {
      if (error.message !== 'transition superseded') return $q.reject(error);
    }

    /**
     * This function just skip error.
     *
     * @alias btUtils.btErrorService#print
     * @param {Error} error - error instance
     */
    function printError(error) {
      console.error(error);
    }

    /**
     * Try to parse error code and message from Loopback Error
     *
     * @alias btUtils.btErrorService#handleHTTPError
     * @param {*|angular.IHttpResponse} obj - some object
     * @return {angular.IPromise<never>}
     */
    function handleHTTPError(obj) {
      // if reason is not a http response skip it
      if (!isHTTPResponse(obj)) {
        return $q.reject(obj);
      }

      return $q.reject(parseHTTPError(obj));
    }

    /**
     * This function checks if object is a HTTP response.
     *
     * @private
     * @param {angular.IHttpResponse} res - http response
     * @return {boolean}
     * @see https://docs.angularjs.org/api/ng/service/$http
     */
    function isHTTPResponse(res) {
      return (
        !!res &&
        res.data !== undefined &&
        res.status !== undefined &&
        res.config !== undefined &&
        res.headers !== undefined
      );
    }

    /**
     * This function parses error from http response.
     *
     * @private
     * @param {angular.IHttpResponse} res - http response
     * @return {Error}
     */
    function parseHTTPError(res) {
      var err;

      if (res.status === -1) {
        // request was aborted
        err = new ServerError(
          ErrorCode.noInternet,
          -1,
          'Request aborted',
          "Can't receive data from server. Please check internet connection."
        );
      } else {
        if (res.data && res.data.error) {
          err = prepareServerError(res, res.data.error.code, res.data.error.status, res.data.error.message);
        } else if (res.data && res.data.message) {
          err = prepareServerError(res, res.data.code, res.data.status, res.data.message);
        } else {
          err = prepareServerError(res);
        }

        if (res.status >= 200 && res.status <= 299) {
          // success
        }

        if (res.status >= 300 && res.status <= 399) {
          // redirection
        }

        if (res.status >= 400 && res.status <= 499) {
          // client errors
        }

        if (res.status >= 500 && res.status <= 599) {
          // server errors
        }
      }

      if (gDebug) console.log(gPrefix, res.status + '-' + res.statusText);

      return err;
    }

    /**
     *
     * @param {angular.IHttpResponse} res - http response
     * @param {string} [code] - error code
     * @param {string} [status] - http status
     * @param {string} [msg] - error message
     * @param {string} [desc] - error description
     * @return {Error}
     */
    function prepareServerError(res, code, status, msg, desc) {
      return new ServerError(
        code || ErrorCode.httpError,
        status || res.status,
        msg || res.statusText,
        desc || 'HTTP ' + res.status + ': ' + res.statusText
      );
    }

    /**
     *
     * @alias btUtils.btErrorService#getErrorHandler
     * @param {string} module - module name
     * @param {string} code - error code
     * @param {string} level - error level
     * @return {function(*=): angular.IPromise<never>}
     */
    function getErrorHandler(module, code, level) {
      return function (error) {
        reportAppError(error, module, code, level);
        return $q.reject(error);
      };
    }

    /**
     * This function reports about user error.
     *
     * @alias btUtils.btErrorService#reportAppError
     * @param {Error} error - error instance
     * @param {string} [module] - module name
     * @param {string} [code] - error code
     * @param {string} [level] - error level
     */
    function reportAppError(error, module, code, level) {
      code = code || ErrorCode.unknownError;
      module = module || 'unknown';
      level = level || ErrorLevel.ERROR;

      switch (level) {
        case ErrorLevel.FATAL:
          storeAppError(error, module, code, level);
          console.error(error);
          break;
        case ErrorLevel.ERROR:
          console.error(error);
          break;
        case ErrorLevel.WARNING:
          console.warn(error);
          break;
        case ErrorLevel.INFO:
          console.log(error);
          break;
        case ErrorLevel.DEBUG:
          console.debug(error);
          break;
        default:
          console.log(error);
      }

      if (window.Sentry) {
        Sentry.withScope(function (scope) {
          scope.setTag('error_code', code || ErrorCode.unknownError);
          scope.setTag('module', module || 'unknown');
          scope.setLevel(level || ErrorLevel.ERROR);
          Sentry.captureException(error);
        });
      }
    }

    /**
     * This function store user error to local storage.
     *
     * @param {Error} error - error instance
     * @param {string} module - module name
     * @param {string} code - error code
     * @param {string} level - error level
     */
    function storeAppError(error, module, code, level) {
      gErrorBuffer.push({
        created: new Date(),
        module: module,
        code: code,
        error: error.toString(),
        level: level,
      });
      if (gErrorBuffer.length > BUFFER_SIZE) gErrorBuffer.shift();
      localStorage.setItem(BUFFER_NAME, JSON.stringify(gErrorBuffer));
    }

    /**
     * This function restore errors from local storage.
     *
     * @return {btAppErrorRecord[]}
     */
    function restoreAppErrors() {
      var errors = [];

      try {
        errors = JSON.parse(localStorage.getItem(BUFFER_NAME) || '[]');
        errors.forEach(function (value) {
          value.created = new Date(/**@type {string}*/ value.created);
        });
      } finally {
        //
      }

      return errors;
    }

    /**
     *
     * @alias btUtils.btErrorService#getAppErrors
     * @return {btAppErrorRecord[]}
     */
    function getAppErrors() {
      return gErrorBuffer;
    }

    // /**
    //  * This function clears error buffer.
    //  *
    //  * @alias btUtils.btErrorService#getAppErrors
    //  */
    // function clearAppErrors() {
    //   while (gErrorBuffer.length) gErrorBuffer.pop();
    //   localStorage.removeItem(BUFFER_NAME);
    // }

    /**
     * This function registers a new error code.
     *
     * @alias btUtils.btErrorService#registerErrorCode
     * @param {string} code - error code
     * @return {boolean}
     */
    function registerErrorCode(code) {
      if (gRegisteredErrorCodes[code] === undefined) {
        gRegisteredErrorCodes[code] = true;
      }

      return gRegisteredErrorCodes[code];
    }
  }
})();
