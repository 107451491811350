/**
 * Created by Sergei Panpurin on 12/26/19.
 */

// @ts-check
(function btUserSettingsServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btUserSettingsService:';

  angular.module('ecapp').factory('$btUserSettings', service);

  service.$inject = ['$q', '$rootScope', 'btShareScopeService', 'btRestrictionService'];

  /**
   * This service ...
   *
   * @ngdoc service
   * @name ecapp.$btUserSettings
   * @param {angular.IQService} $q
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @return {ecapp.IUserSettingsService}
   */
  function service($q, $rootScope, btShareScopeService, btRestrictionService) {
    if (gDebug) console.log(gPrefix, 'running...');

    var gDefaultSettings = {};

    // eslint-disable-next-line no-unused-vars
    var SUPPORTED_UNITS = [
      'toggle',
      'slider',
      'range',
      'select',
      'multiple',
      'radio-group',
      'drop-down',
      'list',
      'title',
    ];

    return {
      setDefaultSettings: setDefaultSettings,
      loadUserSettings: loadUserSettings,
      getUserSettings: getUserSettings,
      saveUserSettings: saveUserSettings,
      compressSettings: compressSettings,
      decompressSettings: decompressSettings,
      getUnit: getUnit,
      getSection: getSection,
      getBlock: getBlock,
      setUnitValue: setUnitValue,
      getUserSettingsValue: getUserSettingsValue,
      setUserSettingsValue: setUserSettingsValue,
      isEqualUserSettings: isEqualUserSettings,
    };

    /**
     * @alias ecapp.$btUserSettings#setDefaultSettings
     * @param {string} name - name of feature
     * @param {ecapp.ISettingsObject} settings - settings object
     */
    function setDefaultSettings(name, settings) {
      if (gDefaultSettings[name]) console.error('Rewriting default settings for ' + name);
      gDefaultSettings[name] = settings;
    }

    /**
     * @alias ecapp.$btUserSettings#loadUserSettings
     * @param {string} name - name of feature
     */
    function loadUserSettings(name) {
      btShareScopeService.wait().then(function () {
        return getUserSettings(name);
      });
    }

    /**
     * This function updates user's filters values in the database
     *
     * @alias ecapp.$btUserSettings#saveUserSettings
     * @param {string} name
     * @param {ecapp.ICompressedSettingsObject} data
     * @return {angular.IPromise<*>}
     */
    function saveUserSettings(name, data) {
      if (!data) return $q.reject(new Error('Bad risk monitor settings!'));

      var promise;

      if (btRestrictionService.hasFeature(name)) {
        promise = $q.resolve();
      } else {
        promise = btRestrictionService.showUpgradePopup(name).then(function (value) {
          if (value !== 'updated') {
            return $q.reject(new Error('Saving failed. You subscription didn\'t support next feature:"' + name + '".'));
          }
        });
      }

      return promise.then(function () {
        $rootScope.$broadcast(name + ':settings:updated', data);
        return btShareScopeService.saveUserSettings(name, data);
      });
    }

    /**
     * This function returns user settings or default settings
     *
     * @alias ecapp.$btUserSettings#getUserSettings
     * @param {string} name - ?
     * @return {?ecapp.ICompressedSettingsObject}
     */
    function getUserSettings(name) {
      if (btRestrictionService.hasFeature(name) && btShareScopeService.getUserSettings(name)) {
        return btShareScopeService.getUserSettings(name);
      } else if (gDefaultSettings[name]) {
        return compressSettings(gDefaultSettings[name]);
      } else {
        console.error('Default settings for ' + name + ' was not defined!');
        return null;
      }
    }

    /**
     * This function converts user's filters' settings to minified format
     *
     * @alias ecapp.$btUserSettings#compressSettings
     * @param {ecapp.ISettingsObject} settings
     * @return {?ecapp.ICompressedSettingsObject}
     */
    function compressSettings(settings) {
      if (settings) {
        /** @type {ecapp.ICompressedSettingsObject} */
        var compressed = {};
        settings.blocks.forEach(function (block) {
          compressBlock(compressed, block);
        });
        if (gDebug) console.log(gPrefix, 'Settings was compressed.', compressed);
        return compressed;
      } else {
        return null;
      }
    }

    /**
     *
     * @param {*} compressed
     * @param {*} block
     */
    function compressBlock(compressed, block) {
      compressed[block.id] = {
        enabled: block.params.enabled,
      };

      block.sections.forEach(function (section) {
        compressSection(compressed, block, section);
      });
    }

    /**
     *
     * @param {*} compressed
     * @param {*} block
     * @param {*} section
     */
    function compressSection(compressed, block, section) {
      section.filters.forEach(function (unit) {
        compressUnit(compressed, block, section, unit);
      });
    }

    /**
     *
     * @param {*} compressed
     * @param {*} block
     * @param {*} section
     * @param {*} unit
     */
    function compressUnit(compressed, block, section, unit) {
      void section;

      if (unit.type !== 'title') {
        compressed[block.id][unit.id] = compressUnitValue(unit);
      }
    }

    /**
     * This function returns minified version of set filter
     * @param {ecapp.IMaxFilterObject} unit
     * @return {*}
     */
    function compressUnitValue(unit) {
      var value;
      if (unit.id === 'instruments' || unit.id === 'currencies') {
        if (unit.id === 'currencies' && !unit.params.expanded) {
          return null;
        }

        value = [];
        unit.options.forEach(function (o) {
          if (o.selected) {
            value.push(o.value);
          }
        });
        return value;
      } else if (unit.id === 'priority') {
        return unit.value === '0' ? null : parseInt(unit.value);
      } else if (unit.type === 'select') {
        return unit.value.value;
      } else if (unit.type === 'range') {
        value = [unit.value.min, unit.value.max];
        if (unit.value.max === unit.range.max && !unit.params.limits.max) value[1] = null;
        if (unit.value.min === unit.range.min && !unit.params.limits.min) value[0] = null;
        return value;
      } else if (unit.type === 'slider') {
        return unit.value;
      } else {
        return unit.value;
      }
    }

    /**
     * This function converts filters' settings in minified format to full one
     *
     * @alias ecapp.$btUserSettings#decompressSettings
     * @param {ecapp.ISettingsObject} template - settings template
     * @param {ecapp.ICompressedSettingsObject} compressed - compressed settings
     * @return {ecapp.ISettingsObject}
     */
    function decompressSettings(template, compressed) {
      // using default filters as a template for filtersMax object
      // var template = getSettingsTemplate(name);

      template.blocks.forEach(function (block) {
        decompressBlock(compressed, block);
      });

      return template;
    }

    // /**
    //  * This function returns copy of default filters settings.
    //  *
    //  * @alias ecapp.$btUserSettings#getSettingsTemplate
    //  * @param {string} name - ?
    //  * @return {ecapp.ISettingsObject}
    //  */
    // function getSettingsTemplate(name) {
    //   return JSON.parse(JSON.stringify(gDefaultSettings[name]));
    // }

    /**
     *
     * @param {*} compressed
     * @param {*} block
     */
    function decompressBlock(compressed, block) {
      if (compressed[block.id]) {
        block.params.enabled = !!compressed[block.id].enabled;

        block.sections.forEach(function (section) {
          decompressSection(compressed, block, section);
        });
      }
    }

    /**
     *
     * @param {*} compressed
     * @param {*} block
     * @param {*} section
     */
    function decompressSection(compressed, block, section) {
      section.filters.forEach(function (unit) {
        decompressUnit(compressed, block, section, unit);
      });
    }

    /**
     *
     * @param {*} compressed
     * @param {*} block
     * @param {*} section
     * @param {*} unit
     */
    function decompressUnit(compressed, block, section, unit) {
      void section;
      var value = parseUnitValue(compressed, block.id, unit.id);
      setUnitValue(unit, value);
    }

    /**
     *
     * @param {*} compressed - minified filters
     * @param {*} blockId - section in which filter is set
     * @param {*} unitId - filter's id
     * @return {*}
     */
    function parseUnitValue(compressed, blockId, unitId) {
      if (compressed[blockId] != null && compressed[blockId][unitId] != null) {
        return compressed[blockId][unitId];
      } else {
        return null;
      }
    }

    /**
     * This function sets filter's value.
     *
     * @alias ecapp.$btUserSettings#setUnitValue
     * @param {ecapp.IMaxFilterObject} unit
     * @param {*} [value]
     * @return {*}
     */
    function setUnitValue(unit, value) {
      if (!unit) {
        console.error('Undefined unit');
        return false;
      }

      if (value == null) return;

      if (unit.id === 'instruments' || unit.id === 'currencies') {
        if (unit.id === 'currencies') {
          unit.params.expanded = !!value; //if currencies set then they're overridden so filter is expanded
        }
        unit.options.forEach(function (o) {
          if (value.indexOf(o.value) === -1) {
            o.selected = false;
          }
        });
      } else if (unit.id === 'priority') {
        // ??? Value could not be null
        unit.value = !value ? '0' : value.toString();
      } else if (unit.type === 'select') {
        // ??? It should not work
        return unit.options.filter(function (option) {
          return option.value === value;
        })[0];
      } else if (unit.type === 'range') {
        var min = value[0] === null ? unit.range.min : value[0];
        var max = value[1] === null ? unit.range.max : value[1];
        unit.value = { min: min, max: max };
      } else if (unit.type === 'slider') {
        unit.value = value > unit.range.max ? unit.range.max : value;
      } else {
        unit.value = value;
      }
    }

    // /**
    //  *
    //  * @param {string} blockIndex
    //  * @param {ecapp.ISettingsObject} settings
    //  * @return {boolean}
    //  */
    // function checkIsUpdated(blockIndex, settings) {
    //   if (!settings) return false;

    //   if (gFilterCache[blockIndex] === undefined) {
    //     gFilterCache[blockIndex] = JSON.parse(JSON.stringify(settings.blocks));
    //     return true;
    //   }

    //   var isUpdated = false;

    //   try {
    //     settings.blocks.forEach(function (block, i) {
    //       block.sections.forEach(function (section, j) {
    //         section.filters.forEach(function (unit, k) {
    //           // toggle, multiple, radio-group, range, magnitude, list, title
    //           if (unit.type === 'range') {
    //             if (gFilterCache[blockIndex][i]['sections'][j]['filters'][k].value.min !== unit.value.min) {
    //               isUpdated = true;
    //             }

    //             if (gFilterCache[blockIndex][i]['sections'][j]['filters'][k].value.max !== unit.value.max) {
    //               isUpdated = true;
    //             }
    //           } else if (unit.type === 'multiple') {
    //             unit.options.forEach(function (option, p) {
    //               if (
    //                 gFilterCache[blockIndex][i]['sections'][j]['filters'][k]['options'][p].selected !== option.selected
    //               ) {
    //                 isUpdated = true;
    //               }
    //             });
    //           } else {
    //             if (gFilterCache[blockIndex][i]['sections'][j]['filters'][k].value !== unit.value) {
    //               isUpdated = true;
    //             }
    //           }
    //         });
    //       });
    //     });
    //   } catch (e) {
    //     gFilterCache[blockIndex] = JSON.parse(JSON.stringify(settings.blocks));
    //     return isUpdated;
    //   }

    //   if (isUpdated) gFilterCache[blockIndex] = JSON.parse(JSON.stringify(settings.blocks));

    //   return isUpdated;
    // }

    // /**
    //  * This function checks if settings was changed and should be updated.
    //  *
    //  * @param {object} settings - risk monitor settings
    //  * @return {boolean}
    //  */
    // function isUpdateRequired(settings) {
    //   var oldSettings = getUserSettings();
    //   if (gDebug) console.log(gPrefix, settings, oldSettings);
    //   return !angular.equals(settings, oldSettings);
    // }

    /**
     * This function checks if user settings is equal.
     *
     * @alias ecapp.$btUserSettings#isEqualUserSettings
     * @param {ecapp.ICompressedSettingsObject} a - user settings A
     * @param {ecapp.ICompressedSettingsObject} b - user settings B
     * @return {boolean}
     */
    function isEqualUserSettings(a, b) {
      return angular.equals(a, b);
    }

    /**
     * This function returns settings unit.
     *
     * @alias ecapp.$btUserSettings#getUnit
     * @param {ecapp.ISettingsObject} settings - settings
     * @param {string} blockId - block identifier
     * @param {number} sectionIndex - section index
     * @param {string} unitId - unit identifier
     * @return {ecapp.IMaxFilterObject}
     */
    function getUnit(settings, blockId, sectionIndex, unitId) {
      var result = null;

      settings.blocks.forEach(function (block) {
        if (block.id === blockId) {
          block.sections[sectionIndex].filters.forEach(function (unit) {
            if (unit.id === unitId) result = unit;
          });
        }
      });

      return result;
    }

    /**
     * This function returns settings section.
     *
     * @alias ecapp.$btUserSettings#getSection
     * @param {ecapp.ISettingsObject} settings - settings
     * @param {string} blockId - block identifier
     * @param {number} sectionIndex - section index
     * @return {ecapp.ISectionObject}
     */
    function getSection(settings, blockId, sectionIndex) {
      var result = null;

      settings.blocks.forEach(function (block) {
        if (block.id === blockId) result = block.sections[sectionIndex];
      });

      return result;
    }

    /**
     * This function returns settings block.
     *
     * @alias ecapp.$btUserSettings#getBlock
     * @param {ecapp.ISettingsObject} settings - settings
     * @param {string} blockId - block identifier
     * @return {ecapp.ISettingsBlock}
     */
    function getBlock(settings, blockId) {
      var result = null;

      settings.blocks.forEach(function (block) {
        if (block.id === blockId) result = block;
      });

      return result;
    }

    /**
     * This function returns settings unit.
     *
     * @alias ecapp.$btUserSettings#getUserSettingsValue
     * @param {ecapp.ISettingsObject} settings - settings
     * @param {string} blockId - block identifier
     * @param {string} unitId - unit identifier
     * @return {*}
     */
    function getUserSettingsValue(settings, blockId, unitId) {
      if (settings) {
        return settings[blockId] ? settings[blockId][unitId] : undefined;
      } else {
        console.error('Undefined settings!');
        return undefined;
      }
    }

    /**
     * This function returns settings unit.
     *
     * @alias ecapp.$btUserSettings#getUserSettingsValue
     * @param {ecapp.ISettingsObject} settings - settings
     * @param {string} blockId - block identifier
     * @param {string} unitId - unit identifier
     * @param {*} value - unit identifier
     */
    function setUserSettingsValue(settings, blockId, unitId, value) {
      if (settings) {
        if (!settings[blockId]) settings[blockId] = {};
        settings[blockId][unitId] = value;
      } else {
        console.error('Undefined settings!');
      }
    }
  }
})();
