(function btFreeShowHideClosure() {
  'use strict';

  var ecapp = angular.module('ecapp');

  /**
   * This directive show something just for free user.
   *
   * @ngdoc directive
   * @name btFreeShow
   * @memberOf ecapp
   */

  ecapp.directive('btFreeShow', [
    'btDateService',
    '$timeout',
    'btRestrictionService',
    function (btDateService, $timeout, btRestrictionService) {
      return {
        restrict: 'A',
        scope: false,
        link: function (scope, elem, attrs) {
          if (attrs['btFreeType'] && btRestrictionService.hasFeature(attrs['btFreeType'])) {
            $(elem[0]).hide();
          } else {
            if (attrs['btFreeDate']) {
              var delay = btDateService.getFreeDelay(btDateService.getDateFromSec(attrs['btFreeDate']));
              if (delay === 0) {
                $(elem[0]).hide();
              } else {
                $(elem[0]).show();

                $timeout(
                  function () {
                    $(elem[0])
                      .find('.free-delay')
                      .text(parseInt(delay / 60 / 1000) + 1);
                  },
                  0,
                  false
                );

                $timeout(
                  function () {
                    $(elem[0]).hide();
                  },
                  delay,
                  false
                );
              }
            } else {
              $(elem[0]).show();
            }
          }
        },
      };
    },
  ]);

  /**
   * This directive hide something from free user.
   *
   * @ngdoc directive
   * @name btFreeHide
   * @memberOf ecapp
   */

  ecapp.directive('btFreeHide', [
    'btDateService',
    '$timeout',
    'btRestrictionService',
    function (btDateService, $timeout, btRestrictionService) {
      return {
        restrict: 'A',
        scope: false,
        link: function (scope, elem, attrs) {
          if (attrs['btFreeType'] && btRestrictionService.hasFeature(attrs['btFreeType'])) {
            $(elem[0]).show();
          } else {
            if (attrs['btFreeDate']) {
              var delay = btDateService.getFreeDelay(btDateService.getDateFromSec(attrs['btFreeDate']));

              if (delay === 0) {
                $(elem[0]).show();
              } else {
                $(elem[0]).hide();

                $timeout(
                  function () {
                    $(elem[0]).show();
                  },
                  delay,
                  false
                );
              }
            } else {
              $(elem[0]).hide();
            }
          }
        },
      };
    },
  ]);
})();
