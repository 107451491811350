(function btInviteCtrlClosure() {
  'use strict';

  var gDebug = false;

  angular.module('ecapp').controller('btInviteController', btInviteController);

  btInviteController.$inject = [
    '$scope',
    '$q',
    'btBranchService',
    'btShareScopeService',
    '$interval',
    'btSettings',
    'btRestrictionService',
    'btSettingsService',
  ];

  /**
   * Controller for invite friend page.
   *
   * @ngdoc controller
   * @name ecapp.btInstrumentPageController
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.IQService} $q - promise interface
   * @param {ecapp.IBranchService} btBranchService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.ISettings} btSettings
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btInviteController(
    $scope,
    $q,
    btBranchService,
    btShareScopeService,
    $interval,
    btSettings,
    btRestrictionService,
    btSettingsService
  ) {
    console.log('Running btInviteController');

    var gTitle = '';
    var gBody = '';

    var footer = encodeURIComponent('\n');
    var interval = null;
    var encodedReferralLink = '';
    var encodedRedirectUri = encodeURIComponent(btSettings.BT_BACKEND_URL + '/auth/facebook/callback');

    $scope.isBigBrainBank = btSettingsService.getDomain() === 'bigbrainbank';

    var FACEBOOK_APP_ID = $scope.isBigBrainBank ? '321072875681660' : '1770221983004395';

    $scope.firstName = null;
    $scope.mailToLink = null;
    $scope.smsLink = null;
    $scope.copyLinkToClipboard = copyLinkToClipboard;
    $scope.messengerLink = messengerLink;
    $scope.facebookLink = facebookLink;
    $scope.share = share;

    interval = $interval(prepareReferralMessage, 300, 0, false);

    btShareScopeService.wait().then(function () {
      if (btRestrictionService.hasFeature('personalization')) {
        btShareScopeService.increaseVisitPage('referral');
      }
    });

    /**
     *
     */
    function getFirstName() {
      $scope.firstName = btShareScopeService.getAccountInfoField('firstName');

      if ($scope.isBigBrainBank) {
        gTitle = 'Try TheBrain app';
        gBody = 'I am using TheBrain app, I think you will find it useful too. Give it a try!\n';
      } else {
        if (window.isIOS && window.isApp) {
          gTitle = 'Try BetterTrader.co app';
        } else {
          gTitle = "Here's a free BetterTrader.co app (worth 99$)";
        }

        if (window.isIOS && window.isApp) {
          gBody = 'I am using BetterTrader app, I think you will find it useful too. Give it a try!\n';
        } else {
          gBody = 'Get your FREE month of BetterTrader.co (worth 99$). To claim your gift, sign up using this link: ';
        }
      }
    }

    /**
     *
     */
    function prepareReferralMessage() {
      if (gDebug) console.log('Interval was called (prepareReferralMessage)');

      getReferralLink().then(function (link) {
        $scope.referralLink = link;
        encodedReferralLink = encodeURIComponent(link);

        // TODO Check???
        if (link) {
          $interval.cancel(interval);
        }

        // if (!$scope.firstName) {
        getFirstName();
        // }
        $scope.mailToLink =
          'mailto:?subject=' +
          encodeURIComponent(gTitle) +
          '&body=' +
          encodeURIComponent(gBody) +
          encodedReferralLink +
          footer;
        $scope.smsLink = 'sms:&body=' + encodeURIComponent(gBody) + encodedReferralLink;
      });
    }

    /**
     * This function prepares referral link.
     *
     * @return {angular.IPromise<string>}
     */
    function getReferralLink() {
      if ($scope.isBigBrainBank) {
        return btBranchService.getReferralLink(btShareScopeService.getAccountInfoField('email'));
      } else {
        return btBranchService.getReferralLink();
      }
    }

    /**
     * This function try to close the invitation banner.
     */
    function closeInvitationBanner() {
      var banner = btSettingsService.getGlobalBanner();
      if (banner && banner.config.id === btSettingsService.Banner.Invitation) {
        btSettingsService.closeGlobalBanner();
        btSettingsService.setReminder('reminders.invite', 1209600000 /* 14 days */);
      }
    }

    /**
     *
     */
    function copyLinkToClipboard() {
      var dummy = document.createElement('input');
      dummy.setAttribute('id', 'dummy_id');
      document.body.appendChild(dummy);
      document.getElementById('dummy_id').value = $scope.referralLink;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);

      closeInvitationBanner();
    }

    /**
     * This function opens messenger to share a link.
     */
    function messengerLink() {
      window.open(
        'https://www.facebook.com/dialog/send?app_id=' +
          FACEBOOK_APP_ID +
          '&display=popup&link=' +
          encodedReferralLink +
          '&redirect_uri=' +
          encodedRedirectUri,
        '',
        'width=500,height=300'
      );

      closeInvitationBanner();
    }

    /**
     * This function opens facebook to share a link.
     */
    function facebookLink() {
      window.open(
        'https://www.facebook.com/dialog/share?app_id=' +
          FACEBOOK_APP_ID +
          '&display=popup' +
          '&href=' +
          encodedReferralLink +
          '&quote=' +
          encodeURIComponent(gBody),
        '',
        'width=500,height=400'
      );

      closeInvitationBanner();
    }

    /**
     * This function uses branch.io ability to share link.
     */
    function share() {
      btBranchService.share(gTitle, gBody);

      closeInvitationBanner();
    }
  }
})();
