/**
 * Created by Itay on 6/26/2016.
 */

(function btHistoricGraphClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive display historical data: chart and table.
     *
     * @ngdoc directive
     * @name btHistoricGraph
     * @memberOf ecapp
     */
    .directive('btHistoricGraph', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/common/historic-graph/historic-graph.html'),
        };
      },
    ]);
})();
