/**
 * Created by Sergey Panpurin on 12/7/2016.
 */

(function btReviewControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btReviewController', btReviewController);

  btReviewController.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    '$stateParams',
    '$state',
    '$q',
    '$analytics',
    'btWindowsSizeService',
    'btCurrenciesService',
    'btRowProcessorService',
    'btInsightsService',
    'btSharedData',
    'btShareSocialService',
    'btSubscriptionService',
    'toastr',
    '$location',
    'btCalendarService',
    'btReleasesService',
    'btBackService',
    'btDevService',
    'btEventsService',
    'btSettingsService',
  ];

  /**
   * This is controller for separate release.
   *
   * List of scope variables:
   *
   *  - $scope.rowModalData
   *  - $scope.showResults
   *  - $scope.openMoreInsights
   *  - $scope.openMoreInstruments
   *  - $scope.isMobile
   *  - $scope.currencyFlags
   *
   * List of scope functions:
   *
   *  - $scope.openModalShareDialog
   *  - $scope.shareRowInfo
   *  - $scope.popOutWindow
   *
   * @ngdoc controller
   * @name btReviewController
   * @memberOf ecapp
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.ITimeoutService} $timeout
   * @param {angular.ui.IStateService} $stateParams
   * @param {angular.ui.IStateService} $state
   * @param {angular.IQService} $q - promise interface
   * @param {ext.IAnalyticsService} $analytics
   * @param {ecapp.IWindowsSizeService} btWindowsSizeService
   * @param {ecapp.ICurrenciesService} btCurrenciesService
   * @param {ecapp.IRowProcessorService} btRowProcessorService
   * @param {ecapp.IInsightsService} btInsightsService
   * @param {ecapp.ISharedData} btSharedData
   * @param {ecapp.IShareSocialService} btShareSocialService
   * @param {ecapp.ISubscriptionService} btSubscriptionService
   * @param {ext.IToastrService} toastr
   * @param {angular.ILocationService} $location
   * @param {ecapp.ICalendarService} btCalendarService
   * @param {ecapp.IReleasesService} btReleasesService
   * @param {ecapp.IBackService} btBackService
   * @param {ecapp.IDevService} btDevService
   * @param {ecapp.IEventsService} btEventsService
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btReviewController(
    $scope,
    $rootScope,
    $timeout,
    $stateParams,
    $state,
    $q,
    $analytics,
    btWindowsSizeService,
    btCurrenciesService,
    btRowProcessorService,
    btInsightsService,
    btSharedData,
    btShareSocialService,
    btSubscriptionService,
    toastr,
    $location,
    btCalendarService,
    btReleasesService,
    btBackService,
    btDevService,
    btEventsService,
    btSettingsService
  ) {
    console.log('Running btReviewController');

    $scope.showResults = false;
    $scope.showInsights = false;
    $scope.subStatus = btSubscriptionService.status;

    $scope.openMoreInsights = false;
    $scope.openMoreInsightsTrading = false;
    $scope.openMoreInstruments = false;

    $scope.isLoading = true;
    $scope.hasError = false;

    $scope.doRefresh = doRefresh;
    $scope.popOutWindow = popOutWindow;
    $scope.shareRowInfo = shareRowInfo;
    $scope.openModalShareDialog = openModalShareDialog;
    $scope.go = goFunc;

    var gReleaseUrl = $state.href($state.current.name, $state.params, { absolute: false });

    activate();

    $scope.$on('$destroy', onDestroy);
    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('$ionicView.enter', onViewEnter);

    /**
     *
     * @return {any}
     */
    function activate() {
      var currentState = 'ecapp.app.main.detail';
      var parentState = 'ecapp.app.main.reviews';
      var backStates = [
        'ecapp.app.main.instrument-page',
        'ecapp.app.main.insights',
        'ecapp.app.main.trade-ideas',
        'ecapp.app.main.barometer',
        'ecapp.app.main.risk-monitor',
      ];

      btBackService.addBackButton($scope, $scope, currentState, parentState, backStates);

      // init currencies flags
      return btCurrenciesService
        .getAllCachedCurrenciesFlags()
        .then(saveFlags)
        .then(getReleasePromise)
        .then(initState)
        .then(function (release) {
          return btReleasesService.getStrengthStatistics(release.eventsInfo.id).then(function (result) {
            $scope.total = result[0];
            $scope.statistics = result[1];
            return release;
          });
        })
        .then(btInsightsService.getInsights)
        .then(processInsights)
        .catch(handleError);
    }

    /**
     *
     * @param {*} path
     */
    function goFunc(path) {
      $location.path(path);
    }

    /**
     *
     */
    function onViewLoaded() {
      $timeout(function () {
        $('#bt-release-page-content').focus();
      }, 1000);
    }

    /**
     *
     */
    function onViewEnter() {
      $timeout(function () {
        $('#bt-release-page-content').focus();
      }, 1000);
    }

    /**
     *
     */
    function onDestroy() {
      console.log('Controller btReviewController was destroyed!');
    }

    /**
     *
     */
    function doRefresh() {
      $scope.showResults = false;
      $scope.showInsights = false;
      $scope.isLoading = true;
      $scope.hasError = false;

      $rootScope.pushRelease = true;

      activate().finally(function () {
        $scope.$broadcast('scroll.refreshComplete');
      });
    }

    /**
     * Test
     *
     * @ngdoc method
     * @name popOutWindow
     * @memberOf ecapp.btReviewController
     * @param {*} windowName -
     */
    function popOutWindow(windowName) {
      btWindowsSizeService.openPopOutWindow(windowName, gReleaseUrl);
    }

    /**
     * Share release info to dashboard
     *
     * @ngdoc method
     * @name shareRowInfo
     * @memberOf ecapp.btReviewController
     * @param {*} row -
     */
    function shareRowInfo(row) {
      $analytics.eventTrack('trigger', { category: 'back-tester', label: 'release-page', eventId: row.eventsInfo.id });

      btSharedData.acquireBackTester();
      btSharedData.runBackTester('release', row, { magnitude: 'all' });

      // noinspection EqualityComparisonWithCoercionJS
      if (null != $rootScope.selectedRow && $rootScope.selectedRow != row) {
        $rootScope.selectedRow.isSelected = false;
      }

      // Select current row and save it to root scope
      row.isSelected = true;
      $rootScope.selectedRow = row;
    }

    /**
     * This function open share dialog
     *
     * @ngdoc method
     * @name openModalShareDialog
     * @memberOf ecapp.btReviewController
     */
    function openModalShareDialog() {
      btShareSocialService.prepareReleaseShareData($scope.rowModalData);
    }

    /**
     *
     * @param {*} calendar
     * @param {*} params
     * @return {any}
     */
    function getRelease(calendar, params) {
      var releaseDesc = btRowProcessorService.releaseIdDataToRequest(params);
      var releases = calendar.cards.filter(function (t) {
        if (t.eventsInfo) {
          return (
            t.eventsInfo.name.toLowerCase() === releaseDesc.name &&
            t.eventsInfo.currency === releaseDesc.currency &&
            Math.floor(t.time / 60) === Math.floor(releaseDesc.time / 60)
          );
        } else {
          return false;
        }
      });
      if (releases && releases.length === 1) {
        return releases[0];
      } else {
        return null;
      }
    }

    /**
     *
     * @return {angular.IPromise<btRelease|null>}
     */
    function getReleasePromise() {
      // load calendar
      if ($rootScope.pushRelease) {
        btDevService.alert('refresh release');
        $rootScope.pushRelease = false;

        return btReleasesService
          .getReleaseByStateParams($stateParams)
          .then(function (promiseValue2) {
            btRowProcessorService.prepareOneRow(promiseValue2);
            return promiseValue2;
          })
          .then(injectEventDescription)
          .then(function (promiseValue) {
            $scope.rowModalData = promiseValue;
            return $scope.rowModalData;
          });
      }

      return btCalendarService
        .getCalendar()
        .then(function (calendar) {
          return getRelease(calendar, $stateParams);
        })
        .then(function (promiseValue) {
          if (promiseValue === null) {
            return btReleasesService.getReleaseByStateParams($stateParams).then(function (promiseValue2) {
              btRowProcessorService.prepareOneRow(promiseValue2);
              return promiseValue2;
            });
          } else {
            return promiseValue;
          }
        })
        .then(injectEventDescription)
        .then(function (promiseValue) {
          $scope.rowModalData = promiseValue;
          return $scope.rowModalData;
        });
    }

    /**
     *
     * @param {*} release
     * @return {any}
     */
    function injectEventDescription(release) {
      return btEventsService.getEventById(release.eventId).then(function (event) {
        release.eventsInfo.description = event.description;

        if (btSettingsService.isLinkDataService()) {
          release.eventsInfo.relatedInstruments = release.eventsInfo.relatedInstruments.filter(function (i) {
            return ['OIL', 'West Texas Oil'].indexOf(i) === -1;
          });
        }

        return release;
      });
    }

    /**
     *
     * @param {*} data
     */
    function saveFlags(data) {
      $scope.currencyFlags = data.allCachedCurrenciesFlags;
    }

    /**
     *
     * @param {*} rowModalData
     * @return {any}
     */
    function initState(rowModalData) {
      $scope.showResults = true;
      $scope.isLoading = false;
      $scope.hasError = false;

      console.log('btReviewController: release id', $scope.rowModalData.id);
      return rowModalData;
    }

    /**
     *
     */
    function processInsights() {
      if ($scope.rowModalData.insightRow && $scope.rowModalData.insightRow.tradingInsights) {
        $scope.rowModalData.insightRow.insightsTrading.forEach(function (t) {
          btInsightsService.prepareTradingInsightsData(t, $scope.rowModalData.time, 'release');
        });
      }
      $scope.showInsights = true;
    }

    /**
     *
     * @param {*} reason
     */
    function handleError(reason) {
      $scope.showResults = false;
      $scope.isLoading = false;
      $scope.hasError = true;
      console.error('btReviewController: error', reason);
    }
  }
})();
