/**
 * Created by yatree on 16/08/16.
 */

(function btAccordionClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive try to implement universal accordion element (still under construction).
     *
     * @ngdoc directive
     * @name btAccordion
     * @memberOf ecapp
     */
    .directive('btAccordion', [
      function () {
        return {
          restrict: 'E',
          transclude: true,
          template:
            '<div ng-click="toggleAccordion()"><div class="icon bt-dash-title bt-uppercase">Show data</div><div class="data" ng-transclude></div></div>',
          scope: false,
          controller: [
            '$scope',
            function ($scope) {
              $scope.isAccordionShown = true;
            },
          ],
          link: function (scope, element) {
            scope.toggleAccordion = function () {
              scope.isAccordionShown = !scope.isAccordionShown;
              if (scope.isAccordionShown) {
                element.find('.data').show();
                element.find('.icon').hide();
              } else {
                element.find('.data').hide();
                element.find('.icon').show();
              }
            };
          },
        };
      },
    ]);
})();
