/**
 * Created by David on 30/1/17.
 */

// @ts-check
(function btShareSocialServiceClosure() {
  'use strict';

  angular
    .module('btUtils')
    /**
     * @ngdoc service
     * @name btShortenerUrlService
     * @memberOf btUtils
     * @description
     *  This factory transform long Url Links To goo.gl shorts links using google shortener link API
     */
    .factory('btShortenerUrlService', btShortenerUrlService);

  btShortenerUrlService.$inject = ['btSettings', '$http', '$q'];

  /**
   *
   * @param {ecapp.ISettings} btSettings - ?
   * @param {angular.IHttpService} $http - ?
   * @param {angular.IQService} $q - promise interface
   * @return {ecapp.IShortenerUrlService}
   */
  function btShortenerUrlService(btSettings, $http, $q) {
    var googleShortenerUrl = btSettings.BT_SHORTENER_URL;
    var apiKey = btSettings.BT_SHORTENER_TOKEN;

    /**
     *
     * @param {*} url - ?
     * @return {*}
     */
    function shrinkUrl(url) {
      var defer = $q.defer();
      $http
        .post(
          googleShortenerUrl,
          { longUrl: url },
          {
            headers: { 'Content-Type': 'application/json' },
            params: { key: apiKey },
          }
        )
        .then(onSuccess, onReject);

      /**
       *
       * @param {*} res - ?
       */
      function onSuccess(res) {
        defer.resolve(res.data.id);
      }

      /**
       *
       * @param {*} error - ?
       */
      function onReject(error) {
        console.log(error);
        defer.resolve(url);
      }

      return defer.promise;
    }

    return {
      shrinkUrl: shrinkUrl,
    };
  }
})();
