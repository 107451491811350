/**
 * Created by BTsergey on 7/10/2017.
 */

// @ts-check
(function btBrokerRequestClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * @ngdoc directive
     * @name btBrokerRequest
     * @memberOf ecapp
     * @description
     *  This directive display broker request button or form.
     */
    .directive('btBrokerRequest', btBrokerRequest)
    .controller('btBrokerRequestController', btBrokerRequestController);

  btBrokerRequest.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btBrokerRequest($templateCache) {
    return {
      restrict: 'E',
      replace: false,
      scope: {
        broker: '=',
      },
      template: $templateCache.get('bt-brokerage/directives/bt-broker-request.html'),
      controller: 'btBrokerRequestController',
      link: {
        post: btBrokerRequestLinkPost,
      },
    };
  }

  btBrokerRequestController.$inject = [
    '$q',
    '$rootScope',
    '$scope',
    'btShareScopeService',
    '$analytics',
    'btToastrService',
    '$http',
    'btErrorService',
    'btToolsService',
    'btWaitingService',
    'btRestrictionService',
  ];

  /**
   *
   * @param {angular.IQService} $q - promise interface
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ext.IAnalyticsService} $analytics
   * @param {ecapp.IToastrService} btToastrService
   * @param {angular.IHttpService} $http
   * @param {ecapp.IErrorService} btErrorService
   * @param {ecapp.IToolsService} btToolsService
   * @param {ecapp.IWaitingService} btWaitingService
   * @param {ecapp.IRestrictionService} btRestrictionService
   */
  function btBrokerRequestController(
    $q,
    $rootScope,
    $scope,
    btShareScopeService,
    $analytics,
    btToastrService,
    $http,
    btErrorService,
    btToolsService,
    btWaitingService,
    btRestrictionService
  ) {
    $scope.newBroker = {
      name: '',
      email: '',
      phone: '',
      market: '',
      country: '',
      note: '',
    };

    // btShareScopeService.wait()
    //   .then(function () {
    //     if (btShareScopeService.accountInfo.telephone) {
    //       $scope.newBroker.phone = btShareScopeService.accountInfo.telephone;
    //     }
    //   });

    $scope.openRequestBroker = openRequestBroker;
    $scope.closeRequestBroker = closeRequestBroker;
    $scope.sendAnotherBroker = sendAnotherBroker;

    $scope.$watch('broker.requested', onShow);

    /**
     *
     * @param {*} newValue
     * @param {*} oldValue
     */
    function onShow(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        btWaitingService.retry(5, 500, function () {
          var phone = $('#phone2');
          if (phone.length > 0) {
            // noinspection JSUnresolvedFunction
            phone.intlTelInput();
            return true;
          } else {
            return false;
          }
        });
      }
    }

    /**
     * Just open request broker form
     */
    function openRequestBroker() {
      $scope.broker.requested = true;
      if (window.btSettings.BT_DOMAIN === 'bettertrader') {
        $analytics.eventTrack('request', { category: 'brokerage', label: 'unknown' });
      }
    }

    /**
     * Just close request broker form
     */
    function closeRequestBroker() {
      $scope.broker.requested = false;
    }

    /**
     * Send new broker request to server
     *
     * @param {Object} form - angular form controller
     */
    function sendAnotherBroker(form) {
      if (form.$valid) {
        var url = window.btSettings.BT_BACKEND_URL;

        btToastrService.info('Request in process!', 'New broker', { type: 'trade' });

        var phone = btToolsService.preparePhoneNumber('phone2', $scope.newBroker.phone);

        var data = {
          params: {
            brokerName: $scope.newBroker.name,
            brokerEmail: $scope.newBroker.email || 'n/a',
            userEmail: $rootScope.currentUser.email,
            userPhone: phone.full,
            userPhoneCountry: phone.country,
            userResidenceCountry: $scope.newBroker.country || 'n/a',
            userMarket: $scope.newBroker.market,
            userNote: $scope.newBroker.note,
          },
        };

        if (btRestrictionService.hasFeature('personalization')) {
          btShareScopeService.setMailChimpField('TELEPHONE', phone.full).catch(btErrorService.skipError);
          btShareScopeService.setMailChimpField('COUNTRY', phone.country).catch(btErrorService.skipError);
        }

        $http
          .get(url + '/broker/request', data)
          .then(function (response) {
            if (response.status === 200 || response.status === 201) {
              $scope.newBroker.name = '';
              $scope.newBroker.email = '';
              form.$setPristine();
              form.$setUntouched();
              btToastrService.success('Soon you will get a confirmation email', 'New Broker', { type: 'trade' });
            } else {
              return $q.reject(response);
            }
          })
          .catch(btErrorService.handleHTTPError)
          .catch(function (error) {
            console.error(error);
            btToastrService.error('Error ' + error.message, 'New broker', { type: 'trade' });
          });
      }
    }
  }

  /**
   *
   */
  function btBrokerRequestLinkPost() {
    // noinspection JSUnresolvedFunction
    $('#phone2').intlTelInput();
  }
})();
