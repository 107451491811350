/**
 * Created by Sergei Panpurin on 9/5/18.
 */

(function btTradesTableClosure() {
  'use strict';

  /**
   * This directive displays list of trade ideas. User can select one of them.
   *
   * @ngdoc directive
   * @name btTradesTable
   * @memberOf ecapp
   */

  angular
    .module('ecapp')
    .directive('btTradesTable', btTradesTable)
    .controller('btTradesTableController', btTradesTableController);

  btTradesTable.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btTradesTable($templateCache) {
    return {
      restrict: 'E',
      scope: {
        widgetId: '@',
        tradeInstrument: '<',
        tradeIdea: '<',
        charts: '<',
        trades: '<',
        events: '<',
        history: '<',
      },
      template: $templateCache.get('components/mobile/bt-back-tester/bt-trades-table.html'),
      controller: 'btTradesTableController',
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btTradesTableController.$inject = [
    '$scope',
    '$state',
    '$timeout',
    '$ionicPopup',
    'btMarketing',
    'btChartService',
    'btTradingSessionsService',
    'btDateService',
  ];

  /**
   *
   * @param {*} $scope
   * @param {angular.ui.IStateService} $state
   * @param {angular.ITimeoutService} $timeout
   * @param {ionic.IPopupService} $ionicPopup
   * @param {ecapp.IMarketing} btMarketing
   * @param {ecapp.IChartService} btChartService
   * @param {ecapp.ITradingSessionsService} btTradingSessionsService
   * @param {ecapp.IDateService} btDateService
   */
  function btTradesTableController(
    $scope,
    $state,
    $timeout,
    $ionicPopup,
    btMarketing,
    btChartService,
    btTradingSessionsService,
    btDateService
  ) {
    void $scope;

    /*jshint validthis: true*/
    var vm = this;

    vm.sideOptions = [
      { id: 'any', label: 'Any side' },
      { id: 'buy', label: 'Long' },
      { id: 'sell', label: 'Short' },
    ];

    vm.side = vm.sideOptions[0];

    var gOptions = [
      { id: 'all', label: 'All Trades' },
      { id: 'win-loss', label: 'Win & Loss' },
      { id: 'win', label: 'Win Only' },
      { id: 'loss', label: 'Loss Only' },
      { id: 'weak', label: 'Weak Only' },
    ];

    var gStatusSideOptions = {
      any: [gOptions[0]],
      buy: [gOptions[0], gOptions[1], gOptions[2], gOptions[3], gOptions[4]],
      sell: [gOptions[0], gOptions[1], gOptions[2], gOptions[3], gOptions[4]],
    };

    vm.statusOptions = gStatusSideOptions.any;

    vm.status = vm.statusOptions[0];

    vm.tradeHourlyStatistics = [];

    vm.hasResults = true;
    vm.hasVisibleResults = true;

    vm.isBuyVisible = true;
    vm.isSellVisible = true;

    vm.$onInit = onInit;
    vm.$onChanges = onChanges;
    vm.$onDestroy = onDestroy;

    vm.onChangeStatus = onChangeStatus;
    vm.onChangeSide = onChangeSide;
    vm.passFilter = passFilter;
    vm.isChartCollapsed = isChartCollapsed;
    vm.toggleChart = toggleChart;
    vm.clearFilter = clearFilter;
    vm.openHelpChat = openHelpChat;
    vm.openHistory = openHistory;
    vm.openRelease = openRelease;

    /**
     *
     * @param {angular.IAngularEvent} $event
     * @param {*} item
     */
    function openRelease($event, item) {
      $state.go('ecapp.app.main.detail', item.event.stateParams);
      $event.preventDefault();
      $event.stopPropagation();
    }

    /**
     * Initialize component
     */
    function onInit() {
      vm.sessions = btTradingSessionsService.getSessions();
      vm.items = vm.trades.map(prepareItem);
      vm.hasResults = vm.items.length > 0;
      onTradeIdeaChange(vm.tradeIdea);
    }

    /**
     *
     * @param {*} changes
     */
    function onChanges(changes) {
      if (changes.tradeInstrument || changes.charts || changes.trades) {
        vm.items = vm.trades.map(prepareItem);
        vm.hasResults = vm.items.length > 0;
      }

      if (changes.tradeIdea) {
        onTradeIdeaChange(changes.tradeIdea.currentValue);
      }
    }

    /**
     * Prepare table line
     *
     * @param {object} trade
     * @param {number} i
     * @return {{id: string, i: number, collapsed: boolean, trade: object, hasTrade: boolean, chart: (zcChartJSON), hasChart: boolean, event: (btRawEvent|btBackEvent), hasEvent: boolean, date: Date, suggestion: string, visible: boolean, sessions: {name: string, class: string, style: {backgroundColor: string}}[]}}
     */
    function prepareItem(trade, i) {
      var date = new Date(vm.events[i].time * 1000);
      var previousTradeIdea = getPreviousTradeIdea(vm.history, date);

      return {
        id: 'bt-test-chart-x-' + vm.widgetId + '-' + i + 1,
        i: i + 1,
        collapsed: true,
        trade: trade,
        hasTrade: !!(trade && trade.buy),
        chart: vm.charts[i],
        hasChart: !!(vm.charts[i] && vm.charts[i].series),
        event: vm.events[i],
        hasEvent: !!(vm.events[i] && vm.events[i].time),
        hasMagnitude: !!(vm.events[i] && vm.events[i].actual),
        date: date,
        formattedDate: btDateService.format(date, 'DD/MM/YY HH:mm (ddd)'),
        history: previousTradeIdea,
        suggestion: previousTradeIdea ? previousTradeIdea.data.action : 'none',
        visible: passFilter(trade),
        sessions: vm.sessions.map(function (value, i) {
          return {
            name: vm.sessions[i].id,
            class: 'bt-session-' + value.checkDate(date),
            style: { backgroundColor: vm.sessions[i].color },
          };
        }),
      };
    }

    /**
     *
     * @param {*} history
     * @param {*} date
     * @return {any}
     */
    function getPreviousTradeIdea(history, date) {
      var tradeIdeas = history.filter(function (tradeIdea) {
        return tradeIdea.date - date === 0;
      });

      if (tradeIdeas.length > 1 && window.isDevMode) {
        alert('Too many previous trade ideas!');
      }

      return tradeIdeas[0];
    }

    /**
     *
     * @param {*} tradeIdea
     */
    function onTradeIdeaChange(tradeIdea) {
      if (tradeIdea) {
        if (tradeIdea.data.action === 'uptrend') {
          vm.side = vm.sideOptions[1];
          vm.statusOptions = gStatusSideOptions.buy;
          vm.status = vm.statusOptions[1];
        }

        if (tradeIdea.data.action === 'downtrend') {
          vm.side = vm.sideOptions[2];
          vm.statusOptions = gStatusSideOptions.sell;
          vm.status = vm.statusOptions[1];
        }
      } else {
        vm.side = vm.sideOptions[0];
        vm.statusOptions = gStatusSideOptions.any;
        vm.status = vm.statusOptions[0];
      }

      onChangeStatus();
    }

    /**
     * Destroy component
     */
    function onDestroy() {}

    /**
     *
     */
    function onChangeStatus() {
      updateTable();
      updateColumns();
      updateHourlyStatistics();
    }

    /**
     *
     */
    function updateHourlyStatistics() {
      vm.tradeHourlyStatistics = [];

      if (vm.side.id === 'buy' || vm.side.id === 'sell') {
        vm.tradeHourlyStatistics = generateHourlyStatistics();
      }
    }

    /**
     *
     * @return {any}
     */
    function generateHourlyStatistics() {
      var stats = [];
      for (var i = 0; i < 24; i++) {
        stats.push(null);
      }

      var results = vm.items.filter(function (item) {
        return item.visible;
      });

      vm.items.forEach(function (item) {
        if (item.visible) {
          // item.trade.buy.status
          var hour = btDateService.getUserHours(item.date);
          if (stats[hour] === null) {
            stats[hour] = { win: 0, loss: 0, weak: 0, total: results.length };
          }

          if (vm.side.id === 'buy' && item.trade.buy) {
            stats[hour][item.trade.buy.status]++;
          }

          if (vm.side.id === 'sell' && item.trade.sell) {
            stats[hour][item.trade.sell.status]++;
          }
        }
      });

      return stats;
    }

    /**
     *
     */
    function onChangeSide() {
      vm.statusOptions = gStatusSideOptions[vm.side.id] || [];

      if (vm.side.id === 'any' && vm.status.id !== 'all') {
        vm.status = vm.statusOptions[0];
      }

      onChangeStatus();
    }

    /**
     *
     */
    function updateColumns() {
      vm.isBuyVisible = true;
      vm.isSellVisible = true;

      if (vm.side.id === 'buy') {
        vm.isBuyVisible = true;
        vm.isSellVisible = false;
      }

      if (vm.side.id === 'sell') {
        vm.isBuyVisible = false;
        vm.isSellVisible = true;
      }

      $timeout(
        function () {
          vm.items.forEach(function (item) {
            if (item.visible) {
              btChartService.updateTradeMarkers(item.id, item.chart, vm.side.id, false);
            }
          });
        },
        1000,
        false
      );
    }

    /**
     *
     */
    function updateTable() {
      vm.hasVisibleResults = vm.hasResults;
      vm.items.forEach(function (item) {
        item.visible = passFilter(item.trade);
      });

      $timeout(function () {
        vm.hasVisibleResults = vm.items.filter(isVisible).length > 0;
      });
    }

    /**
     *
     * @param {*} item
     * @return {boolean}
     */
    function isChartCollapsed(item) {
      return item.collapsed;
    }

    /**
     *
     * @param {*} item
     */
    function toggleChart(item) {
      item.collapsed = !item.collapsed;
    }

    /**
     *
     * @param {*} trade
     * @return {boolean}
     */
    function passFilter(trade) {
      if (!(vm.side && vm.status && trade)) {
        return false;
      }

      if (vm.side.id === 'any' || vm.status.id === 'all') {
        return true;
      } else if (vm.side.id === 'buy') {
        if (trade.buy === undefined) {
          return false;
        }

        if (vm.status.id === 'win-loss') {
          return trade.buy.status === 'win' || trade.buy.status === 'loss';
        }

        if (vm.status.id === 'win') {
          return trade.buy.status === 'win';
        }

        if (vm.status.id === 'loss') {
          return trade.buy.status === 'loss';
        }

        if (vm.status.id === 'weak') {
          return trade.buy.status === 'weak';
        }
      } else if (vm.side.id === 'sell') {
        if (trade.sell === undefined) {
          return false;
        }

        if (vm.status.id === 'win-loss') {
          return trade.sell.status === 'win' || trade.sell.status === 'loss';
        }

        if (vm.status.id === 'win') {
          return trade.sell.status === 'win';
        }

        if (vm.status.id === 'loss') {
          return trade.sell.status === 'loss';
        }

        if (vm.status.id === 'weak') {
          return trade.sell.status === 'weak';
        }
      } else {
        return false;
      }
    }

    /**
     *
     * @param {*} item
     * @return {any}
     */
    function isVisible(item) {
      return item.visible;
    }

    /**
     *
     */
    function clearFilter() {
      vm.side = vm.sideOptions[0];
      vm.statusOptions = gStatusSideOptions.any;
      vm.status = vm.statusOptions[0];

      onChangeStatus();
    }

    /**
     *
     */
    function openHelpChat() {
      btMarketing.askQuestion('I have a question regarding the BackTester:');
    }

    /**
     *
     * @param {angular.IAngularEvent} $event
     * @param {*} item
     * @return {any}
     */
    function openHistory($event, item) {
      $scope.insight = null;
      if (item && item.history) {
        $event.stopPropagation();
        $scope.insight = item.history.convertedInsight;
        var popupHandler = $ionicPopup.show({
          template:
            '<h4>{{data.market}}</h4><bt-trading-card-a insight="insight" show-enter-price="false"></bt-trading-card-a>',
          cssClass: 'popup-trade-card',
          title: 'Trade card',
          // subTitle: 'Choose a way to share content',
          scope: $scope,
          buttons: [
            {
              text: 'Close',
              type: 'button-close',
              onTap: function () {
                popupHandler.close();
              },
            },
          ],
        });
      }

      return false;
    }
  }
})();
