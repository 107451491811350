/**
 * Created by David on 9/20/2016.
 */

(function MarketsControllerClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = "btMarketsController:";

  angular.module('ecapp').controller('btMarketsController', btMarketsController);

  btMarketsController.$inject = [
    '$scope',
    '$timeout',
    '$ionicHistory',
    '$analytics',
    'btShareScopeService',
    'btTradingService',
    'btWatchListService',
    'btToastrService',
    'btTradeIdeasFiltersService',
    'btInstrumentsService',
    'btAdvertisingService',
  ];

  /**
   * This is the markets tab controller.
   *
   * @ngdoc controller
   * @name btMarketsController
   * @memberOf ecapp
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {angular.ITimeoutService} $timeout
   * @param {ionic.IHistoryService} $ionicHistory - ionic history service
   * @param {ext.IAnalyticsService} $analytics
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.IWatchListService} btWatchListService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.ITradeIdeasFiltersService} btTradeIdeasFiltersService
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.IAdvertisingService} btAdvertisingService
   */
  function btMarketsController(
    $scope,
    $timeout,
    $ionicHistory,
    $analytics,
    btShareScopeService,
    btTradingService,
    btWatchListService,
    btToastrService,
    btTradeIdeasFiltersService,
    btInstrumentsService,
    btAdvertisingService
  ) {
    console.log('Running btMarketsController');

    $scope.addWatchedInstrument = addWatchedInstrument;
    $scope.switchPage = switchPage;
    $scope.showLoader = showLoader;
    $scope.openBalance = openBalance;
    $scope.doRefresh = onRefresh;

    $scope.$on('$destroy', onDestroy);
    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('$ionicView.enter', onViewEnter);
    $scope.$on('$ionicView.leave', onViewLeave);
    
    $scope.$on('broker:connected', onBrokerConnect);
    $scope.$on('broker:disconnected', onBrokerDisconnect);

    activate();

    /**
     *
     * @return {angular.IPromise<void>}
     */
    function activate() {
      $scope.hasAdvertising = btAdvertisingService.checkHasAdvertising();
      $scope.balance = { shown: false };
      $scope.watchlist = btWatchListService.getWatchListObject();
      $scope.pages = btWatchListService.getPages();
      $scope.brokerObject = btTradingService.getBrokerObject();

      return btShareScopeService
        .wait()
        .then(btTradingService.initialize)
        .then(btTradingService.connect)
        .then(function () {
          btWatchListService.switchPage('WatchList');

          $scope.pageName = btWatchListService.getSelectedPageName();

          $scope.brokerObject = btTradingService.getBrokerObject();
          $scope.restrictions = btWatchListService.getWatchlistRestrictions();
        });
    }

    /**
     *
     */
    function onDestroy() {
      btWatchListService.stopAutoRefreshing();
    }

    /**
     *
     */
    function onViewLoaded() {
      onViewOpen();

      $ionicHistory.nextViewOptions({
        disableAnimate: true,
        disableBack: true,
      });
    }

    /**
     *
     */
    function onViewEnter() {
      onViewOpen();

      btWatchListService.resumeAutoRefreshing();

      $ionicHistory.clearHistory();
      $ionicHistory.nextViewOptions({
        disableAnimate: true,
        disableBack: true,
      });
    }

    /**
     *
     */
    function onViewOpen() {
      $timeout(function () {
        $('#bt-markets-content').focus();
      }, 1000);
    }

    /**
     *
     */
    function onViewLeave() {
      btWatchListService.pauseAutoRefreshing();
      if (gDebug) console.log(gPrefix, 'view left');
    }

    /**
     * 
     */
    function onBrokerConnect() {
      if (gDebug) console.log(gPrefix, 'broker disconnected');
    }

    /**
     *
     */
    function onBrokerDisconnect() {
      if (gDebug) console.log(gPrefix, 'broker disconnected');
    }

    /**
     * @deprecated
     * @see scopes.InstrumentSearch
     * @param {ecapp.ITradingInstrument} instrument - instrument
     * @return {angular.IPromise<void>}
     */
    function addWatchedInstrument(instrument) {
      // var toast = btToastrService.info('Saving...', instrument.displayName, {
      //   type: 'market',
      //   hidden: true
      // });

      // var instrument = btInstrumentsService.getInstrumentByField('brokerSymbol', instrumentName);
      // var instrument = btTradingService.getInstrumentByBrokerSymbol(instrumentName);

      return btWatchListService.addInstrumentByName(instrument.brokerSymbol).then(function () {
        // btToastrService.remove(toast);
        // btToastrService.info('Has been added to your Watchlist.', instrument.displayName, {type: 'market'});
        btTradeIdeasFiltersService.updateInstruments(instrument, true);
      });
      // .catch(function (reason) {
      // btToastrService.remove(toast);
      // btToastrService.error(reason.message || 'Unknown error!', instrument.displayName, {type: 'market'});
      // });
    }

    /**
     *
     * @param {string} pageName - page name
     */
    function switchPage(pageName) {
      btWatchListService.switchPage(pageName);
      $scope.pageName = btWatchListService.getSelectedPageName();
      $timeout(function () {
        $('#bt-markets-content').focus();
      }, 1000);
    }

    /**
     *
     * @return {boolean}
     */
    function showLoader() {
      // noinspection UnnecessaryLocalVariableJS
      var result =
        ($scope.watchlist.isLoading.watchlist && $scope.pageName !== 'Positions') ||
        ($scope.brokerObject.isTradeable && $scope.watchlist.isLoading.positions && $scope.pageName === 'Positions');

      return result;
    }

    /**
     *
     */
    function openBalance() {
      $scope.balance.shown = true;
    }

    /**
     * Refresh data
     */
    function onRefresh() {
      if (gDebug) console.log(gPrefix, 'page refreshed');
      btWatchListService.refreshPage().finally(function () {
        $scope.$broadcast('scroll.refreshComplete');
      });
    }
  }
})();
