/**
 * Created by Sergey Panpurin on 8/2/2018.
 */

// @ts-check
(function btToolsServiceClosure() {
  'use strict';

  angular
    .module('btUtils')
    /**
     * @ngdoc service
     * @name btToolsService
     * @memberOf btUtils
     * @description
     *  This wrapper for eventEmitter
     */
    .factory('btToolsService', btToolsService);

  btToolsService.$inject = ['$location'];

  /**
   *
   * @param {angular.ILocationService} $location - ?
   * @return {ecapp.IToolsService}
   */
  function btToolsService($location) {
    var gDebugSettings = (localStorage.getItem('btDebugSettings') || '').split(',');

    /* cspell:disable-next-line */
    var UUID_TEMPLATE = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

    return {
      isDebug: isDebug,
      generateUUID: generateUUID,
      isURLBroken: isURLBroken,
      fixBrokenURL: fixBrokenURL,
      preparePhoneNumber: preparePhoneNumber,
      convertListToDict: convertListToDict,
      clearList: clearList,
      updateList: updateList,
      clearDict: clearDict,
      updateDict: updateDict,
      getUTMTags: getUTMTags,
    };

    /**
     * Returns UTM tags.
     *
     * @return {object} UTM tags
     */
    function getUTMTags() {
      var tags = {};
      var search = window.sessionStorage.getItem('btStoredLocationSearch');
      if (search) {
        var params = new URLSearchParams(search);
        ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source', 'utm_term'].forEach(function (key) {
          if (params.has(key)) tags[key] = params.get(key);
        });
      }
      return tags;
    }

    /**
     * This function checks if the feature should be debugged.
     *
     * @param {string} feature - name of the feature
     * @return {boolean} - whether the feature should be debugged
     */
    function isDebug(feature) {
      return gDebugSettings.indexOf(feature) !== -1;
    }

    /**
     * This function generates UUID.
     *
     * @return {string} - universally unique identifier version 4
     */
    function generateUUID() {
      return UUID_TEMPLATE.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }

    /**
     * Check is URL broken or not.
     *
     * @alias btUtils.btToolsService#isURLBroken
     * @return {boolean}
     */
    function isURLBroken() {
      return $location.absUrl().indexOf('?') !== -1 && Object.keys($location.search()).length === 0;
    }

    /**
     * Fix broken URL and restart application.
     *
     * @alias btUtils.btToolsService#fixBrokenURL
     */
    function fixBrokenURL() {
      var parts = $location.absUrl().split('?');
      var newUrl = parts[0];
      parts = parts[1].split('#');
      newUrl += '#' + parts[1] + '?' + parts[0];

      window.sessionStorage.setItem('btStoredLocationSearch', parts[0]);

      window.open(newUrl, '_self');
    }

    /**
     *
     * @alias btUtils.btToolsService#preparePhoneNumber
     * @param {string} id - identifier
     * @param {string} phone - phone number
     * @return {{country: string, short: string, full: string}}
     */
    function preparePhoneNumber(id, phone) {
      var phoneInput = $('#' + id);
      var dialCode = phoneInput.intlTelInput('getSelectedCountryData').dialCode || '000';

      return {
        short: phone,
        full: '(+' + dialCode + ') ' + phone,
        country: phoneInput.intlTelInput('getSelectedCountryData').iso2 || 'n/a',
      };
    }

    /**
     * This function convert array to object.
     *
     * @alias ecapp.btToolsService#convertListToDict
     * @param {Array} array - array
     * @return {object} - object
     */
    function convertListToDict(array) {
      return array.reduce(function (obj, item) {
        obj[item.id] = item;
        return obj;
      }, {});
    }

    /**
     * This function clear list.
     *
     * @alias ecapp.btToolsService#clearList
     * @param {Array} list - list of elements
     */
    function clearList(list) {
      var n = list.length;
      for (var i = 0; i < n; i++) {
        list.pop();
      }
    }

    /**
     * This function updates list.
     *
     * @alias ecapp.btToolsService#clearList
     * @param {Array} dest - destination array
     * @param {Array} src - source array
     */
    function updateList(dest, src) {
      src.forEach(function (value) {
        dest.push(value);
      });
    }

    /**
     * This function clear list.
     *
     * @alias ecapp.btToolsService#clearDict
     * @param {object} dict - dict of elements
     */
    function clearDict(dict) {
      Object.keys(dict).forEach(function (key) {
        delete dict[key];
      });
    }

    /**
     * This function updates object.
     *
     * @alias ecapp.btToolsService#updateDict
     * @param {object} dest - destination object
     * @param {object} src - source object
     */
    function updateDict(dest, src) {
      Object.keys(src).forEach(function (key) {
        dest[key] = src[key];
      });
    }
  }
})();
