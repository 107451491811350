/**
 * Created by Sergey Panpurin on 12/25/19.
 */

(function btSettingsPanelClosure() {
  'use strict';

  var gDebug = false;

  /**
   * This directive displays settings panel.
   *
   * @ngdoc directive
   * @name btSettingsPanel
   * @memberOf ecapp
   * @restrict E
   * @scope
   * @param {btSettingsObject} settings - the settings panel configuration object
   * @param {string} onChange - the function that will be called on unit change "onChange(block, section, unit)"
   * @param {string} onReset - the function that will be called on restoring default settings "onReset()"
   * @param {string} onDiscard - the function that will be called on discarding changes "onDiscard()"
   */

  /**
   * This controller process notification center.
   *
   * @ngdoc controller
   * @name btSettingsPanelController
   * @memberOf ecapp
   */

  /** Directive */
  angular
    .module('ecapp')
    .directive('btSettingsPanel', angularDirective)
    .controller('btSettingsPanelController', angularController);

  angularDirective.$inject = ['$templateCache'];

  /**
   * Implementation of Notification Center Directive
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function angularDirective($templateCache) {
    return {
      restrict: 'E',
      scope: {
        settings: '=',
        onChange: '&',
        onDiscard: '&',
        onReset: '&',
      },
      template: $templateCache.get('directives/common/bt-settings-panel.html'),
      controller: 'btSettingsPanelController',
    };
  }

  angularController.$inject = ['$scope', '$timeout'];

  /**
   * Implementation of Notification Center Controller
   *
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.ITimeoutService} $timeout
   */
  function angularController($scope, $timeout) {
    $scope.resetSettings = resetSettings;
    $scope.discardChanges = discardChanges;
    $scope.isFilterEnabled = isFilterEnabled;
    $scope.getOptionDetails = getOptionDetails;
    $scope.showHideAdvanced = showHideAdvanced;
    $scope.onUnitChange = onUnitChange;

    // The rest of the activation
    $scope.$on('$destroy', onDestroy);

    activate();

    /**
     * Controller constructor
     */
    function activate() {
      $scope.hasResetFunction = typeof $scope.onReset === 'function';
      $scope.hasDiscardFunction = typeof $scope.onDiscard === 'function';
    }

    /**
     * On destroy
     */
    function onDestroy() {}

    /**
     * This function reset settings panel to default settings.
     */
    function resetSettings() {
      if (typeof $scope.onReset === 'function') {
        $scope.onReset();
      } else {
        if (gDebug) console.log('Function "onReset" is not defined.');
      }
    }

    /**
     * This function discards changes.
     */
    function discardChanges() {
      if (typeof $scope.onDiscard === 'function') {
        $scope.onDiscard();
      } else {
        if (gDebug) console.log('Function "onDiscard" is not defined.');
      }
    }

    /**
     * This function reacts on unit change. It is implemented just for limited list of units.
     *
     * @param {btSettingsBlock} block - the settings block object
     * @param {SectionObject} section - the settings section object
     * @param {MaxFilterObject} unit - the settings unit object
     */
    function onUnitChange(block, section, unit) {
      if (typeof $scope.onChange === 'function') {
        $scope.onChange({ block: block, section: section, unit: unit });
      } else {
        if (gDebug) console.log('Function "onChange" is not defined.');
      }
    }

    /**
     * This function checks if filter is enabled.
     *
     * @param {*} filter
     * @return {*}
     */
    function isFilterEnabled(filter) {
      return filter.params.enabled;
    }

    /**
     *
     * @param {*} unit
     * @return {string}
     */
    function getOptionDetails(unit) {
      return (
        unit.options
          .filter(function (option) {
            return option.value === unit.value;
          })
          .map(function (option) {
            return option.details;
          })[0] || 'Unknown'
      );
    }

    /**
     *
     * @param {*} event
     * @param {*} section
     */
    function showHideAdvanced(event, section) {
      section.visible = !section.visible;

      // scroll to element
      if (section.visible) {
        $timeout(function () {
          if (event.target.parentNode.scrollIntoView) {
            event.target.parentNode.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      }
    }
  }
})();
