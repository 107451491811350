/**
 * Created by Orly on 29/01/2019.
 */

(function btSearchCompleteClosure() {
  'use strict';

  /**
   * This directive delays a function call to give the user time to to complete typing.
   *
   * This directive adds listener to input event. Listener compares value of element now and some moment before.
   * As the user enters the text, the value will change. As the user finishes, the value will remain the same.
   *
   * Note: Calling function should have protection again same input.
   *
   * @ngdoc directive
   * @name btSearchComplete
   * @memberOf ecapp
   * @scope
   * @restrict A
   * @param {ecapp.ISearchComplete} btSearchComplete - (function)
   */

  angular.module('ecapp').directive('btSearchComplete', [
    '$timeout',
    function ($timeout) {
      return {
        restrict: 'A',
        scope: {
          btSearchComplete: '&',
        },
        link: function (scope, element, attrs) {
          var gDebug = false;
          var dDelay = 500;

          if (gDebug) console.log('btSearchComplete: search was linked.');
          if (gDebug) console.log('btSearchComplete: element:', element);
          if (gDebug) console.log('btSearchComplete: attributes:', attrs);

          element.on('input', onSearchListener);

          /**
           * This function sets 500ms delay for onSearchDelay function
           * @param {*} event
           */
          function onSearchListener(event) {
            if (gDebug) console.log('btSearchComplete: new search action "' + previous + '"', event);
            var previous = null;

            previous = element.val();

            if (element.val() === '') {
              if (gDebug) console.log('btSearchComplete: empty search');
              scope.btSearchComplete({ text: element.val() });
            } else {
              $timeout(onSearchDelay, dDelay, false);
            }

            /**
             * This function calls callback function if input's value has been changed and it differs from previous
             * value of function's parameter
             */
            function onSearchDelay() {
              if (gDebug) console.log('btSearchComplete: after delay "' + element.val() + '" vs "' + previous + '"');

              if (element.val() === previous) {
                // Value has not been changed in 500ms
                if (gDebug) console.log('btSearchComplete: calling search complete function');
                scope.btSearchComplete({ text: element.val() });
              } else {
                // Value has been changed in 500ms
                if (gDebug) console.log('btSearchComplete: search was changed, wait a bit more');
              }
            }
          }
        },
      };
    },
  ]);
})();
