/**
 * Created by Sergey Panpurin on 5/17/2021.
 */

(function btResizeObserverClosure() {
  'use strict';

  var gDebug = false;

  angular
    .module('ecapp')
    /**
     * This directive add class to element based on breakpoints provided via `bt-resize-breakpoints`.
     * https://philipwalton.com/articles/responsive-components-a-solution-to-the-container-queries-problem/
     *
     * @ngdoc directive
     * @name btResizeObserver
     * @memberOf ecapp
     * @example
     * <div bt-resize-observer bt-resize-breakpoints='{"small":0,"medium":360,"large":720}'></div>
     */
    .directive('btResizeObserver', [
      function () {
        return {
          restrict: 'A',
          link: function ($scope, element, attrs) {
            var id = Math.random();
            if (gDebug) console.log('>>>>>>', id, 'initialize');

            var breakpoints = {};
            try {
              breakpoints = JSON.parse(attrs.btResizeBreakpoints);
            } catch (e) {
              console.error(e);
            }

            setTimeout(selectBreakpoint, 0);

            var resizer = ionic.debounce(selectBreakpoint, 300, false);

            window.addEventListener('resize', resizer);

            $scope.$on('$destroy', function () {
              if (gDebug) console.log('>>>>>>', id, 'destroy');
              window.removeEventListener('resize', resizer);
            });

            /**
             *
             */
            function selectBreakpoint() {
              if (gDebug) console.log('>>>>>>', id, 'select breakpoint');

              var width = element.width();
              var keys = Object.keys(breakpoints);
              if (gDebug) console.log('>>>>>>', id, 'width', width);

              // Update the matching breakpoints on the observed element.
              keys.forEach(function (key, i) {
                var current = breakpoints[key];
                var next = i + 1 < keys.length ? breakpoints[keys[i + 1]] : 100000;
                if (width >= current && width < next) {
                  if (gDebug) console.log('>>>>>>', id, 'add', key);
                  element.addClass(key);
                } else {
                  element.removeClass(key);
                }
              });
            }
          },
        };
      },
    ]);
})();
