// @ts-check
(function btDeviceServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btDeviceService', btDeviceService);

  btDeviceService.$inject = [
    '$rootScope',
    '$ionicPopup',
    'btBranchService',
    'btShareScopeService',
    'btSettingsService',
  ];

  /**
   * @ngdoc service
   * @name btDeviceService
   * @memberOf ecapp
   * @description
   * Show notification about desktop and mobile versions.
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ionic.IPopupService} $ionicPopup
   * @param {ecapp.IBranchService} btBranchService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.ISettingsService} btSettingsService
   * @return {ecapp.IDeviceService}
   */
  function btDeviceService($rootScope, $ionicPopup, btBranchService, btShareScopeService, btSettingsService) {
    console.log('Running btDeviceService');

    /**
     * Show "Start to use desktop version" notification
     */
    function sendGetDesktopAlert() {
      var template;

      if (btSettingsService.getDomain() === 'bigbrainbank') {
        template =
          '<p>We see you have not yet used our Desktop version</p><p>Go to https://app.bettertrader.co to utilize Back Tester, Charts, and more</p>';
      } else {
        template =
          '<p>We see you have not yet used our Desktop version</p><p>Go to https://thebrain.bigbrainbank.org to utilize Back Tester, Charts, and more</p>';
      }

      $ionicPopup.alert({
        template: template,
        title: 'See Desktop version',
        cssClass: 'popup-bar-dark',
      });
    }

    /**
     * Show "Start to use mobile version" notification
     */
    function sendGetMobileAlert() {
      var scope = $rootScope.$new();
      scope.data = {
        phone: null,
        error: false,
      };
      scope.sendMessage = sendMessage;
      var popupHandler = $ionicPopup.show({
        template:
          '<p>Download our mobile app for on-the-go information and notifications.</p>' +
          '<p>Type your phone number and receive link.</p>' +
          '<p ng-if="data.error">Please enter phone number with country code!</p>' +
          '<ion-spinner ng-if="data.sending" icon="ios-small"></ion-spinner>' +
          '<input id="phone" name="phone" type="tel" ng-model="data.phone" placeholder="(650) 123-4567" ' +
          'ng-keyup="$event.keyCode == 13 && sendMessage($event)"/>' +
          '<br>',
        title: 'Get our Mobile App',
        cssClass: 'popup-bar-dark',
        scope: scope,
        buttons: [
          { text: 'Cancel' },
          {
            text: 'Send SMS',
            type: 'button-positive',
            onTap: function (e) {
              sendMessage(e);
            },
          },
        ],
      });

      /**
       *
       * @param {*} e
       * @return {any}
       */
      function sendMessage(e) {
        if (scope.data.phone !== null) {
          scope.data.sending = true;

          var text;

          if (btSettingsService.getDomain() === 'bigbrainbank') {
            text =
              'Click here to download TheBrain app https://thebrain.bigbrainbank.org.\nThis link will expire soon.';
          } else {
            text = 'Click here to download BetterTrader app {{link}}.\nThis link will expire soon.';
          }

          btBranchService
            .sendSMS(scope.data.phone, text)
            .then(function () {
              scope.data.sending = false;
              scope.data.error = false;
              popupHandler.close();
            })
            .catch(function (reason) {
              console.error(reason);
              scope.data.sending = false;
              scope.data.error = true;
            });
        }
        e.preventDefault();
        return true;
      }
    }

    /**
     * Check we show notification about mobile version
     * Use just after btShareScopeService.init or btShareScopeService.wait
     * @return {boolean} should show notification about mobile version
     */
    function shouldShowMobileAlert() {
      var devices = btShareScopeService.getAccountInfoField('devices');
      return (
        devices.indexOf('ios') === -1 &&
        devices.indexOf('android') === -1 &&
        !btShareScopeService.getDeviceAlert() &&
        btSettingsService.getDomain() !== 'bigbrainbank'
      );
    }

    /**
     * Show notification about desktop version
     */
    function showDesktopVersionNotification() {
      // // RefactorMe: Create special service
      // // Sets platform to web if web, ios or android if app, mobileWeb if accessed on mobile Web
      // var platform = window.btPlatform;
      // console.log('platform', platform);

      if (
        $rootScope.isMobile &&
        btShareScopeService.getAccountInfoField('devices').indexOf('web') === -1 &&
        !btShareScopeService.getAccountInfoField('deviceAlert')
      ) {
        sendGetDesktopAlert();
        btShareScopeService.setAccountInfoField('deviceAlert', true);
        btShareScopeService.updateProfileSettingsFromFieldsList(['deviceAlert']);
      }
    }

    return {
      sendGetDesktopAlert: sendGetDesktopAlert,
      sendGetMobileAlert: sendGetMobileAlert,
      shouldShowMobileAlert: shouldShowMobileAlert,
      showDesktopVersionNotification: showDesktopVersionNotification,
    };
  }
})();
