/**
 * Created by Sergei Panpurin on 9/1/18.
 */

(function btTradeSituationClosure() {
  'use strict';

  /**
   * This directive display countdown timer.
   *
   * @ngdoc directive
   * @name btTradeSituation
   * @memberOf ecapp
   */

  angular
    .module('ecapp')
    .directive('btTradeSituation', btTradeSituation)
    .controller('btTradeSituationController', btTradeSituationController);

  btTradeSituation.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btTradeSituation($templateCache) {
    return {
      restrict: 'E',
      scope: {
        initialSituation: '<?',
        initialMoments: '<?',
        onChange: '&',
      },
      template: $templateCache.get('components/mobile/bt-back-tester/bt-trade-situation.html'),
      controller: 'btTradeSituationController',
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btTradeSituationController.$inject = [
    '$scope',
    'btEventService',
    'btTradeIdeasService',
    'btMarketMovementService',
    'btStrengthService',
    'btDateService',
    'btBackTesterService',
  ];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.IEventService} btEventService
   * @param {ecapp.ITradeIdeasService} btTradeIdeasService
   * @param {ecapp.IMarketMovementService} btMarketMovementService
   * @param {ecapp.IStrengthService} btStrengthService
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IBackTesterService} btBackTesterService
   */
  function btTradeSituationController(
    $scope,
    btEventService,
    btTradeIdeasService,
    btMarketMovementService,
    btStrengthService,
    btDateService,
    btBackTesterService
  ) {
    void $scope;

    /*jshint validthis: true*/
    var vm = this;

    vm.categories = getCategories();
    vm.category = vm.categories[0];

    /** @type {btRawEvent[]} */
    vm.events = [];
    vm.event = undefined;
    vm.eventSearch = '';

    vm.magnitudes = btStrengthService.getMagnitudeOptions();
    vm.magnitude = vm.magnitudes[0];

    vm.markets = [];
    vm.market = undefined;
    vm.marketSearch = '';

    vm.movements = getMovements();
    vm.movement = undefined;

    vm.directions = getDirections(null);
    vm.direction = undefined;

    vm.levels = getLevels(null);
    vm.level = undefined;

    vm.selectionStep = null;

    vm.isExpected = false;
    vm.humanizedTime = '2 days ago';
    vm.imagePath = 'images/logo/bt-logo-200px.png';
    vm.trigger = {};

    vm.opened = {
      help: false,
      settings: false,
      trigger: false,
      category: false,
    };

    vm.range = null;

    vm.$onInit = onInit;
    vm.$onChanges = onChanges;
    vm.$onDestroy = onDestroy;

    vm.openSettings = openSettings;
    vm.openHelp = openHelp;
    vm.selectCategory = selectCategory;
    vm.selectTrigger = selectTrigger;
    vm.closePopups = closePopups;
    vm.finishEventSelection = finishEventSelection;
    vm.finishMarketSelection = finishMarketSelection;

    vm.setSelectionStep = setSelectionStep;

    vm.onChangeCategory = onChangeCategory;

    vm.onChangeEvent = onChangeEvent;
    vm.onChangeMagnitude = onChangeMagnitude;
    vm.onChangeMarket = onChangeMarket;
    vm.onChangeMovement = onChangeMovement;
    vm.onChangeLevel = onChangeLevel;

    /**
     * Initialize component
     */
    function onInit() {
      updateSituation();
      updateRange();
    }

    /**
     * React on changes
     * @param {{initialSituation, initialMoments}} changes
     */
    function onChanges(changes) {
      //TODO: what if we received initial moments before initial situation
      if (changes.initialSituation) {
        updateSituation();
      }

      if (changes.initialMoments) {
        updateRange();
      }
    }

    /**
     * Update situation
     */
    function updateSituation() {
      if (vm.initialSituation) {
        if (vm.initialSituation.category === 'events') {
          vm.category = getCategoryById(vm.categories, 'events');

          if (vm.initialSituation.magnitude) {
            vm.magnitude = btStrengthService.getMagnitudeOptionById(vm.initialSituation.magnitude);
          }

          if (vm.magnitude === null) {
            vm.magnitude = vm.magnitudes[0];
          }

          vm.trigger = btTradeIdeasService.generateEventTrigger(
            vm.initialSituation.event.name,
            vm.initialSituation.event.importance,
            vm.initialSituation.event.currency,
            vm.magnitude.label
          );
        }

        if (vm.initialSituation.category === 'markets') {
          vm.category = getCategoryById(vm.categories, 'markets');
          vm.trigger = btMarketMovementService.generateTrigger(
            vm.initialSituation.market,
            vm.initialSituation.movement.split('.')[1],
            vm.initialSituation.level,
            vm.initialSituation.movement.split('.')[2].split('-')[0]
          );
          vm.trigger.html = 'analysis of previous ' + vm.trigger.html;
        }
      } else {
        setTriggerToDefault();
      }

      if (vm.events.length === 0) {
        btEventService.init().then(saveEvents).then(setInitialEvent);
      } else {
        setInitialEvent();
      }
    }

    /**
     * Update situation
     */
    function updateRange() {
      if (vm.initialMoments && vm.initialSituation) {
        vm.range = btBackTesterService.getBacktestingInterval(vm.initialSituation.category, vm.initialMoments);
      }
    }

    /**
     *
     */
    function saveEvents() {
      vm.events = btEventService.getEventsList().map(addEventLabel).sort(sortEventsByLabel);
    }

    /**
     *
     */
    function setInitialEvent() {
      if (vm.initialSituation && vm.initialSituation.category === 'events') {
        vm.event = vm.events.filter(function (event) {
          return isSameEvent(event, vm.initialSituation.event);
        })[0];

        if (vm.event === undefined) {
          vm.event = vm.events[0];
          console.error('Event with id ' + vm.initialSituation.event.id + ' was not found.');
        }
      }
    }

    /**
     * Destroy component
     */
    function onDestroy() {}

    /**
     * Compare events
     *
     * @param {btRawEvent} event1 - first event
     * @param {btRawEvent} event2 - second event
     * @return {Boolean}
     */
    function isSameEvent(event1, event2) {
      return (
        event1.name === event2.name && event1.currency === event2.currency && event1.importance === event2.importance
      );
    }

    /**
     *
     */
    function finishEventSelection() {
      vm.isExpected = true;
      if (vm.event.type !== 0) {
        vm.trigger = btTradeIdeasService.generateEventTrigger(
          vm.event.name,
          vm.event.importance,
          vm.event.currency,
          'any'
        );
        vm.magnitude = vm.magnitudes[0];
      } else {
        vm.trigger = btTradeIdeasService.generateEventTrigger(
          vm.event.name,
          vm.event.importance,
          vm.event.currency,
          vm.magnitude.label
        );
      }
      vm.onChange({ situation: prepareEventSituationObject() });
      closePopups();
      setSelectionStep(null);
    }

    /**
     *
     */
    function finishMarketSelection() {
      vm.trigger = {
        text: 'Some trigger',
        html: 'Some trigger',
        link: '',
        dev: '',
      };

      vm.onChange({ situation: prepareMarketSituationObject() });
      closePopups();
      setSelectionStep(null);
    }

    /**
     * Prepare event situation
     *
     * @return {?btEventSituationObject}
     */
    function prepareEventSituationObject() {
      try {
        return {
          category: 'events',
          event: vm.event,
          magnitude: vm.magnitude,
        };
      } catch (e) {
        console.error(e);
        return null;
      }
    }

    /**
     * Prepare market situation
     *
     * @return {?btMarketSituationObject}
     */
    function prepareMarketSituationObject() {
      try {
        return {
          category: 'markets',
          market: vm.market,
          movement: vm.movement,
          level: vm.level,
        };
      } catch (e) {
        console.error(e);
        return null;
      }
    }

    /**
     * Close all popups
     */
    function closePopups() {
      vm.opened.help = false;
      vm.opened.settings = false;
      vm.opened.trigger = false;
      vm.opened.category = false;
    }

    /**
     * Select trade situation category
     */
    function selectCategory() {
      vm.opened.category = true;
    }

    /**
     * Select trade situation trigger
     */
    function selectTrigger() {
      if (vm.category.id === 'events') {
        setSelectionStep('event');
      }

      if (vm.category.id === 'markets') {
        setSelectionStep('market');
      }

      vm.opened.trigger = true;
    }

    /**
     *
     * @param {*} name
     */
    function setSelectionStep(name) {
      vm.selectionStep = name;
    }

    /**
     *
     */
    function openHelp() {
      vm.opened.help = !vm.opened.help;
    }

    /**
     *
     */
    function openSettings() {
      vm.opened.settings = !vm.opened.settings;
    }

    /**
     *
     */
    function onChangeCategory() {
      vm.isExpected = true;
      setTriggerToDefault();
    }

    /**
     *
     */
    function onChangeEvent() {}

    /**
     *
     */
    function onChangeMagnitude() {}

    /**
     *
     */
    function onChangeMarket() {}

    /**
     *
     */
    function onChangeMovement() {}

    /**
     *
     */
    function onChangeLevel() {}

    /**
     *
     */
    function setTriggerToDefault() {
      vm.trigger = {
        text: 'Select trigger',
        html: 'Select trigger',
        link: '',
        dev: '',
      };
    }

    /**
     * Add label to event object
     * @param {btRawEvent} event - event object
     * @return {btRawEvent}
     */
    function addEventLabel(event) {
      var importance = '';
      for (var i = 0; i < 3; i++) {
        if (i < event.importance) {
          importance += '! ';
        } else {
          importance += '. ';
        }
      }
      event.label = '(' + event.currency + ') ' + importance + event.name;
      return event;
    }

    /**
     * Sort function to sort events by Label
     * @param {Object} event1
     * @param {Object} event2
     * @return {number}
     */
    function sortEventsByLabel(event1, event2) {
      return event1.label.localeCompare(event2.label);
    }

    /**
     * @typedef {Object} btTradeIdeaCategoryOption
     * @property {String} id - identifier
     * @property {String} label - display name
     */

    /**
     * Get array of trade idea category options
     * @return {btTradeIdeaCategoryOption[]}
     */
    function getCategories() {
      return [
        { id: 'events', label: 'News-driven' },
        { id: 'markets', label: 'Price-driven' },
      ];
    }

    /**
     * Get trade idea category to id
     * @param {*} categories - ?
     * @param {*} id - ?
     * @return {*}
     */
    function getCategoryById(categories, id) {
      return categories.filter(function (category) {
        return category.id === id;
      })[0];
    }

    /**
     *
     * @return {any}
     */
    function getMovements() {
      return [
        { id: 'market.consecutive.5-minute', label: 'Consecutive candles' },
        { id: 'market.intraday-movement.5-minute', label: 'Intraday movement' },
        { id: 'market.day-change.5-minute', label: 'Day movement' },
        { id: 'market.interval-change.5-minute', label: 'Change in 5 minutes' },
        { id: 'market.interval-change.30-minute', label: 'Change in 30 minutes' },
        { id: 'market.interval-change.60-minute', label: 'Change in 60 minutes' },
        { id: 'market.cross-yesterday.5-minute', label: 'Crossing yesterday high or low' },
      ];
    }

    /**
     *
     * @param {*} movementId
     * @return {any}
     */
    function getDirections(movementId) {
      if (movementId && movementId === 'market.consecutive.5-minute') {
        return [
          { id: 'positive', label: 'green candles' },
          { id: 'negative', label: 'red candles' },
        ];
      }

      if (movementId && movementId !== 'market.consecutive.5-minute') {
        return [
          { id: 'positive', label: 'moves up' },
          { id: 'negative', label: 'moves down' },
        ];
      }

      return [];
    }

    /**
     *
     * @param {*} movementId
     * @return {any}
     */
    function getLevels(movementId) {
      if (movementId && movementId === 'market.consecutive.5-minute') {
        return [
          { id: '5', label: '5' },
          { id: '6', label: '6' },
          { id: '7', label: '7' },
        ];
      }

      if (movementId && movementId !== 'market.consecutive.5-minute') {
        return [
          { id: '5', label: '0.5%' },
          { id: '10', label: '1.0%' },
          { id: '20', label: '2.0%' },
        ];
      }

      return [];
    }
  }
})();
