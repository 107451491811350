/**
 * Created by Sergei Panpurin 8/23/2018.
 */

/* global Typed */

//@ts-check
(function btBackTesterControllerClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btBackTesterController:';

  /**
   * @see ecapp.ISituationObject
   * @typedef {btEventSituationObject|btMarketSituationObject} btSituationObject
   */

  /**
   * @see ecapp.IEventSituationObject
   * @typedef {object} btEventSituationObject
   * @property {string} category - situation category: events
   * @property {btRawEvent} event - event object
   * @property {string} magnitude - event magnitude option
   */

  /**
   * @see ecapp.IMarketSituationObject
   * @typedef {object} btMarketSituationObject
   * @property {string} category - situation category: markets
   * @property {string} market - event object
   * @property {string} movement - event object
   * @property {number} level - event magnitude option
   */

  /**
   * @see ecapp.IBackEvent
   * @typedef {object} btBackEvent
   * @property {string} time - time
   */

  angular.module('ecapp').controller('btBackTesterController', btBackTesterController);

  btBackTesterController.$inject = [
    '$scope',
    '$rootScope',
    '$q',
    '$ionicLoading',
    '$timeout',
    'btTradeSituationService',
    'btEventService',
    'btTradeIdeasService',
    'btInstrumentsService',
    'btSharedData',
    'btRestrictionService',
    'btDateService',
    'btBackTesterService',
    'btChartService',
    'btStrengthService',
    'btTradingService',
    'btWatchListService',
    'btSettingsService',
    'btTourService',
    'btRowProcessorService',
  ];

  /**
   * This is new back testing controller.
   *
   * Backtesting workflow:
   *  trade ideas backtesting
   *   1. Preparing...
   *   2. Loading previous data...
   *   3. Opening BackTester...
   *   4. Running backtesting...
   *
   * @ngdoc controller
   * @name BackTesterFormController
   * @memberOf dashboard
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.IQService} $q
   * @param {ionic.ILoadingService} $ionicLoading
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.ITradeSituationService} btTradeSituationService
   * @param {ecapp.IEventService} btEventService
   * @param {ecapp.ITradeIdeasService} btTradeIdeasService
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.ISharedData} btSharedData
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IBackTesterService} btBackTesterService
   * @param {ecapp.IChartService} btChartService
   * @param {ecapp.IStrengthService} btStrengthService
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.IWatchListService} btWatchListService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.ITourService} btTourService
   * @param {ecapp.IRowProcessorService} btRowProcessorService
   */
  function btBackTesterController(
    $scope,
    $rootScope,
    $q,
    $ionicLoading,
    $timeout,
    btTradeSituationService,
    btEventService,
    btTradeIdeasService,
    btInstrumentsService,
    btSharedData,
    btRestrictionService,
    btDateService,
    btBackTesterService,
    btChartService,
    btStrengthService,
    btTradingService,
    btWatchListService,
    btSettingsService,
    btTourService,
    btRowProcessorService
  ) {
    if (gDebug) console.log('Running btBackTesterController');

    var vm = this;

    /** @type {?btBackObject} */
    vm.categories = btTradeSituationService.getCategories();

    /** @type {?btBackObject} */
    vm.data = null;

    /** @type {Number} */
    vm.tradeDelay = 90;

    /** @type {TradeIdeaObject[]} */
    vm.tradeIdeas = [];

    /** @type {ecapp.ITradingInstrument[]} */
    vm.tradeInstruments = [];

    /** @type {?btBackChart} */
    vm.currentChart = null;

    /** @type {?btSituationObject} */
    vm.initialSituation = null;

    /** @type {?btSituationObject} */
    vm.situation = null;

    /** @type {ecapp.ITradeIdea} */
    vm.tradeIdea = null;

    /** @type {ecapp.ITradingInstrument} */
    vm.tradeInstrument = null;

    /** @type {string} */
    vm.selectedInstrumentId = '';

    /**
     * Indicates whether the BackTester is busy.
     * @type {Boolean}
     */
    vm.isBusy = false;

    /**
     * Indicates whether the BackTester is blocked due to subscription restrictions.
     * @type {Boolean}
     */
    vm.isBlocked = true;

    /**
     * Indicates whether the loader should be shown.
     * @type {Boolean}
     */
    vm.isLoading = false;

    /**
     * Indicates whether the controller is activated.
     * @type {Boolean}
     */
    vm.isActivated = false;

    vm.isDemoData = true;

    /**
     * Indicates whether the backtesting is pending.
     * @type {Boolean}
     */
    vm.isPending = false;

    /** @type {btBackChart[]} */
    vm.moments = [];

    /** @type {zcChartJSON[]} */
    vm.charts = [];

    /** @type {btTradeSimulation[]} */
    vm.trades = [];

    /** @type {btBackEvent[]} */
    vm.events = [];

    vm.history = [];

    var gInitialDeferred = $q.defer();
    /** @type {angular.IPromise<*>} */
    var gInitialPromise = gInitialDeferred.promise;

    vm.typed = null;

    vm.isBigBrainBank = btSettingsService.domain === 'bigbrainbank';
    vm.isOptimus = btSettingsService.domain === 'optimusfutures';

    vm.showPrice =
      !btRestrictionService.hasFeature('backtesting') &&
      btRestrictionService.hasFeature('personalization') &&
      !vm.isBigBrainBank;

    vm.onSituationChange = onSituationChange;
    vm.onTradeInstrumentSelect = onTradeInstrumentSelect;

    vm.openUpgradeDialog = openUpgradeDialog;
    vm.openWebinarRegistration = openWebinarRegistration;
    vm.getStarted = openUpgradeDialog;
    vm.startTour = btTourService.show;
    vm.openTutorial = openTutorial;
    vm.openCaseStudy1 = openCaseStudy1;
    vm.openCaseStudy2 = openCaseStudy2;

    btSharedData.addCallback('backtesting:release', onBacktestingRelease);
    btSharedData.addCallback('backtesting:moment', onBacktestingMoment);
    btSharedData.addCallback('backtesting:acquire', onBacktestingAcquire);

    $scope.$on('subscription:updated', onSubscriptionUpgradeSuccess);
    $scope.$on('$destroy', onDestroy);

    activate();

    /**
     * This function reacts on subscription update.
     */
    function onSubscriptionUpgradeSuccess() {
      vm.hasPersonalization = btRestrictionService.hasFeature('personalization');
      vm.showPrice =
        !btRestrictionService.hasFeature('backtesting') && btRestrictionService.hasFeature('personalization');
    }

    /**
     *
     */
    function onDestroy() {
      if (vm.typed) {
        if (gDebug) console.log('btBackTesterController (Typed): destroying', vm.typed);
        vm.typed.destroy();
      }
    }

    /**
     * Activate controller
     */
    function activate() {
      if (gDebug) console.log(gPrefix, 'activating...');

      vm.isActivated = false;

      vm.data = btBackTesterService.newBackObject();

      vm.isManualTesting = true;
      vm.hasPersonalization = btRestrictionService.hasFeature('personalization');

      if (btSharedData.isBackTesterAcquired()) vm.isPending = true;

      btTradingService
        .initialize()
        .then(btTradingService.connect)
        .then(initializeBlocker)
        .then(btEventService.init)
        .then(loadInitialData)
        .then(updateInitialSituation)
        .then(updateViewModel)
        .then(loadEvents)
        .catch(handleActivationErrors)
        .finally(finishActivation);
    }

    /**
     * This function initializes BackTester blocker screen
     *
     * It should be called after user initialization.
     */
    function initializeBlocker() {
      var symbols = btWatchListService.getUserWatchlist().map(function (value) {
        return value.displayName;
      });

      if (symbols.length === 0) {
        symbols = ['S&P500', 'Brent Crude Oil', 'Gold', 'EUR/USD', 'GBP/USD', 'Futures Markets', 'FX Markets'];
      }

      createTypedText(symbols);
    }

    /**
     * This function creates typing animation.
     *
     * It use typed.js to implement this effect. Due to library or element can be undefined it wait for them.
     * To prevent duplication this object should be destroyed in controller destructor.
     * @param {String[]} markets - list of markets to show
     */
    function createTypedText(markets) {
      if (window.Typed && document.getElementById('textChange')) {
        vm.typed = new Typed('#textChange', {
          strings: markets,
          typeSpeed: 110,
          backSpeed: 50,
          loop: true,
          cursorChar: '',
          startDelay: 1000,
          backDelay: 2000,
          onDestroy: function () {
            if (gDebug) console.log('btBackTesterController (Typed): destroyed');
          },
        });
        if (gDebug) console.log('btBackTesterController (Typed): created', vm.typed);
      } else {
        setTimeout(createTypedText, 100, markets);
      }
    }

    /**
     *
     * @param {*} type - ?
     * @param {*} id - ?
     * @param {*} symbols - ?
     * @param {*} dates - ?
     * @return {angular.IPromise<*>}
     */
    function getPreviousTradeIdeas(type, id, symbols, dates) {
      return btTradeIdeasService.getTradeIdeasByDates(type, id, symbols, dates).catch(function (reason) {
        console.error(reason);
      });
    }

    /**
     * Load information about events
     */
    function loadEvents() {
      vm.data.eventsList = btEventService.getEventsList();
    }

    /**
     * Handle controller activation errors
     * @param {Error} reason - rejection reason
     */
    function handleActivationErrors(reason) {
      if (gDebug) console.log(gPrefix, 'activation error');
      console.error(reason);
    }

    /**
     * Finish controller activation
     */
    function finishActivation() {
      if (gDebug) console.log(gPrefix, 'activated');
      if (!vm.isPending) $ionicLoading.hide();
      vm.isActivated = true;
      gInitialDeferred.resolve();

      window.btBactTester = { save: btBackTesterService.saveJSON.bind(null, vm.data) };
    }

    /**
     *
     */
    function onBacktestingAcquire() {
      if (btRestrictionService.hasFeature('backtesting')) {
        vm.isBlocked = false;
        vm.isPending = true;
        vm.isLoading = true;
        vm.isDemoData = false;
      }
    }

    /**
     * Run release backtesting
     * @param {btRawRelease} row - release object
     * @param {btBackTestingParameters} params - parameters
     */
    function onBacktestingRelease(row, params) {
      preselectInstrument(params);

      waitForActivation()
        .then(prepareBackTester)
        .then(getBacktestingFunction('event', vm.data, row, params))
        .then(updateInitialSituation)
        .then(updateViewModel)
        .finally(finishBacktesting);
    }

    /**
     * Run moment backtesting
     * @param {Number[]} moments - timestamps in seconds
     * @param {btBackTestingParameters} params - parameters
     */
    function onBacktestingMoment(moments, params) {
      preselectInstrument(params);

      waitForActivation()
        .then(prepareBackTester)
        .then(getBacktestingFunction('moment', vm.data, moments, params))
        .then(updateInitialSituation)
        .then(updateViewModel)
        .finally(finishBacktesting);
    }

    /**
     *
     * @return {angular.IPromise<*>}
     */
    function waitForActivation() {
      if (gDebug) console.log(gPrefix, 'start waiting for activation...');

      var deferred = $q.defer();

      checkActivation();

      return deferred.promise;

      /**
       *
       */
      function checkActivation() {
        if (vm.isActivated) {
          if (gDebug) console.log(gPrefix, 'stop waiting for activation...');
          deferred.resolve();
        } else {
          $timeout(checkActivation, 200);
        }
      }
    }

    /**
     * Preselect instrument according to backtesting parameters
     * @param {btBackTestingParameters} params
     */
    function preselectInstrument(params) {
      if (params && params.instrument) {
        vm.selectedInstrumentId = params.instrument.id;
      } else {
        vm.selectedInstrumentId = '';
      }
    }

    /**
     * Get backtesting function
     * @param {String} type - type of backtesting: event or moment
     * @param {btBackObject} backObject - BackTester object
     * @param {btRawRelease|Number[]} data - backtesting data
     * @param {btBackTestingParameters} params - backtesting parameters
     * @return {ecapp.ICallback}
     */
    function getBacktestingFunction(type, backObject, data, params) {
      return function () {
        if (type === 'event') {
          vm.tradeDelay = 90;
          return btBackTesterService.onShareRowInfo(gInitialPromise, backObject, data, params);
        }

        if (type === 'moment') {
          vm.tradeDelay = 180;
          return btBackTesterService.onShareMoments(gInitialPromise, backObject, data, params);
        }

        return $q.reject(new Error('Unsupported backtesting type: ' + type));
      };
    }

    /**
     * Prepare BackTester
     * @return {angular.IPromise<*>}
     */
    function prepareBackTester() {
      $ionicLoading.show({
        template: '<ion-spinner icon="ios"></ion-spinner><p>Loading markets reaction to similar situations...</p>',
      });
      vm.isLoading = true;

      if (vm.isBusy) return $q.reject(new Error('BackTester is busy.'));

      vm.isManualTesting = false;
      vm.data.situation = null;
      vm.isBusy = true;

      if (!btRestrictionService.hasFeature('backtesting')) {
        btRestrictionService.showUpgradePopup('backtester').finally(function () {
          vm.isBusy = false;
        });

        $ionicLoading.hide();
        return $q.reject(new Error('Bad Subscription'));
      } else {
        vm.isBlocked = false;
      }

      // noinspection JSUnresolvedVariable
      if ($scope.$tabSelected) {
        return gInitialPromise;
      } else {
        return $q.reject(new Error('Tab is not selected.'));
      }
    }

    /**
     * Finish backtesting
     */
    function finishBacktesting() {
      $ionicLoading.hide();
      vm.isLoading = false;
      vm.isPending = false;
      vm.isBusy = false;
      btSharedData.releaseBackTester();
    }

    /**
     * Update initial situation
     */
    function updateInitialSituation() {
      vm.initialSituation = getInitialSituation(vm.data);
    }

    /**
     * Update view model
     *
     * @return {angular.IPromise<*>}
     */
    function updateViewModel() {
      if (vm.data.currentChart) {
        var params = { hideDate: true, hideXMarkers: true, hideYMarkers: false, interval: getCandleInterval(vm.data) };
        vm.data.currentChart.graphs.forEach(function (value) {
          value.chartData = btChartService.modifyStockChart(value.chartData, params) || {};
        });
      }

      vm.currentChart = vm.data.currentChart;

      /** @type {ecapp.ITradingInstrument[]} */
      vm.tradeInstruments = getRelatedInstruments(vm.data);
      vm.hasInstruments = vm.tradeInstruments.length > 0;
      vm.tradeIdeas = vm.data.fullInsights.map(convertTradeIdea.bind(null, vm.data));
      vm.moments = vm.data.charts;
      vm.tradeIdea = null;
      vm.tradeInstrument = null;
      vm.charts = [];
      vm.trades = [];
      vm.events = [];
      vm.history = [];

      vm.previousTradeIdeas = [];

      var symbols = vm.tradeInstruments.map(function (value) {
        return value.OandaSymbol + ':OANDA';
      });

      var dates = vm.moments.map(function (value) {
        return value.time;
      });

      var type = '';
      var id = '';

      if (vm.data.type === 'event') {
        type = 'events';
        id = vm.data.chartEvent.id;
      }

      if (vm.data.type === 'moment') {
        type = 'markets';
        id = vm.data.chartEvent.situationId;
      }

      return getPreviousTradeIdeas(type, id, symbols, dates).then(function (value) {
        vm.previousTradeIdeas = value;
        vm.history = selectPreviousTradeIdeas(vm.previousTradeIdeas, vm.tradeInstrument);
      });
    }

    /**
     * Normalize instrument name
     * @param {btBackObject} data - BackTester object
     * @param {String} name - instrument name
     * @return {?btInstrument}
     */
    function getInstrumentByName(data, name) {
      if (data.type === 'moment') {
        // return btInstrumentsService.getInstrumentBySomeSymbol(name);
        return btInstrumentsService.getInstrumentSmart(name);
      }

      if (data.type === 'event') {
        // return btInstrumentsService.getInstrumentBySomeSymbol(name);
        return btInstrumentsService.getInstrumentSmart(name);
      }

      return null;
    }

    /**
     * Convert trade idea
     * @param {btBackObject} backObject - BackTester object
     * @param {btPreparedInsight} item - prepared insight
     * @return {?btTradeIdea}
     */
    function convertTradeIdea(backObject, item) {
      var tradeIdea = null;
      if (backObject.type === 'moment') {
        tradeIdea = btTradeIdeasService.convertToTradeIdea(simulatePriceDrivenTradeIdea(backObject, item));
      }

      if (backObject.type === 'event') {
        tradeIdea = btTradeIdeasService.convertToTradeIdea(item);
      }

      if (tradeIdea) {
        tradeIdea.convertedInsight.isTimeFaked = item.isTimeFaked;
        return tradeIdea;
      } else {
        return null;
      }
    }

    /**
     * Simulate price-driven trade idea
     * @param {btBackObject} data - BackTester object
     * @param {btPreparedInsight} item - prepared insight
     * @return {btRawMomentInsight}
     */
    function simulatePriceDrivenTradeIdea(data, item) {
      var tradeIdea = JSON.parse(JSON.stringify(data.currentRelease));
      tradeIdea.data = item.insight.data;
      tradeIdea.params = { range: 1 };
      if (tradeIdea.time === 'number') {
        tradeIdea.time = tradeIdea.time * 1000;
      }
      return tradeIdea;
    }

    /**
     * Get related instruments
     * @param {btBackObject} data - BackTester object
     * @return {ecapp.ITradingInstrument[]}
     */
    function getRelatedInstruments(data) {
      try {
        var relatedInstruments = [];

        if (data.type === 'moment') {
          relatedInstruments = data.currentRelease.situation.relatedInstruments;
        }

        if (data.type === 'event') {
          var event = btEventService.getEventById(data.form.eventId);
          relatedInstruments = event.relatedInstruments;
        }

        return relatedInstruments.map(getInstrumentByName.bind(null, data)).filter(function (value) {
          return value && value.OandaSymbol;
        });
      } catch (e) {
        console.error(e);
      }

      return [];
    }

    /**
     * Get shared situation
     * @param {btBackObject} data - BackTester object
     * @return {?btSituationObject}
     */
    function getInitialSituation(data) {
      if (data.situation) {
        if (data.situation.magnitude && data.situation.magnitude.id) {
          data.situation.magnitude = data.situation.magnitude.id;
        }
        return data.situation;
      }

      if (data.type === 'moment') {
        return {
          category: 'markets',
          market: data.currentRelease.situation.object,
          movement: data.currentRelease.insightTemplateName,
          level: data.currentRelease.strength,
        };
      }

      if (data.type === 'event') {
        var event = btEventService.getEventById(data.form.eventId);

        if (event) {
          var magnitude = btStrengthService.getReleaseMagnitudeId(data.currentRelease);
          if (magnitude === 'any') {
            magnitude = btStrengthService.getMagnitudeOptionByRange([
              vm.data.realForm.minRowStrength,
              vm.data.realForm.maxRowStrength,
            ]);
            magnitude = magnitude ? magnitude.id : 'any';
          }

          return {
            category: 'events',
            event: event,
            magnitude: magnitude,
          };
        } else {
          console.error("Can't find event with id " + data.form.eventId);
          return null;
        }
      }

      console.error('Unsupported backtesting type ' + data.type);
      return null;
    }

    /**
     * React on situation change
     * @param {btSituationObject} situation
     */
    function onSituationChange(situation) {
      vm.situation = situation;
      vm.data.situation = situation;

      vm.isManualTesting = true;

      $ionicLoading.show({
        template: '<ion-spinner icon="ios"></ion-spinner><p>Loading markets reaction to similar situations...</p>',
      });
      vm.isLoading = true;

      $timeout(function () {
        var isValid = updateBackObject(vm.data, situation);
        btBackTesterService.submitForm(vm.data, isValid).then(function () {
          updateViewModel();
          vm.isLoading = false;
          $ionicLoading.hide();
        });
      }, 3000);
    }

    /**
     * React on instrument change
     * @param {ecapp.ITradingInstrument} tradeInstrument
     * @param {TradeIdeaObject} tradeIdea
     */
    function onTradeInstrumentSelect(tradeInstrument, tradeIdea) {
      vm.tradeInstrument = tradeInstrument;
      vm.tradeIdea = tradeIdea;
      vm.charts = selectCharts(vm.moments, vm.tradeInstrument);
      vm.trades = selectTrades(vm.moments, vm.tradeInstrument);
      vm.events = selectEvents(vm.moments, vm.data.chartEvent);
      vm.history = selectPreviousTradeIdeas(vm.previousTradeIdeas, vm.tradeInstrument);

      var stats = vm.data.stats[tradeInstrument.OandaSymbol + ':OANDA'];
      if (stats && stats.analysis) {
        if (gDebug) console.log('Debug BackTesting: ' + tradeInstrument.OandaSymbol + ':OANDA', stats.analysis);
      } else {
        if (gDebug) console.log('Debug BackTesting: ' + tradeInstrument.OandaSymbol + ':OANDA', 'no analysis');
      }
    }

    /**
     *
     * @param {*} backObject
     * @return {number}
     */
    function getCandleInterval(backObject) {
      if (backObject.type === 'event') return 1;
      if (backObject.type === 'moment') return 5;

      return 1;
    }

    /**
     *
     * @param {bt.TradeIdeaObject[]} tradeIdeas
     * @param {ecapp.ITradingInstrument} instrument
     * @return {*}
     */
    function selectPreviousTradeIdeas(tradeIdeas, instrument) {
      if (instrument) {
        return tradeIdeas.filter(function (value) {
          return value.displaySymbol === instrument.displayName;
        });
      } else {
        return [];
      }
    }

    /**
     * Select charts for selected instrument
     * @param {btBackChart[]} moments
     * @param {ecapp.ITradingInstrument} instrument
     * @return {ext.IZingChartJSON[]}
     */
    function selectCharts(moments, instrument) {
      return moments.map(function (moment) {
        var result = moment.graphs.filter(function (value) {
          if (vm.data.type === 'event')
            return value.symbol === instrument.histData || value.symbol === instrument.OandaSymbol;
          if (vm.data.type === 'moment') return value.symbol === instrument.OandaSymbol;
          return false;
        })[0];

        if (result) {
          var params = {
            hideDate: true,
            hideXMarkers: true,
            hideYMarkers: false,
            interval: getCandleInterval(vm.data),
          };
          return btChartService.modifyStockChart(result.chartData, params) || {};
        } else {
          return {};
        }
      });
    }

    /**
     * Select trades for selected instruments
     * @param {btBackChart[]} moments
     * @param {ecapp.ITradingInstrument} instrument
     * @return {btTradeSimulation[]}
     */
    function selectTrades(moments, instrument) {
      return moments.map(function (moment) {
        var result = moment.graphs.filter(function (value) {
          if (vm.data.type === 'event')
            return value.symbol === instrument.histData || value.symbol === instrument.OandaSymbol;
          if (vm.data.type === 'moment') return value.symbol === instrument.OandaSymbol;
        })[0];

        if (result && result.chartMeta && result.chartMeta.simulation) {
          return result.chartMeta.simulation;
        } else {
          return {};
        }
      });
    }

    /**
     * Select events for selected instruments
     * @param {btBackChart[]} moments -
     * @param {?btRawEvent|btMomentObject} event -
     * @return {btBackEvent[]}
     */
    function selectEvents(moments, event) {
      moments.forEach(function (moment) {
        if (event.name) moment.stateParams = btRowProcessorService.getReleaseIdData(moment, event);
      });
      return moments;
      // return moments.map(function (moment) {
      //   return {time: moment.time};
      // })
    }

    /**
     * Loads initial data. Initialize first view data.
     *
     * @ngdoc method
     * @name loadInitialData
     * @memberOf dashboard.BackTesterFormController
     * @return {angular.IPromise<*>}
     */
    function loadInitialData() {
      var deferred = $q.defer();

      $.getJSON('research_init.json', function (json) {
        vm.data.situation = json.situation;

        vm.data.chartEvent = json.chartEvent;
        vm.data.realtime = json.realtime;
        vm.data.isRealTime = false;
        vm.data.currentChart = json.currentChart;
        vm.data.currentRelease = json.currentRelease;
        vm.data.showCurrentRelease = false;
        vm.data.type = 'event';
        vm.tradeDelay = 90;

        vm.data.charts = json.charts.map(function (value) {
          var params = { hideDate: true, hideXMarkers: true, hideYMarkers: false, interval: 1 };
          return btChartService.modifyStockChart(value, params);
        });

        vm.data.total = json.total;
        vm.data.stats = json.stats;
        vm.data.realForm = json.realForm;
        vm.data.realForm.from = new Date(vm.data.realForm.from);
        vm.data.realForm.till = new Date(vm.data.realForm.till);
        vm.data.form = vm.data.realForm;
        vm.data.showResults = true;

        $timeout(
          function () {
            vm.data.isResultsReady = true;
          },
          0,
          false
        );

        vm.data.insights = json.insights;
        vm.data.fullInsights = vm.data.insights.map(function (insight) {
          return btBackTesterService.prepareInsight(vm.data, insight, 'release', vm.data.currentRelease);
        });

        vm.data.charts.forEach(function (value) {
          value.strength = btRowProcessorService.prepareStrength(value.releaseStrength, value.time);
        });

        deferred.resolve();
      });

      return deferred.promise;
    }

    /**
     * Updates BackTester object
     *
     * @param {btBackObject} backObject
     * @param {btSituationObject} situation
     * @return {boolean}
     */
    function updateBackObject(backObject, situation) {
      var isValid = false;

      btBackTesterService.resetForm(backObject);

      backObject.chosenInsights = [];

      if (situation.event) {
        //TODO Fix this problem
        var magnitude;
        if (typeof situation.magnitude === 'string') {
          magnitude = btStrengthService.getMagnitudeOptionById(situation.magnitude);
        } else {
          magnitude = situation.magnitude;
        }

        backObject.type = 'event';
        vm.tradeDelay = 90;
        backObject.form.eventId = situation.event.id;
        backObject.form.minRowStrength = magnitude.min;
        backObject.form.maxRowStrength = magnitude.max;
        isValid = true;
      }

      if (situation.market) {
        backObject.type = 'market';
        vm.tradeDelay = 180;
        backObject.form.eventId = null;
        isValid = false;
      }

      return isValid;
    }

    /**
     * This function opens backtesting or upgrade popup.
     */
    function openUpgradeDialog() {
      if (!btRestrictionService.hasFeature('backtesting')) {
        btRestrictionService.showUpgradePopup('backtester');
      } else {
        vm.isBlocked = false;
        // $timeout(function () {
        //   vm.startTour('backtester.get-started');
        // }, 3000, false);
      }
    }

    /**
     *
     */
    function openWebinarRegistration() {
      window.open($rootScope.webinarBackTesterLink, '_system');
    }

    /**
     *
     */
    function openTutorial() {
      window.open('https://youtu.be/NvGT3PJxKBc', '_system');
    }

    /**
     *
     */
    function openCaseStudy1() {
      window.open('https://bettertrader.co/case-studies/how-to-prepare-for-economic-events.html', '_system');
    }

    /**
     *
     */
    function openCaseStudy2() {
      window.open(
        'https://bettertrader.co/case-studies/unemployment-rate-and-its-effect-on-the-SP-500.html',
        '_system'
      );
    }
  }
})();
