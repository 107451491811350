/**
 * Created by Orly on 19/02/19.
 */

(function btRecentActivityClosure() {
  'use strict';

  angular
    .module('ecapp')
    .directive('btRecentActivity', btRecentActivity)
    .filter('btHighlightSearched', btHighlightSearchedText);

  btRecentActivity.$inject = ['$templateCache'];

  /**
   * Description ...
   *
   * @ngdoc directive
   * @name btRecentActivity
   * @memberOf ecapp
   * @scope
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {any}
   */
  function btRecentActivity($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('directives/barometer/bt-recent-activity-widget.html'),
      controller: btRecentActivityController,
      controllerAs: 'vm',
    };
  }

  /**
   *
   * @return {any}
   */
  function btHighlightSearchedText() {
    return function (text, phrase) {
      if (phrase) {
        text = text.toString().replace(
          new RegExp(
            '(' +
              phrase
                .split(' ')
                .sort(function (a, b) {
                  return b.length - a.length;
                })
                .join('|') +
              ')',
            'gi'
          ),
          function (match) {
            return '<span class="highlight-text-background">' + match + '</span>';
          }
        );
      }
      return text;
    };
  }

  btRecentActivityController.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    '$interval',
    'btToastrService',
    'btRssFeed',
    'btDateService',
    'btCurrenciesService',
    'Socialshare',
    'btSettingsService',
  ];

  /**
   *
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.ITimeoutService} $timeout
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.IToastrService} btToastrService
   * @param {btRss.btRssFeed} btRssFeed
   * @param {btUtils.btDateService} btDateService
   * @param {ecapp.ICurrenciesService} btCurrenciesService
   * @param {*} Socialshare
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btRecentActivityController(
    $scope,
    $rootScope,
    $timeout,
    $interval,
    btToastrService,
    btRssFeed,
    btDateService,
    btCurrenciesService,
    Socialshare,
    btSettingsService
  ) {
    void $scope;

    var RSS_LIMIT = 100;
    var HAS_AUTO_UPDATES = false;

    /*jshint validthis: true*/
    var vm = this;

    vm.$onDestroy = onDestroy;

    /** @type {?btRSSItem[]} */
    var gRSSArticles = null;

    var gRssUpdateHandler = null;

    activate();

    /**
     *
     */
    function onDestroy() {
      if (gRssUpdateHandler) $interval.cancel(gRssUpdateHandler);
    }

    /**
     *
     */
    function activate() {
      vm.searchText = '';
      vm.prevSearchText = '';
      vm.searchWords = [];

      vm.isLoading = true;

      vm.openActivity = btToastrService.open;

      /** @type {btNotification[]} */
      vm.notifications = btToastrService.list();

      /** @type {btNotification[]} */
      vm.news = [];

      // vm.getActivities = getActivities;
      vm.isNewActivity = isNewActivity;
      vm.refresh = refresh;
      vm.activityFilter = activityFilter;
      vm.formTweet = formTweet;

      $timeout(function () {
        vm.isLoading = false;
      }, 1000);

      updateRssNews();

      if (HAS_AUTO_UPDATES) {
        gRssUpdateHandler = $interval(updateRssNews, 15000, false);
      }

      $rootScope.$on('notification:inserted', addNotification);
    }

    /**
     *
     */
    function addNotification() {
      // console.log('TEST: added');
      updateActivities();
    }

    /**
     *
     */
    function updateRssNews() {
      btRssFeed
        .getData(50)
        .then(function (response) {
          var updated = false;
          var news = /**@type{btRSSItem[]} */ response.data.feed.reverse();
          btRssFeed.prepareNews(news);

          if (!gRSSArticles) {
            updated = true;
            gRSSArticles = news;
            gRSSArticles.forEach(function (value) {
              vm.news.push(convertRSSItem(value));
              if (vm.news.length > RSS_LIMIT) vm.news.shift();
            });
          } else {
            btRssFeed.getNewest(gRSSArticles, news).forEach(function (value) {
              updated = true;
              vm.news.push(convertRSSItem(value));
              if (vm.news.length > RSS_LIMIT) vm.news.shift();
            });
          }

          if (updated) updateActivities();
        })
        .catch(function (reason) {
          console.error(reason);
        });
    }

    /**
     *
     */
    function updateActivities() {
      vm.activities = [].concat(vm.notifications, vm.news).sort(function (a, b) {
        return b.time - a.time;
      });
    }

    /**
     *
     * @param {*} value
     * @return {btNotification}
     */
    function convertRSSItem(value) {
      return {
        id: 'rss-' + new Date(value.date).toString() + '-' + Math.random(),
        html: value.title,
        title: 'RSS',
        icon: 'ion-social-rss',
        time: new Date(value.date),
        ago: btDateService.getHumanisedTimeFromNow(new Date(value.date), true),
        status: 'unread',
        action: null,
      };
    }

    /**
     *
     */
    function refresh() {
      btToastrService.refresh();

      vm.news.forEach(function (notification) {
        notification.ago = btDateService.getHumanisedTimeFromNow(notification.time, true);
      });
    }

    /**
     *
     * @param {btNotification} activity
     * @return {boolean}
     */
    function isNewActivity(activity) {
      return Date.now() - activity.time < 60 * 1000;
    }

    /**
     * This filter for searched text in recent activities.
     *
     * @param {*} activity
     * @return {boolean}
     */
    function activityFilter(activity) {
      if (vm.searchText) {
        if (vm.searchText !== vm.prevSearchText) {
          vm.prevSearchText = vm.searchText;
          vm.searchWords = vm.searchText.toLowerCase().split(' ');
        }
        if (!activity.$text) activity.$text = (activity.title + ' - ' + activity.html).toLowerCase();

        var result = true;
        vm.searchWords.forEach(function (word) {
          if (activity.$text.indexOf(word) === -1) result = false;
        });

        return result;
      } else return true;
    }

    /**
     * This function prepares tweet and try to share it.
     *
     * @param {btNotification} activity - notification object
     */
    function formTweet(activity) {
      btCurrenciesService
        .getCurrenciesList()
        .catch(function (reason) {
          console.error(reason);
          return { currenciesList: [] };
        })
        .then(function (currencies) {
          var text = activity.title + ' - ' + activity.html;

          if (currencies.currenciesList.length) {
            var codes = currencies.currenciesList.map(function (currency) {
              return currency.id;
            });

            var pattern = '([^A-Za-z0-9#])(' + codes.join('|') + ')(\\b)';

            text = ' ' + text;
            text = text.replace(new RegExp(pattern, 'g'), function (match, p1, p2) {
              return '' + p1 + '#' + p2;
            });
            text = text.substring(1);
          }

          if (text.length > 219) {
            // hard limit
            text = text.substring(0, 217);
            // Could we have a url inside the text?
            // search for ' ' or '\n'
            var last = Math.min.apply(
              null,
              [' ', '\n'].map(function (value) {
                return text.lastIndexOf(value);
              })
            );
            text = text.substring(0, last) + '...';
          }

          if (btSettingsService.isBigBrainBank()) {
            text += '\n\n#FX #futures #trading\n\nSent from https://thebrain.bigbrainbank.org 📢';
          } else if (btSettingsService.isLinkDataService()) {
            text += '\n\n#FX #futures #trading\n\nSent from https://portal.linkdataservices.com 📢';
          } else {
            text += '\n\n#FX #futures #trading\n\nSent from https://BetterTrader.co 📢';
          }

          Socialshare.share({
            provider: 'twitter',
            attrs: {
              socialshareText: text,
              socialshareUrl: '.',
              socialshareHashtags: '',
            },
          });
        })
        .catch(function (reason) {
          console.error(reason);
          btToastrService.error('Something went wrong. Try again late.', 'Sharing');
        });
    }
  }
})();
