/**
 * Created by YYY on MM/DD/YY.
 */

/* global Typed*/

(function btProMembershipClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btProMembership:';

  /**
   * This directive displays notification center button.
   *
   * @ngdoc directive
   * @name btProMembership
   * @memberOf ecapp
   * @restrict E
   * @scope
   */

  /**
   * This controller process notification center.
   *
   * @ngdoc controller
   * @name btProMembershipController
   * @memberOf ecapp
   */

  /** Directive */
  angular
    .module('ecapp')
    .directive('btProMembership', angularDirective)
    .controller('btProMembershipController', angularController);

  angularDirective.$inject = ['$templateCache', '$timeout', '$ionicScrollDelegate'];

  /**
   * Implementation of Notification Center Directive
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {angular.ITimeoutService} $timeout
   * @param {ionic.IScrollDelegateService} $ionicScrollDelegate - ionic scroll delegate service
   * @return {angular.IDirective}
   */
  function angularDirective($templateCache, $timeout, $ionicScrollDelegate) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('directives/common/bt-pro-membership.html'),
      controller: 'btProMembershipController',
      controllerAs: 'vm',
      bindToController: true,
      link: function (scope, element, attrs, controller) {
        controller.gotoAnchor = function (x) {
          var id = 'anchor-' + x + '-' + controller.id;
          var target = element.find('#' + id);
          var ref = null;

          if (element[0] && element[0].parentElement) {
            if (element[0].parentElement.classList.contains('scroll')) {
              ref = element[0].parentElement.parentElement;
            } else {
              ref = element[0].parentElement;
            }
          }

          // Some browsers did not support scrollBy
          if (ref && ref.scrollBy) ref.scrollBy(0, target.offset().top);

          $timeout(function () {
            $ionicScrollDelegate.resize();
          }, 100);
        };
      },
    };
  }

  angularController.$inject = [
    '$scope',
    '$location',
    'btSubscriptionService',
    'btClientsTestimonies',
    'btSettingsService',
  ];

  /**
   * This is a controller for pro membership page.
   *
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.ILocationService} $location
   * @param {ecapp.ISubscriptionService} btSubscriptionService
   * @param {ecapp.IClientsTestimonies} btClientsTestimonies
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function angularController($scope, $location, btSubscriptionService, btClientsTestimonies, btSettingsService) {
    console.log('Running btProMembershipPageController');

    var vm = this;

    vm.id = parseInt(Math.random() * 1000000000);
    vm.typed = null;
    vm.selectedBox = 1;
    vm.isOptimus = btSettingsService.domain === 'optimusfutures';

    vm.showBox = showBox;
    vm.showBoxMobile = showBoxMobile;
    vm.isBoxSelected = isBoxSelected;
    // vm.gotoAnchor = gotoAnchor;
    vm.payMonthlyProPlan = payMonthlyProPlan;
    vm.payYearlyProPlan = payYearlyProPlan;

    vm.hasProPlan = btSettingsService.hasFeature('backtesting');

    var typedId = 'clients-testimonies-' + vm.id;

    $scope.$on('$destroy', onDestroy);

    createTypedText(typedId, btClientsTestimonies);

    /**
     * This function creates typing animation.
     *
     * It use typed.js to implement this effect. Due to library or element can be undefined it wait for them.
     * To prevent duplication this object should be destroyed in controller destructor.
     *
     * @param {String} id - element identifier
     * @param {String[]} strings - strings to display
     */
    function createTypedText(id, strings) {
      if (window.Typed && document.getElementById(id)) {
        vm.typed = new Typed('#' + id, {
          strings: strings,
          typeSpeed: 1,
          backSpeed: 1,
          loop: true,
          cursorChar: '',
          startDelay: 1,
          backDelay: 7000,
          onDestroy: function () {
            if (gDebug) console.log(gPrefix, '(Typed): destroyed');
          },
        });
        if (gDebug) console.log(gPrefix, '(Typed): created', vm.typed);
      } else {
        setTimeout(createTypedText, 100, id, strings);
      }
    }

    /**
     *
     * @param {*} i
     */
    function showBox(i) {
      vm.selectedBox = i;
    }

    /**
     *
     * @param {*} i
     */
    function showBoxMobile(i) {
      vm.selectedBox = i;
    }

    /**
     *
     * @param {*} i
     * @return {any}
     */
    function isBoxSelected(i) {
      return vm.selectedBox === i;
    }

    /**
     *
     * @param {*} x
     */
    function gotoAnchor(x) {
      // $ionicScrollDelegate.$getByHandle('proMemberScroll').scrollBy(0, 200);
      // var newHash = 'anchor-' + x + '-' + vm.id;
      // if ($location.hash() !== newHash) {
      //   // set the $location.hash to `newHash` and
      //   // $anchorScroll will automatically scroll to it
      //   $location.hash(newHash);
      // } else {
      //   // call $anchorScroll() explicitly,
      //   // since $location.hash hasn't changed
      //   $anchorScroll();
      // }
    }

    /**
     * On view destroy
     */
    function onDestroy() {
      console.log('Destroy btProMembershipPageController');
      if (vm.typed) {
        if (gDebug) console.log(gPrefix, '(Typed): destroying', vm.typed);
        vm.typed.destroy();
      }
    }

    /**
     *
     */
    function payMonthlyProPlan() {
      btSubscriptionService.getSpecificPurchase('subscriptions', 'pro', 30).then(function (plan) {
        if (plan) {
          return btSubscriptionService.buyStripeSubscription(plan);
        } else {
          alert("Can't find the pricing plan.");
        }
      });
    }

    /**
     *
     */
    function payYearlyProPlan() {
      btSubscriptionService.getSpecificPurchase('subscriptions', 'pro', 365).then(function (plan) {
        if (plan) {
          return btSubscriptionService.buyStripeSubscription(plan);
        } else {
          alert("Can't find the pricing plan.");
        }
      });
    }
  }
})();
