(function () {
  'use strict';

  // This directive manage the insights inside of the event modal.

  angular
    .module('ecapp')
    /**
     * ???
     *
     * @ngdoc directive
     * @name btEventModalInsight
     * @memberOf ecapp
     */
    .directive('btEventModalInsight', [
      '$templateCache',
      function ($templateCache) {
        return {
          //we use this as a tag
          restrict: 'E',
          scope: {
            //we get an insight object
            insight: '=',
            row: '=',
            isQuickView: '=quickView',
            addWatch: '=',
          },
          template: $templateCache.get('directives/event-modal/regular/event-insight.html'),
          controller: btEventModalInsightController,
        };
      },
    ]);

  btEventModalInsightController.$inject = [
    '$scope',
    'btStrengthService',
    'btInsightsService',
    'btShareSocialService',
    'btSubscriptionService',
    'btSharedData',
    '$rootScope',
  ];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.IStrengthService} btStrengthService
   * @param {ecapp.IInsightsService} btInsightsService
   * @param {ecapp.IShareSocialService} btShareSocialService
   * @param {ecapp.ISubscriptionService} btSubscriptionService
   * @param {ecapp.ISharedData} btSharedData
   * @param {ecapp.ICustomRootScope} $rootScope
   */
  function btEventModalInsightController(
    $scope,
    btStrengthService,
    btInsightsService,
    btShareSocialService,
    btSubscriptionService,
    btSharedData,
    $rootScope
  ) {
    $scope.subStatus = btSubscriptionService.status;

    $scope.runSimulation = runSimulation;
    $scope.shareRowInfo = shareRowInfo;
    $scope.openModalShareDialog = openModalShareDialog;

    activate();

    /**
     *
     */
    function activate() {
      $scope.type = '';
      $scope.strength = '';
      $scope.templateHTML = '';

      if ($scope.addWatch) {
        $scope.$watch('insight', onInsightChange);
      } else {
        setStrength($scope.insight);
        setRangesIfRequired($scope.insight);
        $scope.templateHTML = btInsightsService.prepareTemplate($scope.insight);
        $scope.type = $scope.insight.type;
      }
    }

    /**
     *
     */
    function onInsightChange() {
      if ($scope.insight) {
        setStrength($scope.insight);
        setRangesIfRequired($scope.insight);
        $scope.templateHTML = btInsightsService.prepareTemplate($scope.insight);
        $scope.type = $scope.insight.type;
      }
    }

    /**
     *
     */
    function runSimulation() {
      if ($scope.insight.type === 'back-pre') {
        $scope.shareRowInfo($scope.row, {
          timeRange: 'realtime',
          releaseCase: $scope.insight.case.id,
          strengthRange: $scope.insight.case.range,
          magnitude: 'all',
        });
      }
    }

    /**
     * Connected to card share button. On click share information about selected row.
     * @param {*} row - release data
     * @param {*} opts - options: timeRange ('real-time'), strengthRange
     */
    function shareRowInfo(row, opts) {
      btSharedData.acquireBackTester();
      btSharedData.runBackTester('release', row, opts);

      // Itay: added in order to add selectedRow feature (change style of selected row)
      // At first deselect current row using saved reference from root scope
      if (null != $rootScope.selectedRow && $rootScope.selectedRow != row) {
        $rootScope.selectedRow.isSelected = false;
      }
      // Select current row and save it to root scope
      row.isSelected = true;
      $rootScope.selectedRow = row;
    }

    /**
     * This function takes the strength from the object (which we get from mongo, and manipulate it in order to be able to work easily
     * @param {*} insight
     */
    function setStrength(insight) {
      var time = insight.data ? (insight.data.rowTime ? insight.data.rowTime : 0) : 0;
      if (insight.type === 'post-expected') {
        $scope.strength = btStrengthService.prepareStrength(insight.totalStrength, time);
      } else if (insight.type === 'post') {
        $scope.strength = btStrengthService.prepareStrength(insight.totalSurpriseStrength, time);
      }
    }

    /**
     *
     * @param {*} value
     * @return {any}
     */
    function hasZeros(value) {
      if (value.split('.')[1] === '00' && value.length > 6) {
        return parseFloat(value).toFixed(1);
      } else {
        return value;
      }
    }

    /**
     * This function find the ranges of range and surprise insights
     * @param {*} insight
     */
    function setRangesIfRequired(insight) {
      // the first operation that checks if range was already set is in order to avoid an error when we load the same range insights more than once.
      // TODO: (Itay) Might be problem if row changes
      if (null == $scope.insight.range && (insight.id === 27 || insight.id === 28)) {
        var res = btInsightsService.getRange(insight);
        var range = res.range;
        var templateSplit = res.templateSplit;

        // builds the range for surprise insight
        if (insight.id === 28) {
          insight.range = {
            low: range[0],
            high: range[1],
            actual: hasZeros(btInsightsService.getSurprise($scope.row.actual, $scope.row.forecast).toFixed(2)),
          };
        } else if (insight.id === 27) {
          insight.range = {
            low: range[0],
            high: range[1],
            actual: hasZeros(btInsightsService.getActual($scope.row.actual).toFixed(2)),
            benchmark: $scope.row.eventsInfo.dynamicData.benchmark,
          };
        }
        insight.template = templateSplit[0];
        insight.templateData = templateSplit[1];
      }
    }

    /**
     *
     */
    function openModalShareDialog() {
      btShareSocialService.prepareInsightShareData($scope.row, $scope.insight.template);
    }
  }
})();
