/**
 * Created by Sergey Panpurin on 8/13/2017.
 */

(function btTradingCardAClosure() {
  'use strict';

  var gDebug = false;

  /**
   * This directive shows trading card
   *
   * @ngdoc directive
   * @name btTradingCardA
   * @memberOf ecapp
   * @scope
   * @param {btConvertedTradingInsightObject|btPreparedInsight} insight - ?
   * @param {Boolean} showEnterPrice - ?
   */

  angular
    .module('ecapp')
    .directive('btTradingCardA', btTradingCardA)
    .controller('btTradingCardAController', btTradingCardAController);

  btTradingCardA.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {any}
   */
  function btTradingCardA($templateCache) {
    return {
      restrict: 'E',
      scope: {
        insight: '=',
        showEnterPrice: '=',
      },
      template: $templateCache.get('directives/event-card/trading-card-a.html'),
      controller: 'btTradingCardAController',
    };
  }

  btTradingCardAController.$inject = [
    '$scope',
    '$interval',
    '$timeout',
    'btInstrumentsService',
    'btOandaApiService',
    'btDateService',
    'btTradingCardService',
    'btTradingService',
    'btDevService',
    'btSettingsService',
    'btToastrService',
  ];

  /**
   *
   * @param {*} $scope
   * @param {angular.IIntervalService} $interval
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.IOandaApiService} btOandaApiService
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.ITradingCardService} btTradingCardService
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.IDevService} btDevService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IToastrService} btToastrService
   */
  function btTradingCardAController(
    $scope,
    $interval,
    $timeout,
    btInstrumentsService,
    btOandaApiService,
    btDateService,
    btTradingCardService,
    btTradingService,
    btDevService,
    btSettingsService,
    btToastrService
  ) {
    /**
     * @name $scope.insight
     * @type {btConvertedTradingInsightObject|btPreparedInsight}
     */

    /** @type {null} */
    var interval = null;
    var waitEnterPrice = null;

    $scope.hasEnterTime = false;
    $scope.isLoading = true;
    $scope.hasPrice = false;
    $scope.hasPriceError = false;
    $scope.hasPrices = btSettingsService.hasFeature('prices');
    $scope.hasTrading = btSettingsService.hasFeature('trading');
    $scope.progress = '';

    $scope.showOrders = showOrdersFunc;
    $scope.closePosition = closePositionFunc;
    $scope.cancelOrder = cancelOrderFunc;
    $scope.showHistoryGraph = showHistoryGraphFunc;
    $scope.showMore = showMoreFunc;

    $scope.precision = 2;
    $scope.c = 1;

    $scope.$on('$destroy', btInstrumentOnDestroy);

    activate();

    /**
     *
     */
    function activate() {
      if ($scope.insight.kind === undefined) {
        btDevService.alert('Insight without kind');
      }

      $scope.minAfter = btTradingCardService.getMinutesAfter($scope.insight.kind);

      prepareTradeCard($scope.insight);

      updateExpiration();
    }

    /**
     *
     */
    function btInstrumentOnDestroy() {
      if (interval) $interval.cancel(interval);
      if (waitEnterPrice) $timeout.cancel(waitEnterPrice);
      console.log('Controller btInstrument was destroyed!');
    }

    /**
     *
     */
    function showOrdersFunc() {
      $scope.market.openOrders = !$scope.market.openOrders;
    }

    /**
     *
     * @param {*} position
     */
    function closePositionFunc(position) {
      if (!$scope.isClosing && $scope.market.instrument.hasPositions) {
        $scope.isClosing = true;
        // var position = $scope.market.instrument.positionData;

        btTradingService
          .closePosition(position)
          .then(function () {
            checkPosition($scope.market.instrument);
          })
          .finally(function () {
            $scope.isClosing = false;
          });
      }
    }

    /**
     *
     * @param {*} order
     */
    function cancelOrderFunc(order) {
      if (!$scope.isClosing && order) {
        $scope.isClosing = true;
        btTradingService
          .cancelOrder(order.key)
          .then(function () {
            checkPosition($scope.market.instrument);
          })
          .catch(function (error) {
            btToastrService.error('Error: ' + (error.Message || error.message), 'Cancel Order', { type: 'trade' });
          })
          .finally(function () {
            $scope.isClosing = false;
          });
      }
    }

    /**
     *
     */
    function showHistoryGraphFunc() {
      $scope.market.openCharts = !$scope.market.openCharts;
    }

    /**
     *
     */
    function showMoreFunc() {
      $scope.fullInfo = !$scope.fullInfo;
    }

    /**
     *
     * @param {Object} item
     */
    function prepareTradeCard(item) {
      $scope.tradeType = item.insight.data.action === 'uptrend' ? 'uptrend' : 'downtrend';
      $scope.tradeClass = $scope.tradeType === 'uptrend' ? 'button-balanced' : 'button-assertive';
      $scope.market = {};
      $scope.market.name = item.market;
      // $scope.impactValued = 4;
      $scope.isLoading = true;
      $scope.fullInfo = false;

      var instrument = btInstrumentsService.getInstrumentBySomeSymbol(item.market);
      var priceUnitValue = btInstrumentsService.getPriceUnitValue(instrument);
      var priceUnitName = btInstrumentsService.getPriceUnitName(instrument);
      $scope.trade = btTradingCardService.getTradeData(item, $scope.insight, priceUnitValue, priceUnitName);

      $scope.hasEnterTime = !$scope.insight.isTimeFaked;

      if ($scope.showEnterPrice && $scope.hasEnterTime) {
        $scope.hasEnterPrice = false;
        btTradingCardService.fixPrices($scope.trade, 0);

        if (btInstrumentsService.hasOandaSymbol(instrument)) {
          getEnteringPrice(instrument, $scope.insight.time, $scope.minAfter, 0);
        }
      } else {
        $scope.hasEnterPrice = false;
        $scope.hasPrice = true;
        btTradingCardService.fixPrices($scope.trade, 0);
      }

      btInstrumentsService
        .init()
        .then(function () {
          console.log('Instruments Data has been loaded');
          $scope.isLoading = false;
          $scope.market.instrument = btInstrumentsService.getInstrumentBySomeSymbol($scope.market.name);
          if ($scope.market.instrument.OandaSymbol) {
            updatePricesInterval();

            interval = $interval(updatePricesInterval, 5000);
          }
        })
        .catch(function (reason) {
          $scope.isLoading = false;
          console.log('btInstrumentsContainer: error', reason);
        });
    }

    /**
     *
     * @param {*} instrument
     * @param {Number} time - time in milliseconds
     * @param {*} minAfter
     * @param {*} iAttempt
     */
    function getEnteringPrice(instrument, time, minAfter, iAttempt) {
      var maxAttempt = 20;

      if (
        !$scope.hasPrices ||
        (btTradingService.getBrokerName() !== 'oanda' && btTradingService.getBrokerName() !== 'default')
      ) {
        return;
      }

      if (typeof time !== 'number') {
        console.error('Time should be a number!', time);
        return;
      }

      if (time * 1000 > btDateService.getNowDate()) {
        $scope.hasEnterTime = false;
        return;
      }

      btOandaApiService
        .getEntryPrice(instrument.OandaSymbol, time, minAfter)
        .then(function (data) {
          console.log(' --- getEntryPrice --- ');
          console.log(data);

          if (data && data.candles && data.candles.length > minAfter) {
            var candle = data.candles[minAfter];
            var p = btTradingCardService.getMaxPricePrecision([candle.mid.o, candle.mid.c, candle.mid.l, candle.mid.h]);
            $scope.hasPrice = true;
            $scope.hasEnterPrice = true;
            btTradingCardService.fixPrices($scope.trade, data.candles[minAfter].mid.o, p);
          } else {
            if (iAttempt < maxAttempt) {
              $scope.progress += '.';
              waitEnterPrice = $timeout(
                function () {
                  getEnteringPrice(instrument, time, minAfter, iAttempt + 1);
                },
                5000,
                false
              );
            } else {
              $scope.hasPriceError = true;
            }
          }
        })
        .catch(function (reason) {
          console.error(reason);
          $scope.hasPriceError = true;
        });
    }

    /**
     *
     */
    function updatePricesInterval() {
      if (gDebug) console.log('Interval was called (updatePricesInterval)');

      updateExpiration();
      checkPosition($scope.market.instrument);
      updateInstrumentData($scope.market.instrument.OandaSymbol);
    }

    /**
     *
     */
    function updateExpiration() {
      $scope.isExpired = btTradingCardService.isExpired($scope.insight.time, $scope.minAfter);
      if ($scope.trade && $scope.trade.delay && $scope.trade.delay.reward) {
        $scope.isOld = btTradingCardService.isOld($scope.insight.time, $scope.minAfter, $scope.trade.delay.reward);
        $scope.ttl = btTradingCardService.getTimeToLive(
          $scope.insight.time,
          $scope.minAfter,
          $scope.trade.delay.reward
        );
      } else {
        $scope.isOld = btTradingCardService.isOld($scope.insight.time, $scope.minAfter);
        $scope.ttl = btTradingCardService.getTimeToLive($scope.insight.time, $scope.minAfter);
      }

      $scope.countDown = btTradingCardService.getCountDown($scope.insight, $scope.minAfter);
    }

    /**
     * Check position for selected instrument
     * @param {Object} instrument - instrument object
     */
    function checkPosition(instrument) {
      var positions = btTradingService.hasPositions(instrument.brokerSymbol);
      var orders = btTradingService.hasOrders(instrument.brokerSymbol);

      console.log('Check positions for', instrument.brokerSymbol, positions);

      if (positions.length > 0) {
        instrument.hasPositions = true;
        instrument.positionData = positions;
      } else {
        instrument.hasPositions = false;
        instrument.positionData = null;
      }

      if (orders !== null || orders.length === 0) {
        instrument.numOrders = orders.length;
        instrument.ordersData = orders;
      } else {
        instrument.numOrders = 0;
        instrument.ordersData = null;
      }
    }

    /**
     * Updates instrument data
     * @param {string} symbol - instrument name
     */
    function updateInstrumentData(symbol) {
      if (
        !$scope.hasPrices ||
        (btTradingService.getBrokerName() !== 'oanda' && btTradingService.getBrokerName() !== 'default')
      ) {
        return;
      }

      btOandaApiService
        .getLiveCandleData(symbol)
        .then(function (data) {
          if (data !== undefined) {
            $scope.instrumentsData = data.now;
          }
        })
        .catch(function (reason) {
          console.error('btInstrumentsContainer: error', reason);
        });
    }
  }
})();
