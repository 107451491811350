/**
 * Created by Itay on 6/14/2016.
 */

(function btEventCardRevisionClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This is directive for release revision
     *
     * @ngdoc directive
     * @name btEventCardRevision
     * @memberOf ecapp
     */
    .directive('btEventCardRevision', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/event-card/revision.html'),
        };
      },
    ]);
})();
