(function btTrendFilterClosure() {
  'use strict';

  var trends = {
    uptrend: 'up',
    downtrend: 'down',
  };

  angular
    .module('ecapp')
    /**
     * Returns trend value
     *
     * @ngdoc filter
     * @name btTrendFilter
     * @memberOf ecapp
     */
    .filter('btTrend', [btTrendFilter]);

  /**
   *
   * @return {angular.IFilterFunction}
   */
  function btTrendFilter() {
    return function convertTrend(action) {
      return trends[action];
    };
  }
})();
