/**
 * Created by David on 28/09/16.
 */

// @ts-check
(function btShareSocialServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btShareSocialService', btShareSocialService);

  btShareSocialService.$inject = [
    '$ionicPopup',
    '$rootScope',
    '$window',
    '$state',
    'btRowProcessorService',
    'btShortenerUrlService',
    'btDateService',
    'btRssFeed',
    'btBranchService',
    '$templateCache',
    'btSettingsService',
  ];

  /**
   * @ngdoc service
   * @name btShareSocialService
   * @memberOf ecapp
   * @description
   *  This factory helps to share content on social networks
   * @param {ionic.IPopupService} $ionicPopup
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.IWindowService} $window
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.IRowProcessorService} btRowProcessorService
   * @param {ecapp.IShortenerUrlService} btShortenerUrlService
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IRssFeed} btRssFeed
   * @param {ecapp.IBranchService} btBranchService
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {ecapp.ISettingsService} btSettingsService
   * @return {ecapp.IShareSocialService}
   */
  function btShareSocialService(
    $ionicPopup,
    $rootScope,
    $window,
    $state,
    btRowProcessorService,
    btShortenerUrlService,
    btDateService,
    btRssFeed,
    btBranchService,
    $templateCache,
    btSettingsService
  ) {
    console.log('Running btShareSocialService');

    //TODO: rewrite
    var releaseUrl;
    var currency;
    var name;
    var strength;
    var actual;
    var previous;
    var forecast;
    var insights;
    var templateText;
    var timeText;

    /**
     *
     * @param {*} release
     */
    function getReleaseShareData(release) {
      var linkData = btRowProcessorService.getReleaseIdData(release, release.eventsInfo);

      if (btSettingsService.isBigBrainBank()) {
        releaseUrl =
          'https://thebrain.bigbrainbank.org/' + $state.href('ecapp.app.main.detail', linkData, { absolute: false });
      } else if (btSettingsService.isLinkDataService()) {
        releaseUrl =
          'https://portal.linkdataservices.com/' + $state.href('ecapp.app.main.detail', linkData, { absolute: false });
      } else {
        releaseUrl =
          'https://app.bettertrader.co/' + $state.href('ecapp.app.main.detail', linkData, { absolute: false });
      }

      currency = release.eventsInfo.currency;
      name = release.eventsInfo.name;
      strength = release.strengthPercentage;
      actual = release.actual;
      previous = release.previous;
      forecast = release.forecast;
      insights = null;
      timeText = null;

      if (release.eventsInfo.usuallyNoForecast === true) {
        forecast = 'Usually No Exp';
      }

      if (strength == null && release.class) {
        if (
          release.class === 'eventCardNextHot' ||
          release.class === 'eventCardNext' ||
          release.class === 'eventCardFuture'
        ) {
          timeText = 'IN ' + btDateService.getHumanisedTimeFromNow(release.time * 1000);
        }
      }
    }

    var prepareInsightShareData = function (release, insight) {
      getReleaseShareData(release);

      templateText = insight;

      var header = '';
      var shortHeader = '';
      header += currency ? currency : '';
      header += name ? ' – ' + name : '';
      shortHeader += actual === 'NA' ? header : header + ' [' + actual + ']';
      header += templateText ? ' – ' + templateText : ' -> ' + strength.fullMsg;
      shortHeader += templateText ? ' – ' + templateText : ' -> ' + strength.fullMsg;

      var twitterText = '';
      twitterText += currency ? '#' + currency : '';
      twitterText += name ? ' – ' + name : '';
      twitterText = actual === 'NA' ? twitterText : twitterText + ' [' + actual + ']';
      twitterText += templateText ? ' – ' + templateText : ' -> ' + strength.fullMsg;
      twitterText += '\n#' + name.replace(/[^A-Za-z0-9]+/g, '');

      var content = '';
      content += 'Actual: ' + actual + (strength ? ' -> ' + strength.fullMsg : '');
      content += '\r\nForecast: ' + forecast;
      content += '\r\nPrevious: ' + previous;

      if ($rootScope.isApp) {
        popupMultiShare(header, shortHeader, content, releaseUrl, true, twitterText);
      } else {
        btShortenerUrlService.shrinkUrl(releaseUrl).then(function (data) {
          popupMultiShare(header, shortHeader, content, data, true, twitterText);
        });
      }
    };

    var prepareReleaseShareData = function (release) {
      getReleaseShareData(release);

      if (release.insightRow) {
        // TODO: Check
        insights = release.insightRow.insights;
      }

      var header = '';
      var shortHeader = '';
      header += currency ? currency : '';
      header += name ? ' – ' + name : '';
      shortHeader += actual === 'NA' ? header : header + ' [' + actual + ']';
      header += strength ? ' -> ' + strength.fullMsg : timeText ? ' – ' + timeText : '';
      shortHeader += strength ? ' -> ' + strength.fullMsg : timeText ? ' – ' + timeText : '';

      var twitterText = '';
      twitterText += currency ? '#' + currency : '';
      twitterText += name ? ' – ' + name : '';
      twitterText = actual === 'NA' ? twitterText : twitterText + ' [' + actual + ']';
      twitterText += strength ? ' -> ' + strength.fullMsg : timeText ? ' – ' + timeText : '';
      twitterText += '\n#' + name.replace(/[^A-Za-z0-9]+/g, '');

      var values = '';
      values += 'Actual: ' + actual + (strength ? ' -> ' + strength.fullMsg : '');
      values += '\r\nForecast: ' + forecast;
      values += '\r\nPrevious: ' + previous;

      var details = '';
      if (insights.length > 0) {
        var countPre = 0;
        var countPost = 0;
        //details += '\r\n';
        insights.forEach(function (insight) {
          if (insight.type === 'pre') {
            if (countPre === 0) {
              if (countPost > 0) {
                details += '\r\n';
              }
              details += '\r\nPre release insights:';
            }
            details += '\r\n -> ' + insight.template + (insight.templateData ? ': ' + insight.templateData : '');
            countPre++;
          } else if (insight.type === 'post') {
            if (countPost === 0) {
              if (countPre > 0) {
                details += '\r\n';
              }
              details += '\r\nPost release insights:';
            }
            details += '\r\n -> ' + insight.template;
            countPost++;
          }
        });
      }

      if ($rootScope.isApp) {
        var link = releaseUrl;
        var content = values + '\r\n' + details;
        popupMultiShare(header, shortHeader, content, link, null, twitterText);
      } else {
        btShortenerUrlService.shrinkUrl(releaseUrl).then(function (data) {
          var link = data;
          var content = values + '\r\n' + details;
          popupMultiShare(header, shortHeader, content, link, null, twitterText);
        });
      }
    };

    var prepareNewsShareData = function (row) {
      var header = row.title;
      var shortHeader = row.title.slice(0, 30);

      var twitterText = row.title + ' ' + row.link;

      if (btSettingsService.isBigBrainBank()) {
        twitterText += ' via TheBrain';
      } else if (btSettingsService.isLinkDataService()) {
        twitterText += '';
      } else {
        twitterText += ' via @bettertraderpro';
      }

      var content = btRssFeed.removeHTML(row.description);

      if ($rootScope.isApp) {
        popupMultiShare(header, shortHeader, content, row.link, true, twitterText);
      } else {
        btShortenerUrlService.shrinkUrl(row.link).then(function (data) {
          popupMultiShare(header, shortHeader, content, data, true, twitterText);
        });
      }
    };

    /**
     *
     * @param {*} title
     * @param {*} shortTitle
     * @param {*} content
     * @param {*} link
     * @param {*} isInsight
     * @param {*} twitterText
     * @return {*}
     */
    var popupMultiShare = function (title, shortTitle, content, link, isInsight, twitterText) {
      var sharedObj = $rootScope.$new();

      var footer = 'Sent from';

      if (btSettingsService.isBigBrainBank()) {
        footer += ' https://thebrain.bigbrainbank.org';
        sharedObj.url = 'https://thebrain.bigbrainbank.org';
      } else if (btSettingsService.isLinkDataService()) {
        footer += ' https://portal.linkdataservices.com';
        sharedObj.url = 'https://portal.linkdataservices.com';
      } else {
        footer += ' https://BetterTrader.co';
        sharedObj.url = 'https://bettertrader.co';
      }

      sharedObj.link = link;

      sharedObj.isMobile = $window.isMobile;

      // For Twitter and LinkedIn
      sharedObj.shortHeader = shortTitle;
      sharedObj.shortContent = link + '\r\n\r\n' + footer;
      sharedObj.shortText = sharedObj.shortHeader + '\r\n\r\n' + (isInsight ? footer : sharedObj.shortContent);

      sharedObj.twitterText = twitterText + '\r\n\r\n' + (isInsight ? footer : sharedObj.shortContent);

      sharedObj.header = title;
      sharedObj.description = content + '\r\n\r\n' + link + '\r\n\r\n' + footer;
      sharedObj.descriptionEncoded = encodeURIComponent(sharedObj.description);

      sharedObj.text = sharedObj.header + '\r\n\r\n' + sharedObj.description;

      //sharedObj.hashtags = title.split(' ').join(',').replace(/[^\w\s]/gi, '');

      if ($rootScope.isApp) {
        return btBranchService.share(title, content, sharedObj);
      } else {
        //noinspection JSUnusedLocalSymbols,JSUnusedGlobalSymbols
        var sharePopup = $ionicPopup.show({
          template: $templateCache.get('popup-share-browser.html'),
          cssClass: 'popup-share',
          title: 'Sharing content',
          subTitle: 'Choose a way to share content',
          scope: sharedObj,
          buttons: [
            {
              text: 'Cancel',
              type: 'button-share',
              onTap: function () {
                sharePopup.close();
              },
            },
          ],
        });
        return sharePopup;
      }
    };

    return {
      prepareReleaseShareData: prepareReleaseShareData,
      prepareInsightShareData: prepareInsightShareData,
      prepareNewsShareData: prepareNewsShareData,
      popupMultiShare: popupMultiShare,
    };
  }
})();
