/**
 * Created by Sergey Panpurin on 5/31/2018.
 */

(function btSpeechServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btSpeechService';

  angular
    .module('ecapp')
    /**
     *  This service wrap SpeechSynthesis part of Web Speech API.
     *  By default service is mute and voice is not selected. Service initialize process looks like this:
     *
     *  waitSupportedVoices.then(function () {
     *    setDefaultVoiceByURI(selectedVoiceURI);
     *    setMuteOff();
     *  })
     *
     * @ngdoc service
     * @name btSpeechService
     * @memberOf ecapp
     */
    .factory('btSpeechService', btSpeechService);

  btSpeechService.$inject = ['$q', 'btErrorService'];

  /**
   * @typedef {Object} SpeechSynthesisVoice
   * @property {Boolean} default - A Boolean indicating whether the voice is the default voice for the current app language (true), or not (false.)
   * @property {String} lang - Returns a BCP 47 language tag indicating the language of the voice.
   * @property {Boolean} localService - A Boolean indicating whether the voice is supplied by a local speech synthesizer service (true), or a remote speech synthesizer service (false.)
   * @property {String} name - Returns a human-readable name that represents the voice.
   * @property {String} voiceURI - Returns the type of URI and location of the speech synthesis service for this voice.
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/SpeechSynthesisVoice}
   */

  /**
   *
   * @param {angular.IQService} $q
   * @param {ecapp.IErrorService} btErrorService
   * @return {ecapp.ISpeechService}
   */
  function btSpeechService($q, btErrorService) {
    if (gDebug) console.log('Running btSpeechService');

    /**
     * @namespace window.speechSynthesis
     * @property {Boolean} pending - there is a utterance id queue
     * @property {Boolean} speaking - utterance in process
     * @property {Boolean} paused - utterance is paused
     * */

    var gSynth = window.speechSynthesis;
    var gLang = 'en';
    var gVoice = null;
    var gVolume = 0.6; // from 0 to 1
    var gMute = true;
    var gHasSupportedVoices = true; // TODO it must be true for first check but logically it must be false

    var gDefaultStatus = {
      speaking: false,
      pending: false,
      paused: false,
    };

    // Resume every seconds to prevent freezing
    setInterval(resumeInfinity, 1000);

    btErrorService.registerErrorCode('BT_SUPPORTED_VOICES');

    return {
      test: test,

      cancel: cancel,
      pause: pause,
      resume: resume,

      say: say,
      pronounce: pronounce,

      setMuteOn: setMuteOn,
      setMuteOff: setMuteOff,
      setVolume: setVolume,

      getSpeechSynthesis: getSpeechSynthesis,

      setDefaultVoiceByURI: setDefaultVoiceByURI,
      waitSupportedVoices: waitSupportedVoices,
    };

    /**
     * Resume speech synthesis
     * @private
     */
    function resumeInfinity() {
      if (isSupported()) {
        gSynth.resume();
      }
    }

    /**
     * Get list of voices
     * @return {SpeechSynthesisVoice[]}
     * @private
     */
    function getVoices() {
      if (isSupported()) {
        if (gDebug) console.log('btSpeechService: is supported', gSynth.getVoices());
        return gSynth.getVoices();
      } else {
        if (gDebug) console.log('btSpeechService: is not supported');
        return [];
      }
    }

    /**
     * Cancel all utterances
     * @return {Boolean} - return true if speechSynthesis is supported
     */
    function cancel() {
      if (isSupported()) {
        gSynth.cancel();
        return true;
      } else {
        return false;
      }
    }

    /**
     * Pause utterance
     * @return {Boolean} - return true if speechSynthesis is supported
     */
    function pause() {
      if (isSupported()) {
        gSynth.pause();
        return true;
      } else {
        return false;
      }
    }

    /**
     * Resume utterance
     * @return {Boolean} - return true if speechSynthesis is supported
     */
    function resume() {
      if (isSupported()) {
        gSynth.resume();
        return true;
      } else {
        return false;
      }
    }

    /**
     * Return SpeechSynthesis object or mock object.
     * This object can be use only to reading next properties: paused, pending, speaking.
     * @return {{paused:Boolean, pending:Boolean, speaking:Boolean}} - SpeechSynthesis object
     */
    function getSpeechSynthesis() {
      if (isSupported()) {
        return gSynth;
      } else {
        return gDefaultStatus;
      }
    }

    /**
     * Check that SpeechSynthesis is supported by the browser and at least one voice is supported.
     * @return {Boolean} - SpeechSynthesis supported
     * @private
     */
    function isSupported() {
      // noinspection JSUnresolvedVariable
      return (
        window.speechSynthesis &&
        window.SpeechSynthesisUtterance &&
        typeof window.speechSynthesis === 'object' &&
        typeof window.SpeechSynthesisUtterance === 'function' &&
        gHasSupportedVoices
      );
    }

    /**
     * Get list of supported voices.
     * Return just voice this specified language.
     * @return {SpeechSynthesisVoice[]}
     * @private
     */
    function getSupportedVoices() {
      var voices = getVoices();
      if (gDebug) console.log('btSpeechService: voices =', voices);
      if (voices) {
        return voices.filter(function (voice) {
          return voice.lang.indexOf(gLang + '-') === 0;
        });
      } else {
        return [];
      }
    }

    /**
     * Set voice by index
     * @param {Number } i - voice index
     * @private
     */
    function setDefaultVoiceByIndex(i) {
      var voices = getSupportedVoices();
      if (voices[i]) {
        gVoice = voices[i];
      }
    }

    /**
     * Set voice by URI
     * @param {String} voiceURI - voice URI
     * @return {Boolean} - return true if voice is selected
     */
    function setDefaultVoiceByURI(voiceURI) {
      var voice = getVoices().filter(function (voice) {
        return voice.voiceURI === voiceURI;
      })[0];

      if (voice) {
        gVoice = voice;
        return true;
      } else {
        return false;
      }
    }

    /**
     * This function mutes speech service and cancel all utterances
     */
    function setMuteOn() {
      gMute = true;
      cancel();
    }

    /**
     * This function unmutes speech service
     */
    function setMuteOff() {
      gMute = false;
    }

    /**
     * Set default volume
     * @param {Number} value - number for 0 to 1
     */
    function setVolume(value) {
      if (value) {
        if (value < 0) {
          value = 0;
        }

        if (value > 1) {
          value = 1;
        }

        gVolume = value;
      }
    }

    /**
     * Speak the text if SpeechSynthesis is supported and isn't mute.
     * Set the parameters of the utterance and add the utterance to the utterance queue.
     * The volume will be zero if the sound is muted. If the volume is not defined, the default value will be used.
     * If voice is not defined, the first supported voice will be used (we have at least one supported voice otherwise
     *  isSupported return false).
     * @param {String} text - text to read
     * @param {Number} [volume] - volume of speech.
     * @return {Boolean} - return true if speechSynthesis is supported
     */
    function say(text, volume) {
      if (!gMute) {
        return pronounce(text, gMute ? 0 : volume);
      } else {
        return false;
      }
    }

    /**
     * This function pronounces the text if it's possible.
     *
     * It sets the parameters of the utterance and adds the utterance to the utterance queue.
     * If the volume is undefined, the default value will be used.
     * If voice is not defined, the first supported voice will be used. We should have at least one supported voice
     * otherwise speech service is not supported.
     *
     * @param {String} text - text to read
     * @param {Number} [volume] - volume of speech
     * @return {Boolean} - return true if text was pronounced
     */
    function pronounce(text, volume) {
      if (!isSupported()) return false;

      if (gVoice === null) setDefaultVoiceByIndex(0);

      if (gVoice !== null) {
        // noinspection JSUnresolvedFunction
        var utterance = new SpeechSynthesisUtterance(text);

        utterance.voice = gVoice;
        utterance.volume = volume !== undefined ? volume : gVolume;

        // utterance.onpause = function (event) {
        //   if (gDebug) console.log('Speech paused after ' + event.elapsedTime + ' milliseconds.');
        // };
        //
        // utterance.onresume = function (event) {
        //   if (gDebug) console.log('Speech resumed after ' + event.elapsedTime + ' milliseconds.');
        // };
        //
        // utterance.onstart = function (event) {
        //   if (gDebug) console.log('We have started uttering this speech: ' + event.utterance.text);
        // };

        gSynth.speak(utterance);
        return true;
      } else {
        return false;
      }
    }

    /**
     * Test service: unmute, speak 3 sentences, mute
     */
    function test() {
      setMuteOff();
      say('Hello, World! 1');
      say('Hello, World! 2');
      say('Hello, World! 3');

      setTimeout(function () {
        setMuteOn();
      }, 5000);
    }

    /**
     * Try to get list of supported voices.
     * Browser loads voices async (see window.speechSynthesis.onvoiceschanged). This function try to load voices 5 times
     *  with interval 0.5 second.
     * This function change global variable gHasSupportedVoices.
     * @return {angular.IPromise<SpeechSynthesisVoice[]>}
     */
    function waitSupportedVoices() {
      var deferred = $q.defer();
      var maxAttempt = 5;
      var curAttempt = 0;

      var interval = setInterval(checkVoices, 500);

      return deferred.promise;

      /**
       *
       */
      function checkVoices() {
        var voices = getSupportedVoices();
        if (voices.length > 0) {
          gHasSupportedVoices = true;
          clearInterval(interval);
          deferred.resolve(voices);
        } else {
          if (curAttempt >= maxAttempt) {
            clearInterval(interval);
            gHasSupportedVoices = false;

            deferred.reject(
              new btErrorService.ClientError(
                'BT_SUPPORTED_VOICES',
                'No supported voices',
                "Can't find suitable voice in your browser. Do you use chrome?"
              )
            );
          } else {
            curAttempt++;
          }
        }
      }
    }
  }
})();
