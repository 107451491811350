(function btFirstCapitalClosure() {
  'use strict';

  angular
    .module('btUtils')
    /**
     * @ngdoc filter
     * @name btFirstCapitalFilter
     * @memberOf btUtils
     * @description
     *  Change first letter to capital
     */
    .filter('btFirstCapital', [btFirstCapitalFilter]);

  /**
   *
   * @return {angular.IFilterFunction}
   */
  function btFirstCapitalFilter() {
    return function capitalizeFirstLetter(string) {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      } else {
        return '';
      }
    };
  }
})();
