/**
 * Created by XXX on XXX.
 */

(function btHorizontalCandleClosure() {
  'use strict';

  angular.module('ecapp').directive('btHorizontalCandle', directive);

  directive.$inject = ['$templateCache'];

  /**
   * Horizontal candle.
   *
   * @ngdoc directive
   * @name btHorizontalCandle
   * @memberOf ecapp
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function directive($templateCache) {
    return {
      restrict: 'E',
      template: $templateCache.get('directives/market-card/bt-horizontal-candle.html'),
      scope: {
        values: '<',
      },
      link: link,
    };
  }

  /**
   * Link function.
   *
   * @param {ecapp.IHorizontalCandleScope} scope - scope
   * @param {JQuery<HTMLElement>} el - element
   */
  function link(scope, el) {
    var elFrontBar = el.find('.front-bar');
    var elIconPosition = el.find('.icon-position');
    var elIconOpen = el.find('.icon-open');

    drawCandle(scope, scope.values, elFrontBar, elIconPosition, elIconOpen);

    scope.$watch('values', function (newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        drawCandle(scope, newValue, elFrontBar, elIconPosition, elIconOpen);
      }
    });
  }

  /**
   * Draws candle.
   *
   * @param {ecapp.IHorizontalCandleScope} scope - ?
   * @param {ecapp.IHorizontalCandle} data - ?
   * @param {JQuery<HTMLElement>} frontBar - ?
   * @param {JQuery<HTMLElement>} iconPosition - ?
   * @param {JQuery<HTMLElement>} iconOpen - ?
   */
  function drawCandle(scope, data, frontBar, iconPosition, iconOpen) {
    if (!data || !data.priceValue) return;

    // Checking if chart will be green or red
    if (data.priceValue > data.open) {
      scope.positive = true;
    } else if (data.priceValue < data.open) {
      scope.positive = false;
    }

    // Calculate width and margin for back-bar
    var relativeTotalWidth = data.high - data.low;
    scope.width = (Math.abs(data.priceValue - data.open) / relativeTotalWidth) * 100;

    if (scope.positive) {
      scope.marginLeft = ((data.open - data.low) / relativeTotalWidth) * 100;
      scope.iconMargin = scope.width + scope.marginLeft;
      scope.iconMargin2 = scope.marginLeft;
    } else if (!scope.positive) {
      scope.marginLeft = ((data.priceValue - data.low) / relativeTotalWidth) * 100;
      scope.iconMargin = scope.marginLeft;
      scope.iconMargin2 = scope.width + scope.marginLeft;
    } else {
      // data.priceValue === data.open
    }

    iconPosition.css('left', scope.iconMargin + '%');
    iconOpen.css('left', scope.iconMargin2 + '%');

    frontBar.css({
      width: scope.width + '%',
      left: scope.marginLeft + '%',
    });

    if (scope.positive) {
      frontBar.css('background-color', '#48C064');
    }

    if (!scope.positive) {
      frontBar.css('background-color', '#CF3040');
    }
  }
})();
