/**
 * Created by Sergey Panpurin on 8/18/2017.
 */

(function btEventCardMenuClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive shows event card menu
     *
     * @ngdoc directive
     * @name btEventCardMenu
     * @memberOf ecapp
     */
    .directive('btEventCardMenu', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/event-card/menu.html'),
        };
      },
    ]);
})();
