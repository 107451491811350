(function () {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive is the description directive and currently works with transclusion method, might be changed in the future since it would be irrelevant if the data will be on the row.
     *
     * @ngdoc directive
     * @name btEventModalDescription
     * @memberOf ecapp
     */
    .directive('btEventModalDescription', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/event-modal/description.html'),
          controller: [
            '$scope',
            function ($scope) {
              $scope.hasDescriptionObj = { hasDescription: false };

              // default - to show ellipsis.
              if (angular.isUndefined($scope.showEllipsis)) {
                $scope.showEllipsis = true;
              }
            },
          ],
        };
      },
    ]);
})();
