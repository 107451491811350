/**
 * Created by Sergey Panpurin on 2/21/2018.
 */

// @ts-check
(function btInitServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btInitService', btInitService);

  btInitService.$inject = [
    '$q',
    'btCalendarService',
    'btMomentService',
    'btVoiceAssistantHelperService',
    'btRiskService',
  ];

  /**
   * @ngdoc service
   * @name btInitService
   * @memberOf ecapp
   * @description
   *  This *
   * @param {angular.IQService} $q
   * @param {ecapp.ICalendarService} btCalendarService
   * @param {ecapp.IMomentService} btMomentService
   * @param {ecapp.IVoiceAssistantHelperService} btVoiceAssistantHelperService
   * @param {ecapp.IRiskService} btRiskService
   * @return {ecapp.IInitService}
   */
  function btInitService($q, btCalendarService, btMomentService, btVoiceAssistantHelperService, btRiskService) {
    void btCalendarService;
    void btMomentService;
    void btVoiceAssistantHelperService;

    console.log('Running btInitService');

    return {
      start: start,
    };

    /**
     *
     * @return {any}
     */
    function start() {
      if (window.btSettings.BT_DOMAIN === 'linkdataservice') {
        return $q.resolve();
      } else {
        return btRiskService.initialize();
      }
    }
  }
})();
