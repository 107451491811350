/**
 * Created by Sergey Panpurin on 7/9/2017.
 */

/*global twttr*/

(function btRSSControllerClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btRSSController';

  angular.module('btRss').controller('btRSSController', btRSSController);

  btRSSController.$inject = [
    '$scope',
    '$interval',
    '$window',
    '$rootScope',
    '$timeout',
    '$document',
    '$ionicHistory',
    '$state',
    'btRssFeed',
  ];

  /**
   * This is controller for news (RSS).
   *
   * @ngdoc controller
   * @name btRSSController
   * @memberOf btRss
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {angular.IIntervalService} $interval - ?
   * @param {angular.IWindowService} $window - window object service
   * @param {ecapp.ICustomRootScope} $rootScope - ?
   * @param {angular.ITimeoutService} $timeout - ?
   * @param {angular.IDocumentService} $document - ?
   * @param {ionic.IHistoryService} $ionicHistory - ionic history service
   * @param {angular.ui.IStateService} $state - ?
   * @param {ecapp.IRssFeed} btRssFeed - ?
   */
  function btRSSController(
    $scope,
    $interval,
    $window,
    $rootScope,
    $timeout,
    $document,
    $ionicHistory,
    $state,
    btRssFeed
  ) {
    console.log('Running btRSSController');

    $scope.$on('$destroy', onDestroy);
    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('$ionicView.enter', onViewEnter);
    $scope.$on('$ionicView.leave', onViewLeave);

    // default settings for rss
    $scope.maxResults = 15;
    $scope.maxRequest = 50;

    // is desktop version?
    $scope.isDesktop = !($rootScope.isMobileWeb || $window.isMobile);

    // default page
    $scope.page = $state.current.data.page;

    $scope.isLoading = { Twitter: true, RSS: true, reloadRSS: false };

    $scope.toggleGroup = toggleGroup;
    $scope.doRefresh = doRefresh;
    $scope.moreDataCanBeLoaded = moreDataCanBeLoaded;

    $scope.loadMore = loadMore;

    // setup twitter height and width
    $scope.twitterHeight = $scope.isDesktop ? '100%' : $window.innerHeight - 120;
    $scope.twitterWidth = '100%';

    activate();

    if ($scope.page === 'twitter') {
      // load twitter timeline
      var gTwitterInterval = $interval(waitForTwitter, 1000, 0, false);
    }

    // interval function handler
    var gIntervalFunc = $interval($scope.doRefresh, 30000);

    /**
     *
     */
    function activate() {
      // first init of data
      btRssFeed
        .getData($scope.maxRequest)
        .then(function (response) {
          $scope.news = response.data.feed.reverse();
          btRssFeed.prepareNews($scope.news);
        })
        .finally(function () {
          $scope.isLoading.RSS = false;
        });
    }

    /**
     *
     */
    function onDestroy() {
      if (gIntervalFunc !== null) {
        $interval.cancel(gIntervalFunc);
      }
    }

    /**
     *
     */
    function onViewLoaded() {
      onViewOpen();
    }

    /**
     *
     */
    function onViewEnter() {
      onViewOpen();

      $scope.isActive = true;
      $ionicHistory.nextViewOptions({
        disableAnimate: true,
        disableBack: true,
      });
    }

    /**
     *
     */
    function onViewOpen() {
      if ($scope.page === 'rss') {
        $timeout(function () {
          $('#bt-rss-content').focus();
        }, 1000);
      }

      if ($scope.page === 'twitter') {
        $timeout(function () {
          $('#bt-twitter-content').focus();
        }, 1000);
      }
    }

    /**
     *
     */
    function onViewLeave() {
      $scope.isActive = false;
    }

    /**
     * Show or hide group
     * @param {*} group - ?
     */
    function toggleGroup(group) {
      if ($scope.shownGroup === group) {
        $scope.shownGroup = null;
      } else {
        $scope.shownGroup = group;
      }
    }

    /**
     * Refresh rss
     */
    function doRefresh() {
      if (gDebug) console.log(gPrefix, 'Interval was called (doRefresh)');

      $scope.isLoading.reloadRSS = true;
      btRssFeed
        .getData($scope.maxResults)
        .then(function (response) {
          var news = response.data.feed.reverse();

          $scope.news = btRssFeed.merge($scope.news, news);

          btRssFeed.prepareNews($scope.news);
        })
        .finally(function () {
          $scope.isLoading.reloadRSS = false;
          $scope.$broadcast('scroll.refreshComplete');
        });
    }

    /**
     * Have more rss news to show?
     * @return {Boolean}
     */
    function moreDataCanBeLoaded() {
      if ($scope.news) return $scope.news.length > $scope.maxResults && $scope.maxResults < $scope.maxRequest;
      else return false;
    }

    /**
     * Show more data
     */
    function loadMore() {
      if ($scope.news.length > $scope.maxResults && $scope.maxResults < $scope.maxRequest) {
        $scope.maxResults += 10;
      }
    }

    /**
     *
     */
    function waitForTwitter() {
      if (gDebug) console.log(gPrefix, 'Interval was called (waitForTwitter)');
      // waiting for twitter api

      /**
       * @namespace twttr
       * @property {object} widgets - ?
       * @property {*} widgets.createTimeline - ?
       */

      if (!!window.twttr && !!window.twttr.widgets) {
        if ($('#my-twitter-timeline').find('iframe').first().length === 0) {
          // create timeline and show result after
          twttr.widgets
            .createTimeline(
              {
                sourceType: 'url',
                url: 'https://twitter.com/BetterTraderPro/lists/bettertrader-rss',
              },
              document.getElementById('my-twitter-timeline'),
              {
                theme: 'dark',
                height: $scope.twitterHeight,
                width: $scope.twitterWidth,
                chrome: 'nofooter noscrollbar noheader noborders',
              }
            )
            .then(function () {
              $('#my-twitter-timeline')
                .find('iframe')
                .first()
                .contents()
                .find('.timeline-Viewport')
                .css('-webkit-overflow-scrolling', 'touch');
              $scope.isLoading.Twitter = false;
            });
        }

        $interval.cancel(gTwitterInterval);
      }
    }
  }
})();
