/**
 * Created by Eyal on 6/6/2016.
 */

// @ts-check
(function btRowProcessorServiceClosure() {
  'use strict';

  angular
    .module('ecapp')
    .factory('btRowProcessorService', [
      'btStrengthService',
      'btDateService',
      'btAudioService',
      'btTimeSupervisionService',
      'btDelegateMethodsService',
      'btImportanceFilter',
      btRowProcessorService,
    ]);

  /**
   * @ngdoc service
   * @name btRowProcessorService
   * @memberOf ecapp
   * @description
   *  This factory process release data from database to prepare them to be used in economical calendar.
   *  Credit for guy for the awesome name and idea
   * @param {ecapp.IStrengthService} btStrengthService
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IAudioService} btAudioService
   * @param {ecapp.ITimeSupervisionService} btTimeSupervisionService
   * @param {ecapp.IDelegateMethodsService} btDelegateMethodsService
   * @param {ecapp.IImportanceFilter} btImportanceFilter
   * @return {ecapp.IRowProcessorService} service
   */
  function btRowProcessorService(
    btStrengthService,
    btDateService,
    btAudioService,
    btTimeSupervisionService,
    btDelegateMethodsService,
    btImportanceFilter
  ) {
    console.log('Running btRowProcessorService');

    /**
     * This function plays strength sounds, currently not in use but might be in the near future.
     *
     * @ngdoc method
     * @name playStrengthSound
     * @memberOf ecapp.btRowProcessorService
     * @param {String} rowStrengthStatus - (const)
     */
    function playStrengthSound(rowStrengthStatus) {
      switch (rowStrengthStatus) {
        case 'Buy':
          btTimeSupervisionService(btAudioService.playStrongerSound, null, 'PLAY_STRENGTH', 0.5);
          break;
        case 'Sell':
          btTimeSupervisionService(btAudioService.playWeakerSound, null, 'PLAY_STRENGTH', 0.5);
          break;
        default:
          // nothingStatus/AsExpected
          btTimeSupervisionService(btAudioService.playAsExpSound, null, 'PLAY_STRENGTH', 0.5);
          break;
      }
    }

    /**
     * This function process release strength.
     *
     * Note: This function change row data
     *
     * Properties in use: pRelease.time, pRelease.releaseStrength, pRelease.previous, pRelease.revision,
     *  pRelease.revisionStrength
     * Properties to add: pRelease.strengthPercentage, pRelease.strengthRevisionPercentage
     * Properties in use (can be removed): pRelease.eventId, pRelease.id
     *
     * @ngdoc method
     * @name rowStrengthProcessor
     * @memberOf ecapp.btRowProcessorService
     * @param {btRelease} pRelease - (changeable)
     */
    function rowStrengthProcessor(pRelease) {
      //We call the strength percentage service in order to process the strength so it will be easier to work with
      if (pRelease.releaseStrength && pRelease.releaseStrength) {
        pRelease.strengthPercentage = btStrengthService.prepareStrength(pRelease.releaseStrength.value, pRelease.time);
      } else {
        pRelease.strengthPercentage = null;
      }

      // revision strength calculation, used to be inside of the condition if (pRelease.releaseStrength != null), but in
      // some places it can be shown without a release strength
      // noinspection EqualityComparisonWithCoercionJS
      if (pRelease.previous != pRelease.revision) {
        // noinspection EqualityComparisonWithCoercionJS
        if (pRelease.revisionStrength != null) {
          if (Math.abs(pRelease.revisionStrength.value) <= 0.1) {
            pRelease.revisionStrength.value = pRelease.revisionStrength.value > 0 ? 0.11 : -0.11;
          }
          pRelease.strengthRevisionPercentage = btStrengthService.prepareStrength(
            pRelease.revisionStrength.value,
            pRelease.time
          );
        } else {
          if (pRelease.eventsInfo) {
            pRelease.strengthRevisionPercentage = btStrengthService.calculateRevisionStrength(
              pRelease.previous,
              pRelease.revision,
              pRelease.eventsInfo.direction
            );
            console.warn("There isn't revision strength for event ID:" + pRelease.eventId + ', row ID:' + pRelease.id);
          } else {
            pRelease.strengthRevisionPercentage = null;
          }
        }
      }
    }

    /**
     * Set class for just released event
     *
     * Note: Change row data
     *
     * Properties in use: pRelease.time, pRelease.strengthPercentage
     * Properties to change: pRelease.class
     * Properties in use (can be removed): pRelease.eventId, pRelease.id
     *
     * @ngdoc method
     * @name _rowClassProcessor
     * @memberOf ecapp.btRowProcessorService
     * @param {btRelease} pRelease - (changeable)
     * @param {Boolean} isFromPusher
     */
    function _rowClassProcessor(pRelease, isFromPusher) {
      // in time in the row is between the pastTime and nowTime ==> nowTime

      // the way to color from pusher and color it works good nowTime but if we'll change the interval method, it might
      // won't work
      if (btDateService.isRowInJustReleased(pRelease) || (isFromPusher && btDateService.isRowInNextHot(pRelease))) {
        if (pRelease.strengthPercentage !== null) {
          if (pRelease.strengthPercentage.status === 'Sell') {
            pRelease.class = 'eventCardJustNegative';
          } else if (pRelease.strengthPercentage.status === 'Buy') {
            pRelease.class = 'eventCardJustPositive';
          } else {
            pRelease.class = 'eventCardJustAsExpected';
          }
        } else {
          // this is when there is no strength, so we put the eventCardJustAsExpected just so it'll have colors, but
          // there is no status yet
          pRelease.class = 'eventCardJustAsExpected';
          console.log('Release without strength', pRelease);
        }
      }
    }

    /**
     * This function processes historic rows in order to get relevant data from them (used in historic table and
     * chart).
     *
     * Note: Change row data
     *
     * @ngdoc method
     * @name historicRowProcessor
     * @memberOf ecapp.btRowProcessorService
     * @param {btRelease} row - (changeable)
     */
    function historicRowProcessor(row) {
      rowStrengthProcessor(row);

      row.historicDate = btDateService.getFormattedDateFromRow(row) + '';

      //if there is no strength
      // noinspection EqualityComparisonWithCoercionJS
      if (row.strengthPercentage == null) return;

      switch (row.strengthPercentage.status) {
        case 'Buy':
          row.historicStatusClass = 'positive';
          row.historicData = '+' + row.strengthPercentage.value + '%';
          break;
        case 'Sell':
          row.historicStatusClass = 'negative';
          row.historicData = '-' + row.strengthPercentage.value + '%';
          break;
        default:
          row.historicStatusClass = 'neutral';
          row.historicData = row.strengthPercentage.value + '%';
          break;
      }
    }

    /**
     * Now this function add fields required to economical calendar to release row.
     *
     * Supported release classes: eventCardPast, eventCardNext, eventCardNextHot, eventCardFuture,
     * eventCardJustNegative, eventCardJustPositive, eventCardJustAsExpected
     * TODO: Need to be refactored
     *
     * @ngdoc method
     * @name prepareRow
     * @memberOf ecapp.btRowProcessorService
     * @param {btRelease} review - release row data
     * @param {Date} reviewDate - release data
     * @param {Boolean} isFutureHot -
     * @param {Number} futureClose - time in seconds
     * @param {Number} past - time in ms
     * @param {Number} futureHot - time in ms
     * @param {Number} now - time in ms
     * @return {Object} converted data {isFutureHot: *, futureClose: *}
     */
    function prepareRow(review, reviewDate, isFutureHot, futureClose, past, futureHot, now) {
      // eslint-disable-line complexity
      review.show = true;
      review.canRemoveWatchers = false;

      // Itay addition, to change forecast from NA to usually no exp
      // this is written like that (with == true instead of nothing) because we first need to cast it to boolean
      if (review.eventsInfo.usuallyNoForecast === true) {
        review.forecast = 'Usually No Exp';
      }

      // holds the importance after converted using the conversion service
      review.importanceConvert = btImportanceFilter(review.eventsInfo.importance);

      // build the clock
      review.clock = btDateService.getClockText(reviewDate);

      var reviewTimeMS = reviewDate.getTime();
      // in this section we assign the event class (past / next (future) hot / next / (far) future)
      // NEED TO KNOW: far future appears only when future hot appear, and if there is no future hot, we will only have
      // next.
      if (reviewTimeMS <= past) {
        review.class = 'eventCardPast';

        if (review.actual && review.actual !== null && review.actual !== 'NA') {
          review.canRemoveWatchers = true;
        }
      }

      // after future hot ==> next, appears only if there are no future hots.
      // if we doesn't have future hot, just mark row as next
      if (reviewTimeMS >= futureHot && !isFutureHot) {
        isFutureHot = true;
        futureClose = review.time;
        review.class = 'eventCardNext';
      }

      _mainProcessorService(review, false);

      review.isReleased = reviewTimeMS <= now || review.actual !== 'NA';
      review.isJustReleased = false;

      // smaller than future hot and higher than now ==> nextHot
      if (reviewTimeMS <= futureHot && reviewTimeMS >= now) {
        // maybe should be saved as boolean flag
        isFutureHot = true;

        // this condition was added in order to call the coloring classes
        if (review.strengthPercentage === null) {
          // this condition is making the next hot sound when required. if first checks if review.class is
          // undefined (which avoids sounds when refresh site), and then checks if row's class wasn't hot
          // before the update
          // noinspection EqualityComparisonWithCoercionJS
          if (review.class !== undefined && review.class !== 'eventCardNextHot') {
            btTimeSupervisionService(btAudioService.playHotEventSound, null, 'PLAY_AUDIO', 1);
            btTimeSupervisionService(
              btDelegateMethodsService.getDelegateMethod('SCROLL-TO-LATEST-ROW'),
              null,
              'SCROLL',
              1
            );
          }
          review.class = 'eventCardNextHot';
        }
      }

      // mark first next event
      review.isFirstNext = false;

      if (reviewTimeMS >= now && gFirstNext) {
        review.isFirstNext = true;
        gFirstNext = false;
      }

      // if it's higher than future hot, and future close equals to row time
      if (reviewTimeMS >= futureHot && isFutureHot && futureClose !== undefined && futureClose === review.time) {
        review.class = 'eventCardNext';
        if (gFirstNext) {
          review.isFirstNext = true;
          gFirstNext = false;
        }
      }

      // noinspection EqualityComparisonWithCoercionJS
      if (
        reviewTimeMS >= futureHot &&
        isFutureHot &&
        ((futureClose !== undefined && futureClose !== review.time) || futureClose == undefined)
      ) {
        review.class = 'eventCardFuture';
      }

      if (review.eventsInfo.eventType === 2) {
        review.convertTextTime = 'live <i class="ion-volume-medium"></i>';
      } else {
        review.convertTextTime = btDateService.convertTextTime(review.class, reviewDate);
      }

      review.heightSize = 280;

      prepareTradingInsights(review, reviewTimeMS >= now);

      return { isFutureHot: isFutureHot, futureClose: futureClose };
    }

    /**
     * Prepare trading insights
     * @param {btRelease} pRelease - release object
     * @param {Boolean} isFutureRelease - is future release
     */
    function prepareTradingInsights(pRelease, isFutureRelease) {
      // add type to all trading insights
      if (Array.isArray(pRelease.tradingInsights)) {
        pRelease.tradingInsights.forEach(function (t) {
          t.kind = 'release';
        });
      }

      if (Array.isArray(pRelease.expectedTradingInsights)) {
        pRelease.expectedTradingInsights.forEach(function () {
          //skip
        });
      }

      // number of trading insights
      pRelease.numTradingInsights = pRelease.tradingInsights ? pRelease.tradingInsights.length : 0;
      // noinspection EqualityComparisonWithCoercionJS
      pRelease.showTradingInsights =
        pRelease.showTradingInsights !== undefined ? pRelease.showTradingInsights : pRelease.numTradingInsights > 0;

      // number of expected trading insights
      pRelease.numExpectedTradingInsights = pRelease.expectedTradingInsights
        ? pRelease.expectedTradingInsights.length
        : 0;
      // noinspection EqualityComparisonWithCoercionJS
      pRelease.showExpectedTradingInsights =
        pRelease.showExpectedTradingInsights !== undefined
          ? pRelease.showExpectedTradingInsights
          : pRelease.numTradingInsights == 0 && isFutureRelease && pRelease.numExpectedTradingInsights > 0;
    }

    var gFirstNext = true;

    /**
     * Now this function prepare data for economical calendar. It received information about releases from database
     * and convert it to format on economical calendar: add classes, add day grouping and "no event" boxes.
     *
     * @ngdoc method
     * @name prepareRowsArray
     * @memberOf ecapp.btRowProcessorService
     * @param {btRelease[]} reviews - (changeable) releases' data from database
     * @param {Date} date1 - (const) start date
     * @param {Date} date2 - (const) end date
     * @return {btRelease[]} converted data
     */
    function prepareRowsArray(reviews, date1, date2) {
      // setting is future hot to 0 (if it's boolean it might should be false
      // after some condition will be set to 1 to the end of function
      var isFutureHot = false;

      // assigning future close var in order to keep the future hot time
      // this var exists in order to put many hots if required (this appears only when are are a few hot events that
      // are in the same time.
      var futureClose;

      // past - this variable holds the past period (compared to the time now), which is always 5 minutes back, in
      // milliseconds
      // now - this variable holds the current time, in milliseconds
      // futureHot - this variable holds the future-hot period (compared to the time now), which is always 5 minutes
      // forward, in milliseconds
      var delta = btDateService.deltaMinMS(5);
      var now = btDateService.getNowDate().getTime();
      var past = now - delta;
      var futureHot = now + delta;

      // arrayFinish if probably the array we return after the modification *NOT SURE*
      // var arrayFinish = [];

      // find days borders
      var dayBorders = [];
      var dayBordersText = [];
      var prevDay = null;
      gFirstNext = true;

      var noTime = 0;

      // console.log('TEST: reviews', reviews.length);
      // console.log('TEST:', reviews[0].time, reviews[reviews.length - 1].time);

      // split the array of releases by days and prepare release data
      reviews.forEach(function forEachReviewsFunc(review, i) {
        // skip releases without time
        if (review.time !== undefined) {
          var reviewDate = btDateService.getDateFromRow(review);

          // new day
          var dayText = btDateService.getFullFormattedDate(reviewDate);
          if (prevDay !== dayText) {
            prevDay = dayText;
            dayBorders.push(i);
            dayBordersText.push(dayText);
          }

          var res = prepareRow(review, reviewDate, isFutureHot, futureClose, past, futureHot, now);
          isFutureHot = res.isFutureHot;
          futureClose = res.futureClose;

          review.counter = review.counter === undefined ? 0 : review.counter + 1;
          // arrayFinish.push(review);
        } else {
          noTime++;
        }
      });
      dayBorders.push(reviews.length);

      // console.log('TEST: no time', noTime);
      // console.log('TEST: borders', dayBorders);
      // console.log('TEST: borders text', dayBordersText);

      // number of processing days
      var days = btDateService.daysBetween(date1, date2);

      // console.log('>>> Days:', days);

      // array of calendar cards
      var cards = [];
      for (var ind = 0; ind < days; ind++) {
        // var dateCore = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate() + ind);
        var dateCore = btDateService.nDaysAgo(date1, -ind);
        var dateText = btDateService.getFullFormattedDate(dateCore);
        var inData = dayBordersText.indexOf(dateText);

        dateText = btDateService.getDayName(dateCore);

        // console.log('>>> Day:', dateText);

        // summary holds dateText as in groupDay, summary *NOT SURE* and height size (without the accordion).
        var summary = {
          id: (dateText + '-separator').toLowerCase(),
          groupDay: dateText,
          summary: 'RowsService',
          heightSize: 10,
        };

        if (inData === -1) {
          cards.push(summary);
          cards.push({
            id: (dateText + '-no-events').toLowerCase(),
            box: { data: 'No events for this day!', placeholder: 'NoEventsForThisDayAtAll' },
            heightSize: 60,
          });
        } else {
          cards.push(summary);
          var subArray = reviews.slice(dayBorders[inData], dayBorders[inData + 1]);
          cards = cards.concat(subArray);

          if (
            ind === 1 &&
            dateText === 'Today' &&
            subArray[subArray.length - 1].class !== undefined &&
            subArray[subArray.length - 1].class === 'eventCardPast'
          ) {
            cards.push({
              id: (dateText + '-no-more').toLowerCase(),
              box: { data: 'No more events for today', placeholder: 'NoMoreEventsForToday' },
              heightSize: 60,
            });
          }
        }
      }

      return cards;
    }

    /**
     * Main Processor Service.
     *
     * Note: Change row data
     *
     * @ngdoc method
     * @name _mainProcessorService
     * @memberOf ecapp.btRowProcessorService
     * @param {btRelease} row - (changeable)
     * @param {Boolean} isFromPusher - (changeable)
     */
    function _mainProcessorService(row, isFromPusher) {
      // strengthProcessor should always be called before classProcessor because classProcessor relies on it's results.
      rowStrengthProcessor(row);
      _rowClassProcessor(row, isFromPusher);
    }

    /**
     * This function return object which can identify release for state manager
     *
     * @ngdoc method
     * @name getReleaseIdData
     * @memberOf ecapp.btRowProcessorService
     * @param {btRelease} row - (const) release data
     * @param {*} eventsInfo
     * @return {btReleaseIdData} release identification data
     */
    function getReleaseIdData(row, eventsInfo) {
      var UTCArray = btDateService.getUTCArrayFromRow(row);
      return {
        currency: eventsInfo.currency.toLowerCase(),
        name: eventsInfo.name.toLowerCase().replace(/ /g, '_'),
        year: UTCArray[0],
        month: UTCArray[1],
        day: UTCArray[2],
        hours: UTCArray[3],
        minutes: UTCArray[4],
      };
    }

    /**
     * This function convert release identification data in format useful to database request
     *
     * @ngdoc method
     * @name releaseIdDataToRequest
     * @memberOf ecapp.btRowProcessorService
     * @param {btReleaseIdData} data - (const) release identification data
     * @return {{name: string, currency: string, time: number}} - data to find release in database
     */
    function releaseIdDataToRequest(data) {
      var UTCArray = [data.year, data.month, data.day, data.hours, data.minutes];
      return {
        currency: data.currency.toUpperCase(),
        name: data.name.replace(/_/g, ' '),
        time: btDateService.getUnixFromUTCArray(UTCArray),
      };
    }

    /**
     * Handle insights from pusher
     *
     * @ngdoc method
     * @name handleRows
     * @memberOf ecapp.btRowProcessorService
     * @param {Object} item - data from pusher
     * @param {btRelease} pRelease - review data
     */
    function handleRows(item, pRelease) {
      // check pusher data and do some actions
      var isNewActual = hasNewActual(item, pRelease);
      var isNewRevision = hasNewRevision(item, pRelease);

      pRelease.isJustReleased = true;

      if (isNewActual) {
        pRelease.showExpectedTradingInsights = false;
      }

      for (var kk in item) {
        if (item.hasOwnProperty(kk)) {
          pRelease[kk] = item[kk];
        }
      }

      if (pRelease.show) {
        if (isNewActual || isNewRevision) {
          btTimeSupervisionService(btAudioService.playNewActualOrRevision, null, 'PLAY_RELEASE', 2 / 60);
        }

        // convert row data to ecapp format
        _mainProcessorService(pRelease, true);
      }
    }

    /**
     *
     * @param {*} item
     * @param {*} pRelease
     * @return {any}
     */
    function hasNewActual(item, pRelease) {
      return pRelease['actual'] === 'NA' && item['actual'] !== undefined && item['actual'] !== 'NA';
    }

    /**
     *
     * @param {*} item
     * @param {*} pRelease
     * @return {any}
     */
    function hasNewRevision(item, pRelease) {
      void pRelease;
      return item['revision'] !== undefined && item['revision'] !== 'NA' && item['revision'] !== item['previous'];
    }

    /**
     * Reset filter
     * @param {*} release
     */
    function resetFilter(release) {
      release.show = false;
    }

    /**
     * This function filters release according to user settings.
     * @param {Number} priority - minimal event importance
     * @param {String[]} currencies - allowed currencies of events
     * @param {Number[]} following - identifiers of events, followed by the user
     * @param {btRelease} release - release object
     * @return {any}
     */
    function regularFilter(priority, currencies, following, release) {
      if (release.skipFilter) return true;

      if (release.eventsInfo) {
        return (
          (release.eventsInfo.importance >= priority && currencies.indexOf(release.eventsInfo.currency) !== -1) ||
          following.indexOf(release.eventId) > -1
        );
      } else {
        return false;
      }
    }

    /**
     * This function filters release according to user watchlist.
     * @param {String[]} watchlist - bt names of instruments from watchlist
     * @param {btRelease} release - release object
     * @return {any}
     */
    function watchlistFilter(watchlist, release) {
      if (release.skipFilter) return true;

      var passed = false;

      if (release.eventsInfo) {
        if (release.tradingInsights && release.tradingInsights.length > 0) {
          release.tradingInsights.forEach(function (idea) {
            if (watchlist.indexOf(idea.market) !== -1) passed = true;
          });
        } else if (
          release.actual === 'NA' &&
          release.expectedTradingInsights &&
          release.expectedTradingInsights.length > 0
        ) {
          release.expectedTradingInsights.forEach(function (idea) {
            if (watchlist.indexOf(idea.market) !== -1) passed = true;
          });
        }
      }

      return passed;
    }

    /**
     * Wrap prepareRow for review page
     * @param {*} row
     */
    function prepareOneRow(row) {
      var reviewDate = btDateService.getDateFromRow(row);

      var delta = btDateService.deltaMinMS(5);
      var now = btDateService.getNowDate().getTime();
      var past = now - delta;
      var futureHot = now + delta;

      var isFutureHot = false;

      prepareRow(row, reviewDate, isFutureHot, now, past, futureHot, now);
    }

    /**
     *
     * @param {*} strength
     * @param {*} time
     * @return {any}
     */
    function prepareStrength(strength, time) {
      return btStrengthService.prepareStrength(strength, time);
    }

    return {
      rowStrengthProcessor: rowStrengthProcessor,
      historicRowProcessor: historicRowProcessor,
      prepareRowsArray: prepareRowsArray,
      getReleaseIdData: getReleaseIdData,
      releaseIdDataToRequest: releaseIdDataToRequest,
      handleRows: handleRows,
      hasNewActual: hasNewActual,
      hasNewRevision: hasNewRevision,
      prepareOneRow: prepareOneRow,
      playStrengthSound: playStrengthSound,
      resetFilter: resetFilter,
      regularFilter: regularFilter,
      watchlistFilter: watchlistFilter,
      prepareStrength: prepareStrength,
    };
  }
})();
