/**
 * Created by BTsergey on 5/15/2017.
 */

(function btBrokerWarningClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive just display select broker warning.
     *
     * @ngdoc directive
     * @name btBrokerWarning
     * @memberOf ecapp
     */
    .directive('btBrokerWarning', [
      '$templateCache',
      '$state',
      '$ionicHistory',
      function ($templateCache, $state, $ionicHistory) {
        return {
          restrict: 'E',
          transclude: true,
          scope: null,
          template: $templateCache.get('directives/broker/bt-broker-warning.html'),
          controller: [
            '$scope',
            function ($scope) {
              $ionicHistory.nextViewOptions({
                disableBack: true,
              });

              $scope.redirect = function () {
                console.log('BrokerWarning: try to open ecapp.app.broker');
                $state.go('ecapp.app.broker');
              };
            },
          ],
        };
      },
    ]);
})();
