/**
 * Created by Sergey Panpurin on 2/3/19
 */

(function btHourTradesClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     *  This directive displays time interval.
     *  This directive can be used to display any interval.
     *
     *        open     close
     *  |------|||||||||||-------|
     *  start                  end
     *
     * @ngdoc directive
     * @name btHourTrades
     * @memberOf ecapp
     * @restrict E
     * @scope
     * @param {String} hour - interval title
     * @param {*} stats - open value (beginning of interval)
     */
    .directive('btHourTrades', btHourTrades);

  btHourTrades.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btHourTrades($templateCache) {
    return {
      restrict: 'E',
      scope: {
        hour: '@',
        stats: '<',
      },
      // template: $templateCache.get('components/mobile/bt-trading-sessions/bt-hour-trades.html'),
      template:
        '<div class="bt-trade-hour-stats">' +
        '<div class="bt-trade-hour-win" ng-style="vm.winStyle"><div class="bt-trade-hour-label">{{vm.win}}</div></div>' +
        '<div class="bt-trade-hour-loss" ng-style="vm.lossStyle"><div class="bt-trade-hour-label">{{vm.loss}}</div></div>' +
        '<div class="bt-trade-hour-weak" ng-style="vm.weakStyle"><div class="bt-trade-hour-label">{{vm.weak}}</div></div>' +
        '</div>',
      controller: btHourTradesController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btHourTradesController.$inject = [];

  /**
   *
   */
  function btHourTradesController() {
    /*jshint validthis: true*/
    var vm = this;

    vm.$onInit = onInit;
    vm.$onChanges = onChanges;
    vm.$onDestroy = onDestroy;

    /**
     *
     */
    function onInit() {
      vm.winStyle = { height: '0' };
      vm.lossStyle = { height: '0' };
      vm.weakStyle = { height: '0' };

      updateStatistics();
    }

    /**
     *
     * @param {*} changes
     */
    function onChanges(changes) {
      if (changes.stats) {
        updateStatistics();
      }
    }

    /**
     *
     */
    function onDestroy() {}

    /**
     *
     */
    function updateStatistics() {
      var hour = parseInt(vm.hour);

      if (vm.stats && vm.stats[hour]) {
        vm.win = vm.stats[hour].win > 0 ? vm.stats[hour].win : '';
        vm.loss = vm.stats[hour].loss > 0 ? vm.stats[hour].loss : '';
        vm.weak = vm.stats[hour].weak > 0 ? vm.stats[hour].weak : '';
        vm.winStyle = { height: ((vm.stats[hour].win / vm.stats[hour].total) * 100).toFixed(2) + '%' };
        vm.lossStyle = { height: ((vm.stats[hour].loss / vm.stats[hour].total) * 100).toFixed(2) + '%' };
        vm.weakStyle = { height: ((vm.stats[hour].weak / vm.stats[hour].total) * 100).toFixed(2) + '%' };
      } else {
        vm.win = '';
        vm.loss = '';
        vm.weak = '';
        vm.winStyle = { height: '0', display: 'none' };
        vm.lossStyle = { height: '0', display: 'none' };
        vm.weakStyle = { height: '0', display: 'none' };
      }
    }
  }
})();
