/**
 * Created by Stas on 03/01/20.
 */

(function btSegmentedLineSetting() {
  'use strict';

  angular.module('ecapp').directive('btSegmentedLine', directive);

  directive.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function directive($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('directives/common/bt-segmented-line.html'),
      controller: controller,
    };
  }

  controller.$inject = ['$scope', '$attrs'];

  /**
   *
   * @param {*} $scope
   * @param {angular.IAttributes} $attrs
   */
  function controller($scope, $attrs) {
    $scope.whole = parseInt($attrs.whole);
    $scope.segments = [];

    for (var i = 0; i < $scope.whole; i++) {
      $scope.segments.push({ id: i });
    }
  }
})();
