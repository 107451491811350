/**
 * Created by Sergey on 8/7/2017.
 */

(function btSimpleChartClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btSimpleChart:';

  /**
   * This directive do something.
   *
   * @ngdoc directive
   * @name btSimpleChart
   * @memberOf ecapp
   */
  angular.module('ecapp').directive('btSimpleChart', btSimpleChart);

  btSimpleChart.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {any}
   */
  function btSimpleChart($templateCache) {
    return {
      restrict: 'E',
      scope: {
        chartValues: '<',
      },
      template: $templateCache.get('directives/instruments/simple-chart.html'),
      controller: btSimpleChartController,
    };
  }

  btSimpleChartController.$inject = [
    '$q',
    '$scope',
    '$rootScope',
    '$interval',
    'btChartService',
    'btTradingService',
    'btLevelsService',
    'btSettingsService',
    'btShareScopeService',
    'btToastrService',
    'btRestrictionService',
  ];

  /**
   *
   * @param {angular.IQService} $q - promise interface
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.IChartService} btChartService
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.ILevelsService} btLevelsService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IRestrictionService} btRestrictionService
   */
  function btSimpleChartController(
    $q,
    $scope,
    $rootScope,
    $interval,
    btChartService,
    btTradingService,
    btLevelsService,
    btSettingsService,
    btShareScopeService,
    btToastrService,
    btRestrictionService
  ) {
    if (gDebug) console.log(gPrefix, 'Initializing...');

    $scope.isChartReady = false;
    $scope.idChart = 'bt-chart-' + Math.floor(Math.random() * 100000);
    $scope.chartData = {};

    prepareChartData();

    /**
     * This function prepares new chart data.
     */
    function prepareChartData() {
      if ($scope.chartValues && $scope.chartValues.prices) {
        /** @type {btInstrumentChartValues} */
        var range = btChartService.getBound($scope.chartValues.prices);
        $scope.chartData = btChartService.buildInstrumentChart(range, $scope.chartValues, 'day', 'n/a', [], false);
        $scope.isChartReady = true;
      }
    }

    // var gInterval = null;
    // var gInstrument = $scope.instrument;
    // var gChartInitialData = null;
    // var gIsMockUpdates = false;
    //
    // $scope.isLdsGrade = $scope.instrument.instrument.provider === 'lds';
    //
    // $scope.hasChartError = false;
    // $scope.chartErrorMessage = '';
    //
    // $scope.hasLevelError = false;
    // $scope.levelErrorMessage = '';
    //
    // /** @type {btChartLevel[]} */
    // $scope.levels = [];
    // $scope.levelsTime = null;
    //
    // $scope.idChart = validId($scope.instrument.instrument);
    //
    // $scope.$watch('instrument.instrument.month', onPeriodUpdated);
    //
    // var gLocalSettings = btSettingsService.getLocalSettings();
    // var gLevelsSettings = {};
    //
    // /**
    //  *
    //  * @param {Instrument} instrument - instrument
    //  * @return {string}
    //  */
    // function validId(instrument) {
    //   return instrument.brokerSymbol.toLowerCase().replace(/\//g, 'vs').replace(/@/g, 'at').replace(/ /g, '_') + Math.floor((Math.random() * 1000) + 1).toString();
    // }
    //
    // /**
    //  * Supported level providers
    //  * @type {btLevelsProvider[]}
    //  */
    // $scope.levelProviders = btLevelsService.getProviders();
    //
    // /**
    //  * Selected level provider
    //  * @type {btLevelsProvider}
    //  */
    // $scope.levelProvider = btLevelsService.getProviderById('bt-oanda');
    //
    // /** @type {btSimpleOption[]} */
    // $scope.granularityOptions = [
    //   {id: 'M1', name: '1m'},
    //   {id: 'M5', name: '5m'},
    //   {id: 'M15', name: '15m'},
    //   {id: 'H1', name: '1h'}
    // ];
    //
    // /** @type {btSimpleOption[]} */
    // $scope.levelOptions = [
    //   {id: 'hide', name: 'Hide Levels'},
    //   {id: 'show', name: 'Show Levels'}
    // ];
    //
    // /** @type {btSimpleOption[]} */
    // $scope.voiceOptions = [
    //   {id: 'none', name: 'No Voice'},
    //   {id: 'cross', name: 'Cross Voice'},
    //   {id: 'close', name: 'Close Voice'},
    //   {id: 'all', name: 'All Voice'}
    // ];
    //
    // /** @type {Record<string,btSimpleOption>} */
    // $scope.selected = {
    //   granularity: loadGranularityOption(),
    //   level: loadLevelOption(),
    //   voice: loadVoiceOption()
    // };
    //
    // if ($rootScope.lastInstrument != null && $rootScope.lastInstrument !== gInstrument) {
    //   $rootScope.lastInstrument.openCharts = false;
    // }
    //
    // $rootScope.lastInstrument = gInstrument;
    //
    // $scope.lastPrice = null;
    //
    // // Begin
    // $scope.pastLevels = btLevelsService.getPastLevels([]);
    // if ($scope.isLdsGrade) {
    //   $scope.pastLevels = $scope.pastLevels.slice(1);
    // }
    // $scope.closeLevels = btLevelsService.getCloseLevels();
    // // End
    //
    // $scope.onGranularityOptionChange = onGranularityChange;
    // $scope.onLevelOptionChange = onLevelOptionChange;
    // $scope.onVoiceOptionChange = onVoiceOptionChange;
    // $scope.hasLevelsFeature = hasLevelsFeature;
    // $scope.upgradeLevelsFeature = upgradeLevelsFeature;
    //
    // $scope.hasCloseLevels = !btSettingsService.isLinkDataService();
    // $scope.hasPastLevels = true;
    //
    // $scope.$on('$destroy', onDestroy);
    //
    // activate();
    //
    // /**
    //  * @typedef {object} btInstrumentChartValues
    //  * @property {Array} prices - [time, [o, h, l, c]]
    //  * @property {Array} volumes - [time, v]
    //  */
    //
    // /**
    //  * This function activate controller.
    //  */
    // function activate() {
    //   btShareScopeService.wait().then(updateLevelsSettings);
    //
    //   if ($scope.instrument.instrument.brokerSymbol) {
    //     updateChart();
    //     gInterval = $interval(updateChartInterval, 5000);
    //   } else {
    //
    //   }
    // }
    //
    // function onPeriodUpdated(newValue, oldValue) {
    //   if (newValue && newValue !== oldValue) {
    //     updateChart();
    //   }
    // }
    //
    // /**
    //  * This function reacts on scope destroy event.
    //  */
    // function onDestroy() {
    //   console.log('Destroy instrument');
    //   if (gInterval) $interval.cancel(gInterval);
    // }
    //
    // /**
    //  * This function updates levels settings.
    //  */
    // function updateLevelsSettings() {
    //   gLevelsSettings = btShareScopeService.getUserSettings('levels', {});
    //   $scope.selected.voice = loadVoiceOption();
    //   $scope.instrument.instrument.levelVoice = $scope.selected.voice.id;
    // }
    //
    // /**
    //  * This function updates chart every 5 seconds.
    //  */
    // function updateChartInterval() {
    //   if (gDebug) console.log('Interval was called (getChartDataInterval)');
    //   if (gInterval) updateChart();
    // }
    //
    // /**
    //  * This function updates chart.
    //  *
    //  * @return {angular.IPromise<boolean>}
    //  */
    // function updateChart() {
    //   return getChartData()
    //     .then(getLastLevels)
    //     .then(updateChartData)
    //     .catch(handleUnexpectedError);
    // }
    //
    // /**
    //  * This function gets chart data.
    //  *
    //  * @return {angular.IPromise<btInstrumentChartValues>}
    //  */
    // function getChartData() {
    //   $scope.hasChartError = false;
    //
    //   var symbol = $scope.instrument.instrument.brokerSymbol;
    //
    //   // use test data on Sunday (first time use regular way to initialize data)
    //   if (!gIsMockUpdates || gChartInitialData == null) {
    //     if ($scope.instrument.instrument.month) {
    //       return btTradingService.getLastCandlesData(symbol + ':' + $scope.instrument.instrument.month, $scope.selected.granularity.id)
    //         .then(convertCandles)
    //         .catch(handleChartError);
    //     } else {
    //       return btTradingService.getLastCandlesData(symbol, $scope.selected.granularity.id)
    //         .then(convertCandles)
    //         .catch(handleChartError);
    //     }
    //   } else {
    //     return $q.resolve(runMockUpdate(gChartInitialData));
    //   }
    // }
    //
    // /**
    //  * This function converts candles to different format.
    //  *
    //  * @param {{candles: Array}} data - market data
    //  * @return {btInstrumentChartValues}
    //  */
    // function convertCandles(data) {
    //   var chartValues = {'prices': [], 'volumes': []};
    //   if (data.candles) {
    //     $scope.lastPrice = data.candles[data.candles.length - 1].mid.c;
    //
    //     data.candles.forEach(function (candle) {
    //       var time = candle.time * 1000;
    //       chartValues.prices.push([time, [parseFloat(candle.mid.o), parseFloat(candle.mid.h), parseFloat(candle.mid.l), parseFloat(candle.mid.c)]]);
    //       chartValues.volumes.push([time, candle.volume]);
    //     });
    //
    //     gChartInitialData = chartValues;
    //   }
    //   return chartValues;
    // }
    //
    // /**
    //  * This function handles market data loading errors.
    //  *
    //  * @param {Error} error - error object
    //  */
    // function handleChartError(error) {
    //   console.error(error);
    //   $scope.hasChartError = true;
    //   $scope.chartErrorMessage = error.message;
    //
    //   if (gInterval) $interval.cancel(gInterval);
    //
    //   return $q.reject(error);
    // }
    //
    // /**
    //  * This function prepares new chart data.
    //  * @param {Array} data - market data and levels
    //  * @return {boolean}
    //  */
    // function updateChartData(data) {
    //   /** @type {btInstrumentChartValues} */
    //   var chartValues = data[0];
    //   /** @type {btChartLevel[]} */
    //   var levels = ($scope.selected.level.id === 'show') ? data[1] : [];
    //   var broker = btTradingService.getBrokerInfo().name;
    //   var range = btChartService.getBound(chartValues.prices);
    //
    //   if ($scope.isLdsGrade) {
    //     $scope.chartData = btChartService.buildInstrumentChart(range, chartValues, 'day', broker, levels, false);
    //   } else {
    //     $scope.chartData = btChartService.buildInstrumentChart(range, chartValues, 'minute', broker, levels, true);
    //   }
    //   return true;
    // }
    //
    // /**
    //  * This function gets last levels for the instrument.
    //  *
    //  * @return {angular.IPromise<btChartLevel[]>}
    //  */
    // function getLastLevels(data) {
    //   if ($scope.instrument.instrument.provider === 'lds') {
    //     return getLDSLastLevels(data);
    //   } else {
    //     return getOANDALastLevels(data);
    //   }
    // }
    //
    // /**
    //  * This function gets last levels for the instrument.
    //  *
    //  * @return {angular.IPromise<btChartLevel[]>}
    //  */
    // function getOANDALastLevels(data) {
    //   var price = parseFloat($scope.lastPrice);
    //   return btLevelsService.getLastLevels($scope.levelProvider.id, $scope.instrument.instrument, price)
    //     .then(function (data) {
    //       if (data.error) {
    //         $scope.hasLevelError = true;
    //         $scope.levelErrorMessage = data.error.message;
    //       } else {
    //         $scope.hasLevelError = false;
    //       }
    //       $scope.levels = data.levels;
    //       $scope.levelsTime = data.levels[0] ? data.levels[0].date : null;
    //
    //       $scope.pastLevels = btLevelsService.getPastLevels(data.levels);
    //       $scope.closeLevels = btLevelsService.updateCloseLevels($scope.closeLevels, data.levels, price);
    //
    //       return data.levels;
    //     })
    //     .catch(handleLevelsError)
    //     .then(function (levels) {
    //       return [data, levels];
    //     });
    // }
    //
    // /**
    //  * This function gets last levels for the instrument.
    //  *
    //  * @return {angular.IPromise<btChartLevel[]>}
    //  */
    // function getLDSLastLevels(data) {
    //   var price = parseFloat($scope.lastPrice);
    //   return btLevelsService.getLdsLevels($scope.instrument.instrument, price)
    //     .then(function (data) {
    //       if (data.error) {
    //         $scope.hasLevelError = true;
    //         $scope.levelErrorMessage = data.error.message;
    //       } else {
    //         $scope.hasLevelError = false;
    //       }
    //       $scope.levels = data.levels;
    //       $scope.levelsTime = data.levels[0] ? data.levels[0].date : null;
    //
    //       $scope.pastLevels = btLevelsService.getPastLevels(data.levels);
    //       $scope.pastLevels = $scope.pastLevels.slice(1);
    //       // $scope.closeLevels = btLevelsService.updateCloseLevels($scope.closeLevels, data.levels, price);
    //
    //       return data.levels;
    //     })
    //     .catch(handleLevelsError)
    //     .then(function (levels) {
    //       return [data, levels];
    //     });
    // }
    //
    // /**
    //  * This function handles levels loading errors.
    //  *
    //  * @param {Error} error - error object
    //  */
    // function handleLevelsError(error) {
    //   $scope.hasLevelError = true;
    //   $scope.levelErrorMessage = error.message;
    //
    //   return [];
    // }
    //
    // /**
    //  * This function handles unexpected errors.
    //  *
    //  * @param {Error} error - error object
    //  */
    // function handleUnexpectedError(error) {
    //   console.error(error);
    // }
    //
    //
    // /**
    //  * This function modifies market data to simulate eal-time updates.
    //  *
    //  * @param {object} chartValues - test data object
    //  * @return {btInstrumentChartValues}
    //  */
    // function runMockUpdate(chartValues) {
    //   var candle = chartValues.prices.shift();
    //
    //   var c = 1.0001;
    //
    //   var timeDiff = chartValues.prices[1][0] - chartValues.prices[0][0];
    //   var last = chartValues.prices[chartValues.prices.length - 1];
    //   candle[0] = last[0] + timeDiff;
    //   candle[1][0] = last[1][0] * c;
    //   candle[1][1] = last[1][1] * c;
    //   candle[1][2] = last[1][2] * c;
    //   candle[1][3] = last[1][3] * c;
    //   chartValues.prices.push(candle);
    //
    //   var volume = chartValues.volumes.shift();
    //   last = chartValues.volumes[chartValues.volumes.length - 1];
    //   volume[0] = last[0] + timeDiff;
    //   volume[1] = last[1] * c;
    //   chartValues.volumes.push(volume);
    //
    //   return chartValues;
    // }
    //
    // /**
    //  * This function reacts on granularity change.
    //  */
    // function onGranularityChange() {
    //   gLocalSettings.set('markets.interval', $scope.selected.granularity.id);
    //   $scope.chartData = null;
    //   updateChart();
    // }
    //
    // /**
    //  *
    //  * @return {btSimpleOption}
    //  */
    // function loadGranularityOption() {
    //   return loadOption($scope.granularityOptions, gLocalSettings.get('markets.interval'), 1);
    // }
    //
    // /**
    //  *
    //  */
    // function onLevelOptionChange() {
    //   if (hasLevelsFeature()) {
    //     gLocalSettings.set('markets.levels', $scope.selected.level.id);
    //     $scope.chartData = null;
    //     updateChart();
    //   } else {
    //     $scope.selected.level = $scope.levelOptions[0];
    //     upgradeLevelsFeature();
    //   }
    // }
    //
    // /**
    //  *
    //  * @return {btSimpleOption}
    //  */
    // function loadLevelOption() {
    //   if (hasLevelsFeature()) {
    //     return loadOption($scope.levelOptions, gLocalSettings.get('markets.levels'), 1);
    //   } else {
    //     return $scope.levelOptions[0];
    //   }
    // }
    //
    // /**
    //  * This function reacts on changes of levels settings.
    //  */
    // function onVoiceOptionChange() {
    //   if (hasLevelsFeature()) {
    //     gLevelsSettings[$scope.instrument.instrument.OandaSymbol] = $scope.selected.voice.id;
    //     $scope.instrument.instrument.levelVoice = $scope.selected.voice.id;
    //     btShareScopeService.saveUserSettings('levels', gLevelsSettings)
    //       .then(function () {
    //         $rootScope.$broadcast('levels:settings', gLevelsSettings);
    //         btToastrService.success('Level settings were changed to ' + $scope.selected.voice.name, $scope.instrument.instrument.displayName);
    //       })
    //       .catch(function (reason) {
    //         btToastrService.error(reason.message || 'Unknown error.', 'System');
    //       });
    //   } else {
    //     $scope.selected.voice = $scope.voiceOptions[0];
    //     upgradeLevelsFeature();
    //   }
    // }
    //
    // /**
    //  * This function loads voice option for instrument.
    //  *
    //  * @return {btSimpleOption}
    //  */
    // function loadVoiceOption() {
    //   if (hasLevelsFeature()) {
    //     return loadOption($scope.voiceOptions, gLevelsSettings[$scope.instrument.instrument.OandaSymbol], 0);
    //   } else {
    //     return $scope.voiceOptions[0];
    //   }
    // }
    //
    // /**
    //  * This function load option.
    //  *
    //  * @param {btSimpleOption[]} options - options
    //  * @param {string} id - identifier
    //  * @param {number} initial - initial index
    //  * @return {btSimpleOption}
    //  */
    // function loadOption(options, id, initial) {
    //   if (id) {
    //     var option = options.filter(function (option) {
    //       return option.id === id;
    //     })[0];
    //
    //     if (option) return option;
    //   }
    //   return options[initial];
    // }
    //
    // /**
    //  *
    //  * @return {boolean}
    //  */
    // function hasLevelsFeature() {
    //   return btRestrictionService.hasFeature('levels');
    // }
    //
    //
    // /**
    //  *
    //  * @return {angular.IPromise<string>}
    //  */
    // function upgradeLevelsFeature() {
    //   return btRestrictionService.showUpgradePopup('levels');
    // }
  }
})();
