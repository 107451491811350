/**
 * Created by Itay on 6/14/2016.
 */

(function btRevisionClassesClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive checks if the current $scope.row in scope revision is active, and if it does it adds the classes on the value
     * @ngdoc directive
     * @name btRevisionClasses
     * @memberOf ecapp
     */
    .directive('btRevisionClasses', [
      function () {
        // Note: this directive creates two-way-binding and a new scope, might be inefficient and should work for a better
        // solution
        return {
          restrict: 'A',
          scope: {
            rowName: '@row',
            btRevisionClasses: '@',
          },
          controller: [
            '$scope',
            'btDateService',
            function ($scope, btDateService) {
              // By calling $scope.row this way we save many unnecessary watchers
              $scope.row = $scope.$parent[$scope.rowName];

              // this checks if the $scope.row is the past, because it's not required to watch when the $scope.row is in the
              // past
              $scope.isRowInPast = btDateService.isRowInPast($scope.row);
            },
          ],
          link: function (scope, element) {
            /**
             *
             * @param {*} element
             * @param {*} classesToAdd
             */
            function updateClasses(element, classesToAdd) {
              if (scope.row.previous != scope.row.revision) {
                angular.element(element).addClass(classesToAdd);
              }
            }

            // this function is being called once when the link loads, and every time that it changes, and that's why it
            // appears twice in the code
            updateClasses(element, scope.btRevisionClasses);

            if (!scope.isRowInPast || (scope.isRowInPast && scope.row.actual === 'NA')) {
              scope.$parent.$watch(scope.rowName + '.revision', function () {
                updateClasses(element, scope.btRevisionClasses);
              });
            }
          },
        };
      },
    ]);
})();
