/**
 * Created by Stas on 3/29/20
 */

/* global moment */

(function btHourlyView() {
  'use strict';

  angular.module('ecapp').directive('btHourlyView', directive);

  directive.$inject = ['$templateCache'];

  /**
   * This directive displays ???.
   *
   * @ngdoc directive
   * @name btHourlyView
   * @memberOf ecapp
   * @restrict E
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function directive($templateCache) {
    return {
      restrict: 'E',
      scope: {
        stats: '<',
        change: '<',
      },
      template: $templateCache.get('components/mobile/bt-trading-sessions/bt-hourly-view.html'),
      controller: controller,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  controller.$inject = ['$scope', 'btDateService'];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.IDateService} btDateService - ?
   */
  function controller($scope, btDateService) {
    var vm = this;
    var gMaxValue;

    vm.graphChange = graphChange;
    vm.isNow = isNow;
    vm.typeChange = typeChange;
    vm.getBarStyle = getBarStyle;
    vm.getMarkerStyle = getMarkerStyle;
    vm.getBarTooltip = getBarTooltip;
    vm.getMarkerTooltip = getMarkerTooltip;
    // vm.getCurrentValue = getCurrentValue;
    vm.changeTitle = changeTitle;
    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;
    vm.$onChanges = onChanges;

    // Weekdays
    $scope.days = {
      options: [
        { id: 0, name: 'Sunday' },
        { id: 1, name: 'Monday' },
        { id: 2, name: 'Tuesday' },
        { id: 3, name: 'Wednesday' },
        { id: 4, name: 'Thursday' },
        { id: 5, name: 'Friday' },
        { id: 6, name: 'Saturday' },
      ],
      selected: null,
    };

    $scope.days.selected = $scope.days.options[0];

    // Slides
    $scope.graphs = {
      options: [
        'positive',
        'negative', //,
        // 'range'
      ],
      selected: 'positive',
    };

    $scope.graphs.selected = $scope.graphs.options[2];

    // Time ticks
    $scope.timeGradation = {
      hours: ['00', '06', '12', '18', '24'],
      minutes: '00',
    };

    vm.YGridLines = [];

    /**
     *
     * @return {Array}
     */
    function getDays() {
      if (!vm.stats) {
        vm.stats = new Array(168 - 30).fill({
          ratio: [0, 0, 0],
          positive: 0,
          negative: 0,
          range: 0,
        });
      }

      // First element represent 17:00 - 18:00 EST
      var firstIndex = 17;
      var localTimeOffset = btDateService.getUserTimeZoneOffset();
      var estTimeOffset = moment().tz('America/New_York').utcOffset();
      var hours = Math.floor((localTimeOffset - estTimeOffset) / 60);
      var minutes = Math.abs((localTimeOffset - estTimeOffset) % 60);

      if (minutes < 10) $scope.timeGradation.minutes = '0' + minutes;
      else $scope.timeGradation.minutes = minutes.toString(10);

      firstIndex += hours;
      var endingLength = 168 - firstIndex - vm.stats.length;
      var weekHours = new Array(firstIndex)
        .fill({
          ratio: [0, 0, 0],
          positive: 0,
          negative: 0,
          range: 0,
        })
        .concat(
          vm.stats,
          new Array(endingLength).fill({
            ratio: [0, 0, 0],
            positive: 0,
            negative: 0,
            range: 0,
          })
        );

      //Change null values to 0
      for (var i = 0; i < weekHours.length; i++) {
        if (weekHours[i]) {
          if (typeof weekHours[i].positive !== 'number') weekHours[i].positive = 0;
          if (typeof weekHours[i].negative !== 'number') weekHours[i].negative = 0;
          if (typeof weekHours[i].range !== 'number') weekHours[i].range = 0;
        } else {
          weekHours[i] = {
            ratio: [0, 0, 0],
            positive: 0,
            negative: 0,
            range: 0,
          };
        }
      }

      return [
        weekHours.slice(0, 24),
        weekHours.slice(24, 48),
        weekHours.slice(48, 72),
        weekHours.slice(72, 96),
        weekHours.slice(96, 120),
        weekHours.slice(120, 144),
        weekHours.slice(144, 168),
      ];
    }

    /**
     *
     */
    function selectCurrentDay() {
      var weekday = btDateService.getCurrentDay();
      $scope.days.selected = $scope.days.options.filter(function (value) {
        return value.id === weekday;
      })[0];
    }

    /**
     *
     */
    function selectCurrentGraph() {
      if (vm.change < 0) {
        $scope.graphs.selected = 'negative';
      } else {
        $scope.graphs.selected = 'positive';
      }
    }

    /**
     *
     * @param {number} index - bar index
     * @return {boolean}
     */
    function isNow(index) {
      return $scope.days.selected.id === btDateService.getCurrentDay() && index === btDateService.getCurrentHours();
    }

    /**
     *
     */
    function graphChange() {
      var max = 0;
      switch ($scope.graphs.selected) {
        case 'positive':
          vm.dayHours[$scope.days.selected.id].forEach(function (value) {
            if (value.positive > max) max = value.positive;
          });
          break;
        case 'negative':
          vm.dayHours[$scope.days.selected.id].forEach(function (value) {
            if (value.negative < max) max = value.negative;
          });
          break;
        case 'range':
          vm.dayHours[$scope.days.selected.id].forEach(function (value) {
            if (value.range > max) max = value.range;
          });
          break;
      }
      gMaxValue = max;

      vm.YGridLines = getYGridLines(max);
    }

    /**
     *
     * @param {*} type
     */
    function typeChange(type) {
      $scope.graphs.selected = type;
      graphChange();
    }

    /**
     *
     * @param {*} index
     * @param {*} values
     * @return {{"background-color": string, color: string, width: string, height: string}}
     */
    function getBarStyle(index, values) {
      if (
        !values ||
        typeof values.positive !== 'number' ||
        typeof values.positive !== 'number' ||
        typeof values.positive !== 'number'
      )
        return { width: '100%', height: '0%', 'background-color': '#748595', color: '#748595' };

      var percents = 0;
      var backColor = '';
      switch ($scope.graphs.selected) {
        case 'positive':
          percents = (values.positive * 100) / gMaxValue;
          backColor = '#4DC068';
          break;
        case 'negative':
          percents = (values.negative * 100) / gMaxValue;
          backColor = '#CD2C43';
          break;
        case 'range':
          percents = (values.range * 100) / gMaxValue;
          backColor = '#748595';
          break;
      }

      if (isNow(index)) backColor = '#035FBA';

      return { width: '100%', height: percents + '%', 'background-color': backColor, color: backColor };
    }

    /**
     *
     * @return {{"background-color": string, bottom: string}}
     */
    function getMarkerStyle() {
      var backColor = '#748595';

      if (vm.change === undefined) {
        return { 'background-color': backColor, bottom: '0' };
      } else {
        if (vm.change > 0) backColor = '#4DC068';
        if (vm.change < 0) backColor = '#CD2C43';

        var bottomDistance = Math.abs((vm.change * 100) / gMaxValue);
        if (bottomDistance > 120) {
          bottomDistance = 120;
          backColor = '#FFFF00';
        }

        return { 'background-color': backColor, bottom: 'calc(' + bottomDistance + '% - 1px)' };
      }
    }

    /**
     * This function returns tooltip for bar.
     *
     * @param {number} index - bar index
     * @param {object} values - bar values
     * @return {string} - bar tooltip
     */
    function getBarTooltip(index, values) {
      if (!vm.isReady) return 'Loading...';

      if (
        !values ||
        typeof values.positive !== 'number' ||
        typeof values.positive !== 'number' ||
        typeof values.positive !== 'number'
      )
        return 'Preparing...';

      var tooltipText =
        index +
        ':' +
        $scope.timeGradation.minutes +
        '-' +
        ((index + 1) % 24) +
        ':' +
        $scope.timeGradation.minutes +
        '\n';
      switch ($scope.graphs.selected) {
        case 'positive':
          tooltipText += 'Positive change: ' + values.positive.toFixed(2) + '%';
          break;
        case 'negative':
          tooltipText += 'Negative change: ' + Math.abs(values.negative).toFixed(2) + '%';
          break;
        case 'range':
          tooltipText += 'Change range: ' + values.range.toFixed(2) + '%';
          break;
      }
      return tooltipText;
    }

    /**
     *
     * @return {string}
     */
    function getMarkerTooltip() {
      return 'Current change: ' + (vm.change !== undefined ? Math.abs(vm.change).toFixed(2) + '%' : 'N/A');
    }

    // function getCurrentValue(index, values) {
    //   var value = '';
    //   if (($scope.days.selected.id === btDateService.getCurrentDay()) && (index === btDateService.getCurrentHours()))
    //     switch ($scope.graphs.selected) {
    //       case 'positive':
    //         value = values.positive.toFixed(2);
    //         break;
    //       case 'negative':
    //         value = values.negative.toFixed(2);
    //         break;
    //       case 'range':
    //         value = values.range.toFixed(2);
    //         break;
    //     }
    //   return value;
    // }

    /**
     *
     * @return {string}
     */
    function changeTitle() {
      var titleText = '';
      switch ($scope.graphs.selected) {
        case 'positive':
          titleText = 'Average Positive Change:';
          break;
        case 'negative':
          titleText = 'Average Negative Change:';
          break;
        case 'range':
          titleText = 'Average Change Range:';
          break;
      }
      return titleText;
    }

    /**
     * This function returns Y grid lines.
     *
     * @param {number} max - maximum value
     * @return {Array}
     */
    function getYGridLines(max) {
      max = Math.abs(max);
      if (max > 0.001) {
        var value = Math.floor(max * 10) / 10;
        if (value === 0) value = Math.floor(max * 100) / 100;
        if (value === 0) return [];

        var height = Math.floor((value / max) * 80);
        if (height > 70) {
          return [
            { id: 1, value: value, style: { height: height + '%' } },
            { id: 2, value: value / 2, style: { height: height / 2 + '%' } },
          ];
        } else {
          return [{ id: 1, value: value, style: { height: Math.floor((value / max) * 80) + '%' } }];
        }
      } else {
        return [];
      }
    }

    /**
     *
     */
    function onInit() {
      vm.isReady = false;
      vm.dayHours = getDays();
      vm.isReady = true;
      selectCurrentDay();
      selectCurrentGraph();
      graphChange();
    }

    /**
     *
     * @param {*} changes - ?
     */
    function onChanges(changes) {
      void changes;
    }

    /**
     *
     */
    function onDestroy() {}
  }
})();
