/**
 * Created by edwin on 7/24/17.
 */

/*global branch, Branch*/

/**
 * window.Branch - Mobile SDK (branch-cordova-sdk@4.2.4 via npm)
 * window.branch - Web SDK (branch-sdk@2.24.3 via bower)
 */

// @ts-check
(function btBranchServiceClosure() {
  'use strict';

  angular.module('branch.io').factory('btBranchService', btBranchService);

  btBranchService.$inject = [
    '$ionicPlatform',
    '$ionicLoading',
    '$q',
    '$window',
    '$timeout',
    '$state',
    'btSettings',
    'btEventEmitterService',
    '$rootScope',
    'btDevService',
  ];

  /**
   *
   * @param {ionic.IPlatformService} $ionicPlatform - ionic platform service
   * @param {ionic.ILoadingService} $ionicLoading - ionic loading service
   * @param {angular.IQService} $q - promise interface
   * @param {angular.IWindowService} $window - window object service
   * @param {angular.ITimeoutService} $timeout - timeout service
   * @param {angular.ui.IStateService} $state - ?
   * @param {ecapp.ISettings} btSettings - ?
   * @param {ecapp.IEventEmitterService} btEventEmitterService - ?
   * @param {ecapp.ICustomRootScope} $rootScope - ?
   * @param {ecapp.IDevService} btDevService - ?
   * @return {ecapp.IBranchService}
   */
  function btBranchService(
    $ionicPlatform,
    $ionicLoading,
    $q,
    $window,
    $timeout,
    $state,
    btSettings,
    btEventEmitterService,
    $rootScope,
    btDevService
  ) {
    console.log('Running btBranchService');

    var isInitialized = false;
    var initializationPromises = [];
    var identityId = null;
    var previous = null;

    // DeepLink Routing Configuration
    var routesDeepLink = {
      'app/broker': {
        target: 'ecapp.app.broker',
        parent: 'ecapp.app.broker',
      },
      'social-login': {
        target: 'ecapp.user.social-login',
        parent: 'ecapp.user.social-login',
      },
      'app/payments': {
        target: 'ecapp.app.payments',
        parent: 'ecapp.app.payments',
      },
      'app/main/reviews': {
        target: 'ecapp.app.main.reviews',
        parent: 'ecapp.app.main.reviews',
      },
      'app/main/insights': {
        target: 'ecapp.app.main.insights',
        parent: 'ecapp.app.main.insights',
      },
      'app/main/markets': {
        target: 'ecapp.app.main.markets',
        parent: 'ecapp.app.main.markets',
      },
      'app/main/trade-ideas': {
        target: 'ecapp.app.main.trade-ideas',
        parent: 'ecapp.app.main.trade-ideas',
      },
      'app/main/risk-monitor': {
        target: 'ecapp.app.main.risk-monitor',
        parent: 'ecapp.app.main.risk-monitor',
      },
      'app/main/barometer': {
        target: 'ecapp.app.main.barometer',
        parent: 'ecapp.app.main.barometer',
      },
      'app/main/markets/{broker}/{symbol}': {
        target: 'ecapp.app.main.instrument-page',
        parent: 'ecapp.app.main.markets',
      },
      'app/invite': {
        target: 'ecapp.app.invite',
        parent: 'ecapp.app.invite',
      },
      'app/main/reviews/:currency/:name/:utc/:year/:month/:day/:hours/:minutes': {
        target: 'ecapp.app.main.detail',
        parent: 'ecapp.app.main.reviews',
      },
      'app/notifications/userevents': {
        target: 'ecapp.app.notifications.userevents',
        parent: 'ecapp.app.notifications.userevents',
      },
      'app/settings/currencies': {
        target: 'ecapp.app.calendar-settings.currencies',
        parent: 'ecapp.app.calendar-settings.currencies',
      },
    };

    // Testing
    if (window.isDevelopment) {
      console.log('good', _handleRoutes('https://app.bettertrader.co/#/app/broker'));
      console.log('good', _handleRoutes('https://app.bettertrader.co/#/app/payments'));
      console.log('good', _handleRoutes('https://app.bettertrader.co/#/app/main/reviews'));
      console.log('good', _handleRoutes('https://app.bettertrader.co/#/app/main/insights'));
      console.log('good', _handleRoutes('https://app.bettertrader.co/#/app/main/markets'));
      console.log('good', _handleRoutes('https://app.bettertrader.co/#/app/notifications/userevents'));
      console.log('good', _handleRoutes('https://app.bettertrader.co/#/app/settings/currencies'));
      console.log(
        'good',
        _handleRoutes(
          'https://app.bettertrader.co/#/app/main/reviews/eur/german_industrial_production_(mom)/utc/2017/08/07/06/00'
        )
      );
      console.log(
        'good',
        _handleRoutes(
          'https://app.bettertrader.co/#/app/main/reviews/gbp/halifax_house_price_index_(mom)/utc/2017/08/07/07/30'
        )
      );

      console.log('bad', _handleRoutes(''));
      console.log('bad', _handleRoutes('https://app.bettertrader.co/#/autologin'));
      console.log('bad', _handleRoutes('https://app.bettertrader.co/#/app/balance'));
      console.log('bad', _handleRoutes('asdf'));
      console.log('bad', _handleRoutes('asdf#:s'));
    }

    btEventEmitterService.addListener('login:success', function onLoginSuccess(data) {
      void data;

      if (!isEnabled()) return;

      try {
        console.log('Branch on login:success');
      } catch (e) {
        console.error(e);
      }
    });

    btEventEmitterService.addListener('logout:success', function onLogoutSuccess(data) {
      void data;

      if (!isEnabled()) return;

      try {
        logout().catch(function (error) {
          console.error(error);
        });
      } catch (e) {
        console.error(e);
      }
    });

    return {
      isEnabled: isEnabled,
      initialize: initialize,
      setIdentity: setIdentity,
      getIdentityId: getIdentityId,
      // logout: logout,
      loadRewards: loadRewards,
      creditHistory: creditHistory,
      getFirstReferringParams: getFirstReferringParams,
      getLatestReferringParams: getLatestReferringParams,
      createLink: createLink,
      getReferralLink: getReferralLink,
      userCompletedAction: userCompletedAction,
      share: share,
      sendSMS: sendSMS,
    };

    /**
     * Returns true if Branch.io enabled and token defined.
     * @return {boolean} - whether branch.io enabled
     */
    function isEnabled() {
      return !!btSettings.BT_BRANCH_TOKEN;
    }

    /**
     *
     * @param {string} url - url
     * @return {*}
     */
    function _handleRoutes(url) {
      var match = _matchUrl(url, routesDeepLink);
      if (window.isDevelopment) {
        return match;
      } else {
        _handleMatch(match);
      }
    }

    /**
     *
     * @param {string} url - url
     * @param {*} routes - routes
     * @return {*}
     */
    function _matchUrl(url, routes) {
      // take fragment of url after hash tag
      var match = url.match(/#\/(.*)/);

      // if link without hash tag return null
      if (match === null) {
        return null;
      }

      // return description for this fragment or null
      var path = match[1];
      for (var route in routes) {
        if (routes.hasOwnProperty(route)) {
          var res = _routeMatch(route, path);
          if (res !== false) {
            var routeData = routes[route];
            routeData.args = res;
            return routeData;
          }
        }
      }

      return null;
    }

    /**
     *
     * @param {string} route - deeplink route
     * @param {string} path - url
     * @return {*}
     */
    function _routeMatch(route, path) {
      var parts = path.split('/');
      var routeParts = route.split('/');

      // Our aggregated route params that matched our route path.
      // This is used for things like /post/:id
      var routeParams = {};

      if (parts.length !== routeParts.length) {
        // Can't possibly match if the lengths are different
        return false;
      }

      // Otherwise, we need to check each part

      var rp, pp;
      for (var i in parts) {
        pp = parts[i];
        rp = routeParts[i];

        if (rp[0] === ':') {
          // We have a route param, store it in our
          // route params without the colon
          routeParams[rp.slice(1)] = pp;
        } else if (pp !== rp) {
          return false;
        }
      }
      return routeParams;
    }

    /**
     * Try to open deep link
     * @param {{target: String, parent: String, args: Object}} match - route object
     * @private
     */
    function _handleMatch(match) {
      if (match !== null) {
        console.log('Branch.io - Process deep link via branch.io!');
        // Timeouts can be tweaked to customize the feel of the deeplink
        if (match.parent !== match.target) {
          $timeout(goToParent, 100, false);
        } else {
          $timeout(goToChild, 800, false);
        }
      } else {
        console.warn('Branch.io - No match');
      }

      /**
       *
       */
      function goToParent() {
        console.log('Branch.io: try to open ' + match.parent);
        $timeout(goToChild, 800, false);
        $state.go(match.parent);
      }

      /**
       *
       */
      function goToChild() {
        console.log('Branch.io: try to open ' + match.target + '(' + match.args + ')');
        if (match.target === 'ecapp.app.main.detail') {
          $rootScope.pushRelease = true;
        }
        $state.go(match.target, match.args);
      }
    }

    /**
     * Initialize Branch SDK (Ionic SDK or Web SDK)
     * @return {angular.IPromise<Object>}
     */
    function initialize() {
      if (!isEnabled()) return $q.resolve({});

      // in application initialize Ionic SDK, otherwise initialize Web SDK
      if ($window.isApp) {
        // register branch initialization function on start
        $ionicPlatform.on('deviceready', function onDeviceReady() {
          console.log('Device Ready: Initialize branch');
          _initIonicSDK().then(_onInitSuccess).catch(_onInitFail);
        });

        // register branch initialization function on resume
        $ionicPlatform.on('resume', function onResume() {
          console.log('Device Resume: Initialize branch');
          _initIonicSDK().then(_onInitSuccess).catch(_onInitFail);
        });

        return $q.resolve({});
      } else {
        return _initWebSDK().then(_onInitSuccess).catch(_onInitFail);
      }
    }

    /**
     * Fire on initialization success
     * Run all functions waiting for initialization
     * @param {object} data - initialization data
     * @private
     */
    function _onInitSuccess(data) {
      isInitialized = true;
      console.log('Branch.io - initialization onSuccess: ', data);
      console.log('Branch.io - initializationPromises: ', initializationPromises.length);

      if (initializationPromises.length !== 0) {
        initializationPromises.forEach(function resolvePromises(item) {
          item.resolve(data);
        });
        initializationPromises = [];
      }
    }

    /**
     * Fire on initialization fail
     * Run all functions waiting for initialization
     * @param {Error|Object} error
     * @private
     */
    function _onInitFail(error) {
      isInitialized = true;
      console.log('Branch.io - initialization onFail: ', error);
      console.log('Branch.io - initializationPromises: ', initializationPromises.length);

      if (initializationPromises.length !== 0) {
        initializationPromises.forEach(function rejectPromises(item) {
          item.reject(error);
        });
        initializationPromises = [];
      }
    }

    /**
     * Branch Web SDK initialization
     * @return {angular.IPromise<Object>}
     * @private
     */
    function _initWebSDK() {
      var deferred = $q.defer();

      if (window.branch) {
        branch.init(btSettings.BT_BRANCH_TOKEN, {}, function onBranchInitialization(err, data) {
          console.log('Branch.io - Error:', err);
          console.log('Branch.io - Deep Link Data', data);
          deferred.resolve(data);
        });
      } else {
        console.error('Branch.io Web SDK is not installed!');
        deferred.reject(new Error('Branch.io Web SDK is not installed!'));
      }

      return deferred.promise;
    }

    /**
     * Branch Ionic SDK initialization
     * @return {angular.IPromise<Object>}
     * @private
     */
    function _initIonicSDK() {
      if (window.Branch) {
        // for development and debugging only
        // Branch.setLogging(false);

        // Branch initialization
        return Branch.initSession()
          .then(function (params) {
            _initIonicSDKCallback(params);
            _afterIonicSDKInitialization(params);
          })
          .catch(function onFail(err) {
            console.error('Branch.io - Error: ', err);
            return $q.reject(err);
          });
      } else {
        console.error('Branch.io Ionic SDK is not installed!');
        return $q.reject(new Error('Branch.io Ionic SDK is not installed!'));
      }
    }

    /**
     * Ionic SDK initialization callback
     * Try to open deep link. Save previous open state.
     * @param {object} data - Branch initialization data
     * @private
     */
    function _initIonicSDKCallback(data) {
      btDevService.alert('Init callback ' + JSON.stringify(data));
      // read deep link data on click
      console.log('Branch.io - Deep Link Data: ', data);
      if (data.hasOwnProperty('+non_branch_link')) {
        if (previous !== data['+non_branch_link']) {
          _handleRoutes(data['+non_branch_link']);
          previous = data['+non_branch_link'];
        }
      } else if (data.hasOwnProperty('deeplinkState')) {
        _handleDeepLink(data);
      }
    }

    /**
     * Handle deep link
     * @param {object} data - Branch initialization data
     * @private
     */
    function _handleDeepLink(data) {
      var params = data.deeplinkParams || {};
      try {
        if (typeof params === 'string') {
          params = JSON.parse(params.replace(/\\"/g, '"'));
        }
      } catch (e) {
        console.log("Branch.io - Can't get params");
      }

      if (data.deeplinkState) {
        console.log('Branch.io - Deep link detected. State = ' + data.deeplinkState + ', parameters = ', params);
        $timeout(
          function waitDeeplink() {
            console.log('Branch.io - try to open ' + data.deeplinkState + '(' + params + ')');
            $state.go(data.deeplinkState, params);
          },
          0,
          false
        );
      }
    }

    /**
     * Run after Branch Ionic SDK initialization
     * @param {object} res - Branch initialization data
     * @return {object} Branch initialization data
     * @private
     */
    function _afterIonicSDKInitialization(res) {
      btDevService.alert('Init then ' + JSON.stringify(res));

      // for better Android matching
      Branch.setCookieBasedMatching(btSettings.BT_BRANCH_LINK);

      // save first session information
      if (res['+is_first_session']) {
        window.localStorage.setItem('isFirstInstall', 'true');
      }

      console.log('Branch.io - Response: ', res);

      return res;
    }

    /**
     * Wait for Branch initialization
     * @param {ecapp.ICallback} callback - function to call
     * @private
     */
    function _wait(callback) {
      if (isInitialized) {
        callback();
      } else {
        var deferred = $q.defer();

        initializationPromises.push(deferred);

        deferred.promise.then(function () {
          callback();
        });
      }
    }

    /**
     * This function checks for first session (user response ans cache)
     * @param {object} res - branch response object
     * @return {boolean} is first session
     * @private
     */
    function _isNewInstall(res) {
      var cache = window.localStorage.getItem('isFirstInstall') || 'false';
      return !!(res['+is_first_session'] || cache === 'true');
    }

    /**
     * Set user identity
     * Save firstInstall flag to local storage, track 'app-install' or 'web-open' event
     * @param {string} userId - user id
     */
    function setIdentity(userId) {
      if (!isEnabled()) return;

      _wait(function () {
        console.log('Branch.io: setIdentity', userId);
        if ($window.isApp) {
          Branch.setIdentity(userId)
            .then(function onSuccess(res) {
              console.log('Branch.io - setIdentity - Response: ', res);
              identityId = res.$identity_id;

              // save information about installation
              Branch.getLatestReferringParams()
                .then(function onSuccess(res) {
                  // if first session fire custom event app-install
                  if (_isNewInstall(res)) {
                    userCompletedAction('app-install', {});
                    window.localStorage.setItem('isFirstInstall', 'false');
                  }
                })
                .catch(function onFail(err) {
                  console.error('Branch.io - Error: ' + JSON.stringify(err));
                });
            })
            .catch(function onFail(err) {
              console.error('Branch.io - setIdentity - Error: ', err.message);
            });
        } else {
          branch.setIdentity(userId, function onSetIdentity(err, data) {
            if (err) {
              console.error('Branch.io - setIdentity - Error', err);
            } else {
              console.log('Branch.io - setIdentity - Data', data);
              identityId = data.identity_id;
              userCompletedAction('web-open', {});
            }
          });
        }
      });
    }

    /**
     * Logout
     * @return {angular.IPromise<Object>}
     */
    function logout() {
      var deferred = $q.defer();

      _wait(function logoutFunc() {
        console.log('Branch.io: logout');
        if ($window.isApp) {
          Branch.logout()
            .then(function onSuccess(res) {
              console.log('Branch.io - Response: ', res);
              deferred.resolve(res);
            })
            .catch(function onFail(err) {
              console.log('Branch.io - Error: ', err.message);
              deferred.reject(err);
            });
        } else {
          branch.logout(function onLogout(err) {
            if (err) {
              deferred.reject(new Error(err));
            } else {
              deferred.resolve({});
            }
          });
        }
      });

      return deferred.promise;
    }

    /**
     * Loads rewards for selected bucket.
     *
     * @param {string} bucket - bucket name
     * @return {angular.IPromise<object>}
     */
    function loadRewards(bucket) {
      if (!isEnabled()) return $q.resolve({});

      var deferred = $q.defer();

      _wait(function loadRewardsFunc() {
        console.log('Branch.io: loadRewards');
        if ($window.isApp) {
          Branch.loadRewards(bucket)
            .then(function onSuccess(data) {
              deferred.resolve(data);
            })
            .catch(function onFail(reason) {
              deferred.reject(reason);
            });
        } else {
          branch.credits(function onGetCredits(err, data) {
            if (err) {
              deferred.reject(new Error(err));
            } else {
              deferred.resolve(data[bucket]);
            }
          });
        }
      });

      return deferred.promise;
    }

    /**
     * Get credit history
     * @return {angular.IPromise<Object>}
     */
    function creditHistory() {
      if (!isEnabled()) return $q.resolve({});

      var deferred = $q.defer();

      _wait(function creditHistoryFunc() {
        console.log('Branch.io: creditHistory');
        if ($window.isApp) {
          Branch.creditHistory()
            .then(function onSuccess(data) {
              deferred.resolve(data);
            })
            .catch(function onFail(reason) {
              deferred.reject(reason);
            });
        } else {
          branch.creditHistory(undefined, function onGetCreditHistory(err, data) {
            if (err) {
              deferred.reject(new Error(err));
            } else {
              deferred.resolve(data);
            }
          });
        }
      });

      return deferred.promise;
    }

    /**
     * Get first referring params
     * @return {angular.IPromise<Object>}
     */
    function getFirstReferringParams() {
      if (!isEnabled()) return $q.resolve({});

      var deferred = $q.defer();

      _wait(function getFirstReferringParamsFunc() {
        if ($window.isApp) {
          Branch.getFirstReferringParams()
            .then(function onSuccess(data) {
              deferred.resolve(data);
            })
            .catch(function onFail(reason) {
              deferred.reject(reason);
            });
        } else {
          branch.first(function onGetFirstParams(err, data) {
            if (err) {
              deferred.reject(new Error(err));
            } else {
              deferred.resolve(data);
            }
          });
        }
      });

      return deferred.promise;
    }

    /**
     * Get latest referring params
     * @return {angular.IPromise<Object>}
     */
    function getLatestReferringParams() {
      if (!isEnabled()) return $q.resolve({});

      var deferred = $q.defer();

      _wait(function getLatestReferringParamsFunc() {
        if ($window.isApp) {
          Branch.getLatestReferringParams()
            .then(function onSuccess(data) {
              deferred.resolve(data);
            })
            .catch(function onFail(reason) {
              deferred.reject(reason);
            });
        } else {
          branch.data(function onGetLastParams(err, data) {
            if (err) {
              deferred.reject(new Error(err));
            } else {
              deferred.resolve(data);
            }
          });
        }
      });

      return deferred.promise;
    }

    /**
     * This function creates referral link.
     *
     * @param {object} linkData - link data
     * @return {angular.IPromise<string>}
     */
    function createLink(linkData) {
      if (!isEnabled()) return $q.resolve('url');

      var deferred = $q.defer();

      _wait(function createLinkFunc() {
        console.log('Branch.io: createLink');

        linkData = linkData || {};
        linkData['$desktop_url'] = btSettings.BT_BACKEND_URL + '/in';

        var analytics = {
          channel: 'app',
          feature: 'share',
          campaign: 'referral',
          stage: 'share with friends',
          tags: ['share'],
          data: linkData,
        };

        if ($window.isApp) {
          var properties = {
            canonicalIdentifier: 'BetterTraderApp',
          };

          // create a branchUniversalObj variable to reference with other Branch methods
          var branchUniversalObj = null;
          Branch.createBranchUniversalObject(properties)
            .then(function onSuccess(res) {
              branchUniversalObj = res;
              console.log('Branch.io - Response: ', res);

              branchUniversalObj
                .generateShortUrl(analytics, linkData)
                .then(function onSuccess(res) {
                  console.log('Branch.io - Response: ', res.url);
                  deferred.resolve(res.url);
                })
                .catch(function onFail(err) {
                  console.log('Branch.io - Error: ', err);
                  deferred.reject(err);
                });
            })
            .catch(function onFail(err) {
              console.log('Branch.io - Error: ', err);
            });
        } else {
          branch.link(analytics, function callback(err, data) {
            if (err) {
              deferred.reject(new Error(err));
            } else {
              deferred.resolve(data);
            }
          });
        }
      });

      return deferred.promise;
    }

    /**
     * This function returns referral link.
     *
     * @param {string} [email] - user email
     * @return {angular.IPromise<any>}
     */
    function getReferralLink(email) {
      if (!isEnabled()) return $q.resolve({});

      return createLink(email ? { ref_email: email } : {});
    }

    /**
     * Returns user identifier.
     *
     * @return {angular.IPromise<string>}
     */
    function getIdentityId() {
      if (!isEnabled()) return $q.resolve('N/A');

      var deferred = $q.defer();

      _wait(function getIdentityIdFunc() {
        deferred.resolve(identityId);
      });

      return deferred.promise;
    }

    /**
     * Track event
     * @param {string} eventName - event name
     * @param {object} metaData - event meta data
     * @return {angular.IPromise<Object>}
     */
    function userCompletedAction(eventName, metaData) {
      if (!isEnabled()) return $q.resolve({});

      var deferred = $q.defer();

      /**
       *
       * @param {*} err - error
       * @param {*} [data] - data
       */
      function onTrackAction(err, data) {
        if (err) {
          deferred.reject(new Error(err));
        } else {
          deferred.resolve(data);
        }
      }

      _wait(function userCompletedActionFunc() {
        console.log('Branch.io: userCompletedAction');
        if ($window.isApp) {
          Branch.userCompletedAction(eventName, metaData)
            .then(function onSuccess(data) {
              deferred.resolve(data);
            })
            .catch(function onFail(reason) {
              deferred.reject(reason);
            });
        } else {
          branch.track(eventName, metaData, onTrackAction);
        }
      });

      return deferred.promise;
    }

    /**
     * Share via share-sheet
     * @param {string} subject - email subject
     * @param {string} body - message content
     * @param {object} params - share parameters
     * @return {angular.IPromise<Object>}
     */
    function share(subject, body, params) {
      if (!isEnabled()) return $q.resolve({});

      // set default value of share parameters
      params = params || {};

      if ($window.isApp) {
        $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Waiting...</p>' });

        var deferred = $q.defer();

        $timeout(function () {
          // only canonicalIdentifier is required
          var properties = {
            canonicalIdentifier: 'BetterTraderApp',
          };

          // create a branchUniversalObj variable to reference with other Branch methods
          var branchUniversalObj = null;
          Branch.createBranchUniversalObject(properties)
            .then(function onSuccess(res) {
              branchUniversalObj = res;
              console.log('Branch.io - Response: ', res);

              // optional fields
              var analytics = {
                channel: 'app',
                feature: 'share',
                campaign: 'referral',
                stage: 'share with friends',
                tags: ['share'],
              };

              // optional listeners (must be called before showShareSheet)
              branchUniversalObj.onShareSheetLaunched(function onShareSheetLaunchedFunc(res) {
                // android only
                console.log('Branch.io - onShareSheetLaunched', res);
              });
              branchUniversalObj.onShareSheetDismissed(function onShareSheetDismissedFunc(res) {
                $ionicLoading.hide();
                console.log('Branch.io - onShareSheetDismissed', res);
                deferred.resolve({});
              });
              branchUniversalObj.onLinkShareResponse(function onLinkShareResponseFunc(res) {
                console.log('Branch.io - onLinkShareResponse', res);
              });
              branchUniversalObj.onChannelSelected(function onChannelSelectedFunc(res) {
                // android only
                console.log('Branch.io - onChannelSelected', res);
              });

              // optional fields
              var properties = {
                $email_subject: subject,
              };

              if (params.link) {
                properties['$desktop_url'] = params.link;
                properties['canonicalUrl'] = params.link;
                properties['title'] = subject;
                properties['contentDescription'] = body;
                properties['$og_title'] = subject;
                properties['$og_description'] = body;

                var match = _matchUrl(params.link, routesDeepLink);
                if (match !== null) {
                  properties['deeplinkState'] = match.target;
                  properties['deeplinkParams'] = JSON.stringify(match.args);
                }
              }

              // share sheet
              branchUniversalObj.showShareSheet(analytics, properties, subject + '\r\n\r\n' + body);
            })
            .catch(function onFail(err) {
              $ionicLoading.hide();
              console.log('Branch.io - Error: ', err);
              deferred.reject(err);
            });
        }, 100);

        return deferred.promise;
      } else {
        return $q.resolve({});
      }
    }

    /**
     * This function sends SMS with link to application.
     *
     * @param {string} phone - phone number
     * @param {string} [text] - sms text
     * @return {angular.IPromise<Object>}
     */
    function sendSMS(phone, text) {
      if (!isEnabled()) return $q.resolve({});

      text = text || 'Click here to download BetterTrader app {{link}}.\nThis link will expire soon.';

      var deferred = $q.defer();

      /**
       *
       * @param {*} err - error
       */
      function callback(err) {
        console.log('Branch.io - sendSMS Error', err);
        if (err) {
          deferred.reject(err);
        } else {
          deferred.resolve({});
        }
      }

      var params = {
        tags: ['sms', 'desktop'],
        channel: 'sms',
        feature: 'dashboard',
        stage: 'download app',
        data: {
          $custom_sms_text: text,
        },
      };

      branch.sendSMS(phone, params, undefined, callback);

      return deferred.promise;
    }
  }
})();
