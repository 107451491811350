/**
 * Created by Sergey Panpurin on 09/04/2017.
 */

// @ts-check
(function btTemplateApiServiceClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This factory works with Tradier API.
     *
     * @ngdoc service
     * @name btTemplateApiService
     * @memberOf ecapp
     * @param {angular.IQService} $q - promise interface
     * @param {angular.IHttpService} $http - service that facilitates communication with the remote HTTP servers
     */
    .factory('btTemplateApiService', btTemplateApiService);

  btTemplateApiService.$inject = ['$q'];

  /**
   *
   * @param {angular.IQService} $q
   * @return {ecapp.ITemplateApiService}
   */
  function btTemplateApiService($q) {
    void $q;

    console.log('Running btTemplateApiService');

    /**
     * Resets selected account.
     *
     * @param {ecapp.ITradingAccount[]} accounts - list of BetterTrader accounts
     * @param {string} selectedAccountId - id of selected account
     * @return {string} - id of selected account (updated)
     */
    function resetSelectedAccount(accounts, selectedAccountId) {
      if (accounts.length === 0) {
        return null;
      }

      // if the account was selected, check that we still have this account
      if (selectedAccountId !== null) {
        var res = accounts.filter(function (item) {
          return item.key === selectedAccountId;
        });
        // if not set to null
        if (res.length !== 1) {
          console.log('Reset selected account');
          selectedAccountId = null;
        }
      }

      // if the account was not selected - select the first account
      if (selectedAccountId === null) selectedAccountId = accounts[0].key;

      return selectedAccountId;
    }

    return {
      resetSelectedAccount: resetSelectedAccount,
    };
  }
})();
