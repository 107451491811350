/**
 * Created by Orly on 27/03/19.
 */

(function btActiveTradeIdeasClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * ???
     *
     * @ngdoc directive
     * @name btActiveTradeIdeas
     * @memberOf ecapp
     * @scope
     */

    .directive('btActiveTradeIdeas', btActiveTradeIdeas);

  btActiveTradeIdeas.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btActiveTradeIdeas($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('directives/barometer/bt-active-trade-ideas-widget.html'),
      controller: btActiveTradeIdeasController,
      controllerAs: 'vm',
    };
  }

  btActiveTradeIdeasController.$inject = [
    '$scope',
    'btWatchListService',
    'btDateService',
    'btTradeIdeasService',
    'btSettingsService',
    'btRestrictionService',
    'btLinkService',
  ];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.IWatchListService} btWatchListService
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.ITradeIdeasService} btTradeIdeasService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.ILinkService} btLinkService
   */
  function btActiveTradeIdeasController(
    $scope,
    btWatchListService,
    btDateService,
    btTradeIdeasService,
    btSettingsService,
    btRestrictionService,
    btLinkService
  ) {
    void $scope;

    /*jshint validthis: true*/
    var vm = this;

    var gSettings = btSettingsService.getLocalSettings();

    activate();

    /**
     *
     */
    function activate() {
      vm.tradeIdeas = [];
      vm.isLoading = true;
      vm.hasError = false;
      vm.errorMessage = '';
      vm.softLimit = 6;
      vm.hardLimit = 100;
      vm.collapsed = true;
      collapse(vm.collapsed);

      loadSettings();
      sortColumn(vm.orderByColumn, vm.orderByReverse ? -1 : 1);

      vm.collapse = collapse;
      vm.sortColumn = sortColumn;
      vm.reactivate = reactivate;
      vm.getOrderByExpression = getOrderByExpression;
      vm.openTradeIdea = openTradeIdea;

      loadTradeIdeas();
    }

    /**
     *
     * @param {*} status
     */
    function collapse(status) {
      vm.collapsed = status;
      vm.limit = vm.collapsed ? vm.softLimit : vm.hardLimit;
    }

    /**
     *
     */
    function reactivate() {
      vm.isLoading = true;
      vm.hasError = false;
      vm.errorMessage = '';

      loadTradeIdeas();
    }

    /**
     *
     */
    function loadTradeIdeas() {
      btWatchListService
        .initialize()
        .then(btTradeIdeasService.initialize)
        .then(function () {
          vm.tradeIdeas = btTradeIdeasService.getCachedActiveTradeIdeas();
          vm.isLoading = false;
        })
        .catch(function (reason) {
          vm.isLoading = false;
          vm.hasError = true;
          vm.errorMessage = reason.message || 'unknown';
        });
    }

    /**
     *
     * @param {*} idea
     */
    function openTradeIdea(idea) {
      if (idea.locked) {
        btRestrictionService.showUpgradePopup('trade-ideas');
      } else {
        btLinkService.openInternal('ecapp.app.main.instrument-page', {
          broker: idea.instrument.brokerName,
          symbol: idea.instrument.brokerSymbol,
        });
      }
    }

    /**
     * This function return order expression.
     *
     * @return {String[]}
     */
    function getOrderByExpression() {
      var reverse = vm.orderByReverse;
      if (vm.orderByColumn === 'idea') {
        return [reverse ? '-instrument.displayName' : '+instrument.displayName', '-date'];
      }

      if (vm.orderByColumn === 'type') {
        return [reverse ? '-type' : '+type', '-date', '+instrument.displayName'];
      }

      if (vm.orderByColumn === 'time') {
        return [reverse ? '+date' : '-date', '+instrument.displayName'];
      }
    }

    /**
     * This function changes sorting.
     *
     * @param {String} column
     * @param {Number} order
     */
    function sortColumn(column, order) {
      if (order !== undefined) {
        vm.orderByReverse = order === -1;
      } else {
        vm.orderByReverse = vm.orderByColumn === column ? !vm.orderByReverse : false;
      }

      vm.orderByColumn = column;

      saveSettings();
    }

    /**
     * This function saves user settings.
     */
    function loadSettings() {
      vm.orderByReverse = gSettings.get('barometer.active-trade-ideas.order');
      vm.orderByReverse = vm.orderByReverse !== undefined ? vm.orderByReverse : false;
      vm.orderByColumn = gSettings.get('barometer.active-trade-ideas.column') || 'time';
    }

    /**
     * This function loads user settings.
     */
    function saveSettings() {
      gSettings.set('barometer.active-trade-ideas.order', vm.orderByReverse);
      gSettings.set('barometer.active-trade-ideas.column', vm.orderByColumn);
    }
  }
})();
