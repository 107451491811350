/**
 * Created by Sergey Panpurin on 4/26/2017.
 */

/*global */

// @ts-check
(function btTradeStationAuthServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'TradeStationAPI Service:';

  angular.module('ecapp').factory('btTradeStationAuthService', btTradeStationAuthService);

  btTradeStationAuthService.$inject = ['$q', '$timeout', 'btOauthService'];

  /**
   * This factory works with TradeStation API. This factory contain oauth authorization and TradeStation API functions.
   *
   * Example:
   * // call getDataCallback with externalCallback
   * getDataCallback(Brokerage.getAccountsByUserID.bind(Brokerage), ["token", "user", "callback"],
   *   function externalCallback(error, data) {
   *        accounts = data;
   *        locks['init'] = false;
   *      }
   * );
   *
   * Function getDataCallback use errorHandler inside, so function getAccountsByUserID will be called with
   * callback errorHandler. In good case errorHandler just call externalCallback. In bad case errorHandler refresh
   * token and after run externalCallback
   *
   * In good case
   * getAccounts -> promise
   * getDataCallback -> null
   * _callFunc -> null
   * Brokerage.getAccountsByUserID -> request
   * --> errorHandler -> null
   *     externalCallback -> null | true | false
   *
   * In bad case
   * getAccounts -> promise
   * getDataCallback -> null
   * _callFunc -> null
   * Brokerage.getAccountsByUserID -> request
   * --> errorHandler -> null
   *     _refreshToken -> promise
   *     --> _callFunc -> null
   *         Brokerage.getAccountsByUserID -> request
   *         --> errorHandler -> null
   *             externalCallback -> null | true | false
   *
   * In case of streaming we need to return reference to request to have ability to terminate streaming.
   *
   * @ngdoc service
   * @name btTradeStationAuthService
   * @memberOf ecapp
   * @param {angular.IQService} $q
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.IOauthService} btOauthService
   * @return {ecapp.ITradeStationAuthService}
   */
  function btTradeStationAuthService($q, $timeout, btOauthService) {
    if (gDebug) console.log('Running btTradeStationAuthService');

    var gTokenAutoRefresh = false;

    // Authorization data template
    /** @type {ecapp.ITradeStationAuthData} */
    var gOAuthData = {
      code: null,
      scope: '',
      token: null,
      accessTokenData: { token: null, expires: null, date: null },
      refreshTokenData: { token: null, date: null },
      userId: null,
      wasConnected: false,
      mode: 'demo',
    };

    /**
     * @enum {string}
     */
    var State = {
      INITIAL: 'initial',
      CONNECTED: 'connected',
      DISCONNECTED: 'disconnected',
      WAIT_LOGIN: 'waitLogin',
      WAIT_TOKEN: 'waitToken',
      WAIT_REFRESH: 'waitRefresh',
    };

    /* Authorization states: initial, connected, disconnected, waitLogin, waitToken, waitRefresh */
    var gState = State.INITIAL;

    // Process login flag
    var gProcessLogin = false;

    // User's accounts object
    /** @type {angular.IPromise<any> | null} */
    var gTokenPromise = null;

    var gWasAskedLogin = false;

    _prepare();

    /** @type {ecapp.ICallbackWithData<ecapp.ITradeStationAuthData> | null} */
    var saveDataCallback = null;

    return {
      initialize: initialize,
      connect: connect,
      disconnect: disconnect,

      login: login,
      logout: logout,
      isLoggedIn: isLoggedIn,

      setTradingMode: setTradingMode,
      getTradingMode: getTradingMode,

      auth: auth,
      getDataCallback: getDataCallback,
      getAccessData: getAccessData,
    };

    /* --- Public functions --- */

    /**
     * Connects to the TradeStation.
     *
     * @return {angular.IPromise<{ userId: string, accessToken: string }>}
     */
    function connect() {
      var deferred = $q.defer();
      if (gDebug) console.log(gPrefix, 'connect');

      // FIXME Token can be expired.
      if (isLoggedIn()) {
        if (gDebug) console.log(gPrefix, 'logged in');
        deferred.resolve({
          userId: gOAuthData.userId,
          accessToken: gOAuthData.accessTokenData.token,
        });
      } else if (gTokenPromise !== null) {
        if (gDebug) console.log(gPrefix, 'token promise');

        gTokenPromise
          .then(function () {
            if (gDebug) console.log(gPrefix, 'connect with promise');
            deferred.resolve({
              userId: gOAuthData.userId,
              accessToken: gOAuthData.accessTokenData.token,
            });
          })
          .catch(function (error) {
            console.error(gPrefix, 'connect with promise - error');
            deferred.reject(error);
          });
        // tokenPromise = null;
      } else {
        console.error(gPrefix, 'connect error');
        deferred.reject(new Error("Can't connected to broker!"));
      }

      return deferred.promise;
    }

    /**
     * Disconnect broker
     *
     * @return {angular.IPromise<{}>}
     */
    function disconnect() {
      return logout();
    }

    /**
     * Is user logged in to TradeStation API
     *
     * @return {boolean}
     */
    function isLoggedIn() {
      return _hasAccessToken();
    }

    /**
     * TradeStation API wrapper. Process error, restart if token was expired.
     *
     * @param {ecapp.ICallback} func - function to call
     * @param {Array} args - function's arguments string "token", "user", and "callback" will be replaced
     * @param {ecapp.IRequestCallback|{type:String, func:ecapp.IRequestCallback}} callback - callback
     * @param {Boolean} [forceLogin] -
     * @return {{request: *}}
     */
    function getDataCallback(func, args, callback, forceLogin) {
      _setTokenRefresher();
      _backTrace('back trace - getDataCallback');
      var needForceLogin = forceLogin || false;
      var recCount = 0;
      var tokenError = null;
      var returnData = { request: null };

      /** @type {ecapp.IRequestCallback|{type:String, func:ecapp.IRequestCallback}} */
      var internalCallback;
      /** @type {ecapp.IRequestCallback} */
      var externalCallback;
      if (!(callback instanceof Function)) {
        internalCallback = { type: callback.type, func: callback.func };
        externalCallback = callback.func;
      } else {
        internalCallback = errorHandler;
        externalCallback = callback;
      }

      // try to get data
      if (_hasAccessToken() && gState === State.CONNECTED) {
        if (_isTokenValid()) {
          returnData.request = _callFunc(
            func,
            args,
            gOAuthData.accessTokenData.token,
            gOAuthData.userId,
            internalCallback
          );
        } else {
          returnData.request = _callFunc(
            func,
            args,
            gOAuthData.accessTokenData.token,
            gOAuthData.userId,
            internalCallback
          );
        }
      } else if (_hasCode()) {
        _getToken(gOAuthData.code)
          .then(function () {
            returnData.request = _callFunc(
              func,
              args,
              gOAuthData.accessTokenData.token,
              gOAuthData.userId,
              internalCallback
            );
          })
          .catch(function (error) {
            console.error(gPrefix, 'get token error', error);
          });
      } else {
        if (!_isStartLogin()) {
          login(getTradingMode(), needForceLogin, null);
        }
      }

      return returnData;

      /**
       *
       * @param {*} error
       * @param {*} data
       * @param {*} response
       */
      function errorHandler(error, data, response) {
        _backTrace('back trace - errorHandler');
        void data;

        recCount += 1;

        if (error) {
          if (gDebug) console.log(gPrefix, 'get data error', error);
          if (_isTokenExpiredError(error)) {
            if (recCount < 5) {
              _refreshToken(needForceLogin)
                .then(function () {
                  returnData.request = _callFunc(
                    func,
                    args,
                    gOAuthData.accessTokenData.token,
                    gOAuthData.userId,
                    internalCallback
                  );
                })
                .catch(function (error) {
                  tokenError = error;
                  if (gDebug) console.log(gPrefix, 'refresh token error', error);
                });
            } else {
              console.error("TradeStationAPI Service: Can't refresh token");
              if (gDebug) console.log(gPrefix, 'last error', _prepareError(tokenError));
              externalCallback(_prepareError(tokenError), null);
            }
          } else {
            console.error(gPrefix, 'error was unexpected');
            externalCallback(_prepareError(error), null);
          }
        } else {
          externalCallback(null, response.body, response);
        }
      }
    }

    /**
     *
     * @param {() => Promise} wrappedFunc - function
     * @return {() => angular.IPromise}
     */
    function auth(wrappedFunc) {
      var name = wrappedFunc.name;
      var wrapped = wrappedFunc;

      return function () {
        _setTokenRefresher();
        _backTrace('back trace - auth');
        var needForceLogin = false;
        var self = this;
        var args = arguments;

        var runWrapped = function () {
          var promise = wrapped.apply(self, args);
          if (gDebug) console.log(gPrefix, 'Promise', name, promise);
          return promise.then(_parseError).catch(_improveError);
        };

        // try to get data
        if (_hasAccessToken() && gState === State.CONNECTED) {
          if (_isTokenValid()) {
            return runWrapped();
          } else {
            return _refreshToken(needForceLogin).then(runWrapped);
          }
        }

        if (_hasCode()) {
          return _getToken(gOAuthData.code).then(runWrapped);
        }

        if (!_isStartLogin()) {
          return login(getTradingMode(), needForceLogin, null).then(runWrapped);
        }

        return $q.reject(new Error('No access token'));
      };
    }

    /**
     *
     * @param {*} err
     * @return {any}
     */
    function _improveError(err) {
      if (err.body && err.body.Message) {
        err.message = err.body.Message;
      }

      return $q.reject(err);
    }

    /**
     *
     * @param {*} res
     * @return {any}
     */
    function _parseError(res) {
      if (res && res.Errors && res.Errors.length) {
        var err = res.Errors[0];
        var msg = err.Error + ' - ' + err.Message + (err.Symbol ? ' ' + err.Symbol : '');
        return $q.reject(new Error(msg));
      }

      return res;
    }

    /**
     *
     * @return {Boolean}
     * @private
     */
    function _hasCode() {
      return gOAuthData.code !== undefined && gOAuthData.code !== null;
    }

    /**
     *
     * @return {boolean}
     * @private
     */
    function _hasAccessToken() {
      return gOAuthData.accessTokenData.token !== undefined && gOAuthData.accessTokenData.token !== null;
    }

    /**
     *
     * @return {boolean}
     * @private
     */
    function _isStartLogin() {
      return gProcessLogin;
    }

    /**
     * Checks if token should be valid.
     *
     * @return {boolean}
     * @private
     */
    function _isTokenValid() {
      var date = new Date(gOAuthData.accessTokenData.date).getTime();
      var expired = date + gOAuthData.accessTokenData.expires * 1000;
      return Date.now() <= expired;
    }

    /**
     * Checks if error connected to expired token.
     *
     * @param {{response: Object}} error - error
     * @return {boolean}
     * @private
     */
    function _isTokenExpiredError(error) {
      return (
        error.response &&
        error.response.body &&
        error.response.body.StatusCode == 401 &&
        error.response.body.Message === 'Access token has expired.'
      );
    }

    /**
     * TradeStation API preparation. Load credentials from cache. Parsing URL to get auth code.
     *
     * @private
     */
    function _prepare() {
      if (gDebug) console.log(gPrefix, 'loading cache data');
      var tsCache = null;

      try {
        tsCache = JSON.parse(window.localStorage.getItem('tsCache'));
      } catch (err) {
        console.error(gPrefix, 'error', err);
      }

      if (gDebug) console.log(gPrefix, 'cache', tsCache);
      //
      if (tsCache !== null) {
        if (tsCache.mode !== undefined) {
          setTradingMode(tsCache.mode);
        }
      }

      if (gDebug) console.log(gPrefix, 'global state', gState);

      if (window.location.href.indexOf('code=') !== -1) {
        var code = window.location.href.split('code=')[1];
        code = decodeURIComponent(code);
        gOAuthData.code = code;
        // window.localStorage.setItem('oAuthData', JSON.stringify(oAuthData));
        if (gDebug) console.log(gPrefix, 'auth code was parsed from URL');
      }

      if (_hasCode() && !_hasAccessToken()) {
        if (gDebug) console.log(gPrefix, 'use auth code to get token');
        gTokenPromise = _getToken(gOAuthData.code);
      }
    }

    /**
     * @param {string} mode - trading mode: real ot demo
     * @param {boolean} isForceLogin - force login or not
     * @param {ecapp.ICallbackWithData} callback - function to save access data
     * @return {angular.IPromise<ecapp.ITradeStationAuthData>} - access data
     */
    function login(mode, isForceLogin, callback) {
      var deferred = $q.defer();

      if (callback !== undefined && callback !== null) {
        saveDataCallback = callback;
      }

      setTradingMode(mode);

      if (gState !== State.WAIT_LOGIN && (gWasAskedLogin === false || isForceLogin === true)) {
        gState = State.WAIT_LOGIN;
        gProcessLogin = true;

        gState = State.INITIAL;
        btOauthService
          .login('tradestation-' + mode)
          .then(_saveAccessData)
          .then(function (data) {
            deferred.resolve(data);
          })
          .catch(function (error) {
            deferred.reject(error);
          });
      }

      return deferred.promise;
    }

    /**
     *
     * @param {ecapp.IAuthResponse} data
     * @return {ecapp.ITradeStationAuthData}
     */
    function _saveAccessData(data) {
      gOAuthData.code = null;
      gOAuthData.scope = data.scope;

      gOAuthData.token = data.accessToken || data.access_token;
      gOAuthData.accessTokenData.token = data.accessToken || data.access_token;
      gOAuthData.accessTokenData.expires = data.expiresIn || data.expires_in;
      gOAuthData.accessTokenData.date = new Date();

      if (data.refreshToken !== undefined) {
        gOAuthData.refreshTokenData.token = data.refreshToken || data.refresh_token;
        gOAuthData.refreshTokenData.date = new Date();
      }

      gOAuthData.userId = data.userId || data.user_id;
      gOAuthData.wasConnected = true;
      gWasAskedLogin = false;

      gState = State.CONNECTED;

      if (saveDataCallback !== null) {
        saveDataCallback(gOAuthData);
      }

      return gOAuthData;
    }

    /**
     *
     * @return {angular.IPromise<{}>}
     * @private
     */
    function logout() {
      var deferred = $q.defer();

      gOAuthData = {
        code: null,
        token: null,
        scope: '',
        accessTokenData: { token: null, expires: null, date: null },
        refreshTokenData: { token: null, date: null },
        userId: null,
        wasConnected: false,
        mode: 'demo',
      };

      gTokenAutoRefresh = false;

      gWasAskedLogin = false;

      //window.localStorage.setItem('oAuthData', JSON.stringify(oAuthData));
      if (gDebug) console.log(gPrefix, 'delete account data');

      gState = State.DISCONNECTED;
      if (gDebug) console.log(gPrefix, 'global state', gState);

      deferred.resolve({});

      return deferred.promise;
    }

    /**
     * Sets token refresher.
     */
    function _setTokenRefresher() {
      if (gTokenAutoRefresh === false) {
        var data = gOAuthData.accessTokenData;
        if (gDebug) console.log(gPrefix, 'Token was created at:', data.date);
        if (gDebug) console.log(gPrefix, 'Token time to live:', data.expires, 'seconds');
        var delta = (Date.now() - new Date(data.date).getTime()) / 1000;
        var expire_in = Math.floor(data.expires - delta) - 10;
        if (gDebug) console.log(gPrefix, 'Token expire in:', expire_in, 'seconds');

        if (expire_in > 0) {
          gTokenAutoRefresh = true;
          $timeout(refreshReminder, expire_in * 1000, false);
        }
      }
    }

    /**
     *
     */
    function refreshReminder() {
      if (gTokenAutoRefresh) {
        _refreshToken(false);
      }
    }

    /**
     *
     * @param {string} code
     * @return {angular.IPromise<ecapp.ITradeStationAuthData | void>}
     * @private
     */
    function _getToken(code) {
      return btOauthService
        .getToken('tradestation-' + gOAuthData.mode, { code: code })
        .then(_saveAccessData)
        .catch(_handleTokenError);
    }

    /**
     * Refreshes access token.
     *
     * @param {boolean} forceLogin - force login
     * @return {angular.IPromise<ecapp.ITradeStationAuthData | void>}
     * @private
     */
    function _refreshToken(forceLogin) {
      if (gDebug) console.log(gPrefix, 'refreshing access token...');
      void forceLogin;
      gTokenAutoRefresh = false;
      return btOauthService
        .refreshToken('tradestation-' + gOAuthData.mode, {
          refresh_token: gOAuthData.refreshTokenData.token,
        })
        .then(function (data) {
          if (gDebug) console.log(gPrefix, 'response', data);
          if (gDebug) console.log(gPrefix, 'access token has been refreshed');

          data.refreshToken = gOAuthData.refreshTokenData.token;
          data.userId = gOAuthData.userId;
          data.scope = gOAuthData.scope;
          data.expiresIn = gOAuthData.accessTokenData.expires;

          var res = _saveAccessData(data);
          return res;
        })
        .catch(function (err) {
          if (gDebug) console.log(gPrefix, 'refreshing failed');
          return _handleTokenError(err);
        });
    }

    /**
     *
     * @param {*} error
     */
    function _handleTokenError(error) {
      if (error.status === 401 && error.data === '"Authorization code expired."') {
        console.error(gPrefix, 'Authorization code expired.');
      }

      if (error.status === -1) {
        console.error(gPrefix, 'Status equal to -1');
        alert("Can't access to oauth server");
        return;
      }

      if (error.data === -1) {
        console.error(gPrefix, 'Data equal to null');
      }

      console.error(gPrefix, 'get token error', error);

      login(getTradingMode(), false, null);
    }

    /**
     * This function TradeStation API function.
     * Convert "token", "user" and "callback" arguments to real values
     *
     * @param {ecapp.ICallback} func - function to call
     * @param {Array} args - array of arguments
     * @param {string} accessToken - access token
     * @param {string} userId - user id
     * @param {ecapp.IRequestCallback|{type:String, func:ecapp.IRequestCallback}} callback - callback function
     * @return {*}
     * @private
     */
    function _callFunc(func, args, accessToken, userId, callback) {
      _backTrace('back trace - _callFunc');
      var newArgs = args.map(function (item) {
        if (item === 'token') {
          return accessToken;
        }
        if (item === 'user') {
          return userId;
        }
        if (item === 'callback') {
          return callback;
        }
        return item;
      });

      return func.apply(null, newArgs);
    }

    /**
     * Initialize TradeStation service
     * @param {ecapp.ITradeStationAuthData} data - access data
     * @param {ecapp.ICallbackWithData<ecapp.ITradeStationAuthData>} saveDataFunction - btTradingService function to save access data
     * @return {angular.IPromise<{ userId: string, accessToken: string }>}
     */
    function initialize(data, saveDataFunction) {
      if (data && data.accessTokenData) {
        gOAuthData = data;
        gState = State.CONNECTED;
      }

      saveDataCallback = saveDataFunction;

      return connect();
    }

    /**
     * Prepare error data
     * @param {{response: Object, message: String}} error - TradeStation error object
     * @return {Error} common error object
     * @private
     */
    function _prepareError(error) {
      _backTrace('back trace - _prepareError');
      if (error instanceof Error) {
        if (error && error.response && error.response.body) {
          if (error.response.body.Message) {
            error.message += ': ' + error.response.body.Message;
          }
        }
        //noinspection JSValidateTypes
        return error;
      } else {
        console.error('btTradeStationApiService: unknown error', error);
        return new Error('Unknown error!');
      }
    }

    /**
     * Logs only in development environment.
     *
     * @param {*} data - log data
     * @private
     */
    function _backTrace(data) {
      if (window.isDevelopment) {
        if (gDebug) console.log(gPrefix, data);
      }
    }

    /**
     * Set trading mode
     * @param {string} mode - trading mode (real or demo)
     */
    function setTradingMode(mode) {
      gOAuthData.mode = mode;

      // // change authorization url
      // if (mode === 'real') {
      //   authUrl = 'https://api.tradestation.com/v2/authorize';
      // } else {
      //   authUrl = 'https://sim.api.tradestation.com/v2/authorize';
      // }
    }

    /**
     *
     * @return {string}
     */
    function getTradingMode() {
      return gOAuthData.mode;
    }

    /**
     * This promise return broker access data.
     * Service btTradingService use this function to receive access data and save it.
     * @return {angular.IPromise<Object>}
     */
    function getAccessData() {
      var deferred = $q.defer();

      if (gTokenPromise === null) {
        deferred.resolve(gOAuthData);
      } else {
        gTokenPromise.then(function () {
          deferred.resolve(gOAuthData);
        });
      }

      return deferred.promise;
    }
  }
})();
