// @ts-check
(function btBrokerControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btBrokerController', btBrokerController);

  btBrokerController.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    '$q',
    'btTradingService',
    '$location',
    '$analytics',
    'btToastrService',
    '$ionicHistory',
    'btDevService',
    'btRestrictionService',
    'btShareScopeService',
    'btLinkService',
    'btAdvertisingService',
  ];

  /**
   *
   * @param {ecapp.ICustomScope} $scope - ?
   * @param {ecapp.ICustomRootScope} $rootScope - ?
   * @param {angular.ui.IStateService} $state - ?
   * @param {angular.IQService} $q - promise interface
   * @param {ecapp.ITradingService} btTradingService - ?
   * @param {angular.ILocationService} $location - ?
   * @param {ext.IAnalyticsService} $analytics - ?
   * @param {ecapp.IToastrService} btToastrService - ?
   * @param {ionic.IHistoryService} $ionicHistory - ionic history service
   * @param {ecapp.IDevService} btDevService - ?
   * @param {ecapp.IRestrictionService} btRestrictionService - ?
   * @param {ecapp.IShareScopeService} btShareScopeService - ?
   * @param {ecapp.ILinkService} btLinkService - ?
   * @param {ecapp.IAdvertisingService} btAdvertisingService - ?
   */
  function btBrokerController(
    $scope,
    $rootScope,
    $state,
    $q,
    btTradingService,
    $location,
    $analytics,
    btToastrService,
    $ionicHistory,
    btDevService,
    btRestrictionService,
    btShareScopeService,
    btLinkService,
    btAdvertisingService
  ) {
    // $scope.isProduction = window.isProduction;

    /**
     * States:
     *  - list of brokers
     *  - broker selected
     *    - create account
     *  - another broker
     *  - get started
     */
    /**
     * This object controls page content.
     *
     * @type {{mode: string, requested: boolean, needHelp: boolean, data: null, selected: boolean}}
     */
    $scope.broker = { requested: false, needHelp: false, selected: false, blocked: false, data: null, mode: 'demo' };
    $scope.settings = { tradeDemo: true, defaultTradeSize: 1, hideDemoToggle: false };

    $scope.isLoading = true;
    $scope.isConnected = false;
    $scope.isPractice = false;
    $scope.hasPracticeAccount = false;
    $scope.hasBrokerIntegration = btRestrictionService.hasFeature('broker-integration');

    $scope.login = login;
    $scope.disconnect = disconnect;
    $scope.doRefresh = doRefresh;
    $scope.toBrokerList = toBrokerList;
    $scope.selectBroker = selectBroker;

    $scope.$on('subscription:updated', onSubscriptionUpgradeSuccess);

    activate();

    /**
     * This function reacts on subscription update.
     */
    function onSubscriptionUpgradeSuccess() {
      $scope.hasBrokerIntegration = btRestrictionService.hasFeature('broker-integration');
      // vm.hasCalendar = btRestrictionService.hasFeature('broker-integration');
    }

    /**
     *
     */
    function activate() {
      btTradingService.initialize().then(_prepareBrokerData);
    }

    /**
     * Prepare local broker data
     *
     * @return {angular.IPromise<any>}
     */
    function _prepareBrokerData() {
      $scope.isConnected = false;
      $scope.isLoading = true;
      $scope.adSettings = null;

      btAdvertisingService.initialize().then(function () {
        $scope.adSettings = btAdvertisingService.getBannerSettings('broker-page');
        // $scope.adSettings = btAdvertisingService.getADSettings();
      });

      return btShareScopeService
        .wait()
        .then(btTradingService.connect)
        .then(function onSuccess() {
          $scope.hasPracticeAccount = btTradingService.hasPracticeAccount();
          $scope.isLoading = false;
          if (btTradingService.isDefaultBroker()) {
            $scope.broker.selected = false;
            $scope.broker.data = null;
          } else {
            $scope.broker.selected = true;
            $scope.broker.data = btTradingService.getBrokerInfo();

            $scope.userInfo = btTradingService.getUserInfo();
            $scope.isConnected = btTradingService.isConnected();
            $scope.isPractice = btTradingService.isPractice();

            if ($scope.isPractice) $scope.broker.selected = false;

            $scope.settings.defaultTradeSize = btTradingService.getDefaultTradeSize();

            if (window.location.href.indexOf('code=') !== -1) {
              $location.search({});
              $location.url($location.path());
            }
          }
        });
      // .then(function () {
      //   if (window.isDevMode) {
      //     var location = btShareScopeService.getAccountInfoField('location');
      //     if (location && location.country && location.country.code === 'ZA') {
      //       $scope.broker.selected = true;
      //       $scope.broker.blocked = true;
      //       $scope.broker.data = btTradingService.getSupportedBrokers().ib;
      //     }
      //   }
      // });
    }

    /**
     * This function shows upgrade popup for instrument calendar.
     */
    function showBrokerUpgrade() {
      btRestrictionService.showUpgradePopup('broker-integration').then(function (status) {
        if (status === 'updated') {
          $scope.hasBrokerIntegration = btRestrictionService.hasFeature('broker-integration');
          // vm.hasCalendar = btRestrictionService.hasFeature('broker-integration');
        }
      });
    }

    /**
     * Login to selected broker
     * @param {String} name - broker name
     */
    function login(name) {
      if (!window.hasTradeAbility) {
        btToastrService.info('Trade ability is turned off!', 'Trade from app', { type: 'trade' });
      } else if (!btTradingService.hasBrokerLogin(name)) {
        btToastrService.info('Coming soon! Wait for this broker in a new version.', 'Trade from app', {
          type: 'trade',
        });
      } else if (window.isTradeStation) {
        btToastrService.info('You already use TradeStation!', 'TradeStation', { type: 'trade' });
      } else if (!$scope.hasBrokerIntegration) {
        showBrokerUpgrade();
      } else {
        $scope.isLoading = true;

        var mode = 'demo';
        if (!$scope.settings.tradeDemo) {
          mode = 'real';
        }

        btTradingService
          .login(name, mode)
          .then(_prepareBrokerData)
          .then($ionicHistory.clearCache)
          .finally(_finishLogin);
      }

      if (window.btSettings.BT_DOMAIN === 'bettertrader') {
        $analytics.eventTrack('login', { category: 'brokerage', label: name, mode: mode });
      }
    }

    /**
     * Finish login process
     * @private
     */
    function _finishLogin() {
      $state.reload();
      $scope.isPractice = btTradingService.isPractice();
      $scope.isLoading = false;
      if ($scope.isPractice) {
        console.log('Broker: try to open ecapp.app.balance');
        $state.go('ecapp.app.balance');
      }
    }

    /**
     * Disconnect broker
     */
    function disconnect() {
      $scope.isLoading = true;

      $ionicHistory.nextViewOptions({
        disableAnimate: true,
        disableBack: true,
      });

      btTradingService
        .disconnect()
        .then($ionicHistory.clearCache)
        .then(function onSuccess() {
          $state.reload();
        })
        .catch(function onFail(error) {
          console.log(error);
        })
        .finally(function onFinal() {
          $scope.isConnected = false;
          $scope.broker.selected = false;
          $scope.broker.data = null;
          $scope.isLoading = false;
        });
    }

    /**
     * Refresh page
     */
    function doRefresh() {
      _prepareBrokerData().finally(function () {
        $scope.$broadcast('scroll.refreshComplete');
      });
    }

    /**
     * Back to broker list
     */
    function toBrokerList() {
      $scope.broker.selected = false;
      $scope.broker.data = null;
    }

    /**
     * Select broker
     * @param {Object} broker - broker data
     */
    function selectBroker(broker) {
      if ($scope.broker.data === null && window.btSettings.BT_DOMAIN === 'bettertrader') {
        $analytics.eventTrack('select', { category: 'brokerage', label: broker.name });
      }

      $scope.broker.selected = true;
      $scope.broker.data = broker;
      if (['tradier', 'ctrader'].indexOf(broker.name) !== -1) {
        $scope.broker.mode = 'real';
        $scope.settings.tradeDemo = false;
        $scope.settings.hideDemoToggle = true;
      } else {
        $scope.broker.mode = 'demo';
        $scope.settings.tradeDemo = true;
        $scope.settings.hideDemoToggle = false;
      }
      $scope.requestAccount = false;
    }

    // Begin - New account form
    $scope.requestAccount = false;
    $scope.newAccountSignUp = { username: 'BTrader_username', password: generatePassword() };

    $scope.openNewAccountForm = openNewAccountForm;
    $scope.closeNewAccountForm = closeNewAccountForm;
    $scope.signUp = signUp;

    btTradingService.getUsername($rootScope.currentUser.email).then(function onSuccess(name) {
      $scope.newAccountSignUp.username = name;
    });

    /**
     * Generate password for sign up process
     * @return {Number}
     */
    function generatePassword() {
      return Math.floor(Math.random() * 9999999) + 20000000;
    }

    /**
     * Open new account form
     */
    function openNewAccountForm() {
      $scope.requestAccount = true;
      if (window.btSettings.BT_DOMAIN === 'bettertrader') {
        var name = $scope.broker.data.name;
        $analytics.eventTrack('create', { category: 'brokerage', label: name });
      }
    }

    /**
     * Close new account form
     */
    function closeNewAccountForm() {
      $scope.requestAccount = false;
    }

    /**
     * Create new account
     * @param {Object} form - angular form controller
     */
    function signUp(form) {
      if (form.$valid) {
        btToastrService.info('Creating new account...!', 'New account', { type: 'trade' });

        var userData = {
          username: $scope.newAccountSignUp.username,
          password: $scope.newAccountSignUp.password,
          email: $rootScope.currentUser.email,
          country: 'Singapore',
        };

        // test username
        // if username is not available
        btTradingService
          .checkUser(userData)
          .then(btTradingService.signUp)
          .catch(secondChance)
          .then(refreshForm)
          .then(onSignUpSuccess)
          .catch(onSignUpFail);
      }

      /**
       *
       * @param {Error|ecapp.IHttpErrorResponse} error - error
       * @return {angular.IPromise<*>}
       */
      function secondChance(error) {
        if ('status' in error && error.status === 500) {
          userData.email = addEmailTag(userData.email, 'bt');
          return btTradingService.checkUser(userData).then(btTradingService.signUp);
        } else {
          return $q.reject(error);
        }
      }

      /**
       *
       * @param {string} email - email
       * @param {string} tag - tag
       * @return {string}
       */
      function addEmailTag(email, tag) {
        var parts = email.split('@');
        return parts[0] + '+' + tag + '@' + parts[1];
      }

      /**
       *
       * @param {*} data - data
       * @return {*}
       */
      function onSignUpSuccess(data) {
        if (data !== null) {
          $scope.isLoading = true;
          btTradingService.fastLogin('oanda', 'demo', data).then(_prepareBrokerData).finally(_finishLogin);
        } else {
          btToastrService.error("Error: Account can't be created.", 'New account', { type: 'trade' });
        }
        return data;
      }

      /**
       * @typedef {object} ngHTTPResponse
       * @property {number} status - status code
       * @property {string} statusText - status code
       * @property {object} config - status code
       * @property {object} data - response data
       */

      /**
       * Handle Error
       * @param {Error|ecapp.IHttpErrorResponse} error - error
       */
      function onSignUpFail(error) {
        if ('message' in error) {
          btToastrService.error('Error: ' + error.message, 'New account', { type: 'trade' });
        } else if (error.data && error.data.error && error.data.error.message) {
          btToastrService.error('Error: ' + error.data.error.message, 'New account', { type: 'trade' });
        } else {
          btToastrService.error('Error: Account creation failed.', 'New account', { type: 'trade' });
        }
      }

      /**
       * Refresh sign-up form
       *
       * @param {Object} data
       * @return {Object}
       */
      function refreshForm(data) {
        $scope.newAccountSignUp.username = 'BTrader_username';
        $scope.newAccountSignUp.password = '12345678';
        $scope.requestAccount = false;

        form.$setPristine();
        form.$setUntouched();

        btToastrService.success('Account created! Now you can login.', 'New account', { type: 'trade' });

        return data;
      }
    }

    // End - New account form

    $scope.switchTradeDemo = switchTradeDemo;
    $scope.switchLiveTradeMode = switchLiveTradeMode;
    $scope.updateDefaultTradeSize = updateDefaultTradeSize;
    $scope.getStarted = getStarted;
    $scope.closeGetStarted = closeGetStarted;
    $scope.getPractice = getPractice;

    /**
     * Switches trade mode before login
     *
     * @return {null}
     */
    function switchTradeDemo() {
      // good
      $scope.broker.mode = $scope.settings.tradeDemo ? 'real' : 'demo';
      return null;
    }

    /**
     * Switch trade mode after login (not in use)
     */
    function switchLiveTradeMode() {
      if (btTradingService.getTradingMode() === 'demo') btTradingService.setTradingMode('real');
      else btTradingService.setTradingMode('demo');

      console.log('Broker', $scope.broker.data);
      console.log('Trading mode', $scope.broker.data.tradingMode);

      // reload state to be sure what all subsystem have been updated
      $ionicHistory.nextViewOptions({
        disableAnimate: true,
        disableBack: true,
      });

      $state.reload();
    }

    /**
     * Update default trade size on input change
     */
    function updateDefaultTradeSize() {
      btTradingService.setDefaultTradeSize($scope.settings.defaultTradeSize);
    }

    /**
     *
     */
    function getStarted() {
      if ($scope.adSettings) {
        btLinkService.openExternal($scope.adSettings.url);
      } else {
        $scope.broker.needHelp = true;
      }
    }

    /**
     *
     */
    function closeGetStarted() {
      $scope.broker.needHelp = false;
    }

    /**
     *
     */
    function getPractice() {
      $scope.selectBroker(btTradingService.getSupportedBrokers().oanda);
      $scope.openNewAccountForm();
    }
  }
})();
