/**
 * Created by Sergey Panpurin on 5/15/2017.
 */

(function btBrokerModeClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive display trading mode: real or demo.
     *
     * @ngdoc directive
     * @name btBrokerMode
     * @memberOf ecapp
     */
    .directive('btBrokerMode', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          scope: {},
          template: $templateCache.get('directives/broker/bt-broker-mode.html'),
          controller: ['$scope', '$timeout', 'btTradingService', btBrokerModeController],
        };
      },
    ]);

  /**
   *
   * @param {*} $scope
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.ITradingService} btTradingService
   */
  function btBrokerModeController($scope, $timeout, btTradingService) {
    $scope.isBrokerConnected = false;
    $scope.account = { id: null };

    btTradingService.initialize().then(btTradingService.connect).then(setupScope);

    $scope.$on('broker:disconnected', onBrokerChange);
    $scope.$on('broker:connected', onBrokerChange);
    $scope.$watch(btTradingService.getSelectedAccountId, onSelectedAccountChange);

    /**
     *
     * @param {*} event
     */
    function onBrokerChange(event) {
      void event;
      setupScope();
    }

    /**
     *
     */
    function onSelectedAccountChange() {
      $scope.account.id = btTradingService.getSelectedAccountId();
    }

    /**
     *
     */
    function setupScope() {
      $scope.isBrokerConnected = btTradingService.isConnected() && !btTradingService.isDefaultBroker();
      $scope.tradingMode = btTradingService.getBrokerName() === 'tradier' ? '' : btTradingService.getTradingMode();

      if (btTradingService.getSelectedAccountId()) {
        $timeout(function () {
          $scope.account.id = btTradingService.getSelectedAccountId();
          console.log('btBrokerMode:', $scope.account.id);
        });
      }
    }
  }
})();
