/**
 * Created by Sergei Panpurin on 9/5/18.
 */

(function btZoomableImageClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive displays list of trade ideas. User can select one of them.
     *
     * @ngdoc directive
     * @name btZoomableImage
     * @memberOf ecapp
     */
    .directive('btZoomableImage', btZoomableImage)
    .controller('btZoomableImageController', btZoomableImageController);

  btZoomableImage.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btZoomableImage($templateCache) {
    return {
      restrict: 'E',
      scope: {
        imageSrc: '@',
      },
      template: $templateCache.get('components/mobile/bt-zoomable-image/bt-zoomable-image.html'),
      controller: 'btZoomableImageController',
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btZoomableImageController.$inject = ['$scope'];

  /**
   *
   * @param {*} $scope
   */
  function btZoomableImageController($scope) {
    void $scope;

    /*jshint validthis: true*/
    var vm = this;

    vm.$onInit = onInit;
    vm.$onChanges = onChanges;
    vm.$onDestroy = onDestroy;

    vm.zoomImage = zoomImage;

    /**
     * Initialize component
     */
    function onInit() {
      vm.isZoomed = false;
      vm.titleText = getTitleText(vm.isZoomed);
    }

    /**
     * React on changes
     * @param {Object} changes
     */
    function onChanges(changes) {
      void changes;
    }

    /**
     * Destroy component
     */
    function onDestroy() {}

    /**
     *
     */
    function zoomImage() {
      vm.isZoomed = !vm.isZoomed;
      vm.titleText = getTitleText(vm.isZoomed);
    }

    /**
     *
     * @param {*} isZoomed
     * @return {any}
     */
    function getTitleText(isZoomed) {
      if (isZoomed) {
        return 'Click to close';
      } else {
        return 'Click to zoom';
      }
    }
  }
})();
