/**
 * Created by Sergey Panpurin on 3/16/19
 */

(function btNewsServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btNewsService';

  angular.module('ecapp').factory('btNewsService', service);

  service.$inject = ['Articles', 'btVoiceAssistantHelperService', 'btShareScopeService'];

  /**
   * This function is a implementation of btNewsService
   *
   * @param {angular.IGeneralLoopbackService} lbArticles
   * @param {btUtils.IVoiceAssistantHelperService} btVoiceAssistantHelperService
   * @param {btUtils.IShareScopeService} btShareScopeService
   * @return {ecapp.INewsService}
   */
  function service(lbArticles, btVoiceAssistantHelperService, btShareScopeService) {
    if (gDebug) console.log('Running btNewsService');

    return {
      notifyLatest: notifyLatest,
    };

    /**
     * This function adds custom channel
     *
     * @alias ecapp.btTwitterService#add
     * @return {angular.IPromise<*>}
     */
    function notifyLatest() {
      if (gDebug) console.log(gPrefix, 'getting latest articles');
      return lbArticles
      .getLatest()
      .$promise.then((res) => {
        const lastTimeUsed = btShareScopeService.getAccountInfoField('used')
        return btVoiceAssistantHelperService.pushNewsNotifications(res.latest.map(article => {
          return {
            n: article.name,
            t: article.title,
            l: article.link,
            d: article.description,
            silently: new Date(article.created) < new Date(lastTimeUsed),
            created: new Date(article.created)
          }
        }))
      })
      .catch(function (reason) {
        console.log(gPrefix, 'notifyLatest error -', reason);
      });
    }
  }
})();
