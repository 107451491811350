/**
 * Created by Sergey Panpurin on 08/07/19.
 */

// @ts-check
(function btLinkServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btLinkService:';

  /**
   * @ngdoc service
   * @name btLinkService
   * @memberOf btUtils
   * @description
   *  This service ...
   */

  angular.module('btUtils').factory('btLinkService', service);

  service.$inject = ['$state', 'btSharedData'];

  /**
   *
   * @param {angular.ui.IStateService} $state - ?
   * @param {ecapp.ISharedData} btSharedData - ?
   * @return {ecapp.ILinkService}
   */
  function service($state, btSharedData) {
    if (gDebug) console.log(gPrefix, 'running...');

    var gDesktopLinks = {
      'ecapp.app.pro-membership': { desktop: 'dashboard-pro-membership' },
      'ecapp.app.community': { desktop: 'dashboard-community' },
      'ecapp.app.chat': { desktop: 'dashboard-community' },
      'desktop.info-hub': { desktop: 'dashboard-info-hub' },
    };

    return {
      openExternal: openExternal,
      openInternal: openInternal,
      openSmart: openSmart,
    };

    /**
     * This function opens external link.
     *
     * @alias ecapp.btLinkService#openExternal
     * @param {string} link - external link
     * @return {InAppBrowser | WindowProxy | null}
     */
    function openExternal(link) {
      if (link) {
        var ref = window.open(link, '_system');
        return ref;
      } else {
        return null;
      }
    }

    /**
     * This function opens internal link.
     * According to screen size mobile or desktop version of link will be used. Desktop versions should predefine in
     * this service.
     *
     * @alias ecapp.btLinkService#openInternal
     * @param {string} state - state name
     * @param {Record<string, any>} [params] - state params
     * @return {angular.IPromise<void>}
     */
    function openInternal(state, params) {
      params = params || {};
      var link = gDesktopLinks[state];
      if (link) {
        if (window.isLarge) {
          return btSharedData.openDashboardTabByViewName(link.desktop);
        } else {
          return $state.go(state, params);
        }
      } else {
        return $state.go(state, params);
      }
    }

    /**
     * @typedef {btExternalLink|btInternalLink} btLink
     */

    /**
     * @typedef {string} btExternalLink
     */

    /**
     * @typedef {object} btInternalLink
     * @property {string} state - state name
     * @property {object} params - state parameters
     */

    /**
     * This function open smart link.
     *
     * @alias ecapp.btLinkService#openSmart
     * @param {btLink} link - smart link
     * @return {angular.IPromise<void> | InAppBrowser | WindowProxy | null}
     */
    function openSmart(link) {
      if (link) {
        if (typeof link === 'string') {
          if (gDebug) console.log(gPrefix, 'Open external link', link);
          return openExternal(link);
        } else if (typeof link === 'object' && link.state) {
          if (gDebug) console.log(gPrefix, 'Open internal link', link);
          return openInternal(link.state, link.params);
        } else {
          if (gDebug) console.log(gPrefix, 'Bad link', link);
          return null;
        }
      } else {
        if (gDebug) console.log(gPrefix, 'Empty link', link);
        return null;
      }
    }
  }
})();
