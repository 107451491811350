/**
 * Created by Orly on 20/01/2019.
 */

(function btBackdropClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive displays black droppable background when there is opened droppable popover
     *
     * @ngdoc directive
     * @name btBackdrop
     * @memberOf ecapp
     * @scope
     */
    .directive('btBackdrop', btBackdrop);

  btBackdrop.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btBackdrop($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('directives/common/bt-backdrop.html'),
      controller: ['$scope', '$templateCache', 'btPopoverService', btBackdropController],
    };
  }

  /**
   *
   * @param {*} $scope
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {ecapp.IPopoverService} btPopoverService
   */
  function btBackdropController($scope, $templateCache, btPopoverService) {
    $scope.hideBackdrop = hideBackdrop;
    $scope.status = btPopoverService.status();

    /**
     * This function hides all droppable popovers
     */
    function hideBackdrop() {
      btPopoverService.closeAll();
    }
  }
})();
