//@ts-check
(function () {
  'use strict';

  var gDebug = false;
  var gPrefix = 'UDFMarketDataInterceptor';
  
  var host = 'https://bt-api-server-staging.herokuapp.com';
  // if (window.isDevelopment) host = 'http://localhost:3005';

  var urlBase = host + '/api/udf';

  var module = angular.module('api', ['ngResource']);
  // var isWorking = true;

  var AUTH_HEADER = 'BT-API-KEY';
  var API_KEY = 'ZXdnam9WcGNnZkVnTTg3ZFl2dUhKUG5xTjJxZzZlT09mNGxmTVJubg';

  /**
   *
   * @param {angular.IRequestConfig} config
   * @return {boolean}
   */
  function isUDFMarketDataApi(config) {
    return config.url.includes(host);
  }

  module
    .factory('UDFMarketData', [
      '$resource',
      function ($resource) {
        return $resource(
          urlBase,
          {},
          {
            config: {
              url: urlBase + '/config',
              method: 'GET',
            },
            symbols: {
              url: urlBase + '/symbols',
              method: 'GET',
            },
            search: {
              isArray: true,
              url: urlBase + '/search',
              method: 'GET',
            },
            history: {
              url: urlBase + '/history',
              method: 'GET',
            },
            quotes: {
              url: urlBase + '/quotes',
              method: 'GET',
            },
            time: {
              url: urlBase + '/time',
              method: 'GET',
            },
          }
        );
      },
    ])
    .config([
      '$httpProvider',
      function ($httpProvider) {
        $httpProvider.interceptors.push('UDFMarketDataInterceptor');
      },
    ])
    .factory('UDFMarketDataInterceptor', [
      '$q',
      /**
       *
       * @param {angular.IQService} $q
       * @return {angular.IHttpInterceptor}
       */
      function ($q) {
        return {
          request: function (config) {
            if (isUDFMarketDataApi(config)) {
              if (gDebug) console.log(gPrefix, 'Request', config.url);
              config.headers[AUTH_HEADER] = atob(API_KEY + '==');
              // var err = new Error('AAAAA ' + config.url);
              // // @ts-ignore
              // err.config = config;
              // return $q.reject(err);
            }
            return config;
          },
          requestError: function (rejection) {
            /** @type {angular.IRequestConfig} */
            var config = rejection.config;
            if (isUDFMarketDataApi(config)) {
              if (gDebug) console.log(gPrefix, 'RequestError', config.url);
              return $q.reject(rejection);
            } else {
              return $q.reject(rejection);
            }
          },
          response: function (response) {
            /** @type {angular.IRequestConfig} */
            var config = response.config;
            if (isUDFMarketDataApi(config)) {
              if (gDebug) console.log(gPrefix, 'Response', config.url);
            }
            return response;
          },
          responseError: function responseError(rejection) {
            /** @type {angular.IRequestConfig} */
            var config = rejection.config;
            if (isUDFMarketDataApi(config)) {
              if (gDebug) console.log(gPrefix, 'ResponseError', config.url);
              // isWorking = false;
              return $q.reject(rejection);
            } else {
              return $q.reject(rejection);
            }
          },
        };
      },
    ]);
})();
