/**
 * Created by Eyal on 9/13/2016.
 */

// @ts-check
(function btDelegateMethodsServiceClosure() {
  'use strict';

  angular.module('btUtils').factory('btDelegateMethodsService', [btDelegateMethodsService]);

  /**
   * This factory is used in Scroll-To process.
   *
   * @ngdoc service
   * @name btDelegateMethodsService
   * @memberOf btUtils
   * @return {ecapp.IDelegateMethodsService}
   */
  function btDelegateMethodsService() {
    var delegateMethods = {};

    var addDelegateMethod = function (methodName, method) {
      delegateMethods[methodName] = method;
    };

    var getDelegateMethod = function (methodName) {
      return delegateMethods[methodName];
    };

    return {
      addDelegateMethod: addDelegateMethod,
      getDelegateMethod: getDelegateMethod,
    };
  }
})();
