/**
 * Created by Sergey on 2/18/20.
 */

(function btExpandableClosure() {
  'use strict';

  /**
   * This directive adds ability to expand some content.
   * It adds `isItemExpanded` variable to scope with default value `false` and two functions `expandItem` and `collapseItem`.
   *
   * @ngdoc directive
   * @name btExpandable
   * @memberOf ecapp
   * @restrict A
   * @see ecapp.btCollapsible
   * @scope
   */

  /**
   * This controller process notification center.
   *
   * @ngdoc controller
   * @name btExpandableController
   * @memberOf ecapp
   */

  /** Directive */
  angular
    .module('ecapp')
    .directive('btExpandable', angularDirective)
    .controller('btExpandableController', angularController);

  angularDirective.$inject = ['$templateCache'];

  /**
   * Implementation of btExpandable directive
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function angularDirective($templateCache) {
    return {
      restrict: 'A',
      scope: false,
      controller: 'btExpandableController',
    };
  }

  angularController.$inject = ['$scope'];

  /**
   * Implementation of btExpandableController controller
   *
   * @param {ecapp.ICustomScope} $scope
   */
  function angularController($scope) {
    $scope.$on('$destroy', onDestroy);

    activate();

    /**
     * This function is a controller constructor.
     */
    function activate() {
      if ($scope.isItemExpanded !== undefined || $scope.expandItem !== undefined || $scope.collapseItem !== undefined) {
        alert('Conflict! Directive btExpandable or btCollapsible is already used on parent scope.');
      } else {
        $scope.isItemExpanded = false;

        $scope.expandItem = function () {
          $scope.isItemExpanded = true;
        };

        $scope.collapseItem = function () {
          $scope.isItemExpanded = false;
        };
      }
    }

    /**
     * This function is a controller destructor.
     */
    function onDestroy() {}
  }
})();
