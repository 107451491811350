(function btCommunityTabControllerClosure() {
  'use strict';

  // var gDebug = false;
  // var gPrefix = 'btCommunityController';

  angular
    .module('ecapp')
    .controller('btCommunityPageController', [
      '$scope',
      function ($scope) {
        void $scope;
        console.log('test');
      },
    ])
    .controller('btChatPageController', [
      '$scope',
      function ($scope) {
        void $scope;
        console.log('test');
      },
    ]);
})();
