/**
 * Created by Sergey Panpurin on 5/7/2018.
 */

(function btRangeMeterClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btRangeMeter Directive:';

  angular
    .module('btUI')
    /**
     * This directive represents range meter. Attribute `update` can be used to indicate that parameters were updated.
     *
     * @ngdoc directive
     * @name btRangeMeter
     * @memberOf btUI
     * @param {btRangeMeterParameters} params - parameters
     */
    .directive('btRangeMeter', btRangeMeter);

  btRangeMeter.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btRangeMeter($templateCache) {
    return {
      restrict: 'E',
      scope: {
        params: '=?',
        update: '@?',
      },
      template: $templateCache.get('components/mobile/bt-range-meter/bt-range-meter.html'),
      controller: btRangeMeterController,
      controllerAs: 'vm',
      bindToController: true, //,
      // link: btRangeMeterLink
    };
  }

  btRangeMeterController.$inject = ['$scope', '$btRangeMeter'];

  /**
   *
   * @param {*} $scope
   * @param {btUI.$btRangeMeter} $btRangeMeter
   */
  function btRangeMeterController($scope, $btRangeMeter) {
    /*jshint validthis: true*/
    var vm = this;

    if (gDebug) console.log(gPrefix, 'Controller initializing...');

    /** @type {btRangeMeterParameters} */
    vm.params = vm.params || undefined;
    vm.update = vm.update || undefined;
    vm.meter = undefined;

    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;

    $scope.$watch('vm.params', onItemsChanges);
    $scope.$watch('vm.update', onUpdateChanges);

    if (gDebug) console.log(gPrefix, 'Controller was initialized.');

    /**
     * This function is called during component initialization.
     */
    function onInit() {
      /** @type {string} Unique identifier for range meter*/
      vm.id = $btRangeMeter.getUniqueID();
      vm.meter = $btRangeMeter.getPlaceholder();

      if (gDebug) console.log(gPrefix, 'Component #' + vm.id + ' initializing...');

      if ($btRangeMeter.checkIsParamsValid(vm.params)) {
        vm.meter = $btRangeMeter.prepareData(vm.id, vm.params);
        $btRangeMeter.drawMeter(vm.meter, vm.params);
      } else {
        if (gDebug) console.log(gPrefix, 'Component #' + vm.id + ' has invalid parameters.');
      }

      if (gDebug) console.log(gPrefix, 'Component #' + vm.id + ' was initialized.');
    }

    /**
     * This function is called during component destruction.
     */
    function onDestroy() {
      if (gDebug) console.log(gPrefix, 'Component #' + vm.id + ' was destroyed.');
    }

    /**
     * This function reacts on new parameters.
     *
     * @param {btRangeMeterParameters} current - current value
     * @param {btRangeMeterParameters} original - original value
     */
    function onItemsChanges(current, original) {
      if (current !== original && current !== undefined) {
        if ($btRangeMeter.checkIsParamsValid(vm.params)) {
          vm.meter = $btRangeMeter.prepareData(vm.id, vm.params);
          $btRangeMeter.drawMeter(vm.meter, vm.params);
        } else {
          if (gDebug) console.log(gPrefix, 'Component #' + vm.id + ' has invalid parameters.');
        }
      }
    }

    /**
     * This function reacts on update.
     *
     * @param {string} current - current value
     * @param {string} original - original value
     */
    function onUpdateChanges(current, original) {
      if (current !== original && current !== undefined) {
        vm.meter = $btRangeMeter.prepareData(vm.id, vm.params);
        $btRangeMeter.drawMeter(vm.meter, vm.params);
      }
    }
  }
})();
