(function () {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive is a transclude directive which hold sub-header type items (such as related items, historic graph and more.
     *
     * @ngdoc directive
     * @name btUnrealizedMarginBar
     * @memberOf ecapp
     */
    .directive('btUnrealizedMarginBar', btUnrealizedMarginBar)
    .controller('btUnrealizedMarginBarController', btUnrealizedMarginBarController);

  btUnrealizedMarginBar.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {any}
   */
  function btUnrealizedMarginBar($templateCache) {
    return {
      restrict: 'E',
      template: $templateCache.get('directives/common/bt-unrealized-margin-bar.html'),
      replace: true,
      scope: {
        showMarginBar: '=',
      },
      controller: 'btUnrealizedMarginBarController',
    };
  }

  btUnrealizedMarginBarController.$inject = ['$scope', 'btTradingService', '$timeout'];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.ITradingService} btTradingService
   * @param {angular.ITimeoutService} $timeout
   */
  function btUnrealizedMarginBarController($scope, btTradingService, $timeout) {
    var accountKeys = [];
    $scope.totalUPL = null;
    $scope.totalValue = null;
    $scope.totalMarginAvailable = null;
    $scope.totalMarginUsed = null;
    $scope.isLoading = true;

    $scope.brokerData = btTradingService.getBrokerInfo();

    btTradingService.getAccounts().then(function (accounts) {
      accountKeys = accounts.map(function (a) {
        return a.key;
      });
      btTradingService.getBalances(accountKeys).then(function (balances) {
        var balance = balances.find(function (result) {
          return btTradingService.isAccountSelected(result.key);
        });
        if (balance) {
          $scope.totalUPL = balance.UPL;
          $scope.totalValue = balance.NAV;
          $scope.totalMarginAvailable = balance.MarginAvailable;
          $scope.totalMarginUsed = balance.MarginUsed;
          $scope.isLoading = false;
        } else {
          $scope.totalUPL = null;
          $scope.totalValue = null;
          $scope.totalMarginAvailable = null;
          $scope.totalMarginUsed = null;
        }

        $timeout(function () {
          $scope.showMarginBar = false;
        }, 10000);
      });
    });
  }
})();
