/**
 * Created by Sergey Panpurin on 8/1/2019.
 */

(function btAdBannerClosure() {
  'use strict';

  /**
   * @ngdoc directive
   * @name btAdBanner
   * @memberOf ecapp
   * @restrict E
   * @description
   *  This directive displays notification center button.
   * @scope
   * @param {string} bannerType - type of banner
   */

  /**
   * @ngdoc controller
   * @name btAdBannerController
   * @memberOf ecapp
   * @description
   *  This controller process notification center.
   */

  /** Directive */
  angular
    .module('ecapp')
    .directive('btAdBanner', angularDirective)
    .controller('btAdBannerController', angularController);

  angularDirective.$inject = ['$templateCache'];

  /**
   * Implementation of Notification Center Directive
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function angularDirective($templateCache) {
    return {
      restrict: 'E',
      scope: {
        bannerType: '@',
        // bannerSource: '@'
      },
      template: $templateCache.get('directives/advertising/bt-ad-banner.html'),
      controller: 'btAdBannerController',
    };
  }

  angularController.$inject = ['$scope', '$sce', 'btAdvertisingService'];

  /**
   * Implementation of Notification Center Controller
   *
   * @param {ecapp.ICustomScope} $scope - ?
   * @param {angular.ISCEService} $sce - ?
   * @param {ecapp.IAdvertisingService} btAdvertisingService - ?
   */
  function angularController($scope, $sce, btAdvertisingService) {
    // var types = ['desktop-bottom', 'desktop-side', 'mobile-card'];
    // var sources = ['avatrade', 'ig', 'cfx'];

    // By default, banner is hidden
    $scope.isVisible = undefined;

    // Banner is defined as link and image url.
    $scope.isImageLink = undefined;
    $scope.link = '';
    $scope.image = '';

    // Banner is defined as html code.
    $scope.isHtmlCode = undefined;
    $scope.banner = '';

    $scope.resetAdvertising = btAdvertisingService.reset;

    // The rest of the activation
    $scope.$on('$destroy', onDestroy);

    activate();

    /**
     * Controller constructor
     */
    function activate() {
      btAdvertisingService.initialize().then(function () {
        var settings = btAdvertisingService.getBannerSettings($scope.bannerType);

        $scope.bannerSource = settings.id;
        $scope.isVisible = settings.enabled;

        if (!!settings.html) {
          // html code
          $scope.isImageLink = false;
          $scope.isHtmlCode = true;

          if (typeof settings.html === 'object') {
            $scope.banner = {
              small: $sce.trustAsHtml(settings.html.small),
              large: $sce.trustAsHtml(settings.html.large),
            };
          } else {
            $scope.banner = $sce.trustAsHtml(settings.html);
          }
        } else {
          // link and image url
          $scope.isImageLink = true;
          $scope.isHtmlCode = false;

          $scope.image = settings.image;
          $scope.link = settings.url ? $sce.trustAsResourceUrl(settings.url) : null;
        }
      });
    }

    /**
     * On destroy
     */
    function onDestroy() {}
  }
})();
