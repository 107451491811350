/**
 * Created by Sergey Panpurin on 1/13/19.
 */

(function btOnEnterClosure() {
  'use strict';

  // var gDebug = false;

  angular.module('btUtils').directive('btOnEnter', btOnEnter);

  btOnEnter.$inject = ['$parse'];

  /**
   *
   * @param {angular.IParseService} $parse - ?
   * @return {angular.IDirective}
   */
  function btOnEnter($parse) {
    return {
      restrict: 'A',
      controller: btOnEnterController,
      link: linkFunction,
    };

    /**
     *
     * @param {*} scope - ?
     * @param {*} elem - ?
     * @param {*} attrs - ?
     */
    function linkFunction(scope, elem, attrs) {
      elem.bind('keydown', function (e) {
        var code = e.keyCode || e.which;
        if (code === 13) {
          e.preventDefault();
          var expressionHandler = $parse(attrs.btOnEnter);

          if (typeof expressionHandler === 'function') {
            expressionHandler(scope);
          }
        }
      });
    }
  }

  btOnEnterController.$inject = ['$scope'];

  /**
   *
   * @param {ecapp.ICustomScope} $scope - ?
   */
  function btOnEnterController($scope) {
    $scope.selectInput = selectInput;
    $scope.deselectInput = deselectInput;

    /**
     *
     * @param {*} elementId - ?
     * @param {*} containerId - ?
     */
    function selectInput(elementId, containerId) {
      console.log('Select', elementId, 'at', containerId);
      var element = $('#' + elementId);
      element.focus();
      var elementOffset = element.offset();

      if (containerId) {
        var container = $('#' + containerId);
        var containerOffset = container.offset();

        console.log('Scroll ', container.scrollTop(), elementOffset.top, containerOffset.top);
        container.scrollTop(container.scrollTop() + (elementOffset.top - containerOffset.top) - 50);
      }
    }

    /**
     *
     * @param {*} elementId - ?
     * @param {*} containerId - ?
     */
    function deselectInput(elementId, containerId) {
      console.log('Deselect', elementId, 'at', containerId);
      var element = $('#' + elementId);
      element.blur();

      if (containerId) {
        var container = $('#' + containerId);
        container.scrollTop(0);
      }
    }
  }
})();
