/**
 * Created by Sergey Panpurin on 5/6/2018.
 */

(function btFeedServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btFeedService';

  angular
    .module('ecapp')
    /**
     * This ???
     *
     * @ngdoc service
     * @name btFeedService
     * @memberOf ecapp
     */
    .factory('btFeedService', ['$timeout', 'btDateService', btFeedService]);

  /**
   *
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.IDateService} btDateService
   * @return {angular.IDirective}
   */
  function btFeedService($interval, btDateService) {
    if (gDebug) console.log('Running btFeedService');

    var gDefaultAutoUpdateInterval = 30000;

    /**
     *
     * @param {*} items
     * @param {*} settings
     * @return {any}
     */
    function prepareCards(items, settings) {
      var collapseSize = settings.collapseSize;
      var templates = settings.templates;

      var cards = items.filter(hasRequiredFields).map(convertToCard.bind(null, templates.card));

      cards.sort(function (a, b) {
        return b.data.time - a.data.time;
      });

      markCollapsedCards(cards, collapseSize);

      return cards;
    }

    /**
     *
     * @param {*} cards
     * @param {*} collapseSize
     * @return {any}
     */
    function startAutoUpdate(cards, collapseSize) {
      return $interval(function () {
        var now = btDateService.getNowDate();
        cards.forEach(function (card) {
          card.classes = [card.date < now ? 'bt-past' : 'bt-future'];
        });
        markCollapsedCards(cards, collapseSize);
      }, gDefaultAutoUpdateInterval);
    }

    /**
     *
     * @param {*} promise
     * @return {any}
     */
    function stopAutoUpdate(promise) {
      return $interval.cancel(promise);
    }

    /**
     *
     * @param {*} cards
     * @param {*} collapseSize
     */
    function markCollapsedCards(cards, collapseSize) {
      // number of cards in feed less than size of collapsed view - do nothing
      if (cards.length <= collapseSize) {
        return;
      }

      setCardsShow(cards, 0, collapseSize);
    }

    /**
     *
     * @param {*} cards
     * @param {*} start
     * @param {*} stop
     */
    function setCardsShow(cards, start, stop) {
      cards.forEach(function (t, i) {
        t.show = i >= start && i < stop;
      });
    }

    /**
     *
     * @param {*} template
     * @param {*} item
     * @return {any}
     */
    function convertToCard(template, item) {
      var now = btDateService.getNowDate();
      var date = btDateService.getDateFromRow(item);
      return {
        id: item.id,
        show: true,
        marked: true,
        date: btDateService.getDateFromRow(item),
        classes: [date < now ? 'bt-past' : 'bt-future'],
        template: template,
        day: btDateService.getDayName(btDateService.getDateFromRow(item)),
        data: item,
      };
    }

    /**
     *
     * @param {*} item
     * @return {any}
     */
    function hasRequiredFields(item) {
      return item.id !== undefined && item.time !== undefined;
    }

    return {
      prepareCards: prepareCards,
      startAutoUpdate: startAutoUpdate,
      stopAutoUpdate: stopAutoUpdate,
    };
  }
})();
