/**
 * Created by Itay on 6/14/2016.
 */

(function () {
  'use strict';

  angular
    .module('ecapp')
    /**
     * ???
     * @ngdoc directive
     * @name btEventModalCardRevision
     * @memberOf ecapp
     */
    .directive('btEventModalCardRevision', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/event-modal/event-card/revision.html'),
          controller: [
            '$scope',
            function ($scope) {
              $scope.showFullMsg = window.localStorage.getItem('showFullMsg') === 'true';
            },
          ],
        };
      },
    ]);
})();
