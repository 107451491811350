/**
 * Created by Orly on 10/02/19.
 */

(function btButton() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive displays button
     *
     * @ngdoc directive
     * @name btButton
     * @memberOf ecapp
     * @scope
     * @param {string} clickFunction
     * @param {*} argument
     */
    .directive('btButton', btButton);

  btButton.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btButton($templateCache) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        styleClass: '@',
        label: '@?',
        icon: '@?',
        clickFunction: '&?',
        argument: '=?',
      },
      template: $templateCache.get('directives/common/bt-button.html'),
      controller: ['$scope', btButtonController],
    };
  }

  /**
   *
   * @param {*} $scope
   */
  function btButtonController($scope) {
    $scope.onClick = onClick;

    /**
     *
     */
    function onClick() {
      if ($scope.clickFunction) {
        console.log('btButton: onClick', $scope.clickFunction(), $scope.argument);
        $scope.clickFunction()($scope.argument);
      }
    }
  }
})();
