// cspell:words accuracycorrelation avgRARBSCORE avgcorrSCORE erroravgRARB erroravgcorr simpleerror simpleerrorSCORE negcorr neucorr poscorr

// @ts-check
(function btRequestServiceClosure() {
  'use strict';

  angular
    .module('ecapp')
    .factory('btRequestService', ['$http', '$q', '$timeout', 'CacheFactory', 'btSettings', btRequestService]);

  /**
   * @ngdoc service
   * @name btRequestService
   * @memberOf dashboard
   * @description
   *  ???
   * @param {angular.IHttpService} $http
   * @param {angular.IQService} $q
   * @param {angular.ITimeoutService} $timeout
   * @param {angular.ICacheFactoryService} CacheFactory
   * @param {ecapp.ISettings} btSettings
   * @return {ecapp.IRequestService}
   */
  function btRequestService($http, $q, $timeout, CacheFactory, btSettings) {
    console.log('Running btRequest Service');

    var gUrl, gToken;

    initialize();

    var gStocksCache = CacheFactory('stocks', {
      storageMode: 'localStorage',
      maxAge: 24 * 60 * 60 * 1000,
      deleteOnExpire: 'aggressive',
      storagePrefix: 'bt.caches.',
      onExpire: function (key, value) {
        getStockInstruments()
          .then(function () {
            console.log('Cache: stocks.' + key + ' was refreshed!');
          })
          .catch(function (reason) {
            console.error('Cache: stocks.' + key + ' refresh failed!', reason);
            window.instrumentsCache.put(key, value);
          });
      },
    });

    return {
      getATR: getATR,

      getStockData: getStockData,
      getStocksData: getStocksData,

      getStockNormalizeData: getStockNormalizeData,
      getStocksNormalizeData: getStocksNormalizeData,
      getStocksCorrelation: getStocksCorrelation,
      getStocksPrediction: getStocksPrediction,
      getStocksSingles: getStocksSingles,

      getStockInstruments: getStockInstruments,

      getReleaseData: getReleaseData,
      getReleaseDataList: getReleaseDataList,

      getLast: getLast,
      getCorrelationMixed: getCorrelationMixed,
    };

    /**
     * This function initialize snapshot url and token.
     */
    function initialize() {
      // due to receiving sensitive data from cache or after login
      if (!btSettings.BT_SNAPSHOT_URL || !btSettings.BT_SNAPSHOT_TOKEN) {
        $timeout(initialize, 2000, false);
      } else {
        // console.log('TEST: Initialize Snapshot API');
        gUrl = btSettings.BT_SNAPSHOT_URL;
        gToken = btSettings.BT_SNAPSHOT_TOKEN;
      }
    }

    /**
     *
     * @param {*} url
     * @return {any}
     */
    function httpObject(url) {
      return {
        url: url,
        dataType: 'json',
        async: true,
        method: 'GET',
        headers: {
          Authorization: 'Basic ' + btoa(gToken + ':test'),
        },
        password: '',
      };
    }

    /**
     *
     * @param {*} url
     * @param {*} params
     * @param {*} cachedData
     * @param {*} processData
     * @return {any}
     */
    function getData(url, params, cachedData, processData) {
      var deferred = $q.defer();

      if (cachedData) {
        deferred.resolve(cachedData);
      } else {
        var config = {
          url: url,
          dataType: 'json',
          async: true,
          method: 'GET',
          headers: {
            Authorization: 'Basic ' + btoa(gToken + ':test'),
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          params: params,
        };

        $http(config).then(
          function (response) {
            var res = processData(response);
            // $timeout(function() {
            deferred.resolve(res);
            // }, Math.random() * 1000);
          },
          function (error) {
            console.log('Problem with data from ' + url + ' ' + error);
            console.log(params);
            deferred.reject(error);
          }
        );
      }

      return deferred.promise;
    }

    /**
     *
     * @param {*} url
     * @param {*} data
     * @param {*} cachedData
     * @param {*} processData
     * @return {any}
     */
    function postData(url, data, cachedData, processData) {
      var deferred = $q.defer();

      if (cachedData) {
        deferred.resolve(cachedData);
      } else {
        var config = {
          url: url,
          method: 'POST',
          dataType: 'json',
          async: true,
          headers: {
            Authorization: 'Basic ' + btoa(gToken + ':test'),
          },
          data: JSON.stringify(data),
        };

        $http(config).then(
          function (response) {
            var res = processData(response.data);
            deferred.resolve(res);
          },
          function (error) {
            console.log('Problem with data from ' + url + ' ' + error);
            console.log(data);
            deferred.reject(error);
          }
        );
      }

      return deferred.promise;
    }

    /**
     *
     * @param {*} stock
     * @param {*} dateFrom
     * @param {*} dateTo
     * @param {*} interval
     * @return {any}
     */
    function getStockData(stock, dateFrom, dateTo, interval) {
      void interval;
      if (!gUrl) return $q.reject(new Error('Snapshot URL is not defined.'));

      var url = gUrl + 'market/' + stock + '/from/' + dateFrom + '/to/' + dateTo + '/snapshot?interval=1&output=all';

      return getData(url, {}, null, function (response) {
        var result = [];
        var timestamps = [];
        angular.forEach(response.data.values, function (value) {
          timestamps.push(value[0]);
          result.push(value[1][3]);
        });
        return [result, timestamps];
      });
    }

    /**
     *
     * @param {*} stock
     * @param {*} stock1
     * @param {*} dateFrom
     * @param {*} dateTo
     * @param {*} interval
     * @return {any}
     */
    function getStocksData(stock, stock1, dateFrom, dateTo, interval) {
      void interval;
      if (!gUrl) return $q.reject(new Error('Snapshot URL is not defined.'));

      var deferred = $q.defer();
      var url =
        gUrl +
        'market/' +
        stock +
        '/' +
        stock1 +
        '/from/' +
        dateFrom +
        '/to/' +
        dateTo +
        '/snapshot?interval=60&output=close&regular=1';

      $http(httpObject(url))
        .success(function (response) {
          deferred.resolve([response.values['time'], response.values['data'], response.values['data1']]);
        })
        .error(function (error) {
          console.log('Problem with data ' + error);
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     *
     * @param {*} stock
     * @param {*} dateFrom
     * @param {*} dateTo
     * @param {*} interval
     * @return {any}
     */
    function getStockNormalizeData(stock, dateFrom, dateTo, interval) {
      void interval;
      if (!gUrl) return $q.reject(new Error('Snapshot URL is not defined.'));

      var url =
        gUrl +
        'market/' +
        stock +
        '/from/' +
        dateFrom +
        '/to/' +
        dateTo +
        '/snapshot?interval=60&output=close&normalize=1';

      return getData(url, {}, null, function (response) {
        var result = [];
        angular.forEach(response.data.values, function (value) {
          result.push(value);
        });
        return result;
      });
    }

    /**
     *
     * @param {*} stock
     * @param {*} stock1
     * @param {*} offset
     * @param {*} dateFrom
     * @param {*} dateTo
     * @param {*} interval
     * @return {any}
     */
    function getStocksNormalizeData(stock, stock1, offset, dateFrom, dateTo, interval) {
      void interval;
      if (!gUrl) return $q.reject(new Error('Snapshot URL is not defined.'));

      var deferred = $q.defer();
      var url =
        gUrl +
        'market/' +
        stock +
        '/' +
        stock1 +
        '/from/' +
        dateFrom +
        '/to/' +
        dateTo +
        '/snapshot?interval=60&output=close&normalize_stocks=1' +
        '&offset=' +
        offset;

      $http(httpObject(url))
        .success(function (response) {
          var result = [];
          angular.forEach(response.values, function (value) {
            result.push(value);
          });

          deferred.resolve(result);
        })
        .error(function (error) {
          console.log('Problem with normal data ' + error);
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     *
     * @param {*} stock
     * @param {*} stock1
     * @param {*} coefficient
     * @param {*} offset
     * @param {*} dateFrom
     * @param {*} dateTo
     * @param {*} interval
     * @return {any}
     */
    function getStocksCorrelation(stock, stock1, coefficient, offset, dateFrom, dateTo, interval) {
      void interval;
      if (!gUrl) return $q.reject(new Error('Snapshot URL is not defined.'));

      var deferred = $q.defer();
      var url =
        gUrl +
        'market/' +
        stock +
        '/' +
        stock1 +
        '/from/' +
        dateFrom +
        '/to/' +
        dateTo +
        '/snapshot?interval=60&output=close&correlation=1&coefficient=' +
        coefficient +
        '&offset=' +
        offset;

      $http(httpObject(url))
        .success(function (response) {
          var result = [];
          angular.forEach(response.values['data'], function (value) {
            result.push(value);
          });
          deferred.resolve(result);
        })
        .error(function (error) {
          console.log('Problem with normal data ' + error);
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     *
     * @param {*} stock
     * @param {*} stock1
     * @param {*} coefficient
     * @param {*} offset
     * @param {*} dateFrom
     * @param {*} dateTo
     * @param {*} interval
     * @return {any}
     */
    function getStocksPrediction(stock, stock1, coefficient, offset, dateFrom, dateTo, interval) {
      void interval;
      if (!gUrl) return $q.reject(new Error('Snapshot URL is not defined.'));

      var deferred = $q.defer();
      var url =
        gUrl +
        'market/' +
        stock +
        '/' +
        stock1 +
        '/from/' +
        dateFrom +
        '/to/' +
        dateTo +
        '/snapshot?interval=60&output=close&predict=1&coefficient=' +
        coefficient +
        '&offset=' +
        offset;

      $http(httpObject(url))
        .success(function (response) {
          var result = [];
          angular.forEach(response.values['data'], function (value) {
            result.push(value);
          });
          deferred.resolve(result);
        })
        .error(function (error) {
          console.log('Problem with normal data ' + error);
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     *
     * @param {*} stock
     * @param {*} stock1
     * @param {*} coefficient
     * @param {*} offset
     * @param {*} dateFrom
     * @param {*} dateTo
     * @param {*} interval
     * @return {any}
     */
    function getStocksSingles(stock, stock1, coefficient, offset, dateFrom, dateTo, interval) {
      void interval;
      if (!gUrl) return $q.reject(new Error('Snapshot URL is not defined.'));

      var deferred = $q.defer();
      var url =
        gUrl +
        'market/' +
        stock +
        '/' +
        stock1 +
        '/from/' +
        dateFrom +
        '/to/' +
        dateTo +
        '/snapshot?interval=60&output=close&singles=1&coefficient=' +
        coefficient +
        '&offset=' +
        offset;

      $http(httpObject(url))
        .success(function (response) {
          var result = [
            {
              name: 'accuracycorrelation',
              value: response.values['accuracycorrelation'],
            },
            {
              name: 'avgRARBSCORE',
              value: response.values['avgRARBSCORE'],
            },
            { name: 'avgcorrSCORE', value: response.values['avgcorrSCORE'] },
            { name: 'erroravgRARB', value: response.values['erroravgRARB'] },
            { name: 'erroravgcorr', value: response.values['erroravgcorr'] },
            { name: 'simpleerror', value: response.values['simpleerror'] },
            { name: 'simpleerrorSCORE', value: response.values['simpleerrorSCORE'] },
            { name: 'negcorr', value: response.values['negcorr'] },
            { name: 'neucorr', value: response.values['neucorr'] },
            { name: 'poscorr', value: response.values['poscorr'] },
          ];
          /*angular.forEach (response.values['data'], function (value, key) {
           result.push(value);
           })*/
          deferred.resolve(result);
        })
        .error(function (error) {
          console.log('Problem with normal data ' + error);
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     *
     * @return {any}
     */
    function getStockInstruments() {
      if (!gUrl) return $q.reject(new Error('Snapshot URL is not defined.'));

      var url = gUrl + 'market/instruments';

      return getData(url, {}, gStocksCache.get('instruments'), function (response) {
        var temp = response.data['instruments'].sort();
        gStocksCache.put('instruments', temp);
        return temp;
      });
    }

    /**
     *
     * @param {*} stock
     * @param {*} date
     * @param {*} params
     * @return {any}
     */
    function getReleaseData(stock, date, params) {
      if (!gUrl) return $q.reject(new Error('Snapshot URL is not defined.'));

      var url;
      if (typeof date === 'string') {
        url = gUrl + 'release/' + stock + '/' + date + '/snapshot';
      } else {
        url = gUrl + 'release/' + stock + '/snapshot';
        params.timestamp = date;
      }

      return getData(url, params, null, function (response) {
        return response.data;
      });
    }

    /**
     *
     * @param {*} data
     * @return {any}
     */
    function getReleaseDataList(data) {
      if (!gUrl) return $q.reject(new Error('Snapshot URL is not defined.'));

      var url = gUrl + 'release/snapshot';
      var res = [],
        i,
        chunk = 10;

      for (i = 0; i < data.times.length; i += chunk) {
        var params = {
          symbols: [data.stock.name],
          times: data.times.slice(i, i + chunk),
          before: data.before,
          after: data.after,
          currency_direct: data.direction,
          strengths: data.strengths.slice(i, i + chunk),
          thr_strength: data.thr_strength,
          thr_reaction: data.thr_reaction,
          offset: data.offset,
        };
        res.push(
          postData(url, params, null, function (response) {
            // print(response);
            return response.list;
          })
        );
      }

      return res;
    }

    /**
     * This function returns ATR fo specific instrument and specific moment.
     *
     * @param {String} symbol - instrument symbol
     * @param {Number} interval - candle time interval
     * @param {Number} timestamp - timestamp of specific moment
     * @return {angular.IPromise<*>}
     */
    function getATR(symbol, interval, timestamp) {
      if (!gUrl) return $q.reject(new Error('Snapshot URL is not defined.'));

      var url = gUrl + 'market/' + symbol + '/volatility/value';
      return getData(url, { interval: interval, timestamp: timestamp }, null, function (response) {
        return response['data'];
      });
    }

    /**
     *
     * @param {*} stock
     * @param {*} number
     * @return {any}
     */
    function getLast(stock, number) {
      if (!gUrl) return $q.reject(new Error('Snapshot URL is not defined.'));

      var url = gUrl + 'market/' + stock + '/last/' + number;
      return getData(url, null, null, function (response) {
        return response['data']['data'];
      });
    }

    /**
     *
     * @param {*} stock1
     * @param {*} stock2
     * @param {*} coefficient
     * @param {*} offset
     * @param {*} dateFrom
     * @param {*} dateTo
     * @param {*} bars
     * @param {*} interval
     * @return {any}
     */
    function getCorrelationMixed(stock1, stock2, coefficient, offset, dateFrom, dateTo, bars, interval) {
      if (!gUrl) return $q.reject(new Error('Snapshot URL is not defined.'));

      var deferred = $q.defer();
      // TODO: Changes hardcoded bars
      var url =
        gUrl +
        'market/' +
        stock1 +
        '/' +
        stock2 +
        '/from/' +
        dateFrom +
        '/to/' +
        dateTo +
        '/' +
        bars +
        '/snapshot?interval=' +
        interval +
        '&correlation75=1&correlation84=1&correlation95=1&coefficient=' +
        coefficient +
        '&offset=' +
        offset +
        '&predict=1&singles=1';

      $http(httpObject(url))
        .success(function (response) {
          deferred.resolve(response);
        })
        .error(function (error) {
          console.log('Problem with normal data ' + error);
          deferred.reject(error);
        });

      return deferred.promise;
    }
  }
})();
