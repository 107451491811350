/**
 * Created by Sergei Panpurin on 8/21/2019.
 */

(function btRiskIndicatorClosure() {
  'use strict';

  /**
   * This directive displays notification center button.
   *
   * @ngdoc directive
   * @name btRiskIndicator
   * @module ecapp
   * @restrict E
   * @scope
   * @attribute hasIcons
   */

  /**
   * This controller process notification center.
   *
   * @ngdoc controller
   * @name btRiskIndicatorController
   * @module ecapp
   */

  /** Directive */
  angular
    .module('ecapp')
    .directive('btRiskIndicator', angularDirective)
    .controller('btRiskIndicatorController', angularController);

  angularDirective.$inject = ['$templateCache'];

  /**
   * Implementation of Notification Center Directive
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function angularDirective($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('directives/risks/bt-risk-indicator.html'),
      controller: 'btRiskIndicatorController',
    };
  }

  angularController.$inject = [
    '$scope',
    '$attrs',
    '$ionicModal',
    '$templateCache',
    'btRiskService',
    'btToastrService',
    'btRestrictionService',
    'btSettingsService',
  ];

  /**
   * @typedef {angular.IScope} btRiskIndicatorScope
   * @property {boolean} hasIcons - whether icons should be displayed
   * @property {string} chartId - the unique chart identifier
   * @property {boolean} isInitialized -  whether risk service is initialized
   * @property {boolean} isRiskSettingsUpdating -  whether risk settings is updating
   * @property {object} selected -  one of the baskets from the list
   * @property {ecapp.IRiskInterval[]} intervals - list of intervals
   * @property {ecapp.IRiskInterval} refInterval - current interval from risk service
   * @property {btRiskBasket[]} baskets -  list of baskets
   * @property {ecapp.IRiskBasket} refBasket -  current basket from risk service
   * @property {object} chartGauge - chart configuration
   * @property {object} renderGauge - chart rendering settings
   * @property {function} checkIsLoading - checks if risk data is loading
   * @property {function} selectBasket - selects a new basket
   * @property {function} selectInterval - selects a new interval
   * @property {function} showRiskSettings - shows risk settings dialog
   */

  /**
   * Implementation of Notification Center Controller
   *
   * @param {btRiskIndicatorScope} $scope
   * @param {angular.IAttributes} $attrs
   * @param {ionic.IModalService} $ionicModal - ionic modal service
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {ecapp.IRiskService} btRiskService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function angularController(
    $scope,
    $attrs,
    $ionicModal,
    $templateCache,
    btRiskService,
    btToastrService,
    btRestrictionService,
    btSettingsService
  ) {
    $scope.hasIcons = $attrs.hasIcons === 'true';

    $scope.chartId = btRiskService.getChartId('risk-indicator');

    $scope.isInitialized = false;
    $scope.isRiskSettingsUpdating = false;

    $scope.isBigBrainBank = btSettingsService.getDomain() === 'bigbrainbank';

    $scope.selected = {
      basket: undefined,
      interval: undefined,
    };

    $scope.intervals = btRiskService.getIntervals();
    $scope.refInterval = btRiskService.getSelectedInterval();

    $scope.baskets = btRiskService.getBaskets();
    $scope.refBasket = btRiskService.getSelectedBasket();

    $scope.chartGauge = btRiskService.getSmartRiskChart();
    $scope.renderGauge = { output: 'canvas' };

    $scope.checkIsLoading = btRiskService.checkIsLoading;
    $scope.selectBasket = selectBasket;
    $scope.selectInterval = selectInterval;
    $scope.showRiskSettings = showRiskSettings;
    $scope.hasRiskFeature = hasRiskFeature;
    $scope.upgradeRiskFeature = upgradeRiskFeature;

    $scope.$watch('refInterval.id', onIntervalChange);
    $scope.$watch('refBasket.id', onBasketChange);
    $scope.$on('destroy', onDestroy);

    onInit();

    /**
     * This function handles scope initialization.
     *
     * @return {any}
     */
    function onInit() {
      return btRiskService
        .initialize()
        .then(btRiskService.loadSettings)
        .then(function () {
          $scope.isInitialized = true;
        })
        .catch(function (reason) {
          console.error(reason);
        });
    }

    /**
     * This function handles scope destroying.
     */
    function onDestroy() {}

    /**
     * This function synchronises interval option.
     */
    function onIntervalChange() {
      $scope.selected.interval = $scope.refInterval.id;
    }

    /**
     * This function synchronises basket option.
     */
    function onBasketChange() {
      $scope.selected.basket = $scope.refBasket.id;
    }

    /**
     * This function selects a new basket.
     */
    function selectBasket() {
      btRiskService.selectBasketById($scope.selected.basket).catch(function (reason) {
        console.error(reason);
      });
    }

    /**
     * This function selects a new interval.
     */
    function selectInterval() {
      btRiskService.selectIntervalById($scope.selected.interval).catch(function (reason) {
        console.error(reason);
      });
    }

    /**
     * This function opens settings dialog.
     */
    function showRiskSettings() {
      btRiskService.loadSettings().then(function () {
        btRiskService.openSettingsDialog();
      });
    }

    /**
     *
     * @return {boolean}
     */
    function hasRiskFeature() {
      return btRestrictionService.hasFeature('risk-monitor');
    }

    /**
     *
     * @return {angular.IPromise<string>}
     */
    function upgradeRiskFeature() {
      return btRestrictionService.showUpgradePopup('risk-monitor');
    }
  }
})();
