/**
 * Created by Sergey Panpurin on 4/26/2017.
 */

// cspell:words BrokerageApi MarketdataApi OrderexecutionApi

(function btTradeStationControllerClosure() {
  'use strict';

  var gDebug = false;

  angular.module('ecapp').controller('btTradeStationController', btTradeStationController);

  btTradeStationController.$inject = ['$scope', 'btTradeStationApiService', '$interval'];

  /**
   * This is controller for testing tradestation
   *
   * @ngdoc controller
   * @name btTradeIdeasTabController
   * @memberOf ecapp
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {ecapp.ITradeStationApiService} btTradeStationApiService
   * @param {angular.IIntervalService} $interval
   */
  function btTradeStationController($scope, btTradeStationApiService, $interval) {
    console.log('Running btTradeStationController');

    $scope.buy = buyOrder;
    $scope.sell = sellOrder;
    $scope.delete = deleteOrder;

    var Brokerage = new TradestationApi.BrokerageApi();
    var Market = new TradestationApi.MarketdataApi();
    var Execution = new TradestationApi.OrderexecutionApi();

    var orderBody = {};

    $interval(updateData, 5000);

    /*
     * ACK - open
     * FLL - filled
     * OUT - canceled
     */

    var wrapper = btTradeStationApiService.getDataCallback;

    wrapper(Market.getSymbolLists.bind(Market), ['accessToken', 'callback'], function (error, data) {
      $scope.symbolLists = data;
    });

    wrapper(Market.getSymbolListByID.bind(Market), ['accessToken', 'SP500', 'callback'], function (error, data) {
      $scope.symbolList = data;
    });

    wrapper(Market.getSymbolListSymbolsByID.bind(Market), ['accessToken', 'SP500', 'callback'], function (error, data) {
      $scope.symbolListSymbols = data;
    });

    wrapper(Market.getSymbol.bind(Market), ['accessToken', '$INX', 'callback'], function (error, data) {
      $scope.symbolSP500 = Object.keys(data).map(function (key) {
        return { Name: key, Value: data[key] };
      });
    });

    wrapper(Market.getSymbol.bind(Market), ['accessToken', 'AAPL', 'callback'], function (error, data) {
      $scope.symbolApple = Object.keys(data).map(function (key) {
        return { Name: key, Value: data[key] };
      });
    });

    wrapper(
      Market.searchSymbols.bind(Market),
      ['accessToken', 'n=gs&c=stock&country=us', 'callback'],
      function (error, data) {
        $scope.symbolSearch = data;
      }
    );

    wrapper(Market.streamQuotesSnapshots.bind(Market), ['accessToken', 'INTC', 'callback'], function (error, data) {
      $scope.streamINTC = data;
      console.log('Quote data', $scope.streamINTC);
      return true;
    });

    wrapper(
      Brokerage.getAccountsByUserID.bind(Brokerage),
      ['accessToken', 'userId', 'callback'],
      function (error, data) {
        $scope.accounts = data;

        var accountKeys = $scope.accounts.map(function (a) {
          return a.Key;
        });

        orderBody = {
          AccountKey: $scope.accounts[0].Key,
          AssetType: 'EQ',
          Duration: 'DAY',
          OrderType: 'Market',
          Quantity: '1',
          Symbol: 'AAPL',
          TradeAction: 'BUY',
        };

        wrapper(
          Brokerage.getBalancesByAccounts.bind(Brokerage),
          ['accessToken', accountKeys, 'callback'],
          function (error, data) {
            $scope.balances = data;
          }
        );

        wrapper(
          Brokerage.getPositionsByAccounts.bind(Brokerage),
          ['accessToken', accountKeys, null, 'callback'],
          function (error, data) {
            $scope.positions = data;
          }
        );

        wrapper(
          Brokerage.getOrdersByAccounts.bind(Brokerage),
          ['accessToken', accountKeys, null, 'callback'],
          function (error, data) {
            $scope.positions = data;
          }
        );
      }
    );

    /**
     *
     */
    function buyOrder() {
      orderBody.TradeAction = 'BUY';

      wrapper(
        Execution.postOrderConfirm.bind(Execution),
        ['accessToken', orderBody, 'callback'],
        function (error, data) {
          $scope.buyConfirm = data[0];
          console.log(data[0]);
        }
      );

      wrapper(Execution.postOrder.bind(Execution), ['accessToken', orderBody, 'callback'], function (error, data) {
        $scope.lastOrder = data;
        console.log(data);
      });
    }

    /**
     *
     */
    function sellOrder() {
      orderBody.TradeAction = 'SELL';

      wrapper(
        Execution.postOrderConfirm.bind(Execution),
        ['accessToken', orderBody, 'callback'],
        function (error, data) {
          $scope.sellConfirm = data[0];
          console.log(data[0]);
        }
      );

      wrapper(Execution.postOrder.bind(Execution), ['accessToken', orderBody, 'callback'], function (error, data) {
        $scope.lastOrder = data;
        console.log(data);
      });
    }

    /**
     *
     */
    function deleteOrder() {
      wrapper(
        Execution.cancelOrder.bind(Execution),
        ['accessToken', $scope.lastOrder.OrderID, 'callback'],
        function (error, data) {
          $scope.delete = data;
          console.log(data);
        }
      );
    }

    /**
     *
     */
    function updateData() {
      if (gDebug) console.log('Interval was called (updateData)');
      wrapper(Market.getQuotes.bind(Market), ['accessToken', 'AAPL', 'callback'], function (error, data) {
        $scope.quoteAAPL = data[0];
        console.log('Quote data', $scope.quoteAAPL);
      });

      wrapper(Market.getQuotes.bind(Market), ['accessToken', 'UVXY', 'callback'], function (error, data) {
        $scope.quoteUVXY = data[0];
        console.log('Quote data', $scope.quoteUVXY);
      });
    }
  }
})();
