(function btAuthCallbackControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btAuthCallbackController', btAuthCallbackController);

  btAuthCallbackController.$inject = ['$scope', '$state', 'btAuthService', 'btSettingsService'];

  /**
   * Controller for social login authorization
   *
   * @ngdoc controller
   * @name ecapp.btAuthCallbackController
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.IAuthService} btAuthService
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btAuthCallbackController($scope, $state, btAuthService, btSettingsService) {
    console.log('Running btAuthCallbackController');

    $scope.logoSrc = btSettingsService.getAssets('login-logo-src');
    $scope.logoAlt = btSettingsService.getAssets('login-logo-alt');

    var accessToken = btAuthService.getAccessFromCookies();

    btAuthService
      .postSocialLogin(accessToken)
      .then(function (isTutorialPassed) {
        if (isTutorialPassed) {
          console.log('AuthCallback: try to open ecapp.app.main.reviews');
          $state.go('ecapp.app.main.reviews');
        } else {
          console.log('AuthCallback: try to open ecapp.user.tutorial');
          $state.go('ecapp.user.tutorial');
        }
      })
      .catch(function (error) {
        console.log('Error:', error);
        console.log('AuthCallback: try to open ecapp.user.login');
        $state.go('ecapp.user.login');
      });
  }
})();
