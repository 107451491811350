// @ts-check
(function btEventsServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btEventsService', btEventsService);

  btEventsService.$inject = ['$q', 'EventsInfo', 'btErrorService', 'btSettingsService'];

  /**
   * @ngdoc service
   * @name btEventsService
   * @memberOf ecapp
   * @description
   *  This service caches information about events.
   * @param {angular.IQService} $q
   * @param {ecapp.IGeneralLoopbackService} lbEventsInfo
   * @param {ecapp.IErrorService} btErrorService
   * @param {ecapp.ISettingsService} btSettingsService
   * @return {ecapp.IEventsService}
   */
  function btEventsService($q, lbEventsInfo, btErrorService, btSettingsService) {
    var gEventsCache = null;

    return {
      init: getAllEvents,
      suggestEvent: suggestEvent,
      getAllEvents: getAllEvents,
      getAllEventsFromCache: getAllEventsFromCache,
      getEventById: getEventById,
      getEventByIdFromCache: getEventByIdFromCache,
      getPronunciation: getPronunciation,
    };

    /**
     *
     * @return {angular.IPromise<Array>}
     */
    function getAllEvents() {
      if (gEventsCache) {
        var deferred = $q.defer();
        deferred.resolve(gEventsCache);
        return deferred.promise;
      } else {
        var fields = [
          'name',
          'currency',
          'country',
          'id',
          'importance',
          'direction',
          'relatedInstruments',
          'pronunciation',
          'eventsDescription',
        ];
        var query = { filter: { fields: fields, include: 'eventsDescription' } };

        return lbEventsInfo
          .find(query)
          .$promise.then(_fixDescription)
          .then(_fixRelatedInstruments)
          .then(_saveCache)
          .catch(btErrorService.handleHTTPError);
      }
    }

    /**
     *
     * @param {*} results
     * @return {any}
     */
    function _fixDescription(results) {
      results.forEach(function (result) {
        if (result.eventsDescription) {
          result.description = result.eventsDescription.description || '';
          delete result.eventsDescription;
        } else {
          result.description = '';
        }
      });

      return results;
    }

    /**
     *
     * @param {*} results
     * @return {any}
     */
    function _fixRelatedInstruments(results) {
      if (btSettingsService.isLinkDataService()) {
        results.forEach(function (result) {
          if (result.relatedInstruments) {
            result.relatedInstruments = result.relatedInstruments.filter(function (i) {
              return ['OIL', 'West Texas Oil'].indexOf(i) === -1;
            });
          }
        });
      }

      return results;
    }

    /**
     *
     * @param {*} result
     * @return {any}
     */
    function _saveCache(result) {
      gEventsCache = result;
      return gEventsCache;
    }

    /**
     *
     * @return {any}
     */
    function getAllEventsFromCache() {
      return gEventsCache;
    }

    /**
     *
     * @param {*} text
     * @param {*} limit
     * @param {*} options
     * @return {any}
     */
    function suggestEvent(text, limit, options) {
      void options;

      return getAllEvents().then(function (events) {
        var words = text.toLowerCase().split(' ');
        return events
          .filter(function (event) {
            return words.every(function (word) {
              return event.currency.toLowerCase().indexOf(word) !== -1 || event.name.toLowerCase().indexOf(word) !== -1;
            });
          })
          .slice(0, limit || 50);
      });
    }

    /**
     * Get event by identifier
     * @param {Number} eventId - event identifier
     * @return {PromiseLike<?btRawEvent>}
     */
    function getEventById(eventId) {
      return getAllEvents().then(function (events) {
        return _filterEventsById(eventId, events);
      });
    }

    /**
     * Filter events by id
     * @param {Number} eventId - event identifier
     * @param {btRawEvent[]} events - list of events
     * @return {?btRawEvent}
     * @private
     */
    function _filterEventsById(eventId, events) {
      var event = events.filter(function (event) {
        return event['id'] === eventId;
      })[0];

      return event ? event : null;
    }

    /**
     *
     * @param {*} eventId
     * @return {any}
     */
    function getEventByIdFromCache(eventId) {
      if (gEventsCache) {
        return _filterEventsById(eventId, gEventsCache);
      } else {
        return {};
      }
    }

    /**
     *
     * @param {*} event
     * @return {any}
     */
    function getPronunciation(event) {
      if (event.pronunciation) {
        return event.pronunciation;
      } else {
        var text = event.country + ' ' + event.name;
        text = text.replace('(MoM)', 'month over month');
        text = text.replace('(QoQ)', 'quarter over quarter');
        text = text.replace('(YoY)', 'year over year');
        text = text.replace(/^BoC/, 'Bank of Canada');
        text = text.replace(/^BoE/, 'Bank of England');
        return text;
      }
    }
  }
})();
