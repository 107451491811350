/**
 * Created by Orly on 20/02/2019.
 */

(function btOpenPositionsClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btOpenOrders:';

  angular.module('ecapp').directive('btOpenPositions', btOpenPositions);

  btOpenPositions.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btOpenPositions($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('directives/barometer/bt-open-positions-widget.html'),
      controller: btOpenPositionsController,
      controllerAs: 'vm',
    };
  }

  btOpenPositionsController.$inject = [
    'TS',
    '$scope',
    '$timeout',
    'btTradingService',
    'btToastrService',
    'btSettingsService',
  ];

  /**
   *
   * @param {ecapp.ITSConstants} TS
   * @param {*} $scope
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btOpenPositionsController(TS, $scope, $timeout, btTradingService, btToastrService, btSettingsService) {
    void $scope;
    console.log('Running btOpenPositionsController');

    /*jshint validthis: true*/
    var vm = this;

    var gSettings = btSettingsService.getLocalSettings();

    $scope.$on('broker:disconnected', onBrokerConnected);
    $scope.$on('broker:connected', onBrokerDisconnected);

    activate();

    /**
     * This function activates controller.
     */
    function activate() {
      if (gDebug) console.log(gPrefix, 'controller activation');
      vm.isLoading = true;
      vm.hasError = false;
      vm.errorMessage = '';
      vm.noBroker = false;
      vm.brokerName = '';

      vm.positions = [];

      loadSettings();
      sortColumn(vm.orderByColumn, vm.orderByReverse ? -1 : 1);

      vm.modulo = modulo;
      vm.closePosition = closePosition;
      vm.sortColumn = sortColumn;
      vm.reactivate = reactivate;

      loadOpenPositions();
    }

    /**
     * This function reactivates controller.
     */
    function reactivate() {
      vm.isLoading = true;
      vm.hasError = false;
      vm.errorMessage = '';
      vm.noBroker = false;
      vm.brokerName = '';
      vm.positions = [];

      loadOpenPositions();
    }

    /**
     * This function loads open orders.
     */
    function loadOpenPositions() {
      // initialization of trading service
      btTradingService
        .initialize()
        .then(function () {
          if (btTradingService.isDefaultBroker()) {
            // block for default broker
            vm.isLoading = false;
            vm.hasError = true;
            vm.errorMessage = 'Select your broker.';
            vm.noBroker = true;
            vm.brokerName = 'default';
          } else {
            vm.noBroker = false;
            return btTradingService.connect().then(function () {
              vm.isLoading = false;
              vm.hasError = false;
              vm.errorMessage = '';
              vm.noBroker = false;
              vm.brokerName = btTradingService.getBrokerName();
              vm.positions = btTradingService.getLastPositions();

              const selectedAccount = btTradingService.getSelectedAccount();
              vm.isCryptoAccount = selectedAccount && selectedAccount.type === TS.ACCOUNT_TYPE.CRYPTO;

              sortColumn('symbol', 1);
            });
          }
        })
        .catch(processError);
    }

    /**
     * This function processes errors.
     *
     * @param {Error} error - error object
     */
    function processError(error) {
      console.error(gPrefix, error);
      vm.isLoading = false;
      vm.hasError = true;
      vm.errorMessage = error.message || 'unknown';
    }

    /**
     *
     * @param {*} val
     * @return {number}
     */
    function modulo(val) {
      return Math.abs(val);
    }

    /**
     * This function closes position.
     *
     * @param {ecapp.ITradingPosition} position
     * @return {any}
     */
    function closePosition(position) {
      return btTradingService.closePosition(position).then(function (data) {
        if (!data.empty) {
          vm.positions = btTradingService.getLastPositions();
        }
      });
    }

    /**
     * This function changes parameters of orderBy filter.
     *
     * @param {String} column - sorting column
     * @param {Number} [order] - sorting order 1 or -1
     */
    function sortColumn(column, order) {
      if (order !== undefined) {
        vm.orderByReverse = order === -1;
      } else {
        vm.orderByReverse = vm.orderByColumn === column ? !vm.orderByReverse : false;
      }
      vm.orderByColumn = column;
    }

    /**
     * This function reacts on broker connection.
     */
    function onBrokerConnected() {
      reactivate();
    }

    /**
     * This function reacts on broker disconnection.
     */
    function onBrokerDisconnected() {
      vm.isLoading = false;
      vm.hasError = true;
      vm.errorMessage = '';
      vm.noBroker = true;
      vm.brokerName = '';
      vm.positions = [];
    }

    /**
     * This function saves user settings.
     */
    function loadSettings() {
      vm.orderByReverse = gSettings.get('barometer.open-positions.order');
      vm.orderByReverse = vm.orderByReverse !== undefined ? vm.orderByReverse : false;
      vm.orderByColumn = gSettings.get('barometer.open-positions.column') || 'symbol';
    }

    /**
     * This function loads user settings.
     */
    function saveSettings() {
      gSettings.set('barometer.open-positions.order', vm.orderByReverse);
      gSettings.set('barometer.open-positions.column', vm.orderByColumn);
    }
  }
})();
