/**
 * Created by Orly Knop on 4/16/2019.
 */

// @ts-check
(function btMoversAlertsServiceClosure() {
  'use strict';

  var gDebug = false;

  /**
   * @typedef {Object} btMarketMoverAlerts
   * @property {ecapp.ITradingInstrument} instrument - instrument object
   * @property {btMarketMoverAlert} newLimit - alert object
   * @property {btMarketMoverAlert} volatilityIncrease - alert object
   * @property {btMarketMoverAlert} activeTradeIdea - alert object
   * @property {btMarketMoverAlert} potentialEvent - alert object
   */

  /**
   * @typedef {Object} btMarketMoverAlert
   * @property {number} time - timestamp of alert in milliseconds (for potential event it is a future moment)
   * @property {string} value - alert value
   * @property {angular.IPromise|null} timer - inactivation handler
   * @property {object|string|null} link - connected link
   * @property {string} title - title
   * @property {number} updated - last update time
   */

  /**
   * @typedef {Object} btMarketMoverAlertsSettings
   * @property {btMarketMoverAlertProperties} newLimit - alert settings
   * @property {btMarketMoverAlertProperties} volatilityIncrease - alert settings
   * @property {btMarketMoverAlertProperties} activeTradeIdea - alert settings
   * @property {btMarketMoverAlertProperties} potentialEvent - alert settings
   */

  /**
   * @typedef {object} btMarketMoverAlertProperties
   * @property {string} id - identifier
   * @property {string} property - property name
   * @property {boolean} visible - indicates whether alert is visible
   * @property {boolean} locked - indicates whether alert is locked for current user
   * @property {string} name - name (equal to identifier)
   * @property {string} icon - title icon
   * @property {string} title - title text
   * @property {string} classes - title css classes
   * @property {Record<string, string>} icons - icons
   */

  angular.module('ecapp').factory('btMoversAlertsService', btMoversAlertsService);

  btMoversAlertsService.$inject = [
    '$ionicPlatform',
    '$timeout',
    '$interval',
    '$rootScope',
    'btEventEmitterService',
    'btPusherService',
    'btTradeIdeasService',
    'btRestrictionService',
    'btLinkService',
    'btCalendarService',
    'btDateService',
    'btSocketService',
  ];

  /**
   * @ngdoc service
   * @name btMoversAlertsService
   * @memberOf ecapp
   * @description
   *  ???
   * @param {ionic.IPlatformService} $ionicPlatform
   * @param {angular.ITimeoutService} $timeout
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.IEventEmitterService} btEventEmitterService
   * @param {ecapp.IPusherService} btPusherService
   * @param {ecapp.ITradeIdeasService} btTradeIdeasService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.ILinkService} btLinkService
   * @param {ecapp.ICalendarService} btCalendarService
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.ISocketService} btSocketService
   * @return {ecapp.IMoversAlertsService}
   */
  function btMoversAlertsService(
    $ionicPlatform,
    $timeout,
    $interval,
    $rootScope,
    btEventEmitterService,
    btPusherService,
    btTradeIdeasService,
    btRestrictionService,
    btLinkService,
    btCalendarService,
    btDateService,
    btSocketService
  ) {
    if (gDebug) console.log('Running btMoversAlertsService');

    /**
     * Indicator of service initialization.
     * @type {boolean}
     */
    var gInitialized = false;

    /**
     * Settings for each type of market movers alerts.
     * Next types of alerts are supported:
     *  - new limit,
     *  - volatility increase
     *  - active trade idea
     *  - potential event
     * @type {btMarketMoverAlertsSettings}
     */
    var gAlertsSettings = {
      newLimit: {
        property: 'newLimit',
        id: 'high-low',
        visible: true,
        locked: false,
        name: 'high-low',
        icon: 'ion-ios-pause',
        title: 'New High or New Low',
        classes: 'inactive-alert',
        icons: {
          positive: 'active-alert ion-ios-skipbackward new-high positive',
          negative: 'active-alert ion-ios-skipbackward new-low negative',
          inactive: 'inactive-alert ion-ios-pause',
        },
      },
      volatilityIncrease: {
        property: 'volatilityIncrease',
        id: 'volatility',
        visible: true,
        locked: false,
        name: 'volatility',
        icon: 'ion-flag',
        title: 'Volatility increase',
        classes: '',
        icons: {
          active: 'active-alert ion-flag',
          inactive: 'inactive-alert ion-flag',
        },
      },
      activeTradeIdea: {
        property: 'activeTradeIdea',
        id: 'trade-idea',
        visible: true,
        locked: false,
        name: 'trade-idea',
        icon: 'ion-pricetag',
        title: 'Active trade idea',
        classes: '',
        icons: {
          positive: 'active-alert ion-arrow-graph-up-right positive',
          negative: 'active-alert ion-arrow-graph-down-right negative',
          mixed: 'active-alert ion-pricetags',
          locked: 'active-alert ion-locked insistent',
          inactive: 'inactive-alert ion-pricetag',
        },
      },
      potentialEvent: {
        property: 'potentialEvent',
        id: 'potential-event',
        visible: true,
        locked: false,
        name: 'potential-event',
        icon: 'ion-android-calendar',
        title: 'Event with potential trade idea that could affect this instrument',
        classes: '',
        icons: {
          locked: 'inactive-alert ion-locked',
          active: 'active-alert ion-android-calendar',
          inactive: 'inactive-alert ion-android-calendar',
        },
      },
    };

    /**
     * Array representation of settings for each type of market movers alerts
     * @type {btMarketMoverAlertProperties[]}
     */
    var gAlertSettingsList = generateAlertSettingsList();

    /**
     * Storage of alerts for each instrument.
     * @type {Record<string, btMarketMoverAlerts>}
     */
    var gAlertsStorage = {};

    /**
     * Duration of flicking for new alert in milliseconds.
     * @type {number}
     */
    var gAnimDelay = 3 * 1000;

    /**
     * Duration of showing alert in minutes.
     * @type {number}
     */
    var gShownDelay = 10 * btDateService.MINUTE;

    /**
     * Interval between updates of potential event alerts.
     * @type {number}
     */
    var gPotentialEventsUpdateInterval = 5 * 1000;

    /**
     * Conversion for new limit values.
     * @type {{up: string, down: string}}
     */
    var gConvertLimit = {
      up: 'positive',
      down: 'negative',
    };

    /**
     * Conversion for trade idea values.
     * @type {{short: string, long: string}}
     */
    var gConvertTradeIdea = {
      long: 'positive',
      short: 'negative',
    };

    /**
     * List of active trade ideas.
     * @type {bt.TradeIdeaObject[]}
     */
    var gActiveTradeIdeas = btTradeIdeasService.getCachedActiveTradeIdeas();

    /**
     * List of upcoming events
     * @type {btRelease[]}
     */
    var gUpcomingEvents = [];

    /**
     * Handler for potential event update function.
     */
    var gPotentialEventUpdater = null;

    btEventEmitterService.addListener('login:success', onLoginSuccess);
    btEventEmitterService.addListener('logout:success', onLogoutSuccess);
    $rootScope.$on('active-trade-ideas:updated', onNewActiveTradeIdea);
    $rootScope.$on('subscription:updated', onSubscriptionUpgradeSuccess);

    $ionicPlatform.on('resume', onResume);

    if (gDebug) addDebugFunctions();

    return {
      refresh: refresh,
      getMoverAlerts: getMoverAlerts,
      getAlertsInfo: getAlertsInfo,
      toDisplayCounter: shouldDisplayCounter,
      getCounter: getCounter,
      getTimePassed: getTimeDifference,
      getTime: getTime,
      getValueClasses: getValueClasses,
      toAnimateAlert: toAnimateAlert,
      isClickableAlert: isClickableAlert,
      clickOnAlert: clickOnAlert,
    };

    /**
     * This function reacts on subscription update.
     */
    function onSubscriptionUpgradeSuccess() {
      gAlertsSettings['potentialEvent'].locked = !btRestrictionService.hasFeature('trade-ideas');

      Object.keys(gAlertsStorage).forEach(function (symbol) {
        gAlertsStorage[symbol]['potentialEvent'].value = btRestrictionService.hasFeature('trade-ideas')
          ? 'inactive'
          : 'locked';
      });
    }

    /**
     *
     */
    function refresh() {}

    /**
     * This function initialize service for a new user.
     */
    function initialize() {
      if (gInitialized === false) {
        gInitialized = true;

        gAlertsSettings['potentialEvent'].locked = !btRestrictionService.hasFeature('trade-ideas');
        gUpcomingEvents = btCalendarService.getUpcomingEvents(gShownDelay);
        gPotentialEventUpdater = $interval(updatePotentialEvents, gPotentialEventsUpdateInterval);

        // Connect Pusher
        $timeout(function () {
          // btPusherService.addEventHandler('marketAlerts', 'new-alert', onMarketAlert);
          btPusherService.addEventHandler('personal', 'market-wakeup', onMarketWakeup);

          btSocketService
            .connect('/market-alerts')
            .then(function (socket) {
              socket.on('new-alert', onMarketAlert);
            })
            .catch(console.error);
        }, 1000);
      }
    }

    /**
     * This function clears service during user logout.
     */
    function logout() {
      if (gInitialized) {
        gInitialized = false;
        gAlertsStorage = {};

        gAlertsSettings['potentialEvent'].locked = false;

        // Pusher unbind all handlers of personal channel during logout
        // btPusherService.removeEventHandler('marketAlerts', 'new-alert', onMarketAlert);

        if (gPotentialEventUpdater) {
          $interval.cancel(gPotentialEventUpdater);
          gPotentialEventUpdater = null;
        }
      }
    }

    /**
     * This function reacts on success login event.
     */
    function onLoginSuccess() {
      initialize();
    }

    /**
     * This function reacts on success logout event.
     */
    function onLogoutSuccess() {
      logout();
    }

    /**
     * This function reacts on application resuming event.
     */
    function onResume() {
      Object.keys(gAlertsStorage).forEach(function (symbol) {
        Object.keys(gAlertsStorage[symbol]).forEach(function (key) {
          if (key !== 'instrument') {
            addInactivationTimer(gAlertsStorage[symbol][key], key, symbol);
          }
        });
      });

      onNewActiveTradeIdea(null, null);
    }

    /**
     * This function processes new market wakeup message.
     *
     * @param {btWakeupMessage} message - market wakeup message.
     */
    function onMarketWakeup(message) {
      if (message && message.emotion === 'volatility') {
        var i = getInstrument(message.symbol);
        updateAlert(
          message.symbol,
          'volatilityIncrease',
          'active',
          Date.now(),
          i
            ? { state: 'ecapp.app.main.instrument-page', params: { broker: i.brokerName, symbol: i.brokerSymbol } }
            : null
        );
      }
    }

    /**
     * This function processes new market sense message.
     *
     * @param {btMarketAlertMessage} message - market sense message
     */
    function onMarketAlert(message) {
      if (message && message.type === 'level' && message.payload && message.payload.action === 'new') {
        var i1 = getInstrument(message.symbol);
        updateAlert(
          message.symbol,
          'newLimit',
          gConvertLimit[message.payload.side] || 'inactive',
          message.time * 1000,
          i1
            ? {
                state: 'ecapp.app.main.instrument-page',
                params: { broker: i1.brokerName, symbol: i1.brokerSymbol },
              }
            : null
        );
      }
    }

    /**
     * This function processes new active trade ideas.
     *
     * @param {*} event - event object
     * @param {*} data - event parameters
     */
    function onNewActiveTradeIdea(event, data) {
      void event;
      void data;

      if (gActiveTradeIdeas.length) {
        gActiveTradeIdeas
          .slice()
          .reverse()
          .forEach(function (tradeIdea) {
            if (getTTL('activeTradeIdea', tradeIdea.time * 1000) > 0) {
              updateAlert(
                tradeIdea.instrument.brokerSymbol,
                'activeTradeIdea',
                tradeIdea.locked ? 'locked' : gConvertTradeIdea[tradeIdea.side] || 'inactive',
                tradeIdea.time * 1000,
                { state: 'ecapp.app.main.trade-ideas', params: {} }
              );
            }
          });
      }
    }

    /**
     * This function updates potential events.
     */
    function updatePotentialEvents() {
      if (gUpcomingEvents.length && btRestrictionService.hasFeature('trade-ideas')) {
        gUpcomingEvents.forEach(function (release) {
          if (!release.isReleased) {
            release.expectedTradingInsights.forEach(function (value) {
              updateAlert(value.market.split(':')[0], 'potentialEvent', 'active', release.time * 1000, {
                state: 'ecapp.app.main.reviews',
                params: {},
              });
            });
          }
        });
      }
    }

    /**
     * This function tries to update a new alert if it is necessary.
     *
     * @param {string} symbol - instrument symbol
     * @param {string} id - alert identifier
     * @param {string} value - alert value
     * @param {number} time - alert time reference
     * @param {object} link - link to open on click
     */
    function updateAlert(symbol, id, value, time, link) {
      var alert = getMarketAlertById(symbol, id);
      if (alert) {
        if (alert.value === value && alert.time === time) {
          // skip
        } else if (alert.value === value) {
          setAlert(alert, id, symbol, value, time, link);
        } else if (alert.time === time) {
          if (id === 'activeTradeIdea' && isOppositeAlertValues(alert.value, value)) {
            setAlert(alert, id, '', 'mixed', time, link);
          } else {
            // console.error('Difference alerts at same time.')
          }
        } else {
          setAlert(alert, id, symbol, value, time, link);
        }
      }
    }

    /**
     * This function checks if two alert values are opposite.
     * @param {string} v1 - first alert value
     * @param {string} v2 - second alert value
     * @return {boolean}
     */
    function isOppositeAlertValues(v1, v2) {
      return (v1 === 'positive' && v2 === 'negative') || (v2 === 'negative' && v1 === 'positive');
    }

    /**
     * This function sets alert properties.
     * @param {btMarketMoverAlert} alert - alert object
     * @param {string} id - alert identifier
     * @param {string} symbol - instrument symbol
     * @param {string} value - new alert value
     * @param {number} time - new alert reference time
     * @param {object|string|null} link - new alert link
     */
    function setAlert(alert, id, symbol, value, time, link) {
      alert.value = value;
      alert.time = time;
      alert.link = link;
      addInactivationTimer(alert, id, symbol);
    }

    /**
     * This function returns alerts for specified market mover.
     *
     * @alias ecapp.btMoversAlertsService#getMoverAlerts
     * @param {Instrument} instrument - instrument object
     * @return {btMarketMoverAlerts}
     */
    function getMoverAlerts(instrument) {
      initialize();

      var symbol = instrument.brokerSymbol;

      if (gAlertsStorage[symbol] !== undefined) {
        return gAlertsStorage[symbol];
      } else {
        gAlertsStorage[symbol] = generateAlerts(instrument);
        updatePotentialEvents();
        return gAlertsStorage[symbol];
      }
    }

    /**
     * This function return alert for specified symbol and alert identifier.
     *
     * @param {string} symbol - instrument symbol
     * @param {string} id - alert identifier
     * @return {undefined|btMarketMoverAlert}
     */
    function getMarketAlertById(symbol, id) {
      if (gAlertsStorage[symbol]) {
        return gAlertsStorage[symbol][id];
      } else {
        return undefined;
      }
    }

    /**
     * This function return instrument object linked to alert.
     *
     * @param {string} symbol - instrument symbol
     * @return {undefined|ecapp.ITradingInstrument}
     */
    function getInstrument(symbol) {
      if (gAlertsStorage[symbol]) {
        return gAlertsStorage[symbol].instrument;
      } else {
        return undefined;
      }
    }

    /**
     * This function sets timeout to inactivate alert after 10 minutes passed since it was triggered.
     *
     * @param {btMarketMoverAlert} alert - alert object
     * @param {string} id - alert identifier
     * @param {string} symbol - instrument symbol
     */
    function addInactivationTimer(alert, id, symbol) {
      if (alert.value !== 'inactive') {
        if (getTTL(id, alert.time) <= 0) {
          if (gDebug) console.log('Inactivate expired alert. S:' + symbol + ' ID:' + id + ' V:' + alert.value + '.');
          inactivateAlert(alert);
        } else {
          // time till alert is not expired
          if (gDebug) console.log('Set inactivation timer. S:' + symbol + ' ID:' + id + ' V:' + alert.value + '.');

          if (alert.timer) {
            $timeout.cancel(alert.timer);
            alert.timer = null;
          }

          alert.timer = $timeout(function () {
            if (gDebug) console.log('Inactivate alert by timer. S:' + symbol + ' ID:' + id + ' V:' + alert.value + '.');
            inactivateAlert(alert);
          }, getTTL(id, alert.time));
        }
      }
    }

    /**
     *
     * @param {btMarketMoverAlert} alert
     */
    function inactivateAlert(alert) {
      alert.value = 'inactive';
      alert.link = null;

      if (alert.timer) {
        $timeout.cancel(alert.timer);
        alert.timer = null;
      }
    }

    /**
     * This function calculates alert time to live.
     *
     * @param {string} id - alert identifier
     * @param {number} time - alert reference time
     * @return {number}
     */
    function getTTL(id, time) {
      if (id === 'potentialEvent') {
        return time - Date.now();
      } else {
        return gShownDelay - (Date.now() - time);
      }
    }

    /**
     *
     * @param {Instrument} instrument
     * @return {btMarketMoverAlerts}
     */
    function generateAlerts(instrument) {
      return {
        instrument: instrument,
        newLimit: { value: 'inactive', time: 0, timer: null, link: null, title: '', updated: 0 },
        volatilityIncrease: { value: 'inactive', time: 0, timer: null, link: null, title: '', updated: 0 },
        activeTradeIdea: { value: 'inactive', time: 0, timer: null, link: null, title: '', updated: 0 },
        potentialEvent: {
          value: btRestrictionService.hasFeature('trade-ideas') ? 'inactive' : 'locked',
          time: 0,
          timer: null,
          link: null,
          title: '',
          updated: 0,
        },
      };
    }

    /**
     * @alias ecapp.btMoversAlertsService#getAlertsInfo
     * @return {btMarketMoverAlertProperties[]}
     */
    function getAlertsInfo() {
      return gAlertSettingsList;
    }

    /**
     * @alias ecapp.btMoversAlertsService#getValueClasses
     * @param {*} mover
     * @param {*} alert
     * @return {*}
     */
    function getValueClasses(mover, alert) {
      return gAlertsSettings[alert]['icons'][mover.alerts[alert].value];
    }

    /**
     * Returns if counter should be displayed for mover alert.
     *
     * @alias ecapp.btMoversAlertsService#toDisplayCounter
     * @param {btMarketMover} mover - mover object
     * @param {String} alert - name of the alert
     * @return {boolean}
     */
    function shouldDisplayCounter(mover, alert) {
      return !gAlertsSettings[alert].locked && mover.alerts[alert].value !== 'inactive';
    }

    /**
     * Returns counter for mover alert.
     *
     * @alias ecapp.btMoversAlertsService#getCounter
     * @param {btMarketMover} mover - mover object
     * @param {String} alert - name of the alert
     * @return {Number|undefined}
     */
    function getCounter(mover, alert) {
      return Math.round(getTimeDifference(mover.alerts, alert) / btDateService.MINUTE);
    }

    /**
     *
     * @alias ecapp.btMoversAlertsService#getTimePassed
     * @param {btMarketMoverAlerts} alerts - list of alerts
     * @param {string} id - alert identifier
     * @return {number} - number of milliseconds passed since alert
     */
    function getTimeDifference(alerts, id) {
      if (id === 'potentialEvent') {
        return alerts[id].time - Date.now();
      } else {
        return Date.now() - alerts[id].time;
      }
      // return Math.max(Date.now() - alerts[id].time, 0);
    }

    /**
     * This function return alert time.
     *
     * @alias ecapp.btMoversAlertsService#getTime
     * @param {btMarketMoverAlerts} alerts - list of alerts
     * @param {string} id - alert id
     * @return {number} number of milliseconds passed since alert
     */
    function getTime(alerts, id) {
      return alerts[id].time;
    }

    /**
     * This function checks if alert should be animated.
     *
     * @alias ecapp.btMoversAlertsService#toAnimateAlert
     * @param {btMarketMover} mover - market mover object
     * @param {string} alert - alert identifier
     * @return {boolean}
     */
    function toAnimateAlert(mover, alert) {
      if (alert === 'potentialEvent') {
        // if (mover.alerts[alert].value !== 'inactive') {
        //   console.log('potentialEvent', gAlertMaxDelay - getTimeDifference(mover.alerts, alert));
        // }
        // return gAlertMaxDelay - getTimeDifference(mover.alerts, alert) <= gAlertAnimDelay;
        return false;
      } else {
        return getTimeDifference(mover.alerts, alert) <= gAnimDelay;
      }
    }

    /**
     * This function processes click on alert.
     *
     * @alias ecapp.btMoversAlertsService#clickOnAlert
     * @param {btMarketMover} mover - market mover object
     * @param {string} alert - alert identifier
     */
    function clickOnAlert(mover, alert) {
      btLinkService.openSmart(mover.alerts[alert].link);
    }

    /**
     *
     *
     * @alias ecapp.btMoversAlertsService#isClickableAlert
     * @param {btMarketMover} mover - market mover object
     * @param {string} alert - alert identifier
     * @return {boolean}
     */
    function isClickableAlert(mover, alert) {
      return !!mover.alerts[alert].link;
    }

    // /**
    //  * This function generates random integer from next range [min, max).
    //  *
    //  * @param {Number} max - max value (will not be reached)
    //  * @param {Number} [min] - minimal value
    //  * @return {Number}
    //  */
    // function getRandomNumber(max, min) {
    //   min = min ? min : 0;
    //   return Math.floor((Math.random() * (max - min) + min));
    // }

    /**
     * This function generates list of alert settings.
     *
     * @return {btMarketMoverAlertProperties[]}
     */
    function generateAlertSettingsList() {
      return Object.keys(gAlertsSettings).map(function (alert) {
        gAlertsSettings[alert].property = alert;
        return gAlertsSettings[alert];
      });
    }

    /**
     *
     */
    function addDebugFunctions() {
      // noinspection JSUnusedGlobalSymbols
      window.btMoversAlertsService = {
        mockNewLimit: function (symbol, side) {
          if (symbol && side) {
            onMarketAlert({
              symbol: symbol,
              time: Math.floor(Date.now() / 1000) + 2,
              price: 1689.791,
              type: 'level',
              payload: {
                action: 'new',
                side: side,
                level: 'TH',
                repeated: false,
                data: '1689.749',
              },
            });
          } else {
            console.log('Example: window.btMoversAlertsService.mockNewLimit("SPX500_USD", "up");');
          }
        },
        mockVolatilityIncrease: function (symbol) {
          if (symbol) {
            onMarketWakeup({
              broker: 'oanda',
              symbol: symbol,
              emotion: 'volatility',
              displayName: 'Test',
              price: 1,
            });
          } else {
            console.log('Example: window.btMoversAlertsService.mockVolatilityIncrease("OANDA", "SPX500_USD");');
          }
        },
        mockActiveTradeIdea: function (symbol, type) {
          if (symbol && type) {
            updateAlert(symbol, 'activeTradeIdea', type, Date.now(), {
              state: 'ecapp.app.main.trade-ideas',
              params: {},
            });
          } else {
            console.log('Example: window.btMoversAlertsService.mockActiveTradeIdea("SPX500_USD", "positive");');
          }
        },
        mockPotentialEvent: function (symbol, minutes) {
          if (symbol && minutes) {
            updateAlert(symbol, 'potentialEvent', 'active', Date.now() + minutes * 60 * 1000, {
              state: 'ecapp.app.main.reviews',
              params: {},
            });
          } else {
            console.log('Example: window.btMoversAlertsService.mockPotentialEvent("SPX500_USD", 10);');
          }
        },
      };
    }
  }
})();
