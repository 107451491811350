/**
 * Created by Sergei on 8/22/2016.
 */

(function btCurrenciesAndPrioritySettingsCtrlClosure() {
  'use strict';

  angular
    .module('ecapp')
    .controller('btCurrenciesAndPrioritySettingsController', btCurrenciesAndPrioritySettingsController);

  btCurrenciesAndPrioritySettingsController.$inject = [
    '$scope',
    '$q',
    '$timeout',
    '$rootScope',
    '$ionicLoading',
    '$analytics',
    'btShareScopeService',
    'btCurrenciesService',
    'btSettingsService',
    'btRestrictionService',
    'btTradeIdeasFiltersService',
  ];

  /**
   * This is controller for currencies and priority views.
   *
   * @ngdoc controller
   * @name btCurrenciesAndPrioritySettingsController
   * @memberOf ecapp
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {angular.IQService} $q - promise interface (used once)
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.ICustomRootScope} $rootScope - common angular rootScope service
   * @param {ionic.ILoadingService} $ionicLoading - ionic loading service
   * @param {ext.IAnalyticsService} $analytics
   * @param {ecapp.IShareScopeService} btShareScopeService -
   * @param {ecapp.ICurrenciesService} btCurrenciesService - factory for working with currencies
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.ITradeIdeasFiltersService} btTradeIdeasFiltersService
   */
  function btCurrenciesAndPrioritySettingsController(
    $scope,
    $q,
    $timeout,
    $rootScope,
    $ionicLoading,
    $analytics,
    btShareScopeService,
    btCurrenciesService,
    btSettingsService,
    btRestrictionService,
    btTradeIdeasFiltersService
  ) {
    console.log('Running btCurrenciesAndPrioritySettingsController');

    $scope.updatePriority = updatePriority;
    $scope.updateCurrency = selectOneCurrency;
    $scope.selectCommonCurrencies = selectCommonCurrencies;
    $scope.selectAllCurrencies = selectAllCurrencies;
    $scope.clearCurrencies = clearCurrencies;
    // $scope.selectFollowedEvent = selectFollowedEvent;

    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('$ionicView.enter', onViewEnter);

    btCurrenciesService
      .getAllCachedCurrenciesFlags()
      .then(function (data) {
        $scope.currencyFlags = data.allCachedCurrenciesFlags;
      })
      .catch(function (reason) {
        console.log('btCurrenciesAndPrioritySettingsController: error', reason);
      });

    getAllCurrencies();

    /**
     *
     */
    function onViewLoaded() {
      $timeout(function () {
        $('#bt-settings-currencies-content').focus();
      }, 1000);
    }

    /**
     *
     */
    function onViewEnter() {
      $timeout(function () {
        $('#bt-settings-currencies-content').focus();
      }, 1000);
    }

    /**
     * This function used in template with ng-init to prepare currencies data. At first it load all currencies, next
     *  load user settings, next set up selected currencies. Additionally this function prepare information of
     *  priorities settings.
     *
     * @ngdoc method
     * @name getAllCurrencies
     * @memberOf ecapp.btCurrenciesAndPrioritySettingsController
     */
    function getAllCurrencies() {
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Loading Currencies...</p>' });

      btCurrenciesService
        .getCurrenciesList()
        .then(function (data) {
          $scope.currenciesAll = data.currenciesList;
        })
        .then(function () {
          //noinspection JSUnresolvedVariable
          return btShareScopeService.wait().then(function () {
            $scope.userData = btShareScopeService.accountInfo;
            $scope.initialPriority = $scope.userData.priority;
            loadUserCurrencies();
            $ionicLoading.hide();
          });
        })
        .catch(function (reason) {
          $ionicLoading.hide();
          console.error('btCurrenciesAndPrioritySettingsController: error', reason);
        });
    }

    /**
     * Set up function to load selected currency from user data. Used in $scope.getAllCurrencies.
     *
     * $scope.userData.currency - list of selected currencies from server
     * $scope.currenciesArr - list of selected in ui currencies
     * $scope.currenciesAll - list of all currencies
     *
     * @ngdoc method
     * @name loadUserCurrencies
     * @memberOf ecapp.btCurrenciesAndPrioritySettingsController
     */
    function loadUserCurrencies() {
      // setup showAllCurrencies to false
      $scope.showAllCurrencies = false;

      $scope.currenciesArr = [];

      $scope.currenciesAll.forEach(function (v) {
        var objCurr = { id: v.id, name: v.name, isChecked: false, hasPriority: !!v.bt_priority };

        // check is current currency selected in user data
        // NOTE: try to use indexOf or another way to check this condition
        $scope.userData.currency.forEach(function (val) {
          if (v.id === val) {
            objCurr.isChecked = true;
          }
        });

        $scope.currenciesArr.push(objCurr);
      });
    }

    /**
     * On change select or deselect clicked currency and save changes to server. Wrapper for updateCurrencyFunc.
     *
     * @ngdoc method
     * @name selectOneCurrency
     * @memberOf ecapp.btCurrenciesAndPrioritySettingsController
     * @param {*} currency - Currency for select
     */
    function selectOneCurrency(currency) {
      var oldCurrencies = angular.copy($scope.userData.currency);
      actionWrapper(updateCurrencyFunc.bind(null, currency), 'currency', 'selectOneCurrency', currency, true).then(
        function () {
          btTradeIdeasFiltersService.updateCurrencies($scope.userData.currency, oldCurrencies);
        }
      );
    }

    /**
     * Wrapper for selectCommonCurrenciesFunc. Save currency settings to server.
     *
     * @ngdoc method
     * @name selectCommonCurrencies
     * @memberOf ecapp.btCurrenciesAndPrioritySettingsController
     */
    function selectCommonCurrencies() {
      var oldCurrencies = angular.copy($scope.userData.currency);
      actionWrapper(selectCommonCurrenciesFunc, 'currency', 'selectCommonCurrencies', null, true).then(function () {
        btTradeIdeasFiltersService.updateCurrencies($scope.userData.currency, oldCurrencies);
      });
    }

    /**
     * Wrapper for selectAllCurrenciesFunc. Save currency settings to server.
     *
     * @ngdoc method
     * @name selectAllCurrencies
     * @memberOf ecapp.btCurrenciesAndPrioritySettingsController
     */
    function selectAllCurrencies() {
      var oldCurrencies = angular.copy($scope.userData.currency);
      actionWrapper(selectAllCurrenciesFunc, 'currency', 'selectAllCurrencies', null, true).then(function () {
        btTradeIdeasFiltersService.updateCurrencies($scope.userData.currency, oldCurrencies);
      });
    }

    /**
     * Wrapper for clearCurrenciesFunc
     *
     * @ngdoc method
     * @name clearCurrencies
     * @memberOf ecapp.btCurrenciesAndPrioritySettingsController
     */
    function clearCurrencies() {
      actionWrapper(clearCurrenciesFunc, 'currency', 'clearCurrencies', null, false);
    }

    /**
     * On change save priority settings to server.
     *
     * @ngdoc method
     * @name clearCurrencies
     * @memberOf ecapp.btCurrenciesAndPrioritySettingsController
     * @param {*} previousValue
     */
    function updatePriority(previousValue) {
      actionWrapper(null, 'priority', 'updatePriority', { previous: previousValue }, true).then(function () {
        btTradeIdeasFiltersService.updatePriority($scope.userData.priority);
      });
    }

    /**
     * Select of deselect current currency.
     *
     * @ngdoc method
     * @name updateCurrencyFunc
     * @memberOf ecapp.btCurrenciesAndPrioritySettingsController
     * @param {Object} currency - Currency to select
     * @return {angular.IPromise<Boolean>}
     */
    function updateCurrencyFunc(currency) {
      var deferred = $q.defer();
      if ($scope.userData.currency.length) {
        var exist = 0;
        $scope.userData.currency.forEach(function (v, i) {
          if (currency.id === v) {
            exist = 1;
            if (!currency.isChecked) {
              $scope.userData.currency.splice(i, 1);
            }
          }
          if (
            i + 1 === $scope.userData.currency.length ||
            (exist !== 0 && currency.isChecked === false && i === $scope.userData.currency.length)
          ) {
            if (exist === 0) {
              $scope.userData.currency.push(currency.id);
            }
            deferred.resolve(true);
          }
        });
      } else {
        $scope.userData.currency.push(currency.id);
        deferred.resolve(true);
      }
      return deferred.promise;
    }

    /**
     * Checks the most common currencies.
     *
     * @ngdoc method
     * @name selectCommonCurrenciesFunc
     * @memberOf ecapp.btCurrenciesAndPrioritySettingsController
     * @return {angular.IPromise<Boolean>}
     */
    function selectCommonCurrenciesFunc() {
      var deferred = $q.defer();
      $scope.userData.currency = ['USD', 'EUR', 'GBP', 'CAD', 'JPY', 'AUD', 'CNY', 'CHF'];
      $scope.currenciesArr.forEach(function (v, i) {
        v.isChecked = false;
        $scope.userData.currency.forEach(function (v1) {
          if (v.id === v1) {
            v.isChecked = true;
          }
        });
        if (i + 1 === $scope.currenciesArr.length) {
          deferred.resolve(true);
        }
      });
      return deferred.promise;
    }

    /**
     * Select all currencies.
     *
     * @ngdoc method
     * @name selectAllCurrenciesFunc
     * @memberOf ecapp.btCurrenciesAndPrioritySettingsController
     * @return {angular.IPromise<Boolean>}
     */
    function selectAllCurrenciesFunc() {
      var deferred = $q.defer();
      $scope.userData.currency = [];
      $scope.currenciesArr.forEach(function (v, i) {
        $scope.userData.currency.push(v.id);
        if (!v.isChecked) {
          v.isChecked = true;
        }
        if (i + 1 === $scope.currenciesArr.length) {
          deferred.resolve(true);
        }
      });
      return deferred.promise;
    }

    /**
     * Wrapper for Deselect all currencies. You need to select something new to save new settings to server.
     *
     * @ngdoc method
     * @name clearCurrenciesFunc
     * @memberOf ecapp.btCurrenciesAndPrioritySettingsController
     * @return {angular.IPromise<Boolean>}
     */
    function clearCurrenciesFunc() {
      var deferred = $q.defer();
      $scope.currenciesArr.forEach(function (v, i) {
        if (v.isChecked) {
          v.isChecked = false;
        }
        if (i + 1 === $scope.currenciesArr.length) {
          $scope.userData.currency = [];
          deferred.resolve(true);
        }
      });
      return deferred.promise;
    }

    /**
     * Wrapper for user action.
     *
     * @param {?Function} handler - action handler
     * @param {String} property - property name: 'currency' or 'priority'
     * @param {String} action - action name
     * @param {*} actionDetails - action details
     * @param {Boolean} isSaveRequired - is saving required
     * @return {any}
     */
    function actionWrapper(handler, property, action, actionDetails, isSaveRequired) {
      if (btRestrictionService.hasFeature('personalization')) {
        $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Update User Data...</p>' });

        $rootScope.dataChanged = true;

        return (typeof handler === 'function' ? handler() : $q.resolve())
          .then(function () {
            if (isSaveRequired) {
              return btShareScopeService.updateProfileSettingsFromFieldsList([property]);
            }
          })
          .then(trackAction.bind(null, action, actionDetails))
          .catch(function (reason) {
            console.error('btCurrenciesAndPrioritySettingsController:', reason);
            return $q.reject(reason);
          })
          .finally(function () {
            $ionicLoading.hide();
          });
      } else {
        if (property === 'currency' && action === 'selectOneCurrency') {
          actionDetails.isChecked = !actionDetails.isChecked;
        }

        if (property === 'priority' && action === 'updatePriority') {
          $timeout(function () {
            $scope.userData.priority = $scope.initialPriority;
          });
        }

        return btRestrictionService.showUpgradePopup('personalization');
      }
    }

    /**
     * Track user action
     * @param {String} name - action name
     * @param {*} details - action details
     */
    function trackAction(name, details) {
      if (window.btSettings.BT_DOMAIN === 'bettertrader') {
        var params = { category: 'settings' };
        if (details) {
          params.currency = details.id;
        }

        $analytics.eventTrack(name, params);
      }
    }
  }
})();
