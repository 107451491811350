/**
 * Created by Sergey Panpurin on 9/2/19.
 */

// @ts-check
(function btRiskServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btRiskService:';

  /**
   * This service ...
   *
   * @ngdoc service
   * @name ecapp.btRiskService
   */

  angular.module('ecapp').factory('btRiskService', service);

  service.$inject = [
    '$rootScope',
    '$q',
    '$timeout',
    '$interval',
    'RiskCache',
    'btEventEmitterService',
    'btRiskBasketService',
    'btRiskIntervalService',
    'btRiskChartService',
    '$btRiskSettings',
    'btPusherService',
    'btShareScopeService',
    'btToastrService',
    'btVoiceAssistantHelperService',
    'btInstrumentsService',
    'btSettings',
    'btSocketService',
  ];

  /**
   *
   * @param {ecapp.ICustomRootScope} $rootScope -
   * @param {angular.IQService} $q - promise interface
   * @param {angular.ITimeoutService} $timeout -
   * @param {angular.IIntervalService} $interval -
   * @param {ecapp.IGeneralLoopbackService} lbRiskCache -
   * @param {ecapp.IEventEmitterService} btEventEmitterService -
   * @param {ecapp.IRiskBasketService} btRiskBasketService
   * @param {ecapp.IRiskIntervalService} btRiskIntervalService
   * @param {ecapp.IRiskChartService} btRiskChartService
   * @param {ecapp.IRiskSettingsService} $btRiskSettings
   * @param {ecapp.IPusherService} btPusherService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IVoiceAssistantHelperService} btVoiceAssistantHelperService
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.ISettings} btSettings
   * @param {ecapp.ISocketService} btSocketService - socket.io service
   * @return {ecapp.IRiskService}
   */
  function service(
    $rootScope,
    $q,
    $timeout,
    $interval,
    lbRiskCache,
    btEventEmitterService,
    btRiskBasketService,
    btRiskIntervalService,
    btRiskChartService,
    $btRiskSettings,
    btPusherService,
    btShareScopeService,
    btToastrService,
    btVoiceAssistantHelperService,
    btInstrumentsService,
    btSettings,
    btSocketService
  ) {
    if (gDebug) console.log(gPrefix, 'running...');

    var gLastUpdate = null;
    var gIsInitialized = false;
    var gInitializationPromise;
    var gIsIndicatorsLoading = false;
    var gIsInsightsLoading = false;

    /** @type {ext.IZingChartJSON} */
    var gRiskChart = {};
    var gRiskChartInitialized = false;

    /** @type {ext.IZingChartJSON} */
    var gIndicatorsChart = {};
    var gIndicatorsChartInitialized = false;

    /** @type {ext.IZingChartJSON} */
    var gHistoricalChart = {};
    var gHistoricalChartInitialized = false;

    /** @type {ext.IZingChartJSON} */
    var gRealtimePreviewChart = {};
    var gRealtimePreviewInitialized = false;

    var gIntervalHandler = null;

    /** @type {ecapp.IRiskInsight[]} */
    var gInsights = [];

    /** @type {ecapp.IRiskInsight[]} */
    var gSelectedInsights = [];

    var gChartCounter = {};

    var UPDATE_INTERVAL = 30000;

    var SIGNIFICANT = 40;
    var EXTREME = 75;

    /**
     * @type {{DAILY: string, CROSSING: string, MOVEMENT: string, DIVERGENCE: string}}
     */
    var InsightType = {
      CROSSING: 'crossing',
      MOVEMENT: 'movement',
      DAILY: 'daily',
      DIVERGENCE: 'divergence',
    };

    /**
     * @type {{FROZEN: string, RELEVANT: string, OBSOLETE: string}}
     */
    var InsightStatus = {
      RELEVANT: 'relevant',
      FROZEN: 'frozen',
      OBSOLETE: 'obsolete',
    };

    btEventEmitterService.addListener('logout:success', onLogoutSuccess);
    btEventEmitterService.addListener('login:success', onLoginSuccess);

    var gSocket;

    // if (btSettings.BT_SOCKET_IO_URL) {
    //   var socket = io(btSettings.BT_SOCKET_IO_URL);
    //
    //   socket.on('connect', function (data) {
    //     if (gDebug) console.log('Socket.IO connect', data);
    //   });
    //
    //   socket.on('risk-monitor', function (msg) {
    //     if (gDebug) console.log('Socket.IO risk-monitor', msg);
    //     onRealtimeUpdate(msg);
    //   });
    //
    //   socket.on('disconnect', function (data) {
    //     if (gDebug) console.log('Socket.IO disconnect', data);
    //   });
    // } else {
    //   btToastrService.error('Socket.IO server is undefined!', 'System');
    // }

    var PERIODS = {
      M1: 1,
      M5: 5,
      M10: 10,
      M15: 15,
      M30: 30,
      M60: 60,
      M120: 120,
      M180: 180,
      M240: 240,
      H1: 60,
      H2: 120,
      H3: 180,
      H4: 240,
    };

    var QUICK_MOVEMENT_VELOCITY = 2;

    return {
      initialize: initialize,
      reinitialize: reinitialize,
      loadHistory: loadIndicators,

      getBaskets: getBaskets,
      selectBasket: selectBasket,
      selectBasketById: selectBasketById,
      getSelectedBasket: getSelectedBasket,
      isSelectedBasket: isSelectedBasket,

      getIntervals: getIntervals,
      selectInterval: selectInterval,
      selectIntervalById: selectIntervalById,
      getSelectedInterval: getSelectedInterval,
      isSelectedInterval: isSelectedInterval,

      getIndicators: getIndicators,

      getSmartRiskChart: getSmartRiskChart,
      getSmartIndicatorsChart: getSmartIndicatorsChart,
      getSmartHistoricalChart: getSmartHistoricalChart,
      getRealtimePreviewChart: getRealtimePreviewChart,

      getSelectedInsights: getSelectedInsights,

      getLastUpdateDate: getLastUpdateDate,
      checkIsLoading: checkIsLoading,
      checkIsInitialized: checkIsInitialized,
      stopUpdates: stopUpdates,

      // Testing
      loadSettings: loadSettings,
      unloadSettings: unloadSettings,
      refresh: refresh,

      getChartId: getChartId,
      openSettingsDialog: openSettingsDialog,

      getRangeMeterTemplate: getRangeMeterTemplate,
      updateRangeMeter: updateRangeMeter,
    };

    /**
     * This function initializes risk service and estimate connection to socket io server.
     * @alias initialize
     * @alias ecapp.btRiskService#unloadSettings
     * @return {angular.IPromise<void>}
     */
    function initialize() {
      if (!gSocket) {
        return loadSettings()
          .then(function () {
            return btSocketService.connect('/risk-monitor');
          })
          .then(function (socket) {
            gSocket = socket;
            gSocket.on('update', function (msg) {
              // socket.on('risk-monitor', function (msg) {
              if (gDebug) console.log('Socket.IO risk-monitor', msg);
              onRealtimeUpdate(msg);
            });
          })
          .catch(function (reason) {
            console.error(reason);
            btToastrService.error(reason.message, 'System');
          });
      } else {
        // console.log('TEST: Socket already initialized');
        return $q.resolve();
      }
    }

    /**
     *
     */
    function reinitialize() {
      gLastUpdate = null;
      gIsInitialized = false;
      gIsIndicatorsLoading = false;
      gIsInsightsLoading = false;
      gRiskChart = {};
      gRiskChartInitialized = false;
      gIndicatorsChart = {};
      gIndicatorsChartInitialized = false;
      gHistoricalChart = {};
      gHistoricalChartInitialized = false;
      stopUpdates();
      gIntervalHandler = null;
      // console.log('Reinitialized');
    }

    /**
     * This function handles user logout.
     */
    function onLogoutSuccess() {
      gSocket = undefined;
      stopUpdates();
    }

    /**
     * @alias ecapp.btRiskService#unloadSettings
     */
    function unloadSettings() {
      gInitializationPromise = undefined;
      stopUpdates();
    }

    /**
     *
     */
    function onLoginSuccess() {
      initialize();
    }

    /**
     * This function processes real time updates.
     *
     * @param {ecapp.IRiskMessage} message - risk message
     */
    function onRealtimeUpdate(message) {
      gLastUpdate = new Date();
      var basket = getSelectedBasket();
      var interval = getSelectedInterval();
      btRiskBasketService.updateIndicators(message, interval);
      btRiskBasketService.updateBaskets(message, interval);

      if (message.insights && message.insights.length) {
        message.insights.forEach(function (insight) {
          var obj = createInsight(message, insight);
          gInsights.push(obj);
          if (checkIsInsightVisible(obj)) {
            gSelectedInsights.push(obj);
            notify(obj, basket, interval, true);
          }
        });
      }

      updateCharts();
      updateInsights();
    }

    /**
     *
     * @param {ecapp.IRiskInsight} insight -
     * @param {ecapp.IRiskBasket} basket -
     * @param {ecapp.IRiskInterval} interval -
     * @param {boolean} readable -
     */
    function notify(insight, basket, interval, readable) {
      if (insight.basket === basket.id && insight.interval === interval.granularity) {
        var params = {
          type: 'risk',
          link: { state: 'ecapp.app.main.risk-monitor', params: {} },
        };

        params.date = insight.date;

        if (readable) {
          if ($btRiskSettings.hasToast()) {
            btToastrService.info(insight.text.content, insight.text.title, params);
          } else {
            btToastrService.add(insight.text.content, insight.text.title, params);
          }

          if ($btRiskSettings.hasVoice()) {
            btVoiceAssistantHelperService.readMessage(insight.text.speech, 'text', 'risk');
          }
        } else {
          btToastrService.add(insight.text.content, insight.text.title, params);
        }
      }
    }

    /**
     * This function returns list of risk baskets.
     *
     * @alias ecapp.btRiskService#getBaskets
     * @return {ecapp.IRiskBasket[]}
     */
    function getBaskets() {
      return btRiskBasketService.getBaskets();
    }

    /**
     * This function selects basket.
     * It has a side effect: update charts.
     *
     * @alias ecapp.btRiskService#selectBasketById
     * @param {string} id - basket identifier
     * @return {angular.IPromise<boolean>}
     */
    function selectBasketById(id) {
      var basket = btRiskBasketService.getBaskets().filter(function (value) {
        return value.id === id;
      })[0];

      if (basket) {
        return selectBasket(basket);
      } else {
        return $q.resolve(false);
      }
    }

    /**
     * This function selects basket.
     * It has a side effect: update charts.
     *
     * @alias ecapp.btRiskService#selectBasket
     * @param {ecapp.IRiskBasket} basket - basket object
     * @return {angular.IPromise<boolean>}
     */
    function selectBasket(basket) {
      var result = btRiskBasketService.selectBasket(basket);

      gIsIndicatorsLoading = true;

      if (result) {
        updateCharts();
        updateInsights();
        filterSelectedInsights();
        $btRiskSettings.setUserBasket(basket.id);
        $btRiskSettings.saveUserSettings();
      }

      $timeout(function () {
        gIsIndicatorsLoading = false;
      }, 500);

      return $q.resolve(result);
    }

    /**
     * This function returns selected basket.
     *
     * @alias ecapp.btRiskService#getSelectedBasket
     * @return {ecapp.IRiskBasket} - basket object
     */
    function getSelectedBasket() {
      return btRiskBasketService.getSelectedBasket();
    }

    /**
     * This function checks if a basket selected.
     *
     * @alias ecapp.btRiskService#isSelectedBasket
     * @param {ecapp.IRiskBasket} basket - basket object
     * @return {boolean} - whether basket is selected.
     */
    function isSelectedBasket(basket) {
      return btRiskBasketService.isSelectedBasket(basket);
    }

    /**
     * This function loads indicators for selected interval.
     * Side effects: start of auto updates.
     *
     * @alias ecapp.btRiskService#loadHistory
     * @return {angular.IPromise} - promise to load indicators.
     */
    function loadIndicators() {
      gIsIndicatorsLoading = true;
      return btRiskBasketService
        .loadHistory(getSelectedInterval())
        .then(function () {
          $timeout(function () {
            gIsIndicatorsLoading = false;
          }, 500);

          if (!gIsInitialized) {
            gLastUpdate = new Date();
            btRiskBasketService.updateBaskets();
            updateCharts();
            startUpdates();
          }
        })
        .catch(function (reason) {
          gIsIndicatorsLoading = false;
          return $q.reject(reason);
        });
    }

    /**
     * This function starts updates indicators.
     * @private
     */
    function startUpdates() {
      gIntervalHandler = $interval(function () {
        updateInsights();
        btRiskBasketService.updateHistory(getSelectedInterval()).then(function (wasUpdated) {
          if (wasUpdated) updateCharts();
        });
      }, UPDATE_INTERVAL);
    }

    /**
     * This function stops auto updates.
     *
     * @alias ecapp.btRiskService#stopUpdates
     */
    function stopUpdates() {
      if (gIntervalHandler) $interval.cancel(gIntervalHandler);
    }

    /**
     * This function returns list of intervals.
     *
     * @alias ecapp.btRiskService#getIntervals
     * @return {ecapp.IRiskInterval[]} - list of intervals
     */
    function getIntervals() {
      return btRiskIntervalService.getIntervals();
    }

    /**
     * This function selects interval.
     * Side effects: reloading of indicators, update of baskets and update of charts.
     *
     * @alias ecapp.btRiskService#selectInterval
     * @param {ecapp.IRiskInterval} interval - interval object
     * @return {angular.IPromise<boolean>} - promise to select interval
     */
    function selectInterval(interval) {
      var result = btRiskIntervalService.selectInterval(interval);

      if (result) {
        $btRiskSettings.setUserInterval(interval.id);
        $btRiskSettings.saveUserSettings();
        // gUserSettings['general'].interval = interval.id;
        // btShareScopeService.saveUserSettings('risk-monitor', gUserSettings);

        gIsIndicatorsLoading = true;
        // console.log(gIsLoading);
        btRiskBasketService
          .loadHistory(interval)
          .then(function () {
            $timeout(function () {
              gIsIndicatorsLoading = false;
            }, 500);
            btRiskBasketService.updateBaskets();
            updateCharts();
            updateInsights();
            filterSelectedInsights();
          })
          .catch(function (reason) {
            gIsIndicatorsLoading = false;
            return $q.reject(reason);
          });
      }

      return $q.resolve(result);
    }

    /**
     * This function selects basket.
     * It has a side effect: update charts.
     *
     * @alias ecapp.btRiskService#selectIntervalById
     * @param {string} id - basket identifier
     * @return {angular.IPromise<boolean>}
     */
    function selectIntervalById(id) {
      var interval = btRiskIntervalService.getIntervals().filter(function (item) {
        return item.id === id;
      })[0];

      if (interval) {
        return selectInterval(interval);
      } else {
        return $q.resolve(false);
      }
    }

    /**
     * This function returns selected interval.
     *
     * @alias ecapp.btRiskService#getSelectedInterval
     * @return {ecapp.IRiskInterval} - selected interval
     */
    function getSelectedInterval() {
      return btRiskIntervalService.getSelectedInterval();
    }

    /**
     * This function checks if a interval is selected.
     *
     * @alias ecapp.btRiskService#isSelectedInterval
     * @param {ecapp.IRiskInterval} interval - interval object
     * @return {boolean} - whether interval is selected
     */
    function isSelectedInterval(interval) {
      return btRiskIntervalService.isSelectedInterval(interval);
    }

    /**
     * @alias ecapp.btRiskService#getIndicators
     * @return {ecapp.IRiskIndicator[]}
     */
    function getIndicators() {
      return btRiskBasketService.getIndicators();
    }

    /**
     * @alias ecapp.btRiskService#getSelectedInsights
     * @return {ecapp.IRiskInsight[]}
     */
    function getSelectedInsights() {
      return gSelectedInsights;
    }

    /**
     * This function returns auto-updated risk indicator chart connected to selected basket and selected interval.
     *
     * @alias ecapp.btRiskService#getRiskChart
     * @return {ext.IZingChartJSON} - chart object
     */
    function getSmartRiskChart() {
      if (!gRiskChartInitialized) {
        angular.extend(gRiskChart, btRiskChartService.getRiskChart(getSelectedBasket(), getSelectedInterval()));
        gRiskChartInitialized = true;
      }

      return gRiskChart;
    }

    /**
     * This function returns auto-updated indicators chart connected to selected basket and selected interval.
     *
     * @alias ecapp.btRiskService#getIndicatorsChart
     * @return {ext.IZingChartJSON} - chart object
     */
    function getSmartIndicatorsChart() {
      if (!gIndicatorsChartInitialized) {
        angular.extend(gIndicatorsChart, btRiskChartService.getIndicatorsChart(getSelectedBasket()));
        gIndicatorsChartInitialized = true;
      }

      return gIndicatorsChart;
    }

    /**
     * This function returns auto-updated historical chart connected to selected basket and selected interval.
     *
     * @alias ecapp.btRiskService#getHistoricalChart
     * @return {ext.IZingChartJSON} - chart object
     */
    function getSmartHistoricalChart() {
      if (!gHistoricalChartInitialized) {
        angular.extend(
          gHistoricalChart,
          btRiskChartService.getHistoricalChart(getSelectedBasket(), getSelectedInterval())
        );
        gHistoricalChartInitialized = true;
      }

      return gHistoricalChart;
    }

    /**
     * This function returns auto-updated historical chart connected to selected basket and selected interval.
     *
     * @alias ecapp.btRiskService#getRealtimePreviewChart
     * @return {ext.IZingChartJSON}
     */
    function getRealtimePreviewChart() {
      if (!gRealtimePreviewInitialized) {
        angular.extend(
          gRealtimePreviewChart,
          btRiskChartService.getRealtimePreviewChart(getSelectedBasket(), getSelectedInterval())
        );
        gRealtimePreviewInitialized = true;
      }

      return gRealtimePreviewChart;
    }

    /**
     * This function returns date of last updates of indicators.
     *
     * @alias ecapp.btRiskService#getLastUpdateDate
     * @return {Date|null} - date of last update or null
     */
    function getLastUpdateDate() {
      return gLastUpdate;
    }

    /**
     * This function updates insights for selected basket and selected interval.
     * @private
     */
    function updateInsights() {
      var basket = getSelectedBasket();
      var interval = getSelectedInterval();
      gInsights.forEach(function (insight) {
        if (insight.basket === basket.id && insight.interval === interval.id) {
          updateInsightStatus(insight, interval);
          updateInsightRanges(insight, basket, interval);
        }
      });
    }

    /**
     * This function updates chart objects.
     * @private
     */
    function updateCharts() {
      // console.log('Charts was updated at', new Date());

      angular.extend(gRiskChart, btRiskChartService.getRiskChart(getSelectedBasket(), getSelectedInterval()));
      gRiskChartInitialized = true;

      angular.extend(gIndicatorsChart, btRiskChartService.getIndicatorsChart(getSelectedBasket()));
      gIndicatorsChartInitialized = true;

      angular.extend(
        gHistoricalChart,
        btRiskChartService.getHistoricalChart(getSelectedBasket(), getSelectedInterval())
      );
      gHistoricalChartInitialized = true;

      angular.extend(
        gRealtimePreviewChart,
        btRiskChartService.getRealtimePreviewChart(getSelectedBasket(), getSelectedInterval())
      );
      gRealtimePreviewInitialized = true;
    }

    /**
     * This function returns true if indicators are loading.
     *
     * @alias ecapp.btRiskService#checkIsLoading
     * @return {boolean} - whether indicators are loading
     */
    function checkIsLoading() {
      return gIsIndicatorsLoading;
    }

    /**
     * This function returns true if indicators were initialized.
     *
     * @alias ecapp.btRiskService#checkIsInitialized
     * @return {boolean} - whether indicators were initialized
     */
    function checkIsInitialized() {
      return gIsInitialized;
    }

    /**
     *
     * @alias ecapp.btRiskService#loadSettings
     * @return {angular.IPromise<object>}
     */
    function loadSettings() {
      if (!gInitializationPromise) {
        gInitializationPromise = $btRiskSettings
          .loadAppSettings()
          .then(function (appSettings) {
            if (gDebug) console.log(gPrefix, 'App settings was loaded', appSettings);
            return $btRiskSettings.loadUserSettings();
          })
          .then(function (userSettings) {
            if (gDebug) console.log(gPrefix, 'User settings was loaded', userSettings);
            return btRiskIntervalService.initialize(
              $btRiskSettings.getAppIntervals(),
              $btRiskSettings.getUserInterval()
            );
          })
          .then(function () {
            if (gDebug) console.log(gPrefix, 'Risk intervals was initialized');
            return btRiskBasketService.initialize(
              $btRiskSettings.getAppIndicators(),
              $btRiskSettings.getAppBaskets(),
              btRiskIntervalService.getIntervals(),
              $btRiskSettings.getUserBasket()
            );
          })
          .then(function () {
            if (gDebug) console.log(gPrefix, 'Risk baskets was initialized');

            // if ($rootScope.isDevMode)
            loadInitialInsights().then(processInitialInsights);

            return loadIndicators();
          })
          .then(function () {
            if (gDebug) console.log(gPrefix, 'Indicators was loaded');
            if (gDebug) console.log(gPrefix, 'Service was initialized');
            gIsInitialized = true;
          });
      }

      return gInitializationPromise;
    }

    /**
     *
     * @param {ecapp.IRiskInsight[]} insights
     */
    function processInitialInsights(insights) {
      insights.forEach(function (insight) {
        gInsights.push(insight);
        if (checkIsInsightVisible(insight)) {
          gSelectedInsights.push(insight);
          notify(insight, getSelectedBasket(), getSelectedInterval(), false);
        }
      });
    }

    /**
     *
     */
    function refresh() {}

    /**
     * This function loads initial risk insights.
     *
     * @alias ecapp.btRiskService#getLastRisks
     * @return {angular.IPromise<ecapp.IRiskInsight[]>}
     */
    function loadInitialInsights() {
      gIsInsightsLoading = true;

      var query = {
        filter: {
          where: { 'insights.0': { exists: true } },
          order: ['time ASC'],
        },
      };

      return lbRiskCache
        .find(query)
        .$promise.then(parseInsights)
        .then(function (insights) {
          gIsInsightsLoading = false;
          return insights;
        });

      /**
       * This function parses risk insights from cached risk documents.
       *
       * @param {ecapp.IRiskDocument[]} docs - risk documents
       * @return {ecapp.IRiskInsight[]}
       */
      function parseInsights(docs) {
        return docs.reduce(function (acc, doc) {
          return acc.concat(
            doc.insights.map(function (insight) {
              return createInsight(doc, insight);
            })
          );
        }, []);
      }
    }

    /**
     * This function creates risk insights object from risk message of risk document.
     *
     * @param {ecapp.IRiskMessage|ecapp.IRiskDocument} doc - the risk document or risk message
     * @param {ecapp.IRiskInsightRecord} insight - the risk insight record
     * @return {ecapp.IRiskInsight} - the risk insight object
     */
    function createInsight(doc, insight) {
      var interval = btRiskIntervalService.getIntervalById(insight.interval);
      var basket = btRiskBasketService.getBasketById(insight.basket);

      var obj = {
        time: doc.time,
        date: new Date(doc.time * 1000),
        id: insight.id,
        basket: insight.basket,
        interval: insight.interval,
        indicator: null,
        minutesToFrozen: 0,
        status: InsightStatus.OBSOLETE,
        period: insight.period,
        level: insight.level,
        value: insight.value,
        velocity: insight.period ? getVelocity(insight) : 0,
        magnitude: {
          value: 0,
          impact: 0,
          tag: '[Unknown]',
        },
        text: {
          icon: '',
          title: '',
          content: '',
          speech: '',
        },
        ref: {
          basket: doc.baskets[insight.basket][insight.interval],
          indicator: 0,
          instrument: 0,
        },
        ranges: {
          basket: [],
          indicator: [],
          instrument: [],
        },
      };

      if (insight.indicator) {
        obj.indicator = btRiskBasketService.getIndicatorById(insight.indicator);
        obj.ref.indicator = doc.indicators[insight.indicator][insight.interval];

        if (obj.indicator.instrument) {
          var p = btInstrumentsService.getPrecision(obj.indicator.instrument);
          obj.ref.instrument = parseFloat(doc.indicators[insight.indicator]['RAW'].toFixed(p));
        } else {
          obj.ref.instrument = doc.indicators[insight.indicator]['RAW'];
        }
      }

      if (interval) {
        obj.minutesToFrozen = millisecondsToMinutes(getMilliSecondsToFrozen(interval));
        updateInsightStatus(obj, interval);
      }

      var mgn = $btRiskSettings.getInsightMagnitude(obj);
      obj.magnitude = {
        value: mgn,
        impact: $btRiskSettings.getInsightImpact(mgn),
        tag: '[' + $btRiskSettings.getMagnitudeText(mgn) + ']',
      };

      addContent(obj, insight);
      obj.text.content += ' ' + obj.magnitude.tag;

      if (basket) updateInsightRanges(obj, basket, interval);

      return obj;
    }

    /**
     *
     * @param {ecapp.IRiskInsight} obj - the risk insight object
     * @param {ecapp.IRiskInterval} interval - interval
     */
    function updateInsightStatus(obj, interval) {
      var age = Date.now() - obj.time * 1000;
      if (age < getMilliSecondsToFrozen(interval)) {
        obj.status = InsightStatus.RELEVANT;
      } else if (age < getMilliSecondsToObsolete(interval)) {
        obj.status = InsightStatus.FROZEN;
      } else {
        obj.status = InsightStatus.OBSOLETE;
      }
    }

    /**
     *
     * @param {ecapp.IRiskInsight} obj - the risk insight object
     * @param {ecapp.IRiskBasket} basket - basket
     * @param {ecapp.IRiskInterval} interval - interval
     */
    function updateInsightRanges(obj, basket, interval) {
      // Obsolete insights
      if (obj.status === InsightStatus.OBSOLETE) return;
      // Empty history
      if (!basket.history.length) return;
      // Not enough historical data
      if (basket.history[0].time > obj.time * 1000) return;

      var basketValues = [].concat([obj.ref.basket], getInsightHistory(basket.history, obj, interval));

      updateRiskRange(obj.ranges.basket, basketValues, obj.ref.basket);

      if (obj.indicator) {
        if (obj.indicator.history[0].time > obj.time * 1000) {
          // Not enough historical data
          return;
        }

        if (obj.indicator.history.length !== basket.history.length) {
          console.warn('Basket history and indicator history should have a same size.');
        } else {
          var indValues = [].concat([obj.ref.indicator], getInsightHistory(obj.indicator.history, obj, interval));
          var diffValues = calculateDifference(indValues, basketValues);

          updateRiskRange(obj.ranges.indicator, diffValues, obj.ref.indicator - obj.ref.basket);

          obj.ranges.basket[4] = Math.max(obj.ranges.basket[4], obj.ranges.indicator[4]);
          obj.ranges.indicator[4] = Math.max(obj.ranges.basket[4], obj.ranges.indicator[4]);

          if (obj.indicator.instrument) {
            var prices = [].concat(
              [[obj.time, obj.ref.instrument, obj.ref.instrument, obj.ref.instrument, obj.ref.instrument]],
              getPriceHistory(obj.indicator.instrument.history || [], obj, interval)
            );
            var tick = btInstrumentsService.getPriceUnitValue(obj.indicator.instrument);
            updatePriceRange(obj.ranges.instrument, prices, obj.ref.instrument, tick);
          }
        }
      }
    }

    /**
     *
     * @param {number[]} iValues - indicator values
     * @param {number[]} bValues - basket values
     * @return {number[]}
     */
    function calculateDifference(iValues, bValues) {
      if (iValues.length === bValues.length) {
        return iValues.map(function (value, i) {
          return value - bValues[i];
        });
      } else {
        return [];
      }
    }

    /**
     * This function returns list of magnitudes.
     *
     * @param {ecapp.IRiskRecord[]} history - risk history
     * @param {ecapp.IRiskInsight} obj - risk insight object
     * @param {ecapp.IRiskInterval} interval - interval object
     * @return {number[]} - historical values
     */
    function getInsightHistory(history, obj, interval) {
      return history
        .filter(function (record) {
          return record.time >= obj.time * 1000 && record.time <= obj.time * 1000 + getMilliSecondsToFrozen(interval);
        })
        .map(function (record) {
          return record.mgn;
        });
    }

    /**
     * This function returns list of magnitudes.
     *
     * @param {btPriceCandle[]} history - risk history
     * @param {ecapp.IRiskInsight} obj - risk insight object
     * @param {ecapp.IRiskInterval} interval - interval object
     * @return {btPriceCandle[]} - historical values
     */
    function getPriceHistory(history, obj, interval) {
      return history.filter(function (record) {
        return record[0] >= obj.time * 1000 && record[0] <= obj.time * 1000 + getMilliSecondsToFrozen(interval);
      });
    }

    /**
     * This function updates range using historical values.
     *
     * @param {number[]} range - range data
     * @param {number[]} values - historical values
     * @param {number} ref - reference value
     */
    function updateRiskRange(range, values, ref) {
      var open = Math.round(ref);
      var max = Math.round(Math.max.apply(null, values));
      var min = Math.round(Math.min.apply(null, values));
      var last = Math.round(values[values.length - 1]);
      var scale = Math.floor(Math.max(max - open, open - min) / 10 + 1) * 10;

      range[0] = open;
      range[1] = range[1] === undefined ? max : Math.max(max, range[1]);
      range[2] = range[2] === undefined ? min : Math.min(min, range[1]);
      range[3] = last;
      range[4] = scale;
      range[5] = 0;
    }

    /**
     * This function updates range using historical values.
     *
     * @param {number[]} range - range data
     * @param {btPriceCandle[]} candles - historical values
     * @param {number} ref - reference value
     * @param {number} tick - tick size
     */
    function updatePriceRange(range, candles, ref, tick) {
      var open = ref;
      var max = Math.max.apply(
        null,
        candles.map(function (value) {
          return value[2];
        })
      );
      var min = Math.min.apply(
        null,
        candles.map(function (value) {
          return value[3];
        })
      );
      var last = candles[candles.length - 1][4];
      var scale = Math.floor(Math.max(max - open, open - min) / tick + 1) * tick;

      range[0] = open;
      range[1] = range[1] === undefined ? max : Math.max(max, range[1]);
      range[2] = range[2] === undefined ? min : Math.min(min, range[1]);
      range[3] = last;
      range[4] = scale;
      range[5] = tick;
    }

    /**
     * This function returns insight velocity as points per minute.
     *
     * @param {ecapp.IRiskInsightRecord} insight - the risk insight record
     * @return {number} - insight velocity
     */
    function getVelocity(insight) {
      return insight.value / getPeriodInMinutes(insight);
    }

    /**
     * This function returns period in minutes.
     *
     * @param {ecapp.IRiskInsightRecord} insight - the risk insight record
     * @return {number}
     */
    function getPeriodInMinutes(insight) {
      return PERIODS[insight.period] || 0;
    }

    /**
     * This function adds text content to insight.
     *
     * @param {ecapp.IRiskInsight} obj - the risk insight object
     * @param {ecapp.IRiskInsightRecord} insight - the risk insight record
     */
    function addContent(obj, insight) {
      switch (obj.id) {
        case InsightType.CROSSING:
          obj.text = createCrossing(obj);
          break;
        case InsightType.MOVEMENT:
          if (Math.abs(obj.velocity) >= QUICK_MOVEMENT_VELOCITY) obj.text = createQuickMovement(obj);
          else obj.text = createSignificantMovement(obj);
          break;
        case InsightType.DAILY:
          obj.text = createDailyMovement(obj);
          break;
        case InsightType.DIVERGENCE:
          obj.text = createDivergence(obj, insight);
          break;
        default:
          obj.text = {
            icon: 'ion-ionic',
            title: 'Risk Insight',
            content: JSON.stringify(obj),
            speech: '',
          };
      }
    }

    /**
     * This function creates content for risk insights with type `crossing`.
     *
     * @param {ecapp.IRiskInsight} obj - the risk insight object
     * @return {ecapp.IRiskInsightText} - risk insights content
     */
    function createCrossing(obj) {
      var magnitude = (obj.level > 0 ? 'positive above ' : 'negative below ') + Math.abs(obj.level) + ' points';
      var suffix =
        Math.abs(obj.level) >= SIGNIFICANT
          ? '. Risk is ' + (Math.abs(obj.level) >= EXTREME ? 'Extremely ' : '') + (obj.level > 0 ? 'On' : 'Off')
          : '';
      return {
        icon: 'ion-forward',
        title: 'Risk Index',
        content: 'Trending ' + magnitude + suffix,
        speech: 'Risk index is trending ' + magnitude + '. ' + suffix,
      };
    }

    /**
     * This function creates content for risk insights with type `movement` and low velocity.
     *
     * @param {ecapp.IRiskInsight} obj - the risk insight object
     * @return {ecapp.IRiskInsightText} - risk insights content
     */
    function createSignificantMovement(obj) {
      var side = obj.level > 0 ? 'up' : 'down';
      var movement = Math.abs(obj.level) + ' points in the last ' + getPeriodInMinutes(obj) + ' minutes';
      return {
        icon: 'ion-reply',
        title: 'Risk Index',
        content: 'Went ' + side + ' significantly ' + movement + ' (' + obj.velocity.toFixed(1) + ' pt/min)',
        speech: 'Risk Index went ' + side + ' significantly. ' + movement + '.',
      };
    }

    /**
     * This function creates content for risk insights with type `movement` and high velocity.
     *
     * @param {ecapp.IRiskInsight} obj - the risk insight object
     * @return {ecapp.IRiskInsightText} - risk insights content
     */
    function createQuickMovement(obj) {
      var movement = Math.abs(obj.level) + ' points in the last ' + getPeriodInMinutes(obj) + ' minutes';
      return {
        icon: 'ion-reply-all',
        title: 'Risk Index',
        content:
          (obj.level > 0 ? 'Jumped' : 'Dropped') + ' by ' + movement + ' (' + obj.velocity.toFixed(1) + ' pt/min)',
        speech: 'Risk Index ' + (obj.level > 0 ? 'jumped' : 'dropped') + ' by ' + movement + '.',
      };
    }

    /**
     * This function creates content for risk insights with type `daily`.
     *
     * @param {ecapp.IRiskInsight} obj - the risk insight object
     * @return {ecapp.IRiskInsightText} - risk insights content
     */
    function createDailyMovement(obj) {
      var action = Math.abs(obj.level) + ' points from daily ' + (obj.level > 0 ? 'low to high' : 'high to low');
      return {
        icon: 'ion-share',
        title: 'Risk Index',
        content: 'Moved ' + action + '',
        speech: 'Risk Index moved ' + action + '.',
      };
    }

    /**
     * This function creates content for risk insights with type `divergence`.
     *
     * @param {ecapp.IRiskInsight} obj - the risk insight object
     * @param {ecapp.IRiskInsightRecord} insight - the risk insight record
     * @return {ecapp.IRiskInsightText} - risk insights content
     */
    function createDivergence(obj, insight) {
      var name = obj.indicator.instrument.displayName;
      var pronunciation = btInstrumentsService.getPronunciation(obj.indicator.instrument);

      if (insight.normal) {
        return {
          icon: insight.risk ? 'ion-arrow-down-a' : 'ion-arrow-down-c',
          title: (insight.risk ? 'Relative ' : '') + 'Reconvergence ' + name,
          content: 'Risk Indicator for ' + name + ' is getting closer to the Risk Index',
          speech: 'Reconvergence - Risk Indicator for ' + pronunciation + ' is getting closer to the Risk Index.',
        };
      } else if (insight.risk) {
        // Risk index trending positive (34) but EUR/CHF (-22) lagging below by 56 points.
        // Risk index trending positive (84) but EUR/CHF (22) lagging below by 62 points.
        // Risk index trending positive (34) and EUR/CHF (84) higher by 50 points.
        // Risk index trending negative (-34) but EUR/CHF (22) holding above by 56 points.
        // Risk index trending negative (-84) but EUR/CHF (-22) holding above by 62 points.
        // Risk index trending negative (-34) ant EUR/CHF (-84) lower by 50 points.

        var positive = obj.ref.basket > 0 ? 'positive' : 'negative';
        var basket = Math.floor(obj.ref.basket);
        var indicator = Math.floor(obj.ref.indicator);
        var divergence = Math.floor(Math.abs(obj.value));
        var but =
          ((obj.ref.basket > 0 && obj.ref.indicator > obj.ref.basket) ||
            (obj.ref.basket < 0 && obj.ref.indicator < obj.ref.basket)) > 0
            ? 'and'
            : 'but';
        var higher =
          obj.ref.basket > 0
            ? obj.ref.indicator > obj.ref.basket
              ? 'higher'
              : 'lagging below'
            : obj.ref.indicator < obj.ref.basket
            ? 'lower'
            : 'holding above';

        return {
          icon: 'ion-arrow-up-a',
          title: 'Relative  Divergence ' + name,
          content:
            'Risk index trending ' +
            positive +
            ' (' +
            basket +
            ') ' +
            but +
            ' ' +
            name +
            ' (' +
            indicator +
            ') ' +
            higher +
            ' by ' +
            divergence +
            ' points',
          speech:
            'Emerging Divergence - Risk index trending ' +
            positive +
            ' ' +
            but +
            ' ' +
            pronunciation +
            ' ' +
            higher +
            ' by ' +
            divergence +
            ' points.',
        };
      } else {
        // Oil (-45) is dragging 52 points below the risk index (7)
        // Oil (45) is heading 52 points above the risk index (-7)

        var action =
          obj.value > 0
            ? 'heading ' + Math.floor(Math.abs(obj.value)) + ' points above'
            : 'dragging ' + Math.floor(Math.abs(obj.value)) + ' points below';
        return {
          icon: insight.risk ? 'ion-arrow-up-a' : 'ion-arrow-up-c',
          title: (insight.risk ? 'Relative ' : '') + 'Divergence ' + name,
          content:
            name +
            ' (' +
            Math.floor(obj.ref.indicator) +
            ') is ' +
            action +
            ' the risk index ' +
            ' (' +
            Math.floor(obj.ref.basket) +
            ')',
          speech: 'Emerging Divergence - ' + pronunciation + ' is ' + action + ' the risk index.',
        };
      }
    }

    /**
     * This function checks if insight is visible for user.
     *
     * @alias ecapp.btRiskService#checkIsInsightVisible
     * @param {ecapp.IRiskInsight} insight - the risk insight object
     * @return {boolean} - whether insight is visible
     */
    function checkIsInsightVisible(insight) {
      return (
        insight.basket === getSelectedBasket().id &&
        insight.interval === getSelectedInterval().granularity &&
        $btRiskSettings.isInsightSelected(insight)
      );
    }

    /**
     *
     */
    function filterSelectedInsights() {
      while (gSelectedInsights.length > 0) {
        gSelectedInsights.pop();
      }

      gInsights.forEach(function (insight) {
        if (checkIsInsightVisible(insight)) {
          gSelectedInsights.push(insight);
        }
      });
    }

    /**
     * This function return unique chart identifier.
     *
     * @alias ecapp.btRiskService#getChartId
     * @param {string} name - name of chart
     * @return {string}
     */
    function getChartId(name) {
      if (!gChartCounter[name]) gChartCounter[name] = 0;
      gChartCounter[name]++;
      return 'bt-' + name + '-chart-' + gChartCounter[name];
    }

    /**
     * This function opens risk settings dialog.
     *
     * @alias ecapp.btRiskService#openSettingsDialog
     * @return {angular.IPromise.<boolean>} - whether settings was updated
     */
    function openSettingsDialog() {
      return $btRiskSettings.openSettingsDialog().then(function (wasUpdated) {
        if (wasUpdated) {
          selectBasketById($btRiskSettings.getUserBasket());
          selectIntervalById($btRiskSettings.getUserInterval());
        }
        return wasUpdated;
      });
    }

    /**
     * This function return risk insight lock period.
     *
     * @alias ecapp.btRiskIntervalService#getIntervalLock
     * @param {ecapp.IRiskInterval} interval - interval
     * @return {number} - lock period in milliseconds
     */
    function getMilliSecondsToFrozen(interval) {
      return Math.min(Math.floor((interval.size * interval.step) / 4), /* 12 hours */ 43200000);
    }

    /**
     * This function return risk insight lock period.
     *
     * @alias ecapp.btRiskIntervalService#getIntervalLock
     * @param {ecapp.IRiskInterval} interval - interval
     * @return {number} - lock period in milliseconds
     */
    function getMilliSecondsToObsolete(interval) {
      return Math.min(interval.history * interval.step, /* 24 hours */ 86400000);
      /*  6 hours 21600000 */
      /* 12 hours 43200000 */
      /* 24 hours 86400000 */
    }

    /**
     * This function converts milliseconds to minutes.
     *
     * @param {number} value - value in milliseconds
     * @return {number} - value in minutes
     */
    function millisecondsToMinutes(value) {
      return Math.floor(value / 60000);
    }

    /**
     * This function generates range meter template for risk insight.
     *
     * @alias ecapp.btRiskService#getRangeMeterTemplate
     * @return {btRangeMeterParameters} - range meter parameters
     */
    function getRangeMeterTemplate() {
      return {
        id: '',
        ranges: [
          {
            h: 0,
            l: 0,
            o: 0,
            class: 'bt-bg-positive',
            hidden: false,
            label: {
              html: '',
              hint: '',
              class: 'bt-offset bt-center bt-white',
              hidden: false,
              place: 'center',
            },
          },
        ],
        ticks: [
          {
            p: 100,
            class: 'bt-inactive',
            hidden: true,
            label: {
              html: '+100',
              hint: '',
              class: 'bt-center bt-inactive',
              hidden: true,
            },
          },
          {
            p: 0,
            class: 'bt-high',
            hidden: false,
            label: {
              html: '',
              hint: '',
              class: 'bt-center bt-inactive',
              hidden: false,
            },
          },
          {
            p: 0,
            class: 'bt-inactive',
            hidden: true,
            label: {
              html: '0',
              hint: '',
              class: 'bt-center bt-inactive',
              hidden: true,
            },
          },
          {
            p: 0,
            class: 'bt-low',
            hidden: false,
            label: {
              html: '',
              hint: '',
              class: 'bt-center bt-inactive',
              hidden: false,
            },
          },
          {
            p: -100,
            class: 'bt-inactive',
            hidden: true,
            label: {
              html: '-100',
              hint: '',
              class: 'bt-center bt-inactive',
              hidden: true,
            },
          },
        ],
        markers: [
          {
            p: 0,
            class: 'bt-origin',
            hidden: false,
            label: {
              html: '',
              hint: '',
              class: 'bt-center bt-white',
              hidden: false,
            },
          },
          {
            p: 0,
            class: 'bt-current',
            hidden: false,
            label: {
              html: '',
              hint: '',
              class: 'bt-current bt-center bt-white',
              hidden: false,
            },
          },
        ],
        scale: [-100, 100],
        size: 240,
        align: 'horizontal',
      };
    }

    /**
     * This function updates data of existed range meter .
     *
     * @alias ecapp.btRiskService#updateRangeMeter
     * @param {btRangeMeterParameters} params - range meter parameters
     * @param {number} open - open value
     * @param {number} high - high value
     * @param {number} low - low value
     * @param {number} close - close value
     * @param {number} scale - scale
     * @param {number} tick - tick size
     * @return {btRangeMeterParameters}
     */
    function updateRangeMeter(params, open, high, low, close, scale, tick) {
      var min = open - scale;
      var max = open + scale;

      params.ranges[0].h = open < close ? close : open;
      params.ranges[0].l = open < close ? open : close;
      params.ranges[0].class = open < close ? 'bt-bg-positive' : 'bt-bg-negative';
      params.ranges[0].label.html = tick ? ((close - open) / tick).toFixed(0) + ' ticks' : (close - open).toString();

      params.ticks[0].hidden = tick ? true : max <= 100;
      params.ticks[0].label.hidden = tick ? true : max <= 100;

      params.ticks[1].p = high;
      params.ticks[1].label.html = high.toString();

      params.ticks[2].hidden = tick ? true : min > 0 || max < 0;
      params.ticks[2].label.hidden = tick ? true : min > 0 || max < 0;

      params.ticks[3].p = low;
      params.ticks[3].label.html = low.toString();

      params.ticks[4].hidden = tick ? true : min >= -100;
      params.ticks[4].label.hidden = tick ? true : min >= -100;

      params.markers[0].p = open;
      params.markers[0].label.html = open.toString();

      params.markers[1].p = close;
      params.markers[1].label.html = close.toString();

      params.scale = [min, max];

      return params;
    }
  }
})();
