/**
 * Created by Sergey Panpurin on 12/12/2016.
 */

// @ts-check
(function btDevServiceClosure() {
  'use strict';

  /**
   * @ngdoc service
   * @name btDevService
   * @memberOf btUtils
   * @description
   *  Development service
   */
  angular.module('btUtils').factory('btDevService', btDevService);

  btDevService.$inject = ['$window'];

  /**
   * @param {angular.IWindowService} $window - window object service
   * @return {ecapp.IDevService}
   */
  function btDevService($window) {
    console.log('Running btDevService');

    /**
     * @name btUtils.btDevService#pusherRowsLog
     * @type {Array}
     */
    var pusherRowsLog = [];

    /**
     * @name btUtils.btDevService#pusherInsightsLog
     * @type {Array}
     */
    var pusherInsightsLog = [];

    /**
     * @name btUtils.btDevService#pusherTradingInsightsLog
     * @type {Array}
     */
    var pusherTradingInsightsLog = [];

    return {
      pusherRowsLog: pusherRowsLog,
      pusherInsightsLog: pusherInsightsLog,
      pusherTradingInsightsLog: pusherTradingInsightsLog,
      alert: alert,
    };

    /**
     * This function displays a alert in debug mode and otherwise prints a console message.
     *
     * @alias btUtils.btDevService#alert
     * @param {string} msg - message
     */
    function alert(msg) {
      if ($window.isDebugMode) {
        alert(msg);
      } else {
        console.log('btAlert:', msg);
      }
    }
  }
})();
