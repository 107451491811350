(function btShowForFreeClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * Use time to decide: is this connect available to free user or not
     *
     * @ngdoc filter
     * @name btShowForFreeFilter
     * @memberOf ecapp
     */
    .filter('btShowForFree', ['btSubscriptionService', 'btDateService', btShowForFreeFilter]);

  /**
   *
   * @param {ecapp.ISubscriptionService} btSubscriptionService
   * @param {ecapp.IDateService} btDateService
   * @return {angular.IFilterFunction}
   */
  function btShowForFreeFilter(btSubscriptionService, btDateService) {
    return function isAvailableForFree(time) {
      if (btSubscriptionService.status.isFree) {
        var delay = btDateService.getFreeDelay(btDateService.getDateFromSec(time));
        return delay <= 0;
      } else {
        return true;
      }
    };
  }
})();
