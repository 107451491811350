/**
 * Created by yatree on 10/10/16.
 */

// @ts-check
(function btWindowsSizeServiceClosure() {
  'use strict';

  angular
    .module('btUtils')
    /**
     * @ngdoc service
     * @name btWindowsSizeService
     * @memberOf btUtils
     * @description
     *  This directive
     */
    .factory('btWindowsSizeService', btWindowsSizeService);

  btWindowsSizeService.$inject = ['$rootScope', '$window'];

  /**
   *
   * @param {ecapp.ICustomRootScope} $rootScope - ?
   * @param {angular.IWindowService} $window - window object service
   * @return {ecapp.IWindowsSizeService}
   */
  function btWindowsSizeService($rootScope, $window) {
    var windowsSettings = {
      size: true,
    };

    /**
     *
     * @return {*}
     */
    function getSizeIfForMobile() {
      var mq = '(min-width:760px)';

      windowsSettings.size = $window.matchMedia(mq).matches && !$window.isMobile;

      return windowsSettings.size;
    }

    /**
     *
     */
    function setSize() {
      getSizeIfForMobile();
    }

    /**
     *
     * @return {string}
     */
    function getWidthForMainWindow() {
      if (windowsSettings.size == false) {
        return '100%';
      }

      return '360px';
    }

    /**
     * Open a new browser window with reduced size and no toolbars
     * Used to dedicate window for one event release
     *
     * @param {*} windowName - ?
     * @param {*} url - ?
     */
    function openPopOutWindow(windowName, url) {
      var windowObjectReference;
      var height = window.screen.availHeight;
      var strWindowFeatures = 'menubar=no,location=no,resizable=no,scrollbars=no,status=no,toolbar=no,titlebar=no';
      //var url = window.location.href;
      var title = windowName;
      url = window.location.origin + '/' + url;

      windowObjectReference = window.realOpen(url, title, strWindowFeatures);
      windowObjectReference.moveTo(0, 0);
      windowObjectReference.resizeTo(350, height);
    }

    /**
     *
     * @return {string}
     */
    function getCalendarMainStyle() {
      if (windowsSettings.size == false) {
        return 'flex-calendar';
      } else {
        return 'fix-calendar';
      }
    }

    return {
      windowsSettings: windowsSettings,
      setSize: setSize,
      getSizeIfForMobile: getSizeIfForMobile,
      getWidthForMainWindow: getWidthForMainWindow,
      openPopOutWindow: openPopOutWindow,
      getCalendarMainStyle: getCalendarMainStyle,
    };
  }
})();
