/**
 * Created by Sergey Panpurin on 5/21/2019.
 */

// @ts-check
(function btTourServiceClosure() {
  'use strict';

  /**
   * @ngdoc service
   * @name btTourService
   * @memberOf ecapp
   * @description
   *  This service manages back button for releases' and instruments' pages.
   */

  angular.module('ecapp').factory('btTourService', btTourService);

  btTourService.$inject = ['btLinkService'];

  /**
   *
   * @param {ecapp.ILinkService} btLinkService
   * @return {ecapp.ITourService}
   */
  function btTourService(btLinkService) {
    console.log('Running btTourService');

    var gTours = {
      'backtester.widgets.tutorial': 29575,
      'backtester.tutorial': { link: 'https://www.youtube.com/watch?v=NvGT3PJxKBc' },
      // 'backtester.tutorial': 29546,
      'backtester.get-started': 29605,
    };

    return {
      show: showTourByName,
    };

    /**
     *
     * @param {String} name
     */
    function showTourByName(name) {
      var tour = getTourByName(name);
      if (tour) {
        if (typeof tour === 'number') {
          showTourById(tour);
        } else if (typeof tour === 'object' && tour.link) {
          btLinkService.openExternal(tour.link);
        }
      }
    }

    /**
     *
     * @param {String} name
     * @return {Number|undefined}
     */
    function getTourByName(name) {
      return gTours[name];
    }

    /**
     *
     * @param {Number} id
     */
    function showTourById(id) {
      // if (window.Intercom) {
      // Intercom('startTour', id);
      // }
      console.error('Intercom tours are not supported.');
    }
  }
})();
