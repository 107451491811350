/**
 * Created by Sergey Panpurin on 2/3/19
 */

(function btTradingSessionsServiceClosure() {
  'use strict';

  var gDebug = false;

  angular
    .module('ecapp')
    /**
     * @ngdoc service
     * @name btTradingSessionsService
     * @memberOf ecapp
     * @description
     *  This *
     *
     *  hours from 00:00 to 23:59 -> minutes from 0 to 1439
     */
    .factory('btTradingSessionsService', btTradingSessionsService);

  btTradingSessionsService.$inject = ['$timeout', 'btDateService', 'btSettingsService'];

  /**
   *
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.ISettingsService} btSettingsService
   * @return {ecapp.ITradingSessionsService}
   */
  function btTradingSessionsService($interval, btDateService, btSettingsService) {
    if (gDebug) console.log('Running btTradingSessionsService');

    var gSessionInstances = null;

    /**
     * @typedef {Object} btTradingSessionDescription
     * @property {string} id - 2-letter lower-case identifier of trading session
     * @property {string} name - full name of trading session (region)
     * @property {string} city - city name
     * @property {string} code - city code
     * @property {string} open - open hours
     * @property {string} close - close hours
     * @property {string} color - color
     */

    /**
     * @typedef {Object} btTradingSessionStageDescription
     * @property {string} id - lower-case identifier of trading session stage
     * @property {string} name - full name of trading session stage
     * @property {RangeDescription[]} range - range description
     */

    /**
     * @typedef {Object} RangeDescription
     * @property {number} [open] - open hours modifier
     * @property {number} [close] - close hours modifier
     */

    /**
     * Descriptions of trading sessions
     * @type {btTradingSessionDescription[]}
     */
    var gSessions = [
      {
        id: 'au',
        name: 'Australia',
        timezone: 'Australia/Sydney',
        city: 'Sydney',
        code: 'SYD',
        open: '10:00',
        close: '16:00',
        color: '#79C844',
      },
      {
        id: 'as',
        name: 'Asia',
        timezone: 'Asia/Tokyo',
        city: 'Tokyo',
        code: 'TYO',
        open: '09:00',
        close: '15:15',
        color: '#FE9324',
      },
      {
        id: 'eu',
        name: 'Europe',
        timezone: 'Europe/London',
        city: 'London',
        code: 'LND',
        open: '08:00',
        close: '17:00',
        color: '#41A8F5',
      },
      {
        id: 'us',
        name: 'United States',
        timezone: btSettingsService.isLinkDataService() ? 'America/Chicago' : 'America/New_York',
        city: btSettingsService.isLinkDataService() ? 'Chicago' : 'New York',
        code: btSettingsService.isLinkDataService() ? 'CHI' : 'NYC',
        open: '09:30',
        close: '16:00',
        color: '#EB2376',
      },
    ];

    /**
     * Descriptions of trading session stages
     * @type {btTradingSessionStageDescription[]}
     */
    var gStages = [
      {
        id: 'start',
        name: 'Start',
        range: [{ open: -120 }, { open: +60 }],
      },
      {
        id: 'main',
        name: 'Main',
        range: [{ open: +60 }, { close: -60 }],
      },
      {
        id: 'end',
        name: 'End',
        range: [{ close: -60 }, { close: +60 }],
      },
    ];

    /**
     * Trading session stage
     *
     * @param {btTradingSessionStageDescription} stage
     * @param {number} openMinutes
     * @param {number} closeMinutes
     * @param {BTTradingSession} session
     * @class
     */
    function BTTradingSessionStage(stage, openMinutes, closeMinutes, session) {
      /** @type {string} */
      this.id = stage.id;

      /** @type {string} */
      this.name = stage.name;

      /** @type {BTTradingSession} */
      this.session = session;

      /** @type {?number} */
      this.openMinutes = BTTradingSessionStage.parseRange(stage.range[0], openMinutes, closeMinutes);

      /** @type {?number} */
      this.closeMinutes = BTTradingSessionStage.parseRange(stage.range[1], openMinutes, closeMinutes);

      /** @type {?string} */
      this.open = BTTradingSessionStage.getHours(this.openMinutes);

      /** @type {?string} */
      this.close = BTTradingSessionStage.getHours(this.closeMinutes);
    }

    /**
     * @alias BTTradingSessionStage.parseRange
     * @param {RangeDescription} value
     * @param {number} open - open hours in day minutes
     * @param {number} close - close hours in day minutes
     * @return {?number}
     */
    BTTradingSessionStage.parseRange = function parseRange(value, open, close) {
      if (value.open !== undefined) return open + value.open;
      if (value.close !== undefined) return close + value.close;
      return null;
    };

    /**
     * @alias BTTradingSessionStage.getHours
     * @param {number} hoursMinutes - hours as a minutes
     * @return {?string}
     */
    BTTradingSessionStage.getHours = function getHours(hoursMinutes) {
      if (hoursMinutes) {
        var hours = Math.floor(hoursMinutes / 60);
        var minutes = hoursMinutes % 60;
        return (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes;
      } else {
        return null;
      }
    };

    /**
     * Trading session
     *
     * @param {btTradingSessionDescription} session - session description
     * @param {btTradingSessionStageDescription[]} stages - descriptions of stages
     * @class
     */
    function BTTradingSession(session, stages) {
      /** @type {string} */
      this.id = session.id;

      /** @type {string} */
      this.name = session.name;

      /** @type {string} */
      this.timezone = session.timezone;

      /** @type {string} */
      this.city = session.city;

      /** @type {string} */
      this.code = session.code;

      /** @type {string} */
      this.offset = btDateService.getTimezoneOffset(session.timezone);

      /** @type {string} */
      this.open = session.open;

      /** @type {number} */
      this.openMinutes = BTTradingSession.getMinutes(session.open);

      /** @type {string} */
      this.close = session.close;

      /** @type {number} */
      this.closeMinutes = BTTradingSession.getMinutes(session.close);

      /** @type {string} */
      this.color = session.color;

      /** @type {BTTradingSessionStage[]} */
      this.stages = stages.map(this.createNewStage.bind(this));
    }

    /**
     * This function get trading hours.
     *
     * @param {*[]} dates - list of moment objects
     * @param {string} tz - timezone should be one of these values: exchange (default), utc or local
     * @param {boolean} useStages - indicates whether
     * @return {Array}
     */
    BTTradingSession.prototype.getTradingHours = function (dates, tz, useStages) {
      var session = this;
      return dates.map(function (date) {
        if (useStages) {
          return session.stages.map(function (stage) {
            return session.prepareTradingHours(stage.id, date, stage.open, stage.close, tz);
          });
        } else {
          return session.prepareTradingHours(session.id, date, session.open, session.close, tz);
        }
      });
    };

    /**
     * This function returns trading hours.
     *
     * @param {number[]} days - list of day shifts for example [-1, 0, 1]
     * @param {string} tz - timezone should be one of these values: exchange (default), utc or local
     * @param {boolean} useStages - indicates whether to use stages
     * @return {Array}
     */
    BTTradingSession.prototype.getRelativeTradingHours = function (days, tz, useStages) {
      var ref = moment();
      var dates = days.map(function (day) {
        return ref.clone().add(day, 'days');
      });

      return this.getTradingHours(dates, tz, useStages);
    };

    /**
     * This function prepare trading hours.
     *
     * @param {string} id - identifier
     * @param {Date} date - reference date
     * @param {string} open - open hours
     * @param {string} close - close hours
     * @param {string} tz - timezone should be one of these values: exchange (default), utc or local
     * @return {{id: string, title: string, color: *, class: string, open: *, openTime: *, close: *, closeTime: *, tz: *}}
     */
    BTTradingSession.prototype.prepareTradingHours = function (id, date, open, close, tz) {
      var ref = moment(date).tz(this.timezone).seconds(0).milliseconds(0);
      var openMoment = ref
        .clone()
        .hours(parseInt(open.split(':')[0]))
        .minutes(parseInt(open.split(':')[1]));
      var closeMoment = ref
        .clone()
        .hours(parseInt(close.split(':')[0]))
        .minutes(parseInt(close.split(':')[1]));
      openMoment = this.convertTimezone(openMoment, tz);
      closeMoment = this.convertTimezone(closeMoment, tz);
      return {
        id: id + '@' + ref.unix(),
        title: (id === 'main' ? this.id : '').toUpperCase(),
        color: this.color,
        class: 'bt-trading-stage-' + id,
        open: openMoment.format('HH:mm'),
        openTime: openMoment,
        close: closeMoment.format('HH:mm'),
        closeTime: closeMoment,
        tz: tz,
      };
    };

    /**
     * This function creates a new stage instance.
     *
     * @param {btTradingSessionStageDescription} stage - stage description
     * @return {BTTradingSessionStage}
     */
    BTTradingSession.prototype.createNewStage = function (stage) {
      return new BTTradingSessionStage(stage, this.openMinutes, this.closeMinutes, this);
    };

    /**
     * This function converts timezone.
     *
     * @param {*} moment - moment object
     * @param {string} tz - timezone should be one of these values: exchange (default), utc or local
     * @return {*}
     */
    BTTradingSession.prototype.convertTimezone = function (moment, tz) {
      tz = tz || 'exchange';

      if (tz === 'utc') {
        return moment.utc();
      }

      if (tz === 'local') {
        return moment.tz();
      }

      return moment;
    };

    /**
     * This function returns trading session stage for specified date.
     *
     * @param {Date} date - specified date
     * @return {string} - start, main, end, close
     */
    BTTradingSession.prototype.checkDate = function (date) {
      var hours = moment(date).tz(this.timezone).format('HH:mm');
      var minutes = BTTradingSession.getMinutes(hours);

      var result = this.stages.filter(function (stage) {
        if (stage.closeMinutes < stage.openMinutes) {
          alert('Problem with ' + stage.id + ' stage of ' + stage.session.id + ' session!');
        }
        return stage.openMinutes <= minutes && minutes < stage.closeMinutes;
      })[0];

      if (result) {
        return result.id;
      } else {
        return 'close';
      }
    };

    /**
     * This function returns style for specified hour.
     *
     * @param {number} originalHour - original hour
     * @param {string} tz - timezone should be one of these values: exchange (default), utc or local
     * @return {string}
     */
    BTTradingSession.prototype.paintHour = function (originalHour, tz) {
      tz = tz || 'exchange';
      var hour = originalHour;

      if (tz === 'utc') {
        hour = moment().utc().hour(originalHour).tz(this.timezone).hour();
      }

      if (tz === 'local') {
        hour = moment().hour(originalHour).tz(this.timezone).hour();
      }

      var minutes = hour * 60;
      var stage = this.stages.filter(function (stage) {
        var result = false;
        var open = stage.openMinutes;
        var close = stage.closeMinutes;

        if (close < open) {
          result = (open <= minutes && minutes < 24 * 60) || (0 <= minutes && minutes < close);
        } else {
          result = open <= minutes && minutes < close;
        }

        return result;
      })[0];

      if (stage) {
        return 'bt-trading-stage-' + stage.id;
      } else {
        return 'bt-trading-stage-close';
      }
    };

    /**
     * This function creates trading session instance.
     *
     * @param {btTradingSessionDescription} session - trading session description
     * @return {BTTradingSession}
     */
    BTTradingSession.createNewSession = function (session) {
      return new BTTradingSession(session, gStages);
    };

    /**
     * This function converts hours to daily minutes.
     *
     * @alias BTTradingSession.getMinutes
     * @param {string} hours - hours as a string
     * @return {number}
     */
    BTTradingSession.getMinutes = function getMinutes(hours) {
      var result = hours.split(':');
      return parseInt(result[0]) * 60 + parseInt(result[1]);
    };

    return {
      checkDate: checkDate,
      getSessions: getSessions,
      getSessionById: getSessionById,
    };

    /**
     * This function gets session by id.
     *
     * @param {string} id - 2-letter identifier of trading session
     * @return {?BTTradingSession}
     */
    function getSessionById(id) {
      /** @type {BTTradingSession[]} */
      var sessions = getSessions().filter(function (value) {
        return value.id === id.toLowerCase();
      });

      if (sessions.length === 0) {
        return null;
      } else {
        return sessions[0];
      }
    }

    /**
     * This function returns trading session stage for specified date.
     *
     * @param {string} id - 2-letter identifier of trading session
     * @param {Date} date - specified date
     * @return {string}
     */
    function checkDate(id, date) {
      var session = getSessionById(id);

      if (session) {
        return session.checkDate(date);
      } else {
        return 'unknown';
      }
    }

    /**
     * This function returns list of trading session instances.
     *
     * @alias ecapp.btTradingSessionsService#getSessions
     * @return {BTTradingSession[]}
     */
    function getSessions() {
      if (gSessionInstances === null) {
        gSessionInstances = gSessions.map(function (session) {
          return new BTTradingSession(session, gStages);
        });
      }
      return gSessionInstances;
    }
  }
})();
