/**
 * Created by Orly on 19/02/2019.
 */

(function btBarometerWidgetsClosure() {
  'use strict';

  var gDebug = false;

  /**
   * This directive displays barometer widget
   * Barometer helps traders to be updated about any market movement.
   *
   * Barometer is a container for different widgets. It should be visible all the time. It is very critical to get
   * updates faster.
   *
   * Barometer should work in web and mobile version. In web version it is available in two places: left tab (instead
   * of News) and charts tab (instead of one chart). In mobile version it is available in one place left tab (instead
   * of News).
   *
   * Now it's just container for another widgets. Logic of different representation implemented in scss file
   * _barometer.scss.
   *
   * @ngdoc directive
   * @name btBarometer
   * @memberOf ecapp
   * @scope
   * @param {String} view - this parameter customize barometer representation according to place. It could be `nav`
   *                        (dedicated page) or `charts` (replace one of charts).
   */

  angular.module('ecapp').directive('btBarometer', btBarometer);

  btBarometer.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btBarometer($templateCache) {
    return {
      restrict: 'E',
      scope: {
        view: '@',
      },
      template: $templateCache.get('directives/barometer/bt-barometer.html'),
      controller: btBarometerWidgetsController,
    };
  }

  btBarometerWidgetsController.$inject = [
    '$scope',
    '$state',
    'btSettingsService',
    'btLinkService',
    'btMarketing',
    'btSubscriptionService',
    'btToastrService',
  ];

  /**
   *
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.ILinkService} btLinkService
   * @param {ecapp.IMarketing} btMarketing
   * @param {ecapp.ISubscriptionService} btSubscriptionService
   * @param {ecapp.IToastrService} btToastrService
   */
  function btBarometerWidgetsController(
    $scope,
    $state,
    btSettingsService,
    btLinkService,
    btMarketing,
    btSubscriptionService,
    btToastrService
  ) {
    console.log('Running btBarometerWidgetsController');

    activate();

    /**
     * This function activate controller.
     */
    function activate() {
      if (gDebug) console.log('btBarometerWidgetsController: view', $scope.view);

      $scope.hasTrading = btSettingsService.hasFeature('trading');

      $scope.openProMemberTab = openProMemberTab;
      $scope.askForDiscount = askForDiscount;
      $scope.buyLifetimePro = buyLifetimePro;
    }

    /**
     * This function opens pro member benefits page.
     */
    function openProMemberTab() {
      btLinkService.openInternal('ecapp.app.pro-membership');
    }

    /**
     *
     */
    function askForDiscount() {
      btMarketing.askQuestion('Do you have a discount for the Lifetime Pro plan?');
    }

    /**
     *
     */
    function buyLifetimePro() {
      btSubscriptionService
        .buyLifetimePro()
        .then(function (result) {
          if (result.error) {
            btToastrService.error(result.error.message, 'Subscription', { type: 'settings' });
          }
        })
        .catch(function (err) {
          btToastrService.error(err.message, 'Subscription', { type: 'settings' });
        });
    }
  }
})();
