/**
 * Created by Sergei Panpurin on 6/20/18.
 */

(function btPaymentWrapperClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive wrappers plan payment.
     *
     * @ngdoc directive
     * @name btPaymentWrapper
     * @memberOf ecapp
     */
    .directive('btPaymentWrapper', btPaymentWrapper)
    .controller('btPaymentWrapperController', btPaymentWrapperController);

  btPaymentWrapper.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btPaymentWrapper($templateCache) {
    return {
      restrict: 'E',
      scope: false,
      transclude: true,
      template: $templateCache.get('directives/common/bt-payment-wrapper.html'),
      controller: 'btPaymentWrapperController',
    };
  }

  btPaymentWrapperController.$inject = ['$q', '$scope', '$rootScope', '$window', '$state', 'btSubscriptionService'];

  /**
   *
   * @param {angular.IQService} $q - promise interface
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.IWindowService} $window - window object service
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.ISubscriptionService} btSubscriptionService
   */
  function btPaymentWrapperController($q, $scope, $rootScope, $window, $state, btSubscriptionService) {
    var qDeferred = null;

    $scope.paymentLoading = false;
    $scope.paymentProcessing = false;
    $scope.upgradeConfirming = false;

    $scope.confirmUpgrade = upgradeSubscription;
    $scope.cancelUpgrade = cancelSubscriptionUpgrade;
    $scope.buySubscription = buySubscription;

    /**
     *
     * @param {*} plan
     * @return {angular.IPromise<any>}
     */
    function upgradeSubscription(plan) {
      $scope.paymentProcessing = true;
      $scope.upgradeConfirming = false;
      var subscription = btSubscriptionService.getCombinedSubscription();
      return btSubscriptionService
        .upgrade(subscription.id, plan.id, '')
        .then(function (subscription) {
          if (subscription) {
            $state.reload();
            qDeferred.resolve('success');
            qDeferred = null;
          } else {
            console.error('Subscription upgrade error: Unknown error.');
            qDeferred.reject(new Error('Unknown error.'));
            qDeferred = null;
          }
        })
        .catch(function (err) {
          console.error('Subscription update error:', err);
          qDeferred.reject(err);
          qDeferred = null;
        })
        .finally(function () {
          $scope.paymentLoading = false;
        });
    }

    /**
     *
     */
    function cancelSubscriptionUpgrade() {
      $scope.upgradeConfirming = false;

      if (qDeferred) {
        qDeferred.resolve('closed');
        qDeferred = null;
      }
    }

    /**
     * Buy or upgrade stripe subscription
     * @param {Object} plan - plan object
     * @param {String} couponId - coupon id
     * @param {Object} modal - modal view object
     * @return {angular.IPromise<String>} execution status 'closed' or 'success'
     */
    function buySubscription(plan, couponId, modal) {
      if (qDeferred !== null) {
        return qDeferred.promise;
      }

      return btSubscriptionService.buySubscription(plan);
      // if ($window.isIOS && $window.isMobile) {
      //   return buyAppleSubscription(plan, couponId, modal);
      // } else {
      //   return buyStripeSubscription(plan, couponId, modal);
      // }
    }

    // /**
    //  *
    //  * @param {*} plan
    //  * @param {*} couponId
    //  * @param {*} modal
    //  * @return {angular.IPromise<String>}
    //  */
    // function buyAppleSubscription(plan, couponId, modal) {
    //   void(couponId);
    //   void(modal);
    //   return btSubscriptionService.applePay(plan)
    //     .then(function () {
    //       return btSubscriptionService.updateSubscription();
    //     })
    //     .then(function () {
    //       $state.reload();
    //       return 'success';
    //     });
    // }

    // /**
    //  * Buy or upgrade stripe subscription
    //  * @param {Object} plan - plan object
    //  * @param {String} couponId - coupon id
    //  * @param {Object} modal - modal view object
    //  * @return {angular.IPromise<String>} execution status 'closed' or 'success'
    //  */
    // function buyStripeSubscription(plan, couponId, modal) {
    //   couponId = couponId || '';
    //
    //   qDeferred = $q.defer();
    //
    //   btSubscriptionService.stripePay(plan, $rootScope.currentUser.email, couponId, onStart, onEnd, onToken, onConfirm);
    //
    //   return qDeferred.promise;
    //
    //   /**
    //    *
    //    */
    //   function onStart() {
    //     if (modal && typeof modal.hide === 'function' && typeof modal.isShown === 'function' && modal.isShown()) {
    //       modal.hide();
    //     }
    //     $scope.paymentLoading = true;
    //   }
    //
    //   /**
    //    *
    //    */
    //   function onConfirm() {
    //     $scope.paymentLoading = false;
    //     $scope.upgradeConfirming = true;
    //   }
    //
    //   /**
    //    *
    //    */
    //   function onEnd() {
    //     $scope.$apply(function () {
    //       $scope.paymentLoading = false;
    //       if (!$scope.paymentProcessing) {
    //         qDeferred.resolve('closed');
    //         qDeferred = null;
    //       }
    //     });
    //   }
    //
    //   /**
    //    *
    //    * @param {*} token
    //    */
    //   function onToken(token) {
    //     $scope.paymentLoading = false;
    //     $scope.paymentProcessing = true;
    //     btSubscriptionService.subscribe(plan.id, token.id, couponId)
    //       .then(onSuccess)
    //       .catch(onFail);
    //   }
    //
    //   /**
    //    *
    //    * @param {*} subscription
    //    */
    //   function onSuccess(subscription) {
    //     $scope.paymentProcessing = false;
    //     if (subscription) {
    //       console.log('subscription updated');
    //       $scope.showSubscriptionPopup = false;
    //       btSubscriptionService.updateSubscription();
    //
    //       $state.reload();
    //
    //       qDeferred.resolve('success');
    //       qDeferred = null;
    //     } else {
    //       console.error('Error updating subscription: Unknown error');
    //       qDeferred.reject(new Error('Unknown error'));
    //       qDeferred = null;
    //     }
    //   }
    //
    //   /**
    //    *
    //    * @param {*} error
    //    */
    //   function onFail(error) {
    //     if (modal && typeof modal.hide === 'function' && typeof modal.isShown === 'function' && !modal.isShown()) {
    //       modal.show();
    //     }
    //     $scope.paymentProcessing = false;
    //     console.error('Error during subscription update:', error);
    //     qDeferred.reject(error);
    //     qDeferred = null;
    //   }
    // }
  }
})();
