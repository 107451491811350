/**
 * Created by Sergey Panpurin on 4/5/20.
 */

(function btFollowedEventsClosure() {
  'use strict';

  /**
   * This directive shows list of followed events.
   *
   * @ngdoc directive
   * @name btFollowedEvents
   * @memberOf ecapp
   * @restrict E
   * @scope
   */

  /**
   * This controller configures followed events.
   *
   * @ngdoc controller
   * @name btFollowedEventsController
   * @memberOf ecapp
   */

  /** Directive */
  angular
    .module('ecapp')
    .directive('btFollowedEvents', angularDirective)
    .controller('btFollowedEventsController', angularController);

  angularDirective.$inject = ['$templateCache'];

  /**
   * Implementation of Notification Center Directive
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function angularDirective($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('directives/settings/bt-followed-events.html'),
      controller: 'btFollowedEventsController',
    };
  }

  angularController.$inject = [
    '$scope',
    'btShareScopeService',
    'btCurrenciesService',
    'btRestrictionService',
    'btEventsService',
  ];

  /**
   * Implementation of controller
   *
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.ICurrenciesService} btCurrenciesService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IEventsService} btEventsService
   */
  function angularController($scope, btShareScopeService, btCurrenciesService, btRestrictionService, btEventsService) {
    $scope.numEvents = 0;
    $scope.maxEvents = 0;

    $scope.eventsOrderOptions = [
      {
        label: 'Name',
        field: 'name',
        reverse: false,
      },
      {
        label: 'Currency',
        field: 'currency',
        reverse: false,
      },
    ];
    $scope.orderEventsBy = $scope.eventsOrderOptions[1];

    $scope.eventsIdArr = [];
    $scope.currencyFlags = [];

    $scope.setEventsOrder = setEventsOrder;
    $scope.followEvent = followEvent;

    $scope.$on('calendar:follow-event', onFollowEvent);
    $scope.$on('$destroy', onDestroy);

    activate();

    /**
     * Controller constructor
     */
    function activate() {
      btShareScopeService
        .getAccountSettingsPromise()
        // .then(btSubscriptionService.loadCombinedSubscription)
        // .then(function () {
        //   if (!btRestrictionService.hasFeature('personalization')) {
        //     return btShareScopeService.getLockedMailSubscription()
        //   } else {
        //     return btShareScopeService.initializeMailSubscription();
        //   }
        // })
        // .then(function (value) {
        //   $scope.mailchimp = value;
        // })
        .then(function () {
          // Set by default the order on the currency field
          $scope.orderEventsBy = $scope.eventsOrderOptions[1];

          $scope.accountInfo = btShareScopeService.getAccountSettings();

          // $scope.notifications = btShareScopeService.getNotificationSettings();
          updateFollowedEvents();

          btCurrenciesService
            .getAllCachedCurrenciesFlags()
            .then(function (data) {
              $scope.currencyFlags = data.allCachedCurrenciesFlags;
            })
            .catch(function (reason) {
              console.error(reason);
            });
        });
    }

    /**
     * On destroy
     */
    function onDestroy() {}

    /**
     * Sort list of events by time or currency.
     *
     * @ngdoc method
     * @name setEventsOrder
     * @memberOf ecapp.btNotificationSettingsController
     * @param {*} option
     */
    function setEventsOrder(option) {
      $scope.orderEventsBy = $scope.eventsOrderOptions.filter(function (value) {
        return value.field === option.field;
      })[0];
    }

    /**
     * Emulate click on the follow event button directive.
     *
     * @ngdoc method
     * @name followEvent
     * @memberOf ecapp.btNotificationSettingsController
     * @param {*} event
     */
    function followEvent(event) {
      // console.log('TEST: broadcast tutorial:toggle-event', event.id, ' - ', $scope.$id);
      $scope.$broadcast('tutorial:toggle-event', event.id);
    }

    /**
     *
     * @param {*} event
     * @param {*} eventId
     * @param {*} isSelected
     */
    function onFollowEvent(event, eventId, isSelected) {
      updateFollowedEvents();
    }

    /**
     *
     */
    function updateFollowedEvents() {
      $scope.eventsIdArr = [];
      var followed = btShareScopeService.getListFollowedEvents();

      btEventsService.getAllEvents().then(function (events) {
        events.forEach(function (event) {
          if (followed.indexOf(event.id) !== -1) $scope.eventsIdArr.push(event);
        });
      });

      $scope.numEvents = followed.length;
      $scope.maxEvents = btRestrictionService.getCapacity('followed-events');
    }
  }
})();
