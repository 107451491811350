/**
 * Created by Sergey Panpurin on 12/19/2018.
 */

(function btErrorMessageClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive display countdown timer.
     *
     * @ngdoc directive
     * @name btErrorMessage
     * @memberOf ecapp
     */
    .directive('btErrorMessage', btErrorMessage)
    .controller('btErrorMessageController', btErrorMessageController);

  btErrorMessage.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btErrorMessage($templateCache) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        errorReason: '@',
        customIcon: '@?',
        customClass: '@?',
        customButtonText: '@?',
        customButtonClass: '@?',
        chatMessage: '@?',
        openState: '@?',
        openLink: '@?',
        onAction: '&?',
      },
      template: $templateCache.get('components/mobile/bt-error-message/bt-error-message.html'),
      controller: 'btErrorMessageController',
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btErrorMessageController.$inject = ['$state', 'btMarketing'];

  /**
   *
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.IMarketing} btMarketing
   */
  function btErrorMessageController($state, btMarketing) {
    /*jshint validthis: true*/
    var vm = this;

    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;
    vm.onClick = onClick;

    /**
     * Initialize component
     */
    function onInit() {
      vm.icon = vm.customIcon || 'ion-close-circled';
      vm.class = vm.customClass || '';
      vm.buttonText = vm.customButtonText || 'Click here';
      vm.buttonClass = vm.customButtonClass || 'upgrade-button';
      vm.hasAction = typeof vm.onAction === 'function';
      vm.hasButton =
        vm.hasAction || vm.chatMessage !== undefined || vm.openState !== undefined || vm.openLink !== undefined;
    }

    /**
     * Destroy component
     */
    function onDestroy() {}

    /**
     * React on user click
     */
    function onClick() {
      if (vm.hasAction) {
        vm.onAction();
      } else if (vm.chatMessage) {
        btMarketing.askQuestion(vm.chatMessage);
      } else if (vm.openState) {
        $state.go(vm.openState);
      } else if (vm.openLink) {
        window.open(vm.openLink, '_system');
      }
    }
  }
})();
