/**
 * Created by Sergey Panpurin on 3/28/2019.
 */

/* global fbq */

// @ts-check
(function btTagServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btTagService', btTagService);

  /**
   * @ngdoc service
   * @name btTagService
   * @memberOf ecapp
   * @description
   *  This service interacts with Google Tag Manager.
   */

  /** @namespace window.dataLayer */

  btTagService.$inject = [];

  /**
   *
   * @return {ecapp.ITagService}
   */
  function btTagService() {
    console.log('Running btTagService');

    //                                              |    item id   |    page type     | total value |
    // ---------------------------------------------+--------------+------------------+-------------+
    // Visit bettertrader.co or app.bettertrader.co |  application |       home       |      0      |
    // Visit /#/sign-up                             |  application | conversionintent |      0      |
    // Visit /#/sign-up/success                     |  application |    conversion    |      0      |
    // Visit /#/app/payments                        | subscription |   offerdetail    |      0      |
    // Click upgrade button                         | subscription | conversionintent |      0      |
    // Payment success                              | subscription |    conversion    |   various   |
    // Upgrade pop-up                               | subscription |   offerdetail    |      0      |

    //  - application
    //  - subscription
    //  - trade-ideas

    // 'PurchaseCompleted'
    // 'Registration'
    // 'PurchaseIntention'
    // 'UpgradeRequired'
    // 'AutologinFundedOptimusEvent'
    // 'RemarketingVisitor',
    // 'RemarketingNewFreeUser',
    // 'RemarketingActiveFreeUser',
    // 'RemarketingPaidMonthlyUser',
    // 'RemarketingPaidYearlyUser'

    /**
     * This function send custom event to google tag manager.
     *
     * @param {String} name - event name
     * @param {Object} [tag_params] - event parameters
     * @return {*}
     */
    function sendEvent(name, tag_params) {
      if (window.dataLayer === undefined) window.dataLayer = [];

      var data = { event: name };

      if (tag_params) data.google_tag_params = tag_params;

      return window.dataLayer.push(data);
    }

    /**
     * This function sends user registration event.
     *
     * @alias ecapp.btTagService#intendRegistration
     * @return {*}
     */
    function sendRegistrationIntention() {
      if (window.fbq) {
        fbq('track', 'ViewContent', { content_name: 'SignUpPage' });
      }

      return sendEvent('RegistrationIntention');
    }

    /**
     * This function sends user registration event.
     *
     * @alias ecapp.btTagService#completeRegistration
     * @return {*}
     */
    function sendRegistrationCompletion() {
      if (window.fbq) {
        fbq('track', 'CompleteRegistration', { content_name: 'SignUpPage' });
      }

      return sendEvent('RegistrationCompletion');
    }

    /**
     * This function sends user purchase event.
     *
     * @param {string} plan - name of plan
     * @param {number} [value] - intention value (default 0)
     * @alias ecapp.btTagService#intendPurchase
     * @return {*}
     */
    function sendPurchaseIntention(plan, value) {
      return sendEvent('PurchaseIntention', {
        dynx_itemid: (plan || 'unknown').toLowerCase(),
        dynx_pagetype: 'conversionintent',
        dynx_totalvalue: value || 0,
      });
    }

    /**
     * This function sends user purchase event.
     *
     * @param {string} plan
     * @param {number} [value]
     * @alias ecapp.btTagService#completePurchase
     * @return {*}
     */
    function sendPurchaseCompletion(plan, value) {
      void plan;
      void value;
      return sendEvent('PurchaseCompletion', {
        dynx_itemid: 'subscription',
        dynx_pagetype: 'conversion',
        dynx_totalvalue: 0,
      });
    }

    /**
     * This function sends user purchase event.
     *
     * @alias ecapp.btTagService#cancelPurchase
     * @return {*}
     */
    function sendPurchaseCancelation() {
      return sendEvent('PurchaseCancelation');
    }

    /**
     * This function sends upgrade events.
     *
     * @alias ecapp.btTagService#intendUpgrade
     * @param {Object} params - event parameters
     * @return {*}
     */
    function sendUpgradeIntention(params) {
      return sendEvent('UpgradeRequirement', params);
    }

    /**
     * This function sends upgrade events.
     *
     * @alias ecapp.btTagService#intendSocialLogin
     * @return {*}
     */
    function sendSocialLoginIntention() {
      return sendEvent('SocialLoginIntention');
    }

    /**
     * @enum {String}
     */
    var gRemarketingTags = {
      Visitor: 'RemarketingVisitor',
      NewFreeUser: 'RemarketingNewFreeUser',
      ActiveFreeUser: 'RemarketingActiveFreeUser',
      PaidMonthlyUser: 'RemarketingPaidMonthlyUser',
      PaidYearlyUser: 'RemarketingPaidYearlyUser',
    };

    /**
     * This function sets remarketing tag.
     *
     * @alias ecapp.btTagService#remarketing.send
     * @param {String} tag - remarketing tag
     * @return {*}
     */
    function sendRemarketingTag(tag) {
      if (gRemarketingTags[tag]) {
        return sendEvent(gRemarketingTags[tag]);
      } else {
        return null;
      }
    }

    /**
     * This function sets remarketing tag.
     *
     * @alias ecapp.btTagService#remarketing.parse
     * @param {btUser} user - user object
     * @param {btSubscriptionObject} subscription - subscription object
     * @return {*}
     */
    function parseRemarketingTag(user, subscription) {
      if (user.remarketing && user.remarketing.tag) {
        return user.remarketing.tag;
      } else {
        if (subscription.origin === null) {
          if ((new Date() - new Date(user.created)) / 1000 / 60 / 60 / 24 > 14) {
            return 'ActiveFreeUser';
          } else {
            return 'NewFreeUser';
          }
        } else {
          if (subscription.interval === 'month') return 'PaidMonthlyUser';
          if (subscription.interval === 'year') return 'PaidYearlyUser';
        }
        return 'Unknown';
      }
    }

    /**
     * This function sends Optimus Futures client login events.
     *
     * @alias ecapp.btTagService#optimus.login
     * @param {String} type
     * @return {*}
     */
    function optimusLogin(type) {
      if (type === 'funded') {
        return sendEvent('AutologinFundedOptimusEvent');
      }
    }

    /**
     * This function sends BigBrainBank client login events.
     *
     * @alias ecapp.btTagService#bigbrainbank.login
     * @param {String} type
     * @return {*}
     */
    function bigbrainbankLogin(type) {
      if (type === 'funded') {
        return sendEvent('AutologinFundedBigBrainBankEvent');
      }
    }

    /**
     * This function sets user id to GTM data layer.
     *
     * @alias ecapp.btTagService#setUserId
     * @param {string} id - user id
     * @return {*}
     */
    function setUserId(id) {
      if (window.dataLayer === undefined) window.dataLayer = [];
      return window.dataLayer.push({ userId: id });
    }

    /**
     * This function sets user id to GTM data layer.
     *
     * @alias ecapp.btTagService#unsetUserId
     * @return {*}
     */
    function unsetUserId() {
      if (window.dataLayer === undefined) window.dataLayer = [];
      return window.dataLayer.push({ userId: undefined });
    }

    /**
     * This function sets user id to GTM data layer.
     *
     * @alias ecapp.btTagService#testEvent
     * @return {*}
     */
    function sendTestEvent() {
      if (window.dataLayer === undefined) window.dataLayer = [];
      return window.dataLayer.push({ event: 'testing' });
    }

    return {
      /** @namespace ecapp.btTagService.remarketing */
      remarketing: {
        levels: gRemarketingTags,
        parse: parseRemarketingTag,
        send: sendRemarketingTag,
      },
      /** @namespace ecapp.btTagService.optimus */
      optimus: {
        login: optimusLogin,
      },
      /** @namespace ecapp.btTagService.bigbrainbank */
      bigbrainbank: {
        login: bigbrainbankLogin,
      },
      completeRegistration: sendRegistrationCompletion,
      intendRegistration: sendRegistrationIntention,
      intendPurchase: sendPurchaseIntention,
      completePurchase: sendPurchaseCompletion,
      cancelPurchase: sendPurchaseCancelation,
      intendUpgrade: sendUpgradeIntention,
      setUserId: setUserId,
      unsetUserId: unsetUserId,
      testEvent: sendTestEvent,
      intendSocialLogin: sendSocialLoginIntention,
    };
  }
})();
