(function btNewsCardClosure() {
  'use strict';

  angular.module('btRss').directive('btNewsCard', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        template: $templateCache.get('bt-rss/directives/bt-news-card.html'),
        scope: {
          i: '=',
        },
        controller: ['$scope', 'btRssFeed', 'btShareSocialService', btNewsCardController],
      };
    },
  ]);

  /**
   *
   * @param {ecapp.ICustomScope} $scope - ?
   * @param {ecapp.IRssFeed} btRssFeed - ?
   * @param {ecapp.IShareSocialService} btShareSocialService - ?
   */
  function btNewsCardController($scope, btRssFeed, btShareSocialService) {
    // Now card have three state isClicked, isNew, isGroupShown
    // For new card have highlighting. After click or some time we remove highlighting.
    $scope.isClicked = false;

    $scope.toggleFunction = function toggleFunction(i) {
      $scope.$parent.toggleGroup(i);
    };

    $scope.isGroupShown = function isGroupShown(group) {
      return $scope.$parent.shownGroup === group;
    };

    $scope.isNew = function isNew(date) {
      return btRssFeed.isNew(date);
    };

    $scope.removeHTML = function removeHTML(string) {
      return btRssFeed.removeHTML(string);
    };

    $scope.shareRss = function shareRss(i) {
      btShareSocialService.prepareNewsShareData(i);
    };

    $scope.readMore = function readMore(i) {
      window.open(i.link, '_blank');
    };
  }
})();
