/**
 * Created by Sergei Panpurin on 9/5/18.
 */

(function btTradeInstrumentsClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive displays list of trade ideas. User can select one of them.
     *
     *  Parts: selected, list, help, settings
     *  States: element is not selected, element is selected, empty list
     *
     * @ngdoc directive
     * @name btTradeInstruments
     * @memberOf ecapp
     */
    .directive('btTradeInstruments', btTradeInstruments)
    .controller('btTradeInstrumentsController', btTradeInstrumentsController);

  btTradeInstruments.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btTradeInstruments($templateCache) {
    return {
      restrict: 'E',
      scope: {
        tradeInstruments: '<',
        tradeIdeas: '<',
        onSelect: '&',
        selectedInstrumentId: '@',
      },
      template: $templateCache.get('components/mobile/bt-back-tester/bt-trade-instruments.html'),
      controller: 'btTradeInstrumentsController',
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btTradeInstrumentsController.$inject = ['$scope', '$interval'];

  /**
   *
   * @param {*} $scope
   * @param {angular.IIntervalService} $interval
   */
  function btTradeInstrumentsController($scope, $interval) {
    void $scope;

    /*jshint validthis: true*/
    var vm = this;

    /**
     * @name vm.tradeInstruments
     * @type {ecapp.ITradingInstrument[]}
     */

    /**
     * @name vm.tradeIdeas
     * @type {TradeIdeaObject[]}
     */

    /**
     * @name vm.onSelect
     * @type {Function}
     */

    vm.selectedTradeInstrument = undefined;

    vm.opened = {
      help: false,
      settings: false,
    };

    vm.selectOption = selectOption;

    vm.openSettings = openSettings;
    vm.openHelp = openHelp;
    vm.closePopups = closePopups;

    vm.$onInit = onInit;
    vm.$onChanges = onChanges;
    vm.$onDestroy = onDestroy;

    /**
     * Initialize component
     */
    function onInit() {
      prepareInstruments();

      // $interval(function () {
      //   vm.options.forEach(function (option) {
      //     if (!option.isVirtual) {
      //       option.price += 0.0001;
      //       option.displayPrice = option.price.toFixed(5);
      //     }
      //   })
      // }, 1000);
    }

    /**
     *
     * @param {*} changes
     */
    function onChanges(changes) {
      if (changes.tradeInstruments || changes.tradeIdeas) {
        prepareInstruments();
      }
    }

    /**
     *
     */
    function prepareInstruments() {
      vm.selectedOption = undefined;

      vm.isListEmpty = vm.tradeInstruments.length === 0;
      vm.options = getTradeInstrumentsOptions(vm.tradeInstruments, vm.tradeIdeas);

      if (!vm.isListEmpty) {
        if (vm.selectedInstrumentId) {
          vm.selectedOption = vm.options.filter(function (option) {
            return option.instrument && option.instrument.id === vm.selectedInstrumentId;
          })[0];
        }

        if (vm.selectedOption === undefined) {
          vm.selectedOption = vm.options[0];
        }

        vm.onSelect({ tradeInstrument: vm.selectedOption.instrument, tradeIdea: vm.selectedOption.tradeIdea });
      }
    }
    /**
     * Destroy component
     */
    function onDestroy() {}

    /**
     *
     * @param {*} option
     */
    function selectOption(option) {
      if (!option.isVirtual) {
        vm.selectedOption = option;
        vm.onSelect({ tradeInstrument: option.instrument, tradeIdea: option.tradeIdea });
      }
    }

    /**
     *
     * @param {ecapp.ITradingInstrument[]} tradeInstruments
     * @param {TradeIdeaObject[]} tradeIdeas
     * @return {Array}
     */
    function getTradeInstrumentsOptions(tradeInstruments, tradeIdeas) {
      var options = tradeInstruments.map(function (tradeInstrument) {
        var tradeIdea = getTradeIdea(tradeInstrument, tradeIdeas);
        return {
          id: tradeInstrument.btName,
          isVirtual: false,
          instrument: tradeInstrument,
          tradeIdea: tradeIdea,
          price: 1.12345,
          displayPrice: '1.12345',
          orderLabel: (tradeIdea ? '1.' : '2.') + tradeInstrument.displayName,
        };
      });

      addVirtualOptions(options);

      options.sort(function (a, b) {
        if (a.orderLabel < b.orderLabel) return -1;
        if (a.orderLabel > b.orderLabel) return 1;
        return 0;
      });

      return options;
    }

    /**
     *
     * @param {ecapp.ITradingInstrument} instrument - BT instrument name
     * @param {TradeIdeaObject[]} tradeIdeas
     * @return {TradeIdeaObject}
     */
    function getTradeIdea(instrument, tradeIdeas) {
      var tradeIdea = tradeIdeas.filter(function (value) {
        return value.instrument.btName === instrument.btName;
      })[0];

      if (tradeIdea) {
        return tradeIdea;
      } else {
        return null;
      }
    }

    /**
     *
     * @param  {*} options
     * @return {any}
     */
    function addVirtualOptions(options) {
      if (options.length < 6) {
        for (var i = options.length; i < 6; i++) {
          options.push({
            id: i,
            isVirtual: true,
            instrument: null,
            tradeIdea: null,
            price: null,
            displayPrice: null,
            orderLabel: '3',
          });
        }
      }

      if (options.length < 9) {
        for (var j = options.length; j < 9; j++) {
          options.push({
            id: j,
            isVirtual: true,
            instrument: null,
            tradeIdea: null,
            price: null,
            displayPrice: null,
            orderLabel: '3',
          });
        }
      }

      if (options.length < 12) {
        for (var k = options.length; k < 12; k++) {
          options.push({
            id: k,
            isVirtual: true,
            instrument: null,
            tradeIdea: null,
            price: null,
            displayPrice: null,
            orderLabel: '3',
          });
        }
      }

      return options;
    }

    /**
     *
     */
    function openHelp() {
      vm.opened.help = !vm.opened.help;
    }

    /**
     *
     */
    function openSettings() {
      vm.opened.settings = !vm.opened.settings;
    }

    /**
     * Close all popups
     */
    function closePopups() {
      vm.opened.help = false;
      vm.opened.settings = false;
    }
  }
})();
