/**
 * Created by Sergey Panpurin on 5/7/2018.
 */

(function btRangeMeterServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btRangeMeterService';

  /**
   * @typedef {object} btRangeMeterParameters
   * @property {string} id - unique identifier
   * @property {string} align - component align (@see {@link btUI.$btRangeMeter#Align})
   * @property {number[]} scale - minimal and maximal values [min, max]
   * @property {number} size - reference size in pixels
   * @property {btRangeMeterRange[]} ranges - list of ranges
   * @property {btRangeMeterTick[]} ticks - list of ticks
   * @property {btRangeMeterMarker[]} markers - list of markers
   */

  /**
   * @typedef {object} btRangeMeterRange
   * @property {number} h - high value
   * @property {number} l - low value
   * @property {number} o - offset ???
   * @property {string} class - custom css classes
   * @property {boolean} hidden - whether the range is hidden (it does not affect the label)
   * @property {btRangeMeterLabel} label - label object
   */

  /**
   * @typedef {object} btRangeMeterTick
   * @property {number} p - position value
   * @property {string} class - custom css class
   * @property {boolean} hidden - whether the tick is hidden (it does not affect the label)
   * @property {btRangeMeterLabel} label - label object
   */

  /**
   * @typedef {object} btRangeMeterMarker
   * @property {number} p - position value
   * @property {string} class - custom css classes
   * @property {boolean} hidden -  whether the marker is hidden (it does not affect the label)
   * @property {btRangeMeterLabel} label - label object
   */

  /**
   * @typedef {object} btRangeMeterLabel
   * @property {string} html - label text as a html
   * @property {string} class - custom css classes
   * @property {string} place - element place (@see {@link btUI.$btRangeMeter.Place})
   * @property {string} hint - hint text
   * @property {boolean} hidden - whether the label is hidden
   */

  /**
   * @typedef {object} btRangeMeterRangeElementObject
   * @property {string} id - identifier
   * @property {number} h - high value
   * @property {number} l - low value
   * @property {number} o - offset ???
   * @property {string} class - custom css classes
   * @property {object} style - custom css style
   * @property {boolean} hidden - whether the element is hidden
   */

  /**
   * @typedef {object} btRangeMeterElementObject
   * @property {string} id - identifier
   * @property {number} p - position value
   * @property {string} place - element place (@see {@link btUI.$btRangeMeter.Place})
   * @property {string} class - custom css classes
   * @property {object} style - custom css style
   * @property {boolean} hidden - whether the element is hidden
   */

  /**
   * @typedef {object} btRangeMeterTitleElementObject
   * @property {string} id - identifier
   * @property {number} p - position value
   * @property {number} [max] - max size in pixels
   * @property {string} place - element place (@see {@link btUI.$btRangeMeter.Place})
   * @property {string} html - text as a html
   * @property {string} hint - hint text
   * @property {string} class - custom css classes
   * @property {object} style - custom css style
   * @property {boolean} hidden - whether the element is hidden
   */

  /**
   * @typedef {object} btRangeMeterObject
   * @property {string} class -
   * @property {btRangeMeterRangeElementObject[]} ranges -
   * @property {btRangeMeterTitleElementObject[]} rTitles -
   * @property {btRangeMeterElementObject[]} ticks -
   * @property {btRangeMeterTitleElementObject[]} tTitles -
   * @property {btRangeMeterElementObject[]} markers -
   * @property {btRangeMeterTitleElementObject[]} mTitles -
   */

  angular
    .module('btUI')
    /**
     * @ngdoc service
     * @name $btRangeMeter
     * @memberOf btUI
     * @description
     *  This *
     */
    .factory('$btRangeMeter', service);

  service.$inject = ['$timeout'];

  /**
   *
   * @param {angular.ITimeoutService} $timeout
   * @return {any}
   */
  function service($timeout) {
    if (gDebug) console.log(gPrefix, 'Running btRangeMeterService');

    void $timeout;

    var gCount = 0;

    /**
     * @name btUI.$btRangeMeter#Align
     * @type {{VERTICAL: string, HORIZONTAL: string, DEFAULT: string}}
     */
    var Align = { DEFAULT: 'vertical', VERTICAL: 'vertical', HORIZONTAL: 'horizontal' };

    /**
     * @name btUI.$btRangeMeter#Place
     * @type {{CENTER: string, HIGH: string, LOW: string, DEFAULT: string}}
     */
    var Place = { DEFAULT: 'low', LOW: 'low', CENTER: 'center', HIGH: 'high' };

    /**
     * @name btUI.$btRangeMeter#Element
     * @type {{MARKER: string, TICK: string, RANGE: string}}
     */
    var Element = { RANGE: 'range', TICK: 'tick', MARKER: 'marker' };

    return {
      Align: Align,
      Place: Place,
      Element: Element,
      getUniqueID: getUniqueID,
      checkIsParamsValid: checkIsParamsValid,
      getPlaceholder: getPlaceholder,
      drawMeter: drawMeter,
      prepareData: prepareData,
    };

    /**
     * @alias btUI.$btRangeMeter#getUniqueID
     * @return {string}
     */
    function getUniqueID() {
      gCount++;
      return gCount.toString();
    }

    /**
     * This function checks if parameters is valid.
     *
     * @alias btUI.$btRangeMeter#checkIsParamsValid
     * @param {btRangeMeterParameters} params - parameters
     * @return {any}
     */
    function checkIsParamsValid(params) {
      return !!(params && params.ranges && params.ticks && params.markers);
    }

    /**
     * This function return range meter place holder.
     *
     * @alias btUI.$btRangeMeter#getPlaceholder
     * @return {btRangeMeterObject}
     */
    function getPlaceholder() {
      return {
        class: '',
        ranges: [],
        rTitles: [],
        ticks: [],
        tTitles: [],
        markers: [],
        mTitles: [],
      };
    }

    /**
     * This function parses parameters and reinitializes range meters elements.
     *
     * @alias btUI.$btRangeMeter#prepareData
     * @param {string} id - identifier
     * @param {btRangeMeterParameters} params - parameters
     * @return {btRangeMeterObject}
     */
    function prepareData(id, params) {
      params.align = params.align || Align.DEFAULT;

      return {
        class: params.align,
        ranges: (params.ranges || []).map(function (value, i) {
          return prepareRangeElement(id, Element.RANGE, params, value, i);
        }),
        rTitles: (params.ranges || []).map(function (value, i) {
          return prepareElementTitle(id, Element.RANGE, params, value, i);
        }),
        ticks: (params.ticks || []).map(function (value, i) {
          return prepareElement(id, Element.TICK, params, value, i);
        }),
        tTitles: (params.ticks || []).map(function (value, i) {
          return prepareElementTitle(id, Element.TICK, params, value, i);
        }),
        markers: (params.markers || []).map(function (value, i) {
          return prepareElement(id, Element.MARKER, params, value, i);
        }),
        mTitles: (params.markers || []).map(function (value, i) {
          return prepareElementTitle(id, Element.MARKER, params, value, i);
        }),
      };
    }

    /**
     * This function prepares range element.
     *
     * @param {string} id - identifier
     * @param {string} type - element type: {@link btUI.$btRangeMeter#Element.RANGE}
     * @param {btRangeMeterParameters} params - parameters
     * @param {btRangeMeterRange} source - range source object
     * @param {number} i - range index
     * @return {btRangeMeterRangeElementObject}
     */
    function prepareRangeElement(id, type, params, source, i) {
      return {
        id: 'bt-meter-' + id + '-' + type + '-' + i,
        class: source.class,
        style: {},
        hidden: !!source.hidden,
        h: calculateSize(source.h, params),
        l: calculateSize(source.l, params),
        o: source.o,
      };
    }

    /**
     * This function prepares tick or marker element.
     *
     * @param {string} id - identifier
     * @param {string} type - element type {@link btUI.$btRangeMeter#Element}
     * @param {btRangeMeterParameters} params - parameters
     * @param {btRangeMeterTick|btRangeMeterMarker} source - element source object
     * @param {number} i - range index
     * @return {btRangeMeterElementObject}
     */
    function prepareElement(id, type, params, source, i) {
      return {
        id: 'bt-meter-' + id + '-' + type + '-' + i,
        class: source.class,
        style: {},
        hidden: !!source.hidden,
        p: calculateSize(source.p, params),
        place: Place.DEFAULT,
      };
    }

    /**
     * This function prepares element title.
     *
     * @param {string} id - identifier
     * @param {string} type - element type: {@link btUI.$btRangeMeter#Element}
     * @param {btRangeMeterParameters} params - parameters
     * @param {btRangeMeterRange|btRangeMeterTick|btRangeMeterMarker} source - element source object
     * @param {number} i - range index
     * @return {btRangeMeterTitleElementObject}
     */
    function prepareElementTitle(id, type, params, source, i) {
      var position;
      var max;

      if (type === Element.RANGE) {
        position = getRangeTitlePosition(source, params);
        if (params.size)
          max = Math.ceil(((calculateSize(source.h, params) - calculateSize(source.l, params)) / 100) * params.size);
      } else {
        position = source.p;
      }

      return {
        id: 'bt-meter-' + id + '-' + type + '-title-' + i,
        class: source.label ? source.label.class : '',
        hint: source.label ? source.label.hint || '' : '',
        style: {},
        max: max,
        hidden: source.label ? !!source.label.hidden : false,
        html: source.label ? source.label.html : '',
        p: calculateSize(position, params),
        place: source.label ? source.label.place : Place.DEFAULT,
      };
    }

    /**
     * This function calculate size in percentage.
     *
     * @param {number} value - some value
     * @param {btRangeMeterParameters} params - parameters
     * @return {number}
     */
    function calculateSize(value, params) {
      void params;
      var res = ((value - params.scale[0]) / (params.scale[1] - params.scale[0])) * 100;
      if (res > 100) {
        return 100;
      } else if (res < 0) {
        return 0;
      } else {
        return parseFloat(res.toFixed(2));
      }
    }

    /**
     * This function returns title position.
     *
     * @param {btRangeMeterRange|btRangeMeterTick|btRangeMeterMarker} source - element source object
     * @param {btRangeMeterParameters} params - parameters
     * @return {number}
     */
    function getRangeTitlePosition(source, params) {
      void params;
      switch (source.label.place) {
        case Place.HIGH:
          return source.h + (source.o || 0);
        case Place.CENTER:
          return (source.l + source.h) / 2 + (source.o || 0);
        case Place.LOW:
        default:
          return source.l + (source.o || 0);
      }
    }

    /**
     * This function changes elements styles.
     *
     * @alias btUI.$btRangeMeter#drawMeter
     * @param {btRangeMeterObject} meter
     * @param {btRangeMeterParameters} params - parameters
     */
    function drawMeter(meter, params) {
      meter.ranges.forEach(drawRangeElement.bind(null, params));
      meter.rTitles.forEach(drawTitleElement.bind(null, params));

      meter.ticks.forEach(drawElement.bind(null, params));
      meter.tTitles.forEach(drawTitleElement.bind(null, params));

      meter.markers.forEach(drawElement.bind(null, params));
      meter.mTitles.forEach(drawTitleElement.bind(null, params));
    }

    /**
     * This function changes range element styles.
     *
     * @param {btRangeMeterParameters} params - parameters
     * @param {btRangeMeterRangeElementObject} range - range element
     */
    function drawRangeElement(params, range) {
      if (params.align === Align.VERTICAL) {
        range.style = {
          bottom: range.l + '%',
          height: range.h - range.l + '%',
        };
      } else {
        range.style = {
          left: range.l + '%',
          width: range.h - range.l + '%',
        };
      }
    }

    /**
     * This function changes element styles.
     *
     * @param {btRangeMeterParameters} params - parameters
     * @param {btRangeMeterElementObject} element - range element
     */
    function drawElement(params, element) {
      if (params.align === Align.VERTICAL) {
        element.style = { bottom: element.p + '%' };
      } else {
        switch (element.place) {
          case Place.HIGH:
            element.style = { right: 100 - element.p + '%' };
            break;
          case Place.CENTER:
          case Place.LOW:
          default:
            element.style = { left: element.p + '%' };
            break;
        }
      }
    }

    /**
     * This function changes title element styles.
     * *
     * @param {btRangeMeterParameters} params - parameters
     * @param {btRangeMeterTitleElementObject} element - range element
     */
    function drawTitleElement(params, element) {
      if (params.align === Align.VERTICAL) {
        element.style = { bottom: element.p + '%' };
      } else {
        switch (element.place) {
          case Place.HIGH:
            element.style = {
              right: 100 - element.p + '%',
              'max-width': element.max ? Math.ceil(element.max) + 'px' : undefined,
            };
            break;
          case Place.CENTER:
          case Place.LOW:
          default:
            element.style = {
              left: element.p + '%',
              'max-width': element.max ? Math.ceil(element.max) + 'px' : undefined,
            };
            break;
        }
      }
    }
  }
})();
