/**
 * Created by Sergey Panpurin on 10/28/2021.
 */

// @ts-check
(function btMarketDataServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btMarketDataService:';

  angular.module('ecapp').factory('btMarketDataService', btMarketDataService);

  btMarketDataService.$inject = [
    '$q',
    '$http',
    '$interval',
    '$rootScope',
    'btSettings',
    'btInstrumentsService',
    'MarketData',
    'UDFMarketData',
  ];

  /**
   *  This factory works with BetterTrader Market API.
   *
   * Authorizing user
   * Loading user watchlist
   * Resolving instruments
   * Subscribing to quotes
   * Unsubscribing from quotes
   * Loading historical candles
   * Subscribing to candles
   * Unsubscribing from candles
   *
   * @ngdoc service
   * @name btMarketDataService
   * @memberOf ecapp
   * @param {angular.IQService} $q
   * @param {angular.IHttpService} $http
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.ISettings} btSettings
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.IGeneralLoopbackService} lbMarketData
   * @param {ecapp.IGeneralLoopbackService} UDFMarketData
   * @return {ecapp.IMarketDataService}
   */
  function btMarketDataService(
    $q,
    $http,
    $interval,
    $rootScope,
    btSettings,
    btInstrumentsService,
    lbMarketData,
    UDFMarketData
  ) {
    if (gDebug) console.log(gPrefix, 'running');

    var gInitPromise;

    return {
      initialize: initialize,
      search: search,
      resolve: resolve,
      // resolveMany: resolveMany,
      quotes: quotes,
      latest: latest,
      history: history,
      price: price,
      subscribe: subscribe,
      unsubscribe: unsubscribe,
      subscriptions: subscriptions,
    };

    /**
     *
     * @return {angular.IPromise<void>}
     */
    function initialize() {
      if (!gInitPromise) {
        gInitPromise = $q.resolve();
      }

      return gInitPromise;
    }

    /**
     *
     * @param {string} query
     * @param {ecapp.InstrumentSearchOptions} options
     * @return {angular.IPromise<ecapp.ITradingSymbol[]>}
     */
    function search(query, options) {
      if (gDebug) console.log(gPrefix, 'search', query, options);
      return $q.resolve([]);
    }

    /**
     *
     * @param {string} ticker
     * @return {angular.IPromise<ecapp.ITradingInstrument>}
     */
    function resolve(ticker) {
      if (gDebug) console.log(gPrefix, 'resolve', ticker);
      return $q.resolve(null);
    }

    // /**
    //  *
    //  * @param {string[]} tickers
    //  * @return {angular.IPromise<ecapp.ITradingInstrument[]>}
    //  */
    // function resolveMany(tickers) {
    //   if (gDebug) console.log(gPrefix, 'resolveMany', tickers);
    //   return $q.resolve([]);
    // }

    /**
     *
     * @param {ecapp.ITradingInstrument[]} instruments
     * @return {angular.IPromise<ecapp.ITradingQuote[]>}
     */
    function quotes(instruments) {
      if (gDebug) console.log(gPrefix, 'quotes', instruments);
      return $q.resolve([]);
    }

    /**
     *
     * @param {ecapp.ITradingInstrument} instrument
     * @param {string} granularity
     * @param {number} [count]
     * @return {angular.IPromise<ecapp.ICandlePrices[]>}
     */
    function latest(instrument, granularity, count) {
      if (gDebug) console.log(gPrefix, 'latest', instrument, granularity, count);
      return $q.resolve([]);
    }

    /**
     *
     * @param {ecapp.ITradingInstrument} instrument
     * @param {string} granularity
     * @param {number} from
     * @param {number} to
     * @return {angular.IPromise<ecapp.ICandlePrices[]>}
     */
    function history(instrument, granularity, from, to) {
      if (gDebug) console.log(gPrefix, 'history', instrument, granularity, from, to);
      return $q.resolve([]);
    }

    /**
     *
     * @param {ecapp.ITradingInstrument} instrument
     * @param {number} time
     * @return {angular.IPromise<number>}
     */
    function price(instrument, time) {
      if (gDebug) console.log(gPrefix, 'price', instrument, time);
      return $q.resolve(0);
    }

    /**
     *
     * @param {ecapp.ITradingInstrument} instrument
     * @param {ecapp.MarketEvent} event
     */
    function subscribe(instrument, event) {
      if (gDebug) console.log(gPrefix, 'subscribe', instrument, event);
    }

    /**
     *
     * @param {ecapp.ITradingInstrument} instrument
     * @param {ecapp.MarketEvent} event
     */
    function unsubscribe(instrument, event) {
      if (gDebug) console.log(gPrefix, 'unsubscribe', instrument, event);
    }

    /**
     *
     * @return {ecapp.MarketEventSubscriptions[]}
     */
    function subscriptions() {
      if (gDebug) console.log(gPrefix, 'subscriptions');
      return [];
    }
  }
})();
