(function btImportanceFilterClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * Returns importance
     *
     * @ngdoc service
     * @name btImportanceFilter
     * @memberOf ecapp
     */
    .filter('btImportance', [btImportanceFilter]);

  /**
   *
   * @return {angular.IFilterFunction}
   */
  function btImportanceFilter() {
    return function convertImportance(importance, type) {
      var str;
      if (type === 'text') {
        switch (importance) {
          case 1:
            str = '!';
            break;
          case 2:
            str = '!!';
            break;
          case 3:
            str = '!!!';
            break;
        }
      } else {
        switch (importance) {
          case 1:
            str =
              '<i class="ion-alert active"></i><i class="ion-alert inactive"></i><i class="ion-alert inactive"></i>';
            break;
          case 2:
            str = '<i class="ion-alert active"></i><i class="ion-alert active"></i><i class="ion-alert inactive"></i>';
            break;
          case 3:
            str = '<i class="ion-alert active"></i><i class="ion-alert active"></i><i class="ion-alert active"></i>';
            break;
        }
      }
      return str;
    };
  }
})();
