// @ts-check
(function btOpenAccountControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btOpenAccountController', btOpenAccountController);

  btOpenAccountController.$inject = ['$scope', '$stateParams', 'btTradingPlatformService'];

  /**
   *
   * @param {ecapp.ICustomScope} $scope - ?
   * @param {angular.ui.IStateService} $stateParams - ?
   * @param {ecapp.ITradingPlatformService} btTradingPlatformService - ?
   */
  function btOpenAccountController($scope, $stateParams, btTradingPlatformService) {
    // $scope.isProduction = window.isProduction;

    $scope.broker = btTradingPlatformService.getPlatforms().filter(function (value) {
      return value.id === $stateParams.platform;
    })[0];

    if ($scope.broker) {
      if ($scope.broker.brokers) {
        $scope.broker = $scope.broker.brokers.filter(function (value) {
          return value.id === $stateParams.broker;
        })[0];
      }
      $scope.isLoading = false;
    } else {
      $scope.isLoading = true;
    }
  }
})();
