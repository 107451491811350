/**
 * Created by xxx on xxx.
 */

(function ionStickyClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive connect day header to top in calendar
     *
     * @ngdoc directive
     * @name ionSticky
     * @memberOf ecapp
     * @param {ionic.IPositionService} $ionicPosition - ionic position service
     * @param {angular.ICompileService} $compile - compiles an HTML string or DOM into a template and produces a template function
     * @param {angular.ITimeoutService} $timeout - wrapper for window.setTimeout
     */
    .directive('ionSticky', [
      '$ionicPosition',
      '$compile',
      '$timeout',
      /**
       *
       * @param {ionic.IPositionService} $ionicPosition
       * @param {angular.ICompileService} $compile
       * @param {angular.ITimeoutService} $timeout
       * @return {any}
       */
      function ($ionicPosition, $compile, $timeout) {
        return {
          restrict: 'A',
          require: '^$ionicScroll',
          link: function ($scope, $element, $attr, $ionicScroll) {
            var scroll = angular.element($ionicScroll.element);
            var clone,
              dividers = [];
            $scope.$parent.numberLoad = 0;
            // creates the sticky divider clone and adds it to DOM
            var createAffixClone = function ($element) {
              clone = $element.clone().css({
                position: 'absolute',
                top: $ionicPosition.position(scroll).top + 'px', // put to top
                left: 0,
                right: 0,
              });
              clone[0].className += ' assertive';

              clone.removeAttr('ng-repeat-start');

              //Itay's patch: this line avoids the clone to have "zero-padding" class which will make is transparent.
              clone.removeAttr('ng-class');

              scroll.parent().append(clone);

              // compile the clone so that anything in it is in Angular lifecycle.
              $compile(clone)($scope);
            };

            var removeAffixClone = function () {
              if (clone) clone.remove();
              clone = null;
            };

            $scope.$on('$destroy', function () {
              // remove the clone and unbind the scroll listener
              removeAffixClone();
              angular.element($ionicScroll.element).off('scroll');
            });

            var lastActive;
            scroll.on('scroll', function () {
              if ($scope.$parent.numberLoad === 0) {
                // first load
                $scope.$parent.numberLoad = 1;
                $timeout(
                  function () {
                    // wait for sub elements all added
                    var tmp = $element[0].getElementsByClassName('section-date');
                    for (var i = 0; i < tmp.length; ++i) dividers.push(angular.element(tmp[i]));
                  },
                  0,
                  false
                );
              } else {
                // next load
                var active = null;
                // var scrollTop = $ionicScroll.element.scrollTop;
                for (var i = 0; i < dividers.length; ++i) {
                  // can be changed to binary search
                  if ($ionicPosition.offset(dividers[i]).top - dividers[i].prop('offsetHeight') < 0) {
                    // this equals to jquery outerHeight
                    if (
                      i === dividers.length - 1 ||
                      $ionicPosition.offset(dividers[i + 1]).top -
                        (dividers[i].prop('offsetHeight') + dividers[i + 1].prop('offsetHeight')) >
                        0
                    ) {
                      active = i;
                      break;
                    }
                  }
                }

                if (lastActive != active) {
                  removeAffixClone();
                  lastActive = active;
                  if (active != null) createAffixClone(dividers[active]);
                }
              }
            });
          },
        };
      },
    ]);
})();
