/**
 * Created by Sergey Panpurin on 7/10/2017.
 */

// @ts-check
(function btBrokerListClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * @ngdoc directive
     * @name btBrokerList
     * @memberOf ecapp
     * @description
     *  This directive display list of supported brokers.
     */
    .directive('btBrokerList', btBrokerList)
    .controller('btBrokerListController', btBrokerListController);

  btBrokerList.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache - ?
   * @return {angular.IDirective}
   */
  function btBrokerList($templateCache) {
    return {
      restrict: 'E',
      replace: false,
      scope: {
        select: '&onSelect',
      },
      template: $templateCache.get('bt-brokerage/directives/bt-broker-list.html'),
      controller: 'btBrokerListController',
    };
  }

  btBrokerListController.$inject = [
    '$rootScope',
    '$scope',
    'btTradingService',
    'btAdvertisingService',
    'btLinkService',
  ];

  /**
   *
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.IAdvertisingService} btAdvertisingService
   * @param {ecapp.ILinkService} btLinkService
   */
  function btBrokerListController($rootScope, $scope, btTradingService, btAdvertisingService, btLinkService) {
    // list of supported brokers
    $scope.brokers = btTradingService.getSupportedBrokers();

    $scope.partner = null;
    $scope.error = null;

    $scope.isLoading = true;

    $scope.openPartner = openPartner;

    activate();

    /**
     * This function actives controller.
     */
    function activate() {
      btAdvertisingService
        .initialize()
        .then(function () {
          $scope.isLoading = false;
          $scope.error = null;

          var settings = btAdvertisingService.getBannerSettings('broker-page');
          // var settings = btAdvertisingService.getADSettings();
          if (settings.enabled) {
            $scope.partner = {
              style: 'broker-' + settings.id,
              logo: settings.image,
              name: settings.title,
              url: settings.url,
            };
          } else {
            $scope.partner = null;
          }
        })
        .catch(function (reason) {
          $scope.isLoading = false;
          $scope.error = reason.error;
          console.error(reason);
        });
    }

    /**
     * This function opens AvaTrade link.
     */
    function openPartner() {
      btLinkService.openExternal($scope.partner.url);
    }
  }
})();
