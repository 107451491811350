/**
 * Created by Sergey on 1/22/2017.
 */

/*global zingchart */

// @ts-check
(function btChartServiceClosure() {
  'use strict';

  /**
   * @name Math.log10
   * @type {Function}
   */

  /**
   * @callback zingChartExec
   * @param {String} id - chart id
   * @param {String} command - command name
   * @param {Object} params - command parameters
   */

  /**
   * @namespace window.zingchart
   * @property {zingChartExec} exec - ?
   */

  angular.module('btUtils').factory('btChartService', btChartService);

  btChartService.$inject = ['btDateService'];

  /**
   * This service plot chart using ZingChart library.
   *
   * @ngdoc service
   * @name btChartService
   * @memberOf dashboard
   * @param {ecapp.IDateService} btDateService
   * @return {ecapp.IChartService}
   */
  function btChartService(btDateService) {
    var gDebug = false;
    var gPrefix = 'btChartService';

    var gColors = {
      any: [
        '#002aff',
        '#194fff',
        '#226dff',
        '#308bff',
        '#47a9ff',
        '#61c7ff',
        '#80dbff',
        '#9ceeff',
        '#baf8ff',
        '#d8fff4',
        '#f6ffd7',
        '#fff9b9',
        '#ffef9c',
        '#ffdc7e',
        '#ffc760',
        '#ffa943',
        '#ff8c2b',
        '#ff6f1d',
        '#ff520f',
        '#ff2a00',
      ],
      win: [
        '#95ca9b',
        '#97e4a0',
        '#7cf98a',
        '#66ce72',
        '#0eda26',
        '#46e058',
        '#4ebd5c',
        '#529c5a',
        '#1bb52f',
        '#239e33',
        '#098819',
        '#0b881b',
        '#407946',
        '#326d38',
        '#13711f',
        '#0b5d15',
        '#14691e',
        '#0d5415',
        '#064c0e',
        '#9ac79f',
      ],
      loss: [
        '#d05959',
        '#dc3f3f',
        '#dc2e2e',
        '#d61e1e',
        '#d80f0f',
        '#a53c3c',
        '#a72f2f',
        '#a91c1c',
        '#ad0a0a',
        '#793434',
        '#822626',
        '#8c1c1c',
        '#960c0c',
        '#5d2929',
        '#652121',
        '#611414',
        '#670909',
        '#501919',
        '#460404',
        '#440707',
      ],
      weak: [
        '#b7b9bd',
        '#a0a2a7',
        '#7e7f82',
        '#616265',
        '#4f5052',
        '#303131',
        '#99a2a2',
        '#9c9a9e',
        '#4d4b4e',
        '#d4d3d4',
        '#535665',
        '#b9babd',
        '#5d5e61',
        '#5f6061',
        '#b7babd',
        '#7a8188',
        '#dde8f3',
        '#c9d3dc',
        '#a4aeb7',
        '#696a6b',
      ],
    };

    if (gDebug) {
      console.log('Chart bound (begin testing)');
      [
        [2624, 2688.8],
        [59.6, 60.4],
        [61.267, 63.868],
        [0.55, 1.25],
        [-1.15, 1.25],
        [-1.1, 100.2],
      ].forEach(function (value) {
        console.log('Chart bound (result):', value[0], value[1], '>', normalizeBound(value[0], value[1]));
      });
      console.log('Chart bound (end testing)');
    }

    var MAX_INSTRUMENT_CHART_TIME_RANGE = 30 * 60 * 60 * 1000;

    return {
      // priceLimits: priceLimits,
      getBound: getBound,
      getBaseRelativeBound: getBaseRelativeBound,
      // fixRealTimeBound: fixRealTimeBound,
      buildStockChart: buildStockChart,
      modifyStockChart: modifyStockChart,
      buildScopeChart: buildScopeChart,
      buildPieChart: buildPieChart,
      // buildRealTimeScopeChart: buildRealTimeScopeChart,
      buildStockChartRealTime: buildStockChartRealTime,
      buildInstrumentChart: buildInstrumentChart,
      getDeviation: getDeviation,
      prepareYMarkets: prepareYMarkets,
      prepareXMarkets: prepareXMarkets,
      getSyntheticChartTemplate: getSyntheticChartTemplate,
      prepareSyntheticChartSeries: prepareSyntheticChartSeries,
      updateSyntheticChartScaleValues: updateSyntheticChartScaleValues,
      updateSyntheticChartYMarkers: updateSyntheticChartYMarkers,
      // showSyntheticChartOptions: showSyntheticChartOptions,
      showSyntheticChartOptionsJSON: showSyntheticChartOptionsJSON,
      countSyntheticChartOptions: countSyntheticChartOptions,
      updateTradeMarkers: updateTradeMarkers,
    };

    /**
     * Calculate min and max price in array of candles
     *
     * @param {Array} prices - price data
     * @return {Number[]} - min and max values
     */
    function priceLimits(prices) {
      var minValue = 999999;
      var maxValue = 0;
      for (var i = 0; i < prices.length; i++) {
        if (minValue > prices[i][1][2]) {
          minValue = prices[i][1][2];
        }
        if (maxValue < prices[i][1][1]) {
          maxValue = prices[i][1][1];
        }
      }
      return [minValue, maxValue];
    }

    /**
     * Calculates price limits and step for better displaying of chart.
     *
     * @param {Array} prices - candles array [[time, [o, h, l, c]], [time, [o, h, l, c]]]
     * @param {Object} [meta] - snapshot meta data
     * @return {String} - price limits in format min:max:step
     */
    function getBound(prices, meta) {
      var range = priceLimits(prices);
      var min = range[0];
      var max = range[1];

      if (gDebug) console.log(gPrefix, 'Chart bound (0):', min, max);

      if (meta && meta.prices) {
        if (gDebug)
          console.log(gPrefix, 'Chart bound (0):', min, max, 'vs', meta.prices['min_limit'], meta.prices['max_limit']);
        max = Math.max(meta.prices['max_limit'], max);
        min = Math.min(meta.prices['min_limit'], min);
      }

      return normalizeBound(min, max);
    }

    /**
     * Normalizes limits.
     *
     * @param {Number} originalMinValue - original max value
     * @param {Number} originalMaxValue - original min value
     * @return {String}
     */
    function normalizeBound(originalMinValue, originalMaxValue) {
      var minValue = originalMinValue;
      var maxValue = originalMaxValue;

      var stepValue;

      if (maxValue - minValue < 1) {
        if (gDebug) console.log(gPrefix, 'Chart bound (1): ' + (maxValue - minValue) + ' < 1');

        // reverse 10 power of difference
        var pr = Math.ceil(Math.log10(1 / (maxValue - minValue)));

        // 10 power of average range value
        var pr2 = Math.max(
          Math.ceil(Math.log10(Math.abs(minValue))),
          Math.ceil(Math.log10(Math.abs(maxValue))),
          Math.ceil(Math.log10(Math.abs(minValue + maxValue) / 2)),
          1
        );

        stepValue = Math.pow(10, -pr);

        if (gDebug) console.log(gPrefix, 'Chart bound (1): (power)', pr, 'of', 1 / (maxValue - minValue));
        if (gDebug) console.log(gPrefix, 'Chart bound (1): (power)', pr2, 'of', (minValue + maxValue) / 2);
        if (gDebug) console.log(gPrefix, 'Chart bound (1): (power)', stepValue);

        minValue = Math.floor(minValue * Math.pow(10, pr)) / Math.pow(10, pr);
        maxValue = Math.ceil(maxValue * Math.pow(10, pr)) / Math.pow(10, pr); // + stepValue / 2;

        if (gDebug) console.log(gPrefix, 'Chart bound (1):', minValue, maxValue, stepValue);

        maxValue = normalizeMaxValue(maxValue, minValue, originalMaxValue, stepValue);
        minValue = normalizeMinValue(minValue, originalMinValue, stepValue);

        minValue = minValue.toPrecision(pr + pr2);
        maxValue = maxValue.toPrecision(pr + pr2);
        stepValue = stepValue.toPrecision(1);
      } else {
        if (gDebug) console.log(gPrefix, 'Chart bound (1): ' + (maxValue - minValue) + ' >= 1');

        minValue = Math.floor(minValue);
        maxValue = Math.ceil(maxValue);

        stepValue = (maxValue - minValue) / 8;

        if ((maxValue - minValue) / Math.ceil(stepValue) > 4) {
          stepValue = Math.ceil(stepValue);
        } else if (Math.floor(stepValue) !== 0 && (maxValue - minValue) / Math.floor(stepValue) > 12) {
          stepValue = Math.floor(stepValue);
        } else {
          stepValue = Math.round(stepValue * 10) / 10;
        }

        if (gDebug) console.log(gPrefix, 'Chart bound (1):', minValue, maxValue, stepValue);

        maxValue = normalizeMaxValue(maxValue, minValue, originalMaxValue, stepValue);
        minValue = normalizeMinValue(minValue, originalMinValue, stepValue);
      }

      /* Examples
      /app/main/reviews/usd/participation_rate/utc/2018/11/02/12/30 - S&P500 - 09 March 2018 13:30
      /app/main/reviews/gbp/manufacturing_pmi/utc/2018/11/01/09/30 - GBP/JPY - 02 January 2018 09:30
      */

      if (gDebug) console.log(gPrefix, 'Chart bound (1):', minValue + ':' + maxValue + ':' + stepValue);
      return minValue + ':' + maxValue + ':' + stepValue;
    }

    /**
     * Normalize max value
     * @param {Number} maxValue - current max value
     * @param {Number} minValue - current min value
     * @param {Number} originalMaxValue - original max value
     * @param {Number} stepValue - step value
     * @return {Number}
     */
    function normalizeMaxValue(maxValue, minValue, originalMaxValue, stepValue) {
      // normalize max value to step value
      var times = Math.ceil((maxValue - minValue) / stepValue);
      maxValue = minValue + times * stepValue;

      // if current max value too close to original max value add one step and return result
      if (maxValue - originalMaxValue < 0.4 * stepValue) {
        return maxValue + stepValue;
      }

      // reduce space between current max value and original max value
      while (maxValue - originalMaxValue > 1.4 * stepValue) {
        maxValue -= stepValue;
      }

      return maxValue;
    }

    /**
     * Normalize min value
     * @param {Number} minValue - current min value
     * @param {Number} originalMinValue - original max value
     * @param {Number} stepValue - step value
     * @return {Number}
     */
    function normalizeMinValue(minValue, originalMinValue, stepValue) {
      // if current min value too close to original min value add one step and return result
      if (-(minValue - originalMinValue) < 0.4 * stepValue) {
        return minValue - stepValue;
      }

      // reduce space between current min value and original min value
      while (-(minValue - originalMinValue) > 1.4 * stepValue) {
        minValue += stepValue;
      }

      return minValue;
    }

    /**
     * Recalculate boundaries for relative scale
     *
     * @param {String} limits - price limits
     * @param {Number} basePrice - base price
     * @return {String}
     */
    function getBaseRelativeBound(limits, basePrice) {
      if (!basePrice) return null;

      var rangeStr = limits.split(':');
      var minValue = parseFloat(rangeStr[0]);
      var maxValue = parseFloat(rangeStr[1]);
      var stepValue = parseFloat(rangeStr[2]);
      var rangeValue = maxValue - minValue;

      var maxRelative = ((maxValue - basePrice) * 100) / basePrice;
      var minRelative = ((minValue - basePrice) * 100) / basePrice;
      var rangeRelative = maxRelative - minRelative;

      var stepRelative = (stepValue * rangeRelative) / rangeValue;

      return minRelative + ':' + maxRelative + ':' + stepRelative;
    }

    /**
     * NEVER USED. Increase price limits for realtime charts
     *
     * @param {String} range
     * @param {Array} prices
     * @return {String|*}
     */
    // eslint-disable-next-line no-unused-vars
    function fixRealTimeBound(range, prices) {
      var rangeStr = range.split(':');
      var minRange = parseFloat(rangeStr[0]);
      var maxRange = parseFloat(rangeStr[1]);
      var step = rangeStr[2];
      var minPrice = Math.min.apply(Math, prices);
      var maxPrice = Math.max.apply(Math, prices);
      var newRange;

      if (maxPrice < maxRange) {
        maxPrice = maxRange;
      }

      if (minPrice > minRange) {
        minPrice = minRange;
      }

      maxPrice += step;
      newRange = minPrice + ':' + maxPrice + ':' + step;
      return newRange;
    }

    /**
     * Build stock chart using template
     *
     * @param {String} title - chart title
     * @param {String} yValues - y-axis labels
     * @param {Array} values - data values
     * @param {Number} refX - x reference value
     * @param {Number} refY - y reference value
     * @param {String} yValues2 - y2-axis labels
     * @param {Object[]} xMarkers - x scale markers
     * @param {Object[]} yMarkers - y scale markers
     * @return {ext.IZingChartJSON} - JSON data of chart
     */
    function buildStockChart(title, yValues, values, refX, refY, yValues2, xMarkers, yMarkers) {
      var data = stockChartTemplate();

      if (refX && refX !== 0) {
        data['scale-x']['ref-value'] = refX;
        data['scale-x']['ref-line'] = {};
        data['scale-x']['ref-line']['visible'] = true;
      }

      if (xMarkers) {
        data['scale-x']['markers'] = xMarkers;
      }

      if (refY && refY !== -1) {
        data['scale-y']['ref-value'] = refY;
      }

      if (yValues) {
        data['scale-y']['values'] = yValues;
      }

      if (yMarkers) {
        data['scale-y']['markers'] = yMarkers;
      }

      if (yValues2) {
        data['scale-y-2']['values'] = yValues2;
      }

      if (values) {
        data['series'].push({ values: values });
      }

      if (title) {
        data['title'] = { text: title, color: 'white' };
      } else {
        data['plotarea'] = { margin: [-15, 70, 40, 40] };
      }

      return data;
    }

    /**
     * Modify stock chart
     * @param {Object} data -
     * @param {{hideDate:Boolean, hideXMarkers:Boolean, hideYMarkers:Boolean, interval:Number}} options
     * @return {ext.IZingChartJSON}
     */
    function modifyStockChart(data, options) {
      // hot fix
      if (data) {
        data['background-color'] = '#131722';
        data['backgroundColor'] = '#131722';

        if (data['scale-y']) {
          if (data['scale-y']['ref-line']) {
            data['scale-y']['ref-line']['visible'] = false;
          } else {
            data['scale-y']['ref-line'] = { visible: false };
          }
        }

        if (data['crosshair-x']) {
          data['crosshair-x']['plot-label'] = {
            text: 'O%open, H%high, L%low, C%close',
            decimals: 4,
            multiple: true,
            callout: false,
            'background-color': 'transparent',
            'border-color': 'none',
            color: 'white',
            x: '40%',
            y: '15px',
          };
        }

        if (data['plot']) {
          data['plot']['tooltip'] = {
            visible: false,
            text: 'O: %open, H: %high, L: %low, C: %close',
            decimals: 4,
            'background-color': 'transparent',
            'border-color': 'none',
            color: 'white',
            x: '40%',
            y: '10px',
          };
        }

        if (data['scale-x']) {
          data['scale-x']['tooltip'] = { visible: false };
          if (data['scale-x']['item'] && data['scale-x']['item']['font-color']) {
            data['scale-x']['item']['font-color'] = '#c0c6c9';
          }
        }

        if (data['scale-y']) {
          data['scale-y']['tooltip'] = { visible: false };

          if (data['scale-y']['item'] && data['scale-y']['item']['font-color']) {
            data['scale-y']['item']['font-color'] = '#c0c6c9';
          }

          if (data['scale-y']['markers']) {
            data['scale-y']['markers'].forEach(function (marker) {
              if (marker.label && marker.label.text === 'Entry') {
                marker.lineColor = 'white';
                marker.lineWidth = 1;
                marker.lineStyle = 'solid';
              }
            });
          }
        }

        if (data['scale-y-2']) {
          data['scale-y-2']['tooltip'] = { visible: false };
        }

        if (data['series']) {
          data['series'].forEach(function (serie) {
            serie['tooltip'] = { visible: false };
          });
        }
      }

      if (data && options) {
        data['plotarea'] = options.hideDate ? { margin: [10, 70, 25, 40] } : { margin: [10, 70, 40, 40] };

        if (data['scale-x'] && data['scale-x']['transform']) {
          data['scale-x']['step'] = options.interval ? options.interval * 60000 : 'minute';
          data['scale-x']['transform']['all'] = options.hideDate ? '%H:%i' : undefined;
        }

        if (data['scale-x'] && data['scale-x']['markers']) {
          data['scale-x']['markers'].forEach(function (marker) {
            marker.visible = !options.hideXMarkers;
          });
        }

        if (data['scale-y'] && data['scale-y']['markers']) {
          data['scale-y']['markers'].forEach(function (marker) {
            marker.visible = !options.hideYMarkers;
          });
        }

        if (data['scale-y-2'] && data['scale-y-2']['label'] && data['scale-y-2']['label']['text']) {
          data['scale-y-2']['label']['text'] =
            (options.interval === 1 ? '1 minute ' : options.interval + ' minutes ') + 'Candlestick chart';
        }
      }
      return data;
    }

    /**
     * Build correlation chart
     *
     * @param {*} title - ?
     * @param {*} timestamps - ?
     * @param {*} dataY1 - ?
     * @param {*} dataY2 - ?
     * @return {*}
     */
    function buildScopeChart(title, timestamps, dataY1, dataY2) {
      var colorY1Ln = ['#40beeb', '#4AD8CC'];
      var colorY2Ln = ['#FFAA11', '#11AAFF', '#11FFAA'];
      var dataJSON = correlationChartTemplate();

      if (title) {
        dataJSON['title'] = { text: title, color: 'white' };
      } else {
        dataJSON['plotarea'] = { margin: [-15, 25, 40, 40] };
      }

      dataJSON['scale-x'].values = timestamps;
      if (dataY1 != null) {
        dataY1.forEach(function (item, i) {
          dataJSON.series.push({
            text: item.text + ' Y1',
            scales: 'scale-x,scaleY',
            values: item.values,
            'background-color-1': '#77d9f8',
            'background-color-2': colorY1Ln[i],
            'line-color': colorY1Ln[i],
          });
        });
      }
      if (dataY2 != null) {
        dataY2.forEach(function (item, i) {
          dataJSON['series'].push({
            text: item.text + ' Y2',
            scales: 'scale-x,scaleY2',
            values: item.values,
            'background-color-1': '#77d9f8',
            'background-color-2': colorY2Ln[i],
            'line-color': colorY2Ln[i],
          });
        });
      }
      return dataJSON;
    }

    /**
     * Build stock chart for instrument
     *
     * @alias btUtils.btChartService#buildInstrumentChart
     * @param {string} yValues - range of y values in format `min:max:step`
     * @param {btInstrumentChartValues} values - stock chart values
     * @param {string} timeStep - x values in format `min:max:step`
     * @param {string} broker - broker name
     * @param {btChartLevel[]} levels - list of levels
     * @param {boolean} hasVolume - has volume data
     * @return {btInstrumentChartObject}
     */
    function buildInstrumentChart(yValues, values, timeStep, broker, levels, hasVolume) {
      hasVolume = hasVolume === undefined ? true : hasVolume;

      var data = instrumentChartTemplate();

      if (yValues) data['scale-y']['values'] = yValues;

      data['scale-x']['step'] = timeStep;

      // Insert values
      if (values) {
        if (timeStep === 'day') {
          data['scale-x']['max-items'] = 7;
          data['scale-x']['transform']['all'] = '%d\n%M';
        } else if (values.prices[values.prices.length - 1][0] - values.prices[0][0] > MAX_INSTRUMENT_CHART_TIME_RANGE) {
          data['scale-x']['transform']['all'] = '%H:%i\n%D';
        }

        if (timeStep === 'day') {
          data['type'] = 'line';
          data['series'].push({
            type: 'line',
            'line-width': '1px',
            'border-width': '3px',
            placement: 'node-top',
            scales: 'scale-x,scale-y',
            values: values.prices.map(function (value) {
              return [value[0], value[1][3]];
            }),
          });
        } else {
          data['series'].push({
            type: 'stock', // stock Chart
            aspect: 'candlestick',
            'line-width': '1px',
            'border-width': '3px',
            'trend-up': {
              'line-color': '#48c064',
              'border-color': '#48c064',
              'background-color': '#48c064',
            },
            'trend-down': {
              'line-color': '#cf3040',
              'border-color': '#cf3040',
              'background-color': '#cf3040',
            },
            placement: 'node-top',
            scales: 'scale-x,scale-y',
            values: values.prices,
          });
        }

        if (hasVolume) {
          // delete data['scale-y-2'];
          data['series'].push({
            type: 'bar', // volume Chart
            scales: 'scale-x,scale-y-2',
            'line-color': '#099EFF',
            'bar-width': '4px',
            'background-color': '#099EFF',
            marker: {
              type: 'none',
            },
            values: values.volumes,
          });
        }
      }

      // Add levels
      if (levels.length && data && data['scale-y']) {
        if (data['scale-y']['markers'] === undefined) data['scale-y']['markers'] = [];
        levels.forEach(function (level) {
          if (!level.visible) return;

          data['scale-y']['markers'].push(createLevelMarker(level));
          if (level.range !== 0) data['scale-y']['markers'].push(createLevelRegionMarker(level));
        });
      }

      // Add source label
      if (broker) {
        data['source'] = {
          text: 'Source: ' + broker || 'default',
        };
      }

      normalizeYMarkers(data, yValues, 175);
      return data;
    }

    /**
     * This function normalize size of marker to be at least one pixel.
     *
     * @param {btInstrumentChartObject} data - chart data
     * @param {string} values - Y values string
     * @param {number} height - Y scale height in pixels
     */
    function normalizeYMarkers(data, values, height) {
      if (data && data['scale-y'] && data['scale-y']['markers']) {
        var results = values.split(':');
        var pixel = (results[1] - results[0]) / height;

        data['scale-y']['markers'].forEach(function (marker) {
          if (marker.range.length === 2 && marker.range[0] !== marker.range[1]) {
            if (Math.abs(marker.range[1] - marker.range[0]) < pixel) {
              marker.range[1] = marker.range[0] + pixel;
            }
          }
        });
      }
    }

    /**
     * @typedef {object} zgChartMarker
     * @property {string} type - ?
     */

    /**
     * @see ecapp.IChartLevel
     * @typedef {object} btChartLevel
     * @property {Date} date - creation date
     * @property {object} price - price object
     * @property {number} price.ref - reference price
     * @property {number} price.min - minimum price
     * @property {number} price.mid - medium price
     * @property {number} price.max - maximum price
     * @property {number} precision - price precision
     * @property {number} shift - price shift
     * @property {number} range - level range in ticks
     * @property {number} rawRange - level range in ticks (original)
     * @property {number} unit - instrument unit size
     * @property {string} name - name of level
     * @property {string[]} tags - list of tags
     * @property {string} color - level color
     * @property {boolean} visible - whether the level is visible
     */

    /**
     * This function returns chart marker for price level.
     *
     * @param {btChartLevel} level - level object
     * @return {zgChartMarker}
     */
    function createLevelMarker(level) {
      return {
        type: 'area',
        range: [level.price.min, level.price.max],
        'value-range': true,
        'background-color': level.color,
        'label-placement': level.range === 0 ? 'normal' : 'opposite',
        'label-alignment': 'normal',
        alpha: 0.5,
        label: level.range === 0 ? { text: level.name, color: 'white' } : {},
      };
    }

    /**
     * This function returns chart marker for price level close region.
     *
     * @param {btChartLevel} level
     * @return {zgChartMarker}
     */
    function createLevelRegionMarker(level) {
      return {
        type: 'area',
        range: [level.price.minClose, level.price.maxClose],
        'value-range': true,
        'background-color': level.color,
        'label-placement': level.range === 0 ? 'normal' : 'opposite',
        'label-alignment': 'normal',
        alpha: 0.3,
        label: level.range === 0 ? { text: level.name, color: 'white' } : {},
      };
    }

    /**
     * Build realtime chart
     *
     * @return {{type: String, refresh: {type: String, transport: String, url: String, interval: Number}, series: Array}}
     */
    // eslint-disable-next-line no-unused-vars
    function buildRealTimeScopeChart() {
      // var colorY1Ln = ["#40beeb", "#4AD8CC"];
      // var colorY2Ln = ["#FFAA11", "#11AAFF", "#11FFAA"];
      return {
        type: 'line',
        refresh: {
          type: 'feed',
          transport: 'js',
          url: 'feed1()',
          interval: 2000,
        },
        series: [
          {
            values: [54, 63, 25, 36, 15, 64, 35, 76],
          },
        ],
      };
    }

    /**
     * Build realtime stock chart
     *
     * @param {string} title - chart title
     * @param {string} yValues - range of y values in format `min:max:step`
     * @param {Array} values - chart values
     * @return {*}
     */
    function buildStockChartRealTime(title, yValues, values) {
      var data = realTimeStockChartTemplate();

      if (yValues) {
        data['scale-y']['values'] = yValues;
      }

      if (values) {
        data['series'].shift();
        data['series'].push({ values: values });
      }

      if (title) {
        data['title'] = { text: title, color: 'white' };
      } else {
        data['plotarea'] = { margin: [-15, 25, 40, 40] };
      }

      return data;
    }

    /**
     * Build pie chart
     *
     * @return {*}
     */
    function buildPieChart() {
      return pieChartTemplate();
    }

    /**
     * Returns deviation.
     *
     * @param {*} names - ?
     * @param {*} values - ?
     * @return {{type, "background-color", "border-color", "border-width", "scale-y", "scale-x", plot, plotarea, series}}
     */
    function getDeviation(names, values) {
      var data = volatilityDeviationTemplate();
      data['scale-x']['values'] = names;
      data['series'][0]['values'] = values;
      data['series'][1]['data-rvalues'] = values;
      var max = [];
      var min = [];
      var high_bar = 5;
      var low_bar = -2;
      while (high_bar < Math.max.apply(null, values)) {
        high_bar += 5;
      }
      for (var i = 0; i < values.length; i++) {
        max.push(high_bar);
        min.push(low_bar);
      }
      data['series'][1]['values'] = max;
      data['series'][2]['values'] = min;
      return data;
    }

    /**
     * Returns stock chart template.
     *
     * @return {object}
     */
    function stockChartTemplate() {
      return {
        'background-color': '#131722',
        type: 'stock',
        utc: true,
        timezone: parseInt((btDateService.getUserTimeZoneOffset() / 60).toFixed(0)),
        'scale-x': {
          step: 'minute',
          transform: {
            type: 'date',
          },
          item: {
            'font-size': 10,
            'font-color': 'white',
          },
          'max-items': 10,
          shadow: 0,
          'line-color': 'dimgray',
          tick: {
            'line-color': 'dimgray',
          },
        },
        'scale-y': {
          format: '%v',
          item: {
            'font-size': 10,
            'font-color': 'white',
          },
          guide: {
            'line-style': 'dashed',
            'line-color': 'dimgray',
          },
          'line-color': 'dimgray',
          tick: {
            'line-color': 'dimgray',
          },
        },
        'scale-y-2': {
          format: '%v%',
          decimals: 3,
          item: {
            'font-size': 10,
            'font-color': 'white',
            rules: [
              {
                rule: '%v<0',
                'font-color': '#cf3040',
              },
              {
                rule: '%v>0',
                'font-color': '#48c064',
              },
            ],
          },
          guide: {
            'line-style': 'dashed',
            'line-color': 'dimgray',
          },
          'line-color': 'dimgray',
          tick: {
            'line-color': 'dimgray',
          },
          'ref-line': {
            visible: false,
          },
          label: {
            text: '1 minute Candlestick chart',
            'font-color': '#5f6879',
          },
        },
        'scroll-x': {},
        'crosshair-x': {
          // 'plot-label': {
          //   'text': 'O: %open, H: %high, L: %low, C: %close',
          //   // 'text': 'Open: %open<br>High: %high<br>Low: %low<br>Close: %close',
          //   //     "text": "Open: 10<br>High: 11<br>Low: 8<br>Close: 9",
          //   'decimals': 4,
          //   'multiple': true,
          //   'placement': 'node-bottom',
          //   'offset-y': -7
          // },
          'scale-label': {
            transform: {
              type: 'date',
            },
          },
        },
        'crosshair-y': {
          type: 'multiple',
          'scale-label': {
            visible: false,
          },
        },
        plot: {
          aspect: 'candlestick',
          tooltip: {
            visible: false,
          },
          'trend-up': {
            'line-color': '#48c064',
            'border-color': '#48c064',
            'background-color': '#48c064',
          },
          'trend-down': {
            'line-color': '#cf3040',
            'border-color': '#cf3040',
            'background-color': '#cf3040',
          },
        },
        series: [],
      };
    }

    /**
     * Returns correlation chart template.
     *
     * @return {object}
     */
    function correlationChartTemplate() {
      return {
        'background-color': '#2e3542',
        globals: {
          shadow: false,
          'font-family': 'Helvetica',
        },
        type: 'area',
        legend: {
          layout: 'x4',
          'background-color': 'transparent',
          'border-color': 'transparent',
          marker: {
            'border-radius': '50px',
            'border-color': 'transparent',
          },
          item: {
            'font-color': 'white',
          },
        },
        'scale-x': {
          step: 'minute',
          'max-items': 90,
          transform: {
            type: 'date',
          },
          zooming: true,
          'line-color': 'white',
          'line-width': '1px',
          tick: {
            'line-color': 'white',
            'line-width': '1px',
          },
          item: {
            'font-color': 'white',
          },
          guide: {
            visible: false,
          },
        },
        'scale-y': {
          values: '-1:1',
          'line-color': 'white',
          'line-width': '1px',
          tick: {
            'line-color': 'white',
            'line-width': '1px',
          },
          guide: {
            'line-style': 'solid',
            'line-color': '#626262',
          },
          item: {
            'font-color': 'white',
          },
        },
        tooltip: {
          visible: false,
        },
        'crosshair-x': {
          'scale-label': {
            'background-color': '#fff',
            'font-color': 'black',
          },
          'plot-label': {
            'background-color': '#434343',
            'font-color': '#FFF',
            _text: 'Number of hits : %v',
          },
        },
        plot: {
          'line-width': '2px',
          aspect: 'spline',
          marker: {
            visible: false,
          },
        },
        utc: true,
        timezone: parseInt((btDateService.getUserTimeZoneOffset() / 60).toFixed(0)),
        series: [],
      };
    }

    /**
     * Returns pie chart template.
     *
     * @return {object}
     */
    function pieChartTemplate() {
      return {
        type: 'pie',
        'background-color': '#2A2C3E',
        legend: {
          layout: 'h',
          'background-color': 'none',
          shadow: 0,
          'border-width': 0,
          'toggle-action': 'remove',
          item: {
            fontColor: 'white',
          },
          marker: {
            'border-color': 'white',
            type: 'circle',
          },
        },
        plotarea: {},
        plot: {
          'ref-angle': 270,
          'value-box': {
            placement: 'in',
            text: '%npv%',
            'font-color': '#1A1B26',
            'font-size': 16,
          },
        },
        tooltip: {
          'font-size': 12,
          'font-color': '#1A1B26',
          shadow: 0,
          'border-radius': 3,
          'border-width': 1,
          'border-color': '#fff',
        },
        series: [
          {
            values: [1],
            'background-color': '#AAAAAA',
            text: '/',
          },
          {
            values: [1],
            'background-color': '#00FF00',
            text: '+',
          },
          {
            values: [1],
            'background-color': '#FF0000',
            text: '-',
          },
        ],
      };
    }

    /**
     * @typedef {object} btInstrumentChartObject
     * @property {*} utc - ?
     */

    /**
     * Returns instrument chart template.
     *
     * @return {btInstrumentChartObject}
     */
    function instrumentChartTemplate() {
      return {
        utc: true,
        timezone: parseInt((btDateService.getUserTimeZoneOffset() / 60).toFixed(0)),
        plot: {
          tooltip: {
            visible: false,
          },
        },
        'background-color': '#1d2027',
        'scale-x': {
          'max-items': 10,
          step: 'minute',
          transform: {
            type: 'date',
            all: '%H:%i',
            uniform: 0,
          },
          item: {
            'font-size': 10,
            'font-color': 'white',
          },
          shadow: 0,
          'line-color': 'dimgray',
          tick: {
            'line-color': 'dimgray',
          },
        },
        'scale-y': {
          'offset-start': '15%',
          format: '%v',
          item: {
            'font-size': 10,
            'font-color': 'white',
          },
          guide: {
            'line-style': 'dashed',
            'line-color': 'dimgray',
          },
          'line-color': 'dimgray',
          tick: {
            'line-color': 'dimgray',
          },
        },
        'scale-y-2': {
          //for Volume Chart
          placement: 'default', //to move scale to default (left) side.
          blended: true, //to bind the scale to "scale-y".
          'offset-end': '85%', //to adjust scale offsets.
          format: '%vM',
          guide: {
            'line-style': 'dashed',
            'line-color': 'dimgray',
          },
          item: {
            'font-size': 10,
          },
        },
        plotarea: { margin: [10, 20, 40, 45] },
        series: [],
      };
    }

    /**
     * Returns real-time stock chart template.
     *
     * @return {object}
     */
    function realTimeStockChartTemplate() {
      return {
        'background-color': '#131722',
        // 'background-color': '#2e3542',
        type: 'stock',
        utc: true,
        timezone: parseInt((btDateService.getUserTimeZoneOffset() / 60).toFixed(0)),
        'scale-x': {
          //   "zooming": true,
          //  "offset-end": "1%",
          step: 'minute',
          transform: {
            type: 'date',
          },
          item: {
            'font-size': 10,
            'font-color': 'white',
          },
          'max-items': 10,
          shadow: 0,
          'line-color': 'dimgray',
          tick: {
            'line-color': 'dimgray',
          },
        },
        'scale-y': {
          values: '2.53:2.80:0.0001',
          format: '%v',
          item: {
            'font-size': 10,
            'font-color': 'white',
          },
          guide: {
            'line-style': 'dashed',
            'line-color': 'dimgray',
          },
          'line-color': 'dimgray',
          tick: {
            'line-color': 'dimgray',
          },
        },
        'scale-y-2': {
          format: '%v%',
          item: {
            'font-size': 10,
            'font-color': 'white',
          },
          guide: {
            'line-style': 'dashed',
            'line-color': 'dimgray',
          },
          'line-color': 'dimgray',
          tick: {
            'line-color': 'dimgray',
          },
          'ref-line': {
            visible: false,
          },
        },
        'scroll-x': {},
        'crosshair-x': {
          'plot-label': {
            text: 'Open: $%open<br>High: $%high<br>Low: $%low<br>Close: $%close',
            decimals: 4,
            multiple: true,
            placement: 'node-top',
            'offset-y': -7,
          },
          'scale-label': {
            transform: {
              type: 'date',
            },
          },
        },
        'crosshair-y': {
          type: 'multiple',
          'scale-label': {
            visible: false,
          },
        },

        plot: {
          'bar-width': '35px',
          aspect: 'candlestick',
          tooltip: {
            visible: false,
          },
          'trend-up': {
            'line-color': '#48c064',
            'border-color': '#48c064',
            'background-color': '#48c064',
          },
          'trend-down': {
            'line-color': '#cf3040',
            'border-color': '#cf3040',
            'background-color': '#cf3040',
          },
        },

        series: [{}],
        //"refresh":{
        //            "type":"feed",
        //            "transport":"js",
        //            "url":"feed()",
        //            "interval":5000
        //          }
      };
    }

    /**
     * Returns volatility deviation chart template.
     *
     * @return {object}
     */
    function volatilityDeviationTemplate() {
      return {
        type: 'hbar',
        'background-color': '#363b42',
        'border-color': '#111a21',
        'border-width': '2px',
        'scale-y': {
          'line-color': 'none',
          tick: {
            visible: false,
          },
          item: {
            visible: false,
          },
          guide: {
            visible: false,
          },
        },
        'scale-x': {
          values: [], //names
          'line-color': 'none',
          tick: {
            visible: false,
          },
          item: {
            width: 200,
            'text-align': 'left',
            'offset-x': 210,
            color: '#fff',
          },
          guide: {
            visible: false,
          },
        },
        plot: {
          'bars-overlap': '100%',
          'bar-width': '17px',
          'thousands-separator': ',',
          animation: {
            effect: 'ANIMATION_SLIDE_BOTTOM',
          },
        },
        plotarea: {
          margin: '10px 25px 10px 25px',
        },
        series: [
          {
            values: [], //values
            'z-index': 2,
            tooltip: {
              shadow: false,
            },
            'tooltip-text': '%node-value',
            'hover-state': {
              visible: false,
            },
          },
          {
            'max-trackers': 0,
            values: [], //max values
            'data-rvalues': [], //values
            'background-color': '#000',
            'z-index': 1,
            'value-box': {
              visible: true,
              'offset-y': '-1px',
              'offset-x': '3px',
              placement: 'top-in',
              'text-align': 'right',
              'font-color': '#fff',
              'font-size': '10px',
              text: '%data-rvalues',
            },
          },
          {
            'max-trackers': 0,
            values: [],
            'background-color': '#000',
            'z-index': 1,
          },
        ],
      };
    }

    /**
     * Prepare ZingChart Y scale markers (price)
     *
     * @param {btSnapshotMetaData} metaData - chart meta data
     * @return {Object[]} - array of y scale markers
     */
    function prepareYMarkets(metaData) {
      if (metaData && metaData.simulation) {
        var data = metaData.simulation;
        return [
          prepareMarker(data.buy.entry.price || data.sell.entry.price, 'Entry', 'gray', false, false),
          prepareMarker(data.buy.target.price, 'Buy target', 'green', true, false),
          prepareMarker(data.buy.stop.price, 'Buy stop', 'red', false, false),
          prepareMarker(data.sell.target.price, 'Sell target', 'green', true, true),
          prepareMarker(data.sell.stop.price, 'Sell stop', 'red', false, true),
        ];
      } else {
        return [];
      }
    }

    /**
     * Prepare ZingChart X scale markers (time)
     *
     * @param {btSnapshotMetaData} metaData - chart meta data
     * @return {Object[]} - array of x scale markers
     */
    function prepareXMarkets(metaData) {
      if (metaData && metaData.simulation) {
        var markers = [];

        // for buy and sell sides
        ['buy', 'sell'].forEach(function (t) {
          /** @type {btTradeSimulationMetaData} */
          var data = metaData.simulation[t];
          var title = t === 'buy' ? 'Buy' : 'Sell';

          // profit target
          if (data.target.time !== -1)
            markers.push(prepareMarker(data.target.time, title + ' target', 'green', t === 'buy', false));

          // stop loss
          if (data.stop.time !== -1)
            markers.push(prepareMarker(data.stop.time, title + ' stop', 'red', t === 'buy', false));

          // skip weak trades
          if (data.status !== 'weak') {
            // max reward
            if (data.reward.time !== -1)
              markers.push(prepareMarker(data.reward.time, title + ' max reward', 'green', t === 'buy', false));

            // risk
            if (data.risk.time !== -1)
              markers.push(prepareMarker(data.risk.time, title + ' risk', 'green', t === 'buy', false));
          }
        });

        return markers;
      } else {
        return [];
      }
    }

    /**
     * Prepare ZingChart marker object (type == line)
     *
     * @see {@link https://www.zingchart.com/docs/api/json-configuration/graphset/scale-x/markers/|ZingChart markers}
     * @param {Number} value - line marker value
     * @param {String} text - text of market label
     * @param {String} color - line color
     * @param {Boolean} placement - label placement (true == "opposite" - bottom | false == "normal" - top)
     * @param {Boolean} alignment - label alignment (true == "opposite" - right | false == "normal" - left)
     * @param {String} [style] - line style (default dotted)
     * @param {Number} [width] - line width (default 1)
     * @return {zcMarkerObject}
     */
    function prepareMarker(value, text, color, placement, alignment, style, width) {
      style = style === undefined ? 'dotted' : style;
      width = width === undefined ? 1 : width;
      var marker = {
        type: 'line',
        range: [value],
        valueRange: true,
        lineColor: color,
        lineWidth: width,
        lineStyle: style,
        alpha: 1,
      };

      if (text) {
        marker.labelPlacement = placement ? 'opposite' : 'normal';
        marker.labelAlignment = alignment ? 'opposite' : 'normal';
        marker.label = { text: text, color: 'white' };
      }
      return marker;
    }

    /**
     * @typedef {Object} btSyntheticChartOptions
     * @property {{targets: Number[], stops: Number[]}} long -
     * @property {{targets: Number[], stops: Number[]}} short -
     */

    /**
     * Get synthetic chart template
     *
     * @param {btSyntheticChartOptions} options
     * @return {ext.IZingChartJSON}
     */
    function getSyntheticChartTemplate(options) {
      var yMarkers = [
        prepareMarker(
          options.long.targets ? options.long.targets[0] : 8.0,
          'Long Target 1',
          'green',
          true,
          false,
          'dashed',
          2
        ),
        prepareMarker(
          options.long.targets ? options.long.targets[1] : 8.0,
          'Long Target 2',
          'green',
          true,
          false,
          'dashed',
          2
        ),
        prepareMarker(options.long.stops ? options.long.stops[0] : -7.7, 'Long Stop', 'red', true, false, 'dashed', 2),
        prepareMarker(
          options.long.stops ? options.long.stops[1] : -7.7,
          'Long Stop (Win)',
          'red',
          true,
          false,
          'dashed',
          2
        ),
        prepareMarker(options.short.stops ? options.short.stops[0] : 7.7, 'Short Stop', 'red', true, true, 'dashed', 2),
        prepareMarker(
          options.short.stops ? options.short.stops[1] : 7.7,
          'Short Stop (Win)',
          'red',
          true,
          true,
          'dashed',
          2
        ),
        prepareMarker(
          options.short.targets ? options.short.targets[0] : -8.0,
          'Short Target 1',
          'green',
          true,
          true,
          'dashed',
          2
        ),
        prepareMarker(
          options.short.targets ? options.short.targets[1] : -8.0,
          'Short Target 2',
          'green',
          true,
          true,
          'dashed',
          2
        ),
        prepareMarker(0, null, 'dimgray', true, true, 'solid', 2),
      ];

      return {
        // 'background-color': '#2e3542',
        'background-color': '#131722',
        type: 'line',
        legend: {
          visible: false,
          alpha: 0.2,
          width: '399px',
          'border-radius': '2px',
          draggable: true,
          header: {
            'text-align': 'center',
            text: 'click here and drag legend',
            cursor: 'hand',
            height: '12px',
            'font-family': 'Arial',
            'font-color': 'lightgray',
            'font-size': '12px',
          },
          tooltip: {
            text: '%plot-description',
            'border-radius': '2px',
            'background-color': 'white',
          },
          layout: 'horizontal',
          'border-width': 0,
          shadow: false,
          marker: {
            cursor: 'hand',
            'border-width': 1,
          },
          'background-color': 'white',
          item: {
            'font-color': 'lightgray ',
            cursor: 'hand',
          },
          'toggle-action': 'remove',
          x: '50px',
          y: '10px',
          'max-items': 4,
          overflow: 'page',
          'page-status': {
            'font-color': 'lightgray',
            height: '10px',
          },
        },
        scaleX: {
          label: {
            text: 'Minutes after the release',
            'offset-y': '-42px',
            color: 'white',
            'text-align': 'right',
          },
          item: {
            'font-size': 10,
            'font-color': 'white',
          },
          guide: {
            'line-style': 'dashed',
            'line-color': 'dimgray',
          },
          'line-color': 'dimgray',
          tick: {
            'line-color': 'dimgray',
          },
          tooltip: {
            text: '%v minutes after release',
            'border-radius': '2px',
            'background-color': 'white',
          },
          values: '-5:90',
          markers: [
            {
              type: 'line',
              range: [0.0],
              valueRange: true,
              lineColor: 'gray',
              lineWidth: 1,
              lineStyle: 'dotted',
              alpha: 1,
              labelPlacement: 'normal',
              labelAlignment: 'opposite',
              label: {
                text: 'Trade Entry',
                color: 'white',
              },
            },
          ],
        },
        scaleY: {
          label: {
            text: 'Price delta in ATR (Average True Range)',
            'offset-x': '8px',
            color: 'white',
          },
          item: {
            'font-size': 10,
            'font-color': 'white',
          },
          guide: {
            'line-style': 'dashed',
            'line-color': 'dimgray',
            alpha: 0.4,
          },
          'line-color': 'dimgray',
          tick: {
            'line-color': 'dimgray',
          },
          tooltip: {
            text: 'Change of price in ATR: %v',
            'border-radius': '2px',
            'background-color': 'white',
          },
          'ref-line': {
            visible: false,
          },
          markers: yMarkers,
        },
        tooltip: {
          text: '%plot-description: %v ATR in %k minutes \n Click to highlight',
          decimals: '2',
        },
        plot: {
          'line-width': 1,
          marker: {
            visible: false,
            size: 4,
            'border-width': 0,
          },
          'selection-mode': 'graph',
          'background-state': {
            'line-width': 2,
          },
          'selected-state': {
            'line-width': 2,
          },
          highlight: true,
          'highlight-state': {
            'line-width': 2,
          },
          cursor: 'hand',
        },
        plotarea: {
          margin: '10px 10px 24px 36px',
        },
        series: [],
      };
    }

    /**
     * Update Y markers
     *
     * @param {ext.IZingChartJSON} json - chart json data
     * @param {String} side - trade side id
     */
    function updateSyntheticChartYMarkers(json, side) {
      if (side === 'any') {
        json.plot['data-side'] = 0;
        json.scaleY.markers[0].visible = false;
        json.scaleY.markers[1].visible = false;
        json.scaleY.markers[2].visible = false;
        json.scaleY.markers[3].visible = false;

        json.scaleY.markers[4].visible = false;
        json.scaleY.markers[5].visible = false;
        json.scaleY.markers[6].visible = false;
        json.scaleY.markers[7].visible = false;
      }

      if (side === 'buy') {
        json.plot['data-side'] = 1;
        json.scaleY.markers[0].visible = true;
        json.scaleY.markers[1].visible = true;
        json.scaleY.markers[2].visible = true;
        json.scaleY.markers[3].visible = true;

        json.scaleY.markers[4].visible = false;
        json.scaleY.markers[5].visible = false;
        json.scaleY.markers[6].visible = false;
        json.scaleY.markers[7].visible = false;
      }

      if (side === 'sell') {
        json.plot['data-side'] = 2;
        json.scaleY.markers[0].visible = false;
        json.scaleY.markers[1].visible = false;
        json.scaleY.markers[2].visible = false;
        json.scaleY.markers[3].visible = false;

        json.scaleY.markers[4].visible = true;
        json.scaleY.markers[5].visible = true;
        json.scaleY.markers[6].visible = true;
        json.scaleY.markers[7].visible = true;
      }
    }

    /**
     *
     * @param {*} chartJson - ?
     */
    function updateSyntheticChartScaleValues(chartJson) {
      try {
        chartJson.scaleX.values =
          '-5:' +
          Math.max.apply(
            null,
            chartJson.series.map(function (serie) {
              return serie.values.length ? serie.values[serie.values.length - 1][0] : 0;
            })
          );
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * Prepare synthetic chart series
     *
     * @param {*} event - ?
     * @param {*} chart - ?
     * @param {*} trade - ?
     * @param {*} i - ?
     * @param {*} n - ?
     * @param {*} params - ?
     * @return {{visible: boolean, values: (Array|*[][]), text: *, description: string, marker: {"background-color": string, rules: *}, "line-color": string}}
     */
    function prepareSyntheticChartSeries(event, chart, trade, i, n, params) {
      var values, text, description, rules;
      var date = btDateService.format(btDateService.getDateFromRow(event).toDateString(), 'DD/MM/YY');
      if (chart && chart.series && trade && trade.buy) {
        var atr = trade.buy.atr.value;
        var entryPrice = trade.buy.entry.price;
        var entryTime = trade.buy.entry.time;

        var peaks = getPeaks(trade);

        console.log(trade.buy.target.atr, trade.buy.stop.atr);
        console.log(trade.sell.target.atr, trade.sell.stop.atr);

        values = chart.series[0].values.map(function (value) {
          var minutes = getMinutes(value[0], entryTime);
          return [minutes, normalizePrice(minutes, value[1][0], value[1][1], value[1][2], entryPrice, atr)];
        });
        text = date;
        // description = 'Trade #' + (i + 1) + ' - ' + date + ' - ' +
        //   'buy:' + trade.buy.status + getTargetStop(peaks[0], peaks[1], entryTime) + ', ' +
        //   'sell:' + trade.sell.status + getTargetStop(peaks[2], peaks[3], entryTime);
        rules = getRules(peaks, entryTime);
      } else {
        values = [];
        text = date;
        // description = 'Trade #' + (i + 1) + ' - ' + date;
        rules = [];
      }

      if (event.strength) {
        description = '(' + event.strength.fullMsg + ') Trade #' + (i + 1) + ' at ' + date;
      } else {
        description = 'Trade #' + (i + 1) + ' at ' + date;
      }

      return {
        visible: true,
        values: values,
        text: text,
        description: description,
        marker: {
          'background-color':
            params.strength === 'show' ? getStrengthColor(event.strength) : getColor(i, n, trade, params),
          rules: rules,
        },
        'line-color': params.strength === 'show' ? getStrengthColor(event.strength) : getColor(i, n, trade, params),
        'line-style': params.strength === 'show' ? getStrengthStyle(event.strength) : 'solid',
      };

      /**
       *
       * @return {*}
       */
      function getPeaks() {
        var buyTargetTime = trade.buy.target.time;
        var buyStopTime = trade.buy.stop.time;
        var sellTargetTime = trade.sell.target.time;
        var sellStopTime = trade.sell.stop.time;
        return [
          { time: buyTargetTime, type: 'target', side: 'long' },
          { time: buyStopTime, type: 'stop', side: 'long' },
          { time: sellTargetTime, type: 'target', side: 'short' },
          { time: sellStopTime, type: 'stop', side: 'short' },
        ];
      }

      // function getTargetStop(target, stop, entryTime) {
      //   var t = (target.time === -1) ? 'NA' : getMinutes(target.time, entryTime);
      //   var s = (stop.time === -1) ? 'NA' : getMinutes(stop.time, entryTime);
      //   return '(target in ' + t + ' mins, stop in ' + s + ' mins)';
      // }

      /**
       * Get number of minutes since entry
       *
       * @param {Number} time - timestamp in seconds
       * @param {Number} entry - timestamp in seconds
       * @return {Number}
       */
      function getMinutes(time, entry) {
        return (time - entry) / 1000 / 60;
      }

      /**
       *
       * @param {*} values - ?
       * @param {*} entryTime - ?
       * @return {*}
       */
      function getRules(values, entryTime) {
        return values
          .filter(function (item) {
            return item.time !== -1;
          })
          .map(function (item) {
            return {
              rule: '%k == ' + getMinutes(item.time, entryTime) + ' && %data-side == ' + (item.side === 'long' ? 1 : 2),
              visible: true,
              type: item.type === 'target' ? 'circle' : 'triangle',
            };
          });
      }

      /**
       * Convert candle to one price normalized to ATR
       *
       * @param {Number} minutes - number of minutes since entry
       * @param {Number} open - open price
       * @param {Number} high - high price
       * @param {Number} low - low price
       * @param {Number} base - base price
       * @param {Number} atr - atr value
       * @return {Number}
       */
      function normalizePrice(minutes, open, high, low, base, atr) {
        // Return entry price for entry
        if (minutes === 0) {
          return (open - base) / atr;
        }

        // Return high for candles fully above base price
        if (high <= base && low <= base) {
          return (low - base) / atr;
        }

        // Return low for candles fully under base price
        if (high >= base && low >= base) {
          return (high - base) / atr;
        }

        // Return high for candles more above base price
        if (high - base > base - low) {
          return (high - base) / atr;
        }

        // Return low for candles more under base price
        if (high - base < base - low) {
          return (low - base) / atr;
        }

        // By default return open
        return (open - base) / atr;
      }
    }

    /**
     * Show synthetic chart options
     *
     * @param {String} id
     * @param {Object} option
     * @param {Array} trades
     * @return {Boolean}
     */
    // eslint-disable-next-line no-unused-vars
    function showSyntheticChartOptions(id, option, trades) {
      if (window.zingchart) {
        processSeries(id, option, trades, displayPlot);
        return true;
      } else {
        return false;
      }
    }

    /**
     * Show synthetic chart options
     *
     * @param {String} id
     * @param {Object} option
     * @param {Array} trades
     * @param {Object} json
     * @return {Boolean}
     */
    function showSyntheticChartOptionsJSON(id, option, trades, json) {
      if (window.zingchart) {
        processSeries(id, option, trades, displayPlotJSON.bind(null, json));
        return true;
      } else {
        return false;
      }
    }

    /**
     * Counts synthetic chart options.
     *
     * @param {String} id
     * @param {Object} option
     * @param {Array} trades
     * @return {*}
     */
    function countSyntheticChartOptions(id, option, trades) {
      if (window.zingchart) {
        var count = 0;

        processSeries(id, option, trades, function (id, type, i) {
          void id;
          void i;
          if (type === 'show') count++;
        });

        return count;
      } else {
        return 0;
      }
    }

    /**
     * Show series. Return number of charts shown
     *
     * @param {String} id
     * @param {Object} option
     * @param {Array} trades
     * @param {function} callback
     */
    function processSeries(id, option, trades, callback) {
      trades.forEach(function (trade, i) {
        if (option.status === 'all') {
          callback(id, 'show', i);
        }

        if ((option.side === 'buy' || option.side === 'sell') && trade[option.side]) {
          if (option.status === 'win-loss') {
            if (trade[option.side] && (trade[option.side].status === 'win' || trade[option.side].status === 'loss')) {
              callback(id, 'show', i);
            } else {
              callback(id, 'hide', i);
            }
          }
          if (option.status === 'win') {
            if (trade[option.side] && trade[option.side].status === 'win') {
              callback(id, 'show', i);
            } else {
              callback(id, 'hide', i);
            }
          }
          if (option.status === 'loss') {
            if (trade[option.side] && trade[option.side].status === 'loss') {
              callback(id, 'show', i);
            } else {
              callback(id, 'hide', i);
            }
          }
          if (option.status === 'weak') {
            if (trade[option.side] && trade[option.side].status === 'weak') {
              callback(id, 'show', i);
            } else {
              callback(id, 'hide', i);
            }
          }
        }
      });
    }

    /**
     * This function displays zingchart plot.
     *
     * @param {String} id - chart id
     * @param {String} type - action type: show or hide
     * @param {Number} i - plot index
     */
    function displayPlot(id, type, i) {
      if (type === 'show') {
        window.zingchart.exec(id, 'showplot', { plotindex: i });
      } else {
        window.zingchart.exec(id, 'hideplot', { plotindex: i });
      }
    }

    /**
     * This function updates trade markers and execute `zingchart.exec`.
     * @param {String} chartName - chart name
     * @param {Object} chartData - chart data
     * @param {String} type - trade side: any, buy, sell
     * @param {Boolean} needExecution - indicate whether update is required
     */
    function updateTradeMarkers(chartName, chartData, type, needExecution) {
      if (type === 'any') {
        setMarkerVisibility(chartData, 1, true);
        setMarkerVisibility(chartData, 2, true);
        setMarkerVisibility(chartData, 3, true);
        setMarkerVisibility(chartData, 4, true);
      }

      if (type === 'buy') {
        setMarkerVisibility(chartData, 1, true);
        setMarkerVisibility(chartData, 2, true);
        setMarkerVisibility(chartData, 3, false);
        setMarkerVisibility(chartData, 4, false);
      }

      if (type === 'sell') {
        setMarkerVisibility(chartData, 1, false);
        setMarkerVisibility(chartData, 2, false);
        setMarkerVisibility(chartData, 3, true);
        setMarkerVisibility(chartData, 4, true);
      }

      if (needExecution) {
        zingchart.exec(chartName, 'setdata', {
          data: chartData,
        });
      }
    }

    /**
     *
     * @param {*} chartData - ?
     * @param {*} i - ?
     * @param {*} value - ?
     */
    function setMarkerVisibility(chartData, i, value) {
      if (chartData['scale-y'] && chartData['scale-y']['markers']) {
        chartData['scale-y']['markers'][i].visible = value;
      }
    }

    /**
     * Display plot json
     * @param {Object} json
     * @param {String} id
     * @param {String} type
     * @param {Number} i
     */
    function displayPlotJSON(json, id, type, i) {
      json.series[i].visible = type === 'show';
    }

    /**
     * This function return line color.
     *
     * @param {number} i - line index
     * @param {number} n - total number of lines
     * @param {object} trade - information about a trade
     * @param {object} option - option
     * @return {string} - color
     */
    function getColor(i, n, trade, option) {
      //return gColors[parseInt(i / n * 20)];
      console.log('getColors', trade, option);
      var status = trade[option.side] && option.side !== 'any' ? trade[option.side].status : 'any';
      return gColors[status][Math.floor((i / n) * 20)];
    }

    /*
          if (typeof strength === 'number') {
        if (strength === 0) return 'as-expected';
        if (strength > 0) {
          if (strength > 1) return 'extremely-stronger';
          if (strength > 0.6) return 'much-stronger';
          if (strength > 0.2) return 'stronger';
          return 'a-bit-stronger';
        }
        if (strength < 0) {
          if (strength < -1) return 'extremely-weaker';
          if (strength < -0.6) return 'much-weaker';
          if (strength < -0.2) return 'weaker';
          return 'a-bit-weaker';
        }
      } else {
        return 'any';
      }
     */
    /**
     * This function return a color based on release strength.
     *
     * @param {BTStrength} strength - release strength
     * @return {string} - color
     */
    function getStrengthColor(strength) {
      switch (strength.magnitude) {
        case 'extremely-stronger':
          return '#4DC068';
        case 'much-stronger':
          return '#44a65b';
        case 'stronger':
          return '#3a8c4d';
        case 'a-bit-stronger':
          return '#2f733f';
        case 'as-expected':
          return '#B39A47';
        case 'a-bit-weaker':
          return '#801c2b';
        case 'weaker':
          return '#992234';
        case 'much-weaker':
          return '#b3273c';
        case 'extremely-weaker':
          return '#CD2C43';
        default:
          return '#555e67';
      }
    }

    /**
     * This function return a color based on release strength.
     *
     * @param {BTStrength} strength - release strength
     * @return {string} - color
     */
    function getStrengthStyle(strength) {
      switch (strength.power) {
        case 'extra':
          return 'solid';
        case 'large':
          return 'dashed';
        case 'medium':
          return 'dashed';
        case 'small':
        case 'none':
        default:
          return 'dotted';
      }
    }
  }
})();
