/**
 * Created by Sergey Panpurin on 2/8/2018.
 */

// @ts-check
(function btCalendarServiceClosure() {
  'use strict';

  var gDebug = false;

  angular.module('ecapp').factory('btCalendarService', btCalendarService);

  btCalendarService.$inject = [
    '$q',
    '$rootScope',
    '$window',
    '$interval',
    '$timeout',
    'btToastrService',
    'btDateService',
    'btReleasesService',
    'btInsightsService',
    'btTradeIdeasService',
    'btPusherService',
    'btShareScopeService',
    'btBadgeService',
    'btEventEmitterService',
    'btReleaseTestingService',
    'btWatchListService',
    '$state',
    'btVoiceAssistantHelperService',
    'btDevService',
    'btRestrictionService',
    'btInstrumentsService',
    'btEventsService',
    'btSettingsService',
    'btTradeIdeasFiltersService',
  ];

  /**
   * @ngdoc service
   * @name btCalendarService
   * @memberOf ecapp
   * @description
   *  This service implements calendar shared object.
   *  I can be separated in three services btCalendarObject, btCalendarRefresher, btCalendarLauncher.
   *  Calendar load filter:
   *   - days.backward
   *   - days.forward
   *   - days.offset
   *  Calendar show filter:
   *   - events.priority
   *   - events.currencies
   *   - events.followed
   *   - watchlist
   * @param {angular.IQService} $q
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.IWindowService} $window
   * @param {angular.IIntervalService} $interval
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IReleasesService} btReleasesService
   * @param {ecapp.IInsightsService} btInsightsService
   * @param {ecapp.ITradeIdeasService} btTradeIdeasService
   * @param {ecapp.IPusherService} btPusherService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IBadgeService} btBadgeService
   * @param {ecapp.IEventEmitterService} btEventEmitterService
   * @param {ecapp.IReleaseTestingService} btReleaseTestingService
   * @param {ecapp.IWatchListService} btWatchListService
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.IVoiceAssistantHelperService} btVoiceAssistantHelperService
   * @param {ecapp.IDevService} btDevService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.IEventsService} btEventsService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.ITradeIdeasFiltersService} btTradeIdeasFiltersService
   * @return {ecapp.ICalendarService}
   */
  function btCalendarService(
    $q,
    $rootScope,
    $window,
    $interval,
    $timeout,
    btToastrService,
    btDateService,
    btReleasesService,
    btInsightsService,
    btTradeIdeasService,
    btPusherService,
    btShareScopeService,
    btBadgeService,
    btEventEmitterService,
    btReleaseTestingService,
    btWatchListService,
    $state,
    btVoiceAssistantHelperService,
    btDevService,
    btRestrictionService,
    btInstrumentsService,
    btEventsService,
    btSettingsService,
    btTradeIdeasFiltersService
  ) {
    console.log('Running btCalendarService');

    /**
     * Global object for calendar.
     * @type {btCalendarData}
     */
    var gCalendar = {
      cards: [],
      status: 'empty', // empty, loading, ready
      nextIndex: 0,
      error: null,
      from: null,
      till: null,
    };

    var gCalendarPendingList = [];

    /**
     * Upcoming events for different time intervals in minutes.
     * @type {Record<number, btRelease[]>}
     */
    var gUpcomingEvents = {};

    var gLocalSettings = btSettingsService.getLocalSettings();

    var gSettings = {
      from: null,
      till: null,
      watchlist: gLocalSettings.get('calendar.filters.watchlist'),
    };

    var gPusherBound = false;

    /**
     * Cache for release information from database
     * @type {btRawRelease[]}
     */
    var gReleases = [];

    var gReminders = {
      5: {},
      3: {},
      1: {},
    };

    // initialize array of releases indices
    var gReleaseIndices = null;
    var gLastDataUpdate = new Date(0);

    // time of last calling of interval functions
    var gLastClock = { time: null };
    var gLastConvert = { time: null };

    // noinspection UnnecessaryLocalVariableJS
    var gPusherLog = {};
    window.btPusherLog = gPusherLog;

    var gActivityInterval = null;
    var gAutoUpdateInterval = null;

    var gDelays = {
      check: 5000,
      update: 30000,
      notify: 6000,
      activity: 20000,
    };

    var gTesting = {};

    // equal to true if settings were changed
    $rootScope.dataChanged = false;

    // equal to true if data is out of date (see activeTimer)
    $rootScope.outOfDate = false;

    if ($rootScope.debug === undefined) {
      $rootScope.debug = {};
    }

    $rootScope.debug.convertCount = 0;
    $rootScope.debug.delays = { data: [], min: 0, max: 0, avg: 0 };

    if (btShareScopeService.isLoginSuccess()) {
      activate();
    }

    btEventEmitterService.addListener('login:success', onLoginSuccess);

    btEventEmitterService.addListener('logout:success', onLogoutSuccess);

    // renew data then try to open calendar and dataChanged is true
    $rootScope.$on('$stateChangeStart', onStateChange);

    // renew data on click to timer
    $rootScope.$on('renewECData', onRenewCalendarData);

    $rootScope.$on('broker:connected', onBrokerConnected);

    $rootScope.$on('broker:disconnected', onBrokerDisconnected);
    $rootScope.$on('calendar:follow-event', onFollowEvent);

    return {
      getCalendar: getCalendar,
      refreshCalendar: refreshCalendar,
      toggleWatchListFilter: toggleWatchListFilter,
      getSettings: getSettings,
      getReleaseStateParams: getReleaseStateParams,
      getInstrumentCalendar: getInstrumentCalendar,
      getUpcomingEvents: getUpcomingEvents,
    };

    /**
     *
     */
    function activate() {
      getCalendar()
        .then(btTradeIdeasFiltersService.loadUserSettings)
        .then(function () {
          _startActivityManager(gDelays.check);
          _bindPusher();
        });
    }

    /**
     *
     */
    function onLoginSuccess() {
      try {
        activate();
      } catch (e) {
        console.error(e);
      }
    }

    /**
     *
     */
    function onLogoutSuccess() {
      try {
        btDevService.alert('btCalendar: on logout:success');
        gCalendar.status = 'empty';

        _unbindPusher();
        _stopRealtimeTesting();
        _stopActivityManager();
        _stopAutoRefresh();
        clearUpcomingEvents();
      } catch (e) {
        console.error(e);
      }
    }

    /**
     *
     * @param {*} event
     * @param {*} next
     * @param {*} nextParams
     * @param {*} prev
     * @param {*} prevParams
     * @param {*} options
     */
    function onStateChange(event, next, nextParams, prev, prevParams, options) {
      void event;
      void nextParams;
      void prevParams;
      void options;

      /**
       *
       * @param {*} prev
       * @param {*} next
       * @return {any}
       */
      function leaveSettingsPage(prev, next) {
        return (
          (prev.name === 'ecapp.app.calendar-settings.currencies' ||
            prev.name === 'ecapp.app.calendar-settings.priority') &&
          next.name !== 'ecapp.app.calendar-settings.currencies' &&
          next.name !== 'ecapp.app.calendar-settings.priority'
        );
      }

      /**
       *
       * @param {*} prev
       * @param {*} next
       * @return {any}
       */
      function leaveDevSettingsPage(prev, next) {
        void next;
        return prev.name === 'ecapp.app.dev';
      }

      /**
       *
       * @param {*} prev
       * @param {*} next
       * @return {any}
       */
      function leavePaymentPage(prev, next) {
        void next;
        return prev.name === 'ecapp.app.payments';
      }

      /**
       *
       * @param {*} prev
       * @param {*} next
       * @return {any}
       */
      function leaveTutorialPage(prev, next) {
        void next;
        return prev.name === 'ecapp.user.tutorial';
      }

      if (
        leaveSettingsPage(prev, next) ||
        leaveDevSettingsPage(prev, next) ||
        leavePaymentPage(prev, next) ||
        leaveTutorialPage(prev, next)
      ) {
        if ($rootScope.dataChanged) {
          $rootScope.$broadcast('calendar:changed');
          refreshCalendar();
        }
      }
    }

    /**
     *
     */
    function onRenewCalendarData() {
      $timeout(refreshCalendar, 0, false);
    }

    /**
     *
     */
    function onBrokerConnected() {}

    /**
     *
     */
    function onBrokerDisconnected() {}

    /**
     *
     * @param {*} e
     * @param {*} eventId
     * @param {*} state
     */
    function onFollowEvent(e, eventId, state) {
      void e;
      gCalendar.cards.forEach(function (release) {
        if (release.eventId && release.eventId === eventId) {
          release.isFollowing = state;
        }
      });
    }

    /**
     * This function gets economic calendar.
     *
     * @return {angular.IPromise<btCalendarData>}
     */
    function getCalendar() {
      if (gCalendar.status === 'empty') {
        gCalendar.status = 'loading';
        var started;

        return btShareScopeService
          .wait()
          .then(function () {
            started = Date.now();
          })
          .then(btReleasesService.getUserReleases)
          .then(_prepareCalendar)
          .then(function resetRootScope(calendar) {
            if (window.isDevMode) {
              btToastrService.info('App was loaded in ' + (Date.now() - window.btStartedAt) + ' ms.', 'Debugging');
              btToastrService.info('Calendar was loaded in ' + (Date.now() - started) + ' ms.', 'Debugging');
            }

            $rootScope.dataChanged = false;
            $rootScope.outOfDate = false;
            return calendar;
          });
      } else if (gCalendar.status === 'ready') {
        return $q.resolve(gCalendar);
      } else {
        /** @type {Q.Deferred<*>} */
        var deferred = $q.defer();
        gCalendarPendingList.push(deferred);
        return deferred.promise;
      }
    }

    /**
     * Toggle calendar settings parameter: show just event with trade idea connected to user watchlist
     *
     * @return {Boolean} current value
     */
    function toggleWatchListFilter() {
      if (gSettings.watchlist === false && !btRestrictionService.hasFeature('trade-ideas')) {
        // User try to turn on the feature, but he don't have permission
        btRestrictionService.showUpgradePopup('calendar-smart-filtering');
      } else {
        // console.log('Calendar Filter: show just watchlist', !gSettings.watchlist);
        gSettings.watchlist = !gSettings.watchlist;
        gLocalSettings.set('calendar.filters.watchlist', gSettings.watchlist);
        $timeout(function () {
          _updateCalendar();
        });
        return gSettings.watchlist;
      }
    }

    /**
     * Get calendar settings.
     *
     * @return {{from: null, till: null, watchlist: boolean}}
     */
    function getSettings() {
      return gSettings;
    }

    /**
     * Prepare calendar.
     *
     * @param {btRawRelease[]} releases
     * @return {btCalendarData}
     * @private
     */
    function _prepareCalendar(releases) {
      // console.log('TEST: releases', releases.length);

      var range = btDateService.getDayRange(24, 48);
      gSettings.from = range[0];
      gSettings.till = range[1];

      gReleases = _addRealTimeTesting(releases);
      gReleaseIndices = _saveReviewIndices(gReleases);

      gReleases.forEach(_addInsightsDisplaySymbolForRelease);
      gReleases.forEach(_sortReleaseInsights);

      gLastDataUpdate = btDateService.getNowDate().getTime() / 1000;

      _updateCalendar();

      _startAutoRefresh();

      gCalendarPendingList.forEach(function (deferred) {
        deferred.resolve(gCalendar);
      });

      gCalendarPendingList = [];

      return gCalendar;
    }

    /**
     *
     * @param {*} release
     * @private
     */
    function _sortReleaseInsights(release) {
      if (release.tradingInsights) {
        release.tradingInsights.sort(_sortReleaseTradingInsights);
      }

      if (release.expectedTradingInsights) {
        release.expectedTradingInsights.sort(_sortReleaseExpectedTradingInsights);
      }
    }

    /**
     *
     * @param {*} a
     * @param {*} b
     * @return {number}
     * @private
     */
    function _sortReleaseTradingInsights(a, b) {
      return a.symbol.localeCompare(b.symbol);
    }

    /**
     *
     * @param {*} a
     * @param {*} b
     * @return {number}
     * @private
     */
    function _sortReleaseExpectedTradingInsights(a, b) {
      return a.symbol.localeCompare(b.symbol);
    }

    /**
     *
     * @param {*} release
     * @private
     */
    function _addInsightsDisplaySymbolForRelease(release) {
      if (release.tradingInsights) {
        release.tradingInsights.forEach(function (insight) {
          if (insight.symbol === undefined) {
            var instrument = btInstrumentsService.getInstrumentBySomeSymbol(insight.market);
            insight.symbol = instrument ? instrument.displayName : insight.market;
          }
        });
      }

      if (release.expectedTradingInsights) {
        release.expectedTradingInsights.forEach(function (insight) {
          if (insight.symbol === undefined) {
            var instrument = btInstrumentsService.getInstrumentBySomeSymbol(insight.market);
            insight.symbol = instrument ? instrument.displayName : insight.market;
          }
        });
      }
    }

    /**
     * Filter releases according to user settings
     *
     * @param {btRawRelease[]} releases - releases
     * @return {btRelease[]}
     * @private
     */
    function _filterCalendar(releases) {
      releases.forEach(btReleasesService.resetFilter);

      // noinspection JSValidateTypes
      /** @type {btRelease[]} */
      var lReleases = releases;

      var priority = btShareScopeService.getAccountInfoField('priority');
      var currencies = btShareScopeService.getAccountInfoField('currency');
      var following = btShareScopeService.getListFollowedEvents();

      if (gSettings.watchlist) {
        var symbols = btWatchListService.getUserWatchlist().map(function (instrument) {
          return instrument.OandaSymbol + ':OANDA';
        });

        return lReleases.filter(function (release) {
          release.isFollowing = following.indexOf(release.eventId) !== -1;
          return (
            (release.isFollowing || btReleasesService.regularFilter(priority, currencies, following, release)) &&
            btReleasesService.watchlistFilter(symbols, release)
          );
        });
      } else {
        return lReleases.filter(function (release) {
          release.isFollowing = following.indexOf(release.eventId) !== -1;
          return release.isFollowing || btReleasesService.regularFilter(priority, currencies, following, release);
        });
      }
    }

    /**
     * Wrapper for pusherHandler
     * @param {Object} data - pusher data
     * @private
     */
    function _handleTradingInsights(data) {
      try {
        _pusherHandler(data, 'TradingInsights', btInsightsService.handleInsights);
        btBadgeService.handlePush();
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * Wrapper for pusherHandler
     * @param {Object} data - pusher data
     * @private
     */
    function _handleInsights(data) {
      try {
        _pusherHandler(data, 'Insights', btInsightsService.handleInsights);
        btBadgeService.handlePush();
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * Wrapper for pusherHandler
     * @param {Object} data - pusher data
     * @private
     */
    function _handleRows(data) {
      try {
        _pusherHandler(data, 'Rows', btReleasesService.handleRows);
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * Handle pusher message
     * @param {Object} data - pusher data
     * @param {String} name - type of data
     * @param {Function} callback - callback function to process data
     * @private
     */
    function _pusherHandler(data, name, callback) {
      // console.log('Pusher: New row', data);
      $rootScope.debug['pusher' + name + 'Log'].push(btDateService.getNowDate().toLocaleString());

      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          // changing the source variable
          data[key]['source'] = 'pusher ';
          var releaseId = data[key]['_id'];

          // now for testing
          if (gReleaseIndices === null) {
            console.log("!!!! Can't find release indices !!!!");
            return;
          }

          // find index of release in array
          var i = gReleaseIndices[releaseId];
          if (i !== undefined) {
            if (gPusherLog[releaseId] === undefined) gPusherLog[releaseId] = {};
            gPusherLog[releaseId][name] = btDateService.getNowDate().toLocaleString();

            _showReleaseNotification(name, data[key], gReleases[i]);

            _showInsightNotification(name, data[key], gReleases[i]);

            callback(data[key], gReleases[i]);

            $rootScope.$broadcast('calendar:new-release', gReleases[i]);

            _showTradeIdeasNotification(gReleases[i], _parseUserTradeIdeas(data[key], gReleases[i]));
          } else {
            console.log('!!!! New row !!!!');
          }
        }
      }
    }

    /**
     * Show notification about released data
     * @param {String} type - type of pusher data
     * @param {Object} data - pusher data
     * @param {btRawRelease} release - release data
     * @private
     */
    function _showReleaseNotification(type, data, release) {
      if (type !== 'Rows') return;

      var followed = btShareScopeService.getListFollowedEvents();

      if (!(btReleasesService.hasNewActual(data, release) && _isNotificationNeeded(release, followed))) return;

      var magnitude = _getMagnitude(data, release);
      var msg = _getNotificationMessage(type, data, release, magnitude);
      var title = release.eventsInfo.currency + ' - ' + release.eventsInfo.name;

      var params = {
        timeOut: gDelays.notify,
        closeButton: true,
        type: 'calendar',
        onTap: _openReleasePage.bind(null, release),
      };

      btToastrService.info(msg, title, params);

      if (btReleasesService.hasNewActual(data, release) && _isVoiceNeeded(release, followed)) {
        var speech =
          btReleasesService.getReminderSpeech(0, release, 0, magnitude) +
          btReleasesService.getActualVersusExpectedSpeech(data, release);
        btVoiceAssistantHelperService.readMessage(speech, 'calendar', 'release');
      }
    }

    /**
     * This function shows notification about perspective insights
     *
     * @param {String} type - type of pusher data
     * @param {Object} data - pusher data
     * @param {btRawRelease} release - release data
     * @private
     */
    function _showInsightNotification(type, data, release) {
      if (type !== 'Insights') return;

      var followed = btShareScopeService.getListFollowedEvents();

      if (!_isNotificationNeeded(release, followed)) return;

      var insights = data.insights
        .filter(function (insight) {
          return insight.type === 'post' && btInsightsService.isGoodPerspectiveInsight(insight);
        })
        .sort(function (a, b) {
          return Math.abs(b.totalSurpriseStrength) - Math.abs(a.totalSurpriseStrength);
        });

      if (insights.length === 0) return;

      var msg = insights[0].template + '.';
      var title = release.eventsInfo.currency + ' - ' + release.eventsInfo.name;

      var params = {
        timeOut: gDelays.notify,
        closeButton: true,
        type: 'calendar',
        onTap: _openReleasePage.bind(null, release),
      };

      btToastrService.info(msg, title, params);

      if (_isVoiceNeeded(release, followed)) {
        var speech = btEventsService.getPronunciation(release.eventsInfo) + '. ' + insights[0].template + '.';
        btVoiceAssistantHelperService.readMessage(speech, 'calendar', 'insight');
      }
    }

    /**
     *
     * @param {*} data
     * @param {*} release
     * @return {null|*}
     * @private
     */
    function _getMagnitude(data, release) {
      if (data.releaseStrength) {
        var strength = btReleasesService.prepareStrength(data.releaseStrength.value, release.time);
        return strength.fullMsg;
      } else if (data.actual !== 'NA') {
        return data.actual;
      } else {
        return null;
      }
    }

    /**
     * This function checks the need to pronounce the release notification.
     *
     * @param {btRawRelease} release - release object
     * @param {Number[]} events - identifiers of events followed by user
     * @return {Boolean}
     * @private
     */
    function _isVoiceNeeded(release, events) {
      return events.indexOf(release.eventId) !== -1 || release.eventsInfo.name.indexOf('[TEST]') === 0;
    }

    /**
     * This function checks the need to show the release notification.
     *
     * @param {btRawRelease} release - release object
     * @param {Number[]} events - identifiers of events followed by user
     * @return {Boolean}
     * @private
     */
    function _isNotificationNeeded(release, events) {
      return (
        release['show'] === true ||
        events.indexOf(release.eventId) !== -1 ||
        release.eventsInfo.name.indexOf('[TEST]') === 0
      );
    }

    /**
     * This function returns text of notification message.
     *
     * @param {String} type - type of data Rows, Insights, (--TradingInsights--)
     * @param {Object} data - data received via pusher
     * @param {btRawRelease} release - release object
     * @param {String} magnitude - release magnitude
     * @return {String}
     * @private
     */
    function _getNotificationMessage(type, data, release, magnitude) {
      if (type === 'Rows') {
        return (
          btReleasesService.getReminderText(0, release, 0, magnitude) +
          btReleasesService.getActualVersusExpectedText(data, release)
        );
      }

      // actually these cases don't works due to _isNotificationNeeded passes just Rows
      if (type === 'Insights') return 'insights were calculated';
      // if (type === 'TradingInsights') return 'trade ideas were calculated';
    }

    /**
     * This function parses raw trade idea from pusher message according to user settings.
     *
     * @param {Object} data - pusher message
     * @param {btRawRelease} release - connected release
     * @return {Object[]}
     * @private
     */
    function _parseUserTradeIdeas(data, release) {
      if (data.insights) {
        return data.insights.filter(function (insight) {
          if (insight.type === 'back-test' && btTradeIdeasService.isGoodTradeIdea(insight)) {
            var instrument = btInstrumentsService.getInstrumentBySomeSymbol(insight.data.market);
            var tradeIdea = btTradeIdeasService.convertToTradeIdea(
              btInsightsService.convertToTradingInsight(/** @type {btRelease} */ (release), insight, instrument)
            );
            return tradeIdea.passUserFilter(btTradeIdeasFiltersService.getUserSettings());
          } else {
            return false;
          }
        });
      } else {
        return [];
      }
    }

    /**
     * This function show news-driven trade idea notification.
     *
     * @param {btRawRelease} release - release object
     * @param {btRawInsight[]} tradeIdeas - raw trade ideas from pusher
     * @private
     */
    function _showTradeIdeasNotification(release, tradeIdeas) {
      /** @type {String[]} */
      var symbols = tradeIdeas.map(function (t) {
        return t.data.market;
      });

      if (symbols && symbols.length) {
        var text, speech;
        var nSuffix = symbols.length > 1 ? 's' : '';

        if (btRestrictionService.hasFeature('trade-ideas')) {
          var textSymbols = [];
          var speechSymbols = [];
          symbols.forEach(function (symbol) {
            var instrument = btInstrumentsService.getInstrumentBySomeSymbol(symbol);
            textSymbols.push(instrument.displayName);
            speechSymbols.push(btInstrumentsService.getPronunciation(instrument));
          });
          text = 'Trade idea' + nSuffix + ' for ' + textSymbols.join(', ');
          speech = 'News-driven trade idea' + nSuffix + ' for ' + speechSymbols.join(', ');
        } else {
          text = 'Trade idea' + nSuffix + ' for ' + ' instrument' + nSuffix + ' from your watchlist';
          speech = 'News-driven trade idea' + nSuffix + ' for ' + ' instrument' + nSuffix + ' from your watchlist';
        }

        text += ' triggered by ' + release.eventsInfo.currency + ' ' + release.eventsInfo.name + '.';
        speech += ' triggered by ' + btEventsService.getPronunciation(release.eventsInfo) + '.';

        var params = {
          timeOut: 6000,
          closeButton: true,
          type: 'trade',
          onTap: _openTradeIdeasPage,
        };

        btToastrService.info(text, 'News-Driven', params);

        btVoiceAssistantHelperService.readMessage(speech, 'ideas', 'news');
      }
    }

    /**
     *
     * @private
     */
    function _openTradeIdeasPage() {
      $state.go('ecapp.app.main.trade-ideas');
    }

    /**
     * This function update row data from database
     *
     * @return {angular.IPromise<btCalendarData>}
     * @private
     */
    function refreshCalendar() {
      // // Scroll up to see loader
      // $ionicScrollDelegate.$getByHandle('mainScrollRows').scrollTop();

      if (gCalendar.status !== 'loading') {
        gCalendar.status = 'empty';
      }

      return getCalendar().then(function sendReloadNotification() {
        $rootScope.$broadcast('calendar:reloaded');
      });
    }

    /**
     * Interval callback to update calendar
     * @private
     */
    function _autoUpdateCallback() {
      if (gDebug) console.log('Interval was called (_autoUpdateCallback)');
      try {
        _updateCalendar();
      } finally {
        $rootScope.$broadcast('calendar:refreshed');
      }
    }

    /**
     * Refresh data
     * @private
     */
    function _updateCalendar() {
      // console.log('calendar was updated at', btDateService.getNowDate());
      _sendReminder(gReleases);
      var releases = _filterCalendar(gReleases);
      gCalendar.cards = btReleasesService.prepareReleases(releases, gSettings.from, gSettings.till);
      // console.log('TEST: cards', gCalendar.cards.length);
      gCalendar.status = 'ready';

      gCalendar.nextIndex = 0;
      gCalendar.cards.forEach(function (value, i) {
        if (value.isFirstNext) gCalendar.nextIndex = i;
      });

      if (gCalendar.nextIndex === 0) {
        var now = Date.now() / 1000;
        gCalendar.cards.forEach(function (value, i) {
          if (value.time && value.time < now) gCalendar.nextIndex = i;
        });
        gCalendar.nextIndex++;
      }

      updateUpcomingEvents();

      $rootScope.debug.convertCount += 1;

      _saveDebugInformation();
    }

    /**
     * Send reminders
     * @param {btRawRelease[]} releases - all releases
     * @private
     */
    function _sendReminder(releases) {
      _removeOldReminders();
      var listOfFollowedEvents = btShareScopeService.getListFollowedEvents();
      releases.forEach(function (release) {
        if (listOfFollowedEvents.indexOf(release.eventId) !== -1 || release.eventsInfo.name.indexOf('[TEST]') === 0) {
          if (release.eventsInfo.name.indexOf('[TEST]') === 0) {
            console.log('Testing', _isReminder(5, release), _isReminder(3, release), _isReminder(1, release));
          }

          if (_isReminder(5, release)) _sendReminderByType(5, release);

          if (_isReminder(3, release)) _sendReminderByType(3, release);

          if (_isReminder(1, release)) _sendReminderByType(1, release);
        }
      });
    }

    /**
     *
     * @private
     */
    function _removeOldReminders() {
      var key;
      var now = btDateService.getNowDate();
      for (key in gReminders[5]) {
        if (gReminders[5].hasOwnProperty(key) && now - gReminders[5][key] > 5 * 60 * 1000) {
          delete gReminders[5][key];
        }
      }

      for (key in gReminders[3]) {
        if (gReminders[3].hasOwnProperty(key) && now - gReminders[3][key] > 3 * 60 * 1000) {
          delete gReminders[3][key];
        }
      }

      for (key in gReminders[1]) {
        if (gReminders[1].hasOwnProperty(key) && now - gReminders[1][key] > 2 * 60 * 1000) {
          delete gReminders[1][key];
        }
      }
    }

    /**
     *
     * @param {*} type
     * @param {*} release
     * @return {boolean}
     * @private
     */
    function _isReminder(type, release) {
      var now = btDateService.getNowDate();
      var date = btDateService.getDateFromRow(release);
      return !_wasSendReminder(type, release) && date > now && btDateService.getDifferenceInMinutes(date, now) === type;
    }

    /**
     *
     * @param {*} type
     * @param {*} release
     * @return {boolean}
     * @private
     */
    function _wasSendReminder(type, release) {
      return gReminders[type][release.eventId] !== undefined;
    }

    /**
     * Show release reminder
     * @param {Number} type - type of reminder (number of minutes before release)
     * @param {Object} release - release object
     * @private
     */
    function _sendReminderByType(type, release) {
      if ([1, 3, 5].indexOf(type) === -1) {
        console.error('btCalendarService: Wrong reminder type', type);
        return;
      }

      var title = release.eventsInfo.currency + ' - ' + release.eventsInfo.name;

      gReminders[type][release.eventId] = btDateService.getNowDate();

      var params = {
        timeOut: gDelays.notify,
        closeButton: true,
        type: 'calendar',
        onTap: _openReleasePage.bind(null, release),
      };

      var categories = {
        5: 'calendar',
        3: 'ideas',
        1: 'calendar',
      };

      var subcategories = {
        5: 'upcoming',
        3: 'potential',
        1: 'expected',
      };

      var text;
      var speech;
      if (type === 3) {
        var symbols = btReleasesService.getPotentialTradeIdeasSymbols(release);
        var watchlist = btWatchListService.getWatchedSymbols();
        symbols = btWatchListService.filterWatchedSymbols(symbols, watchlist);

        var nSuffix = symbols.length > 1 ? 's' : '';

        if (symbols && symbols.length > 0) {
          if (btRestrictionService.hasFeature('trade-ideas')) {
            var displaySymbols = [];
            var speechSymbols = [];
            symbols.forEach(function (symbol) {
              var instrument = btInstrumentsService.getInstrumentBySomeSymbol(symbol);
              displaySymbols.push(instrument.displayName);
              speechSymbols.push(btInstrumentsService.getPronunciation(instrument));
            });

            text =
              btReleasesService.getReminderText(type, release, symbols.length) + ' ' + displaySymbols.join(', ') + '.';
            speech =
              btReleasesService.getReminderSpeech(type, release, symbols.length) + ' ' + speechSymbols.join(', ') + '.';
          } else {
            text =
              btReleasesService.getReminderText(type, release, symbols.length) +
              ' instrument' +
              nSuffix +
              ' from your watchlist';
            speech =
              btReleasesService.getReminderSpeech(type, release, symbols.length) +
              ' instrument' +
              nSuffix +
              ' from your watchlist.';
          }
        } else {
          // no trade ideas skip
          return;
        }
      } else {
        text = btReleasesService.getReminderText(type, release);
        speech = btReleasesService.getReminderSpeech(type, release);
      }

      btToastrService.info(text, title, params);

      btVoiceAssistantHelperService.readMessage(speech, categories[type], subcategories[type]);
    }

    /**
     *
     * @param {*} release
     * @private
     */
    function _openReleasePage(release) {
      var params = btReleasesService.getReleaseStateParams(release);
      $state.go('ecapp.app.main.detail', params);
    }

    /**
     * Save release indices
     * @param {*} releases
     * @return {Record<Number, Number>}
     * @private
     */
    function _saveReviewIndices(releases) {
      var indices = {};
      var count = 0;
      releases.forEach(function (release, index) {
        if (release.id) {
          indices[release.id] = index;
          count++;
        }
      });

      console.log(count);

      return indices;
    }

    /**
     * Save debug information on root scope
     * @private
     */
    function _saveDebugInformation() {
      // debug information
      var delay = _timeDelay(gLastConvert);
      if (delay !== 0) {
        $rootScope.debug.delays.data.push(delay);
        $rootScope.debug.delays.min = Math.min.apply(null, $rootScope.debug.delays.data);
        $rootScope.debug.delays.max = Math.max.apply(null, $rootScope.debug.delays.data);
        var sum = $rootScope.debug.delays.data.reduce(function sumElements(a, b) {
          return a + b;
        }, 0);
        $rootScope.debug.delays.avg = Math.round(sum / $rootScope.debug.delays.data.length);
      }

      // _haveMissingData();
      if (gDebug) console.log('Update delay', delay, 'ms');
    }

    /**
     * This function add mock release for real time testing
     * @param {btRawRelease[]} rawReleases
     * @return {btRawRelease[]}
     * @private
     */
    function _addRealTimeTesting(rawReleases) {
      if ($window.localStorage.getItem('realtimeTest') === 'true') {
        btDevService.alert('Realtime testing');
        // var delay = [11, 6 * 60, 6 * 60 + 14, 6 * 60 + 18]; // expect, release, insights, trading insights
        // var delay = [11, 21, 31, 41]; // expect, release, insights, trading insights
        var releaseDelay = 5;
        var delay = [releaseDelay, releaseDelay + 1, releaseDelay + 2, releaseDelay + 3]; // expect, release, insights, trading insights
        var data = btReleaseTestingService.addTestRelease(rawReleases, delay);

        if (data !== null) {
          btDevService.alert('Release name' + data.id);
        }

        // auto release
        if (data !== null) {
          gTesting.release = $timeout(
            function () {
              console.log('RealTimeTesting: Test release data');
              _pusherHandler(data.row, 'Rows', btReleasesService.handleRows);
            },
            delay[1] * 1000,
            false
          );
        }

        // auto release insights
        if (data !== null) {
          gTesting.insights = $timeout(
            function () {
              console.log('RealTimeTesting: Test insights data');
              _pusherHandler(data.insights, 'Insights', btInsightsService.handleInsights);
            },
            delay[2] * 1000,
            false
          );
        }

        // auto trading insights
        if (data !== null) {
          gTesting.tradeIdeas = $timeout(
            function () {
              console.log('RealTimeTesting: Test trade ideas data');
              _pusherHandler(data.tradingInsights, 'TradingInsights', btInsightsService.handleInsights);
            },
            delay[3] * 1000,
            false
          );
        }
      }

      return rawReleases;
    }

    /**
     *
     * @private
     */
    function _stopRealtimeTesting() {
      $timeout.cancel(gTesting.release);
      $timeout.cancel(gTesting.insights);
      $timeout.cancel(gTesting.tradeIdeas);
    }

    /**
     * Start listen pusher
     * @private
     */
    function _bindPusher() {
      if (gPusherBound === true) {
        return;
      }

      gPusherBound = true;

      btPusherService.channels.tradingInsights.bind('update', _handleTradingInsights);
      btPusherService.channels.insights.bind('update', _handleInsights);
      btPusherService.channels.rows.bind('update', _handleRows);

      // add developers events
      if ($window.isDevelopment) {
        btPusherService.channels.tradingInsights.bind('update-dev', _handleTradingInsights);
        btPusherService.channels.insights.bind('update-dev', _handleInsights);
        btPusherService.channels.rows.bind('update-dev', _handleRows);
      }

      // add testing events
      if ($window.isTesting) {
        btPusherService.channels.tradingInsights.bind('update-test', _handleTradingInsights);
        btPusherService.channels.insights.bind('update-test', _handleInsights);
        btPusherService.channels.rows.bind('update-test', _handleRows);
      }
    }

    /**
     * Stop listen pusher
     * @private
     */
    function _unbindPusher() {
      if (gPusherBound === false) {
        return;
      }

      gPusherBound = false;

      btPusherService.channels.tradingInsights.unbind('update', _handleTradingInsights);
      btPusherService.channels.insights.unbind('update', _handleInsights);
      btPusherService.channels.rows.unbind('update', _handleRows);

      // add developers events
      if ($window.isDevelopment) {
        btPusherService.channels.tradingInsights.unbind('update-dev', _handleTradingInsights);
        btPusherService.channels.insights.unbind('update-dev', _handleInsights);
        btPusherService.channels.rows.unbind('update-dev', _handleRows);
      }

      // add testing events
      if ($window.isTesting) {
        btPusherService.channels.tradingInsights.unbind('update-test', _handleTradingInsights);
        btPusherService.channels.insights.unbind('update-test', _handleInsights);
        btPusherService.channels.rows.unbind('update-test', _handleRows);
      }
    }

    /**
     * Start calendar auto refresh
     * @private
     */
    function _startAutoRefresh() {
      if (gAutoUpdateInterval === null) {
        gAutoUpdateInterval = $interval(_autoUpdateCallback, gDelays.update);
      }
    }

    /**
     * Stop calendar auto refresh
     * @private
     */
    function _stopAutoRefresh() {
      if (gAutoUpdateInterval !== null) {
        $interval.cancel(gAutoUpdateInterval);
        gAutoUpdateInterval = null;
      }
    }

    /**
     * Start activity manager
     * @param {Number} interval - interval in milliseconds
     * @private
     */
    function _startActivityManager(interval) {
      if (gActivityInterval === null) {
        btDevService.alert('ActivityManager is on');
        gActivityInterval = $interval(_activityManagerCallback, interval);
      }
    }

    /**
     * Stop activity manager
     * @private
     */
    function _stopActivityManager() {
      if (gActivityInterval !== null) {
        btDevService.alert('ActivityManager is off');
        $interval.cancel(gActivityInterval);
        gActivityInterval = null;
      }
    }

    /**
     * Detects deactivation of javascript. On mobile platform can detect deactivation of phone.
     *
     * @private
     * @param {*} last
     * @return {any}
     */
    function _timeDelay(last) {
      var now = btDateService.getNowDate();
      if (last.time === null) {
        last.time = now;
      }
      var res = now - last.time;
      last.time = now;
      return res;
    }

    /**
     * Try to detect activity issue
     * @private
     */
    function _activityManagerCallback() {
      if (gDebug) console.log('Interval was called (_activityManagerCallback)');

      if (_timeDelay(gLastClock) > gDelays.activity) {
        btDevService.alert('App was frozen!');
        $rootScope.outOfDate = _haveMissingData();

        if ($rootScope.outOfDate === true) {
          btDevService.alert('App have missing data and will be refreshed!');
          refreshCalendar();
        }
      }
    }

    /**
     * Try to detect missing data.
     *
     * @return {Boolean} missing data detected or releases is not prepared
     * @private
     */
    function _haveMissingData() {
      var now = btDateService.getNowDate().getTime() / 1000;
      var badReviews = [];
      var pushedReviews = [];

      console.log('Find missing data...');

      // releases isn't prepared
      if (!(gReleases && Array.isArray(gReleases))) {
        btDevService.alert('Error: gReleases is undefined');
        return false;
      }

      btDevService.alert('Last update ' + gLastDataUpdate);

      // check releases
      for (var i = 0; i < gReleases.length; i++) {
        var release = gReleases[i];

        // if the releases have been updated after the release time, continue
        if (release.time < gLastDataUpdate) {
          continue;
        }

        // break the cycle if the first future release is reached
        if (release.time > now) {
          break;
        }

        // check release actual value
        if (release.actual === undefined || release.actual === 'NA') {
          if (gPusherLog[release.id] === undefined || gPusherLog[release.id]['Rows'] === undefined) {
            badReviews.push({ id: release.id, time: release.time });
            console.log("Release without actual value and don't updated by pusher", release);
          } else {
            pushedReviews.push({ id: release.id, time: release.time });
            console.log('Release without actual value, but updated by pusher', release);
          }
        }
      }

      btDevService.alert('Bad releases: ' + badReviews.length + '. Pushed releases: ' + pushedReviews.length);

      if (pushedReviews.length > 0) {
        btDevService.alert('Last pushed releases: ' + pushedReviews[pushedReviews.length - 1].time);
      }

      console.log('List of bad releases with missing data:', badReviews);

      return badReviews.length > 0;
    }

    /**
     * This function return object which can identify release for state manager.
     *
     * @param {btRelease} release - (const) release data
     * @return {btReleaseIdData} release identification data
     */
    function getReleaseStateParams(release) {
      return btReleasesService.getReleaseStateParams(release);
    }

    /**
     * Part of code connected to pusher. At first just create pusher. After that start to listening two channel:
     * insightsChannel and rowsChannel
     *
     * Some old comment:
     * it seems like we'll get insights of a row even if it was never loaded from the app but we
     * got it from the pusher, which might be not required (because in the first time it'll get loaded normally all
     * the new "updates" will get loaded as well).
     */

    /**
     *
     * @param {*} oandaSymbol
     * @return {angular.IPromise<ReleaseObject[]>}
     */
    function getInstrumentCalendar(oandaSymbol) {
      return btReleasesService.getReleasesForSymbol(oandaSymbol);
    }

    /**
     *
     * @param {number} period - in milliseconds
     * @return {Array}
     */
    function getUpcomingEvents(period) {
      if (gUpcomingEvents[period] === undefined) {
        return (gUpcomingEvents[period] = parseUpcomingEvents(period));
      }

      return gUpcomingEvents[period];
    }

    /**
     *
     * @param {number} period
     * @return {[]}
     */
    function parseUpcomingEvents(period) {
      var now = Date.now();
      var releases = [];
      gCalendar.cards.forEach(function (card) {
        if (card.time && 0 < card.time * 1000 - now && card.time * 1000 - now < period) {
          // console.log('TEST', card.time, card.time * 1000 - now);
          releases.push(card);
        }
      });

      return releases;
    }

    /**
     *
     */
    function updateUpcomingEvents() {
      Object.keys(gUpcomingEvents).forEach(function (period) {
        var releases = parseUpcomingEvents(parseInt(period));
        var n = gUpcomingEvents.length;
        for (var i = 0; i < n; i++) {
          gUpcomingEvents[period].pop();
        }
        releases.forEach(function (value) {
          gUpcomingEvents[period].push(value);
        });
      });
    }

    /**
     *
     */
    function clearUpcomingEvents() {
      Object.keys(gUpcomingEvents).forEach(function (period) {
        var n = gUpcomingEvents.length;
        for (var i = 0; i < n; i++) {
          gUpcomingEvents[period].pop();
        }
      });
    }
  }
})();
