/**
 * Created by Sergey Panpurin on 6/27/2017.
 */

// @ts-check
(function btTradingCardServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btTradingCardService', btTradingCardService);

  btTradingCardService.$inject = ['btDateService', 'btPriceService'];

  /**
   *
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IPriceService} btPriceService
   * @return {ecapp.ITradingCardService}
   */
  function btTradingCardService(btDateService, btPriceService) {
    console.log('Running btTradingCardService');

    var gEntryExpiration = 10; // in minutes
    var gDefaultTradeDuration = 90; // in minutes
    var gTradeExpiration = 10; // in minutes
    // var gEnterPriceShift = -0.25;
    var gEntryShift = 0.5; // ATR
    var gMinStopBits = 10;
    var gMinStopATR = 2.5;

    return {
      getTradeData: getTradeData,
      getTradeDataGeneral: getTradeDataGeneral,
      getMaxPricePrecision: getMaxPricePrecision,
      fixPrices: fixPrices,
      isExpired: isExpired,
      isWaiting: isWaiting,
      isOld: isOld,
      isExpiring: isExpiring,
      getTimeToLive: getTimeToLive,
      getEntryTime: getEntryTime,
      getMinutesAfter: getMinutesAfter,
      getCountDown: getCountDown,
    };

    /**
     * This structure describes suggested trade characteristics for Trade Idea.
     *
     * @typedef {Object} btTradeIdeaCharacteristics
     * @property {String} class - positive or negative or neutral
     * @property {btTradeIdeaCCounts} count -
     * @property {btTradeIdeaCTimes} time -
     * @property {btTradeIdeaCDelays} delay -
     * @property {btTradeIdeaCPrices} price -
     * @property {Number} rate -
     * @property {Number} ratio -
     * @property {btTradeIdeaCProfits} profit -
     * @property {?btBTITradeData2} raw -
     */

    /**
     * @typedef {Object} btTradeIdeaCCounts
     * @property {Number} win - number of wins
     * @property {Number} loss - number of losses
     * @property {Number} weak - number of weak trades
     * @property {Number} total - total number of trades
     */

    /**
     * @typedef {Object} btTradeIdeaCTimes
     * @property {Date} release - date of release
     * @property {String} open - date of open
     * @property {String} risk - date of risk
     * @property {String} reward - date of reward
     */

    /**
     * @typedef {Object} btTradeIdeaCDelays
     * @property {Number} risk - risk is expected after this amount of minutes
     * @property {Number} reward - reward is expected after this amount of minutes
     */

    /**
     * @typedef {Object} btTradeIdeaCPrices
     * @property {Number} atr - trade idea atr
     * @property {String} unit - name of price bit: pip or tick
     * @property {Number} bit - minimum of price change
     * @property {Number} base - suggested trade open price
     * @property {Number} shift - suggested trade open price
     * @property {Number} open - suggested trade open price
     * @property {Number} target1 - first profit target
     * @property {Number} target2 - second profit target
     * @property {Number} target3 - third profit target
     * @property {Number} stop1 - first stop loss
     * @property {Number} stop2 - second stop loss
     * @property {Number} stop3 - third stop loss
     * @property {Number} reward1 - first
     * @property {Number} reward2 - second
     * @property {Number} reward3 - third
     * @property {?Number} reward1u - first
     * @property {?Number} reward2u - second
     * @property {?Number} reward3u - third
     * @property {Number} risk1 - first
     * @property {Number} risk2 - second
     * @property {Number} risk3 - third
     * @property {?Number} risk1u - first
     * @property {?Number} risk2u - second
     * @property {?Number} risk3u - third
     */

    /**
     * @typedef {Object} btTradeIdeaCProfits
     * @property {Number} win -
     * @property {Number} loss -
     */

    /**
     * This function prepare trade idea characteristics.
     *
     * @param {btConvertedInsight} item
     * @param {btRelease} row
     * @param {Number} bit
     * @param {String} unit
     * @return {btTradeIdeaCharacteristics}
     */
    function getTradeData(item, row, bit, unit) {
      var data = null;
      var trade = null;
      if (item && item.insight && item.insight.data) {
        data = item.insight.data;

        if (item && item.insight && item.insight.data.tradeData) {
          trade = item.insight.data.tradeData;
        }
      }

      var time = row && row.time ? btDateService.getDateFromRow(row) : null;

      return getTradeDataGeneral(data, trade, time, bit, unit);
    }

    /**
     *
     * @param {btRawTradingInsightData} data
     * @param {btBTITradeData2} trade
     * @param {Date} date
     * @param {Number} bit
     * @param {String} unit
     * @return {btTradeIdeaCharacteristics}
     */
    function getTradeDataGeneral(data, trade, date, bit, unit) {
      var result = initializeChars();

      if (trade && data && date) {
        // Question: Why we have add 1 minute?
        var openTime = btDateService.getClockText(btDateService.addMinutes(date, 1));
        var riskTime = btDateService.getClockText(btDateService.addMinutes(date, trade.risk.time.avg));
        var rewardTime = btDateService.getClockText(btDateService.addMinutes(date, trade.reward.time.avg));

        var g = +1;
        var b = -1;
        if (data.action === 'downtrend') {
          g = -1;
          b = +1;
        }

        checkPrices(trade);

        result.class = data.class;

        result.count.win = data.win;
        result.count.loss = data.total - data.win;
        result.count.weak = data.weak;
        result.count.total = data.total;

        result.time.release = date;
        result.time.open = openTime;
        result.time.risk = riskTime;
        result.time.reward = rewardTime;

        result.delay.risk = Math.round(trade.risk.time.avg);
        result.delay.reward = Math.round(trade.reward.time.avg);

        result.price.bit = bit;
        result.price.unit = unit;

        if (trade.atr) {
          result.price.atr = trade.atr.value;
          result.price.shift = g * (trade.atr.value * gEntryShift - trade.risk.price.min);
        } else {
          result.price.atr = 0;
          result.price.shift = 0;
        }

        // We was to shift entry price but keep same profit target and stop loss. So we need to recalculate profit
        // target delta and stop loss delta according to entry price shift.
        result.price.reward1 = g * trade.reward.price.min - result.price.shift; // minimum reward of winning trades
        result.price.reward2 = g * trade.reward.price.mdn - result.price.shift; // median reward of winning trades
        result.price.reward3 = g * trade.reward.price.avg - result.price.shift; // average reward of winning trades
        result.price.risk1 = b * result.price.bit; // minimal risk
        result.price.risk2 = b * trade.risk.price.max - result.price.shift; // maximum risk of winning trades
        result.price.risk3 = b * trade.stop.price.max - result.price.shift; // maximum risk of winning and weak trades

        // Increase the stop loss if it is too small.
        result.price.risk1 = normalizeRisk(result.price.risk1, result.price);
        result.price.risk2 = normalizeRisk(result.price.risk2, result.price);
        result.price.risk3 = normalizeRisk(result.price.risk3, result.price);

        result.rate = data.successRate.value;
        result.ratio = Math.abs(result.price.reward1 / result.price.risk3);

        result.profit.win = trade.profit ? trade.profit.strong + trade.profit.weak : 0;
        result.profit.loss = trade.loss ? trade.loss.strong + trade.loss.weak : 0;

        result.raw = trade;
      }

      convertToUnits(result.price);

      return result;
    }

    /**
     * This function normalizes risk connected to stop loss. It should not be less than 10 bits or 2.5 ATR.
     *
     * @param {Number} risk - trade risk
     * @param {btTradeIdeaCPrices} price - trade idea prices
     * @return {Number}
     */
    function normalizeRisk(risk, price) {
      if (risk > 0) {
        if (price.bit && risk < gMinStopBits * price.bit) risk = gMinStopBits * price.bit;
        if (price.atr && risk < gMinStopATR * price.atr) risk = gMinStopATR * price.atr;
      } else {
        if (price.bit && risk > -gMinStopBits * price.bit) risk = -gMinStopBits * price.bit;
        if (price.atr && risk > -gMinStopATR * price.atr) risk = -gMinStopATR * price.atr;
      }
      return risk;
    }

    /**
     * This function initializes trade idea characteristics.
     *
     * @return {btTradeIdeaCharacteristics}
     */
    function initializeChars() {
      var now = new Date();
      return {
        class: 'neutral',
        count: { win: 0, loss: 0, weak: 0, total: 0 },
        time: { release: now, open: 'now', risk: 'now', reward: 'now' },
        delay: { risk: 0, reward: 0 },
        price: {
          atr: 0,
          unit: '-',
          bit: 1,
          base: 0,
          shift: 0,
          open: 0,
          target1: 0,
          target2: 0,
          target3: 0,
          stop1: 0,
          stop2: 0,
          stop3: 0,
          reward1: 0,
          reward2: 0,
          reward3: 0,
          risk1: 0,
          risk2: 0,
          risk3: 0,
          reward1u: null,
          reward2u: null,
          reward3u: null,
          risk1u: null,
          risk2u: null,
          risk3u: null,
        },
        rate: 0,
        ratio: 1,
        profit: { win: 0, loss: 0 },
        raw: null,
      };
    }

    /**
     * This function fixes missed price statistics due to old version of backend didn't provide all price statistics.
     *
     * @param {btBTITradeData2} trade - trade data of trade idea
     */
    function checkPrices(trade) {
      // Fix rewards
      if (trade.reward.price.min === undefined) {
        console.warn('Bad min reward');
        trade.reward.price.min = trade.reward.price.avg;
      }
      if (trade.reward.price.mdn === undefined) {
        console.warn('Bad mdn reward');
        trade.reward.price.mdn = trade.reward.price.avg;
      }
      if (trade.reward.price.opt === undefined) {
        console.warn('Bad opt reward');
        trade.reward.price.opt = trade.reward.price.avg;
      }
      if (trade.reward.price.max === undefined) {
        console.warn('Bad max reward');
        trade.reward.price.max = trade.reward.price.avg;
      }

      if (trade.risk.price.min === undefined) {
        console.warn('Bad min risk');
        trade.risk.price.min = 0;
      }
      if (trade.risk.price.mdn === undefined) {
        console.warn('Bad mdn risk');
        trade.risk.price.mdn = trade.risk.price.avg;
      }
      if (trade.risk.price.opt === undefined) {
        console.warn('Bad opt risk');
        trade.risk.price.opt = trade.risk.price.avg;
      }
      if (trade.risk.price.max === undefined) {
        console.warn('Bad max risk');
        trade.risk.price.max = trade.risk.price.avg;
      }

      if (trade.stop === undefined) {
        trade.stop = {
          atr: {
            min: trade.risk.atr ? trade.risk.atr.avg : '1',
            avg: trade.risk.atr ? trade.risk.atr.avg : '1',
            mdn: trade.risk.atr ? trade.risk.atr.avg : '1',
            opt: trade.risk.atr ? trade.risk.atr.avg : '1',
            max: trade.risk.atr ? trade.risk.atr.avg : '1',
          },
          time: {
            min: trade.risk.time.avg,
            avg: trade.risk.time.avg,
            mdn: trade.risk.time.avg,
            opt: trade.risk.time.avg,
            max: trade.risk.time.avg,
          },
          price: {
            min: trade.risk.price.avg,
            avg: trade.risk.price.avg,
            mdn: trade.risk.price.avg,
            opt: trade.risk.price.avg,
            max: trade.risk.price.avg,
          },
        };
      } else {
        if (trade.stop.price.min === undefined) {
          console.warn('Bad min stop');
          trade.stop.price.min = trade.risk.price.avg;
        }
        if (trade.stop.price.avg === undefined) {
          console.warn('Bad avg stop');
          trade.stop.price.avg = trade.risk.price.avg;
        }
        if (trade.stop.price.mdn === undefined) {
          console.warn('Bad mdn stop');
          trade.stop.price.mdn = trade.risk.price.avg;
        }
        if (trade.stop.price.opt === undefined) {
          console.warn('Bad opt stop');
          trade.stop.price.opt = trade.risk.price.avg;
        }
        if (trade.stop.price.max === undefined) {
          console.warn('Bad max stop');
          trade.stop.price.max = trade.risk.price.avg;
        }
      }
    }

    /**
     * This function represents reward and risk in terms of the minimum price changes.
     *
     * @param {btTradeIdeaCPrices} prices - prices
     */
    function convertToUnits(prices) {
      prices.reward1u = prices.reward1 && prices.bit ? Math.round(prices.reward1 / prices.bit) : null;
      prices.reward2u = prices.reward2 && prices.bit ? Math.round(prices.reward2 / prices.bit) : null;
      prices.reward3u = prices.reward3 && prices.bit ? Math.round(prices.reward3 / prices.bit) : null;
      prices.risk1u = prices.risk1 && prices.bit ? Math.round(prices.risk1 / prices.bit) : null;
      prices.risk2u = prices.risk2 && prices.bit ? Math.round(prices.risk2 / prices.bit) : null;
      prices.risk3u = prices.risk3 && prices.bit ? Math.round(prices.risk3 / prices.bit) : null;
    }

    /**
     * This function fix enter price according to average risk.
     *
     * target = base + reward
     * stop = base + risk
     * open = base + delta
     * ratio = (target - open) / (stop - open)
     *
     * @param {btTradeIdeaCharacteristics} trade - trade object
     * @param {String|Number} basePrice - base price
     * @param {Number} [p] - price precision
     */
    function fixPrices(trade, basePrice, p) {
      if (typeof basePrice === 'string') basePrice = parseFloat(basePrice);

      if (basePrice === 0) p = 5;
      else p = p || btPriceService.getPricePrecision(basePrice);

      if (trade.price.base !== 0) {
        console.error('Update base price again.');
        alert('Bad');
      }

      // basePrice = 0;
      trade.price.p = p;
      trade.price.base = basePrice;

      if (basePrice !== 0) trade.price.open = round(basePrice + trade.price.shift, p);

      // Calculate profit targets
      trade.price.target1 = trade.price.open + trade.price.reward1;
      trade.price.target2 = trade.price.open + trade.price.reward2;
      trade.price.target3 = trade.price.open + trade.price.reward3;

      // Calculate stop loss
      trade.price.stop1 = trade.price.open + trade.price.risk1;
      trade.price.stop2 = trade.price.open + trade.price.risk2;
      trade.price.stop3 = trade.price.open + trade.price.risk3;

      roundPrices(trade, p);
    }

    /**
     *
     * @param {*} trade
     * @param {*} p
     */
    function roundPrices(trade, p) {
      trade.price.target1 = round(trade.price.target1, p);
      trade.price.target2 = round(trade.price.target2, p);
      trade.price.target3 = round(trade.price.target3, p);

      trade.price.stop1 = round(trade.price.stop1, p);
      trade.price.stop2 = round(trade.price.stop2, p);
      trade.price.stop3 = round(trade.price.stop3, p);

      trade.price.reward1 = round(trade.price.reward1, p);
      trade.price.reward2 = round(trade.price.reward2, p);
      trade.price.reward3 = round(trade.price.reward3, p);

      trade.price.risk1 = round(trade.price.risk1, p);
      trade.price.risk2 = round(trade.price.risk2, p);
      trade.price.risk3 = round(trade.price.risk3, p);
    }

    /**
     *
     * @param {*} value
     * @param {*} p
     * @return {number}
     */
    function round(value, p) {
      return parseFloat(value.toFixed(p));
    }

    /**
     * Check if trade idea is expired
     * @param {Number} timestamp - trade idea timestamp in seconds
     * @param {Number} minAfter - number of minutes release
     * @return {Boolean}
     */
    function isExpired(timestamp, minAfter) {
      var openDate = new Date(btDateService.addMinutes(btDateService.getDateFromSec(timestamp), minAfter));
      var nowDate = btDateService.getNowDate();
      if (openDate > nowDate) {
        return false;
      } else {
        var d = btDateService.getDifferenceInMinutes(nowDate, openDate);
        return d > gEntryExpiration;
      }
    }

    /**
     * Check if trade idea is waiting for entry
     * @param {Number} timestamp - trade idea timestamp in seconds
     * @param {Number} minAfter - number of minutes after release
     * @return {Boolean}
     */
    function isWaiting(timestamp, minAfter) {
      var openDate = new Date(btDateService.addMinutes(btDateService.getDateFromSec(timestamp), minAfter));
      var nowDate = btDateService.getNowDate();
      return openDate > nowDate;
    }

    /**
     * Check if trade idea is old
     * @param {Number} timestamp - trade idea timestamp in seconds
     * @param {Number} minAfter - number of minutes after release
     * @param {Number} [lifeTime] - in minutes
     * @return {Boolean}
     */
    function isOld(timestamp, minAfter, lifeTime) {
      var openTime = btDateService.addMinutes(btDateService.getDateFromSec(timestamp), minAfter);
      var d = btDateService.getDifferenceInMinutes(btDateService.getNowDate(), new Date(openTime));
      return d > (lifeTime || gDefaultTradeDuration);
    }

    /**
     *
     * @param {*} timestamp
     * @param {*} minAfter
     * @param {Number} [lifeTime] - in minutes
     * @return {boolean|number}
     */
    function isExpiring(timestamp, minAfter, lifeTime) {
      var openTime = btDateService.addMinutes(btDateService.getDateFromSec(timestamp), minAfter);
      var d = btDateService.getDifferenceInMinutes(btDateService.getNowDate(), new Date(openTime));
      return (lifeTime || gTradeExpiration) - d < gTradeExpiration;
    }

    /**
     *
     * @param {*} timestamp
     * @param {*} minAfter
     * @param {*} lifeTime
     * @return {*}
     */
    function getTimeToLive(timestamp, minAfter, lifeTime) {
      var closeIn = minAfter + (lifeTime || gDefaultTradeDuration);
      var closeDate = new Date(btDateService.addMinutes(btDateService.getDateFromSec(timestamp), closeIn));
      var nowDate = btDateService.getNowDate();
      if (nowDate > closeDate) {
        return 0;
      } else {
        return btDateService.getDifferenceInMinutes(nowDate, closeDate);
      }
    }

    /**
     *
     * @param {*} timestamp
     * @param {*} minAfter
     * @return {Number}
     */
    function getEntryTime(timestamp, minAfter) {
      return btDateService.addMinutes(btDateService.getDateFromSec(timestamp), minAfter);
    }

    /**
     *
     * @param {*} type
     * @return {number}
     */
    function getMinutesAfter(type) {
      if (type === 'release') {
        return 1;
      } else if (type === 'moment') {
        return 0;
      } else {
        return 0;
      }
    }

    /**
     *
     * @param {*} row
     * @param {*} minAfter
     * @return {{isFuture: boolean, text: *}}
     */
    function getCountDown(row, minAfter) {
      var openTime = btDateService.addMinutes(btDateService.getDateFromRow(row), minAfter);
      var diff = btDateService.getMinutesSecondFromNow(openTime, { skipZero: true, skipConvert: true });
      // var text = "";
      // if (diff.asMinutes() > 10) {
      //   text = diff.humanize(true);
      // } else {
      //   text = '' + diff.minutes() + ':' + diff.seconds();
      // }
      // noinspection JSUnresolvedFunction
      return { isFuture: diff > 0, text: diff.humanize(true) };
    }

    /**
     * This function returns maximal price precision.
     *
     * @param {String[]} prices - prices
     * @return {Number}
     */
    function getMaxPricePrecision(prices) {
      var precisions = prices.map(function (price) {
        return btPriceService.getPricePrecision(price);
      });
      return Math.max.apply(null, precisions);
    }
  }
})();
