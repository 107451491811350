/**
 * Created by Sergey Panpurin on 5/18/21.
 */

(function btWeightedAverageClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btWeightedAverage:';

  /**
   * ???
   *
   * @ngdoc directive
   * @name btWeightedAverage
   * @memberOf ecapp
   * @scope
   */
  /** Separator */
  angular.module('ecapp').component('btWeightedAverage', {
    templateUrl: 'directives/barometer/bt-weighted-average-widget.html',
    controller: btWeightedAverageController,
    controllerAs: 'vm',
  });

  btWeightedAverageController.$inject = ['$timeout', 'btSettingsService', 'btLinkDataService'];

  /**
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.ILinkDataService} btLinkDataService
   */
  function btWeightedAverageController($timeout, btSettingsService, btLinkDataService) {
    if (gDebug) console.log(gPrefix, 'Controller');

    var gSettings = btSettingsService.getLocalSettings();

    /*jshint validthis: true*/
    var vm = this;

    vm.markets = [
      {
        id: 1,
        productSymbol: 'LLS',
        productName: 'Light Louisiana Sweet',
        location: 'ST. JAMES, LA',
        bid: 2.0,
        ask: 2.2,
        average: 2.15,
      },
      {
        id: 2,
        productSymbol: 'MARS',
        productName: 'Mars Blend',
        location: 'CLOVELLY, LA',
        bid: -0.2,
        ask: -0.15,
        average: -0.12,
      },
      {
        id: 3,
        productSymbol: 'MIDSWT',
        productName: 'West Texas Intermediate',
        location: 'MIDLAND, TX',
        bid: 0.2,
        ask: 0.25,
        average: 0.18,
      },
      {
        id: 4,
        productSymbol: 'MEH',
        productName: 'West Texas Intermediate',
        location: 'EAST HOUSTON',
        bid: 0.6,
        ask: 0.65,
        average: 0.62,
      },
      {
        id: 5,
        productSymbol: 'WTS',
        productName: 'West Texas Sour',
        location: 'MIDLAND, TX',
        bid: 0.3,
        ask: 0.5,
        average: 0.23,
      },
      {
        id: 6,
        productSymbol: 'WCS CUSH',
        productName: 'Western Canadian Select',
        location: 'CUSHING, OK',
        bid: -5.4,
        ask: -5,
        average: 5.25,
      },
      {
        id: 7,
        productSymbol: 'CLK CUSH',
        productName: 'Cold Lake',
        location: 'CUSHING, OK',
        bid: -5.4,
        ask: -5,
        average: -5.25,
      },
      {
        id: 8,
        productSymbol: 'CDB CUSH',
        productName: 'Christina Dilbit Blend',
        location: 'CUSHING, OK',
        bid: -6,
        ask: -5.75,
        average: -5.75,
      },
      {
        id: 9,
        productSymbol: 'AWB CUSH',
        productName: 'Access Western Blend',
        location: 'CUSHING, OK',
        bid: -6,
        ask: -5.75,
        average: -5.75,
      },
      {
        id: 10,
        productSymbol: 'WCS HOU',
        productName: 'Western Canadian Select',
        location: 'ECHO/NEDERLAND TX',
        bid: -4.25,
        ask: -4,
        average: -4.25,
      },
      {
        id: 11,
        productSymbol: 'CLK US HOU',
        productName: 'Cold Lake',
        location: 'ECHO/NEDERLAND TX',
        bid: -4.25,
        ask: -4,
        average: -4.25,
      },
      {
        id: 12,
        productSymbol: 'CDB HOU',
        productName: 'Christina Dilbit Blend',
        location: 'ECHO/NEDERLAND TX',
        bid: -5,
        ask: -4.85,
        average: -5,
      },
      {
        id: 13,
        productSymbol: 'AWB HOU',
        productName: 'Access Western Blend',
        location: 'ECHO/NEDERLAND TX',
        bid: -4.9,
        ask: -4.5,
        average: 4.9,
      },
      {
        id: 14,
        productSymbol: 'LS',
        productName: 'Light Sweet',
        location: 'GUERNSEY, WY',
        bid: -1.35,
        ask: -1,
        average: -1.2,
      },
      {
        id: 15,
        productSymbol: 'SADDLEHORN LIGHT',
        productName: 'Saddlehorn Light',
        location: 'CUSHING, OK',
        bid: -0.9,
        ask: -0.8,
        average: -0.87,
      },
      {
        id: 16,
        productSymbol: 'WEST TEXAS LIGHT',
        productName: 'West Texas Light',
        location: 'MIDLAND, TX',
        bid: -0.45,
        ask: -0.35,
        average: -0.32,
      },
    ];

    vm.filteredMarkets = vm.markets;

    vm.$onInit = function () {
      if (gDebug) console.log(gPrefix, 'Initializing...');

      loadSettings();

      btLinkDataService.getVolumeWeightedAveragePrices(btLinkDataService.getToday()).catch(function (err) {
        console.error(err);
        vm.storage = null;
        vm.subscription = null;
        vm.error = err.message || 'Unable to load transaction due to unknown problem';
        vm.isLoading = false;
      });
    };

    vm.$onDestroy = function () {
      if (gDebug) console.log(gPrefix, 'Destroying...');
    };

    vm.sortColumn = function (column, order) {
      if (order !== undefined) {
        vm.orderByReverse = order === -1;
        vm.reverse = vm.orderByReverse ? -1 : 1;
      } else {
        vm.orderByReverse = vm.orderByColumn === column ? !vm.orderByReverse : false;
        vm.reverse = vm.orderByReverse ? -1 : 1;
      }

      vm.orderByColumn = column;

      prepareMarkets();

      saveSettings();
    };

    /**
     *
     */
    function prepareMarkets() {
      vm.filteredMarkets = vm.markets.sort(sortMarkets);
    }

    /**
     *
     * @param {object} a - first market
     * @param {object} b - second market
     * @return {number}
     */
    function sortMarkets(a, b) {
      switch (vm.orderByColumn) {
        case 'bid':
          return vm.reverse * (b.bid - a.bid);
        case 'ask':
          return vm.reverse * (b.ask - a.ask);
        case 'average':
          return vm.reverse * (b.average - a.average);

        case 'product-symbol':
          return vm.reverse * b.productSymbol.localeCompare(a.productSymbol);
        case 'product-name':
          return vm.reverse * b.productName.localeCompare(a.productName);
        case 'location':
          return vm.reverse * b.location.localeCompare(a.location);

        default:
          return vm.reverse * b.productSymbol.localeCompare(a.productSymbol);
      }
    }

    /**
     * This function saves user settings.
     */
    function loadSettings() {
      vm.orderByReverse = gSettings.get('barometer.weighted-average.order');
      vm.orderByReverse = vm.orderByReverse !== undefined ? vm.orderByReverse : false;
      vm.reverse = vm.orderByReverse ? -1 : 1;
      vm.orderByColumn = gSettings.get('barometer.weighted-average.column') || 'product-symbol';
    }

    /**
     * This function loads user settings.
     */
    function saveSettings() {
      gSettings.set('barometer.weighted-average.order', vm.orderByReverse);
      gSettings.set('barometer.weighted-average.column', vm.orderByColumn);
    }
  }
})();
