/**
 * Created by Sergey on 3/25/20.
 */

// @ts-check
(function btYouTubeServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btYouTubeService:';

  angular.module('ecapp').factory('btYouTubeService', service);

  service.$inject = ['$q'];

  /**
   * This service is a wrapper for YouTube Player API.
   * https://developers.google.com/youtube/iframe_api_reference
   *
   * @ngdoc service
   * @name ecapp.btYouTubeService
   * @param {angular.IQService} $q - promise
   * @return {ecapp.IYouTubeService}
   */
  function service($q) {
    if (gDebug) console.log(gPrefix, 'running...');

    var gInitializationPromise;

    return {
      init: init,
    };

    /**
     * This function does something.
     *
     * @name ecapp.btYouTubeService.init
     * @return {angular.IPromise<ext.YouTubePlayer>}
     */
    function init() {
      if (!gInitializationPromise) {
        var tag = document.createElement('script');

        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        var deferred = $q.defer();

        window.onYouTubeIframeAPIReady = function () {
          if (gDebug) console.log('YouTube API was initialized');
          deferred.resolve(window.YT);
        };

        gInitializationPromise = deferred.promise;
      }

      return gInitializationPromise;
    }
  }
})();
