/* global Tourguide, fbq */

(function btMainControllerClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btMainController';

  angular.module('ecapp').controller('btMainController', btMainController);

  btMainController.$inject = [
    '$scope',
    '$state',
    '$window',
    '$timeout',
    'btShareSocialService',
    'btTradingService',
    'btInitService',
    '$ionicSideMenuDelegate',
    'btTradingPlatformService',
    'btSettingsService',
    'btRestrictionService',
    'btMarketing',
    'btShareScopeService',
    'btToastrService',
    'btSubscriptionService',
    'btPushNotificationService',
    'btErrorService',
    'btAppFeatures',
  ];

  /**
   * Main controller
   *
   * @ngdoc controller
   * @name ecapp.btMainController
   * @param {ecapp.ICustomScope} $scope - ?
   * @param {angular.ui.IStateService} $state - ?
   * @param {angular.IWindowService} $window - window object service
   * @param {angular.ITimeoutService} $timeout - ?
   * @param {ecapp.IShareSocialService} btShareSocialService - ?
   * @param {ecapp.ITradingService} btTradingService - ?
   * @param {ecapp.IInitService} btInitService - ?
   * @param {ionic.ISideMenuDelegateService} $ionicSideMenuDelegate - ionic side menu delegate
   * @param {ecapp.ITradingPlatformService} btTradingPlatformService - ?
   * @param {ecapp.ISettingsService} btSettingsService - ?
   * @param {ecapp.IRestrictionService} btRestrictionService - ?
   * @param {ecapp.IMarketing} btMarketing - ?
   * @param {ecapp.IShareScopeService} btShareScopeService - ?
   * @param {ecapp.IToastrService} btToastrService - ?
   * @param {ecapp.ISubscriptionService} btSubscriptionService - ?
   * @param {ecapp.IPushNotificationService} btPushNotificationService - ?
   * @param {btUtils.btErrorService} btErrorService - ?
   * @param {ecapp.IAppFeatures} btAppFeatures - ?
   */
  function btMainController(
    $scope,
    $state,
    $window,
    $timeout,
    btShareSocialService,
    btTradingService,
    btInitService,
    $ionicSideMenuDelegate,
    btTradingPlatformService,
    btSettingsService,
    btRestrictionService,
    btMarketing,
    btShareScopeService,
    btToastrService,
    btSubscriptionService,
    btPushNotificationService,
    btErrorService,
    btAppFeatures
  ) {
    console.log('Running btMainController');

    try {
      if (window.fbq) {
        fbq('track', 'ViewContent', { content_name: 'AppPage' });
      }

      initializeTawk();

      var accountKeys = null;
      var gBlockerTimeout;
      var gBannerTimeout;

      $scope.errorCounter = btErrorService.getErrorCounter();

      $scope.logoSrc = btSettingsService.getAssets('header-logo-src');
      $scope.logoAlt = btSettingsService.getAssets('header-logo-alt');
      $scope.openAccountHref = btSettingsService.getAssets('open-account-href');

      $scope.brokerData = null;
      $scope.isPractice = false;
      $scope.isDesktop = !(window.isMobileWeb || window.isMobile);

      $scope.isBetterTrader = btSettingsService.domain === 'bettertrader';
      $scope.isBigBrainBank = btSettingsService.domain === 'bigbrainbank';
      $scope.isOptimus = btSettingsService.domain === 'optimusfutures';

      $scope.hasOpenAccount = $scope.isOptimus && !btRestrictionService.hasFeature('personalization');
      $scope.hasWebinar =
        ($scope.isBigBrainBank || btRestrictionService.hasFeature('personalization')) &&
        !btSettingsService.isLinkDataService();

      $scope.hasPersonalization = btRestrictionService.hasFeature('personalization');
      $scope.hasTrading = btSettingsService.hasFeature(btAppFeatures.Trading);
      $scope.hasReferral = btSettingsService.hasFeature(btAppFeatures.Referral);
      $scope.hasDevPage = btSettingsService.hasFeature('dev-page');
      $scope.hasProPlan = btSettingsService.hasFeature('backtesting');
      $scope.appVersion = window.mainVersion;

      $scope.hasVoiceFeature = function () {
        return btRestrictionService.hasFeature('voice-assistant');
      };

      $scope.positionsCount = 0;
      $scope.ordersCount = 0;
      $scope.openPL = null;
      $scope.tradingPlatform = btTradingPlatformService.status;

      $scope.isChrome = window.isChrome;

      var tour = new Tourguide({
        steps: [
          {
            selector: null,
            step: 1,
            title: 'Introduction',
            content: 'Introduction',
          },
          {
            selector: 'a.bt-main-markets',
            step: 2,
            title: 'Markets',
            content: 'Markets',
          },
          {
            selector: 'a.bt-main-calendar',
            step: 3,
            title: 'Calendar',
            content: 'Calendar',
          },
          {
            selector: 'a.bt-main-barometer',
            step: 4,
            title: 'Barometer',
            content: 'Barometer',
          },
          {
            selector: 'a.bt-main-tradeideas',
            step: 5,
            title: 'TradeIdeas',
            content: 'TradeIdeas',
          },
          {
            selector: 'a.bt-main-ro-ro',
            step: 6,
            title: 'Risk On/Risk Off',
            content: 'Risk On/Risk Off',
          },
          {
            selector: 'a.bt-tab-charts',
            step: 7,
            title: 'Charts',
            content: 'Charts',
          },
          {
            selector: 'a.bt-tab-backtester',
            step: 8,
            title: 'BackTester',
            content: 'BackTester',
          },
          {
            selector: 'a.bt-tab-brokers',
            step: 9,
            title: 'Brokers',
            content: 'Brokers',
          },
          {
            selector: 'a.bt-tab-info-hub',
            step: 10,
            title: 'InfoHub',
            content: 'InfoHub',
          },
          {
            selector: 'a.bt-tab-twitter',
            step: 11,
            title: 'Twitter',
            content: 'Twitter',
          },
          {
            selector: 'a.bt-tab-pro-member',
            step: 12,
            title: 'ProMember',
            content: 'ProMember',
          },
          {
            selector: '#bt-help-tabs',
            step: 13,
            title: 'Help Center',
            content: 'Help Center',
          },
          {
            selector: '#bt-notification-center-tabs',
            step: 14,
            title: 'Notifications',
            content: 'Notifications',
          },
          {
            selector: null,
            step: 15,
            title: 'End',
            content: 'End',
          },
        ],
      });

      var desktopTour = new Tourguide({
        steps: [
          {
            selector: null,
            step: 1,
            title: 'Lets take a moment and look around Docsie library',
            content:
              'Click a button to advance to the next step of this tour.<br/> To stop this tour at any time click a button in the top-right corner.',
            image: 'https://somehost.com/image.jpeg',
          },
          {
            selector: null,
            step: 2,
            title: 'Shelf',
            content:
              'Just like a real library &lt;mark&gt;Docsie&lt;/mark&gt; starts with &lt;dfn&gt;shelves&lt;/dfn&gt;. Each &lt;dfn&gt;shelf&lt;/dfn&gt; represents a separate collection of ideas. You may think of them as individual websites, or website sections.',
          },
        ],
      });

      var mobileTour = new Tourguide({
        steps: [
          {
            selector: null,
            step: 1,
            title: 'Lets take a moment and look around Docsie library',
            content:
              'Click a button to advance to the next step of this tour.<br/> To stop this tour at any time click a button in the top-right corner.',
            image: 'https://somehost.com/image.jpeg',
          },
          {
            selector: null,
            step: 2,
            title: 'Shelf',
            content:
              'Just like a real library &lt;mark&gt;Docsie&lt;/mark&gt; starts with &lt;dfn&gt;shelves&lt;/dfn&gt;. Each &lt;dfn&gt;shelf&lt;/dfn&gt; represents a separate collection of ideas. You may think of them as individual websites, or website sections.',
          },
        ],
      });

      btInitService.start();

      $scope.openIntercom = openIntercom;
      $scope.openModalShareDialog = openModalShareDialog;
      $scope.openRateAppDialog = openRateAppDialog;
      $scope.openTradingPlatform = openTradingPlatform;
      $scope.disconnectTradingPlatform = disconnectTradingPlatform;
      $scope.disconnectBroker = disconnectBroker;
      $scope.isTabHidden = isTabHidden;
      $scope.openChat = openChat;
      $scope.openCommunity = openCommunity;
      $scope.isUpgradePopupOpened = false;
      $scope.startTour = startTour;
      $scope.openDesktopTour = openDesktopTour;
      $scope.openMobileTour = openMobileTour;

      // TODO: Remove this code
      $scope.$on('broker:connected', onBrokerConnect);
      $scope.$on('broker:disconnected', onBrokerDisconnect);
      $scope.$on('subscription:updated', onSubscriptionUpgradeSuccess);
      $scope.$on('$destroy', onDestroy);

      $scope.$watch(isMenuOpen, onMenuOpen);

      // Show banner
      gBannerTimeout = $timeout(function () {
        // console.log('TEST: Try to open global banner');
        btShareScopeService.wait().then(function () {
          if (btShareScopeService.accountInfo.tutorialWasFinished) {
            btPushNotificationService.getSubscriptionState().then(function (value) {
              var banner = btPushNotificationService.getBannerConfig(value);
              if (banner) {
                // console.log('TEST: Open notification banner');
                btSettingsService.openGlobalBanner(banner);
              } else {
                btShareScopeService.getInviteBannerConfig().then(function (banner) {
                  // console.log('TEST: Open invite banner');
                  if (banner) btSettingsService.openGlobalBanner(banner);
                });
              }
            });
          }
        });
      }, 15000);

      btTradingService
        .initialize()
        .then(btTradingService.connect)
        .then(btTradingPlatformService.initialize)
        .then(function () {
          showTrialReminder();
          showUpgradeReminder();
          showBlocker();
        })
        .catch(function (err) {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }

    /**
     *
     */
    function initializeTawk() {
      if (window.Tawk_API && window.isApp) {
        setTimeout(function () {
          window.Tawk_API.hideWidget();
        }, 100);

        window.TawkOnChatMinimized = function () {
          setTimeout(function () {
            window.Tawk_API.hideWidget();
          }, 100);
        };

        window.TawkOnChatEnded = function () {
          setTimeout(function () {
            window.Tawk_API.hideWidget();
          }, 100);
        };
      }
    }

    /**
     *
     * @return {*}
     */
    function showTrialReminder() {
      if (btSubscriptionService.checkIsTrial()) {
        var data = btSubscriptionService.getDaysLeftObject(
          btSubscriptionService.getCombinedSubscription(),
          btShareScopeService.getAccountSettings()
        );
        btToastrService.info('Enjoy the next ' + data.text + ' with your free trial.', 'Trial', {
          toastClass: 'toast toast-upgrade',
          timeOut: 60000,
          extendedTimeOut: 10000,
        });

        return true;
      } else {
        return false;
      }
    }

    /**
     *
     * @return {*}
     */
    function showUpgradeReminder() {
      if (btSubscriptionService.hasUpgradeReminder()) {
        if (btSettingsService.isBigBrainBank()) {
          showBrainUpgradeReminder();
        } else {
          showCommonUpgradeReminder();
        }

        return true;
      } else {
        return false;
      }
    }

    /**
     *
     */
    function showBrainUpgradeReminder() {
      setTimeout(function () {
        btSubscriptionService.showBrainUpgradeReminder().catch(function (reason) {
          btToastrService.error(reason.message, 'Upgrade Issue');
        });
      }, 10 * 60000);
    }

    /**
     *
     */
    function showCommonUpgradeReminder() {
      var hours = btSubscriptionService.getHoursRemain().toLocaleString();
      var msg =
        'Upgrade right now and get a discount. This offer is available till ' + hours + '. Click here to see details.';
      var params = {
        toastClass: 'toast toast-upgrade',
        timeOut: 0,
        extendedTimeOut: 0,
        tapToDismiss: false,
        onTap: showUpgradePopup,
      };

      $timeout(function () {
        btToastrService.info(msg, 'Special Offer', params);
      }, 1000);
    }

    /**
     *
     */
    function showUpgradePopup() {
      if (!$scope.isUpgradePopupOpened) {
        $scope.isUpgradePopupOpened = true;

        btSubscriptionService
          .showUpgradeReminder()
          .then(function () {
            $scope.isUpgradePopupOpened = false;
          })
          .catch(function (reason) {
            $scope.isUpgradePopupOpened = false;
            btToastrService.error(reason.message, 'Upgrade Issue');
          });
      }
    }

    /**
     *
     * @return {*}
     */
    function showBlocker() {
      if (!btRestrictionService.hasFeature('approval')) {
        if (gDebug) console.log(gPrefix, 'DEBUG: Waiting for blocker');

        gBlockerTimeout = $timeout(function () {
          $scope.blocker = btRestrictionService.showSelectPlanBlocker(null);
        }, 4000);

        return true;
      } else {
        return false;
      }
    }

    /**
     *
     */
    function onDestroy() {
      if (gDebug) console.log(gPrefix, 'DEBUG: Destroying btMainController. Blocker:', $scope.blocker);
      if (gBlockerTimeout) $timeout.cancel(gBlockerTimeout);
      if (gBannerTimeout) $timeout.cancel(gBannerTimeout);
      if ($scope.blocker) $scope.blocker.remove();

      window.TawkOnChatMinimized = undefined;
      window.TawkOnChatEnded = undefined;
    }

    /**
     * This function reacts on subscription update.
     */
    function onSubscriptionUpgradeSuccess() {
      $scope.hasOpenAccount =
        btSettingsService.hasFeature('white-label') && !btRestrictionService.hasFeature('personalization');
      $scope.hasPersonalization = btRestrictionService.hasFeature('personalization');
    }

    /**
     *
     * @return {*}
     */
    function isMenuOpen() {
      return $ionicSideMenuDelegate.isOpen();
    }

    /**
     *
     * @param {*} isOpen - ?
     */
    function onMenuOpen(isOpen) {
      if (isOpen) {
        if (window.cordova && cordova.plugins && cordova.plugins.Keyboard) {
          cordova.plugins.Keyboard.close();
        }
        updateBrokerData();
      }
    }

    /**
     *
     * @param {*} event - ?
     */
    function onBrokerConnect(event) {
      void event;
    }

    /**
     *
     */
    function onBrokerDisconnect() {
      $scope.brokerData = null;
      $scope.isPractice = false;
      $scope.positionsCount = 0;
      $scope.ordersCount = 0;
      $scope.openPL = null;
    }

    /**
     *
     * @return {*}
     */
    function openTradingPlatform() {
      return btTradingPlatformService.open();
    }

    /**
     *
     */
    function disconnectTradingPlatform() {
      btTradingPlatformService.open().then(btTradingPlatformService.disconnect);
    }

    /**
     *
     */
    function updateBrokerData() {
      if (!btTradingService.isDefaultBroker()) {
        $scope.brokerData = btTradingService.getBrokerInfo();
        $scope.isPractice = btTradingService.isPractice();
        if (btTradingService.getBrokerName() !== 'ctrader') {
          btTradingService.getAccounts().then(function (accounts) {
            accountKeys = accounts.map(function (a) {
              return a.key;
            });
            btTradingService.getPositions(accountKeys).then(function (results) {
              $scope.positionsCount = results.length;
            });
            btTradingService.getOrders(accountKeys).then(function (results) {
              $scope.ordersCount = results.filter(function (result) {
                return result.status === 'Received' || result.status === 'Queued';
              }).length;
            });
            // FixMe
            btTradingService.getBalances(accountKeys).then(function (results) {
              var balances = results.filter(function (result) {
                return btTradingService.isAccountSelected(result.key);
              });

              $scope.openPL = balances.length === 1 ? balances[0].UPL : 'N/A';
            });
          });
        }
      }
    }

    /**
     *
     */
    function openIntercom() {
      btMarketing.askQuestion();
    }

    /**
     *
     */
    function openModalShareDialog() {
      var title = 'Join me on the BetterTrader app';
      var content = 'Join the BetterTrader App community:\r\nhttps://bettertrader.co';
      btShareSocialService.popupMultiShare(title, title, content, 'https://bettertrader.co');
    }

    /**
     *
     */
    function openRateAppDialog() {
      ionic.Platform.ready(function () {
        if ($window.AppRate) $window.AppRate.promptForRating(true);
      });
    }

    /**
     *
     * @param {string} name
     * @return {boolean}
     */
    function isTabHidden(name) {
      return !btSettingsService.hasMainTab(name);
    }

    /**
     * This function opens mobile chat.
     */
    function openChat() {
      // if (btRestrictionService.hasFeature('community')) {
      $state.go('ecapp.app.chat');
      // } else {
      //   btRestrictionService.showUpgradePopup('community');
      // }
    }

    /**
     * This function opens mobile chat.
     */
    function openCommunity() {
      // if (btRestrictionService.hasFeature('community')) {
      $state.go('ecapp.app.community');
      // } else {
      //   btRestrictionService.showUpgradePopup('community');
      // }
    }

    /**
     *
     */
    function startTour() {
      tour.start();
    }

    /**
     *
     */
    function openDesktopTour() {
      desktopTour.start();
    }

    /**
     *
     */
    function openMobileTour() {
      mobileTour.start();
    }

    /**
     *
     */
    function disconnectBroker() {
      btTradingService.disconnect();
    }
  }
})();
