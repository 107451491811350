/**
 * Created by Sergey on x/xx/xxxx.
 */

// @ts-check
(function btBadgeServiceClosure() {
  'use strict';

  angular.module('btUtils').factory('btBadgeService', btBadgeService);

  btBadgeService.$inject = ['$timeout'];

  /**
   * This factory change app badges via cordova plugin.
   *
   * @ngdoc service
   * @name btBadgeService
   * @memberOf btUtils
   * @param {angular.ITimeoutService} $timeout - ?
   * @return {ecapp.IBadgeService}
   */
  function btBadgeService($timeout) {
    /**
     *
     * @param {*} number - ?
     */
    function setBadge(number) {
      if (_isSupported()) {
        window.cordova.plugins.notification.badge.set(number);
      }
    }

    /**
     *
     */
    function resetBadge() {
      if (_isSupported()) {
        window.cordova.plugins.notification.badge.set(0);
      }
    }

    /**
     *
     */
    function handlePush() {
      if (_isSupported()) {
        window.cordova.plugins.notification.badge.increase();
      }
    }

    /**
     *
     */
    function increase() {
      if (_isSupported()) {
        window.cordova.plugins.notification.badge.set(1);
        $timeout(function () {
          window.cordova.plugins.notification.badge.increase();
        }, 5000);
      }
    }

    /**
     *
     * @return {boolean}
     */
    function _isSupported() {
      return (
        window.cordova &&
        window.cordova.plugins &&
        window.cordova.plugins.notification &&
        window.cordova.plugins.notification.badge
      );
    }

    return {
      setBadge: setBadge,
      resetBadge: resetBadge,
      handlePush: handlePush,
      increase: increase,
    };
  }
})();
