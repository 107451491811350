/**
 * Created by Sergey Panpurin on 6/20/2017.
 */

(function btTestControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btTestController', btTestController);

  btTestController.$inject = ['$q', '$scope', 'btStrengthService'];

  /**
   * This is controller testing.
   *
   * @ngdoc controller
   * @name btTestController
   * @memberOf ecapp
   * @param {angular.IQService} $q - promise interface
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {ecapp.IStrengthService} btStrengthService
   */
  function btTestController($q, $scope, btStrengthService) {
    console.log('Running btTestController');

    console.log('Test btStrengthService.limits:');
    var thr0 = 15;
    var testData0 = [1.11, 1.01, 0.91, 0.61, 0.21, 0.11, 0.01, 0.39929362655807793, 0.684314684684];
    testData0.forEach(function (i) {
      console.log(-i, '+-' + thr0 / 100, btStrengthService.limits(-i, thr0).toString());
      console.log(+i, '+-' + thr0 / 100, btStrengthService.limits(+i, thr0).toString());
    });

    console.log('Test function strength to text:');
    var testData1 = [110, 100, 80, 60, 40, 20, 10, 0, -10, -20, -40, -60, -80, -100, -110];
    testData1.forEach(function (i) {
      console.log(i, ' - ', btStrengthService.toText(i / 100, 0));
    });

    $scope.textOnClick = ':)';

    $scope.doSomethingOnClick = doSomethingOnClick;

    testPromise();

    /**
     *
     * @param {*} text
     */
    function doSomethingOnClick(text) {
      $scope.textOnClick = text;
    }

    /**
     *
     */
    function testPromise() {
      /**
       *
       * @return {any}
       */
      function resolvePromise() {
        var deferred = $q.defer();

        setTimeout(function () {
          deferred.resolve('Resolved');
        }, 2000);

        return deferred.promise;
      }

      /**
       *
       * @return {any}
       */
      function rejectPromise() {
        var deferred = $q.defer();

        setTimeout(function () {
          deferred.reject(new Error('Rejected'));
        }, 2000);

        return deferred.promise;
      }

      /**
       *
       */
      function multipleThen() {
        var name1 = 'Test 1. Good promise chain: ';
        var promise1 = resolvePromise();

        promise1
          .then(function (value) {
            alert(name1 + value);
            return value;
          })
          .then(function (value) {
            alert(name1 + value);
            return value;
          });

        var name2 = 'Test 2. Broken promise chain: ';
        var promise2 = resolvePromise();

        promise2
          .then(function (value) {
            alert(name2 + value);
          })
          .then(function (value) {
            alert(name2 + value);
          });

        var name3 = 'Test 3. Call then in parallel: ';
        var promise3 = resolvePromise();

        promise3.then(function (value) {
          alert(name3 + value);
          return 'New value 1';
        });

        promise3.then(function (value) {
          alert(name3 + value);
          return 'New value 2';
        });

        setTimeout(function () {
          promise3.then(function (value) {
            alert(name3 + value);
            return 'New value 3';
          });
        }, 4000);

        var name4 = 'Test 4. Call then in parallel: ';
        var promise4 = rejectPromise();

        promise4
          .then(function (value) {
            alert(name4 + value);
            return 'New value 1';
          })
          .catch(function (reason) {
            alert(name4 + reason);
          });

        promise4
          .then(function (value) {
            alert(name4 + value);
            return 'New value 2';
          })
          .catch(function (reason) {
            alert(name4 + reason);
          });

        setTimeout(function () {
          promise4
            .then(function (value) {
              alert(name4 + value);
              return 'New value 3';
            })
            .catch(function (reason) {
              alert(name4 + reason);
            });
        }, 4000);
      }

      multipleThen();
    }
  }
})();
