/**
 * Created by Itay on 6/21/2016.
 */

(function btStatusColorClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive is attribute directive which gives the correct color class to its element
     *
     * @ngdoc directive
     * @name btStatusColor
     * @memberOf ecapp
     */
    .directive('btStatusColor', [
      function () {
        return {
          scope: {
            strength: '=btStatusColor',
          },
          restrict: 'A',
          link: function (scope, element, attrs) {
            void attrs;

            /**
             * This function adds the required status class
             *
             * @param {*} strength
             * @param {*} element
             */
            function addStatusClass(strength, element) {
              // first we remove all previous status classes in order to avoid conflicts
              removeAllStatusClasses(element);

              // if the strength is undefined, there is no procedure to do.
              if (strength == undefined) return;

              // decides what class to add by strength status.
              switch (strength.status.toLowerCase()) {
                case 'buy':
                  element.addClass('positive');
                  break;
                case 'sell':
                  element.addClass('negative');
                  break;
                case 'asexpected':
                  element.addClass('neutral');
                  break;
              }
            }

            /**
             *
             * @param {*} element
             */
            function removeAllStatusClasses(element) {
              element.removeClass('positive negative neutral');
            }

            // watching on updates, so when something changes the correct color is added.
            scope.$watchCollection('strength', function (newValue, oldValue) {
              void oldValue;
              addStatusClass(newValue, angular.element(element));
            });
          },
        };
      },
    ])
    /**
     * Use number to determinate class of element: positive, negative or neutral.
     * Use skipNeutral to skip adding of neutral class to element.
     *
     * @ngdoc controller
     * @name btColoredNumber
     * @memberOf dashboard
     */
    .directive('btColoredNumber', [
      '$timeout',
      function ($timeout) {
        return {
          restrict: 'AE',
          scope: {
            number: '@',
            skipNeutral: '@?',
            flashColor: '@?',
          },
          controller: [
            '$scope',
            function ($scope) {
              // set default value to skipNeutral to false
              $scope.skipNeutral = $scope.skipNeutral || 'false';
              $scope.flashColor = $scope.flashColor || 'false';
            },
          ],
          link: function (scope, el, attrs) {
            void attrs;
            scope.$watch('number', function (newValue, oldValue) {
              void oldValue;
              setClass(angular.element(el), parseFloat(newValue), scope.skipNeutral === 'true');
              if (scope.flashColor === 'true') {
                $timeout(
                  function () {
                    setClass(angular.element(el), 0.0, true);
                  },
                  500,
                  false
                );
              }
            });

            /**
             * Set class ot element
             * @param {Object} element - angular reference to element
             * @param {Number} value - numerical value
             * @param {Boolean} skipNeutral - stay neutral without class
             */
            function setClass(element, value, skipNeutral) {
              if (value > 0) {
                element.addClass('positive');
                element.removeClass('negative neutral');
              } else if (value < 0) {
                element.addClass('negative');
                element.removeClass('positive neutral');
              } else {
                element.removeClass('positive negative');
                if (!skipNeutral) {
                  element.addClass('neutral');
                }
              }
            }
          },
        };
      },
    ]);
})();
