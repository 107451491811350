/**
 * Created by Sergey Panpurin on 7/24/19.
 */

// @ts-check
(function btAdvertisingServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btAdvertisingService:';

  /**
   * @memberOf ecapp
   * @ngdoc service
   * @name btAdvertisingService
   * @description
   *  This factory ...
   */
  angular.module('ecapp').factory('btAdvertisingService', service);

  service.$inject = [
    '$q',
    '$sce',
    '$rootScope',
    'btErrorService',
    'btShareScopeService',
    'btSubscriptionService',
    'btRestrictionService',
    'btSettingsService',
  ];

  /**
   * @param {angular.IQService} $q - promise interface
   * @param {angular.ISCEService} $sce
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.IErrorService} btErrorService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.ISubscriptionService} btSubscriptionService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.ISettingsService} btSettingsService
   * @return {ecapp.IAdvertisingService}
   */
  function service(
    $q,
    $sce,
    $rootScope,
    btErrorService,
    btShareScopeService,
    btSubscriptionService,
    btRestrictionService,
    btSettingsService
  ) {
    if (gDebug) console.log(gPrefix, 'running...');

    /** @type {string} */
    var gMockUserCountry;
    // localStorage.setItem('btMockUserCountry', 'GBR');

    /** @type {object} */
    var gMockUserSettings;
    // localStorage.setItem('btMockUserSettings', '{"optimus":false,"free-user":true,"new-user":true,"has-trial":false,"optimus-funded":false,"has-broker":false,"has-personalization":true}');

    try {
      gMockUserCountry = localStorage.getItem('btMockUserCountry');
      gMockUserSettings = JSON.parse(localStorage.getItem('btMockUserSettings'));
      if (gDebug) console.log(gPrefix, 'btMockUserSettings', gMockUserSettings);
    } catch (e) {
      void e;
      gMockUserSettings = null;
    }

    /**
     * @typedef {object} btAdObject
     * @property {string} id - ad identifier
     * @property {string} title - ad title
     * @property {string} [content] - ad content as html
     * @property {btAdButton[]} buttons - ad content as html
     */

    /**
     * @typedef {object} btAdButton
     * @property {string} id - ad identifier
     * @property {string} name - ad identifier
     * @property {string} next - ad identifier
     * @property {string} ad - ad identifier
     */

    /**
     *
     * @type {btAdObject[]}
     */
    var gAds = [
      {
        id: 'question-trading-account',
        title: 'Do you have a trading account?',
        buttons: [
          {
            id: 'yes',
            name: 'Yes',
            next: 'question-trading-account-yes',
            ad: 'upgrade-pro',
          },
          {
            id: 'no',
            name: 'No',
            next: 'question-trading-account-no',
            ad: null,
          },
        ],
      },
      {
        id: 'question-trading-account-yes',
        title: 'Thank you!',
        buttons: [],
      },
      {
        id: 'question-trading-account-no',
        title: 'Thank you!',
        buttons: [],
      },
      {
        id: 'upgrade-pro',
        title:
          btSettingsService.domain === 'bigbrainbank'
            ? 'Check out TheBrain by BigBrainBank'
            : 'Become a BetterTrader with a Pro Plan',
        buttons: [],
      },
      {
        id: 'upgrade-pro-charts',
        title:
          btSettingsService.domain === 'bigbrainbank'
            ? 'Check out TheBrain by BigBrainBank'
            : 'Become a BetterTrader with a Pro Plan',
        buttons: [],
      },
      {
        id: 'calendar-case-study',
        title: 'How to Prepare for Economic Events',
        buttons: [],
      },
    ];

    /**
     * App advertising settings
     * @type {any}
     */
    var gSettings = {};

    /** Day limits */
    var gDaysLimit = 30;

    /** @type {angular.IPromise<any>} */
    var gInitializationPromise;

    /** @type {Partial<ecapp.IIntercomAttributes>} */
    var gAttributes = {};

    /**
     * Calculated advertising settings
     * @type {any}
     */
    var gAdSettings = null;

    /**
     * Active Banners
     * @type {any}
     */
    var gActive = null;

    // AVA Trade
    // Hide:
    // USA - USA
    // ISR - Israel
    // BEL - Belgium
    // NZL - New Zealand
    //
    // Replace:
    // CAN - Canada
    //
    // Show:
    // * - All other countries

    /**
     * This service ...
     *
     * @ngdoc service
     * @name ecapp.btAdvertisingService
     */
    return {
      reset: reset,
      initialize: initialize,
      getActiveAdvertising: getActiveAdvertising,
      selectInitialAd: selectInitialAd,
      processButtonClick: processButtonClick,
      checkHasAdvertising: checkHasAdvertising,
      // getAVATradeSettings: getAVATradeSettings,
      getADSettings: getADSettings,
      getBannerSettings: getBannerSettings,
      getUserRegion: getUserRegion,
      validateUserRegion: validateUserRegion,
    };

    /**
     * Returns user region.
     *
     * @return {string} user region
     */
    function getUserRegion() {
      if (gMockUserCountry) {
        return gMockUserCountry.toLowerCase();
      } else {
        return gAttributes.country ? gAttributes.country.toLowerCase() : 'unknown';
      }
    }

    /**
     * Validates region.
     *
     * @param {{include: string[], exclude: string[]}} settings
     * @param {*} region
     * @return {*}
     */
    function validateUserRegion(settings, region) {
      if (settings) {
        if (settings.include && settings.include.indexOf(region) === -1) {
          return false;
        }

        if (settings.exclude && settings.exclude.indexOf(region) !== -1) {
          return false;
        }
      }

      return true;
    }

    /**
     * Controller constructor
     * @alias ecapp.btAdvertisingService#initialize
     * @return {angular.IPromise<any>}
     */
    function initialize() {
      if (gDebug) console.log(gPrefix, 'initialized');

      if (!gInitializationPromise) {
        gInitializationPromise = btShareScopeService
          .wait()
          .then(btShareScopeService.getIntercomAttributes)
          .then(function (attributes) {
            gAttributes = attributes || {};
            gSettings = btShareScopeService.getUserSettings('advertising', {});

            return btShareScopeService.getAppSettings('advertising');
          })
          .then(function (settings) {
            gActive = parseActiveAdvertising(settings);

            gAdSettings = btSettingsService.validate(settings, {
              avatrade: { enabled: 'boolean|false' },
              cfx: {
                enabled: 'boolean|false',
                web: {
                  enabled: 'boolean|false',
                  link: 'string|',
                  bottom: 'string|',
                  card: 'string|',
                  image: 'string|',
                },
                app: {
                  enabled: 'boolean|false',
                  link: 'string|',
                  bottom: 'string|',
                  card: 'string|',
                  image: 'string|',
                },
              },
              ig: {
                enabled: 'boolean|false',
                web: { enabled: 'boolean|false', link: 'string|', image: 'string|' },
                app: { enabled: 'boolean|false', link: 'string|', image: 'string|' },
              },
            });

            $rootScope.hasAdBanner = (hasIGAds() || hasCFXAds() || !!gActive) && check('free-user');
          })
          .catch(function (reason) {
            if (gDebug) console.error(reason);
            return $q.reject(reason);
          });
      }

      return gInitializationPromise;
    }

    /**
     *
     * @param {*} settings
     * @return {*}
     */
    function parseActiveAdvertising(settings) {
      var active = null;
      var region = getUserRegion();

      Object.keys(settings).forEach(function (key) {
        if (settings[key].enabled) {
          var item = window.isApp ? settings[key].app : settings[key].web;

          if (item.enabled && validateUserRegion(settings[key].region, region)) {
            if (!settings[key].version) {
              active = {
                id: key,
                banners: {
                  broker: {
                    title: item.name,
                    link: item.link,
                    image: item.logo,
                  },
                  side: null,
                  bottom: {
                    link: item.link,
                    image: { small: item.image + '/320x100.png', large: item.image + '/970x90.png' },
                  },
                  card: {
                    link: item.link,
                    image: item.image + '/320x100.png',
                  },
                },
              };
            } else {
              active = { id: key, banners: item };
            }
          }
        }
      });

      return active;
    }

    /**
     *
     * @return {*}
     */
    function getActiveAdvertising() {
      return gActive;
    }

    /**
     *
     * @return {boolean}
     */
    function hasIGAds() {
      return btSettingsService.isBetterTrader() && !$rootScope.isTradeStation && check('ig') && check('usa');
    }

    /**
     *
     * @return {boolean}
     */
    function hasCFXAds() {
      return btSettingsService.isBetterTrader() && !$rootScope.isTradeStation && check('cfx') && !check('usa');
    }

    /**
     *
     * @alias ecapp.btAdvertisingService#selectInitialAd
     * @param {string} type
     * @param {string} place
     * @return {?btAdObject}
     */
    function selectInitialAd(type, place) {
      var ad = getAdById(type);
      if (gDebug) console.log(gPrefix, 'Select initial ad for', type, place);

      if (!check('has-personalization')) {
        if (gDebug) console.log(gPrefix, 'Skip advertising in shared version.');
        return null;
      }

      if (ad === null) {
        if (gDebug) console.log(gPrefix, 'Unknown advertising:', type);
        return null;
      }

      if (type === 'question-trading-account') {
        // Question about trading account

        if (check('optimus')) {
          if (gDebug) console.log(gPrefix, 'Optimus Futures');
          // Special rules for optimus
          if (check('optimus-funded')) {
            // Funded user already has account.
            saveBrokerSettings(true);
            if (gDebug) console.log(gPrefix, 'Funded');
            return selectUserAd(place);
          } else {
            // Skip advertising in shared version.
            if (gDebug) console.log(gPrefix, 'Skip advertising in shared version.');
            return null;
          }
        }

        if (check('bigbrainbank')) {
          if (gDebug) console.log(gPrefix, 'BigBrainBank');
          // Special rules for optimus
          if (check('bigbrainbank-funded')) {
            // Funded user already has account.
            saveBrokerSettings(true);
            if (gDebug) console.log(gPrefix, 'Funded');
            return selectUserAd(place);
          } else {
            // Skip advertising in shared version.
            if (gDebug) console.log(gPrefix, 'Skip advertising in shared version.');
            return null;
          }
        }

        if (gSettings[type] === undefined) {
          if (gDebug) console.log(gPrefix, 'Has no answer');
          // User don't have a answer to question
          if (gAttributes && gAttributes.broker !== null) {
            if (gDebug) console.log(gPrefix, 'Intercom');
            // Use intercom answer
            saveBrokerSettings(gAttributes.broker);
            if (gDebug) console.log(gPrefix, 'Intercom answer.');
            return selectUserAd(place);
          } else {
            if (gDebug) console.log(gPrefix, 'Show');
            // All users should answer to this question
            return ad;
          }
        } else {
          if (gDebug) console.log(gPrefix, 'Has answer');
          return selectUserAd(place);
        }
      }

      if (gDebug) console.log(gPrefix, 'Skip');
      return null;
    }

    /**
     *
     * @param {*} place - ?
     * @return {null|?btAdObject|*}
     */
    function selectUserAd(place) {
      if (check('free-user')) {
        if (place === 'chart') {
          if (check('has-trial') && place === 'chart') {
            if (gDebug) console.log(gPrefix, 'Trial and chart');
            return null;
          } else {
            if (gDebug) console.log(gPrefix, 'Upgrade and chart');
            return getAdById('upgrade-pro-charts');
          }
        } else if (place === 'barometer' || place === 'calendar') {
          if (check('new-user')) {
            if (gDebug) console.log(gPrefix, 'New and ' + place);
            return place === 'calendar' ? getAdById('calendar-case-study') : null;
          } else {
            if (gDebug) console.log(gPrefix, 'Upgrade and ' + place);
            return getAdById('upgrade-pro');
          }
        } else {
          if (gDebug) console.log(gPrefix, 'Upgrade another.');
          return getAdById('upgrade-pro');
        }
      } else {
        if (gDebug) console.log(gPrefix, 'Paid');
        return null;
      }
    }

    /**
     *
     * @param {*} hasBroker - ?
     */
    function saveBrokerSettings(hasBroker) {
      if (hasBroker !== null) {
        if (gAttributes.broker === null) {
          gAttributes.broker = hasBroker;
          window.Intercom('update', { has_broker: getIntercomHasBrokerText(gAttributes.broker) });
        }
        gSettings['question-trading-account'] = hasBroker ? 'yes' : 'no';
        btShareScopeService.saveUserSettings('advertising', gSettings);
      }
    }

    /**
     *
     * @alias ecapp.btAdvertisingService#processButtonClick
     * @param {*} ad - ?
     * @param {*} button - ?
     * @return {angular.IPromise<*>|*}
     */
    function processButtonClick(ad, button) {
      if (gDebug) console.log(gPrefix, 'button clicked', ad, button);

      gSettings[ad.id] = button.id;

      if (ad.id === 'question-trading-account' && window.Intercom && window.intercomSettings) {
        gAttributes.broker = button.id === 'yes';
        window.Intercom('update', { has_broker: getIntercomHasBrokerText(gAttributes.broker) });
      }

      return btShareScopeService.saveUserSettings('advertising', gSettings).then(function () {
        if (button.next) {
          $rootScope.$broadcast('advertising:answer', getAdById(button.next), ad);
          return getAdById(button.next);
        }
        return null;
      });
    }

    /**
     *
     * @param {*} hasBroker - ?
     * @return {string}
     */
    function getIntercomHasBrokerText(hasBroker) {
      return hasBroker ? 'Yes' : 'Not yet';
    }

    /**
     *
     * @param {*} id - ?
     * @return {?btAdObject}
     */
    function getAdById(id) {
      return (
        gAds.filter(function (ad) {
          return ad.id === id;
        })[0] || null
      );
    }

    /**
     *
     * @param {string} type
     * @return {*}
     */
    function check(type) {
      if (gMockUserSettings) return gMockUserSettings[type];

      switch (type) {
        case 'usa':
          return gMockUserCountry ? gMockUserCountry === 'USA' : gAttributes.country === 'USA';
        case 'ig':
          if (window.isApp) {
            return gAdSettings && gAdSettings.ig.enabled && gAdSettings.ig.app.enabled;
          } else {
            return gAdSettings && gAdSettings.ig.enabled && gAdSettings.ig.web.enabled;
          }
        case 'cfx':
          if (window.isApp) {
            return gAdSettings && gAdSettings.cfx.enabled && gAdSettings.cfx.app.enabled;
          } else {
            return gAdSettings && gAdSettings.cfx.enabled && gAdSettings.cfx.web.enabled;
          }
        case 'bigbrainbank':
          return btSettingsService.domain === 'bigbrainbank';
        case 'optimus':
          return btSettingsService.domain === 'optimusfutures';
        case 'free-user':
          return !(
            btSubscriptionService.checkHasPaidSubscription() ||
            btSubscriptionService.checkIsSpecial() ||
            btSubscriptionService.checkIsBigBrainBankFunded() ||
            btSubscriptionService.checkIsOptimusFunded()
          );
        case 'new-user':
          return btShareScopeService.getDaysSince('created') < gDaysLimit || true;
        case 'has-trial':
          return btSubscriptionService.checkIsTrial();
        case 'bigbrainbank-funded':
          return btSubscriptionService.checkIsBigBrainBankFunded();
        case 'optimus-funded':
          return btSubscriptionService.checkIsOptimusFunded();
        case 'has-broker':
          return gAttributes && gAttributes.broker;
        case 'has-broker-answer':
          return gAttributes && typeof gAttributes.broker === 'boolean';
        case 'has-personalization':
          return btRestrictionService.hasFeature('personalization');
        default:
          return undefined;
      }
    }

    /**
     * @typedef {object} btBannerSettings
     * @property {string} id - id
     * @property {string} [logo] - log
     * @property {string} [name] - name
     * @property {string} image - image
     * @property {string} [title] - title
     * @property {string} [html] - html
     * @property {boolean} [enabled] - enabled
     * @property {boolean} [banner] - indicates whether banner should be shown
     * @property {boolean} [account] - indicates whether open account link should be shown
     * @property {?string} url - link to open account
     */

    /**
     *
     * @alias ecapp.btAdvertisingService#getADSettings
     * @return {btBannerSettings}
     */
    function getADSettings() {
      if (hasIGAds()) {
        return {
          id: 'ig',
          logo: 'images/brokers/ig-logo.svg',
          name: 'IG US',
          banner: check('free-user'),
          account: true,
          url:
            (window.isApp ? gAdSettings.ig.app.link : gAdSettings.ig.web.link) ||
            'https://igamerica.ig.com/sign_up_bonus/?utm_source=bettertrader&utm_medium=display&utm_campaign=signupbonus&utm_content=redsheep',
          image: (window.isApp ? gAdSettings.ig.app.image : gAdSettings.ig.web.image) || 'images/ads/ig/get-started/',
        };
      }

      if (hasCFXAds()) {
        return {
          id: 'cfx',
          logo: 'images/brokers/cfx-logo.png',
          name: 'Cedar FX',
          banner: check('free-user'),
          account: true,
          url:
            (window.isApp ? gAdSettings.cfx.app.link : gAdSettings.cfx.web.link) ||
            'https://www.cedarfx.com/?utm_source=BetterTrader&utm_medium=b330x46',
          image: (window.isApp ? gAdSettings.cfx.app.image : gAdSettings.cfx.web.image) || 'images/ads/cfx/',
        };
      }

      return {
        id: 'na',
        logo: 'images/brokers/unknown.png',
        name: 'N/A',
        banner: false,
        account: false,
        url: null,
        image: null,
      };
    }

    /**
     *
     * @alias ecapp.btAdvertisingService#getBannerSettings
     * @param {string} type - `desktop-bottom` or `mobile-card` or `broker-page`
     * @return {btBannerSettings}
     */
    function getBannerSettings(type) {
      var bannerType = convertType(type);
      // var data;

      if (gActive && gActive.banners && gActive.banners[bannerType]) {
        var banner = gActive.banners[bannerType];
        return {
          id: gActive.id,
          // logo: 'images/brokers/ig-logo.svg',
          // name: 'IG US',
          enabled: isBannerEnabled(type),
          // account: true,
          title: banner.title || '',
          html: banner.html || '',
          url: banner.link || '',
          image: banner.image || '',
        };
      } else {
        return {
          id: 'na',
          enabled: false,
          title: '',
          html: '',
          url: '',
          image: '',
        };
      }

      // if (hasIGAds()) {
      //   data = (window.isApp ? gAdSettings.ig.app : gAdSettings.ig.web);
      //   return {
      //     id: 'ig',
      //     logo: 'images/brokers/ig-logo.svg',
      //     name: 'IG US',
      //     banner: check('free-user'),
      //     account: true,
      //     url: data[bannerType] || data.link || 'https://igamerica.ig.com/sign_up_bonus/?utm_source=bettertrader&utm_medium=display&utm_campaign=signupbonus&utm_content=redsheep',
      //     image: data.image || 'images/ads/ig/get-started/'
      //   };
      // }

      // if (hasCFXAds()) {
      //   data = (window.isApp ? gAdSettings.cfx.app : gAdSettings.cfx.web);
      //   return {
      //     id: 'cfx',
      //     logo: 'images/brokers/cfx-logo.png',
      //     name: 'Cedar FX',
      //     banner: check('free-user'),
      //     account: true,
      //     url: data[bannerType] || data.link || 'https://www.cedarfx.com/?utm_source=BetterTrader&utm_medium=b330x46',
      //     image: data.image || 'images/ads/cfx/'
      //   };
      // }

      // return {
      //   id: 'na',
      //   logo: 'images/brokers/unknown.png',
      //   name: 'N/A',
      //   banner: false,
      //   account: false,
      //   url: null,
      //   image: null
      // };
    }

    /**
     *
     * @param {string} type
     * @return {boolean}
     */
    function isBannerEnabled(type) {
      if (type === 'broker-page') {
        return true;
      } else {
        return check('free-user');
      }
    }

    /**
     * Converts type.
     *
     * @param {*} name
     * @return {string}
     */
    function convertType(name) {
      switch (name) {
        case 'broker-page':
        case 'open-account':
          return 'broker';
        case 'desktop-bottom':
          return 'bottom';
        case 'mobile-card':
          return 'card';
        default:
          return 'link';
      }
    }

    // /**
    //  *
    //  * @alias ecapp.btAdvertisingService#getAVATradeSettings
    //  * @return {btBannerSettings}
    //  */
    // function getAVATradeSettings() {
    //   if (gDebug) {
    //     console.log(gPrefix, 'getAVATradeSettings - Is free user?', check('free-user'));
    //     console.log(gPrefix, 'getAVATradeSettings - Is new user?', check('new-user'));
    //     console.log(gPrefix, 'getAVATradeSettings attributes', gAttributes);
    //     console.log(gPrefix, 'getAVATradeSettings settings', gSettings);
    //   }

    //   if (hasIGAds()) {
    //     return { banner: false, account: false, url: null };
    //   }

    //   var template = getAVATradeCountrySettings(gAttributes.country);

    //   // Disable AVA Trade banner
    //   template.banner = false;

    //   if (!check('has-personalization')) {
    //     if (gDebug) console.log(gPrefix, 'Skip advertising in shared version.');
    //     return { banner: false, account: false, url: null };
    //   }

    //   if (check('optimus')) {
    //     if (gDebug) console.log(gPrefix, 'Skip advertising in optimus version.');
    //     return { banner: false, account: false, url: null };
    //   }

    //   if (check('bigbrainbank')) {
    //     if (gDebug) console.log(gPrefix, 'Skip advertising in bigbrainbank version.');
    //     return { banner: false, account: false, url: null };
    //   }

    //   if (check('free-user') && check('new-user') && !check('has-broker') && check('has-broker-answer')) {
    //     return template;
    //   } else {
    //     template.banner = false;
    //     return template;
    //   }
    // }

    // /**
    //  *
    //  * @param {string} country
    //  * @return {btBannerSettings}
    //  */
    // function getAVATradeCountrySettings(country) {
    //   var h = ['USA', 'ISR', 'BEL', 'NZL'];

    //   if (gMockUserCountry) country = gMockUserCountry;

    //   if (h.indexOf(country) === -1) {
    //     // show
    //     if (country === 'CAN') {
    //       // canada
    //       if (gDebug) console.log(gPrefix, 'AVATrade Canada');
    //       return {
    //         banner: true,
    //         account: true,
    //         url: 'https://www.friedbergdirectav.ca/register?tag=113220'
    //       };
    //     } else {
    //       // regular
    //       if (gDebug) console.log(gPrefix, 'AVATrade Regular');
    //       return {
    //         banner: true,
    //         account: true,
    //         url: 'https://www.avatrade.com/trading-account/?tag=113220'
    //       };
    //     }
    //   } else {
    //     // hide
    //     if (gDebug) console.log(gPrefix, 'AVATrade Hidden');
    //     return {
    //       banner: false,
    //       account: false,
    //       url: null
    //     };
    //   }
    // }

    /**
     * This function is used to hide advertising in production version during testing.
     *
     * @alias ecapp.btAdvertisingService#checkHasAdvertising
     * @return {boolean}
     */
    function checkHasAdvertising() {
      return btSettingsService.getDomain() !== 'bigbrainbank';
    }

    /**
     * @alias ecapp.btAdvertisingService#reset
     * @return {*}
     */
    function reset() {
      gSettings = {};
      gAttributes.broker = null;
      window.Intercom('update', { has_broker: undefined });
      return btShareScopeService.saveUserSettings('advertising', gSettings);
    }
  }
})();
