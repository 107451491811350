(function btCalendarTimeFilterClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * Returns calendar time
     *
     * @ngdoc filter
     * @name btCalendarTimeFilter
     * @memberOf ecapp
     */
    .filter('btCalendarTime', btCalendarTimeFilter);

  btCalendarTimeFilter.$inject = ['btDateService'];

  /**
   *
   * @param {ecapp.IDateService} btDateService
   * @return {angular.IFilterFunction}
   */
  function btCalendarTimeFilter(btDateService) {
    return function convertTrend(timestamp) {
      return btDateService.getClockText(timestamp * 1000);
    };
  }
})();
