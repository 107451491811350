/**
 * Created by Sergey on 3/5/20.
 */

// @ts-check
(function btCodesConstantClosure() {
  'use strict';

  /** @type {ecapp.ICodes}*/
  var a = {
    Notification: {
      Enabled: 0,
      // This is section of system notifications.
      System: {
        Enabled: 1,
        // General system notification
        General: 2,
        // System notification about a new version
        Upgrade: 3,
      },
      /* This space is reserved for more system notifications */
      // Notifications from twitter scanner
      TwitterScanner: {
        Enabled: 2000,
        // Notification about a new tweet
        NewTweet: 2001,
      },
      // Notifications from economic calendar
      EconomicCalendar: {
        Enabled: 3000,
        Reminder: 3001,
        Preparation: 3002,
        Release: 3003,
        Insights: 3004,
        Following: 3900,
      },
      // Notifications about trade ideas
      TradeIdeas: {
        Enabled: 4000,
        NewDriven: 4001,
        PotentialNewDriven: 4002,
        PriceDriven: 4003,
      },
      // Notifications about market movements
      MarketMovements: {
        Enabled: 5000,
        DailyChange: 5001,
        DailyRange: 5002,
        CrossYesterday: 5003,
        ShortTermChange: 5004,
        MarketSense: 5100,
        MarketWakeup: 5200,
        Following: 5900,
      },
      // Notifications about opening and closing of stock exchanges
      StockExchange: {
        Enabled: 6000,
        NYSE: 6001 /* United States */,
        LSE: 6002 /*  United Kingdom */,
        JPX: 6003 /*  Japan */,
        SSE: 6004 /*  China */,
        /* NASDAQ: 6005, Removed due to it duplicate NYSE */
        TEST1: 6900,
        TEST2: 6901,
      },
      RiskMonitor: {
        Enabled: 7000,
        Update: 7001,
        Insight: 7002,
      },
      Levels: {
        Enabled: 8000,
        Update: 8001,
        Storing: 8002,
        Crossing: 8003,
      },
      NewsAlerts: {
        NewArticle: 9001
      }
    },
  };

  angular.module('btUtils').constant('btCodes', a);
})();
