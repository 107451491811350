/**
 * Created by Sergey on 10/28/19.
 */

// @ts-check
(function btColorServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btColorService:';

  /**
   * @ngdoc service
   * @name btColorService
   * @memberOf btUtils
   * @description
   *  This service ...
   */

  angular.module('btUtils').factory('btColorService', service);

  service.$inject = [];

  /**
   *
   * @return {ecapp.IColorService}
   */
  function service() {
    if (gDebug) console.log(gPrefix, 'running...');

    // 20 Simple Distinct Colors
    // https://sashat.me/2017/01/11/list-of-20-simple-distinct-colors/
    // eslint-disable-next-line no-unused-vars
    var gSashaColors = [
      '#e6194B',
      '#3cb44b',
      '#ffe119',
      '#4363d8',
      '#f58231',
      '#911eb4',
      '#42d4f4',
      '#f032e6',
      '#bfef45',
      '#fabebe',
      '#469990',
      '#e6beff',
      '#9A6324',
      '#fffac8',
      '#800000',
      '#aaffc3',
      '#808000',
      '#ffd8b1',
      '#000075',
      '#a9a9a9',
      '#ffffff',
      '#000000',
    ];

    // 22 Kelly's Colors
    // theory - https://eleanormaclure.files.wordpress.com/2011/03/colour-coding.pdf (page 5)
    // kelly's colors - https://i.kinja-img.com/gawker-media/image/upload/1015680494325093012.JPG
    // hex values - http://hackerspace.kinja.com/iscc-nbs-number-hex-r-g-b-263-f2f3f4-242-243-244-267-22-1665795040
    var gKellyColors = [
      '#F2F3F4',
      '#222222',
      '#F3C300',
      '#875692',
      '#F38400',
      '#A1CAF1',
      '#BE0032',
      '#C2B280',
      '#848482',
      '#008856',
      '#E68FAC',
      '#0067A5',
      '#F99379',
      '#604E97',
      '#F6A600',
      '#B3446C',
      '#DCD300',
      '#882D17',
      '#8DB600',
      '#654522',
      '#E25822',
      '#2B3D26',
    ];

    var gColors = [
      '#F3C300',
      '#875692',
      '#F38400',
      '#A1CAF1',
      '#C2B280',
      '#848482',
      '#E68FAC',
      '#0067A5',
      '#F99379',
      '#604E97',
      '#F6A600',
      '#B3446C',
      '#DCD300',
      '#882D17',
      '#8DB600',
      '#654522',
      '#E25822',
      '#2B3D26',
    ];

    return {
      getNColors: getNColors,
      getIColor: getIColor,
    };

    /**
     * This function returns N different colors.
     *
     * @name btUtils.btColorService#getNColors
     * @param {number} n - number of colors
     * @return {string[]}
     */
    function getNColors(n) {
      if (n <= gKellyColors.length) {
        return gKellyColors.slice(0, n);
      } else {
        return [];
      }
    }

    /**
     * This function returns i-th color.
     *
     * @name btUtils.btColorService#getIColor
     * @param {number} i - index
     * @return {string}
     */
    function getIColor(i) {
      return gColors[i] || '#FFFFFF';
    }
  }
})();
