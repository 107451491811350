/**
 * Created by Sergey Panpurin on 4/26/2017.
 */

// @ts-check
(function btSampleApiServiceClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This factory ???.
     *
     * @ngdoc service
     * @name btSampleApiService
     * @memberOf ecapp
     */
    .factory('btSampleApiService', btSampleApiService);

  btSampleApiService.$inject = ['$q'];

  /**
   *
   * @param {angular.IQService} $q
   * @return {ecapp.ISampleApiService}
   */
  function btSampleApiService($q) {
    console.log('Running btSampleApiService');

    var DEBUG = false;
    var COMING_SOON = 'Coming soon!';
    var PREFIX = 'btSampleApiService:';

    /* --- Public functions --- */

    /**
     * Initialize service
     *
     * @return {angular.IPromise<*>}
     */
    function initialize() {
      if (DEBUG) console.log(PREFIX, 'initialize');
      return $q.resolve({});
    }

    /**
     *
     * @return {any}
     */
    function getAccessData() {
      return $q.reject(new Error('Undefined'));
    }

    /**
     * Connect Broker
     *
     * @return {angular.IPromise<*>}
     */
    function connect() {
      if (DEBUG) console.log(PREFIX, 'connect');
      return $q.resolve({});
    }

    /**
     * Disconnect broker
     *
     * @return {angular.IPromise<*>}
     */
    function disconnect() {
      if (DEBUG) console.log(PREFIX, 'disconnect');
      return $q.resolve({});
    }

    /**
     * Login Broker
     *
     * @param {string} mode - trading mode: real ot demo
     * @param {boolean} isForceLogin - force login or not
     * @return {angular.IPromise<*>}
     */
    function login(mode, isForceLogin) {
      if (DEBUG) console.log(PREFIX, 'login', mode, isForceLogin);
      return $q.resolve({});
    }

    /**
     * Logout Broker
     *
     * @return {angular.IPromise<*>}
     */
    function logout() {
      if (DEBUG) console.log(PREFIX, 'logout');
      return $q.resolve({});
    }

    /**
     * Is user logged in to TradeStation API
     *
     * @return {*}
     */
    function isLoggedIn() {
      return false;
    }

    /**
     * Get trading mode: live or practice
     *
     * @return {string} - trading mode: "demo" or "real"
     */
    function getTradingMode() {
      return 'demo';
    }

    /**
     * Set trading mode. Return true on success.
     *
     * @param {string} mode - trading mode 'demo' or 'real' (any other values equal to 'demo')
     * @return {boolean} - trading mode was changed
     */
    function setTradingMode(mode) {
      console.log(mode);
      return true;
    }

    /* --- User data --- */
    /**
     * Get list of user accounts for selected broker
     *
     * @return {angular.IPromise<Array>} - list of accounts
     */
    function getAccounts() {
      if (DEBUG) console.log(PREFIX, 'getAccounts');
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     * Get balance for accounts
     *
     * @param {Array} accountIds - list of account ids
     * @return {angular.IPromise<Array>}
     */
    function getBalances(accountIds) {
      if (DEBUG) console.log(PREFIX, 'getAccounts', accountIds);
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     * Get all positions for accounts
     *
     * @param {Array} accountIds - list of account ids
     * @return {angular.IPromise<Array>}
     */
    function getPositions(accountIds) {
      if (DEBUG) console.log(PREFIX, 'getPositions', accountIds);
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     * Get all orders for accounts
     *
     * @param {Array} accountIds - list of account ids
     * @return {angular.IPromise<Array>}
     */
    function getOrders(accountIds) {
      if (DEBUG) console.log(PREFIX, 'getOrders', accountIds);
      return $q.reject(new Error(COMING_SOON));
    }

    /* --- Market Data --- */
    /**
     * Get information about selected symbol
     *
     * @param {string} symbol - symbol name
     * @return {angular.IPromise<ecapp.ITradingInstrument>}
     */
    function getSymbolInfo(symbol) {
      if (DEBUG) console.log(PREFIX, 'getSymbolInfo', symbol);
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     * Search symbol using query string
     *
     * @param {string} query - symbol name
     * @return {angular.IPromise<Array>} - list of symbol names
     */
    function searchSymbol(query) {
      if (DEBUG) console.log(PREFIX, 'searchSymbol', query);
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     * Search symbol using query string
     *
     * @param {string} text - search text
     * @param {number} limit - limit number of results
     * @param {object} params - additional parameters
     * @return {angular.IPromise<Array>} - list of symbol names
     */
    function suggestSymbols(text, limit, params) {
      if (DEBUG) console.log(PREFIX, 'suggestSymbols', text, limit, params);
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     * Get quotes for list of symbols
     *
     * @param {string[]} symbols
     * @return {angular.IPromise<never>}
     */
    function getQuotes(symbols) {
      if (DEBUG) console.log(PREFIX, 'getQuotes', symbols);
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     * Stream snapshot for selected symbol
     *
     * @param {string} symbol - symbol name
     * @param {Function} onProgress - function to call on progress
     * @return {angular.IPromise<*>} - return object to terminate streaming
     */
    function streamQuote(symbol, onProgress) {
      if (DEBUG) console.log(PREFIX, 'streamQuote', symbol, onProgress);
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     * Get snapshots for list of symbols
     *
     * @param {string[]} symbols
     * @param {object} range
     * @param {number} interval -
     * @param {string} unit -
     * @return {angular.IPromise<Array>}
     */
    function getSnapshots(symbols, range, interval, unit) {
      if (DEBUG) console.log(PREFIX, 'getSnapshots', symbols, range, interval, unit);
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     * Stream snapshot for selected symbol
     *
     * @param {string} ticker - ticker
     * @param {string} granularity - granularity
     * @param {number} back - candles back
     * @param {(bar: ecapp.ITradingCandle) => void} onUpdate - function to call on progress
     * @return {angular.IPromise<{stop: () => void}>} - return object to terminate streaming
     */
    function streamSnapshot(ticker, granularity, back, onUpdate) {
      if (DEBUG) console.log(PREFIX, 'streamSnapshot', ticker, granularity, back, onUpdate);
      return $q.reject(new Error(COMING_SOON));
    }

    /* --- Order execution --- */
    /**
     * Confirm order
     *
     * @param {ecapp.ITradingOrderRequest} order - order request data
     * @return {angular.IPromise<ecapp.ITradingOrderConfirmation>}
     */
    function confirmOrder(order) {
      if (DEBUG) console.log(PREFIX, 'confirmOrder', order);
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     * Submit order
     *
     * @param {ecapp.ITradingOrderRequest} order - order request data
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function submitOrder(order) {
      if (DEBUG) console.log(PREFIX, 'submitOrder', order);
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     * Update order
     *
     * @param {string} orderId - order id
     * @param {object} orderChanges - changes in order
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function updateOrder(orderId, orderChanges) {
      if (DEBUG) console.log(PREFIX, 'updateOrder', orderId, orderChanges);
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     * Cancel order
     *
     * @param {string} orderId - order id
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function cancelOrder(orderId) {
      if (DEBUG) console.log(PREFIX, 'cancelOrder', orderId);
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     *
     * @param {*} mode
     * @param {*} data
     * @param {*} cb
     * @return {any}
     */
    function fastLogin(mode, data, cb) {
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     *
     * @return {any}
     */
    function signUp() {
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     *
     * @return {any}
     */
    function checkUser() {
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     *
     * @return {any}
     */
    function getUsername() {
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     *
     * @return {any}
     */
    function getCandles() {
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     *
     * @return {any}
     */
    function getLastCandlesData() {
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     *
     * @return {any}
     */
    function getLiveCandleData() {
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     *
     * @return {any}
     */
    function getLiveCandlesData() {
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     *
     * @return {any}
     */
    function getEntryPrice() {
      return $q.reject(new Error(COMING_SOON));
    }

    /**
     *
     * @return {any}
     */
    function selectAccount() {
      return '';
    }

    /**
     *
     * @return {any}
     */
    function isAccountSelected() {
      return false;
    }

    /**
     *
     * @return {any}
     */
    function getSelectedAccountId() {
      return '';
    }

    return {
      initialize: initialize,

      getAccessData: getAccessData,

      connect: connect,
      disconnect: disconnect,

      login: login,
      logout: logout,
      isLoggedIn: isLoggedIn,

      fastLogin: fastLogin,
      signUp: signUp,
      checkUser: checkUser,
      getUsername: getUsername,
      getCandles: getCandles,
      getLastCandlesData: getLastCandlesData,
      getLiveCandleData: getLiveCandleData,
      getLiveCandlesData: getLiveCandlesData,
      getEntryPrice: getEntryPrice,
      selectAccount: selectAccount,
      isAccountSelected: isAccountSelected,
      getSelectedAccountId: getSelectedAccountId,

      getTradingMode: getTradingMode,
      setTradingMode: setTradingMode,

      getAccounts: getAccounts,
      getBalances: getBalances,
      getPositions: getPositions,
      getOrders: getOrders,

      getSymbolInfo: getSymbolInfo,
      searchSymbol: searchSymbol,
      suggestSymbols: suggestSymbols,
      getQuotes: getQuotes,
      streamQuote: streamQuote,
      getSnapshots: getSnapshots,
      streamSnapshot: streamSnapshot,

      confirmOrder: confirmOrder,
      submitOrder: submitOrder,
      updateOrder: updateOrder,
      cancelOrder: cancelOrder,
    };
  }
})();
