/**
 * Created by Itay on 6/26/2016.
 */

/*global Chart*/

(function btHistoricGraphChartClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive display historical chart.
     *
     * @ngdoc directive
     * @name btHistoricGraphChart
     * @memberOf ecapp
     */
    .directive('btHistoricGraphChart', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          controller: ['$scope', 'btRowProcessorService', 'Rows', btHistoricGraphChartController],
          link: btHistoricGraphChartLinker,
          template: $templateCache.get('directives/common/historic-graph/chart.html'),
        };
      },
    ]);

  /**
   *
   * @param {*} $scope
   * @param {ecapp.IRowProcessorService} btRowProcessorService
   * @param {*} lbRows
   */
  function btHistoricGraphChartController($scope, btRowProcessorService, lbRows) {
    $scope.historicRows1 = [];

    $scope.isLoading = false;

    $scope.loadMoreHistoricItems = function () {
      $scope.isLoading = true;
      var rowData = null;
      if ($scope.a) {
        rowData = $scope.a;
      } else {
        rowData = $scope.rowModalData;
      }
      var query = {
        data: {
          eventId: rowData.eventId,
          time: rowData.time,
          skip: 0,
          limit: 12,
        },
      };
      return lbRows
        .getPrevRows(query)
        .$promise.then(function (res) {
          $scope.isLoading = false;
          // process the recent loaded rows
          angular.forEach(res.getPrevRows, function (row) {
            btRowProcessorService.historicRowProcessor(row);
          });

          // //this condition checks if the amount of loaded items from database equals to the limit, if it does
          // //we assume that there are more items to load, but if it isn't there are no more items to load.
          // if (res.getPrevRows.length != loadLimitation)
          //   $scope.isNoMoreItemsToLoad = true;

          //merging the already loaded array and the new one, after the process
          angular.extend($scope.historicRows1, $scope.historicRows1.concat(res.getPrevRows));
        })
        .catch(function (reason) {
          console.log('btHistoricGraphChart: error', reason);
        });
    };

    //getting historic rows from database
    //        $scope.loadMoreHistoricItems(false);
  }

  /**
   *
   * @param {*} scope
   * @param {*} element
   */
  function btHistoricGraphChartLinker(scope, element) {
    //getting the canvas
    var canvas = element.find('.historic').first()[0];

    //getting the size from the scope.
    // var size = scope.size;

    var canvasParent = element.find('.historic').first().parent();

    var width = canvasParent.width();
    var height = canvasParent.height();

    //setting canvas width and height to the size
    canvas.width = width;
    canvas.height = height;

    //getting canvas context
    var ctx = canvas.getContext('2d');

    // var percentage;
    // var rest;
    // var color = "#817035";

    scope
      .loadMoreHistoricItems(false)
      .then(function () {
        createChart();
      })
      .catch(function (reason) {
        console.log('btHistoricGraphChart: error', reason);
      });

    /**
     *
     */
    function createChart() {
      // if (scope.strength != null) {
      //   percentage = scope.strength.value;
      //   rest = 100 - scope.strength.value;
      //
      //   if (rest < 0)
      //     rest = 0;
      //
      //   if (scope.strength.status === "Buy")
      //     color = "#48C064";
      //   else if (scope.strength.status === "Sell")
      //     color = "#CF3040";
      //
      // } else {
      //   percentage = 0;
      //   rest = 100;
      // }

      var rawData = { strengths: [], values: [], times: [], colors: [] };
      scope.historicRows1.reverse().map(function (row) {
        if (row.hasOwnProperty('historicData')) {
          var strength = parseFloat(row.historicData);
          this.strengths.push(strength);
          if (strength > 0) {
            this.colors.push('rgba(72,192,100,1)'); // #48C064
          } else if (strength < 0) {
            this.colors.push('rgba(207,48,64,1)'); // #CF3040
          } else {
            this.colors.push('rgba(50,50,50,1)');
          }
        } else {
          this.strengths.push(0);
          this.colors.push('rgba(50,50,50,1)');
        }
        if (row.hasOwnProperty('actual')) {
          this.values.push(parseFloat(row.actual.replace(',', '')));
        } else {
          this.values.push(0);
        }
        console.log(row);
        // this.times.push((new Date(row.time*1000)).toISOString().slice(5, 10));
        this.times.push(new Date(row.time * 1000).toString().split(' ').slice(1, 3).join(' '));
      }, rawData);

      console.log(rawData);
      console.log(scope.historicRows1);
      // New version of data for Chart.js v2
      var data = {
        labels: rawData.times,
        datasets: [
          {
            type: 'line',
            yAxisID: 'y-axis-2',
            label: 'Actual values',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgb(48, 103, 207)',
            borderColor: 'rgb(48, 103, 207)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgb(48, 103, 207)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointRadius: 1,
            pointHitRadius: 10,
            data: rawData.values,
            spanGaps: false,
            borderWidth: 2,
          },
          {
            type: 'bar',
            yAxisID: 'y-axis-1',
            label: 'Negative & Magnitude',
            fill: false,
            backgroundColor: rawData.colors,
            borderColor: rawData.colors,
            borderWidth: 1,
            data: rawData.strengths,
          },
        ],
      };

      // Global settings for Chart.js
      Chart.defaults.global.legend.display = false;
      Chart.defaults.global.tooltips.enabled = false;
      Chart.defaults.global.responsive = false;
      Chart.defaults.global.elements.arc.borderWidth = 0;

      // New way to create doughnut chart
      // noinspection JSUnusedLocalSymbols
      var myChart = new Chart(ctx, {
        // eslint-disable-line no-unused-vars
        type: 'bar',
        data: data,
        options: {
          animation: false,
          hover: false,
          scales: {
            yAxes: [
              {
                type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                display: true,
                position: 'right',
                id: 'y-axis-1',
              },
              {
                type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                display: true,
                position: 'left',
                id: 'y-axis-2',
                ticks: { fontColor: 'rgb(48, 103, 207)' },
              },
            ],
          },
        },
      });
    }
  }
})();
