/**
 * Created by Sergey Panpurin on 5/6/2018.
 */

(function btFeedClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     *  This directive represents data as a feed.
     *  Feed have new, regular and old cards.
     *  Each item must have id and date. Feed uses id to track ng-repeat so it must be unique.
     *
     *  Expected data structure of item :
     *  {
     *    id: '1234',
     *    time: 1525172705
     *  }
     *
     *  Item data will be saved to feed card as a data property. So they can be used in custom template. For example,
     *  to access item property "class" use next reference "card.data.class".
     *
     *  Use templates to setup custom templates:
     *  {
     *    empty: 'template for empty feed',
     *    card: 'template for item card'
     *  }
     *
     *  Variable collapseSize control size of collapsed view.
     *
     * @ngdoc directive
     * @name btFeed
     * @memberOf ecapp
     */
    .directive('btFeed', btFeed);

  btFeed.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btFeed($templateCache) {
    return {
      restrict: 'E',
      scope: {
        meta: '=?',
        items: '=',
        templates: '=',
        displayOptions: '=',
      },
      template: $templateCache.get('components/mobile/bt-feed/bt-feed.html'),
      controller: btFeedController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btFeedController.$inject = ['$scope', 'btFeedService', 'btAdvertisingService'];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.IFeedService} btFeedService
   * @param {ecapp.IAdvertisingService} btAdvertisingService
   */
  function btFeedController($scope, btFeedService, btAdvertisingService) {
    /*jshint validthis: true*/
    var vm = this;

    vm.meta = vm.meta || {};
    vm.items = vm.items || [];
    vm.templates = vm.templates || {
      empty: 'components/mobile/bt-feed/bt-feed-default-empty.html',
      card: 'components/mobile/bt-feed/bt-feed-default-card.html',
    };
    vm.cards = [];
    vm.isEmpty = true;
    vm.isCollapsible = false;
    vm.isCollapsed = true;
    vm.hasAdvertising = btAdvertisingService.checkHasAdvertising();

    // number of elements to show in collapsed view
    vm.collapseSize = vm.meta.collapseSize || 3;

    // custom css class for top div
    vm.class = vm.meta.customCssClass || '';

    vm.toggleCollapse = toggleCollapse;

    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;

    var gAutoUpdatePromise = null;

    $scope.$watch('vm.items', onItemsChanges);

    /**
     *
     */
    function onInit() {
      prepareFeed();
    }

    /**
     *
     */
    function onDestroy() {
      cancelAutoUpdate();
    }

    /**
     *
     * @param {*} current
     * @param {*} original
     */
    function onItemsChanges(current, original) {
      if (current !== original) {
        if (current !== undefined) {
          prepareFeed();
        }
      }
    }

    /**
     *
     */
    function cancelAutoUpdate() {
      if (gAutoUpdatePromise !== null) {
        if (btFeedService.stopAutoUpdate(gAutoUpdatePromise)) {
          gAutoUpdatePromise = null;
        }
      }
    }

    /**
     *
     */
    function toggleCollapse() {
      if (vm.isCollapsible) {
        vm.isCollapsed = !vm.isCollapsed;
      }
    }

    /**
     *
     */
    function prepareFeed() {
      var settings = {
        collapseSize: vm.collapseSize,
        templates: vm.templates,
      };

      vm.cards = btFeedService.prepareCards(vm.items, settings);
      vm.isEmpty = vm.cards.length === 0;
      vm.isCollapsible = vm.cards.length > vm.collapseSize;

      gAutoUpdatePromise = btFeedService.startAutoUpdate(vm.cards, vm.collapseSize);
    }
  }
})();
