/**
 * Created by Sergey Panpurin on 6/6/2019.
 */

(function btChatClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btChat:';

  angular.module('ecapp').directive('btChat', btChat).controller('btChatController', btChatController);

  btChat.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btChat($templateCache) {
    return {
      restrict: 'E',
      scope: {
        hasTitle: '@',
      },
      template: $templateCache.get('directives/common/bt-chat.html'),
      controller: 'btChatController',
      link: linker,
    };
  }

  btChatController.$inject = [
    '$q',
    '$scope',
    '$timeout',
    '$interval',
    '$templateCache',
    '$ionicPopup',
    '$sce',
    '$http',
    'btShareScopeService',
    'btOauthService',
    'btSettingsService',
    'btRestrictionService',
    'btCommunityService',
    'btToastrService',
  ];

  /**
   * Chat controller
   *
   * @ngdoc controller
   * @name btChatController
   * @memberOf dashboard
   * @param {angular.IQService} $q - promise interface
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.ITimeoutService} $timeout
   * @param {angular.IIntervalService} $interval
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {ionic.IPopupService} $ionicPopup - ionic popup service
   * @param {angular.ISCEService} $sce
   * @param {angular.IHttpService} $http
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IOauthService} btOauthService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.ICommunityService} btCommunityService
   * @param {ecapp.IToastrService} btToastrService
   */
  function btChatController(
    $q,
    $scope,
    $timeout,
    $interval,
    $templateCache,
    $ionicPopup,
    $sce,
    $http,
    btShareScopeService,
    btOauthService,
    btSettingsService,
    btRestrictionService,
    btCommunityService,
    btToastrService
  ) {
    console.log('Running btCommunityTabController');

    var gLocalSettings = btSettingsService.getLocalSettings();

    var gGitter = {};
    var gUnreadUpdater = null;

    $scope.chat = {
      gitter: null,
      enabled: false,
      loading: true,
      ready: false,
      locked: false,
      connected: false,
      error: null,
      rooms: [],
      available: false,
      selected: undefined,
      unread: { total: 0 },
      target: 'bt-chat-content-' + (Math.random() * 100000).toFixed(0),
      open: openChat,
      close: closeChat,
      join: joinRoom,
      select: selectRoom,
      upgrade: upgradeChat,
      help: helpChat,
    };

    $scope.hideKeyboard = hideKeyboard;
    $scope.showRoomUnreadItems = showRoomUnreadItems;
    $scope.$on('$destroy', onDestroy);
    $scope.$on('community:changed', onCommunityChanged);
    $scope.$on('subscription:updated', onSubscriptionUpgradeSuccess);

    initialize();

    /**
     * This function reacts on subscription update.
     */
    function onSubscriptionUpgradeSuccess() {
      $scope.chat.locked = !btRestrictionService.hasFeature('community');
    }

    /**
     *
     */
    function initialize() {
      if (gDebug) console.log(gPrefix, 'initializing...');

      // Fix from previous version
      var parameter = gLocalSettings.get('community.gitter.connected');
      if (parameter !== undefined && typeof parameter !== 'object') {
        // reset parameter
        gLocalSettings.set('community.gitter.connected', {});
      }

      var savedUser = gLocalSettings.get('community.gitter.user');
      if (savedUser) gGitter.user = savedUser;
      var savedToken = gLocalSettings.get('community.gitter.token');
      if (savedToken) gGitter.token = savedToken;

      addGitterListeners();

      btShareScopeService
        .wait()
        .then(getSettings)
        .then(function () {
          getUnreadItems();
          gUnreadUpdater = $interval(getUnreadItems, 30000);
          if (gDebug) console.log(gPrefix, 'initialized');
        })
        .catch(function (reason) {
          console.error(gPrefix, reason);
        });
    }

    /**
     *
     */
    function onDestroy() {
      if (gUnreadUpdater) $interval.cancel(gUnreadUpdater);
    }

    /**
     *
     * @return {angular.IPromise<any>}
     */
    function getSettings() {
      $scope.chat.locked = !btRestrictionService.hasFeature('community');

      if (gDebug) console.log(gPrefix, 'user has community feature', btRestrictionService.hasFeature('community'));

      if (gDebug) console.log(gPrefix, 'loading settings...');
      return btShareScopeService.getAppSettings('community').then(function (result) {
        if (gDebug) console.log(gPrefix, 'settings were loaded');
        prepareChat(result);
      });
    }

    /**
     *
     * @param {*} result
     */
    function prepareChat(result) {
      $scope.chat.loading = false;
      $scope.chat.enabled = !!result.features.chat;
      $scope.chat.rooms = result.rooms;
      // $scope.chat.selected = result.rooms[0];

      var community = btCommunityService.getSelectedCommunity();
      if (community && community.room) {
        $scope.chat.selected = community.room;
      }

      // if (gLocalSettings.get('community.room') !== undefined) {
      //   $scope.chat.selected = $scope.chat.rooms.filter(function (room) {
      //     return gLocalSettings.get('community.room') === room.id;
      //   })[0];
      // }

      if ($scope.chat.selected === undefined) {
        $scope.chat.selected = $scope.chat.rooms[0];
      }

      $scope.chat.connected = !!gLocalSettings.get('community.gitter.connected.' + $scope.chat.selected.id);
      $scope.chat.ready = true;
      if (gDebug) console.log(gPrefix, 'chat settings', $scope.chat);

      initializeGitterChat();
    }

    /**
     *
     */
    function openChat() {
      $scope.chat.connected = true;
    }

    /**
     *
     */
    function closeChat() {
      $scope.chat.connected = false;

      gGitter = {};
      gLocalSettings.set('community.gitter', {});

      if ($scope.chat.gitter) {
        $scope.chat.gitter.destroy();
      }
    }

    /**
     *
     * @return {*}
     */
    function getGitterUser() {
      if (gGitter.user && gGitter.token) {
        return $q.resolve({ user: gGitter.user, token: gGitter.token });
      } else {
        return btCommunityService
          .gitterLogin()
          .then(function (result) {
            gGitter.token = result.accessToken;
            return btCommunityService.gitterMyUser(result.accessToken);
          })
          .then(function (user) {
            gGitter.user = user;
            btShareScopeService.setAccountInfoField('gitter', {
              id: user.id,
              username: user.username,
              token: gGitter.token,
            });
            gLocalSettings.set('community.gitter.user', { id: user.id, username: user.username });
            gLocalSettings.set('community.gitter.token', gGitter.token);
            return btShareScopeService.updateProfileSettingsFromFieldsList(['gitter']);
          })
          .then(function () {
            return { user: gGitter.user, token: gGitter.token };
          });
      }
    }

    /**
     *
     */
    function joinRoom() {
      var roomId = $scope.chat.selected.id;

      getGitterUser()
        .then(function () {
          return btCommunityService.gitterHasRoom(roomId, gGitter.user.id, gGitter.token);
        })
        .then(function (connected) {
          if (!connected) {
            return btCommunityService
              .gitterInvite(roomId, gGitter.user.username, null)
              .catch(function (reason) {
                if (reason.status === 409) {
                  return $q.resolve({ status: 'invited' });
                }
                return $q.reject(reason);
              })
              .then(function (value) {
                if (value.status === 'added')
                  return btCommunityService.gitterJoinRoom(roomId, gGitter.user.id, gGitter.token);
                if (value.status === 'invited') return $q.reject(new Error('Check invitation mail!'));
                return $q.reject(new Error('Unknown Status'));
              });
          }
        })
        .then(function () {
          gLocalSettings.set('community.gitter.connected.' + roomId, true);
          selectChat($scope.chat.selected);
        })
        .catch(function (reason) {
          btToastrService.error(reason.message, 'System');
          console.error(gPrefix, reason);
        });
    }

    /**
     *
     * @param {*} room
     */
    function selectRoom(room) {
      if (room === $scope.chat.selected) {
        return;
      }

      selectChat(room);
    }

    /**
     *
     * @param {*} room
     */
    function selectChat(room) {
      $scope.chat.selected = room;

      $scope.chat.connected = !!gLocalSettings.get('community.gitter.connected.' + $scope.chat.selected.id);

      gLocalSettings.set('community.room', room.id);

      if ($scope.chat.gitter) {
        $scope.chat.gitter.destroy();
      }

      if (window.gitter && window.gitter.Chat) {
        if ($scope.chat.connected) {
          $timeout(function () {
            $scope.chat.gitter = createChat($scope.chat.selected, $scope.chat.target);
          }, 100);
        }
      }
    }

    /**
     *
     */
    function upgradeChat() {
      btRestrictionService.showUpgradePopup('community');
    }

    /**
     *
     */
    function helpChat() {
      showChatHelp();
    }

    /**
     *
     * @param {Object} chat
     * @param {String} target
     * @return {window.gitter.Chat}
     */
    function createChat(chat, target) {
      return new window.gitter.Chat({
        room: chat.url,
        targetElement: '.' + target,
        showChatByDefault: true,
        preload: false,
      });
    }

    /**
     *
     */
    function addGitterListeners() {
      document.addEventListener('gitter-sidecar-ready', function (evt) {
        if (gDebug) console.log(gPrefix, 'gitter chat is ready', evt);
      });

      document.addEventListener('gitter-sidecar-instance-started', function (evt, chat) {
        if (gDebug) console.log(gPrefix, 'gitter chat was started', evt, chat);
      });
    }

    /**
     *
     */
    function initializeGitterChat() {
      if (window.gitter && window.gitter.Chat) {
        if ($scope.chat.connected) {
          if (gDebug) console.log(gPrefix, 'gitter chat was initialized');
          $timeout(function () {
            $scope.chat.gitter = createChat($scope.chat.selected, $scope.chat.target);
          });
        } else {
          if (gDebug) console.log(gPrefix, 'gitter chat was not initialized due to user is not connected');
        }
      } else {
        setTimeout(function () {
          initializeGitterChat();
        }, 100);
      }
    }

    /**
     *
     */
    function showChatHelp() {
      $ionicPopup.show({
        template: '<p>Explain how to connect chat.</p>',
        title: 'Chat Help',
        scope: $scope,
        cssClass: 'popup-bar-dark',
        buttons: [{ text: 'Okay' }],
      });
    }

    /**
     *
     */
    function getUnreadItems() {
      if (gGitter.token && gGitter.user) {
        var rooms = $scope.chat.rooms.map(function (value) {
          return value.id;
        });
        btCommunityService
          .gitterCountUnreadItems(rooms, gGitter.user.id, gGitter.token)
          .then(function (value) {
            $scope.chat.unread = value;
          })
          .catch(function (reason) {
            console.error(reason);
            delete gGitter.token;
            delete gGitter.user;
          });
      }
    }

    /**
     *
     * @param {string} roomId
     * @return {string}
     */
    function showRoomUnreadItems(roomId) {
      return $scope.chat.unread[roomId] ? ' (' + $scope.chat.unread[roomId] + ')' : '';
    }

    /**
     *
     * @param {*} event
     * @param {*} data
     */
    function onCommunityChanged(event, data) {
      void event;
      selectRoom(data.room);
    }

    /**
     *
     */
    function hideKeyboard() {
      if (window.cordova && cordova.plugins && cordova.plugins.Keyboard) {
        cordova.plugins.Keyboard.close();
      }
    }
  }

  /**
   *
   * @param {*} scope
   * @param {*} elem
   * @param {*} attr
   * @param {*} ctrl
   */
  function linker(scope, elem, attr, ctrl) {
    // window.addEventListener('native.keyboardhide', function (evt) {
    //   if (window.isIOS && window.isApp) {
    //     elem.css('height', '100%');
    //   }
    // });
    // window.addEventListener('native.keyboardshow', function (evt) {
    //   if (window.isIOS && window.isApp) {
    //     elem.css('height', 'calc(100% - ' + 2 * evt.keyboardHeight + 'px)');
    //   }
    // });
  }
})();
