(function btInsightBannerClosure() {
  'use strict';

  angular
    .module('ecapp')
    .controller('btInsightBannerController', btInsightBannerController)
    /**
     * @ngdoc directive
     * @name btInsightBannerRegular
     * @memberOf ecapp
     * @description
     *  Show this banner instead of regular insights for free user (quick view)
     */
    .directive('btInsightBannerRegular', btInsightBannerRegular)
    /**
     * @ngdoc directive
     * @name btInsightBannerRegularBig
     * @memberOf ecapp
     * @description
     *  Show this banner instead of regular insights for free user (release view)
     */
    .directive('btInsightBannerRegularBig', btInsightBannerRegularBig)
    /**
     * @ngdoc directive
     * @name btInsightBannerTrading
     * @memberOf ecapp
     * @description
     *  Show this banner instead of trading insights for free user (release view)
     */
    .directive('btInsightBannerTrading', btInsightBannerTrading)
    /**
     * @ngdoc directive
     * @name btInsightBannerTradingBig
     * @memberOf ecapp
     * @description
     *  Show this banner instead of trading insights for free user (release view)
     */
    .directive('btInsightBannerTradingBig', btInsightBannerTradingBig);

  btInsightBannerRegular.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btInsightBannerRegular($templateCache) {
    return {
      restrict: 'E',
      scope: { hasTimer: '@' },
      replace: true,
      template: $templateCache.get('directives/common/bt-insight-banner-regular.html'),
      controller: 'btInsightBannerController',
    };
  }

  btInsightBannerRegularBig.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btInsightBannerRegularBig($templateCache) {
    return {
      restrict: 'E',
      scope: { hasTimer: '@' },
      template: $templateCache.get('directives/common/bt-insight-banner-regular-big.html'),
      controller: 'btInsightBannerController',
    };
  }

  btInsightBannerTrading.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btInsightBannerTrading($templateCache) {
    return {
      restrict: 'E',
      scope: { hasTimer: '@' },
      replace: true,
      template: $templateCache.get('directives/common/bt-insight-banner-trading.html'),
      controller: 'btInsightBannerController',
    };
  }

  btInsightBannerTradingBig.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btInsightBannerTradingBig($templateCache) {
    return {
      restrict: 'E',
      scope: { hasTimer: '@' },
      template: $templateCache.get('directives/common/bt-insight-banner-trading-big.html'),
      controller: 'btInsightBannerController',
    };
  }

  btInsightBannerController.$inject = ['$scope', '$state', 'btToastrService', 'btRestrictionService'];

  /**
   *
   * @param {*} $scope
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IRestrictionService} btRestrictionService
   */
  function btInsightBannerController($scope, $state, btToastrService, btRestrictionService) {
    $scope.activateInsights = activateInsights;
    $scope.activateTradeIdeas = activateTradeIdeas;

    /**
     *
     */
    function activateInsights() {
      btRestrictionService
        .showUpgradePopup('perspective-insights')
        .then(function (status) {
          if (status === 'updated') {
            $state.reload();
          }
        })
        .catch(function (reason) {
          btToastrService.error(reason.message, 'Subscription', { type: 'settings' });
        });
    }

    /**
     *
     */
    function activateTradeIdeas() {
      btRestrictionService
        .showUpgradePopup('trade-ideas')
        .then(function (status) {
          if (status === 'updated') {
            $state.reload();
          }
        })
        .catch(function (reason) {
          btToastrService.error(reason.message, 'Subscription', { type: 'settings' });
        });
    }
  }
})();
