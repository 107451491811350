/**
 * Created by Itay on 6/14/2016.
 */

(function btRevisionShowClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive checks if the current row in scope revision is active, and if it doesn't, it hides the the div
     *
     * @ngdoc directive
     * @name btRevisionShow
     * @memberOf ecapp
     */
    .directive('btRevisionShow', [
      function () {
        return {
          restrict: 'A',
          scope: {
            rowName: '@btRevisionShow',
          },
          controller: [
            '$scope',
            'btDateService',
            function ($scope, btDateService) {
              // By calling $scope.row this way we save many unnecessary watchers
              $scope.row = $scope.$parent[$scope.rowName];

              // this checks if the $scope.row is the past, because it's not required to watch when the $scope.row is in the
              // past
              $scope.isRowInPast = btDateService.isRowInPast($scope.row);
            },
          ],
          link: function (scope, element) {
            /**
             *
             * @param {*} element
             */
            function updateShow(element) {
              if (scope.row.previous == scope.row.revision) angular.element(element).hide();
              else angular.element(element).show();
            }

            // this function is being called once when the link loads, and every time that it changes, and that's why it
            // appears twice in the code
            updateShow(element);

            if (!scope.isRowInPast || (scope.isRowInPast && scope.row.actual === 'NA')) {
              scope.$parent.$watch(scope.rowName + '.revision', function () {
                updateShow(element);
              });
            }
          },
        };
      },
    ]);
})();
