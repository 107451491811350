/**
 * Created by Orly on 06/02/2019.
 */

// @ts-check
(function btTradeIdeasFiltersServiceClosure() {
  'use strict';

  angular
    .module('ecapp')

    /**
     * @typedef {Object} SettingsParamsObject
     * @property {boolean} enabled - filter is enabled
     */

    /**
     * @typedef {Object} FiltersParamsObject
     * @property {string} style - custom css class
     * @property {boolean} enabled - filter is enabled
     * @property {string} [format] - data format (percentage)
     * @property {boolean} [collapsible] - filter could be collapsed
     * @property {boolean} [expanded] - filter is expanded
     */

    /**
     * @typedef {Object} MaxFilterObject
     * @property {string} id - filter's id
     * @property {string} name - filter's name to display as label
     * @property {string} type - type of filter: toggle, multiple, radio-group, range, magnitude, list, title
     * @property {*} value - value of the filter: boolean, number, string or an object {min: {number}, max: {number}}
     * @property {string} [description] - description for the filter
     * @property {Object} [range] -
     * @property {OptionObject[]} [options] - array of options
     * @property {FiltersParamsObject} [params] - additional parameters of the filter
     */

    /**
     * @typedef {Object} SectionObject
     * @property {string} type - type of the setting section: regular or advanced
     * @property {MaxFilterObject[]} filters - filters within section
     */

    /**
     * @typedef {Object} tradeIdeasSettings
     * @property {string} id - id of the setting
     * @property {string} name - name of the setting
     * @property {string} type - type of the setting: regular or toggle
     * @property {?string} description - description of the setting
     * @property {SettingsParamsObject} params - params of the setting
     * @property {SectionObject[]} sections - sections
     */

    /**
     * @typedef {Object} PriceDrivenTriggerObject
     * @property {string} id - id of the trigger
     * @property {string} label - label of the trigger
     * @property {string} example - example of the trigger text
     */

    /**
     * @typedef {Object} WatchedCurrencyObject
     * @property {string} name - currency's name
     * @property {boolean} selected - shows if currency is selected
     * @property {Object} icon - name of the icon
     * @property {string} icon.name - name of the icon
     * @property {string} icon.class - css classes for the icon
     */

    /**
     * This factory is to manage users' trade ideas filters settings
     *
     * Interaction with calendar settings
     *
     * Trade idea filtering uses some settings from economic calendar: priority, currencies and following events.
     * Due to currencies can be customized in trade idea filter, actions to add or remove a new currency require updating
     * trade ideas filter.
     *
     * Interaction with watchlist
     *
     * Trade idea filtering uses watchlist. Due to watchlist can be customized in trade idea filter, actions to add or
     * remove a new instrument require updating trade ideas filter.
     *
     * Interaction with in app notifications
     *
     * Filters
     *
     * Filter value null means that this filter doesn't work right now.
     * Range filter min or max value null means that this limit doesn't work right now ([null, null] = any value).
     *
     * Broadcast
     *  - trade-ideas-filters:updated
     * @ngdoc service
     * @name btTradeIdeasFiltersService
     * @memberOf ecapp
     * @param {ecapp.ICustomRootScope} $rootScope
     * @param {angular.IQService} $q - promise interface
     * @param {angular.IFilterService} $filter
     * @param {ecapp.IShareScopeService} btShareScopeService
     * @param {ecapp.ITradingService} btTradingService
     * @param {ecapp.ICurrenciesService} btCurrenciesService
     * @param {ecapp.IStrengthService} btStrengthService
     * @param {ecapp.IWatchListService} btWatchListService
     * @param {ecapp.IMarketMovementService} btMarketMovementService
     * @return {*}
     */
    .factory('btTradeIdeasFiltersService', btTradeIdeasFiltersService);

  btTradeIdeasFiltersService.$inject = [
    '$rootScope',
    '$q',
    '$filter',
    'btShareScopeService',
    'btTradingService',
    'btCurrenciesService',
    'btStrengthService',
    'btWatchListService',
    'btMarketMovementService',
    'btRestrictionService',
  ];

  /**
   *
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.IQService} $q - promise interface
   * @param {angular.IFilterService} $filter
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.ICurrenciesService} btCurrenciesService
   * @param {ecapp.IStrengthService} btStrengthService
   * @param {ecapp.IWatchListService} btWatchListService
   * @param {ecapp.IMarketMovementService} btMarketMovementService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @return {ecapp.ITradeIdeasFiltersService}
   */
  function btTradeIdeasFiltersService(
    $rootScope,
    $q,
    $filter,
    btShareScopeService,
    btTradingService,
    btCurrenciesService,
    btStrengthService,
    btWatchListService,
    btMarketMovementService,
    btRestrictionService
  ) {
    var gDebug = false;

    /**
     * Default settings of trade ideas filter
     * @type {tradeIdeasSettings[]}
     */
    var gDefaultFilters = null;

    /**
     * Cached country flags to show with currency
     * @type {Object}
     */
    var gCachedFlags = {};

    var gPriorityOptions = [
      { name: 'Default', value: '0' },
      { name: 'All', value: '1' },
      { name: 'Med+', value: '2' },
      { name: 'High', value: '3' },
    ];

    var gTradeSideOptions = [
      { name: 'Short only', value: 'short' },
      { name: 'Long only', value: 'long' },
      { name: 'Any side', value: 'long-short' },
    ];

    var gTradeIdeasTriggers = [
      { name: 'Only events that I follow (few)', value: 'following' },
      { name: 'Any event from my calendar or events that I follow (more)', value: 'calendar' },
      { name: 'Any event (even more)', value: 'any' },
    ];

    var gMarketOptions = [
      { name: 'Cross', value: 'cross' },
      { name: 'Intra', value: 'self' },
      { name: 'Both', value: 'cross-self' },
    ];

    // noinspection JSUnusedLocalSymbols
    /**
     * @typedef {Object} tradeIdeasFiltersObject
     * @property {Record<string, *>} characteristics - ?
     * @property {Record<string, *>} news-driven - ?
     * @property {Record<string, *>} price-driven - ?
     */

    return {
      filtersToMax: filtersToMax,
      filtersToMin: filtersToMin,
      getDefaultFiltersSettings: getDefaultFiltersSettings,
      loadUserSettings: loadUserSettings,
      saveUserSettings: saveUserSettings,
      getUserSettings: getUserSettings,
      isUpdateRequired: isUpdateRequired,
      updateInstruments: updateInstruments,
      updateInstrumentsByName: updateInstrumentsByName,
      updateCurrencies: updateCurrencies,
      updatePriority: updatePriority,
    };

    /**
     * This function returns default trade ideas settings according to user settings.
     * @param {Object} account - user account
     * @param {ecapp.ITradingInstrument[]} watchlist - user watchlist
     * @param {Object} flags - currency flags
     * @return {tradeIdeasSettings[]}
     */
    function getDefaultFilters(account, watchlist, flags) {
      var currencies = getCurrencies(account, flags);
      var instruments = getInstruments(watchlist);
      var magnitudes = getMagnitudes();
      var setOfQualities = getQualities(1, 5);
      var tradeQualityOptions = createOptionsFromList(setOfQualities.options, setOfQualities.values).reverse();
      var filtersFormPriceDrivenTriggers = getPriceDrivenFilter(btMarketMovementService.getTriggers());

      return [
        {
          id: 'characteristics',
          name: 'Trade Idea characteristics',
          type: 'regular',
          description: null,
          params: { enabled: true },
          sections: [
            {
              type: 'regular',
              filters: [
                {
                  id: 'expired',
                  name: 'Hide expired trade ideas',
                  type: 'toggle',
                  params: { style: '', enabled: true },
                  value: false,
                },
                {
                  id: 'instruments',
                  name: 'Instruments',
                  type: 'multiple',
                  description:
                    'Your watchlist instruments appear here, select the instruments you would like to ' +
                    'receive trade ideas for them.',
                  params: { style: '', collapsible: false, enabled: true },
                  options: instruments,
                },
                {
                  id: 'ratio',
                  name: 'Reward/Risk ratio',
                  type: 'range',
                  description:
                    'Choose the reward and risk ratio range that you like to revive trade ideas. This ' +
                    'filter is based on the ratio between the 1st profit target delta and the stop loss delta.',
                  params: { style: '', format: '', enabled: true, limits: { min: true, max: false } },
                  value: { min: 1.1, max: 5 },
                  range: { min: 1.0, max: 5, step: 0.1 },
                },
              ],
            },
            {
              type: 'advanced',
              filters: [
                {
                  id: 'side',
                  name: 'Trade side',
                  type: 'radio-group',
                  params: { style: '', enabled: true },
                  value: gTradeSideOptions[2].value,
                  options: gTradeSideOptions,
                },
                {
                  id: 'rate',
                  name: 'Historical success rate',
                  type: 'range',
                  params: { style: '', format: 'percentage', enabled: true, limits: { min: true, max: false } },
                  value: { min: 0.66, max: 1 },
                  range: { min: 0.66, max: 1, step: 0.01 },
                },
                {
                  id: 'duration',
                  name: 'Trade duration in minutes',
                  type: 'range',
                  params: { style: '', format: '', enabled: true, limits: { min: true, max: false } },
                  value: { min: 5, max: 180 },
                  range: { min: 5, max: 180, step: 1 },
                },
                {
                  id: 'quality',
                  name: 'Trade quality',
                  type: 'radio-group',
                  params: { style: '', enabled: true },
                  value: tradeQualityOptions[3].value,
                  options: tradeQualityOptions,
                },
              ],
            },
          ],
        },
        {
          id: 'news-driven',
          name: 'News-driven trade ideas',
          type: 'toggle',
          description: '*anyway trade ideas are limited to instruments selection from the upper section',
          params: { enabled: true },
          sections: [
            {
              type: 'regular',
              filters: [
                {
                  id: 'triggered-by',
                  name: 'Show news-driven trade ideas that triggered by:',
                  type: 'list',
                  params: { style: '', enabled: true },
                  value: gTradeIdeasTriggers[1].value,
                  options: gTradeIdeasTriggers,
                },
              ],
            },
            {
              type: 'advanced',
              filters: [
                {
                  id: 'priority',
                  name: 'Event Impact',
                  type: 'radio-group',
                  params: { style: '', enabled: true },
                  value: gPriorityOptions[0].value,
                  description: getPriorityDescription(account.priority),
                  options: gPriorityOptions,
                },
                {
                  id: 'currencies',
                  name: 'Override currencies',
                  type: 'multiple',
                  params: { style: 'currency', collapsible: true, expanded: false, enabled: true },
                  options: currencies,
                },
                {
                  id: 'low-magnitude',
                  name: 'Avoid low magnitude trade ideas',
                  type: 'toggle',
                  description:
                    'Avoid trade ideas that are triggered by As Expected/A bit Weaker/A bit Stronger ' + 'releases',
                  params: { style: '', enabled: true },
                  value: false,
                },
                {
                  id: 'magnitude',
                  name: 'Select magnitude range',
                  type: 'select',
                  params: { style: 'magnitude-select', enabled: $rootScope.isDevMode },
                  value: magnitudes[0],
                  options: magnitudes,
                },
              ],
            },
          ],
        },
        {
          id: 'price-driven',
          name: 'Price-driven trade ideas',
          type: 'toggle',
          description: null,
          params: { enabled: true },
          sections: [
            {
              type: 'regular',
              filters: [
                {
                  id: 'small-movement',
                  name: 'Hide small movement',
                  type: 'toggle',
                  params: { style: '', enabled: $rootScope.isDevMode },
                  value: false,
                },
                getSessionFilter('us', 'US'),
                getSessionFilter('eu', 'Europe'),
                getSessionFilter('as', 'Asia'),
                getSessionFilter('au', 'Australia'),
              ],
            },
            {
              type: 'advanced',
              filters: [
                {
                  id: 'linking',
                  name: 'Market linking',
                  type: 'radio-group',
                  params: { style: '', enabled: true },
                  value: gMarketOptions[2].value,
                  options: gMarketOptions,
                },
                {
                  id: 'triggers',
                  name: 'Select market movement patterns that trigger trade ideas:',
                  type: 'title',
                  params: { style: '', enabled: true },
                  value: null,
                },
              ].concat(filtersFormPriceDrivenTriggers),
            },
          ],
        },
      ];
    }

    /**
     * This function returns user's filters with saved values
     *
     * @ngdoc method
     * @name btTradeIdeasFiltersService#loadUserSettings
     * @return {angular.IPromise<tradeIdeasFiltersObject>}
     */
    function loadUserSettings() {
      return btWatchListService
        .initialize()
        .then(function () {
          return btCurrenciesService.getAllCachedCurrenciesFlags();
        })
        .then(function (data) {
          if (gDefaultFilters === null) {
            var account = btShareScopeService.accountInfo;
            var watchlist = btWatchListService.getUserWatchlist();
            var flags = data.allCachedCurrenciesFlags;
            gCachedFlags = flags;
            gDefaultFilters = getDefaultFilters(account, watchlist, flags);
          }
        })
        .then(function () {
          return getUserSettings();
        })
        .catch(function (reason) {
          console.log('btTradeIdeasFiltersService: error', reason);
          return $q.reject(reason);
        });
    }

    /**
     * This function updates user's filters values in the database
     *
     * @ngdoc method
     * @name btTradeIdeasFiltersService#saveUserSettings
     * @param {tradeIdeasFiltersObject} minFilters
     * @return {angular.IPromise<*>}
     */
    function saveUserSettings(minFilters) {
      if (minFilters === null) {
        return $q.reject(new Error('Bad trade ideas settings!'));
      }

      var promise;

      if (btRestrictionService.hasFeature('trade-ideas')) {
        promise = $q.resolve();
      } else if (window.isApp && window.isIOS) {
        promise = $q.reject(new Error("Saving failed. You subscription didn't support trade idea filter."));
      } else {
        promise = btRestrictionService.showUpgradePopup('trade-ideas').then(function (value) {
          if (value !== 'updated') {
            return $q.reject(new Error("Saving failed. You subscription didn't support trade idea filter."));
          }
        });
      }

      return promise.then(function () {
        $rootScope.$broadcast('trade-ideas-filters:updated', minFilters);
        return btShareScopeService.saveUserSettings('tradeIdeas', minFilters);
      });
    }

    /**
     * This function returns user settings or default settings
     * @ngdoc method
     * @name btTradeIdeasFiltersService#getUserSettings
     * @return {?tradeIdeasFiltersObject}
     */
    function getUserSettings() {
      if (btRestrictionService.hasFeature('trade-ideas')) {
        var settings = btShareScopeService.getUserSettings('tradeIdeas');
        if (settings) return settings;
      }

      return filtersToMin(gDefaultFilters);
    }

    /**
     *
     * @param {*} settings
     * @return {any}
     */
    function isUpdateRequired(settings) {
      var oldSettings = getUserSettings();
      if (gDebug) console.log('btTradeIdeasFiltersService:', settings, oldSettings);
      return !angular.equals(settings, oldSettings);
    }

    /**
     * This function converts user's filters' settings to minified format
     *
     * @ngdoc method
     * @name btTradeIdeasFiltersService#filtersToMin
     * @param {tradeIdeasSettings[]} maxFilters
     * @return {?tradeIdeasFiltersObject}
     */
    function filtersToMin(maxFilters) {
      if (maxFilters) {
        var minFilters = {};
        maxFilters.forEach(function (maxFilter) {
          minFilters[maxFilter.id] = {
            enabled: maxFilter.params.enabled,
          };

          maxFilter.sections.forEach(function (section) {
            section.filters.forEach(function (filter) {
              if (filter.type !== 'title') {
                minFilters[maxFilter.id][filter.id] = minifyFilter(filter);
              }
            });
          });
        });
        if (gDebug) console.log('btTradeIdeasFiltersService: Settings', minFilters);
        return minFilters;
      } else {
        return null;
      }
    }

    /**
     * This function converts filters' settings in minified format to full one
     *
     * @ngdoc method
     * @name btTradeIdeasFiltersService#filtersToMax
     * @param {tradeIdeasFiltersObject} filtersMin
     * @return {tradeIdeasSettings[]}
     */
    function filtersToMax(filtersMin) {
      var filtersMax = getDefaultFiltersSettings(); // using default filters as a template for filtersMax object

      filtersMax.forEach(function (filterMax) {
        if (filtersMin[filterMax.id]) {
          filterMax.params.enabled = !!filtersMin[filterMax.id].enabled;

          filterMax.sections.forEach(function (section) {
            section.filters.forEach(function (filter) {
              var value = getFilterValueFromMinFilters(filtersMin, filterMax.id, filter.id);
              setFilterValue(filter, value);
            });
          });
        }
      });

      return filtersMax;
    }

    /**
     * This function returns copy of default filters settings
     *
     * @ngdoc method
     * @name btTradeIdeasFiltersService#getDefaultFiltersSettings
     * @return {tradeIdeasSettings[]}
     */
    function getDefaultFiltersSettings() {
      return JSON.parse(JSON.stringify(gDefaultFilters));
    }

    /**
     *
     * @param {*} filters - minified filters
     * @param {*} section - section in which filter is set
     * @param {*} filterId - filter's id
     * @return {*}
     */
    function getFilterValueFromMinFilters(filters, section, filterId) {
      if (filters[section] != null && filters[section][filterId] != null) {
        return filters[section][filterId];
      } else {
        return null;
      }
    }

    /**
     * This function returns minified version of set filter
     * @param {MaxFilterObject} filter
     * @return {*}
     */
    function minifyFilter(filter) {
      var value;
      if (filter.id === 'instruments' || filter.id === 'currencies') {
        if (filter.id === 'currencies' && !filter.params.expanded) {
          return null;
        }

        value = [];
        filter.options.forEach(function (o) {
          if (o.selected) {
            value.push(o.value);
          }
        });
        return value;
      } else if (filter.id === 'priority') {
        return filter.value === '0' ? null : parseInt(filter.value);
      } else if (filter.type === 'select') {
        return filter.value.value;
      } else if (filter.type === 'range') {
        value = [filter.value.min, filter.value.max];
        if (filter.value.max === filter.range.max && !filter.params.limits.max) value[1] = null;
        if (filter.value.min === filter.range.min && !filter.params.limits.min) value[0] = null;
        return value;
      } else {
        return filter.value;
      }
    }

    /**
     * This functions returns description for event priority filter
     * @param {Number} priority - event priority
     * @return {String}
     */
    function getPriorityDescription(priority) {
      var option = gPriorityOptions.filter(function (option) {
        return option.value === priority.toString();
      })[0];

      var desc =
        'Default means as you choose in the calendar settings. Your calendar impact is set to ' +
        (option ? option.name : 'N/A') +
        '.';

      if (gDebug) console.log('btTradeIdeasFiltersService:', desc);

      return desc;
    }

    /**
     * This functions returns watched currencies
     * @param {Object} userSettings - user settings
     * @param {Object} flags - currency flags
     * @return {WatchedCurrencyObject[]}
     */
    function getCurrencies(userSettings, flags) {
      var currencies = [];
      var currencyFlags = flags;
      var watchedCurrencies = userSettings.currency;

      watchedCurrencies.forEach(function (c) {
        currencies.push({
          value: c,
          name: c,
          selected: true,
          icon: { name: currencyFlags[c], class: 'flag-img' },
        });
      });

      return currencies;
    }

    /**
     * This functions returns watched instruments
     * @param {ecapp.ITradingInstrument[]} watchlist - user watchlist
     * @return {ecapp.IWatchedInstrumentObject[]}
     */
    function getInstruments(watchlist) {
      var instruments = [];
      watchlist.forEach(function (i) {
        instruments.push({
          value: i.id,
          name: i.displayName,
          selected: true,
        });
      });
      return instruments;
    }

    /**
     * This function returns magnitude options
     * @return {Array}
     */
    function getMagnitudes() {
      var magnitudes = [];
      btStrengthService.getMagnitudeOptions().forEach(function (m) {
        magnitudes.push({
          name: m.label,
          value: m.id,
        });
      });
      return magnitudes;
    }

    /**
     * This function sets filter's value
     * @param {MaxFilterObject} filter
     * @param {*} [value]
     * @return {any}
     */
    function setFilterValue(filter, value) {
      if (value == null) {
        return;
      }

      if (filter.id === 'instruments' || filter.id === 'currencies') {
        if (filter.id === 'currencies') {
          filter.params.expanded = !!value; //if currencies set then they're overridden so filter is expanded
        }
        filter.options.forEach(function (o) {
          if (value.indexOf(o.value) === -1) {
            o.selected = false;
          }
        });
      } else if (filter.id === 'priority') {
        // ??? Value could not be null
        filter.value = value === null ? '0' : value.toString();
      } else if (filter.type === 'select') {
        // ??? It should not work
        return filter.options.filter(function (option) {
          return option.value === value;
        })[0];
      } else if (filter.type === 'range') {
        var min = value[0] === null ? filter.range.min : value[0];
        var max = value[1] === null ? filter.range.max : value[1];
        filter.value = { min: min, max: max };
      } else {
        filter.value = value;
      }
    }

    /**
     * This function creates filters for a session
     * @param {string} id - session id
     * @param {string} session - session name
     * @return {MaxFilterObject}
     */
    function getSessionFilter(id, session) {
      var options = [
        { name: 'No', value: 'no' },
        { name: 'Regular', value: 'main' },
        { name: 'Extended', value: 'start-main-end' },
      ];
      return {
        id: id + '-session',
        name: session + ' session',
        type: 'radio-group',
        params: { style: '', enabled: true },
        options: options,
        value: options[2].value,
      };
    }

    /**
     * This function creates array of option objects from a list of options' names
     * @param {Array} options
     * @param {Array} [values]
     * @return {ecapp.IOptionObject[]}
     */
    function createOptionsFromList(options, values) {
      options.forEach(function (option, index) {
        options[index] = {
          name: option,
          value: values ? values[index] : option.toLowerCase().replace(' ', '-'),
        };
      });
      return options;
    }

    /**
     * This function forms quality options and values where quality ranges from min to max (excluded)
     * @param {number} min
     * @param {number} max
     * @return {{options: ecapp.IOptionObject[], values: number[]}}
     */
    function getQualities(min, max) {
      var options = [];
      var values = [];
      for (var q = min; q < max; q++) {
        var template = "<span class='icon impact-radio-item-icon'>";
        var i;
        for (i = 0; i < q; i++) {
          template += "<i class='ion-alert impact-icon-active'> </i>";
        }
        for (i = 0; i < max - q; i++) {
          template += "<i class='ion-alert'> ";

          if (i === max - q - 1) {
            template += '<span>+</span>';
          }

          template += '</i>';
        }
        template += '</span>';

        options.push(template);
        values.push(q);
      }
      return {
        options: options,
        values: values,
      };
    }

    /**
     * This function converts an array of price-driven triggers into array of filters
     * @param {PriceDrivenTriggerObject[]} triggers
     * @return {MaxFilterObject[]}
     */
    function getPriceDrivenFilter(triggers) {
      var filters = [];
      triggers.forEach(function (trigger) {
        var filter = createFilterFromTrigger(trigger);
        filters.push(filter);
      });
      return filters;
    }

    /**
     * This function converts a price-driven trigger into filter
     * @param {PriceDrivenTriggerObject} trigger
     * @return {MaxFilterObject}
     */
    function createFilterFromTrigger(trigger) {
      return {
        id: trigger.id,
        name: trigger.label,
        type: 'toggle',
        description: trigger.example,
        params: { style: '', enabled: true },
        value: true,
      };
    }

    /**
     *
     * @param {*} groups
     * @param {*} groupId
     * @param {*} filterId
     * @return {any}
     */
    function getFilter(groups, groupId, filterId) {
      var result = null;
      groups.forEach(function (group) {
        if (group.id !== groupId) return;

        group.sections.forEach(function (section) {
          section.filters.forEach(function (filter) {
            if (filter.id === filterId) {
              result = filter;
            }
          });
        });
      });

      return result;
    }

    /**
     * This function updates priority filter from outside.
     * It should be called during changing of priority.
     *
     * @param {Number} priority - new priority
     */
    function updatePriority(priority) {
      if (gDebug) console.log('btTradeIdeasFiltersService: Priority', priority);

      if (gDefaultFilters !== null) {
        var filter = getFilter(gDefaultFilters, 'news-driven', 'priority');

        filter.description = getPriorityDescription(priority);
      }
      if (gDebug) console.log('btTradeIdeasFiltersService:', filter);
    }

    /**
     * This function updates currencies filter from outside.
     * It should be called during changing of currencies.
     *
     * @param {String[]} newCurrencies - new currencies
     * @param {String[]} oldCurrencies - old currencies
     * @return {angular.IPromise<*>}
     */
    function updateCurrencies(newCurrencies, oldCurrencies) {
      if (gDebug) console.log('btTradeIdeasFiltersService: Currencies', newCurrencies, oldCurrencies);

      if (!btRestrictionService.hasFeature('trade-ideas')) {
        if (gDebug) console.log('btTradeIdeasFiltersService: skip instruments update due to user subscription');
        return $q.resolve();
      }

      if (gDefaultFilters !== null) {
        var filter = getFilter(gDefaultFilters, 'news-driven', 'currencies');

        filter.options = newCurrencies.map(function (c) {
          var previous = filter.options.filter(function (option) {
            return option.value === c;
          })[0];
          var isSelected = previous ? previous.selected : true;
          return { value: c, name: c, selected: isSelected, icon: { name: gCachedFlags[c], class: 'flag-img' } };
        });
      }

      return loadUserSettings().then(function (settings) {
        if (settings['news-driven']['currencies']) {
          settings['news-driven']['currencies'] = newCurrencies.filter(function (currency) {
            return (
              settings['news-driven']['currencies'].indexOf(currency) !== -1 || oldCurrencies.indexOf(currency) === -1
            );
          });
          saveUserSettings(settings);
        }
      });
    }

    /**
     * This function updates instruments filter from outside.
     * It should be called during adding or removing instrument from watchlist.
     *
     * @param {String} instrumentName - instrument name
     * @param {Boolean} checked - is instrument selected
     * @return {angular.IPromise<*>}
     */
    function updateInstrumentsByName(instrumentName, checked) {
      var instrument = btTradingService.getInstrumentByBrokerSymbol(instrumentName);
      return updateInstruments(instrument, checked);
    }

    /**
     * This function updates instruments filter from outside.
     * It should be called during adding or removing instrument from watchlist.
     *
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @param {Boolean} checked - is instrument selected
     * @return {angular.IPromise<*>}
     */
    function updateInstruments(instrument, checked) {
      if (gDebug) console.log('btTradeIdeasFiltersService: instruments', instrument, checked);

      if (!btRestrictionService.hasFeature('trade-ideas')) {
        if (gDebug) console.log('btTradeIdeasFiltersService: skip instruments update due to user subscription');
        return $q.resolve();
      }

      if (gDefaultFilters !== null) {
        var filter = getFilter(gDefaultFilters, 'characteristics', 'instruments');

        if (checked) {
          filter.options.push({ value: instrument.id, name: instrument.displayName, selected: true });
        } else {
          filter.options = filter.options.filter(function (option) {
            return option.value !== instrument.id;
          });
        }
      }

      return loadUserSettings().then(function (settings) {
        if (settings['characteristics']['instruments']) {
          if (checked) {
            settings['characteristics']['instruments'].push(instrument.id);
          } else {
            var i = settings['characteristics']['instruments'].indexOf(instrument.id);
            if (i > -1) settings['characteristics']['instruments'].splice(i, 1);
          }
          saveUserSettings(settings);
        }
      });
    }
  }
})();
