/**
 * Created by Sergey Panpurin on 5/7/2018.
 */

(function btTradeMeterClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btTradeMeter';

  angular
    .module('ecapp')
    /**
     * This directive represents range meter.
     *
     * @ngdoc directive
     * @name btTradeMeter
     * @memberOf ecapp
     */
    .directive('btTradeMeter', btTradeMeter);

  btTradeMeter.$inject = [];

  /**
   *
   * @return {angular.IDirective}
   */
  function btTradeMeter() {
    return {
      restrict: 'E',
      scope: {
        trade: '=',
        current: '@?',
        showCurrent: '@?',
        close: '@?',
        entry: '@?',
        size: '@?',
      },
      template: '<bt-range-meter data-params="vm.params" data-update="{{vm.current}}"></bt-range-meter>',
      controller: btTradeMeterController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btTradeMeterController.$inject = ['$scope', '$rootScope'];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   */
  function btTradeMeterController($scope, $rootScope) {
    /*jshint validthis: true*/
    var vm = this;

    var gTextSize = 28 + 7; // px

    vm.size = vm.size ? parseInt(vm.size) : undefined;

    vm.current = vm.current || undefined;
    vm.currentValue = vm.current ? parseFloat(vm.current) : undefined;

    vm.close = vm.close || undefined;
    vm.closeValue = vm.close ? parseFloat(vm.close) : undefined;

    vm.showCurrent = vm.showCurrent || 'true';
    vm.showCurrentValue = vm.showCurrent === 'true';

    vm.entry = vm.entry || undefined;
    vm.entryValue = vm.entry ? parseFloat(vm.entry) : undefined;

    // if (gDebug) console.log('TEST-TEST:', vm.trade.price.open);
    // vm.params = getParams(vm.trade);

    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;

    $scope.$watch('vm.trade', onTradeChanges);
    $scope.$watch('vm.current', onCurrentChanges);
    $scope.$watch('vm.close', onCloseChanges);
    $scope.$watch('vm.entry', onEntryChanges);
    $scope.$watch('vm.entry', onShowCurrentChanges);

    /**
     *
     */
    function onInit() {
      // if (gDebug) console.log('TEST-TEST0:', vm.trade.price.open);
      vm.params = getParams(vm.trade);
    }

    /**
     *
     */
    function onDestroy() {}

    /**
     *
     * @param {*} value
     * @param {*} previous
     */
    function onTradeChanges(value, previous) {
      if (value !== previous) {
        if (value !== undefined) {
          vm.params = getParams(vm.trade);
        }
      }
    }

    /**
     *
     * @param {*} value
     * @param {*} previous
     */
    function onCurrentChanges(value, previous) {
      if (value !== previous) {
        if (value !== undefined) {
          vm.currentValue = parseFloat(vm.current);
          vm.params = getParams(vm.trade);
        }
      }
    }

    /**
     *
     * @param {*} value
     * @param {*} previous
     */
    function onCloseChanges(value, previous) {
      if (value !== previous) {
        if (value !== undefined) {
          vm.closeValue = parseFloat(vm.close);
          vm.params = getParams(vm.trade);
        }
      }
    }

    /**
     *
     * @param {*} value
     * @param {*} previous
     */
    function onEntryChanges(value, previous) {
      if (value !== previous) {
        if (value !== undefined) {
          vm.entryValue = parseFloat(vm.entry);
          vm.params = getParams(vm.trade);
        }
      }
    }

    /**
     *
     * @param {*} value
     * @param {*} previous
     */
    function onShowCurrentChanges(value, previous) {
      if (value !== previous) {
        if (value !== undefined) {
          vm.showCurrentValue = vm.showCurrent === 'true';
          vm.params = getParams(vm.trade);
        }
      }
    }

    /**
     * @param {*} trade
     * @return {{ranges: *[], ticks: *[], markers: {p: *, class: string, hidden: boolean, label: {html: string, class: string[], hidden: boolean}}[], scale: *[], align: string}}
     */
    function getParams(trade) {
      var price = trade.price;
      var scale = Math.max(1.5 * Math.abs(price.risk3), 1.5 * Math.abs(price.reward1));

      var params = initializeParameters(trade.price, scale);

      // Show current price marker
      if (vm.currentValue && price.open && vm.showCurrentValue) {
        params.markers[0].hidden = false;
        params.markers[0].label.hidden = false;
      }

      // Show close price marker (didn't implement yet)
      if (vm.closeValue && price.open) {
        params.markers[1].hidden = false;
        params.markers[1].label.hidden = false;
      }

      // Switch element placement for sell trade
      if (price.target1 < price.open) {
        params.ranges.forEach(function (t) {
          var temp = t.h;
          t.h = t.l;
          t.l = temp;
          if (t.label && t.label.place) {
            t.label.place = t.label.place === 'high' ? 'low' : 'high';
          }
        });
      }

      hideCloseRanges(params, price.reward1, price.reward2, price.reward3, 1, 0, scale, 0.2);

      var p1, p2, d, dpx, offset;

      if (params.ranges[1].label.hidden !== true && vm.size) {
        p1 = getPercent(price.target1, params);
        p2 = getPercent(price.target2, params);
        d = Math.abs(p2 - p1);
        dpx = (d / 100) * vm.size;
        if (dpx < gTextSize) {
          offset = getRealValue((gTextSize / dpx - 1) * d, params);
          if (params.ranges[1].label.place === 'high') {
            if (p2 + offset < 100) {
              params.ranges[1].o = offset;
            } else {
              params.ranges[2].o = -offset;
            }
          } else {
            if (p2 - offset > 0) {
              params.ranges[1].o = -offset;
            } else {
              params.ranges[2].o = offset;
            }
          }
        }
      }

      return params;
    }

    /**
     *
     * @param {*} price
     * @param {*} scale
     * @return {{ranges: *[], ticks: *[], scale: *[], markers: *[], align: string}}
     */
    function initializeParameters(price, scale) {
      return {
        ranges: [
          {
            h: price.target3,
            l: price.open,
            class: 'bt-bg-positive3',
            hidden: true,
            label: {
              html: getLabel('3rd Profit Target', price.target3, price.reward3, price.reward3u, price.open, price.unit),
              class: 'bt-target',
              place: 'high',
              hint: 'Average for all historical wins in this trade.',
              hidden: true,
            },
          },
          {
            h: price.target2,
            l: price.open,
            class: 'bt-bg-positive2',
            label: {
              html: getLabel('2nd Profit Target', price.target2, price.reward2, price.reward2u, price.open, price.unit),
              class: 'bt-target',
              hint: 'Median for all historical wins in this trade.',
              place: 'high',
            },
          },
          {
            h: price.target1,
            l: price.open,
            class: 'bt-bg-positive1',
            label: {
              html: getLabel('1st Profit Target', price.target1, price.reward1, price.reward1u, price.open, price.unit),
              class: 'bt-target',
              hint: 'All historical wins for the trade include this target (safest).',
              place: 'high',
            },
          },
          {
            h: price.open,
            l: price.stop3,
            class: 'bt-bg-negative3',
            label: {
              html: getLabel('Stop Loss', price.stop3, price.risk3, price.risk3u, price.open, price.unit),
              class: 'bt-stop',
              hint: $rootScope.titles.tradeCardStopLoss,
              place: 'low',
            },
          },
          {
            h: price.open,
            l: price.stop2,
            class: 'bt-bg-negative2',
            hidden: !$rootScope.isDevMode,
            label: {
              html: getLabel('Stop Loss (win only)', price.stop2, price.risk2, price.risk2u, price.open, price.unit),
              class: 'bt-stop',
              hint: $rootScope.titles.tradeCardStopLoss,
              place: 'low',
              hidden: true,
            },
          },
          {
            h: price.open,
            l: price.stop1,
            class: 'bt-bg-negative1',
            hidden: false,
            label: {
              html: getLabel('Stop Loss (min)', price.stop1, price.risk1, price.risk1u, price.open, price.unit),
              class: 'bt-stop',
              hint: $rootScope.titles.tradeCardStopLoss,
              place: 'low',
              hidden: true,
            },
          },
        ],
        ticks: [
          {
            p: price.open + scale,
            class: '',
            hidden: true,
            label: {
              html: 'max',
              class: ['bt-white'],
              hidden: true,
            },
          },
          {
            p: price.base,
            class: 'bt-bg-gray',
            label: {
              html: 'base',
              class: 'bt-white',
              hidden: true,
            },
          },
          {
            p: price.open,
            class: '',
            label: {
              html: 'Entry Price ' + (price.open === 0 ? '-' : price.open),
              hint: $rootScope.titles.tradeCardEntering,
              class: 'bt-white',
            },
          },
          {
            p: price.open - scale,
            class: '',
            hidden: true,
            label: {
              html: 'min',
              class: ['bt-white'],
              hidden: true,
            },
          },
        ],
        markers: [
          {
            p: vm.currentValue,
            class: '',
            hidden: true,
            label: {
              html:
                'Current<br><span class="' +
                getMarkerClass(vm.currentValue, price.open, price.target1 < price.open) +
                '">' +
                vm.current +
                '</span>',
              class: ['bt-white', 'bt-current-price'],
              hidden: true,
            },
          },
          {
            p: vm.closeValue,
            class: '',
            hidden: true,
            label: {
              html:
                'Close<br><span class="' +
                getMarkerClass(vm.closeValue, price.open, price.target1 < price.open) +
                '">' +
                vm.current +
                '</span>',
              class: ['bt-white', 'bt-close-price'],
              hidden: true,
            },
          },
        ],
        scale: [price.open - scale, price.open + scale],
        align: 'vertical',
      };
    }

    /**
     * This function hides some ranges
     * @param {*} params
     * @param {*} price1
     * @param {*} price2
     * @param {*} price3
     * @param {*} i1
     * @param {*} i2
     * @param {*} scale
     * @param {*} p
     * @return {*}
     */
    function hideCloseRanges(params, price1, price2, price3, i1, i2, scale, p) {
      // Hide second and third profit targets if difference between first and second targets is less than 20% or if
      // second target is out of scale.
      if ((price1 && price2 && (price2 - price1) / price1 < p) || Math.abs(price1) > scale) {
        params.ranges[i1].hidden = true;
        params.ranges[i1].label.hidden = true;
        params.ranges[i2].hidden = true;
        params.ranges[i2].label.hidden = true;
      } else {
        // Hide third profit target if difference between second and third targets is less than 20% or if third target
        // is out of scale.
        if ((price2 && price3 && (price3 - price2) / price2 < p) || Math.abs(price2) > scale) {
          params.ranges[i2].hidden = true;
          params.ranges[i2].label.hidden = true;
        }
      }

      return params;
    }

    /**
     *
     * @param {*} current
     * @param {*} open
     * @param {*} reverse
     * @return {string}
     */
    function getMarkerClass(current, open, reverse) {
      var cssClass = '';
      if (current && open) {
        if (current > open) {
          cssClass = reverse ? 'bt-lose' : 'bt-win';
        }
        if (current < open) {
          cssClass = reverse ? 'bt-win' : 'bt-lose';
        }
      }
      return cssClass;
    }

    /**
     * This function generates label.
     *
     * @param {String} title
     * @param {Number} price
     * @param {Number} change
     * @param {Number} pip
     * @param {Number} open
     * @param {String} unit
     * @return {String}
     */
    function getLabel(title, price, change, pip, open, unit) {
      return (
        '<span class="bt-name">' +
        title +
        '</span> <span class="bt-price"><br>' +
        (open === 0 ? '-' : price) +
        '</span>&nbsp;' +
        addExtra(change, pip, unit)
      );
    }

    /**
     *
     * @param {Number} change
     * @param {Number} pip
     * @param {String} unit
     * @return {string}
     */
    function addExtra(change, pip, unit) {
      if (pip) {
        return '(<span class="bt-change">' + change + '</span>, <span class="bt-pip">' + pip + '</span> ' + unit + 's)';
      } else {
        return '(<span class="bt-change">' + change + '</span>)';
      }
    }

    /**
     *
     * @param {*} size
     * @param {*} params
     * @return {number}
     */
    function getPercent(size, params) {
      var p = ((size - params.scale[0]) / (params.scale[1] - params.scale[0])) * 100;

      if (p > 100) {
        return 100;
      }

      if (p < 0) {
        return 0;
      }

      return p;
    }

    /**
     *
     * @param {*} size
     * @param {*} params
     * @return {number}
     */
    function getRealValue(size, params) {
      return (size * (params.scale[1] - params.scale[0])) / 100;
    }
  }
})();
