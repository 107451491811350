/**
 * Created by Sergey Panpurin on 9/20/2016.
 */

(function btOnlineTimerClosure() {
  'use strict';

  var gDebug = false;

  angular
    .module('ecapp')
    /**
     * This directive display time now and display status of calendar up to date or out of date.
     * Due to caching of nav bar this code will be executed twice.
     *
     * @ngdoc directive
     * @name btOnlineTimer
     * @memberOf ecapp
     */
    .directive('btOnlineTimer', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          replace: true,
          template: $templateCache.get('directives/common/bt-online-timer.html'),
          scope: {},
          controller: btOnlineTimerController,
        };
      },
    ]);

  btOnlineTimerController.$inject = ['$scope', '$interval', 'btDateService'];

  /**
   *
   * @param {*} $scope
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.IDateService} btDateService
   */
  function btOnlineTimerController($scope, $interval, btDateService) {
    if (gDebug) console.log('Run controller btOnlineTimer!');
    var intervalTimer = $interval(updateTimer, 500, false);

    // cancel $interval on controller destroy
    $scope.$on('$destroy', onDestroy);

    /**
     *
     */
    function onDestroy() {
      $interval.cancel(intervalTimer);
      if (gDebug) console.log('Controller btOnlineTimer was destroyed!');
    }

    /**
     *
     */
    function updateTimer() {
      if (gDebug) console.log('Interval was called (onlineTimer)');
      $scope.timeNow =
        btDateService.getTimerText(btDateService.getNowDate()) + ' ' + btDateService.getUserTimerZoneName();
    }
  }
})();
