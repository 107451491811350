/**
 * Created by Sergey Panpurin on 08/13/17.
 */

// @ts-check
(function btOauthServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btOauthService:';

  angular.module('ecapp').factory('btOauthService', btOauthService);

  btOauthService.$inject = ['$q', '$http', '$ionicLoading', 'btSettings', 'btOpenPopupService'];

  /**
   *  Promise based OAuth for web and mobile
   *  window.open("http://localhost:8100/auth/tradestation/callback" + location.search, "_self")
   *  window.open("http://localhost:8100/auth/oanda/callback" + location.search, "_self")
   *  window.open("http://localhost:8100/auth/spotware/callback" + location.search, "_self")
   *  window.open("http://localhost:8100/auth/tradier/callback" + location.search, "_self")
   *
   * @ngdoc service
   * @name btOauthService
   * @memberOf ecapp
   * @param {angular.IQService} $q
   * @param {angular.IHttpService} $http
   * @param {ionic.ILoadingService} $ionicLoading
   * @param {ecapp.ISettings} btSettings
   * @param {ecapp.IOpenPopupService} btOpenPopupService
   * @return {ecapp.IOauthService}
   */
  function btOauthService($q, $http, $ionicLoading, btSettings, btOpenPopupService) {
    if (gDebug) console.log('Running btOauthService');

    // authorization link (backend wrapper)
    var authUrl = null;
    // token link (backend wrapper)
    var tokenUrl = null;
    // refresh token link (backend wrapper)
    var refreshUrl = null;
    var logoutUrl = null;

    /**
     * Login via selected provider.
     *
     * @param {string} provider - provider name
     * @return {angular.IPromise<ecapp.IAuthResponse>} access data (token, refresh token, expire in)
     */
    function login(provider) {
      if (initialize(provider) === false) {
        return $q.reject(new Error('Unknown authorization provider'));
      }

      if (gDebug) console.log(gPrefix, 'Authorizing...', provider);

      return btOpenPopupService.openPopup(authUrl).then(function (params) {
        return getToken(provider, params);
      });
    }

    /**
     * Logout via selected provider.
     *
     * @param {string} provider - provider name
     * @return {angular.IPromise<any>} promise
     */
    function logout(provider) {
      if (initialize(provider) === false) {
        return $q.reject(new Error('Unknown authorization provider'));
      }

      if (logoutUrl === null) {
        return $q.reject(new Error('Logout is not supported'));
      }

      if (gDebug) console.log(gPrefix, 'Logging out...', provider);
      return _makeRequest(logoutUrl, {});
    }

    /**
     * Initializes authorization and token urls.
     *
     * @param {string} provider - provider name
     * @return {boolean} successful initialization
     */
    function initialize(provider) {
      // TODO Just receive three links or create system to register providers
      if (provider === 'tradestation-demo') {
        authUrl = btSettings.BT_BACKEND_URL + '/auth/tradestation/authorize';
        tokenUrl = btSettings.BT_BACKEND_URL + '/auth/tradestation/token';
        refreshUrl = btSettings.BT_BACKEND_URL + '/auth/tradestation/refresh';
        logoutUrl = btSettings.BT_BACKEND_URL + '/auth/tradestation/logout';
        return true;
      } else if (provider === 'tradestation-real') {
        authUrl = btSettings.BT_BACKEND_URL + '/auth/tradestation/authorize';
        tokenUrl = btSettings.BT_BACKEND_URL + '/auth/tradestation/token';
        refreshUrl = btSettings.BT_BACKEND_URL + '/auth/tradestation/refresh';
        logoutUrl = btSettings.BT_BACKEND_URL + '/auth/tradestation/logout';
        return true;
      } else if (provider === 'oanda-demo') {
        authUrl = btSettings.BT_BACKEND_URL + '/auth/oanda-demo';
        tokenUrl = btSettings.BT_BACKEND_URL + '/auth/oanda-demo/callback';
        refreshUrl = null;
        return true;
      } else if (provider === 'oanda-real') {
        authUrl = btSettings.BT_BACKEND_URL + '/auth/oanda-real';
        tokenUrl = btSettings.BT_BACKEND_URL + '/auth/oanda-real/callback';
        refreshUrl = null;
        return true;
      } else if (provider === 'spotware-demo') {
        authUrl = btSettings.BT_BACKEND_URL + '/auth/spotware-demo';
        tokenUrl = btSettings.BT_BACKEND_URL + '/auth/spotware-demo/callback';
        refreshUrl = btSettings.BT_BACKEND_URL + '/auth/spotware-demo/refresh';
        return true;
      } else if (provider === 'spotware-real') {
        authUrl = btSettings.BT_BACKEND_URL + '/auth/spotware-real';
        tokenUrl = btSettings.BT_BACKEND_URL + '/auth/spotware-real/callback';
        refreshUrl = btSettings.BT_BACKEND_URL + '/auth/spotware-real/refresh';
        return true;
      } else if (provider === 'tradier-demo') {
        authUrl = btSettings.BT_BACKEND_URL + '/auth/tradier-demo';
        tokenUrl = btSettings.BT_BACKEND_URL + '/auth/tradier-demo/callback';
        refreshUrl = null;
        return true;
      } else if (provider === 'tradier-real') {
        authUrl = btSettings.BT_BACKEND_URL + '/auth/tradier-real';
        tokenUrl = btSettings.BT_BACKEND_URL + '/auth/tradier-real/callback';
        refreshUrl = null;
        return true;
      } else if (provider === 'gitter-token') {
        authUrl = btSettings.BT_BACKEND_URL + '/auth/gitter-token';
        tokenUrl = btSettings.BT_BACKEND_URL + '/auth/gitter-token/callback';
        refreshUrl = null;
        return true;
      } else {
        return false;
      }
    }

    /**
     * Makes request to the backend.
     *
     * @param {string} url - URL
     * @param {ecapp.IAuthParams} params - parameters
     * @return {angular.IPromise<ecapp.IAuthResponse>} return data
     */
    function _makeRequest(url, params) {
      $ionicLoading.show({
        template: '<ion-spinner icon="ios"></ion-spinner><p>Log in...</p>',
      });

      return $http
        .get(url, { params: params })
        .then(function (response) {
          if (response.status === 200) {
            // console.log(response.data);
            return $q.resolve(response.data);
          } else {
            // console.log(response);
            return $q.reject(new Error('Error'));
          }
        })
        .catch(function (reason) {
          console.error(reason);
          return $q.reject(reason);
        })
        .finally(function () {
          $ionicLoading.hide();
        });
    }

    /**
     * Exchanges authorization code to access token.
     *
     * @param {string} provider - provider name
     * @param {ecapp.IAuthParams} params - authorization code and other parameters
     * @return {angular.IPromise<ecapp.IAuthResponse>} return access data
     */
    function getToken(provider, params) {
      if (initialize(provider) === false) {
        return $q.reject(new Error('Unknown authorization provider'));
      }

      if (gDebug) console.log(gPrefix, 'Getting token...', provider, params);
      return _makeRequest(tokenUrl, params);
    }

    /**
     * Refreshes access token.
     *
     * @param {string} provider - provider name
     * @param {ecapp.IAuthParams} params - refresh token and other parameters
     * @return {angular.IPromise<ecapp.IAuthResponse>} return access data
     */
    function refreshToken(provider, params) {
      if (initialize(provider) === false) {
        return $q.reject(new Error('Unknown authorization provider'));
      }

      if (refreshUrl === null) {
        return $q.reject(new Error('Refreshing is not supported'));
      }

      if (gDebug) console.log(gPrefix, 'Refreshing token...', provider, params);

      return _makeRequest(refreshUrl, params);
    }

    return {
      login: login,
      logout: logout,
      getToken: getToken,
      refreshToken: refreshToken,
    };
  }
})();
