/**
 * Created by Sergey Panpurin on 5/30/19.
 */

// @ts-check
(function btLevelsServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btLevelsService:';

  angular.module('ecapp').factory('btLevelsService', service);

  service.$inject = ['$q', 'Level', 'UDFSymbol', 'btInstrumentsService'];

  /**
   * This service ...
   *
   * @ngdoc service
   * @name ecapp.btLevelsService
   * @param {angular.IQService} $q
   * @param {ecapp.IGeneralLoopbackService} lbLevel
   * @param {ecapp.IGeneralLoopbackService} lbUDFSymbol
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @return {ecapp.ILevelsService}
   */
  function service($q, lbLevel, lbUDFSymbol, btInstrumentsService) {
    if (gDebug) console.log(gPrefix, 'running...');

    /**
     * @see ecapp.ILevelsProvider
     * @typedef {object} btLevelsProvider
     * @property {string} id - provider identifier
     * @property {string} name - provider name
     */

    /**
     * @see ecapp.IPastLevel
     * @typedef {object} btPastLevel
     * @property {string} id - past level identifier
     * @property {string} name - past level name
     * @property {string} low - past level low price
     * @property {string} high - past level high price
     * @property {number} p - price precision
     */

    /**
     * @see ecapp.ICloseLevel
     * @typedef {object} btCloseLevel
     * @property {string} id - close level identifier
     * @property {string} name - close level name
     * @property {string} mid - medium close level price
     * @property {number} p - price precision
     */

    /**
     * Support and resistance level time to live
     * @type {number}
     */
    var BT_SR_LEVEL_TTL = 24 * 60 * 60 * 1000;

    var MIN_LEVEL_RANGE = 2;
    var MAX_LEVEL_RANGE = 10;

    /**
     * Indicates whether price was shifted
     * @type {boolean}
     */
    var gLevelsShifted = false;

    /**
     * Shift in real price units rounded to pip or tick for each
     * @type {object}
     */
    var gPriceShifts = {};

    /**
     * Name of levels
     * @type {Record<string, string>}
     */
    var gLevelNames = {
      YH: 'Yst - H',
      TYH: 'This Year - H',
      W1H: 'Prev week - H',
      W52H: '52 weeks - H',
      YL: 'Yst - L',
      TYL: 'This Year - L',
      W1L: 'Prev week - L',
      W52L: '52 weeks - L',
    };

    /**
     * Support and resistance levels
     * @type {string[]}
     */
    var gSupResLevels = ['W1', 'W4', 'W12', 'W52'];

    /**
     * Hidden levels
     * @type {string[]}
     */
    var gHiddenLevels = ['TL', 'D1L', 'TWL', 'TML', 'TH', 'D1H', 'TWH', 'TMH', 'YC'];

    /**
     * Supported level providers
     * @type {Record<string, ecapp.ILevelsProvider>}
     */
    var gProviders = {
      'bt-oanda': {
        id: 'bt-oanda',
        name: 'BetterTrader',
      },
    };

    var gLdsLevels = {};

    var gLdsRules = [
      // ['T',   moment().startOf('day'), moment().endOf('day')],
      // ['Y',   moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
      ['TW', moment().startOf('week'), moment().endOf('day')],
      ['TM', moment().startOf('month'), moment().endOf('day')],
      ['TY', moment().startOf('year'), moment().endOf('day')],
      ['W1', moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
      ['W52', moment().subtract(52, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
    ];

    // console.log(gLdsRules);

    return {
      getProviders: getProviders,
      getProviderById: getProviderById,
      getLastLevels: getLastLevels,
      getLdsLevels: getLdsLevels,
      getAllLevelsMessages: getAllLevelsMessages,
      calculatePriceShift: calculatePriceShift,
      shiftLevels: shiftLevels,
      isLevelsShifted: isLevelsShifted,
      getPastLevels: getPastLevels,
      getCloseLevels: getCloseLevels,
      updateCloseLevels: updateCloseLevels,
    };

    // Public

    /**
     * This function returns supported level providers.
     *
     * @alias ecapp.btLevelsService#getProviders
     * @return {ecapp.ILevelsProvider[]}
     */
    function getProviders() {
      return Object.keys(gProviders).map(function (key) {
        return gProviders[key];
      });
    }

    /**
     * This function returns provider with specific identifier or undefined.
     *
     * @alias ecapp.btLevelsService#getProviderById
     * @param {string} id - provider identifier
     * @return {ecapp.ILevelsProvider| undefined}
     */
    function getProviderById(id) {
      return gProviders[id];
    }

    /**
     * This function gets last levels for specific instrument.
     *
     * @alias ecapp.btLevelsService#getLastLevels
     * @param {string} origin - levels origin: bt-oanda
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @param {number} price - current instrument price it's used to customize level
     * @return {angular.IPromise<{levels:ecapp.IChartLevel[], error: Error | null}>}
     */
    function getLastLevels(origin, instrument, price) {
      var provider = getProvider(origin);
      if (!provider)
        return $q.reject(new Error('Unknown provider of support and resistance levels: "' + origin + '".'));

      if (provider.id === 'none') return $q.resolve({ levels: [], error: null });

      var symbol = getSymbol(instrument, origin);
      if (!symbol) return $q.reject(new Error('No support and resistance levels for ' + instrument.displayName + '.'));

      var shift = getPriceShift(instrument, origin);

      var query = {
        filter: {
          where: { origin: provider.id, instrument: symbol },
          order: ['time DESC'],
          limit: 4,
        },
      };

      return lbLevel
        .find(query)
        .$promise.then(fixLevelFormat)
        .then(parseLastLevels)
        .then(function (data) {
          var levels = createChartLevels(data.message, instrument, price, shift);
          return { levels: levels, error: data.error };
        });
    }

    /**
     * This function gets last levels for specific instrument.
     *
     * @alias ecapp.btLevelsService#getLdsLevels
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @param {number} price - current instrument price it's used to customize level
     * @return {angular.IPromise<{levels:ecapp.IChartLevel[], error: ?Error}>}
     */
    function getLdsLevels(instrument, price) {
      var symbol = instrument.getSymbol();
      if (gLdsLevels[symbol]) {
        return gLdsLevels[symbol];
      } else {
        var to = Math.floor(Date.now() / 1000);
        var from = to - 53 * 7 * 24 * 3600;
        var options = { symbol: 'LDS:' + instrument.getSymbol(), from: from, to: to, resolution: '1D' };

        var levels = {
          // T: [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY],
          // Y: [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY],
          TW: [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY],
          W1: [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY],
          TM: [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY],
          TY: [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY],
          W52: [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY],
        };

        gLdsLevels[symbol] = lbUDFSymbol
          .history(options)
          .$promise.then(function (res) {
            var n = res.c.length;
            for (var i = 0; i < n; i++) {
              gLdsRules.forEach(function (rule) {
                if (moment(res.t[i] * 1000) >= rule[1] && moment(res.t[i] * 1000) < rule[2]) {
                  levels[rule[0]][0] = Math.min(levels[rule[0]][0], res.c[i]);
                  levels[rule[0]][1] = Math.max(levels[rule[0]][1], res.c[i]);
                }
              });
            }
            return levels;
          })
          .then(function (result) {
            return [generateLdsLevels(instrument.getSymbol(), Date.now(), price, result)];
          })
          .then(fixLevelFormat)
          .then(parseLastLevels)
          .then(function (data) {
            var levels = createChartLevels(data.message, instrument, price, 0);
            return { levels: levels, error: data.error };
          });

        return gLdsLevels[symbol];
      }
    }

    /**
     * This function gets last levels for specific instrument.
     *
     * @alias ecapp.btLevelsService#getAllLevelsMessages
     * @param {string} origin - levels origin: bt-oanda
     * @return {angular.IPromise<ecapp.IParsedMarketLevelsDocument[]>}
     */
    function getAllLevelsMessages(origin) {
      var provider = getProvider(origin);
      if (!provider)
        return $q.reject(new Error('Unknown provider of support and resistance levels: "' + origin + '".'));

      if (provider.id === 'none') return $q.resolve([]);

      var query = {
        filter: {
          where: { origin: provider.id },
          order: ['time DESC', 'instrument ASC'],
          limit: 100,
        },
      };

      return lbLevel.find(query).$promise.then(fixLevelFormat).then(filterMessages).then(convertMessages);
    }

    /**
     * This function calculate price shift.
     *
     * @alias ecapp.btLevelsService#calculatePriceShift
     * @param {ecapp.IParsedMarketLevelsDocument} message
     * @param {number} price
     * @param {number} time
     * @return {number}
     */
    function calculatePriceShift(message, price, time) {
      var bit = btInstrumentsService.getPriceUnitValue(message.instrument);
      var shift = (price - message.price) / bit;
      var delay = message.time - time;
      if (gDebug)
        console.log(
          'Levels:',
          message.instrument.displayName,
          message.time,
          message.price,
          time,
          price,
          delay,
          bit,
          shift
        );
      return Math.round(shift) * bit;
    }

    /**
     * This function shift levels.
     *
     * @alias ecapp.btLevelsService#shiftLevels
     * @param {*} items - ???
     */
    function shiftLevels(items) {
      items.forEach(function (item) {
        savePriceShift(item.message.instrument, item.message.origin, item.shift);
      });
      gLevelsShifted = true;
    }

    /**
     * This function indicates whether levels was shifted.
     *
     * @alias ecapp.btLevelsService#isLevelsShifted
     * @return {boolean}
     */
    function isLevelsShifted() {
      return gLevelsShifted;
    }

    /**
     * This function parses past levels from list of regular levels.
     *
     * @alias ecapp.btLevelsService#getPastLevels
     * @param {ecapp.IChartLevel[]} levels - list of regular levels.
     * @return {ecapp.IPastLevel[]}
     */
    function getPastLevels(levels) {
      var precision = levels.length ? levels[0].precision : 1;

      /** @type {ecapp.IPastLevel[]} */
      var pastLevels = [
        { id: 'Y', name: 'Yesterday ', low: '---', high: '---', p: precision },
        { id: 'W1', name: 'Prev. week', low: '---', high: '---', p: precision },
        { id: 'TW', name: 'This week ', low: '---', high: '---', p: precision },
        { id: 'TM', name: 'This month', low: '---', high: '---', p: precision },
        { id: 'TY', name: 'This year ', low: '---', high: '---', p: precision },
        { id: 'W52', name: '52 weeks  ', low: '---', high: '---', p: precision },
      ];

      levels.forEach(function (level) {
        pastLevels.forEach(function (pastLevel, i) {
          if (level.tags.indexOf(pastLevel.id + 'L') !== -1) pastLevels[i].low = level.price.mid;
          if (level.tags.indexOf(pastLevel.id + 'H') !== -1) pastLevels[i].high = level.price.mid;
        });
      });

      return pastLevels;
    }

    /**
     * This function parses close levels from list of regular levels.
     *
     * @alias ecapp.btLevelsService#getCloseLevels
     * @return {ecapp.ICloseLevel[]} - list of close levels
     */
    function getCloseLevels() {
      return [
        { id: 'R3', name: 'R3', mid: '---', p: 1 },
        { id: 'R2', name: 'R2', mid: '---', p: 1 },
        { id: 'R1', name: 'R1', mid: '---', p: 1 },
        { id: 'CP', name: 'CP', mid: '---', p: 1 },
        { id: 'S1', name: 'S1', mid: '---', p: 1 },
        { id: 'S2', name: 'S2', mid: '---', p: 1 },
        { id: 'S3', name: 'S3', mid: '---', p: 1 },
      ];
    }

    /**
     * This function parses close levels from list of regular levels.
     *
     * @alias ecapp.btLevelsService#updateCloseLevels
     * @param {ecapp.ICloseLevel[]} closeLevels - list of close levels
     * @param {ecapp.IChartLevel[]} chartLevels - list of regular levels
     * @param {number} price - current price
     * @return {ecapp.ICloseLevel[]} - list of close levels
     */
    function updateCloseLevels(closeLevels, chartLevels, price) {
      var precision = chartLevels.length ? chartLevels[0].precision : 1;

      closeLevels.forEach(function (level) {
        level.p = precision;
      });

      if (price) {
        closeLevels[3].mid = price;
      }

      var resistances = [];
      var supports = [];

      chartLevels.forEach(function (level) {
        if (!isSupResLevel(level)) return;

        if (level.price.mid >= price) {
          resistances.push(level);
        } else {
          supports.push(level);
        }
      });

      resistances.sort(function (a, b) {
        return a.price.mid - b.price.mid;
      });

      supports.sort(function (a, b) {
        return b.price.mid - a.price.mid;
      });

      for (var j = 0; j < 3; j++) {
        if (resistances[j]) closeLevels[2 - j].mid = resistances[j].price.mid;
        if (supports[j]) closeLevels[4 + j].mid = supports[j].price.mid;
      }

      return closeLevels;
    }

    // Private

    /**
     * This function fixes format of levels messages.
     *
     * @private
     * @param {ecapp.IMarketLevelsDocument[]} messages - messages
     * @return {ecapp.IMarketLevelsDocument[]}
     */
    function fixLevelFormat(messages) {
      messages.forEach(function (message) {
        message.levels.forEach(function (level) {
          if (typeof level.price === 'string') {
            // @ts-ignore
            level.price = parseFloat(level.price.toString());
            level.priority = parseInt(level.priority.toString());
            level.range = parseInt(level.range.toString());
          }
        });
      });

      return messages;
    }

    /**
     * This function filters messages by time of one message.
     *
     * @private
     * @param {ecapp.IMarketLevelsDocument[]} messages
     * @return {ecapp.IMarketLevelsDocument[]}
     */
    function filterMessages(messages) {
      if (messages.length) {
        var reference = messages[0].time;

        return messages.filter(function (message) {
          return message.time === reference;
        });
      } else {
        return [];
      }
    }

    /**
     * This function converts raw message to bt message.
     *
     * @private
     * @param {ecapp.IMarketLevelsDocument[]} messages
     * @return {ecapp.IParsedMarketLevelsDocument[]}
     */
    function convertMessages(messages) {
      return messages.map(convertMessage).filter(removeNull);

      /**
       *
       * @param {ecapp.IMarketLevelsDocument} rawMessage
       * @return {ecapp.IParsedMarketLevelsDocument}
       */
      function convertMessage(rawMessage) {
        var instrument = convertSymbol(rawMessage.origin, rawMessage.instrument);
        if (instrument) {
          /** @type {ecapp.IParsedMarketLevelsDocument} */
          var btMessage = JSON.parse(JSON.stringify(rawMessage));
          btMessage.instrument = instrument;
          return btMessage;
        } else {
          return null;
        }
      }

      /**
       *
       * @param {*} value
       * @return {boolean}
       */
      function removeNull(value) {
        return value !== null;
      }
    }

    /**
     * This function parses backend response and return last levels.
     *
     * @private
     * @param {ecapp.IMarketLevelsDocument[]} results - array of levels
     * @return {angular.IPromise<{message: ecapp.IMarketLevelsDocument, error: ?Error }>}
     */
    function parseLastLevels(results) {
      if (results.length === 0) {
        return $q.reject(new Error("Support and resistance levels weren't found."));
      } else if (isLevelsExpired(results[0])) {
        return $q.resolve({ message: results[0], error: new Error('Support and resistance levels were expired.') });
      } else {
        return $q.resolve({ message: results[0], error: null });
      }
    }

    /**
     * This function checks whether levels is expired.
     *
     * @private
     * @param {ecapp.IMarketLevelsDocument} message - levels message
     * @return {boolean}
     */
    function isLevelsExpired(message) {
      return Date.now() - message.time * 1000 > BT_SR_LEVEL_TTL;
    }

    /**
     * This function creates chart levels based on levels message.
     *
     * @private
     * @param {ecapp.IMarketLevelsDocument} message - levels message
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @param {number} price - current instrument price
     * @param {number} shift - price shift
     * @return {ecapp.IChartLevel[]}
     */
    function createChartLevels(message, instrument, price, shift) {
      return message.levels.map(function (level) {
        return convertLevel(message.time, level, instrument, message.price, price, shift);
      });
    }

    /**
     * This function convert level object from database to chart level object.
     *
     * @private
     * @param {number} time - timestamp in seconds
     * @param {ecapp.IMarketLevel} level - level object
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @param {number} messagePrice - current instrument price
     * @param {number} currentPrice - current instrument price
     * @param {number} shift - price shift
     * @return {ecapp.IChartLevel}
     */
    function convertLevel(time, level, instrument, messagePrice, currentPrice, shift) {
      var unit = btInstrumentsService.getPriceUnitValue(instrument);

      var rawRange = level.range;

      if (level.range !== 0) {
        if (level.range < MIN_LEVEL_RANGE) level.range = MIN_LEVEL_RANGE;
        if (level.range > MAX_LEVEL_RANGE) level.range = MAX_LEVEL_RANGE;
      }

      var halfRange = 0.5 * level.range * unit;

      var precision = btInstrumentsService.getPrecision(instrument);
      var refLevelPrice = messagePrice.toFixed(precision);
      var minLevelPrice = (level.price + shift - halfRange).toFixed(precision);
      var minCloseLevelPrice = (level.price + shift - halfRange - 2 * unit).toFixed(precision);
      var midLevelPrice = (level.price + shift).toFixed(precision);
      var maxLevelPrice = (level.price + shift + halfRange).toFixed(precision);
      var maxCloseLevelPrice = (level.price + shift + halfRange + 2 * unit).toFixed(precision);

      var chartLevel = {
        date: new Date(time * 1000),
        price: {
          ref: parseFloat(refLevelPrice),
          min: parseFloat(minLevelPrice),
          mid: parseFloat(midLevelPrice),
          max: parseFloat(maxLevelPrice),
          minClose: parseFloat(minCloseLevelPrice),
          maxClose: parseFloat(maxCloseLevelPrice),
        },
        precision: precision,
        shift: shift,
        range: level.range,
        rawRange: rawRange,
        name: '',
        tags: level.name.split(' '),
        unit: unit,
        visible: true,
        color: 'gray',
      };

      chartLevel.name = level.name
        .split(' ')
        .map(function (value) {
          if (isSupResTag(value)) return value;
          if (isHiddenTag(value)) return undefined;
          return gLevelNames[value];
        })
        .filter(function (value) {
          return !!value;
        })
        .join(', ');

      chartLevel.visible = !!chartLevel.name;

      chartLevel.color = getLevelColor(level, parseFloat(minLevelPrice), parseFloat(maxLevelPrice), currentPrice);

      return chartLevel;
    }

    /**
     * This function checks if the tag is support and resistance tag.
     *
     * @private
     * @param {string} tag - level tag
     * @return {boolean}
     */
    function isSupResTag(tag) {
      return gSupResLevels.indexOf(tag) !== -1;
    }

    /**
     * This function checks if the tag is hidden tag.
     *
     * @private
     * @param {string} tag - level tag
     * @return {boolean}
     */
    function isHiddenTag(tag) {
      return gHiddenLevels.indexOf(tag) !== -1;
    }

    /**
     * This function checks if the level is support and resistance.
     *
     * @private
     * @param {ecapp.IChartLevel} level - level object
     * @return {boolean}
     */
    function isSupResLevel(level) {
      return gSupResLevels.some(function (value) {
        return level.tags.indexOf(value) !== -1;
      });
    }

    /**
     * This function returns level color.
     *
     * @private
     * @param {ecapp.IMarketLevel} level -
     * @param {number} minLevelPrice - minimum level price
     * @param {number} maxLevelPrice - maximum level price
     * @param {number} currentPrice - current market price
     * @return {string}
     */
    function getLevelColor(level, minLevelPrice, maxLevelPrice, currentPrice) {
      if (level.id === 'LIS') return 'magenta';
      if (level.id === 'BBZ') return 'blue';
      if (level.range === 0) return 'white';
      if (currentPrice > maxLevelPrice) return 'green';
      if (currentPrice < minLevelPrice) return 'red';

      return 'cyan';
    }

    /**
     * This function returns levels provider object of undefined.
     *
     * @private
     * @param {string} origin - levels origin: bt-oanda
     * @return {ecapp.ILevelsProvider|undefined}
     */
    function getProvider(origin) {
      return gProviders[origin];
    }

    /**
     * This function returns instrument symbol for specified origin.
     *
     * @private
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @param {string} origin - levels origin: bt-oanda
     * @return {string|undefined}
     */
    function getSymbol(instrument, origin) {
      if (origin === 'bt-oanda') return getBTOandaSymbol(instrument);
      return undefined;
    }

    /**
     * This function returns instrument symbol for bettertrader levels.
     *
     * @private
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @return {string|undefined}
     */
    function getBTOandaSymbol(instrument) {
      return instrument.OandaSymbol;
    }

    /**
     * This function returns instrument object for level symbol.
     *
     * @private
     * @param {string} origin - levels origin: bt-oanda
     * @param {string} symbol - level symbol
     * @return {ecapp.ITradingInstrument | null}
     */
    function convertSymbol(origin, symbol) {
      if (origin === 'bt-oanda') return convertBTOandaSymbol(symbol);
      return null;
    }

    /**
     * This function returns instrument object for level symbol.
     *
     * @private
     * @param {string} symbol - oanda symbol
     * @return {ecapp.ITradingInstrument | null}
     */
    function convertBTOandaSymbol(symbol) {
      var instrument = btInstrumentsService.getInstrumentByField('OandaSymbol', symbol);
      return instrument ? instrument : null;
    }

    /**
     * This function calculate price shift.
     *
     * @private
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @param {string} origin - levels origin: bt-oanda
     * @return {number}
     */
    function getPriceShift(instrument, origin) {
      if (gPriceShifts[origin] && gPriceShifts[origin][instrument.id]) {
        return gPriceShifts[origin][instrument.id];
      } else return 0;
    }

    /**
     * This function saves price shift in memory.
     *
     * @private
     * @param {ecapp.ITradingInstrument} instrument
     * @param {string} origin - levels origin: bt-oanda
     * @param {?number} shift - price shift
     */
    function savePriceShift(instrument, origin, shift) {
      if (gPriceShifts[origin] === undefined) gPriceShifts[origin] = {};

      gPriceShifts[origin][instrument.id] = shift || 0;
    }

    /**
     *
     * @param {*} symbol
     * @param {*} time
     * @param {*} price
     * @param {*} compact
     * @return {*}
     */
    function generateLdsLevels(symbol, time, price, compact) {
      var values = {};
      ['TW', 'W1', 'TM', 'TY', 'W52'].forEach(function (key) {
        if (values[compact[key][0]] === undefined) {
          values[compact[key][0]] = [];
        }
        values[compact[key][0]].push(key + 'L');

        if (values[compact[key][1]] === undefined) {
          values[compact[key][1]] = [];
        }
        values[compact[key][1]].push(key + 'H');
      });

      var levels = Object.keys(values).map(function (value, i) {
        return { id: (++i).toString(), name: values[value].join(' '), price: value, range: 0, priority: 4 };
      });

      return {
        id: window.btTools.getUniqueID('lds-levels'),
        env: 'prod',
        ttl: 21600, // ???
        created: new Date(time), // ???
        time: time,
        instrument: symbol,
        price: price,
        origin: 'lds',
        levels: levels,
      };
    }
  }
})();
