/**
 * Created by Sergey on 7/27/20.
 */

(function btIframeLoaderClosure() {
  'use strict';

  angular.module('ecapp').directive('btOnIframeLoad', onLoadDirective).directive('btIframeLoader', loaderDirective);

  onLoadDirective.$inject = [];

  /**
   *
   * @return {angular.IDirective}
   */
  function onLoadDirective() {
    return {
      scope: {
        callBack: '&btOnIframeLoad',
      },
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.on('load', function () {
          console.log('in directive iframe loaded', element);
          return scope.callBack({ element: element[0].src });
        });
      },
    };
  }

  loaderDirective.$inject = [];

  /**
   *
   * @return {angular.IDirective}
   */
  function loaderDirective() {
    return {
      restrict: 'E',
      scope: {
        src: '@',
      },
      template:
        '<div class="bt-iframe-loader" ng-if="isLoading">' +
        '<div><ion-spinner icon="ios"></ion-spinner></div>' +
        '<div class="title">Loading Page...</div>' +
        '</div>' +
        '<iframe ng-style="iframeStyle" style="width: 100%; height: 100%;" bt-on-iframe-load="onLoad(element)" ng-src="{{iframeURL}}"></iframe>',
      controller: loaderController,
    };
  }

  loaderController.$inject = ['$scope', '$sce'];

  /**
   *
   * @param {*} $scope
   * @param {angular.ISCEService} $sce
   */
  function loaderController($scope, $sce) {
    $scope.isLoading = true;
    $scope.iframeStyle = { display: 'none' };

    console.log('iframe', $scope.src);
    $scope.iframeURL = $sce.trustAsResourceUrl($scope.src);
    console.log('iframe', $scope.iframeURL);

    $scope.onLoad = function (element) {
      console.log('iframe loaded with', element);
      $scope.isLoading = false;
      $scope.iframeStyle = { display: 'block' };
    };

    $scope.lineStyle = {
      width: $scope.size + '%',
    };
  }
})();
