/**
 * Created by Sergey Panpurin on 8/13/2017.
 */

(function btEventCardContentsClosure() {
  'use strict';

  var app = angular.module('ecapp');

  /**
   * This directive card content of regular event
   *
   * @ngdoc directive
   * @name btEventCardContentRegular
   * @memberOf ecapp
   */

  app.directive('btEventCardContentRegular', [
    '$templateCache',
    /**
     *
     * @param {angular.ITemplateCacheService} $templateCache
     * @return {any}
     */
    function ($templateCache) {
      return {
        restrict: 'E',
        template: $templateCache.get('directives/event-card/regular.html'),
      };
    },
  ]);

  /**
   * This directive card content of speech event
   *
   * @ngdoc directive
   * @name btEventCardContentSpeech
   * @memberOf ecapp
   */

  app.directive('btEventCardContentSpeech', [
    '$templateCache',
    /**
     *
     * @param {angular.ITemplateCacheService} $templateCache
     * @return {any}
     */
    function ($templateCache) {
      return {
        restrict: 'E',
        template: $templateCache.get('directives/event-card/speech.html'),
      };
    },
  ]);

  /**
   * This directive card content of report event
   *
   * @ngdoc directive
   * @name btEventCardContentReport
   * @memberOf ecapp
   */

  app.directive('btEventCardContentReport', [
    '$templateCache',
    /**
     *
     * @param {angular.ITemplateCacheService} $templateCache
     * @return {any}
     */
    function ($templateCache) {
      return {
        restrict: 'E',
        template: $templateCache.get('directives/event-card/report.html'),
      };
    },
  ]);
})();
