/**
 * Created by Orly on 19/02/2019.
 */

(function btBarometerControllerClosure() {
  'use strict';
  angular.module('ecapp').controller('btBarometerController', btBarometerController);

  btBarometerController.$inject = [
    '$scope',
    '$timeout',
    'btAdvertisingService',
    'btMoversAlertsService',
    'btTradeIdeasService',
  ];

  /**
   * This is controller for News Dashboard.
   *
   * @ngdoc controller
   * @name btBarometerController
   * @memberOf ecapp
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {angular.ITimeoutService} $timeout -
   * @param {ecapp.IAdvertisingService} btAdvertisingService
   * @param {ecapp.IMoversAlertsService} btMoversAlertsService
   * @param {ecapp.ITradeIdeasService} btTradeIdeasService
   */
  function btBarometerController($scope, $timeout, btAdvertisingService, btMoversAlertsService, btTradeIdeasService) {
    console.log('Running btBarometerController');

    $scope.isVisible = true;
    $scope.hasAdvertising = btAdvertisingService.checkHasAdvertising();

    $scope.doRefresh = doRefresh;

    /**
     *
     */
    function doRefresh() {
      $scope.isVisible = false;

      $timeout(function () {
        $scope.isVisible = true;
        btMoversAlertsService.refresh();
        btTradeIdeasService.refreshCachedActiveTradeIdeas().then(function () {
          $scope.$broadcast('scroll.refreshComplete');
        });
      }, 500);
    }
  }
})();
