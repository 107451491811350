(function () {
  'use strict';

  angular
    .module('ecapp')
    /**
     * ???
     *
     * @ngdoc directive
     * @name btEventModalReportContent
     * @memberOf ecapp
     */
    .directive('btEventModalReportContent', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/event-modal/report/content.html'),
        };
      },
    ]);
})();
