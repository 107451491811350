/**
 * Created by Sergey Panpurin on 6/5/2018.
 */

(function btErrorPageControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btErrorPageController', btErrorPageController);

  btErrorPageController.$inject = [
    '$scope',
    '$window',
    '$location',
    '$state',
    '$stateParams',
    'btErrorService',
    'btSettingsService',
  ];

  /**
   * Controller for about us page.
   *
   * @ngdoc controller
   * @name ecapp.btErrorPageController
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.IWindowService} $window - window object service
   * @param {angular.ILocationService} $location
   * @param {angular.ui.IStateService} $state
   * @param {angular.ui.IStateService} $stateParams
   * @param {ecapp.IErrorService} btErrorService
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btErrorPageController($scope, $window, $location, $state, $stateParams, btErrorService, btSettingsService) {
    console.log('Running btErrorPageController');

    $scope.$on('$destroy', onDestroy);

    $scope.logoSrc = btSettingsService.getAssets('login-logo-src');
    $scope.logoAlt = btSettingsService.getAssets('login-logo-alt');

    if ($stateParams.code === btErrorService.ErrorCode.noInternet) {
      $scope.errorName = 'No Internet';
      $scope.errorMessageHtml = 'Problem with internet connection. Try to restart application.';
    } else {
      $scope.errorName = 'Unknown Error';
      $scope.errorMessageHtml =
        'Something gone wrong. <br><br>' +
        'Try to restart application. <br><br>' +
        'If it will happen again contact us.';
    }

    $scope.restartApplication = restartApplication;
    $scope.clearCache = clearCache;

    /**
     *
     */
    function onDestroy() {
      console.log('Destroy btErrorPageController');
    }

    /**
     *
     */
    function restartApplication() {
      $location.path('/login');
      $window.location.reload();
    }

    /**
     *
     */
    function clearCache() {
      localStorage.removeItem('$LoopBack$accessTokenId');
      localStorage.removeItem('$LoopBack$currentUserId');
      localStorage.removeItem('$LoopBack$rememberMe');

      localStorage.removeItem('currentUser');
      localStorage.removeItem('updateWasRecommended');
      localStorage.removeItem('accessData');
      localStorage.removeItem('accountImage');

      localStorage.removeItem('angular-cache.caches.btInstrumentsCache.data.btInstrumentsCache');
      localStorage.removeItem('angular-cache.caches.btInstrumentsCache.data.InstrumentsCache');
      localStorage.removeItem('angular-cache.caches.btInstrumentsCache.keys');

      localStorage.removeItem('angular-cache.caches.currenciesCache.data.Flags');
      localStorage.removeItem('angular-cache.caches.currenciesCache.data.List');
      localStorage.removeItem('angular-cache.caches.currenciesCache.keys');

      localStorage.removeItem('angular-cache.caches.instrumentsCache.data.Instruments');
      localStorage.removeItem('angular-cache.caches.instrumentsCache.keys');

      localStorage.removeItem('btChartSymbol1');
      localStorage.removeItem('btChartSymbol2');
      localStorage.removeItem('btChartSymbol3');
      localStorage.removeItem('btChartSymbol4');

      localStorage.removeItem('btChartTimeZone');
      localStorage.removeItem('btDashboardTab');
      localStorage.removeItem('btSettings');
      localStorage.removeItem('btTradingPlatform');

      localStorage.removeItem('btVoiceAssistantAsked');
      localStorage.removeItem('btVoiceAssistantAskedDate');
      localStorage.removeItem('btVoiceAssistantEnable');
      localStorage.removeItem('btVoiceAssistantLastUse');
      localStorage.removeItem('btVoiceAssistantMute');
      localStorage.removeItem('btVoiceAssistantVoiceURI');
      localStorage.removeItem('btVoiceAssistantTweetReadable');
      localStorage.removeItem('btVoiceAssistantVolume');

      localStorage.removeItem('btInsightTabShowEvents');
      localStorage.removeItem('btInsightTabShowMoments');
      localStorage.removeItem('btInsightTabJustFromWatchList');
      localStorage.removeItem('btInsightTabShowWeak');
      localStorage.removeItem('btInsightTabShowLow');
      localStorage.removeItem('btInsightTabShowEmpty');

      localStorage.removeItem('tsCache');
      localStorage.removeItem('hasTradeAbility');

      localStorage.setItem('isFirstInstall', 'false');
      localStorage.setItem('isDevMode', 'false');
      localStorage.setItem('isDebugMode', 'false');
      localStorage.setItem('offsetDate', '0');
      localStorage.setItem('realtimeTest', 'false');
      localStorage.setItem('showFullMsg', 'true');

      $location.path('/login');
      $window.location.reload();
    }
  }
})();
