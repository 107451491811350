/**
 * Created by Sergey Panpurin on 10/6/2016.
 */

(function btDevControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btDevController', btDevController);

  btDevController.$inject = [
    '$q',
    '$rootScope',
    '$ionicModal',
    '$templateCache',
    '$scope',
    'appVersions',
    'Reviewer',
    'btBranchService',
    'btOauthService',
    '$timeout',
    'btDevService',
    'btBadgeService',
    'btPushNotificationService',
    'btShareScopeService',
    'btTagService',
    'btSettingsService',
    'btToastrService',
  ];

  /**
   * This is controller for currencies and priority views.
   *
   * @ngdoc controller
   * @name btDevController
   * @memberOf ecapp
   * @param {angular.IQService} $q - promise interface
   * @param {ecapp.ICustomRootScope} $rootScope - common angular rootScope service
   * @param {ionic.IModalService} $ionicModal - ionic modal service
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {*} appVersions - information about app version
   * @param {ecapp.IGeneralLoopbackService} lbUser
   * @param {ecapp.IBranchService} btBranchService
   * @param {ecapp.IOauthService} btOauthService
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.IDevService} btDevService
   * @param {ecapp.IBadgeService} btBadgeService
   * @param {ecapp.IPushNotificationService} btPushNotificationService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.ITagService} btTagService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IToastrService} btToastrService
   */
  function btDevController(
    $q,
    $rootScope,
    $ionicModal,
    $templateCache,
    $scope,
    appVersions,
    lbUser,
    btBranchService,
    btOauthService,
    $timeout,
    btDevService,
    btBadgeService,
    btPushNotificationService,
    btShareScopeService,
    btTagService,
    btSettingsService,
    btToastrService
  ) {
    console.log('Running btDevController');

    $scope.debug = {};
    $scope.debug.offsetDate = parseInt(window.localStorage.getItem('offsetDate') || 0) || 0;
    $scope.debug.hasOffsetDate = $scope.debug.offsetDate > 0;
    $scope.debug.realtimeTest = window.localStorage.getItem('realtimeTest') === 'true';
    $scope.debug.showFullMsg = window.localStorage.getItem('showFullMsg') === 'true';
    $scope.debug.hasTradeAbility = window.hasTradeAbility;
    $scope.debug.isDevMode = window.isDevMode;
    $scope.debug.isDebugMode = window.isDebugMode;

    $scope.ionicVersion = ionic.version;
    $scope.angularVersion = angular.version.full;

    $scope.range = {
      low: '-4.5',
      high: '3.6',
      actual: '-3.3',
      benchmark: '0',
      show: true,
    };

    $scope.row = { eventsInfo: { direction: 1, unit: '' } };
    $scope.appVersions = appVersions;
    $scope.releaseHash = window.releaseHash;

    $scope.isMobile = window.isMobile;
    $scope.isMobileWeb = window.isMobileWeb;
    $scope.isLarge = window.isLarge;
    $scope.isIOS = window.isIOS;
    $scope.isAndroid = window.isAndroid;
    $scope.isDevelopment = window.isDevelopment;
    $scope.isTesting = window.isTesting;
    $scope.isProduction = window.isProduction;
    $scope.isDevMode = window.isDevMode;
    $scope.hasAnalytics = window.hasAnalytics;
    $scope.hasErrorTracking = window.hasErrorTracking;
    $scope.LoopBackEndpoint = window.btSettings.BT_LOOPBACK_URL;

    $scope.pushNotification = {
      id: window.btSettings.BT_ONE_SIGNAL_ID,
      domain: window.isApp ? 'mobile' : window.btSettings.BT_ONE_SIGNAL_DOMAIN,
      status: null,
      prompt: btPushNotificationService.allow,
      // subscribe: btPushNotificationService.subscribe,
      // unsubscribe: btPushNotificationService.unsubscribe
    };
    $scope.savedMobileToken = null;
    $scope.savedDesktopToken = null;

    $scope.referral = {
      id: null,
      link: null,
      link2: null,
      credits: 0,
      creditHistory: null,
    };

    var tradeStationAccess = null;

    $scope.increaseBadge = increaseBadge;
    $scope.refreshTradeStation = refreshTradeStation;
    $scope.loginTradeStation = loginTradeStation;
    $scope.loginOANDA = loginOANDA;
    $scope.getReferralData = getReferralData;
    $scope.emitReferrerTest = emitReferrerTest;
    $scope.emitRefereeTest = emitRefereeTest;
    $scope.setOffsetDate = setOffsetDate;
    $scope.setRealtimeTest = setRealtimeTest;
    $scope.setTradeAbility = setTradeAbility;
    $scope.setDevMode = setDevMode;
    $scope.setDebugMode = setDebugMode;
    $scope.setShowFullMsg = setShowFullMsg;
    $scope.deleteTester = deleteTesterFunc;
    $scope.testTagManager = btTagService.testEvent;
    $scope.resetAllReminders = resetAllReminders;

    $scope.openSettingsDialog = openSettingsDialog;

    $scope.setMockCountry = setMockCountry;
    $scope.resetMockCountry = resetMockCountry;

    activate();

    /**
     *
     */
    function activate() {
      btShareScopeService
        .wait()
        .then(function () {
          $scope.account = btShareScopeService.accountInfo;
          $scope.savedMobileToken = btShareScopeService.accountInfo.pushNotificationMobile;
          $scope.savedDesktopToken = btShareScopeService.accountInfo.pushNotificationWeb;
        })
        .then(btPushNotificationService.getSubscriptionState)
        .then(function (value) {
          $scope.pushNotification.status = value;
        })
        .catch(function (reason) {
          console.error(reason);
          $scope.pushNotification.status = {
            supported: false,
            enabled: false,
            subscribed: false,
            permission: 'localhost',
            user: 'N/A',
            token: 'N/A',
          };
        });
    }

    /**
     *
     */
    function loginTradeStation() {
      btOauthService
        .login('tradestation-demo')
        .then(function (data) {
          tradeStationAccess = data;
          btDevService.alert('Success');
          btDevService.alert(JSON.stringify(data));
        })
        .catch(function (reason) {
          tradeStationAccess = null;
          btDevService.alert('Fail');
          btDevService.alert(reason.message);
        });
    }

    /**
     *
     */
    function refreshTradeStation() {
      if (tradeStationAccess !== null) {
        btOauthService
          .refreshToken('tradestation-demo', { refresh_token: tradeStationAccess.refreshToken })
          .then(function (data) {
            btDevService.alert('Success');
            btDevService.alert(JSON.stringify(data));
            $timeout(refreshTradeStation, data.expires_in * 1000, false);
          })
          .catch(function (reason) {
            btDevService.alert('Fail');
            btDevService.alert(reason.message);
          });
      } else {
        btDevService.alert('No data');
      }
    }

    /**
     *
     */
    function loginOANDA() {
      btOauthService
        .login('oanda-demo')
        .then(function (data) {
          btDevService.alert('Success');
          btDevService.alert(JSON.stringify(data));
        })
        .catch(function (reason) {
          btDevService.alert('Fail');
          btDevService.alert(reason.message);
        });
    }

    /**
     *
     */
    function getReferralData() {
      btBranchService.getIdentityId().then(function (data) {
        $scope.referral.id = data;
      });

      btBranchService.getReferralLink().then(function (data) {
        $scope.referral.link = data;
      });

      btBranchService.createLink().then(function (data) {
        $scope.referral.link2 = data;
      });

      btBranchService.creditHistory().then(function (data) {
        $scope.referral.creditHistory = data;
      });

      btBranchService.loadRewards('test').then(function (data) {
        $scope.referral.credits = data;
      });

      btBranchService.getFirstReferringParams().then(function (promiseValue) {
        console.log(promiseValue);
      });

      btBranchService.getLatestReferringParams().then(function (promiseValue) {
        console.log(promiseValue);
      });
    }

    /**
     *
     */
    function emitReferrerTest() {
      btBranchService.userCompletedAction('testReferrer');
    }

    /**
     *
     */
    function emitRefereeTest() {
      btBranchService.userCompletedAction('testReferee');
    }

    /**
     * Function to save flag offsetDate to local cache.
     *
     * @ngdoc method
     * @name setOffsetDate
     * @memberOf ecapp.btDevController
     */
    function setOffsetDate() {
      $rootScope.dataChanged = true;

      window.localStorage.setItem('offsetDate', $scope.debug.offsetDate);
    }

    /**
     * Function to save flag realtimeTest to local cache.
     *
     * @ngdoc method
     * @name setRealtimeTest
     * @memberOf ecapp.btDevController
     */
    function setRealtimeTest() {
      $rootScope.dataChanged = true;

      window.localStorage.setItem('realtimeTest', $scope.debug.realtimeTest);
    }

    /**
     * Function to save flag hasTradeAbility to local cache.
     *
     * @ngdoc method
     * @name setTradeAbility
     * @memberOf ecapp.btDevController
     */
    function setTradeAbility() {
      window.hasTradeAbility = $scope.debug.hasTradeAbility;

      window.localStorage.setItem('hasTradeAbility', $scope.debug.hasTradeAbility);
    }

    /**
     * Function to save flag isDevMode to local cache
     *
     * @ngdoc method
     * @name setDevMode
     * @memberOf ecapp.btDevController
     */
    function setDevMode() {
      window.isDevMode = $scope.debug.isDevMode;
      $rootScope.isDevMode = $scope.debug.isDevMode;
      $rootScope.dataChanged = true;

      window.localStorage.setItem('isDevMode', $scope.debug.isDevMode);
    }

    /**
     * Function to save flag isDebugMode to local cache.
     *
     * @ngdoc method
     * @name setDebugMode
     * @memberOf ecapp.btDevController
     */
    function setDebugMode() {
      window.isDebugMode = $scope.debug.isDebugMode;
      $rootScope.isDebugMode = $scope.debug.isDebugMode;

      window.localStorage.setItem('isDebugMode', $scope.debug.isDebugMode);
    }

    /**
     * Function to save flag offsetDate to local cache.
     *
     * @ngdoc method
     * @name setOffsetDate
     * @memberOf ecapp.btDevController
     */
    function setShowFullMsg() {
      $rootScope.dataChanged = true;

      window.localStorage.setItem('showFullMsg', $scope.debug.showFullMsg);
    }

    /**
     * Remove test user from database.
     *
     * @ngdoc method
     * @name deleteTester
     * @memberOf ecapp.btDevController
     */
    function deleteTesterFunc() {
      lbUser
        .deleteTester()
        .$promise.then(function () {
          console.log('btDevController: Test user was removed!');
        })
        .catch(function (reason) {
          console.log('btDevController: error', reason);
        });
    }

    /**
     *
     */
    function increaseBadge() {
      btBadgeService.increase();
    }

    /**
     *
     * @return {any}
     */
    function getSettingsTemplate() {
      var selectOptions = [
        { name: 'Option 1', value: '1' },
        { name: 'Option 2', value: '2' },
        { name: 'Option 3', value: '3' },
      ];

      var multipleOptions = [
        { value: '1', name: 'Option 1', selected: true },
        { value: '2', name: 'Option 2', selected: false },
        { value: '3', name: 'Option 3', selected: true },
        { value: '4', name: 'Option 4', selected: false },
      ];

      var radioGroupOptions = [
        { name: 'Option 1', value: '1' },
        { name: 'Option 2', value: '2' },
        { name: 'Option 3', value: '3' },
      ];

      var dropDownOptions = [
        { value: '1', name: 'Option 1', details: 'Option 1 Details' },
        { value: '2', name: 'Option 2', details: 'Option 2 Details' },
        { value: '3', name: 'Option 3', details: 'Option 3 Details' },
      ];

      var listOptions = [
        { value: '1', name: 'Option 1' },
        { value: '2', name: 'Option 2' },
        { value: '3', name: 'Option 3' },
      ];

      return {
        wasChanged: true,
        blocks: [
          {
            id: 'regular',
            name: 'Regular Block',
            type: 'regular',
            title: 'Regular Section Title',
            description: '<div>Regular Section Description</div>',
            params: { enabled: true },
            sections: [
              {
                type: 'regular',
                filters: [
                  {
                    id: 'toggle-1',
                    name: 'Toggle 1',
                    type: 'toggle',
                    title: 'Toggle 1 Title',
                    description: '<div>Toggle 1 Description</div>',
                    params: {
                      style: 'bt-custom',
                      enabled: true,
                    },
                    value: false,
                  },
                  {
                    id: 'toggle-2',
                    name: 'Toggle 2',
                    type: 'toggle',
                    title: '',
                    description: '',
                    params: {
                      style: 'bt-custom',
                      enabled: true,
                    },
                    value: false,
                  },
                  {
                    id: 'slider',
                    name: 'Slider',
                    type: 'slider',
                    title: 'Slider Title',
                    description: '<div>Slider Description</div>',
                    params: {
                      style: 'bt-custom',
                      format: 'number',
                      enabled: true,
                      limits: { min: true, max: false },
                    },
                    range: { min: 1, max: 3, step: 1 },
                    value: 2,
                  },
                  {
                    id: 'range',
                    name: 'Range',
                    type: 'range',
                    title: 'Range Title',
                    description: '<div>Range Description</div>',
                    params: {
                      style: 'bt-custom',
                      format: 'number',
                      enabled: true,
                      limits: { min: true, max: true },
                    },
                    range: { min: 1.0, max: 5, step: 0.1 },
                    value: { min: 1.1, max: 4.8 },
                  },
                  {
                    id: 'select',
                    name: 'Select',
                    type: 'select',
                    title: 'Select Title',
                    description: '<div>Select Description</div>',
                    params: {
                      style: 'bt-custom',
                      enabled: true,
                    },
                    options: selectOptions,
                    value: selectOptions[0],
                  },
                  {
                    id: 'multiple-1',
                    name: 'Multiple 1',
                    type: 'multiple',
                    title: 'Multiple 1 Title',
                    description: '<div>Multiple 1 Description</div>',
                    params: {
                      style: 'bt-custom',
                      collapsible: true,
                      expanded: true,
                      enabled: true,
                    },
                    options: multipleOptions,
                  },
                  {
                    id: 'multiple-2',
                    name: 'Multiple 2',
                    type: 'multiple',
                    title: 'Multiple 2 Title',
                    description: '<div>Multiple 2 Description</div>',
                    params: {
                      style: 'bt-custom',
                      collapsible: false,
                      expanded: true,
                      enabled: true,
                    },
                    options: multipleOptions,
                  },
                  {
                    id: 'radio-group',
                    name: 'Radio Group',
                    type: 'radio-group',
                    title: 'Radio Group Title',
                    description: '<div>Radio Group Description</div>',
                    params: {
                      style: 'bt-custom',
                      enabled: true,
                    },
                    options: radioGroupOptions,
                    value: radioGroupOptions[1].value,
                  },
                  {
                    id: 'drop-down',
                    name: 'Drop Down',
                    type: 'drop-down',
                    title: 'Drop Down Title',
                    description: '<div>Drop Down Description</div>',
                    params: {
                      style: 'bt-custom',
                      enabled: true,
                      detailed: true,
                    },
                    options: dropDownOptions,
                    value: '2',
                  },
                  {
                    id: 'list',
                    name: 'List',
                    type: 'list',
                    title: 'List Title',
                    description: '<div>List Description</div>',
                    params: {
                      style: 'bt-custom',
                      enabled: true,
                    },
                    options: listOptions,
                    value: '2',
                  },
                  {
                    id: 'title-1',
                    name: 'Title 1',
                    type: 'title',
                    title: 'Title 1 Title',
                    description: '<div>Title 1 Description</div>',
                    params: {
                      style: 'bt-custom',
                      enabled: true,
                    },
                    value: null,
                  },
                  {
                    id: 'title-2',
                    name: 'Title 2',
                    type: 'title',
                    title: 'Title 2 Title',
                    description: '<div>Title 2 Description</div>',
                    params: {
                      style: 'bt-custom',
                      enabled: true,
                    },
                    value: null,
                  },
                ],
              },
              {
                type: 'advanced',
                filters: [
                  {
                    id: 'toggle-3',
                    name: 'Toggle 3',
                    type: 'toggle',
                    title: 'Toggle 3 Title',
                    description: '<div>Toggle 3 Description</div>',
                    params: {
                      style: 'bt-custom',
                      enabled: true,
                    },
                    value: false,
                  },
                  {
                    id: 'toggle-4',
                    name: 'Toggle 4',
                    type: 'toggle',
                    title: 'Toggle 4 Title',
                    description: '<div>Toggle 4 Description</div>',
                    params: {
                      style: 'bt-custom',
                      enabled: true,
                    },
                    value: true,
                  },
                ],
              },
            ],
          },
          {
            id: 'toggle',
            name: 'Toggle Block',
            type: 'toggle',
            title: 'Toggle Block Title',
            description: '<div>Toggle Section Description</div>',
            params: { enabled: true },
            sections: [
              {
                type: 'regular',
                filters: [
                  {
                    id: 'toggle-1',
                    name: 'Toggle 1',
                    type: 'toggle',
                    title: '',
                    description: '<div>Toggle 1 Description</div>',
                    params: {
                      style: 'bt-custom',
                      enabled: true,
                    },
                    value: false,
                  },
                  {
                    id: 'toggle-2',
                    name: 'Toggle 2',
                    type: 'toggle',
                    title: 'Toggle 2 Title',
                    description: '<div>Toggle 2 Description</div>',
                    params: {
                      style: 'bt-custom',
                      enabled: true,
                    },
                    value: true,
                  },
                ],
              },
              {
                type: 'advanced',
                filters: [
                  {
                    id: 'toggle-3',
                    name: 'Toggle 3',
                    type: 'toggle',
                    title: 'Toggle 3 Title',
                    description: '<div>Toggle 3 Description</div>',
                    params: {
                      style: 'bt-custom',
                      enabled: true,
                    },
                    value: false,
                  },
                ],
              },
            ],
          },
          {
            id: 'disabled',
            name: 'Disabled Block',
            type: 'regular',
            title: 'Disabled Block Title',
            description: '<div>Disabled Block Description</div>',
            params: { enabled: false },
            sections: [
              {
                type: 'regular',
                filters: [],
              },
              {
                type: 'advanced',
                filters: [],
              },
            ],
          },
        ],
      };
    }

    /**
     *
     * @return {any}
     */
    function openSettingsDialog() {
      var deferred = $q.defer();

      var scope = $rootScope.$new(true);
      scope.modal = $ionicModal.fromTemplate($templateCache.get('modals/bt-test-settings-modal.html'), {
        scope: scope,
        animation: 'slide-in-up',
      });

      scope.$on('modal.hidden', function () {
        alert('Hidden');
      });

      scope.onConfigChange = function () {
        alert('Changed');
      };

      scope.onConfigReset = function () {
        alert('Reset');
      };

      scope.onConfigDiscard = function () {
        alert('Discard');
      };

      scope.count = 0;

      scope.deferred = deferred;

      scope.config = null;

      $timeout(function () {
        scope.config = getSettingsTemplate();
      }, 2000);

      scope.modal.show();

      return deferred.promise;
    }

    /**
     *
     */
    function resetAllReminders() {
      btSettingsService.removeReminder('notifications.reminder');
      btSettingsService.removeReminder('reminders.invite');
      btToastrService.success('All reminders have been reset.');
    }

    /**
     *
     * @param {*} country
     */
    function setMockCountry(country) {
      localStorage.setItem('btMockUserCountry', country);
      btToastrService.success('Done');
    }

    /**
     *
     */
    function resetMockCountry() {
      localStorage.removeItem('btMockUserCountry');
      btToastrService.success('Done');
    }
  }
})();
