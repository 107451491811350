(function btUpdateControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btUpdateController', btUpdateController);

  btUpdateController.$inject = ['$scope', '$window', 'btSettingsService'];

  /**
   * Controller of update page.
   *
   * @ngdoc controller
   * @name ecapp.btUpdateController
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.IWindowService} $window - window object service
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btUpdateController($scope, $window, btSettingsService) {
    console.log('Running btUpdateController');

    $scope.logoSrc = btSettingsService.getAssets('login-logo-src');
    $scope.logoAlt = btSettingsService.getAssets('login-logo-alt');

    $scope.openStore = openStore;

    /**
     *
     */
    function openStore() {
      if ($window.isIOS) {
        window.open('https://itunes.apple.com/app/bettertrader/id1161235956', '_system');
      } else if ($window.isAndroid) {
        window.open('https://play.google.com/store/apps/details?id=com.bettertrader.ecapp', '_system');
      } else {
        window.open('https://bettertrader.co', '_system');
      }
    }
  }
})();
