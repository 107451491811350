/**
 * Created by Jonas Pickholtz on 09/12/19.
 */

(function btSmartCandleClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * Horizontal candle
     *
     * @ngdoc directive
     * @name btSmartCandle
     * @memberOf ecapp
     */
    .directive('btSmartCandle', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/risks/bt-smart-candle.html'),
          scope: {
            values: '=',
          },
          link: function (scope, el) {
            drawCandle(scope.values);
            scope.$watch('values', function (newValue, oldValue) {
              if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                drawCandle(newValue);
              }
            });

            /**
             *
             * @param {*} data
             */
            function drawCandle(data) {
              // Checking if chart will be green or red
              if (data.priceValue > data.open) {
                scope.positive = true;
              } else if (data.priceValue < data.open) {
                scope.positive = false;
              }

              // Calculate width and margin for back-bar
              var relativeTotalWidth = data.high - data.low;
              scope.width = (Math.abs(data.priceValue - data.open) / relativeTotalWidth) * 100;

              if (scope.positive) {
                scope.marginLeft = ((data.open - data.low) / relativeTotalWidth) * 100;
                scope.iconMargin = scope.width + scope.marginLeft;
                scope.iconMargin2 = scope.marginLeft;
              } else if (!scope.positive) {
                scope.marginLeft = ((data.priceValue - data.low) / relativeTotalWidth) * 100;
                scope.iconMargin = scope.marginLeft;
                scope.iconMargin2 = scope.width + scope.marginLeft;
              }

              var frontBar = el[0].childNodes[3].childNodes[1];
              var iconPosition = el[0].childNodes[0];
              var iconOpen = el[0].childNodes[1];
              iconPosition.style.left = scope.iconMargin + '%';
              iconOpen.style.left = scope.iconMargin2 + '%';

              frontBar.style.width = scope.width + '%';
              frontBar.style.left = scope.marginLeft + '%';

              if (scope.positive) {
                frontBar.style.backgroundColor = '#48C064';
              }
              if (!scope.positive) {
                frontBar.style.backgroundColor = '#CF3040';
              }
            }
          },
        };
      },
    ]);
})();
