/**
 * Created by Sergey Panpurin on 11/11/2018.
 */

// @ts-check
(function btOptimusAuthServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btOptimusAuthService', btOptimusAuthService);

  btOptimusAuthService.$inject = ['$q', '$http', 'btSettings', 'btErrorService'];

  /**
   * This service collect logic connected to Optimus integration.
   *
   * @ngdoc service
   * @name btOptimusAuthService
   * @memberOf ecapp
   * @param {angular.IQService} $q - promise interface
   * @param {angular.IHttpService} $http
   * @param {ecapp.ISettings} btSettings
   * @param {ecapp.IErrorService} btErrorService
   * @return {ecapp.IOptimusAuthService}
   */
  function btOptimusAuthService($q, $http, btSettings, btErrorService) {
    console.log('Running btOptimusAuthService');

    return {
      register: register,
    };

    /**
     * This function registers new optimus user in bettertrader system.
     *
     * @param {string} email - email of new optimus user
     * @param {null} details - optimus user details
     * @return {angular.IPromise<boolean>}
     */
    function register(email, details) {
      void details;
      var params = {};
      return $http
        .post(btSettings.BT_BACKEND_URL + '/v1/optimus-futures/users', { email: email }, params)
        .then(function (response) {
          console.log('btShareScopeService:', response);
          return true;
        })
        .catch(btErrorService.handleHTTPError);
    }
  }
})();
