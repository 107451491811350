/**
 * Created by Itay on 6/26/2016.
 */

(function btHistoricGraphStrengthBarClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive display strength bar in historical data table.
     *
     * @ngdoc directive
     * @name btHistoricGraphStrengthBar
     * @memberOf ecapp
     */
    .directive('btHistoricGraphStrengthBar', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/common/historic-graph/strength-bar.html'),
          link: function (scope, element) {
            //this condition checks if strengthPercentage is null, and if it does it just returns
            if (null == scope.row.strengthPercentage) return;

            var visualGraph = angular.element(element).find('.bt-strength-bar-visual');

            //this block of code makes sure that we're not setting the width under 0% or more than 100%.
            var visualWidth;
            if (scope.row.strengthPercentage.value > 100) {
              visualWidth = 100;
            } else if (scope.row.strengthPercentage.value < 0) {
              visualWidth = 0;
            } else {
              visualWidth = scope.row.strengthPercentage.value;
            }
            visualWidth = visualWidth + '%';

            //this appends the value
            visualGraph.css('min-width', visualWidth);
          },
        };
      },
    ]);
})();
