(function btAuthLogoutControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btAuthLogoutController', btAuthLogoutController);

  btAuthLogoutController.$inject = [
    '$scope',
    '$state',
    '$ionicLoading',
    '$ionicHistory',
    'btAuthService',
    'btPushNotificationService',
    'btSettingsService',
  ];

  /**
   * Logout controller connected to state 'logout' with url '/logout'. Remove information about user from root scope,
   * local cache and session cache. Additionally remove loopback authentication information from cache and ionic view
   * cache.
   *
   * @ngdoc controller
   * @name ecapp.btAuthLogoutController
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.ui.IStateService} $state
   * @param {ionic.ILoadingService} $ionicLoading - ionic loading service
   * @param {ionic.IHistoryService} $ionicHistory - ionic history service
   * @param {ecapp.IAuthService} btAuthService
   * @param {ecapp.IPushNotificationService} btPushNotificationService
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btAuthLogoutController(
    $scope,
    $state,
    $ionicLoading,
    $ionicHistory,
    btAuthService,
    btPushNotificationService,
    btSettingsService
  ) {
    console.log('Running btAuthLogoutController');

    logout();

    $scope.logoSrc = btSettingsService.getAssets('login-logo-src');
    $scope.logoAlt = btSettingsService.getAssets('login-logo-alt');

    /**
     * Log out
     */
    function logout() {
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Log out...</p>' });

      btAuthService
        .logout()
        .then(function onSuccess() {
          console.log('Logout success');
        })
        .catch(function onFailure(reason) {
          console.error('Fail to logout due to', reason);
        })
        .finally(function () {
          $ionicLoading.hide();

          console.log('AuthLogout: try to open ecapp.user.login');

          // cleanup view cached by ionic
          $ionicHistory.clearCache();
          $ionicHistory.clearHistory();

          // btBranchService.logout();

          // btTradingService.logout();

          // btShareScopeService.logout();

          $state.go('ecapp.user.login');
        });
    }
  }
})();
