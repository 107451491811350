/**
 * Created by Sergey Panpurin on 5/15/2017.
 */

(function btLoaderClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive just show loader.
     *
     * @ngdoc directive
     * @name btLoader
     * @memberOf ecapp
     */
    .directive('btLoader', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          transclude: true,
          scope: false,
          template: $templateCache.get('directives/common/bt-loader.html'),
        };
      },
    ]);
})();
