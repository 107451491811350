/**
 * Created by Sergey Panpurin on 7/11/2019.
 */

(function btPurchasesDevControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btPurchasesDevController', btPurchasesDevController);

  btPurchasesDevController.$inject = ['$scope', '$rootScope', '$timeout', 'btSubscriptionService'];

  /**
   * This is controller for payments page.
   *
   * @ngdoc controller
   * @name btPurchasesDevController
   * @memberOf ecapp
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.ISubscriptionService} btSubscriptionService
   */
  function btPurchasesDevController($scope, $rootScope, $timeout, btSubscriptionService) {
    console.log('Running btPurchasesDevController');

    $scope.isLoading = true;
    $scope.hasError = false;
    $scope.errorMessage = false;
    $scope.purchase = {
      subscriptions: [],
      consumables: [],
      plugins: [],
    };
    $scope.user = {
      subscriptions: [],
      consumables: [],
      plugins: [],
    };

    $scope.combined = null;

    $scope.buyPurchase = buyPurchase;
    $scope.checkCanSubscribe = checkCanSubscribe;
    $scope.checkCanUnsubscribe = checkCanUnsubscribe;
    $scope.subscribe = subscribe;
    $scope.unsubscribe = unsubscribe;

    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('$ionicView.enter', onViewEnter);
    $scope.$on('subscription:updated', activate);

    activate();

    /**
     *
     */
    function onViewLoaded() {
      $timeout(function () {
        $('#bt-plugins-content').focus();
      }, 1000);
    }

    /**
     *
     */
    function onViewEnter() {
      $timeout(function () {
        $('#bt-plugins-content').focus();
      }, 1000);
    }

    /**
     *
     */
    function activate() {
      $scope.isLoading = false;

      $scope.combined = btSubscriptionService.getCombinedSubscription();
      if ($scope.combined) $scope.combined.collapsed = true;

      btSubscriptionService.getPurchases().then(function (result) {
        $scope.purchase.plugins = result.plugins.map(function (value) {
          value.collapsed = true;
          return value;
        });
        $scope.purchase.subscriptions = result.subscriptions.map(function (value) {
          value.collapsed = true;
          return value;
        });
        $scope.purchase.consumables = result.consumables.map(function (value) {
          value.collapsed = true;
          return value;
        });
      });

      btSubscriptionService.getUserPurchases().then(function (result) {
        $scope.user.plugins = result.plugins.map(function (value) {
          value.collapsed = true;
          return value;
        });
        $scope.user.subscriptions = result.subscriptions.map(function (value) {
          value.collapsed = true;
          return value;
        });
        $scope.user.consumables = result.consumables.map(function (value) {
          value.collapsed = true;
          return value;
        });
      });
    }

    /**
     *
     * @param {*} purchase
     */
    function buyPurchase(purchase) {
      btSubscriptionService.buyPurchase(purchase);
    }

    /**
     *
     * @param {*} plan
     * @return {boolean}
     */
    function checkCanSubscribe(plan) {
      return !btSubscriptionService.checkCanSubscribe(plan);
    }

    /**
     *
     * @param {*} plan
     */
    function subscribe(plan) {
      btSubscriptionService.buySubscription(plan).catch(function (reason) {
        console.error(reason);
      });
    }

    /**
     *
     * @param {*} subscription
     * @return {boolean}
     */
    function checkCanUnsubscribe(subscription) {
      return !btSubscriptionService.checkCanUnsubscribe(subscription);
    }

    /**
     *
     * @param {*} subscription
     */
    function unsubscribe(subscription) {
      btSubscriptionService.cancelSubscription(subscription).catch(function (reason) {
        console.error(reason);
      });
    }
  }
})();
