/**
 * Created by Orly on 19/02/19.
 */

(function btWatchlistMoversClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btWatchlistMovers:';

  /**
   * ???
   * @ngdoc directive
   * @name btWatchlistMovers
   * @memberOf ecapp
   * @scope
   */
  /** Separator */
  angular.module('ecapp').directive('btWatchlistMovers', btWatchlistMovers);

  btWatchlistMovers.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btWatchlistMovers($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('directives/barometer/bt-watchlist-movers-widget.html'),
      controller: btWatchlistMoversController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btWatchlistMoversController.$inject = [
    '$scope',
    '$interval',
    'btWatchListService',
    'btTradingService',
    'btMoversAlertsService',
    'btSettingsService',
    'btCalendarService',
    'btDateService',
  ];

  /**
   *
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.IWatchListService} btWatchListService
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.IMoversAlertsService} btMoversAlertsService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.ICalendarService} btCalendarService
   * @param {ecapp.IDateService} btDateService
   */
  function btWatchlistMoversController(
    $scope,
    $interval,
    btWatchListService,
    btTradingService,
    btMoversAlertsService,
    btSettingsService,
    btCalendarService,
    btDateService
  ) {
    /*jshint validthis: true*/
    var vm = this;

    vm.$onDestroy = onDestroy;

    activate();

    /**
     *
     */
    function activate() {
      if (gDebug) console.log(gPrefix, 'activating...');

      vm.markets = null;

      vm.options = {
        sorting: true,
        adding: true,
        prefix: 'barometer.watchlist-movers',
        loadingText: 'Loading watchlist movers',
        error: null,
      };

      btWatchListService
        .initialize()
        .then(function () {
          vm.markets = btWatchListService.getUserWatchlist();
        })
        .catch(function (reason) {
          vm.options.error = reason.message || 'unknown';
        });
    }

    /**
     *
     */
    function onDestroy() {
      if (gDebug) console.log(gPrefix, 'destroying...');
    }
  }
})();
