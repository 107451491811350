/**
 * Created by Sergei Panpurin on 9/5/18.
 */

(function btSyntheticChartClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btSyntheticChart';

  angular
    .module('ecapp')
    /**
     * This directive displays list of trade ideas. User can select one of them.
     *
     * @ngdoc directive
     * @name btSyntheticChart
     * @memberOf ecapp
     */
    .directive('btSyntheticChart', btSyntheticChart)
    .controller('btSyntheticChartController', btSyntheticChartController);

  btSyntheticChart.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {any}
   */
  function btSyntheticChart($templateCache) {
    return {
      restrict: 'E',
      scope: {
        widgetId: '@',
        tradeInstrument: '<',
        tradeIdea: '<',
        charts: '<',
        trades: '<',
        events: '<',
      },
      template: $templateCache.get('components/mobile/bt-back-tester/bt-synthetic-chart.html'),
      controller: 'btSyntheticChartController',
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btSyntheticChartController.$inject = ['$scope', '$timeout', 'btChartService', 'btMarketing'];

  /**
   *
   * @param {*} $scope
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.IChartService} btChartService
   * @param {ecapp.IMarketing} btMarketing
   */
  function btSyntheticChartController($scope, $timeout, btChartService, btMarketing) {
    void $scope;

    /*jshint validthis: true*/
    var vm = this;

    /**
     * @typedef {Object} btBackTradeSide
     * @property {String} id - trade side id
     * @property {String} label - trade side label
     */

    /**
     * @typedef {Object} btBackTradeStatus
     * @property {String} id - trade status id
     * @property {String} label - trade status label
     */

    /** @type {btBackTradeSide[]} */
    vm.sideOptions = [
      { id: 'any', label: 'Any side' },
      { id: 'buy', label: 'Long' },
      { id: 'sell', label: 'Short' },
    ];

    /** @type {btBackTradeSide} */
    vm.side = vm.sideOptions[0];

    /** @type {btBackTradeStatus[]} */
    var gOptions = [
      { id: 'all', label: 'All Trades' },
      { id: 'win-loss', label: 'Win & Loss' },
      { id: 'win', label: 'Win Only' },
      { id: 'loss', label: 'Loss Only' },
      { id: 'weak', label: 'Weak Only' },
    ];

    /** @type {{any: btBackTradeStatus[], buy: btBackTradeStatus[], sell: btBackTradeStatus[]}} */
    var gStatusSideOptions = {
      any: [gOptions[0]],
      buy: [gOptions[0], gOptions[1], gOptions[2], gOptions[3], gOptions[4]],
      sell: [gOptions[0], gOptions[1], gOptions[2], gOptions[3], gOptions[4]],
    };

    /** @type {btBackTradeStatus[]} */
    vm.statusOptions = gStatusSideOptions.any;

    /** @type {btBackTradeStatus} */
    vm.status = vm.statusOptions[0];

    vm.strengthOptions = [
      { id: 'hide', label: 'Hide Strength' },
      { id: 'show', label: 'Show Strength' },
    ];

    vm.strength = vm.strengthOptions[0];

    /** @type {Boolean} */
    vm.hasStrength = false;

    /** @type {Boolean} */
    vm.hasResults = true;

    /** @type {Boolean} */
    vm.hasVisibleResults = true;

    /** @type {String} */
    vm.syntheticChartName = 'bt-synthetic-chart-' + vm.widgetId;

    /** @type {?zcChartJSON} */
    vm.syntheticChartData = null;

    /** @type {Boolean} */
    vm.hasChart = false;

    /**
     *
     * @type {{isProcessing: boolean}}
     */
    vm.chartStatus = { isProcessing: false };

    vm.$onInit = onInit;
    vm.$onChanges = onChanges;
    vm.$onDestroy = onDestroy;

    vm.onChangeStatus = onChangeStatus;
    vm.onChangeSide = onChangeSide;
    vm.onChangeStrength = onChangeStrength;

    vm.clearFilter = clearFilter;
    vm.openChat = openChat;

    /**
     * Initialize component
     */
    function onInit() {
      if (gDebug) console.log('selectOption: onInit');
      if (vm.charts.length > 0) {
        getStatusAndSide(vm.tradeIdea);

        vm.hasResults = true;

        vm.syntheticChartData = btChartService.getSyntheticChartTemplate(
          getSyntheticChartOptions(vm.side, vm.tradeIdea)
        );
        vm.hasChart = true;

        drawChart();
      } else {
        vm.hasResults = false;
        vm.hasVisibleResults = false;
      }

      onTradeIdeaChange(vm.tradeIdea);
    }

    /**
     * React on input changes
     * @param {Object} changes - input changes
     */
    function onChanges(changes) {
      if (gDebug) console.log('selectOption: onChanges', changes);
      if (changes.tradeInstrument || changes.charts || changes.trades) {
        if (vm.charts.length > 0) {
          vm.hasResults = true;
          vm.syntheticChartData = btChartService.getSyntheticChartTemplate(
            getSyntheticChartOptions(vm.side, vm.tradeIdea)
          );
          vm.hasChart = true;

          drawChart();
        } else {
          vm.hasResults = false;
          vm.hasVisibleResults = false;
        }
      }

      if (changes.tradeIdea) {
        onTradeIdeaChange(changes.tradeIdea.currentValue);
      }
    }

    /**
     *
     * @param {*} side
     * @param {*} tradeIdea
     * @return {any}
     */
    function getSyntheticChartOptions(side, tradeIdea) {
      if (tradeIdea && tradeIdea.trade.price.atr) {
        var id;

        id = 'any';
        if (tradeIdea.data.action === 'uptrend' && side.id === 'buy') id = 'long';
        if (tradeIdea.data.action === 'downtrend' && side.id === 'sell') id = 'short';

        return {
          long: {
            targets:
              id === 'long'
                ? [
                    tradeIdea.trade.price.reward1 / tradeIdea.trade.price.atr,
                    tradeIdea.trade.price.reward2 / tradeIdea.trade.price.atr,
                  ]
                : null,
            stops:
              id === 'long'
                ? [
                    tradeIdea.trade.price.risk3 / tradeIdea.trade.price.atr,
                    tradeIdea.trade.price.risk2 / tradeIdea.trade.price.atr,
                  ]
                : null,
          },
          short: {
            targets:
              id === 'short'
                ? [
                    tradeIdea.trade.price.reward1 / tradeIdea.trade.price.atr,
                    tradeIdea.trade.price.reward2 / tradeIdea.trade.price.atr,
                  ]
                : null,
            stops:
              id === 'short'
                ? [
                    tradeIdea.trade.price.risk3 / tradeIdea.trade.price.atr,
                    tradeIdea.trade.price.risk2 / tradeIdea.trade.price.atr,
                  ]
                : null,
          },
        };
      } else {
        return {
          long: {
            targets: null,
            stops: null,
          },
          short: {
            targets: null,
            stops: null,
          },
        };
      }
    }

    /**
     *
     */
    function drawChart() {
      var params = { status: vm.status.id, side: vm.side.id, strength: vm.strength.id };
      vm.hasStrength = false;
      vm.syntheticChartData.series = vm.charts.map(function (chart, i) {
        if (gDebug) console.log('getColors vm.trades[i]', vm.trades[i]);
        vm.hasStrength = !!vm.events[i].strength;
        return btChartService.prepareSyntheticChartSeries(
          vm.events[i],
          chart,
          vm.trades[i],
          i,
          vm.trades.length,
          params
        );
      });
      btChartService.updateSyntheticChartScaleValues(vm.syntheticChartData);
    }

    /**
     * React on trade idea change
     * @param {bt.TradeIdeaObject} tradeIdea
     */
    function getStatusAndSide(tradeIdea) {
      if (tradeIdea) {
        if (tradeIdea.data.action === 'uptrend') {
          vm.side = vm.sideOptions[1];
          vm.statusOptions = gStatusSideOptions.buy;
          vm.status = vm.statusOptions[1];
        }

        if (tradeIdea.data.action === 'downtrend') {
          vm.side = vm.sideOptions[2];
          vm.statusOptions = gStatusSideOptions.sell;
          vm.status = vm.statusOptions[1];
        }
      } else {
        vm.side = vm.sideOptions[0];
        vm.statusOptions = gStatusSideOptions.any;
        vm.status = vm.statusOptions[0];
      }
    }

    /**
     * React on trade idea change
     * @param {bt.TradeIdeaObject} tradeIdea
     */
    function onTradeIdeaChange(tradeIdea) {
      getStatusAndSide(tradeIdea);

      if (vm.hasResults) updateChartSide();
    }

    /**
     * Destroy component
     */
    function onDestroy() {}

    /**
     * React on trade status change
     */
    function onChangeStatus() {
      drawChart();
      updateChartSide();
    }

    /**
     * React on trade side change
     */
    function onChangeSide() {
      vm.statusOptions = gStatusSideOptions[vm.side.id] || [];

      if (vm.side.id === 'any' && vm.status.id !== 'all') {
        vm.status = vm.statusOptions[0];
      }

      onChangeStatus();
    }

    /**
     *
     */
    function onChangeStrength() {
      drawChart();
    }

    /**
     * Update chart side
     */
    function updateChartSide() {
      var params = { status: vm.status.id, side: vm.side.id };
      var count = btChartService.countSyntheticChartOptions(vm.syntheticChartName, params, vm.trades);

      if (count > 0) {
        btChartService.showSyntheticChartOptionsJSON(vm.syntheticChartName, params, vm.trades, vm.syntheticChartData);
        btChartService.updateSyntheticChartYMarkers(vm.syntheticChartData, vm.side.id);
        vm.hasVisibleResults = true;
      } else {
        vm.hasVisibleResults = false;
      }
    }

    /**
     * Clear trade side and trade status filter
     */
    function clearFilter() {
      vm.side = vm.sideOptions[0];
      vm.statusOptions = gStatusSideOptions.any;
      vm.status = vm.statusOptions[0];

      onChangeStatus();
    }

    /**
     * Open support chat
     * @param {String} text - message text
     */
    function openChat(text) {
      btMarketing.askQuestion(text);
    }
  }
})();
