/**
 * Created by Jonas on 8/6/2019.
 */

/* global zingchart */

(function RisksControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btRiskMonitorTabController', btRisksController);

  btRisksController.$inject = [
    '$scope',
    '$interval',
    'btRiskService',
    'btInstrumentsService',
    'btTradingService',
    'btShareScopeService',
    'btRestrictionService',
    'btSettingsService',
  ];

  /**
   * This is the markets tab controller.
   *
   * @ngdoc controller
   * @name btRisksController
   * @memberOf ecapp
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {angular.IIntervalService} $interval -
   * @param {ecapp.IRiskService} btRiskService -
   * @param {ecapp.IInstrumentsService} btInstrumentsService -
   * @param {ecapp.ITradingService} btTradingService -
   * @param {ecapp.IShareScopeService} btShareScopeService -
   * @param {ecapp.IRestrictionService} btRestrictionService -
   * @param {ecapp.ISettingsService} btSettingsService -
   */
  function btRisksController(
    $scope,
    $interval,
    btRiskService,
    btInstrumentsService,
    btTradingService,
    btShareScopeService,
    btRestrictionService,
    btSettingsService
  ) {
    console.log('Running btRisksController');

    $scope.test = 'Test';

    var gPriceHistoryInterval = null;

    $scope.isBigBrainBank = btSettingsService.domain === 'bigbrainbank';

    $scope.checkIsLoading = btRiskService.checkIsLoading;
    $scope.getSecondsForUpdate = getSecondsForUpdate;
    $scope.hasRiskFeature = hasRiskFeature;
    $scope.upgradeRiskFeature = upgradeRiskFeature;
    $scope.doRefresh = doRefresh;

    $scope.$watch('selectedBasket.id', updateMarkets);

    $scope.$on('$destroy', onDestroy);
    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('$ionicView.enter', onViewEnter);
    $scope.$on('$ionicView.leave', onViewLeave);

    if (window.zingchart) {
      zingchart.bind('bt-risk-chart-histories', 'legend_item_click', legendToggle);
      zingchart.bind('bt-risk-chart-histories', 'legend_marker_click', legendToggle);

      zingchart.bind('bt-risk-chart-realtime', 'legend_item_click', rtLegendToggle);
      zingchart.bind('bt-risk-chart-realtime', 'legend_marker_click', rtLegendToggle);
    }

    activate();

    /**
     *
     * @return {any}
     */
    function activate() {
      $scope.isInitialized = false;

      /** @type {?btRiskBasket} */
      $scope.selectedBasket = btRiskService.getSelectedBasket();

      $scope.chartIndicators = btRiskService.getSmartIndicatorsChart();
      $scope.renderIndicators = { output: 'canvas' };

      $scope.chartHistories = btRiskService.getSmartHistoricalChart();
      $scope.renderHistories = { output: 'canvas' };

      $scope.chartRealtime = btRiskService.getRealtimePreviewChart();
      $scope.renderRealtime = { output: 'canvas' };

      $scope.markets = [];

      $scope.moversOptions = {
        sorting: false,
        adding: false,
        prefix: 'risk-monitor.market-movers',
        error: null,
      };

      return btRiskService
        .initialize()
        .then(btRiskService.loadSettings)
        .then(function () {
          $scope.isInitialized = true;
        })
        .then(function () {
          loadPriceHistory();
          gPriceHistoryInterval = $interval(
            function () {
              loadPriceHistory();
            },
            600000,
            false
          );
        })
        .catch(function (reason) {
          console.error(reason);
          $scope.moversOptions.error = reason.message || 'unknown';
        });
    }

    /**
     *
     */
    function loadPriceHistory() {
      btTradingService
        .initialize()
        .then(btTradingService.connect)
        .then(function () {
          btRiskService.getIndicators().forEach(function (indicator) {
            btTradingService.getLastCandlesData(indicator.oanda, 'M10', 288).then(function (res) {
              indicator.instrument.history = res.candles.map(function (candle) {
                return [
                  parseInt(candle.time) * 1000,
                  parseFloat(candle.mid.o),
                  parseFloat(candle.mid.h),
                  parseFloat(candle.mid.l),
                  parseFloat(candle.mid.c),
                ];
              });

              indicator.instrument.history.push(
                indicator.instrument.history[indicator.instrument.history.length - 1].slice()
              );
            });
          });
        });
    }

    /**
     * This function toggles the history chart legend visibility in local storage
     * @param {{plotid: string, plotindex: number, visible: boolean}} e
     */
    function legendToggle(e) {
      if (e.plotid === 'rInd' || e.plotid === 'rDev') {
        localStorage.setItem('zingchart_plot_' + e.plotid + '_visibility', (!e.visible).toString());
      } else {
        localStorage.setItem('zingchart_plot_' + e.plotindex + '_visibility', (!e.visible).toString());
      }
    }

    /**
     * This function toggles the history chart legend visibility in local storage
     * @param {{plotid: string, plotindex: number, visible: boolean}} e
     */
    function rtLegendToggle(e) {
      if (e.plotid === 'rInd' || e.plotid === 'rDev') {
        localStorage.setItem('zingchart_plot_rt_' + e.plotid + '_visibility', (!e.visible).toString());
      } else {
        localStorage.setItem('zingchart_plot_rt_' + e.plotindex + '_visibility', (!e.visible).toString());
      }
    }

    /**
     * This function updates list of market for market movers widget.
     *
     * @param {string} basket
     */
    function updateMarkets(basket) {
      if (basket) {
        $scope.markets = $scope.selectedBasket.indicators.map(function (indicator) {
          return indicator.instrument;
        });
      } else {
        $scope.markets = [];
      }
    }

    /**
     *
     */
    function onDestroy() {}

    /**
     *
     */
    function onViewLoaded() {}

    /**
     *
     */
    function onViewEnter() {}

    /**
     *
     */
    function onViewLeave() {}

    /**
     *
     * @return {string}
     */
    function getSecondsForUpdate() {
      var sec = 10 - Math.round((new Date() - btRiskService.getLastUpdateDate()) / 1000);
      return sec < -2 ? 'delayed' : 'in ' + sec + ' secs';
    }

    /**
     *
     * @return {boolean}
     */
    function hasRiskFeature() {
      return btRestrictionService.hasFeature('risk-monitor');
    }

    /**
     *
     * @return {angular.IPromise<string>}
     */
    function upgradeRiskFeature() {
      return btRestrictionService.showUpgradePopup('risk-monitor');
    }

    /**
     *
     */
    function doRefresh() {
      btRiskService.unloadSettings();

      activate().finally(function () {
        updateMarkets($scope.selectedBasket.id);
        $scope.$broadcast('scroll.refreshComplete');
      });
    }
  }
})();
