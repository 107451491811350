/**
 * Created by Eyal on 6/17/2016.
 */

// @ts-check
(function btAudioServiceClosure() {
  'use strict';

  angular
    .module('btUtils')
    /**
     * @ngdoc service
     * @name btAudioService
     * @memberOf btUtils
     * @description
     *  This factory play audio before release
     * @param {*} ngAudio - angular directive for playing sounds (https://github.com/danielstern/ngAudio)
     * @param {angular.ITimeoutService} $timeout - wrapper for window.setTimeout
     */
    .factory('btAudioService', btAudioService);

  btAudioService.$inject = ['ngAudio', '$timeout'];

  /**
   *
   * @param {*} ngAudio - ?
   * @param {angular.ITimeoutService} $timeout - ?
   * @return {ecapp.IAudioService}
   */
  function btAudioService(ngAudio, $timeout) {
    /**
     * @namespace ngAudio
     * @property {Function} setUnlock - ?
     * @property {Function} load - ?
     * @property {Function} play - ?
     * @property {Function} mute - ?
     * @property {Function} unmute - ?
     */

    /**
     * @typedef {Object} ngAudioObject
     * @property {Number} remaining - time remaining in seconds.
     * @property {Number} volume - volume of the sound
     * @property {Function} play - plays the sound.
     * @property {Function} unbind - removes all the listeners from the audio object, improving performance, but disabling most read functionality.
     */

    /**
     * Audio player
     * @type {ngAudioObject}
     */
    var player;

    /**
     * Sound player volume
     * @type {Number}
     */
    var soundVolume = 0.5;

    /**
     * Relative path to directory with audio files
     * @type {String}
     */
    var soundDir = 'audio/';

    /**
     * Paths to sound files
     * @type {Record<String,String>}
     */
    var soundDict = {
      HotEvent: soundDir + 'HotEventSound.mp3',
      In10Sec: soundDir + 'in5secSound.mp3',
      PublishedEvent: soundDir + 'publishedEvent.mp3',

      Weaker: soundDir + 'Weaker.mp3',
      Stronger: soundDir + 'Stronger.mp3',
      AsExp: soundDir + 'AsExp.mp3',
      Release: soundDir + 'ReleaseSound.wav',

      IncomingInsight: soundDir + 'incoming-insight.mp3',

      BuySell: soundDir + 'BuySellSound.wav',
      Error: soundDir + 'ErrorSound.mp3',
      Confirmation: soundDir + 'Confirmation.mp3',
    };

    var beepSoundOn = true;

    ngAudio.setUnlock(false);

    /**
     * Play selected sound
     *
     * @param {*} soundDictKey - ?
     * @param {*} playEvenIfBusy - ?
     */
    function playSound(soundDictKey, playEvenIfBusy) {
      // optional parameter
      playEvenIfBusy = playEvenIfBusy || false;

      // play sound if it's not busy or we don't mind about busy
      if (player === undefined || (player !== undefined && player.remaining === 0) || playEvenIfBusy) {
        player = ngAudio.load(soundDict[soundDictKey]);

        player.volume = soundVolume;
        player.play();

        // after 2 second remove interval function in ngAudio library
        $timeout(
          function () {
            player.unbind();
          },
          2000,
          false
        );
      }
    }

    return {
      playHotEventSound: function playHotEventSound() {
        playSound('HotEvent');
      },

      playIn10SecSound: function playIn10SecSound() {
        playSound('In10Sec');
      },

      playWeakerSound: function playWeakerSound() {
        playSound('Weaker');
      },

      playStrongerSound: function playStrongerSound() {
        playSound('Stronger');
      },

      playAsExpSound: function playAsExpSound() {
        playSound('AsExp');
      },

      playNewActualOrRevision: function playNewActualOrRevision() {
        playSound('Release');
      },

      playIncomingInsight: function playIncomingInsight() {
        playSound('IncomingInsight');
      },

      playBuySell: function playBuySell() {
        playSound('BuySell', true);
      },

      playError: function playError() {
        playSound('Error', true);
      },

      playConfirmation: function playConfirmation() {
        playSound('Confirmation', true);
      },

      setVolume: function setVolume(val) {
        soundVolume = val;
      },

      setBeepSoundOnOff: function setBeepSoundOnOff(val) {
        beepSoundOn = val;
      },

      getBeepSoundOn: function getBeepSoundOn() {
        return beepSoundOn;
      },
    };

    /**
     * @ngdoc method
     * @name playHotEventSound
     * @memberOf btUtils.btAudioService
     *
     * Play hot event sound
     */

    /**
     * @ngdoc method
     * @name playIn10SecSound
     * @memberOf btUtils.btAudioService
     *
     * Play in 10 second sound
     */

    /**
     * @ngdoc method
     * @name playWeakerSound
     * @memberOf btUtils.btAudioService
     *
     * Play weaker sound
     */

    /**
     * @ngdoc method
     * @name playStrongerSound
     * @memberOf btUtils.btAudioService
     *
     * Play stronger sound
     */

    /**
     * @ngdoc method
     * @name playAsExpSound
     * @memberOf btUtils.btAudioService
     *
     * Play as expected sound
     */

    /**
     * @ngdoc method
     * @name setVolume
     * @memberOf btUtils.btAudioService
     *
     * Set volume from 0 to 1. Default value is 0.5.
     */

    /**
     * @ngdoc method
     * @name setBeepSoundOnOff
     * @memberOf btUtils.btAudioService
     *
     * Set beep sound on or off - value true of false. Default value is true.
     */
    /**
     * @ngdoc method
     * @name getBeepSoundOn
     * @memberOf btUtils.btAudioService
     *
     * Returns beep sound is on or off - value true of false.
     */
  }
})();
