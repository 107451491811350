/**
 * Created by Sergey Panpurin on 2/26/2017.
 */

(function btPaymentsControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btPaymentsController', btPaymentsController);

  btPaymentsController.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    '$analytics',
    'btShareScopeService',
    'btSettingsService',
    'btRestrictionService',
  ];

  /**
   * This is controller for payments page.
   *
   * @ngdoc controller
   * @name btPaymentsController
   * @memberOf ecapp
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.ITimeoutService} $timeout
   * @param {ext.IAnalyticsService} $analytics
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IRestrictionService} btRestrictionService
   */
  function btPaymentsController(
    $scope,
    $rootScope,
    $timeout,
    $analytics,
    btShareScopeService,
    btSettingsService,
    btRestrictionService
  ) {
    console.log('Running btPaymentsController');

    // waiting for subscription
    $scope.user = $rootScope.currentUser;
    $scope.hasPurchases = btRestrictionService.hasFeature('purchases') && !$rootScope.isLifetime;

    btShareScopeService.wait().then(function () {
      if (btRestrictionService.hasFeature('personalization')) {
        return btShareScopeService.increaseVisitPage('payment');
      }
    });

    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('$ionicView.enter', onViewEnter);

    activate();

    /**
     *
     */
    function activate() {}

    /**
     *
     */
    function onViewLoaded() {
      $timeout(function () {
        $('#bt-payments-content').focus();
      }, 1000);
    }

    /**
     *
     */
    function onViewEnter() {
      $timeout(function () {
        $('#bt-payments-content').focus();
      }, 1000);
    }
  }
})();
