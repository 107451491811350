/**
 * Created by Sergey Panpurin on 10/23/2017.
 */

(function btNextEventClosure() {
  'use strict';

  /**
   * This directive displays information about nex event.
   *
   * @ngdoc directive
   * @name btNextEvent
   * @memberOf ecapp
   */

  angular.module('ecapp').directive('btNextFollowingEvents', [
    '$templateCache',
    /**
     *
     * @param {angular.ITemplateCacheService} $templateCache
     * @return {angular.IDirective}
     */
    function ($templateCache) {
      return {
        restrict: 'E',
        transclude: false,
        scope: {
          name: '@',
        },
        template: $templateCache.get('directives/barometer/bt-next-following-events-widget.html'),
        controller: 'btNextEventController',
      };
    },
  ]);
})();
