/**
 * Created by xxx on xxx.
 */

(function btInstrumentPricesClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive displays received prices values for an instrument and display them as "buy" and "sell" button with designed template.
     *
     * @ngdoc directive
     * @name btInstrumentPricesController
     * @memberOf ecapp
     */
    .directive('btInstrumentPrices', btInstrumentPrices)
    .controller('btInstrumentPricesController', btInstrumentPricesController);

  btInstrumentPrices.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {any}
   */
  function btInstrumentPrices($templateCache) {
    return {
      restrict: 'E',
      scope: {
        symbol: '@', // symbol
        instrument: '<', // instrument
        bid: '@', // bid price
        ask: '@', // aks price
        switchable: '=?', // false or object with action property,
        marketData: '<?', // market data
      },
      template: $templateCache.get('directives/instruments/instrument-prices.html'),
      controller: 'btInstrumentPricesController',
    };
  }

  btInstrumentPricesController.$inject = [
    'BT',
    'TS',
    '$scope',
    '$analytics',
    '$templateCache',
    '$ionicModal',
    'btTradingService',
    'btInstrumentsService',
    'btMarketWidgetsService',
    'btSettingsService',
    'btToastrService',
  ];

  /**
   *
   * @param {ecapp.IBTConstants} BT
   * @param {ecapp.ITSConstants} TS
   * @param {ecapp.ICustomScope} $scope
   * @param {ext.IAnalyticsService} $analytics
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {ionic.IModalService} $ionicModal
   * @param {ecapp.ITradingService } btTradingService
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.IMarketWidgetsService} btMarketWidgetsService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IToastrService} btToastrService
   */
  function btInstrumentPricesController(
    BT,
    TS,
    $scope,
    $analytics,
    $templateCache,
    $ionicModal,
    btTradingService,
    btInstrumentsService,
    btMarketWidgetsService,
    btSettingsService,
    btToastrService
  ) {
    // number of milliseconds in hour
    var HOUR = 60 * 60 * 1000;

    $scope.hasTrading = btSettingsService.hasFeature('trading');

    // turn on new feature for order popup - buy or sell button can be selected to indicate current action
    $scope.switchable = $scope.switchable || false;

    // additional market data
    $scope.marketData = $scope.marketData || false;

    // by default buy and sell buttons have class 'same', after selection they can have class bigger or smaller
    $scope.buyClass = 'same';
    $scope.sellClass = 'same';

    // instrument already has MarketSense
    $scope.hasMarketSense = false;

    // turn on MarketSense after order submission
    $scope.enableMarketSense = true;

    // options for order duration
    $scope.durationOptions = [];

    var btDurations = [
      { name: '1 Hour', value: HOUR },
      { name: '2 Hours', value: 2 * HOUR },
      { name: '3 Hours', value: 3 * HOUR },
      { name: '4 Hours', value: 4 * HOUR },
      { name: '5 Hour', value: 5 * HOUR },
      { name: '6 Hour', value: 6 * HOUR },
      { name: '12 Hours', value: 12 * HOUR },
      { name: '18 Hours', value: 18 * HOUR },
      { name: '1 Day', value: 24 * HOUR, default: true },
      { name: '2 Days', value: 48 * HOUR },
      { name: '1 Week', value: 24 * 7 * HOUR },
      { name: '1 Month', value: 30 * 24 * HOUR },
      { name: '2 Months', value: 2 * 30 * 24 * HOUR },
      { name: '3 Months', value: 3 * 30 * 24 * HOUR },
    ];

    var tsGeneralDurations = [
      { name: 'Day', value: TS.DURATION.DAY },
      { name: 'Day+', value: TS.DURATION.DYP },
      { name: 'GTC', value: TS.DURATION.GTC, default: true },
      { name: 'GTC+', value: TS.DURATION.GCP },
      { name: 'GTD', value: TS.DURATION.GTD, hasExpiration: true },
      { name: 'GTD+', value: TS.DURATION.GDP, hasExpiration: true },
      { name: 'IOC', value: TS.DURATION.IOC },
      { name: 'FOK', value: TS.DURATION.FOK },
      { name: 'OPG', value: TS.DURATION.OPG },
      { name: 'CLO', value: TS.DURATION.CLO },
      { name: '1 min', value: TS.DURATION.M1 },
      { name: '3 min', value: TS.DURATION.M3 },
      { name: '5 min', value: TS.DURATION.M5 },
    ];

    var tsCryptoMarketDurations = [{ name: 'Day', value: TS.DURATION.DAY, default: true }];

    var tsCryptoLimitStopDurations = [
      { name: 'IOC', value: TS.DURATION.IOC },
      { name: 'GTD', value: TS.DURATION.GTD, hasExpiration: true },
      { name: 'GTC', value: TS.DURATION.GTC },
      { name: 'Day', value: TS.DURATION.DAY, default: true },
    ];

    var tsUsdcUsdDurations = [
      { name: 'IOC', value: TS.DURATION.IOC, default: true },
    ]    

    $scope.showOrderPopup = showOrderPopup;
    $scope.openModal = openModal;
    $scope.hideModal = hideModal;
    $scope.cancelOrder = cancelOrder;
    $scope.submitOrder = submitOrder;
    $scope.changeOrder = changeOrder;
    $scope.confirmOrder = confirmOrder;
    $scope.changeType = changeType;
    $scope.updateRequest = updateRequest;
    $scope.selectAction = selectAction;
    $scope.switchMarketSense = switchMarketSense;
    $scope.executeOrder = executeOrder;

    $scope.$on('$destroy', onDestroy);
    $scope.$watch('orderRequest.action', onOrderRequestAction);

    // initialize selection state
    if ($scope.switchable !== false) {
      $scope.$watch('switchable', onChangeSwitchable);
    }

    activate();

    /**
     *
     */
    function activate() {
      if (btTradingService.isDefaultBroker() || !$scope.hasTrading) {
        $scope.bidLabel = 'BID';
        $scope.askLabel = 'ASK';
      } else {
        $scope.bidLabel = 'BUY';
        $scope.askLabel = 'SELL';
      }
    }

    /**
     * On destroy
     */
    function onDestroy() {
      if ($scope.modal !== undefined) {
        $scope.modal.remove();
      }
    }

    /**
     * Watch out for order action due to it is controlled in inner instrument prices directive
     * @param {*} newValue
     */
    function onOrderRequestAction(newValue) {
      $scope.showConfirmation = false;
      if (newValue) {
        $scope.requestText = getRequestText();
      }
    }

    /**
     *
     */
    function onChangeSwitchable() {
      $scope.selectAction($scope.switchable.action);
    }

    /**
     * Open modal view
     */
    function openModal() {
      if ($scope.modal !== undefined) {
        $scope.modal.show();
      }
    }

    /**
     * Hide modal view
     */
    function hideModal() {
      if ($scope.modal !== undefined) {
        $scope.modal.hide();
      }
    }

    /**
     * Cancel order button
     */
    function cancelOrder() {
      $scope.hideModal();
    }

    /**
     * Submit order button
     * @return {{action: String, type: string, units: (Number|*), duration: ({name, time}|*), expiration: string, price: (Number|*), takeProfit: null, stopLoss: null}|*}
     */
    function submitOrder() {
      if ($scope.orderRequest) {
        if ($scope.showConfirmation) {
          // if user have seen confirmation and click submit again execute order
          $scope.showConfirmation = false;
          return $scope.orderRequest;
        } else {
          // show confirmation
          $scope.showConfirmation = true;
          $scope.requestText = getRequestText();
        }
      }
    }

    /**
     * Change order button
     */
    function changeOrder() {
      $scope.showConfirmation = false;
    }

    /**
     * Confirm order button
     */
    function confirmOrder() {
      $scope.hideModal();
      $scope.executeOrder($scope.orderRequest);
    }

    /**
     * Prepare order request text
     * @return {string} - text of order request
     */
    function getRequestText() {
      if ($scope.orderRequest.type === BT.ORDER_TYPE.MARKET)
        return (
          _capitalizeFirstLetter($scope.orderRequest.action) +
          ' ' +
          $scope.orderRequest.units +
          ' units @ ' +
          $scope.orderRequest.type +
          ' (' +
          $scope.orderRequest.duration.name +
          ')'
        );
      else
        return (
          _capitalizeFirstLetter($scope.orderRequest.action) +
          ' ' +
          $scope.orderRequest.units +
          ' units @ ' +
          $scope.orderRequest.price +
          ' ' +
          $scope.orderRequest.type +
          ' (' +
          $scope.orderRequest.duration.name +
          ')'
        );
    }

    /**
     * Just capitalize first letter
     * @param {string} original - original string
     * @return {string} - modified string
     * @private
     */
    function _capitalizeFirstLetter(original) {
      return original.charAt(0).toUpperCase() + original.slice(1);
    }

    /**
     * Handle change of order type
     * @param {string} type - order type 'Market', 'Limit', 'Stop'
     */
    function changeType(type) {
      $scope.orderRequest.type = type;
      $scope.showConfirmation = false;
      $scope.requestText = getRequestText();
      $scope.durationOptions = getDurationOptions($scope.broker, $scope.instrument, type);
      $scope.orderRequest.duration = getDefaultDuration($scope.durationOptions);
    }

    /**
     * Just update request text and confirmation status
     */
    function updateRequest() {
      $scope.showConfirmation = false;
      $scope.requestText = getRequestText();
      if (!$scope.orderRequest.duration.hasExpiration) {
        $scope.orderRequest.expiration = '';
      } else {
        $scope.orderRequest.expiration = new Date();
      }
    }

    /**
     * Just select action: buy or sell
     * @param {string} action - trade action
     */
    function selectAction(action) {
      if ($scope.hasTrading) {
        if ($scope.switchable !== false) {
          if (action === 'buy') {
            $scope.buyClass = 'bigger';
            $scope.sellClass = 'smaller';
            $scope.switchable.action = action;
          } else {
            $scope.buyClass = 'smaller';
            $scope.sellClass = 'bigger';
            $scope.switchable.action = action;
          }
        } else {
          $scope.showOrderPopup(action);
        }
      }
    }

    /**
     * Execute order
     * @param {Object} order - order request data
     */
    function executeOrder(order) {
      console.log('data order request', order);

      // if order is undefined just return
      if (!order) {
        return;
      }

      if (window.btSettings.BT_DOMAIN === 'bettertrader') {
        // track action
        $analytics.eventTrack(order.action.toLowerCase(), {
          category: 'brokerage',
          label: $scope.symbol,
        });
      }

      if (!btTradingService.isDefaultBroker()) {
        var duration = order.type === BT.ORDER_TYPE.MARKET ? 'FOK' : 'GTD';
        var expiration = order.type === BT.ORDER_TYPE.MARKET ? null : Date.now() + order.duration.value;

        if ($scope.broker === 'tradestation') {
          duration = order.duration.value;
          expiration = order.expiration;

          if (order.type === BT.ORDER_TYPE.STOP) {
            order.stopLoss = order.price;
            order.price = null;
          }
        }

        // prepare order body
        var orderBody = {
          symbol: $scope.symbol,
          instrument: $scope.instrument,
          action: order.action.toUpperCase(),
          type: order.type,
          quantity: order.units || $scope.defaultUnits,
          limitPrice: order.price,
          takeProfit: order.takeProfit ? { price: order.takeProfit } : order.takeProfit,
          stopLoss: order.stopLoss ? { price: order.stopLoss } : order.stopLoss,
          timeInForce: duration,
          duration: duration,
          gtdTime: expiration,
          expiration: expiration,
        };

        console.log('data order request', orderBody);

        var convertedSides = {
          buy: 'long',
          sell: 'short',
        };

        // process order and send event after
        btTradingService.processOrder(orderBody).then(function () {
          $scope.$emit($scope.symbol + ':order-executed');
          // turn on MarketSense
          if (!$scope.hasMarketSense && $scope.enableMarketSense && $scope.marketData) {
            var sense = btMarketWidgetsService.getSensitivities($scope.marketData.priceData.priceValue);
            var settings = {
              id: null,
              instrument: $scope.symbol,
              broker: $scope.broker,
              displayName: $scope.displayName,
              duration: 'F',
              sensitivity: sense.default,
              side: convertedSides[order.action],
              price: $scope.marketData.priceData.priceValue,
              userId: null,
            };
            btMarketWidgetsService.createMarketSense(settings).catch(function (error) {
              if (error && error.message) btToastrService.error(error.message, $scope.displayName, { type: 'market' });
            });
          }
        });
      }
    }

    /**
     * Turn on or turn off MarketSense after order submission
     * @return {null}
     */
    function switchMarketSense() {
      $scope.enableMarketSense = !$scope.enableMarketSense;
      return null;
    }

    /**
     * Show pop-up full order dialog
     * @param {string} action - trade action buy or sell
     */
    function showOrderPopup(action) {
      // check broker
      if (btTradingService.isDefaultBroker()) {
        btTradingService.showSelectBrokerMenu($scope);
        return;
      }

      // check trade ability
      if (!window.hasTradeAbility) {
        return;
      }

      // check action
      if (action !== 'buy' && action !== 'sell') {
        return;
      }

      // load broker name to add broker-specified features
      $scope.broker = btTradingService.getBrokerName();

      // collect information about instrument
      // var instrumentObject = btInstrumentsService.getInstrumentByField('brokerSymbol', $scope.symbol);
      // FIXME Can we use `$scope.instrument`?
      var instrumentObject = btInstrumentsService.getInstrumentSmart('brokerSymbol', $scope.symbol);
      if (instrumentObject) {
        // load information about positions
        $scope.positions = instrumentObject.positionData;

        // load pip information
        if (instrumentObject.pip) {
          $scope.hasPIP = true;
          $scope.pip = instrumentObject.pip;
          $scope.step = 2 * instrumentObject.pip;
        } else {
          $scope.hasPIP = false;
          $scope.pip = null;
          $scope.step = 0.1;
        }

        // load display name of instrument
        $scope.displayName = instrumentObject.displayName;
      } else {
        // if we don't have information about instrument use default values
        $scope.hasPIP = false;
        $scope.positions = null;
        $scope.pip = null;
        $scope.step = 0.1;
        $scope.displayName = $scope.symbol;
      }

      // check MarketSense cache
      $scope.hasMarketSense = btMarketWidgetsService.getMarketSenseCache($scope.symbol, $scope.broker, null);
      $scope.durationOptions = getDurationOptions($scope.broker, $scope.instrument, BT.ORDER_TYPE.MARKET);
      $scope.enableMarketSense = $scope.broker !== 'tradestation';

      // prepare order request data
      $scope.baseValue = action === 'buy' ? $scope.bid : $scope.ask;
      $scope.baseValueFloat = parseFloat($scope.baseValue);
      $scope.defaultUnits = btTradingService.getDefaultTradeSize();

      // create order request
      $scope.orderRequest = {
        action: action,
        type: BT.ORDER_TYPE.MARKET,
        units: $scope.defaultUnits,
        duration: getDefaultDuration($scope.durationOptions),
        expiration: '',
        price: $scope.baseValueFloat,
        takeProfit: null,
        stopLoss: null,
      };

      // order confirmation flag
      $scope.showConfirmation = false;

      // order request text
      $scope.requestText = getRequestText();

      if ($scope.modal === undefined) {
        $scope.modal = $ionicModal.fromTemplate($templateCache.get('modal-order.html'), {
          scope: $scope,
          animation: 'slide-in-up',
        });
        $scope.modal.show();
      } else {
        $scope.modal.show();
      }
    }

    /**
     * Returns default duration.
     *
     * @param {any[]} durations - duration options
     * @return {any} default duration
     */
    function getDefaultDuration(durations) {
      var defaultDuration = durations.find(function (item) {
        return item.default;
      });

      return defaultDuration;
    }

    /**
     * Returns duration options.
     * 
     * @param {string} broker - broker 
     * @param {any} instrument - instrument
     * @param {string} orderType - order type
     * @return {any[]} - duration options
     */
    function getDurationOptions(broker, instrument, orderType) {
      if (broker === 'tradestation') {
        if (instrument.symbol === 'USDCUSD') {
          return tsUsdcUsdDurations
        }

        if (instrument.type === TS.ASSET_TYPE.CRYPTO) {
          if (orderType === BT.ORDER_TYPE.MARKET) {
            return tsCryptoMarketDurations;
          } else {
            return tsCryptoLimitStopDurations;
          }
        } else {
          return tsGeneralDurations;
        }
      } else {
        return btDurations;
      }
    }
  }
})();
