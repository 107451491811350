/**
 * Created by yatree on 13/09/16.
 */

(function btEditInPlaceClosure() {
  'use strict';

  /**
   * This directive edit text in place.
   *
   * @ngdoc directive
   * @name btEditInPlace
   * @memberOf ecapp
   */

  angular.module('ecapp').directive('xbtEditInPlace', [
    /**
     *
     * @return {angular.IDirective}
     */
    function () {
      return {
        restrict: 'E',
        scope: { value: '=' },
        template: '<span ng-click="edit()" ng-bind="value"></span><input ng-model="value"/>',
        link: function ($scope, element) {
          // Let's get a reference to the input element, as we'll want to reference it.
          var inputElement = angular.element(element.children()[1]);

          // This directive should have a set class so we can style it.
          element.addClass('edit-in-place');

          // Initially, we're not editing.
          $scope.editing = false;

          // ng-click handler to activate edit-in-place
          $scope.edit = function () {
            $scope.editing = true;

            // We control display through a class on the directive itself. See the CSS.
            element.addClass('active');

            // And we must focus the element.
            // `angular.element()` provides a chainable array, like jQuery so to access a native DOM function,
            // we have to reference the first element in the array.
            inputElement[0].focus();
          };

          // When we leave the input, we're done editing.
          inputElement.prop('onblur', function () {
            $scope.editing = false;
            element.removeClass('active');
          });
        },
      };
    },
  ]);
})();
