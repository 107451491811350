/**
 * Created by Uri on 8/2/2016.
 */

(function btSurpriseMeterClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive visualize range of surprise value.
     *
     * @ngdoc directive
     * @name btSurpriseMeter
     * @memberOf ecapp
     */
    .directive('btSurpriseMeter', [
      '$templateCache',
      /**
       *
       * @param {angular.ITemplateCacheService} $templateCache
       * @return {angular.IDirective}
       */
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/common/bt-surprise-meter.html'),
          scope: {
            data: '=',
          },
          controller: [
            '$scope',
            'btPriceService',
            function ($scope, btPriceService) {
              // save number precision
              this.precision = btPriceService.getPricePrecision($scope.data.low);

              this.data = {};
              this.data.low = parseFloat($scope.data.low);
              this.data.high = parseFloat($scope.data.high);
              this.data.actual = parseFloat($scope.data.actual);

              // noinspection JSUnresolvedVariable
              $scope.eventDirection = $scope.$parent.row.eventsInfo.direction;
              // noinspection JSUnresolvedVariable
              $scope.unit = ($scope.$parent.row.eventsInfo.unit || '').toUpperCase();

              $scope.noActual = this.data.actual === null || isNaN(this.data.actual);

              // Get absolute value of low and high values
              var absLow = Math.abs(this.data.low);
              var abdHigh = Math.abs(this.data.high);

              // Check which one is greater (isLowGreater is true if the abs(low) is greater than abs(high))
              if (absLow > abdHigh) {
                $scope.isLowGreater = true;
              } else if (abdHigh > absLow) {
                $scope.isLowGreater = false;
              } else if (abdHigh === absLow) {
                // If they are the same:
                $scope.lowIsHigh = true;
              }

              // Range is the larger value of the low and high
              $scope.positiveRange = Math.abs(Math.max(absLow, abdHigh));

              // Absolute value of the actual value
              var absoluteActual = Math.abs(this.data.actual);

              // If the actual is greater than the low or high, it becomes the range
              if (absoluteActual > $scope.positiveRange) {
                $scope.positiveRange = absoluteActual;
              }

              // Full range is twice the range
              // var fullRange = $scope.positiveRange * 2;
              $scope.negativeRange = $scope.positiveRange * -1;

              // Length of bar
              var visualLength = this.data.high - this.data.low;

              // Calculations of negative and positive bar with and margin
              $scope.negativeBarWidth = (absLow / $scope.positiveRange) * 100 * 0.5;
              $scope.positiveBarWidth = (abdHigh / $scope.positiveRange) * 100 * 0.5;
              $scope.negativeBarMarginLeft = 50 - $scope.negativeBarWidth;
              $scope.positiveBarMarginLeft = 0;

              // Case if all values are greater than 0 (no bar on negative side)
              if (this.data.low > 0) {
                visualLength = this.data.high - this.data.low;
                $scope.allAboveZero = true;
                $scope.positiveBarWidth = (visualLength / $scope.positiveRange) * 100 * 0.5;
                $scope.positiveBarMarginLeft = 50 - $scope.positiveBarWidth;
                $scope.negativeBarWidth = 0;
                $scope.negativeBarMarginLeft = 50;
              }

              // Case if all values are less than 0 (no bar on positive side)
              else if (this.data.high < 0) {
                var absoluteVisualLength = Math.abs(this.data.high - this.data.low);
                $scope.allBelowZero = true;
                $scope.positiveBarWidth = 0;
                $scope.positiveBarMarginLeft = 50;
                $scope.negativeBarWidth = (absoluteVisualLength / $scope.positiveRange) * 100 * 0.5;
                $scope.negativeBarMarginRight = 50 - $scope.negativeBarWidth;
              }

              // Case if actual is less than zero:
              if (this.data.actual < 0) {
                $scope.actualIsZero = false;
                $scope.actualIsNegative = true;
                $scope.actualIsGreaterThanZero = false;
                $scope.iconMargin = 49.8 - (absoluteActual / $scope.positiveRange) * 0.5 * 100;

                // If the actual value is the same as the negative range (which is range * -1)
                if (this.data.actual === $scope.negativeRange) {
                  $scope.iconMargin = 0;
                  $scope.negativeActualMarginLeft = 0;
                  $scope.positiveActualMarginRight = 0;
                  $scope.negativeActualMarginLeft = 0;
                }
              }
              // Case if actual is greater than zero
              else if (this.data.actual > 0) {
                $scope.actualIsZero = false;
                $scope.actualIsGreaterThanZero = true;
                $scope.actualIsNegative = false;

                // Case if the actual is the same as the range
                if (this.data.actual === $scope.positiveRange) {
                  $scope.iconFloat = 'right';
                  $scope.actualPositiveFloat = 'right';
                  $scope.positiveActualMarginRight = 0;
                  $scope.negativeActualMarginLeft = 0;
                }

                // If not equal to the range, calculate the icon margin:
                else if (this.data.actual != $scope.positiveRange) {
                  $scope.iconMargin = 43.8 + (absoluteActual / $scope.positiveRange) * 0.5 * 100;
                }
              }

              // If the actual is zero, set the icon margin to 50 (slight offset due to the icon)
              else if (this.data.actual === 0) {
                $scope.iconMargin = 50;
                $scope.actualIsZero = true;
              }

              // If the absolute value of the low is greater than that of the high:
              if ($scope.isLowGreater) {
                $scope.negativeActualMarginLeft = 50 * (1 - Math.abs(this.data.low) / Math.abs($scope.positiveRange));
                $scope.positiveActualMarginRight = 50 * (1 - Math.abs(this.data.high) / Math.abs($scope.positiveRange));
              } else if (!$scope.isLowGreater) {
                // If the absolute value of the high is greater than that of the low:
                $scope.positiveActualMarginRight = 50 * (1 - Math.abs(this.data.high) / Math.abs($scope.positiveRange));
                $scope.negativeActualMarginLeft = 50 * (1 - Math.abs(this.data.low) / Math.abs($scope.positiveRange));
              }

              // If all values in the object are below zero set margins for actual text:
              if ($scope.allBelowZero) {
                $scope.negativeActualMarginLeft = 0;
                $scope.positiveActualMarginRight = 49 + Math.abs((this.data.high / $scope.positiveRange) * 50);
                $scope.actualHighColor = '#CF3040';
              }

              // If all values in the object are about zero, set margins for actual text:
              else if ($scope.allAboveZero) {
                $scope.positiveActualMarginRight = 0;
                $scope.negativeActualMarginLeft = 49 + Math.abs((this.data.low / $scope.positiveRange) * 50);
                $scope.actualLowColor = '#48C064';
              }

              // If the low and high are equal (in absolute value), then there are no margins for the actual text:
              else if ($scope.lowIsHigh) {
                $scope.positiveActualMarginRight = 50 * (1 - Math.abs(this.data.high) / Math.abs($scope.positiveRange));
                $scope.negativeActualMarginLeft = 50 * (1 - Math.abs(this.data.low) / Math.abs($scope.positiveRange));
              }

              // convert back to strings
              // this.data.low = this.data.low.toFixed(2);
              // this.data.high = this.data.high.toFixed(2);
              // this.data.actual = this.data.actual.toFixed(2);
              $scope.negativeRange = $scope.negativeRange.toFixed(this.precision);
              $scope.positiveRange = $scope.positiveRange.toFixed(this.precision);
            },
          ],
          link: function (scope, el) {
            // Declaration of DOM elements
            var actualIcon = el[0].childNodes[0].childNodes[1].childNodes[1];
            var negativeBar = el[0].childNodes[0].childNodes[5].childNodes[1];
            var positiveBar = el[0].childNodes[0].childNodes[5].childNodes[5];
            // var actualValue = el[0].childNodes[0].childNodes[7].childNodes[0];
            // var actualText = el[0].childNodes[0].childNodes[1].childNodes[1];
            var negativeActualText = el[0].childNodes[0].childNodes[7].childNodes[1].childNodes[1];
            var positiveActualText = el[0].childNodes[0].childNodes[7].childNodes[1].childNodes[3];

            // Negative and positive bar width and margins
            negativeBar.style.width = scope.negativeBarWidth + '%';
            positiveBar.style.width = scope.positiveBarWidth + '%';
            negativeBar.style.marginLeft = scope.negativeBarMarginLeft + '%';
            positiveBar.style.marginLeft = scope.positiveBarMarginLeft + '%';
            negativeBar.style.marginRight = scope.negativeBarMarginRight + '%';

            // Icon margin
            if (actualIcon.style) {
              actualIcon.style.marginLeft = scope.iconMargin + '%';
              actualIcon.style.float = scope.iconFloat;
            }

            // Actual values color and margins
            positiveActualText.style.marginRight = scope.positiveActualMarginRight + '%';
            positiveActualText.style.color = scope.actualHighColor;
            negativeActualText.style.marginLeft = scope.negativeActualMarginLeft + '%';
            negativeActualText.style.color = scope.actualLowColor;
          },
        };
      },
    ]);
})();
