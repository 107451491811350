(function btDigestHudClosure() {
  'use strict';

  /**
   * https://github.com/pkaminski/digest-hud
   */

  angular.module('btUtils').provider('btDigestHud', [
    '$provide',
    function ($provide) {
      /**
       * This is a class to store information about watcher execution time.
       *
       * @param {string} key - key
       * @class
       */
      function WatchTiming(key) {
        this.key = key;
        this.reset();
      }

      /**
       * @alias WatchTiming#reset
       */
      WatchTiming.prototype.reset = function () {
        this.watch = 0;
        this.handle = 0;
        this.overhead = 0;
        this.total = 0;
        this.cycleTotal = 0;
        this.cycleStart = null;
        this.subTotal = 0;
      };

      /**
       * @alias WatchTiming#startCycle
       * @param {number} start - start time in milliseconds
       */
      WatchTiming.prototype.startCycle = function (start) {
        timingStack.push(this);
        this.cycleStart = start;
        this.cycleTotal = 0;
        this.subTotal = 0;
      };

      /**
       *
       * @param {*} counter - ?
       * @param {*} duration - ?
       */
      WatchTiming.prototype.countTime = function (counter, duration) {
        this[counter] += duration - this.subTotal;
        this.cycleTotal += duration;
        this.subTotal = 0;
      };

      /**
       *
       */
      WatchTiming.prototype.endCycle = function () {
        if (!this.cycleStart) return;
        var duration = Date.now() - this.cycleStart;
        this.overhead += duration - this.cycleTotal;
        this.cycleStart = null;
        timingStack.pop();
        if (timingStack.length) {
          timingStack[timingStack.length - 1].subTotal += duration;
        } else {
          overheadTiming.overhead -= duration;
        }
      };

      /**
       *
       */
      WatchTiming.prototype.sum = function () {
        this.total = this.watch + this.handle + this.overhead;
      };

      /**
       *
       * @param {*} grandTotal - ?
       * @return {string}
       */
      WatchTiming.prototype.format = function (grandTotal) {
        return (
          percentage(this.total / grandTotal) +
          '\u2003(' +
          percentage(this.watch / grandTotal) +
          ' + ' +
          percentage(this.handle / grandTotal) +
          ' + ' +
          percentage(this.overhead / grandTotal) +
          ')\u2003' +
          this.key
        );
      };

      /**
       *
       */
      function flushTimingCycle() {
        if (timingStack.length) timingStack[timingStack.length - 1].endCycle();
      }

      var digestStarts = [];
      var digestTimings = [];
      var watchTimings = {};
      var timingStack;
      var overheadTiming = createTiming('$$ng-overhead');
      var digestHud = this;
      var inDigest = false;
      var hudElement;
      var defaultHudPosition = 'bottom right';
      var customHudPosition;
      var $parse;

      /**
       * Number of watchers in top list.
       * @type {number}
       */
      this.numTopWatches = 20;

      /**
       * Number of digest cycles to calculate average.
       * @type {number}
       */
      this.numDigestStats = 25;

      /**
       * This function enables profiling.
       */
      this.enable = function () {
        var toggle = false;
        var detailsText = '';

        hudElement = angular.element('<div class="digestHud"></div>');
        var buttonsElement = angular.element('<div class="digestHud-buttons"></div>');
        var refreshElement = angular.element('<span class="digestHud-refresh">refresh</span>');
        var resetElement = angular.element('<span class="digestHud-reset">reset</span>');
        buttonsElement.append(refreshElement);
        buttonsElement.append(angular.element('<span class="digestHud-button-separator"> &bull; </span>'));
        buttonsElement.append(resetElement);
        hudElement.append(buttonsElement);
        var summaryElement = angular.element('<div class="digestHud-summary"></div>');
        hudElement.append(summaryElement);
        var detailsElement = angular.element('<div class="digestHud-details"></div>');
        hudElement.append(detailsElement);
        var showDetails = false;
        hudElement.on('click', function () {
          showDetails = !showDetails;
          buttonsElement[0].style.display = showDetails ? '' : 'none';
          detailsElement[0].style.display = showDetails ? '' : 'none';
          if (showDetails) refreshDetails();
        });

        hudElement.on('copy', function (ev) {
          ev.originalEvent.clipboardData.setData('text/plain', detailsText);
          ev.preventDefault();
        });

        refreshElement.on('click', refreshDetails);
        resetElement.on('click', resetTimings);
        buttonsElement.on('click', function (ev) {
          ev.stopPropagation();
        });

        // !!! It looks like it prevent click event
        // hudElement.on('mousedown mouseup click', function(ev) {ev.stopPropagation();});
        hudElement.css({
          position: 'fixed',
          backgroundColor: 'rgba(0, 0, 0, 0.65)',
          color: 'white',
          padding: '2px 5px',
          fontSize: 'small',
          fontFamily: 'monospace',
          cursor: 'default',
          zIndex: '1000000',
        });

        this.setHudPosition(customHudPosition || defaultHudPosition);

        buttonsElement.css({
          float: 'right',
          display: 'none',
        });
        buttonsElement.children().css({
          cursor: 'pointer',
        });
        detailsElement.css({
          whiteSpace: 'pre',
          minWidth: '30em',
          maxWidth: '50em',
          display: 'none',
        });
        angular.element(document.body).append(hudElement);

        /**
         *
         */
        function refreshDetails() {
          var grandTotal = 0,
            topTotal = 0;

          var numWatchers = countWatchers(angular.element(document.body));
          var topWatchTimings = Object.keys(watchTimings)
            .map(function (k) {
              return watchTimings[k];
            })
            .map(function (timing) {
              timing.sum();
              grandTotal += timing.total;
              return timing;
            })
            .sort(function (a, b) {
              var x = a.total;
              var y = b.total;
              return x < y ? 1 : x > y ? -1 : 0;
            })
            .slice(0, digestHud.numTopWatches);

          var lines = topWatchTimings.map(function (timing) {
            topTotal += timing.total;
            return timing.format(grandTotal);
          });
          var rows = lines.map(function (text) {
            var row = angular.element('<div class="digestHud-row"></div>');
            row.css({
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            });
            row.text(text.replace(/[ \n]+/g, ' '));
            row.attr('title', text.slice(29));
            return row;
          });
          detailsElement.empty();
          var headerElement = angular
            .element(
              '<div class="digestHud-header">\u2007Total\u2007\u2007\u2007Watch\u2007Work\u2007Overhead\u2007\u2007Function</div>'
            )
            .css({ borderBottom: '1px solid' });
          detailsElement.append(headerElement);
          rows.forEach(function (row) {
            detailsElement.append(row);
          });
          var footer =
            'Top ' +
            topWatchTimings.length +
            ' items account for ' +
            percentage(topTotal / grandTotal) +
            ' of ' +
            grandTotal +
            'ms of digest processing time.\n' +
            'Number of watchers: ' +
            numWatchers;
          var footerElement = angular.element('<div class="digestHud-footer"></div>').text(footer);
          detailsElement.append(footerElement);
          detailsText =
            'Total  Watch   Work Overhead  Function\n' +
            lines
              .map(function (text) {
                return text.replace(/[ \n]+/g, ' ');
              })
              .join('\n') +
            '\n' +
            footer +
            '\n';
        }

        /**
         *
         */
        function resetTimings() {
          digestTimings = [];

          Object.keys(watchTimings)
            .map(function (k) {
              return watchTimings[k];
            })
            .forEach(function (watchTiming) {
              watchTiming.reset();
            });
        }

        $provide.decorator('$rootScope', [
          '$delegate',
          function ($delegate) {
            var proto = Object.getPrototypeOf($delegate);
            var originalDigest = proto.$digest.original || proto.$digest;
            var originalEvalAsync = proto.$evalAsync;
            var originalApplyAsync = proto.$applyAsync;
            var originalPostDigest = proto.$$postDigest;
            var originalWatch = proto.$watch;
            var originalWatchGroup = proto.$watchGroup;
            // $watchCollection delegates to $watch, no extra processing necessary
            if (proto.$digest.original) {
              proto.$digest.original = instrumentedDigest;
            } else {
              proto.$digest = instrumentedDigest;
            }
            proto.$evalAsync = instrumentedEvalAsync;
            proto.$applyAsync = instrumentedApplyAsync;
            proto.$$postDigest = instrumentedPostDigest;
            proto.$watch = instrumentedWatch;
            proto.$watchGroup = instrumentedWatchGroup;

            var watchTiming;

            /**
             *
             */
            function instrumentedDigest() {
              /*jshint validthis: true*/
              timingStack = [];
              this.$$postDigest(flushTimingCycle);
              var start = Date.now();
              inDigest = true;
              try {
                originalDigest.call(this);
              } finally {
                inDigest = false;
              }
              var end = Date.now();
              var duration = end - start;
              overheadTiming.overhead += duration;
              toggle = !toggle;
              digestStarts.push(start);
              digestTimings.push(duration);
              if (digestStarts.length > digestHud.numDigestStats) digestStarts.shift();
              if (digestTimings.length > digestHud.numDigestStats) digestTimings.shift();
              var len = digestTimings.length;
              var sorted = digestTimings.slice().sort(function (a, b) {
                return a - b;
              });
              var median = len % 2 ? sorted[(len - 1) / 2] : Math.round((sorted[len / 2] + sorted[len / 2 - 1]) / 2);
              var rate = ((digestHud.numDigestStats * 1000) / (end - digestStarts[0])).toFixed(1);
              var description =
                'digest ' +
                sorted[0] +
                'ms ' +
                median +
                'ms ' +
                sorted[len - 1] +
                'ms ' +
                rate +
                'dps ' +
                (toggle ? '\u25cf' : '\u25cb');
              summaryElement.text(description);
            }

            /**
             *
             * @param {*} expression - ?
             * @param {*} locals - ?
             */
            function instrumentedEvalAsync(expression, locals) {
              /*jshint validthis: true*/
              var timing = createTiming('$evalAsync(' + formatExpression(expression) + ')');
              originalEvalAsync.call(this, wrapExpression(expression, timing, 'handle', true, true), locals);
            }

            /**
             *
             * @param {*} expression - ?
             */
            function instrumentedApplyAsync(expression) {
              /*jshint validthis: true*/
              var timing = createTiming('$applyAsync(' + formatExpression(expression) + ')');
              originalApplyAsync.call(this, wrapExpression(expression, timing, 'handle', false, true));
            }

            /**
             *
             * @param {*} fn  - ?
             */
            function instrumentedPostDigest(fn) {
              /*jshint validthis: true*/
              if (timingStack.length) {
                fn = wrapExpression(fn, timingStack[timingStack.length - 1], 'overhead', true, true);
              }
              originalPostDigest.call(this, fn);
            }

            /**
             *
             * @param {*} watchExpression - ?
             * @param {*} listener - ?
             * @param {*} objectEquality - ?
             * @return {*}
             */
            function instrumentedWatch(watchExpression, listener, objectEquality) {
              /*jshint validthis: true*/
              var watchTimingSet = false;
              if (!watchTiming) {
                // Capture watch timing (and its key) once, before we descend in $$watchDelegates.
                watchTiming = createTiming(formatExpression(watchExpression));
                watchTimingSet = true;
              }
              try {
                if (angular.isString(watchExpression)) {
                  watchExpression = $parse(watchExpression);
                }
                if (watchExpression && watchExpression.$$watchDelegate) {
                  return originalWatch.call(this, watchExpression, listener, objectEquality);
                } else {
                  return originalWatch.call(
                    this,
                    wrapExpression(watchExpression, watchTiming, 'watch', true, false),
                    wrapListener(listener, watchTiming),
                    objectEquality
                  );
                }
              } finally {
                if (watchTimingSet) watchTiming = null;
              }
            }

            /**
             *
             * @param {*} watchExpressions - ?
             * @param {*} listener - ?
             * @return {*}
             */
            function instrumentedWatchGroup(watchExpressions, listener) {
              /*jshint validthis: true*/
              var watchTimingSet = false;
              if (!watchTiming) {
                // $watchGroup delegates to $watch for each expression, so just make sure to set the group's
                // aggregate key as the override first.
                watchTiming = createTiming('[' + watchExpressions.map(formatExpression).join(', ') + ']');
                watchTimingSet = true;
              }
              try {
                return originalWatchGroup.call(this, watchExpressions, listener);
              } finally {
                if (watchTimingSet) watchTiming = null;
              }
            }

            return $delegate;
          },
        ]);

        $provide.decorator('$parse', [
          '$delegate',
          function ($delegate) {
            $parse = $delegate;
            return function (expression) {
              var result = $delegate.apply(this, arguments);
              if (angular.isString(expression)) result.exp = expression;
              return result;
            };
          },
        ]);

        $provide.decorator('$q', [
          '$delegate',
          function ($delegate) {
            var proto = Object.getPrototypeOf($delegate.defer().promise);
            var originalThen = proto.then;
            var originalFinally = proto.finally;
            proto.then = instrumentedThen;
            proto.finally = instrumentedFinally;

            /**
             *
             * @param {*} onFulfilled - ?
             * @param {*} onRejected - ?
             * @param {*} progressBack - ?
             * @return {*}
             */
            function instrumentedThen(onFulfilled, onRejected, progressBack) {
              /*jshint validthis: true*/
              return originalThen.call(
                this,
                wrapExpression(
                  onFulfilled,
                  createTiming('$q(' + formatExpression(onFulfilled) + ')'),
                  'handle',
                  false,
                  true
                ),
                wrapExpression(
                  onRejected,
                  createTiming('$q(' + formatExpression(onRejected) + ')'),
                  'handle',
                  false,
                  true
                ),
                wrapExpression(
                  progressBack,
                  createTiming('$q(' + formatExpression(progressBack) + ')'),
                  'handle',
                  false,
                  true
                )
              );
            }

            /**
             *
             * @param {*} callback - ?
             * @param {*} progressBack - ?
             * @return {*}
             */
            function instrumentedFinally(callback, progressBack) {
              /*jshint validthis: true*/
              return originalFinally.call(
                this,
                wrapExpression(callback, createTiming('$q(' + formatExpression(callback) + ')'), 'handle', false, true),
                wrapExpression(
                  progressBack,
                  createTiming('$q(' + formatExpression(callback) + ')'),
                  'handle',
                  false,
                  true
                )
              );
            }

            return $delegate;
          },
        ]);

        var originalBind = angular.bind;
        angular.bind = function (self, fn, args) {
          void args;
          var result = originalBind.apply(this, arguments);
          result.exp = formatExpression(fn);
          return result;
        };
      };

      /**
       * This function set HUD position.
       * @param {string} position - position in format "[top|bottom] [left|right]"
       */
      this.setHudPosition = function (position) {
        if (hudElement) {
          // reset all to defaults
          var styles = {
            top: 'auto',
            right: 'auto',
            bottom: 'auto',
            left: 'auto',
          };
          position = position ? '' + position : defaultHudPosition;
          position.split(' ').map(function (prop) {
            styles[prop] = 0;
          });
          hudElement.css(styles);
        } else {
          // save and apply on enabled
          customHudPosition = position;
        }
      };

      /**
       *
       * @param {*} value - ?
       * @return {*}
       */
      function percentage(value) {
        if (value >= 1) return (value * 100).toFixed(1) + '%';
        return ('\u2007\u2007' + (value * 100).toFixed(1) + '%').slice(-5);
      }

      /**
       *
       * @param {*} watchExpression - ?
       * @return {*}
       */
      function formatExpression(watchExpression) {
        if (!watchExpression) return '';
        if (angular.isString(watchExpression)) return watchExpression;
        if (angular.isString(watchExpression.exp)) return watchExpression.exp;
        if (watchExpression.name) return 'function ' + watchExpression.name + '() {\u2026}';
        return watchExpression.toString();
      }

      /**
       *
       * @param {*} expression - ?
       * @param {*} timing - ?
       * @param {*} counter - ?
       * @param {*} flushCycle - ?
       * @param {*} endCycle - ?
       * @return {*}
       */
      function wrapExpression(expression, timing, counter, flushCycle, endCycle) {
        if (!expression && !flushCycle) return expression;
        var actualExpression = angular.isString(expression) ? $parse(expression) : expression;
        return function instrumentedExpression() {
          if (flushCycle) flushTimingCycle();
          if (!actualExpression) return;
          if (!inDigest) return actualExpression.apply(this, arguments);
          var start = Date.now();
          timing.startCycle(start);
          try {
            return actualExpression.apply(this, arguments);
          } finally {
            timing.countTime(counter, Date.now() - start);
            if (endCycle) timing.endCycle();
          }
        };
      }

      /**
       *
       * @param {*} listener - ?
       * @param {*} timing - ?
       * @return {*}
       */
      function wrapListener(listener, timing) {
        if (!listener) return listener;
        return function instrumentedListener() {
          var start = Date.now();
          try {
            return listener.apply(this, arguments);
          } finally {
            timing.countTime('handle', Date.now() - start);
          }
        };
      }

      /**
       *
       * @param {*} key - ?
       * @return {*}
       */
      function createTiming(key) {
        var timing = watchTimings[key];
        if (!timing) timing = watchTimings[key] = new WatchTiming(key);
        return timing;
      }

      /**
       *
       * @param {*} root - ?
       * @return {*}
       */
      function countWatchers(root) {
        // var root = angular.element(document.getElementsByTagName('body'));
        var watchers = [];

        var f = function (element) {
          angular.forEach(['$scope', '$isolateScope'], function (scopeProperty) {
            if (element.data() && element.data().hasOwnProperty(scopeProperty)) {
              angular.forEach(element.data()[scopeProperty].$$watchers, function (watcher) {
                watchers.push(watcher);
              });
            }
          });

          angular.forEach(element.children(), function (childElement) {
            f(angular.element(childElement));
          });
        };

        f(root);

        // Remove duplicate watchers
        var watchersWithoutDuplicates = [];
        angular.forEach(watchers, function (item) {
          if (watchersWithoutDuplicates.indexOf(item) < 0) {
            watchersWithoutDuplicates.push(item);
          }
        });

        return watchersWithoutDuplicates.length;
      }

      this.$get = function () {};
    },
  ]);
})();
