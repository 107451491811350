/**
 * Created by Itay on 6/7/2016.
 */

/*global Chart*/

(function btCircleClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive is the circle that is placed everywhere. There is Chart.js setup in angular.module('ecapp').
     *
     * @ngdoc directive
     * @name btCircle
     * @memberOf ecapp
     */
    .directive('btCircle', [
      '$templateCache',
      function ($templateCache) {
        var defaultSize = 57;
        return {
          restrict: 'E',
          transclude: true,
          scope: {
            strength: '=',
            size: '@',
          },
          template: $templateCache.get('directives/common/bt-circle.html'),
          controller: [
            '$scope',
            function ($scope) {
              if (angular.isUndefined($scope.size)) {
                $scope.size = defaultSize;
              }
            },
          ],
          link: function (scope, element, attrs) {
            /**
             * Recalculate percentage of strength
             */
            function calcPercentage() {
              if (scope.strength != null) {
                percentage = scope.strength.value;
                rest = 100 - scope.strength.value;

                if (rest < 0) rest = 0;

                if (scope.strength.status.toLowerCase() == 'buy') color = '#48C064';
                else if (scope.strength.status.toLowerCase() == 'sell') color = '#CF3040';
              } else {
                percentage = 0;
                rest = 100;
              }
            }

            /**
             * Update chart
             */
            function updateChart() {
              myChart.data.datasets[0].data = [percentage, rest];
              myChart.data.datasets[0].backgroundColor = [color, '#000000'];
              myChart.update();
            }

            /**
             * Create chart
             */
            function createChart() {
              // New version of data for Chart.js v2
              var data = {
                labels: ['Percentage', 'Rest'],
                datasets: [
                  {
                    data: [percentage, rest],
                    backgroundColor: [color, '#000000'],
                  },
                ],
                hoverBorderWidth: 0,
                borderWidth: 0,
              };

              // New way to create doughnut chart
              myChart = new Chart(ctx, {
                type: 'doughnut',
                data: data,
                options: {
                  animation: false,
                  hover: false,
                  cutoutPercentage: 92,
                },
              });
            }

            //getting the size from the scope.
            var size = scope.size;

            //finding the wrapper div, in order to change its size
            element.find('.bt-circle').css({ width: size + 'px', height: size + 'px' });

            //getting the canvas
            var canvas = element.find('.doughnut')[0];

            //setting canvas width and height to the size
            canvas.width = size;
            canvas.height = size;

            //getting canvas context
            var ctx = canvas.getContext('2d');

            var percentage;
            var rest;
            var color = '#817035';
            var myChart = null;

            calcPercentage();
            createChart();

            // You can add once attribute to prevent reloading
            if (!attrs.hasOwnProperty('once')) {
              scope.$watch('strength', function () {
                calcPercentage();
                updateChart();
              });
            }
          },
        };
      },
    ]);
})();
