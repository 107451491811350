/**
 * Created by yatree on 15/09/16.
 */

(function btAccountInitialsClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive display user image and name in menu and dashboard
     *
     * @ngdoc directive
     * @name btAccountInitials
     * @memberOf ecapp
     * @scope
     * @param {String} styleClass - expect one css class
     */
    .directive('btAccountInitials', btAccountInitials);

  btAccountInitials.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btAccountInitials($templateCache) {
    return {
      restrict: 'E',
      scope: {
        styleClass: '@',
        hasInvitation: '@',
        imageHeight: '=',
        imageWidth: '=',
      },
      template: $templateCache.get('directives/common/bt-account-initials.html'),
      controller: btAccountInitialsController,
    };
  }

  btAccountInitialsController.$inject = [
    '$scope',
    '$templateCache',
    '$timeout',
    'btShareScopeService',
    'btSubscriptionService',
    'btRestrictionService',
    'btSettingsService',
    'btPopoverService',
    'btVoiceAssistantHelperService',
    'btDeviceService',
  ];

  /**
   *
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.ISubscriptionService} btSubscriptionService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IPopoverService} btPopoverService
   * @param {ecapp.IVoiceAssistantHelperService} btVoiceAssistantHelperService
   * @param {ecapp.IDeviceService} btDeviceService
   */
  function btAccountInitialsController(
    $scope,
    $templateCache,
    $timeout,
    btShareScopeService,
    btSubscriptionService,
    btRestrictionService,
    btSettingsService,
    btPopoverService,
    btVoiceAssistantHelperService,
    btDeviceService
  ) {
    $scope.isBigBrainBank = btSettingsService.getDomain() === 'bigbrainbank';

    $scope.hasInvitationValue = $scope.hasInvitation === 'true';
    $scope.hasMenu = !$scope.hasInvitationValue && btRestrictionService.hasFeature('personalization');
    $scope.accountInfo = btShareScopeService.getAccountSettings();
    $scope.plan = btSubscriptionService.getName(btSubscriptionService.getCombinedSubscription());
    $scope.hasPersonalization = btRestrictionService.hasFeature('personalization');
    $scope.hasReferral = btSettingsService.hasFeature('referral');
    $scope.popover = null;

    $scope.speechSynthesis = btVoiceAssistantHelperService.getSpeechSynthesis();
    $scope.status = btVoiceAssistantHelperService.getStatus();
    $scope.isEnable = $scope.status.isEnable;
    $scope.isTweetReadable = btVoiceAssistantHelperService.getTweetReadable();
    $scope.icon = $scope.isEnable ? 'ion-android-volume-up' : 'ion-android-volume-off';
    var devices = btShareScopeService.getAccountInfoField('devices');
    $scope.hasApp = devices.indexOf('ios') !== -1 || devices.indexOf('android') !== -1;

    $scope.openPopover = openPopover;
    $scope.closePopover = closePopover;
    $scope.changeStatus = changeStatus;
    $scope.changeTweetReadable = changeTweetReadable;
    $scope.getInvitationByPhone = getInvitationByPhone;

    $scope.$watch('status.isEnable', onStatusChange);
    $scope.$on('tweetReadableChanged', onTwitterChange);
    $scope.$on('subscription:updated', onSubscriptionUpgradeSuccess);
    $scope.$on('$destroy', onDestroy);

    $scope.isUpgradeSuggested = function () {
      return btSubscriptionService.isUpgradeSuggested(btSubscriptionService.getCombinedSubscription());
    };

    if ($scope.hasMenu) {
      $scope.popover = btPopoverService.init($templateCache.get('modals/bt-user-popover.html'), {
        scope: $scope,
        interactive: true,
        backdrop: true,
      });
    }

    /**
     * This function reacts on subscription update.
     */
    function onSubscriptionUpgradeSuccess() {
      $scope.hasMenu = !$scope.hasInvitationValue && btRestrictionService.hasFeature('personalization');
      $scope.hasPersonalization = btRestrictionService.hasFeature('personalization');
    }

    /**
     *
     * @param {*} event
     * @param {*} data
     */
    function onTwitterChange(event, data) {
      $scope.isTweetReadable = data.position;
    }

    /**
     *
     * @param {*} newValue
     * @param {*} oldValue
     */
    function onStatusChange(newValue, oldValue) {
      if (newValue !== undefined && newValue !== oldValue) {
        // console.log('btToggle - Settings - Watched - position:', newValue);
        $scope.isEnable = newValue;
        $scope.icon = $scope.isEnable ? 'ion-android-volume-up' : 'ion-android-volume-off';
      }
    }

    /**
     *
     * @param {*} name
     * @param {*} position
     * @return {null}
     */
    function changeStatus(name, position) {
      void name;
      $scope.isEnable = position;
      toggleAssistant();

      return null;
    }

    /**
     *
     * @param {string} name
     * @param {boolean} position
     * @return {null}
     */
    function changeTweetReadable(name, position) {
      void name;
      var wasChanged = btVoiceAssistantHelperService.setTweetReadable(position);
      if (!wasChanged) closePopover();
      return wasChanged;
    }

    /**
     *
     */
    function toggleAssistant() {
      if ($scope.status.isEnable) {
        btVoiceAssistantHelperService.disable();
      } else {
        btVoiceAssistantHelperService.enable().then(function () {
          $timeout(function () {
            $scope.isEnable = $scope.status.isEnable;
          });
        });
      }
    }

    /**
     * This function shows account menu
     */
    function openPopover() {
      if ($scope.popover) {
        $scope.popover.show();
      }
    }

    /**
     * This function closes account menu
     */
    function closePopover() {
      if ($scope.popover) {
        $scope.popover.hide();
      }
    }

    /**
     * This function is controller destructor
     */
    function onDestroy() {
      if ($scope.popover) {
        $scope.popover.remove();
      }
    }

    /**
     * This function sends link to download application to the phone number
     */
    function getInvitationByPhone() {
      btDeviceService.sendGetMobileAlert();
    }
  }
})();
