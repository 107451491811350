/**
 * Created by edwin on 3/16/18
 */

// @ts-check
(function btReleasesServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btReleasesService', btReleasesService);

  btReleasesService.$inject = [
    '$q',
    'btInsightsService',
    'btRowProcessorService',
    'Rows',
    'btEventsService',
    'btDateService',
    'btDefaultSpeechImage',
    'btDefaultReportImage',
    'btStrengthService',
    'btSettingsService',
  ];

  /**
   * @ngdoc service
   * @name btReleasesService
   * @memberOf ecapp
   * @description
   *  This service implements releases model.
   * @param {angular.IQService} $q
   * @param {ecapp.IInsightsService} btInsightsService
   * @param {ecapp.IRowProcessorService} btRowProcessorService
   * @param {ecapp.IGeneralLoopbackService} lbRows
   * @param {ecapp.IEventsService} btEventsService
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IDefaultSpeechImage} btDefaultSpeechImage
   * @param {ecapp.IDefaultReportImage} btDefaultReportImage
   * @param {ecapp.IStrengthService} btStrengthService
   * @param {ecapp.ISettingsService} btSettingsService
   * @return {ecapp.IReleasesService}
   */
  function btReleasesService(
    $q,
    btInsightsService,
    btRowProcessorService,
    lbRows,
    btEventsService,
    btDateService,
    btDefaultSpeechImage,
    btDefaultReportImage,
    btStrengthService,
    btSettingsService
  ) {
    console.log('Running btReleasesService');

    var gCurrencies = [
      'GGP',
      'MUR',
      'DKK',
      'UAH',
      'USD',
      'LTL',
      'BZD',
      'AFN',
      'GIP',
      'DJF',
      'VND',
      'CUP',
      'AZN',
      'BHD',
      'RWF',
      'MOP',
      'ZWD',
      'ARS',
      'UGX',
      'EGP',
      'RUB',
      'BAM',
      'KHR',
      'KGS',
      'KZT',
      'COP',
      'BYR',
      'CDF',
      'JOD',
      'HUF',
      'MXN',
      'PYG',
      'MMK',
      'CRC',
      'SEK',
      'BOB',
      'LKR',
      'EUR',
      'BRL',
      'IQD',
      'KES',
      'VEF',
      'IRR',
      'MKD',
      'ZMK',
      'ETB',
      'KYD',
      'GBP',
      'FKP',
      'DZD',
      'MDL',
      'KWD',
      'BDT',
      'TND',
      'MZN',
      'NOK',
      'SBD',
      'MNT',
      'AED',
      'NZD',
      'BIF',
      'PLN',
      'TVD',
      'DOP',
      'MYR',
      'KRW',
      'HRK',
      'LBP',
      'NAD',
      'CLP',
      'SDG',
      'TRY',
      'UZS',
      'TRL',
      'BSD',
      'XOF',
      'BWP',
      'BMD',
      'IMP',
      'ANG',
      'KPW',
      'CNY',
      'SCR',
      'JPY',
      'SAR',
      'HNL',
      'GTQ',
      'RSD',
      'GHC',
      'GNF',
      'TOP',
      'CAD',
      'CVE',
      'AMD',
      'PKR',
      'LRD',
      'LVL',
      'THB',
      'MAD',
      'BGN',
      'ILS',
      'EEK',
      'PEN',
      'NGN',
      'SOS',
      'NPR',
      'NIO',
      'SYP',
      'LYD',
      'TWD',
      'GYD',
      'IDR',
      'QAR',
      'FJD',
      'XAF',
      'ZAR',
      'ERN',
      'HKD',
      'ALL',
      'JEP',
      'UYU',
      'TZS',
      'KMF',
      'SGD',
      'OMR',
      'MGA',
      'SRD',
      'SHP',
      'PHP',
      'LAK',
      'TTD',
      'BBD',
      'BND',
      'JMD',
      'CZK',
      'INR',
      'AUD',
      'AWG',
      'PAB',
      'GEL',
      'YER',
      'RON',
      'SVC',
      'CHF',
      'ISK',
      'GHS',
    ];

    /**
     *
     * @param {btRawRelease} data
     * @memberof bt
     * @class
     */
    function ReleaseObject(data) {
      this.createFromReleaseObject(data);
    }

    ReleaseObject.prototype.updateInterval = function () {
      this.interval = btDateService.getHumanisedTimeFromNow(this.date, true);

      var now = btDateService.getNowDate();
      var hotPast = new Date(now.getTime() - 5 * 60 * 1000);
      var hotFuture = new Date(now.getTime() + 5 * 60 * 1000);

      this.isHot = this.date > hotPast && this.date < hotFuture;
      this.isPast = this.date <= now;
      this.isFuture = this.date > now;
      this.isReleased = this.isPast || this.hasActual;

      if (this.releasedDate) {
        if (now - this.releasedDate > 5 * 60 * 1000) {
          // testing
          // this.class = ['released-positive'];
          this.class = 'bt-null';
        } else {
          if (this.strength.surprise) {
            if (this.strength.surprise.status === 'Sell') {
              this.class = 'released-negative';
            } else if (this.strength.surprise.status === 'Buy') {
              this.class = 'released-positive';
            } else {
              this.class = 'released-as-expected';
            }
          }
        }
      } else {
        this.class = 'bt-null';
      }
    };

    ReleaseObject.prototype.updateInsights = function updateInsights() {
      this.insightsObject.insights = btInsightsService.removeWeakInsights(
        this.insightsObject.insights,
        this.isReleased
      );

      // noinspection JSUnusedGlobalSymbols
      this.hasInsights = this.insightsObject && this.insightsObject.insights.length > 0;
      this.hasPostInsights =
        this.insightsObject &&
        this.insightsObject.insights.length > 0 &&
        this.insightsObject.insights.filter(function (t) {
          return t.type === 'post';
        }).length > 0;
    };

    ReleaseObject.prototype.createFromReleaseObject = function createFromReleaseObject(data) {
      this.id = data.id;
      this.time = data.time;
      this.date = new Date(data.time * 1000);
      this.actual = data.actual;
      this.forecast = data.forecast;
      this.previous = data.previous;
      this.revision = data.revision;

      // noinspection JSUnusedGlobalSymbols
      this.hasRevision = this.revision !== 'NA';
      this.hasActual = this.actual !== 'NA';
      this.releasedDate = this.actual === 'NA' ? null : this.date;

      this.strength = {
        surprise: null,
        revision: null,
      };
      this.links = data.links || [];
      this.params = btRowProcessorService.getReleaseIdData(data, data.eventsInfo);

      if (data.releaseStrength) {
        this.strength.surprise = btStrengthService.prepareStrength(data.releaseStrength.value, data.time);
      }

      if (data.revisionStrength) {
        this.strength.revision = btStrengthService.prepareStrength(data.revisionStrength.value, data.time);
      }

      if (data.eventsInfo) {
        this.event = new EventObject(data.eventsInfo);
        // if (data.eventsInfo.usuallyNoForecast === true) {
        //   this.forecast = 'Usually No Exp';
        // }
      } else {
        this.event = { id: data.eventId };
      }

      this.updateInterval();

      this.tradeIdeas = data.tradingInsights;
      // noinspection JSUnusedGlobalSymbols
      this.hasTradeIdeas = data.tradingInsights.length > 0;
      this.expectedTradeIdeas = data.expectedTradingInsights;
      // noinspection JSUnusedGlobalSymbols
      this.hasExpectedTradeIdeas = data.expectedTradingInsights.length > 0;

      this.insightsObject = data.insights;
      this.updateInsights();
    };

    ReleaseObject.prototype.updateFromReleaseObject = function updateFromReleaseObject(data) {
      this.time = data.time;
      this.date = new Date(data.time * 1000);
      this.actual = data.actual;
      this.forecast = data.forecast;
      this.previous = data.previous;
      this.revision = data.revision;

      // noinspection JSUnusedGlobalSymbols
      this.hasRevision = this.revision !== 'NA';
      this.hasActual = this.actual !== 'NA';

      this.params = btRowProcessorService.getReleaseIdData(data, data.eventsInfo);

      if (data.releaseStrength) {
        this.strength.surprise = btStrengthService.prepareStrength(data.releaseStrength.value, data.time);
      }

      if (data.revisionStrength) {
        this.strength.revision = btStrengthService.prepareStrength(data.revisionStrength.value, data.time);
      }

      this.updateInterval();

      if (data.tradingInsights && data.tradingInsights.length > 0) {
        this.tradeIdeas = data.tradingInsights;
        // noinspection JSUnusedGlobalSymbols
        this.hasTradeIdeas = data.tradingInsights.length > 0;
      }

      if (
        !(
          this.insightsObject &&
          this.insightsObject.insights &&
          this.insightsObject.insights[0] &&
          this.insightsObject.insights[0].templateHTML
        )
      ) {
        if (data.insights && data.insights.insights && data.insights.insights.length > 0) {
          this.insightsObject = data.insights;
          this.updateInsights();
        }
      }

      if (data.class === 'eventCardJustAsExpected') {
        this.class = 'released-as-expected';
        this.releasedDate = btDateService.getNowDate();
      }

      if (data.class === 'eventCardJustPositive') {
        this.class = 'released-positive';
        this.releasedDate = btDateService.getNowDate();
      }

      if (data.class === 'eventCardJustNegative') {
        this.class = 'released-negative';
        this.releasedDate = btDateService.getNowDate();
      }
    };

    ReleaseObject.prototype.filterTradeIdeasByInstrument = function filterTradeIdeasByInstrument(btInstrumentName) {
      this.tradeIdeas = this.tradeIdeas.filter(function (t) {
        return t.market === btInstrumentName;
      });
      // noinspection JSUnusedGlobalSymbols
      this.hasTradeIdeas = this.tradeIdeas.length > 0;
    };

    /**
     *
     * @param {btRawEvent} data
     * @memberof bt
     * @class
     */
    function EventObject(data) {
      this.id = data._id;
      this.name = data.name;
      this.type = data.eventType || 0;
      // noinspection JSUnusedGlobalSymbols
      this.rType = data.relativeType || 0;
      this.currency = data.currency;
      this.importance = data.importance;
      this.direction = data.direction || 1;
      this.unit = data.unit || '';
      // noinspection JSUnusedGlobalSymbols
      this.unpredictable = data.usuallyNoForecast;
      this.instruments = data.relatedInstruments;
      this.benchmark = data.dynamicData.benchmark || 0;
    }

    EventObject.prototype.test = function () {};

    return {
      // getReleases: getReleases,
      getReleasesForSymbol: getReleasesForSymbol,
      getReleaseByStateParams: getReleaseByStateParams,
      getReleaseById: getReleaseById,
      getUserReleases: getUserReleases,
      getStrengthStatistics: getStrengthStatistics,
      prepareReleases: prepareReleases,
      resetFilter: resetFilter,
      regularFilter: regularFilter,
      watchlistFilter: watchlistFilter,
      handleRows: handleRows,
      hasNewActual: hasNewActual,
      hasNewRevision: hasNewRevision,
      getReleaseStateParams: getReleaseStateParams,
      prepareStrength: prepareStrength,
      getReminderText: getReminderText,
      getReminderSpeech: getReminderSpeech,
      getActualVersusExpectedText: getActualVersusExpectedText,
      getActualVersusExpectedSpeech: getActualVersusExpectedSpeech,
      getExpectedText: getExpectedText,
      getExpectedSpeech: getExpectedSpeech,
      getPotentialTradeIdeasSymbols: getPotentialTradeIdeasSymbols,
    };

    /**
     *
     * @param {String} oandaSymbol
     * @param {Number} [from]
     * @param {Number} [till]
     * @return {angular.IPromise<ReleaseObject[]>}
     */
    function getReleasesForSymbol(oandaSymbol, from, till) {
      if (from === undefined || till === undefined) {
        var range = btDateService.getDayRange(24, 48);
        from = range[2];
        till = range[3];
      }

      var query = {
        symbol: oandaSymbol,
        from: from,
        till: till,
      };

      return lbRows.getInstrumentCalendar(query).$promise.then(function (promiseValue) {
        _fixMissingData(promiseValue.calendar);
        return promiseValue.calendar.map(function (t) {
          return new ReleaseObject(t);
        });
      });
    }

    /**
     *
     * @return {any}
     */
    function getUserReleases() {
      return getReleases(undefined, undefined, undefined, undefined);
    }

    // function getReleases(from, till, priority, currency) {
    //   return getSomeReleases(from, till, priority, currency);
    // }

    /**
     * This function get release for selected time frame, priority and currency settings
     *
     * @param {Number} [from] - from timestamp in seconds
     * @param {Number} [till] - from timestamp in seconds
     * @param {Number} [priority] - greater or equal event priority [1,2,3]
     * @param {String[]} [currency] - list of currencies
     * @return {angular.IPromise<btRawRelease[]>}
     */
    function getReleases(from, till, priority, currency) {
      if (from === undefined || till === undefined) {
        var range = btDateService.getDayRange(8, 48);
        from = range[2];
        till = range[3];
      }

      var query = {
        data: {
          from: from,
          till: till,
          importance: priority,
          currency: currency,
          skip: 0,
          limit: 50,
        },
      };

      if (typeof priority === 'number') {
        query.data.importance = priority;
      }

      if (Array.isArray(currency)) {
        query.data.currency = currency;
      }

      return loadEvents([], query)
        .then(function (releases) {
          return _fixMissingData(releases);
        })
        .then(function (releases) {
          return _sortReleases(releases);
        });
    }

    /**
     *
     * @param {*} releases
     * @param {*} query
     * @return {any}
     */
    function loadEvents(releases, query) {
      return lbRows.getRowsOfEC(query).$promise.then(function (res) {
        releases = releases.concat(res.getRowsOfEC);

        if (res.getRowsOfEC.length !== 0) {
          query.data.skip += 50;
          return loadEvents(releases, query);
        } else {
          return releases;
        }
      });
    }

    /**
     *
     * @param {*} releases
     * @return {any}
     */
    function _fixMissingData(releases) {
      releases.forEach(function (release) {
        _setDefaultEventType(release);
        _setDefaultImage(release);

        if (btSettingsService.isLinkDataService()) {
          _fixRelatedInstruments(release);
        }
      });

      return releases;
    }

    /**
     *
     * @param {*} result
     * @return {any}
     */
    function _fixRelatedInstruments(result) {
      if (result.eventsInfo.relatedInstruments) {
        result.eventsInfo.relatedInstruments = result.eventsInfo.relatedInstruments.filter(function (i) {
          return ['OIL', 'West Texas Oil'].indexOf(i) === -1;
        });
      }

      return result;
    }

    /**
     *
     * @param {*} release
     */
    function _setDefaultEventType(release) {
      if (release.eventsInfo) {
        if (release.eventsInfo.eventType === undefined) {
          if (release.eventsInfo.name && release.eventsInfo.name.indexOf('Speaks') !== -1) {
            release.eventsInfo.eventType = 1;
            return;
          }

          if (release.previous === 'NA' && release.actual === 'NA') {
            release.eventsInfo.eventType = 1;
          } else {
            release.eventsInfo.eventType = 2;
          }
        }
      }
    }

    /**
     *
     * @param {*} release
     */
    function _setDefaultImage(release) {
      if (release.eventsInfo && release.eventsInfo.speaker === undefined) {
        if (release.eventsInfo.eventType === 1) {
          release.eventsInfo.speaker = btDefaultSpeechImage;
        }

        if (release.eventsInfo.eventType === 2) {
          release.eventsInfo.speaker = btDefaultReportImage;
        }
      }
    }

    /**
     *
     * @param {*} releases
     * @return {any}
     */
    function _sortReleases(releases) {
      releases.sort(function (a, b) {
        if (a.time !== b.time) {
          return a.time < b.time ? -1 : 1;
        } else {
          if (a.eventsInfo.importance !== b.eventsInfo.importance) {
            return a.eventsInfo.importance < b.eventsInfo.importance ? 1 : -1;
          } else {
            return a.eventsInfo.name > b.eventsInfo.name ? 1 : -1;
          }
        }
      });
      return releases;
    }

    /**
     *
     * @param {*} releases
     * @param {*} from
     * @param {*} till
     * @return {any}
     */
    function prepareReleases(releases, from, till) {
      return btRowProcessorService.prepareRowsArray(releases, from, till);
    }

    /**
     *
     * @param {*} release
     */
    function resetFilter(release) {
      btRowProcessorService.resetFilter(release);
    }

    /**
     *
     * @param {*} priority
     * @param {*} currencies
     * @param {*} following
     * @param {*} release
     * @return {any}
     */
    function regularFilter(priority, currencies, following, release) {
      return btRowProcessorService.regularFilter(priority, currencies, following, release);
    }

    /**
     *
     * @param {*} watchlist
     * @param {*} release
     * @return {any}
     */
    function watchlistFilter(watchlist, release) {
      return btRowProcessorService.watchlistFilter(watchlist, release);
    }

    /**
     *
     * @param {*} item
     * @param {*} pRelease
     */
    function handleRows(item, pRelease) {
      btRowProcessorService.handleRows(item, pRelease);
    }

    /**
     *
     * @param {*} item
     * @param {*} pRelease
     * @return {any}
     */
    function hasNewActual(item, pRelease) {
      return btRowProcessorService.hasNewActual(item, pRelease);
    }

    /**
     *
     * @param {*} item
     * @param {*} pRelease
     * @return {any}
     */
    function hasNewRevision(item, pRelease) {
      return btRowProcessorService.hasNewRevision(item, pRelease);
    }

    /**
     * This function return object which can identify release for state manager
     * @param {btRelease} release - (const) release data
     * @return {btReleaseIdData} release identification data
     */
    function getReleaseStateParams(release) {
      return btRowProcessorService.getReleaseIdData(release, release.eventsInfo);
    }

    /**
     *
     * @param {Object} stateParams - state parameters
     * @return {angular.IPromise<*>}
     */
    function getReleaseByStateParams(stateParams) {
      var requestData = btRowProcessorService.releaseIdDataToRequest(stateParams);

      return lbRows.getRow({ data: requestData }).$promise.then(function (data) {
        // HotFix compatibility with app version
        if (data.getRow.length === 1 && data.getRow[0] !== null && data.getRow[0].error === undefined) {
          return data.getRow[0];
        } else {
          console.log('Error', data.getRow);
          return $q.reject(new Error("Can't get release data"));
        }
      });
    }

    /**
     *
     * @param {String|Number} id - state parameters
     * @return {angular.IPromise<*>}
     */
    function getReleaseById(id) {
      return lbRows.find({ filter: { where: { _id: id } } }).$promise.then(function (data) {
        // HotFix compatibility with app version
        if (data.length === 1 && data[0] !== null) {
          data[0].eventsInfo = btEventsService.getEventByIdFromCache(data[0].eventId);
          return data[0];
        } else {
          console.error('Error', data);
          return $q.reject(new Error("Can't get release data"));
        }
      });
    }

    /**
     * This function returns all releases in last 3 years.
     *
     * @param {number} id - event identifier
     * @return {angular.IPromise<*>}
     */
    function getStrengthStatistics(id) {
      var now = btDateService.getNowDate(); // now
      var from = btDateService.nYearsAgo(now, 3); // 3 years back
      var timestamp = now.getTime();
      var query = {
        filter: {
          where: { eventId: id, time: { gt: Math.floor(from.getTime() / 1000) } },
          limit: 200,
        },
      };

      return lbRows.find(query).$promise.then(function (releases) {
        var stats = {
          any: { name: 'Not Available', amount: 0, class: '' },
          'extremely-stronger': { name: 'Extremely Stronger', amount: 0, class: 'positive' },
          'much-stronger': { name: 'Much Stronger', amount: 0, class: 'positive' },
          stronger: { name: 'Stronger', amount: 0, class: 'positive' },
          'a-bit-stronger': { name: 'A bit Stronger', amount: 0, class: 'positive' },
          'as-expected': { name: 'As Expected', amount: 0, class: 'neutral' },
          'a-bit-weaker': { name: 'A bit Weaker', amount: 0, class: 'negative' },
          weaker: { name: 'Weaker', amount: 0, class: 'negative' },
          'much-weaker': { name: 'Much Weaker', amount: 0, class: 'negative' },
          'extremely-weaker': { name: 'Extremely Weaker', amount: 0, class: 'negative' },
        };

        if (releases.length) {
          releases.forEach(function (release) {
            var strength = release.releaseStrength ? release.releaseStrength.value : null;
            var mgn = btRowProcessorService.prepareStrength(strength, timestamp).magnitude;
            if (stats[mgn] === undefined) stats['any'].amount++;
            else stats[mgn].amount++;
          });
        } else {
          console.log(new Error("Can't load strength statistics."));
        }

        return [releases.length, stats];
      });
    }

    /**
     *
     * @param {*} strength
     * @param {*} time
     * @return {any}
     */
    function prepareStrength(strength, time) {
      return btRowProcessorService.prepareStrength(strength, time);
    }

    /**
     *
     * @param {*} type
     * @param {*} release
     * @param {*} number
     * @param {*} magnitude
     * @return {any}
     */
    function getReminderText(type, release, number, magnitude) {
      number = number || '3';

      var cases = {
        regular: {
          0: 'Just released.',
          1: 'Will be released in about a minute. ' + getExpectedText(release),
          3:
            'Will be released in about 3 minutes. ' +
            'There ' +
            (number > 1 ? 'are' : 'is a') +
            ' potential trade idea' +
            (number > 1 ? 's' : '') +
            ' for ',
          5: 'Will be released in about 5 minutes.',
        },
        speech: {
          0: 'Will speak now.',
          1: 'Will speak in about a minute.',
          3:
            'Will be released in about 3 minutes. ' +
            'There ' +
            (number > 1 ? 'are' : 'is a') +
            ' potential trade idea' +
            (number > 1 ? 's' : '') +
            ' for ',
          5: 'Will speak in about 5 minutes.',
        },
        report: {
          0: 'Will be released now.',
          1: 'Will be released in about a minute.',
          3:
            'Will be released in about 3 minutes. ' +
            'There ' +
            (number > 1 ? 'are' : 'is a') +
            ' potential trade idea' +
            (number > 1 ? 's' : '') +
            ' for ',
          5: 'Will be released in about 5 minutes.',
        },
        default: {
          0: 'Will be released now.',
          1: 'Will be released in about a minute.',
          3:
            'Will be released in about 3 minutes. ' +
            'There ' +
            (number > 1 ? 'are' : 'is a') +
            ' potential trade idea' +
            (number > 1 ? 's' : '') +
            ' for ',
          5: 'Will be released in about 5 minutes.',
        },
      };

      if (release.eventsInfo && release.eventsInfo.eventType === 0) {
        if (type === 0) {
          return magnitude ? magnitude + '.' : cases['regular'][type];
        } else {
          return cases['regular'][type];
        }
      } else if (release.eventsInfo && release.eventsInfo.eventType === 1) {
        return cases['speech'][type];
      } else if (release.eventsInfo && release.eventsInfo.eventType === 2) {
        return cases['report'][type];
      } else {
        return cases['default'][type];
      }
    }

    /**
     *
     * @param {*} type
     * @param {*} release
     * @param {*} number
     * @param {*} magnitude
     * @return {any}
     */
    function getReminderSpeech(type, release, number, magnitude) {
      number = number || '3';

      var cases = {
        regular: {
          0: '',
          1: 'will be released in about a minute. ' + getExpectedText(release),
          3:
            'will be released in about 3 minutes. ' +
            'There ' +
            (number > 1 ? 'are' : 'is a') +
            ' potential trade idea' +
            (number > 1 ? 's' : '') +
            ' for ',
          5: 'will be released in about 5 minutes.',
        },
        speech: {
          0: 'will speak now.',
          1: 'will speak in about a minute.',
          3:
            'will be released in about 3 minutes. ' +
            'There ' +
            (number > 1 ? 'are' : 'is a') +
            ' potential trade idea' +
            (number > 1 ? 's' : '') +
            ' for ',
          5: 'will speak in about 5 minutes.',
        },
        report: {
          0: 'will be released now.',
          1: 'will be released in about a minute.',
          3:
            'will be released in about 3 minutes. ' +
            'There ' +
            (number > 1 ? 'are' : 'is a') +
            ' potential trade idea' +
            (number > 1 ? 's' : '') +
            ' for ',
          5: 'will be released in about 5 minutes.',
        },
        default: {
          0: 'will be released now.',
          1: 'will be released in about a minute.',
          3:
            'will be released in about 3 minutes. ' +
            'There ' +
            (number > 1 ? 'are' : 'is a') +
            ' potential trade idea' +
            (number > 1 ? 's' : '') +
            ' for ',
          5: 'will be released in about 5 minutes.',
        },
      };

      if (release.eventsInfo && release.eventsInfo.eventType === 0) {
        if (type === 0) {
          return (
            (magnitude ? magnitude : 'Just released') +
            ' - ' +
            btEventsService.getPronunciation(release.eventsInfo) +
            '.'
          );
        } else {
          return btEventsService.getPronunciation(release.eventsInfo) + ' ' + cases['regular'][type];
        }
      } else if (release.eventsInfo && release.eventsInfo.eventType === 1) {
        return btEventsService.getPronunciation(release.eventsInfo) + ' ' + cases['speech'][type];
      } else if (release.eventsInfo && release.eventsInfo.eventType === 2) {
        return btEventsService.getPronunciation(release.eventsInfo) + ' ' + cases['report'][type];
      } else {
        return btEventsService.getPronunciation(release.eventsInfo) + ' ' + cases['default'][type];
      }
    }

    /**
     *
     * @param {*} data
     * @param {*} release
     * @return {any}
     */
    function getActualVersusExpectedText(data, release) {
      var text = '';
      if (data.actual && data.actual !== 'NA') {
        text = ' Actual is ' + data.actual;
      }
      if (release.forecast && release.forecast !== 'NA' && release.forecast !== 'Usually No Exp') {
        text += ' versus ' + release.forecast + '.';
      } else {
        text += '.';
      }

      return text;
    }

    /**
     *
     * @param {*} data
     * @param {*} release
     * @return {any}
     */
    function getActualVersusExpectedSpeech(data, release) {
      var text = '';
      if (data.actual && data.actual !== 'NA') {
        text = ' Actual is ' + speechValue(data.actual);
      }
      if (release.forecast && release.forecast !== 'NA' && release.forecast !== 'Usually No Exp') {
        text += ' versus ' + speechValue(release.forecast) + '.';
      } else {
        text += '.';
      }

      return text;
    }

    /**
     *
     * @param {*} release
     * @return {any}
     */
    function getExpectedText(release) {
      if (release.forecast && release.forecast !== 'NA' && release.forecast !== 'Usually No Exp') {
        return 'The expectation is ' + release.forecast + '.';
      } else if (release.eventsInfo && release.eventsInfo.usuallyNoForecast) {
        return 'Usually no expected value.';
      } else {
        return 'No expectation currently available.';
      }
    }

    /**
     *
     * @param {*} release
     * @return {any}
     */
    function getExpectedSpeech(release) {
      if (release.forecast && release.forecast !== 'NA' && release.forecast !== 'Usually No Exp') {
        return 'The expectation is ' + speechValue(release.forecast) + '.';
      } else if (release.eventsInfo && release.eventsInfo.usuallyNoForecast) {
        return 'Usually no expected value.';
      } else {
        return 'No expectation currently available.';
      }
    }

    /**
     *
     * @param {*} value
     * @return {any}
     */
    function speechValue(value) {
      return value
        .replace(/K/, ' thousands')
        .replace(/M/, ' millions')
        .replace(/B/, ' billions')
        .replace(/-/, 'minus ');
    }

    /**
     *
     * @param {*} release
     * @return {any}
     */
    function getPotentialTradeIdeasSymbols(release) {
      if (release.expectedTradingInsights) {
        return release.expectedTradingInsights.map(function (item) {
          return item.market;
        });
      } else {
        return [];
      }
    }
  }
})();
