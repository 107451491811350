/**
 * Created by Sergey on 3/29/20.
 */

(function btLineSetting() {
  'use strict';

  angular.module('ecapp').directive('btLine', directive);

  directive.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function directive($templateCache) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        size: '@',
      },
      template: $templateCache.get('directives/common/bt-line.html'),
      controller: controller,
    };
  }

  controller.$inject = ['$scope'];

  /**
   *
   * @param {*} $scope
   */
  function controller($scope) {
    $scope.lineStyle = {
      width: $scope.size + '%',
    };
  }
})();
