/**
 * Created by Sergey Panpurin on 9/2/19.
 */

// @ts-check
(function btRiskChartServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btRiskChartService:';

  /**
   * This service ...
   *
   * @ngdoc service
   * @name ecapp.btRiskChartService
   */

  angular.module('ecapp').factory('btRiskChartService', service);

  service.$inject = [];

  /**
   *
   * @return {ecapp.IRiskChartService}
   */
  function service() {
    if (gDebug) console.log(gPrefix, 'running...');

    var COLORS = {
      'card-background-color': '#1D2026',
      'risk-index-marker': '#eef316',
      positive: '#48C064',
      negative: '#CF3040',
      'gray-1': '#a3a9ac',
      'gray-2': '#6d6e71',
      'gray-3': '#cbcbcb',
      'gray-4': '#484848',
      white: '#ffffff',
    };

    return {
      getRiskChart: getRiskChart,
      getIndicatorsChart: getIndicatorsChart,
      getHistoricalChart: getHistoricalChart,
      getRealtimePreviewChart: getRealtimePreviewChart,
    };

    /**
     *
     * @alias ecapp.btRiskChartService#getRiskChart
     * @param {ecapp.IRiskBasket} basket
     * @param {ecapp.IRiskInterval} interval
     * @return {ext.IZingChartJSON}
     */
    function getRiskChart(basket, interval) {
      return {
        gui: {
          contextMenu: {
            empty: true,
          },
        },
        graphset: [
          getPreviewChart(basket, interval),
          {
            utc: false,
            type: 'line',
            theme: 'classic',
            height: '100%',
            width: '2px',
            x: '50%',
            y: '0px',
            alpha: 1,
            backgroundColor: COLORS['card-background-color'],
            plotarea: {
              borderLeft: '2px solid #000000',
              margin: '12px 0 12px 0',
            },
          },
          getGaugeChart(basket),
        ],
      };
    }

    /**
     *
     * @private
     * @param {ecapp.IRiskBasket} basket
     * @return {ext.IZingChartJSON}
     */
    function getGaugeChart(basket) {
      return {
        utc: false,
        type: 'gauge',
        theme: 'classic',
        height: '100%',
        width: '50%',
        x: '50%',
        y: '0px',
        alpha: 1,
        // backgroundColor: COLORS['negative'],
        backgroundColor: COLORS['card-background-color'],
        plot: {
          backgroundColor: COLORS['gray-1'],
          // csize: '5%',
          size: '100%',
        },
        tooltip: {
          visible: false,
        },
        plotarea: {
          // backgroundColor: COLORS['gray-1'],
          margin: '20px 14% 0 14%',
        },
        scale: {
          // sizeFactor: 1
        },
        scaleR: {
          aperture: 210,
          values: '-100:100:50',
          // eslint-disable-next-line no-irregular-whitespace
          // ! There is a zero width space ("​") between + and number.
          labels: ['-100', '-50', '0', '+​50', '+​100'],
          tick: {
            alpha: 0,
          },
          guide: {
            visible: false,
          },
          center: {
            visible: false,
          },
          item: {
            fontColor: COLORS['white'],
            fontFamily: 'Roboto Condensed Regular',
            offsetR: -6,
            fontSize: 10,
            visible: true,
          },
          ring: {
            // offsetR: '130px',
            rules: [
              {
                backgroundColor: COLORS['negative'],
                rule: '%v < 0',
              },
              {
                backgroundColor: COLORS['positive'],
                rule: '%v >= 0',
              },
            ],
          },
        },
        labels: [
          {
            id: 'lbl1',
            text: 'Risk On',
            fontSize: '11px',
            color: COLORS['positive'],
            tooltip: {
              visible: false,
            },
            // anchor: 'c',
            backgroundColor: 'none',
            wrapText: true,
            fontFamily: 'Roboto Condensed Regular',
            offsetX: '-28px',
            textAlign: 'center',
            width: '28px',
            height: '22px',
            x: '100%',
            y: '40%',
          },
          {
            id: 'lbl5',
            text: 'Risk Off',
            fontSize: '11px',
            color: COLORS['negative'],
            tooltip: {
              visible: false,
            },
            // anchor: 'c',
            backgroundColor: 'none',
            wrapText: true,
            fontFamily: 'Roboto Condensed Regular',
            offsetX: '0px',
            textAlign: 'center',
            width: '28px',
            height: '22px',
            x: '0%',
            y: '40%',
          },
        ],
        series: [
          {
            values: [basket.spread ? basket.spread[0] : 0],
            backgroundColor: COLORS['gray-3'],
            indicator: [1, 1, 0, 0, 0.75],
          },
          {
            values: [basket.spread ? basket.spread[1] : 0],
            backgroundColor: COLORS['gray-3'],
            indicator: [1, 1, 0, 0, 0.75],
          },
          {
            values: [basket.magnitude],
            indicator: [4, 0, 0, 0, 0],
          },
        ],
      };
    }

    /**
     *
     * @private
     * @param {ecapp.IRiskBasket} basket
     * @param {ecapp.IRiskInterval} interval
     * @return {ext.IZingChartJSON}
     */
    function getPreviewChart(basket, interval) {
      var values = getImprovedBasketPreview(basket, interval);
      return {
        utc: false,
        type: 'line',
        // backgroundColor: COLORS['positive'],
        backgroundColor: COLORS['card-background-color'],
        height: '100%',
        width: '51%',
        x: '0px',
        y: '0px',
        plot: {
          lineWidth: '2px',
          marker: {
            size: '1px',
            visible: false,
          },
        },
        tooltip: {
          visible: false,
        },
        plotarea: {
          // border: '1px solid white',
          // backgroundColor: COLORS['gray-1'],
          margin: '5% 5% 10% 33%',
          height: '90%',
        },
        labels: [
          {
            text: Math.round(basket.magnitude) > 0 ? '+' + Math.round(basket.magnitude) : Math.round(basket.magnitude),
            fontColor: Math.round(basket.magnitude) > 0 ? COLORS['positive'] : COLORS['negative'],
            textAlign: 'center',
            fontSize: '26px',
            width: '33%',
            x: '0',
            y: '26%',
          },
          {
            text: 'SD: ' + Math.round(basket.deviation),
            fontColor: '#696969',
            textAlign: 'center',
            fontSize: '14px',
            width: '33%',
            x: '0',
            y: '52%',
          },
          {
            text: interval.preview.title,
            textAlign: 'center',
            fontColor: COLORS['gray-2'],
            fontFamily: 'Roboto Condensed Regular',
            width: '62%',
            x: '33%',
            y: '65%',
          },
        ],
        scaleX: {
          values: values.map(function (value) {
            return value.time;
          }),
          visible: false,
          tick: {
            visible: false,
          },
        },
        scaleY: {
          refLine: {
            alpha: 0.6,
            lineColor: COLORS['gray-3'],
            lineWidth: '1px',
          },
          visible: true,
          tick: {
            visible: false,
          },
          lineColor: 'none',
          values: '-100:100:25',
          guide: {
            visible: false,
          },
          item: {
            visible: false,
          },
        },
        crosshairX: {
          visible: false,
        },
        series: [
          {
            values: values.map(function (value) {
              return Math.round(value.mgn * 100) / 100;
            }),
            aspect: 'spline',
            backgroundColor: COLORS['card-background-color'],
            rules: [
              {
                rule: '%v == 0',
                lineColor: 'transparent',
              },
              {
                rule: '%v < 0',
                lineColor: COLORS['negative'],
              },
              {
                rule: '%v > 0',
                lineColor: COLORS['positive'],
              },
            ],
            lineWidth: '1px',
          },
        ],
      };
    }

    /**
     *
     * @private
     * @param {ecapp.IRiskBasket} basket
     * @param {ecapp.IRiskInterval} interval
     * @return {ecapp.IRiskRecord[]}
     */
    function getImprovedBasketPreview(basket, interval) {
      return addZeros(basket.history.slice(-interval.preview.size));
    }

    /**
     *
     * @alias ecapp.btRiskChartService#getIndicatorsChart
     * @param {ecapp.IRiskBasket} basket
     * @return {ext.IZingChartJSON}
     */
    function getIndicatorsChart(basket) {
      return {
        gui: {
          contextMenu: {
            empty: true,
          },
        },
        graphset: [
          {
            utc: false,
            type: 'hbar',
            backgroundColor: COLORS['card-background-color'],
            tooltip: {
              visible: window.isDesktop,
            },
            plot: {
              valueBox: {
                backgroundColor: COLORS['card-background-color'],
                borderColor: COLORS['card-background-color'],
                fontColor: COLORS['gray-3'],
                fontFamily: 'Roboto Condensed Regular',
              },
              barMaxWidth: '5px',
              // animation: {
              //   delay: 550,
              //   effect: 'ANIMATION_EXPAND_VERTICAL',
              //   method: 'ANIMATION_BOUNCE_EASE_OUT',
              //   speed: 800
              // },
              stacked: true,
            },
            plotarea: {
              // adjustLayout: true,
              margin: '4px 15px 39px 16px',
              backgroundColor: COLORS['card-background-color'],
              fontColor: COLORS['white'],
            },
            scaleX: {
              alpha: 1,
              labels: ['', '', '', ''],
              lineColor: '#696969',
            },
            scaleY: {
              // maxLabels: 5,
              values: '-100:100:25',
              // eslint-disable-next-line no-irregular-whitespace
              // ! There is a zero width space ("​") between + and number.
              labels: ['-100', '-75', '-50', '-25', '0', '+ 25', '+​50', '+ 75', '+​100'],
              item: {
                fontColor: COLORS['white'],
                fontSize: 11,
              },
              guide: {
                lineStyle: 'dashed',
                lineColor: '#696969',
              },
              refLine: {
                lineColor: '#696969',
              },
              lineColor: '#696969',
            },
            scaleY2: {},
            series: basket.indicators.map(function (indicator, i) {
              var n = basket.indicators.length;
              return {
                values: [indicator.magnitude],
                valueBox: {
                  text: '%data-custom-token',
                  placement: 'bottom',
                  fontColor: indicator.color,
                  padding: 0,
                  shadow: false,
                  offsetY: '-3px',
                },
                offsetY: '-3px',
                barSpace: n < 8 ? '10px' : '4px',
                backgroundColor: indicator.magnitude < 0 ? COLORS['negative'] : COLORS['positive'],
                dataCustomToken: [indicator.name.length < 7 ? indicator.name : indicator.short],
                decimals: 2,
                scales: 'scale-x,scale-y',
                stack: i + 1,
              };
            }),
          },
        ],
      };
    }

    /**
     *
     * @param {ecapp.IRiskRecord[]} results
     * @return {{time: number, mgn: number, dev: number}[]}
     */
    function addZeros(results) {
      var modified = [];
      results.reduce(function (previous, current) {
        modified.push(previous);
        if (previous.mgn > 0 && current.mgn < 0) {
          // var delta1 = previous.mgn * (current.time - previous.time) / (previous.mgn - current.mgn);
          var delta1 = (current.time - previous.time) / 2;
          modified.push({ time: Math.round(previous.time + delta1), mgn: 0.1, dev: previous.dev });
        }

        if (previous.mgn < 0 && current.mgn > 0) {
          // var delta2 = previous.mgn * (current.time - previous.time) / (previous.mgn - current.mgn);
          var delta2 = (current.time - previous.time) / 2;
          modified.push({ time: Math.round(previous.time + delta2), mgn: -0.1, dev: previous.dev });
        }

        return current;
      }, results[0]);

      if (results.length) {
        var last = results[results.length - 1];
        modified.push({ time: last.time, mgn: last.mgn, dev: last.dev });
      }

      return modified;
    }

    /**
     *
     * @alias ecapp.btRiskChartService#getHistoricalChart
     * @param {ecapp.IRiskBasket} basket
     * @param {ecapp.IRiskInterval} interval
     * @return {ext.IZingChartJSON}
     */
    function getHistoricalChart(basket, interval) {
      return getHistChartTemplate(
        basket,
        interval,
        getHistoricalXValues,
        getHistoricalLegends,
        getHistoricalIndValues,
        getHistoricalDeviations,
        getHistoricalAverages
      );
    }

    /**
     *
     * @alias ecapp.btRiskChartService#getRealtimePreviewChart
     * @param {ecapp.IRiskBasket} basket -
     * @param {ecapp.IRiskInterval} interval -
     * @return {ext.IZingChartJSON}
     */
    function getRealtimePreviewChart(basket, interval) {
      return getHistChartTemplate(
        basket,
        interval,
        getRealtimePreviewXValues,
        getRealtimePreviewLegends,
        getRealtimePreviewIndValues,
        getRealtimePreviewDeviations,
        getRealtimePreviewAverages
      );
    }

    /**
     * @callback getXValuesCallback
     * @param {ecapp.IRiskBasket} basket
     * @param {ecapp.IRiskInterval} interval
     * @return {number[]}
     */

    /**
     * @callback getLegendsCallback
     * @param {ecapp.IRiskBasket} basket
     * @param {ecapp.IRiskInterval} interval
     * @return {Object}
     */

    /**
     * @callback getIndValuesCallback
     * @param {ecapp.IRiskIndicator} indicator
     * @param {ecapp.IRiskInterval} interval
     * @return {number[]}
     */

    /**
     * @callback getBskDeviationsCallback
     * @param {ecapp.IRiskBasket} basket
     * @param {ecapp.IRiskInterval} interval
     * @return {number[]}
     */

    /**
     * @callback getBskAveragesCallback
     * @param {ecapp.IRiskBasket} basket
     * @param {ecapp.IRiskInterval} interval
     * @return {number[]}
     */

    /**
     *
     * @alias ecapp.btRiskChartService#getHistoricalChart
     * @param {ecapp.IRiskBasket} basket
     * @param {ecapp.IRiskInterval} interval
     * @param {getXValuesCallback} getXValues
     * @param {getLegendsCallback} getLegends
     * @param {getIndValuesCallback} getIndValues
     * @param {getBskDeviationsCallback} getBskDeviations
     * @param {getBskAveragesCallback} getBskAverages
     * @return {ext.IZingChartJSON}
     */
    function getHistChartTemplate(
      basket,
      interval,
      getXValues,
      getLegends,
      getIndValues,
      getBskDeviations,
      getBskAverages
    ) {
      return {
        gui: {
          contextMenu: {
            empty: true,
          },
        },
        graphset: [
          {
            utc: false,
            type: 'line',
            backgroundColor: COLORS['card-background-color'],
            tooltip: {
              visible: false,
            },
            plot: {
              lineWidth: '2px',
              marker: {
                size: '1px',
                visible: false,
              },
            },
            plotarea: {
              margin: '10px 16px 65px 36px',
            },
            scaleX: {
              item: {
                fontColor: COLORS['white'],
                textAlign: 'center',
                fontSize: 11,
              },
              lineColor: '#696969',
              itemsOverlap: true,
              values: getXValues(basket, interval),
              transform: {
                type: 'date',
                all: interval.format.scale,
              },
              maxItems: 6,
              maxLabels: 6,
              tick: {
                lineWidth: '2px',
                lineColor: '#696969',
              },
              zooming: false,
            },
            scaleY: {
              values: '-75:75:25',
              // eslint-disable-next-line no-irregular-whitespace
              // ! There is a zero width space ("​") between + and number.
              labels: ['-75', '-50', '-25', '0', '+ 25', '+​50', '+​75'],
              markers: [
                {
                  type: 'line',
                  range: [-75],
                  valueRange: true,
                  lineColor: '#696969',
                  lineWidth: 1,
                  lineStyle: 'dashed',
                  alpha: 0.6,
                },
                {
                  type: 'line',
                  range: [-50],
                  valueRange: true,
                  lineColor: '#696969',
                  lineWidth: 1,
                  lineStyle: 'dashed',
                  alpha: 0.6,
                },
                {
                  type: 'line',
                  range: [-25],
                  valueRange: true,
                  lineColor: '#696969',
                  lineWidth: 1,
                  lineStyle: 'dashed',
                  alpha: 0.6,
                },
                {
                  type: 'line',
                  range: [+25],
                  valueRange: true,
                  lineColor: '#696969',
                  lineWidth: 1,
                  lineStyle: 'dashed',
                  alpha: 0.6,
                },
                {
                  type: 'line',
                  range: [+50],
                  valueRange: true,
                  lineColor: '#696969',
                  lineWidth: 1,
                  lineStyle: 'dashed',
                  alpha: 0.6,
                },
                {
                  type: 'line',
                  range: [+75],
                  valueRange: true,
                  lineColor: '#696969',
                  lineWidth: 1,
                  lineStyle: 'dashed',
                  alpha: 0.6,
                },
              ],
              guide: {
                alpha: 0.2,
                lineWidth: '0px',
              },
              item: {
                fontColor: COLORS['white'],
                fontWeight: 'normal',
                fontSize: 11,
              },
              lineColor: '#696969',
            },
            crosshairX: {
              visible: window.isDesktop,
              lineColor: COLORS['white'],
              lineStyle: 'dashed',
              lineWidth: '1px',
              marker: {
                size: '4px',
                visible: true,
              },
              plotLabel: {
                visible: true,
              },
              scaleLabel: {
                text: '%v',
                transform: {
                  type: 'date',
                  all: interval.format.crosshair,
                },
              },
            },
            legend: getLegends(basket, interval),
            series: getHistChartSeries(basket, interval, '', getIndValues, getBskDeviations, getBskAverages),
          },
        ],
      };
    }

    /**
     *
     * @private
     * @param {ecapp.IRiskBasket} basket -
     * @param {ecapp.IRiskInterval} interval -
     * @param {string} prefix -
     * @param {getIndValuesCallback} getIndValues -
     * @param {getBskDeviationsCallback} getDeviations -
     * @param {getBskAveragesCallback} getAverages -
     * @return {any[]}
     */
    function getHistChartSeries(basket, interval, prefix, getIndValues, getDeviations, getAverages) {
      /** @type {any[]} */
      var series = basket.indicators.map(function (indicator, i) {
        return {
          text: indicator.name.length < 7 ? indicator.name : indicator.short,
          values: getIndValues(indicator, interval),
          legendMarker: {
            type: 'circle',
            backgroundColor: indicator.color,
            size: '3px',
          },
          visible: checkIsChartVisible(prefix, i),
          alphaArea: '.3',
          aspect: 'spline',
          backgroundColor: COLORS['card-background-color'],
          fontFamily: 'Roboto',
          fontSize: '13px',
          lineColor: indicator.color,
          lineStyle: 'dashed',
          lineWidth: '1.3px',
        };
      });

      series.push({
        text: 'R-Dev',
        id: 'rDev',
        values: getDeviations(basket, interval),
        legendMarker: {
          type: 'circle',
          backgroundColor: '#696969',
          size: '3px',
        },
        visible: checkIsChartVisible(prefix, 'rDev'),
        alphaArea: '.3',
        aspect: 'spline',
        backgroundColor: COLORS['card-background-color'],
        lineColor: '#696969',
        fontFamily: 'Roboto',
        fontSize: '13px',
        lineWidth: '2px',
      });

      series.push({
        text: 'R-Ind',
        id: 'rInd',
        values: getAverages(basket, interval),
        legendMarker: {
          type: 'circle',
          backgroundColor: COLORS['risk-index-marker'],
          size: '3px',
        },
        visible: checkIsChartVisible(prefix, 'rInd'),
        alphaArea: '.3',
        aspect: 'spline',
        backgroundColor: COLORS['card-background-color'],
        fontFamily: 'Roboto',
        fontSize: '13px',
        rules: [
          {
            rule: '%v < 0',
            lineColor: COLORS['negative'],
          },
          {
            rule: '%v >= 0',
            lineColor: COLORS['positive'],
          },
        ],
        lineWidth: '2px',
      });

      return series;
    }

    /**
     *
     * @param {string} prefix - chart prefix
     * @param {string|number} id - plot identifier or index
     * @return {boolean}
     */
    function checkIsChartVisible(prefix, id) {
      if (prefix === 'realtime') prefix = 'zingchart_plot_rt_';
      else prefix = 'zingchart_plot_';

      var visible = localStorage.getItem(prefix + id + '_visibility');
      if (visible === null) {
        return id === 'rInd' || id === 'rDev' ? true : id === 0;
      } else {
        return visible === 'true';
      }
    }

    // Historical
    /**
     *
     * @private
     * @param {ecapp.IRiskBasket} basket -
     * @param {ecapp.IRiskInterval} interval -
     * @return {number[]}
     */
    function getHistoricalXValues(basket, interval) {
      if (basket.indicators[0]) {
        return getActualHistory(basket.indicators[0].history, interval).map(function (item) {
          return item.time;
        });
      } else {
        return [];
      }
    }

    /**
     *
     * @private
     * @param {ecapp.IRiskBasket} basket -
     * @param {ecapp.IRiskInterval} interval -
     * @return {ext.IZingChartLegend}
     */
    function getHistoricalLegends(basket, interval) {
      void interval;
      var n = basket.indicators.length;
      var m = 6;
      return {
        align: 'center',
        backgroundColor: 'none',
        borderWidth: '0',
        borderColor: 'none',
        margin: '0',
        padding: '0',
        width: '100%',
        item: {
          fontColor: COLORS['white'],
          fontSize: n > m ? '10px' : '11px',
          offsetX: n > m ? '-2px' : '-5px',
          padding: '0',
          margin: '0',
        },
        layout: 'float',
        offsetY: '1px',
        shadow: false,
        textAlign: 'middle',
      };
    }

    /**
     *
     * @private
     * @param {ecapp.IRiskIndicator} indicator -
     * @param {ecapp.IRiskInterval} interval -
     * @return {number[]}
     */
    function getHistoricalIndValues(indicator, interval) {
      void interval;
      return getActualHistory(indicator.history, interval).map(function (item) {
        return Math.round(item.mgn * 100) / 100;
      });
    }

    /**
     *
     * @private
     * @param {ecapp.IRiskBasket} basket -
     * @param {ecapp.IRiskInterval} interval -
     * @return {number[]}
     */
    function getHistoricalDeviations(basket, interval) {
      void interval;
      return getActualHistory(basket.history, interval).map(function (value) {
        return Math.round(value.dev * 100) / 100;
      });
    }

    /**
     *
     * @private
     * @param {ecapp.IRiskBasket} basket -
     * @param {ecapp.IRiskInterval} interval -
     * @return {number[]}
     */
    function getHistoricalAverages(basket, interval) {
      void interval;
      return getActualHistory(basket.history, interval).map(function (value) {
        return Math.round(value.mgn * 100) / 100;
      });
    }

    // Realtime

    /**
     *
     * @private
     * @param {ecapp.IRiskBasket} basket -
     * @param {ecapp.IRiskInterval} interval -
     * @return {number[]}
     */
    function getRealtimePreviewXValues(basket, interval) {
      void interval;
      return basket.realtime.map(function (item) {
        return item.time;
      });
    }

    /**
     *
     * @private
     * @param {ecapp.IRiskBasket} basket -
     * @param {ecapp.IRiskInterval} interval -
     * @return {Object}
     */
    function getRealtimePreviewLegends(basket, interval) {
      void interval;
      var n = basket.indicators.length;
      var m = 6;
      return {
        align: 'center',
        backgroundColor: 'none',
        borderWidth: '0',
        borderColor: 'none',
        margin: '0',
        padding: '0',
        width: '100%',
        item: {
          fontColor: COLORS['white'],
          fontSize: n > m ? '10px' : '11px',
          offsetX: n > m ? '-2px' : '-5px',
          padding: '0',
          margin: '0',
        },
        layout: 'float',
        offsetY: '1px',
        shadow: false,
        textAlign: 'middle',
      };
    }

    /**
     *
     * @private
     * @param {ecapp.IRiskIndicator} indicator -
     * @param {ecapp.IRiskInterval} interval -
     * @return {number[]}
     */
    function getRealtimePreviewIndValues(indicator, interval) {
      return indicator.realtime.map(function (item) {
        return Math.round(item.magnitudes[interval.granularity] * 100) / 100;
      });
    }

    /**
     *
     * @private
     * @param {ecapp.IRiskBasket} basket -
     * @param {ecapp.IRiskInterval} interval -
     * @return {number[]}
     */
    function getRealtimePreviewDeviations(basket, interval) {
      return basket.realtime.map(function (value) {
        return Math.round(value.deviations[interval.granularity] * 100) / 100;
      });
    }

    /**
     *
     * @private
     * @param {ecapp.IRiskBasket} basket -
     * @param {ecapp.IRiskInterval} interval -
     * @return {number[]}
     */
    function getRealtimePreviewAverages(basket, interval) {
      return basket.realtime.map(function (value) {
        return Math.round(value.magnitudes[interval.granularity] * 100) / 100;
      });
    }

    /**
     *
     * @param {Array} history - historical data
     * @param {ecapp.IRiskInterval} interval - interval
     * @return {Array}
     */
    function getActualHistory(history, interval) {
      return history.slice(-(interval.size + 1));
    }
  }
})();
