(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.TradestationApi = f()}})(function(){var define,module,exports;return (function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict'

exports.byteLength = byteLength
exports.toByteArray = toByteArray
exports.fromByteArray = fromByteArray

var lookup = []
var revLookup = []
var Arr = typeof Uint8Array !== 'undefined' ? Uint8Array : Array

var code = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
for (var i = 0, len = code.length; i < len; ++i) {
  lookup[i] = code[i]
  revLookup[code.charCodeAt(i)] = i
}

// Support decoding URL-safe base64 strings, as Node.js does.
// See: https://en.wikipedia.org/wiki/Base64#URL_applications
revLookup['-'.charCodeAt(0)] = 62
revLookup['_'.charCodeAt(0)] = 63

function getLens (b64) {
  var len = b64.length

  if (len % 4 > 0) {
    throw new Error('Invalid string. Length must be a multiple of 4')
  }

  // Trim off extra bytes after placeholder bytes are found
  // See: https://github.com/beatgammit/base64-js/issues/42
  var validLen = b64.indexOf('=')
  if (validLen === -1) validLen = len

  var placeHoldersLen = validLen === len
    ? 0
    : 4 - (validLen % 4)

  return [validLen, placeHoldersLen]
}

// base64 is 4/3 + up to two characters of the original data
function byteLength (b64) {
  var lens = getLens(b64)
  var validLen = lens[0]
  var placeHoldersLen = lens[1]
  return ((validLen + placeHoldersLen) * 3 / 4) - placeHoldersLen
}

function _byteLength (b64, validLen, placeHoldersLen) {
  return ((validLen + placeHoldersLen) * 3 / 4) - placeHoldersLen
}

function toByteArray (b64) {
  var tmp
  var lens = getLens(b64)
  var validLen = lens[0]
  var placeHoldersLen = lens[1]

  var arr = new Arr(_byteLength(b64, validLen, placeHoldersLen))

  var curByte = 0

  // if there are placeholders, only get up to the last complete 4 chars
  var len = placeHoldersLen > 0
    ? validLen - 4
    : validLen

  var i
  for (i = 0; i < len; i += 4) {
    tmp =
      (revLookup[b64.charCodeAt(i)] << 18) |
      (revLookup[b64.charCodeAt(i + 1)] << 12) |
      (revLookup[b64.charCodeAt(i + 2)] << 6) |
      revLookup[b64.charCodeAt(i + 3)]
    arr[curByte++] = (tmp >> 16) & 0xFF
    arr[curByte++] = (tmp >> 8) & 0xFF
    arr[curByte++] = tmp & 0xFF
  }

  if (placeHoldersLen === 2) {
    tmp =
      (revLookup[b64.charCodeAt(i)] << 2) |
      (revLookup[b64.charCodeAt(i + 1)] >> 4)
    arr[curByte++] = tmp & 0xFF
  }

  if (placeHoldersLen === 1) {
    tmp =
      (revLookup[b64.charCodeAt(i)] << 10) |
      (revLookup[b64.charCodeAt(i + 1)] << 4) |
      (revLookup[b64.charCodeAt(i + 2)] >> 2)
    arr[curByte++] = (tmp >> 8) & 0xFF
    arr[curByte++] = tmp & 0xFF
  }

  return arr
}

function tripletToBase64 (num) {
  return lookup[num >> 18 & 0x3F] +
    lookup[num >> 12 & 0x3F] +
    lookup[num >> 6 & 0x3F] +
    lookup[num & 0x3F]
}

function encodeChunk (uint8, start, end) {
  var tmp
  var output = []
  for (var i = start; i < end; i += 3) {
    tmp =
      ((uint8[i] << 16) & 0xFF0000) +
      ((uint8[i + 1] << 8) & 0xFF00) +
      (uint8[i + 2] & 0xFF)
    output.push(tripletToBase64(tmp))
  }
  return output.join('')
}

function fromByteArray (uint8) {
  var tmp
  var len = uint8.length
  var extraBytes = len % 3 // if we have 1 byte left, pad 2 bytes
  var parts = []
  var maxChunkLength = 16383 // must be multiple of 3

  // go through the array every three bytes, we'll deal with trailing stuff later
  for (var i = 0, len2 = len - extraBytes; i < len2; i += maxChunkLength) {
    parts.push(encodeChunk(uint8, i, (i + maxChunkLength) > len2 ? len2 : (i + maxChunkLength)))
  }

  // pad the end with zeros, but make sure to not forget the extra bytes
  if (extraBytes === 1) {
    tmp = uint8[len - 1]
    parts.push(
      lookup[tmp >> 2] +
      lookup[(tmp << 4) & 0x3F] +
      '=='
    )
  } else if (extraBytes === 2) {
    tmp = (uint8[len - 2] << 8) + uint8[len - 1]
    parts.push(
      lookup[tmp >> 10] +
      lookup[(tmp >> 4) & 0x3F] +
      lookup[(tmp << 2) & 0x3F] +
      '='
    )
  }

  return parts.join('')
}

},{}],2:[function(require,module,exports){
(function (Buffer){(function (){
/*!
 * The buffer module from node.js, for the browser.
 *
 * @author   Feross Aboukhadijeh <https://feross.org>
 * @license  MIT
 */
/* eslint-disable no-proto */

'use strict'

var base64 = require('base64-js')
var ieee754 = require('ieee754')

exports.Buffer = Buffer
exports.SlowBuffer = SlowBuffer
exports.INSPECT_MAX_BYTES = 50

var K_MAX_LENGTH = 0x7fffffff
exports.kMaxLength = K_MAX_LENGTH

/**
 * If `Buffer.TYPED_ARRAY_SUPPORT`:
 *   === true    Use Uint8Array implementation (fastest)
 *   === false   Print warning and recommend using `buffer` v4.x which has an Object
 *               implementation (most compatible, even IE6)
 *
 * Browsers that support typed arrays are IE 10+, Firefox 4+, Chrome 7+, Safari 5.1+,
 * Opera 11.6+, iOS 4.2+.
 *
 * We report that the browser does not support typed arrays if the are not subclassable
 * using __proto__. Firefox 4-29 lacks support for adding new properties to `Uint8Array`
 * (See: https://bugzilla.mozilla.org/show_bug.cgi?id=695438). IE 10 lacks support
 * for __proto__ and has a buggy typed array implementation.
 */
Buffer.TYPED_ARRAY_SUPPORT = typedArraySupport()

if (!Buffer.TYPED_ARRAY_SUPPORT && typeof console !== 'undefined' &&
    typeof console.error === 'function') {
  console.error(
    'This browser lacks typed array (Uint8Array) support which is required by ' +
    '`buffer` v5.x. Use `buffer` v4.x if you require old browser support.'
  )
}

function typedArraySupport () {
  // Can typed array instances can be augmented?
  try {
    var arr = new Uint8Array(1)
    arr.__proto__ = { __proto__: Uint8Array.prototype, foo: function () { return 42 } }
    return arr.foo() === 42
  } catch (e) {
    return false
  }
}

Object.defineProperty(Buffer.prototype, 'parent', {
  enumerable: true,
  get: function () {
    if (!Buffer.isBuffer(this)) return undefined
    return this.buffer
  }
})

Object.defineProperty(Buffer.prototype, 'offset', {
  enumerable: true,
  get: function () {
    if (!Buffer.isBuffer(this)) return undefined
    return this.byteOffset
  }
})

function createBuffer (length) {
  if (length > K_MAX_LENGTH) {
    throw new RangeError('The value "' + length + '" is invalid for option "size"')
  }
  // Return an augmented `Uint8Array` instance
  var buf = new Uint8Array(length)
  buf.__proto__ = Buffer.prototype
  return buf
}

/**
 * The Buffer constructor returns instances of `Uint8Array` that have their
 * prototype changed to `Buffer.prototype`. Furthermore, `Buffer` is a subclass of
 * `Uint8Array`, so the returned instances will have all the node `Buffer` methods
 * and the `Uint8Array` methods. Square bracket notation works as expected -- it
 * returns a single octet.
 *
 * The `Uint8Array` prototype remains unmodified.
 */

function Buffer (arg, encodingOrOffset, length) {
  // Common case.
  if (typeof arg === 'number') {
    if (typeof encodingOrOffset === 'string') {
      throw new TypeError(
        'The "string" argument must be of type string. Received type number'
      )
    }
    return allocUnsafe(arg)
  }
  return from(arg, encodingOrOffset, length)
}

// Fix subarray() in ES2016. See: https://github.com/feross/buffer/pull/97
if (typeof Symbol !== 'undefined' && Symbol.species != null &&
    Buffer[Symbol.species] === Buffer) {
  Object.defineProperty(Buffer, Symbol.species, {
    value: null,
    configurable: true,
    enumerable: false,
    writable: false
  })
}

Buffer.poolSize = 8192 // not used by this implementation

function from (value, encodingOrOffset, length) {
  if (typeof value === 'string') {
    return fromString(value, encodingOrOffset)
  }

  if (ArrayBuffer.isView(value)) {
    return fromArrayLike(value)
  }

  if (value == null) {
    throw TypeError(
      'The first argument must be one of type string, Buffer, ArrayBuffer, Array, ' +
      'or Array-like Object. Received type ' + (typeof value)
    )
  }

  if (isInstance(value, ArrayBuffer) ||
      (value && isInstance(value.buffer, ArrayBuffer))) {
    return fromArrayBuffer(value, encodingOrOffset, length)
  }

  if (typeof value === 'number') {
    throw new TypeError(
      'The "value" argument must not be of type number. Received type number'
    )
  }

  var valueOf = value.valueOf && value.valueOf()
  if (valueOf != null && valueOf !== value) {
    return Buffer.from(valueOf, encodingOrOffset, length)
  }

  var b = fromObject(value)
  if (b) return b

  if (typeof Symbol !== 'undefined' && Symbol.toPrimitive != null &&
      typeof value[Symbol.toPrimitive] === 'function') {
    return Buffer.from(
      value[Symbol.toPrimitive]('string'), encodingOrOffset, length
    )
  }

  throw new TypeError(
    'The first argument must be one of type string, Buffer, ArrayBuffer, Array, ' +
    'or Array-like Object. Received type ' + (typeof value)
  )
}

/**
 * Functionally equivalent to Buffer(arg, encoding) but throws a TypeError
 * if value is a number.
 * Buffer.from(str[, encoding])
 * Buffer.from(array)
 * Buffer.from(buffer)
 * Buffer.from(arrayBuffer[, byteOffset[, length]])
 **/
Buffer.from = function (value, encodingOrOffset, length) {
  return from(value, encodingOrOffset, length)
}

// Note: Change prototype *after* Buffer.from is defined to workaround Chrome bug:
// https://github.com/feross/buffer/pull/148
Buffer.prototype.__proto__ = Uint8Array.prototype
Buffer.__proto__ = Uint8Array

function assertSize (size) {
  if (typeof size !== 'number') {
    throw new TypeError('"size" argument must be of type number')
  } else if (size < 0) {
    throw new RangeError('The value "' + size + '" is invalid for option "size"')
  }
}

function alloc (size, fill, encoding) {
  assertSize(size)
  if (size <= 0) {
    return createBuffer(size)
  }
  if (fill !== undefined) {
    // Only pay attention to encoding if it's a string. This
    // prevents accidentally sending in a number that would
    // be interpretted as a start offset.
    return typeof encoding === 'string'
      ? createBuffer(size).fill(fill, encoding)
      : createBuffer(size).fill(fill)
  }
  return createBuffer(size)
}

/**
 * Creates a new filled Buffer instance.
 * alloc(size[, fill[, encoding]])
 **/
Buffer.alloc = function (size, fill, encoding) {
  return alloc(size, fill, encoding)
}

function allocUnsafe (size) {
  assertSize(size)
  return createBuffer(size < 0 ? 0 : checked(size) | 0)
}

/**
 * Equivalent to Buffer(num), by default creates a non-zero-filled Buffer instance.
 * */
Buffer.allocUnsafe = function (size) {
  return allocUnsafe(size)
}
/**
 * Equivalent to SlowBuffer(num), by default creates a non-zero-filled Buffer instance.
 */
Buffer.allocUnsafeSlow = function (size) {
  return allocUnsafe(size)
}

function fromString (string, encoding) {
  if (typeof encoding !== 'string' || encoding === '') {
    encoding = 'utf8'
  }

  if (!Buffer.isEncoding(encoding)) {
    throw new TypeError('Unknown encoding: ' + encoding)
  }

  var length = byteLength(string, encoding) | 0
  var buf = createBuffer(length)

  var actual = buf.write(string, encoding)

  if (actual !== length) {
    // Writing a hex string, for example, that contains invalid characters will
    // cause everything after the first invalid character to be ignored. (e.g.
    // 'abxxcd' will be treated as 'ab')
    buf = buf.slice(0, actual)
  }

  return buf
}

function fromArrayLike (array) {
  var length = array.length < 0 ? 0 : checked(array.length) | 0
  var buf = createBuffer(length)
  for (var i = 0; i < length; i += 1) {
    buf[i] = array[i] & 255
  }
  return buf
}

function fromArrayBuffer (array, byteOffset, length) {
  if (byteOffset < 0 || array.byteLength < byteOffset) {
    throw new RangeError('"offset" is outside of buffer bounds')
  }

  if (array.byteLength < byteOffset + (length || 0)) {
    throw new RangeError('"length" is outside of buffer bounds')
  }

  var buf
  if (byteOffset === undefined && length === undefined) {
    buf = new Uint8Array(array)
  } else if (length === undefined) {
    buf = new Uint8Array(array, byteOffset)
  } else {
    buf = new Uint8Array(array, byteOffset, length)
  }

  // Return an augmented `Uint8Array` instance
  buf.__proto__ = Buffer.prototype
  return buf
}

function fromObject (obj) {
  if (Buffer.isBuffer(obj)) {
    var len = checked(obj.length) | 0
    var buf = createBuffer(len)

    if (buf.length === 0) {
      return buf
    }

    obj.copy(buf, 0, 0, len)
    return buf
  }

  if (obj.length !== undefined) {
    if (typeof obj.length !== 'number' || numberIsNaN(obj.length)) {
      return createBuffer(0)
    }
    return fromArrayLike(obj)
  }

  if (obj.type === 'Buffer' && Array.isArray(obj.data)) {
    return fromArrayLike(obj.data)
  }
}

function checked (length) {
  // Note: cannot use `length < K_MAX_LENGTH` here because that fails when
  // length is NaN (which is otherwise coerced to zero.)
  if (length >= K_MAX_LENGTH) {
    throw new RangeError('Attempt to allocate Buffer larger than maximum ' +
                         'size: 0x' + K_MAX_LENGTH.toString(16) + ' bytes')
  }
  return length | 0
}

function SlowBuffer (length) {
  if (+length != length) { // eslint-disable-line eqeqeq
    length = 0
  }
  return Buffer.alloc(+length)
}

Buffer.isBuffer = function isBuffer (b) {
  return b != null && b._isBuffer === true &&
    b !== Buffer.prototype // so Buffer.isBuffer(Buffer.prototype) will be false
}

Buffer.compare = function compare (a, b) {
  if (isInstance(a, Uint8Array)) a = Buffer.from(a, a.offset, a.byteLength)
  if (isInstance(b, Uint8Array)) b = Buffer.from(b, b.offset, b.byteLength)
  if (!Buffer.isBuffer(a) || !Buffer.isBuffer(b)) {
    throw new TypeError(
      'The "buf1", "buf2" arguments must be one of type Buffer or Uint8Array'
    )
  }

  if (a === b) return 0

  var x = a.length
  var y = b.length

  for (var i = 0, len = Math.min(x, y); i < len; ++i) {
    if (a[i] !== b[i]) {
      x = a[i]
      y = b[i]
      break
    }
  }

  if (x < y) return -1
  if (y < x) return 1
  return 0
}

Buffer.isEncoding = function isEncoding (encoding) {
  switch (String(encoding).toLowerCase()) {
    case 'hex':
    case 'utf8':
    case 'utf-8':
    case 'ascii':
    case 'latin1':
    case 'binary':
    case 'base64':
    case 'ucs2':
    case 'ucs-2':
    case 'utf16le':
    case 'utf-16le':
      return true
    default:
      return false
  }
}

Buffer.concat = function concat (list, length) {
  if (!Array.isArray(list)) {
    throw new TypeError('"list" argument must be an Array of Buffers')
  }

  if (list.length === 0) {
    return Buffer.alloc(0)
  }

  var i
  if (length === undefined) {
    length = 0
    for (i = 0; i < list.length; ++i) {
      length += list[i].length
    }
  }

  var buffer = Buffer.allocUnsafe(length)
  var pos = 0
  for (i = 0; i < list.length; ++i) {
    var buf = list[i]
    if (isInstance(buf, Uint8Array)) {
      buf = Buffer.from(buf)
    }
    if (!Buffer.isBuffer(buf)) {
      throw new TypeError('"list" argument must be an Array of Buffers')
    }
    buf.copy(buffer, pos)
    pos += buf.length
  }
  return buffer
}

function byteLength (string, encoding) {
  if (Buffer.isBuffer(string)) {
    return string.length
  }
  if (ArrayBuffer.isView(string) || isInstance(string, ArrayBuffer)) {
    return string.byteLength
  }
  if (typeof string !== 'string') {
    throw new TypeError(
      'The "string" argument must be one of type string, Buffer, or ArrayBuffer. ' +
      'Received type ' + typeof string
    )
  }

  var len = string.length
  var mustMatch = (arguments.length > 2 && arguments[2] === true)
  if (!mustMatch && len === 0) return 0

  // Use a for loop to avoid recursion
  var loweredCase = false
  for (;;) {
    switch (encoding) {
      case 'ascii':
      case 'latin1':
      case 'binary':
        return len
      case 'utf8':
      case 'utf-8':
        return utf8ToBytes(string).length
      case 'ucs2':
      case 'ucs-2':
      case 'utf16le':
      case 'utf-16le':
        return len * 2
      case 'hex':
        return len >>> 1
      case 'base64':
        return base64ToBytes(string).length
      default:
        if (loweredCase) {
          return mustMatch ? -1 : utf8ToBytes(string).length // assume utf8
        }
        encoding = ('' + encoding).toLowerCase()
        loweredCase = true
    }
  }
}
Buffer.byteLength = byteLength

function slowToString (encoding, start, end) {
  var loweredCase = false

  // No need to verify that "this.length <= MAX_UINT32" since it's a read-only
  // property of a typed array.

  // This behaves neither like String nor Uint8Array in that we set start/end
  // to their upper/lower bounds if the value passed is out of range.
  // undefined is handled specially as per ECMA-262 6th Edition,
  // Section 13.3.3.7 Runtime Semantics: KeyedBindingInitialization.
  if (start === undefined || start < 0) {
    start = 0
  }
  // Return early if start > this.length. Done here to prevent potential uint32
  // coercion fail below.
  if (start > this.length) {
    return ''
  }

  if (end === undefined || end > this.length) {
    end = this.length
  }

  if (end <= 0) {
    return ''
  }

  // Force coersion to uint32. This will also coerce falsey/NaN values to 0.
  end >>>= 0
  start >>>= 0

  if (end <= start) {
    return ''
  }

  if (!encoding) encoding = 'utf8'

  while (true) {
    switch (encoding) {
      case 'hex':
        return hexSlice(this, start, end)

      case 'utf8':
      case 'utf-8':
        return utf8Slice(this, start, end)

      case 'ascii':
        return asciiSlice(this, start, end)

      case 'latin1':
      case 'binary':
        return latin1Slice(this, start, end)

      case 'base64':
        return base64Slice(this, start, end)

      case 'ucs2':
      case 'ucs-2':
      case 'utf16le':
      case 'utf-16le':
        return utf16leSlice(this, start, end)

      default:
        if (loweredCase) throw new TypeError('Unknown encoding: ' + encoding)
        encoding = (encoding + '').toLowerCase()
        loweredCase = true
    }
  }
}

// This property is used by `Buffer.isBuffer` (and the `is-buffer` npm package)
// to detect a Buffer instance. It's not possible to use `instanceof Buffer`
// reliably in a browserify context because there could be multiple different
// copies of the 'buffer' package in use. This method works even for Buffer
// instances that were created from another copy of the `buffer` package.
// See: https://github.com/feross/buffer/issues/154
Buffer.prototype._isBuffer = true

function swap (b, n, m) {
  var i = b[n]
  b[n] = b[m]
  b[m] = i
}

Buffer.prototype.swap16 = function swap16 () {
  var len = this.length
  if (len % 2 !== 0) {
    throw new RangeError('Buffer size must be a multiple of 16-bits')
  }
  for (var i = 0; i < len; i += 2) {
    swap(this, i, i + 1)
  }
  return this
}

Buffer.prototype.swap32 = function swap32 () {
  var len = this.length
  if (len % 4 !== 0) {
    throw new RangeError('Buffer size must be a multiple of 32-bits')
  }
  for (var i = 0; i < len; i += 4) {
    swap(this, i, i + 3)
    swap(this, i + 1, i + 2)
  }
  return this
}

Buffer.prototype.swap64 = function swap64 () {
  var len = this.length
  if (len % 8 !== 0) {
    throw new RangeError('Buffer size must be a multiple of 64-bits')
  }
  for (var i = 0; i < len; i += 8) {
    swap(this, i, i + 7)
    swap(this, i + 1, i + 6)
    swap(this, i + 2, i + 5)
    swap(this, i + 3, i + 4)
  }
  return this
}

Buffer.prototype.toString = function toString () {
  var length = this.length
  if (length === 0) return ''
  if (arguments.length === 0) return utf8Slice(this, 0, length)
  return slowToString.apply(this, arguments)
}

Buffer.prototype.toLocaleString = Buffer.prototype.toString

Buffer.prototype.equals = function equals (b) {
  if (!Buffer.isBuffer(b)) throw new TypeError('Argument must be a Buffer')
  if (this === b) return true
  return Buffer.compare(this, b) === 0
}

Buffer.prototype.inspect = function inspect () {
  var str = ''
  var max = exports.INSPECT_MAX_BYTES
  str = this.toString('hex', 0, max).replace(/(.{2})/g, '$1 ').trim()
  if (this.length > max) str += ' ... '
  return '<Buffer ' + str + '>'
}

Buffer.prototype.compare = function compare (target, start, end, thisStart, thisEnd) {
  if (isInstance(target, Uint8Array)) {
    target = Buffer.from(target, target.offset, target.byteLength)
  }
  if (!Buffer.isBuffer(target)) {
    throw new TypeError(
      'The "target" argument must be one of type Buffer or Uint8Array. ' +
      'Received type ' + (typeof target)
    )
  }

  if (start === undefined) {
    start = 0
  }
  if (end === undefined) {
    end = target ? target.length : 0
  }
  if (thisStart === undefined) {
    thisStart = 0
  }
  if (thisEnd === undefined) {
    thisEnd = this.length
  }

  if (start < 0 || end > target.length || thisStart < 0 || thisEnd > this.length) {
    throw new RangeError('out of range index')
  }

  if (thisStart >= thisEnd && start >= end) {
    return 0
  }
  if (thisStart >= thisEnd) {
    return -1
  }
  if (start >= end) {
    return 1
  }

  start >>>= 0
  end >>>= 0
  thisStart >>>= 0
  thisEnd >>>= 0

  if (this === target) return 0

  var x = thisEnd - thisStart
  var y = end - start
  var len = Math.min(x, y)

  var thisCopy = this.slice(thisStart, thisEnd)
  var targetCopy = target.slice(start, end)

  for (var i = 0; i < len; ++i) {
    if (thisCopy[i] !== targetCopy[i]) {
      x = thisCopy[i]
      y = targetCopy[i]
      break
    }
  }

  if (x < y) return -1
  if (y < x) return 1
  return 0
}

// Finds either the first index of `val` in `buffer` at offset >= `byteOffset`,
// OR the last index of `val` in `buffer` at offset <= `byteOffset`.
//
// Arguments:
// - buffer - a Buffer to search
// - val - a string, Buffer, or number
// - byteOffset - an index into `buffer`; will be clamped to an int32
// - encoding - an optional encoding, relevant is val is a string
// - dir - true for indexOf, false for lastIndexOf
function bidirectionalIndexOf (buffer, val, byteOffset, encoding, dir) {
  // Empty buffer means no match
  if (buffer.length === 0) return -1

  // Normalize byteOffset
  if (typeof byteOffset === 'string') {
    encoding = byteOffset
    byteOffset = 0
  } else if (byteOffset > 0x7fffffff) {
    byteOffset = 0x7fffffff
  } else if (byteOffset < -0x80000000) {
    byteOffset = -0x80000000
  }
  byteOffset = +byteOffset // Coerce to Number.
  if (numberIsNaN(byteOffset)) {
    // byteOffset: it it's undefined, null, NaN, "foo", etc, search whole buffer
    byteOffset = dir ? 0 : (buffer.length - 1)
  }

  // Normalize byteOffset: negative offsets start from the end of the buffer
  if (byteOffset < 0) byteOffset = buffer.length + byteOffset
  if (byteOffset >= buffer.length) {
    if (dir) return -1
    else byteOffset = buffer.length - 1
  } else if (byteOffset < 0) {
    if (dir) byteOffset = 0
    else return -1
  }

  // Normalize val
  if (typeof val === 'string') {
    val = Buffer.from(val, encoding)
  }

  // Finally, search either indexOf (if dir is true) or lastIndexOf
  if (Buffer.isBuffer(val)) {
    // Special case: looking for empty string/buffer always fails
    if (val.length === 0) {
      return -1
    }
    return arrayIndexOf(buffer, val, byteOffset, encoding, dir)
  } else if (typeof val === 'number') {
    val = val & 0xFF // Search for a byte value [0-255]
    if (typeof Uint8Array.prototype.indexOf === 'function') {
      if (dir) {
        return Uint8Array.prototype.indexOf.call(buffer, val, byteOffset)
      } else {
        return Uint8Array.prototype.lastIndexOf.call(buffer, val, byteOffset)
      }
    }
    return arrayIndexOf(buffer, [ val ], byteOffset, encoding, dir)
  }

  throw new TypeError('val must be string, number or Buffer')
}

function arrayIndexOf (arr, val, byteOffset, encoding, dir) {
  var indexSize = 1
  var arrLength = arr.length
  var valLength = val.length

  if (encoding !== undefined) {
    encoding = String(encoding).toLowerCase()
    if (encoding === 'ucs2' || encoding === 'ucs-2' ||
        encoding === 'utf16le' || encoding === 'utf-16le') {
      if (arr.length < 2 || val.length < 2) {
        return -1
      }
      indexSize = 2
      arrLength /= 2
      valLength /= 2
      byteOffset /= 2
    }
  }

  function read (buf, i) {
    if (indexSize === 1) {
      return buf[i]
    } else {
      return buf.readUInt16BE(i * indexSize)
    }
  }

  var i
  if (dir) {
    var foundIndex = -1
    for (i = byteOffset; i < arrLength; i++) {
      if (read(arr, i) === read(val, foundIndex === -1 ? 0 : i - foundIndex)) {
        if (foundIndex === -1) foundIndex = i
        if (i - foundIndex + 1 === valLength) return foundIndex * indexSize
      } else {
        if (foundIndex !== -1) i -= i - foundIndex
        foundIndex = -1
      }
    }
  } else {
    if (byteOffset + valLength > arrLength) byteOffset = arrLength - valLength
    for (i = byteOffset; i >= 0; i--) {
      var found = true
      for (var j = 0; j < valLength; j++) {
        if (read(arr, i + j) !== read(val, j)) {
          found = false
          break
        }
      }
      if (found) return i
    }
  }

  return -1
}

Buffer.prototype.includes = function includes (val, byteOffset, encoding) {
  return this.indexOf(val, byteOffset, encoding) !== -1
}

Buffer.prototype.indexOf = function indexOf (val, byteOffset, encoding) {
  return bidirectionalIndexOf(this, val, byteOffset, encoding, true)
}

Buffer.prototype.lastIndexOf = function lastIndexOf (val, byteOffset, encoding) {
  return bidirectionalIndexOf(this, val, byteOffset, encoding, false)
}

function hexWrite (buf, string, offset, length) {
  offset = Number(offset) || 0
  var remaining = buf.length - offset
  if (!length) {
    length = remaining
  } else {
    length = Number(length)
    if (length > remaining) {
      length = remaining
    }
  }

  var strLen = string.length

  if (length > strLen / 2) {
    length = strLen / 2
  }
  for (var i = 0; i < length; ++i) {
    var parsed = parseInt(string.substr(i * 2, 2), 16)
    if (numberIsNaN(parsed)) return i
    buf[offset + i] = parsed
  }
  return i
}

function utf8Write (buf, string, offset, length) {
  return blitBuffer(utf8ToBytes(string, buf.length - offset), buf, offset, length)
}

function asciiWrite (buf, string, offset, length) {
  return blitBuffer(asciiToBytes(string), buf, offset, length)
}

function latin1Write (buf, string, offset, length) {
  return asciiWrite(buf, string, offset, length)
}

function base64Write (buf, string, offset, length) {
  return blitBuffer(base64ToBytes(string), buf, offset, length)
}

function ucs2Write (buf, string, offset, length) {
  return blitBuffer(utf16leToBytes(string, buf.length - offset), buf, offset, length)
}

Buffer.prototype.write = function write (string, offset, length, encoding) {
  // Buffer#write(string)
  if (offset === undefined) {
    encoding = 'utf8'
    length = this.length
    offset = 0
  // Buffer#write(string, encoding)
  } else if (length === undefined && typeof offset === 'string') {
    encoding = offset
    length = this.length
    offset = 0
  // Buffer#write(string, offset[, length][, encoding])
  } else if (isFinite(offset)) {
    offset = offset >>> 0
    if (isFinite(length)) {
      length = length >>> 0
      if (encoding === undefined) encoding = 'utf8'
    } else {
      encoding = length
      length = undefined
    }
  } else {
    throw new Error(
      'Buffer.write(string, encoding, offset[, length]) is no longer supported'
    )
  }

  var remaining = this.length - offset
  if (length === undefined || length > remaining) length = remaining

  if ((string.length > 0 && (length < 0 || offset < 0)) || offset > this.length) {
    throw new RangeError('Attempt to write outside buffer bounds')
  }

  if (!encoding) encoding = 'utf8'

  var loweredCase = false
  for (;;) {
    switch (encoding) {
      case 'hex':
        return hexWrite(this, string, offset, length)

      case 'utf8':
      case 'utf-8':
        return utf8Write(this, string, offset, length)

      case 'ascii':
        return asciiWrite(this, string, offset, length)

      case 'latin1':
      case 'binary':
        return latin1Write(this, string, offset, length)

      case 'base64':
        // Warning: maxLength not taken into account in base64Write
        return base64Write(this, string, offset, length)

      case 'ucs2':
      case 'ucs-2':
      case 'utf16le':
      case 'utf-16le':
        return ucs2Write(this, string, offset, length)

      default:
        if (loweredCase) throw new TypeError('Unknown encoding: ' + encoding)
        encoding = ('' + encoding).toLowerCase()
        loweredCase = true
    }
  }
}

Buffer.prototype.toJSON = function toJSON () {
  return {
    type: 'Buffer',
    data: Array.prototype.slice.call(this._arr || this, 0)
  }
}

function base64Slice (buf, start, end) {
  if (start === 0 && end === buf.length) {
    return base64.fromByteArray(buf)
  } else {
    return base64.fromByteArray(buf.slice(start, end))
  }
}

function utf8Slice (buf, start, end) {
  end = Math.min(buf.length, end)
  var res = []

  var i = start
  while (i < end) {
    var firstByte = buf[i]
    var codePoint = null
    var bytesPerSequence = (firstByte > 0xEF) ? 4
      : (firstByte > 0xDF) ? 3
        : (firstByte > 0xBF) ? 2
          : 1

    if (i + bytesPerSequence <= end) {
      var secondByte, thirdByte, fourthByte, tempCodePoint

      switch (bytesPerSequence) {
        case 1:
          if (firstByte < 0x80) {
            codePoint = firstByte
          }
          break
        case 2:
          secondByte = buf[i + 1]
          if ((secondByte & 0xC0) === 0x80) {
            tempCodePoint = (firstByte & 0x1F) << 0x6 | (secondByte & 0x3F)
            if (tempCodePoint > 0x7F) {
              codePoint = tempCodePoint
            }
          }
          break
        case 3:
          secondByte = buf[i + 1]
          thirdByte = buf[i + 2]
          if ((secondByte & 0xC0) === 0x80 && (thirdByte & 0xC0) === 0x80) {
            tempCodePoint = (firstByte & 0xF) << 0xC | (secondByte & 0x3F) << 0x6 | (thirdByte & 0x3F)
            if (tempCodePoint > 0x7FF && (tempCodePoint < 0xD800 || tempCodePoint > 0xDFFF)) {
              codePoint = tempCodePoint
            }
          }
          break
        case 4:
          secondByte = buf[i + 1]
          thirdByte = buf[i + 2]
          fourthByte = buf[i + 3]
          if ((secondByte & 0xC0) === 0x80 && (thirdByte & 0xC0) === 0x80 && (fourthByte & 0xC0) === 0x80) {
            tempCodePoint = (firstByte & 0xF) << 0x12 | (secondByte & 0x3F) << 0xC | (thirdByte & 0x3F) << 0x6 | (fourthByte & 0x3F)
            if (tempCodePoint > 0xFFFF && tempCodePoint < 0x110000) {
              codePoint = tempCodePoint
            }
          }
      }
    }

    if (codePoint === null) {
      // we did not generate a valid codePoint so insert a
      // replacement char (U+FFFD) and advance only 1 byte
      codePoint = 0xFFFD
      bytesPerSequence = 1
    } else if (codePoint > 0xFFFF) {
      // encode to utf16 (surrogate pair dance)
      codePoint -= 0x10000
      res.push(codePoint >>> 10 & 0x3FF | 0xD800)
      codePoint = 0xDC00 | codePoint & 0x3FF
    }

    res.push(codePoint)
    i += bytesPerSequence
  }

  return decodeCodePointsArray(res)
}

// Based on http://stackoverflow.com/a/22747272/680742, the browser with
// the lowest limit is Chrome, with 0x10000 args.
// We go 1 magnitude less, for safety
var MAX_ARGUMENTS_LENGTH = 0x1000

function decodeCodePointsArray (codePoints) {
  var len = codePoints.length
  if (len <= MAX_ARGUMENTS_LENGTH) {
    return String.fromCharCode.apply(String, codePoints) // avoid extra slice()
  }

  // Decode in chunks to avoid "call stack size exceeded".
  var res = ''
  var i = 0
  while (i < len) {
    res += String.fromCharCode.apply(
      String,
      codePoints.slice(i, i += MAX_ARGUMENTS_LENGTH)
    )
  }
  return res
}

function asciiSlice (buf, start, end) {
  var ret = ''
  end = Math.min(buf.length, end)

  for (var i = start; i < end; ++i) {
    ret += String.fromCharCode(buf[i] & 0x7F)
  }
  return ret
}

function latin1Slice (buf, start, end) {
  var ret = ''
  end = Math.min(buf.length, end)

  for (var i = start; i < end; ++i) {
    ret += String.fromCharCode(buf[i])
  }
  return ret
}

function hexSlice (buf, start, end) {
  var len = buf.length

  if (!start || start < 0) start = 0
  if (!end || end < 0 || end > len) end = len

  var out = ''
  for (var i = start; i < end; ++i) {
    out += toHex(buf[i])
  }
  return out
}

function utf16leSlice (buf, start, end) {
  var bytes = buf.slice(start, end)
  var res = ''
  for (var i = 0; i < bytes.length; i += 2) {
    res += String.fromCharCode(bytes[i] + (bytes[i + 1] * 256))
  }
  return res
}

Buffer.prototype.slice = function slice (start, end) {
  var len = this.length
  start = ~~start
  end = end === undefined ? len : ~~end

  if (start < 0) {
    start += len
    if (start < 0) start = 0
  } else if (start > len) {
    start = len
  }

  if (end < 0) {
    end += len
    if (end < 0) end = 0
  } else if (end > len) {
    end = len
  }

  if (end < start) end = start

  var newBuf = this.subarray(start, end)
  // Return an augmented `Uint8Array` instance
  newBuf.__proto__ = Buffer.prototype
  return newBuf
}

/*
 * Need to make sure that buffer isn't trying to write out of bounds.
 */
function checkOffset (offset, ext, length) {
  if ((offset % 1) !== 0 || offset < 0) throw new RangeError('offset is not uint')
  if (offset + ext > length) throw new RangeError('Trying to access beyond buffer length')
}

Buffer.prototype.readUIntLE = function readUIntLE (offset, byteLength, noAssert) {
  offset = offset >>> 0
  byteLength = byteLength >>> 0
  if (!noAssert) checkOffset(offset, byteLength, this.length)

  var val = this[offset]
  var mul = 1
  var i = 0
  while (++i < byteLength && (mul *= 0x100)) {
    val += this[offset + i] * mul
  }

  return val
}

Buffer.prototype.readUIntBE = function readUIntBE (offset, byteLength, noAssert) {
  offset = offset >>> 0
  byteLength = byteLength >>> 0
  if (!noAssert) {
    checkOffset(offset, byteLength, this.length)
  }

  var val = this[offset + --byteLength]
  var mul = 1
  while (byteLength > 0 && (mul *= 0x100)) {
    val += this[offset + --byteLength] * mul
  }

  return val
}

Buffer.prototype.readUInt8 = function readUInt8 (offset, noAssert) {
  offset = offset >>> 0
  if (!noAssert) checkOffset(offset, 1, this.length)
  return this[offset]
}

Buffer.prototype.readUInt16LE = function readUInt16LE (offset, noAssert) {
  offset = offset >>> 0
  if (!noAssert) checkOffset(offset, 2, this.length)
  return this[offset] | (this[offset + 1] << 8)
}

Buffer.prototype.readUInt16BE = function readUInt16BE (offset, noAssert) {
  offset = offset >>> 0
  if (!noAssert) checkOffset(offset, 2, this.length)
  return (this[offset] << 8) | this[offset + 1]
}

Buffer.prototype.readUInt32LE = function readUInt32LE (offset, noAssert) {
  offset = offset >>> 0
  if (!noAssert) checkOffset(offset, 4, this.length)

  return ((this[offset]) |
      (this[offset + 1] << 8) |
      (this[offset + 2] << 16)) +
      (this[offset + 3] * 0x1000000)
}

Buffer.prototype.readUInt32BE = function readUInt32BE (offset, noAssert) {
  offset = offset >>> 0
  if (!noAssert) checkOffset(offset, 4, this.length)

  return (this[offset] * 0x1000000) +
    ((this[offset + 1] << 16) |
    (this[offset + 2] << 8) |
    this[offset + 3])
}

Buffer.prototype.readIntLE = function readIntLE (offset, byteLength, noAssert) {
  offset = offset >>> 0
  byteLength = byteLength >>> 0
  if (!noAssert) checkOffset(offset, byteLength, this.length)

  var val = this[offset]
  var mul = 1
  var i = 0
  while (++i < byteLength && (mul *= 0x100)) {
    val += this[offset + i] * mul
  }
  mul *= 0x80

  if (val >= mul) val -= Math.pow(2, 8 * byteLength)

  return val
}

Buffer.prototype.readIntBE = function readIntBE (offset, byteLength, noAssert) {
  offset = offset >>> 0
  byteLength = byteLength >>> 0
  if (!noAssert) checkOffset(offset, byteLength, this.length)

  var i = byteLength
  var mul = 1
  var val = this[offset + --i]
  while (i > 0 && (mul *= 0x100)) {
    val += this[offset + --i] * mul
  }
  mul *= 0x80

  if (val >= mul) val -= Math.pow(2, 8 * byteLength)

  return val
}

Buffer.prototype.readInt8 = function readInt8 (offset, noAssert) {
  offset = offset >>> 0
  if (!noAssert) checkOffset(offset, 1, this.length)
  if (!(this[offset] & 0x80)) return (this[offset])
  return ((0xff - this[offset] + 1) * -1)
}

Buffer.prototype.readInt16LE = function readInt16LE (offset, noAssert) {
  offset = offset >>> 0
  if (!noAssert) checkOffset(offset, 2, this.length)
  var val = this[offset] | (this[offset + 1] << 8)
  return (val & 0x8000) ? val | 0xFFFF0000 : val
}

Buffer.prototype.readInt16BE = function readInt16BE (offset, noAssert) {
  offset = offset >>> 0
  if (!noAssert) checkOffset(offset, 2, this.length)
  var val = this[offset + 1] | (this[offset] << 8)
  return (val & 0x8000) ? val | 0xFFFF0000 : val
}

Buffer.prototype.readInt32LE = function readInt32LE (offset, noAssert) {
  offset = offset >>> 0
  if (!noAssert) checkOffset(offset, 4, this.length)

  return (this[offset]) |
    (this[offset + 1] << 8) |
    (this[offset + 2] << 16) |
    (this[offset + 3] << 24)
}

Buffer.prototype.readInt32BE = function readInt32BE (offset, noAssert) {
  offset = offset >>> 0
  if (!noAssert) checkOffset(offset, 4, this.length)

  return (this[offset] << 24) |
    (this[offset + 1] << 16) |
    (this[offset + 2] << 8) |
    (this[offset + 3])
}

Buffer.prototype.readFloatLE = function readFloatLE (offset, noAssert) {
  offset = offset >>> 0
  if (!noAssert) checkOffset(offset, 4, this.length)
  return ieee754.read(this, offset, true, 23, 4)
}

Buffer.prototype.readFloatBE = function readFloatBE (offset, noAssert) {
  offset = offset >>> 0
  if (!noAssert) checkOffset(offset, 4, this.length)
  return ieee754.read(this, offset, false, 23, 4)
}

Buffer.prototype.readDoubleLE = function readDoubleLE (offset, noAssert) {
  offset = offset >>> 0
  if (!noAssert) checkOffset(offset, 8, this.length)
  return ieee754.read(this, offset, true, 52, 8)
}

Buffer.prototype.readDoubleBE = function readDoubleBE (offset, noAssert) {
  offset = offset >>> 0
  if (!noAssert) checkOffset(offset, 8, this.length)
  return ieee754.read(this, offset, false, 52, 8)
}

function checkInt (buf, value, offset, ext, max, min) {
  if (!Buffer.isBuffer(buf)) throw new TypeError('"buffer" argument must be a Buffer instance')
  if (value > max || value < min) throw new RangeError('"value" argument is out of bounds')
  if (offset + ext > buf.length) throw new RangeError('Index out of range')
}

Buffer.prototype.writeUIntLE = function writeUIntLE (value, offset, byteLength, noAssert) {
  value = +value
  offset = offset >>> 0
  byteLength = byteLength >>> 0
  if (!noAssert) {
    var maxBytes = Math.pow(2, 8 * byteLength) - 1
    checkInt(this, value, offset, byteLength, maxBytes, 0)
  }

  var mul = 1
  var i = 0
  this[offset] = value & 0xFF
  while (++i < byteLength && (mul *= 0x100)) {
    this[offset + i] = (value / mul) & 0xFF
  }

  return offset + byteLength
}

Buffer.prototype.writeUIntBE = function writeUIntBE (value, offset, byteLength, noAssert) {
  value = +value
  offset = offset >>> 0
  byteLength = byteLength >>> 0
  if (!noAssert) {
    var maxBytes = Math.pow(2, 8 * byteLength) - 1
    checkInt(this, value, offset, byteLength, maxBytes, 0)
  }

  var i = byteLength - 1
  var mul = 1
  this[offset + i] = value & 0xFF
  while (--i >= 0 && (mul *= 0x100)) {
    this[offset + i] = (value / mul) & 0xFF
  }

  return offset + byteLength
}

Buffer.prototype.writeUInt8 = function writeUInt8 (value, offset, noAssert) {
  value = +value
  offset = offset >>> 0
  if (!noAssert) checkInt(this, value, offset, 1, 0xff, 0)
  this[offset] = (value & 0xff)
  return offset + 1
}

Buffer.prototype.writeUInt16LE = function writeUInt16LE (value, offset, noAssert) {
  value = +value
  offset = offset >>> 0
  if (!noAssert) checkInt(this, value, offset, 2, 0xffff, 0)
  this[offset] = (value & 0xff)
  this[offset + 1] = (value >>> 8)
  return offset + 2
}

Buffer.prototype.writeUInt16BE = function writeUInt16BE (value, offset, noAssert) {
  value = +value
  offset = offset >>> 0
  if (!noAssert) checkInt(this, value, offset, 2, 0xffff, 0)
  this[offset] = (value >>> 8)
  this[offset + 1] = (value & 0xff)
  return offset + 2
}

Buffer.prototype.writeUInt32LE = function writeUInt32LE (value, offset, noAssert) {
  value = +value
  offset = offset >>> 0
  if (!noAssert) checkInt(this, value, offset, 4, 0xffffffff, 0)
  this[offset + 3] = (value >>> 24)
  this[offset + 2] = (value >>> 16)
  this[offset + 1] = (value >>> 8)
  this[offset] = (value & 0xff)
  return offset + 4
}

Buffer.prototype.writeUInt32BE = function writeUInt32BE (value, offset, noAssert) {
  value = +value
  offset = offset >>> 0
  if (!noAssert) checkInt(this, value, offset, 4, 0xffffffff, 0)
  this[offset] = (value >>> 24)
  this[offset + 1] = (value >>> 16)
  this[offset + 2] = (value >>> 8)
  this[offset + 3] = (value & 0xff)
  return offset + 4
}

Buffer.prototype.writeIntLE = function writeIntLE (value, offset, byteLength, noAssert) {
  value = +value
  offset = offset >>> 0
  if (!noAssert) {
    var limit = Math.pow(2, (8 * byteLength) - 1)

    checkInt(this, value, offset, byteLength, limit - 1, -limit)
  }

  var i = 0
  var mul = 1
  var sub = 0
  this[offset] = value & 0xFF
  while (++i < byteLength && (mul *= 0x100)) {
    if (value < 0 && sub === 0 && this[offset + i - 1] !== 0) {
      sub = 1
    }
    this[offset + i] = ((value / mul) >> 0) - sub & 0xFF
  }

  return offset + byteLength
}

Buffer.prototype.writeIntBE = function writeIntBE (value, offset, byteLength, noAssert) {
  value = +value
  offset = offset >>> 0
  if (!noAssert) {
    var limit = Math.pow(2, (8 * byteLength) - 1)

    checkInt(this, value, offset, byteLength, limit - 1, -limit)
  }

  var i = byteLength - 1
  var mul = 1
  var sub = 0
  this[offset + i] = value & 0xFF
  while (--i >= 0 && (mul *= 0x100)) {
    if (value < 0 && sub === 0 && this[offset + i + 1] !== 0) {
      sub = 1
    }
    this[offset + i] = ((value / mul) >> 0) - sub & 0xFF
  }

  return offset + byteLength
}

Buffer.prototype.writeInt8 = function writeInt8 (value, offset, noAssert) {
  value = +value
  offset = offset >>> 0
  if (!noAssert) checkInt(this, value, offset, 1, 0x7f, -0x80)
  if (value < 0) value = 0xff + value + 1
  this[offset] = (value & 0xff)
  return offset + 1
}

Buffer.prototype.writeInt16LE = function writeInt16LE (value, offset, noAssert) {
  value = +value
  offset = offset >>> 0
  if (!noAssert) checkInt(this, value, offset, 2, 0x7fff, -0x8000)
  this[offset] = (value & 0xff)
  this[offset + 1] = (value >>> 8)
  return offset + 2
}

Buffer.prototype.writeInt16BE = function writeInt16BE (value, offset, noAssert) {
  value = +value
  offset = offset >>> 0
  if (!noAssert) checkInt(this, value, offset, 2, 0x7fff, -0x8000)
  this[offset] = (value >>> 8)
  this[offset + 1] = (value & 0xff)
  return offset + 2
}

Buffer.prototype.writeInt32LE = function writeInt32LE (value, offset, noAssert) {
  value = +value
  offset = offset >>> 0
  if (!noAssert) checkInt(this, value, offset, 4, 0x7fffffff, -0x80000000)
  this[offset] = (value & 0xff)
  this[offset + 1] = (value >>> 8)
  this[offset + 2] = (value >>> 16)
  this[offset + 3] = (value >>> 24)
  return offset + 4
}

Buffer.prototype.writeInt32BE = function writeInt32BE (value, offset, noAssert) {
  value = +value
  offset = offset >>> 0
  if (!noAssert) checkInt(this, value, offset, 4, 0x7fffffff, -0x80000000)
  if (value < 0) value = 0xffffffff + value + 1
  this[offset] = (value >>> 24)
  this[offset + 1] = (value >>> 16)
  this[offset + 2] = (value >>> 8)
  this[offset + 3] = (value & 0xff)
  return offset + 4
}

function checkIEEE754 (buf, value, offset, ext, max, min) {
  if (offset + ext > buf.length) throw new RangeError('Index out of range')
  if (offset < 0) throw new RangeError('Index out of range')
}

function writeFloat (buf, value, offset, littleEndian, noAssert) {
  value = +value
  offset = offset >>> 0
  if (!noAssert) {
    checkIEEE754(buf, value, offset, 4, 3.4028234663852886e+38, -3.4028234663852886e+38)
  }
  ieee754.write(buf, value, offset, littleEndian, 23, 4)
  return offset + 4
}

Buffer.prototype.writeFloatLE = function writeFloatLE (value, offset, noAssert) {
  return writeFloat(this, value, offset, true, noAssert)
}

Buffer.prototype.writeFloatBE = function writeFloatBE (value, offset, noAssert) {
  return writeFloat(this, value, offset, false, noAssert)
}

function writeDouble (buf, value, offset, littleEndian, noAssert) {
  value = +value
  offset = offset >>> 0
  if (!noAssert) {
    checkIEEE754(buf, value, offset, 8, 1.7976931348623157E+308, -1.7976931348623157E+308)
  }
  ieee754.write(buf, value, offset, littleEndian, 52, 8)
  return offset + 8
}

Buffer.prototype.writeDoubleLE = function writeDoubleLE (value, offset, noAssert) {
  return writeDouble(this, value, offset, true, noAssert)
}

Buffer.prototype.writeDoubleBE = function writeDoubleBE (value, offset, noAssert) {
  return writeDouble(this, value, offset, false, noAssert)
}

// copy(targetBuffer, targetStart=0, sourceStart=0, sourceEnd=buffer.length)
Buffer.prototype.copy = function copy (target, targetStart, start, end) {
  if (!Buffer.isBuffer(target)) throw new TypeError('argument should be a Buffer')
  if (!start) start = 0
  if (!end && end !== 0) end = this.length
  if (targetStart >= target.length) targetStart = target.length
  if (!targetStart) targetStart = 0
  if (end > 0 && end < start) end = start

  // Copy 0 bytes; we're done
  if (end === start) return 0
  if (target.length === 0 || this.length === 0) return 0

  // Fatal error conditions
  if (targetStart < 0) {
    throw new RangeError('targetStart out of bounds')
  }
  if (start < 0 || start >= this.length) throw new RangeError('Index out of range')
  if (end < 0) throw new RangeError('sourceEnd out of bounds')

  // Are we oob?
  if (end > this.length) end = this.length
  if (target.length - targetStart < end - start) {
    end = target.length - targetStart + start
  }

  var len = end - start

  if (this === target && typeof Uint8Array.prototype.copyWithin === 'function') {
    // Use built-in when available, missing from IE11
    this.copyWithin(targetStart, start, end)
  } else if (this === target && start < targetStart && targetStart < end) {
    // descending copy from end
    for (var i = len - 1; i >= 0; --i) {
      target[i + targetStart] = this[i + start]
    }
  } else {
    Uint8Array.prototype.set.call(
      target,
      this.subarray(start, end),
      targetStart
    )
  }

  return len
}

// Usage:
//    buffer.fill(number[, offset[, end]])
//    buffer.fill(buffer[, offset[, end]])
//    buffer.fill(string[, offset[, end]][, encoding])
Buffer.prototype.fill = function fill (val, start, end, encoding) {
  // Handle string cases:
  if (typeof val === 'string') {
    if (typeof start === 'string') {
      encoding = start
      start = 0
      end = this.length
    } else if (typeof end === 'string') {
      encoding = end
      end = this.length
    }
    if (encoding !== undefined && typeof encoding !== 'string') {
      throw new TypeError('encoding must be a string')
    }
    if (typeof encoding === 'string' && !Buffer.isEncoding(encoding)) {
      throw new TypeError('Unknown encoding: ' + encoding)
    }
    if (val.length === 1) {
      var code = val.charCodeAt(0)
      if ((encoding === 'utf8' && code < 128) ||
          encoding === 'latin1') {
        // Fast path: If `val` fits into a single byte, use that numeric value.
        val = code
      }
    }
  } else if (typeof val === 'number') {
    val = val & 255
  }

  // Invalid ranges are not set to a default, so can range check early.
  if (start < 0 || this.length < start || this.length < end) {
    throw new RangeError('Out of range index')
  }

  if (end <= start) {
    return this
  }

  start = start >>> 0
  end = end === undefined ? this.length : end >>> 0

  if (!val) val = 0

  var i
  if (typeof val === 'number') {
    for (i = start; i < end; ++i) {
      this[i] = val
    }
  } else {
    var bytes = Buffer.isBuffer(val)
      ? val
      : Buffer.from(val, encoding)
    var len = bytes.length
    if (len === 0) {
      throw new TypeError('The value "' + val +
        '" is invalid for argument "value"')
    }
    for (i = 0; i < end - start; ++i) {
      this[i + start] = bytes[i % len]
    }
  }

  return this
}

// HELPER FUNCTIONS
// ================

var INVALID_BASE64_RE = /[^+/0-9A-Za-z-_]/g

function base64clean (str) {
  // Node takes equal signs as end of the Base64 encoding
  str = str.split('=')[0]
  // Node strips out invalid characters like \n and \t from the string, base64-js does not
  str = str.trim().replace(INVALID_BASE64_RE, '')
  // Node converts strings with length < 2 to ''
  if (str.length < 2) return ''
  // Node allows for non-padded base64 strings (missing trailing ===), base64-js does not
  while (str.length % 4 !== 0) {
    str = str + '='
  }
  return str
}

function toHex (n) {
  if (n < 16) return '0' + n.toString(16)
  return n.toString(16)
}

function utf8ToBytes (string, units) {
  units = units || Infinity
  var codePoint
  var length = string.length
  var leadSurrogate = null
  var bytes = []

  for (var i = 0; i < length; ++i) {
    codePoint = string.charCodeAt(i)

    // is surrogate component
    if (codePoint > 0xD7FF && codePoint < 0xE000) {
      // last char was a lead
      if (!leadSurrogate) {
        // no lead yet
        if (codePoint > 0xDBFF) {
          // unexpected trail
          if ((units -= 3) > -1) bytes.push(0xEF, 0xBF, 0xBD)
          continue
        } else if (i + 1 === length) {
          // unpaired lead
          if ((units -= 3) > -1) bytes.push(0xEF, 0xBF, 0xBD)
          continue
        }

        // valid lead
        leadSurrogate = codePoint

        continue
      }

      // 2 leads in a row
      if (codePoint < 0xDC00) {
        if ((units -= 3) > -1) bytes.push(0xEF, 0xBF, 0xBD)
        leadSurrogate = codePoint
        continue
      }

      // valid surrogate pair
      codePoint = (leadSurrogate - 0xD800 << 10 | codePoint - 0xDC00) + 0x10000
    } else if (leadSurrogate) {
      // valid bmp char, but last char was a lead
      if ((units -= 3) > -1) bytes.push(0xEF, 0xBF, 0xBD)
    }

    leadSurrogate = null

    // encode utf8
    if (codePoint < 0x80) {
      if ((units -= 1) < 0) break
      bytes.push(codePoint)
    } else if (codePoint < 0x800) {
      if ((units -= 2) < 0) break
      bytes.push(
        codePoint >> 0x6 | 0xC0,
        codePoint & 0x3F | 0x80
      )
    } else if (codePoint < 0x10000) {
      if ((units -= 3) < 0) break
      bytes.push(
        codePoint >> 0xC | 0xE0,
        codePoint >> 0x6 & 0x3F | 0x80,
        codePoint & 0x3F | 0x80
      )
    } else if (codePoint < 0x110000) {
      if ((units -= 4) < 0) break
      bytes.push(
        codePoint >> 0x12 | 0xF0,
        codePoint >> 0xC & 0x3F | 0x80,
        codePoint >> 0x6 & 0x3F | 0x80,
        codePoint & 0x3F | 0x80
      )
    } else {
      throw new Error('Invalid code point')
    }
  }

  return bytes
}

function asciiToBytes (str) {
  var byteArray = []
  for (var i = 0; i < str.length; ++i) {
    // Node's code seems to be doing this and not & 0x7F..
    byteArray.push(str.charCodeAt(i) & 0xFF)
  }
  return byteArray
}

function utf16leToBytes (str, units) {
  var c, hi, lo
  var byteArray = []
  for (var i = 0; i < str.length; ++i) {
    if ((units -= 2) < 0) break

    c = str.charCodeAt(i)
    hi = c >> 8
    lo = c % 256
    byteArray.push(lo)
    byteArray.push(hi)
  }

  return byteArray
}

function base64ToBytes (str) {
  return base64.toByteArray(base64clean(str))
}

function blitBuffer (src, dst, offset, length) {
  for (var i = 0; i < length; ++i) {
    if ((i + offset >= dst.length) || (i >= src.length)) break
    dst[i + offset] = src[i]
  }
  return i
}

// ArrayBuffer or Uint8Array objects from other contexts (i.e. iframes) do not pass
// the `instanceof` check but they should be treated as of that type.
// See: https://github.com/feross/buffer/issues/166
function isInstance (obj, type) {
  return obj instanceof type ||
    (obj != null && obj.constructor != null && obj.constructor.name != null &&
      obj.constructor.name === type.name)
}
function numberIsNaN (obj) {
  // For IE11 support
  return obj !== obj // eslint-disable-line no-self-compare
}

}).call(this)}).call(this,require("buffer").Buffer)
},{"base64-js":1,"buffer":2,"ieee754":3}],3:[function(require,module,exports){
/*! ieee754. BSD-3-Clause License. Feross Aboukhadijeh <https://feross.org/opensource> */
exports.read = function (buffer, offset, isLE, mLen, nBytes) {
  var e, m
  var eLen = (nBytes * 8) - mLen - 1
  var eMax = (1 << eLen) - 1
  var eBias = eMax >> 1
  var nBits = -7
  var i = isLE ? (nBytes - 1) : 0
  var d = isLE ? -1 : 1
  var s = buffer[offset + i]

  i += d

  e = s & ((1 << (-nBits)) - 1)
  s >>= (-nBits)
  nBits += eLen
  for (; nBits > 0; e = (e * 256) + buffer[offset + i], i += d, nBits -= 8) {}

  m = e & ((1 << (-nBits)) - 1)
  e >>= (-nBits)
  nBits += mLen
  for (; nBits > 0; m = (m * 256) + buffer[offset + i], i += d, nBits -= 8) {}

  if (e === 0) {
    e = 1 - eBias
  } else if (e === eMax) {
    return m ? NaN : ((s ? -1 : 1) * Infinity)
  } else {
    m = m + Math.pow(2, mLen)
    e = e - eBias
  }
  return (s ? -1 : 1) * m * Math.pow(2, e - mLen)
}

exports.write = function (buffer, value, offset, isLE, mLen, nBytes) {
  var e, m, c
  var eLen = (nBytes * 8) - mLen - 1
  var eMax = (1 << eLen) - 1
  var eBias = eMax >> 1
  var rt = (mLen === 23 ? Math.pow(2, -24) - Math.pow(2, -77) : 0)
  var i = isLE ? 0 : (nBytes - 1)
  var d = isLE ? 1 : -1
  var s = value < 0 || (value === 0 && 1 / value < 0) ? 1 : 0

  value = Math.abs(value)

  if (isNaN(value) || value === Infinity) {
    m = isNaN(value) ? 1 : 0
    e = eMax
  } else {
    e = Math.floor(Math.log(value) / Math.LN2)
    if (value * (c = Math.pow(2, -e)) < 1) {
      e--
      c *= 2
    }
    if (e + eBias >= 1) {
      value += rt / c
    } else {
      value += rt * Math.pow(2, 1 - eBias)
    }
    if (value * c >= 2) {
      e++
      c /= 2
    }

    if (e + eBias >= eMax) {
      m = 0
      e = eMax
    } else if (e + eBias >= 1) {
      m = ((value * c) - 1) * Math.pow(2, mLen)
      e = e + eBias
    } else {
      m = value * Math.pow(2, eBias - 1) * Math.pow(2, mLen)
      e = 0
    }
  }

  for (; mLen >= 8; buffer[offset + i] = m & 0xff, i += d, m /= 256, mLen -= 8) {}

  e = (e << mLen) | m
  eLen += mLen
  for (; eLen > 0; buffer[offset + i] = e & 0xff, i += d, e /= 256, eLen -= 8) {}

  buffer[offset + i - d] |= s * 128
}

},{}],4:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiError = void 0;
class ApiError extends Error {
    constructor(response, message) {
        super(message);
        this.name = 'ApiError';
        this.url = response.url;
        this.status = response.status;
        this.statusText = response.statusText;
        this.body = response.body;
    }
}
exports.ApiError = ApiError;

},{}],5:[function(require,module,exports){
"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _CancelablePromise_isResolved, _CancelablePromise_isRejected, _CancelablePromise_isCancelled, _CancelablePromise_cancelHandlers, _CancelablePromise_promise, _CancelablePromise_resolve, _CancelablePromise_reject;
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelablePromise = exports.CancelError = void 0;
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
class CancelError extends Error {
    constructor(message) {
        super(message);
        this.name = 'CancelError';
    }
    get isCancelled() {
        return true;
    }
}
exports.CancelError = CancelError;
class CancelablePromise {
    constructor(executor) {
        _CancelablePromise_isResolved.set(this, void 0);
        _CancelablePromise_isRejected.set(this, void 0);
        _CancelablePromise_isCancelled.set(this, void 0);
        _CancelablePromise_cancelHandlers.set(this, void 0);
        _CancelablePromise_promise.set(this, void 0);
        _CancelablePromise_resolve.set(this, void 0);
        _CancelablePromise_reject.set(this, void 0);
        __classPrivateFieldSet(this, _CancelablePromise_isResolved, false, "f");
        __classPrivateFieldSet(this, _CancelablePromise_isRejected, false, "f");
        __classPrivateFieldSet(this, _CancelablePromise_isCancelled, false, "f");
        __classPrivateFieldSet(this, _CancelablePromise_cancelHandlers, [], "f");
        __classPrivateFieldSet(this, _CancelablePromise_promise, new Promise((resolve, reject) => {
            __classPrivateFieldSet(this, _CancelablePromise_resolve, resolve, "f");
            __classPrivateFieldSet(this, _CancelablePromise_reject, reject, "f");
            const onResolve = (value) => {
                var _a;
                if (__classPrivateFieldGet(this, _CancelablePromise_isResolved, "f") || __classPrivateFieldGet(this, _CancelablePromise_isRejected, "f") || __classPrivateFieldGet(this, _CancelablePromise_isCancelled, "f")) {
                    return;
                }
                __classPrivateFieldSet(this, _CancelablePromise_isResolved, true, "f");
                (_a = __classPrivateFieldGet(this, _CancelablePromise_resolve, "f")) === null || _a === void 0 ? void 0 : _a.call(this, value);
            };
            const onReject = (reason) => {
                var _a;
                if (__classPrivateFieldGet(this, _CancelablePromise_isResolved, "f") || __classPrivateFieldGet(this, _CancelablePromise_isRejected, "f") || __classPrivateFieldGet(this, _CancelablePromise_isCancelled, "f")) {
                    return;
                }
                __classPrivateFieldSet(this, _CancelablePromise_isRejected, true, "f");
                (_a = __classPrivateFieldGet(this, _CancelablePromise_reject, "f")) === null || _a === void 0 ? void 0 : _a.call(this, reason);
            };
            const onCancel = (cancelHandler) => {
                if (__classPrivateFieldGet(this, _CancelablePromise_isResolved, "f") || __classPrivateFieldGet(this, _CancelablePromise_isRejected, "f") || __classPrivateFieldGet(this, _CancelablePromise_isCancelled, "f")) {
                    return;
                }
                __classPrivateFieldGet(this, _CancelablePromise_cancelHandlers, "f").push(cancelHandler);
            };
            Object.defineProperty(onCancel, 'isResolved', {
                get: () => __classPrivateFieldGet(this, _CancelablePromise_isResolved, "f"),
            });
            Object.defineProperty(onCancel, 'isRejected', {
                get: () => __classPrivateFieldGet(this, _CancelablePromise_isRejected, "f"),
            });
            Object.defineProperty(onCancel, 'isCancelled', {
                get: () => __classPrivateFieldGet(this, _CancelablePromise_isCancelled, "f"),
            });
            return executor(onResolve, onReject, onCancel);
        }), "f");
    }
    then(onFulfilled, onRejected) {
        return __classPrivateFieldGet(this, _CancelablePromise_promise, "f").then(onFulfilled, onRejected);
    }
    catch(onRejected) {
        return __classPrivateFieldGet(this, _CancelablePromise_promise, "f").catch(onRejected);
    }
    finally(onFinally) {
        return __classPrivateFieldGet(this, _CancelablePromise_promise, "f").finally(onFinally);
    }
    cancel() {
        var _a;
        if (__classPrivateFieldGet(this, _CancelablePromise_isResolved, "f") || __classPrivateFieldGet(this, _CancelablePromise_isRejected, "f") || __classPrivateFieldGet(this, _CancelablePromise_isCancelled, "f")) {
            return;
        }
        __classPrivateFieldSet(this, _CancelablePromise_isCancelled, true, "f");
        if (__classPrivateFieldGet(this, _CancelablePromise_cancelHandlers, "f").length) {
            try {
                for (const cancelHandler of __classPrivateFieldGet(this, _CancelablePromise_cancelHandlers, "f")) {
                    cancelHandler();
                }
            }
            catch (error) {
                console.warn('Cancellation threw an error', error);
                return;
            }
        }
        __classPrivateFieldGet(this, _CancelablePromise_cancelHandlers, "f").length = 0;
        (_a = __classPrivateFieldGet(this, _CancelablePromise_reject, "f")) === null || _a === void 0 ? void 0 : _a.call(this, new CancelError('Request aborted'));
    }
    get isCancelled() {
        return __classPrivateFieldGet(this, _CancelablePromise_isCancelled, "f");
    }
}
exports.CancelablePromise = CancelablePromise;
_CancelablePromise_isResolved = new WeakMap(), _CancelablePromise_isRejected = new WeakMap(), _CancelablePromise_isCancelled = new WeakMap(), _CancelablePromise_cancelHandlers = new WeakMap(), _CancelablePromise_promise = new WeakMap(), _CancelablePromise_resolve = new WeakMap(), _CancelablePromise_reject = new WeakMap(), Symbol.toStringTag;

},{}],6:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpenAPI = void 0;
exports.OpenAPI = {
    BASE: 'https://api.tradestation.com',
    VERSION: '',
    WITH_CREDENTIALS: false,
    CREDENTIALS: 'include',
    TOKEN: undefined,
    USERNAME: undefined,
    PASSWORD: undefined,
    HEADERS: undefined,
    ENCODE_PATH: undefined,
};

},{}],7:[function(require,module,exports){
(function (Buffer){(function (){
"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
//@ts-nocheck
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.request = exports.sendRequest = void 0;
const ApiError_1 = require("./ApiError");
const CancelablePromise_1 = require("./CancelablePromise");
const isDefined = (value) => {
    return value !== undefined && value !== null;
};
const isString = (value) => {
    return typeof value === 'string';
};
const isStringWithValue = (value) => {
    return isString(value) && value !== '';
};
const isBlob = (value) => {
    return (typeof value === 'object' &&
        typeof value.type === 'string' &&
        typeof value.stream === 'function' &&
        typeof value.arrayBuffer === 'function' &&
        typeof value.constructor === 'function' &&
        typeof value.constructor.name === 'string' &&
        /^(Blob|File)$/.test(value.constructor.name) &&
        /^(Blob|File)$/.test(value[Symbol.toStringTag]));
};
const isFormData = (value) => {
    return value instanceof FormData;
};
const base64 = (str) => {
    try {
        return btoa(str);
    }
    catch (err) {
        // @ts-ignore
        return Buffer.from(str).toString('base64');
    }
};
const getQueryString = (params) => {
    const qs = [];
    const append = (key, value) => {
        qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
    };
    const process = (key, value) => {
        if (isDefined(value)) {
            if (Array.isArray(value)) {
                value.forEach((v) => {
                    process(key, v);
                });
            }
            else if (typeof value === 'object') {
                Object.entries(value).forEach(([k, v]) => {
                    process(`${key}[${k}]`, v);
                });
            }
            else {
                append(key, value);
            }
        }
    };
    Object.entries(params).forEach(([key, value]) => {
        process(key, value);
    });
    if (qs.length > 0) {
        return `?${qs.join('&')}`;
    }
    return '';
};
const getUrl = (config, options) => __awaiter(void 0, void 0, void 0, function* () {
    const encoder = config.ENCODE_PATH || encodeURI;
    const path = options.url
        .replace('{api-version}', config.VERSION)
        .replace(/{(.*?)}/g, (substring, group) => {
        var _a;
        if ((_a = options.path) === null || _a === void 0 ? void 0 : _a.hasOwnProperty(group)) {
            return encoder(String(options.path[group]));
        }
        return substring;
    });
    const base = yield resolve(options, config.BASE);
    const url = `${base}${path}`;
    if (options.query) {
        return `${url}${getQueryString(options.query)}`;
    }
    return url;
});
const getFormData = (options) => {
    if (options.formData) {
        const formData = new FormData();
        const process = (key, value) => {
            if (isString(value) || isBlob(value)) {
                formData.append(key, value);
            }
            else {
                formData.append(key, JSON.stringify(value));
            }
        };
        Object.entries(options.formData)
            .filter(([_, value]) => isDefined(value))
            .forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach((v) => process(key, v));
            }
            else {
                process(key, value);
            }
        });
        return formData;
    }
    return;
};
const resolve = (options, resolver) => __awaiter(void 0, void 0, void 0, function* () {
    if (typeof resolver === 'function') {
        return resolver(options);
    }
    return resolver;
});
const getHeaders = (config, options) => __awaiter(void 0, void 0, void 0, function* () {
    const token = yield resolve(options, config.TOKEN);
    const username = yield resolve(options, config.USERNAME);
    const password = yield resolve(options, config.PASSWORD);
    const additionalHeaders = yield resolve(options, config.HEADERS);
    const headers = Object.entries(Object.assign(Object.assign({ Accept: 'application/json' }, additionalHeaders), options.headers))
        .filter(([_, value]) => isDefined(value))
        .reduce((headers, [key, value]) => (Object.assign(Object.assign({}, headers), { [key]: String(value) })), {});
    if (isStringWithValue(token)) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    if (isStringWithValue(username) && isStringWithValue(password)) {
        const credentials = base64(`${username}:${password}`);
        headers['Authorization'] = `Basic ${credentials}`;
    }
    if (options.body) {
        if (options.mediaType) {
            headers['Content-Type'] = options.mediaType;
        }
        else if (isBlob(options.body)) {
            headers['Content-Type'] = options.body.type || 'application/octet-stream';
        }
        else if (isString(options.body)) {
            headers['Content-Type'] = 'text/plain';
        }
        else if (!isFormData(options.body)) {
            headers['Content-Type'] = 'application/json';
        }
    }
    return new Headers(headers);
});
const getRequestBody = (options) => {
    var _a;
    if (options.body) {
        if ((_a = options.mediaType) === null || _a === void 0 ? void 0 : _a.includes('/json')) {
            return JSON.stringify(options.body);
        }
        else if (isString(options.body) || isBlob(options.body) || isFormData(options.body)) {
            return options.body;
        }
        else {
            return JSON.stringify(options.body);
        }
    }
    return;
};
const sendRequest = (config, options, url, body, formData, headers, onCancel) => __awaiter(void 0, void 0, void 0, function* () {
    const controller = new AbortController();
    const request = {
        headers,
        body: body !== null && body !== void 0 ? body : formData,
        method: options.method,
        signal: controller.signal,
    };
    if (config.WITH_CREDENTIALS) {
        request.credentials = config.CREDENTIALS;
    }
    onCancel(() => controller.abort());
    const response = yield fetch(url, request);
    response.abort = () => controller.abort();
    return response;
});
exports.sendRequest = sendRequest;
const getResponseHeader = (response, responseHeader) => {
    if (responseHeader) {
        const content = response.headers.get(responseHeader);
        if (isString(content)) {
            return content;
        }
    }
    return;
};
const getResponseBody = (response) => __awaiter(void 0, void 0, void 0, function* () {
    if (response.status !== 204) {
        try {
            const contentType = response.headers.get('Content-Type');
            if (contentType) {
                const isJSON = contentType.toLowerCase().startsWith('application/json');
                if (isJSON) {
                    return yield response.json();
                }
                else {
                    return yield response.text();
                }
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    return;
});
const catchErrorCodes = (options, result) => {
    const errors = Object.assign({ 400: 'Bad Request', 401: 'Unauthorized', 403: 'Forbidden', 404: 'Not Found', 500: 'Internal Server Error', 502: 'Bad Gateway', 503: 'Service Unavailable' }, options.errors);
    const error = errors[result.status];
    if (error) {
        throw new ApiError_1.ApiError(result, error);
    }
    if (!result.ok) {
        throw new ApiError_1.ApiError(result, 'Generic Error');
    }
};
function waitFor(check, updateMs = 100, timeoutMs = 10000) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                clearInterval(interval);
                reject(new Error('Waiting timeout'));
            }, timeoutMs);
            const interval = setInterval(() => {
                if (check()) {
                    clearTimeout(timeout);
                    clearInterval(interval);
                    resolve();
                }
            }, updateMs);
        });
    });
}
/**
 * Request method
 * @param config The OpenAPI configuration object
 * @param options The request options from the service
 * @returns CancelablePromise<T>
 * @throws ApiError
 */
const request = (config, options) => {
    return new CancelablePromise_1.CancelablePromise((resolve, reject, onCancel) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const url = yield getUrl(config, options);
            const formData = getFormData(options);
            const body = getRequestBody(options);
            const headers = yield getHeaders(config, options);
            if (!onCancel.isCancelled) {
                const response = yield (0, exports.sendRequest)(config, options, url, body, formData, headers, onCancel);
                if (isStreamResponse(response)) {
                    const stream = {
                        isStopped: false,
                        stop() {
                            this.isStopped = true;
                        },
                        onMessage: undefined,
                        onDone: undefined,
                    };
                    readStream(stream, response, onCancel);
                    resolve(stream);
                }
                else {
                    const responseBody = yield getResponseBody(response);
                    const responseHeader = getResponseHeader(response, options.responseHeader);
                    const result = {
                        url,
                        ok: response.ok,
                        status: response.status,
                        statusText: response.statusText,
                        body: responseHeader !== null && responseHeader !== void 0 ? responseHeader : responseBody,
                    };
                    catchErrorCodes(options, result);
                    resolve(result.body);
                }
            }
        }
        catch (error) {
            reject(error);
        }
    }));
};
exports.request = request;
const isStreamResponse = (response) => {
    const contentType = response.headers.get('Content-Type');
    // console.log(`${url} ${contentType}`);
    return (response &&
        response.body &&
        (contentType === 'application/vnd.tradestation.streams.v2+json' ||
            contentType === 'application/vnd.tradestation.streams+json'));
};
const readStream = (stream, response, onCancel) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = '';
        yield waitFor(() => !!stream.onMessage);
        while (true) {
            if (stream.isStopped) {
                // console.log(`${url} is stopped`);
                response.abort();
                if (stream.onDone)
                    stream.onDone();
                break;
            }
            if (onCancel.isCancelled) {
                // console.log(`${url} is canceled`);
                if (stream.onDone)
                    stream.onDone();
                break;
            }
            const result = yield reader.read();
            // console.log(`${url} new message`);
            const chunk = decoder.decode(result.value || new Uint8Array(), { stream: !result.done });
            buffer += chunk;
            const parts = buffer.split('\n');
            // console.log(`${url} ${JSON.stringify(parts)}`);
            buffer = parts[parts.length - 1];
            parts.slice(0, -1).forEach((msg) => {
                try {
                    // console.log(`${url} ${msg}`);
                    // console.log(onCancel);
                    // console.log(stream);
                    stream.onMessage(null, JSON.parse(msg));
                }
                catch (error) {
                    stream.onMessage(error);
                }
            });
            if (result.done) {
                // console.log(`${url} done`);
                if (stream.onDone)
                    stream.onDone();
                break;
            }
        }
    }
    catch (error) {
        stream.onMessage(error);
    }
});

}).call(this)}).call(this,require("buffer").Buffer)
},{"./ApiError":4,"./CancelablePromise":5,"buffer":2}],8:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublicService = exports.OrderExecutionService = exports.MarketDataV2Service = exports.MarketDataService = exports.BrokerageService = exports.Status = exports.RiskRewardAnalysisInputLeg = exports.QuantityFormat = exports.PriceFormat = exports.PositionDirection = exports.Position = exports.OrderType = exports.OrderRequestDefinition = exports.OrderLeg = exports.OrderConfirmResponse = exports.OrderConfirmRequestDefinition = exports.MarketActivationRules = exports.MarketActivationRuleDefinition = exports.AssetType = exports.AdvancedOrderType = exports.OpenAPI = exports.CancelError = exports.CancelablePromise = exports.ApiError = void 0;
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
var ApiError_1 = require("./core/ApiError");
Object.defineProperty(exports, "ApiError", { enumerable: true, get: function () { return ApiError_1.ApiError; } });
var CancelablePromise_1 = require("./core/CancelablePromise");
Object.defineProperty(exports, "CancelablePromise", { enumerable: true, get: function () { return CancelablePromise_1.CancelablePromise; } });
Object.defineProperty(exports, "CancelError", { enumerable: true, get: function () { return CancelablePromise_1.CancelError; } });
var OpenAPI_1 = require("./core/OpenAPI");
Object.defineProperty(exports, "OpenAPI", { enumerable: true, get: function () { return OpenAPI_1.OpenAPI; } });
var AdvancedOrderType_1 = require("./models/AdvancedOrderType");
Object.defineProperty(exports, "AdvancedOrderType", { enumerable: true, get: function () { return AdvancedOrderType_1.AdvancedOrderType; } });
var AssetType_1 = require("./models/AssetType");
Object.defineProperty(exports, "AssetType", { enumerable: true, get: function () { return AssetType_1.AssetType; } });
var MarketActivationRuleDefinition_1 = require("./models/MarketActivationRuleDefinition");
Object.defineProperty(exports, "MarketActivationRuleDefinition", { enumerable: true, get: function () { return MarketActivationRuleDefinition_1.MarketActivationRuleDefinition; } });
var MarketActivationRules_1 = require("./models/MarketActivationRules");
Object.defineProperty(exports, "MarketActivationRules", { enumerable: true, get: function () { return MarketActivationRules_1.MarketActivationRules; } });
var OrderConfirmRequestDefinition_1 = require("./models/OrderConfirmRequestDefinition");
Object.defineProperty(exports, "OrderConfirmRequestDefinition", { enumerable: true, get: function () { return OrderConfirmRequestDefinition_1.OrderConfirmRequestDefinition; } });
var OrderConfirmResponse_1 = require("./models/OrderConfirmResponse");
Object.defineProperty(exports, "OrderConfirmResponse", { enumerable: true, get: function () { return OrderConfirmResponse_1.OrderConfirmResponse; } });
var OrderLeg_1 = require("./models/OrderLeg");
Object.defineProperty(exports, "OrderLeg", { enumerable: true, get: function () { return OrderLeg_1.OrderLeg; } });
var OrderRequestDefinition_1 = require("./models/OrderRequestDefinition");
Object.defineProperty(exports, "OrderRequestDefinition", { enumerable: true, get: function () { return OrderRequestDefinition_1.OrderRequestDefinition; } });
var OrderType_1 = require("./models/OrderType");
Object.defineProperty(exports, "OrderType", { enumerable: true, get: function () { return OrderType_1.OrderType; } });
var Position_1 = require("./models/Position");
Object.defineProperty(exports, "Position", { enumerable: true, get: function () { return Position_1.Position; } });
var PositionDirection_1 = require("./models/PositionDirection");
Object.defineProperty(exports, "PositionDirection", { enumerable: true, get: function () { return PositionDirection_1.PositionDirection; } });
var PriceFormat_1 = require("./models/PriceFormat");
Object.defineProperty(exports, "PriceFormat", { enumerable: true, get: function () { return PriceFormat_1.PriceFormat; } });
var QuantityFormat_1 = require("./models/QuantityFormat");
Object.defineProperty(exports, "QuantityFormat", { enumerable: true, get: function () { return QuantityFormat_1.QuantityFormat; } });
var RiskRewardAnalysisInputLeg_1 = require("./models/RiskRewardAnalysisInputLeg");
Object.defineProperty(exports, "RiskRewardAnalysisInputLeg", { enumerable: true, get: function () { return RiskRewardAnalysisInputLeg_1.RiskRewardAnalysisInputLeg; } });
var Status_1 = require("./models/Status");
Object.defineProperty(exports, "Status", { enumerable: true, get: function () { return Status_1.Status; } });
var BrokerageService_1 = require("./services/BrokerageService");
Object.defineProperty(exports, "BrokerageService", { enumerable: true, get: function () { return BrokerageService_1.BrokerageService; } });
var MarketDataService_1 = require("./services/MarketDataService");
Object.defineProperty(exports, "MarketDataService", { enumerable: true, get: function () { return MarketDataService_1.MarketDataService; } });
var MarketDataV2Service_1 = require("./services/MarketDataV2Service");
Object.defineProperty(exports, "MarketDataV2Service", { enumerable: true, get: function () { return MarketDataV2Service_1.MarketDataV2Service; } });
var OrderExecutionService_1 = require("./services/OrderExecutionService");
Object.defineProperty(exports, "OrderExecutionService", { enumerable: true, get: function () { return OrderExecutionService_1.OrderExecutionService; } });
var PublicService_1 = require("./services/PublicService");
Object.defineProperty(exports, "PublicService", { enumerable: true, get: function () { return PublicService_1.PublicService; } });

},{"./core/ApiError":4,"./core/CancelablePromise":5,"./core/OpenAPI":6,"./models/AdvancedOrderType":9,"./models/AssetType":10,"./models/MarketActivationRuleDefinition":11,"./models/MarketActivationRules":12,"./models/OrderConfirmRequestDefinition":13,"./models/OrderConfirmResponse":14,"./models/OrderLeg":15,"./models/OrderRequestDefinition":16,"./models/OrderType":17,"./models/Position":18,"./models/PositionDirection":19,"./models/PriceFormat":20,"./models/QuantityFormat":21,"./models/RiskRewardAnalysisInputLeg":22,"./models/Status":23,"./services/BrokerageService":24,"./services/MarketDataService":25,"./services/MarketDataV2Service":26,"./services/OrderExecutionService":27,"./services/PublicService":28}],9:[function(require,module,exports){
"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdvancedOrderType = void 0;
/**
 * The type of the advanced order.
 */
var AdvancedOrderType;
(function (AdvancedOrderType) {
    AdvancedOrderType["NORMAL"] = "NORMAL";
    AdvancedOrderType["BRK"] = "BRK";
    AdvancedOrderType["OCO"] = "OCO";
})(AdvancedOrderType = exports.AdvancedOrderType || (exports.AdvancedOrderType = {}));

},{}],10:[function(require,module,exports){
"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetType = void 0;
/**
 * The type of financial instrument that a symbol represents.
 */
var AssetType;
(function (AssetType) {
    AssetType["UNKNOWN"] = "UNKNOWN";
    AssetType["STOCK"] = "STOCK";
    AssetType["STOCKOPTION"] = "STOCKOPTION";
    AssetType["FUTURE"] = "FUTURE";
    AssetType["FUTUREOPTION"] = "FUTUREOPTION";
    AssetType["FOREX"] = "FOREX";
    AssetType["CURRENCYOPTION"] = "CURRENCYOPTION";
    AssetType["INDEX"] = "INDEX";
    AssetType["INDEXOPTION"] = "INDEXOPTION";
    AssetType["MUTUALFUND"] = "MUTUALFUND";
    AssetType["MONEYMARKETFUND"] = "MONEYMARKETFUND";
    AssetType["BOND"] = "BOND";
    AssetType["CRYPTO"] = "CRYPTO";
})(AssetType = exports.AssetType || (exports.AssetType = {}));

},{}],11:[function(require,module,exports){
"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarketActivationRuleDefinition = void 0;
var MarketActivationRuleDefinition;
(function (MarketActivationRuleDefinition) {
    /**
     * Type of the activation rule. Currently only support "Price"
 *
     */
    let RuleType;
    (function (RuleType) {
        RuleType["PRICE"] = "Price";
    })(RuleType = MarketActivationRuleDefinition.RuleType || (MarketActivationRuleDefinition.RuleType = {}));
    /**
     * The predicate comparison for the market rule type. E.g. Lt (less than).
 *
     */
    let Predicate;
    (function (Predicate) {
        Predicate["LT"] = "Lt";
        Predicate["LTE"] = "Lte";
        Predicate["GT"] = "Gt";
        Predicate["GTE"] = "Gte";
    })(Predicate = MarketActivationRuleDefinition.Predicate || (MarketActivationRuleDefinition.Predicate = {}));
    /**
     * The ticks behavior for the activation rule.
 *
     */
    let TriggerKey;
    (function (TriggerKey) {
        TriggerKey["STT"] = "STT";
        TriggerKey["STTN"] = "STTN";
        TriggerKey["SBA"] = "SBA";
        TriggerKey["SAB"] = "SAB";
        TriggerKey["DTT"] = "DTT";
        TriggerKey["DTTN"] = "DTTN";
        TriggerKey["DBA"] = "DBA";
        TriggerKey["DAB"] = "DAB";
        TriggerKey["TTT"] = "TTT";
        TriggerKey["TTTN"] = "TTTN";
        TriggerKey["TBA"] = "TBA";
        TriggerKey["TAB"] = "TAB";
    })(TriggerKey = MarketActivationRuleDefinition.TriggerKey || (MarketActivationRuleDefinition.TriggerKey = {}));
    /**
     * Relation with the previous activation rule when given a list of MarketActivationRules. Ignored for the first MarketActivationRule.
 *
     */
    let LogicOperator;
    (function (LogicOperator) {
        LogicOperator["AND"] = "And";
        LogicOperator["OR"] = "Or";
    })(LogicOperator = MarketActivationRuleDefinition.LogicOperator || (MarketActivationRuleDefinition.LogicOperator = {}));
})(MarketActivationRuleDefinition = exports.MarketActivationRuleDefinition || (exports.MarketActivationRuleDefinition = {}));

},{}],12:[function(require,module,exports){
"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarketActivationRules = void 0;
var MarketActivationRules;
(function (MarketActivationRules) {
    /**
     * The ticks behavior for the activation rule. Rule descriptions can be obtained from [Get Activation Triggers](#operation/GetActivationTriggers).
     */
    let TriggerKey;
    (function (TriggerKey) {
        TriggerKey["STT"] = "STT";
        TriggerKey["STTN"] = "STTN";
        TriggerKey["SBA"] = "SBA";
        TriggerKey["SAB"] = "SAB";
        TriggerKey["DTT"] = "DTT";
        TriggerKey["DTTN"] = "DTTN";
        TriggerKey["DBA"] = "DBA";
        TriggerKey["DAB"] = "DAB";
        TriggerKey["TTT"] = "TTT";
        TriggerKey["TTTN"] = "TTTN";
        TriggerKey["TBA"] = "TBA";
        TriggerKey["TAB"] = "TAB";
    })(TriggerKey = MarketActivationRules.TriggerKey || (MarketActivationRules.TriggerKey = {}));
    /**
     * Relation with the previous activation rule when given a list of MarketActivationRules. Ignored for the first MarketActivationRule.
     */
    let LogicOperator;
    (function (LogicOperator) {
        LogicOperator["AND"] = "And";
        LogicOperator["OR"] = "Or";
    })(LogicOperator = MarketActivationRules.LogicOperator || (MarketActivationRules.LogicOperator = {}));
})(MarketActivationRules = exports.MarketActivationRules || (exports.MarketActivationRules = {}));

},{}],13:[function(require,module,exports){
"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderConfirmRequestDefinition = void 0;
var OrderConfirmRequestDefinition;
(function (OrderConfirmRequestDefinition) {
    let AssetType;
    (function (AssetType) {
        AssetType["EQ"] = "EQ";
        AssetType["FU"] = "FU";
        AssetType["OP"] = "OP";
    })(AssetType = OrderConfirmRequestDefinition.AssetType || (OrderConfirmRequestDefinition.AssetType = {}));
    /**
     * Allowed durations vary by Asset Type
 * * DAY - Day, valid until the end of the regular trading session.
 * * DYP - Day Plus; valid until the end of the extended trading session
 * * GTC - Good till canceled
 * * GCP - Good till canceled plus
 * * GTD - Good through date
 * * GDP - Good through date plus
 * * OPG - At the opening; only valid for listed stocks at the opening session Price
 * * CLO - On Close; orders that target the closing session of an exchange.
 * * IOC - Immediate or Cancel; filled immediately or canceled, partial fills are accepted
 * * FOK - Fill or Kill; orders are filled entirely or canceled, partial fills are not accepted
 * * 1 or 1 MIN - 1 minute; expires after the 1 minute
 * * 3 or 3 MIN - 3 minutes; expires after the 3 minutes
 * * 5 or 5 MIN - 5 minutes; expires after the 5 minutes
 *
     */
    let Duration;
    (function (Duration) {
        Duration["DAY"] = "DAY";
        Duration["DYP"] = "DYP";
        Duration["GTC"] = "GTC";
        Duration["GCP"] = "GCP";
        Duration["GTD"] = "GTD";
        Duration["GDP"] = "GDP";
        Duration["OPG"] = "OPG";
        Duration["CLO"] = "CLO";
        Duration["IOC"] = "IOC";
        Duration["FOK"] = "FOK";
        Duration[Duration["_1"] = 1] = "_1";
        Duration["_1_MIN"] = "1 MIN";
        Duration[Duration["_3"] = 3] = "_3";
        Duration["_3_MIN"] = "3 MIN";
        Duration[Duration["_5"] = 5] = "_5";
        Duration["_5_MIN"] = "5 MIN";
    })(Duration = OrderConfirmRequestDefinition.Duration || (OrderConfirmRequestDefinition.Duration = {}));
    let OrderType;
    (function (OrderType) {
        OrderType["LIMIT"] = "Limit";
        OrderType["MARKET"] = "Market";
        OrderType["STOP_LIMIT"] = "StopLimit";
        OrderType["STOP_MARKET"] = "StopMarket";
    })(OrderType = OrderConfirmRequestDefinition.OrderType || (OrderConfirmRequestDefinition.OrderType = {}));
    /**
     * Conveys the intent of the trade
 * * BUY - `equities` and `futures`
 * * SELL - `equities` and `futures`
 * * BUYTOCOVER - `equities`
 * * SELLSHORT - `equities`
 * * BUYTOOPEN - `options`
 * * BUYTOCLOSE - `options`
 * * SELLTOOPEN - `options`
 * * SELLTOCLOSE - `options`
 *
     */
    let TradeAction;
    (function (TradeAction) {
        TradeAction["BUY"] = "BUY";
        TradeAction["SELL"] = "SELL";
        TradeAction["BUYTOCOVER"] = "BUYTOCOVER";
        TradeAction["SELLSHORT"] = "SELLSHORT";
        TradeAction["BUYTOOPEN"] = "BUYTOOPEN";
        TradeAction["BUYTOCLOSE"] = "BUYTOCLOSE";
        TradeAction["SELLTOOPEN"] = "SELLTOOPEN";
        TradeAction["SELLTOCLOSE"] = "SELLTOCLOSE";
    })(TradeAction = OrderConfirmRequestDefinition.TradeAction || (OrderConfirmRequestDefinition.TradeAction = {}));
})(OrderConfirmRequestDefinition = exports.OrderConfirmRequestDefinition || (exports.OrderConfirmRequestDefinition = {}));

},{}],14:[function(require,module,exports){
"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderConfirmResponse = void 0;
var OrderConfirmResponse;
(function (OrderConfirmResponse) {
    /**
     * Indicates the category of the order.
     */
    let OrderAssetCategory;
    (function (OrderAssetCategory) {
        OrderAssetCategory["EQUITY"] = "EQUITY";
        OrderAssetCategory["STOCKOPTION"] = "STOCKOPTION";
        OrderAssetCategory["FUTURE"] = "FUTURE";
        OrderAssetCategory["CRYPTO"] = "CRYPTO";
    })(OrderAssetCategory = OrderConfirmResponse.OrderAssetCategory || (OrderConfirmResponse.OrderAssetCategory = {}));
})(OrderConfirmResponse = exports.OrderConfirmResponse || (exports.OrderConfirmResponse = {}));

},{}],15:[function(require,module,exports){
"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderLeg = void 0;
var OrderLeg;
(function (OrderLeg) {
    /**
     * Indicates the asset type of the order.
     */
    let AssetType;
    (function (AssetType) {
        AssetType["UNKNOWN"] = "UNKNOWN";
        AssetType["STOCK"] = "STOCK";
        AssetType["STOCKOPTION"] = "STOCKOPTION";
        AssetType["FUTURE"] = "FUTURE";
        AssetType["FUTUREOPTION"] = "FUTUREOPTION";
        AssetType["FOREX"] = "FOREX";
        AssetType["CURRENCYOPTION"] = "CURRENCYOPTION";
        AssetType["INDEX"] = "INDEX";
        AssetType["INDEXOPTION"] = "INDEXOPTION";
        AssetType["CRYPTO"] = "CRYPTO";
    })(AssetType = OrderLeg.AssetType || (OrderLeg.AssetType = {}));
})(OrderLeg = exports.OrderLeg || (exports.OrderLeg = {}));

},{}],16:[function(require,module,exports){
"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderRequestDefinition = void 0;
var OrderRequestDefinition;
(function (OrderRequestDefinition) {
    let AssetType;
    (function (AssetType) {
        AssetType["EQ"] = "EQ";
        AssetType["FU"] = "FU";
        AssetType["OP"] = "OP";
    })(AssetType = OrderRequestDefinition.AssetType || (OrderRequestDefinition.AssetType = {}));
    /**
     * Allowed durations vary by Asset Type
 * * DAY - Day, valid until the end of the regular trading session.
 * * DYP - Day Plus; valid until the end of the extended trading session
 * * GTC - Good till canceled
 * * GCP - Good till canceled plus
 * * GTD - Good through date
 * * GDP - Good through date plus
 * * OPG - At the opening; only valid for listed stocks at the opening session Price
 * * CLO - On Close; orders that target the closing session of an exchange.
 * * IOC - Immediate or Cancel; filled immediately or canceled, partial fills are accepted
 * * FOK - Fill or Kill; orders are filled entirely or canceled, partial fills are not accepted
 * * 1 or 1 MIN - 1 minute; expires after the 1 minute
 * * 3 or 3 MIN - 3 minutes; expires after the 3 minutes
 * * 5 or 5 MIN - 5 minutes; expires after the 5 minutes
 *
     */
    let Duration;
    (function (Duration) {
        Duration["DAY"] = "DAY";
        Duration["DYP"] = "DYP";
        Duration["GTC"] = "GTC";
        Duration["GCP"] = "GCP";
        Duration["GTD"] = "GTD";
        Duration["GDP"] = "GDP";
        Duration["OPG"] = "OPG";
        Duration["CLO"] = "CLO";
        Duration["IOC"] = "IOC";
        Duration["FOK"] = "FOK";
        Duration[Duration["_1"] = 1] = "_1";
        Duration["_1_MIN"] = "1 MIN";
        Duration[Duration["_3"] = 3] = "_3";
        Duration["_3_MIN"] = "3 MIN";
        Duration[Duration["_5"] = 5] = "_5";
        Duration["_5_MIN"] = "5 MIN";
    })(Duration = OrderRequestDefinition.Duration || (OrderRequestDefinition.Duration = {}));
    let OrderType;
    (function (OrderType) {
        OrderType["LIMIT"] = "Limit";
        OrderType["MARKET"] = "Market";
        OrderType["STOP_LIMIT"] = "StopLimit";
        OrderType["STOP_MARKET"] = "StopMarket";
    })(OrderType = OrderRequestDefinition.OrderType || (OrderRequestDefinition.OrderType = {}));
    /**
     * Conveys the intent of the trade
 * * BUY - `equities` and `futures`
 * * SELL - `equities` and `futures`
 * * BUYTOCOVER - `equities`
 * * SELLSHORT - `equities`
 * * BUYTOOPEN - `options`
 * * BUYTOCLOSE - `options`
 * * SELLTOOPEN - `options`
 * * SELLTOCLOSE - `options`
 *
     */
    let TradeAction;
    (function (TradeAction) {
        TradeAction["BUY"] = "BUY";
        TradeAction["SELL"] = "SELL";
        TradeAction["BUYTOCOVER"] = "BUYTOCOVER";
        TradeAction["SELLSHORT"] = "SELLSHORT";
        TradeAction["BUYTOOPEN"] = "BUYTOOPEN";
        TradeAction["BUYTOCLOSE"] = "BUYTOCLOSE";
        TradeAction["SELLTOOPEN"] = "SELLTOOPEN";
        TradeAction["SELLTOCLOSE"] = "SELLTOCLOSE";
    })(TradeAction = OrderRequestDefinition.TradeAction || (OrderRequestDefinition.TradeAction = {}));
})(OrderRequestDefinition = exports.OrderRequestDefinition || (exports.OrderRequestDefinition = {}));

},{}],17:[function(require,module,exports){
"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderType = void 0;
/**
 * The order type of the order.
 */
var OrderType;
(function (OrderType) {
    OrderType["LIMIT"] = "Limit";
    OrderType["STOP_MARKET"] = "StopMarket";
    OrderType["MARKET"] = "Market";
    OrderType["STOP_LIMIT"] = "StopLimit";
})(OrderType = exports.OrderType || (exports.OrderType = {}));

},{}],18:[function(require,module,exports){
"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Position = void 0;
var Position;
(function (Position) {
    /**
     * Indicates the asset type of the position.
     */
    let AssetType;
    (function (AssetType) {
        AssetType["STOCK"] = "STOCK";
        AssetType["STOCKOPTION"] = "STOCKOPTION";
        AssetType["FUTURE"] = "FUTURE";
    })(AssetType = Position.AssetType || (Position.AssetType = {}));
})(Position = exports.Position || (exports.Position = {}));

},{}],19:[function(require,module,exports){
"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PositionDirection = void 0;
/**
 * Specifies if the position is Long or Short.
 *
 */
var PositionDirection;
(function (PositionDirection) {
    PositionDirection["LONG"] = "Long";
    PositionDirection["SHORT"] = "Short";
})(PositionDirection = exports.PositionDirection || (exports.PositionDirection = {}));

},{}],20:[function(require,module,exports){
"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceFormat = void 0;
var PriceFormat;
(function (PriceFormat) {
    /**
     * The format of the price.
     */
    let Format;
    (function (Format) {
        Format["DECIMAL"] = "Decimal";
        Format["FRACTION"] = "Fraction";
        Format["SUB_FRACTION"] = "SubFraction";
    })(Format = PriceFormat.Format || (PriceFormat.Format = {}));
    /**
     * The style of increment for price movements.
     */
    let IncrementStyle;
    (function (IncrementStyle) {
        IncrementStyle["SIMPLE"] = "Simple";
        IncrementStyle["SCHEDULE"] = "Schedule";
    })(IncrementStyle = PriceFormat.IncrementStyle || (PriceFormat.IncrementStyle = {}));
})(PriceFormat = exports.PriceFormat || (exports.PriceFormat = {}));

},{}],21:[function(require,module,exports){
"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuantityFormat = void 0;
var QuantityFormat;
(function (QuantityFormat) {
    /**
     * The format of the quantity.
     */
    let Format;
    (function (Format) {
        Format["DECIMAL"] = "Decimal";
    })(Format = QuantityFormat.Format || (QuantityFormat.Format = {}));
})(QuantityFormat = exports.QuantityFormat || (exports.QuantityFormat = {}));

},{}],22:[function(require,module,exports){
"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RiskRewardAnalysisInputLeg = void 0;
var RiskRewardAnalysisInputLeg;
(function (RiskRewardAnalysisInputLeg) {
    /**
     * The kind of trade to place for this leg. Value values are `BUY` and `SELL`.
     */
    let TradeAction;
    (function (TradeAction) {
        TradeAction["BUY"] = "BUY";
        TradeAction["SELL"] = "SELL";
    })(TradeAction = RiskRewardAnalysisInputLeg.TradeAction || (RiskRewardAnalysisInputLeg.TradeAction = {}));
})(RiskRewardAnalysisInputLeg = exports.RiskRewardAnalysisInputLeg || (exports.RiskRewardAnalysisInputLeg = {}));

},{}],23:[function(require,module,exports){
"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Status = void 0;
/**
 * Status defines an Status type
 * - ACK - Received
 * - BRO - Broken
 * - CAN - Canceled
 * - EXP - Expired
 * - FLL - Filled
 * - FLP - Partial Fill (UROut)
 * - FPR - Partial Fill (Alive)
 * - LAT - Too Late to Cancel
 * - OPN - Sent
 * - OUT - UROut
 * - REJ - Rejected
 * - UCH - Replaced
 * - UCN - Cancel Sent
 * - TSC – Trade Server Canceled
 * - RJC – Cancel Request Rejected
 * - DON – Queued
 * - RSN - Replace Sent
 * - CND – Condition Met
 * - OSO - OSO Order
 * - SUS - Suspended
 */
var Status;
(function (Status) {
    Status["ACK"] = "ACK";
    Status["BRO"] = "BRO";
    Status["CAN"] = "CAN";
    Status["EXP"] = "EXP";
    Status["FLL"] = "FLL";
    Status["FLP"] = "FLP";
    Status["FPR"] = "FPR";
    Status["LAT"] = "LAT";
    Status["OPN"] = "OPN";
    Status["OUT"] = "OUT";
    Status["REJ"] = "REJ";
    Status["UCH"] = "UCH";
    Status["UCN"] = "UCN";
    Status["TSC"] = "TSC";
    Status["RJC"] = "RJC";
    Status["DON"] = "DON";
    Status["RSN"] = "RSN";
    Status["CND"] = "CND";
    Status["OSO"] = "OSO";
    Status["SUS"] = "SUS";
})(Status = exports.Status || (exports.Status = {}));

},{}],24:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrokerageService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class BrokerageService {
    /**
     * Get Accounts
     * Fetches the list of Brokerage Accounts available for the current user.
     * @returns Accounts Accounts
     * @throws ApiError
     */
    static getAccounts() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/brokerage/accounts',
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Get Balances
     * Fetches the brokerage account `Balances` for one or more given accounts. Request valid for `Cash`, `Margin`, `Futures`, and `DVP` account types.
     * @param accounts List of valid Account IDs for the authenticated user in comma separated format; for example `"61999124,68910124"`. 1 to 25 account keys can be specified, comma separated. Recommended batch size is 10.
     * @returns Balances Balances
     * @throws ApiError
     */
    static getBalances(accounts) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/brokerage/accounts/{accounts}/balances',
            path: {
                'accounts': accounts,
            },
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Get Balances BOD
     * Fetches the Beginning of Day Balances for the given Accounts. Request valid for `Cash`, `Margin`, `Futures`, and  `DVP` account types.
     * @param accounts List of valid Account IDs for the authenticated user in comma separated format; for example `"61999124,68910124"`. 1 to 25 account keys can be specified, comma separated. Recommended batch size is 10.
     * @returns BalancesBOD BalancesBOD
     * @throws ApiError
     */
    static getBalancesBod(accounts) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/brokerage/accounts/{accounts}/bodbalances',
            path: {
                'accounts': accounts,
            },
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Get Historical Orders
     * Fetches Historical Orders for the given Accounts, sorted in descending order of time placed for open and time executed for closed. Request valid for all account types.
     * @param accounts List of valid Account IDs for the authenticated user in comma separated format; for example `"61999124,68910124"`. 1 to 25 account keys can be specified, comma separated. Recommended batch size is 10.
     * @param since Historical orders since date. For example `"2006-01-13","01-13-2006","2006/01/13","01/13/2006"`. Limited to 90 days prior to the current date.
     * @returns Orders Orders
     * @throws ApiError
     */
    static getHistoricalOrders(accounts, since) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/brokerage/accounts/{accounts}/historicalorders',
            path: {
                'accounts': accounts,
            },
            query: {
                'since': since,
            },
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Get Orders
     * Fetches today's orders for the given Accounts, sorted in descending order of time placed for open and time executed for closed. Request valid for all account types.
     * @param accounts List of valid Account IDs for the authenticated user in comma separated format; for example `"61999124,68910124"`. 1 to 25 account keys can be specified, comma separated. Recommended batch size is 10.
     * @returns Orders Orders
     * @throws ApiError
     */
    static getOrders(accounts) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/brokerage/accounts/{accounts}/orders',
            path: {
                'accounts': accounts,
            },
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Get Positions
     * Fetches positions for the given Accounts. Request valid for `Cash`, `Margin`, `Futures`, and `DVP` account types.
     * @param accounts List of valid Account IDs for the authenticated user in comma separated format; for example `"61999124,68910124"`. 1 to 25 account keys can be specified, comma separated. Recommended batch size is 10.
     * @returns Positions Positions
     * @throws ApiError
     */
    static getPositions(accounts) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/brokerage/accounts/{accounts}/positions',
            path: {
                'accounts': accounts,
            },
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Get Wallets
     * Fetches wallet information. Request valid for `Crypto` account types.
     * @param account A valid crypto Account ID for the authenticated user; for example `"61999124C"`.
     * @returns Wallets Wallets
     * @throws ApiError
     */
    static getWallets(account) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/brokerage/accounts/{account}/wallets',
            path: {
                'account': account,
            },
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Stream Wallets
     * Streams wallet information. Request valid for `Crypto` account types.
     * @param account A valid crypto Account ID for the authenticated user; for example `"61999124C"`.
     * @returns any WalletsStream
     * @throws ApiError
     */
    static streamWallets(account) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/brokerage/stream/accounts/{account}/wallets',
            path: {
                'account': account,
            },
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
}
exports.BrokerageService = BrokerageService;

},{"../core/OpenAPI":6,"../core/request":7}],25:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarketDataService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class MarketDataService {
    /**
     * Get Bars
     * Fetches marketdata bars for the given symbol, interval, and timeframe.
     * @param symbol The valid symbol string.
     * @param interval Default: `1`.  Interval that each bar will consist of -  for minute bars, the number of minutes aggregated in a single bar.  For crypto symbols, valid values are: `1`, `5`, `15`, `30`, `60`, `240`, `480`.  For bar units other than minute, value must be `1`.
     * @param unit Default: `Daily`. The unit of time for each bar interval. Valid values are: `Minute, Daily, Weekly, Monthly`.
     * @param barsback Default: `1`.  Number of bars back to fetch (or retrieve). The maximum number of bars back that a user can query is 57,600.
     * @param startdate Defaults to current timestamp. The start date formated as `YYYY-MM-DD`,`2020-04-20T18:00:00Z`.
     * @param sessiontemplate United States (US) stock market session templates, that extend bars returned to include
 * those outside of the regular trading session. Ignored for non-US equity symbols. Valid values are:
 * `USEQPre`, `USEQPost`, `USEQPreAndPost`, `Default`.
     * @returns Bars Bars
     * @throws ApiError
     */
    static getBars(symbol, interval, unit, barsback, startdate, sessiontemplate) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/marketdata/barcharts/{symbol}',
            path: {
                'symbol': symbol,
            },
            query: {
                'interval': interval,
                'unit': unit,
                'barsback': barsback,
                'startdate': startdate,
                'sessiontemplate': sessiontemplate,
            },
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Stream Bars
     * Streams marketdata bars for the given symbol, interval, and timeframe.
     * @param symbol The valid symbol string.
     * @param interval Interval that each bar will consist of -  for minute bars, the number of
 * minutes aggregated in a single bar.  For crypto symbols, valid values are:
 * `1`, `5`, `15`, `30`, `60`, `240`, `480`.  For bar units other than minute, value must be `1`.
     * @param unit Unit of time for each bar interval. Valid values are: `minute`, `daily`, `weekly`, `monthly`, and `second` (crypto only).
     * @param barsback The bars back - for crypto symbols, the max value is 500. for non-crypto the max value is 57600.
     * @param sessiontemplate United States (US) stock market session templates, that extend bars returned to include
 * those outside of the regular trading session. Ignored for non-US equity symbols. Valid values are:
 * `USEQPre`, `USEQPost`, `USEQPreAndPost`, `Default`.
     * @returns any Bar
     * @throws ApiError
     */
    static streamBars(symbol, interval = '1', unit = 'Daily', barsback, sessiontemplate) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/marketdata/stream/barcharts/{symbol}',
            path: {
                'symbol': symbol,
            },
            query: {
                'interval': interval,
                'unit': unit,
                'barsback': barsback,
                'sessiontemplate': sessiontemplate,
            },
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Get Quote Snapshots
     * Fetches a full snapshot of the latest Quote for the given Symbols. For realtime Quote updates, users should use the Quote Stream endpoint.
     * @param symbols List of valid symbols in comma separated format; for example `"MSFT,BTCUSD"`. No more than 50 symbols per request.
     * @returns QuoteSnapshot Quote
     * @throws ApiError
     */
    static getQuoteSnapshots(symbols) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/marketdata/quotes/{symbols}',
            path: {
                'symbols': symbols,
            },
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Stream Quotes
     * Streams Quote changes for one or more symbols.
     * @param symbols List of valid symbols in comma separated format; for example `"MSFT,BTCUSD"`. No more than 50 symbols per request.
     * @returns any QuoteStream
     * @throws ApiError
     */
    static getQuoteChangeStream(symbols) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/marketdata/stream/quotes/{symbols}',
            path: {
                'symbols': symbols,
            },
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Get Crypto Symbol Names
     * Fetches all crypto Symbol Names information.
     * @returns SymbolNames SymbolNames
     * @throws ApiError
     */
    static getCryptoSymbolNames() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/marketdata/symbollists/cryptopairs/symbolnames',
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Get Symbol Details
     * Fetches symbol details and formatting information for one or more symbols and relevant errors, if any. Use provided formatting objects to display provided prices and quantities from other API endpoints.
 *
 * * **Decimal** prices should be displayed as decimals. For instance, for a given price of `123.2` the price should be displayed as `123.20`.
 *
 * * **Fraction** price display formatting should be displayed with the `PriceFormat.Fraction` in the denominator of the fraction part of the given number. For instance, given a price of `534.5` and a `PriceFormat.Fraction` of `8`, the number should be shown as `534 4/8`.
 *
 * * For **SubFractional** price display formatting, the following equations can be used to derive the display format of `a'b.c` for a given price. For instance, given a price of `125.92969`, a `PriceFormat.Fraction` of `32` and a `PriceFormat.Subfraction` of `4`, the number should be displayed as `125'29.7`.
 * ```
 * x (Price)                   = 125.92969
 * y (PriceFormat.Fraction)    = 32
 * z (PriceFormat.SubFraction) = 4
 * ```
 * ```
 * a = trunc(x)
 * b = trunc(frac(x) * y)
 * c = trunc(((frac(x) - (b/y)) * z * y) / (z/10))
 * ```
     * @param symbols List of valid symbols in comma separated format; for example `"MSFT,BTCUSD"`, no more than 50 symbols per request.
     * @returns SymbolDetailsResponse SymbolDetailsResponse
     * @throws ApiError
     */
    static getSymbolDetails(symbols) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/marketdata/symbols/{symbols}',
            path: {
                'symbols': symbols,
            },
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Get Interest Rates
     * A public route that returns cryptocurrency interest rates.
     * @returns InterestRates GetInterestRates
     * @throws ApiError
     */
    static getInterestRates() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/marketdata/crypto/interestrates',
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Get Option Expirations
     * Get the available option contract expiration dates for the underlying symbol.
     * @param underlying The symbol for the underlying security on which the option contracts are based. The underlying symbol must be an equity or index.
     * @param strikePrice Strike price. If provided, only expirations for that strike price will be returned.
     * @returns Expirations Expirations
     * @throws ApiError
     */
    static getOptionExpirations(underlying, strikePrice) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/marketdata/options/expirations/{underlying}',
            path: {
                'underlying': underlying,
            },
            query: {
                'strikePrice': strikePrice,
            },
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Get Option Risk Reward
     * Analyze the risk vs. reward of a potential option trade. This endpoint is not applicable for option spread types with different expirations, such as Calendar and Diagonal.
     * @param requestBody
     * @returns RiskRewardAnalysisResult RiskRewardAnalysisResult
     * @throws ApiError
     */
    static getOptionRiskReward(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/v3/marketdata/options/riskreward',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Get Option Spread Types
     * Get the available spread types for option chains.
     * @returns SpreadTypes SpreadTypes
     * @throws ApiError
     */
    static getOptionSpreadTypes() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/marketdata/options/spreadtypes',
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Get Option Strikes
     * Get the available strike prices for a spread type and expiration date.
     * @param underlying The symbol for the underlying security on which the option contracts are based. The underlying symbol must be an equity or index.
     * @param spreadType The name of the spread type to get the strikes for. This value can be obtained from the [Get Option Spread Types](#operation/GetOptionSpreadTypes) endpoint.
     * @param strikeInterval Specifies the desired interval between the strike prices in a spread. It must be greater than or equal to 1. A value of 1 uses consecutive strikes; a value of 2 skips one between strikes; and so on.
     * @param expiration Date on which the option contract expires; must be a valid expiration date. Defaults to the next contract expiration date.
     * @param expiration2 Second contract expiration date required for Calendar and Diagonal spreads.
     * @returns Strikes Option strikes for the underlying equity or index specified in the endpoint path. An empty strikes collection will be returned if the underlying symbol has no options or if there are no options that satisfy the query parameters.
     * @throws ApiError
     */
    static getOptionStrikes(underlying, spreadType = 'Single', strikeInterval = 1, expiration, expiration2) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/marketdata/options/strikes/{underlying}',
            path: {
                'underlying': underlying,
            },
            query: {
                'spreadType': spreadType,
                'strikeInterval': strikeInterval,
                'expiration': expiration,
                'expiration2': expiration2,
            },
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Stream Option Chain
     * Stream a chain of option spreads for a given underlying symbol, spread type, and expiration.
 * A maximum of 10 concurrent streams is allowed.
 *
 * For options calculations, the WebAPI uses the following:
 * * 90 days for historical volatility of the underlying
 * * Bjerksund and Stensland option pricing model
 * * Ask price for price of the option
 *
     * @param underlying The symbol for the underlying security on which the option contracts are based.
     * @param expiration Date on which the option contract expires; must be a valid expiration date. Defaults to the next contract expiration date.
     * @param expiration2 Second contract expiration date required for Calendar and Diagonal spreads.
     * @param strikeProximity Specifies the number of spreads to display above and below the priceCenter.
     * @param spreadType Specifies the name of the spread type to use.
     * @param riskFreeRate The theoretical rate of return of an investment with zero risk. Defaults to the current quote for $IRX.X. The percentage rate should be specified as a decimal value. For example, to use 2% for the rate, pass in 0.02.
     * @param priceCenter Specifies the strike price center. Defaults to the last quoted price for the underlying security.
     * @param strikeInterval Specifies the desired interval between the strike prices in a spread. It must be greater than or equal to 1. A value of 1 uses consecutive strikes; a value of 2 skips one between strikes; and so on.
     * @param enableGreeks Specifies whether or not greeks properties are returned.
     * @param strikeRange * If the filter is `ITM` (in-the-money), the chain includes only spreads that have an intrinsic value greater than zero.
 * * If the filter is `OTM` (out-of-the-money), the chain includes only spreads that have an intrinsic value equal to zero.
     * @param optionType Filters the spreads by a specific option type. Valid values are `All`, `Call`, and `Put`.
     * @returns Spread StreamOptionChain
     * @throws ApiError
     */
    static getOptionChain(underlying, expiration, expiration2, strikeProximity = 5, spreadType = 'Single', riskFreeRate, priceCenter, strikeInterval = 1, enableGreeks = true, strikeRange = 'All', optionType = 'All') {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/marketdata/stream/options/chains/{underlying}',
            path: {
                'underlying': underlying,
            },
            query: {
                'expiration': expiration,
                'expiration2': expiration2,
                'strikeProximity': strikeProximity,
                'spreadType': spreadType,
                'riskFreeRate': riskFreeRate,
                'priceCenter': priceCenter,
                'strikeInterval': strikeInterval,
                'enableGreeks': enableGreeks,
                'strikeRange': strikeRange,
                'optionType': optionType,
            },
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Stream Option Quotes
     * Stream price quotes and greeks for the specified option spread. A maximum of 10 concurrent streams
 * is allowed. Leg indexes are expected to be sequential starting at zero. For example, if there are three legs,
 * the legs in the request should contain Legs[0], Legs[1], and Legs[2]. Note that there is no required order for
 * the Legs in the query parameters.  For example, if there are 2 legs, Legs[0].Symbol, Legs[0].Ratio, Legs[1].Symbol,
 * and Legs[1].Ratio can be in any order in the query parameters.
 *
 * For options calculations, the WebAPI uses the following:
 * * 90 days for historical volatility of the underlying
 * * Bjerksund and Stensland option pricing model
 * * Ask price for price of the option
 *
     * @param legs0Symbol * `legs`: Individual components of a multi-part trade.
 * * `[0]`: Represents the position in the legs array.
 * * `Symbol`: Option contract symbol or underlying symbol to be traded for this leg. In some cases, the space in an option symbol may need to be explicitly URI encoded as %20, such as `MSFT%20220916C305`.
     * @param legs0Ratio * `legs`: Individual components of a multi-part trade.
 * * `[0]`: Represents the position in the legs array.
 * * `Ratio`: The number of option contracts or underlying shares for this leg, relative to the other legs. Use a positive number to represent a buy trade and a negative number to represent a sell trade. For example, a quote for a Butterfly spread can be requested using ratios of 1, -2, and 1: buy 1 contract of the first leg, sell 2 contracts of the second leg, and buy 1 contract of the third leg.
     * @param riskFreeRate The theoretical rate of return of an investment with zero risk. Defaults to the current quote for $IRX.X. The percentage rate should be specified as a decimal value. For example, to use 2% for the rate, pass in 0.02.
     * @param enableGreeks Specifies whether or not greeks properties are returned.
     * @returns Spread StreamOptionChain
     * @throws ApiError
     */
    static getOptionQuotes(legs0Symbol, legs0Ratio = 1, riskFreeRate, enableGreeks = true) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/marketdata/stream/options/quotes',
            query: {
                'legs[0].Symbol': legs0Symbol,
                'legs[0].Ratio': legs0Ratio,
                'riskFreeRate': riskFreeRate,
                'enableGreeks': enableGreeks,
            },
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
}
exports.MarketDataService = MarketDataService;

},{"../core/OpenAPI":6,"../core/request":7}],26:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarketDataV2Service = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class MarketDataV2Service {
    /**
     * Suggest Symbols
 *
     * Suggests symbols semantically based upon partial input of symbol name,
 * company name, or description. Does not return Options symbols.
 *
     * @param text Symbol text for suggestion. Partial input of a symbol name, company name, or description.
     * @param top The top number of results to return.
     * @param filter An OData filter to apply to the results. Supports the `eq` and `neq` filter opeators. E.g. `AAP?$filter=Category%20neq%20%27Stock%27`.
 * Valid values are: `Category` (`Stock`, `Index`, `Future`, `Forex`), `Country` (E.g. `United States`, `GB`) `Currency` (E.g. `USD`, `AUD`),
 * and `Exchange` (E.g. `NYSE`).
     * @returns SymbolSuggestDefinition Symbol Suggest
     * @throws ApiError
     */
    static suggestsymbols(text, top, filter) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v2/data/symbols/suggest/{text}',
            path: {
                'text': text,
            },
            query: {
                '$top': top,
                '$filter': filter,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Unexpected Error`,
                502: `Bad Gateway`,
                504: `Gateway Timeout`,
            },
        });
    }
    /**
     * Search for Symbols
 *
     * Searches symbols based upon input criteria including Name, Category and
 * Country.
 *
     * @param criteria Criteria are represented as Key/value pairs (`&` separated):
 *
 * `N`: Name of Symbol. (Optional)
 *
 * `C`: Asset categories. (Optional) Possible values:
 * - `Future` or `FU`
 * - `FutureOption` or `FO`
 * - `Stock` or `S` (Default)
 * - `StockOption` or `SO` (If root is specified, default category)
 * - `Index` or `IDX`
 * - `CurrencyOption` or `CO`
 * - `MutualFund` or `MF`
 * - `MoneyMarketFund` or `MMF`
 * - `IndexOption` or `IO`
 * - `Bond` or `B`
 * - `Forex` or `FX`
 *
 * `Cnt`: Country where the symbol is traded in. (Optional) Possible values:
 * - `ALL` if not specified (Default)
 * - `US`
 * - `DE`
 * - `CA`
 *
 * #### For Equities Lookups:
 *
 * `N`: partial/full symbol name, will return all symbols that contain the provided name value
 *
 * `Desc`: Name of the company
 *
 * `Flg`: indicates whether symbols no longer trading should be included in the results returned. (Optional) This criteria is not returned in the symbol data. Possible values:
 * - `true`
 * - `false` (Default)
 *
 * `Cnt`: Country where the symbol is traded in. (Optional) Possible values:
 * - `ALL` if not specified (Default)
 * - `US`
 * - `DE`
 * - `CA`
 *
 * #### For Options Lookups:
 * (Category=StockOption, IndexOption, FutureOption or CurrencyOption)
 *
 * `R`: Symbol root. Required field, the symbol the option is a derivative of, this search will not return options based on a partial root.
 *
 * `Stk`: Number of strikes prices above and below the underlying price
 * - Default value 3
 *
 * `Spl`: Strike price low
 *
 * `Sph`: Strike price high
 *
 * `Exd`: Number of expiration dates.
 * - Default value 3
 *
 * `Edl`: Expiration date low, ex: 01-05-2011
 *
 * `Edh`: Expiration date high, ex: 01-20-2011
 *
 * `OT`: Option type. Possible values:
 * - `Both` (Default)
 * - `Call`
 * - `Put`
 *
 * `FT`: Future type for FutureOptions. Possible values:
 * - `Electronic` (Default)
 * - `Pit`
 *
 * `ST`: Symbol type: Possible values:
 * - `Both`
 * - `Composite` (Default)
 * - `Regional`
 *
 * #### For Futures Lookups:
 * (Category = Future)
 *
 * `Desc`: Description of symbol traded
 *
 * `R`: Symbol root future trades
 *
 * `FT`: Futures type. Possible values:
 * - `None`
 * - `PIT`
 * - `Electronic` (Default)
 * - `Combined`
 *
 * `Cur`: Currency. Possible values:
 * - `All`
 * - `USD` (Default)
 * - `AUD`
 * - `CAD`
 * - `CHF`
 * - `DKK`
 * - `EUR`
 * - `DBP`
 * - `HKD`
 * - `JPY`
 * - `NOK`
 * - `NZD`
 * - `SEK`
 * - `SGD`
 *
 * `Exp`: whether to include expired contracts
 * - `false` (Default)
 * - `true`
 *
 * `Cnt`: Country where the symbol is traded in. (Optional) Possible values:
 * - `ALL` if not specified (Default)
 * - `US`
 * - `DE`
 * - `CA`
 *
 * #### For Forex Lookups:
 *
 * `N`: partial/full symbol name. Use all or null for a list of all forex symbols
 *
 * `Desc`: Description
 *
 * Note:
 * - The exchange returned for all forex searches will be `FX`
 * - The country returned for all forex searches will be `FOREX`
 *
     * @returns SymbolSearchDefinition Symbol Search Response
     * @throws ApiError
     */
    static searchSymbols(criteria) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v2/data/symbols/search/{criteria}',
            path: {
                'criteria': criteria,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Symbol Not Found`,
                500: `Unexpected Error`,
                502: `Bad Gateway`,
                504: `Gateway Timeout`,
            },
        });
    }
    /**
     * Stream Tick Bars
 *
     * Streams tick bars data for the regular session from a number of bars back, each bar returned separated by interval number of ticks.
 *
     * @param symbol A Symbol Name
     * @param interval Interval for each bar returned (in ticks).
     * @param barsBack The number of bars to stream, going back from current time.
     * @returns TickbarDefinition Tickbar Response
     * @throws ApiError
     */
    static streamTickBars(symbol, interval, barsBack) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v2/stream/tickbars/{symbol}/{interval}/{barsBack}',
            path: {
                'symbol': symbol,
                'interval': interval,
                'barsBack': barsBack,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Symbol Not Found`,
                500: `Unexpected Error`,
                502: `Bad Gateway`,
                504: `Gateway Timeout`,
            },
        });
    }
}
exports.MarketDataV2Service = MarketDataV2Service;

},{"../core/OpenAPI":6,"../core/request":7}],27:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderExecutionService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class OrderExecutionService {
    /**
     * Confirm Order
     * Returns estimated cost and commission information for an order without the order actually being placed. Request valid for `Market`, `Limit`, `Stop Market`, `Stop Limit`, `Options`, and
 * `Order Sends Order (OSO)` order types.  All Crypto market orders, excluding USDCUSD, must have `Day` duration (TimeInForce).
 * The fields that are returned in the response depend on the order type. The following shows the different fields that will be returned.
 *
 * **Base Confirmation**  (All confirmations will have these fields)
 * * Route
 * * Duration
 * * Account
 * * SummaryMessage
 *
 * **Equity Confirmation** (Base Confirmation fields + the following)
 * * EstimatedPrice
 * * EstimatedPriceDisplay
 * * EstimatedCost
 * * EstimatedCostDisplay
 * * EstimatedCommission
 * * EstimatedCommissionDisplay
 * * DebitCreditEstimatedCost
 * * DebitCreditEstimatedCostDisplay
 * * OrderConfirmId
 *
 * **Futures Confirmation** (Base Confirmation fields + the following)
 * * ProductCurrency
 * * AccountCurrency
 * * EstimatedCost
 * * EstimatedPrice
 * * EstimatedPriceDisplay
 * * InitialMarginDisplay
 * * EstimatedCommission
 * * EstimatedCommissionDisplay
 * * OrderConfirmId
     * @param requestBody Order Confirmation request.
     * @returns OrderConfirmResponses OrderConfirmResponses
     * @throws ApiError
     */
    static confirmOrder(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/v3/orderexecution/orderconfirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Confirm Group Order
     * Creates an Order Confirmation for a group order. Request valid for all account types. Request valid for Order Cancels Order (OCO) and Bracket (BRK) order types as well as grouped orders of other types (NORMAL).
 * All Crypto market orders, excluding USDCUSD, must have `Day` duration (TimeInForce).
 *
 * #### Order Cancels Order (OCO)
 * An OCO order is a group of orders whereby if one of the orders is filled or partially-filled, then all of the other orders in the group are cancelled.
 *
 * #### Bracket OCO Orders
 * A bracket order is a special instance of an OCO (Order Cancel Order). Bracket orders are used to exit an existing position. They are designed to limit loss and lock in profit by “bracketing” an order with a simultaneous stop and limit order.
 *
 * Bracket orders are limited so that the orders are all for the same symbol and are on the same side of the market (either all to sell or all to cover), and they are restricted to closing transactions.
 *
 * The reason that they follow these rules is because the orders need to be able to auto decrement when a partial fill occurs with one of the orders. For example, if the customer has a sell limit order for 1000 shares and a sell stop order for 1000 shares, and the limit order is partially filled for 500 shares, then the customer would want the stop to remain open, but it should automatically decrement the order to 500 shares to match the remaining open position.
 *
 * #### Crypto orders
 * - Only one exit bracket can be sent with an entry order in an OSO
 * - Multiple exit brackets can be issued for an open position, as long as the total exit quantity (from all exit orders) does not exceed the crypto balance
 *
 * #### Note
 * When a group order is submitted, the order execution system treats each sibling order as an individual order. Thus, the system does not validate that each order has the same Quantity, and currently it is not able to update a bracket order as one transaction, instead you must update each order within a bracket.
     * @param requestBody Order request
     * @returns OrderConfirmResponses OrderConfirmResponses
     * @throws ApiError
     */
    static confirmGroupOrder(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/v3/orderexecution/ordergroupconfirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Place Group Order
     * Submits a group order. Request valid for all account types. Request valid for Order Cancels Order (OCO) and Bracket (BRK) order types as well as grouped orders of other types (NORMAL).
 * All Crypto market orders, excluding USDCUSD, must have `Day` duration (TimeInForce).
 *
 * #### Order Cancels Order (OCO)
 * An OCO order is a group of orders whereby if one of the orders is filled or partially-filled, then all of the other orders in the group are cancelled.
 *
 * #### Bracket OCO Orders
 * A bracket order is a special instance of an OCO (Order Cancel Order). Bracket orders are used to exit an existing position. They are designed to limit loss and lock in profit by “bracketing” an order with a simultaneous stop and limit order.
 *
 * Bracket orders are limited so that the orders are all for the same symbol and are on the same side of the market (either all to sell or all to cover), and they are restricted to closing transactions.
 *
 * The reason that they follow these rules is because the orders need to be able to auto decrement when a partial fill occurs with one of the orders. For example, if the customer has a sell limit order for 1000 shares and a sell stop order for 1000 shares, and the limit order is partially filled for 500 shares, then the customer would want the stop to remain open, but it should automatically decrement the order to 500 shares to match the remaining open position.
 *
 * #### Crypto orders
 * - Only one exit bracket can be sent with an entry order in an OSO
 * - Multiple exit brackets can be issued for an open position, as long as the total exit quantity (from all exit orders) does not exceed the crypto balance
 *
 * #### Note
 * When a group order is submitted, the order execution system treats each sibling order as an individual order. Thus, the system does not validate that each order has the same Quantity, and currently it is not able to update a bracket order as one transaction, instead you must update each order within a bracket.
     * @param requestBody Order request to place.
     * @returns OrderResponses OrderResponses
     * @throws ApiError
     */
    static placeGroupOrder(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/v3/orderexecution/ordergroups',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Place Order
     * Creates a new brokerage order. Request valid for all account types. Request valid for Market, Limit, Stop Market, Stop Limit, Options and Order Sends Order (OSO) order types. All Crypto market orders, excluding USDCUSD, must have `Day` duration (TimeInForce).
     * @param requestBody Order to place.
     * @returns OrderResponses OrderResponses
     * @throws ApiError
     */
    static placeOrder(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/v3/orderexecution/orders',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Replace Order
     * Replaces an active order with a modified version of that order. You cannot update an order that has been filled.
 * Request valid for `Cash`, `Margin`, `Futures`, and `DVP` account types.
     * @param orderId OrderID for order to replace. Equity, option or future orderIDs should not include dashes (E.g. `1-2345-6789`). Valid format orderId=`123456789`
     * @param requestBody Order to replace.
     * @returns OrderResponse OrderResponse
     * @throws ApiError
     */
    static replaceOrder(orderId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PUT',
            url: '/v3/orderexecution/orders/{orderID}',
            path: {
                'orderID': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Cancel Order
     * Cancels an active order. Request valid for all account types.
     * @param orderId Order ID to cancel. Equity, option or future orderIDs should not include dashes (E.g. `1-2345-6789`). Valid format orderId=`123456789`
     * @returns OrderResponse OrderResponse
     * @throws ApiError
     */
    static cancelOrder(orderId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/v3/orderexecution/orders/{orderID}',
            path: {
                'orderID': orderId,
            },
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Get Activation Triggers
     * To place orders with activation triggers, a valid TriggerKey must be sent with the order. This resource provides the available trigger methods with their corresponding key.
     * @returns ActivationTriggers GetActivationTriggers
     * @throws ApiError
     */
    static getActivationTriggers() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/orderexecution/activationtriggers',
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
    /**
     * Get Routes
     * Returns a list of valid routes that a client can specify when posting an order.
     * @returns Routes Routes
     * @throws ApiError
     */
    static routes() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/orderexecution/routes',
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
}
exports.OrderExecutionService = OrderExecutionService;

},{"../core/OpenAPI":6,"../core/request":7}],28:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublicService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class PublicService {
    /**
     * Get Interest Rates
     * A public route that returns cryptocurrency interest rates.
     * @returns InterestRates GetInterestRates
     * @throws ApiError
     */
    static getInterestRates() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/v3/marketdata/crypto/interestrates',
            errors: {
                400: `ErrorResponse`,
                401: `ErrorResponse`,
                403: `ErrorResponse`,
                404: `ErrorResponse`,
                429: `ErrorResponse`,
                503: `ErrorResponse`,
                504: `ErrorResponse`,
            },
        });
    }
}
exports.PublicService = PublicService;

},{"../core/OpenAPI":6,"../core/request":7}]},{},[8])(8)
});
