/**
 * Created by Sergey Panpurin on 6/2/2018.
 */

// @ts-check
(function btWaitingServiceClosure() {
  'use strict';

  angular
    .module('btUtils')
    /**
     * @ngdoc service
     * @name btWaitingService
     * @memberOf btUtils
     * @description
     *  This wrapper for eventEmitter
     */
    .factory('btWaitingService', btWaitingService);

  btWaitingService.$inject = ['$q', '$timeout', '$interval'];

  /**
   *
   * @param {angular.IQService} $q - promise interface
   * @param {angular.ITimeoutService} $timeout - ?
   * @param {angular.IIntervalService} $interval - ?
   * @return {ecapp.IWaitingService}
   */
  function btWaitingService($q, $timeout, $interval) {
    return {
      retry: retry,
    };

    /**
     *
     * @param {number} n - ?
     * @param {number} seconds - ?
     * @param {ecapp.ICallbackStatus} callback - ?
     * @return {angular.IPromise<string>}
     */
    function retry(n, seconds, callback) {
      var deferred = $q.defer();
      attempt(0, n, seconds, deferred, callback);
      return deferred.promise;
    }

    /**
     *
     * @param {number} i - ?
     * @param {number} n - ?
     * @param {number} seconds - ?
     * @param {angular.IDeferred} deferred - ?
     * @param {ecapp.ICallbackStatus} callback - ?
     */
    function attempt(i, n, seconds, deferred, callback) {
      if (i < n) {
        if (callback()) {
          deferred.resolve('success');
        } else {
          $timeout(attempt.bind(null, i + 1, n, seconds, deferred, callback), seconds);
        }
      } else {
        deferred.reject(new Error('failed'));
      }
    }

    /**
     *
     * @param {number} n - ?
     * @param {number} seconds - ?
     * @param {ecapp.ICallbackStatus} callback - ?
     * @return {angular.IPromise<string>}
     */
    // eslint-disable-next-line no-unused-vars
    function wait(n, seconds, callback) {
      var deferred = $q.defer();

      var attempt = 0;

      $interval(
        function () {
          console.log('waitElementById', attempt);
          var result = callback();
          if (callback()) {
            console.log('waitElementById resolved');
            deferred.resolve(result);
          } else {
            if (attempt++ > n) {
              console.log('waitElementById resolved');
              deferred.reject(new Error('Max attempt reached!'));
            }
          }
        },
        seconds,
        n
      );

      return deferred.promise;
    }
  }
})();
