/**
 * Created by Sergey Panpurin on 08/30/2017.
 */

(function btMarketFollowButtonClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive display MarketFollow button.
     *
     * @ngdoc directive
     * @name btMarketFollowButton
     * @memberOf ecapp
     * @param {{instrument: Object, data: Object}} marketData - market data: instrument object and price data
     */
    .directive('btMarketFollowButton', btMarketFollowButton)
    .controller('btMarketFollowButtonController', btMarketFollowButtonController);

  btMarketFollowButton.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {any}
   */
  function btMarketFollowButton($templateCache) {
    return {
      restrict: 'E',
      scope: {
        marketData: '=instrument',
      },
      template: $templateCache.get('directives/instruments/bt-market-follow-button.html'),
      controller: 'btMarketFollowButtonController',
    };
  }

  btMarketFollowButtonController.$inject = [
    '$scope',
    '$rootScope',
    'btMarketWidgetsService',
    'btToastrService',
    'btVoiceAssistantHelperService',
    'btInstrumentsService',
    'btRestrictionService',
    'btTradeIdeasFiltersService',
  ];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.IMarketWidgetsService} btMarketWidgetsService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IVoiceAssistantHelperService} btVoiceAssistantHelperService
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.ITradeIdeasFiltersService} btTradeIdeasFiltersService
   */
  function btMarketFollowButtonController(
    $scope,
    $rootScope,
    btMarketWidgetsService,
    btToastrService,
    btVoiceAssistantHelperService,
    btInstrumentsService,
    btRestrictionService,
    btTradeIdeasFiltersService
  ) {
    $scope.hasMarketFollow = false;

    $scope.changeMarketFollow = changeMarketFollow;
    $scope.delete = deleteMarketFollow;
    $scope.create = createMarketFollow;

    setDefaultSettings();
    activate();

    /**
     * Activate component
     */
    function activate() {
      // initialize market follow
      btMarketWidgetsService
        .initMarketFollow($scope.settings.instrument, $scope.settings.broker)
        .then(function (initial) {
          if (initial !== null) {
            $scope.hasMarketFollow = true;
            $scope.settings.id = initial;
            // $scope.settings = initial;
          }
        });
    }

    /**
     * Delete market follow
     * @param {btMarketFollowSettings} settings - market sense settings
     */
    function deleteMarketFollow(settings) {
      var toast = btToastrService.info('Removing...', $scope.marketData.instrument.displayName, {
        type: 'settings',
        hidden: true,
      });

      btMarketWidgetsService
        .deleteMarketFollow(settings, $scope.marketData.instrument)
        .then(function () {
          $scope.hasMarketFollow = false;
          setDefaultSettings();

          btToastrService.remove(toast);
          btToastrService.info('Has been removed from your Watchlist.', $scope.marketData.instrument.displayName, {
            type: 'settings',
          });
          if (window.isDevMode) {
            btVoiceAssistantHelperService.readMessage(
              'Stop follow ' + btInstrumentsService.getPronunciation($scope.marketData.instrument),
              'markets',
              'follow'
            );
          }

          updateTradeIdeasFilters(false);
        })
        .catch(function (reason) {
          console.error(reason);

          btToastrService.remove(toast);
          btToastrService.error(reason.message, $scope.marketData.instrument.displayName, { type: 'settings' });
        });
    }

    /**
     * Create market follow
     * @param {btMarketFollowSettings} settings - market sense settings
     */
    function createMarketFollow(settings) {
      var toast = btToastrService.info('Saving...', $scope.marketData.instrument.displayName, {
        type: 'settings',
        hidden: true,
      });

      settings.userId = $rootScope.currentUser.id;
      btMarketWidgetsService
        .createMarketFollow(prepareSettings(settings))
        .then(function (data) {
          settings.id = data.id;
          $scope.hasMarketFollow = true;

          btToastrService.remove(toast);
          btToastrService.info('Has been added to your Watchlist.', $scope.marketData.instrument.displayName, {
            type: 'settings',
          });
          if (window.isDevMode) {
            btVoiceAssistantHelperService.readMessage(
              'Follow ' + btInstrumentsService.getPronunciation($scope.marketData.instrument),
              'markets',
              'follow'
            );
          }

          updateTradeIdeasFilters(true);
        })
        .catch(function (reason) {
          console.error(reason);

          btToastrService.remove(toast);
          btToastrService.error(reason.message, $scope.marketData.instrument.displayName, { type: 'settings' });
        });
    }

    /**
     * Callback connected to bt-star-button to react on select event. Create or delete market follow.
     * Status connected to hasMarketFollow variable.
     * @param {String} data - instrument name
     * @param {Boolean} checked - market sense status
     */
    function changeMarketFollow(data, checked) {
      void data;
      void checked;

      if (!btRestrictionService.hasFeature('personalization')) {
        btRestrictionService.showUpgradePopup('personalization');
        return;
      }

      if ($scope.hasMarketFollow === false) {
        $scope.create($scope.settings);
      } else {
        $scope.delete($scope.settings);
      }
    }

    /**
     * Set default settings
     */
    function setDefaultSettings() {
      $scope.settings = {
        instrument: $scope.marketData.instrument.brokerSymbol,
        broker: $scope.marketData.instrument.brokerName,
      };
    }

    /**
     * Prepare market follow settings
     * @param {btMarketFollowSettings} settings
     * @return {btMarketFollowObject}
     */
    function prepareSettings(settings) {
      return {
        id: settings.id,
        instrument: settings.instrument,
        broker: settings.broker,
        userId: settings.userId,
      };
    }

    /**
     * This function updates list of instruments in trade ideas filters
     * @param {boolean} checked
     */
    function updateTradeIdeasFilters(checked) {
      console.log('Watchlist: Update trade ideas filter');
      btTradeIdeasFiltersService.updateInstruments($scope.marketData.instrument, checked);
    }
  }
})();
