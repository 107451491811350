/**
 * Created by Orly on 03/02/2019.
 */

(function btTradeIdeasToolbarClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btTradeIdeasToolbar';

  /**
   * This directive displays trade ideas toolbar
   *
   * @ngdoc directive
   * @name btTradeIdeasToolbar
   * @memberOf ecapp
   */

  angular.module('ecapp').directive('btTradeIdeasToolbar', btTradeIdeasToolbar);

  btTradeIdeasToolbar.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btTradeIdeasToolbar($templateCache) {
    return {
      restrict: 'E',
      scope: {
        displayOptions: '=',
      },
      template: $templateCache.get('components/mobile/bt-feed/bt-trade-ideas-toolbar.html'),
      controller: btTradeIdeasToolbarController,
    };
  }

  btTradeIdeasToolbarController.$inject = [
    '$scope',
    '$timeout',
    '$templateCache',
    '$ionicModal',
    'btTradeIdeasFiltersService',
    'btToastrService',
  ];

  /**
   *
   * @param {*} $scope
   * @param {angular.ITimeoutService} $timeout
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {ionic.IModalService} $ionicModal
   * @param {ecapp.ITradeIdeasFiltersService} btTradeIdeasFiltersService
   * @param {ecapp.IToastrService} btToastrService
   */
  function btTradeIdeasToolbarController(
    $scope,
    $timeout,
    $templateCache,
    $ionicModal,
    btTradeIdeasFiltersService,
    btToastrService
  ) {
    /**
     * Modal window to show filters
     * @type {*|Object}
     */
    var gFiltersModal = $ionicModal.fromTemplate($templateCache.get('modals/bt-trade-ideas-filters-modal.html'), {
      scope: $scope,
      animation: 'slide-in-up',
    });

    var gSettings = {};

    $scope.updatingFilters = false;

    $scope.showFiltersModal = showFiltersModal;
    $scope.hideFiltersModal = hideFiltersModal;
    $scope.showHideAdvanced = showHideAdvanced;
    $scope.saveFiltersSettings = saveFiltersSettings;
    $scope.resetSettings = resetSettings;
    $scope.setHideExpiredFilter = setHideExpiredFilter;
    $scope.range = range;
    $scope.isFilterEnabled = isEnabled;
    $scope.showRegularSmallTradeIdea = showRegularSmallTradeIdea;

    $scope.$on('$destroy', onDestroy);
    $scope.$on('modal.hidden', saveFiltersSettings);

    $scope.$on('trade-ideas-filters:required', showFiltersModal);
    $scope.$on('trade-ideas-filters:updated', updateQuickFilters);

    activate();

    $scope.ignore = {
      enabled: false,
    };

    /**
     * Controller constructor
     */
    function activate() {
      getFilterSettings();
    }

    /**
     * This function shows modal window
     */
    function showFiltersModal() {
      if (!$scope.updatingFilters) {
        getFilters()
          .then(function () {
            gFiltersModal.show();
          })
          .catch(function (err) {
            console.error(err);
            btToastrService.error(err.message, 'Trade Ideas');
          });
      }
    }

    /**
     * This function hides modal window
     */
    function hideFiltersModal() {
      gFiltersModal.hide();
    }

    /**
     * This function resets all filters' values to default ones
     */
    function resetSettings() {
      $scope.filters = btTradeIdeasFiltersService.getDefaultFiltersSettings();
    }

    /**
     * This function is controller destructor
     */
    function onDestroy() {
      gFiltersModal.remove();
    }

    /**
     * This function returns array of set length
     * @param {number} n
     * @return {any[]}
     */
    function range(n) {
      return new Array(n);
    }

    /**
     *
     * @return {any}
     */
    function getFilterSettings() {
      return btTradeIdeasFiltersService.loadUserSettings().then(function (settings) {
        $scope.ignore.enabled = !!settings.ignore;
        gSettings = settings;
        updateQuickFilters(null, gSettings);
      });
    }

    /**
     * This function resets hide expired filter value
     *
     * Value $scope.hideExpired will be updated via updateQuickFilters
     */
    function setHideExpiredFilter() {
      if (gDebug) console.log('btTradeIdeasToolbar: Set hide expired filter');

      $scope.updatingFilters = true;

      if (gSettings['characteristics'] === undefined) {
        $scope.updatingFilters = false;
      } else {
        gSettings['characteristics']['expired'] = !$scope.hideExpired;
        gSettings.ignore = $scope.ignore.enabled;
        btTradeIdeasFiltersService
          .saveUserSettings(gSettings)
          .catch(function (reason) {
            if (gDebug) console.log('btTradeIdeasToolbar', reason);
            btToastrService.error(reason.message || 'There was error while saving settings', 'Saving settings');
          })
          .finally(function () {
            $scope.updatingFilters = false;
          });
      }
    }

    /**
     * Filters to display in modal window
     * @return {any}
     */
    function getFilters() {
      $scope.filters = [];
      return btTradeIdeasFiltersService.loadUserSettings().then(function (settings) {
        $scope.ignore.enabled = !!settings.ignore;
        $scope.filters = btTradeIdeasFiltersService.filtersToMax(settings);
      });
    }

    /**
     * This function shows or hides advanced filters of the settings
     * @param {Object} event - click event
     * @param {Object} section - section object
     */
    function showHideAdvanced(event, section) {
      section.show = !section.show;

      // scroll to element
      if (section.show) {
        $timeout(function () {
          if (event.target.parentNode.scrollIntoView) {
            event.target.parentNode.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      }
    }

    /**
     * This function saves filters' settings
     */
    function saveFiltersSettings() {
      var settings = btTradeIdeasFiltersService.filtersToMin($scope.filters);
      settings.ignore = $scope.ignore.enabled;

      if (btTradeIdeasFiltersService.isUpdateRequired(settings)) {
        if (gDebug) console.log('btTradeIdeasToolbar: Save user settings');
        btTradeIdeasFiltersService
          .saveUserSettings(settings)
          .then(function () {
            gSettings = settings;
            btToastrService.success(
              'Trade ideas filter has been saved and synced with mobile & in-app notification.',
              'Saving settings'
            );
          })
          .catch(function (reason) {
            if (gDebug) console.log('btTradeIdeasToolbar', reason);
            btToastrService.error(reason.message || 'There was error while saving settings', 'Saving settings');
          });
      }
    }

    /**
     * This function updates filters in the toolbar
     * @param {*} event
     * @param {*} settings
     */
    function updateQuickFilters(event, settings) {
      if (settings['characteristics']) {
        $scope.hideExpired = settings['characteristics']['expired'];
      } else {
        $scope.hideExpired = false;
      }
    }

    /**
     *
     * @param {*} filter
     * @return {boolean}
     */
    function isEnabled(filter) {
      return filter.params.enabled;
    }

    /**
     *
     */
    function showRegularSmallTradeIdea() {
      if ($scope.displayOptions.displayMode === 'regular') {
        $scope.displayOptions.displayMode = 'small';
      } else {
        $scope.displayOptions.displayMode = 'regular';
      }
    }
  }
})();
