/**
 * Created by Sergey Panpurin on 6/6/2019.
 */

(function btCommunityClosure() {
  'use strict';

  /** @namespace window.gitter.Chat */

  var gDebug = false;
  var gPrefix = 'btCommunity:';

  angular
    .module('ecapp')
    .directive('btCommunity', btCommunity)
    .controller('btCommunityController', btCommunityController);

  btCommunity.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btCommunity($templateCache) {
    return {
      restrict: 'E',
      scope: {
        hasChat: '@',
      },
      template: $templateCache.get('directives/common/bt-community.html'),
      controller: 'btCommunityController',
    };
  }

  btCommunityController.$inject = [
    '$q',
    '$rootScope',
    '$scope',
    '$ionicPopup',
    '$sce',
    'btShareScopeService',
    'btSettingsService',
    'btRestrictionService',
    'btSubscriptionService',
    'btToastrService',
    'btCommunityService',
  ];

  /**
   * Community controller
   *
   * @ngdoc controller
   * @name btCommunityController
   * @memberOf dashboard
   * @param {angular.IQService} $q - promise interface
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.ICustomScope} $scope
   * @param {ionic.IPopupService} $ionicPopup - ionic popup service
   * @param {angular.ISCEService} $sce
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.ISubscriptionService} btSubscriptionService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.ICommunityService} btCommunityService
   */
  function btCommunityController(
    $q,
    $rootScope,
    $scope,
    $ionicPopup,
    $sce,
    btShareScopeService,
    btSettingsService,
    btRestrictionService,
    btSubscriptionService,
    btToastrService,
    btCommunityService
  ) {
    console.log('Running btCommunityController');

    var gLocalSettings = btSettingsService.getLocalSettings();

    $scope.community = {
      enabled: false,
      loading: true,
      ready: false,
      locked: false,
      error: null,
      topics: [],
      selected: undefined,
      select: selectBlog,
      upgrade: upgradeBlog,
      help: helpBlog,
    };

    $scope.buyMetalQuarterly = buyMetalQuarterly;
    $scope.buyMetalYearly = buyMetalYearly;

    // $scope.type = 'embedded';
    $scope.type = 'iframe';

    $scope.$on('subscription:updated', onSubscriptionUpgradeSuccess);

    initialize();

    /**
     * This function reacts on subscription update.
     */
    function onSubscriptionUpgradeSuccess() {
      if ($scope.community.selected) {
        $scope.community.locked = !btRestrictionService.hasPlugin($scope.community.selected.plugin);
      }
    }

    /**
     *
     */
    function initialize() {
      if (gDebug) console.log(gPrefix, 'initializing...');

      // Fix from previous version
      var parameter = gLocalSettings.get('community.gitter.connected');
      if (parameter !== undefined && typeof parameter !== 'object') {
        // reset parameter
        gLocalSettings.set('community.gitter.connected', {});
      }

      btShareScopeService
        .wait()
        .then(getSettings)
        .then(function () {
          if (gDebug) console.log(gPrefix, 'initialized');
          $rootScope.$broadcast('community:changed', $scope.community.selected);
        })
        .catch(function (reason) {
          console.error(gPrefix, reason);
        });
    }

    /**
     *
     * @return {angular.IPromise<any>}
     */
    function getSettings() {
      $scope.community.ready = false;
      $scope.community.loading = true;
      $scope.community.locked = true;

      // $scope.community.locked = !btRestrictionService.hasFeature('community');

      if (gDebug) console.log(gPrefix, 'user has community feature', btRestrictionService.hasFeature('community'));

      if (gDebug) console.log(gPrefix, 'loading settings...');
      return btShareScopeService.getAppSettings('community').then(function (result) {
        if (gDebug) console.log(gPrefix, 'settings were loaded');
        prepareCommunity(result);
      });
    }

    /**
     *
     * @param {*} value
     * @return {boolean}
     */
    function checkIsTopicVisible(value) {
      return (
        value.stage === 'live' || (value.stage === 'test' && btShareScopeService.checkHasRole('community-manager'))
      );
    }

    /**
     *
     * @param {{url: String, publisher: String, event: String, version: String}} value
     * @return {*}
     */
    function addArenaLink(value) {
      value.url = getArenaLink(value.publisher, value.event, value.version);
      return value;
    }

    /**
     *
     * @param {*} result
     */
    function prepareCommunity(result) {
      $scope.community.loading = false;

      $scope.community.enabled = !!result.features.blog;
      $scope.community.topics = result.topics.filter(checkIsTopicVisible).map(addArenaLink);

      if (gLocalSettings.get('community.topic') !== undefined) {
        $scope.community.selected = $scope.community.topics.filter(function (topic) {
          return gLocalSettings.get('community.topic') === topic.id;
        })[0];
      }

      if ($scope.community.selected === undefined) {
        $scope.community.selected = $scope.community.topics[0];
      }

      btCommunityService.setSelectedCommunity($scope.community.selected);
      $scope.community.ready = true;
      $scope.community.locked = !btRestrictionService.hasPlugin($scope.community.selected.plugin);

      if (gDebug) console.log(gPrefix, 'blog settings', $scope.community);
    }

    /**
     *
     * @param {*} topic
     */
    function selectBlog(topic) {
      if (topic === $scope.community.selected) {
        return;
      }

      gLocalSettings.set('community.topic', topic.id);
      $scope.community.selected = topic;
      $scope.community.locked = !btRestrictionService.hasPlugin($scope.community.selected.plugin);
      $rootScope.$broadcast('community:changed', topic);
    }

    /**
     *
     */
    function upgradeBlog() {
      btRestrictionService.showUpgradePopup('community');
    }

    /**
     *
     */
    function helpBlog() {
      showBlogHelp();
    }

    /**
     *
     * @param {*} publisher
     * @param {*} event
     * @param {*} version
     * @return {*}
     */
    function getArenaLink(publisher, event, version) {
      var url =
        'https://go.arena.im/embed?publisher=' +
        publisher +
        '&event=' +
        event +
        '&v=' +
        version +
        '&token=MzM5NzczMDgtZmQ3NC00MjcxLTk3MTEtZDhhNTc3ZmI3MThl';
      return $sce.trustAsResourceUrl(url);
    }

    /**
     *
     */
    function showBlogHelp() {
      $ionicPopup.show({
        template: '<p>Explain how to use blog.</p>',
        title: 'Blog Help',
        scope: $scope,
        cssClass: 'popup-bar-dark',
        buttons: [{ text: 'Okay' }],
      });
    }

    /**
     *
     */
    function buyMetalQuarterly() {
      btSubscriptionService
        .getUserPurchases()
        .then(function () {
          return btSubscriptionService.getSpecificPurchase('plugins', 'community-metals-commodities', 90);
        })
        .then(processPurchase);
    }

    /**
     *
     */
    function buyMetalYearly() {
      btSubscriptionService
        .getUserPurchases()
        .then(function () {
          return btSubscriptionService.getSpecificPurchase('plugins', 'community-metals-commodities', 365);
        })
        .then(processPurchase);
    }

    /**
     *
     * @param {*} purchase
     */
    function processPurchase(purchase) {
      if (purchase) {
        btSubscriptionService
          .buySubscription(purchase)
          .then(function (status) {
            console.log(status);
            if (status === 'success') {
              btToastrService.success('Plugin was purchased.', 'Subscription', { type: 'system' });
            } else if (status === 'closed') {
              btToastrService.warning('The purchase has been terminated by the user.', 'Subscription', {
                type: 'system',
              });
            } else {
              btToastrService.warning('Unknown status: ' + status, 'Subscription', { type: 'system' });
            }
          })
          .catch(function (reason) {
            console.error(reason);
            btToastrService.error('Error: ' + reason.message, 'Subscription', { type: 'system' });
          });
      } else {
        console.error('Plugin was not found!');
        btToastrService.error('Error: Plugin was not found!', 'Subscription', { type: 'system' });
      }
    }
  }
})();
