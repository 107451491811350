/**
 * Created by Sergey Panpurin on 2/3/19
 */

(function btTradingSessionsClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive displays trading sessions.
     *
     * @ngdoc directive
     * @name btTradingSessions
     * @memberOf ecapp
     */
    .directive('btTradingSessions', btTradingSessions);

  btTradingSessions.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btTradingSessions($templateCache) {
    return {
      restrict: 'E',
      scope: {
        stats: '<',
      },
      template: $templateCache.get('components/mobile/bt-trading-sessions/bt-trading-sessions.html'),
      controller: btTradingSessionsController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btTradingSessionsController.$inject = ['btDateService', 'btTradingSessionsService'];

  /**
   *
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.ITradingSessionsService} btTradingSessionsService
   */
  function btTradingSessionsController(btDateService, btTradingSessionsService) {
    /*jshint validthis: true*/
    var vm = this;

    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;
    vm.$onChanges = onChanges;

    /**
     *
     */
    function onInit() {
      vm.hours = get24Hours();
      vm.sessions = btTradingSessionsService.getSessions();
      vm.adjustable = true;
      vm.timezone = 'local';
      vm.hasStats = vm.stats !== null;

      var now = btDateService.getNowDate();
      if (vm.adjustable) {
        var ref;
        if (vm.timezone === 'utc') {
          ref = moment().utc();
        } else if (vm.timezone === 'local') {
          ref = moment().tz(btDateService.getUserTimeZone());
        } else {
          ref = moment().tz(vm.timezone);
        }

        vm.start = ref.clone().add(-12, 'hours');
        vm.end = ref.clone().add(12, 'hours');
        vm.timeStyle = { 'margin-left': ((-ref.minutes() * 60 * 1000) / (vm.end - vm.start)) * 100 + '%' };
      } else {
        vm.start = btDateService.getDateStart(now, vm.timezone);
        vm.end = btDateService.getDateEnd(now, vm.timezone);
        vm.timeStyle = {};
      }

      vm.sessionHours = get24Hours().map(function (value) {
        return (vm.start.hours() + value) % 24;
      });

      vm.sessionHours.push(vm.sessionHours[vm.sessionHours.length - 1] + 1);

      vm.sessionIntervals = vm.sessions.map(function (value) {
        return [].concat.apply([], value.getRelativeTradingHours([-1, 0, 1], 'utc', true));
      });
    }

    /**
     *
     * @return {number[]}
     */
    function get24Hours() {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    }

    /**
     * This function handles input changes.
     *
     * @param {*} changes
     */
    function onChanges(changes) {}

    /**
     * This function handles destroying.
     */
    function onDestroy() {}
  }
})();
