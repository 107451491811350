/**
 * Created by Sergey on 2/18/20.
 */

(function btCollapsibleClosure() {
  'use strict';

  /**
   * This directive adds ability to expand some content.
   * It adds `isItemCollapsed` variable to scope with default value `false` and two functions `expandItem` and `collapseItem`.
   *
   * @ngdoc directive
   * @name btCollapsible
   * @memberOf ecapp
   * @restrict A
   * @see ecapp.btExpandable
   * @scope
   */

  /**
   * This controller process notification center.
   *
   * @ngdoc controller
   * @name btCollapsibleController
   * @memberOf ecapp
   */

  /** Directive */
  angular
    .module('ecapp')
    .directive('btCollapsible', angularDirective)
    .controller('btCollapsibleController', angularController);

  angularDirective.$inject = ['$templateCache'];

  /**
   * Implementation of btCollapsible directive
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function angularDirective($templateCache) {
    return {
      restrict: 'A',
      scope: false,
      controller: 'btCollapsibleController',
    };
  }

  angularController.$inject = ['$scope'];

  /**
   * Implementation of btCollapsibleController controller
   *
   * @param {ecapp.ICustomScope} $scope
   */
  function angularController($scope) {
    $scope.$on('$destroy', onDestroy);

    activate();

    /**
     * This function is a controller constructor.
     */
    function activate() {
      if (
        $scope.isItemCollapsed !== undefined ||
        $scope.expandItem !== undefined ||
        $scope.collapseItem !== undefined
      ) {
        alert('Conflict! Directive btExpandable or btCollapsible is already used on parent scope.');
      } else {
        $scope.isItemCollapsed = false;

        $scope.expandItem = function () {
          $scope.isItemCollapsed = false;
        };

        $scope.collapseItem = function () {
          $scope.isItemCollapsed = true;
        };
      }
    }

    /**
     * This function is a controller destructor.
     */
    function onDestroy() {}
  }
})();
