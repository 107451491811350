/**
 * Created by Eyal on 8/5/2016.
 */

(function btEventSpeechReportLinksClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive display link for reports and speeches
     *
     * @ngdoc directive
     * @name btEventSpeechReportLinks
     * @memberOf ecapp
     */
    .directive('btEventSpeechReportLinks', btEventSpeechReportLinks);

  btEventSpeechReportLinks.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btEventSpeechReportLinks($templateCache) {
    return {
      restrict: 'E',
      scope: {
        rowName: '@row',
        textToWrite: '@',
        naIfNotExists: '@?',
        differentFontSizeWhenShown: '@?',
      },
      template: $templateCache.get('directives/common/bt-event-speech-report-links.html'),
      controller: btEventSpeechReportLinksController,
      link: function (scope, element) {
        //bt-event-report-link exists, which means we show links, apply smaller font

        if (scope.differentFontSizeWhenShown != null)
          element.find('.bt-event-report-link').css('font-size', scope.differentFontSizeWhenShown);
      },
    };
  }

  btEventSpeechReportLinksController.$inject = ['$scope', '$analytics', 'btRestrictionService'];

  /**
   *
   * @param {*} $scope
   * @param {ext.IAnalyticsService} $analytics
   * @param {ecapp.IRestrictionService} btRestrictionService
   */
  function btEventSpeechReportLinksController($scope, $analytics, btRestrictionService) {
    //this optional parameter decided if it writes NA instead of textToWrite when there is no content.
    $scope.naIfNotExists = $scope.naIfNotExists != null;

    //we used to have here merge between array of text and videos, since the database was changed and now everything is located in one field: "links", things got simpler.
    $scope.row = $scope.$parent[$scope.rowName];
    var links = $scope.row.links;
    var liveLink = $scope.row.liveLink;

    // Sometime links nay be equal to empty string. In this case we still need to convert it to empty array.
    if (!links) links = [];

    if (null != liveLink) {
      links.unshift(liveLink);
    }

    $scope.textAndVideos = links;

    $scope.openLink = openLink;

    /**
     *
     */
    function openLink() {
      if (!btRestrictionService.hasFeature('release-magnitude')) {
        btRestrictionService.showUpgradePopup('release-report');
      } else {
        if (window.btSettings.BT_DOMAIN === 'bettertrader') {
          $analytics.eventTrack('open', {
            category: 'calendar',
            label: 'report-link',
            eventId: $scope.row.eventInfo.id,
          });
        }
        window.open(links[0], '_system');
      }
    }
  }
})();
