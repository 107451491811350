/**
 * Created by Sergei Panpurin on 9/5/18.
 */

(function btWidgetClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive displays list of trade ideas. User can select one of them.
     *
     * @ngdoc directive
     * @name btWidget
     * @memberOf ecapp
     */
    .directive('btWidget', btWidget)
    .controller('btWidgetController', btWidgetController);

  btWidget.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btWidget($templateCache) {
    return {
      restrict: 'E',
      scope: {
        widgetId: '@',
        instrument: '<?',
        tradeIdea: '<?',
        trades: '<?',
        charts: '<?',
        events: '<?',
        history: '<?',
        suggestedWidgetId: '@?',
        manualTesting: '@',
        tradeDelay: '<',
        hasInstruments: '<',
        currentChart: '<?',
        onSelect: '&',
        isMain: '@',
      },
      template: $templateCache.get('components/mobile/bt-back-tester/bt-widget.html'),
      controller: 'btWidgetController',
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btWidgetController.$inject = ['$scope', '$timeout', 'btSettingsService', 'btTourService'];

  /**
   *
   * @param {*} $scope
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.ITourService} btTourService
   */
  function btWidgetController($scope, $timeout, btSettingsService, btTourService) {
    void $scope;

    var gSettings = btSettingsService.getLocalSettings();

    /*jshint validthis: true*/
    var vm = this;

    /** @type {String} */
    vm.number = vm.widgetId === '1' ? 'first' : vm.widgetId === '2' ? 'second' : 'third';

    /** @type {{help: boolean, settings: boolean}} */
    vm.opened = {
      help: false,
      settings: false,
    };

    /** @type {Boolean} */
    vm.isLoading = false;

    /**
     * btBackWidget - backtester widget object
     *
     * @typedef {Object} btBackWidget
     * @property {String} id - widget id
     * @property {String} label - widget label
     * @property {String} icon - widget icon
     */

    /** @type {?btBackWidget} */
    vm.widget = null;

    /** @type {?btBackWidget} */
    vm.markedWidget = null;

    /** @type {btBackWidget[]} */
    vm.widgets = [
      { id: 'real-time', label: 'Real-time chart', icon: 'ion-stats-bars' },
      { id: 'trades-table', label: 'Previous trades', icon: 'ion-ios-paper' },
      { id: 'latest-trade', label: 'Latest trade', icon: 'ion-ios-checkmark' },
      { id: 'synthetic', label: 'Synthetic chart', icon: 'ion-ios-color-filter' },
      { id: 'trading-card', label: 'Trading card', icon: 'ion-pricetags' },
    ];

    /** @type {Boolean} */
    vm.isMainWidget = (vm.isMain || 'false') === 'true';

    vm.onChangeWidget = onChangeWidget;
    vm.selectWidget = selectWidget;
    vm.markWidget = markWidget;
    vm.isSelected = isWidgetSelected;
    vm.isMarked = isWidgetMarked;

    vm.openSettings = openSettings;
    vm.openHelp = openHelp;
    vm.closePopups = closePopups;
    vm.openRealtime = openRealtime;
    vm.startTour = btTourService.show;

    vm.$onInit = onInit;
    vm.$onChanges = onChanges;
    vm.$onDestroy = onDestroy;

    /**
     * Initialize component
     */
    function onInit() {
      if (vm.suggestedWidgetId) {
        vm.suggestedWidget = getWidgetById(vm.suggestedWidgetId);
        if (vm.suggestedWidget) {
          vm.suggestedWidgetLabel = vm.suggestedWidget.label;
        }
      }

      var widgetId = gSettings.get('backtester.widgets.' + vm.widgetId);
      if (widgetId) {
        vm.widget = getWidgetById(widgetId);
      }
    }

    /**
     * React on changes
     * @param {Object} changes
     */
    function onChanges(changes) {
      if (changes.instrument) {
        vm.isLoading = true;
        $timeout(function () {
          vm.isLoading = false;
        }, 500);
      }
    }

    /**
     * Destroy component
     */
    function onDestroy() {}

    /**
     * React on widget change
     */
    function onChangeWidget() {
      closePopups();
    }

    /**
     * Select widget
     *
     * @param {btBackWidget} widget - selected widget
     */
    function selectWidget(widget) {
      vm.widget = widget;
      closePopups();
    }

    /**
     * Select widget
     *
     * @param {btBackWidget} widget - selected widget
     */
    function markWidget(widget) {
      vm.markedWidget = widget;
    }

    /**
     * Check is widget selected
     * @param {String} id - widget id
     * @return {Boolean}
     */
    function isWidgetSelected(id) {
      return vm.widget && vm.widget.id === id;
    }

    /**
     * Check is widget selected
     * @param {String} id - widget id
     * @return {Boolean}
     */
    function isWidgetMarked(id) {
      return vm.markedWidget && vm.markedWidget.id === id;
    }

    /**
     * Open help popup
     */
    function openHelp() {
      vm.opened.help = !vm.opened.help;
    }

    /**
     * Open settings popup
     */
    function openSettings() {
      vm.opened.settings = !vm.opened.settings;
    }

    /**
     * Close all popups
     */
    function closePopups() {
      vm.opened.help = false;
      vm.opened.settings = false;
      if (vm.widget && vm.widget.id) {
        gSettings.set('backtester.widgets.' + vm.widgetId, vm.widget.id);
      }
    }

    /**
     * Get widget by id
     *
     * @param {String} id - widget id
     * @return {?btBackWidget}
     */
    function getWidgetById(id) {
      var widget = vm.widgets.filter(function (value) {
        return value.id === id;
      })[0];

      return widget === undefined ? null : widget;
    }

    /**
     * Open realtime chart
     */
    function openRealtime() {
      vm.widget = getWidgetById('real-time');
      gSettings.set('backtester.widgets.' + vm.widgetId, vm.widget.id);
    }
  }
})();
