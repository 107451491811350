(function btDisclaimerControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btDisclaimerController', btDisclaimerController);

  btDisclaimerController.$inject = ['$scope', 'btSettingsService'];

  /**
   * Controller for disclaimer page.
   *
   * @ngdoc controller
   * @name ecapp.btDisclaimerController
   * @param {ecapp.ICustomScope} $scope - ?
   * @param {ecapp.ISettingsService} btSettingsService - ?
   */
  function btDisclaimerController($scope, btSettingsService) {
    console.log('Running btDisclaimerController');

    $scope.isBetterTrader = btSettingsService.domain === 'bettertrader';
    $scope.isBigBrainBank = btSettingsService.domain === 'bigbrainbank';
    $scope.isOptimus = btSettingsService.domain === 'optimusfutures';
  }
})();
