/**
 * Created by Sergey Panpurin on 5/31/2018.
 */

(function btVoiceAssistantServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btVoiceAssistantService:';

  angular.module('ecapp').factory('btVoiceAssistantService', service);

  service.$inject = [
    '$q',
    '$rootScope',
    '$ionicPopup',
    '$ionicLoading',
    '$analytics',
    'btSpeechService',
    'btSettingsService',
  ];

  /**
   * @param {angular.IQService} $q
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ionic.IPopupService} $ionicPopup
   * @param {ionic.ILoadingService} $ionicLoading
   * @param {ext.IAnalyticsService} $analytics
   * @param {ecapp.ISpeechService} btSpeechService
   * @param {ecapp.ISettingsService} btSettingsService
   * @return {ecapp.IVoiceAssistantService}
   */
  function service($q, $rootScope, $ionicPopup, $ionicLoading, $analytics, btSpeechService, btSettingsService) {
    if (gDebug) console.log('Running btVoiceAssistantService');

    /**
     * This service implements core voice assistance features. This service store settings locally.
     *
     * @ngdoc service
     * @name btVoiceAssistantService
     * @memberOf ecapp
     */

    /**
     * @typedef {Object} btVoiceAssistantStatus
     * @property {Boolean} isEnable - is enabled
     * @property {Boolean} hasVoice - has voice
     */

    var gReady = false;
    var gEnable;
    var gAsked;
    var gAskedDate;
    var gMute;
    var gVoiceURI;
    var gVolume = 'max';

    /**
     * Supported volume levels: *min*, *mid* and *max*
     * @name ecapp.btVoiceAssistantService~gVolumeLevels
     * @enum {Number}
     */
    var gVolumeLevels = {
      min: 0.1,
      mid: 0.6,
      max: 1.0,
    };

    /**
     * Voice assistant status object
     * @type {btVoiceAssistantStatus}
     */
    var gStatus = {
      isEnable: false,
      hasVoice: false,
    };

    activate();

    return {
      test: test,
      ask: ask,
      read: read,
      pronounce: pronounce,
      enable: enable,
      disable: disable,
      getStatus: getStatus,
      getSpeechSynthesis: getSpeechSynthesis,
      selectVoice: selectVoice,
      setVolumeLevel: setVolumeLevel,
      getVolumeLevel: getVolumeLevel,
    };

    /**
     * Activate service
     */
    function activate() {
      loadLocalSettings();
    }

    /**
     * Set volume level
     *
     * @alias ecapp.btVoiceAssistantService#setVolumeLevel
     * @param {String} volume - volume level
     */
    function setVolumeLevel(volume) {
      gVolume = volume;
      saveLocalSettings();
    }

    /**
     * This function gets volume level
     *
     * @name btVoiceAssistantService#getVolumeLevel
     * @return {String} - volume level
     */
    function getVolumeLevel() {
      return gVolume;
    }

    /**
     * Load settings from localStorage
     */
    function loadLocalSettings() {
      // noinspection JSValidateTypes
      gEnable = (localStorage.getItem('btVoiceAssistantEnable') || 'false') === 'true';
      // noinspection JSValidateTypes
      gAsked = (localStorage.getItem('btVoiceAssistantAsked') || 'false') === 'true';
      gAskedDate = new Date(localStorage.getItem('btVoiceAssistantAskedDate') || '2018-01-01T00:00:00.000Z');
      gMute = (localStorage.getItem('btVoiceAssistantMute') || 'true') === 'true';
      gVolume = localStorage.getItem('btVoiceAssistantVolume') || 'max';
      gVoiceURI = localStorage.getItem('btVoiceAssistantVoiceURI') || null;

      gStatus.hasVoice = gVoiceURI !== 'null';

      gVoiceURI = !gStatus.hasVoice ? null : gVoiceURI;

      gStatus.isEnable = gEnable;

      btSpeechService.waitSupportedVoices().then(function (voices) {
        gReady = true;
        if (gVoiceURI !== null) {
          btSpeechService.setDefaultVoiceByURI(gVoiceURI);
        } else {
          var voice = suggestVoice(voices, { playback: false });
          if (voice) btSpeechService.setDefaultVoiceByURI(voice.voiceURI);
        }

        if (gEnable && !gMute) {
          btSpeechService.setMuteOff();
        }

        if (gAsked) sayWelcomeMessage();
      });
    }

    /**
     * Save settings to localStorage
     */
    function saveLocalSettings() {
      localStorage.setItem('btVoiceAssistantEnable', gEnable);
      localStorage.setItem('btVoiceAssistantAsked', gAsked);
      localStorage.setItem('btVoiceAssistantAskedDate', gAskedDate);
      localStorage.setItem('btVoiceAssistantMute', gMute);
      localStorage.setItem('btVoiceAssistantVolume', gVolume);
      localStorage.setItem('btVoiceAssistantVoiceURI', gVoiceURI);
    }

    /**
     * Ask user about voice assistant
     * @alias ecapp.btVoiceAssistantService#ask
     * @return {any}
     */
    function ask() {
      if (!gAsked) {
        return showIntro();
      } else {
        return $q.resolve(true);
      }
    }

    /**
     * Run select voice process: load voices, show dialog, handle errors
     *
     * @alias ecapp.btVoiceAssistantService#selectVoice
     * @return {angular.IPromise<Boolean>} - return true if voice was selected
     */
    function selectVoice() {
      // show loading due to it can take some time to load voices
      $ionicLoading.show();
      return btSpeechService
        .waitSupportedVoices()
        .then(function (voices) {
          $ionicLoading.hide();
          return voices;
        })
        .then(showVoiceOptions)
        .catch(function (reason) {
          if (reason.code === 'BT_SUPPORTED_VOICES') {
            return showNotVoiceWarning();
          } else {
            return showError(reason);
          }
        })
        .finally(function () {
          $ionicLoading.hide();
        });
    }

    /**
     * Show a pop-up dialog box with the option of selecting one of the supported voices
     * @param {SpeechSynthesisVoice[]} voices - list of supported voices
     * @return {any}
     */
    function showVoiceOptions(voices) {
      var voiceAssistantTutorialSrc = btSettingsService.getAssets('voice-assistant-tutorial-src');

      var scope = $rootScope.$new(true);
      scope.data = {
        voices: voices,
        selectedVoice: suggestVoice(voices),
        onSelect: onSelectVoice.bind(null, scope),
        errors: {
          selectVoice: false,
        },
      };

      var templateHTML;

      if (btSettingsService.isBigBrainBank()) {
        templateHTML =
          '<span class="bt-speech">&mdash; "Hi there! I am TheBrain by BigBrainBank, your personal AI financial advisor. It\'s great knowing you. Can I be of assistance? You can enable me from the menu bar."</span><br>\n<br>\n' +
          'Choose a voice of assistant:<br>\n' +
          '<select ng-model="data.selectedVoice" ng-change="data.onSelect()" ng-options="voice.name for voice in data.voices"></select><br>\n<br>\n' +
          '<img class="bt-demo-image" src="' +
          voiceAssistantTutorialSrc +
          '" alt="Demo">\n' +
          '<p ng-if="data.errors.selectVoice">Please, choose a voice of assistant to continue!</p>';
      } else if (btSettingsService.isLinkDataService()) {
        templateHTML =
          '<span class="bt-speech">&mdash; "Hello, I\'m your voice assistant, I will read the news to you in real time. You can switch on and off anytime from the menu bar!"</span><br>\n<br>\n' +
          'Choose a voice of assistant:<br>\n' +
          '<select ng-model="data.selectedVoice" ng-change="data.onSelect()" ng-options="voice.name for voice in data.voices"></select><br>\n<br>\n' +
          '<img class="bt-demo-image" src="' +
          voiceAssistantTutorialSrc +
          '" alt="Demo">\n' +
          '<p ng-if="data.errors.selectVoice">Please, choose a voice of assistant to continue!</p>';
      } else {
        templateHTML =
          '<span class="bt-speech">&mdash; "Hello, I\'m Jay Adward from BetterTrader, I will read the news to you in real time. You can switch on and off anytime from the menu bar!"</span><br>\n<br>\n' +
          'Choose a voice of assistant:<br>\n' +
          '<select ng-model="data.selectedVoice" ng-change="data.onSelect()" ng-options="voice.name for voice in data.voices"></select><br>\n<br>\n' +
          '<img class="bt-demo-image" src="' +
          voiceAssistantTutorialSrc +
          '" alt="Demo">\n' +
          '<p ng-if="data.errors.selectVoice">Please, choose a voice of assistant to continue!</p>';
      }

      var popupHandler = $ionicPopup.show({
        template: templateHTML,
        title: '<i class="ion-android-volume-up"></i> Voice Settings',
        cssClass: 'bt-voice-assistant-popup',
        scope: scope,
        buttons: [
          {
            text: gAsked ? 'Cancel' : 'Off',
            onTap: onVoiceOptionsCancel,
          },
          {
            text: 'Choose',
            type: 'button-positive',
            onTap: onVoiceOptionsOkay.bind(null, scope),
          },
        ],
      });

      scope.popup = popupHandler;

      return popupHandler.then(function (res) {
        if (gDebug) console.log(gPrefix, 'showVoiceOptions: Tapped!', res);
        if (res) {
          if (gDebug) console.log(gPrefix, 'showVoiceOptions: Do something');
        }
        return res;
      });
    }

    /**
     * Test the voice selected by user
     * @param {Object} scope - pop-up scope
     */
    function onSelectVoice(scope) {
      testVoice(scope.data.selectedVoice.voiceURI);
    }

    /**
     * Test a voice
     * @param {String} voiceURI - voice URI
     */
    function testVoice(voiceURI) {
      btSpeechService.cancel();
      setTimeout(function () {
        btSpeechService.setMuteOff();
        btSpeechService.setDefaultVoiceByURI(voiceURI);
        if (btSettingsService.isBigBrainBank()) {
          btSpeechService.say(
            'Hi there! I am TheBrain by BigBrainBank, your personal AI financial advisor. ' +
              "It's great knowing you. Can I be of assistance? You can enable me from the menu bar."
          );
        } else if (btSettingsService.isLinkDataService()) {
          btSpeechService.say(
            "Hello, I'm your voice assistant, I will read the news to you in real " +
              'time. You can switch on and off anytime from the menu bar.!'
          );
        } else {
          btSpeechService.say(
            "Hello, I'm Jay Adward from BetterTrader, I will read the news to you in real " +
              'time. You can switch on and off anytime from the menu bar.!'
          );
        }
      }, 100);
    }

    /**
     * Test a voice
     */
    function sayWelcomeMessage() {
      btSpeechService.cancel();
      setTimeout(function () {
        btSpeechService.setMuteOff();
        // btSpeechService.setDefaultVoiceByURI(voiceURI);
        if (btSettingsService.domain === 'bigbrainbank') {
          btSpeechService.say(
            'Welcome back! I am TheBrain, your personal AI financial advisor. You can access me anytime at the Menu Bar.'
          );
        }
      }, 100);
    }

    /**
     * User clicked cancel in voice options popup
     * @return {Boolean} - return false
     */
    function onVoiceOptionsCancel() {
      if (!gAsked) {
        disable();
        markAsked();
        saveLocalSettings();
      }

      btSpeechService.cancel();

      return false;
    }

    /**
     * User clicked okay in voice options popup
     * @param {Object} scope - popup scope
     * @param {Object} e - event object
     * @return {Boolean} - return true
     */
    function onVoiceOptionsOkay(scope, e) {
      if (scope.data.selectedVoice !== null) {
        gVoiceURI = scope.data.selectedVoice.voiceURI;
        gStatus.hasVoice = true;

        btSpeechService.setDefaultVoiceByURI(scope.data.selectedVoice.voiceURI);
        btSpeechService.cancel();

        enable().then(markAsked).then(saveLocalSettings);

        // close popup this true value to use it this promise
        scope.popup.close(true);
      } else {
        scope.data.errors.selectVoice = true;
      }

      e.preventDefault();
      return true;
    }

    /**
     * @typedef {object} VoiceSuggestOptions
     * @property {string} [suggested] - name of suggested voice
     * @property {boolean} [playback] - whether voice should be tested
     */

    /**
     * Return 'Google UK English Female' or first voice.
     * @param {SpeechSynthesisVoice[]} voices - list of supported voices
     * @param {VoiceSuggestOptions} [options] - options
     * @return {*}
     */
    function suggestVoice(voices, options) {
      options = options || {};
      options.suggested = options.suggested || 'Google UK English Female';
      options.playback = typeof options.playback === 'boolean' ? options.playback : true;

      var suggested = null;
      voices.forEach(function (voice) {
        if (voice.name === options.suggested) {
          suggested = voice;
        }
      });

      if (suggested === null && voices.length > 0) {
        suggested = voices[0];
      }

      if (suggested && options.playback) {
        testVoice(suggested.voiceURI);
      }

      return suggested;
    }

    /**
     * Show warning pop-up: no supported voices
     * @return {any}
     */
    function showNotVoiceWarning() {
      var scope = $rootScope.$new(true);
      scope.data = {};

      var templateHTML =
        "<p>\n  Can't find suitable voice in your browser.<br> \n  This service will be disabled. Try again late.<br>\n  <br>\n  (We suggest to use Chrome Web Browser for this feature)\n</p>";

      var popupHandler = $ionicPopup.show({
        template: templateHTML,
        title: '<i class="ion-android-volume-up"></i> Voice Settings',
        cssClass: 'bt-voice-assistant-popup',
        scope: scope,
        buttons: [
          {
            text: 'Okay',
            type: 'button-positive',
            onTap: onErrorOkay,
          },
        ],
      });

      return popupHandler.then(function (res) {
        if (gDebug) console.log(gPrefix, 'showNotVoiceWarning: Tapped!', res);
        if (res) {
          if (gDebug) console.log(gPrefix, 'showNotVoiceWarning: Do something');
        }
        return res;
      });
    }

    /**
     * Show error pop-up
     * @param {Object} error - error object
     * @return {any}
     */
    function showError(error) {
      var templateHTML = '<p>Error: ' + error.message + '<br><br> This service will be disabled.</p>';

      var popupHandler = $ionicPopup.show({
        template: templateHTML,
        title: '<i class="ion-android-volume-up"></i> Voice Settings',
        cssClass: 'bt-voice-assistant-popup',
        buttons: [
          {
            text: 'Okay',
            type: 'button-positive',
            onTap: onErrorOkay,
          },
        ],
      });

      return popupHandler.then(function (res) {
        if (gDebug) console.log(gPrefix, 'showError: Tapped!', res);
        if (res) {
          if (gDebug) console.log(gPrefix, 'showError: Do something');
        }
        return res;
      });
    }

    /**
     * User clicked okay in warning or error popup
     * @return {Boolean} - return false
     */
    function onErrorOkay() {
      disable();
      markAsked();
      saveLocalSettings();
      return false;
    }

    /**
     * Show introduction pop-up
     * @return {angular.IPromise<Boolean>}
     */
    function showIntro() {
      var voiceAssistantTutorialSrc = btSettingsService.getAssets('voice-assistant-tutorial-src');

      var scope = $rootScope.$new(true);
      scope.data = {};

      var templateHTML;

      if (btSettingsService.domain === 'bigbrainbank') {
        templateHTML =
          '<p>You can now hear the news as it happens!<br>' +
          '<br>' +
          'This feature is a voice for:<br>' +
          '&nbsp;- all the events you are following<br>' +
          '&nbsp;- market movements<br>' +
          '&nbsp;- twitter accounts that you care about<br>' +
          '<br>' +
          "Now you don't even have to look at the screen to know what happened. Just listen!" +
          '</p>' +
          '<img class="bt-demo-image" src="' +
          voiceAssistantTutorialSrc +
          '" alt="Demo">';
      } else {
        templateHTML =
          '<p>You can now hear the news as it happens!<br>' +
          '<br>' +
          'This feature is a voice for:<br>' +
          '&nbsp;- all the events you are following<br>' +
          '&nbsp;- market movements<br>' +
          '&nbsp;- twitter accounts that you care about<br>' +
          '<br>' +
          "Now you don't even have to look at the screen to know what happened. Just listen!" +
          '</p>' +
          '<img class="bt-demo-image" src="' +
          voiceAssistantTutorialSrc +
          '" alt="Demo">';
      }

      var popupHandler = $ionicPopup.show({
        template: templateHTML,
        title: '<i class="ion-android-volume-up"></i> Voice Assistant',
        cssClass: 'bt-voice-assistant-popup',
        scope: scope,
        buttons: [
          {
            text: 'Off',
            onTap: onIntroTurnOff,
          },
          {
            text: 'On',
            type: 'button-positive',
            onTap: onIntroTurnOn,
          },
        ],
      });

      return popupHandler.then(function (res) {
        if (gDebug) console.log(gPrefix, 'showIntro: Tapped!', res);
        if (res) {
          if (gDebug) console.log(gPrefix, 'showIntro: Do something');
        }
        return res;
      });
    }

    /**
     * User clicked off in introduction
     * @return {Boolean} - return false
     */
    function onIntroTurnOff() {
      disable();
      markAsked();
      saveLocalSettings();
      return false;
    }

    /**
     * User clicked on in introduction
     * @return {angular.IPromise<Boolean>}
     */
    function onIntroTurnOn() {
      return selectVoice();
    }

    /**
     * Mark that user was asked
     * @private
     */
    function markAsked() {
      gAsked = true;
      gAskedDate = new Date();
      saveLocalSettings();
    }

    /**
     * This function reads text.
     *
     * @alias ecapp.btVoiceAssistantService#read
     * @param {String} text - text to read
     * @return {Boolean} - return true if text was read
     */
    function read(text) {
      if (gEnable && gReady) {
        btSpeechService.say(normalize(text), gVolumeLevels[gVolume] || 1.0);
        localStorage.setItem('btVoiceAssistantLastUse', new Date().toISOString());
        return true;
      } else {
        return false;
      }
    }

    /**
     * @alias ecapp.btVoiceAssistantService#pronounce
     * @param {string} text
     * @return {boolean}
     */
    function pronounce(text) {
      if (gReady) {
        btSpeechService.pronounce(normalize(text));
        return true;
      } else {
        return false;
      }
    }

    /**
     *
     * @param {string} text - text
     * @return {any}
     */
    function normalize(text) {
      text = text.replace(/(\d+)\s?bps/gi, '$1 basis points');
      text = text.replace('EUR/USD', 'Euro Dollar');
      text = text.replace('EURUSD', 'Euro Dollar');
      text = text.replace('EUR/GBP', 'Euro Sterling');
      text = text.replace('EURGBP', 'Euro Sterling');
      text = sliceText(text);
      return text;
    }

    /**
     * Slice text to 200 symbols
     * @param {String} text - original text
     * @return {String} - sliced text
     * @private
     */
    function sliceText(text) {
      if (text.length > 199) {
        text.slice(0, 199);
        var words = text.split(' ');
        return words.slice(0, words.length - 1).join(' ') + '…';
      } else {
        return text;
      }
    }

    /**
     * Test service
     * @alias ecapp.btVoiceAssistantService#test
     */
    function test() {
      btSpeechService.waitSupportedVoices().then(function () {
        btSpeechService.setMuteOff();
        read('There is a price-driven trade idea for S&P500');
        read('There is a price-driven trade idea for EUR/USD');
      });
    }

    /**
     * Enable service
     * @alias ecapp.btVoiceAssistantService#enable
     * @return {angular.IPromise<Boolean>} - return true if enabled
     */
    function enable() {
      if (gVoiceURI) {
        gEnable = true;
        gStatus.isEnable = true;
        gMute = false;
        btSpeechService.setMuteOff();
        saveLocalSettings();
        read("It looks like we're all set.");

        $analytics.eventTrack('enable', { category: 'settings', label: 'voice-assistant' });

        return $q.resolve(true);
      } else {
        return showIntro();
      }
    }

    /**
     * Disable service
     * @alias ecapp.btVoiceAssistantService#disable
     */
    function disable() {
      gEnable = false;
      gStatus.isEnable = false;
      btSpeechService.setMuteOn();
      saveLocalSettings();

      $analytics.eventTrack('disable', { category: 'settings', label: 'voice-assistant' });
    }

    /**
     * Get status of service
     * @alias ecapp.btVoiceAssistantService#getStatus
     * @return {btVoiceAssistantStatus} - status object
     */
    function getStatus() {
      return gStatus;
    }

    /**
     * Get SpeechSynthesis object
     * @alias ecapp.btVoiceAssistantService#getSpeechSynthesis
     * @return {{paused:Boolean, pending:Boolean, speaking:Boolean}} - SpeechSynthesis object
     */
    function getSpeechSynthesis() {
      return btSpeechService.getSpeechSynthesis();
    }
  }
})();
