/**
 * Created by Sergey on 9/5/17.
 */

(function btAboutControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btAboutController', btAboutController);

  btAboutController.$inject = ['$scope', '$window', 'btSettingsService', 'btDeviceService'];

  /**
   * Controller for about us page.
   *
   * @ngdoc controller
   * @name ecapp.btAboutController
   * @param {ecapp.ICustomScope} $scope - view scope
   * @param {angular.IWindowService} $window - window object service
   * @param {ecapp.ISettingsService} btSettingsService -
   * @param {ecapp.IDeviceService} btDeviceService -
   */
  function btAboutController($scope, $window, btSettingsService, btDeviceService) {
    console.log('Running btAboutController');

    $scope.$on('$destroy', onDestroy);

    $scope.openRateAppDialog = openRateAppDialog;
    $scope.getInvitationByPhone = getInvitationByPhone;

    $scope.isOptimus = btSettingsService.getDomain() === 'optimusfutures';
    $scope.isBigBrainBank = btSettingsService.getDomain() === 'bigbrainbank';
    $scope.isBetterTrader = btSettingsService.getDomain() === 'bettertrader';

    /**
     * On view destroy
     */
    function onDestroy() {
      console.log('Destroy btAboutController');
    }

    /**
     * Use AppRate plugin to rate application
     */
    function openRateAppDialog() {
      ionic.Platform.ready(function () {
        if ($window.AppRate) $window.AppRate.promptForRating(true);
      });
    }

    /**
     * This function sends link to download application to the phone number
     */
    function getInvitationByPhone() {
      btDeviceService.sendGetMobileAlert();
    }
  }
})();
