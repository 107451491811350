/**
 * Created by Sergey Panpurin on 3/10/2018.
 */

// @ts-check
(function btDeferCacheServiceClosure() {
  'use strict';

  angular.module('btUtils').factory('btDeferCacheService', ['$q', btDeferCacheService]);

  /**
   * This service cache promise return.
   *
   * @ngdoc service
   * @name btDeferCacheService
   * @memberOf btUtils
   * @param {angular.IQService} $q - promise interface
   * @return {ecapp.IDeferCacheService}
   */
  function btDeferCacheService($q) {
    var gCaches = {};
    var gDefers = {};

    /**
     *
     * @param {*} key - ?
     * @param {*} callbackFn - ?
     * @return {*}
     */
    function getDeferred(key, callbackFn) {
      var defer = _getDefer(key);

      if (defer && defer.promise.$$state.status === 0) {
        return defer.promise;
      }

      if (!defer) {
        defer = _createDefer(key);
      }

      var cache = _getOrCreateCache(key);

      if (cache) {
        defer.resolve(cache);
      } else {
        callbackFn().then(function (result) {
          _addToCache(key, result);
          defer.resolve(result);
        });
      }

      return defer.promise;
    }

    /**
     *
     * @param {*} key - ?
     * @return {*}
     */
    function get(key) {
      return gCaches[key];
    }

    /**
     *
     * @param {*} key - ?
     */
    function destroy(key) {
      gCaches[key] = undefined;
      gDefers[key] = undefined;
    }

    /**
     *
     * @param {*} key - ?
     * @return {*}
     */
    function _getDefer(key) {
      return gDefers[key];
    }

    /**
     *
     * @param {*} key - ?
     * @return {*}
     */
    function _createDefer(key) {
      gDefers[key] = $q.defer();
      return gDefers[key];
    }

    /**
     *
     * @param {*} key - ?
     * @return {*}
     */
    function _getOrCreateCache(key) {
      if (!gCaches[key]) {
        gCaches[key] = undefined;
      }

      return gCaches[key];
    }

    /**
     *
     * @param {*} key - ?
     * @param {*} data - ?
     */
    function _addToCache(key, data) {
      gCaches[key] = data;
    }

    return {
      getDeferred: getDeferred,
      get: get,
      destroy: destroy,
    };
  }
})();
