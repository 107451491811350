/**
 * Created by Sergey Panpurin on 5/31/2018.
 */

(function btVoiceAssistantControlClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive controls voice assistant.
     *
     * @ngdoc directive
     * @name btVoiceAssistantControl
     * @memberOf ecapp
     */
    .directive('btVoiceAssistantControl', btVoiceAssistantControl)
    .controller('btVoiceAssistantControlController', btVoiceAssistantControlController);

  btVoiceAssistantControl.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btVoiceAssistantControl($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('components/mobile/bt-voice-assistant/bt-voice-assistant-control.html'),
      controller: 'btVoiceAssistantControlController',
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btVoiceAssistantControlController.$inject = ['$window', 'btVoiceAssistantHelperService'];

  /**
   *
   * @param {angular.IWindowService} $window
   * @param {ecapp.IVoiceAssistantHelperService} btVoiceAssistantHelperService
   */
  function btVoiceAssistantControlController($window, btVoiceAssistantHelperService) {
    /*jshint validthis: true*/
    var vm = this;

    vm.status = {};
    vm.speech = {};

    vm.toggleAssistant = toggleAssistant;

    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;

    activate();

    /**
     *
     */
    function onInit() {}

    /**
     *
     */
    function onDestroy() {}

    /**
     *
     */
    function activate() {
      vm.status = btVoiceAssistantHelperService.getStatus();
      vm.speech = btVoiceAssistantHelperService.getSpeechSynthesis();

      if ($window.isDesktop) {
        btVoiceAssistantHelperService.ask();
      }
    }

    /**
     *
     */
    function toggleAssistant() {
      if (vm.status.isEnable) {
        btVoiceAssistantHelperService.disable();
      } else {
        btVoiceAssistantHelperService.enable();
      }
    }
  }
})();
