/**
 * Created by yatree on 15/09/16.
 */

(function btSubscriptionPreviewClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive display user subscription
     *
     * @ngdoc directive
     * @name btSubscriptionPreview
     * @memberOf ecapp
     * @param {ecapp.ISubscriptionService} btSubscriptionService - ?
     */
    .directive('btSubscriptionPreview', btSubscriptionPreview);

  btSubscriptionPreview.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btSubscriptionPreview($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('directives/common/bt-subscription-preview.html'),
      controller: btSubscriptionPreviewController,
    };
  }

  btSubscriptionPreviewController.$inject = ['$scope', '$rootScope', 'btSubscriptionService', 'btShareScopeService'];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.ISubscriptionService} btSubscriptionService
   * @param {ecapp.IShareScopeService} btShareScopeService
   */
  function btSubscriptionPreviewController($scope, $rootScope, btSubscriptionService, btShareScopeService) {
    $scope.subscription = null;
    $scope.name = null;
    $scope.isTrial = false;
    $scope.daysLeft = null;
    $scope.expirationStyle = 'bt-regular';
    $scope.isUpgradeSuggested = false;

    activate();

    /**
     *
     */
    function activate() {
      updateSubscriptionPreview();
    }

    $rootScope.$on('subscription:updated', onUpdateSubscription);

    /**
     *
     * @param {*} event
     * @param {*} subscription
     */
    function onUpdateSubscription(event, subscription) {
      void event;
      void subscription;
      updateSubscriptionPreview();
    }

    /**
     * Prepare subscription and user object and update subscription preview
     */
    function updateSubscriptionPreview() {
      btShareScopeService.getAccountSettingsPromise().then(function (user) {
        var subscription = btSubscriptionService.getCombinedSubscription();
        updateScopeVariables(subscription, user);
      });
    }

    /**
     * Update scope variables
     *
     * @param {Object} subscription - subscription object
     * @param {Object} user - user object
     */
    function updateScopeVariables(subscription, user) {
      // get subscription name
      $scope.name = btSubscriptionService.getName(subscription);

      // decide is it necessary to show expiration
      if (btSubscriptionService.hasExpiration(subscription)) {
        if (subscription.status === 'none') {
          $scope.hasExpiration = false;

          $scope.daysLeft = null;
          $scope.expirationStyle = 'bt-regular';
        } else {
          $scope.hasExpiration = true;

          var data = btSubscriptionService.getDaysLeftObject(subscription, user);
          $scope.daysLeft = data.text;
          $scope.expirationStyle = data.style;
        }

        // modify subscription to clean a space for expiration text
        $scope.name = $scope.name.replace('Monthly', '');
        $scope.name = $scope.name.replace('Annually', '');
      } else {
        $scope.expirationStyle = 'bt-regular';
        $scope.hasExpiration = false;
        $scope.daysLeft = null;
      }

      $scope.isUpgradeSuggested = btSubscriptionService.isUpgradeSuggested(subscription);
    }
  }
})();
