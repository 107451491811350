/**
 * Created by Sergey on 12/26/2017.
 */

(function btExpectedInsightsClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * Container of expected insights
     *
     * @ngdoc directive
     * @name btExpectedInsights
     * @memberOf ecapp
     */
    .directive('btExpectedInsights', btExpectedInsights)
    .controller('btExpectedInsightsController', btExpectedInsightsController);

  btExpectedInsights.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {any}
   */
  function btExpectedInsights($templateCache) {
    return {
      restrict: 'E',
      scope: {
        insights: '=',
        table: '=',
        release: '=',
        subscription: '=',
        showDetails: '=',
      },
      template: $templateCache.get('directives/insight/bt-expected-insights.html'),
      controller: 'btExpectedInsightsController',
    };
  }

  btExpectedInsightsController.$inject = ['$scope', 'btRestrictionService'];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.IRestrictionService} btRestrictionService
   */
  function btExpectedInsightsController($scope, btRestrictionService) {
    console.log('btExpectedInsights test');

    $scope.status = {
      isEnable: btRestrictionService.hasFeature('trade-ideas'),
    };

    $scope.openMoreInsightsExpectedTrading = false;
    $scope.selectedId = null;

    $scope.columns = [
      'extr-neg',
      'much-neg',
      'just-neg',
      'abit-neg',
      'asexpect',
      'abit-pos',
      'just-pos',
      'much-pos',
      'extr-pos',
    ];

    // !!! > Check
    $scope.findColumn = findColumn;
    $scope.someCondition = someCondition;
    $scope.selectInsight = selectInsight;

    /**
     *
     * @param {*} colName
     * @return {any}
     */
    function findColumn(colName) {
      return $scope.table.some(function (insight) {
        return insight.data[colName].value !== '-';
      });
    }

    /**
     *
     * @param {*} insight
     * @return {any}
     */
    function someCondition(insight) {
      return (
        insight.data['extr-neg'].value !== '-' ||
        insight.data['much-neg'].value !== '-' ||
        insight.data['just-neg'].value !== '-' ||
        insight.data['abit-neg'].value !== '-' ||
        insight.data['asexpect'].value !== '-' ||
        insight.data['abit-pos'].value !== '-' ||
        insight.data['just-pos'].value !== '-' ||
        insight.data['much-pos'].value !== '-' ||
        insight.data['extr-pos'].value !== '-'
      );
    }

    /**
     *
     * @param {*} item
     */
    function selectInsight(item) {
      if (item.value !== '-' && $scope.showDetails) {
        // $scope.selectedId = null;
        // $timeout(function () {
        $scope.selectedId = item.id;
        $scope.selectedItem = $scope.insights.filter(function (t) {
          return t.uniqueId === $scope.selectedId;
        })[0];
        // });
      }
    }
  }
})();
