/**
 * Created by Sergey Panpurin on 4/23/2017.
 */

// @ts-check
(function btInAppPurchaseServiceClosure() {
  'use strict';

  angular
    .module('btUtils')
    /**
     * @ngdoc service
     * @name btInAppPurchaseService
     * @memberOf btUtils
     * @description
     *  This service ???
     */
    .factory('btInAppPurchaseService', ['btInAppPurchaseMockService', btInAppPurchaseService]);

  /**
   *
   * @param {ecapp.IInAppPurchaseService} btInAppPurchaseMockService - ?
   * @return {ecapp.IInAppPurchaseService}
   */
  function btInAppPurchaseService(btInAppPurchaseMockService) {
    // https://github.com/AlexDisler/cordova-plugin-inapppurchase

    // We should use https://github.com/j3k0/cordova-plugin-purchase instead.

    return {
      getInstance: function () {
        if (window.inAppPurchase === undefined) {
          return btInAppPurchaseMockService;
        } else {
          return window.inAppPurchase;
        }
      },
    };
  }
})();
