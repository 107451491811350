/**
 * Created by Sergey Panpurin on 10/17/2017.
 */

/**
 * Market moments.
 *
 * Market-based or price-driven insights criteria:
 *  1. Criterion to generate
 *  2. Criterion to notify
 *  3. Criterion to display
 *
 */
// @ts-check
(function btMomentServiceClosure() {
  'use strict';

  /**
   * @ngdoc service
   * @name btMomentService
   * @memberOf ecapp
   * @description
   *  Help to work with price
   */

  /**
   * @ngdoc service
   * @name btMomentConverterService
   * @memberOf ecapp
   * @description
   *  Help to work with price
   */

  /**
   * @ngdoc service
   * @name btMarketMovementService
   * @memberOf ecapp
   * @description
   *  Help to work with price
   */
  /**
   *
   */
  angular
    .module('ecapp')
    .factory('btMomentService', btMomentService)
    .factory('btMomentConverterService', btMomentConverterService)
    .factory('btMarketMovementService', btMarketMovementService);

  btMomentConverterService.$inject = ['btMarketMovementService', 'btDateService', 'btInstrumentsService'];

  /**
   *
   * @param {ecapp.IMarketMovementService} btMarketMovementService
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @return {ecapp.IMomentConverterService}
   */
  function btMomentConverterService(btMarketMovementService, btDateService, btInstrumentsService) {
    return {
      extendMoment: extendMoment,
    };

    /**
     * Extend moment object
     * @param {btMomentObject} moment
     * @return {btMomentObject}
     */
    function extendMoment(moment) {
      if (moment.extended) return moment;

      btMarketMovementService.fixMomentTime(moment);
      moment.extended = true;
      moment.strengthText = 'Test';
      moment.strengthTemplate = '<span>Test</span>';
      moment.extra = { start: null, range: null };

      if (moment.situation.insightTemplate.type === 'consecutive') {
        moment.strengthText = btMarketMovementService.consecutiveTemplate(moment.strength, '5 min');
        moment.strengthTemplate = btMarketMovementService.consecutiveHtmlTemplate(moment.strength, '5 min');

        btMarketMovementService.fixServerBug01(moment);

        moment.extra = {
          start: null,
          range: 'From ' + moment.payload.open + ' to ' + moment.payload.close,
        };
      }

      if (moment.situation.insightTemplate.type === 'intraday-movement') {
        moment.strengthText = btMarketMovementService.intradayMovementTemplate(moment.strength);
        moment.strengthTemplate = btMarketMovementService.intradayMovementHtmlTemplate(moment.strength);

        moment.extra = {
          start:
            'Start ' +
            btDateService.format(moment.payload.start * 1000, 'DD/MM/YYYY') +
            ' at ' +
            btDateService.format(moment.payload.start * 1000, 'HH:mm Z'),
          range: 'From ' + moment.payload.from + ' to ' + moment.payload.to,
        };
      }

      if (moment.situation.insightTemplate.type === 'cross-yesterday') {
        moment.strengthText = btMarketMovementService.crossYesterdayTemplate(moment.strength);
        moment.strengthTemplate = btMarketMovementService.crossYesterdayHtmlTemplate(moment.strength);

        moment.extra = {
          start:
            'Start ' +
            btDateService.format(moment.payload.start * 1000, 'DD/MM/YYYY') +
            ' at ' +
            btDateService.format(moment.payload.start * 1000, 'HH:mm Z'),
          range:
            'From ' +
            moment.payload.yesterday +
            ' to ' +
            (moment.strength > 0 ? moment.payload.high : moment.payload.low),
        };
      }

      if (moment.situation.insightTemplate.type === 'day-change') {
        moment.strengthText = btMarketMovementService.dailyChangeTemplate(moment.strength);
        moment.strengthTemplate = btMarketMovementService.dailyChangeHtmlTemplate(moment.strength);

        moment.extra = {
          start:
            'Start ' +
            btDateService.format(moment.payload.start * 1000, 'DD/MM/YYYY') +
            ' at ' +
            btDateService.format(moment.payload.start * 1000, 'HH:mm Z'),
          range:
            'From ' +
            moment.payload.yesterday +
            ' to ' +
            (moment.strength > 0 ? moment.payload.high : moment.payload.low),
        };
      }

      if (moment.situation.insightTemplate.type === 'interval-change') {
        moment.strengthText = btMarketMovementService.intervalChangeTemplate(
          moment.strength,
          moment.situation.insightTemplate.params.interval
        );
        moment.strengthTemplate = btMarketMovementService.intervalChangeHtmlTemplate(
          moment.strength,
          moment.situation.insightTemplate.params.interval
        );

        moment.extra = {
          start:
            'Start ' +
            btDateService.format(moment.payload.start * 1000, 'DD/MM/YYYY') +
            ' at ' +
            btDateService.format(moment.payload.start * 1000, 'HH:mm Z'),
          range: 'From ' + moment.payload.from + ' to ' + moment.payload.to,
        };
      }

      moment.strengthHtml = moment.strengthTemplate;

      moment.class = moment.strength > 0 ? 'positive' : 'negative';
      moment.timeBack = btDateService.getHumanisedTimeFromNow(moment.time, true);
      moment.timeHours = btDateService.getClockText(moment.time);

      moment.situation.objectRaw = moment.situation.object;
      moment.situation.objectBroker = btInstrumentsService.getSymbol(moment.situation.object);
      var triggerInstrument = btInstrumentsService.getInstrumentByComplexSymbol(moment.situation.object);
      moment.situation.object = triggerInstrument.displayName;

      moment.situation.insightTemplate.name = moment.situation.insightTemplate.name.toLowerCase();

      var i = moment.situation.relatedInstruments.indexOf(moment.situation.objectRaw);
      if (i !== -1) {
        moment.situation.relatedInstruments.splice(i, 1);
        moment.situation.relatedInstruments.splice(0, 0, moment.situation.objectRaw);
      }
      return moment;
    }
  }

  btMomentService.$inject = [
    'Moment',
    'btSharedData',
    'btInstrumentsService',
    'btSettingsService',
    '$q',
    'btDateService',
    '$ionicLoading',
    '$window',
    'btPusherService',
    '$rootScope',
    'btEventEmitterService',
    '$state',
    'btToastrService',
    'btWatchListService',
    'btAudioService',
    'btTradingService',
    'btVoiceAssistantHelperService',
    'btRestrictionService',
    'btTimeSupervisionService',
    'btTradeIdeasService',
    'btTradeIdeasFiltersService',
    'btMarketMovementService',
    'btMomentConverterService',
  ];

  /**
   *
   * @param {ecapp.IGeneralLoopbackService} lbMoment
   * @param {ecapp.ISharedData} btSharedData
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {angular.IQService} $q
   * @param {ecapp.IDateService} btDateService
   * @param {ionic.ILoadingService} $ionicLoading
   * @param {angular.IWindowService} $window
   * @param {ecapp.IPusherService} btPusherService
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.IEventEmitterService} btEventEmitterService
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IWatchListService} btWatchListService
   * @param {ecapp.IAudioService} btAudioService
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.IVoiceAssistantHelperService} btVoiceAssistantHelperService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.ITimeSupervisionService} btTimeSupervisionService
   * @param {ecapp.ITradeIdeasService} btTradeIdeasService
   * @param {ecapp.ITradeIdeasFiltersService} btTradeIdeasFiltersService
   * @param {ecapp.IMarketMovementService} btMarketMovementService
   * @param {ecapp.IMomentConverterService} btMomentConverterService
   * @return {ecapp.IMomentService}
   */
  function btMomentService(
    lbMoment,
    btSharedData,
    btInstrumentsService,
    btSettingsService,
    $q,
    btDateService,
    $ionicLoading,
    $window,
    btPusherService,
    $rootScope,
    btEventEmitterService,
    $state,
    btToastrService,
    btWatchListService,
    btAudioService,
    btTradingService,
    btVoiceAssistantHelperService,
    btRestrictionService,
    btTimeSupervisionService,
    btTradeIdeasService,
    btTradeIdeasFiltersService,
    btMarketMovementService,
    btMomentConverterService
  ) {
    console.log('Running btMomentService');

    var gPusherBound = false;

    var gMovementCache = {};
    var LESS_IMPRESSIVE_DELAY = 5 * 60 * 1000;

    activate();

    btEventEmitterService.addListener('login:success', onLoginSuccess);
    btEventEmitterService.addListener('logout:success', onLogoutSuccess);

    return {
      getTodayMoments: getTodayMoments,
      getHistory: getHistory,
      getMomentById: getMomentById,
      testConvertedMoment: testConvertedMoment,
      prepareMomentInsight: prepareMomentInsight,
    };

    /**
     *
     */
    function onLoginSuccess() {
      try {
        activate();
      } catch (e) {
        console.error(e);
      }
    }

    /**
     *
     */
    function onLogoutSuccess() {
      try {
        _unbindPusher();
      } catch (e) {
        console.error(e);
      }
    }

    /**
     *
     */
    function activate() {
      btTradeIdeasFiltersService.loadUserSettings().then(function () {
        _bindPusher();
      });
    }

    /**
     *
     * @return {boolean}
     */
    function isGoodVersion() {
      return btSettingsService.compareServerVersion(0, 2, 21) === -1;
    }

    /**
     * This function gets moments in some time range (by default 12 hours back).
     * This function connected to date offset feature (dev mode date offset).
     * @return {angular.IPromise<*>|angular.IPromise<*>}
     */
    function getTodayMoments() {
      if (isGoodVersion()) {
        if (gPusherBound === false) {
          _bindPusher();
        }

        var range = btDateService.getDayRange(24, 24);

        return lbMoment
          .getMomentsTimeBack({ interval: 'hours', number: 12, offset: range[4] })
          .$promise.then(function (response) {
            console.log('btMomentService: getTodayMoments data -', response.data);
            return response.data.filter(filterMoment).map(btMomentConverterService.extendMoment);
          })
          .catch(function (reason) {
            console.log('btMomentService: getTodayMoments error -', reason);
          });
      } else {
        return $q.reject(new Error('Old version'));
      }
    }

    /**
     *
     * @param {*} moment
     * @return {boolean}
     */
    function filterMoment(moment) {
      if ($window.isProduction) {
        return moment.situation.insightTemplate.status === 'live';
      } else {
        if ($window.isDevelopment) {
          return true;
        }

        if ($window.isTesting) {
          return ['live', 'test'].indexOf(moment.situation.insightTemplate.status) !== -1;
        }

        return false;
      }
    }

    /**
     * Get history for selected moment by id
     * @param {String} id - moment id
     * @return {angular.IPromise<*>|angular.IPromise<*>}
     */
    function getHistory(id) {
      if (isGoodVersion()) {
        return lbMoment
          .getHistory({ id: id })
          .$promise.then(function (response) {
            console.log('btMomentService: getHistory (', id, ') data -', response.data);

            response.data.forEach(btMarketMovementService.fixMomentTime);

            return response.data;
          })
          .catch(function (reason) {
            console.log('btMomentService: getHistory error -', reason);
          });
      } else {
        return $q.reject(new Error('Old version'));
      }
    }

    /**
     * Run back-testing for selected moment
     * @param {Object} moment - moment object
     * @param {*} opts
     * @return {angular.IPromise<*>|angular.IPromise<*>}
     */
    function testMoment(moment, opts) {
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Loading BackTester Data...</p>' });
      return getHistory(moment.id)
        .then(function (rawMoments) {
          var moments = rawMoments.map(function (t) {
            return new Date(t.time).getTime() / 1000;
          });

          var yearAgo = btDateService.nYearsAgo(moment.time, 1).getTime() / 1000;
          var threeHoursAgo = btDateService.addMinutes(moment.time, -180) / 1000;

          moments = moments.filter(function (t) {
            return t > yearAgo && t <= threeHoursAgo;
          });

          // moments = moments.reverse();

          opts = opts || {};
          opts.moment = moment;

          return btSharedData.runBackTester('moment', moments, opts);
        })
        .catch(function (reason) {
          console.error('btMomentService: testMoment error -', reason);
          $ionicLoading.hide();
        });
    }

    /**
     *
     * @param {*} convertedMoment
     * @param {*} opts
     * @return {angular.IPromise.<any>}
     */
    function testConvertedMoment(convertedMoment, opts) {
      try {
        var moment = JSON.parse(JSON.stringify(convertedMoment));
        if (typeof moment.time === 'string') {
          moment.time = new Date(moment.time);
        } else {
          moment.time = new Date(moment.time * 1000);
        }
        return testMoment(moment, opts);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    /**
     *
     * @param {*} moment
     * @param {*} insight
     * @return {{id: number, totalSurpriseStrength: number, data1: {quality: number, successRate: {value: number, status: string}, win, action, total, class: string, event: string, weak: number, direction: string, market}, data, template: string, templateVars: {market, rate: string}, strengths: {}, totalStrength: number, type: string}}
     */
    function prepareMomentInsight(moment, insight) {
      void moment;

      var symbol = insight.values.symbol;

      if (btInstrumentsService.getBroker(symbol) === 'OANDA') {
        var oandaSymbol = btInstrumentsService.getSymbol(symbol);
        symbol = btInstrumentsService.convertBTName(oandaSymbol, 'oanda');
      }

      insight.data.btMarket = symbol;

      var template =
        insight.values.win +
        ' out of ' +
        insight.values.total +
        ' times ' +
        symbol +
        ' was on the ' +
        insight.values.direction +
        ' in similar situations';

      return {
        id: insight.values.direction === 'uptrend' ? 300 : 301,
        totalSurpriseStrength: 0,
        data1: {
          quality: 3.5,
          successRate: {
            value: 66,
            status: 'buy',
          },
          win: insight.values.win,
          action: insight.values.direction,
          total: insight.values.total,
          class: insight.values.direction === 'uptrend' ? 'positive' : 'negative',
          event: '***',
          weak: 1,
          direction: '***',
          market: insight.values.symbol,
        },
        data: insight.data,
        template: template,
        templateVars: {
          market: symbol,
          rate: insight.values.win + ' out of ' + insight.values.total,
        },
        strengths: {},
        totalStrength: 1,
        type: 'back-test',
      };
    }

    /**
     * Bind to pusher
     * @private
     */
    function _bindPusher() {
      if (gPusherBound === true) {
        return;
      }

      gPusherBound = true;
      btPusherService.channels.moments.bind('update', _handleTradeIdeas);

      // add developers events
      if ($window.isDevelopment) {
        btPusherService.channels.moments.bind('update-dev', _handleTradeIdeas);
      }

      // add testing events
      if ($window.isTesting) {
        btPusherService.channels.moments.bind('update-test', _handleTradeIdeas);
      }
    }

    /**
     * Unbind from pusher
     * @private
     */
    function _unbindPusher() {
      if (gPusherBound === false) {
        return;
      }

      gPusherBound = false;

      btPusherService.channels.moments.unbind('update', _handleTradeIdeas);

      // add developers events
      if ($window.isDevelopment) {
        btPusherService.channels.moments.unbind('update-dev', _handleTradeIdeas);
      }

      // add testing events
      if ($window.isTesting) {
        btPusherService.channels.moments.unbind('update-test', _handleTradeIdeas);
      }
    }

    /**
     * Handle pusher data
     * @param {btMomentObject} data - pusher message
     */
    function _handleTradeIdeas(data) {
      data = btMomentConverterService.extendMoment(data);

      if (isSituationObjectInWatchList(data, btWatchListService.getWatchedSymbols()) && isHighLevelMoment(data)) {
        btTimeSupervisionService(btAudioService.playIncomingInsight, null, 'PLAY_INSIGHT', 0.5);
        showMomentNotification(data);
      }

      showTradeIdeasNotification(data, parseUserTradeIdeas(data));

      $rootScope.$broadcast('moments:new-release', data);
    }

    /**
     *
     * @param {*} data
     * @param {*} watchlist
     * @return {boolean}
     */
    function isSituationObjectInWatchList(data, watchlist) {
      return watchlist.indexOf(btInstrumentsService.convertComplexName2BTName(data.situationObject)) !== -1;
    }

    /**
     *
     * @param {*} data
     * @return {*}
     */
    function parseUserTradeIdeas(data) {
      return data.insights.filter(function (insight) {
        var tradeIdea = btTradeIdeasService.convertToTradeIdea(insight);
        return tradeIdea.passUserFilter(btTradeIdeasFiltersService.getUserSettings());
      });
    }

    /**
     *
     * @param {btMomentObject} data - moment object
     */
    function showMomentNotification(data) {
      if (data.situation.insightTemplateName.indexOf('market.consecutive') !== 0) {
        var broker = btInstrumentsService.getBroker(data.situation.objectRaw);
        var params = {
          timeOut: 6000,
          closeButton: true,
          type: 'market',
          onTap: openInstrumentPage.bind(null, broker, data.situation.objectRaw),
        };

        var instrument = btInstrumentsService.getInstrumentByComplexSymbol(data.situation.objectRaw);
        if (data.situation.insightTemplateName.indexOf('market.interval-change') === 0) {
          if (hasMoreImpressive(instrument, data)) {
            if ($window.isDevMode) {
              btToastrService.info(data.strengthText, '[Less Impressive] ' + instrument.displayName, params);
            }
          } else {
            btToastrService.info(data.strengthText, instrument.displayName, params);
          }
        } else {
          btToastrService.info(data.strengthText, instrument.displayName, params);
        }

        var text = btInstrumentsService.getPronunciation(instrument) + ' ';

        if (data.situation.insightTemplateName.indexOf('market.day-change') === 0) {
          text = text + btMarketMovementService.dailyChangeTemplate(data.strength).toLowerCase();
          btVoiceAssistantHelperService.readMessage(text, 'movements', 'yesterday');
        } else if (data.situation.insightTemplateName.indexOf('market.intraday-movement') === 0) {
          text = text + btMarketMovementService.intradayMovementTemplate(data.strength).toLowerCase();
          btVoiceAssistantHelperService.readMessage(text, 'movements', 'lowHigh');
        } else if (data.situation.insightTemplateName.indexOf('market.interval-change') === 0) {
          var minutes = 'unknown';
          if (data.situation.insightTemplate.params && data.situation.insightTemplate.params.interval) {
            minutes = data.situation.insightTemplate.params.interval;
          }
          text = text + btMarketMovementService.intervalChangeTemplate(data.strength, minutes).toLowerCase();
          btVoiceAssistantHelperService.readMessage(text, 'movements', 'interval');
        } else if (data.situation.insightTemplateName.indexOf('market.cross-yesterday') === 0) {
          // It is replaced by realtime market alerts.
          // text = text + btMarketMovementService.crossYesterdayTemplate(data.strength).toLowerCase();
          // btVoiceAssistantHelperService.readMessage(text, 'movements', 'crossing');
        }
      }
    }

    /**
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @param {btMomentObject} data - moment object
     * @return {boolean}
     */
    function hasMoreImpressive(instrument, data) {
      var velocity = Math.abs(data.strength) / data.situation.insightTemplate.params.interval;

      if (
        gMovementCache[instrument.OandaSymbol] &&
        velocity <= gMovementCache[instrument.OandaSymbol].velocity &&
        data.time <= gMovementCache[instrument.OandaSymbol].expired
      ) {
        return true;
      } else {
        if (!gMovementCache[instrument.OandaSymbol]) {
          gMovementCache[instrument.OandaSymbol] = {};
        }

        gMovementCache[instrument.OandaSymbol].velocity = velocity;
        gMovementCache[instrument.OandaSymbol].expired = data.time + LESS_IMPRESSIVE_DELAY;

        return false;
      }
    }

    /**
     *
     * @param {*} data
     * @param {*} tradeIdeas
     */
    function showTradeIdeasNotification(data, tradeIdeas) {
      /** @type {String[]} */
      var symbols = tradeIdeas.map(function (t) {
        return t.data.market;
      });

      if (symbols && symbols.length) {
        var params = {
          timeOut: 6000,
          closeButton: true,
          type: 'trade',
          onTap: openTradeIdeasTab,
        };

        var situation = btTradingService.getInstrumentByBrokerSymbol(
          btInstrumentsService.getSymbol(data.situation.objectRaw)
        );

        var text, speech;
        var nSuffix = symbols.length > 1 ? 's' : '';

        if (btRestrictionService.hasFeature('trade-ideas')) {
          var textSymbols = [];
          var speechSymbols = [];
          symbols.forEach(function (symbol) {
            var instrument = btInstrumentsService.getInstrumentBySomeSymbol(symbol);
            textSymbols.push(instrument.displayName);
            speechSymbols.push(btInstrumentsService.getPronunciation(instrument));
          });

          text = 'Trade idea' + nSuffix + ' for ' + textSymbols.join(', ');
          speech = 'Price-driven trade idea' + nSuffix + ' for ' + speechSymbols.join(', ') + '.';
        } else {
          text = 'Trade idea' + nSuffix + ' for instrument' + nSuffix + ' from your watchlist';
          speech = 'Price-driven trade idea' + nSuffix + ' for instrument' + nSuffix + ' from your watchlist.';
        }

        text += ' triggered by ' + situation.displayName + ' movement.';
        speech += ' triggered by ' + btInstrumentsService.getPronunciation(situation) + ' movement.';

        btTimeSupervisionService(btAudioService.playIncomingInsight, null, 'PLAY_INSIGHT', 0.5);
        btToastrService.info(text, 'Price-driven', params);
        btVoiceAssistantHelperService.readMessage(speech, 'ideas', 'price');
      }
    }

    /**
     *
     */
    function openTradeIdeasTab() {
      $state.go('ecapp.app.main.trade-ideas');
    }

    /**
     *
     * @param {*} broker
     * @param {*} instrument
     */
    function openInstrumentPage(broker, instrument) {
      $state.go('ecapp.app.main.instrument-page', {
        broker: btTradingService.isDefaultBroker() && broker === 'OANDA' ? 'default' : broker.toLowerCase(),
        symbol: btInstrumentsService.getSymbol(instrument),
      });
    }

    /**
     *
     * @param {*} id
     * @return {angular.IPromise<unknown>}
     */
    function getMomentById(id) {
      var query = { filter: { where: { _id: id }, include: { situation: 'insightTemplate' } } };
      return lbMoment
        .findOne(query)
        .$promise.then(function (momentObject) {
          // fix time moment time
          // momentObject.time = (new Date(momentObject.time)).getTime() / 1000;
          return btMomentConverterService.extendMoment(momentObject);
        })
        .catch(function (reason) {
          console.log('btMomentService: error', reason);
        });
    }

    /**
     * Filter low level market movements usually base on levels.
     *
     * If movement don't have levels and don't special treatment it will be filtered. Consecutive candles and yesterday
     * extrema have special treatment. Consecutive candles have predefined threshold. Yesterday extrema pass under any
     * conditions.
     *
     * @param {btMomentObject} moment - market movement object
     * @return {Boolean}
     */
    function isHighLevelMoment(moment) {
      return isHighLevelMovement(
        moment.situation.insightTemplate.type,
        moment.strength,
        moment.situation.insightTemplate.params.levels
      );
    }

    /**
     *
     * @param {string} type -
     * @param {number} strength -
     * @param {number[]} levels -
     * @return {boolean}
     */
    function isHighLevelMovement(type, strength, levels) {
      return btMarketMovementService.isHighLevelMovement(type, strength, levels);
    }
  }

  btMarketMovementService.$inject = [];

  /**
   *
   * @return {ecapp.IMarketMovementService}
   */
  function btMarketMovementService() {
    console.log('Running btMomentService');

    var gThresholds = {
      consecutive: 6,
    };

    return {
      consecutiveTemplate: consecutiveTemplate,
      consecutiveHtmlTemplate: consecutiveHtmlTemplate,
      intradayMovementTemplate: intradayMovementTemplate,
      intradayMovementHtmlTemplate: intradayMovementHtmlTemplate,
      crossYesterdayTemplate: crossYesterdayTemplate,
      crossYesterdayHtmlTemplate: crossYesterdayHtmlTemplate,
      dailyChangeTemplate: dailyChangeTemplate,
      dailyChangeHtmlTemplate: dailyChangeHtmlTemplate,
      intervalChangeTemplate: intervalChangeTemplate,
      intervalChangeHtmlTemplate: intervalChangeHtmlTemplate,
      isHighLevelMovement: isHighLevelMovement,
      generateTrigger: generateTrigger,
      getTriggers: getTriggers,
      fixMomentTime: fixMomentTime,
      fixServerBug01: fixServerBug01,
    };

    /**
     * Fix server bug of open and close price calculation in cae of consecutive candles.
     * It can be removed in next version.
     * TODO: remove in next version
     * @param {Object} moment - moment object
     */
    function fixServerBug01(moment) {
      var temp;
      if (moment.strength > 0 && moment.payload.open > moment.payload.close) {
        temp = moment.payload.close;
        moment.payload.close = moment.payload.open;
        moment.payload.open = temp;
      }

      if (moment.strength < 0 && moment.payload.open < moment.payload.close) {
        temp = moment.payload.close;
        moment.payload.close = moment.payload.open;
        moment.payload.open = temp;
      }
    }

    /**
     *
     * @param {*} t
     */
    function fixMomentTime(t) {
      var milliseconds = new Date(t.time).getTime();
      if (milliseconds / 1000 < 1519127700 && t.insightTemplateName.indexOf('market.interval-change') !== 0) {
        t.time = new Date(milliseconds + 5 * 60 * 1000).toISOString();
      }
    }

    /**
     *
     * @param {*} strength
     * @param {*} interval
     * @return {string}
     */
    function consecutiveTemplate(strength, interval) {
      var number = Math.abs(strength).toString();
      var color = strength > 0 ? 'green' : 'red';
      return number + ' consecutive ' + color + ' candles (' + interval + ')';
    }

    /**
     *
     * @param {*} strength
     * @param {*} interval
     * @return {string}
     */
    function consecutiveHtmlTemplate(strength, interval) {
      var number = Math.abs(strength).toString();
      var color = strength > 0 ? 'green' : 'red';
      var cssClass = strength > 0 ? 'positive' : 'negative';
      return (
        '<span class="' +
        cssClass +
        '">' +
        number +
        '</span> consecutive <span class="' +
        cssClass +
        '">' +
        color +
        '</span> candles (' +
        interval +
        ')'
      );
    }

    /**
     *
     * @param {*} strength
     * @return {string}
     */
    function intradayMovementTemplate(strength) {
      var number = Math.abs(strength).toString();
      var trend = strength > 0 ? 'up' : 'down';
      var desc = strength > 0 ? 'low to high' : 'high to low';
      return 'Moves ' + trend + ' ' + number + '% (daily ' + desc + ')';
    }

    /**
     *
     * @param {*} strength
     * @return {string}
     */
    function intradayMovementHtmlTemplate(strength) {
      var number = Math.abs(strength).toString();
      var trend = strength > 0 ? 'up' : 'down';
      var desc = strength > 0 ? 'low to high' : 'high to low';
      var cssClass = strength > 0 ? 'positive' : 'negative';
      return 'Moves <span class="' + cssClass + '">' + trend + ' ' + number + '%</span> (daily ' + desc + ')';
    }

    /**
     *
     * @param {*} strength
     * @return {string}
     */
    function crossYesterdayTemplate(strength) {
      var trend = strength > 0 ? 'high' : 'low';
      return "Crosses yesterday's " + trend;
    }

    /**
     *
     * @param {*} strength
     * @return {string}
     */
    function crossYesterdayHtmlTemplate(strength) {
      var trend = strength > 0 ? 'high' : 'low';
      var cssClass = strength > 0 ? 'positive' : 'negative';
      return 'Crosses yesterday <span class="' + cssClass + '">' + trend;
    }

    /**
     *
     * @param {*} strength
     * @return {string}
     */
    function dailyChangeTemplate(strength) {
      var number = Math.abs(strength).toString();
      var trend = strength > 0 ? 'up' : 'down';
      return 'Moves ' + trend + ' ' + number + "% from yesterday's close";
    }

    /**
     *
     * @param {*} strength
     * @return {string}
     */
    function dailyChangeHtmlTemplate(strength) {
      var number = Math.abs(strength).toString();
      var trend = strength > 0 ? 'up' : 'down';
      var cssClass = strength > 0 ? 'positive' : 'negative';
      return 'Moves <span class="' + cssClass + '">' + trend + ' ' + number + "%</span> from yesterday's close";
    }

    /**
     *
     * @param {*} strength
     * @param {*} minutes
     * @return {string}
     */
    function intervalChangeTemplate(strength, minutes) {
      var number = Math.abs(strength).toString();
      var trend = strength > 0 ? 'up' : 'down';
      return 'Moves ' + trend + ' ' + number + '% in the last ' + minutes + ' minutes';
    }

    /**
     *
     * @param {*} strength
     * @param {*} minutes
     * @return {string}
     */
    function intervalChangeHtmlTemplate(strength, minutes) {
      var number = Math.abs(strength).toString();
      var trend = strength > 0 ? 'up' : 'down';
      var cssClass = strength > 0 ? 'positive' : 'negative';
      return (
        'Moves <span class="' + cssClass + '">' + trend + ' ' + number + '%</span> in the last ' + minutes + ' minutes'
      );
    }

    /**
     *
     * @param {string} type -
     * @param {number} strength -
     * @param {number[]} levels -
     * @return {boolean}
     */
    function isHighLevelMovement(type, strength, levels) {
      if (type === 'consecutive') {
        return Math.abs(strength) > gThresholds.consecutive;
      }

      if (type === 'cross-yesterday') {
        return true;
      }

      if (levels) {
        if (levels.length > 2) {
          return Math.abs(strength) > levels[1];
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    /**
     *
     * @param {*} displaySymbol
     * @param {*} insightType
     * @param {*} strength
     * @param {*} minutes
     * @param {*} link
     * @return {any}
     */
    function generateTrigger(displaySymbol, insightType, strength, minutes, link) {
      var trigger = {};

      if (link) {
        trigger.html = '<a href="' + link + '" class="highlight-text">' + displaySymbol + '</a> ';
        trigger.text = displaySymbol + ' ';
      } else {
        trigger.html = '<span class="highlight-text">' + displaySymbol + '</span> ';
        trigger.text = displaySymbol + ' ';
      }

      if (insightType === 'consecutive') {
        trigger.html += consecutiveHtmlTemplate(strength, '5min').toLowerCase();
        trigger.text += consecutiveTemplate(strength, '5min').toLowerCase();
      }

      if (insightType === 'intraday-movement') {
        trigger.html += intradayMovementHtmlTemplate(strength).toLowerCase();
        trigger.text += intradayMovementTemplate(strength).toLowerCase();
      }

      if (insightType === 'cross-yesterday') {
        trigger.html += crossYesterdayHtmlTemplate(strength).toLowerCase();
        trigger.text += crossYesterdayTemplate(strength).toLowerCase();
      }

      if (insightType === 'day-change') {
        trigger.html += dailyChangeHtmlTemplate(strength).toLowerCase();
        trigger.text += dailyChangeTemplate(strength).toLowerCase();
      }

      if (insightType === 'interval-change') {
        trigger.html += intervalChangeHtmlTemplate(strength, minutes).toLowerCase();
        trigger.text += intervalChangeTemplate(strength, minutes).toLowerCase();
      }

      return trigger;
    }

    /**
     * This function returns triggers
     * @return {{id: string, label: string, example: string}[]}
     */
    function getTriggers() {
      return [
        {
          id: 'cross-yesterday',
          label: 'Crossing yesterday high/low',
          example: 'Example: S&P500 crosses yesterday high',
        },
        {
          id: 'day-change',
          label: "Moves from yesterday's close",
          example: "Example: S&P500 moves up 2% from yesterday's close",
        },
        {
          id: 'interval-change',
          label: 'Fast movements',
          example: 'Example: S&P500 moves down 1% in the last 5 min',
        },
        {
          id: 'intraday-movement',
          label: 'Daily low to high movements',
          example: 'Example: S&P500 moves down 0.75%, daily high to low',
        },
        {
          id: 'consecutive',
          label: 'Consecutive green/red candles',
          example: 'Example: S&P500 8 green consecutive candles (5 minutes)',
        },
      ];
    }
  }
})();
