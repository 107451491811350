/**
 * Created by Sergey Panpurin on 5/6/2018.
 */

(function btComponentCalendarServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btComponentCalendarService';

  angular
    .module('ecapp')
    /**
     * This service implements calendar logic.
     *
     * @ngdoc service
     * @name btComponentCalendarService
     * @memberOf ecapp
     */
    .factory('btComponentCalendarService', ['$timeout', 'btDateService', btComponentCalendarService]);

  /**
   *
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.IDateService} btDateService
   * @return {ecapp.IComponentCalendarService}
   */
  function btComponentCalendarService($timeout, btDateService) {
    if (gDebug) console.log('Running btComponentCalendarService');

    return {
      prepareCards: prepareCards,
      startAutoUpdate: startAutoUpdate,
      stopAutoUpdate: stopAutoUpdate,
    };

    /**
     * @typedef {Object} btComponentCalendarCard
     * @property {String} id - card id
     * @property {Boolean} show - card visibility
     * @property {Boolean} marked - true for bt-calendar-end and bt-calendar-card
     * @property {String} day - card day
     * @property {Date} date - card date
     * @property {String[]} classes - card css classes
     * @property {String} template - path to html template of card
     * @property {Object} [data] - item object
     */

    /**
     * Prepare calendar cards: convert items to cards, add separators and end card if needed.
     * @param {Array} items - items to show
     * @param {Boolean} hasSeparator - has day separators
     * @param {{card:String, separator:String, end:String}} templates - paths to html templates
     * @return {btComponentCalendarCard[]} - calendar cards
     */
    function prepareCards(items, hasSeparator, templates) {
      var cards = items.filter(hasRequiredFields).map(convertToCard.bind(null, templates.card));

      if (cards.length > 0 && hasSeparator) {
        addDaySeparators(cards, templates.separator);
      }

      addEndCard(cards, templates.end);

      return cards;
    }

    /**
     * Start calendar auto update. Mark next cards, update collapsed view according to hot and next cards.
     * @param {btComponentCalendarCard[]} cards - calendar cards
     * @param {Number} collapseSize - number of cards in collapsed view
     * @param {Function} callback - update callback
     */
    function startAutoUpdate(cards, collapseSize, callback) {
      var nextDate = findNextCard(cards);

      var iNextCards = [];
      var iHotPastCards = [];
      if (nextDate !== null) {
        iNextCards = markNextCards(cards, nextDate);
        iHotPastCards = findHotPastCardIndices(cards, iNextCards[0], collapseSize);
        iNextCards = iHotPastCards.reverse().slice(0, 4).reverse().concat(iNextCards);
      }

      markCollapsedCards(cards, iNextCards, collapseSize);

      if (nextDate !== null) {
        var promise = scheduleNextMarkerUpdate(nextDate, cards, collapseSize, callback);
        if (typeof callback === 'function') {
          callback(promise);
        }
      }
    }

    /**
     * Find next card and return its date.
     * @param {btComponentCalendarCard[]} cards - calendar cards
     * @return {Date|null} - date of next card
     */
    function findNextCard(cards) {
      var now = btDateService.getNowDate();

      var futures = cards.filter(function (card) {
        return card.date > now && card.marked;
      });

      if (futures.length > 0) {
        return futures[0].date;
      } else {
        return null;
      }
    }

    /**
     * Find next card and return its date.
     * @param {btComponentCalendarCard[]} cards - calendar cards
     * @param {Number} iNext - index of first next card
     * @param {Number} collapseSize - number of cards in collapsed view
     * @return {Array} - indices of hot cards in past close to next card
     */
    function findHotPastCardIndices(cards, iNext, collapseSize) {
      var indices = [];
      var now = btDateService.getNowDate();

      cards.forEach(function (card, i) {
        if (card.marked && card.data.isHot && card.date < now && iNext - i <= collapseSize) {
          indices.push(i);
        }
      });

      return indices;
    }

    /**
     * Mark next cards.
     * @param {btComponentCalendarCard[]} cards - calendar cards
     * @param {Date} nextDate - date of next card
     * @return {Array} indices of next cards
     */
    function markNextCards(cards, nextDate) {
      var indices = [];
      var isFirst = true;

      cards.forEach(function (card, i) {
        if (card.date.getTime() === nextDate.getTime() && card.marked) {
          indices.push(i);
          addNextClass(card, isFirst);

          if (isFirst) {
            isFirst = false;
          }
        }
      });

      return indices;
    }

    /**
     * Add next css class to card.
     * @param {btComponentCalendarCard} card - calendar card
     * @param {Boolean} isFirst - is first next card
     */
    function addNextClass(card, isFirst) {
      card.classes.splice(1);
      card.classes.push('next');

      if (isFirst) {
        card.classes.push('first');
      }
    }

    /**
     * Mark cards for collapsed view.
     * @param {btComponentCalendarCard[]} cards - calendar cards
     * @param {Number[]} iNextCards - indices of cards to show in collapsed view
     * @param {Number} collapseSize - number of cards in collapsed view
     */
    function markCollapsedCards(cards, iNextCards, collapseSize) {
      // number of cards in calendar less than size of collapsed view - do nothing
      if (cards.length <= collapseSize) {
        return;
      }

      if (iNextCards.length === 0) {
        // we don't have next card = all cards in past - show last cards
        setCardsShow(cards.slice().reverse(), 0, collapseSize);
      } else if (iNextCards.length > collapseSize) {
        // we have next cards and it's number more than size of collapsed view - show part of next cards
        setCardsShow(cards, iNextCards[0], iNextCards[0] + collapseSize);
      } else {
        // we have next cards but it's number less than size of collapsed view - show max number of past cards
        var nRemains = collapseSize - iNextCards.length;
        if (iNextCards[0] > nRemains) {
          setCardsShow(cards, iNextCards[0] - nRemains, iNextCards[0] - nRemains + collapseSize);
        } else {
          setCardsShow(cards, 0, collapseSize);
        }
      }
    }

    /**
     * Schedule update of next cards.
     * @param {Date} nextDate - date of next card
     * @param {btComponentCalendarCard[]} cards - calendar cards
     * @param {Number} collapseSize - number of cards in collapsed view
     * @param {Function} callback - callback to save $timeout promise
     * @return {angular.IPromise<*>} $timeout promise
     */
    function scheduleNextMarkerUpdate(nextDate, cards, collapseSize, callback) {
      var delay = nextDate - btDateService.getNowDate();
      return $timeout(nextMarkerUpdateCallback.bind(null, cards, collapseSize, callback), delay);
    }

    /**
     * Update next cards.
     * @param {btComponentCalendarCard[]} cards - calendar cards
     * @param {Number} collapseSize - number of cards in collapsed view
     * @param {Function} callback - callback to save $timeout promise
     */
    function nextMarkerUpdateCallback(cards, collapseSize, callback) {
      remarkNextCards(cards);
      startAutoUpdate(cards, collapseSize, callback);
    }

    /**
     * Remark next cards.
     * @param {btComponentCalendarCard[]} cards - calendar cards
     */
    function remarkNextCards(cards) {
      cards.forEach(function (card) {
        if (isMarkedNext(card)) {
          moveNextToPast(card);
        }
      });
    }

    /**
     * Check is card marked as next.
     * @param {btComponentCalendarCard} card - calendar card
     * @return {Boolean} - is marked as next card
     */
    function isMarkedNext(card) {
      return card.classes.indexOf('next') !== -1;
    }

    /**
     * Remove last class ('next') and 'past' class.
     * @param {btComponentCalendarCard} card - calendar card
     */
    function moveNextToPast(card) {
      card.classes.splice(1);
      card.classes.push('past');
    }

    /**
     * Stop calendar auto update.
     * @param {angular.IPromise<*>} promise - $timeout promise
     * @return {Boolean} - promise was canceled
     */
    function stopAutoUpdate(promise) {
      return $timeout.cancel(promise);
    }

    /**
     * Set show to true.
     * @param {btComponentCalendarCard[]} cards - calendar cards
     * @param {Number} start - start index
     * @param {Number} stop - stop index
     */
    function setCardsShow(cards, start, stop) {
      cards.forEach(function (card, i) {
        card.show = i >= start && i < stop;
      });
    }

    /**
     * Convert item to card.
     * @param {String} template - path to html template
     * @param {Object} item - item object
     * @return {btComponentCalendarCard} - calendar card
     */
    function convertToCard(template, item) {
      var now = btDateService.getNowDate();
      var date = btDateService.getDateFromRow(item);
      return {
        id: item.id,
        show: true,
        marked: true,
        date: btDateService.getDateFromRow(item),
        classes: ['bt-calendar-card', date < now ? 'past' : 'future'],
        template: template,
        day: btDateService.getDayName(btDateService.getDateFromRow(item)),
        data: item,
      };
    }

    /**
     * Check does card has required fields.
     * @param {Object} item - item object
     * @return {Boolean} - does card has required fields
     */
    function hasRequiredFields(item) {
      return item.id !== undefined && item.time !== undefined;
    }

    /**
     * Add day separators to array of cards.
     * @param {btComponentCalendarCard[]} cards - calendar cards
     * @param {String} template - path to html template
     */
    function addDaySeparators(cards, template) {
      var days = [];
      var dayStart = btDateService.getDateFromRow(cards[0].data);
      days.push({ index: 0, date: dayStart });

      cards.forEach(function (card, i) {
        var itemStart = btDateService.getDateFromRow(card.data);
        if (!btDateService.sameDay(dayStart, itemStart)) {
          dayStart = itemStart;
          days.push({ index: i, date: dayStart });
        }
      });

      days.reverse().forEach(function (day) {
        cards.splice(day.index, 0, {
          id: btDateService.getDayName(day.date),
          show: true,
          marked: false,
          date: day.date,
          classes: ['bt-calendar-separator'],
          template: template,
          day: btDateService.getDayName(day.date),
        });
      });
    }

    /**
     * Add end card to array of cards, if last card is in the past.
     * @param {btComponentCalendarCard[]} cards - calendar cards
     * @param {String} template - path to html template
     */
    function addEndCard(cards, template) {
      var now = btDateService.getNowDate();

      if (cards.length > 0 && cards[cards.length - 1].date < now) {
        var tomorrow = btDateService.getTomorrow();

        cards.push({
          id: 'bt-calendar-end',
          show: true,
          marked: true,
          date: tomorrow,
          classes: ['bt-calendar-end'],
          template: template,
          data: {
            isHot: true,
          },
          day: btDateService.getDayName(tomorrow),
        });
      }
    }
  }
})();
