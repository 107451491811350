/**
 * Created by yatree on 16/08/16.
 */

(function btSliderFixClosure() {
  'use strict';

  /**
   * This directive fix ionic slider.
   *
   * @ngdoc directive
   * @name btSliderFix
   * @memberOf ecapp
   * @restrict A
   */

  angular.module('ecapp').directive('btSliderFix', btSliderFix);

  /**
   *
   * @return {angular.IDirective}
   */
  function btSliderFix() {
    return {
      restrict: 'A',
      controller: [
        '$scope',
        function ($scope) {
          $scope.isAccordionShown = true;
          $scope.slide = function (index) {
            $scope.selectedSlide = index;
          };
        },
      ],
    };
  }
})();
