/**
 * Created by Sergei Panpurin on 9/1/18.
 */

(function btTradeSituationServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btTradeSituationService';

  angular
    .module('ecapp')
    /**
     * Help to work with price
     *
     * @ngdoc service
     * @name btTradeSituationService
     * @memberOf ecapp
     */
    .factory('btTradeSituationService', btTradeSituationService);

  btTradeSituationService.$inject = ['btDateService', 'btTradeIdeasService'];

  /**
   *
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.ITradeIdeasService} btTradeIdeasService
   * @return {any}
   */
  function btTradeSituationService(btDateService, btTradeIdeasService) {
    if (gDebug) console.log('Running btTradeSituationService');

    var categoryIds = {
      events: 0,
      markets: 1,
      tweets: 2,
    };

    var categories = [
      { id: 'events', name: 'News-driven' },
      { id: 'markets', name: 'Price-driven' },
      { id: 'tweets', name: 'Tweets-driven' },
    ];

    /**
     *
     * @return {any}
     */
    function getCategories() {
      return categories;
    }

    /**
     *
     * @param {*} id
     * @return {any}
     */
    function getCategoryById(id) {
      if (categoryIds[id]) {
        return categories[categoryIds[id]];
      } else {
        return undefined;
      }
    }

    /**
     *
     * @param {*} id
     * @return {any}
     */
    function getCategoryHandler(id) {
      if (id === 'events') {
        return createFromRelease;
      }

      if (id === 'markets') {
        return createFromMoment;
      }

      if (id === 'tweets') {
        return createFromTweet;
      } else {
        return undefined;
      }
    }

    /**
     *
     * @param {*} category
     * @param {*} data
     */
    function TradeSituation(category, data) {
      initialize.apply(this);

      if (category && data) {
        var handler = getCategoryHandler(category);
        if (handler !== undefined) {
          handler.apply(this);
        } else {
          if (gDebug) console.log('Unsupported category');
        }
      } else {
        if (gDebug) console.log('Invalid arguments');
      }
    }

    /**
     *
     */
    function initialize() {
      this.category = {
        id: 'na',
        name: 'N/A',
      };

      this.isExpected = false;
      this.time = new Date();
      this.humanizedTime = 'now';

      this.trigger = {
        text: 'N/A',
        html: 'N/A',
        link: 'N/A',
        dev: 'N/A',
      };

      this.data = {};
    }

    /**
     *
     * @param {btRawTradingInsightContainer} release
     */
    function createFromRelease(release) {
      this.category = getCategoryById('events');

      this.isExpected = false;
      this.time = btDateService.getDateFromRow(release);
      this.humanizedTime = btDateService.getHumanisedTimeFromNow(this.time, true);

      this.trigger = btTradeIdeasService.generateReleaseTrigger(release);
      this.data = release;
    }

    /**
     *
     * @param {btRawMomentInsight} moment
     */
    function createFromMoment(moment) {
      this.category = getCategoryById('markets');

      this.isExpected = false;
      this.time = btDateService.getDateFromRow(moment);
      this.humanizedTime = btDateService.getHumanisedTimeFromNow(this.time, true);

      this.trigger = btTradeIdeasService.generateMomentTrigger(moment);
      this.data = moment;
    }

    /**
     *
     * @param {Object} tweet
     */
    function createFromTweet(tweet) {
      this.category = getCategoryById('tweets');

      this.data = tweet;
    }

    return {
      getCategories: getCategories,
      tradeSituationModel: TradeSituation,
    };
  }
})();
