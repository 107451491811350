/**
 * Created by yatree on 16/08/16.
 */

(function btToggleClosure() {
  'use strict';

  /**
   *  This directive display toggle. Use btCheck to connect it to some value. Variable btArgument can be used to use one
   *  clickFunction in few cases, it will define changed value.
   *
   *  Features:
   *   1. Synchronization - value can be changed outside, toggle will show this change
   *   2. Restriction - clickFunction can return false to move toggle immediately to previous position.
   *      Synchronization can be used to implement Restriction too. In this case clickFunction must change value.
   *
   * @ngdoc directive
   * @name btToggle
   * @memberOf ecapp
   * @restrict E
   * @scope
   * @priority 0
   * @param {expression} btCheck
   * @param {function} clickFunction
   * @param {function} onUpgrade
   */

  angular.module('ecapp').directive('btToggle', btToggle);

  btToggle.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btToggle($templateCache) {
    return {
      restrict: 'E',
      template: $templateCache.get('directives/common/bt-toggle.html'),
      scope: {
        btIcon: '@?',
        onIcon: '&?',
        btIconArg: '=?',
        btLink: '@?',
        btLabel: '@',
        btSubLabel: '@?',
        btDisabled: '@?',
        btCheck: '=',
        btArgument: '@',
        clickFunction: '&',
        needUpgrade: '@?',
        upgradeText: '@?',
        onUpgrade: '&?',
        btActionText: '@?',
        onAction: '&?',
        btActionArg: '=?',
      },
      controller: btToggleController,
    };
  }

  btToggleController.$inject = ['$scope'];

  /**
   *
   * @param {*} $scope
   */
  function btToggleController($scope) {
    $scope.openLink = openLink;
    $scope.onToggleChange = onToggleChange;
    $scope.hasLink = !!$scope.btLink;
    $scope.$watch('btCheck', onCheckChange);

    $scope.hasUpgrade = $scope.needUpgrade === 'true';
    $scope.upgrade = upgrade;
    $scope.onActionClick = onActionClick;
    $scope.onIconClick = onIconClick;

    /**
     * Open link if it defined
     */
    function openLink() {
      if ($scope.btLink) {
        window.open($scope.btLink, '_system');
      }
    }

    /**
     * Call on change of toggle position by user. To deny this action use $timeout(function () {}) in clickFunction
     */
    function onToggleChange() {
      // console.log('btToggle - Changed - position:', $scope.btCheck);
      var result;
      var previousValue;
      if ($scope.btCheck) {
        result = $scope.clickFunction({ name: $scope.btArgument, position: true });
        previousValue = false;
      } else {
        result = $scope.clickFunction({ name: $scope.btArgument, position: false });
        previousValue = true;
      }
      if (typeof result === 'boolean') {
        if (result === false) {
          $scope.btCheck = previousValue;
        }
      } else if (result && typeof result.then === 'function') {
        result
          .then(function (isSuccess) {
            if (isSuccess === false) {
              $scope.btCheck = previousValue;
            }
          })
          .catch(function (reason) {
            console.log(reason);
            $scope.btCheck = previousValue;
          });
      }
    }

    /**
     * Synchronize toggle position
     * @param {Boolean} newValue - new toggle position
     * @param {Boolean} oldValue - old toggle position
     */
    function onCheckChange(newValue, oldValue) {
      if (newValue !== oldValue && oldValue !== undefined) {
        // console.log('btToggle - Watched - position:', $scope.btCheck);
      }
    }

    /**
     *
     */
    function upgrade() {
      if (typeof $scope.onUpgrade === 'function') {
        $scope.onUpgrade();
      }
    }

    /**
     *
     */
    function onActionClick() {
      if (typeof $scope.onAction === 'function') {
        $scope.onAction({ item: $scope.btActionArg });
      }
    }

    /**
     *
     */
    function onIconClick() {
      if (typeof $scope.onIcon === 'function') {
        $scope.onIcon({ item: $scope.btIconArg });
      }
    }
  }
})();
