/**
 * Created by Sergey Panpurin on 5/23/2016.
 */

(function btTradeIdeasTabControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btTradeIdeasTabController', btTradeIdeasTabController);

  btTradeIdeasTabController.$inject = [
    '$scope',
    '$timeout',
    '$ionicHistory',
    '$analytics',
    '$state',
    'btShareScopeService',
    'btEventsService',
    'btInstrumentsService',
    'btTradingService',
    'btSettingsService',
  ];

  /**
   * This is controller for insights tab.
   * Display post insights with absolute value of totalSurpriseStrength more than insightThr (0.4)
   *
   * @ngdoc controller
   * @name btTradeIdeasTabController
   * @memberOf ecapp
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {angular.ITimeoutService} $timeout
   * @param {ionic.IHistoryService} $ionicHistory - ionic history service
   * @param {ext.IAnalyticsService} $analytics
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.IShareScopeService} btShareScopeService - common angular scope service
   * @param {ecapp.IEventsService} btEventsService
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btTradeIdeasTabController(
    $scope,
    $timeout,
    $ionicHistory,
    $analytics,
    $state,
    btShareScopeService,
    btEventsService,
    btInstrumentsService,
    btTradingService,
    btSettingsService
  ) {
    console.log('Running btTradeIdeasTabController');

    var gLocalSettings = btSettingsService.getLocalSettings();

    // page states
    $scope.pageState = 'init';

    $scope.intervalFunc = null;
    $scope.displayOptions = gLocalSettings.get('trade-ideas.appearance') || btTradingService.getDefaultDisplayOptions();
    $scope.refreshPage = refreshPageFunc;
    $scope.doRefresh = doRefresh;
    $scope.openMarkets = openMarkets;

    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('$ionicView.enter', onViewEnter);
    $scope.$on('market:follow', onWatchlistUpdate);
    $scope.$on('market:unfollow', onWatchlistUpdate);
    $scope.$watchCollection('displayOptions', updateDisplayOptions);

    activate();

    /**
     * Whether watchlist was changed refresh trade ideas page.
     */
    function onWatchlistUpdate() {
      // Hide trade ideas feed
      $scope.pageState = 'loading';

      // Wait a bit to be sure that DOM was updated
      $timeout(function () {
        refreshPageFunc();
      }, 200);
    }

    /**
     *
     * @param {*} newValue
     * @param {*} oldValue
     */
    function updateDisplayOptions(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        console.log('updateDisplayOptions', newValue, oldValue);
        gLocalSettings.set('trade-ideas.appearance', newValue);
      }
    }

    /**
     *
     */
    function onViewLoaded() {
      onViewOpen();
    }

    /**
     *
     */
    function onViewEnter() {
      $ionicHistory.clearHistory();

      onViewOpen();
    }

    /**
     *
     */
    function onViewOpen() {
      $timeout(function () {
        $('#bt-trade-ideas-content').focus();
      }, 1000);
    }

    /**
     *
     */
    function refreshPageFunc() {
      activate();
    }

    /**
     *
     */
    function doRefresh() {
      activate().finally(function () {
        $scope.$broadcast('scroll.refreshComplete');
      });
    }

    /**
     *
     */
    function openMarkets() {
      $state.go('ecapp.app.main.markets');
    }

    /**
     *
     * @return {*}
     */
    function activate() {
      $scope.pageState = 'loading';

      return btShareScopeService
        .wait()
        .then(btTradingService.initialize)
        .then(btTradingService.connect)
        .then(btEventsService.init)
        .then(btInstrumentsService.init)
        .then(function () {
          var watchedInstruments = btTradingService.getWatchedInstruments() || [];
          $scope.pageState = watchedInstruments.length > 0 ? 'good' : 'empty';
          $scope.complexSymbols = watchedInstruments
            .map(function (t) {
              return t.complexSymbol;
              // return btInstrumentsService.createComplexSymbol(t.brokerSymbol, t.brokerName);
            })
            .filter(function (t) {
              return !!t;
            })
            .join(',');
          $scope.displaySymbols = $scope.complexSymbols;
        })
        .catch(function (reason) {
          console.error(reason);
          $scope.pageState = 'error';
        });
    }
  }
})();
