/**
 * Created by Sergey Panpurin on 1/22/19.
 */

(function btCalendarToolbar() {
  'use strict';

  var gDebug = false;

  angular
    .module('ecapp')
    /**
     * This directive displays calendar toolbar
     *
     * @ngdoc directive
     * @name btCalendarToolbar
     * @memberOf ecapp
     * @param {String} title - toolbar title display current date
     * @param {function} onAction - callback function to react on filtering or refreshing
     * @scope
     */
    .directive('btCalendarToolbar', btCalendarToolbar)
    .controller('btCalendarToolbarController', btCalendarToolbarController);

  btCalendarToolbar.$inject = ['$templateCache', '$ionicScrollDelegate', '$ionicPosition'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {ionic.IScrollDelegateService} $ionicScrollDelegate
   * @param {ionic.IPositionService} $ionicPosition
   * @return {angular.IDirective}
   */
  function btCalendarToolbar($templateCache, $ionicScrollDelegate, $ionicPosition) {
    return {
      restrict: 'E',
      scope: {
        filters: '=',
        amount: '@',
        onAction: '&',
      },
      template: $templateCache.get('directives/calendar/bt-calendar-toolbar.html'),
      controller: 'btCalendarToolbarController',
      link: link,
    };

    /**
     *
     * @param {*} $scope
     * @param {angular.IRootElementService} $element
     * @param {angular.IAttributes} $attrs
     */
    function link($scope, $element, $attrs) {
      void $attrs;

      if (gDebug) {
        console.log('btCalendarToolbar: Element', $element);
        // noinspection JSUnresolvedFunction
        console.log('btCalendarToolbar: Scroll Delegate', $ionicScrollDelegate.$getByHandle('mainScrollRows'));
      }

      // Prepare title object
      /** @type {ToolbarTitle} */
      var title = new ToolbarTitle($element.children().children().first());

      // var title = {
      //   element: $element.children().children().first(),
      //   updated: Date.now(),
      //   interval: 66,
      //   pending: false,
      //   update: updateTitle
      // };

      var $scroll = null;
      var $dividers = [];
      var previous = null;

      var scrollHandle = getScrollHandle();

      if (scrollHandle) {
        $scroll = angular.element(scrollHandle.element);
        $scroll.on('scroll', calendarScrollObserver);
      } else {
        alert("Can't initialize calendar toolbar");
      }

      /**
       * This function is called on calendar scrolling. It is called without debouncing due to setTimeout can increase
       * delay during scrolling.
       */
      function calendarScrollObserver() {
        // Initialize dividers
        if ($dividers.length === 0 && $scroll) {
          var dividers = $scroll[0].getElementsByClassName('section-date');
          for (var i = 0; i < dividers.length; ++i) {
            $dividers.push(angular.element(dividers[i]));
          }
        }

        // Find actual divider
        var current = null;
        for (var j = $dividers.length - 1; j >= 0; --j) {
          // Use reverse order to stop after first actual divider
          if (isActual($dividers[j])) {
            current = j;
            break;
          }
        }

        // React on new actual divider
        if (previous !== current) {
          previous = current;
          if (current !== null) {
            title.update($dividers[current].text() + (window.isDevMode ? '(' + $scope.amount + ')' : ''));
          }
        }

        // Execute pending title update
        if (title.pending && previous) {
          title.update($dividers[previous].text() + (window.isDevMode ? '(' + $scope.amount + ')' : ''));
        }

        if (gDebug) console.log('btCalendarToolbar: Scrolling', Date.now());
      }

      /**
       * This function checks is divider actual
       *
       * @param {Object} divider - jQuery object
       * @return {Boolean}
       */
      function isActual(divider) {
        var delta = $ionicPosition.offset(divider).top;
        if (gDebug) console.log('btCalendarToolbar: delta', delta);
        return delta < 150;
      }
    }

    /**
     * This function get scroll handle
     *
     * @return {Object}
     */
    function getScrollHandle() {
      var scrollName = 'mainScrollRows';
      // noinspection JSUnresolvedFunction
      var instances = $ionicScrollDelegate.$getByHandle(scrollName)['_instances'];
      return instances.filter(function (element) {
        return element['$$delegateHandle'] === scrollName;
      })[0];
    }
  }

  /**
   * Class of toolbar title
   * @param {Object} element
   * @class
   */
  function ToolbarTitle(element) {
    /** @type {Object} */
    this.element = element;
    /** @type {Number} */
    this.updated = Date.now();
    /** @type {Number} */
    this.interval = 66;
    /** @type {Boolean} */
    this.pending = false;
  }

  /**
   * This function updates toolbar title
   *
   * Scrolling can generate a lot of events. Due to setTimeout can increase delay during scrolling,
   * this implementation use internal timer to prevent big number of DOM manipulations. Last title update can be
   * marked as pending if it was skipped.
   *
   * @name ToolbarTitle#update
   * @param {String} text - new title text
   */
  ToolbarTitle.prototype.update = function updateTitle(text) {
    var now = Date.now();
    if (now - this.updated > this.interval) {
      this.updated = now;
      this.pending = false;
      this.element.text(text);
      if (gDebug) console.log('btCalendarToolbar: Update title');
    } else {
      this.pending = true;
    }
  };

  btCalendarToolbarController.$inject = ['$scope'];

  /**
   *
   * @param {*} $scope
   */
  function btCalendarToolbarController($scope) {
    activate();

    /**
     * Controller constructor
     */
    function activate() {
      // Function declarations
      $scope.refreshCalendar = refreshCalendar;
      $scope.toggleWatchListFilter = toggleWatchListFilter;

      // Variable declarations

      // The rest of the activation
      $scope.$on('$destroy', onDestroy);
    }

    /**
     * This function is controller destructor
     */
    function onDestroy() {}

    /**
     *
     */
    function refreshCalendar() {
      $scope.onAction({ action: 'refresh' });
    }

    /**
     *
     */
    function toggleWatchListFilter() {
      $scope.onAction({ action: 'toggle-watchlist' });
    }
  }
})();
