(function () {
  'use strict';

  angular
    .module('ecapp')
    /**
     * ???
     *
     * @ngdoc directive
     * @name btEventModalSpeakerDescription
     * @memberOf ecapp
     */
    .directive('btEventModalSpeakerDescription', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/event-modal/speech/description.html'),
          controller: [
            '$scope',
            function ($scope) {
              $scope.hasDescriptionObj = {};
              $scope.hasDescriptionObj.hasDescription = false;
            },
          ],
        };
      },
    ]);
})();
