/**
 * Created by Orly on 20/02/19.
 */

(function btSurprisingEventsClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * ???
     *
     * @ngdoc directive
     * @name btSurprisingEvents
     * @memberOf ecapp
     * @scope
     */
    .directive('btSurprisingEvents', btSurprisingEvents);

  btSurprisingEvents.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btSurprisingEvents($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('directives/barometer/bt-surprising-events-widget.html'),
      controller: btSurprisingEventsController,
      controllerAs: 'vm',
    };
  }

  btSurprisingEventsController.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    'btShareScopeService',
    'btEventsService',
    'btRowProcessorService',
    'btCalendarService',
    'btDateService',
    'btRestrictionService',
    'btSettingsService',
  ];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IEventsService} btEventsService
   * @param {ecapp.IRowProcessorService} btRowProcessorService
   * @param {ecapp.ICalendarService} btCalendarService
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btSurprisingEventsController(
    $scope,
    $rootScope,
    $state,
    btShareScopeService,
    btEventsService,
    btRowProcessorService,
    btCalendarService,
    btDateService,
    btRestrictionService,
    btSettingsService
  ) {
    void $scope;

    /*jshint validthis: true*/
    var vm = this;

    var gSettings = btSettingsService.getLocalSettings();

    vm.text = {
      loading: 'Loading surprising events',
      title: 'Surprising events you follow',
      less: 'Show less',
      more: 'Show more',
      empty: 'Surprising events will be displayed here.',
    };

    vm.html = {
      upgrade: '',
    };

    vm.isLoading = true;
    vm.events = [];
    vm.listExpanded = false;
    vm.hasFeature = btRestrictionService.hasFeature('release-magnitude');
    vm.upgrade = upgrade;

    vm.openRelease = openRelease;
    vm.updateTimePassed = updateTimePassed;
    vm.showMoreLess = showMoreLess;
    vm.getEventTimeAgo = getEventTimeAgo;

    $rootScope.$on('calendar:new-release', updateWidget);
    $rootScope.$on('calendar:changed', updateWidget);
    $rootScope.$on('calendar:reloaded', updateWidget);
    $rootScope.$on('calendar:follow-event', updateWidget);
    $rootScope.$on('subscription:updated', onSubscriptionUpgradeSuccess);

    activate();

    /**
     * This function reacts on subscription update.
     */
    function onSubscriptionUpgradeSuccess() {
      vm.hasFeature = btRestrictionService.hasFeature('release-magnitude');
    }

    /**
     * This function actives component.
     */
    function activate() {
      loadSettings();
      getEvents();
    }

    /**
     * This function toggles more-less option.
     */
    function showMoreLess() {
      vm.listExpanded = !vm.listExpanded;
      saveSettings();
      getEvents();
    }

    /**
     * This function get events.
     */
    function getEvents() {
      vm.isLoading = true;

      if (!vm.hasFeature) {
        vm.events = [];
        vm.isLoading = false;
      } else {
        btCalendarService.getCalendar().then(function (calendar) {
          var favorites = btShareScopeService.getListFollowedEvents();
          var magnitudes;
          if (vm.listExpanded) {
            magnitudes = ['extremely-stronger', 'extremely-weaker', 'much-stronger', 'much-weaker'];
          } else {
            magnitudes = ['extremely-stronger', 'extremely-weaker'];
          }

          vm.events = calendar.cards.filter(isSurprisingEvent).sort(sortByTime);
          vm.isLoading = false;

          /**
           *
           * @param {*} event
           * @return {boolean}
           */
          function isSurprisingEvent(event) {
            return (
              isRelease(event) &&
              isTodayRelease(event) &&
              isSurprisingRelease(magnitudes, event) &&
              isFavoriteRelease(favorites, event)
            );
          }
        });
      }
    }

    /**
     *
     * @param {*} event
     * @return {*}
     */
    function isRelease(event) {
      return event && event.eventId;
    }

    /**
     *
     * @param {*} magnitudes
     * @param {*} event
     * @return {boolean}
     */
    function isSurprisingRelease(magnitudes, event) {
      return !!event.strengthPercentage && magnitudes.indexOf(event.strengthPercentage.magnitude) > -1;
    }

    /**
     *
     * @param {*} favorites
     * @param {*} event
     * @return {boolean}
     */
    function isFavoriteRelease(favorites, event) {
      return favorites.indexOf(event.eventId) > -1;
    }

    /**
     *
     * @param {*} event
     * @return {boolean}
     */
    function isTodayRelease(event) {
      return Date.now() - btDateService.getDateFromRow(event).getTime() < 24 * 60 * 60 * 1000;
    }

    /**
     *
     * @param {*} a
     * @param {*} b
     * @return {number}
     */
    function sortByTime(a, b) {
      return b.time - a.time;
    }

    /**
     * This function open release page
     *
     * @ngdoc method
     * @name openRelease
     * @memberOf ecapp.btAllReviewsController
     * @param {btRelease} release - (changeable) release data
     */
    function openRelease(release) {
      var data = btRowProcessorService.getReleaseIdData(release, release.eventsInfo);
      $state.go('ecapp.app.main.detail', data);
    }

    /**
     *
     * @param {*} event
     */
    function updateTimePassed(event) {
      event.ago = btDateService.getHumanisedTimeFromNow(btDateService.getDateFromRow(event), true);
    }

    /**
     *
     * @param {*} event
     * @param {*} data
     */
    function updateWidget(event, data) {
      console.log('btSurprisingEventsWidget', data);
      getEvents();
    }

    /**
     *
     * @param {*} event
     * @return {string}
     */
    function getEventTimeAgo(event) {
      return event.ago + ' @ ' + event.clock;
    }

    /**
     *
     */
    function upgrade() {
      btRestrictionService.showUpgradePopup('release-magnitude');
    }

    /**
     * This function saves user settings.
     */
    function loadSettings() {
      vm.listExpanded = gSettings.get('barometer.surprising-events.expanded');
      vm.listExpanded = vm.listExpanded !== undefined ? vm.listExpanded : false;
    }

    /**
     * This function loads user settings.
     */
    function saveSettings() {
      gSettings.set('barometer.surprising-events.expanded', vm.listExpanded);
    }
  }
})();
