/**
 * Created by Orly on 31/01/2019.
 */

(function btAccountSettingsCtrlClosure() {
  'use strict';

  angular.module('ecapp').controller('btHelpCenterArticleController', btHelpCenterArticleController);

  btHelpCenterArticleController.$inject = ['$scope', '$stateParams', '$http', '$filter', 'btSettings'];

  /**
   * Controller for help center article page.
   *
   * @ngdoc controller
   * @name ecapp.btHelpCenterArticleController
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {angular.ui.IStateService} $stateParams
   * @param {angular.IHttpService} $http
   * @param {angular.IFilterService} $filter
   * @param {ecapp.ISettings} btSettings
   */
  function btHelpCenterArticleController($scope, $stateParams, $http, $filter, btSettings) {
    console.log('btHelpCenterArticleController: ', $stateParams, $stateParams.state, $stateParams.type);
    var gBaseUrl = btSettings.BT_BACKEND_URL + '/v1/help-center';
    getHelpCenterArticle();

    /**
     *
     */
    function getHelpCenterArticle() {
      $http
        .get(gBaseUrl + '/articles', {
          // different url => different response => no need for filtering articles
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            state: $stateParams.state,
            type: $stateParams.type,
          },
        })
        .then(function success(response) {
          console.log('btHelpCenterArticleController: success', response);
          $scope.article = $filter('filter')(response.data.articles, function (article) {
            return article.title === $stateParams.title;
          })[0];
          console.log('btHelpCenterArticleController: found', $scope.article);
        })
        .catch(function error(response) {
          console.log('btHelpCenterArticleController: error', response);
        });
    }
  }
})();
