// Created by Sergey Panpurin on 8/10/2016.
//
// This service publish message to PubNub for next processing
//

/*global PubNub*/

// @ts-check
(function btPubNubServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btPubNubService', btPubNubService);

  btPubNubService.$inject = ['$rootScope', 'btSettings'];

  /**
   * @ngdoc service
   * @name btPubNubService
   * @memberOf dashboard
   * @description
   *  ???
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.ISettings} btSettings
   * @return {ecapp.IPubNubService}
   */
  function btPubNubService($rootScope, btSettings) {
    var devClient = null;
    var mainClient = null;

    activate();

    return {
      devPublish: devPublish,
    };

    /**
     *
     */
    function activate() {
      // PubNub Dev Client
      if (window.isDevMode) {
        devClient = new PubNub({
          publishKey: btSettings.BT_PUBNUB_DEV_PUBLISH,
          subscribeKey: btSettings.BT_PUBNUB_DEV_SUBSCRIBE,
          logVerbosity: false,
        });
      }

      // // PubNub Main Client
      // mainClient = new PubNub(
      //   {
      //     publishKey: btSettings.BT_PUBNUB_PUBLISH,
      //     subscribeKey: btSettings.BT_PUBNUB_SUBSCRIBE,
      //     logVerbosity: true
      //   });
      //
      // var existingListener = {
      //   status: function (statusEvent) {
      //     if (statusEvent.category === 'PNConnectedCategory') {
      //       console.log('Connected');
      //     }
      //   },
      //   signal: function (s) {
      //     // handle signal
      //     var channelName = s.channel; // The channel for which the signal belongs
      //     var channelGroup = s.subscription; // The channel group or wildcard subscription match (if exists)
      //     var pubTT = s.timetoken; // Publish timetoken
      //     var msg = s.message; // The Payload
      //     var publisher = s.publisher; //The Publisher
      //   },
      //   message: function (m) {
      //     // handle message
      //     var channelName = m.channel; // The channel for which the message belongs
      //     // var channelGroup = m.subscription; // The channel group or wildcard subscription match (if exists)
      //     // var pubTT = m.timetoken; // Publish time token
      //     var msg = m.message; // The Payload
      //     // var publisher = m.publisher; // The Publisher
      //     if (channelName === 'risk-monitor') {
      //       console.log(msg);
      //       $rootScope.$broadcast('risk-monitor:update', msg);
      //     }
      //   },
      //   messageAction: function (ma) {
      //     // handle message action
      //     var channelName = ma.channel; // The channel for which the message belongs
      //     var publisher = ma.publisher; //The Publisher
      //     var event = ma.message.event; // message action added or removed
      //     var type = ma.message.data.type; // message action type
      //     var value = ma.message.data.value; // message action value
      //     var messageTimetoken = ma.message.data.messageTimetoken; // The timetoken of the original message
      //     var actionTimetoken = ma.message.data.actionTimetoken; //The timetoken of the message action
      //   }
      // };
      //
      // // Subscribe to the demo_tutorial channel
      // mainClient.addListener(existingListener);
      //
      // mainClient.subscribe({
      //   channels: ['risk-monitor']
      // });

      // mainClient.removeListener(existingListener);
    }

    /**
     *
     * @param {*} channel
     * @param {*} data
     */
    function devPublish(channel, data) {
      if (devClient) {
        devClient.publish(
          {
            channel: channel,
            message: data,
          },
          function (status, response) {
            console.log(status, response);
          }
        );
      }
    }
  }
})();
