// @ts-check
(function btTradierControllerClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * @ngdoc controller
     * @name ecapp.btTradierController
     * @description
     *  ???
     */
    .controller('btTradierController', btTradierController);

  btTradierController.$inject = [
    '$scope',
    'btToastrService',
    '$q',
    'btSubscriptionService',
    'btOpenPopupService',
    'btShareScopeService',
    'btRestrictionService',
  ];

  /**
   *
   * @param {ecapp.ICustomScope} $scope - ?
   * @param {ecapp.IToastrService} btToastrService - ?
   * @param {angular.IQService} $q - promise interface
   * @param {ecapp.ISubscriptionService} btSubscriptionService - ?
   * @param {ecapp.IOpenPopupService} btOpenPopupService - ?
   * @param {ecapp.IShareScopeService} btShareScopeService - ?
   * @param {ecapp.IRestrictionService} btRestrictionService - ?
   */
  function btTradierController(
    $scope,
    btToastrService,
    $q,
    btSubscriptionService,
    btOpenPopupService,
    btShareScopeService,
    btRestrictionService
  ) {
    // list of offers (it is cropped stripe plan object sue to we use payments-box to display offer)
    $scope.offers = [
      {
        id: 'tradier-monthly',
        amount: 9999,
        currency: 'usd',
        interval: 'month',
        metadata: {
          html:
            '<ul class="payments-list"> ' +
            '<li class="payments-list">BetterTrader Advanced Plan</li> ' +
            '<li class="payments-list">Reliable Broker</li> ' +
            '<li class="payments-list">No commissions per trade; just one payment per month</li> ' +
            '<li class="payments-list">Analyse and Trade quickly with your broker without having to switch between apps</li> </ul>',
        },
        name: '$0 COMMISSION Plan',
        url: 'https://brokerage.tradier.com/signup/getting-started?platform=54&promo=UnlimitedWithBetterTrader',
        button: 'Open Account',
        handler: handleUnlimited,
      },
      {
        id: 'tradier-pay-as-go',
        amount: 0,
        metadata: {
          html:
            '<ul class="payments-list"> <li class="payments-list">Pay just $3.45 per equity order</li> ' +
            '<li class="payments-list">$99 monthly fee is waived if you trade 23 or more in a month</li> ' +
            '<li class="payments-list">90-day Free Trial!</li> </ul>',
        },
        name: 'Pay As You Go Plan',
        url: 'https://brokerage.tradier.com/signup/getting-started?platform=54&promo=PayAsYouGoWithBetterTrader',
        button: 'Open Account',
        handler: handlePayAsGo,
      },
    ];

    // selected offer
    $scope.selected = $scope.offers[0];

    // payment in process
    $scope.paymentLoading = false;

    // signal to show thank-you page
    $scope.finished = false;

    // popup was closed
    $scope.popupClosed = false;

    // get subscription information from cache
    var subscription = btSubscriptionService.getCombinedSubscription();

    // is user has subscription
    $scope.recurring = subscription.recurring;

    // is user subscription plan in Tradier Unlimited
    $scope.hasUnlimited = subscription.id === 'tradier-monthly';

    /**
     * Open popup window to fill Tradier sign up form
     * @return {angular.IPromise<*>}
     */
    $scope.openForm = function () {
      var url = $scope.offers[0].url;
      if ($scope.selected !== null) {
        url = $scope.selected.url;
      }

      return btOpenPopupService
        .openPopup(url)
        .then(function (params) {
          console.log(params);
        })
        .finally(function () {
          $scope.popupClosed = true;
        });
    };

    /**
     * Select plan
     *
     * @param {object} offer - offer object from $scope.offers
     */
    $scope.selectPlan = function (offer) {
      $scope.selected = offer;
    };

    /**
     *
     * @param {*} plan - ?
     * @param {*} buySubscription - ?
     */
    $scope.upgradeClick = function (plan, buySubscription) {
      if (!plan) return;

      $scope.selected = plan;

      $scope.selected.handler($scope.selected, buySubscription);
    };

    /**
     *
     * @param {*} offer - ?
     */
    function handlePayAsGo(offer) {
      void offer;

      $scope.finished = true;

      // save information of visiting this page to sent mail with instructions
      if (btRestrictionService.hasFeature('personalization')) {
        btShareScopeService.increaseVisitPage('tradierpay');
      }
    }

    /**
     *
     * @param {*} plan - ?
     * @param {*} buySubscription - ?
     */
    function handleUnlimited(plan, buySubscription) {
      if (typeof buySubscription === 'function') {
        var promise = buySubscription(plan, '');
        if (promise && typeof promise.then === 'function') {
          promise
            .then(function (status) {
              if (status === 'success') {
                btToastrService.success('Congratulations. You have subscription!', 'Subscription', { type: 'trade' });
                $scope.finished = true;
                if (btRestrictionService.hasFeature('personalization')) {
                  return btShareScopeService.increaseVisitPage('tradierunl');
                }
              }
            })
            .catch(function (reason) {
              btToastrService.error(reason.message, 'Create Tradier account', { type: 'trade' });
            });
        }
      }
    }

    /**
     *
     * @param {*} plan - ?
     * @param {*} cancel - ?
     * @return {*}
     */
    $scope.upgrade = function (plan, cancel) {
      console.log('upgrade:', plan, cancel);
      if (!plan.upgrade) {
        plan.upgrade = true;
        return $q.reject(new Error('Confirm'));
      } else if (cancel) {
        plan.upgrade = false;
        return $q.reject(new Error('Cancel'));
      } else {
        $scope.loadingAccountData = true;
        return btSubscriptionService
          .upgrade(subscription.id, plan.id, '')
          .then(function (result) {
            console.log('Subscription upgrade result: ', result);
            $scope.loadingAccountData = false;
            plan.upgrade = false;
            return {};
          })
          .catch(function (err) {
            console.log('Subscription upgrade error: ', err);
            $scope.loadingAccountData = false;
            plan.upgrade = false;
          })
          .then(function () {
            $scope.finished = true;
          })
          .then(function () {
            if (btRestrictionService.hasFeature('personalization')) {
              return btShareScopeService.increaseVisitPage('tradierunl');
            }
          });
      }
    };
  }
})();
