/**
 * Created by Jonas on 8/21/2019.
 */

(function btBasketInsightsClosure() {
  'use strict';

  /**
   * This directive displays risk insights.
   *
   * @ngdoc directive
   * @name btBasketInsights
   * @module ecapp
   * @restrict E
   * @scope
   */

  /**
   * This controller process notification center.
   *
   * @ngdoc controller
   * @name btBasketInsightsController
   * @module ecapp
   */

  /** Directive */
  angular
    .module('ecapp')
    .directive('btBasketInsights', angularDirective)
    .controller('btBasketInsightsController', angularController);

  angularDirective.$inject = ['$templateCache'];

  /**
   * Implementation of btBasketInsights directive
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function angularDirective($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('directives/risks/bt-basket-insights.html'),
      controller: 'btBasketInsightsController',
    };
  }

  angularController.$inject = ['$scope', 'btRiskService'];

  /**
   * Implementation of btBasketInsights controller.
   *
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.IRiskService} btRiskService
   */
  function angularController($scope, btRiskService) {
    $scope.insights = btRiskService.getSelectedInsights();

    $scope.$on('$destroy', onDestroy);

    activate();

    /**
     * Controller constructor
     */
    function activate() {}

    /**
     * On destroy
     */
    function onDestroy() {}
  }
})();
