/**
 * Created by Itay on 6/26/2016.
 */

(function btHistoricGraphTableItemClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive display item of historical data table.
     *
     * @ngdoc directive
     * @name btHistoricGraphTableItem
     * @memberOf ecapp
     */
    .directive('btHistoricGraphTableItem', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          scope: {
            row: '=',
          },
          controller: [
            '$scope',
            function ($scope) {
              var event = $scope.$parent.rowModalData.eventsInfo;

              //event event type is speech or report
              $scope.showReportSpeechLinks = event.eventType === 2 || event.eventType === 1;

              //if showReportSpeechLinks is true, we need to decide about the title.
              if ($scope.showReportSpeechLinks) $scope.reportSpeechTitle = event.eventType === 2 ? 'Report' : 'Speech';
            },
          ],
          template: $templateCache.get('directives/common/historic-graph/table-item.html'),
        };
      },
    ]);
})();
