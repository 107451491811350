/**
 * Created by Eyal on 5/27/2016.
 */

// @ts-check
(function btCurrenciesServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btCurrenciesService', btCurrenciesService);

  btCurrenciesService.$inject = ['Currencies', '$q', 'CacheFactory', 'btErrorService'];

  /**
   * @ngdoc service
   * @name btCurrenciesService
   * @memberOf ecapp
   * @description
   *  This factory work with currencies.
   * @param {ecapp.IGeneralLoopbackService} lbCurrencies
   * @param {angular.IQService} $q
   * @param {angular.ICacheFactoryService} CacheFactory
   * @param {ecapp.IErrorService} btErrorService
   * @return {ecapp.ICurrenciesService}
   */
  function btCurrenciesService(lbCurrencies, $q, CacheFactory, btErrorService) {
    console.log('Running btCurrenciesService');

    var gAllCachedCurrenciesFlags = null;
    var gCurrenciesList = null;
    var gCurrenciesCache;

    var gCurrenciesOrder = ['bt_priority DESC', '_id ASC'];
    // Currencies list contain currency data: ["id", "name", "symbol", "name_plural", "decimal_digits", "country", "rounding", "symbol_native", "bt_priority", "currenciesDirections"]
    // Currencies directions is  the object like {"id": "USD", "USDCHF": "1", "GBPAUD": "1"}

    /**
     * Returns list of all currencies flags (cached them before)
     *
     * @ngdoc method
     * @name getAllCachedCurrenciesFlags
     * @memberOf ecapp.btCurrenciesService
     * @return {angular.IPromise<*>}
     */
    function getAllCachedCurrenciesFlags() {
      var deferred = $q.defer();

      if (gAllCachedCurrenciesFlags === null) {
        var localCache = gCurrenciesCache.get('flags');
        if (localCache === undefined) {
          // first call - loads all the currencies from the DB
          console.log('Currencies being called from the DB');
          lbCurrencies
            .find({ filter: { order: gCurrenciesOrder, fields: { flag: true, id: true } } })
            .$promise.then(function (data) {
              gAllCachedCurrenciesFlags = {};

              for (var i = 0; i < data.length; i++) gAllCachedCurrenciesFlags[data[i].id] = data[i].flag;

              gCurrenciesCache.put('flags', gAllCachedCurrenciesFlags);

              deferred.resolve({ allCachedCurrenciesFlags: gAllCachedCurrenciesFlags });
            })
            .catch(btErrorService.handleHTTPError)
            .catch(function (reason) {
              console.error('btCurrenciesService:', reason);
              deferred.reject(reason);
            });
        } else {
          gAllCachedCurrenciesFlags = localCache;
          deferred.resolve({ allCachedCurrenciesFlags: gAllCachedCurrenciesFlags });
        }
      } else {
        console.log('Currencies being called from the Cache');
        deferred.resolve({ allCachedCurrenciesFlags: gAllCachedCurrenciesFlags });
      }
      return deferred.promise;
    }

    /**
     * Returns list of currencies information (cached them before)
     *
     * @ngdoc method
     * @name getCurrenciesList
     * @memberOf ecapp.btCurrenciesService
     * @return {angular.IPromise<{currenciesList: Array}>}
     */
    function getCurrenciesList() {
      var deferred = $q.defer();

      if (gCurrenciesList === null) {
        var localCache = gCurrenciesCache.get('list');
        if (localCache === undefined) {
          // first call - loads all the currencies from the DB
          console.log('Currencies being called from the DB');

          var currenciesFields = {
            id: true,
            name: true,
            symbol: true,
            name_plural: true,
            decimal_digits: true,
            country: true,
            rounding: true,
            symbol_native: true,
            bt_priority: true,
          };

          var query = {
            filter: { order: gCurrenciesOrder, fields: currenciesFields, include: 'currenciesDirections' },
          };

          lbCurrencies
            .find(query)
            .$promise.then(function (data) {
              gCurrenciesList = data;

              gCurrenciesCache.put('list', data);

              deferred.resolve({ currenciesList: data });
            })
            .catch(function (reason) {
              console.log('btCurrenciesService: error', reason);

              deferred.reject(reason);
            });
        } else {
          gCurrenciesList = localCache;
          deferred.resolve({ currenciesList: gCurrenciesList });
        }
      } else {
        console.log('Currencies being called from the Cache');
        deferred.resolve({ currenciesList: gCurrenciesList });
      }
      return deferred.promise;
    }

    /**
     * Cache flag and info to use directly
     *
     * @ngdoc method
     * @name init
     * @memberOf ecapp.btCurrenciesService
     * @return {angular.IPromise<*>}
     */
    function init() {
      return getCurrenciesList()
        .then(function () {
          getAllCachedCurrenciesFlags()
            .then(function () {
              console.log('btCurrenciesService: success');
            })
            .catch(function (reason) {
              console.log('btCurrenciesService: error', reason);
            });
        })
        .catch(function (reason) {
          console.log('btCurrenciesService: error', reason);
        });
    }

    /**
     * Directly return currency flag
     *
     * @ngdoc method
     * @name getCurrencyFlag
     * @memberOf ecapp.btCurrenciesService
     * @param {*} currencyName
     * @return {Object}
     */
    function getCurrencyFlag(currencyName) {
      console.log('CurrencyName', currencyName);
      return gAllCachedCurrenciesFlags[currencyName];
    }

    /**
     * Directly return currency direction
     *
     * @ngdoc method
     * @name getCurrencyDirection
     * @memberOf ecapp.btCurrenciesService
     * @param {*} instrumentName
     * @param {*} currencyName
     * @return {Number}
     */
    function getCurrencyDirection(instrumentName, currencyName) {
      var currencyDirection = 1;

      var currency = gCurrenciesList.filter(function (row) {
        return row['id'] === currencyName;
      })[0];

      if (currency && currency['currenciesDirections']) {
        console.log(currency['currenciesDirections']); // TODO: Delete
        currencyDirection = parseInt(currency['currenciesDirections'][instrumentName]) || 0;
        console.log('Currency direction: ' + currencyDirection);
      } else {
        console.log('Default currency direction!');
      }

      return currencyDirection;
    }

    /**
     * Simple function just to change format of array of currencies from currencyArray to userCurrencies
     *
     * @ngdoc method
     * @name prepareUserCurrencies
     * @memberOf ecapp.btCurrenciesService
     * @param {*} currencyArray
     * @return {Array}
     */
    function prepareUserCurrencies(currencyArray) {
      var userCurrencies = [];
      currencyArray.forEach(function (v) {
        userCurrencies.push({ name: v, isChecked: true });
      });

      return userCurrencies;
    }

    /**
     * Simple function just to change format of array of currencies from userCurrencies to currencyArray
     *
     * @ngdoc method
     * @name prepareCurrencyArray
     * @memberOf ecapp.btCurrenciesService
     * @param {*} userCurrencies
     * @return {Array}
     */
    function prepareCurrencyArray(userCurrencies) {
      var currencyArray = [];
      userCurrencies.forEach(function (v) {
        if (v.isChecked) currencyArray.push(v.name);
      });

      return currencyArray;
    }

    /**
     *
     */
    function setupCache() {
      // TODO: Remove code duplication
      // cache currencies information: flag and list

      /**
       *
       * @param {string} key
       * @return {any}
       */
      function freshCache(key) {
        if (key === 'flags') {
          return getAllCachedCurrenciesFlags();
        } else if (key === 'list') {
          return getCurrenciesList();
        }
      }

      if (!CacheFactory.get('currencies')) {
        gCurrenciesCache = CacheFactory('currencies', {
          storageMode: 'localStorage',
          maxAge: 1000 * 60 * 60 * 24,
          deleteOnExpire: 'aggressive',
          storagePrefix: 'bt.caches.',
          onExpire: function (key, value) {
            freshCache(key)
              .then(function () {
                console.log('Cache: currencies.' + key + ' was refreshed!');
              })
              .catch(function () {
                console.error('Cache: currencies.' + key + ' refresh failed!');
                gCurrenciesCache.put(key, value);
              });
          },
        });
      } else {
        gCurrenciesCache = CacheFactory.get('currencies');
      }
    }

    setupCache();

    // noinspection JSUnusedGlobalSymbols
    return {
      /*
       return is this structure:
       {flag_name: base64flag, ...}
       */
      init: init,
      setupCache: setupCache,

      getCurrencyDirection: getCurrencyDirection,
      getCurrencyFlag: getCurrencyFlag,

      getCurrenciesList: getCurrenciesList,
      getAllCachedCurrenciesFlags: getAllCachedCurrenciesFlags,

      prepareUserCurrencies: prepareUserCurrencies,
      prepareCurrencyArray: prepareCurrencyArray,
    };
  }
})();
