/**
 * Created by Sergey Panpurin on 11/11/2018.
 */

// @ts-check
(function btOptimusServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btOptimusService', btOptimusService);

  btOptimusService.$inject = ['btOptimusAuthService'];

  /**
   * This service collect logic connected to Optimus integration.
   *
   * @ngdoc service
   * @name btOptimusService
   * @memberOf ecapp
   * @param {ecapp.IOptimusAuthService} btOptimusAuthService
   * @return {ecapp.IOptimusService}
   */
  function btOptimusService(btOptimusAuthService) {
    console.log('Running btOptimusService');

    return {
      auth: btOptimusAuthService,
    };
  }
})();
