/**
 * Created by yatree on 30/09/16.
 */

(function btNotificationSettingsCtrlClosure() {
  'use strict';

  angular.module('ecapp').controller('btNotificationSettingsController', btNotificationSettingsController);

  btNotificationSettingsController.$inject = [
    'btCurrenciesService',
    '$scope',
    'btShareScopeService',
    '$timeout',
    '$analytics',
    'btSubscriptionService',
    'btSettingsService',
    'btRestrictionService',
    'btToastrService',
    'btEventsService',
    'btPushNotificationService',
    'btBigBrainBankService',
    '$rootScope',
  ];

  /**
   * This is notification & settings page controller.
   *
   * @ngdoc controller
   * @name btNotificationSettingsController
   * @memberOf ecapp
   * @param {ecapp.ICurrenciesService} btCurrenciesService - factory for working with currencies
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {angular.ITimeoutService} $timeout - ?
   * @param {ext.IAnalyticsService} $analytics - ?
   * @param {ecapp.ISubscriptionService} btSubscriptionService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IEventsService} btEventsService
   * @param {ecapp.IPushNotificationService} btPushNotificationService
   * @param {ecapp.IBigBrainBankService} btBigBrainBankService
   * @param {ecapp.ICustomRootScope} $rootScope
   */
  function btNotificationSettingsController(
    btCurrenciesService,
    $scope,
    btShareScopeService,
    $timeout,
    $analytics,
    btSubscriptionService,
    btSettingsService,
    btRestrictionService,
    btToastrService,
    btEventsService,
    btPushNotificationService,
    btBigBrainBankService,
    $rootScope
  ) {
    console.log('Running btNotificationSettingsController');

    $scope.manageMailSubscription = manageMailSubscription;
    $scope.changeNotificationRules = changeNotificationRules;
    $scope.changeNotificationSettings = changeNotificationSettings;
    // $scope.setEventsOrder = setEventsOrder;
    // $scope.followEvent = followEvent;
    $scope.updatePremium = updatePremium;
    $scope.onReleaseUpgrade = onReleaseUpgrade;
    $scope.onInsightsUpgrade = onInsightsUpgrade;
    $scope.onTradeIdeasUpgrade = onTradeIdeasUpgrade;
    $scope.onLevelsUpgrade = onLevelsUpgrade;

    $scope.exchanges = btSettingsService.getStockExchanges();

    $scope.accountInfo = null;

    $scope.hasPremiumNotifications = btRestrictionService.hasFeature('premium-notifications') || !$rootScope.hasUpgrade;
    $scope.hasNewsDrivenTradeIdeas = btRestrictionService.hasFeature('trade-ideas') || !$rootScope.hasUpgrade;
    $scope.hasPotentialNewsDrivenTradeIdeas = btRestrictionService.hasFeature('trade-ideas') || !$rootScope.hasUpgrade;
    $scope.hasPriceDrivenTradeIdeas = btRestrictionService.hasFeature('trade-ideas') || !$rootScope.hasUpgrade;
    $scope.hasReleaseMagnitude = btRestrictionService.hasFeature('release-magnitude') || !$rootScope.hasUpgrade;
    $scope.hasPerspectiveInsights = btRestrictionService.hasFeature('release-insights') || !$rootScope.hasUpgrade;
    $scope.hasLevels = btRestrictionService.hasFeature('levels') || !$rootScope.hasUpgrade;

    $scope.hasTradeIdeasUpgrade =
      !$scope.hasNewsDrivenTradeIdeas || !$scope.hasPotentialNewsDrivenTradeIdeas || !$scope.hasPriceDrivenTradeIdeas;

    $scope.hasMacroNewsUpgrade = !$scope.hasReleaseMagnitude || !$scope.hasPerspectiveInsights;

    // $scope.eventsIdArr = [];
    // $scope.currencyFlags = [];

    // $scope.eventsOrderOptions = [
    //   {
    //     label: 'Name',
    //     field: 'name',
    //     reverse: false
    //   },
    //   {
    //     label: 'Currency',
    //     field: 'currency',
    //     reverse: false
    //   }
    // ];
    // $scope.orderEventsBy = $scope.eventsOrderOptions[1];

    // $scope.numEvents = 0;
    // $scope.maxEvents = 0;

    $scope.dailyIcon = undefined;
    $scope.weeklyIcon = undefined;
    $scope.marketSenseIcon = undefined;
    $scope.marketWakeUpIcon = undefined;
    $scope.hasTrading = btSettingsService.hasFeature('trading');
    $scope.notificationStatus = 'Loading...';
    $scope.prefix = btSettingsService.domain === 'bigbrainbank' ? 'TheBrain will' : "We'll";

    activate();

    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('$ionicView.enter', onViewEnter);
    // $scope.$on('calendar:follow-event', onFollowEvent);
    $scope.$on('subscription:updated', onSubscriptionUpgradeSuccess);

    /**
     * This function reacts on subscription update.
     */
    function onSubscriptionUpgradeSuccess() {
      $scope.hasPremiumNotifications = btRestrictionService.hasFeature('premium-notifications');
      $scope.hasNewsDrivenTradeIdeas = btRestrictionService.hasFeature('trade-ideas');
      $scope.hasPotentialNewsDrivenTradeIdeas = btRestrictionService.hasFeature('trade-ideas');
      $scope.hasPriceDrivenTradeIdeas = btRestrictionService.hasFeature('trade-ideas');
      $scope.hasReleaseMagnitude = btRestrictionService.hasFeature('release-magnitude');
      $scope.hasPerspectiveInsights = btRestrictionService.hasFeature('release-insights');

      $scope.hasTradeIdeasUpgrade =
        !$scope.hasNewsDrivenTradeIdeas || !$scope.hasPotentialNewsDrivenTradeIdeas || !$scope.hasPriceDrivenTradeIdeas;

      $scope.hasMacroNewsUpgrade = !$scope.hasReleaseMagnitude || !$scope.hasPerspectiveInsights;
    }

    // function onFollowEvent(event, eventId, isSelected) {
    //   if (isSelected) {
    //     $scope.numEvents = $scope.numEvents + 1;
    //   } else {
    //     $scope.numEvents = $scope.numEvents - 1;
    //   }
    // }

    /**
     *
     */
    function activate() {
      btShareScopeService
        .getAccountSettingsPromise()
        .then(btSubscriptionService.loadCombinedSubscription)
        .then(initializeMailSubscription)
        .then(function () {
          $scope.accountInfo = btShareScopeService.getAccountSettings();

          $scope.notifications = btShareScopeService.getNotificationSettings();

          // $scope.eventsIdArr = [];
          // btEventsService.getAllEvents().then(function (events) {
          //   var followed = btShareScopeService.getListFollowedEvents();
          //   events.forEach(function (event) {
          //     if (followed.indexOf(event.id) !== -1) $scope.eventsIdArr.push(event);
          //   });
          // });
          // $scope.numEvents = btShareScopeService.getListFollowedEvents().length;
          // $scope.maxEvents = btRestrictionService.getCapacity('followed-events');

          btPushNotificationService.getSubscriptionState().then(function (value) {
            $scope.notificationStatus = btPushNotificationService.getNotificationStatus(
              btShareScopeService.accountInfo,
              value
            );
          });

          if (resetSettings($scope.accountInfo.notificationRules)) {
            return btShareScopeService.updateProfileSettingsFromFieldsList(['notificationRules']);
          }
        });

      // btCurrenciesService.getAllCachedCurrenciesFlags()
      //   .then(function (data) {
      //     $scope.currencyFlags = data.allCachedCurrenciesFlags;
      //   })
      //   .catch(function (reason) {
      //     console.log('btNotificationSettingsController: error', reason);
      //   });

      // // Set by default the order on the currency field
      // $scope.orderEventsBy = $scope.eventsOrderOptions[1];

      if (!btRestrictionService.hasFeature('personalization')) {
        $scope.dailyIcon = 'ion-locked';
        $scope.weeklyIcon = 'ion-locked';
      } else {
        if (btRestrictionService.hasFeature('weekly-mails')) {
          $scope.weeklyIcon = undefined;
        } else {
          $scope.weeklyIcon = 'ion-locked';
        }
      }

      if (!btRestrictionService.hasFeature('personalization')) {
        $scope.marketSenseIcon = 'ion-locked';
        $scope.marketWakeUpIcon = 'ion-locked';
      } else {
        $scope.marketSenseIcon = undefined;
        $scope.marketWakeUpIcon = undefined;
      }
    }

    /**
     *
     */
    function onViewLoaded() {
      $timeout(function () {
        $('#bt-notifications-events-content').focus();
      }, 1000);
    }

    /**
     *
     */
    function onViewEnter() {
      $timeout(function () {
        $('#bt-notifications-events-content').focus();
      }, 1000);
    }

    /**
     *
     */
    function updatePremium() {
      btRestrictionService.showUpgradePopup('premium-notifications');
    }

    // /**
    //  * Sort list of events by time or currency.
    //  *
    //  * @ngdoc method
    //  * @name setEventsOrder
    //  * @memberOf ecapp.btNotificationSettingsController
    //  */
    // function setEventsOrder(option) {
    //   $scope.orderEventsBy = $scope.eventsOrderOptions.filter(function (value) {
    //     return value.field === option.field;
    //   })[0];
    // }

    // /**
    //  * Emulate click on the follow event button directive.
    //  *
    //  * @ngdoc method
    //  * @name followEvent
    //  * @memberOf ecapp.btNotificationSettingsController
    //  *
    //  * @param {*} event
    //  */
    // function followEvent(event) {
    //   $scope.$broadcast('tutorial:toggle-event', event.id);
    // }

    /**
     *
     * @return {any}
     */
    function initializeMailSubscription() {
      if (btSettingsService.getDomain() === 'bigbrainbank') {
        return initializeBigBrainBankMailSubscription().then(function (value) {
          $scope.mailchimp = value;
        });
      } else {
        return initializeBetterTraderMailSubscription().then(function (value) {
          $scope.mailchimp = value;
        });
      }
    }

    /**
     *
     * @return {any}
     */
    function initializeBetterTraderMailSubscription() {
      if (!btRestrictionService.hasFeature('personalization')) {
        return btShareScopeService.getLockedMailSubscription();
      } else {
        return btShareScopeService.initializeMailSubscription();
      }
    }

    /**
     *
     * @return {any}
     */
    function initializeBigBrainBankMailSubscription() {
      return btBigBrainBankService.mailing
        .initialize(btShareScopeService.accountInfo.email)
        .then(function (isSubscribed) {
          return {
            status: isSubscribed ? 'subscribed' : 'unsubscribed',
            subscribed: isSubscribed,
            blocked: false,
            icon: undefined,
          };
        })
        .catch(function (reason) {
          console.log(reason);

          return {
            status: 'unsubscribed',
            subscribed: false,
            blocked: true,
            icon: 'ion-locked',
          };
        });
    }

    /**
     * Manage mail subscription
     *
     * @param {String} name - toggle name
     * @param {Boolean} position - toggle position
     * @return {any}
     */
    function manageMailSubscription(name, position) {
      if (btSettingsService.getDomain() === 'bigbrainbank') {
        return manageBigBrainBankMailSubscription(name, position);
      } else {
        return manageBetterTraderMailSubscription(name, position);
      }
    }

    /**
     * Manage mail subscription
     *
     * @param {String} name - toggle name
     * @param {Boolean} position - toggle position
     * @return {any}
     */
    function manageBetterTraderMailSubscription(name, position) {
      if (!btRestrictionService.hasFeature('personalization')) {
        btRestrictionService.showUpgradePopup('personalization');
        return false;
      }

      if ($scope.mailchimp.blocked) {
        btToastrService.error(
          'Something went wrong with your email preferences, please try to reload the page. ' +
            "If you're still having an issue, please chat with us.",
          'Email preferences',
          { type: 'settings' }
        );
        return false;
      }

      if (position === true) {
        return btShareScopeService
          .startMailSubscription()
          .then(function () {
            return true;
          })
          .catch(function (reason) {
            console.error(reason);
            btToastrService.error(
              'Something went wrong with your email preferences, please try to reload the page. ' +
                "If you're still having an issue, please chat with us.",
              'Email preferences',
              { type: 'settings' }
            );
            return false;
          });
      } else {
        return btShareScopeService
          .stopMailSubscription()
          .then(function () {
            return true;
          })
          .catch(function (reason) {
            console.error(reason);
            btToastrService.error(
              'Something went wrong with your email preferences, please try to reload the page. ' +
                "If you're still having an issue, please chat with us.",
              'Email preferences',
              { type: 'settings' }
            );
            return false;
          });
      }
    }

    /**
     * Manage mail subscription
     *
     * @param {String} name - toggle name
     * @param {Boolean} position - toggle position
     * @return {any}
     */
    function manageBigBrainBankMailSubscription(name, position) {
      // if (!btRestrictionService.hasFeature('personalization')) {
      //   btRestrictionService.showUpgradePopup('personalization');
      //   return false;
      // }
      //
      // if ($scope.mailchimp.blocked) {
      //   btToastrService.error('Something went wrong with your email preferences, please try to reload the page. ' +
      //     'If you\'re still having an issue, please chat with us.', 'Email preferences', {type: 'settings'});
      //   return false;
      // }

      if (position === true) {
        return (
          btBigBrainBankService.mailing
            .subscribe(btShareScopeService.accountInfo.email, { name: btShareScopeService.accountInfo.firstName })
            // return btShareScopeService.startMailSubscription()
            .then(function () {
              return true;
            })
            .catch(function (reason) {
              console.error(reason);
              btToastrService.error(
                'Something went wrong with your email preferences, please try to reload the page. ' +
                  "If you're still having an issue, please chat with us.",
                'Email preferences',
                { type: 'settings' }
              );
              return false;
            })
        );
      } else {
        return (
          btBigBrainBankService.mailing
            .unsubscribe(btShareScopeService.accountInfo.email)
            // return btShareScopeService.stopMailSubscription()
            .then(function () {
              return true;
            })
            .catch(function (reason) {
              console.error(reason);
              btToastrService.error(
                'Something went wrong with your email preferences, please try to reload the page. ' +
                  "If you're still having an issue, please chat with us.",
                'Email preferences',
                { type: 'settings' }
              );
              return false;
            })
        );
      }
    }

    /**
     * Send to mongoDB state of notifications rules and full-time rule (in accounts settings).
     *
     * @ngdoc method
     * @name changeNotificationRules
     * @memberOf ecapp.btNotificationSettingsController
     * @param {*} name
     * @param {*} position
     * @return {boolean}
     */
    function changeNotificationRules(name, position) {
      void position;

      if (btSettingsService.isMailSettings(name) && !hasMailPermission(name)) {
        $scope.accountInfo.notificationRules[name] = false;
        return false;
      }

      $scope.accountInfo.notificationRules[name] = position;

      btShareScopeService
        .updateProfileSettingsFromFieldsList(['notificationRules'])
        .then(trackChange.bind(null, name))
        .catch(function (reason) {
          console.error('btNotificationSettingsController: error', reason);
        });

      return true;
    }

    /**
     *
     * @param {*} name
     * @param {*} position
     * @return {any}
     */
    function changeNotificationSettings(name, position) {
      return btShareScopeService
        .updateNotificationSettings(name, position)
        .then(function () {
          return true;
        })
        .catch(function (reason) {
          console.log(reason);
          return false;
        });
    }

    /**
     *
     * @param {*} name
     * @return {boolean}
     */
    function hasMailPermission(name) {
      if (!btRestrictionService.hasFeature('personalization')) {
        if (!$rootScope.hasUpgrade) {
          alert('This feature is not included in your subscription!');
        } else {
          btRestrictionService.showUpgradePopup('personalization');
        }
        return false;
      } else {
        if (btSettingsService.isDailyMail(name)) {
          //skip
        }

        if (btSettingsService.isWeeklyMail(name) && !btRestrictionService.hasFeature('weekly-mails')) {
          if (!$rootScope.hasUpgrade) {
            alert('This feature is not included in your subscription!');
          } else {
            btRestrictionService.showUpgradePopup('weekly-mails');
          }
          return false;
        }
      }
      return true;
    }

    /**
     *
     * @param {*} name
     * @return {any}
     */
    // eslint-disable-next-line no-unused-vars
    function hasNotificationPermission(name) {
      if (!btRestrictionService.hasFeature('personalization')) {
        btRestrictionService.showUpgradePopup('personalization');
        return false;
      } else {
        if (
          btSettingsService.isPremiumNotification(name) &&
          !btRestrictionService.hasFeature('premium-notifications')
        ) {
          btRestrictionService.showUpgradePopup('premium-notifications');
          return false;
        }
      }
      return true;
    }

    /**
     *
     * @param {*} notifications
     * @return {any}
     */
    function resetSettings(notifications) {
      var isUpdateNeeded = false;
      if (
        !btRestrictionService.hasFeature('personalization') &&
        (notifications.weekStartMail ||
          notifications.weekEndMail ||
          notifications.morningMail ||
          notifications.eveningMail)
      ) {
        notifications.weekStartMail = false;
        notifications.weekEndMail = false;
        notifications.morningMail = false;
        notifications.eveningMail = false;
        isUpdateNeeded = true;
      }

      if (
        !btRestrictionService.hasFeature('weeklyMails') &&
        (notifications.weekStartMail || notifications.weekEndMail)
      ) {
        notifications.weekStartMail = false;
        notifications.weekEndMail = false;
        isUpdateNeeded = true;
      }

      // if (!btRestrictionService.hasNewsDrivenTradeIdeas() && notifications.tradingInsights) {
      //   notifications.tradingInsights = false;
      //   isUpdateNeeded = true;
      // }

      // if (!btRestrictionService.hasPotentialNewsDrivenTradeIdeas() && notifications.expectedTradingInsights) {
      //   notifications.expectedTradingInsights = false;
      //   isUpdateNeeded = true;
      // }

      // if (!btRestrictionService.hasPerspectiveInsights() && notifications.insights) {
      //   notifications.insights = false;
      //   isUpdateNeeded = true;
      // }

      return isUpdateNeeded;
    }

    /**
     *
     * @param {*} name
     */
    function trackChange(name) {
      void name;

      if (window.btSettings.BT_DOMAIN === 'bettertrader') {
        $analytics.eventTrack('change', { category: 'settings', label: 'notification' });
      }
    }

    /**
     *
     */
    function onReleaseUpgrade() {
      btRestrictionService.showUpgradePopup('release-magnitude');
    }

    /**
     *
     */
    function onInsightsUpgrade() {
      btRestrictionService.showUpgradePopup('perspective-insights');
    }

    /**
     *
     */
    function onTradeIdeasUpgrade() {
      btRestrictionService.showUpgradePopup('trade-ideas');
    }

    /**
     *
     */
    function onLevelsUpgrade() {
      btRestrictionService.showUpgradePopup('levels');
    }
  }
})();
