/**
 * Created by Sergey Panpurin on 3/23/20.
 */

// @ts-check
(function btSocketServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btSocketService:';

  angular.module('ecapp').factory('btSocketService', service);

  service.$inject = ['$q', 'btEventEmitterService'];

  /**
   * This service implements connection to socket.io.
   *
   * @ngdoc service
   * @name ecapp.btSocketService
   * @param {angular.IQService} $q
   * @param {ecapp.IEventEmitterService} btEventEmitterService
   * @return {ecapp.ISocketService}
   */
  function service($q, btEventEmitterService) {
    if (gDebug) console.log(gPrefix, 'running...');

    var gSocket = {};

    btEventEmitterService.addListener('login:success', onLoginSuccess);
    btEventEmitterService.addListener('logout:success', onLogoutSuccess);

    return {
      connect: connect,
    };

    /**
     *
     */
    function onLoginSuccess() {}

    /**
     *
     */
    function onLogoutSuccess() {
      Object.keys(gSocket).forEach(function (key) {
        gSocket[key].disconnect();
      });

      gSocket = {};
    }

    /**
     * This function returns socket object.
     *
     * @name ecapp.btSocketService#connect
     * @param {string} namespace - name of namespace
     * @return {angular.IPromise<typeof io.Socket>}
     */
    function connect(namespace) {
      if (window.btSettings.BT_SOCKET_IO_URL && window.btSettings.BT_SOCKET_IO_URL !== 'NA') {
        if (!gSocket[namespace]) {
          var socket = io(window.btSettings.BT_SOCKET_IO_URL + (namespace || ''));

          gSocket[namespace] = socket;

          socket.on('error', function (err) {
            console.error('Socket.IO error:', err);
          });

          if (gDebug) {
            socket.on('connect', function (data) {
              console.log('Socket.IO was connected. Data:', data);
            });

            socket.on('disconnect', function (data) {
              console.log('Socket.IO was disconnected. Data:', data);
            });
          }
        }
        return $q.resolve(gSocket[namespace]);
      } else {
        return $q.reject(new Error('Socket.IO server is not defined.'));
      }
    }
  }
})();
