/**
 * Created by XXX on XXX.
 */

/**
 * BetterTrader Instrument Object
 * @typedef {Object} BTStrength
 * @property {String} status      - strength status: 'Buy', 'Sell', 'AsExpected' or 'nothingStatus'
 * @property {String} msg         - message: 'Stronger', 'Weaker' or 'As Exp'
 * @property {String} class       - css class: 'positive', 'negative' or 'neutral'
 * @property {String} fullMsg     - full message: 'A bit Weaker', 'Extremely Stronger' or 'As Expected'
 * @property {String} html        - full message html: 'A bit Weaker', 'Extremely Stronger' or 'As Expected'
 * @property {String} brHtml      - full message html: 'A bit<br> Weaker', 'Extremely<br> Stronger' or 'As<br> Expected'
 * @property {String} value       - value in percent
 * @property {Boolean} locked     - locked
 */

// @ts-check
(function btStrengthServiceClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * @ngdoc service
     * @name btStrengthService
     * @memberOf ecapp
     * @description
     *  This service manipulates the strength data from the server and returns it in percentage base and with "status" field.
     *
     *  Note: Try to remove btRestrictionService. Use some flag in this service ans manage it from btRestrictionService.
     */
    .factory('btStrengthService', btStrengthService);

  btStrengthService.$inject = ['$sce', 'btDateService', 'btRestrictionService'];

  /**
   *
   * @param {angular.ISCEService} $sce
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @return {ecapp.IStrengthService}
   */
  function btStrengthService($sce, btDateService, btRestrictionService) {
    console.log('Running btStrengthService');

    /**
     * @typedef {Object} btReleaseMagnitudeOption
     * @property {String} id - identifier
     * @property {String} label - display name
     * @property {Number} min - magnitude range minimal value
     * @property {Number} max - magnitude range maximal value
     */

    // @formatter:off
    var gMagnitudeOptions = [
      { id: 'any', label: 'Any', min: -10.0, max: +10.0, case: 'back-any' },

      { id: 'all-stronger', label: 'All Stronger', min: +0.0, max: +10.0, case: 'back-pos' },
      { id: 'extremely-stronger', label: 'Extremely Stronger', min: +1.0, max: +10.0, case: 'extr-pos' },
      { id: 'much-stronger', label: 'Much Stronger', min: +0.6, max: +1.0, case: 'much-pos' },
      { id: 'stronger', label: 'Stronger', min: +0.2, max: +0.6, case: 'just-pos' },
      { id: 'a-bit-stronger', label: 'A bit Stronger', min: +0.0, max: +0.2, case: 'abit-pos' },

      { id: 'as-expected', label: 'As Expected', min: -0.0, max: +0.0, case: 'asexpect' },

      { id: 'a-bit-weaker', label: 'A bit Weaker', min: -0.2, max: -0.0, case: 'abit-neg' },
      { id: 'weaker', label: 'Weaker', min: -0.6, max: -0.2, case: 'just-neg' },
      { id: 'much-weaker', label: 'Much Weaker', min: -1.0, max: -0.6, case: 'much-neg' },
      { id: 'extremely-weaker', label: 'Extremely Weaker', min: -10.0, max: -1.0, case: 'extr-neg' },
      { id: 'all-weaker', label: 'All Weaker', min: -10.0, max: -0.0, case: 'back-neg' },
    ];
    // @formatter:on

    return {
      isLowMagnitude: isLowMagnitude,
      passMagnitudeFilter: passMagnitudeFilter,
      getStrengthMagnitudeId: getStrengthMagnitudeId,
      getReleaseMagnitudeId: getReleaseMagnitudeId,
      getMagnitudeOptions: getMagnitudeOptions,
      getMagnitudeOptionById: getMagnitudeOptionById,
      getMagnitudeOptionByCase: getMagnitudeOptionByCase,
      getMagnitudeOptionByRange: getMagnitudeOptionByRange,
      prepareStrength: createStrengthObject,
      calculateRevisionStrength: calculateRevisionStrength,
      limits: getStrengthLimits,
      toText: strengthToText,
      limitsToText: limitsToText,
    };

    /**
     *
     * @param {string} magnitude
     * @return {boolean}
     */
    function isLowMagnitude(magnitude) {
      return ['a-bit-weaker', 'as-expected', 'a-bit-stronger'].indexOf(magnitude) !== -1;
    }

    /**
     *
     * @param {string} magnitude
     * @param {string} rule
     * @return {boolean}
     */
    function passMagnitudeFilter(magnitude, rule) {
      if (rule === 'any') {
        return true;
      }

      if (rule === 'all-stronger') {
        return magnitude.indexOf('stronger') >= 0;
      }

      if (rule === 'all-weaker') {
        return magnitude.indexOf('weaker') >= 0;
      }

      return magnitude === rule;
    }

    /**
     * Get release magnitude option
     * @param {?btRawRelease} release - release object
     * @return {String}
     */
    function getReleaseMagnitudeId(release) {
      if (release && release.releaseStrength) {
        return getStrengthMagnitudeId(release.releaseStrength.value);
      } else {
        return 'any';
      }
    }

    /**
     * This function returns strength magnitude option.
     *
     * @param {?number} strength - strength value
     * @return {string}
     */
    function getStrengthMagnitudeId(strength) {
      if (typeof strength === 'number') {
        if (strength === 0) return 'as-expected';
        if (strength > 0) {
          if (strength > 1) return 'extremely-stronger';
          if (strength > 0.6) return 'much-stronger';
          if (strength > 0.2) return 'stronger';
          return 'a-bit-stronger';
        }
        if (strength < 0) {
          if (strength < -1) return 'extremely-weaker';
          if (strength < -0.6) return 'much-weaker';
          if (strength < -0.2) return 'weaker';
          return 'a-bit-weaker';
        }
      } else {
        return 'any';
      }
    }

    /**
     * This function returns strength power.
     *
     * @param {?number} strength - strength value
     * @return {string}
     */
    function getStrengthPower(strength) {
      if (typeof strength === 'number') {
        if (strength === 0) return 'zero';
        if (strength > 0) {
          if (strength > 1) return 'extra';
          if (strength > 0.6) return 'large';
          if (strength > 0.2) return 'medium';
          return 'small';
        }
        if (strength < 0) {
          if (strength < -1) return 'extra';
          if (strength < -0.6) return 'large';
          if (strength < -0.2) return 'medium';
          return 'small';
        }
      } else {
        return 'none';
      }
    }

    /**
     * Get release magnitude options
     *
     * @return {btReleaseMagnitudeOption[]}
     */
    function getMagnitudeOptions() {
      return gMagnitudeOptions;
    }

    /**
     * Get release magnitude option by identifier
     *
     * @param {String} magnitudeId - identifier of release magnitude option
     * @return {?btReleaseMagnitudeOption}
     */
    function getMagnitudeOptionById(magnitudeId) {
      var magnitude = gMagnitudeOptions.filter(function (magnitude) {
        return magnitude.id === magnitudeId;
      })[0];

      return magnitude ? magnitude : null;
    }

    /**
     * Get release magnitude option by identifier
     *
     * @param {String} caseId - identifier of release magnitude option
     * @return {?btReleaseMagnitudeOption}
     */
    function getMagnitudeOptionByCase(caseId) {
      var magnitude = gMagnitudeOptions.filter(function (magnitude) {
        return magnitude.case === caseId;
      })[0];

      return magnitude ? magnitude : null;
    }

    /**
     * Get release magnitude option by identifier
     *
     * @param {Number[]} range - magnitude range [min, max]
     * @return {?btReleaseMagnitudeOption}
     */
    function getMagnitudeOptionByRange(range) {
      console.log('getMagnitudeOptionByRange:', range);

      if (range[0] === null && range[1] === null) return gMagnitudeOptions[0];

      var magnitude = gMagnitudeOptions.filter(function (magnitude) {
        console.log(
          'getMagnitudeOptionByRange:',
          magnitude.id,
          magnitude.min,
          magnitude.max,
          Math.abs(magnitude.min - range[0]) < 0.01,
          Math.abs(magnitude.max - range[1]) < 0.01
        );
        return Math.abs(magnitude.min - range[0]) < 0.01 && Math.abs(magnitude.max - range[1]) < 0.01;
      })[0];

      return magnitude ? magnitude : null;
    }

    /**
     *
     * @param {Number} strength
     * @param {Number} timestamp
     * @class
     */
    function BTStrength(strength, timestamp) {
      var returnDict = getStrengthObject(strength, timestamp);

      /**
       *
       * @type {string|string|string|string}
       */
      this.status = returnDict.status;
      this.msg = returnDict.msg;
      this.class = returnDict.class;
      this.fullMsg = returnDict.fullMsg;
      this.html = returnDict.html;
      this.brHtml = returnDict.brHtml;
      this.value = returnDict.value;
      this.locked = returnDict.locked;
      this.magnitude = returnDict.magnitude;
      this.power = returnDict.power;
    }

    /**
     * Transform strength to text message. Catch up border 100 => Much.
     *
     * @param {Number} strength - number from -1 to 1 (or a bit more)
     * @param {Number} timestamp - release timestamp
     * @return {String} text version of strength
     */
    function strengthToText(strength, timestamp) {
      if (!btRestrictionService.hasFeature('release-magnitude')) {
        if (btDateService.getFreeDelay(btDateService.getDateFromSec(timestamp)) > 0) return '';
      }

      return convertStrength(strength);
    }

    /**
     * Transform strength to html. Catch up border 100 => Much.
     *
     * @param {Number} strength - number from -1 to 1 (or a bit more)
     * @param {Number} timestamp - release timestamp
     * @return {String} html version of strength
     */
    function strengthToHtml(strength, timestamp) {
      if (!btRestrictionService.hasFeature('release-magnitude')) {
        if (btDateService.getFreeDelay(btDateService.getDateFromSec(timestamp)) > 0)
          return '&nbsp;<i class="ion-locked"></i>&nbsp;';
      }

      return convertStrength(strength);
    }

    /**
     *
     * @param {*} strength
     * @return {string}
     */
    function convertStrength(strength) {
      var words = ['Extremely', 'Much', '', 'A bit'];
      var range = [1000, 100, 60, 20, 0];

      for (var i = 0; i < words.length; i++) {
        if (range[i + 1] / 100 < Math.abs(strength) && Math.abs(strength) <= range[i] / 100) {
          return words[i];
        }
      }
      return '';
    }

    /**
     * Create strength object
     * @param {Number} strength - (const) strength
     * @param {Number} timestamp - timestamp in seconds
     * @return {btPercentage}
     */
    function getStrengthObject(strength, timestamp) {
      var returnDict = {};
      var strengthText = '';
      var strengthHtml = '';

      if (timestamp === undefined || timestamp === null) {
        console.error('Empty time');
      }

      if (typeof timestamp !== 'number') {
        console.error('Bad time');
      }

      if (strength > 0) {
        returnDict.status = 'Buy';
        returnDict.msg = 'Stronger';
        returnDict.class = 'positive';
        strengthText = strengthToText(strength, timestamp);
        strengthHtml = strengthToHtml(strength, timestamp);
        returnDict.fullMsg = strengthText + (strengthText === '' ? '' : ' ') + 'Stronger';
        returnDict.html = $sce.trustAsHtml(strengthHtml + (strengthHtml === '' ? '' : ' ') + 'Stronger');
        returnDict.brHtml = $sce.trustAsHtml(strengthHtml + (strengthHtml === '' ? '' : '<br> ') + 'Stronger');

        returnDict.value = strength;
      } else if (strength < 0) {
        returnDict.status = 'Sell';
        returnDict.msg = 'Weaker';
        returnDict.class = 'negative';
        strengthText = strengthToText(strength, timestamp);
        strengthHtml = strengthToHtml(strength, timestamp);
        returnDict.fullMsg = strengthText + (strengthText === '' ? '' : ' ') + 'Weaker';
        returnDict.html = $sce.trustAsHtml(strengthHtml + (strengthHtml === '' ? '' : ' ') + 'Weaker');
        returnDict.brHtml = $sce.trustAsHtml(strengthHtml + (strengthHtml === '' ? '' : '<br> ') + 'Weaker');

        // in order to make the number positive (it comes negative from the database.
        returnDict.value = -1 * strength;
      } else if (strength === 0) {
        returnDict.status = 'AsExpected';
        returnDict.msg = 'As Exp';
        returnDict.class = 'neutral';
        returnDict.fullMsg = 'As Expected';
        returnDict.html = $sce.trustAsHtml('As Expected');
        returnDict.brHtml = $sce.trustAsHtml('As<br> Expected');

        returnDict.value = strength;
      } else {
        returnDict.status = 'nothingStatus';
        returnDict.msg = '';
        returnDict.class = '';
        returnDict.fullMsg = '';
        returnDict.html = '';
        returnDict.brHtml = '';

        returnDict.value = strength;
      }

      // those conditions are here in order to handle a situation where the percentage is smaller than 1%, in that
      // condition we make it 1% positive/negative.
      if (-0.01 < strength && strength < 0) {
        returnDict.value = 0.01;
      } else if (0 < strength && strength < 0.01) {
        returnDict.value = 0.01;
      }

      // converts to percentage
      if (
        !btRestrictionService.hasFeature('release-magnitude') &&
        btDateService.getFreeDelay(btDateService.getDateFromSec(timestamp)) > 0
      ) {
        returnDict.locked = true;
        if (strength !== 0) {
          returnDict.value = 100;
        } else {
          returnDict.value = 0;
        }
      } else {
        returnDict.locked = false;
        returnDict.value = parseInt(returnDict.value * 100);
      }

      returnDict.magnitude = getStrengthMagnitudeId(strength);
      returnDict.power = getStrengthPower(strength);

      return returnDict;
    }

    /**
     * Get range of strength with selected threshold level
     * @param {Number} originalStrength - strength around -1 to 1
     * @param {Number} threshold - strength threshold in same units
     * @return {Number[]} - strength range
     */
    function getStrengthLimits(originalStrength, threshold) {
      var strength = 0;

      if (originalStrength > 0) {
        strength = originalStrength > 1 ? 100 : parseInt(originalStrength * 100);
      } else {
        strength = originalStrength < -1 ? -100 : parseInt(originalStrength * 100);
      }

      var minRowStrength = (strength - threshold) / 100,
        maxRowStrength = (strength + threshold) / 100;

      if (strength > 0 && minRowStrength < 0) minRowStrength = 0;

      if (strength < 0 && maxRowStrength > 0) maxRowStrength = 0;

      // if (minRowStrength > 1) minRowStrength = 0.85;
      if (minRowStrength < -1) minRowStrength = -1;

      if (maxRowStrength > 1) maxRowStrength = 1;
      // if (maxRowStrength < -1) maxRowStrength = -0.85;

      return [minRowStrength, maxRowStrength];
    }

    /**
     * Create strength object
     * @param {number} strength - (const) strength
     * @param {number} time - timestamp in seconds
     * @return {BTStrength}
     */
    function createStrengthObject(strength, time) {
      return new BTStrength(strength, time);
    }

    /**
     *
     * @param {String} previous - previous value
     * @param {String} revision - revision value
     * @param {Number} direction - direction -1 or 1
     * @return {BTStrength}
     */
    function calculateRevisionStrength(previous, revision, direction) {
      var strength;
      var previousNumber = parseFloat(previous);
      var revisionNumber = parseFloat(revision);
      if (direction > 0) {
        strength = revisionNumber > previousNumber ? 0.4 : -0.4;
      } else {
        strength = revisionNumber > previousNumber ? -0.4 : 0.4;
      }

      return createStrengthObject(strength, parseInt(btDateService.getNowDate().getTime() / 1000));
    }

    /**
     *
     * @param {Number} minValue - minimal value
     * @param {Number} maxValue - maximal value
     * @return {String}
     */
    function limitsToText(minValue, maxValue) {
      var strength = (minValue + maxValue) / 2;
      if (strength === 0) {
        return 'as-expected';
      } else {
        return convertStrength(strength);
      }
    }
  }
})();
