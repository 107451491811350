/**
 * Created by Sergey Panpurin on 5/7/2018.
 */

(function btRiskMeterClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btRiskMeter';

  angular
    .module('ecapp')
    /**
     * This directive represents range meter.
     *
     * @ngdoc directive
     * @name btRiskMeter
     * @memberOf ecapp
     */
    .directive('btRiskMeter', btRiskMeter);

  btRiskMeter.$inject = [];

  /**
   *
   * @return {angular.IDirective}
   */
  function btRiskMeter() {
    return {
      restrict: 'E',
      scope: {
        range: '<',
      },
      template:
        '<bt-range-meter ng-if="vm.isRangeValid" class="horizontal outline" data-params="vm.params" data-update="{{vm.update}}"></bt-range-meter>' +
        '<div ng-if="!vm.isRangeValid">No Data</div>',
      controller: btRiskMeterController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btRiskMeterController.$inject = ['$scope', '$rootScope', 'btRiskService'];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.IRiskService} btRiskService
   */
  function btRiskMeterController($scope, $rootScope, btRiskService) {
    /*jshint validthis: true*/
    var vm = this;

    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;

    $scope.$watch('vm.range[3]', onChanges);

    /**
     *
     */
    function onInit() {
      vm.params = btRiskService.getRangeMeterTemplate();
      vm.update = 1;
      vm.isRangeValid = vm.range[3] !== undefined;

      if (vm.isRangeValid)
        btRiskService.updateRangeMeter(
          vm.params,
          vm.range[0],
          vm.range[1],
          vm.range[2],
          vm.range[3],
          vm.range[4],
          vm.range[5]
        );
    }

    /**
     *
     */
    function onDestroy() {}

    /**
     *
     * @param {*} value
     * @param {*} previous
     */
    function onChanges(value, previous) {
      if (value !== previous && value !== undefined) {
        vm.isRangeValid = vm.range[3] !== undefined;

        if (vm.isRangeValid) {
          btRiskService.updateRangeMeter(
            vm.params,
            vm.range[0],
            vm.range[1],
            vm.range[2],
            vm.range[3],
            vm.range[4],
            vm.range[5]
          );
          vm.update++;
        }
      }
    }
  }
})();
