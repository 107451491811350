/**
 * Created by Itay on 6/26/2016.
 */

(function btHistoricGraphTableClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive display historical data table.
     *
     * @ngdoc directive
     * @name btHistoricGraphTable
     * @memberOf ecapp
     */
    .directive('btHistoricGraphTable', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/common/historic-graph/table.html'),
          controller: [
            '$scope',
            'btRowProcessorService',
            'Rows',
            '$ionicScrollDelegate',
            '$state',
            btHistoricGraphTableController,
          ],
        };
      },
    ]);

  /**
   *
   * @param {*} $scope
   * @param {ecapp.IRowProcessorService} btRowProcessorService
   * @param {*} lbRows
   * @param {ionic.IScrollDelegateService} $ionicScrollDelegate
   * @param {angular.ui.IStateService} $state
   */
  function btHistoricGraphTableController($scope, btRowProcessorService, lbRows, $ionicScrollDelegate, $state) {
    //show table is a variable which decides if this directive is shown, in certain circumstances we don't want it to load data.
    $scope.showTable = true;

    //if usually no link equals to true, it means that this event is speech or report, and the table shouldn't be shown (since there are no links in previous rows)

    var usuallyNoLink = $scope.rowModalData.eventsInfo.usuallyNoLink;
    //usuallyNoLink is assigned and equals to true
    if (null != usuallyNoLink && usuallyNoLink) {
      $scope.showTable = false;
    }

    $scope.historicRows = [];

    var historicItemLoaded = 0;
    var loadLimitation = 5;

    $scope.isNoMoreItemsToLoad = false;
    $scope.isLoading = false;

    $scope.loadMoreHistoricItems = function (isScrollBottom) {
      $scope.isLoading = true;

      var query = {
        data: {
          eventId: $scope.rowModalData.eventId,
          time: $scope.rowModalData.time,
          skip: historicItemLoaded,
          limit: loadLimitation,
        },
      };

      lbRows
        .getPrevRows(query)
        .$promise.then(function (res) {
          $scope.isLoading = false;
          // process the recent loaded rows
          angular.forEach(res.getPrevRows, function (row) {
            btRowProcessorService.historicRowProcessor(row);
            var data = btRowProcessorService.getReleaseIdData(row, $scope.rowModalData.eventsInfo);
            row.personalLink = $state.href('ecapp.app.main.detail', data, { absolute: false });
          });

          //this condition checks if the amount of loaded items from database equals to the limit, if it does we
          // assume that there are more items to load, but if it isn't there are no more items to load.
          if (res.getPrevRows.length != loadLimitation) $scope.isNoMoreItemsToLoad = true;

          //merging the already loaded array and the new one, after the process
          angular.extend($scope.historicRows, $scope.historicRows.concat(res.getPrevRows));

          //resize the scroll so it won't get stuck
          $ionicScrollDelegate.resize();

          //scrolling to bottom is required. MIGHT BE CHANGED IF HISTORY TABLE WON'T BE IN THE END OF THE PAGE
          if (isScrollBottom) $ionicScrollDelegate.$getByHandle('modalScroll').scrollBottom(true);
        })
        .catch(function (reason) {
          console.log('btHistoricGraphTable: error', reason);
        });

      //updating the counter in order to know how many was loaded already
      historicItemLoaded += loadLimitation;
    };

    // if showTable equals to false, we don't want to auto load the items, since they're not going to be shown anyway.
    if ($scope.showTable) {
      //getting historic rows from database
      $scope.loadMoreHistoricItems(false);
    }
  }
})();
