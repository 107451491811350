/**
 * Created by Sergey Panpurin on 5/6/2018.
 */

//@ts-check
(function btTradeIdeasFeedClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btTradeIdeasFeed';

  angular.module('ecapp').directive('btTradeIdeasFeed', btTradeIdeasFeed);

  btTradeIdeasFeed.$inject = ['$templateCache'];

  /**
   * This directive displays event feed of trade ideas.
   *
   * Update calendar every 30 seconds (just update interval data).
   *
   * complexSymbols - comma separated complex name of symbols
   * displaySymbols - comma separated display name of symbols
   *
   * Expected item
   *
   *  id
   *  time
   *  updateInterval
   *
   * @ngdoc directive
   * @name btTradeIdeasFeed
   * @memberOf ecapp
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btTradeIdeasFeed($templateCache) {
    return {
      restrict: 'E',
      scope: {
        complexSymbolsString: '@?complexSymbols',
        displaySymbolsString: '@?displaySymbols',
        feedSize: '@?',
        isWatchlist: '@?',
        insights: '<?',
        displayOptions: '=',
      },
      template: $templateCache.get('components/mobile/bt-feed/bt-trade-ideas-feed.html'),
      controller: btTradeIdeasFeedController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btTradeIdeasFeedController.$inject = ['$rootScope', '$interval', 'btTradeIdeasService', 'btTradeIdeasFiltersService'];

  /**
   *
   * @param {ecapp.ICustomRootScope} $rootScope - ?
   * @param {angular.IIntervalService} $interval - ?
   * @param {ecapp.ITradeIdeasService} btTradeIdeasService - ?
   * @param {ecapp.ITradeIdeasFiltersService} btTradeIdeasFiltersService - ?
   */
  function btTradeIdeasFeedController($rootScope, $interval, btTradeIdeasService, btTradeIdeasFiltersService) {
    /*jshint validthis: true*/
    /** @type {scopes.TradeIdeasFeed.IController} */
    var vm = this;
    var gIntervalPromise = null;
    var gDefaultUpdateInterval = 5000;
    var gDefaultFeedSize = '3';
    var gFilter = {};

    vm.complexSymbols = vm.complexSymbolsString ? vm.complexSymbolsString.split(',') : [];
    vm.displaySymbols = vm.displaySymbolsString ? vm.displaySymbolsString.split(',') : [];
    vm.feedSize = parseInt(vm.feedSize || gDefaultFeedSize);
    vm.insights = vm.insights || [];
    vm.isWatchlist = vm.isWatchlist || 'false';
    vm.meta = vm.meta || {};
    vm.tradeIdeas = vm.tradeIdeas || [];
    vm.templates = vm.templates || {
      empty: 'components/mobile/bt-feed/bt-trade-ideas-feed-empty.html',
      card: 'components/mobile/bt-feed/bt-trade-ideas-feed-card.html',
    };

    vm.meta.hasAdvertising = vm.isWatchlist === 'true';
    vm.meta.customCssClass = 'bt-trade-ideas-feed';
    vm.meta.collapseSize = vm.feedSize;

    vm.isLoading = true;
    vm.hasError = false;
    vm.errorMessage = 'Unknown error';

    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;
    vm.$onChanges = onChanges;

    vm.requireTradeIdeasFilter = requireTradeIdeasFilter;

    $rootScope.$on('calendar:new-release', onNewRelease);
    $rootScope.$on('moments:new-release', onNewMoment);

    $rootScope.$on('trade-ideas-filters:updated', updateFilteredTradeIdeasList);

    /**
     *
     */
    function onDestroy() {
      stopAutoUpdate();
    }

    /**
     *
     * @param {Object} changes
     */
    function onChanges(changes) {
      if (changes.complexSymbolsString) {
        vm.complexSymbols = vm.complexSymbolsString ? vm.complexSymbolsString.split(',') : [];
      }

      if (changes.displaySymbols) {
        vm.displaySymbols = vm.displaySymbolsString ? vm.displaySymbolsString.split(',') : [];
      }
    }

    /**
     *
     * @param {*} callback - ?
     * @param {*} interval - ?
     */
    function startAutoUpdate(callback, interval) {
      if (gIntervalPromise === null) {
        gIntervalPromise = $interval(callback, interval);
      }
    }

    /**
     *
     */
    function stopAutoUpdate() {
      if (gIntervalPromise !== null) {
        if ($interval.cancel(gIntervalPromise)) {
          gIntervalPromise = null;
        }
      }
    }

    /**
     *
     */
    function autoUpdateCallback() {
      vm.tradeIdeas.forEach(function (item) {
        if (typeof item.updateInterval === 'function') {
          item.updateInterval();
        }
      });

      filterTradeIdeas();

      if (gDebug) console.log(gPrefix, 'trade ideas for ' + vm.complexSymbols + ' was updated at', new Date());
    }

    /**
     *
     * @param {*} event - ?
     * @param {btRawRelease} data - ?
     */
    function onNewRelease(event, data) {
      void event;
      var tradeIdeas = btTradeIdeasService.parseInstrumentTradeIdea(
        data.tradingInsights,
        data,
        'release',
        vm.complexSymbols
      );

      if (tradeIdeas.length > 0) {
        addTradeIdeas(tradeIdeas);
      }
    }

    /**
     *
     * @param {*} event - ?
     * @param {btMomentObject} message - ?
     */
    function onNewMoment(event, message) {
      void event;

      /**
       * btMomentObject
       */
      var data = JSON.parse(JSON.stringify(message));

      var tradeIdeas = btTradeIdeasService.parseInstrumentTradeIdea(data.insights, data, 'moment', vm.complexSymbols);

      if (tradeIdeas.length > 0) {
        addTradeIdeas(tradeIdeas);
      }
    }

    /**
     *
     * @param {*} tradeIdeas - ?
     */
    function addTradeIdeas(tradeIdeas) {
      var newTradeIdeas = tradeIdeas.filter(function (tradeIdea) {
        return (
          vm.tradeIdeas.filter(function (item) {
            return item.id === tradeIdea.id;
          }).length === 0
        );
      });

      vm.tradeIdeas = newTradeIdeas.concat(vm.tradeIdeas);
      filterTradeIdeas();
    }

    /**
     *
     */
    function onInit() {
      btTradeIdeasService.initialize().then(function () {
        prepareCalendar();
      });
    }

    /**
     *
     */
    function prepareCalendar() {
      if (vm.isWatchlist === 'true') {
        vm.templates.empty = 'components/mobile/bt-feed/bt-trade-ideas-feed-empty-tab.html';
        vm.meta.requireTradeIdeasFilter = requireTradeIdeasFilter;
      }

      if (vm.insights.length === 0) {
        if (vm.complexSymbols.length === 0) {
          vm.errorMessage = "Instrument isn't selected";
          vm.hasError = true;
          vm.isLoading = false;
          return;
        }

        if (vm.complexSymbols.length !== vm.displaySymbols.length) {
          vm.errorMessage = 'Wrong instrument description';
          vm.hasError = true;
          vm.isLoading = false;
          return;
        }

        vm.meta.symbols = vm.displaySymbols.join(', ');

        btTradeIdeasService
          .getTradeIdeasBySymbols(vm.complexSymbols)
          .then(function (promiseValue) {
            if (vm.isWatchlist === 'true') {
              return btTradeIdeasFiltersService.loadUserSettings().then(function (settings) {
                gFilter = settings;
                return promiseValue;
              });
            } else {
              return promiseValue;
            }
          })
          .then(function (promiseValue) {
            vm.tradeIdeas = promiseValue;
            filterTradeIdeas();
            vm.hasError = false;
            vm.isLoading = false;
            startAutoUpdate(autoUpdateCallback, gDefaultUpdateInterval);
          })
          .catch(function (reason) {
            console.error(reason);
            stopAutoUpdate();
            vm.errorMessage = reason.message || 'Unknown error';
            vm.hasError = true;
            vm.isLoading = false;
          });
      } else {
        vm.tradeIdeas = vm.insights.map(function (item) {
          return btTradeIdeasService.convertToTradeIdea(item);
        });

        vm.hasError = false;
        vm.isLoading = false;
        vm.filteredTradeIdeas = vm.tradeIdeas;
        startAutoUpdate(autoUpdateCallback, gDefaultUpdateInterval);

        vm.meta.symbols = 'this release';
      }
    }

    /**
     * This function runs trade ideas filtering by new user's settings.
     *
     * @param {*} event - ?
     * @param {*} settings - ?
     */
    function updateFilteredTradeIdeasList(event, settings) {
      gFilter = settings;
      filterTradeIdeas();
    }

    /**
     * This function filters trade ideas by user's settings
     */
    function filterTradeIdeas() {
      vm.totalNumber = vm.tradeIdeas.length;
      vm.filteredTradeIdeas = vm.tradeIdeas.filter(filterTradeIdeaByTradeIdeasFilters);
      vm.filteredNumber = vm.filteredTradeIdeas.length;
    }

    /**
     * This function filters trade idea by user's settings
     * @param {ecapp.ITradeIdea} item
     * @return {boolean}
     */
    function filterTradeIdeaByTradeIdeasFilters(item) {
      if (vm.isWatchlist === 'true') {
        return item.passUserFilter(gFilter);
      } else {
        return true;
      }
    }

    /**
     *
     */
    function requireTradeIdeasFilter() {
      $rootScope.$broadcast('trade-ideas-filters:required');
    }
  }
})();
