/**
 * Created by Eyal on 8/2/2016.
 */

(function btReadingsRangeClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive visualize range of reading data (last actual values).
     *
     * @ngdoc directive
     * @name btReadingsRange
     * @memberOf ecapp
     */
    .directive('btReadingsRange', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/common/bt-readings-range.html'),
          scope: {
            data: '=',
            row: '=',
          },
          controller: [
            '$scope',
            'btPriceService',
            function ($scope, btPriceService) {
              /**
               * This function get low value of ranger according to actual value
               *
               * @param {Number} low - low value from data
               * @param {Number} actual - actual value
               * @return {Number} real low value
               */
              function getLow(low, actual) {
                if (actual < low) {
                  return actual;
                } else {
                  return low;
                }
              }

              /**
               * This function get high value of ranger according to actual value
               *
               * @param {Number} high - high value from data
               * @param {Number} actual - actual value
               * @return {Number} real high value
               */
              function getHigh(high, actual) {
                if (actual > high) {
                  return actual;
                } else {
                  return high;
                }
              }

              // save number precision
              this.precision = btPriceService.getPricePrecision($scope.data.low);

              // convert to numbers for calculations
              this.data = {};
              this.data.low = parseFloat($scope.data.low);
              this.data.high = parseFloat($scope.data.high);
              this.data.actual = parseFloat($scope.data.actual);
              this.data.benchmark = parseFloat($scope.data.benchmark);

              // noinspection JSUnresolvedVariable
              $scope.unit = ($scope.$parent.row.eventsInfo.unit || '').toUpperCase();

              $scope.iconFLoat = null;

              // move icon for actual value to right
              $scope.iconRight = true;

              // differences between low, high, actual values and benchmark used to find total width in symmetric case
              var lowDifference = Math.abs(this.data.low - this.data.benchmark);
              var highDifference = Math.abs(this.data.high - this.data.benchmark);
              var largestDifference, totalWidth;

              // check if an actual value exists
              if (this.data.actual === null || isNaN(this.data.actual)) {
                $scope.noActual = true;
                largestDifference = Math.max(lowDifference, highDifference);
                $scope.low = this.data.low;
                $scope.high = this.data.high;
              } else {
                $scope.noActual = false;
                var absoluteActual = Math.abs(this.data.actual - this.data.benchmark);
                largestDifference = Math.max(lowDifference, highDifference, absoluteActual);

                // check actual value
                $scope.low = getLow(this.data.low, this.data.actual);
                $scope.high = getHigh(this.data.high, this.data.actual);
              }

              // decide show benchmark or not, total width is equal to double the largest difference or high-low difference
              if (this.data.benchmark < $scope.low || this.data.benchmark > $scope.high) {
                // benchmark out of range
                $scope.benchmarkHidden = true;
                totalWidth = $scope.high - $scope.low;
              } else {
                // benchmark inside range
                $scope.low = this.data.benchmark - largestDifference;
                $scope.high = this.data.benchmark + largestDifference;
                totalWidth = largestDifference * 2;
              }

              // the positiveActualText will be floated right
              $scope.positiveActualTextFloat = 'right';

              // the negativeActualText will be floated left
              $scope.negativeActualTextFloat = 'left';

              // bar size and margin, as well as negative and positive actual text margins
              $scope.barWidth = (Math.abs(this.data.high - this.data.low) / totalWidth) * 100;
              $scope.barMarginLeft = (Math.abs(this.data.low - $scope.low) / totalWidth) * 100;

              // data low and high text position
              $scope.negativeActualTextMarginLeft = (Math.abs(this.data.low - $scope.low) / totalWidth) * 2 * 100;
              $scope.positiveActualTextMarginLeft = (Math.abs($scope.high - this.data.high) / totalWidth) * 2 * 100;
              $scope.OldHighLabelMarginRight = (Math.abs($scope.high - this.data.high) / totalWidth) * 100;
              $scope.OldLowLabelMarginLeft = (Math.abs(this.data.low - $scope.low) / totalWidth) * 100;

              // actual value indicator position
              if ($scope.noActual) {
                $scope.iconMargin = 0;
              } else {
                $scope.iconMargin = (($scope.high - this.data.actual) / totalWidth) * 100;
              }

              // if the actual is the same as the high value set above:
              if (this.data.actual === $scope.high) {
                $scope.iconWrapperTextAlign = 'right';
                $scope.iconMargin = 0;
                $scope.iconRight = true;
              }

              // if the actual is the same as the low value set above
              else if (this.data.actual === $scope.low) {
                $scope.iconFLoat = 'left';
                $scope.iconRight = false;
                $scope.iconMargin = 87;
              }

              // if the actual is the same as the benchmark, the margin of the icon is 50
              else if (this.data.actual === this.data.benchmark) {
                $scope.iconMargin = 50;
              } else if (this.data.actual < $scope.low + ($scope.high - $scope.low) / 2) {
                $scope.iconRight = false;
                $scope.iconMargin = (($scope.high - this.data.actual) / totalWidth) * 100 - 6;
              }

              // convert back to strings
              $scope.low = $scope.low.toFixed(this.precision);
              $scope.high = $scope.high.toFixed(this.precision);
              // $scope.data.low = $scope.data.low.toFixed(this.precision);
              // $scope.data.high = $scope.data.high.toFixed(this.precision);
              // $scope.data.actual = $scope.data.actual.toFixed(this.precision);
            },
          ],
          link: function (scope, el) {
            // DOM Elements
            var readingRangeBar = el[0].childNodes[0].childNodes[5];
            var readingRangeIcon = el[0].childNodes[0].childNodes[1];
            var negativeActualText = el[0].childNodes[0].childNodes[9].childNodes[1].childNodes[0];
            var positiveActualText = el[0].childNodes[0].childNodes[9].childNodes[3].childNodes[0];

            // Bar width and margin
            readingRangeBar.style.width = scope.barWidth + '%';
            readingRangeBar.style.marginLeft = scope.barMarginLeft + '%';

            // Actual (positive and negative) margins
            negativeActualText.style.marginLeft = scope.negativeActualTextMarginLeft + '%';
            positiveActualText.style.marginLeft = scope.positiveActualTextMarginLeft + '%';

            // EYAL STOP HERE FRENCH CPI have ACTUAL and prob with high actual 15 instead 30
            positiveActualText.style.marginRight = 2 * scope.OldHighLabelMarginRight + '%';

            // Icon margins and float
            readingRangeIcon.style.marginRight = scope.iconMargin + '%';
            if (scope.iconFLoat) {
              readingRangeIcon.style.float = scope.iconFLoat;
            }

            // Positive and negative text floats
            positiveActualText.style.float = scope.positiveActualTextFloat;
            negativeActualText.style.float = scope.negativeActualTextFloat;

            //if scope.benchmarkHidden is set as true, we want to make the white center bar to disappear, by adding a class
            if (null != scope.benchmarkHidden && true == scope.benchmarkHidden) {
              el.find('.reading-range-center-icon').addClass('bt-transparent');
              el.find('.reading-range-benchmark-text').addClass('bt-transparent');
            }
          },
        };
      },
    ]);
})();
