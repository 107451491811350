/**
 * Created by Sergey Panpurin on 2019-09-22
 */

(function btClocksClosure() {
  'use strict';

  /**
   * This directive displays trader clocks.
   *
   * @ngdoc directive
   * @name btClocks
   * @memberOf ecapp
   * @restrict E
   * @scope
   */

  /**
   * This controller processes trader clocks.
   *
   * @ngdoc controller
   * @name btClocksController
   * @memberOf ecapp
   */

  /** Directive */
  angular.module('ecapp').directive('btClocks', angularDirective).controller('btClocksController', angularController);

  angularDirective.$inject = ['$templateCache'];

  /**
   * Implementation of Notification Center Directive
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function angularDirective($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('directives/common/bt-clocks.html'),
      controller: 'btClocksController',
    };
  }

  angularController.$inject = ['$scope', '$interval', 'btTradingSessionsService'];

  /**
   * Implementation of Notification Center Controller
   *
   * @param {ecapp.IClockScope} $scope
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.ITradingSessionsService} btTradingSessionsService
   */
  function angularController($scope, $interval, btTradingSessionsService) {
    /**
     * List of sessions.
     * @type {BTTradingSession[]}
     */
    var gSessions = btTradingSessionsService.getSessions();

    /**
     * Updates handler.
     * @type {*}
     */
    var gUpdateHandler = null;

    // The rest of the activation
    $scope.$on('$destroy', onDestroy);

    activate();

    /**
     * @typedef {object} btClock
     * @property {BTTradingSession} session -
     * @property {string} stage -
     * @property {string} name -
     * @property {string} desc -
     * @property {string} color -
     * @property {*} moment -
     * @property {string} time -
     * @property {string} tz -
     */

    /**
     * Controller constructor
     */
    function activate() {
      $scope.clocks = gSessions.map(getSessionClock);
      $scope.clocks.unshift(getLocalClock());
      startUpdates();
    }

    /**
     * This function updates clocks.
     */
    function updateClocks() {
      $scope.clocks.forEach(function (clock) {
        clock.stage = clock.session ? 'bt-' + clock.session.checkDate(new Date()) : 'bt-main';
        clock.moment = clock.tz !== 'Local' ? moment().tz(clock.tz) : moment();
        clock.time = clock.tz !== 'Local' ? moment().tz(clock.tz).format('HH:mm') : moment().format('HH:mm');
      });
    }

    /**
     * This function returns session's clock.
     *
     * @param {BTTradingSession} session - session instance
     * @return {btClock}
     */
    function getSessionClock(session) {
      return {
        id: session.id,
        session: session,
        stage: 'bt-' + session.checkDate(new Date()),
        name: session.code,
        desc: session.city + ' Time',
        color: session.color,
        moment: moment().tz(session.timezone),
        time: moment().tz(session.timezone).format('HH:mm'),
        tz: session.timezone,
      };
    }

    /**
     * This function returns local clock.
     *
     * @return {btClock}
     */
    function getLocalClock() {
      return {
        session: null,
        stage: 'bt-main',
        name: 'Local',
        desc: 'Local Time',
        color: '#AAAAAA',
        moment: moment(),
        time: moment().format('HH:mm'),
        tz: 'Local',
      };
    }

    /**
     * This function reacts on scope destroy.
     */
    function onDestroy() {
      stopUpdates();
    }

    /**
     * This function starts updates.
     */
    function startUpdates() {
      gUpdateHandler = $interval(function () {
        updateClocks();
      }, 1000);
    }

    /**
     * This function stops updates.
     */
    function stopUpdates() {
      if (gUpdateHandler) $interval.cancel(gUpdateHandler);
      gUpdateHandler = null;
    }
  }
})();
