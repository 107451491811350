/**
 * Created by Sergey Panpurin on 10/23/2017.
 */

(function btNextEventClosure() {
  'use strict';

  var gDebug = false;

  /**
   * This directive displays information about nex event.
   *
   * @ngdoc directive
   * @name btNextEvent
   * @memberOf ecapp
   */

  angular
    .module('ecapp')
    .controller('btNextEventController', btNextEventController)
    .directive('btNextEvent', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          transclude: false,
          scope: {
            name: '@',
          },
          template: $templateCache.get('directives/common/bt-next-event.html'),
          controller: 'btNextEventController',
        };
      },
    ]);

  btNextEventController.$inject = [
    '$scope',
    '$state',
    'btDateService',
    '$interval',
    'btCalendarService',
    'btTimeSupervisionService',
    'btDelegateMethodsService',
    'btRowProcessorService',
  ];

  /**
   *
   * @param {*} $scope
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.IDateService} btDateService
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.ICalendarService} btCalendarService
   * @param {ecapp.ITimeSupervisionService} btTimeSupervisionService
   * @param {ecapp.IDelegateMethodsService} btDelegateMethodsService
   * @param {ecapp.IRowProcessorService} btRowProcessorService
   */
  function btNextEventController(
    $scope,
    $state,
    btDateService,
    $interval,
    btCalendarService,
    btTimeSupervisionService,
    btDelegateMethodsService,
    btRowProcessorService
  ) {
    var gTimerFunc = null;
    var gExtraUpdate = false;

    $scope.openNextEvent = openNextEvent;
    $scope.openRelease = openRelease;

    // cancel interval function on destroy
    $scope.$on('$destroy', function () {});

    $scope.$on('calendar:refreshed', function () {
      prepareData();
    });

    $scope.$on('calendar:reloaded', function () {
      prepareData();
    });

    prepareData();

    /**
     *
     */
    function prepareData() {
      if ($scope.name === 'following') {
        $scope.isLoading = true;
        prepareNextFollowingEventsData();
      } else {
        // prepare next event data
        prepareNextEventData();
      }
    }

    /**
     *
     */
    function prepareNextFollowingEventsData() {
      btCalendarService
        .getCalendar()
        .then(getSoonestEvents)
        .then(function (nextEvents) {
          $scope.nextFollowingEvents = nextEvents;

          var soonest = nextEvents[0];
          if (soonest) {
            var seconds = secondsToRelease(soonest);
            $scope.nextEventTime = getHumanTime(soonest, seconds);
          } else {
            $scope.nextEventTime = null;
          }
          $scope.isLoading = false;
        });
    }

    /**
     * This function open release page
     *
     * @ngdoc method
     * @name openRelease
     * @memberOf ecapp.btAllReviewsController
     * @param {btRelease} release - (changeable) event data
     */
    function openRelease(release) {
      // load insights and save information of release to cache
      // btRowProcessorService.prepareEventDetailsView(release);

      var data = btRowProcessorService.getReleaseIdData(release, release.eventsInfo);
      $state.go('ecapp.app.main.detail', data);
    }

    /**
     *
     */
    function openNextEvent() {
      $state.go('ecapp.app.main.reviews').then(function () {
        btDelegateMethodsService.getDelegateMethod('SCROLL-TO-LATEST-ROW').apply(null);
      });
    }

    /**
     * Prepare information about next event
     */
    function prepareNextEventData() {
      btCalendarService
        .getCalendar()
        .then(getSoonestEvents)
        .then(function (nextEvents) {
          $scope.soonest = nextEvents[0];
          if ($scope.soonest) {
            $scope.isComingSoon = isComingSoon($scope.soonest);
            var seconds = secondsToRelease($scope.soonest);
            $scope.nextEventTime = getHumanTime($scope.soonest, seconds);
            if (seconds > 0 && seconds < 60) {
              setDisplaySecondsTimer(seconds);
            }
          }
        });
    }

    /**
     * Is need timer
     * @param {Object} release
     * @return {number}
     */
    function secondsToRelease(release) {
      return parseInt((btDateService.getDateFromRow(release).getTime() - btDateService.getNowDate().getTime()) / 1000);
    }

    /**
     * Set timer
     * @param {Number} seconds - number of seconds to release
     */
    function setDisplaySecondsTimer(seconds) {
      if (gTimerFunc !== null) $interval.cancel(gTimerFunc);

      gTimerFunc = $interval(countdownTimer, 1000, seconds);

      if (!gExtraUpdate) {
        gExtraUpdate = true;
        $interval(releaseTimer, (seconds + 2) * 1000, 1);
      }
    }

    /**
     * Show countdown
     */
    function countdownTimer() {
      if (gDebug) console.log('Interval was called (countdownTimer)');

      $scope.isComingSoon = isComingSoon($scope.soonest);
      $scope.nextEventTime = getHumanTime($scope.soonest, 1);
    }

    /**
     * Update after release
     */
    function releaseTimer() {
      if (gDebug) console.log('Interval was called (releaseTimer)');
      prepareData();
      gExtraUpdate = false;
    }

    /**
     * Parse array of releases and find soonest ones
     *
     * @param {btCalendarData} calendar - array of releases
     * @return {btRelease[]} - soonest releases
     */
    function getSoonestEvents(calendar) {
      var soonest = [];
      calendar.cards.forEach(function (review) {
        if (review.class === 'eventCardNextHot' || review.class === 'eventCardNext') {
          soonest.push(review);
        }
      });

      if (soonest.length) {
        return soonest;
      }

      calendar.cards.forEach(function (review) {
        if (
          review.class === 'eventCardJustPositive' ||
          review.class === 'eventCardJustAsExpected' ||
          review.class === 'eventCardJustNegative'
        ) {
          soonest.push(review);
        }
      });

      // for testing
      // soonest.time = ((new Date()).getTime())/ 1000 + 45;

      return soonest;
    }

    /**
     * Check is release soonest
     *
     * @param {btRelease} release - release object
     * @return {Boolean} - is release soonest
     */
    function isComingSoon(release) {
      if (release) {
        return release.class === 'eventCardNextHot';
      } else {
        return false;
      }
    }

    /**
     * Convert time to human readable
     *
     * @param {btRelease} release - release object
     * @param {Number} seconds - number of seconds
     * @return {String} - time in human readable format
     */
    function getHumanTime(release, seconds) {
      if (seconds <= 0) {
        return 'right now';
      } else {
        var eventTime = release ? btDateService.getHumanisedTimeFromNow(btDateService.getDateFromRow(release)) : '';
        return eventTime.slice(0, 2).toLowerCase() === 'in' ? '' : 'in ' + eventTime;
      }
    }
  }
})();
