(function humanizeDuration() {
  'use strict';

  angular.module('btUtils').filter('humanizeDuration', [
    'btDateService',
    function (btDateService) {
      return function (input, options) {
        void options;
        console.log('humanizeDuration', input);
        return btDateService.getHumanisedTimeFromNow(input, true);
      };
    },
  ]);
})();
