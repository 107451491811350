// @ts-check
(function btTradingPlatformServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btTradingPlatformService', btTradingPlatformService);

  btTradingPlatformService.$inject = [
    '$q',
    '$ionicPopup',
    'btSharedData',
    'btSettingsService',
    'btShareScopeService',
    'btMarketing',
    'btToastrService',
    'btRestrictionService',
    'btAdvertisingService',
    'CacheFactory',
  ];

  /**
   * This is a service.
   *
   * @ngdoc service
   * @name btTradingPlatformService
   * @memberOf dashboard
   * @param {angular.IQService} $q
   * @param {ionic.IPopupService} $ionicPopup
   * @param {ecapp.ISharedData} btSharedData
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IMarketing} btMarketing
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IAdvertisingService} btAdvertisingService
   * @param {angular.ICacheFactoryService} CacheFactory
   * @return {ecapp.ITradingPlatformService}
   */
  function btTradingPlatformService(
    $q,
    $ionicPopup,
    btSharedData,
    btSettingsService,
    btShareScopeService,
    btMarketing,
    btToastrService,
    btRestrictionService,
    btAdvertisingService,
    CacheFactory
  ) {
    /**
     * @typedef {object} btTradingPlatformObject
     * @property {string} id - platform identifier ('ctrader'),
     * @property {string} name - platform name ('cTrader'),
     * @property {string} title - title ('cTrader (Live & Practice)'),
     * @property {string} image - image url ('images/brokers/ctrader-big.png'),
     * @property {string} website - website ('https://spotware.com'),
     * @property {boolean} enabled - enabled (true)
     * @property {boolean} [partner] - partnership (true)
     * @property {boolean} [practice] - practice (true)
     * @property {btTradingPlatformRegion} [region] - region settings ({include: ['usa']})
     * @property {string} [account] - link to create new account ('https://spotware.com')
     * @property {string} [benefits] - benefits ('Benefits for existing and new clients')
     * @property {string} [url] - trading platform url
     * @property {btTradingPlatformVariant[]} [variants] - list of variants
     * @property {btTradingPlatformBroker[]} [brokers] - list of brokers
     */

    /**
     * @typedef {object} btTradingPlatformRegion
     * @property {string[]} include - list of region to include
     * @property {string[]} exclude - list of region to exclude
     */

    /**
     * @typedef {object} btTradingPlatformVariant
     * @property {string} id - identifier
     * @property {string} url - url
     * @property {string} title - title
     * @property {string[]} text - information
     */

    /** @type {btTradingPlatformObject[]} */
    var gPlatforms = [];

    /** @type {{connected: boolean, platform: ?string, broker: ?btTradingPlatformObject, variant: string}} */
    var gStatus = {
      connected: false,
      platform: null,
      broker: null,
      variant: null,
    };

    var gNote =
      '<div class="bt-note"><b>Important note:</b> when closing the BetterTrader app or disconnecting your ' +
      'trading platform from the BetterTrader app, your browser might still stay connected to your trading platform.' +
      '<br><br>' +
      'If you would like to disconnect from your broker in the browser as well - you need first disconnect from ' +
      'your broker and then disconnect your platform from BetterTrader.</div>';

    var gInitPromise;
    // activate();

    var gCache = CacheFactory('cache', {
      storageMode: 'sessionStorage',
      // maxAge: 24 * 3600 * 1000,
      maxAge: 10000,
      deleteOnExpire: 'passive',
      storagePrefix: 'bt.',
    });

    return {
      initialize: initialize,
      status: gStatus,
      open: openTab,
      connect: connect,
      disconnect: disconnect,
      getPlatforms: getPlatforms,
      getPlatformById: getPlatformById,
      getPlatformUrl: getPlatformUrl,
      // getBrokers: getBrokers,
      addDisconnectListener: addDisconnectListener,
    };

    /**
     *
     * @return {*} ?
     */
    function initialize() {
      if (!gInitPromise) {
        gInitPromise = btAdvertisingService
          .initialize()
          .then(loadRemoteSettings)
          .then(function () {
            checkUserRegion();
            loadLocalSettings();
          });
      }

      return gInitPromise;
    }

    /**
     * Loads remote settings.
     *
     * @return {angular.IPromise<*>}
     */
    function loadRemoteSettings() {
      var KEY = 'trading-platforms';
      var cached = gCache.get(KEY);

      if (cached) {
        gPlatforms = cached || [];
        return $q.resolve();
      } else {
        return btShareScopeService.getAppSettings(KEY).then(function (settings) {
          gPlatforms = settings.platforms || [];
          gCache.put(KEY, gPlatforms);
        });
      }
    }

    /**
     * Hides platforms based on user region.
     */
    function checkUserRegion() {
      var region = btAdvertisingService.getUserRegion();

      gPlatforms.forEach(function (platform) {
        if (!btAdvertisingService.validateUserRegion(platform.region, region)) {
          platform.enabled = false;
        }
      });
    }

    /**
     * Saves settings to local storage.
     *
     * @param {*} data - ?
     */
    function saveSettings(data) {
      var params = {
        connected: data.connected,
        platform: data.platform,
        broker: data.broker ? data.broker.id : null,
        variant: data.variant,
      };

      window.localStorage.setItem('btTradingPlatform', JSON.stringify(params));
    }

    /**
     * Loads settings from local storage.
     */
    function loadLocalSettings() {
      var defaultPlatformId = btSettingsService.getAssets('trading-integration-platform');
      var defaultVariantId = btSettingsService.getAssets('trading-integration-variant');
      var defaultBrokerId = btSettingsService.getAssets('trading-integration-broker');

      if (defaultPlatformId && defaultBrokerId) {
        updateStatus(true, defaultPlatformId, defaultBrokerId, defaultVariantId || null);
      } else {
        try {
          if (!btRestrictionService.hasFeature('trading-platform')) return;
          clearStatus();

          var data = window.localStorage.getItem('btTradingPlatform');
          data = JSON.parse(data);

          if (data && data.connected) {
            updateStatus(data.connected, data.platform, data.broker, data.variant);

            if (gStatus.broker === undefined) {
              clearStatus();
            }
          }
        } catch (e) {
          clearStatus();
          console.error(e);
        }
      }
    }

    /**
     * Clears global status.
     */
    function clearStatus() {
      gStatus.connected = false;
      gStatus.platform = null;
      gStatus.broker = null;
      gStatus.variant = null;
    }

    /**
     * Update global status.
     *
     * @param {boolean} connected - ?
     * @param {string} platformId - ?
     * @param {string} brokerId - ?
     * @param {string} variantId - ?
     */
    function updateStatus(connected, platformId, brokerId, variantId) {
      gStatus.connected = connected;
      gStatus.platform = platformId;
      gStatus.variant = variantId;

      // Select platform object
      var platform = gPlatforms.filter(function (platform) {
        return platform.id === platformId;
      })[0];

      if (platform && platform.brokers) {
        gStatus.broker = platform.brokers.filter(function (broker) {
          return (broker.id = brokerId);
        })[0];
      } else {
        gStatus.broker = gPlatforms.filter(function (platform) {
          return platform.id === brokerId;
        })[0];
      }
    }

    /**
     *
     * @return {angular.IPromise<*>}
     */
    function openTab() {
      return btSharedData.openTradingPlatform();
    }

    /**
     * Checks if user have ability to connect trading platform.
     *
     * @param {*} broker - ?
     * @return {*}
     */
    function checkRestriction(broker) {
      if (btRestrictionService.hasFeature('trading-platform')) {
        return $q.resolve(broker);
      } else {
        return btRestrictionService.showUpgradePopup('trading-platform').then(function (value) {
          if (value === 'updated') {
            return $q.resolve(broker);
          } else {
            return $q.reject(new Error('Platform is locked! Try to upgrade subscription plan.'));
          }
        });
      }
    }

    /**
     * Connects trading platform.
     *
     * @param {string} platformId - platform identifier
     * @param {btTradingPlatformObject} brokerObj - platform object
     * @param {string} [variantId] - platform variant identifier
     * @return {angular.IPromise<any>}
     */
    function connect(platformId, brokerObj, variantId) {
      var data = getVariant(brokerObj, variantId);

      if (!data) {
        btToastrService.error('Unexpected error. Variant is not defined.', 'System');
        return $q.resolve({ confirmed: false, url: '' });
      }

      if (!data.url) {
        btToastrService.error('Unexpected error. URL is not defined.', 'System');
        return $q.resolve({ confirmed: false, url: '' });
      }

      return (data.text ? showMoreInfo(data.name, data.text, data.external ? 'Open' : 'Next') : $q.resolve(true))
        .then(function (confirmed) {
          return confirmed ? checkRestriction(data).then(confirmConnection) : $q.resolve(false);
        })
        .then(function (confirmed) {
          if (confirmed) {
            if (data.external) {
              window.open(data.url, '_system');
              return { confirmed: false, url: data.url };
            }

            gStatus.connected = true;
            gStatus.broker = brokerObj;
            gStatus.platform = platformId;
            gStatus.variant = variantId;

            saveSettings(gStatus);

            btToastrService.success('You are now connected to ' + brokerObj.name + '.', 'Trading Platform', {
              type: 'trade',
            });
            if (btRestrictionService.hasFeature('personalization')) {
              btShareScopeService.setMailChimpField('platform', brokerObj.id + ' - ' + platformId);
            }

            btMarketing.updateClientData({ launched_platform: brokerObj.id + ' - ' + platformId });

            return { confirmed: true, url: data.url };
          } else {
            return { confirmed: false, url: '' };
          }
        })
        .catch(function (reason) {
          btToastrService.error(reason.message, 'System');
          return { confirmed: false, url: '' };
        });
    }

    /**
     *
     * @param {btTradingPlatformObject} brokerObj - platform object
     * @param {string} [variantId] - variant identifier
     * @return {*}
     */
    function getVariant(brokerObj, variantId) {
      return brokerObj.variants && variantId
        ? brokerObj.variants.filter(function (variant) {
            return variant.id === variantId;
          })[0]
        : brokerObj;
    }

    /**
     * Returns platform url.
     *
     * @param {btTradingPlatformObject} brokerObj - platform
     * @param {string|null} variantId - variant
     * @return {string}
     */
    function getPlatformUrl(brokerObj, variantId) {
      var variant = getVariant(brokerObj, variantId);
      return variant.url;
    }

    /**
     * Disconnects trading platform.
     *
     * @return {angular.IPromise<*>}
     */
    function disconnect() {
      return confirmDisconnect().then(function (confirmed) {
        if (confirmed) {
          var brokerName = gStatus.broker.name;
          clearStatus();
          btSharedData.shareData('platform:disconnect', {}, {});
          saveSettings(gStatus);
          btToastrService.success('You are now disconnected from ' + brokerName + '.', 'Trading Platform', {
            type: 'trade',
          });
        }
      });
    }

    /**
     * Confirms platform connection.
     *
     * @param {{name: string, url: string, external: boolean}} platform - platform object
     * @return {angular.IPromise<boolean>}
     */
    function confirmConnection(platform) {
      if (platform.external) {
        return $q.resolve(true);
      }

      var confirmPopup = $ionicPopup.confirm({
        title: 'Would you like to launch <b>' + platform.name + '</b> platform?',
        okText: 'Launch',
        cssClass: 'bt-trading-platform-confirmation',
        template:
          '<div class="bt-trading-platform-confirmation-body">' +
          'You are now launching the ' +
          platform.name +
          ' platform.<br>' +
          '(' +
          platform.url +
          ')<br><br>' +
          'In order to disconnect from ' +
          platform.name +
          ' and return to the previous page select ' +
          '"Disconnect Trading Platform" in the menu (see image below).' +
          '<div class="bt-image"><img src="images/trading-platform-tutorial.png" alt="Tutorial" height="55"></div>' +
          gNote +
          '</div>',
      });

      return confirmPopup.then(function (res) {
        return !!res;
      });
    }

    /**
     *
     * @param {string} title - title
     * @param {string[]} text - text
     * @param {string} button - button text
     * @return {angular.IPromise<*>}
     */
    function showMoreInfo(title, text, button) {
      var confirmPopup = $ionicPopup.confirm({
        title: title,
        okText: button,
        cssClass: 'bt-trading-platform-confirmation',
        template: '<div class="bt-trading-platform-confirmation-body"><p>' + text.join('</p><p>') + '</p></div>',
      });

      return confirmPopup.then(function (res) {
        return !!res;
      });
    }

    /**
     * Confirms disconnection.
     *
     * @return {angular.IPromise<*>}
     */
    function confirmDisconnect() {
      var data = getVariant(gStatus.broker, gStatus.variant);

      var confirmPopup = $ionicPopup.confirm({
        title: 'Would you like to disconnect <b>' + data.name + '</b> platform?',
        okText: 'Disconnect',
        cssClass: 'bt-trading-platform-confirmation',
        template:
          '<div class="bt-trading-platform-confirmation-body">' +
          'You are now disconnecting from ' +
          data.name +
          '.' +
          gNote +
          '</div>',
      });

      return confirmPopup.then(function (res) {
        return !!res;
      });
    }

    /**
     *
     * @return {btTradingPlatformObject[]}
     */
    function getPlatforms() {
      return gPlatforms.filter(function (value) {
        return value.enabled;
      });
    }

    /**
     *
     * @param {*} id - ?
     * @return {btTradingPlatformObject}
     */
    function getPlatformById(id) {
      return gPlatforms.filter(function (value) {
        return value.id === id;
      })[0];
    }

    // /**
    //  *
    //  * @param {*} platform - ?
    //  * @return {*}
    //  */
    // function getBrokers(platform) {
    //   if (gBrokers[platform]) {
    //     return gBrokers[platform].filter(function (value) {
    //       return value.enabled;
    //     });
    //   } else {
    //     return [];
    //   }
    // }

    /**
     *
     * @param {*} callback - ?
     */
    function addDisconnectListener(callback) {
      btSharedData.addCallback('platform:disconnect', callback);
    }
  }
})();
