/**
 * Created by Sergey Panpurin on 10/24/17.
 */

(function btTooltipClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive shows tooltip.
     *
     * @ngdoc directive
     * @name btTooltip
     * @memberOf ecapp
     * @scope
     */
    .directive('btTooltip', [
      '$templateCache',
      /**
       *
       * @param {angular.ITemplateCacheService} $templateCache
       * @return {any}
       */
      function ($templateCache) {
        return {
          restrict: 'E',
          transclude: true,
          template: $templateCache.get('directives/common/bt-tooltip.html'),
          scope: {
            text: '@?',
            link: '@?',
            icon: '@?',
          },
          controller: [
            '$scope',
            function ($scope) {
              $scope.hasLink = !!$scope.link;
              $scope.hasText = !!$scope.text;
              $scope.icon = 'ion-help-circled';
            },
          ],
          link: {
            post: function (scope, element) {
              element.bind('click', function () {
                element.find('.tooltip-text').toggleClass('show-tooltip-text');
              });
            },
          },
        };
      },
    ]);
})();
