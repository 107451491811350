/**
 * Created by Sergey Panpurin on 1/13/2019.
 */

(function btTestMarkerClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive display marker of test application.
     *
     * @ngdoc directive
     * @name btTestMarker
     * @memberOf ecapp
     */
    .directive('btTestMarker', btTestMarker);

  btTestMarker.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btTestMarker($templateCache) {
    return {
      restrict: 'E',
      template: $templateCache.get('directives/common/bt-test-marker.html'),
    };
  }
})();
