/**
 * Created by Sergey Panpurin on 3/14/18
 */

// @ts-check
(function btBrokersServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btBrokersService', btBrokersService);

  btBrokersService.$inject = [
    'btSymbolPrefix',
    'btSettingsService',
    'btInstrumentsService',
    'btTradeStationApiService',
    'btSampleApiService',
    'btOandaApiService',
    'btCTraderApiService',
    'btTradierApiService',
    'btLinkDataServiceApiService',
    'btMarketApiService',
  ];

  /**
   * This service contains the list of supported brokers.
   *
   * @ngdoc service
   * @name btBrokersService
   * @memberOf ecapp
   * @param {ecapp.ISymbolPrefix} btSymbolPrefix
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.ITradeStationApiService} btTradeStationApiService
   * @param {ecapp.ISampleApiService} btSampleApiService
   * @param {ecapp.IOandaApiService} btOandaApiService
   * @param {ecapp.ICTraderApiService} btCTraderApiService
   * @param {ecapp.ITradierApiService} btTradierApiService
   * @param {ecapp.ILinkDataServiceApiService} btLinkDataServiceApiService
   * @param {ecapp.IMarketApiService} btMarketApiService
   * @return {ecapp.IBrokersService}
   */
  function btBrokersService(
    btSymbolPrefix,
    btSettingsService,
    btInstrumentsService,
    btTradeStationApiService,
    btSampleApiService,
    btOandaApiService,
    btCTraderApiService,
    btTradierApiService,
    btLinkDataServiceApiService,
    btMarketApiService
  ) {
    console.log('Running btBrokersService');

    /**
     * Information about supported brokers
     * @type {Record<string, ecapp.IBrokerInfo>}
     */
    var gBrokers = {
      tradier: {
        visible: false,
        name: 'tradier',
        displayName: 'Tradier',
        logo: 'images/brokers/tradier-big.png',
        service: btTradierApiService,
        defaultTradeSize: 1,
        hasRegistration: true,
        support: {
          tradeCard: false,
          orders: false,
          positions: false,
          bidAndAsk: false,
          trading: false,
          margin: true,
        },
        defaultCharts: [],
        defaultWatchlist: ['SPY', 'AAPL', 'AMZN', 'GOOG', 'NFLX', 'TSLA', 'FB', 'TWTR'],
      },
      tradestation: {
        visible: true,
        name: 'tradestation',
        displayName: 'Trade Station',
        logo: 'images/brokers/tradestation-big.png',
        service: btTradeStationApiService,
        defaultTradeSize: 1,
        hasRegistration: false,
        support: {
          tradeCard: false,
          orders: true,
          positions: true,
          bidAndAsk: true,
          trading: true,
          margin: false,
        },
        defaultCharts: ['TRADESTATION:MSFT', 'TRADESTATION:AMZN', 'TRADESTATION:BTCUSD', 'TRADESTATION:SPY'],
        defaultWatchlist: ['MSFT', 'AMZN', 'BTCUSD', 'SPY'],
      },
      oanda: {
        visible: false,
        name: 'oanda',
        displayName: 'OANDA',
        logo: 'images/brokers/oanda-big.png',
        service: btOandaApiService,
        defaultTradeSize: 100,
        hasRegistration: true,
        support: {
          tradeCard: true,
          orders: true,
          positions: true,
          bidAndAsk: true,
          trading: true,
          margin: true,
        },
        defaultCharts: [],
        defaultWatchlist: ['SPX500_USD', 'EUR_USD', 'USD_CAD', 'GBP_USD', 'BCO_USD', 'XAU_USD'],
      },
      // Temporary hide cTrader from brokers list
      // ctrader: {
      //   visible: true,
      //   name: 'ctrader',
      //   displayName: 'cTrader',
      //   logo: 'images/brokers/ctrader-big.png',
      //   service: btCTraderApiService,
      //   defaultTradeSize: 1,
      //   hasRegistration: false,
      //   support: {
      //    tradeCard: false,
      //    orders: false,
      //    positions: false,
      //    bidAndAsk: false,
      //    trading: false,
      //    margin: true,
      //   },
      //   defaultCharts: [],
      //   defaultWatchlist: ['SPY', 'URR', 'USO', 'GLD']
      // },
      // cqg: {
      //   visible: true,
      //   name: 'cqg',
      //   displayName: 'CQG',
      //   logo: 'images/brokers/cqg-big.png',
      //   service: btSampleApiService,
      //   defaultTradeSize: 1,
      //   hasRegistration: false,
      //   support: {
      //    tradeCard: false,
      //    orders: false,
      //    positions: false,
      //    bidAndAsk: false,
      //    trading: false,
      //    margin: true,
      //   },
      //   defaultCharts: [],
      //   defaultWatchlist: []
      // },
      ib: {
        visible: false,
        name: 'ib',
        displayName: 'Interactive Brokers',
        logo: 'images/brokers/ib-big.png',
        service: btSampleApiService,
        defaultTradeSize: 1,
        hasRegistration: false,
        support: {
          tradeCard: false,
          orders: false,
          positions: false,
          bidAndAsk: false,
          trading: false,
          margin: true,
        },
        defaultCharts: [],
        defaultWatchlist: [],
      },
      lds: {
        visible: false,
        name: 'lds',
        displayName: 'LinkDataService',
        logo: 'images/logo/BetterTrader-logo-white.png',
        service: btLinkDataServiceApiService,
        defaultTradeSize: 200,
        hasRegistration: false,
        support: {
          tradeCard: true,
          orders: false,
          positions: false,
          bidAndAsk: false,
          trading: false,
          margin: true,
        },
        defaultCharts: [],
        defaultWatchlist: ['LLS', 'MARS', 'MIDSWT', 'WTS', 'MEH'],
      },
      default: {
        visible: false,
        name: 'default',
        displayName: btSettingsService.isLinkDataService() ? '' : 'BetterTrader',
        logo: 'images/logo/BetterTrader-logo-white.png',
        service: btSettingsService.isLinkDataService() ? btLinkDataServiceApiService : btMarketApiService,
        defaultTradeSize: 200,
        hasRegistration: false,
        support: {
          tradeCard: true,
          orders: false,
          positions: false,
          bidAndAsk: false,
          trading: false,
          margin: true,
        },
        defaultCharts: btSettingsService.isLinkDataService()
          ? ['LDS:MEH:M1', 'LDS:WTS:M1', 'LDS:MIDSWT:M1', 'LDS:LLS:M1']
          : ['SPX500_USD', 'EUR_USD', 'USD_CAD', 'GBP_USD'].map(function (symbol) {
              return btSymbolPrefix.FOREX + ':' + symbol;
            }),
        defaultWatchlist: btSettingsService.isLinkDataService()
          ? ['LLS', 'MARS', 'MIDSWT', 'WTS', 'MEH']
          : ['SPX500_USD', 'EUR_USD', 'USD_CAD', 'GBP_USD', 'BCO_USD', 'XAU_USD'],
      },
    };

    return {
      getBrokers: getBrokers,
    };

    /**
     * This function returns object with information about supported brokers (key = broker's id)
     * @return {Record<string, ecapp.IBrokerInfo>}
     */
    function getBrokers() {
      return gBrokers;
    }
  }
})();
