/**
 * Created by Orly on 08/01/19.
 */

(function btHelp() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive displays button to get help
     *
     * @ngdoc directive
     * @name btHelp
     * @memberOf ecapp
     * @scope
     */
    .directive('btHelp', btHelp);

  btHelp.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btHelp($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('directives/common/bt-help-center.html'),
      controller: [
        '$scope',
        '$templateCache',
        '$http',
        '$state',
        'btSettings',
        'btMarketing',
        'btSharedData',
        'btPopoverService',
        'btSettingsService',
        'btWindowsSizeService',
        btHelpController,
      ],
    };
  }

  /**
   *
   * @param {*} $scope
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {angular.IHttpService} $http
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.ISettings} btSettings
   * @param {ecapp.IMarketing} btMarketing
   * @param {ecapp.ISharedData} btSharedData
   * @param {ecapp.IPopoverService} btPopoverService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IWindowsSizeService} btWindowsSizeService
   */
  function btHelpController(
    $scope,
    $templateCache,
    $http,
    $state,
    btSettings,
    btMarketing,
    btSharedData,
    btPopoverService,
    btSettingsService,
    btWindowsSizeService
  ) {
    var gBaseUrl = btSettings.BT_BACKEND_URL + '/v1/help-center';
    // Getting articles for current state and tab
    var gSettings = btSettingsService.getLocalSettings();
    var gTab = gSettings.get('dashboard.tab');
    var gState = $state.current.name;

    activate();

    /**
     * @typedef {object} btHelpContent
     * @property {btHelpCategory} search - articles connected to user search
     * @property {btHelpCategory} mobile - articles connected to mobile view
     * @property {btHelpCategory} desktop - articles connected to desktop view
     * @property {btHelpCategory} general - general articles
     */

    /**
     * @typedef {object} btHelpCategory
     * @property {?string} title  - ?
     * @property {btHelpArticle[]} articles - ?
     */

    /**
     * @typedef {object} btHelpArticle
     * @property {string} type - type of article: article, video, case-study
     * @property {string} title - article title
     * @property {?string} link - article link
     * @property {?string} content - article content
     * @property {?string} icon - article icon
     */

    /**
     * @typedef {object} btHelpReadingArticle
     * @property {string} title - article title
     * @property {string} content - article content
     * @property {boolean} opened - article status
     */

    /**
     * Controller constructor
     */
    function activate() {
      // Function declarations
      $scope.closeTutorial = closeTutorial;
      $scope.openPopover = openPopover;
      $scope.closePopover = closePopover;
      $scope.openIntercom = openIntercom;
      $scope.goToInfoHub = goToInfoHub;
      $scope.readArticle = readArticle;
      $scope.closeArticle = closeArticle;
      $scope.startLoading = startLoading;
      $scope.stopLoading = stopLoading;
      $scope.popOutArticle = popOutArticle;
      $scope.searchArticle = searchArticle;
      $scope.clearSearch = clearSearch;

      /**
       * List of all the articles in help center
       * @type {btHelpContent}
       */
      $scope.helpArticles = {
        search: {
          title: 'found articles',
          articles: [],
        },
        mobile: {
          title: null,
          articles: [],
        },
        desktop: {
          title: null,
          articles: [],
        },
        general: {
          title: 'Most Popular',
          articles: [
            {
              type: 'video',
              icon: 'ion-social-youtube',
              title: 'Introduction',
              link: 'https://www.youtube.com/watch?v=cjKTH9WlEgo',
            },
            {
              type: 'video',
              icon: 'ion-social-youtube',
              title: 'Benefits of the Pro Plan',
              link: 'https://youtu.be/oDpTinSIYpE',
            },
            {
              type: 'video',
              icon: 'ion-social-youtube',
              title: 'Intro to Markets Tab and WatchList',
              link: 'https://youtu.be/AVxYIisHB8o',
            },
            {
              type: 'video',
              icon: 'ion-social-youtube',
              title: 'How to Master Economic Events',
              link: 'https://youtu.be/WpBuj1OpLq0',
            },
            {
              type: 'video',
              icon: 'ion-social-youtube',
              title: 'Always be Notified about Economic Events',
              link: 'https://youtu.be/PiboYjDjsv0',
            },
            {
              type: 'video',
              icon: 'ion-social-youtube',
              title: 'Voice Customized Twitter Notifications',
              link: 'https://youtu.be/RnnaIc96TUM',
            },
            {
              type: 'video',
              icon: 'ion-social-youtube',
              title: 'Barometer - All your Trading Interests in one Place',
              link: 'https://youtu.be/ZAyInUCrPFU',
            },
            {
              type: 'video',
              icon: 'ion-social-youtube',
              title: 'Charts - Learn the Benefits of the Charts',
              link: 'https://youtu.be/FNJrWe7TCyo',
            },
            {
              type: 'video',
              icon: 'ion-social-youtube',
              title: 'Full Explanation on the BackTester',
              link: 'https://youtu.be/NvGT3PJxKBc',
            },

            {
              type: 'article',
              icon: 'ion-university',
              title: 'Case studies - Read About Traders Who Utilize BetterTrader',
              link: 'https://bettertrader.co/case-studies/',
            },

            {
              type: 'article',
              icon: 'ion-university',
              title: 'How to properly use the trade ideas',
              link: 'https://help.bettertrader.co/must-read-collection/how-to-properly-use-the-trade-ideas',
            },
            {
              type: 'article',
              icon: 'ion-university',
              title: 'Integration with brokers and trading platforms',
              link: 'https://help.bettertrader.co/must-read-collection/integration-with-brokers-and-trading-platforms',
            },
            {
              type: 'video',
              icon: 'ion-social-youtube',
              title: 'Basic Video Tutorials [New]',
              link: 'https://www.youtube.com/playlist?list=PLMaN-InBLbF1f9p9_AXQqnnFnc4E7fc9w',
            },
            {
              type: 'video',
              icon: 'ion-social-youtube',
              title: 'Advanced Video Tutorials [New]',
              link: 'https://www.youtube.com/playlist?list=PLMaN-InBLbF3SXhKmHVqsUHwSVwC4aoVq',
            },
          ],
        },
      };

      // Variable declarations
      /** @type {btPopoverObject} */
      $scope.popover = btPopoverService.init($templateCache.get('modals/bt-help-popover.html'), {
        scope: $scope,
        interactive: true,
      });

      // Shows if list of articles is being loaded
      $scope.loading = false;

      /**
       * Opened article
       * @type {btHelpReadingArticle}
       */
      $scope.article = {
        title: '',
        content: '',
        opened: false,
      };

      getHelpCenterContent(gTab, 'desktop');
      getHelpCenterContent(gState, 'mobile');

      // tutorial
      $scope.showTutorial =
        gSettings.get('tutorial.helpCenter') === undefined ? true : gSettings.get('tutorial.helpCenter');

      if ($scope.showTutorial) gSettings.set('tutorial.helpCenter', false);

      // tutorial end

      // The rest of the activation
      $scope.$on('$destroy', onDestroy);

      // Get new articles when state changes
      $scope.$on('$stateChangeSuccess', function (event, toState) {
        // noinspection JSUnresolvedVariable
        gState = toState.name;
        getHelpCenterContent(gState, 'mobile');
      });

      // Get new articles when tab changes
      $scope.$on('tabChanged', function (event, data) {
        // noinspection JSUnresolvedVariable
        gTab = data.view;
        getHelpCenterContent(gTab, 'desktop');
      });
    }

    /**
     *
     * @param {angular.IAngularEvent} $event
     */
    function closeTutorial($event) {
      $scope.showTutorial = false;
      $event.stopPropagation();
    }

    /**
     * This function opens help center
     */
    function openPopover() {
      $scope.popover.show();
    }

    /**
     * This function closes help center
     */
    function closePopover() {
      $scope.popover.hide();
      closeArticle();
    }

    /**
     * This function is controller destructor
     */
    function onDestroy() {
      $scope.popover.remove();
    }

    /**
     * This function opens intercom
     */
    function openIntercom() {
      btMarketing.askQuestion();
    }

    /**
     * This function relocates to InfoHub view
     */
    function goToInfoHub() {
      btSharedData.openDashboardTabByViewName('dashboard-info-hub').catch(function (error) {
        console.error(error);
      });
    }

    /**
     * This function opens content of the article
     * @param {btHelpArticle} article
     */
    function readArticle(article) {
      if (article.content) {
        $scope.article = {
          title: article.title,
          content: article.content,
          opened: true,

          // REMOVE
          // type: article.type,
          // state: article.state
        };
      }
    }

    /**
     *
     * @param {*} article
     */
    function popOutArticle(article) {
      btWindowsSizeService.openPopOutWindow(
        article.title,
        $state.href(
          'ecapp.help-center.article',
          {
            type: article.type,
            state: article.state,
            title: article.title,
          },
          { absolute: false }
        )
      );
    }

    /**
     * This function calls actions on load start
     */
    function startLoading() {
      $scope.loading = true;
    }

    /**
     * This function calls actions on load end
     */
    function stopLoading() {
      $scope.loading = false;
    }

    /**
     * This function closes content of the article
     */
    function closeArticle() {
      $scope.article = {};
      // Update list of articles
      getHelpCenterContent(gTab, 'desktop');
      getHelpCenterContent(gState, 'mobile');
    }

    /**
     * This function defines list of articles
     * @param {string} state - current state or tab
     * @param {string} type - 'mobile' for state and 'desktop' for tab
     */
    function getHelpCenterContent(state, type) {
      if (!state) return;

      startLoading();
      var options = { headers: { 'Content-Type': 'application/json' }, params: { state: state, type: type } };

      $http
        .get(gBaseUrl + '/articles', options)
        .then(function success(response) {
          //REMOVE IT'S FOR TESTING OPENING ARTICLES IN NEW WINDOW
          response.data.articles.forEach(function (article) {
            // article.type = type;
            // article.state = state;
            article.icon = getArticleIcon(article);
          });

          // END REMOVE
          $scope.helpArticles[type].articles = response.data.articles;
          $scope.helpArticles[type].title = response.data.sectionName;
          stopLoading();
        })
        .catch(function error(response) {
          console.log('btHelpCenter: error', state, type, response);
          $scope.helpArticles[type].articles = [];
          stopLoading();
        });
    }

    /**
     * This function gets articles by search words
     * @param {string} searchInput
     */
    function searchArticle(searchInput) {
      if (searchInput !== '') {
        startLoading();
        var options = { headers: { 'Content-Type': 'application/json' }, params: { text: searchInput } };

        $http
          .get(gBaseUrl + '/search', options)
          .then(function success(response) {
            void response;
            stopLoading();
            //show found articles
          })
          .catch(function error(response) {
            console.log('btHelpCenter: error', response);
            stopLoading();
            $scope.helpArticles.search.articles = [];
          });
      } else {
        stopLoading();
        $scope.helpArticles.search.articles = [];
      }
    }

    /**
     * This function clears search field
     */
    function clearSearch() {
      $scope.searchInput = '';
    }

    /**
     * This function returns article icon.
     * @param {btHelpArticle} article - article object
     * @return {string}
     */
    function getArticleIcon(article) {
      if (article.icon) {
        return article.icon;
      } else {
        switch (article.type) {
          case 'article':
            return 'ion-university';
          case 'video':
            return 'ion-social-youtube';
          case 'case-study':
            return 'ion-erlenmeyer-flask';
          default:
            return 'ion-document-text';
        }
      }
    }
  }
})();
