/**
 * Created by Sergey Panpurin on 8/13/2017.
 */

(function btEventCardClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive ???
     *
     * @ngdoc directive
     * @name btEventCardTradingInfo
     * @memberOf ecapp
     */
    .directive('btEventCardTradingInfo', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/event-card/trading-info.html'),
          controller: [
            '$scope',
            '$rootScope',
            'btInsightsService',
            'btTradeIdeasService',
            btEventCardTradingInfoController,
          ],
        };
      },
    ]);

  /**
   *
   * @param {*} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.IInsightsService} btInsightsService
   * @param {ecapp.ITradeIdeasService} btTradeIdeasService
   * @return {angular.IController}
   */
  function btEventCardTradingInfoController($scope, $rootScope, btInsightsService, btTradeIdeasService) {
    $scope.isLoading = true;
    $scope.hasError = false;

    var row = $scope.a;

    // trading card connected to trading insights
    if (row.showTradingInsights) {
      if ($rootScope.lastOpenedEventRow != null && $rootScope.lastOpenedEventRow !== row) {
        $rootScope.lastOpenedEventRow.openAccordionInsights = false;
        $rootScope.lastOpenedEventRow.openAccordionBars = false;
        $rootScope.lastOpenedEventRow.openAccordionTrading = false;
        $scope.heightSize($rootScope.lastOpenedEventRow);
      }
      $rootScope.lastOpenedEventRow = row;
    }

    return btInsightsService
      .getInsights(row)
      .then(function onSuccess() {
        $scope.isLoading = false;
        $scope.hasError = false;
        $scope.tradeIdeas = row.tradingInsights.map(function (t) {
          return btTradeIdeasService.convertToTradeIdea(t);
        });
      })
      .catch(function onFail(reason) {
        $scope.hasError = true;
        $scope.errorMessage = reason.toString();
      });
  }
})();
