/**
 * Created by Sergey Panpurin on 08/24/2017.
 */

(function btMarketSenseButtonClosure() {
  'use strict';

  angular
    .module('ecapp')
    .directive('btMarketSenseButton', btMarketSenseButton)
    .controller('btMarketSenseButtonController', btMarketSenseButtonController);

  btMarketSenseButton.$inject = ['$templateCache'];

  /**
   * This directive display MarketSense button.
   *
   * @ngdoc directive
   * @name btMarketSenseButton
   * @memberOf ecapp
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btMarketSenseButton($templateCache) {
    return {
      restrict: 'E',
      scope: {
        marketData: '<instrument',
      },
      template: $templateCache.get('directives/instruments/bt-market-sense-button.html'),
      controller: 'btMarketSenseButtonController',
    };
  }

  btMarketSenseButtonController.$inject = [
    '$scope',
    '$rootScope',
    '$ionicModal',
    'btMarketWidgetsService',
    'btToastrService',
    'btEventEmitterService',
    '$templateCache',
  ];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ionic.IModalService} $ionicModal
   * @param {ecapp.IMarketWidgetsService} btMarketWidgetsService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IEventEmitterService} btEventEmitterService
   * @param {angular.ITemplateCacheService} $templateCache
   */
  function btMarketSenseButtonController(
    $scope,
    $rootScope,
    $ionicModal,
    btMarketWidgetsService,
    btToastrService,
    btEventEmitterService,
    $templateCache
  ) {
    var gIsProcessing = false;

    var hourInMs = 60 * 60 * 1000;

    $scope.durations = [
      { id: '1H', name: '1 Hour', time: hourInMs },
      { id: '6H', name: '6 Hour', time: 6 * hourInMs },
      { id: '12H', name: '12 Hours', time: 12 * hourInMs },
      { id: '1D', name: '1 Day', time: 24 * hourInMs },
      { id: '1W', name: '1 Week', time: 24 * 7 * hourInMs },
      { id: '1M', name: '1 Month', time: 30 * 24 * hourInMs },
      { id: '3H', name: '3 Months', time: 3 * 30 * 24 * hourInMs },
      { id: 'F', name: 'Forever', time: 0 },
    ];

    $scope.sensitivitiesOptions = [
      { id: 0, name: '0.0', sense: 0.0 },
      { id: 1, name: '0.1', sense: 0.1 },
      { id: 2, name: '0.2', sense: 0.2 },
      { id: 3, name: '0.3', sense: 0.3 },
      { id: 4, name: '0.4', sense: 0.4 },
      { id: 5, name: '0.5', sense: 0.5 },
      { id: 6, name: '0.6', sense: 0.6 },
      { id: 7, name: '0.7', sense: 0.7 },
      { id: 8, name: '0.8', sense: 0.8 },
      { id: 9, name: '0.9', sense: 0.9 },
      { id: 10, name: '1.0', sense: 1.0 },
    ];

    $scope.sides = [
      { id: 'long', name: 'Long', class: 'long' },
      { id: 'short', name: 'Short', class: 'short' },
    ];

    // set default values
    $scope.hasMarketSense = false;

    $scope.callbackMarketSense = callbackMarketSense;
    $scope.changeSide = changeSide;
    $scope.delete = deleteMarketSense;
    $scope.submit = submitMarketSenseWrapped;
    $scope.openModal = openModal;
    $scope.closeModal = closeModal;

    btMarketWidgetsService.updateMinSense($scope.marketData.instrument.brokerName);

    setDefaultSettings();

    // turn on MarketSense after order submission
    var eventName = 'marketSense:' + $scope.settings.instrument + ':' + $scope.settings.broker + ':add';
    btEventEmitterService.addListener(eventName, function onEvent(initial) {
      if ($scope.hasMarketSense === false) {
        _initMarketSense(initial);
      }
    });

    // initialize MarketSense
    btMarketWidgetsService
      .initMarketSense($scope.settings.instrument, $scope.settings.broker, $rootScope.currentUser.id)
      .then(_initMarketSense);

    // prepare modal view
    $scope.modal = $ionicModal.fromTemplate($templateCache.get('directives/instruments/bt-market-sense-dialog.html'), {
      scope: $scope,
      animation: 'slide-in-up',
    });

    $scope.$on('$destroy', onDestroy);

    /**
     *
     */
    function onDestroy() {
      if ($scope.modal !== undefined) {
        $scope.modal.remove();
      }
    }

    /**
     * Initialize MarketSense
     * @param {btMarketSenseClientObject} initial - MarketSense Object
     * @private
     */
    function _initMarketSense(initial) {
      if (initial !== null) {
        $scope.hasMarketSense = true;
        // $scope.settings = initial;
        $scope.settings.id = initial.id;
        $scope.settings.instrument = initial.instrument;
        $scope.settings.broker = initial.broker;
        $scope.settings.displayName = initial.displayName;
        $scope.settings.price = initial.price;
        $scope.settings.startPrice = initial.startPrice;
        // $scope.settings.userId = initial.userId;
        $scope.settings.sense = initial.sensitivity.toString();

        $scope.settings.duration = $scope.durations.filter(function filterFunc(t) {
          return t.id === initial.duration;
        })[0];
        $scope.settings.side = $scope.sides.filter(function filterFunc(t) {
          return t.id === initial.side;
        })[0];

        $scope.settings.isUp = $scope.settings.side.id === 'long';
        $scope.realSettings = JSON.parse(JSON.stringify($scope.settings));
      }
    }

    /**
     * Set default settings
     */
    function setDefaultSettings() {
      $scope.hasPIP = false;
      $scope.hasPercent = false;

      if ($scope.marketData.instrument.pip) {
        $scope.pip = $scope.marketData.instrument.pip;
        $scope.hasPIP = true;
      }

      if ($scope.marketData.instrument.brokerName === 'tradier') {
        $scope.hasPIP = false;
        $scope.hasPercent = true;
      }

      $scope.sensitivities = btMarketWidgetsService.getSensitivities(
        $scope.marketData.priceData.priceValue,
        $scope.marketData.priceData.decimalRound
      );

      $scope.precision = $scope.sensitivities.precision;

      $scope.settings = {
        instrument: $scope.marketData.instrument.brokerSymbol,
        broker: $scope.marketData.instrument.brokerName,
        displayName: $scope.marketData.instrument.displayName,
        duration: $scope.durations[7],
        minSense: $scope.sensitivities.min.toString(),
        maxSense: $scope.sensitivities.max.toString(),
        sense: $scope.sensitivities.default.toString(),
        side: $scope.sides[0],
        isUp: true,
        userId: $rootScope.currentUser.id,
      };

      $scope.realSettings = JSON.parse(JSON.stringify($scope.settings));
    }

    /**
     * Prepare market sense settings
     * @param {btMarketSenseSettings} settings
     * @return {btMarketSenseObject}
     */
    function prepareObject(settings) {
      return {
        id: settings.id,
        instrument: settings.instrument,
        broker: settings.broker,
        displayName: settings.displayName,
        duration: settings.duration.id,
        sensitivity: parseFloat(parseFloat(settings.sense).toFixed($scope.precision)),
        side: settings.side.id,
        price: settings.price,
        userId: settings.userId,
      };
    }

    /**
     * Callback connected to bt-star-button to react on select event. Just open modal view.
     * Status connected to hasMarketSense variable.
     * @param {String} data - instrument name
     * @param {Boolean} checked - market sense status
     */
    function callbackMarketSense(data, checked) {
      void data;
      void checked;
      $scope.openModal();
    }

    /**
     * Change trade side
     */
    function changeSide() {
      if ($scope.settings.isUp === false) {
        $scope.settings.side = $scope.sides[1];
      } else {
        $scope.settings.side = $scope.sides[0];
      }
    }

    /**
     * Delete market sense. Connected to delete button.
     * @param {String} id - market sense id
     */
    function deleteMarketSense(id) {
      if (id !== undefined) {
        btMarketWidgetsService
          .deleteMarketSense(id)
          .then(function onSuccess() {
            btToastrService.info('turned OFF.', 'MarketSense for ' + $scope.marketData.instrument.displayName, {
              type: 'market',
            });
            setDefaultSettings();
            $scope.hasMarketSense = false;
          })
          .catch(function onFail(error) {
            if (error && error.message)
              btToastrService.error(error.message, $scope.marketData.instrument.displayName, { type: 'market' });
          });
      }

      $scope.modal.hide();
    }

    /**
     *
     * @param {*} settings
     */
    function submitMarketSenseWrapped(settings) {
      if (settings.id) {
        submitMarketSense(settings);
      } else {
        $scope.modal.hide();
        btMarketWidgetsService
          .hasMarketSenseCapacity(settings)
          .then(function () {
            $scope.modal.show();
            submitMarketSense(settings);
          })
          .catch(function (reason) {
            $scope.modal.show();
            btToastrService.error(reason.message, $scope.marketData.instrument.displayName, { type: 'market' });
          });
      }
    }

    /**
     * Create or update market sense. Connected to submit/change button.
     * @param {btMarketSenseSettings} settings - market sense settings
     */
    function submitMarketSense(settings) {
      // add necessary data: price and user id
      settings.price = $scope.marketData.priceData.priceValue;

      // create or update market sense
      if ($scope.hasMarketSense === false) {
        btMarketWidgetsService.createMarketSense(prepareObject(settings)).then(onSuccess).catch(onFail);
      } else {
        btMarketWidgetsService.updateMarketSense(prepareObject(settings)).then(onSuccess).catch(onFail);
      }

      $scope.modal.hide();

      /**
       *
       * @param {*} data
       */
      function onSuccess(data) {
        if (data.id) {
          settings.id = data.id;
        }

        $scope.hasMarketSense = true;
        $scope.realSettings = JSON.parse(JSON.stringify($scope.settings));
        btToastrService.info('turned ON.', 'MarketSense for ' + $scope.marketData.instrument.displayName, {
          type: 'market',
        });
      }

      /**
       *
       * @param {*} error
       */
      function onFail(error) {
        if (error && error.message)
          btToastrService.error(error.message, $scope.marketData.instrument.displayName, { type: 'market' });
      }
    }

    /**
     * Just open modal view
     */
    function openModal() {
      if (!gIsProcessing) {
        gIsProcessing = true;
        $scope.modal
          .show()
          .catch(function (reason) {
            btToastrService.error(reason.message, $scope.marketData.instrument.displayName, { type: 'market' });
          })
          .finally(function () {
            gIsProcessing = false;
          });
      }
    }

    /**
     * Just close modal view
     */
    function closeModal() {
      $scope.settings = JSON.parse(JSON.stringify($scope.realSettings));
      $scope.modal.hide();
    }
  }
})();
