/**
 * Created by yatree on 17/08/16.
 */

(function btStarButtonClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive display star which can be checked.
     *
     * @ngdoc directive
     * @name btStarButton
     * @memberOf ecapp
     */
    .directive('btStarButton', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/common/bt-star-button.html'),
          scope: {
            iconFollow: '@',
            iconUnfollow: '@',
            argument: '=',
            clickFunction: '&',
            checked: '=',
            label: '@?',
          },
          controller: ['$scope', btStarButtonController],
        };
      },
    ]);

  /**
   *
   * @param {*} $scope
   */
  function btStarButtonController($scope) {
    $scope.label = $scope.label || '';
    $scope.onClick = onClick;

    $scope.$watch('checked', onChange);

    /**
     *
     */
    function onClick() {
      $scope.clickFunction({ data: $scope.argument, checked: !$scope.checked });
    }

    /**
     *
     * @param {*} newValue
     * @param {*} oldValue
     */
    function onChange(newValue, oldValue) {
      // console.log('btStar - Watched - status:', $scope.checked);
      if (newValue !== oldValue && oldValue !== undefined) {
        // skip
      }
    }
  }
})();
