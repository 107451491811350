/**
 * Created by Sergey Panpurin on 1/17/2017.
 */

(function btStrengthClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive is attribute directive which gives the correct color class to its element
     *
     * @ngdoc directive
     * @name btStrength
     * @memberOf ecapp
     */
    .directive('btStrength', btStrength)
    .controller('btStrengthController', btStrengthController);

  btStrength.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btStrength($templateCache) {
    return {
      restrict: 'E',
      scope: { strength: '=' },
      template: $templateCache.get('directives/common/bt-strength.html'),
      controller: 'btStrengthController',
    };
  }

  btStrengthController.$inject = ['$scope', '$state', 'btToastrService', 'btRestrictionService'];

  /**
   *
   * @param {*} $scope
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IRestrictionService} btRestrictionService
   */
  function btStrengthController($scope, $state, btToastrService, btRestrictionService) {
    $scope.showFullMsg = window.localStorage.getItem('showFullMsg') === 'true';
    $scope.activateMagnitude = activateMagnitude;

    /**
     *
     */
    function activateMagnitude() {
      if ($scope.strength.locked) {
        btRestrictionService
          .showUpgradePopup('release-magnitude')
          .then(function (status) {
            if (status === 'updated') {
              $state.reload();
            }
          })
          .catch(function (reason) {
            btToastrService.error(reason.message, 'Subscription', { type: 'settings' });
          });
      }
    }
  }
})();
