/**
 * Created by Stas on 3/4/20
 */

(function btSignificanceTooltipFilter() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * Returns significance tooltip
     *
     * @ngdoc filter
     * @name btCalendarDate
     * @memberOf ecapp
     */
    .filter('btSignificanceTooltip', btSignificanceTooltip);

  var gPercent = [95, 98, 99, 99.5];

  /**
   *
   * @return {angular.IFilterFunction}
   */
  function btSignificanceTooltip() {
    return function (value) {
      switch (value) {
        case 1:
        case 2:
        case 3:
        case 4:
          return 'During ' + gPercent[value - 1] + '% of the time, the market moved less than now.';
        default:
          return '';
      }
    };
  }
})();
