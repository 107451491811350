/**
 * Created by Itay on 6/26/2016.
 */

(function () {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive is a transclude directive which hold sub-header type items (such as related items, historic graph and more.
     *
     * @ngdoc directive
     * @name btSubHeader
     * @memberOf ecapp
     */
    .directive('btSubHeader', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/event-modal/bt-sub-header.html'),
          transclude: true,
          scope: {
            icon: '@',
            title: '@',
            hint: '@',
            class: '@',
          },
        };
      },
    ]);
})();
