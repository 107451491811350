/**
 * Created by Sergey Panpurin on 2/21/2018.
 */

// @ts-check
(function btLocalSettingsServiceClosure() {
  'use strict';

  angular
    .module('btUtils')
    /**
     * @ngdoc service
     * @name btLocalSettingsService
     * @memberOf ecapp
     * @description
     *  This *
     */
    .factory('btLocalSettingsService', btLocalSettingsService);

  btLocalSettingsService.$inject = [];

  /**
   *
   * @return {ecapp.ILocalSettingsService}
   */
  function btLocalSettingsService() {
    console.log('Running btLocalSettingsService');

    var gLocalSettings = null;

    /**
     *
     * @return {LocalSettings}
     */
    function getLocalSettings() {
      if (gLocalSettings === null) {
        gLocalSettings = new LocalSettings();
      }

      return gLocalSettings;
    }

    /**
     *
     * @param {*} value - ?
     * @return {*}
     */
    function isObject(value) {
      return typeof value === 'object' && value instanceof Object && value !== null && value.length === undefined;
    }

    /**
     *
     * @class
     */
    function LocalSettings() {
      this.settings = {};
      this.load();
    }

    LocalSettings.prototype.load = function () {
      try {
        var text = localStorage.getItem('btSettings');
        var settings = JSON.parse(text);
        if (settings !== null) {
          this.settings = settings;
        }
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    };

    LocalSettings.prototype.save = function () {
      try {
        var text = JSON.stringify(this.settings);
        localStorage.setItem('btSettings', text);
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    };

    /**
     *
     * @param {string} key - key
     * @return {any | undefined}
     */
    LocalSettings.prototype.get = function (key) {
      try {
        var items = key.split('.');
        var property = this.settings;
        for (var i = 0; i < items.length; i++) {
          if (i === items.length - 1) {
            return property[items[i]];
          }

          if (property[items[i]] === undefined) {
            property[items[i]] = {};
          }

          if (isObject(property[items[i]])) {
            property = property[items[i]];
          } else {
            console.error('Can\'t read "' + key + '". Item', items[i], 'is not a object (' + property[items[i]] + ')');
            return undefined;
          }
        }

        return undefined;
      } catch (e) {
        return undefined;
      }
    };

    /**
     *
     * @param {string} key - key
     * @param {any} value - value
     * @return {boolean | undefined}
     */
    LocalSettings.prototype.set = function (key, value) {
      try {
        var items = key.split('.');
        var property = this.settings;
        for (var i = 0; i < items.length; i++) {
          if (i === items.length - 1) {
            property[items[i]] = value;
            return this.save();
          }

          if (property[items[i]] === undefined) {
            property[items[i]] = {};
          }

          if (isObject(property[items[i]])) {
            property = property[items[i]];
          } else {
            console.error('Can\'t read "' + key + '". Item', items[i], 'is not a object (' + property[items[i]] + ')');
            return false;
          }
        }

        return false;
      } catch (e) {
        return false;
      }
    };

    /**
     *
     * @param {string} key - key
     * @return {any | undefined}
     */
    LocalSettings.prototype.delete = function (key) {
      try {
        var items = key.split('.');
        var property = this.settings;
        for (var i = 0; i < items.length; i++) {
          if (i === items.length - 1) {
            delete property[items[i]];
            return this.save();
          }

          if (property[items[i]] === undefined) {
            property[items[i]] = {};
          }

          if (isObject(property[items[i]])) {
            property = property[items[i]];
          } else {
            console.error('Can\'t read "', key, '". Item', items[i], 'is not a object (', property[items[i]], ')');
            return undefined;
          }
        }

        return property;
      } catch (e) {
        return undefined;
      }
    };

    //
    // function test() {
    //   var settings = getLocalSettings();
    //
    //   console.log('Begin Test Local Settings');
    //
    //   console.log('a=', settings.get('a'));
    //   settings.set('a', 2);
    //   console.log('a=', settings.get('a'));
    //
    //   console.log('a.b=', settings.get('a.b'));
    //   settings.set('a.b', 'sdf');
    //   console.log('a.b=', settings.get('a.b'));
    //
    //   console.log('c.b.a=', settings.get('c.b.a'));
    //   settings.set('c.b.a', 'test');
    //   console.log('c.b.a=', settings.get('c.b.a'));
    //
    //   console.log('End Test Local Settings');
    // }
    //
    // test();

    return {
      getLocalSettings: getLocalSettings,
    };
  }
})();
