/**
 * Created by Sergey Panpurin on 6/13/2018.
 */

(function btChartsSettingsControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btChartsSettingsController', btChartsSettingsController);

  btChartsSettingsController.$inject = [
    '$scope',
    'btToastrService',
    'btRestrictionService',
    'btDateService',
    'btChartsService',
  ];

  /**
   * Controller for voice assistant settings page.
   *
   * @ngdoc controller
   * @name ecapp.btChartsSettingsController
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IChartsService} btChartsService
   */
  function btChartsSettingsController($scope, btToastrService, btRestrictionService, btDateService, btChartsService) {
    console.log('Running btChartsSettingsController');

    $scope.wasChanged = false;
    $scope.prevSymbols = null;
    $scope.symbols = btChartsService.getSavedCharts();
    $scope.prevSymbols = JSON.parse(JSON.stringify($scope.symbols));

    // var notSortedListOfTimeZones = btShareScopeService.getListOfTimeZones();
    // $scope.timeZoneList = $filter('orderBy')(notSortedListOfTimeZones, 'offset');
    // $scope.timeZoneList = btDateService.getTradingViewTimeZones();
    // $scope.timezone = loadSavedTimezone();
    // $scope.prevTimezoneName = $scope.timezone.value.name;

    $scope.saveSettings = saveSettings;
    $scope.onChange = onChange;
    // $scope.onChangeTimezone = onChangeTimezone;

    $scope.$on('$destroy', onDestroy);

    /**
     *
     */
    function onDestroy() {
      console.log('Destroy btChartsSettingsController');
    }

    /**
     *
     */
    function onChange() {
      $scope.wasChanged = !isEqual($scope.symbols, $scope.prevSymbols);
    }

    // function onChangeTimezone() {
    //   console.log($scope.timezone.value);
    //   $scope.wasChanged = !($scope.timezone.value.name === $scope.prevTimezoneName);
    // }

    /**
     *
     * @param {*} symbols
     * @param {*} prevSymbols
     * @return {boolean|boolean}
     */
    function isEqual(symbols, prevSymbols) {
      /*return symbols.chart1 === prevSymbols.chart1 && symbols.chart2 === prevSymbols.chart2
        && symbols.chart3 === prevSymbols.chart3 && symbols.chart4 === prevSymbols.chart4;*/
      return (
        symbols[0] === prevSymbols[0] &&
        symbols[1] === prevSymbols[1] &&
        symbols[2] === prevSymbols[2] &&
        symbols[3] === prevSymbols[3]
      );
    }

    /**
     *
     */
    function saveSettings() {
      if (btRestrictionService.hasFeature('custom-charts')) {
        // btChartsService.setChartTimeZone($scope.timezone.value.name);
        btChartsService.setCurrentCharts($scope.symbols);
        btChartsService.saveCharts();
        btChartsService.restoreCharts();

        $scope.prevSymbols = JSON.parse(JSON.stringify($scope.symbols));
        $scope.wasChanged = false;
        btToastrService.success('Charts have been updated and will be reloaded immediately', 'Charts', {
          type: 'charts',
        });
      } else {
        btRestrictionService.showUpgradePopup('custom-charts');
      }
    }

    // function loadSavedTimezone() {
    //   //var name = localStorage.getItem('btChartTimeZone') || 'Etc/UTC';
    //   var name = btChartsService.getChartTimeZone() || 'Etc/UTC';
    //   var result = $scope.timeZoneList.filter(function (item) {
    //     return item.name === name;
    //   });
    //
    //   return {
    //     value: (result.length === 0) ? 'Etc/UTC' : result[0]
    //   };
    // }
  }
})();
