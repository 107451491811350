/**
 * Created by Sergey Panpurin on 08/24/2017.
 */

// @ts-check
(function btMarketWidgetsServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btMarketWidgetsService', btMarketWidgetsService);

  btMarketWidgetsService.$inject = [
    '$http',
    'btSettings',
    '$q',
    '$rootScope',
    'btShareScopeService',
    'btWatchListService',
    'btPriceService',
    'btEventEmitterService',
    'btRestrictionService',
    'btErrorService',
  ];

  /**
   * @ngdoc service
   * @name btMarketWidgetsService
   * @memberOf ecapp
   * @description
   *  This factory work with market widgets: MarketSense , MarketWakeup, MarketFollow.
   * @param {angular.IHttpService} $http
   * @param {ecapp.ISettings} btSettings
   * @param {angular.IQService} $q
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IWatchListService} btWatchListService
   * @param {ecapp.IPriceService} btPriceService
   * @param {ecapp.IEventEmitterService} btEventEmitterService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IErrorService} btErrorService
   * @return {ecapp.IMarketWidgetsService}
   */
  function btMarketWidgetsService(
    $http,
    btSettings,
    $q,
    $rootScope,
    btShareScopeService,
    btWatchListService,
    btPriceService,
    btEventEmitterService,
    btRestrictionService,
    btErrorService
  ) {
    console.log('Running btMarketWidgetsService');

    var gCache = {};

    var minSenseFactor = 0.0025;

    activate();

    // clear cache on logout
    btEventEmitterService.addListener('login:success', onLogin);
    btEventEmitterService.addListener('logout:success', onLogout);

    return {
      initMarketSense: initMarketSense,
      createMarketSense: createMarketSense,
      updateMarketSense: updateMarketSense,
      deleteMarketSense: deleteMarketSense,
      // getMarketSenses: getMarketSenses,
      getMarketSenseCache: getMarketSenseCache,
      getSensitivities: getSensitivities,
      updateMinSense: updateMinSense,
      hasMarketSenseCapacity: hasMarketSenseCapacity,

      initMarketWakeup: initMarketWakeup,
      createMarketWakeup: createMarketWakeup,
      deleteMarketWakeup: deleteMarketWakeup,
      // getMarketWakeups: getMarketWakeups,
      hasMarketWakeupCapacity: hasMarketWakeupCapacity,

      initMarketFollow: initMarketFollow,
      createMarketFollow: createMarketFollow,
      deleteMarketFollow: deleteMarketFollow,
    };

    /**
     *
     * @param {string} symbol
     * @return {string}
     */
    function downgradeSymbol(symbol) {
      return symbol;
      // // FIXME Remove later
      // var parts = symbol.split(':');
      // if (parts[0] !== 'OANDA') {
      //   throw new Error('Unsupported Symbol');
      // } else {
      //   return symbol.split(':').slice(1).join(':');
      // }
    }

    /**
     *
     * @param {string} symbol
     * @return {string}
     */
    function upgradeSymbol(symbol) {
      return symbol;
      // // FIXME Remove later
      // return 'OANDA:' + symbol;
    }

    /**
     *
     */
    function activate() {}

    /**
     * On user login
     */
    function onLogin() {
      try {
        console.log('login');
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * On user logout
     */
    function onLogout() {
      try {
        _clearCache();
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * Get sensitivities: min, max and default
     * @param {Number} price - current price
     * @param {Number} [precision] - precision
     * @return {{min: Number, default: number, max: number, precision: Number}}
     */
    function getSensitivities(price, precision) {
      var p = precision || btPriceService.getPricePrecision(price);
      var minSensitivity = parseFloat((price * minSenseFactor).toFixed(p));
      return {
        min: minSensitivity,
        default: 3 * minSensitivity,
        max: 6 * minSensitivity,
        precision: p,
      };
    }

    /**
     * Check broker support of MarketSense and MarketWakeup
     * @param {String} broker - lower case broker name
     * @param {String} action - action name: market-sense or market-wakeup
     * @return {boolean}
     * @private
     */
    function _checkBrokerSupport(broker, action) {
      if (broker === 'oanda') {
        return true;
      }

      // noinspection RedundantIfStatementJS
      if (broker === 'tradier' && action === 'market-sense') {
        return true;
      }

      return false;
    }

    /**
     * Handle HTTP errors
     * @param {Object} error - error object
     * @return {angular.IPromise<Object>}
     * @private
     */
    function _handleUserError(error) {
      console.log(error);
      if (error && error.message) {
        return $q.reject(error);
      } else if (error.data && error.data.failure_message) {
        return $q.reject(new Error(error.data.failure_message));
      } else {
        return $q.reject(error);
      }
    }

    /* ---------- MarketSense ---------- */

    /**
     * @typedef {Object} btMarketSenseServerObject
     * @property {String} [id]               - database id
     * @property {String} direction          - direction: long or short
     * @property {String} broker             - broker name
     * @property {String} displayName        - instrument display name
     * @property {Number} notification_type  - 1 for MarketSense
     * @property {Number} start_price        - starting price
     * @property {String} user_id            - user id
     * @property {String} instrument         - broker name of instrument
     * @property {Number} sensitivity        - sensitivity
     * @property {Date}   [start_time]       - starting time
     * @property {Number} [last_threshold]   - last crossed price
     * @property {Number} [count_sent]       - number of send notifications
     */

    /**
     * @typedef {Object} btMarketSenseClientObject
     * @property {String} id              - database id
     * @property {String} instrument      - broker name of instrument
     * @property {String} broker          - broker name
     * @property {String} duration        - F = infinity
     * @property {Number} sensitivity     - sensitivity
     * @property {String} side            - trade side: long or short
     * @property {String} [displayName]   - instrument display name
     * @property {Number} startPrice     - starting price
     * @property {Number} lastThreshold  - last crossed price
     * @property {Number} [price]         - starting price
     * @property {String} [userId]        - user id
     */

    /**
     * Convert MarketSense data from client format to backend format
     * @param {btMarketSenseClientObject} data - client MarketSense object
     * @return {?btMarketSenseServerObject} - converted server MarketSense object
     */
    function convertSenseToBack(data) {
      if (data) {
        return {
          direction: data.side,
          broker: data.broker,
          displayName: data.displayName,
          notification_type: 1,
          start_price: data.price,
          user_id: data.userId,
          instrument: downgradeSymbol(data.instrument),
          sensitivity: Math.max(data.sensitivity, data.price * minSenseFactor),
        };
      } else {
        return null;
      }
    }

    /**
     * Convert MarketSense data from server format to client format
     * @param {btMarketSenseServerObject} data - server MarketSense object
     * @return {?btMarketSenseClientObject} - converted client MarketSense object
     */
    function convertSenseFromBack(data) {
      if (data) {
        data.start_time = new Date(data.start_time);

        return {
          id: data.id,
          instrument: upgradeSymbol(data.instrument),
          broker: data.broker,
          duration: 'F',
          sensitivity: data.sensitivity,
          side: data.direction,
          startPrice: data.start_price,
          lastThreshold: data.last_threshold,
        };
      } else {
        return null;
      }
    }

    /**
     * Update minimal sensitivity
     * @param {String} broker - broker name
     * @private
     */
    function updateMinSense(broker) {
      minSenseFactor = broker === 'tradier' ? 0.0075 : 0.0025;
    }

    /**
     * Get market senses by instrument and user id. Return only first object.
     * @param {String} instrument - instrument name (now just OANDA)
     * @param {String} broker - broker name
     * @param {String} userId - user id
     * @return {angular.IPromise<btMarketSenseClientObject | null | Error>}
     */
    function initMarketSense(instrument, broker, userId) {
      if (broker === 'default') {
        broker = 'oanda';
      }

      var params = {
        headers: { 'Content-Type': 'application/json' },
        params: {
          instrument: downgradeSymbol(instrument),
          broker: broker,
          user_id: userId,
        },
      };

      var cache = getMarketSenseCache(instrument, broker, userId);
      if (cache) {
        return $q.resolve(cache);
      }

      // return $http.get('http://localhost:3000' + '/market-senses', params)
      return $http
        .get(btSettings.BT_BACKEND_URL + '/market-senses', params)
        .catch(btErrorService.handleHTTPError)
        .then(function (response) {
          // console.log(response);
          if (response.status === 200) {
            var data = convertSenseFromBack(response.data[0]);
            _addMarketSenseCache(instrument, broker, userId, data);
            return $q.resolve(data);
          } else {
            return $q.reject(response);
          }
        })
        .catch(_handleUserError);
    }

    /**
     * Add MarketSense to cache
     * @param {String} instrument - instrument name
     * @param {String} broker - broker name
     * @param {String} userId - user id
     * @param {btMarketSenseClientObject} data - MarketSense Object
     * @private
     */
    function _addMarketSenseCache(instrument, broker, userId, data) {
      if (gCache[userId] === undefined) {
        gCache[userId] = {};
      }

      if (gCache[userId][broker] === undefined) {
        gCache[userId][broker] = {};
      }

      if (gCache[userId][broker][instrument] === undefined) {
        gCache[userId][broker][instrument] = {};
      }

      gCache[userId][broker][instrument] = data;
    }

    /**
     * Remove MarketSense from cache by id
     * @param {String} id - MarketSense id
     * @private
     */
    function _removeMarketSenseCacheById(id) {
      for (var userId in gCache) {
        for (var broker in gCache[userId]) {
          // noinspection JSUnfilteredForInLoop
          for (var instrument in gCache[userId][broker]) {
            // noinspection JSUnfilteredForInLoop
            if (gCache[userId][broker][instrument] !== null && gCache[userId][broker][instrument].id === id) {
              // noinspection JSUnfilteredForInLoop
              gCache[userId][broker][instrument] = null;
              return;
            }
          }
        }
      }
    }

    /**
     * Get MarketSense from cache
     * @param {String} instrument - instrument name
     * @param {String} broker - broker name
     * @param {String|null} userId - user id or null
     * @return {?btMarketSenseClientObject} - client MarketSense object
     */
    function getMarketSenseCache(instrument, broker, userId) {
      if (userId === null) {
        userId = $rootScope.currentUser.id;
      }

      if (broker === 'default') {
        broker = 'oanda';
      }

      if (gCache[userId] && gCache[userId][broker] && gCache[userId][broker][instrument]) {
        return gCache[userId][broker][instrument];
      } else {
        return null;
      }
    }

    /**
     * Get number of MarketSense for selected user and broker
     * @param {String} userId - user id
     * @param {String} broker - broker name
     * @return {angular.IPromise<btMarketSenseServerObject[]>}
     */
    function getMarketSenses(userId, broker) {
      if (userId === null) {
        userId = $rootScope.currentUser.id;
      }

      if (broker === 'default') {
        broker = 'oanda';
      }

      var params = {
        headers: { 'Content-Type': 'application/json' },
        params: {
          broker: broker,
          user_id: userId,
        },
      };

      // return $http.get('http://localhost:3000' + '/market-senses', params)
      return $http
        .get(btSettings.BT_BACKEND_URL + '/market-senses', params)
        .catch(btErrorService.handleHTTPError)
        .then(function (response) {
          if (response.status === 200) {
            return $q.resolve(response.data);
          } else {
            return $q.reject(response);
          }
        })
        .catch(_handleUserError);
    }

    /**
     * Clear MarketSense cache
     * @private
     */
    function _clearCache() {
      gCache = {};
    }

    /**
     * Create new MarketSense
     * @param {btMarketSenseClientObject} settings - MarketSense settings
     * @return {angular.IPromise<btMarketSenseServerObject>} - id of created MarketSense
     */
    function createMarketSense(settings) {
      if (settings.userId === null) {
        settings.userId = $rootScope.currentUser.id;
      }

      if (settings.broker === 'default') {
        settings.broker = 'oanda';
      }

      if (!_checkBrokerSupport(settings.broker, 'market-sense')) {
        return $q.reject(new Error('MarketSense notification - coming soon.'));
      }

      var params = { headers: { 'Content-Type': 'application/json' } };

      var userData = convertSenseToBack(settings);
      if (userData === null && userData.sensitivity === null) {
        return $q.reject(new Error('Bad settings!'));
      }

      userData.start_time = new Date();
      userData.last_threshold = settings.price;
      userData.count_sent = 0;
      userData.start_price = settings.price;

      // return $http.post('http://localhost:3000' + '/market-senses', {data: userData}, params)
      return $http
        .post(btSettings.BT_BACKEND_URL + '/market-senses', { data: userData }, params)
        .catch(btErrorService.handleHTTPError)
        .then(function (response) {
          // console.log(response);
          if (response.status === 200) {
            return $q.resolve(response.data);
          } else {
            return $q.reject(response);
          }
        })
        .then(function (data) {
          settings.id = data.id;
          var eventName = 'marketSense:' + settings.instrument + ':' + settings.broker + ':add';
          _addMarketSenseCache(settings.instrument, settings.broker, settings.userId, settings);
          btEventEmitterService.emitEvent(eventName, [settings]);
          return data;
        })
        .catch(_handleUserError);
    }

    /**
     * Update MarketSense settings
     * @param {btMarketSenseClientObject} settings - MarketSense settings
     * @return {angular.IPromise<btMarketSenseServerObject>} - updated object
     */
    function updateMarketSense(settings) {
      if (settings.broker === 'default') {
        settings.broker = 'oanda';
      }

      var params = { headers: { 'Content-Type': 'application/json' } };

      var userData = convertSenseToBack(settings);

      // return $http.put('http://localhost:3000' + '/market-senses/' + settings.id, {data: userData}, params)
      return $http
        .put(btSettings.BT_BACKEND_URL + '/market-senses/' + settings.id, { data: userData }, params)
        .catch(btErrorService.handleHTTPError)
        .then(function (response) {
          // console.log(response);
          if (response.status === 200) {
            return $q.resolve(response.data);
          } else {
            return $q.reject(response);
          }
        })
        .catch(_handleUserError);
    }

    /**
     * Delete MarketSense by id
     * @param {String} id - MarketSense id
     * @return {angular.IPromise<{count: Number}>} - Number of deleted records
     */
    function deleteMarketSense(id) {
      var params = { headers: { 'Content-Type': 'application/json' } };

      // return $http.delete('http://localhost:3000' + '/market-senses/' + id, params)
      return $http
        .delete(btSettings.BT_BACKEND_URL + '/market-senses/' + id, params)
        .catch(btErrorService.handleHTTPError)
        .then(function (response) {
          // console.log(response);
          if (response.status === 200) {
            _removeMarketSenseCacheById(id);

            if (response.data === undefined || response.data.count !== 1) {
              console.error('Count must be equal to 1!');
            }

            return $q.resolve(response.data);
          } else {
            return $q.reject(response);
          }
        })
        .catch(_handleUserError);
    }

    /**
     * Has capacity to create a new MarketSense
     * @param {btMarketSenseClientObject} settings - MarketSense settings
     * @return {angular.IPromise<*>}
     */
    function hasMarketSenseCapacity(settings) {
      var c, l;
      return getMarketSenses(settings.userId, settings.broker)
        .then(function (data) {
          c = data.length;
          l = btRestrictionService.getCapacity('market-sense');
          if (c >= l) {
            return btRestrictionService.showUpgradePopup('market-sense').then(function (status) {
              if (status !== 'updated') {
                return $q.reject(
                  new Error('' + c + '/' + l + ' MarketSense limit was reached! Try to upgrade subscription plan.')
                );
              }
            });
          } else {
            return $q.resolve();
          }
        })
        .catch(function (reason) {
          return $q.reject(reason);
        });
    }

    /* ---------- MarketWakeup ---------- */

    /**
     * @typedef {Object} btMarketWakeupServerObject
     * @property {String} [id]               - database id
     * @property {String} broker             - broker name
     * @property {String} displayName        - instrument display name
     * @property {Number} notification_type  - 2 for MarketSense
     * @property {String} user_id            - user id
     * @property {String} instrument         - broker name of instrument
     * @property {Number} volatility         - ???
     * @property {Number} change             - ???
     * @property {Number} volume             - ???
     * @property {Date}   [start_time]       - starting time
     * @property {Number} [count_sent]       - number of send notifications
     * @property {Date} [time_of_last_message]     - time of last message
     * @property {Date} [time_of_last_volatility]  - time of last volatility
     */

    /**
     * @typedef {Object} btMarketWakeupClientObject
     * @property {String} id              - database id
     * @property {String} instrument      - broker name of instrument
     * @property {String} broker          - broker name
     * @property {String} [displayName]   - instrument display name
     * @property {String} [userId]        - user id
     *
     /**
     * Convert MarketWakeup data from backend format to client format
     * @param {btMarketWakeupClientObject} data - client MarketWakeup object
     * @return {?btMarketWakeupServerObject} - converted server MarketWakeup object
     */
    function convertWakeupToBack(data) {
      if (data) {
        return {
          broker: data.broker,
          displayName: data.displayName,
          notification_type: 2,
          user_id: data.userId,
          instrument: downgradeSymbol(data.instrument),
          volatility: 1,
          change: 0,
          volume: 0,
        };
      } else {
        return null;
      }
    }

    /**
     * Convert MarketWakeup data from client format to backend format
     * @param {btMarketWakeupServerObject} data - market widget data
     * @return {?btMarketWakeupClientObject} converted market widget data
     */
    function convertWakeupFromBack(data) {
      if (data) {
        return {
          id: data.id,
          instrument: upgradeSymbol(data.instrument),
          broker: data.broker,
        };
      } else {
        return null;
      }
    }

    /**
     * Get MarketWakeup by instrument and user id. Return only first object.
     * @param {String} instrument - instrument name (now just OANDA)
     * @param {String} broker - broker name
     * @param {String} userId - user id
     * @return {angular.IPromise<Object>}
     */
    function initMarketWakeup(instrument, broker, userId) {
      if (broker === 'default') {
        broker = 'oanda';
      }

      var params = {
        headers: { 'Content-Type': 'application/json' },
        params: {
          instrument: downgradeSymbol(instrument),
          broker: broker,
          user_id: userId,
        },
      };

      // return $http.get('http://localhost:3000' + '/market-wakeups', params)
      return $http
        .get(btSettings.BT_BACKEND_URL + '/market-wakeups', params)
        .catch(btErrorService.handleHTTPError)
        .then(function (response) {
          // console.log(response);
          if (response.status === 200) {
            return $q.resolve(convertWakeupFromBack(response.data[0]));
          } else {
            return $q.reject(response);
          }
        })
        .catch(_handleUserError);
    }

    /**
     * Create new MarketSense
     * @param {Object} settings - MarketSense settings
     * @return {angular.IPromise<Object>} - id of created MarketSense
     */
    function createMarketWakeup(settings) {
      if (settings.broker === 'default') {
        settings.broker = 'oanda';
      }

      if (!_checkBrokerSupport(settings.broker, 'market-wakeup')) {
        return $q.reject(new Error('MarketWakeup notification - coming soon.'));
      }

      var params = { headers: { 'Content-Type': 'application/json' } };

      var userData = convertWakeupToBack(settings);
      userData.start_time = new Date();
      userData.count_sent = 0;
      userData.time_of_last_message = new Date();

      // return $http.post('http://localhost:3000' + '/market-wakeups', {data: userData}, params)
      return $http
        .post(btSettings.BT_BACKEND_URL + '/market-wakeups', { data: userData }, params)
        .catch(btErrorService.handleHTTPError)
        .then(function (response) {
          // console.log(response);
          if (response.status === 200) {
            return $q.resolve(response.data);
          } else {
            return $q.reject(response);
          }
        })
        .catch(_handleUserError);
    }

    /**
     * Delete MarketWakeup by id
     * @param {string} id - MarketWakeup id
     * @return {angular.IPromise<Object>} - ???
     */
    function deleteMarketWakeup(id) {
      var params = { headers: { 'Content-Type': 'application/json' } };

      // return $http.delete('http://localhost:3000' + '/market-wakeups/' + id, params)
      return $http
        .delete(btSettings.BT_BACKEND_URL + '/market-wakeups/' + id, params)
        .catch(btErrorService.handleHTTPError)
        .then(function (response) {
          // console.log(response);
          if (response.status === 200) {
            return $q.resolve(response.data);
          } else {
            return $q.reject(response);
          }
        })
        .catch(_handleUserError);
    }

    /**
     * Get number of MarketWakeup for selected user and broker
     * @param {string} userId - user id
     * @param {string} broker - broker name
     * @return {angular.IPromise<btMarketWakeupServerObject[]>}
     */
    function getMarketWakeups(userId, broker) {
      if (broker === 'default') {
        broker = 'oanda';
      }

      var params = {
        headers: { 'Content-Type': 'application/json' },
        params: {
          broker: broker,
          user_id: userId,
        },
      };

      // return $http.get('http://localhost:3000' + '/market-wakeups', params)
      return $http
        .get(btSettings.BT_BACKEND_URL + '/market-wakeups', params)
        .catch(btErrorService.handleHTTPError)
        .then(function (response) {
          // console.log(response);
          if (response.status === 200) {
            return $q.resolve(response.data);
          } else {
            return $q.reject(response);
          }
        })
        .catch(_handleUserError);
    }

    /**
     * Has capacity to create a new MarketWakeup
     * @param {btMarketWakeupClientObject} settings - MarketWakeup settings
     * @return {angular.IPromise<*>}
     */
    function hasMarketWakeupCapacity(settings) {
      var c, l;
      return getMarketWakeups(settings.userId, settings.broker)
        .then(function (data) {
          c = data.length;
          l = btRestrictionService.getCapacity('market-wakeup');
          if (c >= l) {
            return btRestrictionService.showUpgradePopup('market-wakeup').then(function (status) {
              if (status !== 'updated') {
                return $q.reject(
                  new Error('' + c + '/' + l + ' MarketWakeup limit was reached! Try to upgrade subscription plan.')
                );
              }
            });
          } else {
            return $q.resolve();
          }
        })
        .catch(function (reason) {
          return $q.reject(reason);
        });
    }

    /* ---------- MarketFollow ---------- */

    /**
     *
     * @param {string} symbol
     * @param {string} broker
     * @return {angular.IPromise<string | null>}
     */
    function initMarketFollow(symbol, broker) {
      var symbols = btShareScopeService.getWatchedInstruments(broker) || [];
      if (symbols === undefined) {
        return $q.reject(new Error("WatchList isn't created"));
      } else {
        var data = symbols.filter(function (item) {
          return item === symbol;
        });

        return $q.resolve(data[0] ? data[0] : null);
      }
    }

    /**
     * Adds instrument to watchlist.
     *
     * @param {*} settings
     * @return {angular.IPromise<{id: string}>}
     */
    function createMarketFollow(settings) {
      return btWatchListService.addInstrumentByName(settings.instrument, settings.broker);
    }

    /**
     * Removes instrument from watchlist.
     *
     * @param {btMarketFollowSettings} settings
     * @param {ecapp.ITradingInstrument} instrument
     * @return {angular.IPromise<*>}
     */
    function deleteMarketFollow(settings, instrument) {
      if (!btShareScopeService.isInitialized()) {
        return $q.reject(new Error('Not initialized'));
      }

      var symbols = btShareScopeService.getWatchedInstruments(settings.broker) || [];
      if (symbols === undefined) {
        return $q.reject(new Error("WatchList isn't created"));
      } else {
        var indexOfWatchedInstruments = symbols.indexOf(settings.instrument);
        symbols.splice(indexOfWatchedInstruments, 1);

        var levelsSettings = btShareScopeService.getUserSettings('levels', {});
        var fields = ['watchList'];

        // Remove levels settings
        if (levelsSettings[instrument.OandaSymbol]) {
          delete levelsSettings[instrument.OandaSymbol];
          fields.push('settings');
          btShareScopeService.setUserSettings('levels', levelsSettings);
        }

        btShareScopeService.setWatchedInstruments(symbols, settings.broker);
        return btShareScopeService.updateProfileSettingsFromFieldsList(fields).then(function (data) {
          console.log('Watchlist:', data);
          $rootScope.$broadcast('market:unfollow', { symbol: settings.instrument });
        });
      }
    }
  }
})();
