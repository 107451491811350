/**
 * Created by Sergey on 3/2/18.
 */

// @ts-check
(function btReleaseTestingServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btReleaseTestingService', ['btDateService', btReleaseTestingService]);

  /**
   * @ngdoc service
   * @name btReleaseTestingService
   * @memberOf ecapp
   * @description
   *  This factory ???
   * @param {ecapp.IDateService} btDateService
   * @return {ecapp.IReleaseTestingService}
   */
  function btReleaseTestingService(btDateService) {
    console.log('Running btReleaseTestingService');

    /**
     * This function adds test event to calendar and simulate release after some delay.
     *
     * You can use pusher dev console to send test message. You need to set right id, event id and time.
     *
     * @ngdoc method
     * @name addTestRelease
     * @memberOf ecapp.btRowProcessorService
     * @param {btRawRelease[]} reviews - (changeable)
     * @param {Array} delay - (const) in seconds
     * @return {Object|null} - data to simulate release
     */
    function addTestRelease(reviews, delay) {
      if (window.location.hostname === 'localhost' || window.location.hostname === '') {
        var now = btDateService.getNowDate().getTime() / 1000;

        // find next release
        var j = -1;
        for (var i = 0; i < reviews.length; i++) {
          if (reviews[i].time >= now) {
            j = i;
            break;
          }
        }

        // shift back to find good release: not speech or report, and with actual value
        var shift = 0;
        while (j - shift >= 0 && _isGoodTestRelease(reviews[j - shift])) {
          shift++;
        }

        var testRelease = {};
        if (j - shift < 0) {
          // if we don't have good previous release return template
          j = 0;
          testRelease = _getTestReleaseTemplate();
        } else {
          // clone good previous release
          testRelease = angular.copy(reviews[j - shift]);
        }

        // set new properties
        testRelease.time = Math.round(btDateService.getNowDate().getTime() / 1000) + delay[0];
        testRelease.actual = 'NA';
        testRelease.id = testRelease.id * 10;
        testRelease.eventsInfo.name = '[TEST] ' + testRelease.eventsInfo.name;
        testRelease.expectedTradingInsights = _getTestExpectedTradingInsights(testRelease);

        testRelease.skipFilter = true;

        delete testRelease.oldStrength;
        delete testRelease.oldStrengthRevision;
        delete testRelease.releaseStrength;
        delete testRelease.revisionStrength;
        delete testRelease.tradingInsights;

        reviews.splice(j, 0, testRelease);

        var data = { id: testRelease.id, row: {}, insights: {}, tradingInsights: {} };
        data.row[testRelease.id] = _getTestRelease(testRelease);

        data.insights[testRelease.id] = _getTestInsights(testRelease);

        data.tradingInsights[testRelease.id] = _getTestTradingInsights(testRelease);

        console.log('Test release data:', data);

        return data;
      } else {
        return null;
      }
    }

    /**
     *
     * @param {*} release
     * @return {boolean}
     */
    function _isGoodTestRelease(release) {
      return release.eventsInfo.eventType !== 0 || release.actual === undefined || release.actual === 'NA';
    }

    /**
     *
     * @return {any}
     */
    function _getTestReleaseTemplate() {
      return {
        id: 338695,
        eventId: 304,
        revision: '3.6%',
        actual: 'NA',
        previous: '3.6%',
        forecast: 'NA',
        time: 1481492700,
        eventsInfo: {
          id: 304,
          name: 'Visitor Arrivals (MoM)',
          lastForecast: 1208731500,
          relativeType: 1,
          direction: 1,
          importance: 1,
          relatedInstruments: ['NZ50', 'USDNZD', 'AUDNZD', 'NZDEUR'],
          currency: 'NZD',
          country: 'New Zealand',
          eventType: 0,
          dynamicData: { benchmark: 0 },
          usuallyNoForecast: true,
          unit: '',
        },
      };
    }

    /**
     *
     * @param {*} testRelease
     * @return {any}
     */
    function _getTestRelease(testRelease) {
      return {
        time: testRelease.time,
        _id: testRelease.id,
        eventId: testRelease.eventId,
        actual: '200',
        forecast: 'NA',
        previous: '205.8',
        revision: '207.8',
        oldStrength: -1.3728387006571335,
        oldStrengthRevision: 0,
        // "releaseStrength": null,
        releaseStrength: {
          analysisQuality: { pValue: 0.0000011122321470491414, samples: 190 },
          value: -1.7683212728440612,
        },
        // "revisionStrength": null
        revisionStrength: { analysisQuality: { pValue: 2.732579584829864e-8, samples: 211 }, value: 0 },
      };
    }

    /**
     *
     * @param {*} testRelease
     * @return {any}
     */
    function _getTestInsights(testRelease) {
      return {
        _id: testRelease.id,
        eventId: testRelease.eventId,
        totalStrength: -0.68415341137933786,
        totalSurpriseStrength: -0.68415341137933786,
        insights: [
          {
            totalSurpriseStrength: -0.38415341137933786,
            template: 'This release is the lowest in the last 11 months',
            type: 'post',
            strengths: {
              checkedPeriod: {
                analysisQuality: { pValue: 0.32864417483258923, samples: 37 },
                value: -0.33849968450372286,
              },
              diffCheckedAndAvg: {
                analysisQuality: { pValue: 4.415950050074002e-8, samples: 37 },
                value: -0.02080074910254651,
              },
              diffFromMinOfActuals: {
                analysisQuality: { pValue: 5.404243799134179e-7, samples: 37 },
                value: -0.023758777267083833,
              },
              numOfChecks: {
                analysisQuality: { pValue: 0.2726834867118566, samples: 37 },
                value: -0.3535544346439982,
              },
            },
            totalStrength: -0.38415341137933786,
            id: 17,
            templateVars: { period: '11 months' },
          },
          {
            grade: 0,
            totalSurpriseStrength: null,
            template: 'Range in the past 3 years: 72.4% to 76.6%',
            type: 'pre',
            strengths: null,
            totalStrength: null,
            id: 27,
            templateVars: { min: '72.4%', period: '3 years', max: '76.6%' },
          },
        ],
      };
    }

    /**
     *
     * @param {*} testRelease
     * @return {any}
     */
    function _getTestTradingInsights(testRelease) {
      return {
        eventId: testRelease.eventId,
        _id: testRelease.id,
        totalStrength: 0,
        totalExpectedStrength: 0,
        totalSurpriseStrength: 0,
        increment: true,
        insights: [
          {
            totalStrength: 1,
            id: 301,
            templateVars: { rate: '9 out of 13 times', market: 'EURGBP' },
            totalSurpriseStrength: 1,
            strengths: {},
            type: 'back-test',
            template: '9 out of 13 times EURGBP was on the downtrend in similar situations',
            data: {
              event: '***',
              total: 13,
              quality: 3.4444444444444446,
              weak: 0,
              action: 'downtrend',
              class: 'negative',
              successRate: { value: 69, status: 'sell' },
              direction: '***',
              market: 'EURGBP',
              win: 9,
              tradeData: {
                reward: {
                  price: {
                    avg: 0.0015853218442475688,
                    min: 0.001580643688495157,
                    mdn: 0.0015853218442475688,
                    opt: 0.0015853218442475688,
                  },
                  atr: { min: 8.022170956519798, mdn: 8.045913793365465, avg: 8.045913793365465 },
                  time: { avg: 24 },
                },
                risk: { price: { avg: 0.0004925860147652839 }, atr: { avg: 2.5 }, time: { avg: 1.5 } },
                profit: { strong: 16.09182758673093, weak: 0 },
                loss: { strong: 7.699999999999468, weak: 0 },
              },
            },
          },
          {
            totalStrength: 1,
            id: 301,
            templateVars: { rate: '10 out of 12 times', market: 'EURJPY' },
            totalSurpriseStrength: 1,
            strengths: {},
            type: 'back-test',
            template: '10 out of 12 times EURJPY was on the downtrend in similar situations',
            data: {
              event: '***',
              total: 12,
              quality: 3.4444444444444446,
              weak: 0,
              action: 'downtrend',
              class: 'negative',
              successRate: { value: 69, status: 'sell' },
              direction: '***',
              market: 'EURJPY',
              win: 10,
              tradeData: {
                reward: {
                  price: {
                    avg: 0.0015853218442475688,
                    min: 0.001580643688495157,
                    mdn: 0.0015853218442475688,
                    opt: 0.0015853218442475688,
                  },
                  atr: { min: 8.022170956519798, mdn: 8.045913793365465, avg: 8.045913793365465 },
                  time: { avg: 24 },
                },
                risk: { price: { avg: 0.0004925860147652839 }, atr: { avg: 2.5 }, time: { avg: 1.5 } },
                profit: { strong: 16.09182758673093, weak: 0 },
                loss: { strong: 7.699999999999468, weak: 0 },
              },
            },
          },
        ],
      };
    }

    /**
     *
     * @param {*} testRelease
     * @return {any}
     */
    function _getTestExpectedTradingInsights(testRelease) {
      return [
        {
          id: '58d78351f0a3a52e9c9274cd',
          rowId: testRelease.id,
          expectedInsights: [
            {
              totalStrength: 1,
              template: '4 out of 6 times JPXJPY was on the uptrend in expected situations',
              case: { class: 'positive', id: 'back-pos', name: 'Positive spectrum', range: [0.22, 1] },
              type: 'back-pre',
              data: {
                win: 4,
                total: 6,
                market: 'JPXJPY',
                event: '***',
                successRate: { value: 66, status: 'buy' },
                weak: 1,
                quality: 3.25,
                class: 'positive',
                direction: '***',
                action: 'uptrend',
              },
              strengths: {},
              id: 302,
              totalSurpriseStrength: 1,
              templateVars: { market: 'JPXJPY', rate: '4 out of 6 times' },
            },
            {
              totalStrength: 1,
              template: '9 out of 13 times JPXJPY was on the downtrend in expected situations',
              case: { class: 'neutral', id: 'back-exp', name: 'Close to Expected', range: [-0.12, 0.12] },
              type: 'back-pre',
              data: {
                win: 9,
                total: 13,
                market: 'JPXJPY',
                event: '***',
                successRate: { value: 69, status: 'sell' },
                weak: 2,
                quality: 3.111111111111111,
                class: 'negative',
                direction: '***',
                action: 'downtrend',
              },
              strengths: {},
              id: 303,
              totalSurpriseStrength: 1,
              templateVars: { market: 'JPXJPY', rate: '9 out of 13 times' },
            },
            {
              totalStrength: 1,
              template: '3 out of 4 times JPXJPY was on the uptrend in expected situations',
              case: { class: 'positive', id: 'just-pos', name: 'Stronger', range: [0.2, 0.6] },
              type: 'back-pre',
              data: {
                win: 3,
                total: 4,
                market: 'JPXJPY',
                event: '***',
                successRate: { value: 75, status: 'buy' },
                weak: 1,
                quality: 3.6666666666666665,
                class: 'positive',
                direction: '***',
                action: 'uptrend',
              },
              strengths: {},
              id: 302,
              totalSurpriseStrength: 1,
              templateVars: { market: 'JPXJPY', rate: '3 out of 4 times' },
            },
            {
              totalStrength: 1,
              template: '6 out of 9 times JPXJPY was on the downtrend in expected situations',
              case: { class: 'neutral', id: 'asexpect', name: 'As Expected', range: [-0.01, 0.01] },
              type: 'back-pre',
              data: {
                win: 6,
                total: 9,
                market: 'JPXJPY',
                event: '***',
                successRate: { value: 66, status: 'sell' },
                weak: 1,
                quality: 3.1666666666666665,
                class: 'negative',
                direction: '***',
                action: 'downtrend',
              },
              strengths: {},
              id: 303,
              totalSurpriseStrength: 1,
              templateVars: { market: 'JPXJPY', rate: '6 out of 9 times' },
            },
          ],
          market: 'JPXJPY',
          time: testRelease.time,
          eventId: testRelease.eventId,
        },
        {
          id: '58d78351f0a3a52e9c9274ce',
          rowId: testRelease.id,
          expectedInsights: [
            {
              totalStrength: 1,
              template: '3 out of 4 times USDJPY was on the downtrend in expected situations',
              case: { class: 'negative', id: 'back-neg', name: 'Negative spectrum', range: [-1, -0.22] },
              type: 'back-pre',
              data: {
                win: 3,
                total: 4,
                market: 'USDJPY',
                event: '***',
                successRate: { value: 75, status: 'sell' },
                weak: 0,
                quality: 2.3333333333333335,
                class: 'negative',
                direction: '***',
                action: 'downtrend',
              },
              strengths: {},
              id: 303,
              totalSurpriseStrength: 1,
              templateVars: { market: 'USDJPY', rate: '3 out of 4 times' },
            },
          ],
          market: 'USDJPY',
          time: testRelease.time,
          eventId: testRelease.eventId,
        },
      ];
    }

    return {
      addTestRelease: addTestRelease,
    };
  }
})();

// var test = {
//   381541: {
//     increment: true,
//     eventId: 235,
//     _id: 381541,
//     totalStrength: 0,
//     totalExpectedStrength: 0,
//     totalSurpriseStrength: 0,
//     insights: [
//       {
//         id: 300,
//         template: '2 out of 3 times OIL was on the uptrend in similar situations',
//         templateVars: { rate: '2 out of 3 times', market: 'OIL' },
//         strengths: {},
//         totalStrength: 1,
//         totalSurpriseStrength: 1,
//         type: 'back-test',
//         data: {
//           successRate: { status: 'buy', value: 66 },
//           tradeData: {
//             reward: {
//               price: {
//                 avg: 0.752968382333171,
//                 min: 0.7159367646663358,
//                 mdn: 0.752968382333171,
//                 opt: 0.752968382333171,
//               },
//               atr: { min: 19.507325497187242, mdn: 20.51633614612495, avg: 20.51633614612495 },
//               time: { avg: 64.5 },
//             },
//             risk: { price: { avg: 0.14000000000000057 }, atr: { avg: 3.8146184193781782 }, time: { avg: 7.5 } },
//             profit: { strong: 41.0326722922499, weak: 0 },
//             loss: { strong: 7.700000000000121, weak: 0 },
//           },
//           win: 2,
//           total: 3,
//           loss: 1,
//           weak: 0,
//           broken: 0,
//           noData: 0,
//           market: 'OIL',
//           action: 'uptrend',
//           class: 'positive',
//           quality: 5,
//           version: '2.3',
//         },
//       },
//       {
//         id: 300,
//         template: '2 out of 3 times Silver was on the uptrend in similar situations',
//         templateVars: { rate: '2 out of 3 times', market: 'Silver' },
//         strengths: {},
//         totalStrength: 1,
//         totalSurpriseStrength: 1,
//         type: 'back-test',
//         data: {
//           successRate: { status: 'buy', value: 66 },
//           tradeData: {
//             reward: {
//               price: {
//                 avg: 0.05953703756674357,
//                 min: 0.05207407513348698,
//                 mdn: 0.05953703756674357,
//                 opt: 0.05953703756674357,
//               },
//               atr: { min: 8.680905161454588, mdn: 9.925003476029122, avg: 9.925003476029122 },
//               time: { avg: 55 },
//             },
//             risk: { price: { avg: 0.038000000000000256 }, atr: { avg: 6.334714448402101 }, time: { avg: 34 } },
//             profit: { strong: 19.850006952058244, weak: 0 },
//             loss: { strong: 7.6999999999999975, weak: 0 },
//           },
//           win: 2,
//           total: 3,
//           loss: 1,
//           weak: 0,
//           broken: 0,
//           noData: 0,
//           market: 'Silver',
//           action: 'uptrend',
//           class: 'positive',
//           quality: 3,
//           version: '2.3',
//         },
//       },
//       {
//         id: 300,
//         template: '2 out of 3 times West Texas Oil was on the uptrend in similar situations',
//         templateVars: { rate: '2 out of 3 times', market: 'West Texas Oil' },
//         strengths: {},
//         totalStrength: 1,
//         totalSurpriseStrength: 1,
//         type: 'back-test',
//         data: {
//           successRate: { status: 'buy', value: 66 },
//           tradeData: {
//             reward: {
//               price: {
//                 avg: 0.752968382333171,
//                 min: 0.7159367646663358,
//                 mdn: 0.752968382333171,
//                 opt: 0.752968382333171,
//               },
//               atr: { min: 19.507325497187242, mdn: 20.51633614612495, avg: 20.51633614612495 },
//               time: { avg: 64.5 },
//             },
//             risk: { price: { avg: 0.14000000000000057 }, atr: { avg: 3.8146184193781782 }, time: { avg: 7.5 } },
//             profit: { strong: 41.0326722922499, weak: 0 },
//             loss: { strong: 7.700000000000121, weak: 0 },
//           },
//           win: 2,
//           total: 3,
//           loss: 1,
//           weak: 0,
//           broken: 0,
//           noData: 0,
//           market: 'West Texas Oil',
//           action: 'uptrend',
//           class: 'positive',
//           quality: 5,
//           version: '2.3',
//         },
//       },
//       {
//         id: 300,
//         template: '2 out of 3 times GBPUSD was on the uptrend in similar situations',
//         templateVars: { rate: '2 out of 3 times', market: 'GBPUSD' },
//         strengths: {},
//         totalStrength: 1,
//         totalSurpriseStrength: 1,
//         type: 'back-test',
//         data: {
//           successRate: { status: 'buy', value: 66 },
//           tradeData: {
//             reward: {
//               price: {
//                 avg: 0.0015853218442475688,
//                 min: 0.001580643688495157,
//                 mdn: 0.0015853218442475688,
//                 opt: 0.0015853218442475688,
//               },
//               atr: { min: 8.022170956519798, mdn: 8.045913793365465, avg: 8.045913793365465 },
//               time: { avg: 24 },
//             },
//             risk: { price: { avg: 0.0004925860147652839 }, atr: { avg: 2.5 }, time: { avg: 1.5 } },
//             profit: { strong: 16.09182758673093, weak: 0 },
//             loss: { strong: 7.699999999999468, weak: 0 },
//           },
//           win: 2,
//           total: 3,
//           loss: 1,
//           weak: 0,
//           broken: 0,
//           noData: 0,
//           market: 'GBPUSD',
//           action: 'uptrend',
//           class: 'positive',
//           quality: 3,
//           version: '2.3',
//         },
//       },
//       {
//         id: 300,
//         template: '3 out of 3 times Nasdaq 100 was on the uptrend in similar situations',
//         templateVars: { rate: '3 out of 3 times', market: 'Nasdaq 100' },
//         strengths: {},
//         totalStrength: 1,
//         totalSurpriseStrength: 1,
//         type: 'back-test',
//         data: {
//           successRate: { status: 'buy', value: 100 },
//           tradeData: {
//             reward: {
//               price: { avg: 25, min: 22.525967242486484, mdn: 25, opt: 24.24285322171963 },
//               atr: { min: 11.112262855715997, mdn: 12.332725534152681, avg: 11.959218197928692 },
//               time: { avg: 69 },
//             },
//             risk: { price: { avg: 6.75 }, atr: { avg: 3.329835894221224 }, time: { avg: 33 } },
//             profit: { strong: 35.877654593786076, weak: 0 },
//             loss: { strong: 0, weak: 0 },
//           },
//           win: 3,
//           total: 3,
//           loss: 0,
//           weak: 0,
//           broken: 0,
//           noData: 0,
//           market: 'Nasdaq 100',
//           action: 'uptrend',
//           class: 'positive',
//           quality: 3.6666666666666665,
//           version: '2.3',
//         },
//       },
//     ],
//   },
// };
