/**
 * Created by Orly on 06/02/19.
 */

(function btRadioGroup() {
  'use strict';

  angular
    .module('ecapp')

    /**
     * This directive displays radio button group
     * @ngdoc directive
     * @name btRadioGroup
     * @memberOf ecapp
     * @scope
     * @param {ecapp.IOptionObject[]} options - array of options
     * @param {string} model - model of the radio group
     * @param {string} name - name of the radio group
     * @param {function} [onChange] - function to call on change
     */

    .directive('btRadioGroup', btRadioGroup);

  btRadioGroup.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btRadioGroup($templateCache) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        options: '=',
        model: '=',
        name: '@',
        onChange: '&?',
      },
      template: $templateCache.get('directives/common/bt-radio-group.html'),
      controller: ['$scope', '$templateCache', btRadioGroupController],
    };
  }

  /**
   *
   * @param {*} $scope
   */
  function btRadioGroupController($scope) {
    $scope.valueChanged = valueChanged;
    $scope.getOptionWidth = getOptionWidth;

    /**
     * Action on value change
     * @param {*} newValue
     */
    function valueChanged(newValue) {
      $scope.model = newValue;
      if ($scope.onChange && typeof $scope.onChange === 'function') {
        $scope.onChange({ option: newValue });
      }
    }

    /**
     * This function returns width of the option
     * @return {string}
     */
    function getOptionWidth() {
      return Math.floor(100 / $scope.options.length) + '%';
    }
  }
})();
