/**
 * Created by Sergey Panpurin on 5/15/2017.
 */

(function btMessageClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive displays info message.
     *
     * @ngdoc directive
     * @name btMessageInfo
     * @memberOf ecapp
     */
    .directive('btMessageInfo', btMessageInfo);

  btMessageInfo.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btMessageInfo($templateCache) {
    return {
      restrict: 'E',
      transclude: true,
      scope: false,
      template: $templateCache.get('directives/common/bt-message-info.html'),
    };
  }
})();
