(function btCalendarDateClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * Returns calendar date
     *
     * @ngdoc filter
     * @name btCalendarDate
     * @memberOf ecapp
     */
    .filter('btCalendarDate', btCalendarDateFilter);

  btCalendarDateFilter.$inject = ['btDateService'];

  /**
   *
   * @param {ecapp.IDateService} btDateService
   * @return {angular.IFilterFunction}
   */
  function btCalendarDateFilter(btDateService) {
    return function convertTrend(timestamp) {
      return btDateService.getDateText(timestamp * 1000);
    };
  }
})();
