/**
 * Created by Sergey on 5/27/19.
 */

(function btProMembershipPageControllerClosure() {
  'use strict';

  // var gDebug = false;
  // var gPrefix = 'btProMembershipPageController:';

  angular.module('ecapp').controller('btProMembershipPageController', btProMembershipPageController);

  btProMembershipPageController.$inject = ['$scope'];

  /**
   * This is a controller for pro membership page.
   *
   * @ngdoc controller
   * @name ecapp.btProMembershipPageController
   * @param {ecapp.ICustomScope} $scope
   */
  function btProMembershipPageController($scope) {
    void $scope;

    console.log('Running btProMembershipPageController');

    var vm = this;
    void vm;
  }
})();
