/**
 * Created by Sergey Panpurin on 5/2/2018.
 */

// @ts-check
(function btBackServiceClosure() {
  'use strict';

  var gDebug = false;

  angular.module('ecapp').factory('btBackService', btBackService);

  btBackService.$inject = ['$state', '$ionicHistory'];

  /**
   * This service manages back button for releases' and instruments' pages.
   *
   * @ngdoc service
   * @name btBackService
   * @memberOf ecapp
   * @param {angular.ui.IStateService} $state
   * @param {ionic.IHistoryService} $ionicHistory
   * @return {ecapp.IBackService}
   */
  function btBackService($state, $ionicHistory) {
    console.log('Running btBackService');

    var TITLE = {
      'ecapp.app.main.instrument-page': 'to Market',
      'ecapp.app.main.detail': 'to Release',
      'ecapp.app.main.insights': 'to Insights',
      'ecapp.app.main.trade-ideas': 'to TradeIdeas',
      'ecapp.app.main.barometer': 'to Barometer',
      'ecapp.app.main.risk-monitor': 'to RO/RO',
    };

    var gLastBackUrl = null;
    var gLastPrevUrl = null;

    return {
      addBackButton: addBackButton,
    };

    /**
     *
     * @param {ecapp.ICustomScope} $scope
     * @param {angular.IController} vm
     * @param {string} currentState
     * @param {string} parentState
     * @param {string[]} backStates
     */
    function addBackButton($scope, vm, currentState, parentState, backStates) {
      void parentState;

      vm.hasBackButton = false;

      /** @type {ecapp.IView} */
      var prevView = null;

      $scope.$on('$stateChangeStart', stateChangeStart);

      $scope.$on('$ionicView.beforeEnter', ionicViewBeforeEnter);

      vm.goBack = goBack;

      /**
       * Goes back.
       */
      function goBack() {
        if (gDebug) console.log('btBackService', '$ionicHistory viewHistory', $ionicHistory.viewHistory());
        if (gDebug) console.log('btBackService', '$ionicHistory backView', $ionicHistory.backView());
        goToGoodView();
      }

      /**
       * Handles state change.
       *
       * @param {angular.IAngularEvent} event - event
       * @param {angular.ui.IState} next - next state
       * @param {*} nextParams - next state parameters
       * @param {angular.ui.IState} prev - previous state
       * @param {*} prevParams - previous state parameters
       * @param {*} options - options
       */
      function stateChangeStart(event, next, nextParams, prev, prevParams, options) {
        void event;
        void nextParams;
        void options;

        if (next.name === currentState) {
          if (gDebug) console.log('btBackService', '$stateChangeStart 1', prev);
          if (gDebug) console.log('btBackService', '$stateChangeStart 1', prevParams);

          prevView = {
            stateName: prev.name,
            stateParams: prevParams,
            url: $state.href(prev.name, prevParams),
            changedState: true,
          };
        } else {
          prevView = null;
        }
      }

      /**
       * Handles Ionic event.
       *
       * @param {angular.IAngularEvent} event
       * @param {*} data
       */
      function ionicViewBeforeEnter(event, data) {
        void event;
        if (gDebug) console.log('btBackService', data);
        if (gDebug) console.log('btBackService', $ionicHistory.currentStateName());

        var view = getGoodView();
        vm.hasBackButton = !!view;

        vm.smartBackViewTitle = '';

        if (view && backStates.indexOf(view.stateName) !== -1) {
          vm.smartBackViewTitle = TITLE[view.stateName] || '';

          // if (view.stateName === 'ecapp.app.main.instrument-page') {
          //   vm.smartBackViewTitle = 'to Market';
          // }

          // if (view.stateName === 'ecapp.app.main.detail') {
          //   vm.smartBackViewTitle = 'to Release';
          // }

          // if (view.stateName === 'ecapp.app.main.insights') {
          //   vm.smartBackViewTitle = 'to Insights';
          // }

          // if (view.stateName === 'ecapp.app.main.trade-ideas') {
          //   vm.smartBackViewTitle = 'to TradeIdeas';
          // }

          // if (view.stateName === 'ecapp.app.main.barometer') {
          //   vm.smartBackViewTitle = 'to Barometer';
          // }

          // if (view.stateName === 'ecapp.app.main.risk-monitor') {
          //   vm.smartBackViewTitle = 'to RO/RO';
          // }
        }
      }

      /**
       * Opens back view if it is available.
       */
      function goToGoodView() {
        var view = getGoodView();

        if (view) {
          // noinspection RedundantIfStatementJS
          if (view.changedState) {
            gLastPrevUrl = view.url;
          } else {
            gLastBackUrl = '#' + view.url;
          }

          // console.log('btBackService','$ionicHistory before', JSON.stringify($ionicHistory.viewHistory()));
          // $ionicHistory.clearHistory();
          // console.log('btBackService','$ionicHistory after', JSON.stringify($ionicHistory.viewHistory()));

          $state.go(view.stateName, view.stateParams);
        }
      }

      /**
       * Returns view for back button or null.
       *
       * @return {ecapp.IView | null}
       */
      function getGoodView() {
        // var view = getForwardView() || getBackView() || prevView;
        var view = null;

        var back = getBackView();

        if (back) {
          view = back;
        } else {
          if (prevView) {
            view = prevView;
          } else {
            view = null;
          }
        }

        var currentView = $ionicHistory.currentView();
        var currentUrl = $state.href(currentView.stateName, currentView.stateParams);

        if (gLastPrevUrl === currentUrl || gLastBackUrl === currentUrl) {
          return null;
        }

        if (gDebug) console.log('btBackService good view', view);
        return view;
      }

      /**
       * Checks if the view support back button.
       *
       * @param {ecapp.IView} view - view
       * @return {ecapp.IView | null}
       */
      function isGoodView(view) {
        if (view && backStates.indexOf(view.stateName) !== -1) {
          return view;
        } else {
          return null;
        }
      }

      /**
       * Returns back view or null.
       *
       * @return {ecapp.IView | null}
       */
      function getBackView() {
        return isGoodView($ionicHistory.backView());
      }

      // function getForwardView() {
      //   return null;
      //   // return isGoodView($ionicHistory.forwardView());
      // }
    }
  }
})();
