/**
 * Created by Sergey Panpurin on 10/10/2018.
 */

// @ts-check
(function btComponentServiceClosure() {
  'use strict';

  /**
   * @ngdoc service
   * @name btComponentService
   * @memberOf btUtils
   * @description
   *  This service helps to select some item in list.
   */

  angular.module('btUtils').factory('btComponentService', btComponentService);

  btComponentService.$inject = ['btEventEmitterService'];

  /**
   * @param {ecapp.IEventEmitterService} btEventEmitterService
   * @return {ecapp.IComponentService}
   */
  function btComponentService(btEventEmitterService) {
    console.log('Running btComponentService');

    var gComponents = {};
    var gGroups = {};

    btEventEmitterService.addListener('logout:success', onLogoutSuccess);

    return {
      newComponent: newComponent,
      newGroup: newGroup,
      select: select,
      collapse: collapse,
      deleteComponentsByPrefix: deleteComponentsByPrefix,
      deleteComponentById: deleteComponentById,
      deleteAllComponents: deleteAllComponents,
      deleteGroupById: deleteGroupById,
      deleteAllGroups: deleteAllGroups,
    };

    /**
     *
     */
    function onLogoutSuccess() {
      deleteAllComponents();
      deleteAllGroups();
    }

    /**
     *
     * @param {*} prefix - ?
     * @param {*} id - ?
     * @return {*}
     */
    function newComponent(prefix, id) {
      if (gComponents[prefix] === undefined) {
        gComponents[prefix] = {};
      }

      if (gComponents[prefix][id] === undefined) {
        gComponents[prefix][id] = {
          isCollapsed: true,
          isSelected: false,
        };
      }

      return gComponents[prefix][id];
    }

    /**
     *
     * @param {*} name - ?
     * @return {*}
     */
    function newGroup(name) {
      if (gGroups[name] === undefined) {
        gGroups[name] = {
          lastSelected: null,
          lastCollapsed: null,
        };
      }

      return gGroups[name];
    }

    /**
     *
     * @param {*} component - ?
     * @param {*} groupId - ?
     */
    function select(component, groupId) {
      component.isSelected = true;

      var group = newGroup(groupId);

      if (group.lastSelected && group.lastSelected !== component) {
        group.lastSelected.isSelected = false;
      }

      group.lastSelected = component;
    }

    /**
     *
     * @param {*} component - ?
     * @param {*} action - ?
     * @param {*} groupId - ?
     */
    function collapse(component, action, groupId) {
      if (action === 'show') {
        component.isCollapsed = false;
      }

      if (action === 'hide') {
        component.isCollapsed = true;
      }

      if (action === 'toggle') {
        component.isCollapsed = !component.isCollapsed;
      }

      var group = newGroup(groupId);

      if (group.lastCollapsed && group.lastCollapsed !== component) {
        group.lastCollapsed.isCollapsed = false;
      }

      group.lastCollapsed = component;
    }

    /**
     *
     * @param {*} prefix - ?
     */
    function deleteComponentsByPrefix(prefix) {
      if (gComponents[prefix]) {
        for (var key in gComponents[prefix]) {
          if (gComponents[prefix].hasOwnProperty(key)) {
            deleteComponentById(prefix, key);
          }
        }
        delete gComponents[prefix];
      }
    }

    /**
     *
     * @param {*} prefix - ?
     * @param {*} id - ?
     */
    function deleteComponentById(prefix, id) {
      if (gComponents[prefix] && gComponents[prefix][id]) {
        gComponents[prefix][id] = undefined;
        delete gComponents[prefix][id];
      }
    }

    /**
     *
     */
    function deleteAllComponents() {
      for (var key in gComponents) {
        if (gComponents.hasOwnProperty(key)) {
          deleteComponentsByPrefix(key);
        }
      }
    }

    /**
     *
     * @param {*} id - ?
     */
    function deleteGroupById(id) {
      if (gGroups[id]) {
        gGroups[id] = undefined;
        delete gGroups[id];
      }
    }

    /**
     *
     */
    function deleteAllGroups() {
      for (var key in gGroups) {
        if (gGroups.hasOwnProperty(key)) {
          deleteGroupById(key);
        }
      }
    }
  }
})();
