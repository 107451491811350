/**
 * Created by Sergey Panpurin on 06/05/2019.
 */

// @ts-check
(function btCommunityServiceClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * @ngdoc service
     * @name btCommunityService
     * @memberOf ecapp
     * @description
     *  Development service
     */
    .factory('btCommunityService', btCommunityService);

  btCommunityService.$inject = ['$q', '$http', 'btSettings', 'btErrorService', 'btOauthService', 'btSettingsService'];

  /**
   *
   * @param {angular.IQService} $q
   * @param {angular.IHttpService} $http
   * @param {ecapp.ISettings} btSettings
   * @param {ecapp.IErrorService} btErrorService
   * @param {ecapp.IOauthService} btOauthService
   * @param {ecapp.ISettingsService} btSettingsService
   * @return {ecapp.ICommunityService}
   */
  function btCommunityService($q, $http, btSettings, btErrorService, btOauthService, btSettingsService) {
    console.log('Running btCommunityService');

    var gLocalSettings = btSettingsService.getLocalSettings();
    var gSelectedCommunity = null;

    return {
      gitterJoinRoom: gitterJoinRoom,
      gitterHasRoom: gitterHasRoom,
      gitterCountUnreadItems: gitterCountUnreadItems,
      gitterInvite: gitterInvite,
      gitterLogin: gitterLogin,
      gitterMyUser: gitterMyUser,
      setSelectedCommunity: setSelectedCommunity,
      getSelectedCommunity: getSelectedCommunity,
    };

    /**
     * @return {angular.IPromise<*>}
     */
    function gitterLogin() {
      return btOauthService.login('gitter-token');
    }

    /**
     *
     * @param {*} accessToken
     * @return {angular.IPromise<any>}
     */
    function gitterMyUser(accessToken) {
      var params = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      return $http
        .get('https://api.gitter.im/v1/user/me', params)
        .catch(btErrorService.handleHTTPError)
        .then(function (response) {
          if (response.status === 200) {
            return $q.resolve(response.data);
          } else {
            return $q.reject(response);
          }
        })
        .catch(_handleUserError);
    }

    /**
     * This function sends request to backend to invite user to room.
     *
     * User can be identified by email or name. User name give ability to add user to room immediately.
     *
     * Supported error:
     * 409 Conflict - invitation already sent
     * 428 Precondition Required - user is unreachable
     *
     * @param {String} roomId - room identifier
     * @param {String} userName - user name (example: BetterTraderPro_twitter)
     * @param {String} email - user email (gitter ignore emails with 'plus' )
     * @return {angular.IPromise<*>}
     */
    function gitterInvite(roomId, userName, email) {
      var params = { room: roomId };

      if (userName) {
        params.username = userName;
      } else if (email) {
        params.email = email;
      }

      var options = {
        headers: { 'Content-Type': 'application/json' },
        params: params,
      };

      return $http
        .get(btSettings.BT_BACKEND_URL + '/gitter/invite', options)
        .catch(btErrorService.handleHTTPError)
        .then(function (response) {
          if (response.status === 200) {
            return $q.resolve(response.data);
          } else {
            if (response.data && response.data.error) {
              return $q.reject(new Error(response.data.error));
            } else {
              console.error(response);
              return $q.reject(new Error('Unknown error'));
            }
          }
        })
        .catch(_handleUserError);
    }

    /**
     *
     * @param {*} roomId
     * @param {*} userId
     * @param {*} accessToken
     */
    function gitterHasRoom(roomId, userId, accessToken) {
      gitterUserRooms(userId, accessToken).then(function (rooms) {
        var room = rooms.filter(function (room) {
          return room.id === roomId;
        })[0];

        if (room) {
          $q.resolve(true);
        } else {
          $q.resolve(false);
        }
      });
    }

    /**
     * This function adds user to selected group if it possible.
     *
     * @param {String} roomId - room identifier
     * @param {String} userId - user identifier
     * @param {String} accessToken - user access token
     * @return {angular.IPromise<*>}
     */
    function gitterJoinRoom(roomId, userId, accessToken) {
      var options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      return $http
        .post('https://api.gitter.im/v1/user/' + userId + '/rooms', { id: roomId }, options)
        .catch(btErrorService.handleHTTPError)
        .then(function (response) {
          if (response.status === 200) {
            return $q.resolve(response.data);
          } else {
            return $q.reject(response);
          }
        })
        .catch(_handleUserError);
    }

    /**
     * This function returns list of user rooms.
     *
     * @param {String} userId - user identifier
     * @param {String} accessToken - user access token
     * @return {angular.IPromise<*>}
     */
    function gitterUserRooms(userId, accessToken) {
      var params = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      return $http
        .get('https://api.gitter.im/v1/user/' + userId + '/rooms', params)
        .catch(btErrorService.handleHTTPError)
        .then(function (response) {
          if (response.status === 200) {
            return $q.resolve(response.data);
          } else {
            return $q.reject(response);
          }
        })
        .catch(_handleUserError);
    }

    /**
     *
     * @param {String[]} roomIds
     * @param {String} userId
     * @param {String} accessToken
     * @return {angular.IPromise<*>}
     */
    function gitterCountUnreadItems(roomIds, userId, accessToken) {
      return gitterUserRooms(userId, accessToken).then(function (rooms) {
        var result = { total: 0 };
        rooms
          .filter(function (room) {
            return roomIds.indexOf(room.id) !== -1;
          })
          .forEach(function (room) {
            result[room.id] = room.unreadItems;
            result['total'] += room.unreadItems;
          });

        return result;
      });
    }

    /**
     *
     * @param {*} error
     * @return {void | angular.IPromise<any> | *}
     * @private
     */
    function _handleUserError(error) {
      if (error && error.message) {
        return $q.reject(error);
      } else if (error.data && error.data.failure_message) {
        return $q.reject(new Error(error.data.failure_message));
      } else {
        return $q.reject(error);
      }
    }

    /**
     *
     * @param {*} topic
     */
    function setSelectedCommunity(topic) {
      gSelectedCommunity = topic;
    }

    /**
     *
     * @return {any}
     */
    function getSelectedCommunity() {
      return gSelectedCommunity;
    }
  }
})();
