/**
 * Created by Sergey Panpurin on 4/30/2018.
 */

(function btCountdownClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btCountdown';

  angular
    .module('ecapp')
    /**
     * This directive display countdown timer.
     *
     * @ngdoc directive
     * @name btCountdown
     * @memberOf ecapp
     */
    .directive('btCountdown', btCountdown)
    .controller('btCountdownController', btCountdownController);

  btCountdown.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btCountdown($templateCache) {
    return {
      restrict: 'E',
      scope: {
        final: '<?',
      },
      template: $templateCache.get('components/mobile/bt-countdown/bt-countdown.html'),
      controller: 'btCountdownController',
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btCountdownController.$inject = ['$scope', 'btDateService', '$interval'];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.IDateService} btDateService
   * @param {angular.IIntervalService} $interval
   */
  function btCountdownController($scope, btDateService, $interval) {
    void $scope;

    /*jshint validthis: true*/
    var vm = this;

    vm.final = vm.final || btDateService.getNowDate();

    vm.remain = btDateService
      .getMinutesSecondFromNow(vm.final, { skipZero: false, skipConvert: false })
      .format('mm:ss');

    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;

    var intervalPromise = $interval(updateCountdown, 1000);

    /**
     * Initialize component
     */
    function onInit() {}

    /**
     * Destroy component
     */
    function onDestroy() {
      stopInterval(intervalPromise);
    }

    /**
     * Stop interval updates
     * @param {*} promise
     */
    function stopInterval(promise) {
      if (promise !== null) {
        if ($interval.cancel(promise)) {
          promise = null;
        }
      }
    }

    /**
     * Update countdown every second.
     */
    function updateCountdown() {
      if (gDebug) console.log('Interval was called (updateCountdown)');
      if (vm.final <= btDateService.getNowDate()) {
        vm.class = 'animate-flicker';
        vm.remain = '00:00';
        stopInterval(intervalPromise);
      } else {
        vm.class = '';
        vm.remain = btDateService.getMinutesSecondFromNow(vm.final, null).format('mm:ss');
      }
    }
  }
})();
