(function btResetPasswordControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btResetPasswordController', btResetPasswordController);

  btResetPasswordController.$inject = [
    '$scope',
    'btAuthService',
    '$state',
    '$rootScope',
    '$ionicLoading',
    'btSettingsService',
  ];

  /**
   * Reset password controller
   *
   * @ngdoc controller
   * @name ecapp.btResetPasswordController
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.IAuthService} btAuthService
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ionic.ILoadingService} $ionicLoading - ionic loading service
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btResetPasswordController($scope, btAuthService, $state, $rootScope, $ionicLoading, btSettingsService) {
    console.log('Running btResetPasswordController');

    $scope.logoSrc = btSettingsService.getAssets('login-logo-src');
    $scope.logoAlt = btSettingsService.getAssets('login-logo-alt');

    // default data
    $scope.user = { email: '' };

    $scope.forgetPassword = forgetPasswordFunc;

    //reset the form display
    $scope.$on('$stateChangeStart', onStateChange);

    /**
     *
     */
    function onStateChange() {
      $scope.errorAlert = false;
      $scope.successAlert = false;
    }

    /**
     *
     * @param {*} forgetForm
     */
    function forgetPasswordFunc(forgetForm) {
      if (forgetForm.$valid) {
        $scope.errorAlert = false;
        $scope.successAlert = false;

        $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Reset password...</p>' });

        btAuthService
          .resetPassword($scope.user.email)
          .then(function onSuccess() {
            $ionicLoading.hide();

            $scope.successAlert = true;
          })
          .catch(function onFailure(error) {
            $ionicLoading.hide();

            console.log('Error during resetting of password', error);

            $scope.errorAlert = true;
          });
      } else {
        $scope.errorAlert = true;
      }
    }
  }
})();
