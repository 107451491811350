/**
 * Created by Sergey Panpurin on 2/6/19
 */

(function btTradingSessionsDescriptionClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive displays trading sessions.
     *
     * @ngdoc directive
     * @name btTradingSessionsDescription
     * @memberOf ecapp
     */
    .directive('btTradingSessionsDescription', btTradingSessionsDescription);

  btTradingSessionsDescription.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btTradingSessionsDescription($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('components/mobile/bt-trading-sessions/bt-trading-sessions-description.html'),
      controller: btTradingSessionsDescriptionController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btTradingSessionsDescriptionController.$inject = [];

  /**
   *
   */
  function btTradingSessionsDescriptionController() {
    /*jshint validthis: true*/
    var vm = this;

    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;
    vm.$onChanges = onChanges;

    /**
     *
     */
    function onInit() {}

    /**
     *
     * @param {*} changes
     */
    function onChanges(changes) {}

    /**
     *
     */
    function onDestroy() {}
  }
})();
