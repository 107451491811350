/**
 * Created by Sergey Panpurin on 2/3/19
 */

(function btTimeIntervalClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     *  This directive displays time interval.
     *  This directive can be used to display any interval.
     *
     *        open     close
     *  |------|||||||||||-------|
     *  start                  end
     *
     * @ngdoc directive
     * @name btTimeInterval
     * @memberOf ecapp
     * @restrict E
     * @scope
     * @param {String} text - interval title
     * @param {*} open - open value (beginning of interval)
     * @param {*} close - close value (end of interval)
     * @param {*} start - start value (beginning of viewport)
     * @param {*} end - end value (end of viewport)
     */
    .directive('btTimeInterval', btTimeInterval);

  btTimeInterval.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btTimeInterval($templateCache) {
    return {
      restrict: 'E',
      scope: {
        text: '@',
        color: '@',
        open: '<',
        close: '<',
        start: '<',
        end: '<',
      },
      template: $templateCache.get('components/mobile/bt-trading-sessions/bt-time-interval.html'),
      controller: btTimeIntervalController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btTimeIntervalController.$inject = [];

  /**
   *
   */
  function btTimeIntervalController() {
    /*jshint validthis: true*/
    var vm = this;

    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;

    /**
     *
     */
    function onInit() {
      var scale = vm.end - vm.start;

      if (vm.open >= vm.start && vm.close <= vm.end) {
        vm.width = ((vm.close - vm.open) / scale) * 100;
        vm.left = ((vm.open - vm.start) / scale) * 100;
      } else if (vm.open < vm.start && vm.close > vm.start) {
        vm.width = ((vm.close - vm.start) / scale) * 100;
        vm.left = 0;
      } else if (vm.close > vm.end) {
        vm.width = ((vm.end - vm.open) / scale) * 100;
        vm.left = 100 - vm.width;
      } else {
        vm.left = 0;
        vm.width = 0;
      }
      vm.style = {
        width: vm.width + (vm.width === 0 ? '' : '%'),
        left: vm.left + (vm.left === 0 ? '' : '%'),
        display: vm.width === 0 && vm.left === 0 ? 'none' : 'block',
        backgroundColor: vm.color,
      };
    }

    /**
     *
     */
    function onDestroy() {}
  }
})();
