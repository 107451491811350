// @ts-check
(function btBrokerControllerClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btBrokerControllers:';

  angular.module('ecapp').controller('btPositionsController', btPositionsController);
  btPositionsController.$inject = ['$scope', 'TS', 'btTradingService', 'btToastrService', '$interval'];

  angular.module('ecapp').controller('btOrdersController', btOrdersController);
  btOrdersController.$inject = ['$scope', 'TS', 'btTradingService', 'btToastrService', '$interval'];

  angular.module('ecapp').controller('btBalanceController', btBalanceController);
  btBalanceController.$inject = [
    '$q',
    '$scope',
    'btTradingService',
    'btToastrService',
    '$interval',
    '$ionicHistory',
    '$state',
  ];

  /**
   * Common part of brokerage initialization
   *
   * @param {string} name - controller name
   * @param {ecapp.ICustomScope} $scope - controller scope
   * @param {angular.IIntervalService} $interval - setInterval wrapper
   * @param {ecapp.ITradingService} btTradingService - trading service
   * @param {ecapp.IToastrService} btToastrService - notification service
   * @param {*} opts - options
   * @param {*} initialize - callback to initialize data
   * @param {*} refresh - callback to refresh data
   */
  function initBrokerageController(
    name,
    $scope,
    $interval,
    btTradingService,
    btToastrService,
    opts,
    initialize,
    refresh
  ) {
    console.log(gPrefix, 'running on scope', $scope.$id);

    // setup states
    $scope.hasResult = false;
    $scope.hasError = false;
    $scope.isLoading = true;
    $scope.broker = $scope.broker || {};

    // interval function handler
    $scope.intervalFunc = null;

    // on refresh run callback
    $scope.doRefresh = doRefresh;
    $scope.processError = processError;
    $scope.handleNoAccounts = handleNoAccounts;

    // cancel interval function on destroy
    $scope.$on('$destroy', onDestroy);
    $scope.$on('$ionicView.leave', onLeave);

    activate();

    /**
     * This function cancels interval function on destroy.
     */
    function onDestroy() {
      if (gDebug) console.log(gPrefix, $scope.$id, name + ' is destroying...');
      if ($scope.intervalFunc !== null) {
        if (gDebug)
          console.log(gPrefix, $scope.$id, name + ' ' + $scope.intervalFunc.$$intervalId + ' interval was removed.');
        $interval.cancel($scope.intervalFunc);
        $scope.intervalFunc = null;
      }
    }

    /**
     * This function cancels interval function on leave.
     */
    function onLeave() {
      if (gDebug) console.log(gPrefix, $scope.$id, name + ' is leaving...');
      if ($scope.intervalFunc !== null) {
        if (gDebug)
          console.log(gPrefix, $scope.$id, name + ' ' + $scope.intervalFunc.$$intervalId + ' interval was removed.');
        $interval.cancel($scope.intervalFunc);
        $scope.intervalFunc = null;
      }
    }

    /**
     * This function activate controller.
     *
     * @return {angular.IPromise<any>}
     */
    function activate() {
      // initialization of trading service
      return btTradingService
        .initialize()
        .then(function () {
          if (btTradingService.isDefaultBroker()) {
            // block for default broker
            $scope.hasResult = false;
            $scope.isLoading = false;
            $scope.hasError = false;
          } else {
            return btTradingService
              .connect()
              .then(function () {
                $scope.broker.data = btTradingService.getBrokerInfo();
                var result = initialize();

                if (result && typeof result.then === 'function') {
                  return result;
                }

                // if (btTradingService.getBrokerName() !== 'ctrader') {
                // var delay = 5000;
                // add auto refresh
                // if (!opts.noRefresh) {
                //   $scope.intervalFunc = $interval(updateBrokerData, delay);
                //   if (gDebug) console.log(gPrefix, $scope.$id, name + ' ' + $scope.intervalFunc.$$intervalId + ' interval was created.');
                // }
                // }
              })
              .catch($scope.processError.bind(null, 'Broker connection', false));
          }
        })
        .catch($scope.processError.bind(null, 'Broker initialization', false));
    }

    /**
     * This function refresh the page. It works just on mobile.
     */
    function doRefresh() {
      if (!$scope.hasError) {
        var result = refresh();

        if (result && typeof result.then === 'function') {
          result.finally(function () {
            $scope.$broadcast('scroll.refreshComplete');
          });
          return;
        }
      }

      $scope.$broadcast('scroll.refreshComplete');
    }

    // /**
    //  *
    //  */
    // function updateBrokerData() {
    //   if (gDebug) console.log(gPrefix, $scope.$id, name + ' ' + $scope.intervalFunc.$$intervalId + ' interval was called.');
    //   callback();
    // }

    /**
     * This function process errors
     *
     * @param {String} title - notification title
     * @param {Boolean} notify - notify about error or not
     * @param {Object} error - error object
     */
    function processError(title, notify, error) {
      $scope.hasResult = false;
      $scope.isLoading = false;
      $scope.hasError = true;

      console.error(gPrefix, 'API call failed.', error);

      if (notify) {
        if (error && error.message) btToastrService.error(error.message, title, { type: 'trade' });
      } else {
        $scope.hasError = false;
      }
    }

    /**
     * This function handle no accounts error.
     */
    function handleNoAccounts() {
      $scope.noAccounts = true;
      btToastrService.error('No trading accounts.', 'Broker account', { type: 'trade' });
      $scope.hasResult = false;
      $scope.hasError = true;
      if (btTradingService.getBrokerName() === 'oanda') {
        $scope.errorMessage = 'No supported accounts. Please check that you have account v20.';
      } else {
        $scope.errorMessage = 'No trading accounts.';
      }
      $scope.isLoading = false;
    }
  }

  /**
   *
   * @param {ecapp.ICustomScope} $scope - ?
   * @param {ecapp.ITSConstants} TS
   * @param {ecapp.ITradingService} btTradingService - ?
   * @param {ecapp.IToastrService} btToastrService - ?
   * @param {angular.IIntervalService} $interval - ?
   */
  function btPositionsController($scope, TS, btTradingService, btToastrService, $interval) {
    // initBrokerageController('positions', $scope, $interval, btTradingService, btToastrService, {noRefresh: true}, getPositions);
    initBrokerageController(
      'positions',
      $scope,
      $interval,
      btTradingService,
      btToastrService,
      {},
      getPositions,
      refreshPositions
    );

    $scope.accounts = [{ name: 'All Accounts' }];
    $scope.selectedAccount = $scope.accounts[0];
    $scope.isCryptoAccount = false;

    $scope.positions = [];

    $scope.closePosition = closePosition;
    $scope.closeAllPositions = closeAllPositions;

    $scope.modulo = Math.abs;
    $scope.calcTotalOPL = calcTotalOPL;

    /**
     * This function try to close position. User should confirm the action.
     *
     * @param {ecapp.ITradingPosition} position
     * @return {angular.IPromise<*>}
     */
    function closePosition(position) {
      if (gDebug) console.log(gPrefix, 'closing position...', position);

      return btTradingService.closePosition(position).then(function (data) {
        if (data.empty) {
          if (gDebug) console.log(gPrefix, 'closing was canceled', data);
        } else {
          if (gDebug) console.log(gPrefix, 'position was closed', data);
          // getPositions();
        }
      });
    }

    /**
     * This function try to close all shown positions. User should confirm the action.
     *
     * @param {ecapp.ITradingPosition[]} positions
     * @return {angular.IPromise<*>}
     */
    function closeAllPositions(positions) {
      if (gDebug) console.log(gPrefix, 'closing all positions...');

      return btTradingService.closeAllPositions(positions).then(function (data) {
        if (data.empty) {
          if (gDebug) console.log(gPrefix, 'closing was canceled', data);
        } else {
          if (gDebug) console.log(gPrefix, 'all position was closed', data);
          // getPositions();
        }
      });
    }

    // /**
    //  * This function calculate open profit and loss for accounts and total.
    //  *
    //  * @param {ecapp.ITradingPosition[]} positions
    //  * @return {{all: Number}}
    //  */
    // function calcTotal(positions) {
    //   var total = {all: 0};
    //   for (var i = 0; i < positions.length; i++) {
    //     total.all += positions[i].OPL;
    //     total[positions[i].acc] = total[positions[i].acc] == null ? positions[i].OPL : total[positions[i].acc] + positions[i].OPL;
    //   }
    //   return total;
    // }

    /**
     * This function calculate open profit and loss for accounts and total.
     *
     * @param {ecapp.ITradingPosition[]} positions
     * @return {Number}
     */
    function calcTotalOPL(positions) {
      var total = 0;
      for (var i = 0; i < positions.length; i++) {
        total += positions[i].OPL;
      }
      return total;
    }

    /**
     * This function initializes positions
     */
    function getPositions() {
      $scope.hasResult = true;
      $scope.hasError = false;
      $scope.isLoading = false;

      $scope.positions = btTradingService.getLastPositions();

      const selectedAccount = btTradingService.getSelectedAccount();
      $scope.isCryptoAccount = selectedAccount && selectedAccount.type === TS.ACCOUNT_TYPE.CRYPTO;

      // $scope.total = calcTotal($scope.positions);

      // return btTradingService
      //   .getAccounts()
      //   .then(function (accounts) {
      //     if (accounts.length === 0) {
      //       $scope.handleNoAccounts();
      //     } else {
      //       $scope.accounts = [{name: 'All Accounts'}].concat(accounts);
      //       var accountKeys = btTradingService.parseAccountList(accounts);
      //
      //       btTradingService.getPositions(accountKeys)
      //         .then(function (positions) {
      //           $scope.hasResult = true;
      //           $scope.hasError = false;
      //           $scope.isLoading = false;
      //
      //           $scope.total = calcTotal(positions);
      //
      //           positions.forEach(function (pos) {
      //             if (pos.lastPrice === '-') {
      //               btTradingService.getLiveCandleData(pos.symbol)
      //                 .then(function (data) {
      //                   if (data !== undefined) {
      //                     pos.lastPrice = data.now.lastText;
      //                   }
      //                 });
      //             }
      //           });
      //           $scope.positions = positions;
      //         })
      //         .catch($scope.processError.bind(null, 'Broker positions', true));
      //     }
      //   })
      //   .catch($scope.processError.bind(null, 'Broker accounts', true));
    }

    /**
     * This function refreshes positions data. Now it just show loader.
     */
    function refreshPositions() {
      $scope.isLoading = true;
      $scope.hasResult = false;
      $interval(
        function () {
          $scope.isLoading = false;
          $scope.hasResult = true;
        },
        1000,
        1
      );
    }
  }

  /**
   *
   * @param {ecapp.ICustomScope} $scope - ?
   * @param {ecapp.ITSConstants} TS
   * @param {ecapp.ITradingService} btTradingService - ?
   * @param {ecapp.IToastrService} btToastrService - ?
   * @param {angular.IIntervalService} $interval - ?
   */
  function btOrdersController($scope, TS, btTradingService, btToastrService, $interval) {
    initBrokerageController(
      'orders',
      $scope,
      $interval,
      btTradingService,
      btToastrService,
      {},
      getOrders,
      refreshOrders
    );

    $scope.accounts = [{ name: 'All Accounts' }];
    $scope.selectedAccount = $scope.accounts[0];
    $scope.isCryptoAccount = $scope.selectedAccount.type === TS.ACCOUNT_TYPE.CRYPTO;

    $scope.filter = { type: 'open' };
    $scope.orders = [];

    $scope.cancelOrder = cancelOrder;
    $scope.cancelAllOrders = cancelAllOrders;
    $scope.getOrderFilter = getOrderFilter;
    $scope.hasFilteredOrders = hasFilteredOrders;

    /**
     *
     * @param {*} order
     * @return {*}
     */
    function getOrderFilter(order) {
      if ($scope.filter.type === 'open') return order.status === 'Received' || order.status === 'Queued';
      if ($scope.filter.type === 'filled') return order.status === 'Filled' || order.status === 'PartialFill';
      if ($scope.filter.type === 'canceled') return order.status === 'Rejected' || order.status === 'UROut';
      return false;
    }

    /**
     *
     * @param {*} orders
     * @return {*}
     */
    function hasFilteredOrders(orders) {
      return orders.filter(getOrderFilter).length > 0;
    }

    /**
     * This function initializes orders
     */
    function getOrders() {
      $scope.hasResult = true;
      $scope.hasError = false;
      $scope.isLoading = false;

      $scope.orders = btTradingService.getLastOrders();

      // return btTradingService
      //   .getAccounts()
      //   .then(function (accounts) {
      //     if (accounts.length === 0) {
      //       $scope.handleNoAccounts();
      //     } else {
      //       $scope.accounts = [{name: 'All Accounts'}].concat(accounts);
      //       var accountKeys = btTradingService.parseAccountList(accounts);
      //
      //       btTradingService
      //         .getOrders(accountKeys)
      //         .then(function (orders) {
      //           $scope.hasResult = true;
      //           $scope.hasError = false;
      //           $scope.isLoading = false;
      //
      //           $scope.orders = orders;
      //         })
      //         .catch($scope.processError.bind(null, 'Broker orders', true));
      //     }
      //   })
      //   .catch($scope.processError.bind(null, 'Broker accounts', true));
    }

    /**
     * This function refreshes orders data. Now it just show loader.
     */
    function refreshOrders() {
      $scope.isLoading = true;
      $scope.hasResult = false;
      $interval(
        function () {
          $scope.isLoading = false;
          $scope.hasResult = true;
        },
        1000,
        1
      );
    }

    /**
     * This function cancels order.
     *
     * @param {String} orderId - order identifier
     */
    function cancelOrder(orderId) {
      btTradingService.cancelOrder(orderId);
    }

    /**
     * This function cancels all open orders.
     *
     * @param {btOrderObject[]} orders
     */
    function cancelAllOrders(orders) {
      orders.forEach(function (order) {
        if (isOrderOpened(order)) {
          btTradingService.cancelOrder(order.key);
        }
      });

      // getOrders();
    }

    /**
     * This function checks whether order is open.
     *
     * @param {btOrderObject} order
     * @return {Boolean}
     */
    function isOrderOpened(order) {
      return order.status === 'Received' || order.status === 'Queued';
    }
  }

  /**
   *
   * @param {angular.IQService} $q - promise interface
   * @param {ecapp.ICustomScope} $scope - ?
   * @param {ecapp.ITradingService} btTradingService - ?
   * @param {ecapp.IToastrService} btToastrService - ?
   * @param {angular.IIntervalService} $interval - ?
   * @param {ionic.IHistoryService} $ionicHistory - ionic history service
   * @param {angular.ui.IStateService} $state - ?
   */
  function btBalanceController($q, $scope, btTradingService, btToastrService, $interval, $ionicHistory, $state) {
    // common initialization
    initBrokerageController(
      'balances',
      $scope,
      $interval,
      btTradingService,
      btToastrService,
      {},
      getBalance,
      refreshBalance
    );

    $scope.balances = null;
    $scope.noAccounts = false;
    $scope.isBadAccount = false;
    $scope.selected = {
      account: null,
    };
    $scope.selectedOption = null;

    $scope.$watch('selected.account', function (newVal) {
      if (newVal) getAccountBalance();
    });

    $scope.$watchCollection('balances', function (newVal) {
      if (newVal) $scope.selectedOption = newVal[0];
    });

    /**
     * Select account
     *
     * @param {String} id - account id
     */
    $scope.selectAccount = function (id) {
      if (btTradingService.selectAccount(id) == null) {
        btToastrService.error("Can't select the account.", 'Broker account', { type: 'trade' });
      }
    };

    $scope.isAccountSelected = function (id) {
      return btTradingService.isAccountSelected(id);
    };

    $scope.disconnectBalance = function () {
      $scope.isLoading = true;

      $ionicHistory.nextViewOptions({
        disableAnimate: true,
        disableBack: true,
      });

      btTradingService
        .disconnect()
        .then($ionicHistory.clearCache)
        .then(function () {
          $interval.cancel($scope.intervalFunc);
          $scope.intervalFunc = null;
          console.log('BrokerData: try to open ecapp.app.broker');
          $state.go('ecapp.app.broker');
        })
        .catch(function (error) {
          console.error(error);
        })
        .finally(function () {
          $scope.isLoading = false;
        });
    };

    /**
     * Returns balance
     *
     * @return {*}
     */
    function getBalance() {
      return btTradingService
        .getAccounts()
        .then(function (accounts) {
          $scope.accounts = accounts;

          if (accounts.length === 0) {
            $scope.handleNoAccounts();
          } else {
            $scope.selected.account = $scope.accounts.filter(function (value) {
              return value.key === btTradingService.getSelectedAccountId();
            })[0];
          }
        })
        .catch($scope.processError.bind(null, 'Broker account', true));
    }

    /**
     *
     */
    function getAccountBalance() {
      if ($scope.selected.account.key === btTradingService.getSelectedAccountId()) {
        $scope.balances = btTradingService.getLastBalances();
        $scope.selectedOption = $scope.balances[0];

        $scope.hasResult = true;
        $scope.hasError = false;
        $scope.isLoading = false;
        $scope.isBadAccount = false;
      } else {
        btTradingService
          .getBalances([$scope.selected.account.key])
          .then(function (balances) {
            $scope.hasResult = true;
            $scope.hasError = false;
            $scope.isLoading = false;
            $scope.isBadAccount = false;

            $scope.balances = balances;
            $scope.selectedOption = $scope.balances[0];
          })
          .catch(function (reason) {
            if (reason.message === 'The provided request was forbidden.') $scope.isBadAccount = true;
            return $q.reject(reason);
          })
          .catch($scope.processError.bind(null, 'Broker balance', true));
      }
    }

    /**
     *
     */
    function refreshBalance() {
      $scope.isBadAccount = false;
    }
  }
})();
