/**
 * Created by Orly on 26/03/2019.
 */

// @ts-check
(function btBarometerServiceClosure() {
  'use strict';

  angular
    .module('ecapp')

    /**
     * This factory is to manage barometer
     *
     * @ngdoc service
     * @name btBarometerService
     * @memberOf ecapp
     * @return {*}
     */
    .factory('btBarometerService', btBarometerService);

  btBarometerService.$inject = ['$q'];

  /**
   *
   * @param {angular.IQService} $q
   * @return {ecapp.IBarometerService}
   */
  function btBarometerService($q) {
    var gDebug = false;
    var gBarometer = { element: null, isShown: true };

    return {
      init: init,
      toggle: toggleBarometer,
      getBarometer: getBarometer,
    };

    /**
     *
     */
    function init() {
      var promise = $q.defer();
      gBarometer.element = $('.bt-barometer-wrapper.view-charts');
      // gBarometer.isShown = gBarometer.element.is(':visible');
      if (gDebug) console.log('btBarometerService', gBarometer);
      promise.resolve();
    }

    /**
     *
     */
    function toggleBarometer() {
      if (gBarometer.isShown) {
        gBarometer.isShown = false;
        gBarometer.element.hide();
      } else {
        gBarometer.isShown = true;
        gBarometer.element.show();
      }
      // gBarometer.isShown = gBarometer.element.is(':visible');
    }

    /**
     *
     * @return {any}
     */
    function getBarometer() {
      return gBarometer;
    }
  }
})();
