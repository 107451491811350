/**
 * Created by Stas on 03/16/20.
 */

(function btGlobalBannerManifestation() {
  'use strict';

  angular.module('ecapp').directive('btGlobalBanner', directive);

  directive.$inject = ['$templateCache'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function directive($templateCache) {
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('directives/common/bt-global-banner.html'),
      controller: controller,
    };
  }

  controller.$inject = ['$scope', 'btPushNotificationService', 'btSettingsService'];

  /**
   *
   * @param {*} $scope
   * @param {ecapp.IPushNotificationService} btPushNotificationService
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function controller($scope, btPushNotificationService, btSettingsService) {
    $scope.hasClose = false;
    $scope.banner = btSettingsService.getGlobalBanner();

    $scope.closeBanner = closeBanner;
    $scope.processAnswer = processAnswer;
    $scope.$watch('banner.config.id', function (newValue) {
      if (newValue !== undefined) {
        $scope.hasClose = $scope.banner.config.onClose;
      }
    });

    /**
     *
     * @param {*} answer
     */
    function processAnswer(answer) {
      $scope.banner.config.onAnswer(answer).then(function (success) {
        if (success) btSettingsService.closeGlobalBanner();
      });
    }

    /**
     *
     */
    function closeBanner() {
      $scope.banner.config.onClose();
      btSettingsService.closeGlobalBanner();
    }
  }
})();
