(function () {
  'use strict';

  angular
    .module('ecapp')
    /**
     * ???
     *
     * @ngdoc directive
     * @name btEventModalReportText
     * @memberOf ecapp
     */
    .directive('btEventModalReportText', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('directives/event-modal/report/text.html'),
        };
      },
    ]);
})();
